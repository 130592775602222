import { Overwrite } from 'utility-types'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from 'core/injector/injector.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { IShippingModuleService, ShippingModule, ShippingModuleDefinition } from './shipping.module'
import { IShippingService } from './shipping.service'
import { IShippingUtilsService } from './shipping-utils.service'
import { shippingServiceToken, shippingUtilsServiceToken } from './shipping.constants'

function resolveShippingModule(): Overwrite<ShippingModuleDefinition, IShippingModuleService> {
  const module = LazyModulesService.resolveModule<ShippingModuleDefinition>(ShippingModule)
  const ShippingUtilsService = Injector.resolve<IShippingUtilsService>(shippingUtilsServiceToken)
  const ShippingService = Injector.resolve<IShippingService>(shippingServiceToken)
  return { ...module, ShippingService, ShippingUtilsService }
}

export const useShippingModule = moduleHookResolver(resolveShippingModule)
