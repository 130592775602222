import * as yup from 'yup'
import { DateSchema } from 'yup'
import { addDays } from 'date-fns'
import { validationMessage } from 'common/validation/validation'
import { convertDate } from 'common/utils/date.utils'
import { MAX_EXECUTION_DAY, MIN_EXECUTION_DAY, MIN_NUMBER_VALUE, RoutingItemControlName } from './routing-item.constants'

export const validationSchema = yup.object().shape(
  {
    [RoutingItemControlName.WorkCenter]: yup.object().nullable().required(validationMessage.isRequired),
    [RoutingItemControlName.ExecutionDay]: yup
      .number()
      .min(MIN_EXECUTION_DAY, validationMessage.min(MIN_EXECUTION_DAY))
      .max(MAX_EXECUTION_DAY, validationMessage.max(MAX_EXECUTION_DAY))
      .nullable()
      .required(validationMessage.isRequired),
    [RoutingItemControlName.SetupTime]: yup.number().min(MIN_NUMBER_VALUE, validationMessage.min(MIN_NUMBER_VALUE)).nullable(),
    [RoutingItemControlName.ReportSetupCostAs]: yup.object().nullable().required(validationMessage.isRequired),
    [RoutingItemControlName.ReportRunCostAs]: yup.object().nullable().required(validationMessage.isRequired),
    [RoutingItemControlName.StandardOperation]: yup.object().nullable(),
    [RoutingItemControlName.RunTime]: yup.number().min(MIN_NUMBER_VALUE, validationMessage.min(MIN_NUMBER_VALUE)).nullable(),
    [RoutingItemControlName.Per]: yup.number().min(MIN_NUMBER_VALUE, validationMessage.min(MIN_NUMBER_VALUE)).nullable(),
    [RoutingItemControlName.ProductionRatio]: yup.number().min(MIN_NUMBER_VALUE, validationMessage.min(MIN_NUMBER_VALUE)).nullable(),
    [RoutingItemControlName.Expires]: yup
      .date()
      .nullable()
      .typeError(validationMessage.invalidDate)
      .when(RoutingItemControlName.Effective, (effective: Date | null, schema: DateSchema) =>
        effective instanceof Date ? schema.min(addDays(effective, 1), validationMessage.minDate(convertDate(effective))) : schema
      ),
    [RoutingItemControlName.Effective]: yup
      .date()
      .nullable()
      .typeError(validationMessage.invalidDate)

      .when(RoutingItemControlName.Expires, (expires: Date | null, schema: DateSchema) =>
        expires instanceof Date ? schema.max(addDays(expires, -1), validationMessage.maxDate(convertDate(expires))) : schema
      ),
  },

  [[RoutingItemControlName.Expires, RoutingItemControlName.Effective]]
)
