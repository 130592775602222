import { IPaginationData, IPaginationParams } from 'common/common.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { IRoutingItemsFilters, IRoutingListFilters, IRoutingService } from './routing.service'
import { IRoutingListTableRow } from './routing-list/routing-list.types'
import { normalizeData, normalizeRoutingItemsData } from './routing.utils'
import { IRoutingItem, WIPLocationOption } from './routing.types'
import { IRoutingDetailsTableItem } from './routing-details/routing-details.types'
import { checkPaginationParams } from '../../common/utils/utils'
import { DocumentType, IDocumentsService } from '../../documents/documents.types'

export interface IRoutingUtilsService {
  fetchRoutings(
    filters: IRoutingListFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IRoutingListTableRow>>
  fetchRoutingItems(
    { itemNumber, showExpired, showFuture }: IRoutingItemsFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IRoutingDetailsTableItem>>
  requestRoutingItems(itemNumber: string): Promise<IRoutingItem[]>
  loadWipLocationOptions(page: number, limit: number): Promise<IPaginationData<WIPLocationOption>>
}

export class RoutingUtilsService implements IRoutingUtilsService {
  constructor(private readonly routingService: IRoutingService, private readonly documentsService: IDocumentsService) {
    bindAllInstanceMethods(this)
  }

  public async fetchRoutings(
    filters: IRoutingListFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IRoutingListTableRow>> {
    const { total, data } = await this.routingService.getAll(filters, paginationParams, sortOptions)
    return {
      data: normalizeData(data),
      total,
    }
  }

  public async fetchRoutingItems(
    { itemNumber, showExpired, showFuture }: IRoutingItemsFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IRoutingDetailsTableItem>> {
    if (!itemNumber) {
      return {
        data: [],
        total: 0,
      }
    }
    const { total, data } = await this.routingService.getAllItems(itemNumber, { showExpired, showFuture }, paginationParams, sortOptions)
    return {
      data: normalizeRoutingItemsData(data),
      total,
    }
  }

  public async requestRoutingItems(itemNumber: string): Promise<IRoutingItem[]> {
    try {
      const { routing_items } = await this.routingService.get(itemNumber, false, false)
      return routing_items
    } catch {
      return []
    }
  }

  public async loadWipLocationOptions(page: number, limit: number): Promise<IPaginationData<WIPLocationOption>> {
    const paginationParams = checkPaginationParams(page, limit)

    const { total, data } = await this.documentsService.getDocuments(DocumentType.List, paginationParams)
    return {
      total,
      data: data.map(({ name, number }) => ({
        id: number,
        label: `${name}-${number}`,
        wip_location_site: name,
      })),
    }
  }
}
