import { IXtTableCellAction } from '../../../../components/table/table-cell-actions/table-cell-actions'
import { SalesOrdersListAction } from '../../../../sales-orders/components/sales-orders-list-general/sales-orders-list-general.types'
import { SvgIconIds } from '../../../../components/svg-icon/svg-icon.types'

export const opportunitySalesOrderActions: IXtTableCellAction[] = [
  {
    name: SalesOrdersListAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: SalesOrdersListAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: SalesOrdersListAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
  {
    name: SalesOrdersListAction.Print,
    label: 'Print',
    icon: SvgIconIds.PRINT,
  },
]
