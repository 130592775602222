import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { DictionaryModule } from 'dictionary/dictionary.module'
import { itemSourcesModule } from './item-source.constants'
import { ItemSourceUtilsModule } from './item-source-utils.module'

export type ItemSourceModuleDefinition = typeof import('./item-source.exports')

export const ItemSourceModule: ILazyModule<ItemSourceModuleDefinition> = {
  name: itemSourcesModule,
  resolver: () => import('./item-source.exports'),
  dependencies: [ItemSourceUtilsModule, DictionaryModule],
}
