import { Observable } from 'rxjs'
import { IRemarksHook } from 'comments/remarks/remarks-hook'
import { ICharacteristicsHook } from 'characteristics/characteristics-hook'
import { IDocumentsHook } from 'documents/documents-hook'
import { XtMode } from 'common/common.types'

export enum AccountDetailsTabsName {
  Contacts = 'Contacts',
  Activities = 'Activities',
  Characteristics = 'Characteristics',
  Documents = 'Documents',
  Remarks = 'Remarks',
}

export interface IAccountDetailsTabs {
  account: IAccountInput
  disabled: boolean
  isMobile: boolean
  remarks: IRemarksHook
  characteristics: ICharacteristicsHook
  documents: IDocumentsHook
  mode: XtMode
  contactsUpdate$: Observable<unknown>
  tasksUpdate$: Observable<unknown>
}

export interface IAccountInput {
  account_name: string | null
  account_number: string | null
  editable: boolean
}
