import { nanoid } from 'nanoid'
import { CountryStates } from 'dictionary/dictionary.types'
import { IAccount } from '../create-accounts.types'
import { CreateAccountFormField } from './create-account-dialog.constants'
import { ICreateAccountValues } from './create-account-dialog.types'

export function defineAccountData(data: IAccount | null, countryStates?: CountryStates): ICreateAccountValues {
  return {
    [CreateAccountFormField.Id]: data?.id ?? nanoid(),
    [CreateAccountFormField.AccountNumber]: data?.account_number ?? '',
    [CreateAccountFormField.AccountName]: data?.account_name ?? '',
    [CreateAccountFormField.AccountType]: data?.account_type ?? 'Prospect',
    [CreateAccountFormField.FirstName]: data?.first_name ?? '',
    [CreateAccountFormField.MiddleName]: data?.middle_name ?? '',
    [CreateAccountFormField.LastName]: data?.last_name ?? '',
    [CreateAccountFormField.Active]: data?.active ?? true,
    [CreateAccountFormField.Address1]: data?.address1 ?? '',
    [CreateAccountFormField.Address2]: data?.address2 ?? '',
    [CreateAccountFormField.City]: data?.city ?? '',
    [CreateAccountFormField.Country]: countryStates?.initialCountry ?? null,
    [CreateAccountFormField.State]: countryStates?.initialState ?? null,
    [CreateAccountFormField.Postalcode]: data?.postalcode ?? '',
    [CreateAccountFormField.Email]: data?.email ?? '',
    [CreateAccountFormField.Phone]: data?.phone ?? '',
    [CreateAccountFormField.AllowSMS]: data?.allowsms ?? false,
  }
}
