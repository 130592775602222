import { IRouteGuard } from 'common/routing/routing.types'
import { homeRoutePath } from 'home/home.constants'
import { Injector } from 'core/injector/injector.service'
import { IAuthService } from '../auth.types'
import { authServiceToken } from '../auth.constants'

export class LoginGuard implements IRouteGuard {
  public readonly redirectTo: string = homeRoutePath
  private readonly authService: IAuthService = Injector.resolve<IAuthService>(authServiceToken)

  public validate(): boolean {
    return !this.authService.isAuthenticated()
  }
}
