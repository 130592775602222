import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IClassCodeDefaultTaxType } from 'products/items-shared/class-codes/class-codes.types'
import { ItemType } from 'products/items/items.types'
import { ITaxType } from 'products/items-shared/tax-types/tax-types.types'
import { convertClassCodeTaxTypes } from 'products/items-shared/items-shared.utils'
import { formFieldTypes, formFieldValues } from './item-details-card.constants'
import { IItemDetailsFormFieldState, ItemDetailsFormFieldStateField } from './item-details-card.types'

function checkFormFieldTypesEntry(
  itemTypeId: string
): (entry: [string, ItemType[]]) => entry is [ItemDetailsFormFieldStateField, ItemType[]] {
  return (entry: [string, ItemType[]]): entry is [ItemDetailsFormFieldStateField, ItemType[]] => {
    const [_, types] = entry
    return !!types.find((type) => type.valueOf() === itemTypeId)
  }
}

export function calculateFormFieldState(itemType: IXtAutocompleteOption | null): IItemDetailsFormFieldState[] | null {
  if (!itemType) {
    return null
  }
  return Object.entries(formFieldTypes)
    .filter<[ItemDetailsFormFieldStateField, ItemType[]]>(checkFormFieldTypesEntry(itemType.id))
    .map(([field]) => {
      const value = formFieldValues[field]

      return { field, disabled: true, value }
    })
}

export function convertToTaxTypes(taxTypes: ITaxType[], defaultTaxTypes: IClassCodeDefaultTaxType[]): ITaxType[] {
  const convertedDefaults = convertClassCodeTaxTypes(defaultTaxTypes)

  if (!taxTypes.length) {
    return convertedDefaults
  }

  const filteredTaxTypes = taxTypes.filter((taxType) => !taxType.isDefault)

  return [...filteredTaxTypes, ...convertedDefaults]
}
