import { ICharacteristicsUtilsService } from './characteristics/characteristics-utils.service'
import { ICommentTypesUtilsService } from './comment-types/comment-types-utils.service'
import { IRolesManagementService } from './roles-management/roles-management.types'
import { ILazyModule } from '../../core/react-lazy-modules/react-lazy-modules.types'
import {
  setupCharacteristicsUtilsServiceToken,
  setupCommentTypesUtilsServiceToken,
  setupRolesManagementServiceToken,
  setupRolesManagementUtilsServiceToken,
  setupSystemModule,
} from './system.constants'
import { Injector } from '../../core/injector/injector.service'
import { ICharacteristicService } from '../../characteristics/characteristics.sevice'
import { characteristicsServiceToken } from '../../characteristics/characteristics.constants'
import { Scope } from '../../core/injector/injector.types'
import { ICommentsService } from '../../comments/comments.service'
import { commentsServiceToken } from '../../comments/comments.constants'
import { CharacteristicsModule } from '../../characteristics/characteristics.module'
import { CommentsModule } from '../../comments/comments.module'
import { IRolesManagementUtilsService } from './roles-management/roles-management-utils.service'
import { DocumentsModule } from '../../documents/documents.module'
import { CoreModule } from '../../core/core.module'
import { sourceServiceToken } from '../../core/core.constants'
import { ISourceService } from '../../core/services/source.service'
import { httpClientToken, IHttpClient } from '../../core/services/http-client'

export type SetupSystemModuleDefinition = typeof import('./system.exports')

export interface ISetupSystemModuleServices {
  RolesManagementService: IRolesManagementService
  CharacteristicsUtilsService: ICharacteristicsUtilsService
  CommentTypesUtilsService: ICommentTypesUtilsService
  RolesManagementUtilsService: IRolesManagementUtilsService
}

function systemModuleInitializer({
  CharacteristicsUtilsService,
  CommentTypesUtilsService,
  RolesManagementUtilsService,
  RolesManagementService,
}: SetupSystemModuleDefinition): void {
  const httpClient = Injector.resolve<IHttpClient>(httpClientToken)

  Injector.register<IRolesManagementService>(
    setupRolesManagementServiceToken,
    () => new RolesManagementService(httpClient),
    Scope.Singleton
  )

  Injector.register<IRolesManagementUtilsService>(
    setupRolesManagementUtilsServiceToken,
    (injector) => {
      const rolesManagementService = injector.resolve<IRolesManagementService>(setupRolesManagementServiceToken)

      return new RolesManagementUtilsService(rolesManagementService)
    },
    Scope.Singleton
  )

  Injector.register<ICharacteristicsUtilsService>(
    setupCharacteristicsUtilsServiceToken,
    (injector) => {
      const characteristicsService = injector.resolve<ICharacteristicService>(characteristicsServiceToken)

      return new CharacteristicsUtilsService(characteristicsService)
    },
    Scope.Singleton
  )

  Injector.register<ICommentTypesUtilsService>(
    setupCommentTypesUtilsServiceToken,
    (injector) => {
      const commentsService = injector.resolve<ICommentsService>(commentsServiceToken)
      const sourceService = injector.resolve<ISourceService>(sourceServiceToken)

      return new CommentTypesUtilsService(commentsService, sourceService)
    },
    Scope.Singleton
  )
}

export const SetupSystemModule: ILazyModule<SetupSystemModuleDefinition> = {
  name: setupSystemModule,
  resolver: () => import('./system.exports'),
  initializer: systemModuleInitializer,
  dependencies: [CoreModule, DocumentsModule, CharacteristicsModule, CommentsModule],
}
