import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { characteristicsToPayload } from 'characteristics/characteristics.utils'
import { ITaxType } from 'products/items-shared/tax-types/tax-types.types'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { CreateItemPayload, IItem, ItemType } from '../items.types'
import { ItemDetailsForm, ItemDetailsFormField } from './item-details.types'

export function defineFormData(item: IItem | null): ItemDetailsForm {
  return {
    [ItemDetailsFormField.ItemNumber]: item?.item_number ?? '',
    [ItemDetailsFormField.Active]: item?.active ?? true,
    [ItemDetailsFormField.Description1]: item?.description1 ?? '',
    [ItemDetailsFormField.Description2]: item?.description2 ?? '',
    [ItemDetailsFormField.ItemType]: item?.item_type
      ? defineAutocompleteOption(item.item_type)
      : defineAutocompleteOption(ItemType.Purchased),
    [ItemDetailsFormField.MaximumDesiredCost]: item?.maximum_desired_cost ?? 0,
    [ItemDetailsFormField.ClassCode]: defineAutocompleteOption(item?.class_code),
    [ItemDetailsFormField.InventoryUOM]: item?.inventory_uom
      ? { ...item.inventory_uom, id: item.inventory_uom.name, label: item.inventory_uom.name }
      : null,
    [ItemDetailsFormField.PickList]: item?.pick_list_item ?? true,
    [ItemDetailsFormField.Fractional]: item?.fractional ?? false,
    [ItemDetailsFormField.Configured]: item?.configured ?? false,
    [ItemDetailsFormField.FreightClass]: defineAutocompleteOption(item?.freight_class),
    [ItemDetailsFormField.ItemIsSold]: item?.item_is_sold ?? false,
    [ItemDetailsFormField.ProductCategory]: defineAutocompleteOption(item?.product_category),
    [ItemDetailsFormField.Exclusive]: item?.exclusive ?? false,
    [ItemDetailsFormField.ListPrice]: item?.list_price ?? 0,
    [ItemDetailsFormField.Warranty]: item?.warranty_days ?? 0,
    [ItemDetailsFormField.BarCode]: item?.upc_code ?? '',
    [ItemDetailsFormField.ProductWeight]: item?.product_weight ?? null,
    [ItemDetailsFormField.Packaging]: item?.packaging_weight ?? null,
    [ItemDetailsFormField.WholesalePrice]: item?.wholesale_price ?? 0,
    [ItemDetailsFormField.SellingUOM]: defineAutocompleteOption(item?.list_price_uom_name),
  }
}

export function convertItemData(
  formData: ItemDetailsForm,
  characteristics: IAppliedCharacteristic[],
  notes: string,
  additionalNotes: string,
  taxTypes: ITaxType[],
  currency: string
): CreateItemPayload {
  return {
    ext_description: additionalNotes,
    item_characteristics: characteristicsToPayload(characteristics),
    notes,
    uom_conversions: [],
    ...formData,
    list_price_uom_name: formData.list_price_uom_name?.id ?? null,
    item_type: formData.item_type?.id ?? '',
    class_code: formData.class_code?.id ?? '',
    inventory_uom_name: formData.inventory_uom?.name ?? null,
    freight_class: formData.freight_class?.id ?? null,
    product_category: formData.product_category?.id ?? null,
    tax_types: taxTypes,
    currency,
    product_weight: formData.product_weight ?? 0,
    packaging_weight: formData.packaging_weight ?? 0,
  }
}
