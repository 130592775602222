import React, { FC } from 'react'
import { OutlinedInputProps, TextField } from '@material-ui/core'
import { cls } from 'common/utils/utils'
import { XtInputMask } from './xt-input-mask'

export type AutoCompleteProperties = 'off' | 'new-password'

export interface IXtInputProps {
  label: string
  value?: string
  disabled?: boolean
  className?: string
  error?: string
  hidden?: boolean
  mask?: string
  inputProps?: OutlinedInputProps['inputProps']
  type?: React.InputHTMLAttributes<unknown>['type']
  disableTitle?: boolean
  onBlur?(): void
  onChange?(value: string): void
  autoComplete?: AutoCompleteProperties
  required?: boolean
}

export const XtInput: FC<IXtInputProps> = ({
  label,
  value,
  disabled,
  onChange,
  className,
  inputProps,
  error,
  onBlur,
  hidden = false,
  type,
  disableTitle = false,
  mask,
  autoComplete,
  required,
}) => {
  const maskInput = mask ? XtInputMask : undefined
  const inputComponent = inputProps?.inputComponent ?? maskInput
  const { endAdornment, ...restInputProps } = inputProps ?? { endAdornment: null }

  return (
    <TextField
      InputProps={{ inputComponent, autoComplete, inputProps: { type, mask, ...restInputProps }, endAdornment }}
      className={cls('MuiFormField', className, value && 'MuiFormFieldFilled')}
      variant="outlined"
      label={label}
      title={!disableTitle && value ? `${value}` : ''}
      value={value ?? ''}
      onBlur={onBlur}
      onChange={(e) => onChange && onChange(e.target.value)}
      disabled={disabled}
      hidden={hidden}
      error={!!error}
      helperText={error}
      required={required}
    />
  )
}
