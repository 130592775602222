import * as breakpoints from '../assets/sass/breakpoints.module.scss'

export interface IGlobalConstants {
  apiUrl: string
  dateFormat: string
  datetimeFormat: string
  serverDateFormat: string
  paginationLimit: number
  inputDebounce: number
  tableFilterDebounce: number
  formChangeDebounce: number
  dialogAnimationTime: number
  dialogAnimationFadeTime: number
  dateStringRepresentation: {
    always: string
    never: string
    earliest: string
    latest: string
  }
}
export interface IConfirmationMessages {
  deleted: string
  deletedPurchaseOrder: string
  customerDuplicatedProspect: string
  quoteConvert: string
  salesOrderConvertProspect: string
  setupWizardLaunch: string
  setupWizardPrompt: string
  unsavedChanges: string
}

export const confirmationMessages: IConfirmationMessages = {
  deleted: 'The item will be deleted. Are you sure?',
  deletedPurchaseOrder: 'This Purchase Order does not have any line items. Are you sure you want to delete this Purchase Order?',
  customerDuplicatedProspect: 'This number is currently assigned to a Prospect. Do you want to convert the Prospect to a Customer?',
  quoteConvert: 'This Quote is for a Prospect, not a Customer. Do you want to convert Prospect to a Customer?',
  salesOrderConvertProspect: 'Sales Order can be created only by Customer. Do you want to convert this Prospect to a Customer?',
  setupWizardLaunch: 'It appears you have not run the Setup Wizard. Would you like to run the wizard now?',
  setupWizardPrompt: 'Would you like to be prompted to run the Setup Wizard again?',
  unsavedChanges: 'Are you sure you want to leave the page? Changes will not be saved.',
}

export const globalConstants: IGlobalConstants = {
  apiUrl: process.env.REACT_APP_API_URL || '',
  dateFormat: 'MM/dd/yyyy',
  datetimeFormat: 'MM/dd/yyyy hh:mm aa',
  serverDateFormat: 'yyyy-MM-dd',
  paginationLimit: 10,
  inputDebounce: 700,
  dialogAnimationTime: 700,
  dialogAnimationFadeTime: 400,
  formChangeDebounce: 100,
  tableFilterDebounce: 1000,
  dateStringRepresentation: {
    always: 'Always',
    never: 'Never',
    earliest: 'Earliest',
    latest: 'Latest',
  },
}

export const projectVersion: string = process.env.REACT_APP_VERSION ?? ''

export const xsMq = `(max-width:${breakpoints.xs})`
export const smMq = `(max-width:${breakpoints.sm})`
export const mdMq = `(max-width:${breakpoints.md})`
export const lgMq = `(max-width:${breakpoints.lg})`

// TODO update me
export const maskDefinitions = {
  letter: {
    symbol: '#',
    pattern: /\p{L}/gu,
  },
  digit: {
    symbol: '0',
    pattern: /[0-9]/,
  },
  any: {
    symbol: '*',
    pattern: /./,
  },
}

export const inputMaskDefinitions = {
  [maskDefinitions.letter.symbol]: maskDefinitions.letter.pattern,
  [maskDefinitions.digit.symbol]: maskDefinitions.digit.pattern,
  [maskDefinitions.any.symbol]: maskDefinitions.any.pattern,
}

export const phoneNumberMask = '000000000000000'

export const development = 'development'

export const clearFilters = 'Clear Filters'

export const noDataAvailable = 'No Data Available.'

export enum LoaderMessage {
  //Accounts
  Accounts = 'Loading Accounts...',
  Customers = 'Loading Customers...',
  Prospects = 'Loading Prospects...',
  Leads = 'Loading Leads...',
  Contacts = 'Loading Contacts...',
  Vendors = 'Loading Vendors...',

  //Products
  Items = 'Loading Items...',
  BOMs = 'Loading BOMs...',
  Routing = 'Loading BOO...',

  //Activities
  Tasks = 'Loading Tasks...',
  Opportunities = 'Loading Opportunities...',

  //Inventory
  QOH = 'Loading Quantity on Hand...',
  InventoryAvailabilityWorkbench = 'Loading Inventory Availability Workbench...',
  RunningAvailability = 'Loading Running Availability',
  InventoryHistoryLocation = 'Loading Inventory History by Location...',
  InventoryHistoryByLot = 'Loading Inventory History by Lot...',

  //Purchasing
  PurchaseRequest = 'Loading Purchase Requests...',
  PurchaseOrder = 'Loading Purchase Order...',
  ItemSource = 'Loading Item Sources...',

  //Orders
  Quotes = 'Loading Quotes...',
  SaleOrders = 'Loading Sales Orders...',

  LineItems = 'Loading Line items...',
}

export const voidFunction = () => {}
export const PERIOD = '.'
