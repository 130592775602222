import { Overwrite } from 'utility-types'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from 'core/injector/injector.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { CompaniesContactsModule, CompaniesContactsModuleDefinition } from './contacts.module'
import { companiesContactsUtilsServiceToken } from './contacts.constants'
import { ICompaniesContactsUtilsService } from './contacts-utils.service'

function resolveCompaniesContactsModule(): Overwrite<
  CompaniesContactsModuleDefinition,
  { ContactsUtilsService: ICompaniesContactsUtilsService }
> {
  const module = LazyModulesService.resolveModule<CompaniesContactsModuleDefinition>(CompaniesContactsModule)
  const CompaniesContactsUtilsService = Injector.resolve<ICompaniesContactsUtilsService>(companiesContactsUtilsServiceToken)

  return {
    ...module,
    ContactsUtilsService: CompaniesContactsUtilsService,
  }
}

export const useCompaniesContactsModule = moduleHookResolver(resolveCompaniesContactsModule)
