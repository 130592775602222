import React, { FC, useEffect, useRef, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { LoadingSpinner } from 'components/loading-spinner'
import { cls } from 'common/utils/utils'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { DocumentType } from 'documents/documents.types'
import { characteristicsToPayload } from 'characteristics/characteristics.utils'
import { convertToNewComments } from 'comments/comments.utils'
import { useMultipleFormStateManager } from 'common/hooks/form/multiple-form-manager/multiple-form-manager'
import { convertMode } from 'common/utils/mode.utils'
import { useCharacteristicsModule } from 'characteristics/characteristics-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCommentsModule } from 'comments/comments-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { XtPrompt } from 'components/xt-prompt'
import { XtButtonLabels } from 'components/buttons/xt-button/xt-button.types'
import { confirmationMessages, xsMq } from 'common/constants'
import * as styles from './opportunity-details.module.scss'
import { oppGeneralFormKey } from './general-form/general-form.constants'
import {
  IOpportunityDetailsCombinedState,
  IOpportunityDetailsParams,
  IOpportunityState,
  OpportunityFormState,
} from './opportunity-details.types'
import { convertFormValues, defaultOpportunityState } from './opportunity-details.utils'
import { OpportunityDetailsTabs } from './opportunity-details-tabs'
import { useOpportunitiesModule } from '../opportunities-module-hook'
import { getOpportunityDetailsPageTitle } from '../opportunities.constants'

export const OpportunityDetails: FC<IOpportunityDetailsParams> = ({ mode, opportunityNumber, onClose, accountNumber }) => {
  const { useCharacteristics } = useCharacteristicsModule()
  const { useDocuments } = useDocumentsModule()
  const { useRemarks } = useCommentsModule()
  const { OpportunitiesService } = useOpportunitiesModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { AuthService } = useAuthModule()
  useDocumentTitle(getOpportunityDetailsPageTitle(opportunityNumber ?? undefined))
  const {
    register,
    getFormValue,
    getFormState,
    formState,
    handleSubmit,
    reset,
  } = useMultipleFormStateManager<IOpportunityDetailsCombinedState>()
  const opportunityGeneralForm = getFormValue(oppGeneralFormKey)
  const opportunityGeneralFormState = getFormState(oppGeneralFormKey)
  const { isViewMode, isEditMode, isNewMode } = convertMode(mode)

  const username = AuthService.getCurrentUser()?.username
  const defaultOpportunity = defaultOpportunityState.opportunity
    ? { ...defaultOpportunityState.opportunity, account: accountNumber ?? '' }
    : null
  const [state, setState] = useState<IOpportunityState>({ ...defaultOpportunityState, opportunity: defaultOpportunity })
  const isMobile = useMediaQuery(xsMq)

  const remarksState = useRemarks(DocumentType.Opportunity)
  const characteristicsState = useCharacteristics([])
  const documentsState = useDocuments(DocumentType.Opportunity, state.opportunity?.number)

  const isDirty = formState.isDirty || remarksState.isDirty || characteristicsState.isDirty || documentsState.isDirty

  const disabled = formState.isSubmitting || isViewMode

  const title = !isNewMode ? `Opportunity Name: ${state.opportunity?.name}` : 'New Opportunity'

  const initError = useRef<Error | null>(null)
  const setAccountOption = async (): Promise<void> => {
    try {
      if (accountNumber) {
        setState((prev) => ({ ...prev, loading: true }))
        const crmAccount = await OpportunitiesService.getCRMAccount(accountNumber)
        const opportunity: OpportunityFormState | null = defaultOpportunity ? { ...defaultOpportunity, accountDetails: crmAccount } : null
        setState((prev) => ({ ...prev, loading: false, opportunity }))
      }
    } catch (e) {
      setState((prev) => ({ ...prev, loading: false }))
      ErrorHandler.handleError(e)
    }
  }

  const init: () => Promise<void> = async () => {
    if (isNewMode || !opportunityNumber) {
      await setAccountOption()
      return
    }

    try {
      setState((prev) => ({ ...prev, loading: true }))
      const opportunity = await OpportunitiesService.getOpportunityWithAccount(opportunityNumber)
      characteristicsState.reset(opportunity.opportunity_characteristics || [])
      remarksState.reset(opportunityNumber, opportunity.notes)
      setState((prev) => ({ ...prev, opportunity, loading: false }))
    } catch (e) {
      setState((prev) => ({ ...prev, loading: false }))
      throw e
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setError = (e: Error) => {
    throw e
  }

  useEffect(
    () =>
      void init().catch((e) => {
        initError.current = e
      }),
    []
  )

  useEffect(() => {
    if (initError.current) setError(initError.current)
  }, [initError.current])

  const resetAll = () => {
    reset(isNewMode ? true : false)
    characteristicsState.reset(characteristicsState.characteristics ?? [])
    remarksState.reset(remarksState.comments, remarksState.notes, remarksState.additionalNotes)
  }
  const onSave: (formData: IOpportunityDetailsCombinedState) => Promise<void> = async (formData) => {
    const generalForm = formData[oppGeneralFormKey]
    if (!generalForm) {
      return
    }

    try {
      if (isNewMode) {
        const { message } = await OpportunitiesService.create(
          {
            ...convertFormValues(generalForm),
            opportunity_characteristics: characteristicsToPayload(characteristicsState.characteristics),
            notes: remarksState.notes,
          },
          convertToNewComments(remarksState.comments),
          documentsState.getUnsavedDocuments()
        )
        ToastService.showSuccess(message)
      }
      if (isEditMode && state.opportunity) {
        const message = await OpportunitiesService.update({
          ...convertFormValues(generalForm),
          opportunity_characteristics: characteristicsToPayload(characteristicsState.characteristics),
          notes: remarksState.notes,
          number: generalForm.number,
        })
        ToastService.showSuccess(message)
      }

      resetAll()
      onClose()
    } catch (e) {
      ErrorHandler.handleError(e)
    } finally {
      setState((prev) => ({ ...prev, isSubmitting: false }))
    }
  }

  const account = opportunityGeneralForm?.account ?? null
  const markGeneralTabAsInvalid = opportunityGeneralFormState?.fieldValidatorsShown ?? false

  return (
    <div className={cls('xt-content', styles.opportunityDetails)}>
      {state.loading && <LoadingSpinner />}
      <div hidden={state.loading}>
        <div className={cls(styles.header, 'xt-sticky-header', 'xt-section-border')}>
          <h3 className="xt-page-title" title={title}>
            {title}
          </h3>
          <div className={cls(styles.headerButtons, isViewMode && styles.buttonsIsViewMode)}>
            <XtButton label={XtButtonLabels.Cancel} onClick={onClose} />
            <XtButton
              hidden={isViewMode}
              disabled={disabled || !isDirty}
              loading={formState.isSubmitting}
              label="Save"
              onClick={handleSubmit(onSave)}
            />
          </div>
        </div>
        <div className={styles.opportunityDetailsContent}>
          <OpportunityDetailsTabs
            register={register}
            markGeneralTabAsInvalid={markGeneralTabAsInvalid}
            opportunity={state.opportunity}
            user={username ?? null}
            remarksState={remarksState}
            characteristicsState={characteristicsState}
            documentsState={documentsState}
            disabled={disabled}
            isMobile={isMobile}
            account={account}
            mode={mode}
          />
        </div>
      </div>

      <XtPrompt showPrompt={isDirty} message={confirmationMessages.unsavedChanges} />
    </div>
  )
}
