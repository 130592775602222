import { IResponseStatusPagination } from 'common/common.types'

export const LastUsed = 'LastUsed'

export enum PageFilterMapping {
  CRMAccounts = 'CRMAccounts',
  Customers = 'Customers',
  Prospects = 'Prospects',
  Opportunity = 'Opportunity',
  Contacts = 'Contacts',
  Item = 'Item',
  ItemSource = 'ItemSource',
  Tasks = 'Tasks',
  Quotes = 'Quotes',
  SalesOrders = 'SalesOrders',
  SalesOrdersGL = 'SalesOrdersGL',
  RecallOrders = 'RecallOrders',
  DashOpp = 'DashOpp',
  DashTasks = 'DashTasks',
  Calendar = 'Calendar',
  Vendor = 'Vendor',
  VendorPO = 'VendorPO',
  VendorReceipt = 'VendorReceipt',
  PurchaseOrders = 'PurchaseOrders',
  PurchaseRequests = 'PurchaseRequests',
  ItemCstClsCd = 'ItemCstClsCd',
  QOH = 'QOH',
  HistoryLot = 'HistoryLot',
  InvHistory = 'InvHistory',
  BOM = 'BOM',
}

export interface IFilterResponse<TData> {
  data: TData
  status: IResponseStatusPagination
}

export type ObjectFilter = {
  id: string
  value?: string
}

export interface IPageFilterValue {
  parameter: string
  value: string | boolean | number | Date | ObjectFilter
}

export type IPageFilterValues = IPageFilterValue[]

export interface IPageFilter {
  shared?: boolean
  filtername: string
  filtervalues?: string
}

export type IPageFilters = IPageFilter[]

export interface IParsedPageFilter {
  [key: string]: string | boolean | number
}
