import { IPaginationData, IPaginationParams, IPaginationResponse } from 'common/common.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IHttpClient } from 'core/services/http-client'
import { IQuantityOnHandListRow } from './quantity-on-hand-list/quantity-on-hand-list.types'
import { IQuantityOnHandListFilter } from './quantity-on-hand-list/quantity-on-hand-list-filter/quantity-on-hand-list-filter.types'

export interface IQuantityOnHandService {
  getQuantityOnHand(
    paginationParams: IPaginationParams,
    filters: IQuantityOnHandListFilter,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IQuantityOnHandListRow>>

  printQuantityOnHand(filters: IQuantityOnHandListFilter): Promise<Blob>
}

export class QuantityOnHandService implements IQuantityOnHandService {
  constructor(private readonly httpClient: IHttpClient) {
    bindAllInstanceMethods(this)
  }

  public async getQuantityOnHand(
    paginationParams: IPaginationParams,
    filters: IQuantityOnHandListFilter,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IQuantityOnHandListRow>> {
    const params = prepareRequestParams(paginationParams, filters, sortOptions)
    const {
      data: { data, status },
    } = await this.httpClient.get<IPaginationResponse<IQuantityOnHandListRow>>('/inventory/qoh', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async printQuantityOnHand(filters: IQuantityOnHandListFilter): Promise<Blob> {
    const params = prepareRequestParams(undefined, filters)
    const { data } = await this.httpClient.get('/inventory/qoh/form', {
      params,
      responseType: 'blob',
    })
    return data
  }
}
