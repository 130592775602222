import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormCheckboxLabel, FormSelectField, FormXtTransferList } from 'common/utils/form/form.components'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { TransferListOption } from 'components/controls/xt-transfer-list/xt-transfer-list.types'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IUom } from 'core/services/uom.service'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { useCoreModule } from 'core/core-module-hook'
import * as styles from './conversion-details-dialog.module.scss'
import {
  ConversionsDetailsDialogForm,
  ConversionsDetailsDialogFormField,
  ConversionsDetailsDialogLabel,
} from './conversions-details-dialog.types'
import { IConversionsTableItem } from '../conversions-tab.types'
import { conversionDetailsDialogValidation } from './conversion-details-dialog.validation'
import { convertFormDataToTableItem, defineFormData, onUomChangeFormDataDefinition } from './conversion-details-dialog.utils'
import { valueDecimalScale } from './conversions-details-dialog.constants'

interface IConversionDetailsDialog {
  uomConversion: IConversionsTableItem | null
  onSubmit(formData: IConversionsTableItem): void
  onClose(): void
  availableTypes: TransferListOption[]
  uomName: string
  usedUom: Set<string>
}

export const ConversionDetailsDialog: FC<IConversionDetailsDialog> = ({
  uomConversion,
  onSubmit,
  onClose,
  availableTypes,
  uomName,
  usedUom,
}) => {
  const { DocumentsUtilsService } = useDocumentsModule()
  const { ErrorHandler, UomService } = useCoreModule()

  const [uomOptions, setUomOptions] = useState<IXtAutocompleteOption[]>([])
  const [disabledUom, setDisableUom] = useState<boolean>(Boolean(uomConversion))
  const [decimalDisabled, setDecimalDisabled] = useState<boolean>(false)
  const [uom, setUom] = useState<IUom | null>(null)

  const {
    formState: { isDirty },
    control,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    reset,
  } = useForm<ConversionsDetailsDialogForm>({
    defaultValues: defineFormData(uomConversion, uomName),
    mode: 'onBlur',
    resolver: yupResolver(conversionDetailsDialogValidation),
  })

  const title = uomConversion ? 'Edit Conversion' : 'New Conversion'
  const fetchUomOptions: () => Promise<void> = async () => {
    const { data } = await DocumentsUtilsService.loadUomOptions()

    if (!uomConversion) {
      setUomOptions(data.filter(({ id }) => !usedUom.has(id)))
      return
    }
    setUomOptions(data)
  }

  const uniqueAvailableTypes: TransferListOption[] = useMemo(() => {
    const uomTypes = getValues(ConversionsDetailsDialogFormField.UomTypes)
    return availableTypes.filter((type) => !uomTypes.some(({ id }) => id === type.id))
  }, [availableTypes, getValues])

  useEffect(() => {
    void fetchUomOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    async function init(): Promise<void> {
      try {
        const data = await UomService.get(uomName)
        setUom(data)
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }
    void init()
  }, [uomName])

  const handleUomChange = useCallback(
    (key: ConversionsDetailsDialogFormField) => (option: IXtAutocompleteOption) => {
      if (option.id !== uomName) {
        const selectedUomConversion = (uom?.uom_conversions ?? []).find(
          ({ uom_from, uom_to }) => uom_from === option.id || uom_to === option.id
        )

        if (selectedUomConversion) {
          reset(onUomChangeFormDataDefinition(selectedUomConversion, option.id, key, uomName, uomConversion))
          setDecimalDisabled(true)
        }

        setValue(key, option, { shouldDirty: true })
        void trigger([ConversionsDetailsDialogFormField.UomFrom, ConversionsDetailsDialogFormField.UomTo])
        setDisableUom(true)
      }
    },
    [uomName, uom, setValue, trigger]
  )

  const onCancel: VoidFunction = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave this page? Updates will not be applied.')) {
      return
    }
    onClose()
  }

  const onSave = (formValues: ConversionsDetailsDialogForm): void => {
    onSubmit(convertFormDataToTableItem(formValues, uomConversion))
  }

  const onFormSubmit: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(onSave)
    void eventHandler(e)
  }

  return (
    <div className={styles.conversionsDialogContainer}>
      <h3 className="xt-page-title">{title}</h3>
      <form onSubmit={onFormSubmit} className={styles.conversionsDialogSection}>
        <div className={styles.conversionsDialogForm}>
          <div className={styles.conversionsDialogColumn}>
            <FormSelectField
              options={uomOptions}
              name={ConversionsDetailsDialogFormField.UomFrom}
              control={control}
              label={ConversionsDetailsDialogLabel.UomFrom}
              disabled={Boolean(uomConversion) || disabledUom}
              onChange={handleUomChange(ConversionsDetailsDialogFormField.UomFrom)}
            />
            <DecimalFormField
              name={ConversionsDetailsDialogFormField.FromValue}
              control={control}
              label={ConversionsDetailsDialogLabel.FromValue}
              disabled={decimalDisabled}
              fixedDecimalScale={valueDecimalScale}
              allowNegative={false}
            />
          </div>
          <SvgIcon className={styles.conversionsDialogFormIcon} iconId={SvgIconIds.COMPARE_ARROW} />
          <div className={styles.conversionsDialogColumn}>
            <FormSelectField
              options={uomOptions}
              name={ConversionsDetailsDialogFormField.UomTo}
              control={control}
              label={ConversionsDetailsDialogLabel.UomTo}
              disabled={Boolean(uomConversion) || disabledUom}
              onChange={handleUomChange(ConversionsDetailsDialogFormField.UomTo)}
            />
            <DecimalFormField
              name={ConversionsDetailsDialogFormField.ToValue}
              control={control}
              label={ConversionsDetailsDialogLabel.ToValue}
              disabled={decimalDisabled}
              fixedDecimalScale={valueDecimalScale}
              allowNegative={false}
            />
          </div>
        </div>
        <div>
          <FormCheckboxLabel
            name={ConversionsDetailsDialogFormField.Fractional}
            control={control}
            label={ConversionsDetailsDialogLabel.Fractional}
          />
          <FormCheckboxLabel
            name={ConversionsDetailsDialogFormField.Active}
            control={control}
            label={ConversionsDetailsDialogLabel.Active}
          />
        </div>
        <FormXtTransferList
          availableOptions={uniqueAvailableTypes}
          name={ConversionsDetailsDialogFormField.UomTypes}
          control={control}
          selectedOptionsLabel={ConversionsDetailsDialogLabel.UomTypes}
        />
        <div className={styles.controlButtonsBlock}>
          <XtButton label="Cancel" onClick={onCancel} />
          <XtButton label="Save" type="submit" disabled={!isDirty} />
        </div>
      </form>
    </div>
  )
}
