import React, { useCallback, useEffect, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import { IXtEditableCell, XtEditableCellControlInput, XtEditableCellType } from './table-editable-cell.types'

function isDecimalType(control: XtEditableCellControlInput): control is IXtEditableCell<number> {
  return control.type === XtEditableCellType.Number
}

export function XtEditableCellInput(control: XtEditableCellControlInput): React.ReactElement {
  const [state, setState] = useState<string | number | null>(control.value)

  const isBlurredRef = useRef<boolean>(false)
  const stateRef = useRef(control.value)

  const handleBlur: VoidFunction = useCallback(() => {
    if (stateRef.current === control.value) {
      return
    }

    control.onChange(stateRef.current)
    if (typeof control.onBlur === 'function') {
      control.onBlur()
    }

    isBlurredRef.current = true
  }, [control])

  useEffect(() => {
    return () => {
      if (!isBlurredRef.current) {
        handleBlur()
      }
    }
  }, [handleBlur])

  const handleChange = (value: string): void => {
    stateRef.current = value
    setState(value)
  }

  const input = isDecimalType(control) ? (
    <NumberFormat
      disabled={control.disabled}
      className="xt-table-editable-cell-input"
      value={state}
      onValueChange={({ value }) => handleChange(value)}
      onBlur={handleBlur}
      decimalScale={control.decimalScale}
      fixedDecimalScale={!!control.decimalScale}
    />
  ) : (
    <input
      disabled={control.disabled}
      type="text"
      className="xt-table-editable-cell-input"
      value={state ?? ''}
      onChange={({ target: { value } }) => handleChange(value)}
      onBlur={handleBlur}
    />
  )

  return <div className="xt-table-editable-cell-input-container">{input}</div>
}
