import * as React from 'react'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import * as styles from '../nested-xt-table.module.scss'

export const recursivelyDataGridStyles = {
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
  },
}

export const recursivelyDataGridComponents = {
  DetailPanelCollapseIcon: () => <SvgIcon iconId={SvgIconIds.EXPAND_MORE} className={styles.detailPanelIcon} />,
  DetailPanelExpandIcon: () => <SvgIcon iconId={SvgIconIds.ARROW} className={styles.detailPanelIcon} />,
  Footer: () => null,
}

export const defaultHeightSmall = 250
