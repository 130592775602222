// extracted by mini-css-extract-plugin
export var root = "registration-dialog_root__GJAoL";
export var xtContent = "registration-dialog_xt-content__bm-a6";
export var xtContentWithRemarks = "registration-dialog_xt-content-with-remarks__FpgKe";
export var muiFormHelperTextRoot = "registration-dialog_MuiFormHelperText-root__hnaFx";
export var xtListPageContainer = "registration-dialog_xt-list-page-container__df7Gc";
export var muiInputBaseRoot = "registration-dialog_MuiInputBase-root__C690F";
export var muiTextFieldRoot = "registration-dialog_MuiTextField-root__bwGGE";
export var muiTablePaginationRoot = "registration-dialog_MuiTablePagination-root__ctP8B";
export var muiTablePaginationSelectRoot = "registration-dialog_MuiTablePagination-selectRoot__V6v6P";
export var muiTablePaginationSelect = "registration-dialog_MuiTablePagination-select__BKiLg";
export var muiStepperRoot = "registration-dialog_MuiStepper-root__2TUtN";
export var muiStepperHorizontal = "registration-dialog_MuiStepper-horizontal__q8XWx";
export var muiStepIconRoot = "registration-dialog_MuiStepIcon-root__CIMpZ";
export var muiStepIconActive = "registration-dialog_MuiStepIcon-active__1KFI5";
export var muiStepIconCompleted = "registration-dialog_MuiStepIcon-completed__KHLLM";
export var muiListItemRoot = "registration-dialog_MuiListItem-root__ELtbT";
export var muiSelected = "registration-dialog_Mui-selected__k3F7a";
export var xtPageTitle = "registration-dialog_xt-page-title__IzmDC";
export var xtSubPageTitle = "registration-dialog_xt-subPage-title__Gjdj-";
export var xtSectionBorder = "registration-dialog_xt-section-border__+9Dvv";
export var xtPageHeader = "registration-dialog_xt-page-header__CEDdp";
export var xtDialogScrollable = "registration-dialog_xt-dialog-scrollable__16adb";
export var reactToastNotificationsContainer = "registration-dialog_react-toast-notifications__container__WmAFn";
export var xtScrollbarStyle = "registration-dialog_xt-scrollbar-style__JO0bJ";
export var xtModalDetailsContent = "registration-dialog_xt-modal-details-content__DRLTh";
export var dialogContainer = "registration-dialog_dialogContainer__jkYlg";
export var xtDialogDetailsContent = "registration-dialog_xt-dialog-details-content__pHglN";
export var xtLazyModuleLoaderTabContainer = "registration-dialog_xt-lazy-module-loader-tab-container__bvcwJ";
export var xtTextTruncated = "registration-dialog_xt-text-truncated__b7lSP";
export var xtSummaryContent = "registration-dialog_xt-summary-content__YvibQ";
export var xtSummaryLabel = "registration-dialog_xt-summary-label__UcShT";
export var visibleHidden = "registration-dialog_visible-hidden__oi2kB";
export var headerBottomMargin = "registration-dialog_header-bottom-margin__tYET2";
export var xtDialogHeaderLogoAlign = "registration-dialog_xt-dialog-header-logoAlign__qePs4";
export var xtStickyHeader = "registration-dialog_xt-sticky-header__uhULx";
export var noBorder = "registration-dialog_no-border__6hNzc";
export var muiOutlinedInputNotchedOutline = "registration-dialog_MuiOutlinedInput-notchedOutline__e3GTU";
export var formContent = "registration-dialog_formContent__uWeVB";
export var header = "registration-dialog_header__ynnSF";
export var formSection = "registration-dialog_formSection__gf7QW";
export var fields = "registration-dialog_fields__feQLe";
export var infoFields = "registration-dialog_infoFields__e1GKe";
export var dateFields = "registration-dialog_dateFields__O1G3F";
export var headerButtons = "registration-dialog_headerButtons__UkdZF";
export var salesOrderWithInfo = "registration-dialog_salesOrderWithInfo__TJx4s";