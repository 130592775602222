import type { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'

import type { ICompaniesLeadsUtilsService } from './leads-utils.service'
import { SharedModule } from 'shared/shared-module'
import { AuthModule } from 'auth/auth.module'
import { DocumentsModule } from 'documents/documents.module'
import { Injector } from 'core/injector/injector.service'

import { Scope } from 'core/injector/injector.types'
import { ILeadService } from 'leads/leads.service'
import { leadsServiceToken } from 'leads/leads.constants'
import { LeadsModule } from 'leads/leads.module'
import { companiesLeadsModule, companiesLeadsUtilsServiceToken } from './leads.constants'

export type CompaniesLeadsModuleDefinition = typeof import('./leads.export')

export const CompaniesLeadsModule: ILazyModule<CompaniesLeadsModuleDefinition> = {
  name: companiesLeadsModule,
  resolver: () => import('./leads.export'),
  initializer: ({ LeadsUtilsService }) => {
    Injector.register<ICompaniesLeadsUtilsService>(
      companiesLeadsUtilsServiceToken,
      (injector) => {
        const leadsService = injector.resolve<ILeadService>(leadsServiceToken)

        return new LeadsUtilsService(leadsService)
      },
      Scope.Singleton
    )
  },
  dependencies: [SharedModule, LeadsModule, AuthModule, DocumentsModule],
}
