import { IModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { CoreModule } from 'core/core.module'
import { AuthModule } from 'auth/auth.module'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { IAuthService } from 'auth/auth.types'
import { authServiceToken } from 'auth/auth.constants'
import { IDictionaryService } from './dictionary.types'
import * as DictionaryModuleExport from './dictionary.export'
import { dictionaryModuleToken, dictionaryServiceToken, dictionaryUtilsServiceToken } from './dictionary.constants'
import { IDictionaryUtilsService } from './dictionary-utils.service'

export type DictionaryModuleDefinition = typeof DictionaryModuleExport

export interface IDictionaryModuleServices {
  DictionaryService: IDictionaryService
  DictionaryUtilsService: IDictionaryUtilsService
}

export const DictionaryModule: IModule<DictionaryModuleDefinition> = {
  name: dictionaryModuleToken,
  resolver: () => DictionaryModuleExport,
  initializer: ({ DictionaryService, DictionaryUtilsService }) => {
    Injector.register<IDictionaryService>(
      dictionaryServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        const authClient = injector.resolve<IAuthService>(authServiceToken)

        return new DictionaryService(httpClient, authClient)
      },
      Scope.Singleton
    )
    Injector.register<IDictionaryUtilsService>(
      dictionaryUtilsServiceToken,
      (injector) => {
        const dictionaryService = injector.resolve<IDictionaryService>(dictionaryServiceToken)

        return new DictionaryUtilsService(dictionaryService)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, AuthModule],
}
