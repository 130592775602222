import type { IAsyncRoute, IRoute } from 'common/routing/routing.types'
import { XtMode } from 'common/common.types'
import { buildPageComponent } from 'common/routing/routing.utils'
import { PageNotFound } from 'page-not-found/page-not-found'
import { VendorModule, VendorModuleDefinition } from './vendor.module'
import { VendorDetailsGuard } from './vendor-details/vendor-details.guard'
import { vendorRoutePath } from './vendor.constants'
import { VendorGuard } from './vendor.guard'

export function vendorRouteFactory({ VendorList, VendorDetails }: VendorModuleDefinition): IRoute[] {
  return [
    {
      path: '',
      exact: true,
      component: VendorList,
    },
    {
      path: `/:vendorNumber/${XtMode.Edit}`,
      exact: true,
      component: (props) => buildPageComponent(props, XtMode.Edit, VendorDetails),
      guards: [VendorDetailsGuard],
    },
    {
      path: `/:vendorNumber/${XtMode.View}`,
      exact: true,
      component: (props) => buildPageComponent(props, XtMode.View, VendorDetails),
    },
    {
      path: `/${XtMode.New}`,
      exact: true,
      component: (props) => buildPageComponent(props, XtMode.New, VendorDetails),
      guards: [VendorDetailsGuard],
    },
    {
      path: '*',
      component: PageNotFound,
      exact: true,
    },
  ]
}

export const vendorRoute: IAsyncRoute<VendorModuleDefinition> = {
  path: vendorRoutePath,
  name: 'Vendors',
  guards: [VendorGuard],
  module: VendorModule,
  childrenRoutesFactory: vendorRouteFactory,
}
