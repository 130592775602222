import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Switch } from 'react-router-dom'
import { Toast as ToastProvider } from './toast/toast-provider'

import './assets/sass/exports.scss'

import { XtMain } from './layout/main/main'
import { AuthRoutes } from './auth/auth.routes'
import { XtBrowserRouter } from './common/routing/browser-router/browser-router'
import { GuardedRoute } from './common/routing/guarded-route/guarded-route'
import { appRoutes } from './routes'
import { AuthGuard } from './auth/auth.guard'
import { NoMobileLandscape } from './layout/no-landscape/no-landscape'
import { authRoutePath } from './auth/auth.constants'
import { LoginGuard } from './auth/login/login.guard'
import { appInitializer } from './app-initializer'

appInitializer()

ReactDOM.render(
  <NoMobileLandscape>
    <ToastProvider>
      <XtBrowserRouter>
        <Switch>
          <GuardedRoute path={authRoutePath} guards={[LoginGuard]} render={AuthRoutes} />
          <GuardedRoute path="/" guards={[AuthGuard]} render={() => <XtMain routes={appRoutes} />} />
        </Switch>
      </XtBrowserRouter>
    </ToastProvider>
  </NoMobileLandscape>,
  document.getElementById('root')
)
