import { useCallback, useEffect, useState } from 'react'
import { useCoreModule } from 'core/core-module-hook'
import { IRoutingItem } from '../../../routing/routing.types'
import { useProductsModule } from '../../../products-module-hook'

interface IRoutingDialogState {
  routingItems: IRoutingItem[]
  routingItemsLoading: boolean
  openRoutingDialog: boolean
}
interface IUseRoutingItemsDialog extends IRoutingDialogState {
  routingItemsAvailable: boolean
  onOpenRoutingDialog(): void
  onCloseRoutingDialog(): void
}

const initialRoutingItemsState = {
  routingItems: [],
  routingItemsLoading: false,
  openRoutingDialog: false,
}

export function useRoutingItemsDialog(parentItemNumber: string): IUseRoutingItemsDialog {
  const { RoutingUtilsService } = useProductsModule()
  const { ErrorHandler } = useCoreModule()

  const [state, setState] = useState<IRoutingDialogState>(initialRoutingItemsState)

  const initRoutingItems: () => Promise<void> = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }))
      const routingItems = await RoutingUtilsService.requestRoutingItems(parentItemNumber)
      setState((prevState) => ({ ...prevState, routingItemsLoading: false, routingItems }))
    } catch (error) {
      setState(initialRoutingItemsState)
      ErrorHandler.handleError(error)
    }
  }

  const onOpenRoutingDialog = useCallback<VoidFunction>(() => setState((prev) => ({ ...prev, openRoutingDialog: true })), [])
  const onCloseRoutingDialog = useCallback<VoidFunction>(() => setState((prev) => ({ ...prev, openRoutingDialog: false })), [])
  const routingItemsAvailable: boolean = Array.isArray(state.routingItems) && !!state.routingItems.length

  useEffect(() => void initRoutingItems(), [parentItemNumber])

  return {
    ...state,
    onOpenRoutingDialog,
    onCloseRoutingDialog,
    routingItemsAvailable,
  }
}
