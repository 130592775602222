import { ITransferOrder } from 'core/services/transfer-order.service'
import { XtInfoValue } from 'components/xt-info-values/xt-info-values.types'

export function resolveTransferOrderValues(transferOrder: ITransferOrder | null): XtInfoValue[] {
  return [
    {
      label: 'TO',
      value: transferOrder?.status ?? null,
    },
    {
      label: 'From',
      value: transferOrder?.source_warehouse?.source_site ?? null,
    },
    {
      label: 'To',
      value: transferOrder?.destination_warehouse?.destination_site ?? null,
    },
  ]
}
