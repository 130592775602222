import * as Yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { AccountDetailsField } from './account-details.types'

export const accountDetailsValidationSchema = Yup.object().shape({
  [AccountDetailsField.AccountNumber]: Yup.string().required(validationMessage.isRequired),
  [AccountDetailsField.AccountName]: Yup.string().required(validationMessage.isRequired),
  [AccountDetailsField.Active]: Yup.boolean(),
  [AccountDetailsField.Parent]: Yup.object().nullable(),
  [AccountDetailsField.Owner]: Yup.object().nullable(),
  [AccountDetailsField.Type]: Yup.string(),
})
