import React, { FC, memo, useCallback, useEffect } from 'react'
import { useXtForm } from 'common/hooks/form/form'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { XtMode } from 'common/common.types'
import { FormField, FormCheckboxLabel, FormSelectField } from 'common/utils/form/form.components'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { cls } from 'common/utils/utils'
import { ICreateItemForm, ICreateItemFormState } from './create-item-dialog.types'
import { defaultCreateItemFormState, CreateItemFormField, CreateItemFormLabel, createItemOptions } from './create-item-dialog.constants'
import { CreateItemFormSchema } from './create-item-dialog.validation'
import { defineData } from './create-item-dialog.utils'
import * as styles from './create-item-dialog.module.scss'
import { convertItemType } from '../create-items.utils'

export const CreateItemDialog: FC<ICreateItemForm> = memo(({ onClose, onConfirm, data, mode }) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
    watch,
  } = useXtForm<ICreateItemFormState>({
    defaultValues: defaultCreateItemFormState,
    mode: 'onBlur',
    validationSchema: CreateItemFormSchema,
  })

  const onSaveForm = useCallback(
    async (formData: ICreateItemFormState) => {
      onConfirm({ ...formData, item_type: convertItemType(formData.item_type) })
    },
    [onConfirm]
  )

  const title = `Item: ${data?.item_number}`

  const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(onSaveForm)
    void eventHandler(e)
  }

  useEffect(() => {
    if (data) {
      void reset(defineData(data))
    }
  }, [data])

  return (
    <div className={styles.createItemForm}>
      <div className={cls(styles.createItemDialogFormHeader, 'xt-section-border')}>
        <h3 className="xt-page-title" title={title}>
          {mode == XtMode.New ? 'New Item' : title}
        </h3>
      </div>
      <form onSubmit={submitForm}>
        <FormField
          required
          control={control}
          name={CreateItemFormField.Number}
          label={CreateItemFormLabel.Number}
          className={styles.formField}
          disabled={mode == XtMode.Edit}
        />
        <FormField
          required
          control={control}
          name={CreateItemFormField.Description}
          label={CreateItemFormLabel.Description}
          className={styles.formField}
        />
        <FormSelectField
          required
          name={CreateItemFormField.ItemType}
          control={control}
          label={CreateItemFormLabel.ItemType}
          options={createItemOptions}
          className={styles.formField}
        />
        <FormCheckboxLabel
          control={control}
          label={CreateItemFormLabel.Sold}
          name={CreateItemFormField.Sold}
          className={styles.formField}
        />
        <DecimalFormField
          control={control}
          name={CreateItemFormField.Price}
          label={CreateItemFormLabel.Price}
          className={styles.formField}
          disabled={!watch(CreateItemFormField.Sold)}
        />
        <div className={styles.createItemFormButtons}>
          <XtButton label="Cancel" onClick={onClose} />
          <XtButton type="submit" label="Save" disabled={!isDirty} />
        </div>
      </form>
    </div>
  )
})
