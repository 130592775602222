import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { httpClientToken, IHttpClient, HttpClient } from 'core/services/http-client'
import { Scope } from 'core/injector/injector.types'
import { Injector } from 'core/injector/injector.service'
import { CoreModule } from 'core/core.module'
import { ProductsModule } from 'products/products.module'
import { DictionaryModule } from 'dictionary/dictionary.module'
import { SetupCrmModule } from 'setup/crm/crm.module'
import { AccountsModule } from 'companies/accounts/accounts.module'
import { ISetupWizardService } from './setup-wizard.service'
import { setupWizardServiceToken } from './setup-wizard.constants'

export type SetupWizardModuleDefinition = typeof import('./setup-wizard.exports')

export interface ISetupWizardModuleService {
  SetupWizardService: ISetupWizardService
}

function setupWizardInitializer({ SetupWizardService }: SetupWizardModuleDefinition): void {
  Injector.register(httpClientToken, () => HttpClient, Scope.Singleton)
  const httpClient = Injector.resolve<IHttpClient>(httpClientToken)
  Injector.register<ISetupWizardService>(setupWizardServiceToken, () => new SetupWizardService(httpClient), Scope.Singleton)
}

export const SetupWizardModule: ILazyModule<SetupWizardModuleDefinition> = {
  name: 'SetupWizardModule',
  resolver: () => import('./setup-wizard.exports'),
  initializer: setupWizardInitializer,
  dependencies: [CoreModule, DictionaryModule, ProductsModule, AccountsModule, SetupCrmModule],
}
