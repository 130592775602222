import { CoreModule } from 'core/core.module'
import { IModule } from '../core/react-lazy-modules/react-lazy-modules.types'
import { sharedModuleToken } from './shared.constants'
import * as SharedModuleExport from './shared.export'

export type SharedModuleDefinition = typeof SharedModuleExport

export const SharedModule: IModule<SharedModuleDefinition> = {
  name: sharedModuleToken,
  resolver: () => SharedModuleExport,
  dependencies: [CoreModule],
}
