import * as React from 'react'
import { FC, memo } from 'react'
import { UseFormReturn } from 'react-hook-form/dist/types'
import { IXtTab } from 'components/xt-tabs/xt-tabs.types'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { FormTextAreaField } from 'common/utils/form/form.components'
import { RoutingItemFormData } from '../routing-item.types'
import { RoutingItemTabsName } from './routing-item-general-tab/routing-item-tabs.constants'
import { RoutingItemGeneralTab } from './routing-item-general-tab/routing-item-general-tab'
import { RoutingItemControlName } from '../routing-item.constants'

interface IRoutingItemTabs {
  disabled: boolean
  formMethods: UseFormReturn<RoutingItemFormData>
  uom?: string
}

export const RoutingItemTabs: FC<IRoutingItemTabs> = memo(({ disabled, formMethods, uom }) => {
  const { control } = formMethods

  const tabs: IXtTab[] = [
    {
      name: RoutingItemTabsName.General,
      template: <RoutingItemGeneralTab disabled={disabled} uom={uom} formMethods={formMethods} />,
    },
    {
      name: RoutingItemTabsName.Instructions,
      template: (
        <FormTextAreaField
          name={RoutingItemControlName.Instructions}
          control={control}
          label={RoutingItemTabsName.Instructions}
          disabled={disabled}
          rows={11}
        />
      ),
    },
  ]

  return <XtTabs tabs={tabs} />
})
