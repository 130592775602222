import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { IItemSite } from 'products/items/items.types'
import {
  DefaultLocationType,
  IRestrictedLocationRow,
  SitesDetailsDialogLocationForm,
  SitesDetailsDialogLocationFormField,
} from './sites-details-dialog-location.types'
import { locationNA, RestrictedLocationAction } from './sites-details-dialog-location.consts'

export const defineDefaultLocationTypeOptions: IXtAutocompleteOption[] = [
  {
    id: DefaultLocationType.Location,
    label: DefaultLocationType.Location,
  },
  {
    id: DefaultLocationType.UserDefined,
    label: DefaultLocationType.UserDefined,
  },
]

export function getRestrictedLocationRowActions(item: IRestrictedLocationRow, actions: IXtTableCellAction[]): IXtTableCellAction[] {
  return item.allowed
    ? actions.filter((action: IXtTableCellAction) => action.name === RestrictedLocationAction.Disallow)
    : actions.filter((action: IXtTableCellAction) => action.name === RestrictedLocationAction.Allow)
}

export function getRestrictedLocations(site: IItemSite | null): IRestrictedLocationRow[] {
  if (!site) {
    return []
  }
  return site.restricted_locations.map(
    (value): IRestrictedLocationRow => ({
      id: value.restricted_location,
      restrictedLocation: value.restricted_location,
      locationDescription: value.location_description,
      allowed: value.allowed,
    })
  )
}

const emptyLocationFormData: SitesDetailsDialogLocationForm = {
  [SitesDetailsDialogLocationFormField.MultipleLocationControl]: false,
  [SitesDetailsDialogLocationFormField.DisallowBlankWipLocations]: false,
  [SitesDetailsDialogLocationFormField.UseDefaultLocation]: false,
  [SitesDetailsDialogLocationFormField.DefaultLocationType]: DefaultLocationType.Location,
  [SitesDetailsDialogLocationFormField.ReceiveLocation]: null,
  [SitesDetailsDialogLocationFormField.AutoDistrReceiveLocation]: false,
  [SitesDetailsDialogLocationFormField.Location]: null,
  [SitesDetailsDialogLocationFormField.AutoDistrLocation]: false,
  [SitesDetailsDialogLocationFormField.IssueLocation]: null,
  [SitesDetailsDialogLocationFormField.AutoDistrIssueLocation]: false,
  [SitesDetailsDialogLocationFormField.UserDefinedLocation]: null,
  [SitesDetailsDialogLocationFormField.LocationComment]: null,
  [SitesDetailsDialogLocationFormField.RestrictedLocations]: [],
}

export function defineDefaultLocationFormData(site: IItemSite | null): SitesDetailsDialogLocationForm {
  if (!site) {
    return emptyLocationFormData
  }

  const isLocation = site.location !== locationNA || site.issue_location !== locationNA || site.receive_location !== locationNA
  const isUserDefined = Boolean(site?.user_defined_location)
  const defaultLocationType = isUserDefined ? DefaultLocationType.UserDefined : DefaultLocationType.Location
  const location = site.location !== locationNA ? site.location : null
  const issueLocation = site.issue_location !== locationNA ? site.issue_location : null
  const receiveLocation = site.receive_location !== locationNA ? site.receive_location : null

  return {
    [SitesDetailsDialogLocationFormField.MultipleLocationControl]: site.multiple_location_control,
    [SitesDetailsDialogLocationFormField.DisallowBlankWipLocations]: Boolean(site.disallow_blank_wip_locations),
    [SitesDetailsDialogLocationFormField.UseDefaultLocation]: isLocation || isUserDefined,
    [SitesDetailsDialogLocationFormField.DefaultLocationType]: defaultLocationType,
    [SitesDetailsDialogLocationFormField.ReceiveLocation]: defineAutocompleteOption(receiveLocation),
    [SitesDetailsDialogLocationFormField.AutoDistrReceiveLocation]: Boolean(site.auto_distr_receive_location),
    [SitesDetailsDialogLocationFormField.Location]: defineAutocompleteOption(location),
    [SitesDetailsDialogLocationFormField.AutoDistrLocation]: Boolean(site.auto_distr_location),
    [SitesDetailsDialogLocationFormField.IssueLocation]: defineAutocompleteOption(issueLocation),
    [SitesDetailsDialogLocationFormField.AutoDistrIssueLocation]: Boolean(site.auto_distr_issue_location),
    [SitesDetailsDialogLocationFormField.UserDefinedLocation]: String(site.user_defined_location),
    [SitesDetailsDialogLocationFormField.LocationComment]: String(site.location_comment),
    [SitesDetailsDialogLocationFormField.RestrictedLocations]: getRestrictedLocations(site),
  }
}
