export enum PasswordChangeFormField {
  Password = 'password',
  Verify = 'verify',
}
export enum PasswordChangeLabel {
  Password = 'Password',
  Verify = 'Verify',
}

export type PasswordChangeForm = {
  [PasswordChangeFormField.Password]: string
  [PasswordChangeFormField.Verify]: string
}
