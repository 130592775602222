import { DocumentLocationOption } from 'documents/documents.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'

export enum TransactionTypeOptionValue {
  Shipments = 'Shipments',
  Issues = 'Issues',
  Receipts = 'Receipts',
  Scraps = 'Scraps',
  AdjustmentsAndCounts = 'Adjustments',
  Transfers = 'Transfers',
  AllTransaction = '',
}

export enum TransactionTypeOptionLabel {
  Shipments = 'Shipments',
  Issues = 'Issues',
  Receipts = 'Receipts',
  Scraps = 'Scraps',
  AdjustmentsAndCounts = 'Adjustments and Counts',
  Transfers = 'Transfers',
  AllTransaction = 'All Transactions',
}

export enum LocationFormField {
  Location = 'location',
  Site = 'site',
  TransactionType = 'transaction_type',
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export enum LocationFormLabel {
  Location = 'Location',
  Site = 'Site',
  TransactionType = 'Transaction Type',
  StartDate = 'Start Date',
  EndDate = 'End Date',
}

export enum LocationInfoLabel {
  Netable = 'Netable',
  Restricted = 'Restricted',
  Usable = 'Usable',
}

export type InventoryHistoryByLocationState = {
  [LocationFormField.Location]: DocumentLocationOption | null
  [LocationFormField.Site]: IXtAutocompleteOption | null
  [LocationFormField.TransactionType]: IXtAutocompleteOption | null
  [LocationFormField.StartDate]: Date | null
  [LocationFormField.EndDate]: Date | null
}

export type InventoryHistoryByLocationFilters = {
  [LocationFormField.Location]?: string
  [LocationFormField.Site]?: string
  [LocationFormField.TransactionType]?: string
  [LocationFormField.StartDate]?: string | null
  [LocationFormField.EndDate]?: string | null
}

export interface IInventoryHistoryByLocationSharedParams<TFilters> {
  onChange: (filters: TFilters) => void
}

export type InventoryHistoryByLocationListParams = IInventoryHistoryByLocationSharedParams<InventoryHistoryByLocationFilters>
