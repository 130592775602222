import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { map } from 'rxjs/operators'
import { useXtForm } from 'common/hooks/form/form'
import { XtInfoValues } from 'components/xt-info-values/xt-info-values'
import { FormRadioGroup, FormSelectField } from 'common/utils/form/form.components'
import { useProductsModule } from 'products/products-module-hook'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { CostedBomFilters } from 'products/items/items.types'
import { defaultItemNumberFilters } from 'products/products.constants'
import * as styles from '../../bom-list-forms.module.scss'
import { convertFormDataToCostedBomFilters } from './single-level-list-form.utils'
import { FormSharedField, FormSharedLabel, IBomListFormSharedParams, IBomListSharedForm } from '../../shared/shared.types'
import { useBomListShared } from '../../shared/bom-list-form-shared-hook'
import { costsOptions, defaultBomListSharedForm } from '../../shared/shared.constants'

export const SingleLevelListForm: FC<IBomListFormSharedParams<CostedBomFilters>> = ({ onChange }) => {
  const { FormXtItemNumber, ItemsUtilsService, CostingBomUtilsService } = useProductsModule()

  const [printing, setPrinting] = useState<boolean>(false)

  const formMethods = useXtForm<IBomListSharedForm>({
    defaultValues: defaultBomListSharedForm,
  })

  const { control, formValueChanges$, getValues } = formMethods

  const { revisionStatus, revisions, onItemChange, itemDetails } = useBomListShared(formMethods)

  const printForm = async (): Promise<void> => {
    if (!itemDetails) {
      return
    }

    const printFilters = getValues()
    if (!printFilters) {
      return
    }

    setPrinting(true)
    const data = convertFormDataToCostedBomFilters(printFilters)
    await CostingBomUtilsService.printCostedBom(data)
    setPrinting(false)
  }

  useEffect(() => {
    const sub = formValueChanges$.pipe(map(convertFormDataToCostedBomFilters)).subscribe(onChange)

    return () => {
      sub.unsubscribe()
    }
  }, [formValueChanges$, onChange])

  return (
    <div className={styles.formContent}>
      <div className={styles.header}>
        <div className={styles.fields}>
          <FormXtItemNumber
            control={control}
            name={FormSharedField.ItemDetails}
            label={FormSharedLabel.ItemDetails}
            filters={defaultItemNumberFilters}
            onChange={onItemChange}
            isEditMode
            loadOptions={ItemsUtilsService.loadItemOptions}
            className={styles.itemNumber}
          />
          <div>
            <FormSelectField
              disabled={!revisions.length}
              options={revisions}
              name={FormSharedField.Revision}
              control={control}
              label={FormSharedLabel.Revision}
              className={styles.revisionField}
            />
            <XtInfoValues hidden={!revisionStatus} values={[{ label: 'Revision Status', value: revisionStatus }]} />
          </div>
        </div>
        <XtButton disabled={!itemDetails || printing} className={styles.printButton} label="Print" onClick={printForm} />
      </div>
      <FormRadioGroup className={styles.options} options={costsOptions} label="" control={control} name={FormSharedField.CostsOption} />
    </div>
  )
}
