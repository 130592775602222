import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { HeaderInfoLabelKey, IHeaderInfoLabels, LotSerialAction, LotSerialField } from './lot-serial.types'

export const defaultHeaderInfoLabels: IHeaderInfoLabels = {
  [HeaderInfoLabelKey.QtyToAssign]: 'Qty. to Assign',
  [HeaderInfoLabelKey.QtyAssigned]: 'Qty. Assigned',
  [HeaderInfoLabelKey.QtyRemaining]: 'Qty. Remaining',
}

export const lotSerialTableRulesValidationMessage: Record<string, string> = {
  uniquePair: 'The specified Location - Lot/Serial # combination is already defined in the table',
  uniqueLocation: 'The specified Location is already defined in the table',
  uniqueLotSerial: 'The specified Lot/Serial # is either already defined in the table or entered into inventory',
}

export const serialControlMethodDefaultQuantity: number = 1

export const lotSerialEditAction: IXtTableCellAction = {
  name: LotSerialAction.Edit,
  label: 'Edit',
  icon: SvgIconIds.CREATE,
}

export const lotSerialDeleteAction: IXtTableCellAction = {
  name: LotSerialAction.Delete,
  label: 'Delete',
  icon: SvgIconIds.DELETE,
}

export const lotSerialEntryLabels: Record<string, string> = {
  [LotSerialField.Location]: 'Location',
  [LotSerialField.LotSerialNumber]: 'Lot/Serial #',
  [LotSerialField.ExpirationDate]: 'Expires',
  [LotSerialField.WarrantyDate]: 'Warranty',
  [LotSerialField.Quantity]: 'Qty',
  [LotSerialField.QuantityBefore]: 'Qty. Before',
}
