import * as React from 'react'
import { FC } from 'react'
import { Divider, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { XtAccordion } from 'components/xt-accordion/xt-accordion'
import { convertNumberWithThousandSeparatorAndDigits } from 'common/utils/utils'
import { DecimalField } from 'components/controls/decimal-form-field/decimal-form-field'
import { IBomDetailsSectionProps } from './bom-details-section.types'
import * as styles from './bom-details-section.module.scss'

// TODO Update me after API is finished

export const BomDetailsSection: FC<IBomDetailsSectionProps> = ({ isMobile = false, data }) => {
  const makeBomDetailsRows = () => [
    {
      label: 'Pick-List Items',
      count: data?.picklist_number ?? '0.00',
      total: convertNumberWithThousandSeparatorAndDigits(data?.picklist_qtyper ?? 0),
    },
    {
      label: 'Non Pick-List Items',
      count: data?.non_picklist_number ?? '0.00',
      total: convertNumberWithThousandSeparatorAndDigits(data?.non_picklist_qtyper ?? 0),
    },
    { label: 'Totals', count: data?.total_number ?? '0.00', total: convertNumberWithThousandSeparatorAndDigits(data?.total_qtyper ?? 0) },
  ]
  // TODO Update me after API is finished
  const makeCostRows = () => [
    { label: 'Standard Material Cost:', total: convertNumberWithThousandSeparatorAndDigits(data?.standard_cost ?? 0) },
    { label: 'Actual Material Cost:', total: convertNumberWithThousandSeparatorAndDigits(data?.actual_cost ?? 0) },
    { label: 'Maximum Desired Cost:', total: convertNumberWithThousandSeparatorAndDigits(data?.max_cost ?? 0) },
  ]
  return (
    <XtAccordion className={styles.bomDetailsSectionContent} summary="Details">
      <div className={styles.tablesContainer}>
        <Table aria-label="Bom Details">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell variant="head" align="center">
                # of items
              </TableCell>
              <TableCell variant="head" align="center">
                Total Qty. Per
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {makeBomDetailsRows().map((row) => (
              <TableRow key={row.label}>
                <TableCell variant="head" component="th" scope="row">
                  {row.label}
                </TableCell>
                <TableCell align="center">{row.count}</TableCell>
                <TableCell align="center">{row.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {isMobile ? <Divider /> : <Divider variant="middle" orientation="vertical" />}

        <Table aria-label="Cost Details">
          <TableBody>
            {makeCostRows().map((row) => (
              <TableRow key={row.label}>
                <TableCell variant="head" component="th" scope="row">
                  {row.label}
                </TableCell>
                <TableCell align="right">{row.total}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell variant="head" component="th" scope="row">
                Total Qty.Per should equals:
              </TableCell>
              <TableCell align="right">
                {/* TODO: finish me */}
                <DecimalField disabled label="" value={0} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </XtAccordion>
  )
}
