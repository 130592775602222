import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { CoreModule } from 'core/core.module'
import { DocumentsModule } from 'documents/documents.module'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { IDocumentsService } from 'documents/documents.types'
import { documentsServiceToken } from 'documents/documents.constants'
import { prospectsServicesModuleToken, prospectsServiceToken, prospectsUtilsServiceToken } from '../prospects.constants'
import { IProspectsService, IProspectsUtilsService } from './prospects-services.types'

type ProspectsServicesModuleDefinition = typeof import('./prospects-services.export')

export const ProspectsServicesModule: ILazyModule<ProspectsServicesModuleDefinition> = {
  name: prospectsServicesModuleToken,
  resolver: () => import('./prospects-services.export'),
  initializer: ({ ProspectsService, ProspectsUtilsService }) => {
    Injector.register(
      prospectsServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)

        return new ProspectsService(httpClient, documentsService)
      },
      Scope.Singleton
    )

    Injector.register<IProspectsUtilsService>(
      prospectsUtilsServiceToken,
      (injector) => {
        const prospectsService = injector.resolve<IProspectsService>(prospectsServiceToken)

        return new ProspectsUtilsService(prospectsService)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, DocumentsModule],
}
