import React, { FC, useCallback, useState } from 'react'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { IShipping } from 'shipping/shipping.types'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { IXtTab } from 'components/xt-tabs/xt-tabs.types'
import { useCoreModule } from 'core/core-module-hook'
import { useShippingModule } from 'shipping/shipping-module-hook'
import { convertDate } from 'common/utils/date.utils'
import { OrderType } from 'core/core.types'
import { renderPhoneCell } from 'components/controls/phone/phone-link/phone-link.utils'
import { useXtForm } from 'common/hooks/form/form'
import { FormTextAreaField } from 'common/utils/form/form.components'
import * as styles from './shipping-information-dialog.module.scss'
import { ShipmentHeaderTab } from './components/shipment-header-tab/shipment-header-tab'
import { ItemsTab } from './components/items-tab/items-tab'
import { convertDataToInstance, defineShippingInformationFormData, isShipmentHeaderFormFilled } from './shipping-information-dialog.utils'
import { shippingInformationValidation } from './shipping-information-dialog.validation'
import {
  ShipmentHeaderForm,
  ShippingInformationFormField,
  ShippingInformationFormLabel,
  ShippingInformationTabName,
} from './shipping-information-dialog.types'

interface IShippingInformationDialog {
  onClose(): void
  refreshShipping(): Promise<void>
  shipping: IShipping
}

export const ShippingInformationDialog: FC<IShippingInformationDialog> = ({ onClose, refreshShipping, shipping }) => {
  const { ErrorHandler, ToastService } = useCoreModule()
  const { ShippingService } = useShippingModule()

  const [isItemReturned, setIsItemReturned] = useState<boolean>(false)
  const formMethods = useXtForm<ShipmentHeaderForm>({
    mode: 'onBlur',
    validationSchema: shippingInformationValidation,
    defaultValues: defineShippingInformationFormData(shipping),
  })

  const {
    formState: { isDirty, isSubmitting, fieldValidatorsShown },
    control,
    handleSubmit,
  } = formMethods

  const onCancel: VoidFunction = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isItemReturned) {
      void refreshShipping()
    }

    onClose()
  }

  const onShipmentSave = useCallback(
    async (formData: ShipmentHeaderForm): Promise<void> => {
      if (!isShipmentHeaderFormFilled(formData)) {
        return
      }
      try {
        const convertedFormData = convertDataToInstance(shipping, formData)
        const message = await ShippingService.update(convertedFormData)
        ToastService.showSuccess(message)
        onClose()
        await refreshShipping()
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    },
    [shipping, ShippingService, ToastService, refreshShipping, onClose, ErrorHandler]
  )

  const handleItemReturn = (): void => {
    setIsItemReturned(true)
  }

  const tabs: IXtTab[] = [
    {
      name: ShippingInformationTabName.ShippingHeader,
      markAsInvalid: fieldValidatorsShown,
      template: <ShipmentHeaderTab formMethods={formMethods} />,
    },
    {
      name: ShippingInformationTabName.Items,
      template: <ItemsTab shipping={shipping} handleItemReturn={handleItemReturn} refreshShipping={refreshShipping} onClose={onClose} />,
    },
    {
      name: ShippingInformationTabName.Notes,
      template: (
        <FormTextAreaField control={control} name={ShippingInformationFormField.Notes} label={ShippingInformationFormLabel.Notes} />
      ),
    },
  ]

  return (
    <div className={styles.shippingInformationDialogContainer}>
      <div className={styles.shippingInformationDialogFormContainer}>
        <div className={styles.shippingInformationHeader}>
          <h1 className="xt-page-title">Shipping Information</h1>
          <div className={styles.controlBlock}>
            <XtButton label="Cancel" onClick={onCancel} />
            <XtButton label="Save" disabled={!isDirty || isSubmitting} onClick={handleSubmit(onShipmentSave)} />
          </div>
        </div>
        <div className={styles.formContainer}>
          <div className={styles.informationFields}>
            <span>Order #: {shipping?.order_number ?? ''}</span>
            <span>
              {shipping?.order_type ?? ''}: {shipping?.customer_info.order_status ?? ''}
            </span>
            <span hidden={shipping?.order_type === OrderType.SalesOrder}>From: {shipping?.shipment_site}</span>
            <span hidden={shipping?.order_type === OrderType.SalesOrder}>To: {shipping?.customer_info.customer_name ?? ''}</span>
            <span hidden={shipping?.order_type === OrderType.TransferOrder}>Customer #: {shipping?.customer_info.customer_name ?? ''}</span>
            <span>Order Date: {convertDate(shipping?.customer_info.order_date ?? null)}</span>
            <span>Shipment #: {shipping?.shipment_number ?? ''}</span>
            <span hidden={shipping?.order_type === OrderType.TransferOrder}>Cust. Name: {shipping?.customer_info.customer_name ?? ''}</span>
            <span hidden={shipping?.order_type === OrderType.TransferOrder}>
              Cust. Phone:
              {renderPhoneCell({
                number: shipping?.customer_info.customer_phone ?? '',
                allowsms: shipping?.customer_info.allow_sms ?? false,
              })}
            </span>
            <span>P/O Number: {shipping?.customer_info.po_number ?? ''}</span>
            <span>Ship To Name: {shipping?.customer_info.shipto_name ?? ''}</span>
          </div>
          <div className={styles.separateLine} />
          <XtTabs tabs={tabs} />
        </div>
      </div>
    </div>
  )
}
