import React, { FC, useMemo } from 'react'
import { expandN } from 'regex-to-strings'
import { convertStringToRegex, isValidRegex } from 'common/utils/regexp.utils'
import * as styles from './pattern-validator-popup.module.scss'

export interface IXtPatternValidatorPopupParams {
  pattern: string | null
}

const maxNumberOfExamples = 5

export const XtPatternValidatorPopup: FC<IXtPatternValidatorPopupParams> = ({ pattern }) => {
  const examples = useMemo<string[]>(() => {
    if (!pattern || !isValidRegex(pattern)) {
      return []
    }
    const regex = convertStringToRegex(pattern)
    return expandN(regex, maxNumberOfExamples)
  }, [pattern])

  return (
    <div className={styles.patterValidatorPopup}>
      <h4>{examples.length ? 'Valid examples:' : 'No examples available.'}</h4>
      <div hidden={!examples.length} className={styles.patterValidatorPopupContent}>
        {examples.map((example) => (
          <span key={example} className={styles.patterValidatorPopupExample}>
            {example}
          </span>
        ))}
      </div>
    </div>
  )
}
