import React, { FC, useCallback } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { confirmationMessages, xsMq } from 'common/constants'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { XtList } from 'components/list/list'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { XtMode } from 'common/common.types'
import { useSharedModule } from 'shared/shared-module-hook'
import { IXtEntityDialogData } from 'core/core.types'
import { useTable } from 'common/hooks/useTable'
import { useInventoryAdjustmentModule } from 'inventory/inventory-adjustments/inventory-adjustments-module.hook'
import { useCoreModule } from 'core/core-module-hook'
import * as styles from './registration-list.module.scss'
import { RegistrationDialog } from '../registration-dialog/registration-dialog'
import { registrationActions, registrationColumns } from './registration-list.constants'
import { IRegistrationsList, RegistrationActions } from './registration-list.types'
import { IRegistrationFilters, RegistrationTableItem } from '../registration.types'

export const RegistrationList: FC<IRegistrationsList> = ({ itemNumber, lotSerialNumber }) => {
  const { useDialog } = useSharedModule()
  const { RegistrationUtilsService, RegistrationService } = useInventoryAdjustmentModule()
  const { ToastService } = useCoreModule()

  const {
    state: { data, loading, sortOptions },
    sort,
    refresh,
  } = useTable<RegistrationTableItem, IRegistrationFilters>({ itemNumber, lotSerialNumber }, RegistrationUtilsService.fetchRegistrations)

  const {
    open: confirmationOpen,
    openDialog: openConfirmationDialog,
    closeDialog: closeConfirmationDialog,
    itemId,
  } = useConfirmationDialog<string>()

  const {
    open: registrationDialogOpen,
    data: { data: registrationNumber, mode: registrationDialogMode },
    openDialog: openRegistrationDialog,
    closeDialog: closeRegistrationDialog,
  } = useDialog<IXtEntityDialogData<string | null>>({ data: null, mode: XtMode.New })

  const isMobile = useMediaQuery(xsMq)

  const handleRowClick = useCallback<(item: RegistrationTableItem) => void>(
    ({ number }) => openRegistrationDialog({ data: number, mode: XtMode.Edit }),
    [openRegistrationDialog]
  )

  const handleAction = useCallback<(item: RegistrationTableItem, action: RegistrationActions) => void>(
    (registration, action) => {
      if (action === RegistrationActions.Edit) {
        openRegistrationDialog({ data: registration.number, mode: XtMode.Edit })
        return
      }
      if (action === RegistrationActions.Delete) {
        openConfirmationDialog(registration.number)
      }
    },
    [openConfirmationDialog, openRegistrationDialog]
  )

  const handleDeletion = useCallback<VoidFunction>(async () => {
    closeConfirmationDialog()
    if (itemId) {
      const message = await RegistrationService.delete(itemId)
      ToastService.showSuccess(message)
      await refresh()
    }
  }, [RegistrationService, ToastService, closeConfirmationDialog, itemId, refresh])

  const openRegistrationDialogInNewMode = (): void => openRegistrationDialog({ data: null, mode: XtMode.New })

  return (
    <div className={styles.registrationListContainer}>
      <XtConfirmationDialog
        open={confirmationOpen}
        message={confirmationMessages.deleted}
        title="Delete Registration"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />
      <XtDialog
        className="xt-dialog-details-content"
        open={registrationDialogOpen}
        fullScreen={false}
        animation={XtDialogAnimation.SlideAnimation}
      >
        <RegistrationDialog
          itemNumber={itemNumber}
          onClose={closeRegistrationDialog}
          registrationNumber={registrationNumber}
          onSubmit={refresh}
          mode={registrationDialogMode}
          lotSerialNumber={lotSerialNumber}
        />
      </XtDialog>

      <div className={styles.buttonContainer}>
        <XtResponsiveButton onClick={openRegistrationDialogInNewMode} icon={SvgIconIds.ADD_CIRCLE} label="New Registration" />
      </div>
      <XtList
        actions={registrationActions}
        onRowClick={handleRowClick}
        onAction={handleAction}
        isMobile={isMobile}
        loading={loading}
        data={data}
        columns={registrationColumns}
        sortOptions={sortOptions}
        onColumnHeaderClick={sort}
      />
    </div>
  )
}
