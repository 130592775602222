import { PermissionGuard } from 'auth/permission.guard'
import { UserPermission } from 'auth/auth.types'
// import { shippingRoutePath } from '../shipping.constants'

export class PrintDialogsGuard extends PermissionGuard {
  public redirectTo = '/shipping'
  public permissions = [
    UserPermission.PrintInvoices,
    UserPermission.MaintainSalesOrders,
    UserPermission.ViewSalesOrders,
    UserPermission.MaintainTransferOrders,
    UserPermission.ViewTransferOrders,
    UserPermission.PrintBillsOfLading,
    UserPermission.PrintPackingLists,
  ]
}
