import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { CoreModule } from 'core/core.module'
import { KanbanModuleDefinition } from './kanban-module.types'
import { kanbanModuleToken } from './kanban.constants'

export const KanbanModule: ILazyModule<KanbanModuleDefinition> = {
  name: kanbanModuleToken,
  resolver: () => import('./kanban.exports'),
  dependencies: [CoreModule],
}
