import * as React from 'react'
import { FC, useState, useMemo } from 'react'
import { useCoreModule } from 'core/core-module-hook'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { useXtSelect } from 'components/controls/xt-select/xt-select-hook'
import { XtSelectCreatable } from 'components/controls/xt-select/xt-select-creatable'
import { FilterOption } from 'components/pagefilter/pagefilter.types'
import * as styles from './save-pre-set-dialog.module.scss'

interface ISavePresetDialog {
  onClose: () => void
  pagename: PageFilterMapping
  updatePageFilter: (preset: FilterOption, shared?: boolean) => void
  createPageFilter: (preset: FilterOption, shared?: boolean) => void
}

export const SavePresetDialog: FC<ISavePresetDialog> = ({ onClose, pagename, updatePageFilter, createPageFilter }) => {
  const { PageFilterUtilsService } = useCoreModule()
  const { options: pageFilters } = useXtSelect(PageFilterUtilsService.getAllFilterOptions, { pagename })

  const [shared, setShared] = useState(false)
  const [preset, setPreset] = useState<FilterOption | null>(null)

  const onPreSetChange = (option: FilterOption): void => {
    setPreset(option)
  }

  const { label, action } = useMemo(() => {
    const isSaveMove = !preset || !pageFilters.find((filter) => preset.id === filter.id)
    const buttonLabel = isSaveMove ? 'Save' : 'Update'
    const buttonAction = isSaveMove ? () => preset && createPageFilter(preset, shared) : () => preset && updatePageFilter(preset, shared)
    return {
      label: buttonLabel,
      action: buttonAction,
    }
  }, [preset, pageFilters, createPageFilter, shared, updatePageFilter])

  return (
    <div className={styles.saveDialog}>
      <XtSelectCreatable options={pageFilters} onChange={onPreSetChange} value={preset} label="Filter Name" />
      <XtButton label="Cancel" onClick={onClose} />
      <XtCheckbox value={shared} onChange={setShared} label="Shared" />
      <XtButton disabled={!preset} label={label} onClick={action} />
    </div>
  )
}
