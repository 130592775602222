import React, { FC, useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'

interface ILoadingSpinnerDelayed {
  text?: string
  className?: string
}

export const LoadingSpinnerDelayed: FC<ILoadingSpinnerDelayed> = ({ text = 'Loading...', className }) => {
  const [showLoader, setShowLoader] = useState<boolean>(false)

  useEffect(() => {
    const timer = setTimeout(() => setShowLoader(true), 100)

    return () => clearTimeout(timer)
  }, [])

  return showLoader ? (
    <div className={className}>
      <CircularProgress size={15} /> {text}
    </div>
  ) : null
}
