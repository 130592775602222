import { isSafari, isMobile } from 'react-device-detect'

export function checkIsLandscape({ innerHeight, innerWidth, screen: { orientation } }: Window): boolean {
  if (!isSafari) {
    if (orientation.type === 'landscape-primary' || orientation.type === 'landscape-secondary') {
      return true
    }
  }
  return innerHeight < innerWidth
}

export function checkIsMobile(): boolean {
  return isMobile
}
