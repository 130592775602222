// extracted by mini-css-extract-plugin
export var root = "bom-copy-dialog_root__UUz97";
export var xtContent = "bom-copy-dialog_xt-content__+LKd+";
export var xtContentWithRemarks = "bom-copy-dialog_xt-content-with-remarks__LwuiJ";
export var muiFormHelperTextRoot = "bom-copy-dialog_MuiFormHelperText-root__xdErA";
export var xtListPageContainer = "bom-copy-dialog_xt-list-page-container__hfxLv";
export var muiInputBaseRoot = "bom-copy-dialog_MuiInputBase-root__uNRfo";
export var muiTextFieldRoot = "bom-copy-dialog_MuiTextField-root__zdefL";
export var muiTablePaginationRoot = "bom-copy-dialog_MuiTablePagination-root__puDCn";
export var muiTablePaginationSelectRoot = "bom-copy-dialog_MuiTablePagination-selectRoot__a5NtB";
export var muiTablePaginationSelect = "bom-copy-dialog_MuiTablePagination-select__SnYSm";
export var muiStepperRoot = "bom-copy-dialog_MuiStepper-root__U882D";
export var muiStepperHorizontal = "bom-copy-dialog_MuiStepper-horizontal__oRsb5";
export var muiStepIconRoot = "bom-copy-dialog_MuiStepIcon-root__kqVEG";
export var muiStepIconActive = "bom-copy-dialog_MuiStepIcon-active__WbISE";
export var muiStepIconCompleted = "bom-copy-dialog_MuiStepIcon-completed__lEwRm";
export var muiListItemRoot = "bom-copy-dialog_MuiListItem-root__6Mplk";
export var muiSelected = "bom-copy-dialog_Mui-selected__63lBi";
export var xtPageTitle = "bom-copy-dialog_xt-page-title__ogI4Z";
export var xtSubPageTitle = "bom-copy-dialog_xt-subPage-title__sHbjU";
export var xtSectionBorder = "bom-copy-dialog_xt-section-border__hFDpp";
export var xtPageHeader = "bom-copy-dialog_xt-page-header__XyGIi";
export var header = "bom-copy-dialog_header__C3yby";
export var xtDialogScrollable = "bom-copy-dialog_xt-dialog-scrollable__zIctW";
export var reactToastNotificationsContainer = "bom-copy-dialog_react-toast-notifications__container__c9+l1";
export var xtScrollbarStyle = "bom-copy-dialog_xt-scrollbar-style__j7gh0";
export var xtModalDetailsContent = "bom-copy-dialog_xt-modal-details-content__me27M";
export var xtDialogDetailsContent = "bom-copy-dialog_xt-dialog-details-content__LI4tm";
export var xtLazyModuleLoaderTabContainer = "bom-copy-dialog_xt-lazy-module-loader-tab-container__QjaQn";
export var xtTextTruncated = "bom-copy-dialog_xt-text-truncated__-9uR9";
export var xtSummaryContent = "bom-copy-dialog_xt-summary-content__616Rm";
export var xtSummaryLabel = "bom-copy-dialog_xt-summary-label__vCecE";
export var visibleHidden = "bom-copy-dialog_visible-hidden__cQd1w";
export var headerBottomMargin = "bom-copy-dialog_header-bottom-margin__63gj2";
export var xtDialogHeaderLogoAlign = "bom-copy-dialog_xt-dialog-header-logoAlign__zC5ou";
export var xtStickyHeader = "bom-copy-dialog_xt-sticky-header__o26cm";
export var noBorder = "bom-copy-dialog_no-border__vSeWB";
export var muiOutlinedInputNotchedOutline = "bom-copy-dialog_MuiOutlinedInput-notchedOutline__iBw4s";
export var bomCopyDialog = "bom-copy-dialog_bomCopyDialog__M4M7h";
export var headerButtons = "bom-copy-dialog_headerButtons__HxRMC";
export var bomFields = "bom-copy-dialog_bomFields__JLIHf";
export var fields = "bom-copy-dialog_fields__WyGFw";
export var itemNumber = "bom-copy-dialog_itemNumber__OgWUP";
export var additionalInfo = "bom-copy-dialog_additionalInfo__y4KzV";