function defineMinValidatorMessage(minValue: number | string, scale: number = 0): string {
  const minValueString = typeof minValue === 'number' ? minValue.toFixed(scale) : minValue

  return `Value below minimum requirement ${minValueString}.`
}

function defineMaxValidatorMessage(maxValue: number | string, scale: number = 0): string {
  const maxValueString = typeof maxValue === 'number' ? maxValue.toFixed(scale) : maxValue

  return `Should be less than ${maxValueString}.`
}

function defineOrderMultipleValidatorMessage(orderMultiple: number): string {
  return `Value should be a multiple of ${orderMultiple}.`
}

export const validationMessage = {
  isRequired: 'Required.',
  alreadyUsed: 'Already in use.',
  invalidNumber: 'Invalid number.',
  invalidItemNumber: 'Please enter a valid Item #.',
  invalidDate: 'Invalid date format.',
  invalidEmail: 'Please enter a valid email address.',
  invalidPhone: 'Please enter a valid phone number.',
  invalidPostalCode: 'Please enter a valid Postal Code.',
  invalidFormat: 'Invalid format.',
  invalidPatternValidator: 'Validator should have valid Regex syntax.',
  invalidInputMask: 'Mask is invalid. Please check the mask definitions.',
  invalidDecimal: 'You can only have 4 decimal places.',
  inputMaskHasConflictWithValidator: "Mask shouldn't conflict with the validator.",
  minimumDate: '01/01/1900',
  passwordMatch: 'Passwords do not match.',
  min: defineMinValidatorMessage,
  max: defineMaxValidatorMessage,
  cannotBeGreaterThan: (value: string) => `Cannot be greater than ${value}.`,
  cannotBeLessThan: (value: string) => `Cannot be less than ${value}.`,
  minDate: (min: string) => `Should be later than ${min}.`,
  maxDate: (max: string) => `Should be earlier than ${max}.`,
  notEqual: (value: string) => `Should differ from ${value}`,
  equals: (value: string) => `Should be equal to ${value}.`,
  diapason: (startValue: string | number, endValue: string | number) => `Should match diapason from ${startValue} to ${endValue}.`,
  orderMulitple: defineOrderMultipleValidatorMessage,
  existingSequencenumber: 'Sequence number already exists in BOM',
}

export const validationRegExp: {
  phone: RegExp
  postalCode: RegExp
  email: RegExp
  fileType: RegExp
  decimal: RegExp
} = {
  phone: /^(\+\d{1,3}\s?)?(\(\d+\)|\d+)[- ]?\d{1,5}[- ]?\d{1,5}$/,
  postalCode: /^(?=.*\d)[a-zA-Z0-9]+([- ]?[a-zA-Z0-9]+)*$/,
  email: /(?!.*\.\..*)(?!.*--.*)(?!.* .*)(?!.*@@.*)(?!.*(\.|@|^)-.*)(?!.*\.(\.|@|$).*)(?!.*(\.|@)[A-Za-z0-9-]{64,}(\.|$).*)^[A-Za-z0-9][A-Za-z0-9!#$%&'*+\/=?^_`{|}~.-]{0,62}[^.]?@[A-Za-z0-9.:\-\[\]]{0,253}[A-Za-z0-9\[\]][.][A-Za-z.]{0,62}?$/,
  fileType: /(jpg|jpeg|png|gif|bmp|tiff)$/i,
  decimal: /^\d+(\.\d{0,4})?$/,
}

export function validatePhone(phone?: string): boolean {
  if (!phone) {
    return false
  }
  return validationRegExp.phone.test(phone)
}

export function validatePostalCode(postalCode?: string): boolean {
  if (!postalCode) {
    return false
  }
  return validationRegExp.postalCode.test(postalCode)
}

export function validateEmail(email?: string): boolean {
  if (!email) {
    return false
  }
  return validationRegExp.email.test(email)
}

export function validateFileType(type?: string): boolean {
  if (!type) {
    return false
  }
  return validationRegExp.fileType.test(type)
}

export const maxNumber = 10 ** 16 - 2

export function validateDecimal(decimal: string) {
  if (!decimal) {
    return false
  }
  return validationRegExp.decimal.test(decimal)
}

export const minDate = '1900-01-01'
