import * as React from 'react'
import { ReactElement } from 'react'
import { Control } from 'react-hook-form'
import { FormDatePicker } from 'common/utils/form/form.components'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { dateToServerFormat } from 'common/utils/date.utils'
import { AsOfFilters, IInventoryListFilterForm, WorkbenchFilterFormField } from './inventory-list-filter.types'
import * as styles from './inventory-list-filter.module.scss'
import { IInventoryLookupParams } from '../inventory-list.types'
import { lookAheadDaysMaxValue, lookAheadDaysMinValue } from './inventory-list-filter.constants'

export function resolveSelectedFilterTemplate(filter: string, control: Control<IInventoryListFilterForm>, disabled: boolean): ReactElement {
  switch (filter) {
    case AsOfFilters.ItemSiteLeadTime:
      return <div />
    case AsOfFilters.LookAheadDays:
      return (
        <DecimalFormField
          disabled={disabled}
          name={WorkbenchFilterFormField.LookAheadDays}
          control={control}
          label={AsOfFilters.LookAheadDays}
          className={styles.filterTemplate}
          fixedDecimalScale={0}
          maxValue={lookAheadDaysMaxValue}
          minValue={lookAheadDaysMinValue}
          counters
          allowNegative={false}
        />
      )
    case AsOfFilters.CutoffDate:
      return (
        <FormDatePicker
          disabled={disabled}
          className={styles.filterTemplate}
          control={control}
          label={AsOfFilters.CutoffDate}
          name={WorkbenchFilterFormField.CutoffDate}
        />
      )
    case AsOfFilters.Dates:
      return (
        <div className={styles.datesFilter}>
          <FormDatePicker disabled={disabled} control={control} label="From" name={WorkbenchFilterFormField.StartDate} />
          <FormDatePicker disabled={disabled} control={control} label="To" name={WorkbenchFilterFormField.EndDate} />
        </div>
      )
    default: {
      return <div />
    }
  }
}

export function convertToFilterParams(formState: IInventoryListFilterForm): IInventoryLookupParams {
  return {
    byDate: dateToServerFormat(formState[WorkbenchFilterFormField.CutoffDate] ?? null),
    endDate: dateToServerFormat(formState[WorkbenchFilterFormField.EndDate] ?? null),
    startDate: dateToServerFormat(formState[WorkbenchFilterFormField.StartDate] ?? null),
    byDays: formState[WorkbenchFilterFormField.LookAheadDays],
  }
}
