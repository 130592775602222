import * as React from 'react'
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format'
import { Controller } from 'react-hook-form'
import { Button, TextField } from '@material-ui/core'
import { FC, ReactElement } from 'react'
import { Overwrite } from 'utility-types'
import { cls } from 'common/utils/utils'
import { convertToError, shouldShowError } from 'common/utils/form/form.components.utils'
import { ICounterButtons, IDecimalField, IDecimalFormField } from './decimal-form-field.types'
import { SvgIconIds } from '../../svg-icon/svg-icon.types'
import { SvgIcon } from '../../svg-icon/svg-icon'
import * as styles from './decimal-form-field.module.scss'

type NumberFormatProperties = Overwrite<NumberFormatProps, { defaultValue: number }>

function getValueLimiter(minValue: number | undefined, maxValue: number | undefined): undefined | ((value: NumberFormatValues) => boolean) {
  if (minValue === undefined && maxValue === undefined) {
    return
  }
  return ({ floatValue }) => {
    if (maxValue !== undefined && floatValue !== undefined && floatValue > maxValue) {
      return false
    }
    return minValue !== undefined && floatValue !== undefined ? floatValue >= minValue : true
  }
}

function isNotEmpty(value: number | null): boolean {
  if (typeof value === 'number') {
    return true
  }
  return !!value
}

function NumberFormatCustom(props: NumberFormatProperties): ReactElement<NumberFormatProperties> {
  const { inputRef, fixedDecimalScale, allowNegative, thousandSeparator, isAllowed, onValueChange, prefix, suffix, ...other } = props

  return (
    <NumberFormat
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      isAllowed={isAllowed}
      getInputRef={inputRef}
      fixedDecimalScale={fixedDecimalScale}
      decimalScale={fixedDecimalScale}
      allowNegative={allowNegative}
      thousandSeparator={thousandSeparator}
      onValueChange={onValueChange}
      prefix={prefix}
      suffix={suffix}
    />
  )
}

function CounterButtons({ onIncrease, onDecrease, disabled, onBlur }: ICounterButtons): JSX.Element {
  return (
    <div className={styles.counterButtons}>
      <Button onClick={onIncrease} disabled={disabled} onBlur={onBlur}>
        <SvgIcon iconId={SvgIconIds.COUNTER} className={styles.svgIncreaseButton} />
      </Button>
      <Button onClick={onDecrease} disabled={disabled} onBlur={onBlur}>
        <SvgIcon iconId={SvgIconIds.COUNTER} className={styles.svgDecreaseButton} />
      </Button>
    </div>
  )
}

// TODO research minValue - seems like it doesn't work
export const DecimalField: FC<IDecimalField> = ({
  value,
  disabled,
  label,
  onChange,
  fixedDecimalScale = 2,
  className,
  inputProps,
  error,
  hidden = false,
  allowNegative = true,
  maxValue,
  minValue,
  onBlur,
  counters = false,
  thousandSeparator = ',',
  prefix,
  suffix,
  required,
}) => {
  const isAllowed = getValueLimiter(minValue, maxValue)

  const onValueChange = ({ floatValue }: NumberFormatValues): void => onChange && onChange(floatValue ?? minValue ?? null)

  return (
    <TextField
      inputProps={{
        ...inputProps,
        isAllowed,
        onValueChange,
        fixedDecimalScale,
        allowNegative,
        thousandSeparator,
        maxvalue: maxValue,
        prefix,
        suffix,
      }}
      className={cls('MuiFormField', className, isNotEmpty(value) && 'decimalFormInputFilled')}
      variant="outlined"
      label={label}
      required={required}
      value={value ?? ''}
      onBlur={onBlur}
      disabled={disabled}
      hidden={hidden}
      error={!!error}
      helperText={error}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      InputProps={{
        inputComponent: NumberFormatCustom,
        endAdornment: !counters ? null : (
          <CounterButtons
            disabled={disabled}
            onBlur={onBlur}
            onDecrease={() => {
              const currentValue = value || 0
              if (!onChange || (minValue !== undefined && currentValue <= minValue)) {
                return
              }
              onChange(currentValue - 1)
            }}
            onIncrease={() => {
              const currentValue = value || 0
              if (!onChange || (maxValue !== undefined && maxValue <= currentValue)) {
                return
              }
              onChange(currentValue + 1)
            }}
          />
        ),
      }}
    />
  )
}

export function DecimalFormField<TFieldValues>({
  disabled,
  name,
  control,
  label,
  onChange,
  fixedDecimalScale = 2,
  className,
  inputProps,
  error,
  hidden = false,
  allowNegative = true,
  maxValue,
  minValue,
  counters,
  thousandSeparator,
  onBlur,
  prefix,
  suffix,
  required,
}: IDecimalFormField<TFieldValues>): ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value: controlValue, onChange: onControlChange, onBlur: onControlBlur },
        fieldState: { error: fieldError, isTouched },
        formState: { isSubmitted },
      }) => {
        const handleChange = onChange ?? onControlChange
        const controlError = shouldShowError(isSubmitted, isTouched, disabled) ? convertToError(error, fieldError) : undefined

        const handleBlur = (): void => {
          onControlBlur()
          if (typeof onBlur === 'function') {
            onBlur()
          }
        }

        return (
          <DecimalField
            inputProps={inputProps}
            allowNegative={allowNegative}
            fixedDecimalScale={fixedDecimalScale}
            maxValue={maxValue}
            minValue={minValue}
            className={cls('MuiFormField', className)}
            label={label}
            value={controlValue as number}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={disabled}
            hidden={hidden}
            error={controlError}
            counters={counters}
            thousandSeparator={thousandSeparator}
            prefix={prefix}
            suffix={suffix}
            required={required}
          />
        )
      }}
    />
  )
}
