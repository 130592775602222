import React, { FC } from 'react'
import * as styles from './no-landscape.module.scss'
import logo from '../../assets/img/xtuple.png'

import { useDeviceDetect } from '../../common/hooks/useDeviceDetect/useDeviceDetect'

export const NoMobileLandscape: FC = ({ children }) => {
  const { isLandscapeBlocked } = useDeviceDetect()

  return isLandscapeBlocked ? (
    <div className={styles.noLandscapeContainer}>
      <img src={logo} alt="xTuple Logo" />
      <div className={styles.noLandscapeText}>
        <p>Landscape orientation is not supported for mobile devices.</p>
        <p>Please rotate your device.</p>
      </div>
    </div>
  ) : (
    <>{children}</>
  )
}
