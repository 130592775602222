import React from 'react'
import { cls } from 'common/utils/utils'
import { TaskCalendarItem } from '../tasks-calendar.types'

import * as styles from './tasks-calendar-item.module.scss'

/* function isSaturdayDay(date: string): boolean {
  return new Date(date).getDay() === 6
}

function renderAssignees(assignees: IAssignee[] | null): React.ReactNode {
  if (!Array.isArray(assignees) || !assignees.length) {
    return (
      <div className={styles.tasksCalendarItemBlock}>
        <span className={styles.taskCalendarItemLabel}>Assigned To:</span>
        <span className={styles.taskCalendarItemValue}>N/A</span>
      </div>
    )
  }
  return (
    <div>
      <span className={styles.taskCalendarItemLabel}>Assigned To:</span>
      <div className={styles.taskCalendarItemAssignees}>
        {assignees.map(({ username }) => (
          <span className={styles.taskCalendarItemValue}>{username}</span>
        ))}
      </div>
    </div>
  )
} */

export function TasksCalendarItemTemplate({ raw: { name } }: TaskCalendarItem): React.ReactElement {
  /*  const isSaturday = isSaturdayDay(due_date) */

  return (
    <div className={styles.taskCalendarItem}>
      <p className={cls(styles.taskCalendarItemValue, 'xt-text-truncated')}>{name}</p>
      {/* TODO need to find a way how to implement the tooltip correctly  */}
      {/* <div className={cls(styles.taskCalendarItemDetails, isSaturday && styles.taskCalendarItemDetailsRightEdge)}>
        <div className={styles.tasksCalendarItemBlock}>
          <span className={styles.taskCalendarItemLabel}>Task Name:</span>
          <span className={styles.taskCalendarItemValue}>{name}</span>
        </div>
        {renderAssignees(assignees)}
        <div className={styles.tasksCalendarItemBlock}>
          <span className={styles.taskCalendarItemLabel}>Priority:</span>
          <span className={styles.taskCalendarItemValue}>{priority}</span>
        </div>
        <XtButton label="Show Details" />
      </div> */}
    </div>
  )
}
