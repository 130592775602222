import { PermissionGuard } from '../../auth/permission.guard'
import { UserPermission } from '../../auth/auth.types'
import { setupRoutePath } from '../setup.constants'

export class CrmTabGuard extends PermissionGuard {
  public redirectTo = setupRoutePath
  public permissions = [
    UserPermission.ConfigureCRM,
    UserPermission.MaintainOpportunitySources,
    UserPermission.MaintainOpportunityStages,
    UserPermission.MaintainOpportunityTypes,
  ]
}
