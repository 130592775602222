import * as yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { dateYupValidator } from 'common/utils/date.utils'
import { ProspectFormField } from '../prospect-details.types'

export const prospectFormSchema = yup.object().shape({
  [ProspectFormField.Number]: yup.string().trim().required(validationMessage.isRequired),
  [ProspectFormField.Name]: yup.string().trim().required(validationMessage.isRequired),
  [ProspectFormField.Currency]: yup.mixed().required(validationMessage.isRequired),
  [ProspectFormField.LastTouched]: dateYupValidator.notRequired(),
  [ProspectFormField.AssignedDate]: dateYupValidator.notRequired(),
})
