import { GridColumns } from '@mui/x-data-grid-pro'
import { ICostedBomWithId } from '../bom-utils.types'
import { singleLevelNumberConverter } from '../single-level/single-level-list.utils'
import { IndentedListField, IndentedListLabel } from './intended-list.types'

export const columns: GridColumns<ICostedBomWithId> = [
  {
    field: IndentedListField.Seq,
    headerName: IndentedListLabel.Seq,
    minWidth: 90,
  },
  {
    field: IndentedListField.ItemNumber,
    headerName: IndentedListLabel.ItemNumber,
    minWidth: 140,
  },
  {
    field: IndentedListField.ItemDescription,
    headerName: IndentedListLabel.ItemDescription,
    minWidth: 240,
  },
  {
    field: IndentedListField.UOM,
    headerName: IndentedListLabel.UOM,
    minWidth: 90,
  },
  {
    field: IndentedListField.BatchSize,
    headerName: IndentedListLabel.BatchSize,
    minWidth: 140,
    valueParser: (value: number) => singleLevelNumberConverter(value),
  },
  {
    field: IndentedListField.QtyFixed,
    headerName: IndentedListLabel.QtyFixed,
    minWidth: 140,
    valueParser: (value: number) => singleLevelNumberConverter(value),
  },
  {
    field: IndentedListField.QtyPer,
    headerName: IndentedListLabel.QtyPer,
    minWidth: 140,
    valueParser: (value: number) => singleLevelNumberConverter(value),
  },
  {
    field: IndentedListField.Scrap,
    headerName: IndentedListLabel.Scrap,
    minWidth: 140,
    valueParser: (value: number) => singleLevelNumberConverter(value),
  },
  {
    field: IndentedListField.Effective,
    headerName: IndentedListLabel.Effective,
    minWidth: 140,
  },
  {
    field: IndentedListField.Expires,
    headerName: IndentedListLabel.Expires,
    minWidth: 140,
  },
  {
    field: IndentedListField.UnitCost,
    headerName: IndentedListLabel.UnitCost,
    minWidth: 140,
    valueParser: (value: number) => singleLevelNumberConverter(value),
  },
  {
    field: IndentedListField.ExtendedCost,
    headerName: IndentedListLabel.ExtendedCost,
    minWidth: 140,
    valueParser: (value: number) => singleLevelNumberConverter(value),
  },
]

export const intendedListPageTitle = 'xTuple - Costed Indented Level BOM'
