export const setupRoutePath = '/settings'
export const setupModule = 'SetupModule'

export const setupRoutes = {
  sales: 'sales',
  crm: 'crm',
  products: 'products',
  system: 'system',
  HR: 'HR',
  userManagement: 'user-management',
}
