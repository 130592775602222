import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router'
import { XtMode } from 'common/common.types'
import { useTable } from 'common/hooks/useTable'
import { useCoreModule } from 'core/core-module-hook'
import { IRoutingItem } from 'products/routing/routing.types'
import { productsRoutePath, productsRoutes } from 'products/products.constants'
import { useProductsModule } from 'products/products-module-hook'
import { normalizeRoutingItemsData } from 'products/routing/routing.utils'
import { ItemOption } from 'products/items/items.types'
import { IRoutingDetailsFormData, RoutingDetailsFilter, RoutingDetailsFormField } from '../routing-details.types'
import { convertFormDataCreate, convertFormDataUpdate, defineFormData } from '../routing-details.utils'
import { routingDetailsValidationSchema } from '../routing-details.validation'
import { IRoutingDetailsHook, IRoutingState } from './routing-details-hook.types'

const defaultRoutingState: IRoutingState = {
  routing: null,
  initializing: false,
}

const defaultTableFiltersState = { showExpired: false, showFuture: false }

export function useRoutingDetails(itemNumber: string | undefined, mode: XtMode): IRoutingDetailsHook {
  const { ErrorHandler, ToastService } = useCoreModule()
  const { RoutingService, RoutingUtilsService, ItemsService } = useProductsModule()

  const history = useHistory()

  const [currentMode, setCurrentMode] = useState<XtMode>(mode)

  const [{ routing, initializing }, setRoutingState] = useState<IRoutingState>(defaultRoutingState)

  const formMethods = useForm<IRoutingDetailsFormData>({
    defaultValues: defineFormData(null, null),
    resolver: yupResolver(routingDetailsValidationSchema),
    mode: 'onBlur',
  })

  const { watch, reset, getValues } = formMethods
  const itemNumberValue = watch(RoutingDetailsFormField.ItemNumber)

  const [routingNotFound, setRoutingNotFound] = useState(false)

  const tableState = useTable({ ...defaultTableFiltersState, itemNumber }, RoutingUtilsService.fetchRoutingItems)

  const { filter, state, setLoading, setData, refresh } = tableState

  useEffect(() => {
    filter({ ...state.filters, itemNumber: itemNumberValue?.item_number || itemNumber })
  }, [itemNumberValue?.item_number])

  const handleItemNumberChange = async (item: ItemOption | null): Promise<void> => {
    if (!item) {
      reset(defineFormData(null, null))
      return
    }
    try {
      setLoading(true)
      const routingData = await RoutingService.get(item.item_number, false, false)

      setRoutingState({ initializing: false, routing: routingData })
      setCurrentMode(XtMode.Edit)
      reset(defineFormData(routingData, item))
      setData([], 0)
    } catch {
      setRoutingState(defaultRoutingState)
      reset(defineFormData(null, item))
      setData([], 0)
    } finally {
      setLoading(false)
    }
  }

  async function init(): Promise<void> {
    try {
      if (currentMode === XtMode.New || !itemNumber) {
        reset(defineFormData(null, null))
        return
      }
      filter({ ...defaultTableFiltersState, itemNumber })

      setRoutingState((prev) => ({ ...prev, initializing: true }))
      const routingData = await RoutingService.get(itemNumber, false, false)
      const item = routingData ? await ItemsService.get(routingData.item_number) : null

      reset(defineFormData(routingData, item))
      setRoutingState({ routing: routingData, initializing: false })
      setRoutingNotFound(false)
    } catch (error) {
      setRoutingNotFound(true)
    }
  }

  useEffect(() => {
    void init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (routingNotFound) throw Error(`Routing ${itemNumber} not found.`)
  }, [routingNotFound])

  const deleteRoutingItem = async (itemId: number): Promise<void> => {
    if (!itemId) {
      return
    }
    try {
      if (currentMode === XtMode.New) {
        const updatedTableData = state.data.filter(({ id }) => id !== itemId)
        setData(updatedTableData, updatedTableData.length)
        return
      }
      setLoading(true)
      const message = await RoutingService.deleteItem(routing!.item_number, itemId)
      ToastService.showSuccess(message)
      await refresh()
      setLoading(false)
    } catch (error) {
      ErrorHandler.handleError(error)
      setLoading(false)
    }
  }

  const expireRoutingItem = useCallback(
    async (id: number) => {
      const currentItem = getValues(RoutingDetailsFormField.ItemNumber)
      if (!currentItem) {
        return
      }
      try {
        setLoading(true)
        const message = await RoutingService.expireItem(currentItem.itemNumber, id)
        await refresh()
        setLoading(false)
        ToastService.showSuccess(message)
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    },
    [ErrorHandler, RoutingService, ToastService, getValues, refresh, setLoading]
  )

  const onFiltersChange = (name: RoutingDetailsFilter) => (checked: boolean): void => {
    filter({ ...state.filters, [name]: checked })
  }

  const handleRoutingItemSubmitInNewMode = (routingItemData: IRoutingItem, routingItemMode: XtMode): void => {
    if (routingItemMode === XtMode.Edit) {
      const itemIndex = state.data.findIndex(({ id }) => id === routingItemData.sequence_number)
      const updatedTableData = normalizeRoutingItemsData([
        ...state.data.slice(0, itemIndex),
        routingItemData,
        ...state.data.slice(itemIndex + 1),
      ])
      setData(updatedTableData, updatedTableData.length)
      return
    }
    if (routingItemMode === XtMode.New) {
      const newItem = { ...routingItemData, id: state.data.length ?? 0 }
      const data = normalizeRoutingItemsData([...state.data, newItem])
      setData(data, data.length)
    }
  }

  const handleRoutingItemSubmit = async (routingItemData: IRoutingItem, routingItemMode: XtMode): Promise<void> => {
    if (currentMode === XtMode.New) {
      return handleRoutingItemSubmitInNewMode(routingItemData, routingItemMode)
    }
    if (!routing) {
      return
    }
    try {
      if (routingItemMode === XtMode.Edit) {
        const message = await RoutingService.updateItem(routing.item_number, routingItemData)
        ToastService.showSuccess(message)
      }
      if (routingItemMode === XtMode.New) {
        const message = await RoutingService.createItem(routing.item_number, routingItemData)
        ToastService.showSuccess(message)
      }
      await refresh()
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const onRoutingSubmitNew = useCallback(
    async (formValues: IRoutingDetailsFormData) => {
      const values = convertFormDataCreate(formValues, routing?.lead_time, routing?.revision_status, state.data)
      try {
        const message = await RoutingService.create(values)
        ToastService.showSuccess(message)
        history.push(`${productsRoutePath}/${productsRoutes.routing}`)
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    },
    [ErrorHandler, RoutingService, ToastService, history, routing?.lead_time, routing?.revision_status, state.data]
  )

  const onRoutingSubmit = useCallback(
    async (formValues: IRoutingDetailsFormData) => {
      try {
        if (!routing) {
          return
        }
        const values = convertFormDataUpdate(
          formValues,
          routing.item_number,
          routing.item_description,
          routing.lead_time,
          routing.revision_status
        )
        const message = await RoutingService.update(values)
        ToastService.showSuccess(message)
      } catch (error) {
        ErrorHandler.handleError(error)
      }
    },
    [ErrorHandler, RoutingService, ToastService, history, routing]
  )

  return {
    mode: currentMode,
    tableState,
    formMethods,
    routingState: { routing, initializing },
    handleItemNumberChange,
    expireRoutingItem,
    deleteRoutingItem,
    handleRoutingItemSubmit,
    onRoutingSubmit: currentMode === XtMode.New ? onRoutingSubmitNew : onRoutingSubmit,
    onFiltersChange,
  }
}
