import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { ReportType as ShippingReportType } from 'shipments/shipments.types'
import { IPrintShippingFormSharedState } from '../print-dialogs.types'

export interface IPackingListPrintForm {
  onClose: VoidFunction
}

export enum ReportTypeLabel {
  AutoSelect = 'Auto Select',
  PackingList = 'Packing List',
  PickList = 'Pick List',
}

export enum PackingListPrintFormField {
  Site = 'site',
  ReportType = 'report_type',
}

export enum PackingListPrintFormLabel {
  Site = 'Site',
}

export interface IPackingListPrintFormState extends IPrintShippingFormSharedState {
  [PackingListPrintFormField.Site]: IXtAutocompleteOption | null
  [PackingListPrintFormField.ReportType]: ShippingReportType
}
