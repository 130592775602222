import { PrintSharedFormField, ShippingPrintFormSharedField } from './print-dialogs.types'

export const defaultPrintFormState = {
  [PrintSharedFormField.From]: 1,
  [PrintSharedFormField.To]: 1,
  [PrintSharedFormField.ReportName]: null,
  [PrintSharedFormField.Number]: null,
}

export const defaultPrintShippingFormState = {
  [ShippingPrintFormSharedField.Order]: null,
  [ShippingPrintFormSharedField.ShipmentNumber]: null,
}
