import { FilterType, IFilter } from 'components/pagefilter/filter/filter.types'
import { IDocumentsUtilsService } from 'documents/documents-utils.service'
import { taskStatusOptions } from '../tasks.constants'
import { TaskListFilterField, TaskListFilterLabel } from '../tasks.types'

export function resolveFilters({ loadUserOptions }: IDocumentsUtilsService): IFilter[] {
  return [
    {
      type: FilterType.Dropdown,
      label: TaskListFilterLabel.Status,
      fieldName: TaskListFilterField.Status,
      options: taskStatusOptions,
    },
    {
      type: FilterType.Autocomplete,
      label: TaskListFilterLabel.User,
      fieldName: TaskListFilterField.User,
      autocompleteProps: {
        loadOptions: loadUserOptions,
      },
    },
  ]
}
