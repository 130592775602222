import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { QuotesAction } from 'sales-shared/quotes.types'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { ICRMAccountDocument } from 'documents/documents.types'
import { isCustomerCrmAccount } from 'companies/accounts/accounts.utils'

export function defineQuoteActions(account: Pick<ICRMAccountDocument, 'crm_roles'> | null): IXtTableCellAction[] {
  if (account && isCustomerCrmAccount(account)) {
    return [
      {
        name: QuotesAction.Edit,
        label: QuotesAction.Edit,
        icon: SvgIconIds.CREATE,
      },
      {
        name: QuotesAction.View,
        label: QuotesAction.View,
        icon: SvgIconIds.VISIBILITY,
      },
      {
        name: QuotesAction.Delete,
        label: QuotesAction.Delete,
        icon: SvgIconIds.DELETE,
      },
      {
        name: QuotesAction.Convert,
        label: QuotesAction.Convert,
        icon: SvgIconIds.CONVERT,
      },
      {
        name: QuotesAction.PrintForm,
        label: QuotesAction.PrintForm,
        icon: SvgIconIds.PRINT,
      },
    ]
  }
  return [
    {
      name: QuotesAction.Edit,
      label: QuotesAction.Edit,
      icon: SvgIconIds.CREATE,
    },
    {
      name: QuotesAction.View,
      label: QuotesAction.View,
      icon: SvgIconIds.VISIBILITY,
    },
    {
      name: QuotesAction.Delete,
      label: QuotesAction.Delete,
      icon: SvgIconIds.DELETE,
    },
    {
      name: QuotesAction.PrintForm,
      label: QuotesAction.PrintForm,
      icon: SvgIconIds.PRINT,
    },
  ]
}
