import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'

export const profileRoutePath: string = '/profile'

export const rowsPerPageOptions: IXtAutocompleteOption[] = [
  {
    id: '10',
    label: '10',
  },
  {
    id: '25',
    label: '25',
  },
  {
    id: '50',
    label: '50',
  },
  {
    id: '100',
    label: '100',
  },
]
