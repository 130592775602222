import { nanoid } from 'nanoid'
import React from 'react'
import { IconButton } from '@material-ui/core'
import { ControlMethodOption, IItemSite, ILotSerialNumber, ItemSiteDistributionOption } from 'products/items/items.types'
import {
  IXtTableColumnEditable,
  IXtTableColumnNumberEditable,
  IXtTableColumnSelectEditable,
} from 'components/table/table-head/table-head.types'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { XtEditableCellType } from 'components/table/table-editable-cell/table-editable-cell.types'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { lotSerialDeleteAction, lotSerialEditAction, lotSerialEntryLabels } from './lot-serial.constants'
import { LotSerialAction, LotSerialEntryRow, LotSerialField, LotSerialOption, NewLotSerialButtonLabel } from './lot-serial.types'

export function supportsLotSerial(controlMethod: ControlMethodOption): boolean {
  return controlMethod === ControlMethodOption.LotNumber || controlMethod === ControlMethodOption.SerialNumber
}

/**
 * Generates a new Lot/Serial table row with the provided defaults
 * @param location
 * @param lotSerialNumber
 * @param quantity
 * @param quantityBefore
 */
export function generateLotSerialItemPlaceholder(
  location: ItemSiteDistributionOption | null,
  lotSerialNumber: LotSerialOption | null,
  quantity: number,
  quantityBefore: number
): LotSerialEntryRow {
  return {
    id: nanoid(),
    location,
    lotSerialNumber,
    expirationDate: lotSerialNumber?.expiry_date ?? null,
    warrantyDate: lotSerialNumber?.warranty_date ?? null,
    comments: null,
    lotserial_characteristics: null,
    notes: '',
    quantity,
    quantityBefore,
    isValid: true,
    touched: false,
  }
}

export function shouldDisplayQtyBeforeColumn(
  { control_method, multiple_location_control }: IItemSite,
  isNegativeQtyToAssign: boolean
): boolean {
  return multiple_location_control || (supportsLotSerial(control_method) && isNegativeQtyToAssign)
}

export function defineAddButtonLabel({ control_method, multiple_location_control }: IItemSite): string {
  if (!multiple_location_control) {
    return 'Assign Lot/Serial #'
  }
  if (control_method === ControlMethodOption.LotNumber || control_method === ControlMethodOption.SerialNumber) {
    return NewLotSerialButtonLabel.LocationAndLotSerial
  }
  return NewLotSerialButtonLabel.Location
}

function shouldDisableLocation(autoDistributedLocation: boolean | undefined, automaticLocationIsApplied: boolean): boolean {
  if (!autoDistributedLocation) {
    return false
  }
  return automaticLocationIsApplied
}

function shouldDisableQuantity(
  { multiple_location_control, auto_distr_location, auto_ls_number, control_method }: IItemSite,
  isNegativeQtyToAssign: boolean,
  automaticLocationIsApplied: boolean
): boolean {
  // We should disable the column in case of auto applied location
  if (multiple_location_control && auto_distr_location && automaticLocationIsApplied) {
    return true
  }
  // We should disable the column in case of auto generated lot/serial numbers and positive Qty to Assign
  return supportsLotSerial(control_method) && !!auto_ls_number && !isNegativeQtyToAssign
}

export function defineTableColumns(
  itemSite: IItemSite | null,
  locationOptions: ItemSiteDistributionOption[],
  lotSerialOptions: LotSerialOption[],
  isNegativeQtyToAssign: boolean,
  autoDistributedLocationIsApplied: boolean,
  onAction: (item: LotSerialEntryRow, action: LotSerialAction) => void,
  quantityDecimalScale: number
): Array<IXtTableColumnEditable<LotSerialEntryRow>> {
  if (!itemSite) {
    return []
  }
  const { control_method, multiple_location_control, auto_distr_location, auto_ls_number, perishable, require_warranty } = itemSite

  const columns: IXtTableColumnEditable<LotSerialEntryRow>[] = []

  if (multiple_location_control) {
    columns.push({
      id: LotSerialField.Location,
      field: LotSerialField.Location,
      type: XtEditableCellType.Select,
      headerName: lotSerialEntryLabels[LotSerialField.Location],
      flex: '1 0 200px',
      width: 200,
      unsortable: true,
      options: locationOptions,
      disabled: shouldDisableLocation(auto_distr_location, autoDistributedLocationIsApplied),
    } as IXtTableColumnSelectEditable<LotSerialEntryRow, ItemSiteDistributionOption>)
  }

  if (supportsLotSerial(control_method)) {
    columns.push({
      id: LotSerialField.LotSerialNumber,
      type: isNegativeQtyToAssign ? XtEditableCellType.Select : XtEditableCellType.SelectCreatable,
      field: LotSerialField.LotSerialNumber,
      headerName: lotSerialEntryLabels[LotSerialField.LotSerialNumber],
      flex: '1 0 210px',
      width: 210,
      unsortable: true,
      options: lotSerialOptions,
      disabled: auto_ls_number !== null && !isNegativeQtyToAssign,
    } as IXtTableColumnSelectEditable<LotSerialEntryRow, LotSerialOption>)

    if (perishable) {
      columns.push({
        id: LotSerialField.ExpirationDate,
        type: XtEditableCellType.Date,
        field: LotSerialField.ExpirationDate,
        headerName: lotSerialEntryLabels[LotSerialField.ExpirationDate],
        flex: '1 0 190px',
        width: 190,
        unsortable: true,
        disabled: isNegativeQtyToAssign || !perishable,
      })
    }
    if (require_warranty) {
      columns.push({
        id: LotSerialField.WarrantyDate,
        type: XtEditableCellType.Date,
        field: LotSerialField.WarrantyDate,
        headerName: lotSerialEntryLabels[LotSerialField.WarrantyDate],
        flex: '1 0 190px',
        width: 190,
        unsortable: true,
        disabled: isNegativeQtyToAssign || !require_warranty,
      })
    }
  }

  if (shouldDisplayQtyBeforeColumn(itemSite, isNegativeQtyToAssign)) {
    columns.push({
      id: LotSerialField.QuantityBefore,
      type: XtEditableCellType.Input,
      field: LotSerialField.QuantityBefore,
      headerName: lotSerialEntryLabels[LotSerialField.QuantityBefore],
      flex: '1 0 130px',
      width: 130,
      unsortable: true,
      disabled: true,
    })
  }

  columns.push({
    id: LotSerialField.Quantity,
    type: XtEditableCellType.Number,
    field: LotSerialField.Quantity,
    headerName: lotSerialEntryLabels[LotSerialField.Quantity],
    flex: '1 0 120px',
    width: 120,
    unsortable: true,
    decimalScale: quantityDecimalScale,
    disabled: shouldDisableQuantity(itemSite, isNegativeQtyToAssign, autoDistributedLocationIsApplied),
  } as IXtTableColumnNumberEditable<LotSerialEntryRow>)

  if (supportsLotSerial(control_method)) {
    columns.push(
      {
        id: 'characteristics',
        type: XtEditableCellType.Custom,
        headerName: 'Characteristics',
        flex: '1 0 160px',
        width: 160,
        unsortable: true,
        renderCell: (item) => (
          <IconButton disabled={!item[LotSerialField.LotSerialNumber]} onClick={() => onAction(item, LotSerialAction.Characteristics)}>
            <SvgIcon iconId={SvgIconIds.CHARACTERISTICS} />
          </IconButton>
        ),
      },
      {
        id: 'documents',
        type: XtEditableCellType.Custom,
        headerName: 'Documents',
        flex: '1 0 120px',
        width: 120,
        renderCell: (item) => (
          <IconButton disabled={!item[LotSerialField.LotSerialNumber]} onClick={() => onAction(item, LotSerialAction.Documents)}>
            <SvgIcon iconId={SvgIconIds.DOCUMENTS} />
          </IconButton>
        ),
      }
    )
  }

  return columns
}

/**
 * Defines if we should hide Delete action for Lot/Serial table.
 * Generally, Delete action is hidden in case of automatic Location or Lot/Serial control.
 * @param controlMethod
 * @param autoLotSerial
 * @param isQtyToAssignNegative
 * @param autoDistributedLocationIsApplied
 */
export function shouldHideDeleteAction(
  controlMethod: ControlMethodOption,
  autoLotSerial: boolean,
  isQtyToAssignNegative: boolean,
  autoDistributedLocationIsApplied: boolean
): boolean {
  if (supportsLotSerial(controlMethod) && !isQtyToAssignNegative && autoLotSerial) {
    return true
  }
  return autoDistributedLocationIsApplied
}

export function defineTableActions(
  itemSite: IItemSite | null,
  isQtyToAssignNegative: boolean,
  autoDistributedLocationIsApplied: boolean
): IXtTableCellAction[] {
  const actions: IXtTableCellAction[] = []

  if (!itemSite) {
    return actions
  }
  const { control_method, auto_ls_number } = itemSite

  if (supportsLotSerial(control_method) && !isQtyToAssignNegative) {
    actions.push(lotSerialEditAction)
  }
  if (!shouldHideDeleteAction(control_method, !!auto_ls_number, isQtyToAssignNegative, autoDistributedLocationIsApplied)) {
    actions.push(lotSerialDeleteAction)
  }
  return actions
}

export function convertToLotSerialOption({ lotserial_number }: ILotSerialNumber): LotSerialOption {
  return { id: lotserial_number, label: lotserial_number, lotserial: lotserial_number }
}
