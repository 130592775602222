import { IXtTableColumn } from '../../../../components/table/table-head/table-head.types'
import { IRoutingTableItem } from './routing-items-dialog.types'

export const routingDialogColumns: IXtTableColumn<IRoutingTableItem>[] = [
  {
    id: 'sequence_number',
    field: 'sequence_number',
    headerName: '#',
    width: 100,
  },
  {
    id: 'standard_operation',
    field: 'standard_operation',
    headerName: 'Std. Oper.',
    width: 150,
  },
  {
    id: 'description',
    field: 'description',
    headerName: 'Description',
    flex: 1,
  },
]
