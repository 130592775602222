import { nanoid } from 'nanoid'
import { InventoryUomOption, IUomConversion } from 'core/services/uom.service'
import { TransferListOption } from 'components/controls/xt-transfer-list/xt-transfer-list.types'
import { convertNumberWithThousandSeparatorAndDigits } from 'common/utils/utils'
import { IConversionsTableItem } from './conversions-tab.types'
import { availableTypes } from './conversions-tab.constants'

export function calculateRatio(fromValue: number | undefined, toValue: number | undefined): string {
  const conversionFromValue = convertNumberWithThousandSeparatorAndDigits(fromValue ?? 0, 5)
  const conversionToValue = convertNumberWithThousandSeparatorAndDigits(toValue ?? 0, 5)

  return `${conversionFromValue}/${conversionToValue}`
}

export function convertToTableItem(uomConversion: IUomConversion): IConversionsTableItem {
  return {
    ...uomConversion,
    id: nanoid(),
    conversions_where_used: `${uomConversion.uom_from}/${uomConversion.uom_to}`,
    uom_types_table: (uomConversion.uom_types ?? []).join(','),
    ratio: calculateRatio(uomConversion.from_value, uomConversion.to_value),
  }
}

export function convertToUomConversions(tableData: IConversionsTableItem[]): IUomConversion[] {
  return tableData.map(({ uom_to, to_value, uom_from, from_value, active, global, fractional, uom_types }) => ({
    uom_to,
    to_value,
    uom_from,
    from_value,
    fractional,
    global,
    active,
    uom_types,
  }))
}

export function convertToUom(uom: InventoryUomOption, uomConversions: IConversionsTableItem[]): InventoryUomOption {
  return {
    ...uom,
    uom_conversions: convertToUomConversions(uomConversions),
  }
}

export function convertToTransferList(uomConversions: IUomConversion[] | undefined): TransferListOption[] {
  if (!uomConversions) {
    return availableTypes
  }
  const availableTypesMap = new Map<string, TransferListOption>(availableTypes.map((type) => [type.id, type]))
  uomConversions.forEach(({ uom_types }) => {
    uom_types?.forEach((type) => {
      if (type === 'Selling') {
        return
      }
      availableTypesMap.delete(type)
    })
  })

  return [...availableTypesMap.values()]
}

export function calculateUsedUom(uomConversions: IUomConversion[] | undefined, uomName: string | undefined): Set<string> {
  const usedUom: Set<string> = new Set()

  if (!uomConversions || !uomName) {
    return usedUom
  }

  uomConversions.forEach(({ uom_to, uom_from }) => {
    usedUom.add(uom_to)
    usedUom.add(uom_from)
  })

  usedUom.delete(uomName)

  return usedUom
}
