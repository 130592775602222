import * as React from 'react'
import { FC } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { LoaderMessage, xsMq } from 'common/constants'
import { cls } from 'common/utils/utils'
import { useTable } from 'common/hooks/useTable'
import { XtList } from 'components/list/list'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { useCoreModule } from 'core/core-module-hook'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { useInventoryHistoryModule } from 'inventory/inventory-history-section/inventory-history-module-hook'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import * as styles from './inventory-history-by-lot-serial-list.module.scss'
import { historyByLotSerialPageTitle, inventoryHistoryByLocationColumns } from './inventory-history-by-lot-serial-list.constansts'
import { InventoryHistoryByLotSerialListForm } from './inventory-history-by-lot-serial-list-form/inventory-history-by-lot-serial-list-form'

export const InventoryHistoryByLotSerialList: FC = () => {
  const isMobile = useMediaQuery(xsMq)
  const { CostingItemCostsUtilsService } = useInventoryHistoryModule()
  const { ErrorHandler } = useCoreModule()
  useDocumentTitle(historyByLotSerialPageTitle)
  const {
    state: { data, loading, sortOptions, filters },
    pagination,
    sort,
    filter,
  } = useTable({}, CostingItemCostsUtilsService.getInventoryHistoryByLotSerial, undefined, PageFilterMapping.HistoryLot)

  const print = async (): Promise<void> => {
    try {
      await CostingItemCostsUtilsService.printInventoryHistoryByLotSerial(filters)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  return (
    <div className={cls('xt-list-page-container', styles.listContent)}>
      <div className={cls('xt-page-header', styles.headerWithPrint)}>
        <h1 className="xt-page-title">Inventory History by Lot/Serial #</h1>
        <XtResponsiveButton
          showIconOnDesktopMode={false}
          icon={SvgIconIds.PRINT}
          label="Print"
          disabled={loading || !data.length}
          onClick={print}
        />
      </div>
      <InventoryHistoryByLotSerialListForm loading={loading} onChange={filter} filters={filters} />
      <XtList
        isMobile={isMobile}
        pagination={pagination}
        loading={loading}
        data={data}
        columns={inventoryHistoryByLocationColumns}
        sortOptions={sortOptions}
        onColumnHeaderClick={sort}
        loadMessage={LoaderMessage.InventoryHistoryByLot}
      />
    </div>
  )
}
