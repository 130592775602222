import { AddToast, RemoveAllToasts, RemoveToast } from 'react-toast-notifications'
import { appendPeriod } from 'common/utils/string.utils'

interface IToastMethods {
  addToast: AddToast
  removeAllToasts: RemoveAllToasts
  removeToast: RemoveToast
}

export interface IToastService {
  initialize(methods: IToastMethods): void
  showSuccess(message: string, id?: string): void
  showError(message: string, id?: string): void
  removeToast(id: string): void
  removeAllToasts(): void
}

export class ToastService implements IToastService {
  private methods: IToastMethods | null = null

  public initialize(methods: IToastMethods): void {
    this.methods = methods
  }

  public showSuccess(message: string, id?: string): void {
    if (this.methods) {
      this.methods.addToast(message ? appendPeriod(message) : 'Success', id ? { id, appearance: 'success' } : { appearance: 'success' })
    }
  }

  public showError(message: string, id?: string): void {
    if (this.methods) {
      this.methods.addToast(message ? appendPeriod(message) : 'Error', id ? { id, appearance: 'error' } : { appearance: 'error' })
    }
  }

  public removeToast(id: string): void {
    if (this.methods) {
      this.methods.removeToast(id)
    }
  }

  public removeAllToasts(): void {
    if (this.methods) {
      this.methods.removeAllToasts()
    }
  }
}
