import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from 'common/common.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IHttpClient } from 'core/services/http-client'
import { ICharacteristic, CharacteristicPayload } from './characteristics.types'
import { isEmptyResponse } from '../core/core.utils'

export interface ICharacteristicService {
  getAll(
    { limit, page }: IPaginationParams,
    usedOnFilter?: string | null,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<ICharacteristic>>
  get(characteristic: string): Promise<ICharacteristic>
  delete(name: string): Promise<string>
  update(data: CharacteristicPayload): Promise<string>
  rename(characteristic: string, name: string): Promise<string>
  create(data: CharacteristicPayload): Promise<string>
}

export class CharacteristicsService implements ICharacteristicService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getAll(
    paginationParams: IPaginationParams,
    usedOnFilter: string | null,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<ICharacteristic>> {
    const params = prepareRequestParams(paginationParams, { used_on: usedOnFilter }, sortOptions)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ICharacteristic>>('/characteristic', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(characteristic: string): Promise<ICharacteristic> {
    const { data } = await this.apiClient.get<IResponse<ICharacteristic>>(`/characteristic/${characteristic}`)
    if (isEmptyResponse(data)) {
      throw new Error(`Characteristic ${characteristic} is not found.`)
    }
    return data.data
  }

  public async delete(name: string): Promise<string> {
    const body = { data: { name } }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post('/characteristic/delete', body)
    return message
  }

  public async update(data: CharacteristicPayload): Promise<string> {
    const body = { data }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post('/characteristic/update', body)
    return message
  }

  public async rename(characteristic: string, name: string): Promise<string> {
    const body = { data: { name } }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post(`/characteristic/${characteristic}/rename`, body)
    return message
  }

  public async create(data: CharacteristicPayload): Promise<string> {
    const body = { data }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post('/characteristic/create', body)
    return message
  }
}
