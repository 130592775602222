import React, { FC, useEffect } from 'react'
import { CardHeader } from '@material-ui/core'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { ItemDetailsFormField, ItemWeightFormLabel } from '../../../../item-details.types'
import * as styles from '../item-details-cards.module.scss'
import { IItemWeightCard } from './item-weight-card.types'

const maxValue = 10 ** 14 - 1

export const ItemWeightCard: FC<IItemWeightCard> = ({ formMethods, disabled, shouldClear }) => {
  const { control, setValue } = formMethods

  useEffect(() => {
    if (shouldClear) {
      setValue(ItemDetailsFormField.ProductWeight, null, { shouldDirty: true })
      setValue(ItemDetailsFormField.Packaging, null, { shouldDirty: true })
    }
  }, [shouldClear])

  return (
    <div className={styles.cardContainer}>
      <CardHeader title="Weight" className={styles.cardHeader} />
      <div className={styles.cardContent}>
        <DecimalFormField
          name={ItemDetailsFormField.ProductWeight}
          control={control}
          label={ItemWeightFormLabel.Product}
          maxValue={maxValue}
          fixedDecimalScale={2}
          allowNegative={false}
          disabled={disabled}
        />
        <DecimalFormField
          name={ItemDetailsFormField.Packaging}
          control={control}
          label={ItemWeightFormLabel.Packaging}
          maxValue={maxValue}
          fixedDecimalScale={2}
          allowNegative={false}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
