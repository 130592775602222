import { BehaviorSubject, defer, merge, Observable, Subject } from 'rxjs'
import { filter, map, pairwise } from 'rxjs/operators'
import { FieldValues } from 'react-hook-form/dist/types'
import { ValidationError } from 'yup'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import * as Yup from 'yup'
import { MutableRefObject } from 'react'
import { Resolver } from 'react-hook-form/dist/types/resolvers'
import { UseFormGetValues } from 'react-hook-form/dist/types/form'
import { deepEqual } from 'fast-equals'
import { IFormState, IFormStateBase, IFormStateChanges, IFormStateChangesNullable } from './form.types'

function convertToFormStateObservable<TValues>(
  subject: BehaviorSubject<IFormStateBase>,
  defaultState: IFormStateChanges<TValues>
): Observable<IFormStateChanges<TValues>> {
  return subject.asObservable().pipe(
    pairwise(),
    filter(([prev, current]) => !deepEqual(prev, current)),
    map(([_, { isValid, isDirty }]) => {
      defaultState.state.isValid = isValid
      defaultState.state.isDirty = isDirty
      return { ...defaultState }
    })
  )
}

function convertToFormDataStateObservable<TValues>(
  subject: Subject<TValues>,
  defaultState: IFormStateChangesNullable<TValues>
): Observable<IFormStateChanges<TValues>> {
  return subject.asObservable().pipe(
    map((data) => {
      defaultState.data = data
      return { ...defaultState } as IFormStateChanges<TValues>
    })
  )
}

function convertToFormBooleanObservable<TValues>(
  subject: BehaviorSubject<boolean>,
  key: keyof IFormState,
  defaultState: IFormStateChanges<TValues>
): Observable<IFormStateChanges<TValues>> {
  return subject.asObservable().pipe(
    pairwise(),
    filter(([prev, current]) => prev !== current),
    map(([_, value]) => {
      defaultState.state[key] = value
      return { ...defaultState }
    })
  )
}

export function buildFormStateObservable<TValues>(
  stateSubject: BehaviorSubject<IFormStateBase>,
  touchedSubject: BehaviorSubject<boolean>,
  valuesSubject: Subject<TValues>,
  fieldValidatorsShownSubject: BehaviorSubject<boolean>,
  getValues: UseFormGetValues<TValues>
): Observable<IFormStateChanges<TValues>> {
  return defer(() => {
    const formChanges: IFormStateChanges<TValues> = {
      data: getValues() as TValues,
      state: { ...stateSubject.value, touched: touchedSubject.value, fieldValidatorsShown: fieldValidatorsShownSubject.value },
    }

    return merge(
      convertToFormStateObservable(stateSubject, formChanges),
      convertToFormBooleanObservable(touchedSubject, 'touched', formChanges),
      convertToFormDataStateObservable(valuesSubject, formChanges),
      convertToFormBooleanObservable(fieldValidatorsShownSubject, 'fieldValidatorsShown', formChanges)
    )
  })
}

function resolveErrors<TFormValues extends FieldValues>(errors: ValidationError[]): FieldErrors<TFormValues> {
  return errors.reduce(
    (allErrors, currentError) => ({
      ...allErrors,
      [currentError.path]: { type: currentError.type ?? 'validation', message: currentError.message },
    }),
    {}
  )
}

export function validationResolver<TFormValues extends FieldValues>(
  validationSchema: Yup.ObjectSchema,
  disabledRef: MutableRefObject<boolean>
): Resolver<TFormValues> {
  return async (data) => {
    try {
      const disabled = disabledRef.current

      if (disabled) {
        return { values: {}, errors: {} }
      }

      const values = await validationSchema.validate(data, {
        abortEarly: false,
      })

      return {
        values: values ?? {},
        errors: {},
      }
    } catch (error) {
      if (!(error instanceof ValidationError)) {
        throw new Error('Invalid Yup error.')
      }
      return {
        values: {},
        errors: resolveErrors(error.inner),
      }
    }
  }
}
