import axios from 'axios'
import { globalConstants } from '../../common/constants'

// Set the base URL globally so each component only has to
// request their endpoint, such as /task, to avoid embedding
// the URL in a bunch of places
export const HttpClient = axios.create({
  baseURL: globalConstants.apiUrl,
})

export const httpClientToken = 'HttpClient'

export type IHttpClient = typeof HttpClient
