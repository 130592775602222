import { Overwrite } from 'utility-types'
import { ISalesSharedModuleServices, SalesSharedModule, SalesSharedModuleDefinition } from './sales-shared.module'
import { LazyModulesService } from '../core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from '../core/injector/injector.service'
import { ISalesOrdersService } from './sales-orders.service'
import { IQuotesService } from './quotes.service'
import { salesSharedUtilsServiceToken, quotesServiceToken, salesOrdersServiceToken } from './sales-shared.constants'
import { ISalesSharedUtilsService } from './sales-shared-utils.service'
import { moduleHookResolver } from '../core/react-lazy-modules/react-lazy-modules.utils'

export function resolveSalesSharedModule(): Overwrite<SalesSharedModuleDefinition, ISalesSharedModuleServices> {
  const module = LazyModulesService.resolveModule<SalesSharedModuleDefinition>(SalesSharedModule)
  const SalesOrdersService = Injector.resolve<ISalesOrdersService>(salesOrdersServiceToken)
  const QuotesService = Injector.resolve<IQuotesService>(quotesServiceToken)
  const SalesSharedUtilsService = Injector.resolve<ISalesSharedUtilsService>(salesSharedUtilsServiceToken)

  return { ...module, SalesOrdersService, QuotesService, SalesSharedUtilsService }
}

export const useSalesSharedModule = moduleHookResolver(resolveSalesSharedModule)
