import { PermissionGuard } from 'auth/permission.guard'
import { UserPermission } from 'auth/auth.types'
import { companiesRoutePath } from 'companies/companies.constants'

export class AccountsGuard extends PermissionGuard {
  public redirectTo = companiesRoutePath
  public permissions = [
    UserPermission.ViewAllCRMAccounts,
    UserPermission.ViewPersonalCRMAccounts,
    UserPermission.MaintainAllCRMAccounts,
    UserPermission.MaintainPersonalCRMAccounts,
  ]
}
