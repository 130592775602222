import React, { FC } from 'react'
import { cls } from 'common/utils/utils'
import { IXtInfoValues } from './xt-info-values.types'

export const XtInfoValues: FC<IXtInfoValues> = ({ values, classes, hidden }) => (
  <div hidden={hidden} className={classes?.values}>
    {values.map(({ label, value, hidden: fieldHidden }) => (
      <div key={`${label}-${value}`} className={cls('xt-summary-content', classes?.content)} hidden={fieldHidden}>
        <span className={cls('xt-summary-label', classes?.label)}>{label}: &nbsp;</span>
        <span className={cls('xt-text-truncated', classes?.value)} title={value ? value : undefined}>
          {value}
        </span>
      </div>
    ))}
  </div>
)
