import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { numberConverter } from 'common/utils/utils'
import { booleanColumnConverter } from 'common/utils/table.utils'
import { dateConverter } from 'common/utils/date.utils'
import { IItemCostsHistoryTableRow } from '../item-costs.types'
import { IHistoryFilters } from './history-list.types'

export const initialHistoryFilters: IHistoryFilters = { itemNumber: null }

export const oldCurrencyFieldName = 'oldCurrency'
export const newCurrencyFieldName = 'newCurrency'

export const historyColumns: IXtTableColumn<IItemCostsHistoryTableRow>[] = [
  {
    id: 'element',
    field: 'element',
    headerName: 'Element',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'lowlevel',
    field: 'lowlevel',
    headerName: 'Lower',
    flex: '1 0 100px',
    width: 100,
    converter: booleanColumnConverter,
  },
  {
    id: 'type',
    field: 'type',
    headerName: 'Type',
    flex: '1 0 120px',
    width: 120,
  },
  {
    id: 'datetime',
    field: 'datetime',
    headerName: 'Time',
    flex: '1 0 220px',
    width: 220,
    converter: (value) => dateConverter(value, true),
  },
  {
    id: 'username',
    field: 'username',
    headerName: 'User',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'old_cost',
    field: 'old_cost',
    headerName: 'Old',
    flex: '1 0 160px',
    width: 160,
    converter: (value) => numberConverter(value, 4),
  },
  {
    id: oldCurrencyFieldName,
    field: oldCurrencyFieldName,
    headerName: 'Currency',
    flex: '1 0 120px',
    width: 120,
  },
  {
    id: 'new_cost',
    field: 'new_cost',
    headerName: 'New',
    flex: '1 0 160px',
    width: 160,
    converter: (value) => numberConverter(value, 4),
  },
  {
    id: newCurrencyFieldName,
    field: newCurrencyFieldName,
    headerName: 'Currency',
    flex: '1 0 140px',
    width: 140,
  },
]

export const historyListPageTitle = 'xTuple - Item Costs History'
