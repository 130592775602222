import { Overwrite } from 'utility-types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { ShipmentDataOption } from 'shipments/shipments.types'
import { IPrintShippingFormSharedState, ShippingFormOrderOption, ShippingPrintFormSharedField } from '../print-dialogs.types'

export interface IPrintShippingForm {
  onClose: VoidFunction
  shipmentNumber?: string
  shippingForm?: string
}

export enum ShippingPrintFormField {
  ShippingForm = 'shipping_form',
}

export enum ShippingPrintFormLabel {
  ShippingForm = 'Shipping Form',
}

export interface IPrintShippingFormState extends IPrintShippingFormSharedState {
  [ShippingPrintFormField.ShippingForm]: IXtAutocompleteOption | null
}

export type PrintShippingFormStateFilled = Overwrite<
  IPrintShippingFormState,
  {
    [ShippingPrintFormSharedField.Order]: ShippingFormOrderOption
    [ShippingPrintFormSharedField.ShipmentNumber]: ShipmentDataOption
    [ShippingPrintFormField.ShippingForm]: IXtAutocompleteOption
  }
>
