import { getType } from 'mime'
import { AttachedDocumentTargetType, IAttachedDocument, IAttachedDocumentWithFile } from 'documents/documents.types'
import { AttachedDocumentAction } from './documents.types'
import { IXtTableCellAction } from '../../components/table/table-cell-actions/table-cell-actions'
import { defaultDocumentState } from './documents.constants'

export function convertFileListToDocuments(files: FileList): IAttachedDocumentWithFile[] {
  const id = Date.now()
  return Array.from(files).map((file, index) => ({ ...defaultDocumentState, file, id: id + index, name: file.name }))
}

export function isPreviewedType(type: string | null): boolean {
  return !!type && type.startsWith('image/')
}

export function isPreviewedDocument(item: IAttachedDocument): boolean {
  if (!item) {
    return false
  }

  const type = item.file ? item.file.type : getType(item.file_link)
  return isPreviewedType(type)
}

export function getDocumentActions(item: IAttachedDocument, actions: IXtTableCellAction[]): IXtTableCellAction[] {
  if (!item) {
    return actions
  }

  if (item.target_type !== AttachedDocumentTargetType.File) {
    return actions.filter((action: IXtTableCellAction) => action.name === AttachedDocumentAction.Delete)
  }

  if (!isPreviewedDocument(item)) {
    return actions.filter((action: IXtTableCellAction) => action.name !== AttachedDocumentAction.Preview)
  }

  return actions
}
