import React from 'react'
import { IMaskInput } from 'react-imask'
import { inputMaskDefinitions } from 'common/constants'

export interface IXtInputMaskProps {
  name: string
  onChange(event: { target: { name: string; value: string } }): void
  mask: string
  placeholder: string
}

export const XtInputMask = React.forwardRef<unknown, IXtInputMaskProps>(({ name, onChange, mask, placeholder, ...other }, ref) => (
  <IMaskInput
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...other}
    // @ts-ignore // TODO remove when TS issue is fixed: https://github.com/uNmAnNeR/imaskjs/issues/554
    mask={mask}
    definitions={inputMaskDefinitions}
    unmask
    inputRef={ref}
    onAccept={(value: string) => onChange({ target: { name, value } })}
    placeholder={placeholder}
  />
))
