import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { AccountDetailsField, IAccountsDetails } from './account-details.types'
import { IAccount } from '../accounts.types'

export function defineAccountDetailsFormState(accountData: IAccount | null): IAccountsDetails {
  return {
    [AccountDetailsField.AccountNumber]: accountData?.number ?? '',
    [AccountDetailsField.AccountName]: accountData?.name ?? '',
    [AccountDetailsField.Active]: accountData?.active ?? false,
    [AccountDetailsField.Parent]: defineAutocompleteOption(accountData?.parent),
    [AccountDetailsField.Owner]: defineAutocompleteOption(accountData?.owner),
    [AccountDetailsField.Type]: accountData?.type ?? '',
    [AccountDetailsField.Addresses]: accountData?.addresses ?? null,
    [AccountDetailsField.AssignedUser]: accountData?.assigned_user ?? null,
  }
}
