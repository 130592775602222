import { FilterType, IFilter } from 'components/pagefilter/filter/filter.types'
import { IDocumentsUtilsService } from 'documents/documents-utils.service'
import { ProspectFiltersFieldName, ProspectFiltersLabel } from '../services/prospects-services.types'

export function resolveFilters({ loadDocumentOppSourceOptions, loadUserOptions }: IDocumentsUtilsService): IFilter[] {
  return [
    {
      type: FilterType.Text,
      label: ProspectFiltersLabel.ProspectNumber,
      fieldName: ProspectFiltersFieldName.ProspectNumber,
    },
    {
      type: FilterType.Text,
      label: ProspectFiltersLabel.ProspectName,
      fieldName: ProspectFiltersFieldName.ProspectName,
    },
    {
      type: FilterType.Autocomplete,
      label: ProspectFiltersLabel.Owner,
      fieldName: ProspectFiltersFieldName.Owner,
      autocompleteProps: {
        loadOptions: loadUserOptions,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: ProspectFiltersLabel.AssignedTo,
      fieldName: ProspectFiltersFieldName.AssignedTo,
      autocompleteProps: {
        loadOptions: loadUserOptions,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: ProspectFiltersLabel.Source,
      fieldName: ProspectFiltersFieldName.Source,
      autocompleteProps: {
        loadOptions: loadDocumentOppSourceOptions,
      },
    },
    {
      type: FilterType.Text,
      label: ProspectFiltersLabel.ContactName,
      fieldName: ProspectFiltersFieldName.ContactName,
    },
    {
      type: FilterType.Text,
      label: ProspectFiltersLabel.City,
      fieldName: ProspectFiltersFieldName.City,
    },
    {
      type: FilterType.Text,
      label: ProspectFiltersLabel.State,
      fieldName: ProspectFiltersFieldName.State,
    },
  ]
}
