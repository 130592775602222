import { UserPermission } from 'auth/auth.types'
import { PermissionGuard } from 'auth/permission.guard'
import { homeRoutePath } from 'home/home.constants'

export class InventoryGuard extends PermissionGuard {
  public redirectTo = homeRoutePath
  public permissions = [
    UserPermission.ViewPurchaseOrders,
    UserPermission.PrintPurchaseOrders,
    UserPermission.MaintainPurchaseOrders,
    UserPermission.ViewPurchaseRequests,
    UserPermission.MaintainPurchaseRequests,
    UserPermission.ViewItemSources,
    UserPermission.MaintainItemSources,
  ]
}
