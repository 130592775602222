import { dateToServerFormat } from 'common/utils/date.utils'
import { defineAutocompleteOption, isAutocompleteValue } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { CharacteristicType, CharacteristicValue, IAppliedCharacteristicNew, ICharacteristic } from '../characteristics.types'
import { CharacteristicDialogFormField, ICharacteristicFormState } from './characteristic-dialog.types'
import { defineCharacteristicOption } from '../characteristics.utils'
import { defaultCharacteristicFormValues } from './characteristic-dialog.constants'

function convertCharacteristicValue(value: CharacteristicValue): CharacteristicValue {
  if (value instanceof Date) {
    return dateToServerFormat(value)
  }
  if (isAutocompleteValue(value)) {
    return value.id
  }
  return value
}

export const convertCharacteristicFormValues = (data: ICharacteristicFormState): IAppliedCharacteristicNew => ({
  characteristic: data?.characteristic?.name ?? '',
  characteristic_value: convertCharacteristicValue(data.characteristic_value),
  characteristic_group: data?.characteristic?.characteristic_group ?? '',
  default_characteristic: false,
})

function defineCharacteristicValue(characteristicType: CharacteristicType, value: CharacteristicValue): CharacteristicValue {
  if (characteristicType === CharacteristicType.List) {
    return defineAutocompleteOption(value as string | null)
  }

  if (characteristicType === CharacteristicType.Date) {
    if (typeof value == 'string') {
      const dateObj = new Date(value as Date | string)
      dateObj.setMinutes(dateObj.getMinutes() + dateObj.getTimezoneOffset())
      return dateObj
    }
    return new Date(value as Date | string)
  }

  return value
}

export function defineCharacteristicFormState(
  characteristic: ICharacteristic | null,
  value: CharacteristicValue
): ICharacteristicFormState {
  if (!characteristic) {
    return defaultCharacteristicFormValues
  }
  return {
    [CharacteristicDialogFormField.Characteristic]: defineCharacteristicOption(characteristic),
    [CharacteristicDialogFormField.Value]: defineCharacteristicValue(characteristic.characteristic_type, value),
  }
}
