import React, { useMemo, useState } from 'react'
import { List, ListItem } from '@material-ui/core'
import { cls } from 'common/utils/utils'
import { XtButton } from '../../buttons/xt-button/xt-button'
import {
  ISelectedOptionState,
  IXtTransferListParams,
  ListOptionType,
  TransferListAction,
  TransferListOption,
} from './xt-transfer-list.types'
import { SvgIconIds } from '../../svg-icon/svg-icon.types'
import * as styles from './xt-transfer-list.module.scss'
import { IconPosition } from '../../buttons/xt-button/xt-button.types'
import {
  prepareDataForAddAction,
  prepareDataForAddAllAction,
  prepareDataForRemoveAction,
  prepareDataForRemoveAllAction,
} from './xt-transfer-list.utils'

function renderOptions<Option extends TransferListOption>(
  options: Option[],
  chosenValue: Option | undefined,
  onClick: (value: Option) => void,
  onDoubleClick: (value: Option) => void,
  disabled: boolean
): React.ReactElement[] {
  return options.map((value) => (
    <ListItem
      disabled={disabled || value.disabled}
      className={styles.listItemContainer}
      selected={chosenValue?.id === value.id}
      key={value.id}
      button
      onClick={() => onClick(value)}
      onDoubleClick={() => onDoubleClick(value)}
    >
      <div className={styles.listItemLabel} title={value.label}>
        {value.label}
      </div>
    </ListItem>
  ))
}

function TransferList<Option extends TransferListOption>({
  availableOptions,
  selectedOptions,
  onChange,
  disabled = false,
  className,
  error,
  availableOptionsLabel = 'Available Types',
  selectedOptionsLabel,
  removeButtonLabel = 'Remove',
  allMode,
}: IXtTransferListParams<Option>): React.ReactElement {
  const [selectedOption, setSelectedOption] = useState<ISelectedOptionState<Option> | null>(null)

  const handleAction: (action: TransferListAction, option?: Option) => void = (action, option) => {
    if (action === TransferListAction.AddAll) {
      const data = prepareDataForAddAllAction(availableOptions, selectedOptions)
      onChange(data)
    }

    if (action === TransferListAction.RemoveAll) {
      const data = prepareDataForRemoveAllAction(availableOptions, selectedOptions)
      onChange(data)
    }

    if (!option) {
      return
    }

    if (action === TransferListAction.Add) {
      const data = prepareDataForAddAction(availableOptions, selectedOptions, option)
      onChange(data)
    }

    if (action === TransferListAction.Remove) {
      const data = prepareDataForRemoveAction(availableOptions, selectedOptions, option)
      onChange(data)
    }
    setSelectedOption(null)
  }

  const handleListItemClick = (listDirection: ListOptionType) => (option: Option) => {
    setSelectedOption({ chosenValue: option, direction: listDirection })
  }

  const handleListItemDoubleClick = (listDirection: ListOptionType) => (option: Option) => {
    const action = listDirection === ListOptionType.Available ? TransferListAction.Add : TransferListAction.Remove
    handleAction(action, option)
  }

  const handleButtonClick: (action: TransferListAction) => VoidFunction = (action) => () =>
    handleAction(action, selectedOption?.chosenValue)

  const removeAllButtonDisabled = useMemo<boolean>(
    () => !selectedOptions.length || selectedOptions.every(({ disabled: disabledOption }) => disabledOption),
    [selectedOptions]
  )

  return (
    <div className={cls(styles.xtTransferListContainer, className)}>
      <div className={styles.listContainer}>
        <p className={styles.transferListLabel}>{availableOptionsLabel}</p>
        <List className={styles.list}>
          {renderOptions(
            availableOptions,
            selectedOption?.chosenValue,
            handleListItemClick(ListOptionType.Available),
            handleListItemDoubleClick(ListOptionType.Available),
            disabled
          )}
        </List>
      </div>
      <div className={styles.controlButtonsContainer}>
        <div className={styles.controlButton}>
          <XtButton
            label="Add"
            disabled={disabled || !selectedOption || selectedOption.direction !== ListOptionType.Available}
            onClick={handleButtonClick(TransferListAction.Add)}
            icon={SvgIconIds.ARROW_CIRCLE}
            iconPosition={IconPosition.Right}
            iconClass={styles.addIcon}
            className={allMode ? styles.buttonAdd : undefined}
            labelClass={allMode ? styles.addLabel : undefined}
          />

          <XtButton
            hidden={!allMode}
            label="Add All"
            disabled={disabled || !availableOptions.length}
            onClick={handleButtonClick(TransferListAction.AddAll)}
            icon={SvgIconIds.TWO_ARROW_CIRCLE}
            iconPosition={IconPosition.Right}
            iconClass={styles.addIcon}
            className={styles.buttonAdd}
            labelClass={styles.addLabel}
          />
        </div>
        <div className={styles.controlButton}>
          <XtButton
            label={removeButtonLabel}
            disabled={disabled || !selectedOption || selectedOption.direction !== ListOptionType.Selected}
            onClick={handleButtonClick(TransferListAction.Remove)}
            icon={SvgIconIds.ARROW_CIRCLE}
            iconClass={styles.removeIcon}
            className={allMode ? styles.buttonRemove : undefined}
            labelClass={allMode ? styles.removeLabel : undefined}
          />
          <XtButton
            hidden={!allMode}
            label={`${removeButtonLabel} All`}
            disabled={disabled || removeAllButtonDisabled}
            onClick={handleButtonClick(TransferListAction.RemoveAll)}
            icon={SvgIconIds.TWO_ARROW_CIRCLE}
            iconClass={styles.removeIcon}
            className={styles.buttonRemove}
            labelClass={styles.removeLabel}
          />
        </div>
      </div>
      <div className={styles.listContainer}>
        <p className={styles.transferListLabel}>{selectedOptionsLabel}</p>
        <List className={cls(styles.list, error && styles.error)}>
          {renderOptions(
            selectedOptions,
            selectedOption?.chosenValue,
            handleListItemClick(ListOptionType.Selected),
            handleListItemDoubleClick(ListOptionType.Selected),
            disabled
          )}
        </List>
        {error && <span className={styles.errorMessage}>{error}</span>}
      </div>
    </div>
  )
}

export const XtTransferList = React.memo(TransferList) as typeof TransferList // type casting is used to forward Generic types
