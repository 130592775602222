import { GridSortModel } from '@mui/x-data-grid-pro'

export const defaultMultiSortModel: GridSortModel = [{ field: 'seq', sort: 'asc' }]

export const nestedTableClasses = {
  '& .css-fvc8ir-MuiBadge-badge': {
    display: 'none',
  },
  '& .MuiIconButton-root.Mui-disabled': {
    display: 'none',
  },
  '& .css-1o4n2ye': {
    display: 'none',
  },
}

export const tablePaginationRootClasses = { root: 'xt-table-pagination' }

export const defaultHeightBase = 500
