import React, { FC, memo } from 'react'
import { DocumentType, UsedOnValue } from 'documents/documents.types'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { TaskParentType } from 'tasks/tasks.types'
import { UserPermission } from 'auth/auth.types'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCharacteristicsModule } from 'characteristics/characteristics-module-hook'
import { useCommentsModule } from 'comments/comments-module-hook'
import { useTasksModule } from 'tasks/tasks-module-hook'
import { useContactsModule } from 'contacts/contacts-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { convertMode } from 'common/utils/mode.utils'
import { AccountDetailsTabsName, IAccountDetailsTabs } from './account-details-tabs.types'
import { isFilledAccount } from './account-details-tabs.utils'

export const AccountDetailsTabs: FC<IAccountDetailsTabs> = memo(
  ({ account, disabled, isMobile, remarks, characteristics, documents, mode, contactsUpdate$, tasksUpdate$ }) => {
    const { XtDocuments } = useDocumentsModule()
    const { XtCharacteristics } = useCharacteristicsModule()
    const { XtRemarks } = useCommentsModule()
    const { TaskList } = useTasksModule()
    const { ContactList } = useContactsModule()
    const { PermissionsService } = useAuthModule()

    const { isNewMode } = convertMode(mode)

    const canEditContacts = PermissionsService.hasSomePermission([
      UserPermission.MaintainAllContacts,
      UserPermission.MaintainPersonalContacts,
    ])

    const canViewContacts = PermissionsService.hasSomePermission([UserPermission.ViewPersonalContacts, UserPermission.ViewAllContacts])

    const canViewActivities = PermissionsService.hasSomePermission([
      UserPermission.MaintainAllTaskItems,
      UserPermission.MaintainPersonalTaskItems,
      UserPermission.ViewAllTaskItems,
      UserPermission.ViewPersonalTaskItems,
    ])

    const contactsTab = {
      hidden: !canViewContacts && !canEditContacts,
      name: AccountDetailsTabsName.Contacts,
      template: isFilledAccount(account) && (
        <ContactList
          refreshDataObservable={contactsUpdate$}
          account_number={account.account_number}
          account_name={account.account_name}
          isMobile={isMobile}
          disabled={disabled}
          editableByPermissions={account.editable || canEditContacts}
        />
      ),
    }

    const remarksTab = {
      name: AccountDetailsTabsName.Remarks,
      template: (
        <XtRemarks
          source={DocumentType.Account}
          loadMore={remarks.loadMore}
          canLoadMore={remarks.canLoadMore}
          textAreaOnChange={remarks.setNotes}
          textAreaValue={remarks.notes}
          textAreaName="Notes"
          onCommentsUpdate={remarks.updateComment}
          onCommentsSave={remarks.saveComment}
          comments={remarks.comments}
          username={remarks.username}
          disabled={disabled}
        />
      ),
    }

    const characteristicsTab = {
      name: AccountDetailsTabsName.Characteristics,
      template: (
        <XtCharacteristics
          usedOnFilter={UsedOnValue.Account}
          disabled={disabled}
          onCreate={characteristics.createCharacteristic}
          onUpdate={characteristics.updateCharacteristic}
          onDelete={characteristics.deleteCharacteristic}
          characteristics={characteristics.characteristics}
        />
      ),
    }

    const activitiesTab = {
      hidden: !canViewActivities,
      name: AccountDetailsTabsName.Activities,
      template: isFilledAccount(account) && (
        <TaskList
          refreshDataObservable={tasksUpdate$}
          parentType={TaskParentType.Account}
          parentNumber={account.account_number}
          disabled={disabled}
          filters={{ account: account.account_number }}
        />
      ),
    }

    const documentsTab = {
      name: AccountDetailsTabsName.Documents,
      template: (
        <XtDocuments
          mode={mode}
          disabled={disabled}
          onDocumentCreate={documents.saveDocument}
          onDocumentsCreate={documents.saveDocuments}
          onDocumentDelete={documents.deleteDocument}
          onDocumentUpdate={documents.updateDocument}
          tableState={documents.state}
          pagination={documents.pagination}
        />
      ),
    }

    const tabs = isNewMode
      ? [remarksTab, characteristicsTab, documentsTab]
      : [contactsTab, remarksTab, characteristicsTab, activitiesTab, documentsTab]

    return <XtTabs tabs={tabs} />
  }
)
