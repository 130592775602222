import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { DocumentsModule } from 'documents/documents.module'
import { Injector } from 'core/injector/injector.service'
import { IDocumentsService } from 'documents/documents.types'
import { documentsServiceToken } from 'documents/documents.constants'
import { Scope } from 'core/injector/injector.types'
import { CoreModule } from 'core/core.module'
import { errorHandlerServiceToken } from 'core/core.constants'
import { IErrorHandler } from 'core/services/error-handler.service'
import { ISettingsService } from 'setup/settings/settings.service'
import { setupCrmModule, setupCrmUtilsServiceToken } from './crm.constants'
import { ICrmUtilsService } from './crm-utils.service'
import { SettingsModule } from '../settings/settings.module'
import { settingsServiceToken } from '../settings/settings.constants'
import { OpportunitiesServicesModule } from '../../activities/opportunities/services/opportunities-services.module'

export type SetupCrmModuleDefinition = typeof import('./crm.exports')

export interface ISetupCrmModuleServices {
  CrmUtilsService: ICrmUtilsService
}

function setupCrmModuleInitializer({ CrmUtilsService }: SetupCrmModuleDefinition): void {
  Injector.register<ICrmUtilsService>(
    setupCrmUtilsServiceToken,
    (injector) => {
      const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
      const errorHandler = Injector.resolve<IErrorHandler>(errorHandlerServiceToken)
      const settingsService = Injector.resolve<ISettingsService>(settingsServiceToken)

      return new CrmUtilsService(documentsService, settingsService, errorHandler)
    },
    Scope.Singleton
  )
}

export const SetupCrmModule: ILazyModule<SetupCrmModuleDefinition> = {
  name: setupCrmModule,
  resolver: () => import('./crm.exports'),
  initializer: setupCrmModuleInitializer,
  dependencies: [CoreModule, DocumentsModule, SettingsModule, OpportunitiesServicesModule],
}
