// extracted by mini-css-extract-plugin
export var formContent = "bom-list-forms_formContent__YF0ii";
export var options = "bom-list-forms_options__D5oE8";
export var fields = "bom-list-forms_fields__LwHcl";
export var revisionField = "bom-list-forms_revisionField__xJZrV";
export var itemNumber = "bom-list-forms_itemNumber__BBxen";
export var thresholdDaysSection = "bom-list-forms_thresholdDaysSection__yLWXO";
export var mainSection = "bom-list-forms_mainSection__tORyy";
export var header = "bom-list-forms_header__TODHM";
export var printButton = "bom-list-forms_printButton__vNfMr";
export var checkbox = "bom-list-forms_checkbox__SXSyi";