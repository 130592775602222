import React, { FC, useCallback, useMemo } from 'react'
import { CardHeader } from '@material-ui/core'
import { FormCheckboxLabel, FormSelectField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { ItemDetailsFormField, ItemSoldFormLabel } from '../../../../item-details.types'
import * as styles from '../item-details-cards.module.scss'
import { extractUomOptions } from './item-sold-card.utils'
import { MAX_VALUE, MAX_WARRANTY_VALUE, MAX_WHOLESALE_PRICE_VALUE, MIN_VALUE } from './item-sold-card.constants'
import { IItemSoldCard } from './item-sold-card.types'

export const ItemSoldCard: FC<IItemSoldCard> = ({ formMethods, disabled }) => {
  const { DocumentsUtilsService } = useDocumentsModule()

  const { control, watch, setValue, trigger } = formMethods
  const isItemSold = watch(ItemDetailsFormField.ItemIsSold)
  const inventoryUom = watch(ItemDetailsFormField.InventoryUOM)
  const uomOptions = useMemo(() => extractUomOptions(inventoryUom), [inventoryUom])

  const handleItemIsSoldChange = useCallback(
    (itemIsSold: boolean) => {
      setValue(ItemDetailsFormField.ItemIsSold, itemIsSold, { shouldDirty: true })
      if (!itemIsSold) {
        void trigger(ItemDetailsFormField.ProductCategory)
      }
    },
    [setValue, trigger]
  )

  return (
    <div className={styles.cardContainer}>
      <CardHeader title="Item Sold" className={styles.cardHeader} />
      <div className={styles.cardContent}>
        <FormCheckboxLabel
          className={styles.cardCheckboxSingle}
          label={ItemSoldFormLabel.ItemIsSold}
          control={control}
          name={ItemDetailsFormField.ItemIsSold}
          disabled={disabled}
          onChange={handleItemIsSoldChange}
        />
        <FormXtAutocomplete
          required
          loadOptions={DocumentsUtilsService.loadProductCategories}
          name={ItemDetailsFormField.ProductCategory}
          control={control}
          disabled={disabled || !isItemSold}
          label={ItemSoldFormLabel.ProductCategory}
        />
        <DecimalFormField
          name={ItemDetailsFormField.ListPrice}
          control={control}
          disabled={disabled || !isItemSold}
          label={ItemSoldFormLabel.ListPrice}
          allowNegative={false}
          maxValue={MAX_VALUE}
          fixedDecimalScale={4}
        />
        <FormSelectField
          required
          name={ItemDetailsFormField.SellingUOM}
          control={control}
          disabled={disabled || !isItemSold}
          label={ItemSoldFormLabel.SellingUOM}
          options={uomOptions}
        />
        <DecimalFormField
          name={ItemDetailsFormField.Warranty}
          control={control}
          disabled={disabled || !isItemSold}
          label={ItemSoldFormLabel.Warranty}
          minValue={MIN_VALUE}
          maxValue={MAX_WARRANTY_VALUE}
          fixedDecimalScale={0}
          allowNegative={false}
          counters
        />
        <DecimalFormField
          name={ItemDetailsFormField.WholesalePrice}
          control={control}
          disabled={disabled || !isItemSold}
          label={ItemSoldFormLabel.WholesalePrice}
          maxValue={MAX_WHOLESALE_PRICE_VALUE}
          allowNegative={false}
          fixedDecimalScale={6}
        />
        <FormCheckboxLabel
          className={styles.cardCheckboxSingle}
          name={ItemDetailsFormField.Exclusive}
          control={control}
          label={ItemSoldFormLabel.Exclusive}
          disabled={disabled || !isItemSold}
        />
      </div>
    </div>
  )
}
