import React, { FC, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FilterDialogWrapper } from 'components/pagefilter/filter/filter-dialog-wrapper'
import { FormField, FormRadioGroup, FormSelectField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { getAutocompleteInputLabelAsId, renderColumnOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useXtSelect } from 'components/controls/xt-select/xt-select-hook'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { AccountingPeriodOption, IDocumentFilters } from 'documents/documents.types'
import { SortDirection } from 'components/table/table-head/table-head.types'
import * as styles from './quantity-on-hand-list-filter.module.scss'
import {
  IQuantityOnHandListFilterForm,
  QuantityOnHandListFilterFormField,
  QuantityOnHandListFilterFormFieldLabel,
} from './quantity-on-hand-list-filter.types'
import {
  abcClassOptions,
  defaultQuantityOnHandListFilterValues,
  showInventoryValueOptions,
  showQuantityOptions,
} from './quantity-on-hand-list-filter.constants'
import { quantityOnHandListFilterValidationScheme } from './quantity-on-hand-list-filter.validation'

interface IXtQuantityOnHandListFilter {
  open: boolean

  onCancel(): void

  onFilterSubmit(filters: IQuantityOnHandListFilterForm): void
}

export const XtQuantityOnHandListFilter: FC<IXtQuantityOnHandListFilter> = ({ open, onCancel, onFilterSubmit }) => {
  const { DocumentsUtilsService } = useDocumentsModule()

  const {
    loadClassCodesNumberAndDescription,
    loadCostCategories,
    loadItemOptions,
    loadItemGroups,
    loadSites,
    loadAccountingPeriods,
  } = DocumentsUtilsService

  const { options: accountingPeriods } = useXtSelect<AccountingPeriodOption, IDocumentFilters>(async (filters) =>
    loadAccountingPeriods(filters, [
      {
        sortField: 'period_start',
        sortDirection: SortDirection.Descending,
      },
    ])
  )

  const { control, handleSubmit, setValue, reset, getValues } = useForm<IQuantityOnHandListFilterForm>({
    mode: 'onBlur',
    defaultValues: defaultQuantityOnHandListFilterValues,
    resolver: yupResolver(quantityOnHandListFilterValidationScheme),
  })

  const [showInventoryValueState, setShowInventoryValueState] = useState<boolean>(false)

  const handleShowInventoryValueCheckboxChange = (checked: boolean): void => {
    setValue(QuantityOnHandListFilterFormField.ShowValue, checked)
    setShowInventoryValueState(checked)
  }

  const onClearFilters: VoidFunction = () => {
    reset()
    setShowInventoryValueState(false)
    onFilterSubmit(defaultQuantityOnHandListFilterValues)
    onCancel()
  }

  const submitForm = useCallback(
    (formData: IQuantityOnHandListFilterForm): void => {
      onFilterSubmit(formData)
      onCancel()
    },
    [onCancel, onFilterSubmit]
  )

  return (
    <FilterDialogWrapper
      open={open}
      onCancel={onCancel}
      onApplyFilters={handleSubmit(submitForm)}
      onClearFilters={onClearFilters}
      reset={reset}
      getValues={getValues}
    >
      <div className={styles.quantityOnHandListFiltersContainer}>
        <FormXtAutocomplete
          control={control}
          label={QuantityOnHandListFilterFormFieldLabel.ClassCode}
          name={QuantityOnHandListFilterFormField.ClassCode}
          loadOptions={loadClassCodesNumberAndDescription}
          getInputLabel={getAutocompleteInputLabelAsId}
          renderOption={renderColumnOption}
        />
        <FormField
          control={control}
          label={QuantityOnHandListFilterFormFieldLabel.CostCategoryPattern}
          name={QuantityOnHandListFilterFormField.ClassCodePattern}
        />
        <FormXtAutocomplete
          control={control}
          label={QuantityOnHandListFilterFormFieldLabel.CostCategory}
          name={QuantityOnHandListFilterFormField.CostCategory}
          loadOptions={loadCostCategories}
          getInputLabel={getAutocompleteInputLabelAsId}
          renderOption={renderColumnOption}
        />
        <FormField
          control={control}
          label={QuantityOnHandListFilterFormFieldLabel.CostCategoryPattern}
          name={QuantityOnHandListFilterFormField.CostCategoryPattern}
        />
        <FormXtAutocomplete
          control={control}
          label={QuantityOnHandListFilterFormFieldLabel.ItemNumber}
          name={QuantityOnHandListFilterFormField.ItemNumber}
          loadOptions={loadItemOptions}
          getInputLabel={getAutocompleteInputLabelAsId}
          renderOption={renderColumnOption}
        />
        <FormXtAutocomplete
          control={control}
          label={QuantityOnHandListFilterFormFieldLabel.ItemGroup}
          name={QuantityOnHandListFilterFormField.ItemGroup}
          loadOptions={loadItemGroups}
          getInputLabel={getAutocompleteInputLabelAsId}
          renderOption={renderColumnOption}
        />
        <FormField
          control={control}
          label={QuantityOnHandListFilterFormFieldLabel.ItemGroupPattern}
          name={QuantityOnHandListFilterFormField.ItemGroupPattern}
        />
        <FormSelectField
          control={control}
          label={QuantityOnHandListFilterFormFieldLabel.AbcClass}
          name={QuantityOnHandListFilterFormField.AbcClass}
          options={abcClassOptions}
          clearable
        />
        <FormXtAutocomplete
          control={control}
          label={QuantityOnHandListFilterFormFieldLabel.Site}
          name={QuantityOnHandListFilterFormField.Site}
          loadOptions={loadSites}
        />
        <FormSelectField
          control={control}
          label={QuantityOnHandListFilterFormFieldLabel.AsOf}
          name={QuantityOnHandListFilterFormField.AsOf}
          options={accountingPeriods}
          clearable
        />
        <FormRadioGroup control={control} name={QuantityOnHandListFilterFormField.ShowQuantities} options={showQuantityOptions} />
        <div className={styles.checkboxContainer}>
          <XtCheckbox
            label={QuantityOnHandListFilterFormFieldLabel.ShowInventoryValue}
            value={showInventoryValueState}
            onChange={handleShowInventoryValueCheckboxChange}
          />
          <FormRadioGroup
            control={control}
            name={QuantityOnHandListFilterFormField.UseCost}
            options={showInventoryValueOptions}
            hidden={!showInventoryValueState}
          />
        </div>
      </div>
    </FilterDialogWrapper>
  )
}
