import * as React from 'react'
import { Control } from 'react-hook-form'
import { FormXtAutocomplete } from 'common/utils/form/form.components'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IFilterFormState, IFilter } from '../filter.types'

export function makeAutocompleteFilter<TOption extends IXtAutocompleteOption>(
  filter: IFilter<TOption>,
  control: Control<IFilterFormState>
): JSX.Element {
  if (!filter.autocompleteProps) {
    throw new Error('Filter type was Autocomplete, but no Autocomplete object was passed')
  }
  const {
    autocompleteProps: { renderOption, loadOptions, value, onChange, getInputLabel, filters },
  } = filter

  return (
    <FormXtAutocomplete
      key={`filter-${filter.fieldName}-controller`}
      onChange={onChange}
      loadOptions={loadOptions}
      renderOption={renderOption}
      getInputLabel={getInputLabel}
      value={value}
      label={filter.label}
      name={filter.fieldName}
      control={control}
      filters={filters}
    />
  )
}
