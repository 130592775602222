import { getAutocompleteInputLabelAsId, renderColumnOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { FilterType, IFilter } from 'components/pagefilter/filter/filter.types'
import { IDocumentsUtilsService } from 'documents/documents-utils.service'
import { IItemsUtilsService } from '../items-utils.service'
import { ItemsFilterFieldName, ItemsFilterLabel } from './items-list.types'

export const resolveFilters = async (
  { loadItemGroups, loadItemTypes }: IDocumentsUtilsService,
  { requestOptions }: IItemsUtilsService
): Promise<IFilter[]> => {
  const [classCodeOptions, freightClassOptions, productCategoryOptions] = await requestOptions()

  return [
    {
      type: FilterType.Dropdown,
      label: ItemsFilterLabel.ClassCode,
      fieldName: ItemsFilterFieldName.ClassCode,
      options: classCodeOptions,
    },
    {
      type: FilterType.Text,
      label: ItemsFilterLabel.ClassCodePattern,
      fieldName: ItemsFilterFieldName.ClassCodePattern,
    },
    {
      type: FilterType.Dropdown,
      label: ItemsFilterLabel.FreightClass,
      fieldName: ItemsFilterFieldName.FreightClass,
      options: freightClassOptions,
    },
    {
      type: FilterType.Text,
      label: ItemsFilterLabel.FreightClassPattern,
      fieldName: ItemsFilterFieldName.FreightClassPattern,
    },
    {
      type: FilterType.Text,
      label: ItemsFilterLabel.ItemNumberPattern,
      fieldName: ItemsFilterFieldName.ItemNumberPattern,
    },
    {
      type: FilterType.Text,
      label: ItemsFilterLabel.ItemDescription,
      fieldName: ItemsFilterFieldName.ItemDescription,
    },
    {
      type: FilterType.Autocomplete,
      label: ItemsFilterLabel.ItemGroup,
      fieldName: ItemsFilterFieldName.ItemGroup,
      autocompleteProps: {
        loadOptions: loadItemGroups,
        getInputLabel: getAutocompleteInputLabelAsId,
        renderOption: renderColumnOption,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: ItemsFilterLabel.ItemType,
      fieldName: ItemsFilterFieldName.ItemType,
      autocompleteProps: {
        loadOptions: loadItemTypes,
      },
    },
    {
      type: FilterType.Dropdown,
      label: ItemsFilterLabel.ProductCategory,
      fieldName: ItemsFilterFieldName.ProductCategory,
      options: productCategoryOptions,
    },
    {
      type: FilterType.Text,
      label: ItemsFilterLabel.ProductCategoryPattern,
      fieldName: ItemsFilterFieldName.ProductCategoryPattern,
    },
  ]
}
