import { IPaginationResponse, IPaginationData, IPaginationParams, IResponse } from 'common/common.types'
import { IHttpClient } from 'core/services/http-client'
import { CommentsCreationError, NewComment } from 'comments/comments.types'
import { ICommentsService } from 'comments/comments.service'
import { DocumentType } from 'documents/documents.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { convertNewComments } from 'comments/comments.utils'
import { BomItemCreateRequest, BomItemQuickAddInput, BomItemUpdateRequest, IBomItemsFilters, IBomItem } from '../bom.types'

export interface IBomItemsService {
  getAll(
    itemNumber: string,
    { showExpired, showFuture }: IBomItemsFilters,
    { limit, page }: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IBomItem>>
  get(itemNumber: string, sequenceNumber: number): Promise<IBomItem>
  update(itemNumber: string, data: BomItemUpdateRequest): Promise<string>
  create(itemNumber: string, body: BomItemCreateRequest, comments: NewComment[]): Promise<string>
  delete(itemNumber: string, sequenceNumber: number): Promise<string>
  replace(itemNumber: string, sequenceNumber: number, data: BomItemCreateRequest, comments: NewComment[]): Promise<void>
  quickAdd(itemNumber: string, body: BomItemQuickAddInput): Promise<string>
  expire(item_number: string, sequence_number: number): Promise<string>
}

export class BomItemsService implements IBomItemsService {
  constructor(private readonly apiClient: IHttpClient, private commentsService: ICommentsService) {}

  public async getAll(
    itemNumber: string,
    filters: IBomItemsFilters,
    paginationParams?: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IBomItem>> {
    const params = prepareRequestParams(paginationParams, filters, sortOptions)

    const response = await this.apiClient.get<IPaginationResponse<IBomItem>>(`/item/${itemNumber}/bomitem`, { params })
    const {
      data: { data, status },
    } = response
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(itemNumber: string, sequenceNumber: number): Promise<IBomItem> {
    const response = await this.apiClient.get<IResponse<IBomItem>>(`/item/${itemNumber}/bom/${sequenceNumber}`)

    if (!response.data.data || !Object.keys(response.data.data).length) {
      throw new Error(`BOM Item not found. Sequence number: ${sequenceNumber}, parent item number: ${itemNumber}`)
    }
    return response.data.data
  }

  private async createCommentsForBomItem(number: string, comments: NewComment[]): Promise<void> {
    if (!comments.length) {
      return
    }
    try {
      const commentsPayload = convertNewComments(comments, DocumentType.BomItem, number)
      await this.commentsService.createAll(commentsPayload)
    } catch (e) {
      throw new CommentsCreationError()
    }
  }

  public async create(itemNumber: string, body: BomItemCreateRequest, comments: NewComment[]): Promise<string> {
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post(`/item/${itemNumber}/bomitem/create`, { data: [body] })
    await this.createCommentsForBomItem(`${body.bom_item_number}-${body.sequence_number}`, comments)
    return message
  }

  public async update(itemNumber: string, body: BomItemUpdateRequest): Promise<string> {
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post(`/item/${itemNumber}/bomitem/update`, { data: [body] })
    return message
  }

  public async delete(itemNumber: string, sequenceNumber: number): Promise<string> {
    const body = { sequence_number: sequenceNumber }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post(`/item/${itemNumber}/bomitem/delete`, { data: [body] })
    return message
  }

  public async expire(item_number: string, sequence_number: number): Promise<string> {
    const body = { item_number, sequence_number }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post(`/item/bom/expire`, { data: body })
    return message
  }

  public async replace(itemNumber: string, sequenceNumber: number, data: BomItemCreateRequest, comments: NewComment[]): Promise<void> {
    await this.create(itemNumber, data, comments)
    await this.expire(itemNumber, sequenceNumber)
  }

  public async quickAdd(itemNumber: string, body: BomItemQuickAddInput): Promise<string> {
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post(`/item/${itemNumber}/bomitem/create`, { data: [body] })
    return message
  }
}
