// extracted by mini-css-extract-plugin
export var root = "inventory-history-by-location-list_root__tCi0y";
export var xtContent = "inventory-history-by-location-list_xt-content__WLtIj";
export var xtContentWithRemarks = "inventory-history-by-location-list_xt-content-with-remarks__RNPss";
export var muiFormHelperTextRoot = "inventory-history-by-location-list_MuiFormHelperText-root__KerUa";
export var xtListPageContainer = "inventory-history-by-location-list_xt-list-page-container__n1I9L";
export var listContent = "inventory-history-by-location-list_listContent__Wh8qB";
export var muiInputBaseRoot = "inventory-history-by-location-list_MuiInputBase-root__SNQ5Z";
export var muiTextFieldRoot = "inventory-history-by-location-list_MuiTextField-root__6-qtz";
export var muiTablePaginationRoot = "inventory-history-by-location-list_MuiTablePagination-root__GeMk9";
export var muiTablePaginationSelectRoot = "inventory-history-by-location-list_MuiTablePagination-selectRoot__yVdCa";
export var muiTablePaginationSelect = "inventory-history-by-location-list_MuiTablePagination-select__vheao";
export var muiStepperRoot = "inventory-history-by-location-list_MuiStepper-root__o6hpG";
export var muiStepperHorizontal = "inventory-history-by-location-list_MuiStepper-horizontal__mHGqu";
export var muiStepIconRoot = "inventory-history-by-location-list_MuiStepIcon-root__VUeT7";
export var muiStepIconActive = "inventory-history-by-location-list_MuiStepIcon-active__R2Bi4";
export var muiStepIconCompleted = "inventory-history-by-location-list_MuiStepIcon-completed__IiA59";
export var muiListItemRoot = "inventory-history-by-location-list_MuiListItem-root__OwnVM";
export var muiSelected = "inventory-history-by-location-list_Mui-selected__l2J0r";
export var xtPageTitle = "inventory-history-by-location-list_xt-page-title__pzaOI";
export var xtSubPageTitle = "inventory-history-by-location-list_xt-subPage-title__mt7Ol";
export var xtSectionBorder = "inventory-history-by-location-list_xt-section-border__3YkYb";
export var xtPageHeader = "inventory-history-by-location-list_xt-page-header__66mkF";
export var xtDialogScrollable = "inventory-history-by-location-list_xt-dialog-scrollable__yNKKH";
export var reactToastNotificationsContainer = "inventory-history-by-location-list_react-toast-notifications__container__+pXVF";
export var xtScrollbarStyle = "inventory-history-by-location-list_xt-scrollbar-style__icKPT";
export var xtModalDetailsContent = "inventory-history-by-location-list_xt-modal-details-content__TknKd";
export var xtDialogDetailsContent = "inventory-history-by-location-list_xt-dialog-details-content__6iPHb";
export var xtLazyModuleLoaderTabContainer = "inventory-history-by-location-list_xt-lazy-module-loader-tab-container__C72aJ";
export var xtTextTruncated = "inventory-history-by-location-list_xt-text-truncated__S9056";
export var xtSummaryContent = "inventory-history-by-location-list_xt-summary-content__hYfmU";
export var xtSummaryLabel = "inventory-history-by-location-list_xt-summary-label__+mmlH";
export var visibleHidden = "inventory-history-by-location-list_visible-hidden__CDJmg";
export var headerBottomMargin = "inventory-history-by-location-list_header-bottom-margin__M0jAe";
export var xtDialogHeaderLogoAlign = "inventory-history-by-location-list_xt-dialog-header-logoAlign__zP2M3";
export var xtStickyHeader = "inventory-history-by-location-list_xt-sticky-header__CaOwD";
export var noBorder = "inventory-history-by-location-list_no-border__hO51k";
export var muiOutlinedInputNotchedOutline = "inventory-history-by-location-list_MuiOutlinedInput-notchedOutline__Rp8C1";