import { Required } from 'utility-types'
import { Redirect, Route, Switch } from 'react-router-dom'
import React from 'react'
import { IRoute, ParentRoute } from '../../common/routing/routing.types'
import { IXtNavbarOption } from '../header/navbar/navbar.types'
import { buildRoute } from '../../common/routing/routing.utils'
import { canActivateRoute } from '../../common/routing/guarded-route/guarder-route.utils'

export function fitsNavbarOption(route: IRoute): route is Required<IRoute, 'name'> {
  return typeof route.name === 'string' && !!route.name.length && canActivateRoute(route.guards)
}

export function convertRouteChildrenToNavbarOptions(routes: IRoute[] | undefined): IXtNavbarOption[] | undefined {
  if (!Array.isArray(routes)) {
    return undefined
  }
  return routes.filter(fitsNavbarOption).map(({ name, path, type, children }) => ({ name, path, type, children }))
}

export function isParentRoute(route: IRoute): route is ParentRoute {
  return Array.isArray(route.children) && !!route.children.length
}

function defineParentRouteRedirectPath({ children }: ParentRoute): string | null {
  const [firstAvailableRoute] = children.filter(fitsNavbarOption)
  return firstAvailableRoute?.path ?? null
}

export function renderRoute(route: IRoute, shouldDefineRedirect: boolean = false): JSX.Element {
  if (!isParentRoute(route)) {
    return buildRoute(route)
  }
  const redirectPath = defineParentRouteRedirectPath(route)

  const parentRouteRender: () => React.ReactNode = () => (
    <Switch key={`${route.path}-parent`}>
      {redirectPath && shouldDefineRedirect && (
        <Route exact path={route.path} key={`${route.path}-parent-redirect`} render={() => <Redirect to={redirectPath} exact />} />
      )}
      {route.children.map((childRoute) => renderRoute(childRoute))}
    </Switch>
  )

  return buildRoute(route, parentRouteRender)
}
