import { deepEqual } from 'fast-equals'

export function filterByField<TData, TField extends keyof TData>(data: TData[], field: TField): TData[] {
  const map = new Map([...data.map<readonly [TData[TField], TData]>((item) => [item[field], item])])
  return Array.from(map.values())
}

export function updateByFieldValue<TData, TField extends keyof TData>(data: TData[], field: TField, item: TData): TData[] {
  const index = data.findIndex((dataItem) => dataItem[field] === item[field])
  return [...data.slice(0, index), item, ...data.slice(index + 1)]
}

export function deleteByFieldValue<TData, TField extends keyof TData>(data: TData[], field: TField, value: TData[TField]): TData[] {
  const index = data.findIndex((dataItem) => dataItem[field] === value)
  return [...data.slice(0, index), ...data.slice(index + 1)]
}

export function areEqual<T1 extends Array<unknown>, T2 extends Array<unknown>>(array1: T1, array2: T2): boolean {
  if (array1.length !== array2.length) {
    return false
  }
  return deepEqual(array1, array2)
}

export function sortByField<TObject, TField extends keyof TObject>(field: TField): (obj1: TObject, obj2: TObject) => number {
  return (obj1, obj2) => (obj1[field] < obj2[field] ? -1 : 1)
}

/**
 * Merges two collections and returns a new collection, which contains unique values. Objects are compared by using the "field" argument.
 * @param arr1 - first collection
 * @param arr2 - second collection. Objects from the second collection override the same objects provided in the first collection if they are equal.
 * @param field - object field that should be used as ID.
 */
export function mergeCollections<TData, TField extends keyof TData>(arr1: TData[], arr2: TData[], field: TField): TData[] {
  const map1 = arr1.map<readonly [TData[TField], TData]>((value) => [value[field], value])
  const map2 = arr2.map<readonly [TData[TField], TData]>((value) => [value[field], value])

  const mergedMap: Map<TData[TField], TData> = new Map([...map1, ...map2])

  return Array.from(mergedMap.values())
}
