import React from 'react'
import { IAsyncRoute, IRoute } from '../routing.types'
import { GuardedRoute } from '../guarded-route/guarded-route'
import { LazyRoutingModuleLoader } from './lazy-routing-module-loader'

export function buildAsyncRoute<T = never>(route: IAsyncRoute<T>, render: (route: IRoute) => React.ReactElement): JSX.Element {
  return (
    <GuardedRoute
      exact={route.exact}
      key={route.path}
      path={route.path}
      guards={route.guards}
      component={() => <LazyRoutingModuleLoader route={route} render={render} />}
    />
  )
}
