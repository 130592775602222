import { GridColumns } from '@mui/x-data-grid-pro'
import { dateConverter } from 'common/utils/date.utils'
import { numberConverter } from 'common/utils/utils'
import {
  InventoryHistoryTableItem,
  InventoryHistoryField,
  InventoryHistoryLabel,
  InventoryHistoryDetailTableItem,
  InventoryHistoryDetailField,
  InventoryHistoryDetailLabel,
} from './inventory-history-list.types'

export const inventoryHistoryColumns: GridColumns<InventoryHistoryTableItem> = [
  {
    field: InventoryHistoryField.TransactionTime,
    headerName: InventoryHistoryLabel.TransactionTime,
    minWidth: 180,
    valueParser: (value: string) => dateConverter(value, true),
  },
  {
    field: InventoryHistoryField.CreatedTime,
    headerName: InventoryHistoryLabel.CreatedTime,
    minWidth: 180,
    valueParser: (value: string) => dateConverter(value, true),
  },
  {
    field: InventoryHistoryField.Site,
    headerName: InventoryHistoryLabel.Site,
    minWidth: 110,
  },
  {
    field: InventoryHistoryField.ItemNumber,
    headerName: InventoryHistoryLabel.ItemNumber,
    minWidth: 140,
  },
  {
    field: InventoryHistoryField.Type,
    headerName: InventoryHistoryLabel.Type,
    minWidth: 120,
  },
  {
    field: InventoryHistoryField.Order,
    headerName: InventoryHistoryLabel.Order,
    minWidth: 140,
  },
  {
    field: InventoryHistoryField.UOM,
    headerName: InventoryHistoryLabel.UOM,
    minWidth: 120,
  },
  {
    field: InventoryHistoryField.Qty,
    headerName: InventoryHistoryLabel.Qty,
    minWidth: 120,
    valueParser: (value: number) => numberConverter(value, 4),
  },
  {
    field: InventoryHistoryField.Value,
    headerName: InventoryHistoryLabel.Value,
    minWidth: 120,
    valueParser: (value: number) => numberConverter(value, 4),
  },
  {
    field: InventoryHistoryField.FromArea,
    headerName: InventoryHistoryLabel.FromArea,
    minWidth: 140,
  },
  {
    field: InventoryHistoryField.QtyBefore,
    headerName: InventoryHistoryLabel.QtyBefore,
    minWidth: 160,
    valueParser: (value: number) => numberConverter(value, 4),
  },
  {
    field: InventoryHistoryField.ToArea,
    headerName: InventoryHistoryLabel.ToArea,
    minWidth: 120,
  },
  {
    field: InventoryHistoryField.QohAfter,
    headerName: InventoryHistoryLabel.QohAfter,
    minWidth: 160,
    valueParser: (value: number) => numberConverter(value, 4),
  },
  {
    field: InventoryHistoryField.CostMethod,
    headerName: InventoryHistoryLabel.CostMethod,
    minWidth: 160,
  },
  {
    field: InventoryHistoryField.ValueBefore,
    headerName: InventoryHistoryLabel.ValueBefore,
    minWidth: 160,
    valueParser: (value: number) => numberConverter(value, 4),
  },
  {
    field: InventoryHistoryField.ValueAfter,
    headerName: InventoryHistoryLabel.ValueAfter,
    minWidth: 160,
    valueParser: (value: number) => numberConverter(value, 4),
  },
  {
    field: InventoryHistoryField.TransactionUser,
    headerName: InventoryHistoryLabel.TransactionUser,
    minWidth: 200,
  },
]

export const inventoryHistoryDetailColumns: GridColumns<InventoryHistoryDetailTableItem> = [
  {
    field: InventoryHistoryDetailField.DetailLocation,
    headerName: InventoryHistoryDetailLabel.DetailLocation,
    minWidth: 180,
    sortable: false,
  },
  {
    field: InventoryHistoryDetailField.Qty,
    headerName: InventoryHistoryDetailLabel.Qty,
    minWidth: 160,
    sortable: false,
  },
  {
    field: InventoryHistoryDetailField.QohBefore,
    headerName: InventoryHistoryDetailLabel.QohBefore,
    minWidth: 160,
    sortable: false,
  },
  {
    field: InventoryHistoryDetailField.QohAfter,
    headerName: InventoryHistoryDetailLabel.QohAfter,
    minWidth: 160,
    sortable: false,
  },
]

export const minDetailPanelContentHeight = 110

export const inventoryHistoryPageTitle = 'xTuple - Inventory History'
