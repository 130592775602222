import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { booleanColumnConverter } from 'common/utils/table.utils'
import { numberConverter } from 'common/utils/utils'
import { SiteTableItem, SitesAction } from './sites-tab.types'

export const SitesTabActionsViewMode: IXtTableCellAction[] = [
  {
    name: SitesAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]

export const SitesTabActions: IXtTableCellAction[] = [
  {
    name: SitesAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: SitesAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: SitesAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const SitesTabColumns: IXtTableColumn<SiteTableItem>[] = [
  {
    id: 'active',
    field: 'active',
    headerName: 'Active',
    flex: '1 0 100px',
    width: 100,
    converter: booleanColumnConverter,
  },
  {
    id: 'site',
    field: 'site',
    headerName: 'Site',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'site_description',
    field: 'site_description',
    headerName: 'Description',
    flex: '1 0 180px',
    width: 180,
  },
  {
    id: 'control_method',
    field: 'control_method',
    headerName: 'Cntrl. Method',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'multiple_location_control',
    field: 'multiple_location_control',
    headerName: 'Loc. Cntrl.',
    flex: '1 0 140px',
    width: 140,
    converter: booleanColumnConverter,
  },
  {
    id: 'cost_method',
    field: 'cost_method',
    headerName: 'Cost Method',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'item_site_cost',
    field: 'item_site_cost',
    headerName: 'Site Cost',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
]
