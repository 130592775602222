import * as Yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { dateYupValidator } from 'common/utils/date.utils'
import { BomDetailsFormField } from './bom-details.types'

export const bomDetailsValidationSchema = Yup.object().shape({
  [BomDetailsFormField.BatchSize]: Yup.number().nullable().required(validationMessage.isRequired),
  [BomDetailsFormField.ItemNumber]: Yup.mixed().required(validationMessage.isRequired).typeError(validationMessage.invalidNumber),
  [BomDetailsFormField.RevisionDate]: dateYupValidator,
})
