import { LocationFormField, TransactionTypeOptionLabel, TransactionTypeOptionValue } from './inventory-history-by-location-list-form.types'

export const defaultTransactionTypeOption = {
  id: TransactionTypeOptionValue.AllTransaction,
  label: TransactionTypeOptionLabel.AllTransaction,
}

export const allSitesOption = {
  id: '',
  label: 'ALL SITES',
}

export const transactionTypeOption = [
  {
    id: TransactionTypeOptionValue.Shipments,
    label: TransactionTypeOptionLabel.Shipments,
  },
  {
    id: TransactionTypeOptionValue.Issues,
    label: TransactionTypeOptionLabel.Issues,
  },
  {
    id: TransactionTypeOptionValue.Receipts,
    label: TransactionTypeOptionLabel.Receipts,
  },
  {
    id: TransactionTypeOptionValue.Scraps,
    label: TransactionTypeOptionLabel.Scraps,
  },
  {
    id: TransactionTypeOptionValue.AdjustmentsAndCounts,
    label: TransactionTypeOptionLabel.AdjustmentsAndCounts,
  },
  {
    id: TransactionTypeOptionValue.Transfers,
    label: TransactionTypeOptionLabel.Transfers,
  },
  defaultTransactionTypeOption,
]

export const initDefaultFormDataToInventoryHistory = {
  [LocationFormField.Location]: null,
  [LocationFormField.Site]: null,
  [LocationFormField.TransactionType]: defaultTransactionTypeOption,
  [LocationFormField.StartDate]: new Date(),
  [LocationFormField.EndDate]: new Date(),
}

export const maxDate = new Date()
