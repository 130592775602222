import { IStandardOperation } from 'core/services/standard-operation.service'
import { dateToServerFormat, parseServerDateWithStringValue } from 'common/utils/date.utils'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { globalConstants } from 'common/constants'
import { RoutingItemControlName, MIN_NUMBER_VALUE, StandardOperationNone } from './routing-item.constants'
import { RoutingItemFormData, StandardOperationData } from './routing-item.types'
import { IRoutingItem } from '../routing.types'

export function defineRoutingItemFormData(data: IRoutingItem | null): RoutingItemFormData {
  return {
    [RoutingItemControlName.StandardOperation]: defineAutocompleteOption(data?.standard_operation) ?? StandardOperationNone,
    // TODO add OperationTypeService.get(data.operation_type)
    [RoutingItemControlName.OperationType]: defineAutocompleteOption(data?.operation_type),
    [RoutingItemControlName.RoutingDescription1]: data?.routing_description1 ?? '',
    [RoutingItemControlName.RoutingDescription2]: data?.routing_description2 ?? '',
    [RoutingItemControlName.WorkCenter]: defineAutocompleteOption(data?.work_center),
    [RoutingItemControlName.ToolingReference]: data?.tooling_reference ?? '',
    [RoutingItemControlName.ExecutionDay]: data?.execution_day ?? 1,
    [RoutingItemControlName.Expires]: parseServerDateWithStringValue(data?.expires),
    [RoutingItemControlName.Effective]: parseServerDateWithStringValue(data?.effective),
    [RoutingItemControlName.Instructions]: data?.instructions ?? '',
    [RoutingItemControlName.SetupTime]: data?.setup_time ?? MIN_NUMBER_VALUE,
    [RoutingItemControlName.RunTime]: data?.run_time ?? MIN_NUMBER_VALUE,
    [RoutingItemControlName.Per]: data?.run_qty_per ?? MIN_NUMBER_VALUE,
    [RoutingItemControlName.ReportSetupCostAs]: defineAutocompleteOption(data?.report_setup_as),
    [RoutingItemControlName.ReportSetupTime]: data?.report_setup ?? true,
    [RoutingItemControlName.ReportRunCostAs]: defineAutocompleteOption(data?.report_runtime_as),
    [RoutingItemControlName.ReportRunTime]: data?.report_runtime ?? true,
    [RoutingItemControlName.ProductionUOM]: defineAutocompleteOption(data?.production_uom),
    [RoutingItemControlName.ProductionRatio]: data?.production_inventory_ratio ?? MIN_NUMBER_VALUE,
    [RoutingItemControlName.ReceiveInventory]: data?.receive_inventory ?? false,
    [RoutingItemControlName.IssueComponents]: data?.issue_components ?? false,
    [RoutingItemControlName.AllowOverlap]: data?.allow_overlap ?? false,
    [RoutingItemControlName.WIPLocation]: data?.wip_location
      ? {
          id: data.wip_location,
          label: `${data.wip_location_site}-${data.wip_location}`,
          wip_location_site: data.wip_location_site ?? null,
        }
      : null,
  }
}

export function defineStdOperationData(standardOperations: IStandardOperation | null): StandardOperationData {
  return {
    [RoutingItemControlName.RoutingDescription1]: standardOperations?.operation_description1 ?? '',
    [RoutingItemControlName.RoutingDescription2]: standardOperations?.operation_description2 ?? '',
    [RoutingItemControlName.WorkCenter]: defineAutocompleteOption(standardOperations?.work_center),
    [RoutingItemControlName.ToolingReference]: standardOperations?.tooling_reference ?? '',
    [RoutingItemControlName.Instructions]: standardOperations?.instructions ?? '',
    [RoutingItemControlName.SetupTime]: standardOperations?.setup_time ?? MIN_NUMBER_VALUE,
    [RoutingItemControlName.RunTime]: standardOperations?.run_time ?? MIN_NUMBER_VALUE,
    [RoutingItemControlName.Per]: standardOperations?.qty_per ?? MIN_NUMBER_VALUE,
    [RoutingItemControlName.ReportRunCostAs]: defineAutocompleteOption(standardOperations?.report_run_as),
    [RoutingItemControlName.ReportRunTime]: standardOperations?.report_run ?? false,
    [RoutingItemControlName.ReportSetupCostAs]: defineAutocompleteOption(standardOperations?.report_setup_as),
    [RoutingItemControlName.ReportSetupTime]: standardOperations?.report_setup ?? false,
    [RoutingItemControlName.ProductionUOM]: defineAutocompleteOption(standardOperations?.production_uom),
    [RoutingItemControlName.ProductionRatio]: standardOperations?.inventory_production_ratio ?? MIN_NUMBER_VALUE,
  }
}

export function convertToRoutingItem(formData: RoutingItemFormData, routingItem: IRoutingItem | null): IRoutingItem {
  return {
    sequence_number: routingItem?.sequence_number ?? 0,
    work_center: formData.work_center!.id,
    // We have to check for the `StandardOperationNone` object here in order to allow displaying the
    // label of 'NONE' in the form input. It would be preferable to simply check for a `null` id,
    // but the types have been written in such a way as to make that difficult to implement without
    // making changes to several other unrelated modules.
    standard_operation:
      formData.standard_operation && formData.standard_operation !== StandardOperationNone ? formData.standard_operation.id : null,
    operation_type: formData.operation_type?.id ?? '',
    routing_description1: formData.routing_description1,
    routing_description2: formData.routing_description2,
    effective: dateToServerFormat(formData.effective, globalConstants.dateStringRepresentation.always),
    expires: dateToServerFormat(formData.expires, globalConstants.dateStringRepresentation.never),
    execution_day: formData.execution_day,
    tooling_reference: formData.tooling_reference,
    setup_time: formData.setup_time ?? 0,
    report_setup_as: formData.report_setup_as?.id ?? '',
    report_setup: formData.report_setup,
    run_time: formData.run_time ?? 0,
    run_qty_per: formData.run_qty_per ?? 0,
    report_runtime_as: formData.report_runtime_as?.id ?? '',
    report_runtime: formData.report_runtime,
    production_uom: formData.production_uom?.id ?? '',
    inventory_uom: routingItem?.inventory_uom ?? '',
    production_inventory_ratio: formData.production_inventory_ratio ?? 0,
    issue_components: formData.issue_components,
    receive_inventory: formData.receive_inventory,
    allow_overlap: formData.allow_overlap,
    instructions: formData.instructions,
    wip_location_site: formData.wip_location ? formData.wip_location.wip_location_site : null,
    wip_location: formData.wip_location?.id ?? null,
  }
}
