import React, { FC, KeyboardEventHandler, useCallback } from 'react'
import { Key } from 'ts-key-enum'
import { convertDate } from 'common/utils/date.utils'
import { PhoneLink } from 'components/controls/phone/phone-link/phone-link'
import { cls } from 'common/utils/utils'
import * as styles from './opportunity-kanban-card.module.scss'
import { IKanbanOpportunityCard } from '../opportunities-board.types'

function getAmountValueText(amount: number | null, currency: string | null): string {
  if (typeof amount !== 'number') {
    return ''
  }
  if (!currency) {
    return amount.toLocaleString()
  }
  return `${currency} ${amount.toLocaleString()}`
}

function getProbabilityValueText(probability: number | null): string {
  if (typeof probability !== 'number') {
    return ''
  }
  return `${probability}%`
}

export const OpportunityKanbanCard: FC<IKanbanOpportunityCard> = ({
  amount,
  name,
  currency,
  account,
  contact,
  allow_sms,
  lastActivityDate,
  phone,
  probability,
  onClick,
}) => {
  const dateValue = convertDate(lastActivityDate)
  const amountValue = getAmountValueText(amount, currency)
  const probabilityValue = getProbabilityValueText(probability)
  const contactValue = contact ?? ''

  const onKeyDown: KeyboardEventHandler = useCallback<(event: React.KeyboardEvent<HTMLElement>) => void>(
    (event) => {
      if (event.key === Key.Enter && typeof onClick === 'function') {
        onClick(event)
      }
    },
    [onClick]
  )

  return (
    <div className={styles.card} onClick={onClick} role="gridcell" tabIndex={0} onKeyDown={onKeyDown}>
      <div className={styles.cardRow}>
        <span className={styles.cardValue} title={name}>
          {name}
        </span>
      </div>

      <div className={styles.cardRow}>
        <span className={styles.cardValue} title={account}>
          {account}
        </span>
      </div>

      <div className={styles.cardRow}>
        <span className={styles.cardValue}>{contactValue}</span>
      </div>

      {phone && (
        <div className={cls(!phone && styles.cardRow)}>
          <PhoneLink showMessageAction={!!allow_sms} phone={phone ?? undefined} />
        </div>
      )}

      <div className={styles.cardRow}>
        <span className={styles.cardLabel}>Last Activity Date:</span>
        <span className={styles.cardValue}>{dateValue}</span>
      </div>

      <div className={styles.cardRow}>
        <span className={styles.cardLabel}>Amount:</span>
        <span className={styles.cardValue}>{amountValue}</span>
      </div>

      <div className={styles.cardRow}>
        <span className={styles.cardLabel}>Probability:</span>
        <span className={styles.cardValue}>{probabilityValue}</span>
      </div>
    </div>
  )
}
