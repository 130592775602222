import { IModule } from './core/react-lazy-modules/react-lazy-modules.types'
import { CoreModule } from './core/core.module'
import { ToastModule } from './toast/toast.module'
import { AuthModule } from './auth/auth.module'
import { UsersModule } from './users/users.module'
import { DocumentsModule } from './documents/documents.module'
import { CharacteristicsModule } from './characteristics/characteristics.module'
import { CommentsModule } from './comments/comments.module'
import { DictionaryModule } from './dictionary/dictionary.module'

export const AppModule: IModule = {
  name: 'AppModule',
  resolver: () => ({}),
  dependencies: [
    CoreModule,
    CommentsModule,
    CharacteristicsModule,
    DocumentsModule,
    ToastModule,
    AuthModule,
    UsersModule,
    DictionaryModule,
  ],
}
