import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { FilterType, IFilter } from 'components/pagefilter/filter/filter.types'
import { IDocumentsUtilsService } from 'documents/documents-utils.service'
import { AccountAction, IAccountTableItem } from './accounts-list.types'
import { AccountFilterFieldName } from '../services/accounts-services.types'

export function getAccountActions(item: Pick<IAccountTableItem, 'editable'>, actions: IXtTableCellAction[]): IXtTableCellAction[] {
  return item.editable ? actions : actions.filter((action: IXtTableCellAction) => action.name === AccountAction.View)
}

export function resolveFilters({ loadAccountTypeOptions, loadUserOptions }: IDocumentsUtilsService): IFilter[] {
  return [
    {
      type: FilterType.Autocomplete,
      label: 'Owner',
      fieldName: AccountFilterFieldName.Owner,
      autocompleteProps: {
        loadOptions: loadUserOptions,
      },
    },
    {
      type: FilterType.Text,
      label: 'Account Number Pattern',
      fieldName: AccountFilterFieldName.AccountNumberPattern,
    },
    {
      type: FilterType.Text,
      label: 'Account Name Pattern',
      fieldName: AccountFilterFieldName.AccountNamePattern,
    },
    {
      type: FilterType.Autocomplete,
      label: 'Account Type',
      fieldName: AccountFilterFieldName.AccountType,
      autocompleteProps: {
        loadOptions: loadAccountTypeOptions,
      },
    },
    {
      type: FilterType.Text,
      label: 'Contact Name Pattern',
      fieldName: AccountFilterFieldName.ContactNamePattern,
    },
    {
      type: FilterType.Text,
      label: 'City Pattern',
      fieldName: AccountFilterFieldName.CityPattern,
    },
    {
      type: FilterType.Text,
      label: 'State Pattern',
      fieldName: AccountFilterFieldName.StatePattern,
    },
  ]
}
