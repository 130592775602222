import { IPaginationData, IPaginationParams, IPaginationResponse } from '../../common/common.types'
import { prepareRequestParams } from '../../common/utils/request.utils'
import { IHttpClient } from './http-client'

export interface ITerm {
  code: string
  description: string
  type: string
  due_days: number
  discount_days: number
  discount_percent: number
  cutoff_day: number
  use_for_ap: boolean
  use_for_ar: boolean
  terms_fincharg: boolean
  terms_created: string
  terms_lastupdated: string
}

export interface ITermsService {
  getAll(pagination?: IPaginationParams): Promise<IPaginationData<ITerm>>
}

export class TermsService implements ITermsService {
  constructor(private readonly httpClient: IHttpClient) {}

  public async getAll(pagination?: IPaginationParams): Promise<IPaginationData<ITerm>> {
    const params = prepareRequestParams(pagination)
    const {
      data: { data, status },
    } = await this.httpClient.get<IPaginationResponse<ITerm>>('/terms', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}
