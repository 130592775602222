import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { DocumentOrderOption } from 'documents/documents.types'
import { ShipmentDataOption } from 'shipments/shipments.types'
import { IPrintLabelsForm } from './print-labels/print-labels.types'

export enum PrintSharedFormField {
  Number = 'number',
  ReportName = 'report_name',
  From = 'from',
  To = 'to',
}

export enum PrintSharedFormLabel {
  OrderNumber = 'Order #',
  From = 'From',
  To = 'To',
  ReportName = 'Report Name',
}

export interface IPrintFormDialogParams {
  onClose: VoidFunction
}

export interface IPrintFormSharedState<Option extends IXtAutocompleteOption = IXtAutocompleteOption> extends IPrintLabelsForm {
  [PrintSharedFormField.ReportName]: IXtAutocompleteOption | null
  [PrintSharedFormField.Number]: Option | null
}

export type ShippingFormOrderOption = Pick<DocumentOrderOption, keyof IXtAutocompleteOption | 'order_type' | 'number'>

export enum ShippingPrintFormSharedField {
  Order = 'order_number',
  ShipmentNumber = 'shipment_number',
}

export enum ShippingPrintFormSharedLabel {
  Order = 'Order #',
  ShipmentNumber = 'Shipment #',
}

export interface IPrintShippingFormSharedState {
  [ShippingPrintFormSharedField.Order]: ShippingFormOrderOption | null
  [ShippingPrintFormSharedField.ShipmentNumber]: ShipmentDataOption | null
}
