import * as React from 'react'
import { FC, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { cls } from 'common/utils/utils'
import { useCoreModule } from 'core/core-module-hook'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { useTable } from 'common/hooks/useTable'
import { XtList } from 'components/list/list'
import { LoaderMessage, xsMq } from 'common/constants'
import { XtPageFilter } from 'components/pagefilter/pagefilter'
import { usePageFilter } from 'components/pagefilter/pagefilter.utils'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { qohListPageTitle, quantityOnHandColumns } from './quantity-on-hand-list.constants'
import { useQuantityOnHandServices } from '../quantity-on-hand-module-hook'
import * as styles from './quantity-on-hand-list.module.scss'
import { XtQuantityOnHandListFilter } from './quantity-on-hand-list-filter/quantity-on-hand-list-filter'
import { IQuantityOnHandListFilterForm } from './quantity-on-hand-list-filter/quantity-on-hand-list-filter.types'
import { defaultQuantityOnHandListFilterValues } from './quantity-on-hand-list-filter/quantity-on-hand-list-filter.constants'

export const QuantityOnHandList: FC = () => {
  const { ErrorHandler } = useCoreModule()
  const { QuantityOnHandUtilsService } = useQuantityOnHandServices()
  const isMobile = useMediaQuery(xsMq)
  useDocumentTitle(qohListPageTitle)
  const { state, filter, pagination, sort } = useTable(
    defaultQuantityOnHandListFilterValues,
    QuantityOnHandUtilsService.fetchQuantityOnHand,
    undefined,
    PageFilterMapping.QOH
  )

  const QOHLotListFilters = usePageFilter<IQuantityOnHandListFilterForm>(PageFilterMapping.QOH)

  const [openFilters, setOpenFilters] = useState<boolean>(false)

  const toggleFiltersDialog: VoidFunction = () => {
    setOpenFilters((prev) => !prev)
  }

  const print = async (): Promise<void> => {
    try {
      await QuantityOnHandUtilsService.printQuantityOnHand(state.filters)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  return (
    <div className={cls('xt-list-page-container', styles.listContent)}>
      <div className={styles.listControls}>
        <div className="xt-page-header">
          <h1 className="xt-page-title">Quantity on Hand</h1>
        </div>
        <div className={styles.filterSection}>
          <XtPageFilter
            state={QOHLotListFilters}
            defaultFilterValues={defaultQuantityOnHandListFilterValues}
            filter={filter}
            tableFilters={state.filters}
            onClick={toggleFiltersDialog}
            CustomFilterDialog={() => (
              <XtQuantityOnHandListFilter open={openFilters} onCancel={toggleFiltersDialog} onFilterSubmit={filter} />
            )}
          />
          <XtResponsiveButton
            showIconOnDesktopMode={false}
            label="Print"
            icon={SvgIconIds.PRINT}
            disabled={state.loading || !state.data.length}
            onClick={print}
          />
        </div>
      </div>
      <XtList
        isMobile={isMobile}
        pagination={pagination}
        loading={state.loading}
        data={state.data}
        columns={quantityOnHandColumns}
        sortOptions={state.sortOptions}
        onColumnHeaderClick={sort}
        loadMessage={LoaderMessage.QOH}
      />
    </div>
  )
}
