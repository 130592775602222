import { IDocumentListFilters } from 'documents/documents.types'
import { IShipmentFilters } from 'shipments/shipments.types'
import { IPrintShippingFormState, ShippingPrintFormField } from './print-shipping-form.types'
import { defaultPrintShippingFormState } from '../print-dialogs.constants'

export const shippingPrintFormDefaultValues: IPrintShippingFormState = {
  ...defaultPrintShippingFormState,
  [ShippingPrintFormField.ShippingForm]: null,
}

export const orderFilters: IDocumentListFilters = { showInactive: true, so: true, to: true }

export const defaultShipmentFilters: IShipmentFilters = { showShipped: true }
