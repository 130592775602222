import React, { FC, memo } from 'react'

import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { DocumentType, UsedOnValue } from 'documents/documents.types'
import { TaskParentType } from 'tasks/tasks.types'
import { QuoteFilterFieldName } from 'sales-shared/quotes.types'
import { UserPermission } from 'auth/auth.types'
import { LazyModuleLoader } from 'components/lazy-module-loader/lazy-module-loader'
import { QuotesModule, QuotesModuleDefinition } from 'quotes/quotes.module'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCharacteristicsModule } from 'characteristics/characteristics-module-hook'
import { useCommentsModule } from 'comments/comments-module-hook'
import { useContactsModule } from 'contacts/contacts-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { ActivitiesTab } from 'activities/components/activities-tab'
import { XtMode } from 'common/common.types'
import * as styles from './prospect-details-tabs.module.scss'
import { QuotesListActionsEditMode, ContactsInfoMessage } from './prospect-details-tabs.constants'
import { ProspectDetailsGeneralTab } from './prospect-details-tabs.general'
import { ProspectDetailsTabsName, IProspectDetailsTabs } from './prospect-details-tabs.types'
import { convertProspectToAccountFulfilled } from '../../../accounts/accounts.utils'

export const ProspectDetailsTabs: FC<IProspectDetailsTabs> = memo(
  ({
    disabled,
    isMobile,
    characteristics,
    onCreateCharacteristic,
    onUpdateCharacteristic,
    onDeleteCharacteristic,
    remarks,
    prospectNumber,
    prospect,
    documents,
    mode,
  }) => {
    const { PermissionsService } = useAuthModule()
    const { XtDocuments } = useDocumentsModule()
    const { XtCharacteristics } = useCharacteristicsModule()
    const { XtRemarks } = useCommentsModule()
    const { ContactList } = useContactsModule()

    const canViewContacts = PermissionsService.hasSomePermission([
      UserPermission.MaintainAllContacts,
      UserPermission.MaintainPersonalContacts,
      UserPermission.ViewPersonalContacts,
      UserPermission.ViewAllContacts,
    ])

    const canMaintainContacts = PermissionsService.hasPermission(UserPermission.MaintainProspectMasters)
    const canViewQuotes = PermissionsService.hasSomePermission([UserPermission.MaintainQuotes, UserPermission.ViewQuotes])

    const generalTab = {
      name: ProspectDetailsTabsName.General,
      template: <ProspectDetailsGeneralTab disabled={disabled} shouldSetDefaults={!prospect} />,
    }

    const remarksTab = {
      name: ProspectDetailsTabsName.Remarks,
      template: (
        <XtRemarks
          source={DocumentType.Prospect}
          canLoadMore={remarks.canLoadMore}
          loadMore={remarks.loadMore}
          onCommentsUpdate={remarks.updateComment}
          onCommentsSave={remarks.saveComment}
          comments={remarks.comments}
          username={remarks.username}
          textAreaOnChange={remarks.setNotes}
          textAreaValue={remarks.notes}
          textAreaName="Notes"
          disabled={disabled}
        />
      ),
    }

    const characteristicsTab = {
      name: ProspectDetailsTabsName.Characteristics,
      template: (
        <XtCharacteristics
          usedOnFilter={UsedOnValue.Prospect}
          onCreate={onCreateCharacteristic}
          characteristics={characteristics}
          onUpdate={onUpdateCharacteristic}
          onDelete={onDeleteCharacteristic}
          disabled={disabled}
        />
      ),
    }

    const documentsTab = {
      name: ProspectDetailsTabsName.Documents,
      template: (
        <XtDocuments
          disabled={disabled}
          onDocumentCreate={documents.saveDocument}
          onDocumentsCreate={documents.saveDocuments}
          onDocumentDelete={documents.deleteDocument}
          onDocumentUpdate={documents.updateDocument}
          tableState={documents.state}
          pagination={documents.pagination}
          mode={mode}
        />
      ),
    }

    const contactsTab = {
      hidden: !canViewContacts,
      name: ProspectDetailsTabsName.Contacts,
      template: (
        <div className={styles.contactTabSection}>
          <span className={mode === XtMode.New ? styles.contactTabList : ''}>
            <ContactList
              account_name={prospect?.name ?? ''}
              account_number={prospect?.number ?? ''}
              isMobile={isMobile}
              disabled={disabled}
              editableByPermissions={canMaintainContacts}
              mode={mode}
              infoMsg={ContactsInfoMessage}
            />
          </span>
        </div>
      ),
      // ),
    }

    const quotesTab = {
      hidden: !canViewQuotes,
      name: ProspectDetailsTabsName.Quotes,
      template: prospect && (
        <LazyModuleLoader
          className="xt-lazy-module-loader-tab-container"
          module={QuotesModule}
          render={({ QuotesListGeneral, defineQuoteFilters }: QuotesModuleDefinition) => (
            <QuotesListGeneral
              account={convertProspectToAccountFulfilled(prospect)}
              disabled={disabled}
              mode={mode}
              isMobile={isMobile}
              actions={QuotesListActionsEditMode}
              shouldOpenDetailsInDialog
              filters={defineQuoteFilters({ [QuoteFilterFieldName.Customer]: defineAutocompleteOption(prospect.number) })}
            />
          )}
        />
      ),
    }

    const activitiesTab = {
      name: ProspectDetailsTabsName.Activities,
      template: prospect && <ActivitiesTab disabled={disabled} parentType={TaskParentType.Prospect} parentNumber={prospectNumber} />,
    }

    const newModeTabs = [generalTab, contactsTab, remarksTab, characteristicsTab, documentsTab]
    const editOrViewModeTabs = [generalTab, contactsTab, activitiesTab, remarksTab, quotesTab, characteristicsTab, documentsTab]
    const tabs = prospect ? editOrViewModeTabs : newModeTabs

    return <XtTabs tabs={tabs} classNameRoot={styles.crmProspectDetailsTabs} />
  }
)
