import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useXtForm } from 'common/hooks/form/form'
import { useCoreModule } from 'core/core-module-hook'
import { FormCheckboxLabel, FormSelectField } from 'common/utils/form/form.components'
import { convertMode } from 'common/utils/mode.utils'
import { XtInput } from 'components/controls/xt-input/xt-input'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { useProductsModule } from 'products/products-module-hook'
import * as styles from './sites-details-dialog-main.module.scss'
import {
  ISitesDetailsDialogMain,
  ISitesDetailsDialogMainForm,
  SitesDetailsDialogMainFormField,
  SitesDetailsDialogMainFormLabel,
  SitesDetailsSiteOption,
} from './sites-details-dialog-main.types'
import { integerDecimalScale, rankingMaxValue, rankingMinValue, sitesDetailsDialogMainFormKey } from './sites-details-dialog-main.consts'
import { convertToOption, defineSitesMap, sitesDetailsDialogMainDefaults } from './sites-details-dialog-main.utils'
import { sitesDialogValidation } from './sites-details-dialog-main.validation'

export const SitesDetailsDialogMain: FC<ISitesDetailsDialogMain> = ({ mode, register, uomName, item, itemSite }) => {
  const { control, formChanges$, watch, setValue, reset, getValues, formState } = useXtForm<ISitesDetailsDialogMainForm>({
    mode: 'onBlur',
    validationSchema: sitesDialogValidation,
  })

  useEffect(() => {
    register(sitesDetailsDialogMainFormKey, formChanges$, null, { data: getValues(), state: formState }, reset)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formChanges$, register, getValues])

  const { ItemSitesService } = useProductsModule()
  const { SitesService, ErrorHandler } = useCoreModule()
  const sitesRef = useRef<Map<string, string>>(defineSitesMap(item))

  const [sites, setSites] = useState<SitesDetailsSiteOption[]>([])

  const { isViewMode, isNewMode, isEditMode } = convertMode(mode)

  const isSoldFromSite = watch(SitesDetailsDialogMainFormField.SoldFromSite)
  const currentSite = watch(SitesDetailsDialogMainFormField.Site)

  const fetchSites = useCallback(async (): Promise<SitesDetailsSiteOption[]> => {
    if (!isNewMode) {
      return [
        {
          id: itemSite?.site ?? '',
          label: itemSite?.site_description ?? '',
          transit_site: itemSite?.is_transit_site ?? false,
          description: itemSite?.site_description ?? '',
        },
      ]
    }

    const { data } = await SitesService.getAll(undefined, undefined)
    const items = isNewMode
      ? data.filter((value) => !sitesRef.current.has(value.site))
      : data.filter((value) => !sitesRef.current.has(value.site) || value.site === itemSite?.site)

    return items.map(convertToOption)
  }, [SitesService, isNewMode, itemSite?.is_transit_site, itemSite?.site, itemSite?.site_description])

  useEffect(() => {
    const init = async (): Promise<void> => {
      try {
        const fetched = await fetchSites()
        setSites(fetched)

        if (!itemSite) {
          reset(sitesDetailsDialogMainDefaults(null))
          return
        }

        const siteData = await ItemSitesService.getItemSite(item.item_number, itemSite.site)
        reset(sitesDetailsDialogMainDefaults(siteData))
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }

    void init()
  }, [ErrorHandler, item, uomName, reset, itemSite, ItemSitesService, fetchSites])

  const onSiteChange = useCallback(
    (selectedSite: SitesDetailsSiteOption | null) => {
      setValue(SitesDetailsDialogMainFormField.Site, selectedSite)
      if (selectedSite && selectedSite.transit_site) {
        setValue(SitesDetailsDialogMainFormField.SoldFromSite, false)
      }
    },
    [setValue]
  )

  return (
    <div className={styles.sitesDetailsDialogFormHeaderItemInfoContainer}>
      <div className={styles.sitesDetailsDialogFormHeaderItemInfo}>
        <XtInput value={item.item_number} disabled={true} disableTitle={true} label="Item #" />
        <div>UOM: {uomName}</div>
        <FormSelectField
          options={sites}
          name={SitesDetailsDialogMainFormField.Site}
          control={control}
          label={SitesDetailsDialogMainFormLabel.Site}
          clearable={true}
          disabled={isViewMode || isEditMode}
          onChange={onSiteChange}
        />
        <div className={styles.sitesDetailsDialogFormHeaderControls}>
          <div className={styles.sitesDetailsDialogFormHeaderCheckboxes}>
            <FormCheckboxLabel
              name={SitesDetailsDialogMainFormField.Active}
              control={control}
              label={SitesDetailsDialogMainFormLabel.Active}
              disabled={isViewMode}
            />
            <FormCheckboxLabel
              name={SitesDetailsDialogMainFormField.SoldFromSite}
              control={control}
              label={SitesDetailsDialogMainFormLabel.SoldFromSite}
              disabled={!currentSite || currentSite.transit_site || isViewMode}
            />
          </div>
          <DecimalFormField
            className={styles.sitesDetailsDialogFormHeaderRanking}
            control={control}
            name={SitesDetailsDialogMainFormField.Ranking}
            label={SitesDetailsDialogMainFormLabel.Ranking}
            allowNegative={false}
            fixedDecimalScale={integerDecimalScale}
            minValue={rankingMinValue}
            maxValue={rankingMaxValue}
            counters={true}
            disabled={isViewMode || !isSoldFromSite}
          />
        </div>
      </div>
      <div>Description: {item.description1}</div>
    </div>
  )
}
