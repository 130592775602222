import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { numberConverter } from 'common/utils/utils'
import { IInventoryLookup } from './inventory-list.types'
import { IItemsFilters } from '../../items/items-list/items-list.types'

export const itemNumberFilter: IItemsFilters = { showInactive: true }

export const inventoryColumns: IXtTableColumn<IInventoryLookup>[] = [
  {
    id: 'site',
    field: 'site',
    headerName: 'Site',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'item_number',
    field: 'item_number',
    headerName: 'Item',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'item_description1',
    field: 'item_description1',
    headerName: 'Description',
    flex: '1 0 250px',
    width: 250,
  },
  {
    id: 'uom',
    field: 'uom',
    headerName: 'UOM',
    flex: '1 0 110px',
    width: 110,
  },
  {
    id: 'lead_time',
    field: 'lead_time',
    headerName: 'LT',
    flex: '1 0 100px',
    width: 100,
  },
  {
    id: 'abc_class',
    field: 'abc_class',
    headerName: 'ABC Class',
    flex: '1 0 120px',
    width: 120,
  },
  {
    id: 'quantity_on_hand',
    field: 'quantity_on_hand',
    headerName: 'Current QOH Availability',
    flex: '1 0 270px',
    width: 270,
    converter: numberConverter,
  },
  {
    id: 'quantity_allocated',
    field: 'quantity_allocated',
    headerName: 'Allocated',
    flex: '1 0 150px',
    width: 150,
    converter: numberConverter,
  },
  {
    id: 'quantity_unallocated',
    field: 'quantity_unallocated',
    headerName: 'Unallocated',
    flex: '1 0 150px',
    width: 150,
    converter: numberConverter,
  },
  {
    id: 'on_order',
    field: 'on_order',
    headerName: 'On Order',
    flex: '1 0 140px',
    width: 140,
    converter: numberConverter,
  },
  {
    id: 'requests',
    field: 'requests',
    headerName: 'PO Requests',
    flex: '1 0 170px',
    width: 170,
    converter: numberConverter,
  },
  {
    id: 'reorder_level',
    field: 'reorder_level',
    headerName: 'Reorder Level',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
  {
    id: 'out_level',
    field: 'out_level',
    headerName: 'OUT Level',
    flex: '1 0 140px',
    width: 140,
    converter: numberConverter,
  },
  {
    id: 'available',
    field: 'available',
    headerName: 'Available',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
]

export const inventoryAvailabilityPageTitle = 'xTuple - Inventory Availability'

export const inventoryAvailabilityWorkbenchPageTitle = 'Inventory Availability'
