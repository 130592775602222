import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { ICostingItemCostsUtilsService } from './item-costs/item-costs-utils.service'
import { inventoryHistoryItemCostsUtilsServiceToken } from './item-costs/item-costs.constants'

export type InventoryHistoryModuleDefinition = typeof import('./inventory-history.exports')

export interface IInventoryHistoryInterfaces {
  CostingItemCostsUtilsService: ICostingItemCostsUtilsService
}

export const InventoryHistoryModule: ILazyModule<InventoryHistoryModuleDefinition> = {
  name: inventoryHistoryItemCostsUtilsServiceToken,
  resolver: async () => import('./inventory-history.exports'),
  initializer: () => {},
  dependencies: [],
}
