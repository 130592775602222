import { Overwrite } from 'utility-types'
import { IItem, IItemSite } from 'products/items/items.types'
import { SingleFormRegister } from 'common/hooks/form/multiple-form-manager/multiple-form-manager.types'
import { XtMode } from 'common/common.types'
import { sitesDetailsDialogCheckboxesFormKey } from './sites-details-dialog-checkboxes.consts'

export interface ISitesDetailsDialogCheckboxes {
  mode: XtMode
  register: SingleFormRegister<typeof sitesDetailsDialogCheckboxesFormKey, SitesDetailsDialogCheckboxesForm>
  item: IItem
  itemSite: IItemSite | null
  isJobCosted: boolean
  isTransitSite: boolean
}

export type ItemSiteCheckboxes = Pick<
  IItemSite,
  | SitesDetailsDialogCheckboxesFormField.WoSuppliedAtSite
  | SitesDetailsDialogCheckboxesFormField.CreateWos
  | SitesDetailsDialogCheckboxesFormField.PoSuppliedAtSite
  | SitesDetailsDialogCheckboxesFormField.CreatePrs
  | SitesDetailsDialogCheckboxesFormField.CreateSoprs
  | SitesDetailsDialogCheckboxesFormField.CreateSopos
  | SitesDetailsDialogCheckboxesFormField.Dropship
  | SitesDetailsDialogCheckboxesFormField.SuppressAutomaticOrders
>

export enum SitesDetailsDialogCheckboxesFormField {
  WoSuppliedAtSite = 'wo_supplied_at_site',
  CreateWos = 'create_wos',
  PoSuppliedAtSite = 'po_supplied_at_site',
  CreatePrs = 'create_prs',
  CreateSoprs = 'create_soprs',
  CreateSopos = 'create_sopos',
  Dropship = 'dropship',
  SuppressAutomaticOrders = 'suppress_automatic_orders',
}

export enum SitesDetailsDialogCheckboxesFormLabel {
  WoSuppliedAtSite = 'Site Can Manufacture this Item',
  CreateWos = 'Create Work Order linked to Sales Order',
  PoSuppliedAtSite = 'Site Can Purchase this Item',
  CreatePrs = 'Create Purchase Requests for Work Order requirements',
  CreateSoprs = 'Create Purchase Requests for Sales Orders',
  CreateSopos = 'Create Purchase Orders linked to Sales Orders',
  Dropship = 'Drop ship Items by default',
  SuppressAutomaticOrders = 'Suppress Automatic Order Creation',
}

export type SitesDetailsDialogCheckboxesForm = Overwrite<
  ItemSiteCheckboxes,
  {
    [SitesDetailsDialogCheckboxesFormField.WoSuppliedAtSite]: boolean
    [SitesDetailsDialogCheckboxesFormField.CreateWos]: boolean
    [SitesDetailsDialogCheckboxesFormField.PoSuppliedAtSite]: boolean
    [SitesDetailsDialogCheckboxesFormField.CreatePrs]: boolean
    [SitesDetailsDialogCheckboxesFormField.CreateSoprs]: boolean
    [SitesDetailsDialogCheckboxesFormField.CreateSopos]: boolean
    [SitesDetailsDialogCheckboxesFormField.Dropship]: boolean
    [SitesDetailsDialogCheckboxesFormField.SuppressAutomaticOrders]: boolean
  }
>

export type SitesDetailsDialogCheckboxesFormDisableState = Overwrite<
  ItemSiteCheckboxes,
  {
    [SitesDetailsDialogCheckboxesFormField.WoSuppliedAtSite]: boolean
    [SitesDetailsDialogCheckboxesFormField.CreateWos]: boolean
    [SitesDetailsDialogCheckboxesFormField.PoSuppliedAtSite]: boolean
    [SitesDetailsDialogCheckboxesFormField.CreatePrs]: boolean
    [SitesDetailsDialogCheckboxesFormField.CreateSoprs]: boolean
    [SitesDetailsDialogCheckboxesFormField.CreateSopos]: boolean
    [SitesDetailsDialogCheckboxesFormField.Dropship]: boolean
    [SitesDetailsDialogCheckboxesFormField.SuppressAutomaticOrders]: boolean
  }
>
