// extracted by mini-css-extract-plugin
export var logout = "personal-profile_logout__WyTOK";
export var logoutText = "personal-profile_logoutText__5fRF7";
export var hoverLogout = "personal-profile_hoverLogout__IfmrY";
export var logoutSidebar = "personal-profile_logoutSidebar__aF2S8";
export var username = "personal-profile_username__Ih1C-";
export var email = "personal-profile_email__B7-Md";
export var personalProfileDialog = "personal-profile_personalProfileDialog__sB+y+";
export var profileCard = "personal-profile_profileCard__PJ-ET";
export var paper = "personal-profile_paper__OSGo+";
export var menuList = "personal-profile_menuList__enXLQ";