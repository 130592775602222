import * as React from 'react'
import { Controller, Control } from 'react-hook-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IFilterFormState, IFilter } from '../filter.types'
import * as styles from '../filter.module.scss'

export function makeRadioFilter<TOption extends IXtAutocompleteOption>(
  filter: IFilter<TOption>,
  control: Control<IFilterFormState>
): JSX.Element {
  if (!filter.options) {
    throw new Error('Radio options for radio pagefilter are undefined')
  }

  return (
    <Controller
      key={`filter-${filter.fieldName}-controller`}
      control={control}
      name={filter.fieldName}
      render={({ field: { onChange, onBlur, value } }) => (
        <RadioGroup id={`filter-${filter.fieldName}`}>
          <p>{filter.label}</p>
          {(filter.options ?? []).map((option, index) => (
            <FormControlLabel
              control={
                <Radio color="primary" classes={{ root: styles.muiRadioRoot, checked: styles.muiRadioChecked }} name={filter.fieldName} />
              }
              label={option.label}
              value={String(index)}
              key={option.id}
              onChange={(e) => onChange((e.target as HTMLInputElement).value)}
              onBlur={onBlur}
              checked={value === String(index)}
            />
          ))}
        </RadioGroup>
      )}
    />
  )
}
