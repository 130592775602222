// extracted by mini-css-extract-plugin
export var documentsContent = "documents_documentsContent__4OMKo";
export var listItem = "documents_listItem__476vq";
export var listItemName = "documents_listItemName__niSJU";
export var listItemActions = "documents_listItemActions__KwyNw";
export var listItemContainer = "documents_listItemContainer__Bh47O";
export var listItemWrapper = "documents_listItemWrapper__Myh66";
export var listWrapper = "documents_listWrapper__aWseW";
export var noDataAvailableMessage = "documents_noDataAvailableMessage__IydYs";
export var documentsList = "documents_documentsList__UvSOr";
export var documentPreviewImg = "documents_documentPreviewImg__bibQ3";
export var documentPreviewDialogRoot = "documents_documentPreviewDialogRoot__ceoRz";
export var documentPreviewDialogContainer = "documents_documentPreviewDialogContainer__DE9cv";
export var closeIcon = "documents_closeIcon__Z1FNw";
export var closeIconSize = "documents_closeIconSize__s-4I6";
export var documentPreviewLoadingSpinner = "documents_documentPreviewLoadingSpinner__vgiuM";