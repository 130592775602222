import { ICoreModuleServices } from './core.module'
import { IDashboardService } from './services/dashboard.service'
import { INavigationService } from './services/navigation.service'
import { Injector } from './injector/injector.service'
import {
  coreUtilsServiceToken,
  crmRolesServiceToken,
  dashboardServiceToken,
  errorHandlerServiceToken,
  expenseCategoryServiceToken,
  glAccountsServiceToken,
  invoiceServiceToken,
  navigationServiceToken,
  operationTypesServiceToken,
  phoneServiceToken,
  projectServiceToken,
  salesRepServiceToken,
  sitesServiceToken,
  sourceServiceToken,
  standardOperationServiceToken,
  termsServiceToken,
  transferOrderServiceToken,
  uomServiceToken,
  workCenterServiceToken,
  revisionServiceToken,
  inventoryAvailabilityServiceToken,
  issueOrderServiceToken,
  workOrderServiceToken,
  pageFilterServiceToken,
  pageFilterUtilsServiceToken,
} from './core.constants'
import { IGlAccountsService } from './services/gl-accounts.service'
import { IWorkingCenterService } from './services/work-center.service'
import { IStandardOperationService } from './services/standard-operation.service'
import { IOperationTypesService } from './services/operation-types.service'
import { IUomService } from './services/uom.service'
import { ICRMRolesService } from './services/crm-role.service'
import { ICoreUtilsService } from './services/utils.service'
import { IErrorHandler } from './services/error-handler.service'
import { ISourceService } from './services/source.service'
import { ITermsService } from './services/terms.service'
import { ISitesService } from './services/sites.service'
import { ISalesRepService } from './services/sales-rep.service'
import { IProjectService } from './services/project.service'
import { IToastService } from '../toast/toast.service'
import { toastServiceToken } from '../toast/toast.constants'
import { moduleHookResolver } from './react-lazy-modules/react-lazy-modules.utils'
import { IPhoneService } from './services/phone.service'
import { IExpenseCategoryService } from './services/expense-category.service'
import { IRevisionService } from './services/revision.service'
import { IInvoiceService } from './services/invoice.service'
import { ITransferOrderService } from './services/transfer-order.service'
import { IInventoryAvailabilityService } from './services/inventory-availability.service'
import { IIssueOrderService } from './services/issue-order.service'
import { IWorkingOrderService } from './services/work-order.service'
import { IPageFilterService } from './services/pagefilters/pagefilters.service'
import { IPageFilterUtilsService } from './services/pagefilters/pagefilters-utils.service'

function resolveCoreModule(): ICoreModuleServices {
  const DashboardService = Injector.resolve<IDashboardService>(dashboardServiceToken)
  const NavigationService = Injector.resolve<INavigationService>(navigationServiceToken)
  const GlAccountsService = Injector.resolve<IGlAccountsService>(glAccountsServiceToken)
  const ExpenseCategoryService = Injector.resolve<IExpenseCategoryService>(expenseCategoryServiceToken)
  const ErrorHandler = Injector.resolve<IErrorHandler>(errorHandlerServiceToken)
  const WorkCenterService = Injector.resolve<IWorkingCenterService>(workCenterServiceToken)
  const StandardOperationService = Injector.resolve<IStandardOperationService>(standardOperationServiceToken)
  const OperationTypesService = Injector.resolve<IOperationTypesService>(operationTypesServiceToken)
  const UomService = Injector.resolve<IUomService>(uomServiceToken)
  const CRMRolesService = Injector.resolve<ICRMRolesService>(crmRolesServiceToken)
  const CoreUtilsService = Injector.resolve<ICoreUtilsService>(coreUtilsServiceToken)
  const SourceService = Injector.resolve<ISourceService>(sourceServiceToken)
  const TermsService = Injector.resolve<ITermsService>(termsServiceToken)
  const SitesService = Injector.resolve<ISitesService>(sitesServiceToken)
  const SalesRepService = Injector.resolve<ISalesRepService>(salesRepServiceToken)
  const ProjectService = Injector.resolve<IProjectService>(projectServiceToken)
  const ToastService = Injector.resolve<IToastService>(toastServiceToken)
  const PhoneService = Injector.resolve<IPhoneService>(phoneServiceToken)
  const RevisionService = Injector.resolve<IRevisionService>(revisionServiceToken)
  const TransferOrderService = Injector.resolve<ITransferOrderService>(transferOrderServiceToken)
  const InventoryAvailabilityService = Injector.resolve<IInventoryAvailabilityService>(inventoryAvailabilityServiceToken)
  const InvoiceService = Injector.resolve<IInvoiceService>(invoiceServiceToken)
  const IssueOrderService = Injector.resolve<IIssueOrderService>(issueOrderServiceToken)
  const WorkOrderService = Injector.resolve<IWorkingOrderService>(workOrderServiceToken)
  const PageFilterService = Injector.resolve<IPageFilterService>(pageFilterServiceToken)
  const PageFilterUtilsService = Injector.resolve<IPageFilterUtilsService>(pageFilterUtilsServiceToken)

  return {
    ToastService,
    ErrorHandler,
    WorkCenterService,
    StandardOperationService,
    OperationTypesService,
    UomService,
    DashboardService,
    NavigationService,
    CoreUtilsService,
    CRMRolesService,
    GlAccountsService,
    ExpenseCategoryService,
    SourceService,
    SitesService,
    TermsService,
    SalesRepService,
    ProjectService,
    PhoneService,
    RevisionService,
    TransferOrderService,
    InventoryAvailabilityService,
    InvoiceService,
    IssueOrderService,
    WorkOrderService,
    PageFilterService,
    PageFilterUtilsService,
  }
}

export const useCoreModule = moduleHookResolver<ICoreModuleServices>(resolveCoreModule)
