import React, { FC, useEffect, useState } from 'react'
import { useCoreModule } from 'core/core-module-hook'
import { cls } from 'common/utils/utils'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { XtMode } from 'common/common.types'
import { useDocumentsModule } from '../../documents-module-hook'
import { DocumentType, IAttachedDocumentWithFile } from '../../documents.types'

import * as styles from './documents-dialog.module.scss'

interface IDocumentsDialogParams {
  disabled: boolean
  mode: XtMode
  onClose: VoidFunction
  type: DocumentType
  onConfirm(documents: IAttachedDocumentWithFile[], entityId: string | null, sourceNumber: string | null): Promise<void>
  entityId: string | null
  sourceNumber: string | null
  defaultDocuments?: IAttachedDocumentWithFile[]
}

export const XtDocumentsDialog: FC<IDocumentsDialogParams> = ({
  disabled = false,
  onClose,
  onConfirm,
  entityId,
  type,
  sourceNumber,
  mode,
  defaultDocuments,
}) => {
  const { ErrorHandler } = useCoreModule()
  const { useDocuments, XtDocuments } = useDocumentsModule()
  const {
    isDirty,
    saveDocument,
    saveDocuments,
    updateDocument,
    deleteDocument,
    getUnsavedDocuments,
    state: documentsState,
    pagination,
    reset,
  } = useDocuments(type, sourceNumber)

  const [submitting, setSubmitting] = useState<boolean>(false)

  const onCancel: VoidFunction = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave the dialog? Updates will not be applied.')) {
      return
    }
    onClose()
  }

  const onSave = async (): Promise<void> => {
    try {
      setSubmitting(true)
      await onConfirm(getUnsavedDocuments(), entityId, sourceNumber)
      onClose()
    } catch (e) {
      ErrorHandler.handleError(e)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    if (!sourceNumber && defaultDocuments) {
      reset(defaultDocuments)
    }
  }, [defaultDocuments, reset, sourceNumber])

  const title: string = entityId ? `Documents ${entityId}` : 'New Documents'

  return (
    <div className={styles.documentsDialog}>
      <div className={cls('xt-page-header', styles.headerContent)}>
        <h1 className="xt-page-title">{title}</h1>
        <div className={styles.buttons}>
          <XtButton label="Cancel" onClick={onCancel} />
          <XtButton label="Save" onClick={onSave} disabled={!isDirty || disabled || submitting} />
        </div>
      </div>

      <XtDocuments
        mode={mode}
        disabled={disabled}
        onDocumentCreate={saveDocument}
        onDocumentsCreate={saveDocuments}
        onDocumentDelete={deleteDocument}
        onDocumentUpdate={updateDocument}
        tableState={documentsState}
        pagination={pagination}
      />
    </div>
  )
}
