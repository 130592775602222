import { ITableState } from 'common/hooks/useTable'
import { ITablePagination } from 'common/types/table.types'
import { IAttachedDocument, IAttachedDocumentWithFile } from 'documents/documents.types'
import { XtMode } from 'common/common.types'

export interface IAttachedDocumentsDescription {
  onDocumentCreate(attachedDocument: IAttachedDocumentWithFile): Promise<boolean>
  onDocumentsCreate(document: IAttachedDocumentWithFile[]): Promise<boolean>
  onDocumentUpdate(attachedDocument: IAttachedDocument): Promise<boolean>
  onDocumentDelete(attachedDocument: IAttachedDocument): Promise<void>
  mode: XtMode
  disabled?: boolean
  tableState: ITableState<IAttachedDocument, {}>
  pagination: ITablePagination
}

export interface IAttachedDocumentPreviewImage {
  url: string | null
  loading: boolean
  open: boolean
}

export enum AttachedDocumentAction {
  Delete = 'Delete',
  Download = 'Download',
  Preview = 'Preview',
  Edit = 'Edit',
}

export type AttachedDocumentActionCallback = (item: IAttachedDocument, action: AttachedDocumentAction) => void
