import * as React from 'react'
import { FC, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form/dist/types'
import { FormDatePicker, FormField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { parseAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import * as styles from './routing-details-form.module.scss'
import { IRoutingDetailsFormData, RoutingDetailsFormField } from '../routing-details.types'
import { renderWipLocationOption } from '../../routing-item/routing-item-tabs/routing-item-general-tab/routing-item-general-tab'

interface IRoutingDetailsFormProps {
  disabled: boolean
  leadTime: number
  formMethods: UseFormReturn<IRoutingDetailsFormData>
}

export const RoutingDetailsForm: FC<IRoutingDetailsFormProps> = ({ disabled, leadTime, formMethods }) => {
  const { DocumentsUtilsService } = useDocumentsModule()

  const { control, watch } = formMethods
  const site = watch(RoutingDetailsFormField.FinalLocationSite)

  const filters = useMemo(() => (site ? { site: parseAutocompleteOption(site) } : {}), [site])

  return (
    <div className={styles.routingDetailsFields}>
      <FormField name={RoutingDetailsFormField.RevisionNumber} control={control} disabled label="Revision" />
      <FormField label="Document #" name={RoutingDetailsFormField.DocumentNumber} control={control} disabled={disabled} />
      <FormDatePicker label="Revision Date" name={RoutingDetailsFormField.RevisionDate} control={control} disabled={disabled} />

      <FormXtAutocomplete
        disabled={disabled}
        control={control}
        name={RoutingDetailsFormField.FinalLocationSite}
        label="Final Location Site"
        loadOptions={DocumentsUtilsService.loadSites}
      />
      <FormXtAutocomplete
        disabled={disabled}
        control={control}
        name={RoutingDetailsFormField.FinalLocation}
        label="Final Location"
        loadOptions={DocumentsUtilsService.loadDocumentListOptions}
        renderOption={renderWipLocationOption}
        filters={filters}
      />
      {leadTime >= 0 && <div>{`Prod. Lead Time: ${leadTime}`}</div>}
    </div>
  )
}
