import * as yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { DefaultLocationType, SitesDetailsDialogLocationFormField } from './sites-details-dialog-location.types'

export const sitesDialogValidation = yup.object().shape({
  [SitesDetailsDialogLocationFormField.ReceiveLocation]: yup
    .object()
    .when([SitesDetailsDialogLocationFormField.UseDefaultLocation, SitesDetailsDialogLocationFormField.DefaultLocationType], {
      is: (useDefaultLocation, defaultLocationType) => useDefaultLocation && defaultLocationType === DefaultLocationType.Location,
      then: yup.object().required(validationMessage.isRequired),
    })
    .nullable(),
  [SitesDetailsDialogLocationFormField.Location]: yup
    .object()
    .when([SitesDetailsDialogLocationFormField.UseDefaultLocation, SitesDetailsDialogLocationFormField.DefaultLocationType], {
      is: (useDefaultLocation, defaultLocationType) => useDefaultLocation && defaultLocationType === DefaultLocationType.Location,
      then: yup.object().required(validationMessage.isRequired),
    })
    .nullable(),
  [SitesDetailsDialogLocationFormField.IssueLocation]: yup
    .object()
    .when([SitesDetailsDialogLocationFormField.UseDefaultLocation, SitesDetailsDialogLocationFormField.DefaultLocationType], {
      is: (useDefaultLocation, defaultLocationType) => useDefaultLocation && defaultLocationType === DefaultLocationType.Location,
      then: yup.object().required(validationMessage.isRequired),
    })
    .nullable(),
  [SitesDetailsDialogLocationFormField.UserDefinedLocation]: yup
    .string()
    .when([SitesDetailsDialogLocationFormField.UseDefaultLocation, SitesDetailsDialogLocationFormField.DefaultLocationType], {
      is: (useDefaultLocation, defaultLocationType) => useDefaultLocation && defaultLocationType === DefaultLocationType.UserDefined,
      then: yup.string().required(validationMessage.isRequired),
    })
    .nullable(),
})
