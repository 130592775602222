import { MouseEvent } from 'react'
import { IDocumentUpdateEvent } from 'documents/documents.types'
import { ITaskUpdateEvent } from 'tasks/tasks.types'
import { IContactUpdateEvent } from 'contacts/contacts.types'
import { XtMode } from 'common/common.types'
import { IRoles } from '../../accounts.types'

export interface IActiveRolesSection {
  roles: IRoles
  accountNumber: string
  accountName: string | null
  onChange(changes: IAccountDetailsChanges): void
  disabled: boolean
}

export type OptionRole = {
  crm_role: boolean
  name: string
  label: string
  canAdd: boolean
  canOpen: boolean
  canDelete: boolean
  modeToOpen: XtMode
}

export interface IRolesOptionsState {
  listOptions: OptionRole[]
  selectedOptions: OptionRole[]
  loading: boolean
}

export enum AutocompleteChangeAction {
  CreateOption = 'create-option',
  SelectOption = 'select-option',
  RemoveOption = 'remove-option',
  Clear = 'clear',
  Blur = 'blur',
}

export enum ConfirmationDialogAction {
  DeleteCustomer,
  DeleteProspect,
  Test,
  ConvertProspect,
  CreateCustomer,
  CreateProspect,
}
export interface IConfirmationDialogState {
  message: string
  action: ConfirmationDialogAction
}

export interface ITagComponentProps {
  className: string
  disabled: boolean
  key: number
  'data-tag-index': number
  tabIndex: number
  onDelete(event: MouseEvent<HTMLDivElement, MouseEvent>): void
}

export interface IAccountDetailsChanges {
  accountUpdate: boolean
  documentsUpdate: IDocumentUpdateEvent | null
  tasksUpdate: ITaskUpdateEvent | null
  contactsUpdate: IContactUpdateEvent | null
}

export interface IActiveRolesPermissions {
  canViewCustomer: boolean
  canEditCustomer: boolean
  canViewProspect: boolean
  canEditProspect: boolean
}
