import { ISortOption, SortDirection, TableSortingFn } from './table-head.types'

export function handleColumnHeaderClick(sortOption: ISortOption, onSortLabelClick: TableSortingFn | undefined): void {
  if (onSortLabelClick) {
    void onSortLabelClick(sortOption)
  }
}

export function checkSortDirection(sortOption: ISortOption | undefined): SortDirection | undefined {
  if (!sortOption || !sortOption.sortDirection) {
    return SortDirection.Ascending
  }
  if (sortOption.sortDirection === SortDirection.Descending) {
    return undefined
  }
  return SortDirection.Descending
}
