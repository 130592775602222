import React, { useEffect , FC } from 'react'
import { Prompt } from 'react-router'
import { cls } from 'common/utils/utils'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { convertMode } from 'common/utils/mode.utils'
import { useCoreModule } from 'core/core-module-hook'
import { useXtForm } from 'common/hooks/form/form'
import { confirmationMessages } from 'common/constants'
import { RoutingItemTabs } from './routing-item-tabs/routing-item-tabs'
import { convertToRoutingItem, defineRoutingItemFormData } from './routing-item.utils'
import { validationSchema } from './routing-item.validation'
import { IRoutingItemComponent, RoutingItemFormData } from './routing-item.types'
import * as styles from './routing-item.module.scss'
import { RoutingItemForm } from './routing-item-form/routing-item-form'
import { useProductsModule } from '../../products-module-hook'

export const RoutingItem: FC<IRoutingItemComponent> = ({ onClose, data, mode, parentItem, onSubmit }) => {
  const { XtItemNumber } = useProductsModule()
  const { ErrorHandler } = useCoreModule()

  const { isViewMode } = convertMode(mode)

  const formMethods = useXtForm<RoutingItemFormData>({
    defaultValues: defineRoutingItemFormData(data),
    mode: 'onBlur',
    validationSchema: validationSchema,
  })

  const {
    formState: { isSubmitting, isDirty, isSubmitSuccessful },
    handleSubmit,
  } = formMethods
  const showPrompt = !isSubmitSuccessful && isDirty
  const disabled = isViewMode || isSubmitting

  const save = async (formData: RoutingItemFormData): Promise<void> => {
    try {
      const payload = convertToRoutingItem(formData, data)
      await onSubmit(payload, mode)
      onClose()
    } catch (e) {
      ErrorHandler.handleError(e as Error)
    }
  }

  const close = (): void => {
    // TODO implement confirmation dialog
    // eslint-disable-next-line no-restricted-globals,no-alert
    if (isDirty && !confirm(confirmationMessages.unsavedChanges)) {
      return
    }
    onClose()
  }

  useEffect(() => {
    //exit only after form has been sucessfully submiited
    if (isSubmitSuccessful) onClose()
  }, [isSubmitSuccessful])

  return (
    <div className="xt-dialog-scrollable">
      <form className={styles.routingItemDialog}>
        <div className={styles.itemHeaderBlock}>
          <XtItemNumber value={parentItem} />
          <div className={cls(isViewMode && styles.buttonsViewMode, styles.buttons)}>
            <XtButton onClick={close} label="Cancel" />
            <XtButton
              loading={isSubmitting}
              label="Save"
              onClick={handleSubmit(save)}
              disabled={disabled || !isDirty}
              hidden={isViewMode}
            />
          </div>
        </div>
        <div className={styles.routingItemContent}>
          <div className={styles.leftSideForm}>
            <RoutingItemForm
              initialOperationType={data?.operation_type ?? null}
              disabled={disabled}
              formMethods={formMethods}
              sequenceNumber={data?.sequence_number}
            />
          </div>
          <div className={styles.routingTabsContainer}>
            <RoutingItemTabs disabled={disabled} uom={data?.inventory_uom} formMethods={formMethods} />
          </div>
        </div>
      </form>
      <Prompt when={showPrompt} message={confirmationMessages.unsavedChanges} />
    </div>
  )
}
