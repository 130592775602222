import React, { ReactElement } from 'react'
import { XtButton } from '../../../buttons/xt-button/xt-button'
import * as styles from './phone-invalid-dialog.module.scss'

interface IInvalidPhoneDialog {
  onClose: () => void
}

export function PhoneInvalidDialog({ onClose }: IInvalidPhoneDialog): ReactElement {
  return (
    <div className={styles.invalidPhoneContainer}>
      <div className={styles.mainContainer}>
        <p>
          This phone number does not appear to be correctly formatted. Please ensure it starts with a plus sign (+) and includes the country
          code, for example: +17573614022
        </p>
        <div className={styles.controlButton}>
          <XtButton label="OK" onClick={onClose} />
        </div>
      </div>
    </div>
  )
}
