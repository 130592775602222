// extracted by mini-css-extract-plugin
export var root = "pagefilter_root__9pxRX";
export var xtContent = "pagefilter_xt-content__AFFoB";
export var xtContentWithRemarks = "pagefilter_xt-content-with-remarks__pNJm0";
export var muiFormHelperTextRoot = "pagefilter_MuiFormHelperText-root__onfqW";
export var xtListPageContainer = "pagefilter_xt-list-page-container__w6O8w";
export var muiInputBaseRoot = "pagefilter_MuiInputBase-root__0pgEu";
export var muiTextFieldRoot = "pagefilter_MuiTextField-root__DCF8t";
export var muiTablePaginationRoot = "pagefilter_MuiTablePagination-root__Y8TJm";
export var muiTablePaginationSelectRoot = "pagefilter_MuiTablePagination-selectRoot__XEdwU";
export var muiTablePaginationSelect = "pagefilter_MuiTablePagination-select__VtViI";
export var muiStepperRoot = "pagefilter_MuiStepper-root__xWu5-";
export var muiStepperHorizontal = "pagefilter_MuiStepper-horizontal__aXiKB";
export var muiStepIconRoot = "pagefilter_MuiStepIcon-root__OWeXn";
export var muiStepIconActive = "pagefilter_MuiStepIcon-active__SECim";
export var muiStepIconCompleted = "pagefilter_MuiStepIcon-completed__2zew5";
export var muiListItemRoot = "pagefilter_MuiListItem-root__f6Kr2";
export var muiSelected = "pagefilter_Mui-selected__FnFch";
export var xtPageTitle = "pagefilter_xt-page-title__REP7s";
export var xtSubPageTitle = "pagefilter_xt-subPage-title__Ev3Gp";
export var xtSectionBorder = "pagefilter_xt-section-border__6Ey7l";
export var xtPageHeader = "pagefilter_xt-page-header__wf9Tg";
export var xtDialogScrollable = "pagefilter_xt-dialog-scrollable__c7Gc-";
export var reactToastNotificationsContainer = "pagefilter_react-toast-notifications__container__GOvc8";
export var xtScrollbarStyle = "pagefilter_xt-scrollbar-style__ApPc2";
export var xtModalDetailsContent = "pagefilter_xt-modal-details-content__YnUG+";
export var xtDialogDetailsContent = "pagefilter_xt-dialog-details-content__K6KGR";
export var xtLazyModuleLoaderTabContainer = "pagefilter_xt-lazy-module-loader-tab-container__8NVU7";
export var xtTextTruncated = "pagefilter_xt-text-truncated__o2B0z";
export var xtSummaryContent = "pagefilter_xt-summary-content__760bW";
export var xtSummaryLabel = "pagefilter_xt-summary-label__p1duR";
export var visibleHidden = "pagefilter_visible-hidden__dTlWE";
export var headerBottomMargin = "pagefilter_header-bottom-margin__fcTNe";
export var xtDialogHeaderLogoAlign = "pagefilter_xt-dialog-header-logoAlign__+gfRn";
export var xtStickyHeader = "pagefilter_xt-sticky-header__auXB2";
export var noBorder = "pagefilter_no-border__bljc3";
export var muiOutlinedInputNotchedOutline = "pagefilter_MuiOutlinedInput-notchedOutline__THf+q";
export var filterContent = "pagefilter_filterContent__ubugI";
export var filterSection = "pagefilter_filterSection__Nyp1J";
export var notificationDot = "pagefilter_notificationDot__0qUNZ";
export var closeIcon = "pagefilter_closeIcon__C1Tcm";
export var closeIconSize = "pagefilter_closeIconSize__BEWlN";
export var presetContent = "pagefilter_presetContent__Hw9xX";