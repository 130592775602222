import * as yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { QuantityOnHandListFilterField } from './quantity-on-hand-list-filter.types'
import { maxPatternStringLength } from './quantity-on-hand-list-filter.constants'

export const quantityOnHandListFilterValidationScheme = yup.object().shape(
  {
    [QuantityOnHandListFilterField.ClassCodePattern]: yup
      .string()
      .nullable()
      .max(maxPatternStringLength)
      .typeError(validationMessage.invalidFormat),
    [QuantityOnHandListFilterField.CostCategoryPattern]: yup
      .string()
      .nullable()
      .max(maxPatternStringLength)
      .typeError(validationMessage.invalidFormat),
    [QuantityOnHandListFilterField.ItemGroupPattern]: yup
      .string()
      .nullable()
      .max(maxPatternStringLength)
      .typeError(validationMessage.invalidFormat),
  },
  []
)
