import * as yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { ItemDetailsFormField } from './item-details.types'

export const itemsDetailsValidation = yup.object().shape({
  [ItemDetailsFormField.ItemNumber]: yup
    .string()
    .required(validationMessage.isRequired)
    .test('notEmptyOrSpaces', validationMessage.invalidItemNumber, (value): boolean => {
      if (typeof value === 'string') {
        return value.trim() !== ''
      }
      return false
    }),
  [ItemDetailsFormField.ItemType]: yup.object().required(validationMessage.isRequired).nullable(),
  [ItemDetailsFormField.ClassCode]: yup.object().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [ItemDetailsFormField.InventoryUOM]: yup.object().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [ItemDetailsFormField.ProductCategory]: yup.object().when(ItemDetailsFormField.ItemIsSold, {
    is: true,
    then: yup.object().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
    otherwise: yup.object().nullable(),
  }),
  [ItemDetailsFormField.SellingUOM]: yup
    .object()
    .nullable()
    .when(ItemDetailsFormField.ItemIsSold, {
      is: true,
      then: yup.object().nullable().required(validationMessage.isRequired),
    }),
})
