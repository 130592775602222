import { PermissionGuard } from 'auth/permission.guard'
import { homeRoutePath } from 'home/home.constants'
import { UserPermission } from 'auth/auth.types'

export class ShippingGuard extends PermissionGuard {
  public redirectTo = homeRoutePath
  public permissions = [
    UserPermission.RecallOrders,
    UserPermission.ShipOrders,
    UserPermission.IssueStockToShipping,
    UserPermission.PrintInvoices,
    UserPermission.MaintainSalesOrders,
    UserPermission.ViewSalesOrders,
    UserPermission.MaintainTransferOrders,
    UserPermission.ViewTransferOrders,
    UserPermission.PrintBillsOfLading,
    UserPermission.PrintPackingLists,
  ]
}
