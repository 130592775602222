import React, { FC, memo } from 'react'
import { XtMode } from 'common/common.types'
import { DocumentType, UsedOnValue } from 'documents/documents.types'
import { IDocumentsHook } from 'documents/documents-hook'
import { ICharacteristicsHook } from 'characteristics/characteristics-hook'
import { IRemarksHook } from 'comments/remarks/remarks-hook'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { useCharacteristicsModule } from 'characteristics/characteristics-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCommentsModule } from 'comments/comments-module-hook'
import { IXtTab } from 'components/xt-tabs/xt-tabs.types'
import { convertMode } from 'common/utils/mode.utils'
import { RegistrationList } from './registration/registration-list/registration-list'
import { LotSerialDialogTab } from './lot-serial-details.types'

export interface ILotSerialDetailsTabsParams {
  mode: XtMode
  itemNumber: string
  lotSerialNumber: string
  characteristicsState: ICharacteristicsHook
  documentsState: IDocumentsHook
  remarksState: IRemarksHook
}

export const LotSerialDetailsTabs: FC<ILotSerialDetailsTabsParams> = memo(
  ({ mode, remarksState, characteristicsState, documentsState, itemNumber, lotSerialNumber }) => {
    const { isEditMode, isViewMode } = convertMode(mode)

    const { XtCharacteristics } = useCharacteristicsModule()
    const { XtDocuments } = useDocumentsModule()
    const { XtRemarks } = useCommentsModule()

    const tabs: IXtTab[] = [
      {
        name: LotSerialDialogTab.Characteristics,
        template: (
          <XtCharacteristics
            usedOnFilter={UsedOnValue.LotSerial}
            disabled={isViewMode}
            onCreate={characteristicsState.createCharacteristic}
            onUpdate={characteristicsState.updateCharacteristic}
            onDelete={characteristicsState.deleteCharacteristic}
            characteristics={characteristicsState.characteristics}
          />
        ),
      },
      {
        name: LotSerialDialogTab.Registrations,
        hidden: !isEditMode,
        template: isEditMode && <RegistrationList itemNumber={itemNumber} lotSerialNumber={lotSerialNumber} />,
      },
      {
        name: LotSerialDialogTab.Remarks,
        template: (
          <XtRemarks
            source={DocumentType.LotSerial}
            loadMore={remarksState.loadMore}
            canLoadMore={remarksState.canLoadMore}
            textAreaOnChange={remarksState.setNotes}
            textAreaValue={remarksState.notes}
            textAreaName="Notes"
            onCommentsUpdate={remarksState.updateComment}
            onCommentsSave={remarksState.saveComment}
            comments={remarksState.comments}
            username={remarksState.username}
            disabled={isViewMode}
          />
        ),
      },
      {
        name: LotSerialDialogTab.Documents,
        template: (
          <XtDocuments
            mode={mode}
            onDocumentCreate={documentsState.saveDocument}
            onDocumentsCreate={documentsState.saveDocuments}
            onDocumentDelete={documentsState.deleteDocument}
            onDocumentUpdate={documentsState.updateDocument}
            tableState={documentsState.state}
            pagination={documentsState.pagination}
            disabled={isViewMode}
          />
        ),
      },
    ]

    return <XtTabs tabs={tabs} />
  }
)
