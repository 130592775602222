import { Overwrite } from 'utility-types'
import { SingleFormRegister } from 'common/hooks/form/multiple-form-manager/multiple-form-manager.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { XtMode } from 'common/common.types'
import { IItem, IItemSite } from 'products/items/items.types'
import { sitesDetailsDialogPlanningFormKey } from './sites-details-dialog-planning.consts'

export interface ISitesDetailsDialogPlanning {
  mode: XtMode
  register: SingleFormRegister<typeof sitesDetailsDialogPlanningFormKey, SitesDetailsDialogPlanningForm>
  item: IItem
  itemSite: IItemSite | null
}

export enum SitesDetailsDialogPlanningFormField {
  PlanningSystem = 'planning_system',
  SafetyStock = 'safety_stock',
  GroupMpsMrpOrders = 'group_mps_mrp_orders',
  LeadTime = 'lead_time',
  MpsTimeFence = 'mps_time_fence',
  FirstGroup = 'first_group',
  CreatePlannedTransfersOrders = 'create_planned_transfers_orders',
  SuppliedFromSite = 'supplied_from_site',
  EnforceOrderParameters = 'enforce_order_parameters',
  ReorderLevel = 'reorder_level',
  OrderUpTo = 'order_up_to',
  MinimumOrder = 'minimum_order',
  MaximumOrder = 'maximum_order',
  OrderMultiple = 'order_multiple',
  EnforceOnManualOrders = 'enforce_on_manual_orders',
}

export enum SitesDetailsDialogPlanningFormFieldLabel {
  PlanningSystem = 'Planning System',
  SafetyStock = 'Safety Stock',
  GroupMpsMrpOrders = 'Group Planned Orders Every',
  LeadTime = 'Lead Time',
  MpsTimeFence = 'MPS Time Fence',
  FirstGroup = 'First Group',
  CreatePlannedTransfersOrders = 'Create Planned Transfers Orders',
  SuppliedFromSite = 'Supplied from Site',
  EnforceOrderParametersEnabled = 'Enforce Order Parameters',
  ReorderLevel = 'Reorder Level',
  OrderUpTo = 'Order Up To',
  MinimumOrder = 'Minimum Order',
  MaximumOrder = 'Maximum Order',
  OrderMultiple = 'Order Multiple',
  EnforceOnManualOrders = 'Enforce on Manual Orders',
}

export type ItemSitePlanning = Pick<
  IItemSite,
  | SitesDetailsDialogPlanningFormField.PlanningSystem
  | SitesDetailsDialogPlanningFormField.SafetyStock
  | SitesDetailsDialogPlanningFormField.GroupMpsMrpOrders
  | SitesDetailsDialogPlanningFormField.LeadTime
  | SitesDetailsDialogPlanningFormField.MpsTimeFence
  | SitesDetailsDialogPlanningFormField.FirstGroup
  | SitesDetailsDialogPlanningFormField.SuppliedFromSite
  | SitesDetailsDialogPlanningFormField.ReorderLevel
  | SitesDetailsDialogPlanningFormField.OrderUpTo
  | SitesDetailsDialogPlanningFormField.MinimumOrder
  | SitesDetailsDialogPlanningFormField.MaximumOrder
  | SitesDetailsDialogPlanningFormField.OrderMultiple
  | SitesDetailsDialogPlanningFormField.EnforceOnManualOrders
  | SitesDetailsDialogPlanningFormField.EnforceOrderParameters
>

export type SitesDetailsDialogPlanningForm = Overwrite<
  ItemSitePlanning,
  {
    [SitesDetailsDialogPlanningFormField.PlanningSystem]: IXtAutocompleteOption | null
    [SitesDetailsDialogPlanningFormField.SafetyStock]: number | null
    [SitesDetailsDialogPlanningFormField.GroupMpsMrpOrders]: number | null
    [SitesDetailsDialogPlanningFormField.LeadTime]: number | null
    [SitesDetailsDialogPlanningFormField.MpsTimeFence]: number | null
    [SitesDetailsDialogPlanningFormField.FirstGroup]: boolean
    [SitesDetailsDialogPlanningFormField.SuppliedFromSite]: IXtAutocompleteOption | null
    [SitesDetailsDialogPlanningFormField.ReorderLevel]: number | null
    [SitesDetailsDialogPlanningFormField.OrderUpTo]: number | null
    [SitesDetailsDialogPlanningFormField.MinimumOrder]: number | null
    [SitesDetailsDialogPlanningFormField.MaximumOrder]: number | null
    [SitesDetailsDialogPlanningFormField.OrderMultiple]: number | null
    [SitesDetailsDialogPlanningFormField.EnforceOnManualOrders]: boolean
    [SitesDetailsDialogPlanningFormField.EnforceOrderParameters]: boolean
  }
> & {
  [SitesDetailsDialogPlanningFormField.CreatePlannedTransfersOrders]: boolean
}

export enum ItemPlanningSystem {
  None = 'None',
  MRP = 'MRP',
  MPS = 'MPS',
}
