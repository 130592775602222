import React, { FC, useEffect } from 'react'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useXtForm } from 'common/hooks/form/form'
import { FormSelectField, FormXtAutocomplete, FormXtAutocompletePlain } from 'common/utils/form/form.components'
import { XtInfoValues } from 'components/xt-info-values/xt-info-values'
import { useCoreModule } from 'core/core-module-hook'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { useShipmentsModule } from 'shipments/shipments-module-hook'
import { cls } from 'common/utils/utils'
import { useXtSelect } from 'components/controls/xt-select/xt-select-hook'
import { IPrintShippingForm, IPrintShippingFormState, ShippingPrintFormField, ShippingPrintFormLabel } from './print-shipping-form.types'
import { shippingPrintFormSchema as validationSchema } from './print-shipping-form.validation'
import { defaultShipmentFilters, orderFilters, shippingPrintFormDefaultValues as defaultValues } from './print-shipping-form.constants'
import {
  isFilledForm,
  resolveShipmentInfoValues,
  resolveSalesOrderValues,
  resolveTransferOrderValues,
  defineDefaultShippingForm,
} from './print-shipping-form.utils'
import { usePrintShippingForm } from '../print-shipping-form-hook/print-shipping-form-hook'
import { ShippingPrintFormSharedField, ShippingPrintFormSharedLabel } from '../print-dialogs.types'

import * as styles from './print-shipping-form.module.scss'

export const PrintShippingForm: FC<IPrintShippingForm> = ({ onClose, shipmentNumber, shippingForm }) => {
  const { DocumentsUtilsService } = useDocumentsModule()
  const { ShipmentsUtilsService } = useShipmentsModule()
  const { ErrorHandler } = useCoreModule()

  const formMethods = useXtForm<IPrintShippingFormState>({
    defaultValues,
    mode: 'onBlur',
    validationSchema,
  })

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    setValue,
  } = formMethods

  const { orderValues, shipmentValues, shipmentsState, onShipmentChange, onOrderChange } = usePrintShippingForm(
    formMethods,
    resolveSalesOrderValues,
    resolveTransferOrderValues,
    resolveShipmentInfoValues,
    defaultShipmentFilters,
    shipmentNumber
  )

  const { options: shippingForms, reset: resetShippingForms } = useXtSelect(
    async () => {
      const { data } = await DocumentsUtilsService.loadShippingForm()
      return data
    },
    undefined,
    false
  )

  useEffect(() => {
    async function initShippingForms(): Promise<void> {
      try {
        const options = await resetShippingForms(undefined)
        const defaultOption = defineDefaultShippingForm(shippingForm, options)
        setValue(ShippingPrintFormField.ShippingForm, defaultOption)
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }

    void initShippingForms()
  }, [ErrorHandler, resetShippingForms, setValue, shippingForm])

  const onSaveForm = async (formData: IPrintShippingFormState): Promise<void> => {
    if (!isFilledForm(formData)) {
      return
    }

    const shipmentNumberValue = formData[ShippingPrintFormSharedField.ShipmentNumber].id
    const formName = formData[ShippingPrintFormField.ShippingForm].id

    try {
      await ShipmentsUtilsService.printShipmentsForm(shipmentNumberValue, formName)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  return (
    <form className={styles.printDialog}>
      <div className={cls('xt-page-header', styles.printDialogHeader)}>
        <h3 className="xt-page-title">Print Shipping Form</h3>
        <div className={styles.printDialogButtons}>
          <XtButton label="Cancel" onClick={onClose} />
          <XtButton label="Print" onClick={handleSubmit(onSaveForm)} disabled={!isDirty || isSubmitting} />
        </div>
      </div>
      <FormSelectField
        options={shippingForms}
        name={ShippingPrintFormField.ShippingForm}
        control={control}
        label={ShippingPrintFormLabel.ShippingForm}
      />
      <div className={styles.orderValuesSection}>
        <FormXtAutocomplete
          loadOptions={DocumentsUtilsService.loadOrders}
          onChange={onOrderChange}
          name={ShippingPrintFormSharedField.Order}
          control={control}
          label={ShippingPrintFormSharedLabel.Order}
          filters={orderFilters}
        />
        <XtInfoValues values={orderValues} />
      </div>
      <div className={styles.shipmentValuesSection}>
        <FormXtAutocompletePlain
          name={ShippingPrintFormSharedField.ShipmentNumber}
          control={control}
          label={ShippingPrintFormSharedLabel.ShipmentNumber}
          options={shipmentsState.options}
          onChange={onShipmentChange}
          loading={shipmentsState.loading}
          loadMore={shipmentsState.loadMoreOptions}
          filter={shipmentsState.search}
          reset={shipmentsState.reset}
        />
        {shipmentValues && <XtInfoValues values={shipmentValues} />}
      </div>
    </form>
  )
}
