import { IBomItem, IBomItemsFilters, IBomListFilters } from './bom.types'
import { IPaginationData, IPaginationParams } from '../../common/common.types'
import { IBomItemsService } from './bom-item/bom-item.service'
import { bindAllInstanceMethods } from '../../common/utils/object.utils'
import { ISortOption } from '../../components/table/table-head/table-head.types'
import { IBomTableItem } from './bom-list/bom-list.types'
import { IBomService } from './bom.service'

export interface IBomUtilsService {
  fetchBoms(
    filters: IBomListFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IBomTableItem>>
  fetchBomItems(
    filters: IBomItemsFilters,
    paginationParams: IPaginationParams,
    sortOptions: ISortOption[],
    itemNumber?: string
  ): Promise<IPaginationData<IBomItem>>
}

export class BomUtilsService implements IBomUtilsService {
  constructor(private readonly bomService: IBomService, private readonly bomItemsService: IBomItemsService) {
    bindAllInstanceMethods(this)
  }

  public async fetchBomItems(
    filters: IBomItemsFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[],
    itemNumber?: string
  ): Promise<IPaginationData<IBomItem>> {
    if (itemNumber) {
      const { total, data } = await this.bomItemsService.getAll(itemNumber, filters, paginationParams, sortOptions)
      return {
        data,
        total,
      }
    }
    return {
      data: [],
      total: 0,
    }
  }

  public async fetchBoms(
    filters: IBomListFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IBomTableItem>> {
    const { total, data } = await this.bomService.getAll(filters, paginationParams, sortOptions)

    return {
      data: data.map((bom) => ({ ...bom, id: bom.item_number })),
      total,
    }
  }
}
