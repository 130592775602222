import React, { FC, KeyboardEventHandler, useCallback } from 'react'
import { Key } from 'ts-key-enum'
import { isBefore, startOfDay } from 'date-fns'
import { convertDate } from 'common/utils/date.utils'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import * as styles from './task-kanban-card.module.scss'
import { IKanbanTaskCard } from '../tasks-board.types'

const taskCompletedStatus = 'COMPLETED'

export const TaskKanbanCard: FC<IKanbanTaskCard> = ({ name, assignees, dueDate, onClick, status }) => {
  const dateValue = convertDate(dueDate)

  const onKeyDown: KeyboardEventHandler = useCallback<(event: React.KeyboardEvent<HTMLElement>) => void>(
    (event) => {
      if (event.key === Key.Enter && typeof onClick === 'function') {
        onClick(event)
      }
    },
    [onClick]
  )

  const isAlertHidden = !isBefore(startOfDay(dueDate), startOfDay(new Date())) || status.toUpperCase() === taskCompletedStatus

  return (
    <div className={styles.card} onClick={onClick} role="gridcell" tabIndex={0} onKeyDown={onKeyDown}>
      <div className={styles.cardRowWithIcon}>
        <span className={styles.cardValue}>{name}</span>
        <div hidden={isAlertHidden} className={styles.cardIconContainer}>
          <SvgIcon iconId={SvgIconIds.ALERT_ICON} />
          <div className={styles.iconTooltipContainer}>
            <span className={styles.iconTooltip}>This task is overdue.</span>
            <div className={styles.iconTooltipTriangle} />
          </div>
        </div>
      </div>
      <div hidden={!assignees} className={styles.cardRow}>
        <span className={styles.cardValue}>{assignees}</span>
      </div>
      <div className={styles.cardRow}>
        <span className={styles.cardLabel}>Due:</span>
        <span className={styles.cardValue}>{dateValue}</span>
      </div>
    </div>
  )
}
