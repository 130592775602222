import { Injector } from 'core/injector/injector.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import {
  IOpportunitiesService,
  IOpportunitiesServices,
  IOpportunitiesUtilsService,
  IOpportunitySourcesService,
  IOpportunityStagesService,
  IOpportunityTypesService,
} from './opportunities-services.types'
import {
  opportunitiesServiceToken,
  opportunitiesUtilsServiceToken,
  opportunitySourcesServiceToken,
  opportunityStagesServiceToken,
  opportunityTypesServiceToken,
} from '../opportunities.constants'

export function resolveOpportunitiesServicesModule(): IOpportunitiesServices {
  const OpportunitiesService = Injector.resolve<IOpportunitiesService>(opportunitiesServiceToken)
  const OpportunitiesUtilsService = Injector.resolve<IOpportunitiesUtilsService>(opportunitiesUtilsServiceToken)
  const OpportunityTypesService = Injector.resolve<IOpportunityTypesService>(opportunityTypesServiceToken)
  const OpportunitySourcesService = Injector.resolve<IOpportunitySourcesService>(opportunitySourcesServiceToken)
  const OpportunityStagesService = Injector.resolve<IOpportunityStagesService>(opportunityStagesServiceToken)

  return {
    OpportunitiesService,
    OpportunityTypesService,
    OpportunitySourcesService,
    OpportunityStagesService,
    OpportunitiesUtilsService,
  }
}

export const useOpportunitiesServicesModule = moduleHookResolver<IOpportunitiesServices>(resolveOpportunitiesServicesModule)
