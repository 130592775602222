// extracted by mini-css-extract-plugin
export var root = "bom-list_root__AHPny";
export var xtContent = "bom-list_xt-content__1rGJT";
export var xtContentWithRemarks = "bom-list_xt-content-with-remarks__EMC16";
export var muiFormHelperTextRoot = "bom-list_MuiFormHelperText-root__ImMyi";
export var xtListPageContainer = "bom-list_xt-list-page-container__9JEx+";
export var listContent = "bom-list_listContent__ij6pL";
export var muiInputBaseRoot = "bom-list_MuiInputBase-root__GTHur";
export var muiTextFieldRoot = "bom-list_MuiTextField-root__60tVR";
export var muiTablePaginationRoot = "bom-list_MuiTablePagination-root__o1Q+L";
export var muiTablePaginationSelectRoot = "bom-list_MuiTablePagination-selectRoot__p5C2Y";
export var muiTablePaginationSelect = "bom-list_MuiTablePagination-select__iSYLH";
export var muiStepperRoot = "bom-list_MuiStepper-root__8aKZM";
export var muiStepperHorizontal = "bom-list_MuiStepper-horizontal__loYp+";
export var muiStepIconRoot = "bom-list_MuiStepIcon-root__9P+yy";
export var muiStepIconActive = "bom-list_MuiStepIcon-active__+v3an";
export var muiStepIconCompleted = "bom-list_MuiStepIcon-completed__s9SHW";
export var muiListItemRoot = "bom-list_MuiListItem-root__1F1Y7";
export var muiSelected = "bom-list_Mui-selected__-LYH9";
export var xtPageTitle = "bom-list_xt-page-title__MXxco";
export var xtSubPageTitle = "bom-list_xt-subPage-title__eyHTg";
export var xtSectionBorder = "bom-list_xt-section-border__4ToGJ";
export var xtPageHeader = "bom-list_xt-page-header__-p838";
export var xtDialogScrollable = "bom-list_xt-dialog-scrollable__zesxw";
export var reactToastNotificationsContainer = "bom-list_react-toast-notifications__container__N3Coc";
export var xtScrollbarStyle = "bom-list_xt-scrollbar-style__99HE+";
export var xtModalDetailsContent = "bom-list_xt-modal-details-content__mfA7l";
export var xtDialogDetailsContent = "bom-list_xt-dialog-details-content__PUJ4W";
export var xtLazyModuleLoaderTabContainer = "bom-list_xt-lazy-module-loader-tab-container__zA5In";
export var xtTextTruncated = "bom-list_xt-text-truncated__68Osm";
export var xtSummaryContent = "bom-list_xt-summary-content__4g+Bk";
export var xtSummaryLabel = "bom-list_xt-summary-label__c1vxt";
export var visibleHidden = "bom-list_visible-hidden__ak7c2";
export var headerBottomMargin = "bom-list_header-bottom-margin__Mvqw3";
export var xtDialogHeaderLogoAlign = "bom-list_xt-dialog-header-logoAlign__TUOB3";
export var xtStickyHeader = "bom-list_xt-sticky-header__qQRk9";
export var noBorder = "bom-list_no-border__orccM";
export var muiOutlinedInputNotchedOutline = "bom-list_MuiOutlinedInput-notchedOutline__p6XER";
export var listHeader = "bom-list_listHeader__9iAFZ";
export var listControls = "bom-list_listControls__aXBDj";
export var filterButton = "bom-list_filterButton__v61nb";
export var filterListCheckbox = "bom-list_filterListCheckbox__dMjaB";
export var filtersContainer = "bom-list_filtersContainer__uzYx8";