import type { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { PurchaseOrdersModule, PurchaseOrdersModuleDefinition } from 'purchase-orders/purchase-orders.module'
import { ItemSourceModule, ItemSourceModuleDefinition } from 'item-source/item-source.module'
import { PurchaseRequestModule, PurchaseRequestsModuleDefinition } from 'purchase-requests/purchase-requests.module'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { purchasingModuleToken } from './purchasing.constants'

export type PurchasingModuleDefinition = PurchaseOrdersModuleDefinition & PurchaseRequestsModuleDefinition & ItemSourceModuleDefinition

export const PurchasingModule: ILazyModule<PurchasingModuleDefinition> = {
  name: purchasingModuleToken,
  resolver: async () => {
    const purchaseOrdersModule = LazyModulesService.resolveModule<PurchaseOrdersModuleDefinition>(PurchaseOrdersModule)
    const purchaseRequestsModule = LazyModulesService.resolveModule<PurchaseRequestsModuleDefinition>(PurchaseRequestModule)
    const itemSourceModule = LazyModulesService.resolveModule<ItemSourceModuleDefinition>(ItemSourceModule)
    return {
      ...purchaseOrdersModule,
      ...purchaseRequestsModule,
      ...itemSourceModule,
    }
  },
  initializer: () => {},
  dependencies: [PurchaseOrdersModule, PurchaseRequestModule, ItemSourceModule],
}
