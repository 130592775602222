import { nanoid } from 'nanoid'
import { Optional } from 'utility-types'
import { IItemsService } from 'products/items/items.service'
import { CostedBomFilters, CostedBomSummaryFilter, CostedBomSummaryFilters, ICostedBom } from 'products/items/items.types'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { savePDFFile } from 'common/utils/file.utils'
import { convertFiltersPanelState } from 'common/utils/filter.utils'
import { IErrorHandler } from 'core/services/error-handler.service'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { ICostedBomSummaryWithId, ICostedBomTotalsWithId, ICostedBomWithId } from './bom-utils.types'

export interface ICostingBomUtilsService {
  fetchCostedBomSummary(
    filters: Optional<CostedBomSummaryFilters, CostedBomSummaryFilter.ItemNumber>,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<ICostedBomSummaryWithId>>
  fetchCostedBom(
    filters: Optional<CostedBomFilters, CostedBomSummaryFilter.ItemNumber>,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<ICostedBomWithId>>
  getCostedBomTotals(filters: Partial<CostedBomFilters>): Promise<IPaginationData<ICostedBomTotalsWithId>>
  printCostedBomSummary(filters: CostedBomSummaryFilters): Promise<void>
  printCostedBom(filters: CostedBomFilters): Promise<void>
}

export class CostingBomUtilsService implements ICostingBomUtilsService {
  constructor(private readonly itemsService: IItemsService, private readonly errorHandler: IErrorHandler) {
    bindAllInstanceMethods(this)
  }

  public async fetchCostedBomSummary(
    filters: CostedBomSummaryFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<ICostedBomSummaryWithId>> {
    if (!filters.item_number) {
      return {
        data: [],
        total: 0,
      }
    }
    try {
      const { total, data } = await this.itemsService.getCostedBomSummary(convertFiltersPanelState(filters), paginationParams, sortOptions)
      return {
        data: data.map((item) => ({ ...item, id: nanoid() })),
        total,
      }
    } catch (e) {
      this.errorHandler.handleError(e)
      return {
        data: [],
        total: 0,
      }
    }
  }

  public async fetchCostedBom(
    filters: CostedBomFilters,
    paginationParams: IPaginationParams,
    sortOptions: ISortOption[]
  ): Promise<IPaginationData<ICostedBomWithId>> {
    if (!filters.item_number) {
      return {
        data: [],
        total: 0,
      }
    }
    try {
      const { total, data } = await this.itemsService.getCostedBom(convertFiltersPanelState(filters), paginationParams, sortOptions)
      return {
        data: this.convertSingleLevelData(data),
        total,
      }
    } catch (e) {
      this.errorHandler.handleError(e)
      return {
        data: [],
        total: 0,
      }
    }
  }

  public async printCostedBom(filters: CostedBomFilters): Promise<void> {
    try {
      const itemNumber = filters[CostedBomSummaryFilter.ItemNumber]
      const file = await this.itemsService.getCostedBomPDF(convertFiltersPanelState(filters))
      savePDFFile(file, `${itemNumber}-costed-bom-file`)
    } catch (e) {
      this.errorHandler.handleError(e)
    }
  }

  public async getCostedBomTotals(filters: CostedBomFilters): Promise<IPaginationData<ICostedBomTotalsWithId>> {
    if (!filters.item_number) {
      return {
        data: [],
        total: 0,
      }
    }

    try {
      const { data, total } = await this.itemsService.getCostedBomTotals(filters)
      return {
        data: data.map((item) => ({ ...item, id: nanoid() })),
        total,
      }
    } catch (e) {
      this.errorHandler.handleError(e)
      return {
        data: [],
        total: 0,
      }
    }
  }

  public async printCostedBomSummary(filters: CostedBomSummaryFilters): Promise<void> {
    try {
      const itemNumber = filters[CostedBomSummaryFilter.ItemNumber]
      const file = await this.itemsService.getCostedBomSummaryPDF(convertFiltersPanelState(filters))
      savePDFFile(file, `${itemNumber}-costed-summarized-bom-file`)
    } catch (e) {
      this.errorHandler.handleError(e)
    }
  }

  private convertSingleLevelData(data: ICostedBom[]): ICostedBomWithId[] {
    return data.map((item) => ({ ...item, id: nanoid(), parentId: item.has_children ? item.item_number : undefined }))
  }
}
