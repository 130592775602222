import { ILotSerialHookMeta } from './lot-serial-hook.types'

export const defaultLotSerialMetaState: ILotSerialHookMeta = {
  qtyToAssign: 0,
  qtyAssigned: 0,
  qtyRemaining: 0,
}

export const lotSerialErrors = {
  invalidQuantityAssigned: (qtyToAssign: number, qtyAssigned: number) =>
    `Total quantity assigned in the table ${qtyAssigned} is not equal to Qty. to Assign: ${qtyToAssign}.`,
  duplicatedSerialNumber: (serialNumber: string) => `Serial # ${serialNumber} is presented more than once.`,
}

export const defaultQuantityDecimalScale = 0
export const fractionalQuantityDecimalScale = 2
