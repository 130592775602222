import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { map } from 'rxjs/operators'
import { useXtForm } from 'common/hooks/form/form'
import { XtInfoValues } from 'components/xt-info-values/xt-info-values'
import { FormCheckboxLabel, FormRadioGroup, FormSelectField } from 'common/utils/form/form.components'
import { useProductsModule } from 'products/products-module-hook'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { defaultItemNumberFilters } from 'products/products.constants'
import { defaultValues, maxThresholdDaysValue, minThresholdDaysValue } from './summarized-list-form.constants'
import { ISummarizedForm, FormField, FormLabel, SummarizedListFormParams } from './summarized-list-form.types'
import * as styles from '../../bom-list-forms.module.scss'
import { convertFormDataToCostedBomFilters } from './summarized-list-form.utils'
import { useBomListShared } from '../../shared/bom-list-form-shared-hook'
import { FormSharedField, FormSharedLabel } from '../../shared/shared.types'
import { costsOptions } from '../../shared/shared.constants'

export const SummarizedListForm: FC<SummarizedListFormParams> = ({ onChange }) => {
  const { FormXtItemNumber, ItemsUtilsService, CostingBomUtilsService } = useProductsModule()

  const [printing, setPrinting] = useState<boolean>(false)

  const formMethods = useXtForm<ISummarizedForm>({
    defaultValues,
  })

  const { control, watch, setValue, formValueChanges$, getValues } = formMethods

  const { revisionStatus, revisions, onItemChange, itemDetails } = useBomListShared(formMethods)

  const [showExpiredDays, showFutureDays, withExpiredDays, withFutureDays] = watch([
    FormField.ShowExpiredComponents,
    FormField.ShowFutureComponents,
    FormField.ThresholdWithExpiredDays,
    FormField.ThresholdWithFutureDays,
  ])

  const printForm = async (): Promise<void> => {
    if (!itemDetails) {
      return
    }

    const printFilters = getValues()
    if (!printFilters) {
      return
    }

    setPrinting(true)
    const data = convertFormDataToCostedBomFilters(printFilters)
    await CostingBomUtilsService.printCostedBomSummary(data)
    setPrinting(false)
  }

  const onShowExpiredDaysChange = (value: boolean): void => {
    setValue(FormField.ShowExpiredComponents, value)
    if (!value && withExpiredDays !== 0) {
      setValue(FormField.ThresholdWithExpiredDays, 0)
    }
  }

  const onShowFutureDaysChange = (value: boolean): void => {
    setValue(FormField.ShowFutureComponents, value)
    if (!value && withFutureDays !== 0) {
      setValue(FormField.ThresholdWithFutureDays, 0)
    }
  }

  useEffect(() => {
    const sub = formValueChanges$.pipe(map(convertFormDataToCostedBomFilters)).subscribe(onChange)

    return () => {
      sub.unsubscribe()
    }
  }, [formValueChanges$, onChange])

  return (
    <div className={styles.formContent}>
      <div className={styles.header}>
        <div className={styles.fields}>
          <FormXtItemNumber
            control={control}
            name={FormSharedField.ItemDetails}
            label={FormSharedLabel.ItemDetails}
            onChange={onItemChange}
            loadOptions={ItemsUtilsService.loadItemOptions}
            isEditMode
            filters={defaultItemNumberFilters}
            className={styles.itemNumber}
          />
          <div>
            <FormSelectField
              disabled={!revisions.length}
              options={revisions}
              name={FormSharedField.Revision}
              control={control}
              label={FormSharedLabel.Revision}
              className={styles.revisionField}
            />
            <XtInfoValues hidden={!revisionStatus} values={[{ label: 'Revision Status', value: revisionStatus }]} />
          </div>
        </div>
        <XtButton disabled={printing || !itemDetails} className={styles.printButton} label="Print" onClick={printForm} />
      </div>
      <div className={styles.mainSection}>
        <div className={styles.fields}>
          <div className={styles.thresholdDaysSection}>
            <FormCheckboxLabel
              name={FormField.ShowExpiredComponents}
              control={control}
              className={styles.checkbox}
              label={FormLabel.ShowExpiredComponents}
              onChange={onShowExpiredDaysChange}
            />
            <DecimalFormField
              disabled={!showExpiredDays}
              name={FormField.ThresholdWithExpiredDays}
              control={control}
              label={FormLabel.ThresholdDays}
              maxValue={maxThresholdDaysValue}
              minValue={minThresholdDaysValue}
              fixedDecimalScale={0}
              allowNegative={false}
              counters
            />
          </div>
          <div className={styles.thresholdDaysSection}>
            <FormCheckboxLabel
              name={FormField.ShowFutureComponents}
              control={control}
              className={styles.checkbox}
              label={FormLabel.ShowFutureComponents}
              onChange={onShowFutureDaysChange}
            />
            <DecimalFormField
              disabled={!showFutureDays}
              name={FormField.ThresholdWithFutureDays}
              control={control}
              label={FormLabel.ThresholdDays}
              maxValue={maxThresholdDaysValue}
              minValue={minThresholdDaysValue}
              allowNegative={false}
              fixedDecimalScale={0}
              counters
            />
          </div>
        </div>
        <FormRadioGroup className={styles.options} options={costsOptions} label="" control={control} name={FormSharedField.CostsOption} />
      </div>
    </div>
  )
}
