import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { cls } from 'common/utils/utils'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { useTable } from 'common/hooks/useTable'
import { XtMode } from 'common/common.types'
import { LoaderMessage, confirmationMessages, xsMq } from 'common/constants'
import { XtList } from 'components/list/list'
import { UserPermission } from 'auth/auth.types'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { XtPageFilter } from 'components/pagefilter/pagefilter'
import { usePageFilter } from 'components/pagefilter/pagefilter.utils'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { productsRoutePath, productsRoutes } from '../../products.constants'
import * as styles from './items-list.module.scss'
import { IDeletionState, IItemsTableItem, ItemsAction, ItemsFilterFieldName, ItemsFilterLabel, ItemsFilterPanel } from './items-list.types'
import {
  defaultDeletionState,
  filterDefaultValues,
  itemColumns,
  ItemsListActionsEditMode,
  ItemsListActionsViewMode,
  itemsPageTitle,
} from './items-list.constants'
import { resolveFilters } from './items-list.utils'
import { useProductsModule } from '../../products-module-hook'

export const ItemsList: FC = () => {
  const { DocumentsUtilsService } = useDocumentsModule()
  const { ItemsService, ItemsUtilsService } = useProductsModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { PermissionsService } = useAuthModule()
  useDocumentTitle(itemsPageTitle)
  const history = useHistory()
  const { path } = useRouteMatch()

  const canManageItems = PermissionsService.hasPermission(UserPermission.MaintainItemMasters)
  const isMobile = useMediaQuery(xsMq)

  const [deletionState, setDeletionState] = useState<IDeletionState>(defaultDeletionState)
  const {
    state: { loading, data, filters: tableFilters, sortOptions },
    setLoading,
    refresh,
    pagination,
    filter,
    sort,
  } = useTable<IItemsTableItem, ItemsFilterPanel>(filterDefaultValues, ItemsUtilsService.fetchItems, undefined, PageFilterMapping.Item)

  const itemsListFilters = usePageFilter<ItemsFilterPanel>(PageFilterMapping.Item)

  const handleRowClick = useCallback<(item: IItemsTableItem) => void>(
    ({ item_number }) => {
      const mode = canManageItems ? XtMode.Edit : XtMode.View

      history.push(`${path}/${item_number}/${mode}`)
    },
    [path, history, canManageItems]
  )

  const handleAction = useCallback<(item: IItemsTableItem, action: ItemsAction) => void>(
    ({ item_number }, action) => {
      switch (action) {
        case ItemsAction.Edit:
          history.push(`${productsRoutePath}/${productsRoutes.items}/${item_number}/${XtMode.Edit}`)
          break
        case ItemsAction.Delete:
          setDeletionState({ itemNumber: item_number, confirmationOpen: true })
          break
        case ItemsAction.Print:
          void ItemsUtilsService.printItem(item_number)
          break
        case ItemsAction.View:
          history.push(`${productsRoutePath}/${productsRoutes.items}/${item_number}/${XtMode.View}`)
          break
        default:
          break
      }
    },
    [ItemsUtilsService, history]
  )

  const closeConfirmationDialog = useCallback<() => void>(() => setDeletionState(defaultDeletionState), [])

  const handleDeletion = useCallback<() => Promise<void>>(async () => {
    closeConfirmationDialog()
    if (deletionState.itemNumber) {
      try {
        setLoading(true)
        const message = await ItemsService.delete(deletionState.itemNumber)
        await refresh()
        setLoading(false)
        ToastService.showSuccess(message)
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    }
  }, [closeConfirmationDialog, deletionState.itemNumber, setLoading, ItemsService, refresh, ErrorHandler])

  const handleShowInactiveFilterChange = (checked: boolean): void => {
    const params = { ...tableFilters, [ItemsFilterFieldName.ShowInactive]: checked }
    void filter(params)
    void itemsListFilters.handleLastUsedFilter(params)
  }

  return (
    <div className={cls('xt-content', styles.listContent)}>
      <XtConfirmationDialog
        open={deletionState.confirmationOpen}
        message={confirmationMessages.deleted}
        title="Delete Item"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />

      <div className={styles.listControls}>
        <div className={cls('xt-section-border', styles.headerContent)}>
          <h1 className="xt-page-title">Items</h1>
          <XtResponsiveButton
            label="New Item"
            disabled={!canManageItems}
            icon={SvgIconIds.ADD_CIRCLE}
            onClick={() => history.push(`${path}/${XtMode.New}`)}
          />
        </div>
        <div className={styles.filtersContainer}>
          <XtPageFilter
            state={itemsListFilters}
            defaultFilterValues={filterDefaultValues}
            resolveFilters={() => resolveFilters(DocumentsUtilsService, ItemsUtilsService)}
            filter={filter}
            tableFilters={tableFilters}
          />
          <XtCheckbox
            className={styles.itemsListCheckbox}
            label={ItemsFilterLabel.ShowInactive}
            value={Boolean(tableFilters[ItemsFilterFieldName.ShowInactive])}
            onChange={handleShowInactiveFilterChange}
            disabled={loading}
          />
        </div>
      </div>
      <XtList
        actions={canManageItems ? ItemsListActionsEditMode : ItemsListActionsViewMode}
        onRowClick={handleRowClick}
        onAction={handleAction}
        isMobile={isMobile}
        pagination={pagination}
        loading={loading}
        data={data}
        columns={itemColumns}
        sortOptions={sortOptions}
        onColumnHeaderClick={sort}
        loadMessage={LoaderMessage.Items}
      />
    </div>
  )
}
