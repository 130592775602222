import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { characteristicsToPayload } from 'characteristics/characteristics.utils'
import { CustomerCreatePayload } from '../customers.types'
import { ICustomerMainForm } from './customer-details-form/customer-details-form.types'
import { ContactSectionFormField, IContactSectionForm } from '../../../contacts/components/contact-section/contact-section.types'
import { ISettingTermsForm, SettingsTermsFormField } from './customer-details-tabs/settings-tab/setting-tab.types'

export function shouldDisableCustomerType(isNewMode: boolean, isEditMode: boolean, canCreateType: boolean, canEditType: boolean): boolean {
  const disabledInEditMode = isEditMode && !canEditType
  const disabledInNewMode = isNewMode && !canCreateType && !canEditType

  return disabledInNewMode || disabledInEditMode
}

export function convertCustomerPayload(
  mainForm: ICustomerMainForm,
  contactSectionForm: IContactSectionForm,
  characteristics: IAppliedCharacteristic[],
  notes: string,
  termsForm: ISettingTermsForm
): CustomerCreatePayload {
  return {
    ...mainForm,
    allow_free_form_billto: termsForm[SettingsTermsFormField.AllowFreeFormBillTo],
    allow_free_form_shipto: termsForm[SettingsTermsFormField.AllowFreeFormShipTo],
    billing_contact: contactSectionForm[ContactSectionFormField.Contact1],
    correspond_contact: contactSectionForm[ContactSectionFormField.Contact2],
    notes,
    terms_code: termsForm[SettingsTermsFormField.Terms]?.id ?? '',
    default_discount: termsForm[SettingsTermsFormField.Discount] ?? 0,
    default_currency: termsForm[SettingsTermsFormField.Currency]?.id ?? '',
    credit_limit: termsForm[SettingsTermsFormField.CreditLimit] ?? 0,
    credit_status_exceed_warn: termsForm[SettingsTermsFormField.CreditStatusExceedWarn] ?? false,
    credit_status_exceed_hold: termsForm[SettingsTermsFormField.CreditStatusExceedHold] ?? false,
    credit_status: termsForm[SettingsTermsFormField.CreditStatus]?.id ?? 'In Good Holding',
    uses_blanket_pos: termsForm[SettingsTermsFormField.UsesBlanketPOs] ?? false,
    uses_purchase_orders: termsForm[SettingsTermsFormField.UsesPurchaseOrders] ?? false,
    alternate_grace_days: termsForm[SettingsTermsFormField.AlternateGraceDays] ?? 0,
    credit_limit_currency: termsForm[SettingsTermsFormField.CreditLimitCurrency]?.id ?? '',
    customer_type: mainForm.customer_type?.id ?? '',
    customer_characteristics: characteristicsToPayload(characteristics),
    preferred_selling_site: mainForm.preferred_selling_site?.id ? mainForm.preferred_selling_site.id : '',
  }
}
