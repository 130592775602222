// extracted by mini-css-extract-plugin
export var root = "nested-xt-table_root__PFPKq";
export var xtContent = "nested-xt-table_xt-content__4WrsU";
export var xtContentWithRemarks = "nested-xt-table_xt-content-with-remarks__j3Qsf";
export var muiFormHelperTextRoot = "nested-xt-table_MuiFormHelperText-root__hsZ+3";
export var xtListPageContainer = "nested-xt-table_xt-list-page-container__STob-";
export var muiInputBaseRoot = "nested-xt-table_MuiInputBase-root__CdkGE";
export var muiTextFieldRoot = "nested-xt-table_MuiTextField-root__t6S8+";
export var muiTablePaginationRoot = "nested-xt-table_MuiTablePagination-root__uuYvZ";
export var muiTablePaginationSelectRoot = "nested-xt-table_MuiTablePagination-selectRoot__f51xl";
export var muiTablePaginationSelect = "nested-xt-table_MuiTablePagination-select__vAb4j";
export var muiStepperRoot = "nested-xt-table_MuiStepper-root__YSkas";
export var muiStepperHorizontal = "nested-xt-table_MuiStepper-horizontal__kG21I";
export var muiStepIconRoot = "nested-xt-table_MuiStepIcon-root__fMgIq";
export var muiStepIconActive = "nested-xt-table_MuiStepIcon-active__Gkgge";
export var muiStepIconCompleted = "nested-xt-table_MuiStepIcon-completed__z0nZ0";
export var muiListItemRoot = "nested-xt-table_MuiListItem-root__fJGvq";
export var muiSelected = "nested-xt-table_Mui-selected__1qiGU";
export var xtPageTitle = "nested-xt-table_xt-page-title__44NNI";
export var xtSubPageTitle = "nested-xt-table_xt-subPage-title__rthY3";
export var xtSectionBorder = "nested-xt-table_xt-section-border__CV+PP";
export var xtPageHeader = "nested-xt-table_xt-page-header__-x9ve";
export var xtDialogScrollable = "nested-xt-table_xt-dialog-scrollable__p1fmd";
export var reactToastNotificationsContainer = "nested-xt-table_react-toast-notifications__container__q58Ox";
export var xtScrollbarStyle = "nested-xt-table_xt-scrollbar-style__XqDX9";
export var xtModalDetailsContent = "nested-xt-table_xt-modal-details-content__owNzO";
export var xtNestedScrollbarStyle = "nested-xt-table_xtNestedScrollbarStyle__7zw-N";
export var xtDialogDetailsContent = "nested-xt-table_xt-dialog-details-content__5P0Ql";
export var xtLazyModuleLoaderTabContainer = "nested-xt-table_xt-lazy-module-loader-tab-container__LcNOF";
export var xtTextTruncated = "nested-xt-table_xt-text-truncated__8cGdD";
export var xtSummaryContent = "nested-xt-table_xt-summary-content__6m7tm";
export var xtSummaryLabel = "nested-xt-table_xt-summary-label__Ej69L";
export var visibleHidden = "nested-xt-table_visible-hidden__kzPDX";
export var headerBottomMargin = "nested-xt-table_header-bottom-margin__h2fBW";
export var xtDialogHeaderLogoAlign = "nested-xt-table_xt-dialog-header-logoAlign__WlotS";
export var xtStickyHeader = "nested-xt-table_xt-sticky-header__jVsXE";
export var noBorder = "nested-xt-table_no-border__UMsB3";
export var muiOutlinedInputNotchedOutline = "nested-xt-table_MuiOutlinedInput-notchedOutline__5aEJe";
export var detailPanelIcon = "nested-xt-table_detailPanelIcon__BlRM2";
export var noRowsOverlay = "nested-xt-table_noRowsOverlay__xzpv8";
export var tableCell = "nested-xt-table_tableCell__FWA7t";