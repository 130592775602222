import { IFormHook } from 'common/hooks/form/form.types'
import { IInventoryAdjustmentFormState } from '../inventory-adjustment-details.types'
import { ILotSerialHook } from '../lot-serial/hooks/lot-serial-hook/lot-serial-hook.types'

export interface IInventoryAdjustmentTabsParams {
  lotSerialState: ILotSerialHook
  formMethods: IFormHook<IInventoryAdjustmentFormState>
  submitted: boolean
}

export enum InventoryAdjustmentsTabName {
  LotSerial = 'Assign Location/Lot/Serial #',
  Cost = 'Cost',
  Notes = 'Notes',
}
