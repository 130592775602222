// extracted by mini-css-extract-plugin
export var root = "inventory-list_root__8trO9";
export var xtContent = "inventory-list_xt-content__0wdda";
export var xtContentWithRemarks = "inventory-list_xt-content-with-remarks__RiNzz";
export var muiFormHelperTextRoot = "inventory-list_MuiFormHelperText-root__DDvq3";
export var xtListPageContainer = "inventory-list_xt-list-page-container__zbJyh";
export var listContent = "inventory-list_listContent__pkYrd";
export var muiInputBaseRoot = "inventory-list_MuiInputBase-root__0ffov";
export var muiTextFieldRoot = "inventory-list_MuiTextField-root__6mtqQ";
export var muiTablePaginationRoot = "inventory-list_MuiTablePagination-root__hbGGa";
export var muiTablePaginationSelectRoot = "inventory-list_MuiTablePagination-selectRoot__bpgUI";
export var muiTablePaginationSelect = "inventory-list_MuiTablePagination-select__-BlkA";
export var muiStepperRoot = "inventory-list_MuiStepper-root__IuVAF";
export var muiStepperHorizontal = "inventory-list_MuiStepper-horizontal__k8rMf";
export var muiStepIconRoot = "inventory-list_MuiStepIcon-root__3lJ9j";
export var muiStepIconActive = "inventory-list_MuiStepIcon-active__190ya";
export var muiStepIconCompleted = "inventory-list_MuiStepIcon-completed__JHbXV";
export var muiListItemRoot = "inventory-list_MuiListItem-root__h7D2F";
export var muiSelected = "inventory-list_Mui-selected__UjWxF";
export var xtPageTitle = "inventory-list_xt-page-title__2tmSZ";
export var xtSubPageTitle = "inventory-list_xt-subPage-title__aoftN";
export var xtSectionBorder = "inventory-list_xt-section-border__Yakhq";
export var xtPageHeader = "inventory-list_xt-page-header__Zq6RB";
export var xtDialogScrollable = "inventory-list_xt-dialog-scrollable__ZgRZo";
export var reactToastNotificationsContainer = "inventory-list_react-toast-notifications__container__FgQTT";
export var xtScrollbarStyle = "inventory-list_xt-scrollbar-style__GBhte";
export var xtModalDetailsContent = "inventory-list_xt-modal-details-content__cebva";
export var xtDialogDetailsContent = "inventory-list_xt-dialog-details-content__Ew+0S";
export var xtLazyModuleLoaderTabContainer = "inventory-list_xt-lazy-module-loader-tab-container__dY0FU";
export var xtTextTruncated = "inventory-list_xt-text-truncated__MywGw";
export var xtSummaryContent = "inventory-list_xt-summary-content__pYvt2";
export var xtSummaryLabel = "inventory-list_xt-summary-label__LA4+e";
export var visibleHidden = "inventory-list_visible-hidden__8g6q+";
export var headerBottomMargin = "inventory-list_header-bottom-margin__EKkrM";
export var xtDialogHeaderLogoAlign = "inventory-list_xt-dialog-header-logoAlign__emVNT";
export var xtStickyHeader = "inventory-list_xt-sticky-header__-UWDj";
export var noBorder = "inventory-list_no-border__2+Qui";
export var muiOutlinedInputNotchedOutline = "inventory-list_MuiOutlinedInput-notchedOutline__dbT2f";
export var showFiltersSection = "inventory-list_showFiltersSection__BCzlq";
export var showFilters = "inventory-list_showFilters__vY-5q";
export var showText = "inventory-list_showText__Of2is";
export var filtersContainer = "inventory-list_filtersContainer__-Vc1r";
export var workbenchFilters = "inventory-list_workbenchFilters__7TjsU";
export var showPositiveQty = "inventory-list_showPositiveQty__fX8nr";