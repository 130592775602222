import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { CoreModule } from 'core/core.module'
import { DocumentsModule } from 'documents/documents.module'
import { CommentsModule } from 'comments/comments.module'
import { ContactsModule } from 'contacts/contacts.module'
import { TasksModule } from 'tasks/tasks.module'
import { ShipmentsModule } from 'shipments/shipments.module'
import { CharacteristicsModule } from 'characteristics/characteristics.module'
import { AuthModule } from 'auth/auth.module'
import { SharedModule } from 'shared/shared-module'
import { CustomersServicesModule } from './services/customers-services.module'
import { customersModuleToken } from './customers.constants'
import { ProspectsServicesModule } from '../prospects/services/prospects-services.module'

export type CustomersModuleDefinition = typeof import('./customers.export')

export const CustomersModule: ILazyModule<CustomersModuleDefinition> = {
  name: customersModuleToken,
  resolver: () => import('./customers.export'),
  dependencies: [
    CoreModule,
    SharedModule,
    DocumentsModule,
    CustomersServicesModule,
    ProspectsServicesModule,
    CommentsModule,
    ContactsModule,
    TasksModule,
    ShipmentsModule,
    CharacteristicsModule,
    AuthModule,
  ],
}
