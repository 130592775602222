import type { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import type { OpportunitiesModuleDefinition } from './opportunities/opportunities.module'
import type { TasksModuleDefinition } from 'tasks/tasks.module'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { TasksModule } from 'tasks/tasks.module'
import { activitiesModuleToken } from './activities.constants'
import { OpportunitiesModule } from './opportunities/opportunities.module'

export type ActivitiesModuleDefinition = OpportunitiesModuleDefinition & TasksModuleDefinition

export const ActivitiesModule: ILazyModule<ActivitiesModuleDefinition> = {
  name: activitiesModuleToken,
  resolver: async () => {
    const opportunitiesModule = LazyModulesService.resolveModule<OpportunitiesModuleDefinition>(OpportunitiesModule)
    const tasksModule = LazyModulesService.resolveModule<TasksModuleDefinition>(TasksModule)
    return {
      ...opportunitiesModule,
      ...tasksModule,
    }
  },
  initializer: () => {},
  dependencies: [OpportunitiesModule, TasksModule],
}
