import React, { FC } from 'react'
import { renderEmailCell } from 'components/controls/email/email-link/email-link.utils'
import * as styles from './footer.module.scss'

export const SetUpWizardFooter: FC<{}> = () => {
  return (
    <div className={styles.footerContainer}>
      Thank you for your interest in xT Sales! If you have any questions or concerns, please contact {renderEmailCell('xthelp@caisoft.com')}
    </div>
  )
}
