import React, { FC, useCallback } from 'react'
import { cls } from 'common/utils/utils'
import { useTable } from 'common/hooks/useTable'
import { useShippingModule } from 'shipping/shipping-module-hook'
import { XtNestedList } from 'components/xt-nested-list/xt-nested-list'
import { OrderType } from 'core/core.types'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import * as styles from './maintain-shipping.module.scss'
import { MaintainShippingFilter } from './components/maintain-shipping-filter/maintain-shipping-filter'
import { IMaintainShippingFilterFormFilled } from './components/maintain-shipping-filter/maintain-shipping-filter.types'
import { getSummaryTemplate } from './maintain-shipping.utils'
import { ShippingExtendedTemplate } from './components/shipping-extended-template/shipping-extended-template'
import { maintainShipmentsLabel, maintainShippingPageTitle } from './maintain-shipping.constants'

export const MaintainShipping: FC = () => {
  const { ShippingUtilsService } = useShippingModule()

  const { pagination, state, filter, refresh } = useTable({ showDetails: true }, ShippingUtilsService.fetchShippings)
  useDocumentTitle(maintainShippingPageTitle)
  const handleFilterChange = useCallback(
    (filterData: IMaintainShippingFilterFormFilled): void => {
      void filter({ ...filterData, order_type: filterData.order_number ? OrderType.SalesOrder : null, showDetails: true })
    },
    [filter]
  )

  return (
    <div className={cls('xt-content', styles.listContent)}>
      <div className={styles.listHeader}>
        <h1 className="xt-page-title">{maintainShipmentsLabel}</h1>
      </div>
      <MaintainShippingFilter onChange={handleFilterChange} />
      <XtNestedList
        data={state.data}
        summaryTemplate={getSummaryTemplate}
        expandedTemplate={(shipping) => <ShippingExtendedTemplate shipping={shipping} refreshShipping={refresh} />}
        pagination={pagination}
        loading={state.loading}
      />
    </div>
  )
}
