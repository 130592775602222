// this function is needed in case of Safari vh implementation (https://stackoverflow.com/questions/23223157/how-to-fix-vhviewport-unit-css-in-mobile-safari)

function setViewportProperty(): void {
  const vh = window.innerHeight
  const vw = window.innerWidth
  document.documentElement.style.setProperty(`--xtViewportHeight`, `${vh}px`)
  document.documentElement.style.setProperty(`--xtViewportWidth`, `${vw}px`)
}

export function calculateVH(): void {
  setViewportProperty()
  window.addEventListener('resize', () => {
    setViewportProperty()
  })
}
