import * as React from 'react'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { reduceOneDay, increaseOneDay } from 'common/utils/date.utils'
import { cls } from 'common/utils/utils'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { DocumentType } from 'documents/documents.types'
import { useFormControlState } from 'common/hooks/form/form-control.hook'
import { XtTextArea } from 'components/controls/xt-text-area/xt-text-area'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { useXtForm } from 'common/hooks/form/form'
import { maxNumber } from 'common/validation/validation'
import { FormCheckboxLabel, FormDatePicker, FormField, FormSelectField } from 'common/utils/form/form.components'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { useCommentsModule } from 'comments/comments-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { XtAutocompleteLoadOptionsFunc } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { ItemOption, ItemType } from 'products/items/items.types'
import { useProductsModule } from 'products/products-module-hook'
import { IItemsFilters } from 'products/items/items-list/items-list.types'
import { confirmationMessages } from 'common/constants'
import { XtPrompt } from 'components/xt-prompt'
import { convertBomItemMode, convertToBomItem, convertUomConversions, defineFormState, getItemInputLabel } from './bom-item.utils'
import { useRoutingItemsDialog } from './routing-items-dialog/routing-items-dialog.hook'
import { RoutingItemsDialog } from './routing-items-dialog/routing-items-dialog'
import { BomItemCounter } from './bom-item-counter/bom-item-counter'
import { BomItemFormSchema } from './bom-item.validation'
import { BomItemFormField, FieldName, IBomItemForm, IBomItemParams } from './bom-item.types'
import * as styles from './bom-item.module.scss'
import { issueMethods, itemNumberFilters } from './bom-item.constants'

export const BomItem: FC<IBomItemParams> = ({ parentItemNumber, bomItem, mode, onSubmit, onClose, bomItemsList }) => {
  const { XtComments, useComments } = useCommentsModule()
  const { ErrorHandler } = useCoreModule()
  const { FormXtItemNumber, ItemsUtilsService } = useProductsModule()

  const loadItemOptions: XtAutocompleteLoadOptionsFunc<ItemOption, IItemsFilters> = useCallback(
    async (page, limit, searchFilter, filters) => {
      const { data, total } = await ItemsUtilsService.loadItemOptions(page, limit, searchFilter, filters)
      return {
        data: data.filter((item) => item?.item_number !== parentItemNumber),
        total,
      }
    },
    [ItemsUtilsService, parentItemNumber]
  )

  const { isViewMode, isNewMode, isReplaceMode, isEditMode } = convertBomItemMode(mode)

  const {
    onOpenRoutingDialog,
    onCloseRoutingDialog,
    routingItemsAvailable,
    openRoutingDialog,
    routingItems,
    routingItemsLoading,
  } = useRoutingItemsDialog(parentItemNumber)

  const notesState = useFormControlState<string>(bomItem?.notes ?? '')
  const referenceState = useFormControlState<string>(bomItem?.reference ?? '')
  // TODO:  update unique id after discussing the incident 160064
  const commentSourceNumber =
    bomItem && parentItemNumber && bomItem.sequence_number ? `${parentItemNumber}-${bomItem.sequence_number}` : undefined
  const commentsState = useComments(DocumentType.BomItem, commentSourceNumber)

  const formMethods = useXtForm<IBomItemForm>({
    defaultValues: defineFormState(null),
    validationSchema: BomItemFormSchema(bomItemsList, bomItem, isEditMode),
    mode: 'onChange',
  })

  const { formState, control, handleSubmit, reset, setValue: setFormValue, watch, trigger } = formMethods

  const isDirty = formState.isDirty || notesState.isDirty || referenceState.isDirty || commentsState.isDirtyComments
  const disabled = formState.isSubmitting || isViewMode
  const [effective, expires, usedAt, createChild, scheduleOperation] = watch([
    BomItemFormField.Effective,
    BomItemFormField.Expires,
    BomItemFormField.UsedAt,
    BomItemFormField.CreateChild,
    BomItemFormField.ScheduleOperation,
  ])

  const itemDetails = watch(BomItemFormField.Item)

  useEffect(() => {
    reset(defineFormState(bomItem ?? null))
  }, [bomItem, reset])

  const save: (data: IBomItemForm) => Promise<void> = async (formData) => {
    try {
      const data = convertToBomItem(bomItem, formData, notesState.value, referenceState.value)
      await onSubmit(parentItemNumber, bomItem, data, mode, commentsState.comments)
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }

  const onItemChange = useCallback<(item: ItemOption | null) => Promise<void>>(
    async (item) => {
      setFormValue(BomItemFormField.Item, item)
      setFormValue(BomItemFormField.IssueUom, defineAutocompleteOption(item?.inventory_uom_name), {
        shouldDirty: true,
        shouldValidate: true,
      })
    },
    [setFormValue]
  )

  const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(save)
    void eventHandler(e)
  }

  const tabs = useMemo(
    () => [
      {
        name: FieldName.Notes,
        template: <XtTextArea value={notesState.value} label="Notes" onChange={notesState.setValue} disabled={disabled} />,
      },
      {
        name: FieldName.Reference,
        template: <XtTextArea value={referenceState.value} label="Reference" onChange={referenceState.setValue} disabled={disabled} />,
      },
      {
        name: FieldName.Comments,
        template: (
          <XtComments
            source={DocumentType.BomItem}
            canLoadMore={commentsState.canLoadMore}
            loadMore={commentsState.loadMore}
            creationEnabled={!isViewMode}
            username={commentsState.username}
            onUpdate={commentsState.updateComment}
            onAdd={commentsState.saveComment}
            comments={commentsState.comments}
            disabled={disabled}
          />
        ),
      },
    ],
    [
      notesState.value,
      notesState.setValue,
      disabled,
      referenceState.value,
      referenceState.setValue,
      XtComments,
      commentsState.canLoadMore,
      commentsState.loadMore,
      commentsState.username,
      commentsState.updateComment,
      commentsState.saveComment,
      commentsState.comments,
      isViewMode,
    ]
  )

  const onUsedAtFieldClear: VoidFunction = () => {
    setFormValue(BomItemFormField.UsedAt, '')
    setFormValue(BomItemFormField.ScheduleOperation, false)
  }

  const uomConversions = useMemo(() => convertUomConversions(itemDetails?.inventory_uom, bomItem?.bom_item_inventory_uom), [
    bomItem?.bom_item_inventory_uom,
    itemDetails?.inventory_uom,
  ])

  const closeRoutingDialog: (value?: string) => void = useCallback(
    (value) => {
      if (value) {
        setFormValue(BomItemFormField.UsedAt, value, { shouldDirty: true })
      }
      onCloseRoutingDialog()
    },
    [setFormValue, onCloseRoutingDialog]
  )

  const triggerDatesValidation: VoidFunction = () => {
    void trigger([BomItemFormField.Expires, BomItemFormField.Effective])
  }

  const handleOnClose = () => {
    if (!isDirty) {
      onClose()
      return
    }
    if (confirm(confirmationMessages.unsavedChanges)) {
      onClose()
    }
  }

  return (
    <div className={styles.bomItemDialogContent}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div hidden={!isNewMode} className="xt-page-header no-border">
        <h3 className="xt-page-title">New BOM Item</h3>
      </div>

      <form onSubmit={submitForm} className={styles.bomFormContent}>
        <div className={cls(styles.bomItemFormHeader, 'xt-section-border')}>
          <div className={styles.bomItemHeaderControls}>
            <Controller
              control={control}
              name={BomItemFormField.SequenceNumber}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <BomItemCounter
                  hidden={isNewMode}
                  value={value}
                  onChange={(sequenceNumber) => onChange(sequenceNumber)}
                  disabled={disabled || isReplaceMode}
                  error={isDirty ? error?.message : undefined}
                />
              )}
            />
            <FormXtItemNumber
              control={control}
              name={BomItemFormField.Item}
              isEditMode={true}
              loadOptions={loadItemOptions}
              onChange={onItemChange}
              filters={itemNumberFilters}
              disabled={disabled || isEditMode}
              getInputLabel={getItemInputLabel}
            />
          </div>
          <div className={cls(styles.bomItemHeaderButtonsSection, isViewMode && styles.bomItemHeaderButtonsIsViewMode)}>
            <XtButton label="Cancel" onClick={handleOnClose} />
            <XtButton
              hidden={isViewMode}
              loading={formState.isSubmitting}
              label="Save"
              type="submit"
              disabled={disabled || !isDirty || !formState.isValid}
            />
          </div>
        </div>

        <div className={styles.bomItemForm}>
          <XtDialog open={openRoutingDialog && routingItemsAvailable} fullScreen={false} animation={XtDialogAnimation.FadeAnimation}>
            <RoutingItemsDialog
              bomUom={itemDetails?.inventory_uom_name ?? ''}
              parentItemNumber={parentItemNumber ?? ''}
              items={routingItems}
              onClose={closeRoutingDialog}
            />
          </XtDialog>
          <FormDatePicker
            control={control}
            name={BomItemFormField.Effective}
            label={FieldName.Effective}
            invalidOrNullableLabel="Always"
            maxDate={reduceOneDay(expires)}
            disabled={scheduleOperation || disabled}
            onBlur={triggerDatesValidation}
          />
          <FormDatePicker
            control={control}
            name={BomItemFormField.Expires}
            label={FieldName.Expires}
            invalidOrNullableLabel="Never"
            minDate={increaseOneDay(effective)}
            disabled={scheduleOperation || disabled}
            onBlur={triggerDatesValidation}
          />{' '}
        </div>
        <div className={styles.bomItemForm}>
          <FormCheckboxLabel
            control={control}
            disabled={disabled || itemDetails?.item_type !== ItemType.Manufactured}
            name={BomItemFormField.CreateChild}
            label="Create Child W/O at Parent W/O Explosion"
          />
          <FormCheckboxLabel
            control={control}
            disabled={disabled || !createChild || itemDetails?.item_type !== ItemType.Manufactured}
            name={BomItemFormField.IssueChild}
            label="Issue Child W/O to Parent W/O at Receipt"
          />
        </div>
        <div className={styles.bomItemForm}>
          <DecimalFormField
            control={control}
            name={BomItemFormField.FixedQty}
            label={FieldName.FixedQty}
            disabled={disabled}
            maxValue={maxNumber}
            allowNegative={false}
            fixedDecimalScale={4}
          />
          <DecimalFormField
            control={control}
            name={BomItemFormField.QtyPer}
            label={FieldName.QtyPer}
            disabled={disabled}
            maxValue={maxNumber}
            allowNegative={false}
            fixedDecimalScale={4}
          />
          <FormSelectField
            disabled={disabled || !uomConversions?.length}
            control={control}
            name={BomItemFormField.IssueUom}
            options={uomConversions}
            label={FieldName.IssueUOM}
          />
        </div>
        <div className={styles.bomItemForm}>
          <DecimalFormField
            control={control}
            name={BomItemFormField.Scrap}
            label={FieldName.Scrap}
            disabled={disabled}
            maxValue={maxNumber}
            allowNegative={false}
            fixedDecimalScale={4}
          />
          <FormSelectField
            disabled={disabled}
            name={BomItemFormField.IssueMethod}
            control={control}
            options={issueMethods}
            label={FieldName.IssueMethod}
          />
          <FormField control={control} disabled={disabled} name={BomItemFormField.EcnNumber} label={FieldName.EcnNumber} />
        </div>
        <div className={styles.bomItemForm}>
          <FormField
            disabled
            name={BomItemFormField.UsedAt}
            control={control}
            label={FieldName.UsedAt}
            inputProps={{ readOnly: true, placeholder: FieldName.UsedAt }}
          />
          <FormCheckboxLabel
            control={control}
            name={BomItemFormField.ScheduleOperation}
            label="Schedule at W/O Operation"
            disabled={!usedAt || disabled}
          />
        </div>
        <div className={styles.bomItemButtonsSection}>
          <XtButton
            loading={routingItemsLoading}
            disabled={!routingItemsAvailable || disabled}
            // TODO notify user if no data is available
            onClick={onOpenRoutingDialog}
            label="Routing Item"
          />
          <XtButton className={styles.bomItemFormButton} label="Clear" disabled={disabled || !usedAt} onClick={onUsedAtFieldClear} />
        </div>
        <XtTabs classNameRoot={styles.bomItemFormTabs} tabs={tabs} />
      </form>

      <XtPrompt showPrompt={isDirty} message={confirmationMessages.unsavedChanges} />
    </div>
  )
}
