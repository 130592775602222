import React, { FC } from 'react'
import { useHistory } from 'react-router'
import { inventoryRoutePath, inventoryRoutes } from '../inventory.constants'
import { InventoryAdjustmentDetails } from './inventory-adjustment-details/inventory-adjustment-details'

export const InventoryAdjustmentsPage: FC = () => {
  const history = useHistory()

  const onCancel = (): void => history.push(`${inventoryRoutePath}/${inventoryRoutes.inventoryAvailability}`)
  return <InventoryAdjustmentDetails onClose={onCancel} />
}
