import { ControlMethodOption, IItemSite } from 'products/items/items.types'

export function isLotSerialTabDisabled(site: IItemSite | null): boolean {
  if (!site) {
    return true
  }
  return (
    !site.multiple_location_control &&
    (site.control_method === ControlMethodOption.Regular || site.control_method === ControlMethodOption.None)
  )
}
