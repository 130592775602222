import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { CoreModule } from 'core/core.module'
import { DocumentsModule } from 'documents/documents.module'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { IDocumentsService } from 'documents/documents.types'
import { documentsServiceToken } from 'documents/documents.constants'
import { IErrorHandler } from 'core/services/error-handler.service'
import { errorHandlerServiceToken } from 'core/core.constants'
import { customersServiceToken, customersUtilsServiceToken, customersServicesModuleToken } from '../customers.constants'
import { ICustomersService, ICustomersUtilsService } from './customers-services.types'
import { IProspectsService } from '../../prospects/services/prospects-services.types'
import { prospectsServiceToken } from '../../prospects/prospects.constants'
import { ProspectsServicesModule } from '../../prospects/services/prospects-services.module'

type CustomersServicesModuleDefinition = typeof import('./customers-services.export')

export const CustomersServicesModule: ILazyModule<CustomersServicesModuleDefinition> = {
  name: customersServicesModuleToken,
  resolver: () => import('./customers-services.export'),
  initializer: ({ CustomersService, CustomersUtilsService }) => {
    Injector.register(
      customersServiceToken,
      (injector) => {
        const prospectsService = injector.resolve<IProspectsService>(prospectsServiceToken)
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)

        return new CustomersService(httpClient, documentsService, prospectsService)
      },
      Scope.Singleton
    )

    Injector.register<ICustomersUtilsService>(
      customersUtilsServiceToken,
      (injector) => {
        const customersService = injector.resolve<ICustomersService>(customersServiceToken)
        const errorHandler = injector.resolve<IErrorHandler>(errorHandlerServiceToken)

        return new CustomersUtilsService(customersService, errorHandler)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, DocumentsModule, ProspectsServicesModule],
}
