import { IShipmentPrintLabelPayload } from 'shipments/shipments.types'
import { OrderType } from 'core/core.types'
import { IPrintFormSharedState, PrintSharedFormField } from './print-dialogs.types'

export function convertPrintFormToPrintPayload(
  formData: IPrintFormSharedState,
  type: OrderType,
  orderNumber: string | undefined | null
): IShipmentPrintLabelPayload {
  return {
    order_type: type,
    order_number: orderNumber ?? '',
    label_from: formData[PrintSharedFormField.From],
    label_to: formData[PrintSharedFormField.To],
    report_name: formData[PrintSharedFormField.ReportName]?.id,
  }
}
