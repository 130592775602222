import React, { FC, useEffect, useState } from 'react'
import { XtInput } from 'components/controls/xt-input/xt-input'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { DocumentType } from 'documents/documents.types'
import { useCommentsModule } from 'comments/comments-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCharacteristicsModule } from 'characteristics/characteristics-module-hook'
import { XtMode } from 'common/common.types'
import { useCoreModule } from 'core/core-module-hook'
import { LotSerialPayload } from 'products/items/items.types'
import { defineLotSerialSourceNumber } from 'products/items/items.utils'
import { useProductsModule } from 'products/products-module-hook'
import { useInventoryAdjustmentModule } from 'inventory/inventory-adjustments/inventory-adjustments-module.hook'
import * as styles from './lot-serial-details.module.scss'
import { ILotSerialDialog, LotSerialFormLabel } from './lot-serial-details.types'
import { LotSerialDetailsTabs } from './lot-serial-details-tabs'

export const LotSerialDetails: FC<ILotSerialDialog> = ({ onClose, onClientSubmit, lotSerialNumber, item, itemSite: { item_number } }) => {
  const { useCharacteristics } = useCharacteristicsModule()
  const { useDocuments } = useDocumentsModule()
  const { useRemarks } = useCommentsModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { ItemsService } = useProductsModule()
  const { InventoryAdjustmentUtilsService } = useInventoryAdjustmentModule()

  const [mode, setMode] = useState<XtMode>(XtMode.New)
  const [sourceNumber, setSourceNumber] = useState<string | undefined>()
  const remarksState = useRemarks(DocumentType.LotSerial)
  const characteristicsState = useCharacteristics([])
  const documentsState = useDocuments(DocumentType.LotSerial, sourceNumber)

  const isDirty = documentsState.isDirty || characteristicsState.isDirty || remarksState.isDirty

  const { reset: resetRemarks } = remarksState
  const { reset: resetCharacteristics } = characteristicsState
  const { reset: resetDocuments } = documentsState

  useEffect(() => {
    async function init(): Promise<void> {
      try {
        const lotSerial = await InventoryAdjustmentUtilsService.requestLotSerial(item_number, lotSerialNumber)
        if (lotSerial) {
          const number = defineLotSerialSourceNumber(item_number, lotSerialNumber)
          setSourceNumber(number)
          resetRemarks(number, lotSerial.notes)
          resetCharacteristics(lotSerial.lotserial_characteristics ?? [])
          setMode(XtMode.Edit)

          return
        }

        setMode(XtMode.New)
        resetCharacteristics(item.lotserial_characteristics ?? [])
        resetRemarks(item.comments ?? [], item.notes)
        resetDocuments(item.documents ?? [])
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }

    void init()
  }, [
    ErrorHandler,
    InventoryAdjustmentUtilsService,
    resetDocuments,
    item,
    item_number,
    lotSerialNumber,
    resetCharacteristics,
    resetRemarks,
  ])

  const onCancel = (): void => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave the dialog? Updates will not be applied.')) {
      return
    }
    onClose()
  }

  const handleSubmit = async (): Promise<void> => {
    try {
      const payload: LotSerialPayload = {
        item_number,
        lotserial: lotSerialNumber,
        comments: mode === XtMode.New ? remarksState.comments : null,
        notes: remarksState.notes,
        lotserial_characteristics: characteristicsState.characteristics,
      }
      // const type = control_method === ControlMethodOption.SerialNumber ? 'Serial' : 'Lot'

      if (mode === XtMode.New) {
        onClientSubmit({ ...item, ...payload, documents: documentsState.getUnsavedDocuments() })
      } else {
        const message = await ItemsService.updateLotSerial(payload)
        ToastService.showSuccess(message)
      }
      onClose()
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  return (
    <div className={styles.lotSerialDialog}>
      <div className={styles.headerForm}>
        <div className={styles.headerFormSection}>
          <h1 className="xt-page-title">Lot/Serial # Details</h1>
          <div className={styles.headerFormButtons}>
            <XtButton label="Cancel" onClick={onCancel} />
            <XtButton label="Save" disabled={!isDirty} onClick={handleSubmit} />
          </div>
        </div>
        <div className={styles.headerFormFields}>
          <XtInput disabled value={item_number} label={LotSerialFormLabel.ItemNumber} />
          <XtInput disabled value={lotSerialNumber} label={LotSerialFormLabel.LotSerial} />
        </div>
      </div>
      <LotSerialDetailsTabs
        remarksState={remarksState}
        documentsState={documentsState}
        mode={mode}
        itemNumber={item_number}
        lotSerialNumber={lotSerialNumber}
        characteristicsState={characteristicsState}
      />
    </div>
  )
}
