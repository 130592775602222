import * as React from 'react'
import { FC, useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { cls } from 'common/utils/utils'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { useTable } from 'common/hooks/useTable'
import { useProductsModule } from 'products/products-module-hook'
import { IItemCostsSummaryTotal, ItemOption } from 'products/items/items.types'
import { useCoreModule } from 'core/core-module-hook'
import { xsMq } from 'common/constants'
import { XtList } from 'components/list/list'
import { itemCostsSummaryTotals } from 'products/items/items.constants'
import { DecimalField } from 'components/controls/decimal-form-field/decimal-form-field'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { defineItemNumberOption } from 'products/items/components/item-number/item-number.utils'
import { defaultItemNumberFilters } from 'products/products.constants'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { summaryColumns, initialSummaryFilters, summaryListPageTitle } from './summary-list.constants'
import * as styles from './summary-list.module.scss'
import { convertSummaryFilters } from './summary-list.utils'
import { ISummaryListParams } from './summary-list.types'

export const SummaryList: FC<ISummaryListParams> = ({ className, onClose, itemNumber }) => {
  const { ErrorHandler } = useCoreModule()
  const { XtItemNumber, ItemsUtilsService, ItemsService, CostingItemCostsUtilsService } = useProductsModule()
  useDocumentTitle(summaryListPageTitle)
  const [itemOption, setItemOption] = useState<ItemOption | null>(null)
  const [defaultItemLoading, setDefaultItemLoading] = useState<boolean>(false)

  const [totals, setTotals] = useState<IItemCostsSummaryTotal>(itemCostsSummaryTotals)

  const isMobile = useMediaQuery(xsMq)

  const fetchCostsSummary = useCallback<typeof CostingItemCostsUtilsService.fetchCostsSummary>(
    async (filters, paginationParams, sortOptions) => {
      const summary = await CostingItemCostsUtilsService.fetchCostsSummary(filters, paginationParams, sortOptions)
      const totalsData = await CostingItemCostsUtilsService.fetchCostsSummaryTotal(filters)

      setTotals(totalsData)

      return summary
    },
    [CostingItemCostsUtilsService]
  )

  const { state, pagination, sort, filter } = useTable(initialSummaryFilters, fetchCostsSummary)

  useEffect(() => {
    async function init(number: string): Promise<void> {
      try {
        setDefaultItemLoading(true)
        const defaultItemNumber = await ItemsService.get(number)
        const option = defineItemNumberOption(defaultItemNumber)
        setItemOption(option)
        filter(option)
      } catch (e) {
        ErrorHandler.handleError(e)
      } finally {
        setDefaultItemLoading(false)
      }
    }

    if (itemNumber) {
      void init(itemNumber)
    }
  }, [ErrorHandler, ItemsService, filter, itemNumber])

  const print = async (): Promise<void> => {
    if (!itemOption) {
      return
    }
    try {
      await CostingItemCostsUtilsService.printCostsSummary(itemOption.item_number)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const onItemChange = (option: ItemOption | null): void => {
    setItemOption(option)
    filter(convertSummaryFilters(option))
  }

  return (
    <div className={cls(styles.listContent, className)}>
      <div className={styles.listControls}>
        <div className={cls('xt-page-header', onClose && styles.listDialogHeader)}>
          <h1 className="xt-page-title">Item Costs Summary</h1>
          <XtButton hidden={!onClose} label="Cancel" onClick={() => onClose && onClose()} />
        </div>
        <div className={styles.filterSection}>
          <XtItemNumber
            loadOptions={ItemsUtilsService.loadItemOptions}
            isEditMode
            className={styles.itemNumber}
            value={itemOption}
            onChange={onItemChange}
            disabled={defaultItemLoading}
            loading={defaultItemLoading}
            filters={defaultItemNumberFilters}
          />
          <XtResponsiveButton
            showIconOnDesktopMode={false}
            className={styles.printButton}
            label="Print"
            icon={SvgIconIds.PRINT}
            disabled={state.loading || !state.data.length}
            onClick={print}
          />
        </div>
      </div>
      <div className={styles.listContainer}>
        <XtList
          isMobile={isMobile}
          pagination={pagination}
          loading={state.loading}
          data={state.data}
          columns={summaryColumns}
          sortOptions={state.sortOptions}
          onColumnHeaderClick={sort}
        />
        <div hidden={isMobile && !state.data.length} className={styles.totalsSection}>
          <DecimalField label="Total Std. Cost" value={totals.std_cost ?? 0} disabled fixedDecimalScale={4} />
          <DecimalField label="Total Act. Cost" value={totals.act_cost ?? 0} disabled fixedDecimalScale={4} />
        </div>
      </div>
    </div>
  )
}
