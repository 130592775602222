import { IAuthService, UserPermission } from './auth.types'

export interface IPermissionsService {
  hasPermission(permission: UserPermission): boolean
  hasPermissions(permissions: UserPermission[]): boolean[]
  hasSomePermission(permissions: UserPermission[]): boolean
  hasEveryPermission(permissions: UserPermission[]): boolean
}

export class PermissionsService implements IPermissionsService {
  constructor(private readonly authService: IAuthService) {}

  public hasPermission(permission: UserPermission): boolean {
    const user = this.authService.getCurrentUser()
    if (!user) {
      return false
    }
    return user.privileges.includes(permission)
  }

  public hasPermissions(permissions: UserPermission[]): boolean[] {
    const user = this.authService.getCurrentUser()
    if (!user) {
      return []
    }
    return permissions.map((permission) => user.privileges.includes(permission))
  }

  public hasSomePermission(permissions: UserPermission[]): boolean {
    const user = this.authService.getCurrentUser()
    if (!user || !Array.isArray(user?.privileges)) {
      return false
    }
    return permissions.some((permission) => user.privileges.includes(permission))
  }

  public hasEveryPermission(permissions: UserPermission[]): boolean {
    const user = this.authService.getCurrentUser()
    if (!user || !Array.isArray(user?.privileges)) {
      return false
    }
    return permissions.every((permission) => user.privileges.includes(permission))
  }
}
