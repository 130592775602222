import React, { useEffect, useState } from 'react'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import * as styles from './lazy-module-loader.module.scss'
import { LoadingSpinnerDelayed } from '../loading-spinner-delayed'
import { ILazyModule } from '../../core/react-lazy-modules/react-lazy-modules.types'

export interface ILazyModuleLoader<AsyncModuleDefinition> {
  module: ILazyModule<AsyncModuleDefinition>
  render: (route: AsyncModuleDefinition) => React.ReactElement
  className?: string
}

interface ILazyModuleLoaderState<AsyncModuleDefinition> {
  loading: boolean
  definition: AsyncModuleDefinition | null
  error: string | null
}

const defaultState = { loading: true, error: null, definition: null }

export function LazyModuleLoader<AsyncModuleDefinition>({
  module,
  render,
  className,
}: ILazyModuleLoader<AsyncModuleDefinition>): React.ReactElement {
  const [state, setState] = useState<ILazyModuleLoaderState<AsyncModuleDefinition>>(defaultState)

  const loadModule: () => Promise<void> = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }))
      const definition = await LazyModulesService.loadAsyncModule<AsyncModuleDefinition>(module)
      setState({ definition, loading: false, error: null })
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }))
      console.error(error)
    }
  }

  useEffect(() => {
    void loadModule()
  }, [])

  if (state.error) {
    return <div className={className ?? styles.lazyModuleLoaderContainer}>Content cannot be loaded. Please try again later.</div>
  }

  if (state.loading || !state.definition) {
    return (
      <div className={className ?? styles.lazyModuleLoaderContainer}>
        <LoadingSpinnerDelayed />
      </div>
    )
  }

  return render(state.definition)
}
