import { useCallback, useState } from 'react'

import { defineDefaultState } from './customer-details.hook.utils'
import { ICustomerDialogHookState, ICustomerDialogState } from './customer-details.hook.types'

export function useCustomerDialogState(): ICustomerDialogHookState {
  const [state, setState] = useState<ICustomerDialogState>(defineDefaultState)

  const onCloseCustomerDialog = useCallback(() => setState(defineDefaultState), [])

  const onOpenCustomerDialog = useCallback(
    (customerDialogNumber, customerDialogMode) => setState({ isOpenCustomerDialog: true, customerDialogMode, customerDialogNumber }),
    []
  )
  return {
    ...state,
    onCloseCustomerDialog,
    onOpenCustomerDialog,
  }
}
