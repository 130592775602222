// extracted by mini-css-extract-plugin
export var tasksBoard = "tasks-board_tasksBoard__l5669";
export var tasksBoardHeader = "tasks-board_tasksBoardHeader__i+3KO";
export var tasksBoardAddNewButton = "tasks-board_tasksBoardAddNewButton__L+XmG";
export var tasksBoardCheckboxesFilter = "tasks-board_tasksBoardCheckboxesFilter__StjGP";
export var taskListCheckbox = "tasks-board_taskListCheckbox__HaO22";
export var tasksBoardCheckboxFilter = "tasks-board_tasksBoardCheckboxFilter__q9XGZ";
export var boardHeader = "tasks-board_boardHeader__yRK+l";
export var listControls = "tasks-board_listControls__fy3OD";
export var taskBoardLinksGroup = "tasks-board_taskBoardLinksGroup__FSIQY";
export var taskBoardLink = "tasks-board_taskBoardLink__9C63X";
export var taskBoardLinkActive = "tasks-board_taskBoardLinkActive__V--Yy";
export var taskBoardLinkIcon = "tasks-board_taskBoardLinkIcon__qJqhq";
export var filtersContainer = "tasks-board_filtersContainer__6w50k";