import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { renderEmailCell } from 'components/controls/email/email-link/email-link.utils'
import { renderPhoneCell } from 'components/controls/phone/phone-link/phone-link.utils'
import { dateConverter } from 'common/utils/date.utils'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { booleanColumnConverter, convertPhoneData, phoneColumnConverter } from 'common/utils/table.utils'
import { PhoneRole } from '../contacts.types'
import { ContactAction, IContactTableItem } from './contact-list.types'

export const newContactNumberPrefix: string = 'xtContactNumber'

export const contactActionsEditMode: IXtTableCellAction[] = [
  {
    name: ContactAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: ContactAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: ContactAction.Detach,
    label: 'Detach',
    icon: SvgIconIds.CLOSE_CIRCLE,
  },
]

export const contactActionsViewMode: IXtTableCellAction[] = [
  {
    name: ContactAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]

function convertContactRoles(accounts: unknown, number: string): string {
  if (!Array.isArray(accounts)) {
    return ''
  }
  return (
    accounts
      .filter((item) => item.account_number === number && item.active_role)
      .map((v) => v.role)
      .join(', ') ?? ''
  )
}

export const contactColumns = (accountNumber: string): IXtTableColumn<IContactTableItem>[] => [
  {
    id: 'contact_roles',
    field: 'accounts',
    headerName: 'Active Role(s)',
    flex: '1 0 160px',
    width: 160,
    converter: (accounts: unknown) => convertContactRoles(accounts, accountNumber), // TODO: fix after TableHead types update
    unsortable: true,
  },
  {
    id: 'first_name',
    field: 'first_name',
    headerName: 'First Name',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'last_name',
    field: 'last_name',
    headerName: 'Last Name',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'active',
    field: 'active',
    headerName: 'Active Contact',
    flex: '1 0 170px',
    width: 170,
    converter: booleanColumnConverter,
  },
  {
    id: 'owner',
    field: 'owner',
    headerName: 'Owner',
    flex: '1 0 160px',
    width: 160,
    unsortable: true,
  },
  {
    id: 'account_number',
    field: 'account_number',
    headerName: 'CRM Account',
    flex: '1 0 200px',
    width: 200,
    unsortable: true,
  },
  {
    id: 'company',
    field: 'company',
    headerName: 'Company',
    flex: '1 0 140px',
    width: 140,
  },
  {
    id: 'job_title',
    field: 'job_title',
    headerName: 'Title',
    flex: '1 0 130px',
    width: 130,
  },
  {
    id: 'office_phone',
    headerName: 'Office #',
    flex: '1 0 300px',
    width: 300,
    unsortable: true,
    renderCell: (item) => renderPhoneCell(convertPhoneData(item?.phones, PhoneRole.Office)),
  },
  {
    id: 'mobile_phone',
    headerName: 'Mobile #',
    flex: '1 0 300px',
    width: 300,
    unsortable: true,
    renderCell: (item) => renderPhoneCell(convertPhoneData(item?.phones, PhoneRole.Mobile)),
  },
  {
    id: 'fax_phone',
    field: 'phones',
    headerName: 'Fax #',
    flex: '1 0 220px',
    converter: phoneColumnConverter(PhoneRole.Fax),
    width: 220,
    unsortable: true,
  },
  {
    id: 'email',
    headerName: 'Email',
    flex: '1 0 160px',
    width: 160,
    renderCell: (item) => renderEmailCell(item?.email),
  },
  {
    id: 'web',
    field: 'web',
    headerName: 'Web Address',
    flex: '1 0 180px',
    width: 180,
  },
  {
    id: 'address',
    field: 'contact_address.address1',
    headerName: 'Address',
    flex: '1 0 160px',
    width: 160,
    unsortable: true,
  },
  {
    id: 'city',
    field: 'contact_address.city',
    headerName: 'City',
    flex: '1 0 140px',
    width: 140,
    unsortable: true,
  },
  {
    id: 'state',
    field: 'contact_address.state',
    headerName: 'State',
    flex: '1 0 110px',
    width: 110,
    unsortable: true,
  },
  {
    id: 'country',
    field: 'contact_address.country',
    headerName: 'Country',
    flex: '1 0 120px',
    width: 120,
    unsortable: true,
  },
  {
    id: 'postalcode',
    field: 'contact_address.postalcode',
    headerName: 'Postal Code',
    flex: '1 0 140px',
    width: 140,
    unsortable: true,
  },
  {
    id: 'created',
    field: 'created',
    headerName: 'Created',
    flex: '1 0 160px',
    width: 160,
    converter: dateConverter,
  },
  {
    id: 'lastupdated',
    field: 'lastupdated',
    headerName: 'Updated',
    flex: '1 0 160px',
    width: 160,
    converter: dateConverter,
  },
]
