import { DataGridPro, GridColumns, GridRowParams, GridColumnHeaderParams, GridSortModel } from '@mui/x-data-grid-pro'
import * as React from 'react'
import { useMemo, useCallback, useState } from 'react'
import { TablePagination, TablePaginationProps } from '@material-ui/core'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { ITableRowWithParentId } from 'components/table/table.types'
import { IObjectWithId } from 'common/common.types'
import { globalConstants } from 'common/constants'
import { TableRequestFn, useTable } from 'common/hooks/useTable'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { INestedTableParams, NestedTable } from './nested-xt-table.types'
import * as styles from './nested-xt-table.module.scss'
import { RecursivelyTable } from './recursively-table/recursively-table'
import { useMultiSortModel } from './nested-xt-table.utils'
import { nestedTableClasses, tablePaginationRootClasses, defaultHeightBase } from './nested-xt-table.constants'
import { getRowsPerPageOptions } from '../table/table'

export function useNestedXtTable<TData extends IObjectWithId, TFilters extends {}>(
  initialFilters: TFilters,
  requestFn: TableRequestFn<TData, TFilters>,
  defaultSortOptions: GridSortModel = [],
  pagename?: PageFilterMapping,
  defaultLimit: number = globalConstants.paginationLimit
): NestedTable<TData, TFilters> {
  const { state, sort, ...otherTableProps } = useTable(initialFilters, requestFn, undefined, pagename, defaultLimit)

  const { sortModel, handleSortModelChange } = useMultiSortModel(defaultSortOptions, sort)

  return {
    state: {
      ...state,
      sortOptions: sortModel,
    },
    ...otherTableProps,
    sort: handleSortModelChange,
  }
}

export function NestedXtTable<TData extends ITableRowWithParentId, TColumns extends GridColumns<TData>>({
  columns,
  data,
  loading,
  pagination,
  getIsHasChildren,
  loadRowsFn,
  onColumnHeaderClick,
  sortOptions,
  onRowClick,
  detailPanelColumns,
  getIsHasChildrenForNestedRows,
  detailPanelContent,
  minDetailPanelContentHeight,
}: INestedTableParams<TData, TColumns>): React.ReactElement {
  const [height, setHeight] = useState(minDetailPanelContentHeight ?? defaultHeightBase)

  const onChangePage = useCallback<TablePaginationProps['onChangePage']>((_, newPage) => pagination?.onChangePage(newPage), [pagination])

  const onChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = useCallback(
    ({ target: { value } }) => pagination?.onChangeRowsPerPage(parseInt(value, 10)),
    [pagination]
  )

  const getDetailPanelContent = (params: GridRowParams): JSX.Element | undefined => {
    if (getIsHasChildren && getIsHasChildren(params) && loadRowsFn) {
      return detailPanelContent ? (
        detailPanelContent
      ) : (
        <RecursivelyTable
          setHeight={setHeight}
          params={params}
          columns={detailPanelColumns ?? columns}
          loadRowsFn={loadRowsFn}
          getIsHasChildrenForNestedRows={getIsHasChildrenForNestedRows}
        />
      )
    }
    return
  }

  const components = useMemo(
    () => ({
      DetailPanelCollapseIcon: () => <SvgIcon iconId={SvgIconIds.EXPAND_MORE} className={styles.detailPanelIcon} />,
      DetailPanelExpandIcon: () => <SvgIcon iconId={SvgIconIds.ARROW} className={styles.detailPanelIcon} />,
      Footer: () =>
        pagination ? (
          <TablePagination
            classes={tablePaginationRootClasses}
            component="div"
            count={pagination.count}
            rowsPerPage={pagination.rowsPerPage}
            rowsPerPageOptions={getRowsPerPageOptions(pagination)}
            page={pagination.page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        ) : null,
      NoRowsOverlay: () => <div className={styles.noRowsOverlay}>No data available.</div>,
    }),
    [onChangePage, onChangeRowsPerPage, pagination]
  )

  const handleColumnHeaderClick = useCallback(
    (params: GridColumnHeaderParams): void => {
      if (onColumnHeaderClick) {
        onColumnHeaderClick(params)
      }
    },
    [onColumnHeaderClick]
  )

  const handleRowClick = useCallback(
    (params: GridRowParams): void => {
      if (onRowClick) {
        onRowClick(params)
      }
    },
    [onRowClick]
  )

  return (
    <DataGridPro
      classes={{ virtualScroller: styles.xtNestedScrollbarStyle }}
      sx={nestedTableClasses}
      columns={columns}
      rows={data}
      loading={loading}
      rowSpacingType="margin"
      sortModel={sortOptions}
      sortingMode="server"
      onColumnHeaderClick={handleColumnHeaderClick}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={() => height}
      components={components}
      onRowClick={handleRowClick}
      disableColumnMenu
      disableRowGrouping
      showColumnRightBorder
    />
  )
}
