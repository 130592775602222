import { PermissionGuard } from '../../auth/permission.guard'
import { UserPermission } from '../../auth/auth.types'
import { activitiesRoutePath } from '../activities.constants'

export class OpportunitiesGuard extends PermissionGuard {
  public redirectTo = activitiesRoutePath
  public permissions = [
    UserPermission.ViewAllOpportunities,
    UserPermission.ViewPersonalOpportunities,
    UserPermission.MaintainAllOpportunities,
    UserPermission.MaintainPersonalOpportunities,
  ]
}
