import { BehaviorSubject, Observable } from 'rxjs'

interface INavbarService {
  toggle(): void
  open(): void
  close(): void
  sidebar$: Observable<ISidebarState>
}

interface ISidebarState {
  shown: boolean
}

class Service implements INavbarService {
  private sidebarStateSubject = new BehaviorSubject<ISidebarState>({ shown: false })
  public sidebar$: Observable<ISidebarState> = this.sidebarStateSubject.asObservable()

  public open(): void {
    this.sidebarStateSubject.next({ shown: true })
  }

  public close(): void {
    this.sidebarStateSubject.next({ shown: false })
  }

  public toggle(): void {
    this.sidebarStateSubject.next({ shown: !this.sidebarStateSubject.value.shown })
  }
}

export const SidebarService = new Service()
