import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { dateConverter } from 'common/utils/date.utils'
import { numberConverter } from 'common/utils/utils'
import { booleanCostColumnConverter } from './elements.utils'
import { CostTableItem } from '../../../../../items.types'

export const CostElementsColumns: IXtTableColumn<CostTableItem>[] = [
  {
    id: 'cost_element',
    field: 'cost_element',
    headerName: 'Element',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'lower_level',
    field: 'lower_level',
    headerName: 'Lower',
    flex: '1 0 100px',
    width: 100,
    converter: booleanCostColumnConverter,
  },
  {
    id: 'standard_cost',
    field: 'standard_cost',
    headerName: 'Standard Cost',
    flex: '1 0 200px',
    width: 200,
    converter: numberConverter,
  },
  {
    id: 'standard_cost_currency',
    field: 'standard_cost_currency',
    headerName: 'Currency',
    flex: '1 0 110px',
    width: 110,
  },
  {
    id: 'posted',
    field: 'posted',
    headerName: 'Posted',
    flex: '1 0 140px',
    width: 140,
    converter: dateConverter,
  },
  {
    id: 'actual_cost',
    field: 'actual_cost',
    headerName: 'Actual Cost',
    flex: '1 0 180px',
    width: 180,
    converter: numberConverter,
  },
  {
    id: 'actual_cost_currency',
    field: 'actual_cost_currency',
    headerName: 'Currency',
    flex: '1 0 110px',
    width: 110,
  },
  {
    id: 'lastupdated',
    field: 'lastupdated',
    headerName: 'Updated',
    flex: '1 0 140px',
    width: 140,
    converter: dateConverter,
  },
]
