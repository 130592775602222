import React, { useEffect, useState } from 'react'
import { Controller, PathValue } from 'react-hook-form'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { FieldPath, FieldValues } from 'react-hook-form/dist/types'
import { XtAutocomplete } from 'components/controls/xt-autocomplete/xt-autocomplete'
import {
  IXtAutocompleteOption,
  XtAutocompleteChangeFunc,
  XtAutocompleteLoadOptionsFunc,
  XtAutocompleteOptionType,
  XtAutocompleteValue,
} from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { cls } from 'common/utils/utils'
import './form.components.scss'
import { XtTextArea } from 'components/controls/xt-text-area/xt-text-area'
import { TransferListChangeHandler, TransferListOption } from 'components/controls/xt-transfer-list/xt-transfer-list.types'
import { XtTransferList } from 'components/controls/xt-transfer-list/xt-transfer-list'
import { XtInput } from 'components/controls/xt-input/xt-input'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { XtRadioButton } from 'components/controls/xt-radio-button/xt-radio-button'
import { XtSelectCreatable } from 'components/controls/xt-select/xt-select-creatable'
import { XtRadioGroup } from 'components/controls/xt-radio-group/xt-radio-group'
import { XtDatePicker } from 'components/controls/date-picker/date-picker'
import { XtAutocompletePlain } from 'components/controls/xt-autocomplete/xt-autocomplete-plain'
import { XtSelect } from 'components/controls/xt-select/xt-select'
import { globalConstants } from '../../constants'
import { convertDatePickerValue, convertToError, convertTransferListValueToOptions, shouldShowError } from './form.components.utils'
import {
  IFormField,
  IFormDatePicker,
  IFormCheckboxLabel,
  IFormSelectField,
  IFormXtAutocomplete,
  IFormRadioGroup,
  IFormRadioLabel,
  IFormCheckboxGroup,
  IFormCheckboxOnChangeValue,
  IFormTextAreaField,
  IFormXtTransferList,
  IHandleOnBlurProps,
  IFormSelectCreatableField,
  IFormXtAutocompletePlain,
  IFormXtAutocompleteWithTextInputs,
} from './form.components.types'

export function handleOnBlur({ onControlBlur, onBlur }: IHandleOnBlurProps): void {
  onControlBlur()
  if (typeof onBlur === 'function') {
    onBlur()
  }
}

export function FormField<TFieldValues extends FieldValues>({
  name,
  control,
  label,
  disabled,
  onChange,
  className,
  inputProps,
  error,
  onBlur,
  hidden = false,
  type,
  disableTitle = false,
  mask,
  autoComplete,
  required,
}: IFormField<TFieldValues>): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange: onControlChange, onBlur: onControlBlur },
        fieldState: { error: fieldError, isTouched },
        formState: { isSubmitted },
      }) => {
        const controlError = shouldShowError(isSubmitted, isTouched, disabled) ? convertToError(error, fieldError) : undefined

        return (
          <XtInput
            inputProps={inputProps}
            type={type}
            className={className}
            label={label}
            disableTitle={disableTitle}
            value={value ? String(value) : ''}
            onBlur={() => handleOnBlur({ onControlBlur, onBlur })}
            onChange={onChange ?? onControlChange}
            disabled={disabled}
            hidden={hidden}
            error={controlError}
            mask={mask}
            autoComplete={autoComplete}
            required={required}
          />
        )
      }}
    />
  )
}

export function FormTextAreaField<TFieldValues extends FieldValues>({
  name,
  control,
  label,
  disabled,
  onChange,
  className,
  inputProps,
  onBlur,
  error,
  hidden = false,
  type,
  disableTitle = false,
  rows,
  placeholder,
}: IFormTextAreaField<TFieldValues>): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange: onControlChange, onBlur: onControlBlur },
        fieldState: { error: fieldError, isTouched },
        formState: { isSubmitted },
      }) => {
        const controlError = shouldShowError(isSubmitted, isTouched, disabled) ? convertToError(error, fieldError) : undefined

        return (
          <XtTextArea
            hidden={hidden}
            inputProps={{ type, ...inputProps }} // inputProps.type priority higher than props type
            disabled={disabled}
            className={className}
            rows={rows}
            value={String(value ?? '')}
            title={!disableTitle && value ? `${value}` : ''}
            label={label}
            placeholder={placeholder ?? ''}
            error={controlError}
            onBlur={() => handleOnBlur({ onControlBlur, onBlur })}
            onChange={(changeValue) => (onChange ? onChange(changeValue) : onControlChange(changeValue))}
          />
        )
      }}
    />
  )
}

export function FormSelectField<TFieldValue extends FieldValues, Option extends IXtAutocompleteOption>({
  name,
  control,
  label,
  disabled,
  options,
  onChange,
  className,
  error,
  hidden,
  onBlur,
  multiple,
  clearable,
  renderOption,
  getInputLabel,
  getOptionDisabled,
  required,
}: IFormSelectField<TFieldValue, Option>): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange: onControlChange, onBlur: onControlBlur },
        fieldState: { error: fieldError, isTouched },
        formState: { isSubmitted },
      }) => {
        const controlError = shouldShowError(isSubmitted, isTouched, disabled) ? convertToError(error, fieldError) : undefined

        return (
          <XtSelect
            value={value as XtAutocompleteValue<Option>}
            error={controlError}
            clearable={clearable}
            hidden={hidden}
            className={className}
            onBlur={() => handleOnBlur({ onControlBlur, onBlur })}
            onChange={onChange ?? onControlChange}
            disabled={disabled}
            label={label}
            multiple={multiple}
            options={options}
            renderOption={renderOption}
            getInputLabel={getInputLabel}
            getOptionDisabled={getOptionDisabled}
            required={required}
          />
        )
      }}
    />
  )
}

export function FormSelectCreatableField<TFieldValue extends FieldValues>({
  name,
  control,
  label,
  disabled,
  options,
  onChange,
  className,
  error,
  hidden,
  onBlur,
  endAdornment,
  getInputLabel,
}: IFormSelectCreatableField<TFieldValue>): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange: onControlChange, onBlur: onControlBlur },
        fieldState: { error: fieldError, isTouched },
        formState: { isSubmitted },
      }) => {
        const controlError = shouldShowError(isSubmitted, isTouched, disabled) ? convertToError(error, fieldError) : undefined

        return (
          <XtSelectCreatable
            value={value as IXtAutocompleteOption | null}
            error={controlError}
            hidden={hidden}
            className={className}
            onBlur={() => handleOnBlur({ onControlBlur, onBlur })}
            onChange={onChange ?? onControlChange}
            disabled={disabled}
            label={label}
            options={options}
            endAdornment={endAdornment}
            getInputLabel={getInputLabel}
          />
        )
      }}
    />
  )
}

export function FormDatePicker<TFieldValue extends FieldValues>({
  name,
  control,
  label,
  disabled,
  format = globalConstants.dateFormat,
  className,
  error,
  hidden,
  onBlur,
  onChange,
  maxDate,
  minDate,
  invalidOrNullableLabel,
}: IFormDatePicker<TFieldValue>): React.ReactElement {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange: onControlChange, value, onBlur: onControlBlur },
          fieldState: { error: fieldError, isTouched },
          formState: { isSubmitted },
        }) => {
          const controlError = shouldShowError(isSubmitted, isTouched, disabled) ? convertToError(error, fieldError) : undefined
          return (
            <XtDatePicker
              invalidOrNullableLabel={invalidOrNullableLabel}
              hidden={hidden}
              className={className}
              format={format}
              onBlur={() => handleOnBlur({ onControlBlur, onBlur })}
              label={label}
              minDate={minDate}
              maxDate={maxDate}
              value={convertDatePickerValue(value)}
              onChange={(date, dateString) =>
                onChange ? onChange(date as PathValue<TFieldValue, FieldPath<TFieldValue>>, dateString) : onControlChange(date)
              }
              disabled={disabled}
              error={controlError}
            />
          )
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

export function FormCheckboxLabel<TFieldValue extends FieldValues>({
  label,
  disabled,
  name,
  control,
  onChange,
  className,
  hidden,
  onBlur,
}: IFormCheckboxLabel<TFieldValue>): React.ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange: onControlChange, onBlur: onControlBlur } }) => (
        <XtCheckbox
          value={Boolean(value)}
          className={className}
          label={label}
          hidden={hidden}
          disabled={disabled}
          onBlur={() => handleOnBlur({ onControlBlur, onBlur })}
          onChange={onChange ?? onControlChange}
        />
      )}
    />
  )
}

export function FormRadioLabel<TFieldValue extends FieldValues>({
  label,
  disabled,
  name,
  control,
  onChange,
  className,
  hidden,
  onBlur,
}: IFormRadioLabel<TFieldValue>): React.ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange: onControlChange, onBlur: onControlBlur } }) => (
        <XtRadioButton
          hidden={hidden}
          className={className}
          value={Boolean(value)}
          disabled={disabled}
          onBlur={() => handleOnBlur({ onControlBlur, onBlur })}
          onChange={onChange ?? onControlChange}
          label={label}
        />
      )}
    />
  )
}

export function FormRadioGroup<TFieldValue extends FieldValues>({
  label,
  name,
  disabled,
  className,
  hidden,
  control,
  options,
  onChange,
  onBlur,
}: IFormRadioGroup<TFieldValue>): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange: onControlChange, onBlur: onControlBlur, value } }) => (
        <XtRadioGroup
          value={value as string}
          options={options}
          hidden={hidden}
          className={className}
          label={label}
          disabled={disabled}
          onBlur={() => handleOnBlur({ onControlBlur, onBlur })}
          onChange={(option) => (onChange ? onChange(option) : onControlChange(option))}
        />
      )}
    />
  )
}

export function FormCheckboxGroup<TFieldValue extends FieldValues>({
  label,
  name,
  disabled,
  className,
  hidden,
  control,
  options,
  onChange,
  onBlur,
}: IFormCheckboxGroup<TFieldValue>): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange: onControlChange, onBlur: onControlBlur, value: unpreparedValue } }) => {
        const value = unpreparedValue as IFormCheckboxOnChangeValue
        return (
          <div className={className} hidden={hidden}>
            <p hidden={!label}>{label}</p>
            {options.map((option) => (
              <XtCheckbox
                label={option.label}
                key={`${option.key}`}
                onChange={() =>
                  onChange
                    ? onChange({
                        ...value,
                        [option.key]: value[option.key] === option.value ? false : option.value,
                      })
                    : onControlChange({
                        ...value,
                        [option.key]: value[option.key] === option.value ? false : option.value,
                      })
                }
                onBlur={() => handleOnBlur({ onControlBlur, onBlur })}
                disabled={disabled || option.disabled}
                value={value && value[option.key] === option.value}
              />
            ))}
          </div>
        )
      }}
    />
  )
}

export function FormXtAutocomplete<TFieldValue extends FieldValues, LoadFunc extends XtAutocompleteLoadOptionsFunc>({
  name,
  control,
  label,
  disabled,
  onChange,
  className,
  loadOptions,
  extraOption,
  error,
  hidden,
  renderOption,
  getInputLabel,
  noOptionsText,
  getOptionDisabled,
  onBlur,
  filters,
  limit,
  disableInput,
  clearable,
  required,
}: IFormXtAutocomplete<TFieldValue, LoadFunc>): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange: onControlChange, onBlur: onControlBlur },
        fieldState: { error: fieldError, isTouched },
        formState: { isSubmitted },
      }) => {
        const controlError = shouldShowError(isSubmitted, isTouched, disabled) ? convertToError(error, fieldError) : undefined
        const onChangeHandler: XtAutocompleteChangeFunc<XtAutocompleteOptionType<LoadFunc>> = onChange ?? onControlChange

        return (
          <XtAutocomplete
            limit={limit}
            getOptionDisabled={getOptionDisabled}
            hidden={hidden}
            error={controlError}
            className={cls('MuiFormField', className)}
            value={value as XtAutocompleteValue<XtAutocompleteOptionType<LoadFunc>>}
            onBlur={() => handleOnBlur({ onControlBlur, onBlur })}
            onChange={onChangeHandler}
            loadOptions={loadOptions}
            extraOption={extraOption}
            renderOption={renderOption}
            disabled={disabled}
            placeholder={label}
            getInputLabel={getInputLabel}
            noOptionsText={noOptionsText}
            filters={filters}
            disableInput={disableInput}
            clearable={clearable}
            required={required}
          />
        )
      }}
    />
  )
}

export function FormXtAutocompleteWithTextInputs<TFieldValue extends FieldValues, LoadFunc extends XtAutocompleteLoadOptionsFunc>({
  inputProps,
  control,
  loadOptions,
  renderOption,
  getInputLabel,
  onChange,
  required,
  disabled,
}: IFormXtAutocompleteWithTextInputs<TFieldValue, LoadFunc>): React.ReactElement {
  const [autoComplete, ...rest] = inputProps

  return (
    <>
      {!disabled && (
        <FormXtAutocomplete
          required={required}
          disabled={disabled}
          control={control}
          name={autoComplete.name}
          label={autoComplete.label}
          loadOptions={loadOptions}
          renderOption={renderOption}
          getInputLabel={getInputLabel}
          onChange={onChange}
        />
      )}
      {Array.isArray(rest) &&
        (disabled ? inputProps : rest).map((element) => {
          return <FormField key={element.name} name={element.name} control={control} disabled label={element.label} />
        })}
    </>
  )
}

export function FormXtAutocompletePlain<TFieldValue extends FieldValues, Option extends IXtAutocompleteOption>({
  name,
  control,
  label,
  disabled,
  onChange,
  className,
  options,
  error,
  hidden,
  renderOption,
  getInputLabel,
  noOptionsText,
  getOptionDisabled,
  onBlur,
  loadMore,
  reset,
  filter,
  loading,
  required,
}: IFormXtAutocompletePlain<TFieldValue, Option>): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange: onControlChange, onBlur: onControlBlur },
        fieldState: { error: fieldError, isTouched },
        formState: { isSubmitted },
      }) => {
        const controlError = shouldShowError(isSubmitted, isTouched, disabled) ? convertToError(error, fieldError) : undefined

        const onChangeHandler: XtAutocompleteChangeFunc<Option> = onChange ?? onControlChange

        return (
          <XtAutocompletePlain
            getOptionDisabled={getOptionDisabled}
            hidden={hidden}
            error={controlError}
            className={cls('MuiFormField', className)}
            value={value as XtAutocompleteValue<Option>}
            onBlur={() => handleOnBlur({ onControlBlur, onBlur })}
            onChange={onChangeHandler}
            options={options}
            renderOption={renderOption}
            disabled={disabled}
            placeholder={label}
            getInputLabel={getInputLabel}
            noOptionsText={noOptionsText}
            filter={filter}
            loadMore={loadMore}
            reset={reset}
            loading={loading}
            required={required}
          />
        )
      }}
    />
  )
}

export function FormXtTransferList<TFieldValue extends FieldValues, Option extends TransferListOption>({
  control,
  name,
  onChange,
  availableOptions,
  disabled,
  className,
  availableOptionsLabel,
  selectedOptionsLabel,
  allMode,
}: IFormXtTransferList<TFieldValue, Option>): React.ReactElement {
  const [controlAvailableOptions, setControlAvailableOptions] = useState<Option[]>(availableOptions)

  useEffect(() => {
    setControlAvailableOptions(availableOptions)
  }, [availableOptions])

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange: onControlChange }, fieldState: { error, isTouched }, formState: { isSubmitted } }) => {
        const controlError = isSubmitted || isTouched ? error?.message : undefined

        const handleChange: TransferListChangeHandler<Option> = ({ selectedOptions, availableOptions: updatedAvailableOptions }) => {
          setControlAvailableOptions(updatedAvailableOptions)
          const handler = onChange ?? onControlChange
          handler(selectedOptions)
        }

        return (
          <XtTransferList
            availableOptions={controlAvailableOptions}
            // TODO: make work with all options
            onChange={handleChange}
            selectedOptions={convertTransferListValueToOptions<Option>(value)}
            disabled={disabled}
            availableOptionsLabel={availableOptionsLabel}
            selectedOptionsLabel={selectedOptionsLabel}
            className={className}
            error={controlError}
            allMode={allMode}
          />
        )
      }}
    />
  )
}
