import { Overwrite } from 'utility-types'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from 'core/injector/injector.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { CompaniesLeadsModule, CompaniesLeadsModuleDefinition } from './leads.module'
import { companiesLeadsUtilsServiceToken } from './leads.constants'
import { ICompaniesLeadsUtilsService } from './leads-utils.service'

function resolveCompaniesLeadsModule(): Overwrite<CompaniesLeadsModuleDefinition, { LeadsUtilsService: ICompaniesLeadsUtilsService }> {
  const module = LazyModulesService.resolveModule<CompaniesLeadsModuleDefinition>(CompaniesLeadsModule)
  const CompaniesLeadsUtilsService = Injector.resolve<ICompaniesLeadsUtilsService>(companiesLeadsUtilsServiceToken)

  return {
    ...module,
    LeadsUtilsService: CompaniesLeadsUtilsService,
  }
}

export const useCompaniesLeadsModule = moduleHookResolver(resolveCompaniesLeadsModule)
