import * as React from 'react'
import { FC, useCallback } from 'react'

import { useMediaQuery } from '@material-ui/core'
import { cls } from 'common/utils/utils'
import { useTable } from 'common/hooks/useTable'
import { xsMq } from 'common/constants'
import { XtList } from 'components/list/list'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { RunningAvailabilityFilter } from 'products/items/items.types'
import { runningAvailabilityColumns } from './running-availability-list.constants'
import { useRunningAvailabilityModule } from '../running-availability-module.hook'
import { RunningAvailabilityForm } from './running-availability-list-form/running-availability-list-form'
import { IRunningAvailabilityFormFilled } from './running-availability-list-form/running-availability-list-form.types'
import { IRunningAvailabilityList } from './running-availability-list.types'
import * as styles from './running-availability-list.module.scss'
import { defineRunningAvailabilityFilters } from './running-availability-list.utils'

export const RunningAvailabilityList: FC<IRunningAvailabilityList> = ({ itemNumber, site, className, onClose }) => {
  const { RunningAvailabilityUtilsService } = useRunningAvailabilityModule()
  const isMobile = useMediaQuery(xsMq)

  const { state, pagination, filter, sort } = useTable(
    defineRunningAvailabilityFilters(itemNumber, site),
    RunningAvailabilityUtilsService.fetchItems
  )

  const onOnlyShowPlannedChange = (showPlanned: boolean): void => {
    filter({ ...state.filters, showPlanned })
  }

  const onFilterChange = useCallback(
    async (filtersFormData: IRunningAvailabilityFormFilled) => {
      filter({
        ...state.filters,
        [RunningAvailabilityFilter.Site]: filtersFormData.site.site,
        [RunningAvailabilityFilter.ItemNumber]: filtersFormData.itemNumber.item_number,
      })
    },
    [state.filters, filter]
  )

  return (
    <div className={cls(styles.listContent, className)}>
      <div className={styles.listControls}>
        <div className={cls('xt-section-border', styles.headerContent)}>
          <h1 className="xt-page-title">Running Availability</h1>
          {onClose && <XtButton label="Cancel" onClick={onClose} />}
        </div>
      </div>
      <RunningAvailabilityForm itemNumber={itemNumber} site={site ?? null} disabled={state.loading} onFilterChange={onFilterChange} />
      <XtCheckbox
        className={styles.showPlannedCheckbox}
        value={Boolean(state.filters.showPlanned)}
        label="Show Planned Orders"
        disabled={state.loading}
        onChange={onOnlyShowPlannedChange}
      />
      <div className={styles.table}>
        <XtList
          isMobile={isMobile}
          pagination={pagination}
          loading={state.loading}
          data={state.data}
          columns={runningAvailabilityColumns}
          sortOptions={state.sortOptions}
          onColumnHeaderClick={sort}
        />
      </div>
    </div>
  )
}
