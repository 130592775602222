import { Overwrite } from 'utility-types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IXtMode } from 'common/common.types'
import { IContact, ContactOption } from 'contacts/contacts.types'
import { CRMAccountDocumentOption } from 'documents/documents.types'
import { IOpportunity, IOpportunityExtended } from '../opportunities.types'
import { oppGeneralFormKey } from './general-form/general-form.constants'

export interface IOpportunityDetailsParams extends IXtMode {
  opportunityNumber: string | null
  onClose(): void
  onSubmit?(): void
  accountNumber?: string
}

export interface IOpportunityRequestParams {
  opportunityNumber: string
}
export type OpportunityFormState = Pick<IOpportunityExtended, keyof IOpportunityFormValues | 'notes' | 'accountDetails'>

export interface IOpportunityState {
  opportunity: OpportunityFormState | null
  loading: boolean
}

export enum OpportunityTab {
  General = 'General',
  Characteristics = 'Characteristics',
  Documents = 'Documents',
  Remarks = 'Remarks',
  Sales = 'Sales',
  Tasks = 'Tasks',
}

export enum OpportunityFormField {
  Number = 'number',
  Name = 'name',
  Active = 'active',
  Account = 'account',
  Amount = 'amount',
  Currency = 'currency',
  Owner = 'owner',
  AssignedTo = 'assigned_user',
  Project = 'project',
  OppType = 'type',
  Stage = 'stage',
  Priority = 'priority',
  LeadSource = 'source',
  Probability = 'probability_percent',
  StartDate = 'start_date',
  AssigneeDate = 'assigned_date',
  TargetDate = 'target_date',
  ActualDate = 'actual_date',
  Contact = 'contact',
}

export interface IOpportunityFormValues
  extends Overwrite<
    Pick<IOpportunity, OpportunityFormField>,
    {
      [OpportunityFormField.Account]: CRMAccountDocumentOption | null
      [OpportunityFormField.Currency]: IXtAutocompleteOption | null
      [OpportunityFormField.Owner]: IXtAutocompleteOption | null
      [OpportunityFormField.AssignedTo]: IXtAutocompleteOption | null
      [OpportunityFormField.Project]: IXtAutocompleteOption | null
      [OpportunityFormField.OppType]: IXtAutocompleteOption | null
      [OpportunityFormField.Stage]: IXtAutocompleteOption | null
      [OpportunityFormField.Priority]: IXtAutocompleteOption | null
      [OpportunityFormField.LeadSource]: IXtAutocompleteOption | null
      [OpportunityFormField.StartDate]: Date | null
      [OpportunityFormField.AssigneeDate]: Date | null
      [OpportunityFormField.TargetDate]: Date | null
      [OpportunityFormField.ActualDate]: Date | null
      [OpportunityFormField.Contact]: ContactOption<IContact> | null
      [OpportunityFormField.Probability]: number | null
    }
  > {}

export enum OpportunityFormLabel {
  Number = 'Opportunity #',
  Name = 'Opportunity Name',
  Active = 'Active',
  Account = 'Account',
  Amount = 'Amount',
  Currency = 'Currency',
  Owner = 'Owner',
  AssignedTo = 'Assigned To',
  Project = 'Project',
  OppType = 'Opp. Type',
  Stage = 'Stage',
  Priority = 'Priority',
  LeadSource = 'Lead Source',
  Probability = 'Probability %',
  Contact = 'Contact',
  StartDate = 'Started',
  AssigneeDate = 'Assigned',
  TargetDate = 'Target Close',
  ActualDate = 'Actual Close',
}
export interface IOpportunityDetailsCombinedState {
  [oppGeneralFormKey]: IOpportunityFormValues
}
