import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IUom, IUomConversion, UomTypes } from 'core/services/uom.service'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'

function isSellingUomConversion({ uom_types }: IUomConversion): boolean {
  if (!uom_types) {
    return false
  }
  return uom_types.includes(UomTypes.Selling)
}

export function extractUomOptions(uom: IUom | null): IXtAutocompleteOption[] {
  if (!uom) {
    return []
  }
  const uomOptions = new Set([uom.name])
  uom?.uom_conversions.filter(isSellingUomConversion).forEach(({ uom_to, uom_from = uom_to }) => {
    uomOptions.add(uom_to)
    uomOptions.add(uom_from)
  })

  return [...uomOptions].map((value) => defineAutocompleteOption(value))
}
