import { Overwrite } from 'utility-types'
import { LazyModulesService } from '../core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from '../core/injector/injector.service'
import { IToastService } from './toast.service'
import { toastModuleToken, toastServiceToken } from './toast.constants'
import { moduleHookResolver } from '../core/react-lazy-modules/react-lazy-modules.utils'
import { IToastModuleServices, ToastModuleDefinition } from './toast-module.types'

function resolveToastModule(): Overwrite<ToastModuleDefinition, IToastModuleServices> {
  const module = LazyModulesService.resolveModule<ToastModuleDefinition>(toastModuleToken)
  const ToastService = Injector.resolve<IToastService>(toastServiceToken)

  return { ...module, ToastService }
}

export const useToastModule = moduleHookResolver(resolveToastModule)
