import React, { FC } from 'react'
import * as styles from './opportunity-kanban-column-footer.module.scss'
import { IKanbanOpportunityColumn } from '../opportunities-board.types'

export const OpportunityKanbanColumnFooter: FC<IKanbanOpportunityColumn> = ({
  data,
  baseCurrency,
  opportunityTotal,
  styles: opportunitiesStyles,
}) => {
  const totalText = data.length ? `${baseCurrency} ${opportunityTotal.toLocaleString()}` : ''
  const backgroundColor = opportunitiesStyles?.footerColor

  return (
    <div className={styles.columnFooter} hidden={!data.length} style={{ backgroundColor }}>
      <span className={styles.columnFooterText}>{totalText}</span>
    </div>
  )
}
