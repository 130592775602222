import { SingleFormRegister } from 'common/hooks/form/multiple-form-manager/multiple-form-manager.types'
import { ICustomer } from 'companies/customers/customers.types'

import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { settingsFormKey } from './settings-tab.constants'

export interface ISettingTab {
  register: SingleFormRegister<typeof settingsFormKey, ISettingTermsForm>
  customer: ICustomer | null
  disabled?: boolean
}

export enum SettingsTermsFormField {
  Terms = 'terms_code',
  Discount = 'default_discount',
  Currency = 'default_currency',
  CreditLimit = 'credit_limit',
  CreditLimitCurrency = 'credit_limit_currency',
  CreditRating = 'credit_rating',
  UsesPurchaseOrders = 'uses_purchase_orders',
  UsesBlanketPOs = 'uses_blanket_pos',
  CreditStatus = 'credit_status',
  CreditStatusExceedHold = 'credit_status_exceed_hold',
  CreditStatusExceedWarn = 'credit_status_exceed_warn',
  AlternateGraceDays = 'alternate_grace_days',
  AllowFreeFormShipTo = 'allow_free_form_shipto',
  AllowFreeFormBillTo = 'allow_free_form_billto',
}

export enum SettingsTermsFormFieldLabel {
  Terms = 'Terms',
  Discount = 'Discount',
  Currency = 'Currency',
  CreditLimit = 'Credit Limit',
  CreditLimitCurrency = 'Credit Limit Currency',
  CreditStatusExceedWarn = 'Place on Credit Warning when Credit Limit/Grace Days is Exceeded',
  CreditStatusExceedHold = 'Place open sales order on Credit Hold when Credit Limit is Exceeded',
  CreditStatus = 'Credit Status',
  UsesPurchaseOrders = 'Uses Purchase Orders',
  UsesBlanketPOs = `Uses Blanket P/O's`,
  AlternateGraceDays = 'Alternate Grace Days',
  AllowFreeFormShipTo = 'Allow free form Ship-To',
  AllowFreeFormBillTo = 'Allow free form Bill- To',
}

export interface ISettingsTermsDefaultValues {
  options: {
    // tax_zone_options: IXtAutocompleteOption[]
    terms_options: IXtAutocompleteOption[]
  }
}

export interface ISettingTermsForm {
  [SettingsTermsFormField.Terms]: IXtAutocompleteOption | null
  [SettingsTermsFormField.Discount]: number | null
  [SettingsTermsFormField.Currency]: IXtAutocompleteOption | null
  [SettingsTermsFormField.CreditLimit]: number | null
  [SettingsTermsFormField.CreditLimitCurrency]: IXtAutocompleteOption | null
  [SettingsTermsFormField.CreditRating]: string
  [SettingsTermsFormField.CreditStatus]: IXtAutocompleteOption | null
  [SettingsTermsFormField.CreditStatusExceedHold]: boolean
  [SettingsTermsFormField.CreditStatusExceedWarn]: boolean
  [SettingsTermsFormField.AlternateGraceDays]: number
  [SettingsTermsFormField.UsesPurchaseOrders]: boolean
  [SettingsTermsFormField.UsesBlanketPOs]: boolean
  [SettingsTermsFormField.AllowFreeFormBillTo]: boolean
  [SettingsTermsFormField.AllowFreeFormShipTo]: boolean
}
