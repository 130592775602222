import { IRoutingTableItem } from './routing-items-dialog.types'
import { IRoutingItem } from '../../../routing/routing.types'

export function convertBooItems(items: IRoutingItem[]): IRoutingTableItem[] {
  return items.map((value) => ({
    id: value.sequence_number,
    sequence_number: value.sequence_number,
    standard_operation: value.standard_operation,
    description: value.routing_description2 ? `${value.routing_description1} - ${value.routing_description2}` : value.routing_description1,
  }))
}

export function convertToOperation(item: IRoutingTableItem | null): string {
  return item?.standard_operation ? `${item.sequence_number}-${item.standard_operation}` : item!.sequence_number.toString()
}
