import { defaultSetupWizardRoute } from 'setup-wizard/setup-wizard.constants'
export const logout: string = 'Log out'
export const setupWizard: string = 'Setup Wizard'

export interface IPersonalProfileMenuItems {
  name: string
  link?: string
}

export const menuItems: IPersonalProfileMenuItems[] = [
  {
    name: setupWizard,
    link: defaultSetupWizardRoute,
  },
  {
    name: logout,
  },
]
