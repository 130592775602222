import { CharacteristicOption, IAppliedCharacteristic, IAppliedCharacteristicNew, ICharacteristic } from './characteristics.types'
import { TableValueConverter } from '../components/table/table-head/table-head.types'
import { convertDate } from '../common/utils/date.utils'
import { NumberTypeUtils } from '../common/utils/type.utils'
import { convertNumberWithThousandSeparatorAndDigits } from '../common/utils/utils'

export const characteristicsToPayload = (characteristics: IAppliedCharacteristic[]): IAppliedCharacteristicNew[] =>
  characteristics.map(({ id: _, characteristic_value, ...char }) => ({ ...char, characteristic_value: String(characteristic_value) }))

export function convertCharacteristicDefault(value: unknown): string {
  if (typeof value !== 'boolean') {
    return 'No'
  }
  return value ? 'Yes' : 'No'
}

// TODO: fix unknown type
export const characteristicValueConverter: TableValueConverter = (value: unknown) => {
  if (!value && !NumberTypeUtils.isNumber(value)) {
    return ''
  }
  if (NumberTypeUtils.isNumber(value)) {
    return convertNumberWithThousandSeparatorAndDigits(Number(value), 0)
  }
  if (value instanceof Date) {
    return convertDate(value)
  }
  if (typeof value === 'string') {
    const date = convertDate(value)
    return date || value
  }

  return String(value)
}

export function defineCharacteristicOption(characteristic: ICharacteristic): CharacteristicOption
export function defineCharacteristicOption(characteristic: ICharacteristic | null | undefined): CharacteristicOption | null

export function defineCharacteristicOption(characteristic: ICharacteristic | null | undefined): CharacteristicOption | null {
  return characteristic ? { ...characteristic, id: characteristic.name, label: characteristic.name } : null
}
