// extracted by mini-css-extract-plugin
export var root = "maintain-shipping_root__W2vdc";
export var xtContent = "maintain-shipping_xt-content__IUhEk";
export var xtContentWithRemarks = "maintain-shipping_xt-content-with-remarks__2qSH4";
export var muiFormHelperTextRoot = "maintain-shipping_MuiFormHelperText-root__cn1QQ";
export var xtListPageContainer = "maintain-shipping_xt-list-page-container__94-J7";
export var muiInputBaseRoot = "maintain-shipping_MuiInputBase-root__JDy7S";
export var muiTextFieldRoot = "maintain-shipping_MuiTextField-root__03Bk+";
export var muiTablePaginationRoot = "maintain-shipping_MuiTablePagination-root__MAA5O";
export var muiTablePaginationSelectRoot = "maintain-shipping_MuiTablePagination-selectRoot__I3SZB";
export var muiTablePaginationSelect = "maintain-shipping_MuiTablePagination-select__p+evC";
export var muiStepperRoot = "maintain-shipping_MuiStepper-root__WSD4b";
export var muiStepperHorizontal = "maintain-shipping_MuiStepper-horizontal__zUhsy";
export var muiStepIconRoot = "maintain-shipping_MuiStepIcon-root__tcgle";
export var muiStepIconActive = "maintain-shipping_MuiStepIcon-active__emgsY";
export var muiStepIconCompleted = "maintain-shipping_MuiStepIcon-completed__T9d0R";
export var muiListItemRoot = "maintain-shipping_MuiListItem-root__2uKzp";
export var muiSelected = "maintain-shipping_Mui-selected__FGFXu";
export var xtPageTitle = "maintain-shipping_xt-page-title__497Wu";
export var xtSubPageTitle = "maintain-shipping_xt-subPage-title__sLhBG";
export var xtSectionBorder = "maintain-shipping_xt-section-border__l7QGC";
export var xtPageHeader = "maintain-shipping_xt-page-header__NLJiV";
export var listHeader = "maintain-shipping_listHeader__y-2-J";
export var xtDialogScrollable = "maintain-shipping_xt-dialog-scrollable__BBYA1";
export var reactToastNotificationsContainer = "maintain-shipping_react-toast-notifications__container__7VOq0";
export var xtScrollbarStyle = "maintain-shipping_xt-scrollbar-style__dhgS0";
export var xtModalDetailsContent = "maintain-shipping_xt-modal-details-content__fXrPo";
export var xtDialogDetailsContent = "maintain-shipping_xt-dialog-details-content__voK1V";
export var xtLazyModuleLoaderTabContainer = "maintain-shipping_xt-lazy-module-loader-tab-container__13+DZ";
export var xtTextTruncated = "maintain-shipping_xt-text-truncated__Mxw2h";
export var summaryContent = "maintain-shipping_summaryContent__+1Xyi";
export var xtSummaryContent = "maintain-shipping_xt-summary-content__UfF2K";
export var xtSummaryLabel = "maintain-shipping_xt-summary-label__9Ak9A";
export var visibleHidden = "maintain-shipping_visible-hidden__LP4-s";
export var headerBottomMargin = "maintain-shipping_header-bottom-margin__CE5hh";
export var xtDialogHeaderLogoAlign = "maintain-shipping_xt-dialog-header-logoAlign__3ePmE";
export var xtStickyHeader = "maintain-shipping_xt-sticky-header__trIVy";
export var noBorder = "maintain-shipping_no-border__Dij5z";
export var muiOutlinedInputNotchedOutline = "maintain-shipping_MuiOutlinedInput-notchedOutline__dTcsk";
export var listContent = "maintain-shipping_listContent__xNdCz";