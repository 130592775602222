// extracted by mini-css-extract-plugin
export var setupWizard = "welcome-msg_setupWizard__izeSA";
export var setupWizardContent = "welcome-msg_setupWizardContent__Gunt9";
export var setupWizardContainer = "welcome-msg_setupWizardContainer__fGXKv";
export var navigationListContainer = "welcome-msg_navigationListContainer__bB9E9";
export var buttonToolbar = "welcome-msg_buttonToolbar__9gOV9";
export var sectionDivider = "welcome-msg_sectionDivider__jD4ls";
export var muiListItemRoot = "welcome-msg_MuiListItem-root__kEP1t";
export var muiSelected = "welcome-msg_Mui-selected__uwz88";
export var buttonContainer = "welcome-msg_buttonContainer__Sir9C";
export var disabledListItem = "welcome-msg_disabledListItem__UaPv1";
export var stepListItem = "welcome-msg_stepListItem__WAebJ";
export var goBackButton = "welcome-msg_goBackButton__WLTX5";
export var welcomeMsgContainer = "welcome-msg_welcomeMsgContainer__j4PPQ";
export var welcomeMsgHeader = "welcome-msg_welcomeMsgHeader__0YkJI";
export var boldText = "welcome-msg_boldText__-kf2I";
export var disabledBullet = "welcome-msg_disabledBullet__xreYZ";
export var listContainer = "welcome-msg_listContainer__+5EcM";