export enum TransferListAction {
  Add = 'add',
  Remove = 'remove',
  AddAll = 'add_all',
  RemoveAll = 'remove_all',
}

export enum ListOptionType {
  Available,
  Selected,
}

export type TransferListOption = { id: string; label: string; disabled?: boolean }

export interface ITransferListState<Option> {
  selectedOptions: Option[]
  availableOptions: Option[]
}

export interface ISelectedOptionState<Option> {
  chosenValue: Option
  direction: ListOptionType
}

export interface ITransferListOptions<Option> {
  selectedOptions: Option[]
  availableOptions: Option[]
}

export interface ITransferListOptionsChange<Option> extends ITransferListOptions<Option> {
  removedOptions: Option[]
  addedOptions: Option[]
  action: TransferListAction
}

export type TransferListChangeHandler<Option extends TransferListOption = TransferListOption> = (
  options: ITransferListOptionsChange<Option>
) => void

export interface IXtTransferListParams<Option extends TransferListOption> {
  availableOptions: Option[]
  selectedOptions: Option[]
  onChange: TransferListChangeHandler<Option>
  disabled?: boolean
  className?: string
  error?: string
  availableOptionsLabel?: string
  selectedOptionsLabel?: string
  removeButtonLabel?: string
  allMode?: boolean
}
