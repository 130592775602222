import type { IContact, IContactAddress } from '../contacts/contacts.types'
import type { IAddress, IContactAndAddress } from './accounts/accounts.types'

export function convertContactAddressToAddress(address: IContactAddress | null | undefined): IAddress {
  return {
    address1: address?.address1 ?? '',
    address2: address?.address2 ?? '',
    address3: address?.address3 ?? '',
    city: address?.city ?? '',
    state: address?.state ?? '',
    postal_code: address?.postalcode ?? '',
    country: address?.country ?? '',
  }
}

export function convertBillToContactAndAddressType(data: IContact | null | undefined): IContactAndAddress | null {
  if (!data || !data.contact_number) {
    return null
  }
  return {
    contact: data,
    address: convertContactAddressToAddress(data?.contact_address),
  }
}
