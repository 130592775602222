import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { Scope } from 'core/injector/injector.types'
import { DocumentsModule } from 'documents/documents.module'
import { CoreModule } from 'core/core.module'
import { IDocumentsService } from 'documents/documents.types'
import { documentsServiceToken } from 'documents/documents.constants'
import { SalesOrdersModule } from 'sales-orders/sales-orders.module'
import { IPurchaseOrdersUtilsService } from './purchase-orders-utils.service'
import { purchaseOrdersModule, purchaseOrdersServiceToken, purchaseOrdersUtilsServiceToken } from './purchase-orders.constants'
import { IPurchaseOrdersService } from './purchase-orders.service'
import { VendorServicesModule } from '../vendor/vendor-services.module'
import { IErrorHandler } from '../core/services/error-handler.service'
import { errorHandlerServiceToken } from '../core/core.constants'
import { ItemSourceUtilsModule } from '../item-source/item-source-utils.module'
import { DictionaryModule } from '../dictionary/dictionary.module'
import { ContactsModule } from '../contacts/contacts.module'
import { ShipmentsModule } from '../shipments/shipments.module'
import { ICommentsService } from '../comments/comments.service'
import { commentsServiceToken } from '../comments/comments.constants'
import { IVendorService } from '../vendor/vendor.service'
import { vendorServiceToken } from '../vendor/vendor.constants'
import { IItemsUtilsService } from '../products/items/items-utils.service'
import { itemsUtilsServiceToken } from '../products/products.constants'

export type PurchaseOrdersModuleDefinition = typeof import('./purchase-orders.exports')

export interface IPurchaseOrdersModuleServices {
  PurchaseOrdersService: IPurchaseOrdersService
  PurchaseOrdersUtilsService: IPurchaseOrdersUtilsService
}

export const PurchaseOrdersModule: ILazyModule<PurchaseOrdersModuleDefinition> = {
  name: purchaseOrdersModule,
  resolver: () => import('./purchase-orders.exports'),
  initializer: ({ PurchaseOrdersService, PurchaseOrdersUtilsService }) => {
    Injector.register<IPurchaseOrdersService>(
      purchaseOrdersServiceToken,
      (injector) => {
        const apiClient = injector.resolve<IHttpClient>(httpClientToken)
        const commentsService = injector.resolve<ICommentsService>(commentsServiceToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
        return new PurchaseOrdersService(apiClient, commentsService, documentsService)
      },
      Scope.Singleton
    )
    Injector.register<IPurchaseOrdersUtilsService>(
      purchaseOrdersUtilsServiceToken,
      (injector) => {
        const purchaseOrdersService = injector.resolve<IPurchaseOrdersService>(purchaseOrdersServiceToken)
        const vendorService = injector.resolve<IVendorService>(vendorServiceToken)
        const itemsUtilsService = injector.resolve<IItemsUtilsService>(itemsUtilsServiceToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
        const errorHandler = injector.resolve<IErrorHandler>(errorHandlerServiceToken)

        return new PurchaseOrdersUtilsService(purchaseOrdersService, vendorService, itemsUtilsService, documentsService, errorHandler)
      },
      Scope.Singleton
    )
  },
  dependencies: [
    DocumentsModule,
    CoreModule,
    VendorServicesModule,
    ItemSourceUtilsModule,
    DictionaryModule,
    ContactsModule,
    ShipmentsModule,
    SalesOrdersModule,
  ],
}
