import { IHttpClient } from 'core/services/http-client'
import { isHttpError } from '../core.utils'

export interface ICallViaTwilioPhone {
  to: string
  from: string
  record: boolean
}
export interface ISmsPhone {
  to: string
  message: string
  image?: File | null
}

export interface IPhoneService {
  callPhone: (data: ICallViaTwilioPhone) => Promise<string>
  smsPhone: (data: ISmsPhone) => Promise<string>
}

export class PhoneCallFormatError extends Error {
  constructor(phone: string) {
    super(
      `Error calling ${phone}, please check the phone number is formatted properly. Be sure to include a plus sign (+) and country code for international numbers.`
    )
  }
}

export class PhoneTextFormatError extends Error {
  constructor(phone: string) {
    super(
      `Error sending message to ${phone}, please check the phone number is formatted properly. Be sure to include a plus sign (+) and country code for international numbers.`
    )
  }
}

export class PhoneService implements IPhoneService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async callPhone(data: ICallViaTwilioPhone): Promise<string> {
    let responseMessage
    try {
      const body = { data }
      const {
        data: {
          status: { message },
        },
      } = await this.apiClient.post('/voice/call', body)
      responseMessage = message
    } catch (e) {
      if (PhoneService.isInvalidRequest(e)) {
        throw new PhoneCallFormatError(data.to)
      }
      throw e
    }
    return responseMessage
  }

  public async smsPhone(data: ISmsPhone): Promise<string> {
    let responseMessage
    try {
      const body = { data }

      const {
        data: {
          status: { message },
        },
      } = await this.apiClient.post('/sms/send', body)
      responseMessage = message
    } catch (e) {
      if (PhoneService.isInvalidRequest(e)) {
        throw new PhoneTextFormatError(data.to)
      }
      throw e
    }
    return responseMessage
  }

  private static isInvalidRequest(error: Error): boolean {
    return isHttpError(error) && error.response.status === 400
  }
}
