import { useState, useCallback } from 'react'
import { GridColumnHeaderParams, GridSortModel } from '@mui/x-data-grid-pro'
import { SortDirection, TableSortingFn } from '../table/table-head/table-head.types'

interface IMultiSortModel {
  sortModel: GridSortModel
  handleSortModelChange: (params: GridColumnHeaderParams) => void
}

export const useMultiSortModel = (initialValue: GridSortModel, sort: TableSortingFn): IMultiSortModel => {
  const [sortModel, setSortModel] = useState<GridSortModel>(initialValue)

  const handleSortModelChange = useCallback(
    (params: GridColumnHeaderParams): void => {
      const { field } = params

      if (field !== 'XtTableCellActions') {
        setSortModel((prev) => {
          const sortModelMap = new Map(prev.map((model) => [model.field, model]))
          const currentSortModel = sortModelMap.get(field)
          if (currentSortModel && !currentSortModel.sort) {
            sortModelMap.set(field, { ...currentSortModel, sort: SortDirection.Ascending })
            void sort({ sortField: field, sortDirection: SortDirection.Ascending })
            return [...sortModelMap.values()]
          }
          if (currentSortModel && currentSortModel.sort) {
            const sortDirection = currentSortModel.sort === SortDirection.Ascending ? SortDirection.Descending : undefined
            sortModelMap.set(field, { ...currentSortModel, sort: sortDirection })
            void sort({ sortField: field, sortDirection })
            return [...sortModelMap.values()]
          }
          sortModelMap.set(field, { field, sort: 'asc' })
          void sort({ sortField: field, sortDirection: SortDirection.Ascending })
          return [...sortModelMap.values()]
        })
      }
    },
    [sort]
  )

  return { sortModel, handleSortModelChange }
}
