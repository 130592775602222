import { Overwrite } from 'utility-types'
import { IShipmentsServices, ShipmentsModule, ShipmentsModuleDefinition } from './shipments.module'
import { IShipmentsService } from './shipments.service'
import { LazyModulesService } from '../core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from '../core/injector/injector.service'
import { shipmentsServiceToken, shipmentsUtilsServiceToken } from './shipments.constants'
import { IShipmentsUtilsService } from './shipments-utils.service'
import { moduleHookResolver } from '../core/react-lazy-modules/react-lazy-modules.utils'

type ShipmentsModuleHook = Overwrite<ShipmentsModuleDefinition, IShipmentsServices>

function resolveShipmentsModule(): ShipmentsModuleHook {
  const module = LazyModulesService.resolveModule<ShipmentsModuleDefinition>(ShipmentsModule)
  const ShipmentsService = Injector.resolve<IShipmentsService>(shipmentsServiceToken)
  const ShipmentsUtilsService = Injector.resolve<IShipmentsUtilsService>(shipmentsUtilsServiceToken)

  return { ...module, ShipmentsService, ShipmentsUtilsService }
}

export const useShipmentsModule = moduleHookResolver<ShipmentsModuleHook>(resolveShipmentsModule)
