import * as React from 'react'
import { FC, useCallback } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { cls } from 'common/utils/utils'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { LoaderMessage, confirmationMessages, xsMq } from 'common/constants'
import { useTable } from 'common/hooks/useTable'
import { XtList } from 'components/list/list'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { XtMode } from 'common/common.types'
import { UserPermission } from 'auth/auth.types'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { XtPageFilter } from 'components/pagefilter/pagefilter'
import { usePageFilter } from 'components/pagefilter/pagefilter.utils'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import * as styles from './accounts-list.module.scss'
import { AccountActions, accountsListPageTitle, crmAccountColumns, defaultFilterValues } from './accounts-list.constants'
import { getAccountActions, resolveFilters } from './accounts-list.utils'
import { useAccountsModule } from '../accounts-module-hook'
import { AccountAction, IAccountTableItem } from './accounts-list.types'
import { AccountFilterFieldName, AccountFilterPanel } from '../services/accounts-services.types'

export const AccountsList: FC = () => {
  const { DocumentsUtilsService } = useDocumentsModule()
  const { AccountsService, AccountsUtilsService } = useAccountsModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { PermissionsService } = useAuthModule()
  useDocumentTitle(accountsListPageTitle)
  const history = useHistory()
  const { path } = useRouteMatch()

  const { open, openDialog, closeDialog, itemId } = useConfirmationDialog<string>()

  const canCreateAccount = PermissionsService.hasSomePermission([
    UserPermission.MaintainAllCRMAccounts,
    UserPermission.MaintainPersonalCRMAccounts,
  ])

  const {
    state: { loading, data, filters: tableFilters, sortOptions },
    setLoading,
    refresh,
    pagination,
    filter,
    sort,
  } = useTable(defaultFilterValues, AccountsUtilsService.fetchAccounts, undefined, PageFilterMapping.CRMAccounts)

  const accountListFilters = usePageFilter<AccountFilterPanel>(PageFilterMapping.CRMAccounts)

  const handleRowClick = useCallback<(item: IAccountTableItem) => void>(
    ({ id, editable }) => {
      history.push(`${path}/${id}/${editable ? XtMode.Edit : XtMode.View}`)
    },
    [path, history]
  )

  const handleAction = useCallback<(item: IAccountTableItem, action: AccountAction) => void>(
    ({ number }, action) => {
      switch (action) {
        case AccountAction.Delete: {
          openDialog(number)
          break
        }
        case AccountAction.Edit: {
          history.push(`${path}/${number}/${XtMode.Edit}`)
          break
        }
        case AccountAction.View: {
          history.push(`${path}/${number}/${XtMode.View}`)
          break
        }
        default:
      }
    },
    [openDialog, history, path]
  )

  const isMobile = useMediaQuery(xsMq)

  const handleDeletion = useCallback<() => Promise<void>>(async () => {
    closeDialog()
    if (itemId) {
      try {
        setLoading(true)
        const message = await AccountsService.delete(itemId)
        ToastService.showSuccess(message)
        await refresh()
        setLoading(false)
      } catch (error) {
        ErrorHandler.handleError(error as Error)
        setLoading(false)
      }
    }
  }, [closeDialog, itemId, setLoading, AccountsService, ToastService, refresh, ErrorHandler])

  const handleShowInactiveFilterChange = (checked: boolean): void => {
    const params = { ...tableFilters, [AccountFilterFieldName.ShowInactive]: checked }
    void filter(params)
    void accountListFilters.handleLastUsedFilter(params)
  }

  return (
    <div className={cls('xt-content', styles.listContent)}>
      <XtConfirmationDialog
        open={open}
        message={confirmationMessages.deleted}
        title="Delete Account"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeDialog}
      />

      <div className={styles.listControls}>
        <div className={cls('xt-section-border', styles.listHeader)}>
          <h1 className="xt-page-title">Accounts</h1>
          <XtResponsiveButton
            disabled={!canCreateAccount}
            label="New Account"
            icon={SvgIconIds.ADD_CIRCLE}
            onClick={() => history.push(`${path}/${XtMode.New}`)}
          />
        </div>
        <div className={styles.filtersContainer}>
          <XtPageFilter
            state={accountListFilters}
            defaultFilterValues={defaultFilterValues}
            resolveFilters={() => resolveFilters(DocumentsUtilsService)}
            filter={filter}
            tableFilters={tableFilters}
          />
          <XtCheckbox
            className={styles.accountsListCheckbox}
            label="Show Inactive"
            value={Boolean(tableFilters[AccountFilterFieldName.ShowInactive])}
            onChange={handleShowInactiveFilterChange}
            disabled={loading}
          />
        </div>
      </div>
      <XtList
        actions={AccountActions}
        onRowClick={handleRowClick}
        onAction={handleAction}
        isMobile={isMobile}
        getItemActions={getAccountActions}
        pagination={pagination}
        loading={loading}
        data={data}
        columns={crmAccountColumns}
        sortOptions={sortOptions}
        onColumnHeaderClick={sort}
        loadMessage={LoaderMessage.Accounts}
      />
    </div>
  )
}
