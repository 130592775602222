import { Observable } from 'rxjs'
import { Overwrite } from 'utility-types'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { NewComment } from 'comments/comments.types'
import { IAttachedDocumentWithFile, ICRMAccountDocument } from 'documents/documents.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import {
  IOpportunity,
  IOpportunityExtended,
  IOpportunityStage,
  IOpportunityType,
  IOpportunityUpdateEvent,
  OpportunityCreatePayload,
  OpportunityUpdatePayload,
  IOpportunityStageWithId,
  IOpportunityTypeWithId,
  IOpportunitySourceWithId,
  IOpportunitySource,
  OpportunityStageOption,
  IOpportunityCreate,
} from '../opportunities.types'
import { IOpportunitiesTableItem } from '../opportunities-list/opportunities-list.constants'

export interface IOpportunitiesServices {
  OpportunitiesService: IOpportunitiesService
  OpportunityStagesService: IOpportunityStagesService
  OpportunityTypesService: IOpportunityTypesService
  OpportunitySourcesService: IOpportunitySourcesService
  OpportunitiesUtilsService: IOpportunitiesUtilsService
}

export interface IOpportunitiesService {
  getAll(
    pagination: IPaginationParams,
    filters?: Partial<IOpportunitiesFilters>,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IOpportunity>>
  get(opportunityNumber: string): Promise<IOpportunity>
  getOpportunityWithAccount(opportunityNumber: string): Promise<IOpportunityExtended>
  update(data: OpportunityUpdatePayload): Promise<string>
  create(data: OpportunityCreatePayload, comments: NewComment[], documents: IAttachedDocumentWithFile[]): Promise<IOpportunityCreate>
  delete(number: string): Promise<string>
  deactivate(number: string): Promise<string>
  activate(number: string): Promise<string>
  updateStage(opportunityId: string, stage: string): Promise<void>
  opportunityUpdate$: Observable<IOpportunityUpdateEvent>
  getCRMAccount(number: string): Promise<ICRMAccountDocument>
}

export interface IOpportunitiesUtilsService {
  fetchOpportunities(
    filters: OpportunitiesFiltersPanel,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IOpportunitiesTableItem>>
  fetchOpportunityStages(
    _: unknown,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IOpportunityStageWithId>>
  fetchOpportunityTypes(
    _: unknown,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IOpportunityTypeWithId>>
  fetchOpportunitySources(
    _: unknown,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IOpportunitySourceWithId>>
  loadOpportunityStageOptions(page: number, limit: number, searchFilter: string | null): Promise<IPaginationData<OpportunityStageOption>>
}

export interface IOpportunityStagesService {
  getAll: (params?: IPaginationParams, sortOptions?: ISortOption[]) => Promise<IPaginationData<IOpportunityStage>>
  get(name: string): Promise<IOpportunityStage>
  create(data: Partial<IOpportunityStage>): Promise<string>
  update(data: Partial<IOpportunityStage>): Promise<string>
  rename(rename: string, name: string): Promise<string>
  delete(name: string): Promise<string>
}

export interface IOpportunityTypesService {
  getAll: (params: IPaginationParams, sortOptions?: ISortOption[]) => Promise<IPaginationData<IOpportunityType>>
  get(name: string): Promise<IOpportunityType>
  create(data: IOpportunityType): Promise<string>
  update(data: IOpportunityType): Promise<string>
  rename(rename: string, name: string): Promise<string>
  delete(name: string): Promise<string>
}

export interface IOpportunitySourcesService {
  getAll: (params: IPaginationParams, sortOptions?: ISortOption[]) => Promise<IPaginationData<IOpportunitySource>>
  get(name: string): Promise<IOpportunitySource>
  create(data: IOpportunitySource): Promise<string>
  update(data: IOpportunitySource): Promise<string>
  rename(rename: string, name: string): Promise<string>
  delete(name: string): Promise<string>
}

export enum OpportunitiesFilter {
  ShowInactive = 'showinactive',
  User = 'username',
  AssignedTo = 'assigned_username',
  TargetDateOnOrAfter = 'startDate',
  TargetDateOnOrBefore = 'endDate',
  Project = 'project',
  NamePattern = 'name_pattern',
  Account = 'account',
  Type = 'type',
  TypePattern = 'type_pattern',
  Source = 'source',
  SourcePattern = 'source_pattern',
  Stage = 'stage',
  StagePattern = 'stage_pattern',
  CreateDateOnOrAfter = 'startCreateDate',
  CreateDateOnOrBefore = 'endCreateDate',
}

export interface IOpportunitiesFilters {
  [OpportunitiesFilter.ShowInactive]?: boolean
  [OpportunitiesFilter.User]?: string
  [OpportunitiesFilter.AssignedTo]?: string
  [OpportunitiesFilter.TargetDateOnOrAfter]?: string
  [OpportunitiesFilter.TargetDateOnOrBefore]?: string
  [OpportunitiesFilter.Project]?: string
  [OpportunitiesFilter.NamePattern]?: string
  [OpportunitiesFilter.Account]?: string
  [OpportunitiesFilter.Type]?: string
  [OpportunitiesFilter.TypePattern]?: string
  [OpportunitiesFilter.Source]?: string
  [OpportunitiesFilter.SourcePattern]?: string
  [OpportunitiesFilter.Stage]?: string
  [OpportunitiesFilter.StagePattern]?: string
  [OpportunitiesFilter.CreateDateOnOrAfter]?: string
  [OpportunitiesFilter.CreateDateOnOrBefore]?: string
}

export type OpportunitiesFiltersPanel = Overwrite<
  IOpportunitiesFilters,
  {
    [OpportunitiesFilter.ShowInactive]: boolean
    [OpportunitiesFilter.User]: IXtAutocompleteOption | null
    [OpportunitiesFilter.AssignedTo]: IXtAutocompleteOption | null
    [OpportunitiesFilter.TargetDateOnOrAfter]: Date | null
    [OpportunitiesFilter.TargetDateOnOrBefore]: Date | null
    [OpportunitiesFilter.Project]: IXtAutocompleteOption | null
    [OpportunitiesFilter.NamePattern]: string
    [OpportunitiesFilter.Account]: IXtAutocompleteOption | null
    [OpportunitiesFilter.Type]: IXtAutocompleteOption | null
    [OpportunitiesFilter.TypePattern]: string
    [OpportunitiesFilter.Source]: IXtAutocompleteOption | null
    [OpportunitiesFilter.SourcePattern]?: string
    [OpportunitiesFilter.Stage]: IXtAutocompleteOption | null
    [OpportunitiesFilter.StagePattern]: string
    [OpportunitiesFilter.CreateDateOnOrAfter]: Date | null
    [OpportunitiesFilter.CreateDateOnOrBefore]: Date | null
  }
>
