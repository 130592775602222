import { ItemCostsByClassCodeFilter } from 'products/items/items.types'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { numberConverter } from 'common/utils/utils'
import { booleanColumnConverter } from 'common/utils/table.utils'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { ClassCodeFilter, ClassCodeFilters } from './by-class-code-list.types'
import { IItemCostsByClassCodeWithId } from '../item-costs.types'

export enum ClassCodeAction {
  ViewItemCostingSummary = 'View Item Costing Summary',
}

export const byClassCodeActions: IXtTableCellAction[] = [
  {
    name: ClassCodeAction.ViewItemCostingSummary,
    label: ClassCodeAction.ViewItemCostingSummary,
    icon: SvgIconIds.VISIBILITY,
  },
]

export const classCodeColumns: IXtTableColumn<IItemCostsByClassCodeWithId>[] = [
  {
    id: 'item_number',
    field: 'item_number',
    headerName: 'Item Number',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'active',
    field: 'active',
    headerName: 'Active',
    flex: '1 0 100px',
    width: 100,
    converter: booleanColumnConverter,
  },
  {
    id: 'item_description',
    field: 'item_description',
    headerName: 'Description',
    flex: '1 0 400px',
    width: 400,
  },

  {
    id: 'uom',
    field: 'uom',
    headerName: 'UOM',
    flex: '1 0 110px',
    width: 110,
  },
  {
    id: 'std_cost',
    field: 'std_cost',
    headerName: 'Std. Cost',
    flex: '1 0 130px',
    width: 130,
    converter: (value) => numberConverter(value, 4),
  },

  {
    id: 'act_cost',
    field: 'act_cost',
    headerName: 'Act. Cost',
    flex: '1 0 130px',
    width: 130,
    converter: (value) => numberConverter(value, 4),
  },
  {
    id: 'percent_variance',
    field: 'percent_variance',
    headerName: 'Var %',
    flex: '1 0 120px',
    width: 120,
    converter: (value) => numberConverter(value, 4),
  },
  {
    id: 'item_type',
    field: 'item_type',
    headerName: 'Item Type',
    flex: '1 0 150px',
    width: 150,
  },
]

const defaultItemTypeOptions = [
  {
    id: ItemCostsByClassCodeFilter.ShowPurchased,
    label: 'Purchased',
  },
  {
    id: ItemCostsByClassCodeFilter.ShowMfg,
    label: 'Manufactured',
  },
  {
    id: ItemCostsByClassCodeFilter.ShowOS,
    label: 'Outside Service',
  },
]

export const defaultFilterState: ClassCodeFilters = {
  [ItemCostsByClassCodeFilter.ClassCode]: '',
  [ItemCostsByClassCodeFilter.ClassCodePattern]: '',
  [ItemCostsByClassCodeFilter.OnlyShowZeroStdCosts]: false,
  [ItemCostsByClassCodeFilter.OnlyShowDiffCosts]: false,
  [ItemCostsByClassCodeFilter.ShowInactive]: false,
  [ItemCostsByClassCodeFilter.OnlyShowZeroLastCosts]: false,
  [ItemCostsByClassCodeFilter.ShowPurchased]: false,
  [ClassCodeFilter.ItemTypes]: defaultItemTypeOptions,
}

export const itemTypeOptions = [
  ...defaultItemTypeOptions,
  { id: ItemCostsByClassCodeFilter.ShowCosting, label: 'Costing' },
  {
    id: ItemCostsByClassCodeFilter.ShowPlanning,
    label: 'Planning',
  },
  { id: ItemCostsByClassCodeFilter.ShowOther, label: 'All Others' },
]

export const byClassCodePageTitle = 'xTuple - Item Costs by Class Code'
