import React, { useState , FC, useCallback } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router'
import { cls } from 'common/utils/utils'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { XtMode } from 'common/common.types'
import { LoaderMessage, confirmationMessages, xsMq } from 'common/constants'
import { useTable } from 'common/hooks/useTable'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { XtList } from 'components/list/list'
import { UserPermission } from 'auth/auth.types'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { BomListAction, IBomTableItem } from './bom-list.types'
import { bomListActionsEditMode, bomListActionsViewMode, bomListColumns, bomListPageTitle, bomListPageConstant } from './bom-list.constants'
import * as styles from './bom-list.module.scss'
import { useProductsModule } from '../../products-module-hook'
import { BomListFilters, BomListLabel } from '../bom.types'
import { BomCopyDialog } from '../bom-copy-dialog/bom-copy-dialog'
import { IBomCopyDialogState } from '../bom-copy-dialog/bom-copy-dialog.types'
import { defaultCopyDialogState } from '../bom-copy-dialog/bom-copy-dialog.constants'

export const BomList: FC = () => {
  const { BomService, BomUtilsService } = useProductsModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { PermissionsService } = useAuthModule()

  const history = useHistory()
  const { path } = useRouteMatch()
  const canEditBoms = PermissionsService.hasPermission(UserPermission.MaintainBOMs)
  const { itemId: itemIdToDelete, open: confirmationDialogOpen, openDialog, closeDialog } = useConfirmationDialog<string>()
  const {
    state: { loading, data, filters, sortOptions },
    setLoading,
    refresh,
    filter,
    pagination,
    sort,
  } = useTable({}, BomUtilsService.fetchBoms)

  useDocumentTitle(bomListPageTitle)

  const [copyDialogState, setCopyDialogState] = useState<IBomCopyDialogState>(defaultCopyDialogState)
  const handleRowClick = useCallback<(item: IBomTableItem) => void>(
    ({ item_number }) => history.push(`${path}/${item_number}/${canEditBoms ? XtMode.Edit : XtMode.View}`),
    [history, path, canEditBoms]
  )

  const handleAction = useCallback<(item: IBomTableItem, action: BomListAction) => void>(
    (item, action) => {
      if (action === BomListAction.Delete && !loading) {
        openDialog(item?.item_number)
        return
      }
      if (action === BomListAction.Edit) {
        history.push(`${path}/${item?.item_number}/${XtMode.Edit}`)
      }
      if (action === BomListAction.View) {
        history.push(`${path}/${item?.item_number}/${XtMode.View}`)
      }
      if (action === BomListAction.Print) {
        // TODO handle print
      }
      if (action === BomListAction.Copy) {
        if (item?.item_number) {
          setCopyDialogState({
            itemNumber: item?.item_number,
            itemInventoryUOM: item?.item_inventory_uom,
            itemDescription: item?.item_description,
            item: item,
            open: true,
          })
        }
      }
    },
    [history, openDialog, path, loading]
  )

  const isMobile = useMediaQuery(xsMq)

  const handleDeletion = useCallback<VoidFunction>(async () => {
    closeDialog()
    if (itemIdToDelete) {
      try {
        setLoading(true)
        const message = await BomService.delete(itemIdToDelete)
        await refresh()
        ToastService.showSuccess(message)
        setLoading(false)
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    }
  }, [BomService, ErrorHandler, ToastService, closeDialog, itemIdToDelete, refresh, setLoading])

  const handleShowExpiredFilterChange = (checked: boolean): void => {
    void filter({ ...filters, [BomListFilters.ShowInactive]: checked })
  }

  const handleShowFutureFilterChange = (checked: boolean): void => {
    void filter({ ...filters, [BomListFilters.ShowComponentItems]: checked })
  }

  const closeCopyBomDialog = () => setCopyDialogState((prev) => ({ ...prev, open: false }))

  return (
    <div>
      <XtConfirmationDialog
        open={confirmationDialogOpen}
        message={confirmationMessages.deleted}
        title="Delete BOM"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeDialog}
      />
      <XtDialog
        animation={XtDialogAnimation.FadeAnimation}
        fullScreen={false}
        open={copyDialogState.open}
        className="xt-dialog-details-content"
      >
        <BomCopyDialog bomItem={copyDialogState} onClose={closeCopyBomDialog} refresh={refresh} />
      </XtDialog>
      <div className={cls('xt-content', styles.listContent)}>
        <div className={styles.listControls}>
          <div className={cls('xt-section-border', styles.listHeader)}>
            <h1 className="xt-page-title">{bomListPageConstant.bom}</h1>
            <XtResponsiveButton
              disabled={!canEditBoms}
              label={bomListPageConstant.new_bom}
              icon={SvgIconIds.ADD_CIRCLE}
              onClick={() => history.push(`${path}/${XtMode.New}`)}
            />
          </div>
          <div className={styles.filtersContainer}>
            <XtCheckbox
              className={styles.filterListCheckbox}
              label={BomListLabel.ShowInactive}
              value={Boolean(filters[BomListFilters.ShowInactive])}
              onChange={handleShowExpiredFilterChange}
              disabled={loading}
            />
            <XtCheckbox
              className={styles.filterListCheckbox}
              label={BomListLabel.ShowComponentItems}
              value={Boolean(filters[BomListFilters.ShowComponentItems])}
              onChange={handleShowFutureFilterChange}
              disabled={loading}
            />
          </div>
        </div>
        <XtList
          actions={canEditBoms ? bomListActionsEditMode : bomListActionsViewMode}
          onRowClick={handleRowClick}
          onAction={handleAction}
          isMobile={isMobile}
          pagination={pagination}
          loading={loading}
          data={data}
          columns={bomListColumns}
          sortOptions={sortOptions}
          onColumnHeaderClick={sort}
          loadMessage={LoaderMessage.BOMs}
        />
      </div>
    </div>
  )
}
