import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from 'common/common.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { CommentsCreationError, NewComment } from 'comments/comments.types'
import { convertNewComments } from 'comments/comments.utils'
import { DocumentType } from 'documents/documents.types'
import { ICommentsService } from 'comments/comments.service'
import { IHttpClient } from 'core/services/http-client'
import {
  ICost,
  IRunningAvailability,
  IItemSite,
  RunningAvailabilityFilters,
  IItemSitePayload,
  TransactionType,
  ItemSiteDistributionFilters,
  ItemSiteDistributionResponse,
  ControlMethodServerOption,
  ILotSerialSequence,
  ILotSerialSequencesFilters,
} from './items.types'

export interface IItemSitesFilters {
  showInactive?: boolean
  showDetails?: boolean
  itemNumber?: string
  po_supplied_at_site?: boolean
  sold_from_site?: boolean
  control_method?: ControlMethodServerOption
}

export interface IItemSitesService {
  getItemSites(
    itemNumber: string,
    pagination?: IPaginationParams,
    filters?: IItemSitesFilters,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemSite>>
  getItemSite(itemNumber: string, site: string): Promise<IItemSite>
  createItemSite(site: IItemSitePayload, comments: NewComment[]): Promise<string>
  updateItemSite(site: IItemSitePayload): Promise<string>
  deleteItemSite(item_number: string, site: string): Promise<string>
  getItemCosts(itemNumber: string): Promise<IResponse<ICost>>
  getAllRunningAvailability: (
    params: IPaginationParams,
    filters: RunningAvailabilityFilters,
    sortOptions?: ISortOption[]
  ) => Promise<IPaginationData<IRunningAvailability>>
  getItemSiteDistributions<IncludeLotSerial extends boolean>(
    itemNumber: string,
    site: string,
    transactionType: TransactionType,
    includeLotSerial: IncludeLotSerial
  ): Promise<ItemSiteDistributionResponse<IncludeLotSerial>[]>
  getLotSerialSequences(pagination?: IPaginationParams, filters?: ILotSerialSequencesFilters): Promise<ILotSerialSequence[]>
}

export class ItemSitesService implements IItemSitesService {
  constructor(private readonly apiClient: IHttpClient, private readonly commentsService: ICommentsService) {}

  public async getItemSites(
    itemNumber: string,
    pagination?: IPaginationParams,
    filters?: IItemSitesFilters,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemSite>> {
    const params = prepareRequestParams(pagination, filters, sortOptions)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IItemSite>>(`/item/${itemNumber}/site`, { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async createItemSite(site: IItemSitePayload, comments: NewComment[]): Promise<string> {
    const body = { data: site }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post(`/item/site/create`, body)
    await this.createCommentsForItemSite(site.item_number, site.site, comments)
    return message
  }

  public async updateItemSite(site: IItemSitePayload): Promise<string> {
    const body = { data: site }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post(`/item/site/update`, body)
    return message
  }

  public async deleteItemSite(item_number: string, site: string): Promise<string> {
    const body = { data: { item_number, site } }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post(`/item/site/delete`, body)
    return message
  }

  public async getItemCosts(itemNumber: string): Promise<IResponse<ICost>> {
    const {
      data: { data, status },
    } = await this.apiClient.get<IResponse<ICost>>(`/item/${itemNumber}/cost`)

    return {
      data,
      status,
    }
  }

  public async getItemSite(itemNumber: string, site: string): Promise<IItemSite> {
    const params = prepareRequestParams(undefined, { showInactive: true }, undefined)
    const {
      data: { data },
    } = await this.apiClient.get<IResponse<IItemSite>>(`/item/${itemNumber}/site/${site}`, { params })
    return data
  }

  private async createCommentsForItemSite(itemNumber: string, site: string, comments: NewComment[]): Promise<void> {
    if (!comments.length) {
      return
    }
    try {
      const commentsPayload = convertNewComments(comments, DocumentType.ItemSite, `${itemNumber}-${site}`)
      await this.commentsService.createAll(commentsPayload)
    } catch (e) {
      throw new CommentsCreationError()
    }
  }

  public async getAllRunningAvailability(
    paginationParams: IPaginationParams,
    { itemNumber, site, ...otherFilters }: RunningAvailabilityFilters,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IRunningAvailability>> {
    const params = prepareRequestParams(paginationParams, otherFilters, sortOptions)

    if (!itemNumber || !site) {
      return {
        data: [],
        total: 0,
      }
    }

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IRunningAvailability>>(`/item/${itemNumber}/${site}/runningavailability`, { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getItemSiteDistributions<IncludeLotSerial extends boolean>(
    itemNumber: string,
    site: string,
    transactionType: TransactionType,
    includeLotSerial: IncludeLotSerial
  ): Promise<ItemSiteDistributionResponse<IncludeLotSerial>[]> {
    const params = this.defineItemSiteDistributionFilters(transactionType, includeLotSerial)

    const {
      data: { data },
    } = await this.apiClient.get<IResponse<ItemSiteDistributionResponse<IncludeLotSerial>[]>>(`/item/${itemNumber}/${site}/distribution`, {
      params,
    })

    return data
  }

  public async getLotSerialSequences(pagination?: IPaginationParams, filters?: ILotSerialSequencesFilters): Promise<ILotSerialSequence[]> {
    const params = prepareRequestParams(pagination, filters)

    const {
      data: { data },
    } = await this.apiClient.get<IResponse<ILotSerialSequence[]>>(`/lotserial/sequence`, {
      params,
    })
    return data
  }

  private defineItemSiteDistributionFilters(
    transactionType: TransactionType,
    includeLotSerial: boolean
  ): ItemSiteDistributionFilters<typeof includeLotSerial> {
    if (includeLotSerial) {
      return { transaction_type: transactionType, IncludeLotSerial: true }
    }
    return { transaction_type: transactionType, NoIncludeLotSerial: true }
  }
}
