import { IHttpClient } from 'core/services/http-client'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from 'common/common.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { BomCreateInput, BomUpdateInput, IBom, IBomListFilters } from './bom.types'
import { IBomCopyPayload } from './bom-copy-dialog/bom-copy-dialog.types'

export interface IBomService {
  get: (itemNumber: string, showExpired: boolean, showFuture: boolean) => Promise<IBom>
  getAll: (
    filters: IBomListFilters,
    pagination: IPaginationParams,
    sortOptions?: ISortOption[],
    showDetail?: boolean
  ) => Promise<IPaginationData<IBom>>
  create(data: BomCreateInput): Promise<string>
  update(item: BomUpdateInput): Promise<string>
  delete(itemNumber: string): Promise<string>
  copy(payload: IBomCopyPayload): Promise<string>
}

export class BomService implements IBomService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getAll(
    filters: IBomListFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[],
    showDetail: boolean = true
  ): Promise<IPaginationData<IBom>> {
    const params = prepareRequestParams(paginationParams, { ...filters, showDetail }, sortOptions)

    const response = await this.apiClient.get<IPaginationResponse<IBom>>('/item/bom', { params })
    const {
      data: { data, status },
    } = response
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(itemNumber: string, showExpired: boolean, showFuture: boolean): Promise<IBom> {
    const params: Record<string, true | number> = {}
    if (showExpired) {
      params.showExpired = true
    }
    if (showFuture) {
      params.showFuture = true
    }
    try {
      const response = await this.apiClient.get<IResponse<IBom>>(`/item/${itemNumber}/bom`, { params })
      if (!response.data.data || !Object.keys(response.data.data).length) {
        throw new Error(`BOM not found. Item number: ${itemNumber}.`)
      }
      return response.data.data
    } catch (error) {
      if (error?.response?.data?.status?.message) {
        throw new Error(error.response.data.status.message)
      } else throw new Error(error)
    }
  }

  public async create(data: BomCreateInput): Promise<string> {
    const body = { data }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post('/item/bom/create', body)
    return message
  }

  public async update(data: BomUpdateInput): Promise<string> {
    const body = { data }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post('/item/bom/update', body)
    return message
  }

  public async delete(itemNumber: string): Promise<string> {
    const body = { data: { item_number: itemNumber } }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post('/item/bom/delete', body)
    return message
  }

  public async copy(data: IBomCopyPayload): Promise<string> {
    const body = { data }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post('/item/bom/copy', body)
    return message
  }
}
