import { IModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { AuthModule } from 'auth/auth.module'
import { CoreModule } from 'core/core.module'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { IAuthService } from 'auth/auth.types'
import { authServiceToken } from 'auth/auth.constants'
import { usersServiceToken, usersUtilsServiceToken } from './users.constants'
import * as UsersModuleExports from './users.exports'
import { IUsersService } from './users.service'
import { IUsersUtilsService } from './users-utils.service'

export type UsersModuleDefinition = typeof UsersModuleExports

export interface IUsersModuleServices {
  UsersService: IUsersService
  UsersUtilsService: IUsersUtilsService
}

export const UsersModule: IModule<UsersModuleDefinition> = {
  name: 'UsersModule',
  resolver: () => UsersModuleExports,
  initializer: ({ UsersService, UsersUtilsService }) => {
    Injector.register<IUsersService>(
      usersServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        const authService = injector.resolve<IAuthService>(authServiceToken)

        return new UsersService(httpClient, authService)
      },
      Scope.Singleton
    )

    Injector.register<IUsersUtilsService>(
      usersUtilsServiceToken,
      (injector) => new UsersUtilsService(injector.resolve<IUsersService>(usersServiceToken)),
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, AuthModule],
}
