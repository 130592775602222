import React, { FC, useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { IShipping, ShippingLineTableItem } from 'shipping/shipping.types'
import { XtList } from 'components/list/list'
import { XtTableCellButton } from 'components/table/table-cell-actions/table-cell-button/table-cell-button'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { convertDate } from 'common/utils/date.utils'
import { numberConverter } from 'common/utils/utils'
import { booleanColumnConverter } from 'common/utils/table.utils'
import { useCoreModule } from 'core/core-module-hook'
import { useShippingModule } from 'shipping/shipping-module-hook'
import { xsMq } from 'common/constants'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { useSharedModule } from 'shared/shared-module-hook'
import { renderPhoneCell } from 'components/controls/phone/phone-link/phone-link.utils'
import { useSalesSharedModule } from 'sales-shared/sales-shared-module-hook'
import { ISalesOrder } from 'sales-shared/sales-orders.types'
import { useSalesOrdersModule } from 'sales-orders/sales-orders-module-hook'
import { XtMode } from 'common/common.types'
import { OrderType } from 'core/core.types'
import { useAuthModule } from 'auth/auth-module-hook'
import { UserPermission } from 'auth/auth.types'
import { shippingLinesActions, shippingLinesColumns } from './shipping-extended-template.constants'
import * as styles from './shipping-extended-template.module.scss'
import {
  IssueToShippingDialogState,
  ShippingInformationKeys,
  ShippingLinesAction,
  ShippingSalesLineItemDialogState,
} from './shipping-extended-template.types'
import {
  convertShippingLinesToReturnInstance,
  defineShippingLineItemDialogState,
  getLineItemsAction,
  normalizeShippingLine,
} from './shipping-extended-template.utils'
import { ShippingInformationDialog } from '../shipping-information-dialog/shipping-information-dialog'

interface IShippingExtendedTemplate {
  shipping: IShipping
  refreshShipping(): Promise<void>
}

export const ShippingExtendedTemplate: FC<IShippingExtendedTemplate> = ({ shipping, refreshShipping }) => {
  const isMobile = useMediaQuery(xsMq)

  const { ShippingService } = useShippingModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { useDialog } = useSharedModule()
  const { SalesOrderDetails } = useSalesOrdersModule()
  const { LineItemDetails, SalesOrdersService } = useSalesSharedModule()
  const { PrintShippingForm, IssueToShipping, ShipOrder } = useShippingModule()
  const { PermissionsService } = useAuthModule()

  const [
    canIssueStock,
    canReturnStock,
    canEnterShippingInformation,
    canPrintBillsOfLading,
    canShipOrders,
  ] = PermissionsService.hasPermissions([
    UserPermission.IssueStockToShipping,
    UserPermission.ReturnStockFromShipping,
    UserPermission.EnterShippingInformation,
    UserPermission.PrintBillsOfLading,
    UserPermission.ShipOrders,
  ])
  const canViewSalesOrder = PermissionsService.hasSomePermission([UserPermission.ViewSalesOrders, UserPermission.MaintainSalesOrders])

  const [salesOrder, setSalesOrder] = useState<ISalesOrder | null>(null)

  const {
    open: openShippingInformation,
    openDialog: openShippingInformationDialog,
    data: shippingInformationData,
    closeDialog: closeShippingInformationDialog,
  } = useDialog<IShipping | null>(null)

  const {
    open: openSalesLineItem,
    openDialog: openSalesLineItemDialog,
    closeDialog: closeSalesLineItemDialog,
    data: salesLineItemData,
  } = useDialog<ShippingSalesLineItemDialogState | null>(null)

  const {
    open: openIssueToShipping,
    openDialog: openIssueToShippingDialog,
    closeDialog: closeIssueToShippingDialog,
    data: issueToShippingData,
  } = useDialog<IssueToShippingDialogState | null>(null)

  const { open: openShipOrder, closeDialog: closeShipOrderDialog, openDialog: openShipOrderDialog, data: shipOrderData } = useDialog<
    string | null
  >(null)
  const { open: openSalesOrder, openDialog: openSalesOrderDialog, closeDialog: closeSalesOrderDialog } = useDialog()
  const { open: openPrintShippingForm, openDialog: openPrintShippingFormDialog, closeDialog: closePrintShippingFormDialog } = useDialog()

  const handleOpenSalesLineItemDialog = useCallback(
    async (lineNumber: string): Promise<void> => {
      if (!salesOrder) {
        return
      }
      const orderItem = await SalesOrdersService.getOrderItem(shipping.order_number, lineNumber)
      openSalesLineItemDialog(defineShippingLineItemDialogState(salesOrder, orderItem))
    },
    [SalesOrdersService, openSalesLineItemDialog, salesOrder, shipping.order_number]
  )

  const handleReturnAll = useCallback(
    async (item: IShipping): Promise<void> => {
      try {
        const message = await ShippingService.returnLines(convertShippingLinesToReturnInstance(item))
        ToastService.showSuccess(message)
        await refreshShipping()
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    },
    [ErrorHandler, ShippingService, ToastService, refreshShipping]
  )

  const handleShippingInformationDialogOpen = async (shipmentNumber: string): Promise<void> => {
    try {
      const shippingData = await ShippingService.get(shipmentNumber)
      openShippingInformationDialog(shippingData)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const onAdditionalButtonsClick = (key: ShippingInformationKeys) => () => {
    switch (key) {
      case ShippingInformationKeys.ShippingInformation:
        return handleShippingInformationDialogOpen(shipping.shipment_number)
      case ShippingInformationKeys.ReturnAll:
        return handleReturnAll(shipping)
      case ShippingInformationKeys.ViewOrder:
        return openSalesOrderDialog()
      case ShippingInformationKeys.PrintShippingForm:
        return openPrintShippingFormDialog()
      case ShippingInformationKeys.ShipOrder:
        return openShipOrderDialog(shipping.shipment_number)
    }
  }

  const handleTableReturnAll = useCallback(
    async (item: IShipping, shipmentLine: ShippingLineTableItem): Promise<void> => {
      try {
        const currentLine = item.shipment_lines.find((line) => line.line_number === shipmentLine.line_number)
        if (!currentLine) {
          return
        }
        const itemWithReturnedLine = { ...item, shipment_lines: [currentLine] }
        const message = await ShippingService.returnLines(convertShippingLinesToReturnInstance(itemWithReturnedLine))
        ToastService.showSuccess(message)
        await refreshShipping()
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    },
    [ErrorHandler, ShippingService, ToastService, refreshShipping]
  )

  const onTableAction = useCallback(
    (item: IShipping, shipmentLine: ShippingLineTableItem, action: ShippingLinesAction): void => {
      switch (action) {
        case ShippingLinesAction.IssueAdditionalStock:
          return openIssueToShippingDialog({ site: shipmentLine.site, orderNumber: item?.order_number ?? '' })
        case ShippingLinesAction.ReturnAll:
          void handleTableReturnAll(item, shipmentLine)
          return
        case ShippingLinesAction.ViewOrder:
          void handleOpenSalesLineItemDialog(String(shipmentLine.line_number))
          return
      }
    },
    [handleOpenSalesLineItemDialog, handleTableReturnAll, openIssueToShippingDialog]
  )
  useEffect(() => {
    async function init(): Promise<void> {
      if (shipping.order_type !== OrderType.SalesOrder) {
        return
      }

      try {
        const salesOrderData = await SalesOrdersService.get(shipping.order_number)
        setSalesOrder(salesOrderData)
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }

    void init()
  }, [ErrorHandler, SalesOrdersService, ShippingService, shipping.order_number, shipping.order_type])

  return (
    <div className={styles.extendedTemplateContent}>
      <XtDialog className="xt-modal-details-content" open={openShipOrder} fullScreen={false} animation={XtDialogAnimation.FadeAnimation}>
        <ShipOrder onClose={closeShipOrderDialog} orderNumber={shipOrderData ?? undefined} onSubmit={refreshShipping} />
      </XtDialog>
      <XtDialog
        className="xt-modal-details-content"
        open={openIssueToShipping}
        fullScreen={false}
        animation={XtDialogAnimation.SlideAnimation}
      >
        {issueToShippingData && (
          <IssueToShipping
            onClose={closeIssueToShippingDialog}
            orderNumber={issueToShippingData.orderNumber}
            site={issueToShippingData.site}
            onShip={refreshShipping}
          />
        )}
      </XtDialog>
      <XtDialog
        className="xt-dialog-details-content"
        open={openShippingInformation}
        fullScreen={false}
        animation={XtDialogAnimation.FadeAnimation}
      >
        {shippingInformationData && (
          <ShippingInformationDialog
            onClose={closeShippingInformationDialog}
            refreshShipping={refreshShipping}
            shipping={shippingInformationData}
          />
        )}
      </XtDialog>
      <XtDialog
        className="xt-modal-details-content"
        open={openSalesLineItem}
        fullScreen={false}
        animation={XtDialogAnimation.SlideAnimation}
      >
        {salesLineItemData && (
          <LineItemDetails
            mode={XtMode.View}
            onClose={closeSalesLineItemDialog}
            site={salesLineItemData.site}
            customerNumber={salesLineItemData.customerNumber}
            currency={salesLineItemData.currency}
            lineItemInput={salesLineItemData.lineItemInput}
            pricesData={salesLineItemData.pricesData}
            orderNumber={salesOrder?.order_number}
          />
        )}
      </XtDialog>
      <XtDialog className="xt-modal-details-content" open={openSalesOrder} fullScreen={false} animation={XtDialogAnimation.SlideAnimation}>
        <SalesOrderDetails
          salesOrderNumber={salesOrder?.order_number ?? null}
          account={null}
          onCancel={closeSalesOrderDialog}
          opportunityNumber={null}
          mode={XtMode.View}
        />
      </XtDialog>
      <XtDialog
        className="xt-dialog-details-content"
        open={openPrintShippingForm}
        fullScreen={false}
        animation={XtDialogAnimation.FadeAnimation}
      >
        <PrintShippingForm
          onClose={closePrintShippingFormDialog}
          shipmentNumber={shipping.shipment_number}
          shippingForm={shipping.shipping_form}
        />
      </XtDialog>
      <XtList
        columns={shippingLinesColumns}
        data={normalizeShippingLine(shipping.shipment_lines)}
        isMobile={isMobile}
        getItemActions={(_, actions) =>
          getLineItemsAction(actions, Boolean(salesOrder), shipping.shipped, canIssueStock, canReturnStock, canViewSalesOrder)
        }
        actions={shippingLinesActions}
        onAction={(item, action) => onTableAction(shipping, item, action as ShippingLinesAction)}
      />
      <div className={styles.shipmentInformationMainSection}>
        <h3>Shipment Information:</h3>
        <div className={styles.shipmentInformationControlSection}>
          <XtTableCellButton
            onClick={onAdditionalButtonsClick(ShippingInformationKeys.ShippingInformation)}
            title="Shipping Information"
            icon={SvgIconIds.CREATE}
            hidden={!canEnterShippingInformation}
          />
          <XtTableCellButton
            onClick={onAdditionalButtonsClick(ShippingInformationKeys.ViewOrder)}
            title="View Order"
            icon={SvgIconIds.VISIBILITY}
            hidden={shipping.order_type !== OrderType.SalesOrder || !canViewSalesOrder}
          />
          <XtTableCellButton
            onClick={onAdditionalButtonsClick(ShippingInformationKeys.PrintShippingForm)}
            title="Print Shipping Form"
            icon={SvgIconIds.PRINT}
            hidden={!canPrintBillsOfLading}
          />
          <XtTableCellButton
            onClick={onAdditionalButtonsClick(ShippingInformationKeys.ShipOrder)}
            title="Ship Order"
            icon={SvgIconIds.SHIPPING}
            hidden={!canShipOrders}
          />
          <XtTableCellButton
            onClick={onAdditionalButtonsClick(ShippingInformationKeys.ReturnAll)}
            title="Return All Stock Issued to Order"
            icon={SvgIconIds.EXPIRE}
            hidden={!canReturnStock}
          />
        </div>
        <div className={styles.shipmentInformationText}>
          <div className={styles.shipmentTextColumn}>
            <span>
              Cust. Name:
              {shipping.customer_info.customer_name}
            </span>
            <span>
              Cust. Phone:
              {renderPhoneCell({ number: shipping.customer_info.customer_phone ?? '', allowsms: shipping.customer_info.allow_sms })}
            </span>
            <span>P/O Number: {shipping.customer_info.po_number}</span>
            <span>Order Date: {convertDate(shipping.customer_info.order_date)}</span>
            <span>Ship Date: {convertDate(shipping.ship_date)}</span>
          </div>
          <div className={styles.shipmentTextColumn}>
            <span>Ship Via: {shipping.ship_via}</span>
            <span>Shipping Form: {shipping.shipping_form}</span>
            <span>Freight: {`${numberConverter(shipping.freight)} ${shipping.freight_currency}`}</span>
            <span>Shipped: {booleanColumnConverter(shipping.shipped)}</span>
            <span>Notes: {shipping.notes}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
