// extracted by mini-css-extract-plugin
export var opportunitiesBoard = "opportunities-board_opportunitiesBoard__LeVts";
export var opportunitiesBoardHeader = "opportunities-board_opportunitiesBoardHeader__+F8I1";
export var opportunitiesBoardAddNewButton = "opportunities-board_opportunitiesBoardAddNewButton__P9A+O";
export var opportunitiesBoardCheckboxesFilter = "opportunities-board_opportunitiesBoardCheckboxesFilter__p4ncL";
export var taskListCheckbox = "opportunities-board_taskListCheckbox__Gt3zv";
export var opportunitiesBoardCheckboxFilter = "opportunities-board_opportunitiesBoardCheckboxFilter__Kx3b4";
export var boardHeader = "opportunities-board_boardHeader__Jvx8D";
export var filtersContainer = "opportunities-board_filtersContainer__MR7tI";
export var boardControls = "opportunities-board_boardControls__mJukc";
export var opportunitiesBoardLinksGroup = "opportunities-board_opportunitiesBoardLinksGroup__5CA0E";
export var opportunitiesBoardLink = "opportunities-board_opportunitiesBoardLink__ngmWR";
export var opportunitiesBoardLinkActive = "opportunities-board_opportunitiesBoardLinkActive__4QHX+";
export var opportunitiesBoardLinkIcon = "opportunities-board_opportunitiesBoardLinkIcon__NT-Mh";