import { deepEqual } from 'fast-equals'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IFilter, IFilterFormState } from './filter.types'

export function clearFilterValues<
  TOption extends IXtAutocompleteOption,
  TFilters extends Array<IFilter<TOption>>,
  TFiltersState extends IFilterFormState
>(defaultValues: IFilterFormState, filters: TFilters): TFiltersState {
  const updatedValues: IFilterFormState = { ...defaultValues }

  filters.forEach(({ fieldName }) => {
    const hasValueProperty = Object.prototype.hasOwnProperty.call(updatedValues, fieldName)
    if (!hasValueProperty) {
      return
    }
    updatedValues[fieldName] = null
  })

  return updatedValues as TFiltersState
}

//This method just check for equality of existing filter values with defalut values
export function equalFilterValues<TFiltersState extends IFilterFormState>(defaultFilters: TFiltersState, filters: TFiltersState): boolean {
  for (let key in defaultFilters) {
    const value = filters[key]
    const defaultValue = defaultFilters[key]

    if (!(value instanceof Object)) {
      //treat all falsy as equals
      if (!value && !!value !== !!defaultValue) return false
      else if (value && value != defaultValue) return false
    } else if (!deepEqual(value, defaultValue)) return false
  }

  return true
}
