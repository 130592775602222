import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Divider } from '@material-ui/core'
import { confirmationMessages } from 'common/constants'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { useCoreModule } from 'core/core-module-hook'
import { XtButtonLabels } from 'components/buttons/xt-button/xt-button.types'
import { NavigationList } from './navigation-list/navigation-list'
import { ProgressStepper } from './progress-stepper/progress-stepper'
import { defaultWizardDialogState, defaultWizardState } from './setup-wizard.constants'
import { ISetupWizardDialogState, ISetupWizardRequestParams, ISetupWizardModuleState } from './setup-wizard.types'
import { setupModuleList, SetupModulesParams, BasicConfigSteps } from './setup-wizard.exports'
import { ICreateItemsState } from './basic-configuration-steps/create-items/create-items.types'
import { ICreateAccountsState } from './basic-configuration-steps/create-accounts/create-accounts.types'
import { useSetupWizardModule } from './setup-wizard-module-hook'
import { countSteps, getCurrentStep } from './setup-wizard.utils'
import * as styles from './setup-wizard.module.scss'
import { XtSalesHeader } from './header/header'
import { SetUpWizardFooter } from './footer/footer'

export const SetupWizard: FC = () => {
  const history = useHistory()
  const prevWizardState = useLocation<{ isDirty: boolean; formState: ISetupWizardModuleState }>()

  const { ToastService, ErrorHandler } = useCoreModule()

  const { activeModule, activeStep } = useParams<ISetupWizardRequestParams>()
  const { SetupWizardService } = useSetupWizardModule()

  const [wizardState, setWizardState] = useState<ISetupWizardDialogState>(defaultWizardDialogState)
  const [formState, setFormState] = useState<ISetupWizardModuleState>(prevWizardState?.state?.formState ?? defaultWizardState)
  const [isLoading, setLoading] = useState<boolean>(false)

  const { open: confirmationDialogOpen, openDialog: openConfirmationDialog, closeDialog: closeConfirmationDialog } = useConfirmationDialog()

  const isDirty =
    prevWizardState?.state?.isDirty ||
    wizardState.isDirty ||
    formState.basic.createItems.items.length > 0 ||
    formState.basic.createAccounts.accounts.length

  const onChangeData = useCallback(
    (formData: ICreateItemsState | ICreateAccountsState, isFormDirty: boolean) => {
      const newFormData = {
        ...formState,
        [activeModule]: {
          ...formState[activeModule],
          [wizardState.activeStep.state?.param]: formData,
        },
      }
      setWizardState((prevState) => ({
        ...prevState,
        isFormDirty,
      }))
      setFormState(newFormData)
    },
    [formState, wizardState, activeModule]
  )

  const onSaveData = useCallback(async (): Promise<void> => {
    try {
      setLoading(true)
      if (activeModule == SetupModulesParams.BasicConfiguration && activeStep === BasicConfigSteps.Accounts) {
        if (formState?.basic?.createItems?.items.length > 0) {
          const message = await SetupWizardService.createItems(formState.basic.createItems?.items ?? [])
          message.map((res) => {
            ToastService.showSuccess(res)
          })
        }
        if (formState?.basic?.createAccounts?.accounts.length > 0) {
          const message = await SetupWizardService.createAccounts(formState.basic.createAccounts?.accounts ?? [])
          message.map((res) => {
            ToastService.showSuccess(res)
          })
        }
      }
      setLoading(false)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }, [formState, SetupWizardService, ErrorHandler, activeModule, activeStep])

  const onCloseSetupWizard = useCallback((): void => {
    closeConfirmationDialog()
    history.push('/')
    setLoading(false)
  }, [closeConfirmationDialog])

  const onCancelSetupWizard = useCallback(async (): Promise<void> => {
    try {
      await SetupWizardService.updateSetting(true)
    } catch (e) {
      ErrorHandler.handleError(e)
    } finally {
      onCloseSetupWizard()
    }
  }, [onCloseSetupWizard])

  const onFinishSetup = useCallback(async () => {
    if (prevWizardState?.state?.isDirty || wizardState.isDirty) {
      ToastService.showSuccess('You have successfully completed the Setup Wizard.')
    }
    try {
      await SetupWizardService.updateSetting(false)
    } catch (e) {
      ErrorHandler.handleError(e)
    } finally {
      closeConfirmationDialog()
      history.push('/')
    }
  }, [closeConfirmationDialog, history, prevWizardState, ToastService, SetupWizardService, wizardState.isDirty])

  const onNextStep = useCallback(async () => {
    await onSaveData()
    if (wizardState.activeStep.id === wizardState.activeModule.steps.length) {
      if (setupModuleList[setupModuleList.length - 1].name === wizardState.activeModule.name) {
        openConfirmationDialog(false)
        return
      }
      const nextModule = setupModuleList[wizardState.activeModule.id + 1]
      setWizardState((prevState) => ({
        ...prevState,
        activeModule: nextModule,
        activeStep: {
          id: nextModule.steps[0].id,
          state: nextModule.steps[0],
        },
      }))
      history.push({
        pathname: `/setup-wizard/${nextModule.param}/${nextModule.steps[0].name}`,
        state: { isDirty: isDirty, formState },
      })
      return
    }
    const nextStep = wizardState.activeStep.id + 1
    setWizardState((prevState) => ({
      ...prevState,
      activeStep: {
        id: nextStep,
        state: wizardState.activeModule.steps[nextStep - 1],
      },
    }))
    history.push({
      pathname: `/setup-wizard/${wizardState.activeModule.param}/${wizardState.activeModule.steps[nextStep - 1].name}`,
      state: { isDirty, formState },
    })
  }, [wizardState, history, isDirty, onSaveData, openConfirmationDialog])

  const onPreviousStep = useCallback(() => {
    if (wizardState.activeStep.id == defaultWizardDialogState.activeStep.id) {
      if (wizardState.activeModule == defaultWizardDialogState.activeModule) {
        return
      }
    }
    const filteredStep = wizardState.activeModule.steps.filter((item) => item.id === wizardState.activeStep.id - 1)[0]
    history.push({
      pathname: `/setup-wizard/${activeModule}/${filteredStep.name}`,
      state: { isDirty: prevWizardState?.state?.isDirty ?? false, formState },
    })
  }, [wizardState, prevWizardState, history, activeModule, isDirty])

  const getProgress = useCallback(() => {
    return ((getCurrentStep(wizardState) - 1) / countSteps()) * 100
  }, [wizardState])

  const onChangeStep = useCallback(
    (moduleId: number, stepId: number): void => {
      const filteredModule = setupModuleList[moduleId - 1]
      setWizardState((prevState) => ({
        ...prevState,
        activeModule: filteredModule,
        activeStep: {
          id: stepId,
          state: filteredModule.steps[stepId - 1],
        },
      }))
      history.push({
        pathname: `/setup-wizard/${filteredModule.param}/${filteredModule.steps[stepId - 1].name}`,
        state: { isDirty: prevWizardState?.state?.isDirty ?? false, formState },
      })
    },
    [history, wizardState, prevWizardState, isDirty]
  )

  useEffect(() => {
    const filteredModule = setupModuleList.filter((item) => item.param === activeModule)[0]
    const filteredStep = filteredModule.steps.filter((item) => item.name === activeStep)[0]
    const isLastStep =
      setupModuleList[setupModuleList.length - 1].name === filteredModule.name &&
      wizardState.activeStep.id === wizardState.activeModule.steps.length
    setWizardState((prevState) => ({
      ...prevState,
      isLastStep,
      activeModule: filteredModule,
      activeStep: {
        id: filteredStep.id,
        state: filteredStep,
      },
    }))
  }, [activeStep, activeModule])

  return (
    <div className={styles.setupWizard}>
      <div className={styles.setupWizardContainer}>
        <NavigationList activeModule={activeModule} activeStep={activeStep} onChangeStep={onChangeStep} />
        <div className={styles.setupWizardContent}>
          <XtSalesHeader />
          <ProgressStepper state={wizardState} />
          {setupModuleList.map((setupModule) => {
            return setupModule.param == activeModule
              ? setupModule.steps.map((step, stepIndex) => {
                  return step.name == activeStep ? (
                    <step.component onChange={onChangeData} key={stepIndex} onSubmit={onNextStep} state={formState} />
                  ) : (
                    ''
                  )
                })
              : ''
          })}
        </div>
      </div>
      <Divider className={styles.sectionDivider} />
      <div className={styles.buttonToolbar}>
        <div>
          <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" value={getProgress()} />
            <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
              <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(getProgress())}%`}</Typography>
            </Box>
          </Box>
        </div>
        <div className={styles.buttonContainer}>
          <XtButton label={XtButtonLabels.Cancel} onClick={() => openConfirmationDialog(false)} loading={isLoading} />
          <XtButton
            label={XtButtonLabels.Back}
            onClick={onPreviousStep}
            loading={isLoading}
            disabled={
              wizardState.activeModule.id == defaultWizardDialogState.activeModule.id &&
              wizardState.activeStep.id == defaultWizardDialogState.activeStep.id
            }
          />
          <XtButton label={XtButtonLabels.Next} onClick={onNextStep} loading={isLoading} />
        </div>
      </div>
      <SetUpWizardFooter />
      <XtConfirmationDialog
        open={confirmationDialogOpen}
        message={confirmationMessages.setupWizardPrompt}
        title="Run Setup Wizard Again"
        confirmationButtonLabel={XtButtonLabels.Yes}
        cancelButtonLabel={XtButtonLabels.No}
        onConfirm={onFinishSetup}
        onClose={onCancelSetupWizard}
      />
    </div>
  )
}
