import * as yup from 'yup'
import { addDays } from 'date-fns'
import { DateSchema } from 'yup'
import { validationMessage, validateDecimal } from 'common/validation/validation'
import { BomItemFormField, FieldName } from './bom-item.types'
import { IBomItem } from '../bom.types'

export const BomItemFormSchema = (bomItems: IBomItem[], bomItem: IBomItem | null, isEditMode: boolean = false) =>
  yup.object().shape(
    {
      [BomItemFormField.Item]: yup.mixed().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
      [BomItemFormField.FixedQty]: yup
        .number()
        .required(validationMessage.isRequired)
        .nullable()
        .test('is-valid-decimal', validationMessage.invalidDecimal, (value) => validateDecimal(String(value))),
      [BomItemFormField.QtyPer]: yup
        .number()
        .required(validationMessage.isRequired)
        .nullable()
        .test('is-valid-decimal', validationMessage.invalidDecimal, (value) => validateDecimal(String(value))),
      [BomItemFormField.IssueUom]: yup.object().nullable().required(validationMessage.isRequired),
      [BomItemFormField.IssueMethod]: yup.object().nullable().required(validationMessage.isRequired),
      [BomItemFormField.Scrap]: yup
        .number()
        .required(validationMessage.isRequired)
        .nullable()
        .test('is-valid-decimal', validationMessage.invalidDecimal, (value) => validateDecimal(String(value))),
      [BomItemFormField.Expires]: yup
        .date()
        .nullable()
        .typeError(validationMessage.invalidDate)
        .when(BomItemFormField.Effective, (effective: Date | null, schema: DateSchema) =>
          effective instanceof Date ? schema.min(addDays(effective, 1), validationMessage.minDate(FieldName.Effective)) : schema
        ),
      [BomItemFormField.Effective]: yup
        .date()
        .nullable()
        .typeError(validationMessage.invalidDate)
        .when(BomItemFormField.Expires, (expires: Date | null, schema: DateSchema) =>
          expires instanceof Date ? schema.max(addDays(expires, -1), validationMessage.maxDate(FieldName.Expires)) : schema
        ),
      [BomItemFormField.SequenceNumber]: yup.number().test('seq-exists', validationMessage.existingSequencenumber, (seqNum) => {
        if (!isEditMode) return true
        return !bomItems.some((item) => bomItem && bomItem.sequence_number !== item.sequence_number && item.sequence_number === seqNum)
      }),
    },
    [[BomItemFormField.Expires, BomItemFormField.Effective]]
  )
