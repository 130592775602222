import React, { FC, useEffect, useMemo } from 'react'
import { useXtForm } from 'common/hooks/form/form'
import { FormCheckboxLabel, FormRadioGroup, FormSelectField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { useCoreModule } from 'core/core-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { convertMode } from 'common/utils/mode.utils'
import { useProductsModule } from 'products/products-module-hook'
import { ControlMethodOption, CostingMethodOption } from 'products/items/items.types'
import * as styles from './sites-details-dialog-inventory.module.scss'
import {
  abcClassOptions,
  defineCostingMethodOptions,
  defineDefaultInventoryFormData,
  defineItemCostRecognitionOptions,
  getControlMethodOptions,
} from './sites-details-dialog-inventory.utils'
import {
  daysSuffix,
  integerDecimalScale,
  sitesDetailsDialogInventoryFormKey,
  typicalMaxValue,
  typicalMinValue,
} from './sites-details-dialog-inventory.consts'
import {
  ISitesDetailsDialogInventory,
  SitesDetailsDialogInventoryForm,
  SitesDetailsDialogInventoryFormField,
  SitesDetailsDialogInventoryFormFieldLabel,
} from './sites-details-dialog-inventory.types'
import { sitesDialogInventoryValidation } from './sites-details-dialog-inventory.validation'

export const SitesDetailsDialogInventory: FC<ISitesDetailsDialogInventory> = ({ itemSite, mode, register, fractional }) => {
  const { ErrorHandler } = useCoreModule()
  const { isViewMode } = convertMode(mode)
  const { DocumentsUtilsService } = useDocumentsModule()
  const { ItemSitesUtilsService } = useProductsModule()

  const { control, formChanges$, trigger, getValues, formState, reset, setValue, watch } = useXtForm<SitesDetailsDialogInventoryForm>({
    mode: 'onBlur',
    defaultValues: defineDefaultInventoryFormData(null),
    validationSchema: sitesDialogInventoryValidation,
  })

  const controlMethod = watch(SitesDetailsDialogInventoryFormField.ControlMethod)
  const costingMethod = watch(SitesDetailsDialogInventoryFormField.CostingMethod)
  const overrideWorkOrderItemCostRecognitionDetailsEnabled = watch(
    SitesDetailsDialogInventoryFormField.OverrideWorkOrderItemCostRecognitionDetailsEnabled
  )

  const controlMethodOptions = useMemo<IXtAutocompleteOption[]>(() => getControlMethodOptions(fractional), [fractional])
  const costingMethodOptions = defineCostingMethodOptions(controlMethod?.id === ControlMethodOption.None)

  const preventNegativeInventoryDisabled = useMemo(
    (): boolean => controlMethod?.id !== ControlMethodOption.Regular || costingMethod === CostingMethodOption.Job,
    [controlMethod, costingMethod]
  )

  useEffect(() => {
    const init = async (): Promise<void> => {
      try {
        reset(defineDefaultInventoryFormData(itemSite))
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }
    void init()
  }, [ErrorHandler, itemSite, reset])

  useEffect(() => {
    register(sitesDetailsDialogInventoryFormKey, formChanges$, trigger, { data: getValues(), state: formState }, reset)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formChanges$, register, trigger, getValues])

  const onControlMethodChange = (option: IXtAutocompleteOption): void => {
    const { id: method } = option
    if (method === ControlMethodOption.None) {
      setValue(SitesDetailsDialogInventoryFormField.CostingMethod, CostingMethodOption.None)
    }
    if (method !== ControlMethodOption.None && costingMethod === CostingMethodOption.None) {
      setValue(SitesDetailsDialogInventoryFormField.CostingMethod, CostingMethodOption.Average)
    }
    if (method !== ControlMethodOption.Regular) {
      setValue(SitesDetailsDialogInventoryFormField.PreventNegativeInventory, true)
    }
    setValue(SitesDetailsDialogInventoryFormField.ControlMethod, option)
  }

  const onCostingMethodChange: (method: CostingMethodOption) => void = (method) => {
    if (method === CostingMethodOption.Job) {
      setValue(SitesDetailsDialogInventoryFormField.PreventNegativeInventory, true)
    }
    setValue(SitesDetailsDialogInventoryFormField.CostingMethod, method)
  }

  return (
    <div className={styles.inventoryContainer}>
      <div>
        <div className={styles.inventoryContainerLeft}>
          <h1 className="xt-page-title">Control</h1>
          <FormSelectField
            control={control}
            options={controlMethodOptions}
            name={SitesDetailsDialogInventoryFormField.ControlMethod}
            label={SitesDetailsDialogInventoryFormFieldLabel.ControlMethod}
            onChange={onControlMethodChange}
            getOptionDisabled={(option: IXtAutocompleteOption | null): boolean => option?.disabled ?? false}
            clearable={true}
            disabled={isViewMode}
          />
          <FormXtAutocomplete
            control={control}
            loadOptions={DocumentsUtilsService.loadPlannerCodes}
            name={SitesDetailsDialogInventoryFormField.PlannerCode}
            label={SitesDetailsDialogInventoryFormFieldLabel.PlannerCode}
            disabled={isViewMode}
          />
          <FormXtAutocomplete
            control={control}
            loadOptions={DocumentsUtilsService.loadCostCategories}
            name={SitesDetailsDialogInventoryFormField.CostCategoryCode}
            label={SitesDetailsDialogInventoryFormFieldLabel.CostCategory}
            disabled={isViewMode}
          />
          <FormCheckboxLabel
            control={control}
            name={SitesDetailsDialogInventoryFormField.PreventNegativeInventory}
            label={
              preventNegativeInventoryDisabled
                ? SitesDetailsDialogInventoryFormFieldLabel.NegativeInventoryPrevented
                : SitesDetailsDialogInventoryFormFieldLabel.PreventNegativeInventory
            }
            disabled={preventNegativeInventoryDisabled || isViewMode}
          />
          <h1 className="xt-page-title">Settings</h1>
          <FormCheckboxLabel
            control={control}
            name={SitesDetailsDialogInventoryFormField.CalculateInventoryBuffer}
            label={SitesDetailsDialogInventoryFormFieldLabel.CalculateInventoryBuffer}
            disabled={isViewMode}
          />
          <FormCheckboxLabel
            control={control}
            name={SitesDetailsDialogInventoryFormField.AllowAutomaticABCUpdated}
            label={SitesDetailsDialogInventoryFormFieldLabel.AllowAutomaticABCUpdated}
            disabled={isViewMode}
          />
          <FormSelectField
            control={control}
            options={abcClassOptions}
            name={SitesDetailsDialogInventoryFormField.AbcClass}
            label={SitesDetailsDialogInventoryFormFieldLabel.AbcClass}
            clearable={true}
            disabled={isViewMode}
          />
          <DecimalFormField
            control={control}
            name={SitesDetailsDialogInventoryFormField.CyclCntFreq}
            label={SitesDetailsDialogInventoryFormFieldLabel.CyclCntFreq}
            minValue={typicalMinValue}
            maxValue={typicalMaxValue}
            allowNegative={false}
            fixedDecimalScale={integerDecimalScale}
            suffix={daysSuffix}
            disabled={isViewMode}
          />
          <DecimalFormField
            control={control}
            name={SitesDetailsDialogInventoryFormField.EventFence}
            label={SitesDetailsDialogInventoryFormFieldLabel.EventFence}
            minValue={typicalMinValue}
            maxValue={typicalMaxValue}
            allowNegative={false}
            fixedDecimalScale={integerDecimalScale}
            suffix={daysSuffix}
            disabled={isViewMode}
          />
        </div>
      </div>
      <div>
        <div className={styles.inventoryContainerRight}>
          <h1 className="xt-page-title">{SitesDetailsDialogInventoryFormFieldLabel.CostingMethod}</h1>
          <FormRadioGroup
            control={control}
            options={costingMethodOptions}
            name={SitesDetailsDialogInventoryFormField.CostingMethod}
            label=""
            disabled={isViewMode}
            onChange={onCostingMethodChange}
          />
          <div>
            <FormCheckboxLabel
              control={control}
              name={SitesDetailsDialogInventoryFormField.OverrideWorkOrderItemCostRecognitionDetailsEnabled}
              label={SitesDetailsDialogInventoryFormFieldLabel.OverrideWorkOrderItemCostRecognitionDetailsEnabled}
              disabled={isViewMode}
            />
            <FormRadioGroup
              control={control}
              options={defineItemCostRecognitionOptions}
              name={SitesDetailsDialogInventoryFormField.OverrideWorkOrderItemCostRecognitionDetails}
              label=""
              className={styles.itemCostRecognition}
              disabled={isViewMode || !overrideWorkOrderItemCostRecognitionDetailsEnabled}
            />
          </div>
          <h1 className="xt-page-title">Automatic Lot/Serial Numbering</h1>
          <FormXtAutocomplete
            control={control}
            loadOptions={ItemSitesUtilsService.fetchLotSerialSequences}
            name={SitesDetailsDialogInventoryFormField.AutomaticLotSerialNumbering}
            label={SitesDetailsDialogInventoryFormFieldLabel.AutomaticLotSerialNumbering}
            disabled={isViewMode}
          />
        </div>
      </div>
    </div>
  )
}
