import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'

export enum QuantityOnHandListFilterField {
  ClassCode = 'class_code',
  ClassCodePattern = 'class_code_pattern',
  CostCategory = 'cost_category',
  CostCategoryPattern = 'cost_category_pattern',
  ItemNumber = 'item_number',
  ItemGroup = 'item_group',
  ItemGroupPattern = 'item_group_pattern',
  AbcClass = 'abc_class',
  Site = 'site',
  AsOf = 'asOf',
  ShowPositiveQuantities = 'showPositive',
  ShowNegativeQuantities = 'showNegative',
  ShowValue = 'showValue',
  UseActualCosts = 'useActualCosts',
}

export interface IQuantityOnHandListFilter {
  [QuantityOnHandListFilterField.ClassCode]: string | null
  [QuantityOnHandListFilterField.ClassCodePattern]: string | null
  [QuantityOnHandListFilterField.CostCategory]: string | null
  [QuantityOnHandListFilterField.CostCategoryPattern]: string | null
  [QuantityOnHandListFilterField.ItemNumber]: string | null
  [QuantityOnHandListFilterField.ItemGroup]: string | null
  [QuantityOnHandListFilterField.ItemGroupPattern]: string | null
  [QuantityOnHandListFilterField.AbcClass]: string | null
  [QuantityOnHandListFilterField.Site]: string | null
  [QuantityOnHandListFilterField.AsOf]: string | null
  [QuantityOnHandListFilterField.ShowPositiveQuantities]: boolean | null
  [QuantityOnHandListFilterField.ShowNegativeQuantities]: boolean | null
  [QuantityOnHandListFilterField.ShowValue]: boolean | null
  [QuantityOnHandListFilterField.UseActualCosts]: boolean | null
}

export enum QuantityOnHandListFilterFormField {
  ClassCode = 'class_code',
  ClassCodePattern = 'class_code_pattern',
  CostCategory = 'cost_category',
  CostCategoryPattern = 'cost_category_pattern',
  ItemNumber = 'item_number',
  ItemGroup = 'item_group',
  ItemGroupPattern = 'item_group_pattern',
  AbcClass = 'abc_class',
  Site = 'site',
  AsOf = 'asOf',
  ShowQuantities = 'show_quantities',
  ShowValue = 'showValue',
  UseCost = 'useCost',
}

export enum QuantityOnHandListFilterFormFieldLabel {
  ClassCode = 'Class Code',
  ClassCodePattern = 'Class Code Pattern',
  CostCategory = 'Cost Category',
  CostCategoryPattern = 'Cost Category Pattern',
  ItemNumber = 'Item Number',
  ItemGroup = 'Item Group',
  ItemGroupPattern = 'Item Group Pattern',
  AbcClass = 'ABC Class',
  Site = 'Site',
  AsOf = 'As Of',
  ShowInventoryValue = 'Show Inventory Value',
}

export type IQuantityOnHandListFilterForm = {
  [QuantityOnHandListFilterFormField.ClassCode]: IXtAutocompleteOption | null
  [QuantityOnHandListFilterFormField.ClassCodePattern]: string | null
  [QuantityOnHandListFilterFormField.CostCategory]: IXtAutocompleteOption | null
  [QuantityOnHandListFilterFormField.CostCategoryPattern]: string | null
  [QuantityOnHandListFilterFormField.ItemNumber]: IXtAutocompleteOption | null
  [QuantityOnHandListFilterFormField.ItemGroup]: IXtAutocompleteOption | null
  [QuantityOnHandListFilterFormField.ItemGroupPattern]: string | null
  [QuantityOnHandListFilterFormField.AbcClass]: IXtAutocompleteOption | null
  [QuantityOnHandListFilterFormField.Site]: IXtAutocompleteOption | null
  [QuantityOnHandListFilterFormField.AsOf]: IXtAutocompleteOption | null
  [QuantityOnHandListFilterFormField.ShowQuantities]: ShowQuantityOptions | null
  [QuantityOnHandListFilterFormField.ShowValue]: boolean
  [QuantityOnHandListFilterFormField.UseCost]: UseCostOptions | null
}

export enum AbcClassOptions {
  A = 'A',
  B = 'B',
  C = 'C',
}

export enum AbcClassOptionsLabel {
  A = 'A class',
  B = 'B class',
  C = 'C class',
}

export enum ShowQuantityOptions {
  All = 'all',
  Positive = 'positive',
  Negative = 'negative',
}

export enum ShowQuantityOptionsLabel {
  All = 'Show All Quantities',
  Positive = 'Only Show Positive Quantities',
  Negative = 'Only Show Negative Quantities',
}

export enum UseCostOptions {
  Actual = 'actual',
  Standard = 'standard',
}

export enum UseCostOptionsLabel {
  Actual = 'Use Actual Costs',
  Standard = 'Use Standard Costs',
}
