import { Overwrite } from 'utility-types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IShipping } from 'shipping/shipping.types'

export enum ShippingInformationTabName {
  ShippingHeader = 'Shipping Header',
  Items = 'Items',
  Notes = 'Notes',
}

export enum ShippingInformationFormField {
  ShipDate = 'ship_date',
  ShipVia = 'ship_via',
  ShippingForm = 'shipping_form',
  Freight = 'freight',
  Currency = 'freight_currency',
  Notes = 'notes',
}

export enum ShippingInformationFormLabel {
  ShipDate = 'Ship Date',
  ShipVia = 'Ship Via',
  ShippingForm = 'Shipping Form',
  Freight = 'Freight',
  Currency = 'Currency',
  Notes = 'Notes',
}

export type ShipmentHeaderFormFilled = Overwrite<
  Pick<
    IShipping,
    | ShippingInformationFormField.ShipDate
    | ShippingInformationFormField.ShipVia
    | ShippingInformationFormField.ShippingForm
    | ShippingInformationFormField.Freight
    | ShippingInformationFormField.Currency
    | ShippingInformationFormField.Notes
  >,
  {
    [ShippingInformationFormField.ShipDate]: Date | null
    [ShippingInformationFormField.ShipVia]: IXtAutocompleteOption
    [ShippingInformationFormField.ShippingForm]: IXtAutocompleteOption
    [ShippingInformationFormField.Freight]: number | null
    [ShippingInformationFormField.Currency]: IXtAutocompleteOption
  }
>

export type ShipmentHeaderForm = Overwrite<
  ShipmentHeaderFormFilled,
  {
    [ShippingInformationFormField.ShipVia]: IXtAutocompleteOption | null
    [ShippingInformationFormField.ShippingForm]: IXtAutocompleteOption | null
    [ShippingInformationFormField.Currency]: IXtAutocompleteOption | null
  }
>
