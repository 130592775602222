import React, { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { loginRoutePath } from 'auth/auth.constants'
import { XtAccordion } from 'components/xt-accordion/xt-accordion'
import { cls } from 'common/utils/utils'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { useUsersModule } from 'users/users-module-hook'
import { useDictionaryModule } from 'dictionary/dictionary-module-hook'
import {
  FormCheckboxLabel,
  FormField,
  FormSelectCreatableField,
  FormSelectField,
  FormXtAutocomplete,
} from 'common/utils/form/form.components'
import { useXtSelect } from 'components/controls/xt-select/xt-select-hook'
import { confirmationMessages } from 'common/constants'
import { profileValidation } from './profile.validation'
import * as styles from './profile.module.scss'
import { convertFormData, defineFormData } from './profile.utils'
import {
  PasswordChangeFormField,
  PasswordChangeLabel,
  ProfileDetailsForm,
  ProfileDetailsFormField,
  ProfileDetailsLabel,
} from './profile.types'
import { rowsPerPageOptions } from './profile.constants'

interface IProfileDetails {
  onClose(): void
}

export const ProfileDialog: FC<IProfileDetails> = ({ onClose }) => {
  const { DocumentsUtilsService } = useDocumentsModule()
  const { DictionaryUtilsService } = useDictionaryModule()
  const { ToastService } = useCoreModule()
  const { AuthService } = useAuthModule()
  const { UsersService } = useUsersModule()

  const history = useHistory()
  const {
    formState: { isDirty },
    control,
    reset,
    handleSubmit,
    getValues,
    setValue,
    trigger,
  } = useForm<ProfileDetailsForm>({
    defaultValues: defineFormData(null),
    mode: 'onBlur',
    resolver: yupResolver(profileValidation),
  })
  const { options: sites } = useXtSelect(DocumentsUtilsService.loadSiteOptions)
  const username = AuthService.getUsername()

  const init: () => Promise<void> = async () => {
    if (!username) {
      return
    }
    const user = await UsersService.get(username)
    if (user?.preferred_country) {
      const country = await DictionaryUtilsService.loadCountry(user?.preferred_country)
      reset(defineFormData(user, country))
      return
    }
    reset(defineFormData(user))
  }

  useEffect(() => {
    void init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onLogOut: VoidFunction = () => {
    AuthService.logout()
    history.push(loginRoutePath)
  }

  const onCancel: VoidFunction = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm(confirmationMessages.unsavedChanges)) {
      return
    }
    onClose()
  }

  const onPersonalProfileSubmit: (formData: ProfileDetailsForm) => Promise<void> = async (formData) => {
    try {
      const message = await UsersService.update(convertFormData(formData))
      ToastService.showSuccess(message)
      onClose()
    } catch {
      ToastService.showError('Something went wrong.')
    }
  }

  const onChangePassword: (pass: string) => void = (pass) => {
    setValue(PasswordChangeFormField.Password, pass, { shouldDirty: true, shouldValidate: true })
    const passwordVerify = getValues(PasswordChangeFormField.Verify)
    if (passwordVerify) {
      void trigger(PasswordChangeFormField.Verify)
    }
  }

  const onChangeVerify: (verifyPass: string) => void = (verifyPass) => {
    setValue(PasswordChangeFormField.Verify, verifyPass, { shouldValidate: true, shouldDirty: true })
  }

  return (
    <div className={cls(styles.mainContainer, 'xt-dialog-scrollable')}>
      <h1 className={styles.headerText}>Profile Details</h1>
      <form className={styles.profileDetailsFormContainer} onSubmit={handleSubmit(onPersonalProfileSubmit)}>
        <div className={styles.controlButtonsContainer}>
          <XtButton label="Cancel" onClick={onCancel} />
          <XtButton label="Save" type="submit" disabled={!isDirty} />
        </div>
        <div className={styles.formColumn}>
          <FormCheckboxLabel name={ProfileDetailsFormField.Active} control={control} label={ProfileDetailsLabel.Active} disabled />
          <FormField name={ProfileDetailsFormField.Username} control={control} label={ProfileDetailsLabel.Username} disabled />
          <FormField name={ProfileDetailsFormField.ProperName} control={control} label={ProfileDetailsLabel.ProperName} />
          <FormField name={ProfileDetailsFormField.Initials} control={control} label={ProfileDetailsLabel.Initials} />
          <FormXtAutocomplete
            name={ProfileDetailsFormField.Locale}
            control={control}
            label={ProfileDetailsLabel.Locale}
            loadOptions={DocumentsUtilsService.loadLocales}
          />
          <FormXtAutocomplete
            name={ProfileDetailsFormField.PreferredCountry}
            control={control}
            label={ProfileDetailsLabel.PreferredCountry}
            loadOptions={DictionaryUtilsService.loadCountries}
          />
          <FormSelectField
            control={control}
            name={ProfileDetailsFormField.PreferredSite}
            label={ProfileDetailsLabel.PreferredSite}
            options={sites}
            clearable
          />
          <FormSelectCreatableField
            options={rowsPerPageOptions}
            name={ProfileDetailsFormField.RowsPerPage}
            control={control}
            label={ProfileDetailsLabel.RowsPerPage}
          />
          <XtAccordion summary="Password">
            <div className={styles.passwordContainer}>
              <FormField
                name={PasswordChangeFormField.Password}
                control={control}
                label={PasswordChangeLabel.Password}
                type="password"
                onChange={onChangePassword}
                autoComplete="new-password"
              />
              <FormField
                name={PasswordChangeFormField.Verify}
                control={control}
                label={PasswordChangeLabel.Verify}
                type="password"
                onChange={onChangeVerify}
                autoComplete="new-password"
              />
            </div>
          </XtAccordion>
          <XtButton label="Log Out" onClick={onLogOut} className={styles.logoutButton} />
        </div>
      </form>
    </div>
  )
}
