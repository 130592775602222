import { Injector } from 'core/injector/injector.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { IUsersModuleServices } from './users.module'
import { IUsersService } from './users.service'
import { usersServiceToken, usersUtilsServiceToken } from './users.constants'
import { IUsersUtilsService } from './users-utils.service'

function resolveUsersModule(): IUsersModuleServices {
  const UsersService = Injector.resolve<IUsersService>(usersServiceToken)
  const UsersUtilsService = Injector.resolve<IUsersUtilsService>(usersUtilsServiceToken)

  return { UsersService, UsersUtilsService }
}

export const useUsersModule = moduleHookResolver<IUsersModuleServices>(resolveUsersModule)
