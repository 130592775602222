import { CostedBomSummaryFilters } from 'products/items/items.types'
import { IBomListFormSharedParams, IBomListSharedForm } from '../../shared/shared.types'

export enum FormField {
  ShowExpiredComponents = 'expiredDays',
  ThresholdWithExpiredDays = 'expired_threshold_days',
  ThresholdWithFutureDays = 'future_threshold_days',
  ShowFutureComponents = 'futureDays',
}

export enum FormLabel {
  ShowExpiredComponents = 'Show Expired Components',
  ThresholdDays = 'Threshold Days',
  ShowFutureComponents = 'Show Future Components',
}

export interface ISummarizedForm extends IBomListSharedForm {
  [FormField.ThresholdWithExpiredDays]: number
  [FormField.ThresholdWithFutureDays]: number
  [FormField.ShowExpiredComponents]: boolean
  [FormField.ShowFutureComponents]: boolean
}

export type SummarizedListFormParams = IBomListFormSharedParams<CostedBomSummaryFilters>
