import { LineItemDetailsInput } from 'sales-shared/line-items/line-item-details/line-item-details.types'
import { IPricesData } from 'sales-shared/sales-shared.types'

export enum ShippingLinesAction {
  IssueAdditionalStock = 'Issue Additional Stock to Order Line',
  ReturnAll = 'Return all of this Stock Issued in this Transaction',
  ViewOrder = 'View Order Line',
}

export enum ShippingInformationKeys {
  ShippingInformation,
  ViewOrder,
  PrintShippingForm,
  ShipOrder,
  ReturnAll,
}

export type ShippingSalesLineItemDialogState = {
  site: string | null
  customerNumber: string
  currency: string
  lineItemInput: LineItemDetailsInput
  pricesData: IPricesData
}

export type IssueToShippingDialogState = {
  site: string
  orderNumber: string
}
