import { IObjectWithId } from '../../../common/common.types'
import { IBom } from '../bom.types'

export enum BomListAction {
  View = 'view',
  Edit = 'edit',
  Delete = 'delete',
  Print = 'print',
  Copy = 'copy',
}

export interface IBomTableItem
  extends IObjectWithId,
    Pick<IBom, 'item_number' | 'item_inventory_uom' | 'uom' | 'item_description' | 'description1' | 'revision' | 'revision_status'> {}

export type BomListActionHandler = (item: IBomTableItem, action: BomListAction) => void
