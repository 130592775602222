import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { QuotesAction } from 'sales-shared/quotes.types'
import { SalesOrdersListAction } from '../../../../../sales-orders/components/sales-orders-list-general/sales-orders-list-general.types'

export const QuotesListActionsEditMode: IXtTableCellAction[] = [
  {
    name: QuotesAction.Edit,
    label: QuotesAction.Edit,
    icon: SvgIconIds.CREATE,
  },
  {
    name: QuotesAction.View,
    label: QuotesAction.View,
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: QuotesAction.Delete,
    label: QuotesAction.Delete,
    icon: SvgIconIds.DELETE,
  },
  {
    name: QuotesAction.Copy,
    label: QuotesAction.Copy,
    icon: SvgIconIds.COPY,
  },
  {
    name: QuotesAction.CopyTo,
    label: QuotesAction.CopyTo,
    icon: SvgIconIds.COMPARE_ARROW,
  },
  {
    name: QuotesAction.Convert,
    label: QuotesAction.ConvertToSO,
    icon: SvgIconIds.CONVERT,
  },
  {
    name: QuotesAction.PrintForm,
    label: QuotesAction.PrintForm,
    icon: SvgIconIds.PRINT,
  },
]

export const customerSalesOrderActions: IXtTableCellAction[] = [
  {
    name: SalesOrdersListAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: SalesOrdersListAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: SalesOrdersListAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
  {
    name: SalesOrdersListAction.CopyForCustomer,
    label: 'Copy',
    icon: SvgIconIds.COPY,
  },
  {
    name: SalesOrdersListAction.Print,
    label: 'Print',
    icon: SvgIconIds.PRINT,
  },
]
