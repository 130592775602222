import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { dateConverter } from 'common/utils/date.utils'
import { AttachField, AttachTableItem } from './attach-dialog.types'

export const orderNumberColumnId = 'order_number'
export const orderDateColumnId = 'order_date'

export const defaultAttachFormData = {
  [AttachField.Site]: null,
}

export const addressTableColumns: IXtTableColumn<AttachTableItem>[] = [
  {
    id: orderNumberColumnId,
    field: 'order_number',
    headerName: 'Order #',
    width: 160,
    flex: '2 0 160px',
  },
  {
    id: 'customer_number',
    field: 'customer_number',
    headerName: 'Customer',
    width: 160,
    flex: '2 0 160px',
  },
  {
    id: orderDateColumnId,
    field: 'order_date',
    headerName: 'Ordered',
    width: 160,
    flex: '2 0 160px',
    converter: dateConverter,
  },
  {
    id: 'scheduled_date',
    field: 'scheduled_date',
    headerName: 'Scheduled',
    width: 160,
    flex: '2 0 160px',
    converter: dateConverter,
  },
]
