import * as React from 'react'
import { TableRow } from '@material-ui/core'
import { useCallback } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { IXtTableRowType } from './table-row.types'
import { getFieldValue } from '../table.utils'
import { XtTableCell } from '../table-cell/table-cell'

const tableRowClasses = {
  root: 'xt-table-row',
  selected: 'xt-table-row--selected',
}

export const XtTableRow: IXtTableRowType = ({ item, columns, onClick, withDnd, index, rowClassName, selected = false, renderRow }) => {
  const onRowClick = useCallback(() => onClick?.(item), [item, onClick])
  // TODO id must be unique !!!
  return (
    <Draggable isDragDisabled={!withDnd} key={item.id} draggableId={item.id?.toString()} index={index}>
      {(provided) =>
        renderRow ? (
          renderRow(item, columns, { selected, tabIndex: -1, rowClasses: tableRowClasses, onRowClick, ...provided })
        ) : (
          <TableRow
            selected={selected}
            classes={tableRowClasses}
            component="div"
            className={rowClassName}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={onRowClick}
            tabIndex={-1}
          >
            {columns.map((column) => {
              const { field, renderCell, converter, id } = column

              if (field) {
                const fieldValue = getFieldValue(item, field.toString(), converter)

                return (
                  <XtTableCell title={fieldValue} column={column} key={id}>
                    {fieldValue}
                  </XtTableCell>
                )
              }
              if (renderCell) {
                return (
                  <XtTableCell column={column} key={id}>
                    {renderCell(item)}
                  </XtTableCell>
                )
              }
              return null
            })}
          </TableRow>
        )
      }
    </Draggable>
  )
}
