import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { FormRadioGroupOptions } from 'components/controls/xt-radio-group/xt-radio-group'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { ControlMethodOption, CostingMethodOption, IItemSite } from 'products/items/items.types'
import {
  AbcClassOptions,
  ItemCostRecognition,
  ItemCostRecognitionLabel,
  SitesDetailsDialogInventoryForm,
  SitesDetailsDialogInventoryFormField,
} from './sites-details-dialog-inventory.types'

export function getControlMethodOptions(fractional: boolean): IXtAutocompleteOption[] {
  return [
    {
      id: ControlMethodOption.None,
      label: ControlMethodOption.None,
    },
    {
      id: ControlMethodOption.Regular,
      label: ControlMethodOption.Regular,
    },
    {
      id: ControlMethodOption.LotNumber,
      label: ControlMethodOption.LotNumber,
    },
    {
      id: ControlMethodOption.SerialNumber,
      label: ControlMethodOption.SerialNumber,
      disabled: fractional,
    },
  ]
}

export const abcClassOptions: FormRadioGroupOptions = [
  {
    id: AbcClassOptions.A,
    label: AbcClassOptions.A,
  },
  {
    id: AbcClassOptions.B,
    label: AbcClassOptions.B,
  },
  {
    id: AbcClassOptions.C,
    label: AbcClassOptions.C,
  },
]

export function defineCostCategory(site: IItemSite | null): IXtAutocompleteOption | null {
  if (!site) {
    return null
  }
  return {
    id: site.cost_category.cost_category_code,
    label: site.cost_category.description,
    disabled: false,
  }
}

export function defineDefaultInventoryFormData(site: IItemSite | null): SitesDetailsDialogInventoryForm {
  return {
    [SitesDetailsDialogInventoryFormField.ControlMethod]: defineAutocompleteOption(site?.control_method ?? ControlMethodOption.Regular),
    [SitesDetailsDialogInventoryFormField.PlannerCode]: defineAutocompleteOption(site?.planner_code),
    [SitesDetailsDialogInventoryFormField.CostCategoryCode]: defineCostCategory(site),
    [SitesDetailsDialogInventoryFormField.CostCategory]: site?.cost_category ?? null,
    [SitesDetailsDialogInventoryFormField.PreventNegativeInventory]:
      site?.control_method === ControlMethodOption.None || site?.cost_method === CostingMethodOption.Job
        ? true
        : site?.prevent_negative_inventory ?? false,
    [SitesDetailsDialogInventoryFormField.CostingMethod]: site?.cost_method ?? CostingMethodOption.Average,
    [SitesDetailsDialogInventoryFormField.OverrideWorkOrderItemCostRecognitionDetailsEnabled]: site?.override_wo_cost_recognition != null,
    [SitesDetailsDialogInventoryFormField.OverrideWorkOrderItemCostRecognitionDetails]:
      site?.override_wo_cost_recognition ?? ItemCostRecognition.ToDate,
    [SitesDetailsDialogInventoryFormField.CalculateInventoryBuffer]: site?.stocked ?? false,
    [SitesDetailsDialogInventoryFormField.AllowAutomaticABCUpdated]: site?.allow_automatic_updates ?? false,
    [SitesDetailsDialogInventoryFormField.AbcClass]: defineAutocompleteOption(site?.abc_class ?? AbcClassOptions.A),
    [SitesDetailsDialogInventoryFormField.CyclCntFreq]: site?.cycl_count_freq ?? 0,
    [SitesDetailsDialogInventoryFormField.EventFence]: site?.event_fence ?? 10,
    [SitesDetailsDialogInventoryFormField.AutomaticLotSerialNumbering]: defineAutocompleteOption(site?.auto_ls_number),
  }
}

export function defineCostingMethodOptions(isControlMethodNone: boolean): FormRadioGroupOptions {
  return [
    { id: CostingMethodOption.None, label: CostingMethodOption.None, disabled: !isControlMethodNone },
    { id: CostingMethodOption.Average, label: CostingMethodOption.Average, disabled: isControlMethodNone },
    { id: CostingMethodOption.Standard, label: CostingMethodOption.Standard, disabled: isControlMethodNone },
    { id: CostingMethodOption.Job, label: CostingMethodOption.Job, disabled: isControlMethodNone },
  ]
}

export const defineItemCostRecognitionOptions: FormRadioGroupOptions = [
  { id: ItemCostRecognition.ToDate, label: ItemCostRecognitionLabel.ToDate },
  { id: ItemCostRecognition.Proportional, label: ItemCostRecognitionLabel.Proportional },
]
