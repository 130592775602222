import { PageNotFound } from 'page-not-found/page-not-found'
import { IAsyncRoute, IRoute } from '../common/routing/routing.types'
import { setupRoutePath, setupRoutes } from './setup.constants'
import { SetupGuard } from './setup.guard'
import { SalesGuard } from './sales/sales-tab.guard'
import { ProductsGuard } from './products/products.guard'
import { SystemGuard } from './system/system.guard'
import { UsersManagementGuard } from './users/users-management.guard'
import { CrmTabGuard } from './crm/crm-tab.guard'
import { SetupModule, SetupModuleDefinition } from './setup.module'

export function setupRoutesFactory({ SalesTab, CRMTab, SystemSetup, ProductsSetup, UsersList }: SetupModuleDefinition): IRoute[] {
  return [
    {
      path: setupRoutes.sales,
      name: 'Sales',
      guards: [SalesGuard],
      children: [
        {
          path: '',
          exact: true,
          component: SalesTab,
        },
        {
          path: '*',
          component: PageNotFound,
          exact: true,
        },
      ],
    },
    {
      path: setupRoutes.crm,
      name: 'CRM',
      guards: [CrmTabGuard],
      children: [
        {
          path: '',
          exact: true,
          component: CRMTab,
        },
        {
          path: '*',
          component: PageNotFound,
          exact: true,
        },
      ],
    },
    {
      path: setupRoutes.products,
      name: 'Products',
      guards: [ProductsGuard],
      children: [
        {
          path: '',
          exact: true,
          component: ProductsSetup,
        },
        {
          path: '*',
          component: PageNotFound,
          exact: true,
        },
      ],
    },
    {
      path: setupRoutes.HR,
      name: 'HR',
      type: 'dropdown',
      guards: [UsersManagementGuard],
      children: [
        {
          path: setupRoutes.userManagement,
          name: 'User Management',
          exact: true,
          component: UsersList,
        },
      ],
    },
    {
      path: setupRoutes.system,
      name: 'System',
      guards: [SystemGuard],
      children: [
        {
          path: '',
          exact: true,
          component: SystemSetup,
        },
        {
          path: '*',
          component: PageNotFound,
          exact: true,
        },
      ],
    },
    {
      path: '*',
      component: PageNotFound,
      exact: true,
    },
  ]
}

export const setupRoute: IAsyncRoute<SetupModuleDefinition> = {
  path: setupRoutePath,
  name: 'Setup',
  guards: [SetupGuard],
  module: SetupModule,
  childrenRoutesFactory: setupRoutesFactory,
}
