import { Injector } from 'core/injector/injector.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { prospectsServiceToken, prospectsUtilsServiceToken } from '../prospects.constants'
import { IProspectsService, IProspectsServices, IProspectsUtilsService } from './prospects-services.types'

export function resolveProspectsServicesModule(): IProspectsServices {
  const ProspectsService = Injector.resolve<IProspectsService>(prospectsServiceToken)
  const ProspectsUtilsService = Injector.resolve<IProspectsUtilsService>(prospectsUtilsServiceToken)

  return { ProspectsService, ProspectsUtilsService }
}

export const useProspectsServicesModule = moduleHookResolver<IProspectsServices>(resolveProspectsServicesModule)
