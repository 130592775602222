import * as React from 'react'
import { FC, useCallback } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { cls } from 'common/utils/utils'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { useTable } from 'common/hooks/useTable'
import { useCoreModule } from 'core/core-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { xsMq } from 'common/constants'
import { XtList } from 'components/list/list'
import { useSharedModule } from 'shared/shared-module-hook'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { ItemCostsByClassCodeFilter, ItemCostsByClassCodeLabel } from 'products/items/items.types'
import { XtPageFilter } from 'components/pagefilter/pagefilter'
import { resolveFilters } from 'companies/accounts/accounts-list/accounts-list.utils'
import { usePageFilter } from 'components/pagefilter/pagefilter.utils'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { useProductsModule } from 'products/products-module-hook'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import {
  byClassCodeActions,
  byClassCodePageTitle,
  ClassCodeAction,
  classCodeColumns,
  defaultFilterState,
} from './by-class-code-list.constants'
import { IItemCostsByClassCodeWithId } from '../item-costs.types'
import { ClassCodeFilters } from './by-class-code-list.types'
import * as styles from './by-class-code-list.module.scss'

export const ByClassCodeList: FC = () => {
  const { ErrorHandler } = useCoreModule()
  const { DocumentsUtilsService } = useDocumentsModule()
  const { CostingItemCostsUtilsService, SummaryList } = useProductsModule()
  const { useDialog } = useSharedModule()
  useDocumentTitle(byClassCodePageTitle)
  const isMobile = useMediaQuery(xsMq)

  const {
    open: summaryListDialogOpen,
    closeDialog: closeSummaryListDialog,
    openDialog: openSummaryListDialog,
    data: summaryListData,
  } = useDialog<string | undefined>()

  const {
    state: { loading, data, sortOptions, filters: tableFilters },
    filter,
    pagination,
    sort,
  } = useTable(defaultFilterState, CostingItemCostsUtilsService.fetchCostsByClassCode, undefined, PageFilterMapping.ItemCstClsCd)

  const itemCstClsCdListFilters = usePageFilter<ClassCodeFilters>(PageFilterMapping.ItemCstClsCd)

  const handleAction = useCallback<(item: IItemCostsByClassCodeWithId, action: ClassCodeAction) => void>(
    ({ item_number }, action) => {
      if (action === ClassCodeAction.ViewItemCostingSummary) {
        openSummaryListDialog(item_number)
      }
    },
    [openSummaryListDialog]
  )

  const handleRowClick = useCallback<(item: IItemCostsByClassCodeWithId) => void>(
    ({ item_number }) => {
      openSummaryListDialog(item_number)
    },
    [openSummaryListDialog]
  )

  const print = async (): Promise<void> => {
    try {
      await CostingItemCostsUtilsService.printCostsByClassCode(tableFilters)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const handleOnlyShowZeroStdCostsFilterChange = (checked: boolean): void => {
    const params = { ...tableFilters, [ItemCostsByClassCodeFilter.OnlyShowZeroStdCosts]: checked }
    void filter(params)
    void itemCstClsCdListFilters.handleLastUsedFilter(params)
  }

  const handleOnlyShowDiffCostsFilterChange = (checked: boolean): void => {
    const params = { ...tableFilters, [ItemCostsByClassCodeFilter.OnlyShowDiffCosts]: checked }
    void filter(params)
    void itemCstClsCdListFilters.handleLastUsedFilter(params)
  }

  const handleShowInactiveFilterChange = (checked: boolean): void => {
    const params = { ...tableFilters, [ItemCostsByClassCodeFilter.ShowInactive]: checked }
    void filter(params)
    void itemCstClsCdListFilters.handleLastUsedFilter(params)
  }

  const handleOnlyShowZeroLastCostsFilterChange = (checked: boolean): void => {
    const params = { ...tableFilters, [ItemCostsByClassCodeFilter.OnlyShowZeroLastCosts]: checked }
    void filter(params)
    void itemCstClsCdListFilters.handleLastUsedFilter(params)
  }

  return (
    <div className={cls('xt-list-page-container', styles.listContent)}>
      <XtDialog
        className="xt-modal-details-content"
        open={summaryListDialogOpen}
        fullScreen={true}
        animation={XtDialogAnimation.FadeAnimation}
      >
        <SummaryList className="xt-item-costs-summary-dialog-content" onClose={closeSummaryListDialog} itemNumber={summaryListData} />
      </XtDialog>
      <div className={styles.listControls}>
        <div className="xt-page-header">
          <h1 className="xt-page-title">Item Costs by Class Code</h1>
        </div>
        <div className={styles.filterSection}>
          <XtPageFilter
            state={itemCstClsCdListFilters}
            defaultFilterValues={defaultFilterState}
            resolveFilters={() => resolveFilters(DocumentsUtilsService)}
            filter={filter}
            tableFilters={tableFilters}
          />
          <XtResponsiveButton
            showIconOnDesktopMode={false}
            label="Print"
            icon={SvgIconIds.PRINT}
            disabled={loading || !data.length}
            onClick={print}
          />
        </div>
        <div className={styles.byClassCodeListCheckboxes}>
          <XtCheckbox
            className={styles.byClassCodeListCheckbox}
            value={Boolean(tableFilters[ItemCostsByClassCodeFilter.OnlyShowZeroStdCosts])}
            label={ItemCostsByClassCodeLabel.OnlyShowZeroStdCosts}
            disabled={loading}
            onChange={handleOnlyShowZeroStdCostsFilterChange}
          />
          <XtCheckbox
            className={styles.byClassCodeListCheckbox}
            value={Boolean(tableFilters[ItemCostsByClassCodeFilter.OnlyShowDiffCosts])}
            label={ItemCostsByClassCodeLabel.OnlyShowDiffCosts}
            disabled={loading}
            onChange={handleOnlyShowDiffCostsFilterChange}
          />
          <XtCheckbox
            className={styles.byClassCodeListCheckbox}
            value={Boolean(tableFilters[ItemCostsByClassCodeFilter.ShowInactive])}
            label={ItemCostsByClassCodeLabel.ShowInactive}
            disabled={loading}
            onChange={handleShowInactiveFilterChange}
          />
          <XtCheckbox
            className={styles.byClassCodeListCheckbox}
            value={Boolean(tableFilters[ItemCostsByClassCodeFilter.OnlyShowZeroLastCosts])}
            label={ItemCostsByClassCodeLabel.OnlyShowZeroLastCosts}
            disabled={loading}
            onChange={handleOnlyShowZeroLastCostsFilterChange}
          />
        </div>
      </div>
      <XtList
        onRowClick={handleRowClick}
        actions={byClassCodeActions}
        onAction={handleAction}
        isMobile={isMobile}
        pagination={pagination}
        loading={loading}
        data={data}
        columns={classCodeColumns}
        sortOptions={sortOptions}
        onColumnHeaderClick={sort}
      />
    </div>
  )
}
