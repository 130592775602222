import * as React from 'react'
import { FC, useEffect, useMemo } from 'react'
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators'
import { useProductsModule } from 'products/products-module-hook'
import { XtInfoValues } from 'components/xt-info-values/xt-info-values'
import { numberConverter } from 'common/utils/utils'
import { useRunningAvailabilityModule } from 'running-availability/running-availability-module.hook'
import { useXtForm } from 'common/hooks/form/form'
import { FormXtAutocomplete } from 'common/utils/form/form.components'
import { globalConstants } from 'common/constants'
import * as styles from './running-availability-list-form.module.scss'
import {
  IRunningAvailabilityForm,
  RunningAvailabilityFormLabel,
  IRunningAvailabilityFormParams,
  RunningAvailabilityFormField,
} from './running-availability-list-form.types'

import { defineFormData, isFilterFormDataFilled } from './running-availability-list-form.utils'

export const RunningAvailabilityForm: FC<IRunningAvailabilityFormParams> = ({ onFilterChange, disabled, itemNumber, site }) => {
  const { FormXtItemNumber, ItemsUtilsService } = useProductsModule()
  const { RunningAvailabilityUtilsService } = useRunningAvailabilityModule()

  const { control, watch, reset, formValueChanges$ } = useXtForm<IRunningAvailabilityForm>({ mode: 'onBlur' })

  const itemNumberValue = watch(RunningAvailabilityFormField.ItemNumber)
  const siteValue = watch(RunningAvailabilityFormField.Site)

  const siteFilters = useMemo(() => ({ itemNumber: itemNumberValue?.item_number, showDetail: true }), [itemNumberValue?.item_number])

  const siteValues = useMemo(
    () => [
      {
        label: 'Available QOH',
        value: numberConverter(siteValue?.available_qty, 2),
      },
      {
        label: 'Reorder Level',
        value: numberConverter(siteValue?.reorder_level, 2),
      },

      {
        label: 'Order Up To Qty',
        value: numberConverter(siteValue?.order_up_to, 2),
      },
      {
        label: 'Order Multiple',
        value: numberConverter(siteValue?.order_multiple, 2),
      },
      {
        label: 'Netable QOH',
        value: numberConverter(siteValue?.total_on_hand_qty, 2),
      },
    ],
    [siteValue]
  )

  useEffect(() => {
    const init = async (): Promise<void> => {
      if (!itemNumber || !site) {
        return
      }
      const { site: siteOption, item: itemOption } = await RunningAvailabilityUtilsService.requestItemAndSiteData(itemNumber, site)
      reset(defineFormData(itemOption, siteOption))
    }

    void init()
  }, [RunningAvailabilityUtilsService, itemNumber, reset, site])

  useEffect(() => {
    const sub = formValueChanges$
      .pipe(
        debounceTime(globalConstants.formChangeDebounce),
        distinctUntilChanged(),
        switchMap(async (formData) => {
          if (isFilterFormDataFilled(formData)) {
            await onFilterChange(formData)
          }
        })
      )
      .subscribe()

    return () => sub.unsubscribe()
  }, [formValueChanges$, onFilterChange])

  return (
    <div className={styles.runningAvailabilityForm}>
      <FormXtItemNumber
        name={RunningAvailabilityFormField.ItemNumber}
        control={control}
        className={styles.itemNumber}
        isEditMode={!itemNumber}
        label={RunningAvailabilityFormLabel.ItemNumber}
        loadOptions={ItemsUtilsService.loadItemOptions}
      />
      <div className={styles.siteSection}>
        <FormXtAutocomplete
          name={RunningAvailabilityFormField.Site}
          disabled={disabled || !itemNumberValue}
          control={control}
          filters={siteFilters}
          label={RunningAvailabilityFormLabel.Site}
          loadOptions={ItemsUtilsService.loadSiteOptions}
        />
        <XtInfoValues hidden={!siteValue} values={siteValues} classes={{ values: styles.siteValues, content: styles.siteValue }} />
      </div>
    </div>
  )
}
