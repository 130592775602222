interface IThroughputData {
  per: number
  runTime: number
  uomRatio: number
}

export const throughputFormula = (data: IThroughputData, perMinute?: boolean): number | string => {
  const { per, runTime, uomRatio } = data
  const perNum = Number(per)
  const runTimeNum = Number(runTime)
  const uomRatioNum = Number(uomRatio)

  if (perNum > 0 && runTimeNum > 0 && uomRatioNum > 0) {
    if (perMinute) {
      // per Minute Formula: per / Production/Inventory Ratio / Run Time (min.)
      return (perNum / uomRatioNum / runTimeNum).toFixed(2)
    }
    // (min.) per Formula: Production/Inventory Ratio * Run Time (min.) / per
    return ((uomRatioNum * runTimeNum) / perNum).toFixed(2)
  }
  return '0.00'
}
