import { ControlMethodOption, IItemSite, ItemSiteDistributionResponse, TransactionType } from 'products/items/items.types'
import { IItemSitesService } from 'products/items/item-sites.service'
import { IItemsService } from 'products/items/items.service'
import { convertItemSiteDistributionDate } from 'products/items/items.utils'
import { ILotSerialNumberHookResetResponse } from './lot-serial-number-hook.types'
import { convertToLotSerialOption } from '../../lot-serial.utils'

/**
 * Handles negative quantity assignment
 * @param ItemSitesService
 * @param itemSite
 * @param locations
 */
export async function handleNegativeAssignment(
  ItemSitesService: IItemSitesService,
  itemSite: IItemSite,
  locations?: ItemSiteDistributionResponse<true>[]
): Promise<ILotSerialNumberHookResetResponse> {
  const { item_number, site } = itemSite

  let distributions = locations

  if (!distributions) {
    distributions = await ItemSitesService.getItemSiteDistributions(item_number, site, TransactionType.MiscAdjustment, true)
  }

  const options = distributions.map(({ lotserial, location_name, expiry_date, warranty_date, qty_before }) => ({
    id: lotserial,
    label: lotserial,
    location_name,
    lotserial,
    warranty_date: convertItemSiteDistributionDate(warranty_date),
    expiry_date: convertItemSiteDistributionDate(expiry_date),
    qty_before,
  }))

  return {
    options,
    defaultNumbers: null,
  }
}

/**
 * Handles positive quantity assignment in manual mode
 * @param ItemsService
 * @param itemSite
 */
export async function handleManualAssignment(ItemsService: IItemsService, itemSite: IItemSite): Promise<ILotSerialNumberHookResetResponse> {
  const { item_number, site } = itemSite

  const lotSerialNumbers = await ItemsService.getLotSerialNumbers({
    item_number,
    site: site,
    transaction_type: TransactionType.MiscAdjustment,
  })
  const numberOptions = lotSerialNumbers.map(convertToLotSerialOption)

  return {
    options: numberOptions,
    defaultNumbers: null,
  }
}

/**
 * Handles positive quantity assignment in automatic mode
 * @param ItemsService
 * @param itemSite
 * @param qtyToAssign
 */
export async function handleAutomaticAssignment(
  ItemsService: IItemsService,
  itemSite: IItemSite,
  qtyToAssign: number
): Promise<ILotSerialNumberHookResetResponse> {
  const { item_number, site, control_method } = itemSite

  const qty = control_method === ControlMethodOption.SerialNumber ? qtyToAssign : undefined

  const lotSerialNumbers = await ItemsService.getLotSerialNumbers({
    item_number,
    site: site,
    transaction_type: TransactionType.MiscAdjustment,
    qty,
  })

  const defaultNumbers = lotSerialNumbers.map(convertToLotSerialOption)

  return {
    options: [],
    defaultNumbers,
  }
}
