import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { IDocumentsService } from 'documents/documents.types'
import { documentsServiceToken } from 'documents/documents.constants'
import { Scope } from 'core/injector/injector.types'
import { DocumentsModule } from 'documents/documents.module'
import { CoreModule } from 'core/core.module'
import { UsersModule } from 'users/users.module'
import { IUsersService } from 'users/users.service'
import { usersServiceToken } from 'users/users.constants'
import { setupUsersModule, setupUsersUtilsServiceToken } from './users.constants'
import { IUsersUtilsService } from './users-utils.service'

export type SetupUsersModuleDefinition = typeof import('./users.exports')

export interface ISetupUsersModuleServices {
  UsersUtilsService: IUsersUtilsService
}

function usersModuleInitializer({ UsersUtilsService }: SetupUsersModuleDefinition): void {
  Injector.register<IUsersUtilsService>(
    setupUsersUtilsServiceToken,
    (injector) => {
      const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
      const usersService = injector.resolve<IUsersService>(usersServiceToken)

      return new UsersUtilsService(documentsService, usersService)
    },
    Scope.Singleton
  )
}

export const SetupUsersModule: ILazyModule<SetupUsersModuleDefinition> = {
  name: setupUsersModule,
  resolver: () => import('./users.exports'),
  initializer: usersModuleInitializer,
  dependencies: [CoreModule, UsersModule, DocumentsModule],
}
