import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { merge, Observable, Subject } from 'rxjs'
import { Chip, CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab'
import { map } from 'rxjs/operators'
import { useProspectDialogState } from 'companies/prospects/prospect-details/prospects-details-hook/prospects-detais.hook'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { useCustomerDialogState } from 'companies/customers/customer-details/customer-details-hook/customer-detais.hook'
import { cls } from 'common/utils/utils'
import { CRMAccountRole } from 'documents/documents.types'
import { XtMode } from 'common/common.types'
import { UserPermission } from 'auth/auth.types'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { useTasksModule } from 'tasks/tasks-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useContactsModule } from 'contacts/contacts-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import {
  AutocompleteChangeAction,
  ConfirmationDialogAction,
  IActiveRolesSection,
  IConfirmationDialogState,
  IRolesOptionsState,
  ITagComponentProps,
  OptionRole,
} from './active-roles-section.types'
import { convertOptions, findRole, isContactEvent, isDocumentEvent, isTaskEvent } from './active-roles-section.utils'
import * as styles from './active-roles.module.scss'
import { confirmationDialogActionMessage, initialRolesOptionsState } from './active-roles-section.constants'
import { useProspectsModule } from '../../../prospects/prospects-module-hook'
import { useCustomersModule } from '../../../customers/customers-module-hook'

export const ActiveRolesSection: FC<IActiveRolesSection> = ({ roles, accountNumber, accountName, disabled, onChange }) => {
  const { CustomersService, CustomerDetails } = useCustomersModule()
  const { ProspectDetails, ProspectsService } = useProspectsModule()
  const { ContactsService } = useContactsModule()
  const { DocumentsService } = useDocumentsModule()
  const { TasksService } = useTasksModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { PermissionsService } = useAuthModule()

  const [canViewCustomer, canEditCustomer, canViewProspect, canEditProspect] = PermissionsService.hasPermissions([
    UserPermission.ViewCustomerMasters,
    UserPermission.MaintainCustomerMasters,
    UserPermission.ViewProspectMasters,
    UserPermission.MaintainProspectMasters,
  ])

  const refreshDataSubject = useRef<Subject<void>>(new Subject())
  const refreshData$ = useRef<Observable<void>>(refreshDataSubject.current.asObservable())
  const [rolesState, setRolesState] = useState<IRolesOptionsState>(initialRolesOptionsState)
  const { isOpenCustomerDialog, customerDialogMode, onCloseCustomerDialog, onOpenCustomerDialog } = useCustomerDialogState()
  const { isOpenProspectDialog, prospectDialogMode, onCloseProspectDialog, onOpenProspectDialog } = useProspectDialogState()
  const {
    open: confirmationDialogOpen,
    openDialog: openConfirmationDialog,
    closeDialog: closeConfirmationDialog,
    itemId: confirmationItem,
  } = useConfirmationDialog<IConfirmationDialogState>()

  useEffect(() => {
    setRolesState({
      ...convertOptions(roles, { canEditProspect, canEditCustomer, canViewProspect, canViewCustomer }, disabled),
      loading: false,
    })
  }, [roles, canEditProspect, canEditCustomer, canViewProspect, canViewCustomer, disabled])

  useEffect(() => {
    if (isOpenCustomerDialog || isOpenProspectDialog) {
      const sub = merge(DocumentsService.documentUpdate$, ContactsService.contactUpdate$, TasksService.taskUpdate$)
        .pipe(
          map((event) => ({
            accountUpdate: false,
            documentsUpdate: isDocumentEvent(event) ? event : null,
            tasksUpdate: isTaskEvent(event) ? event : null,
            contactsUpdate: isContactEvent(event) ? event : null,
          }))
        )
        .subscribe(onChange)

      return () => sub.unsubscribe()
    }
    return () => {}
  }, [
    ContactsService.contactUpdate$,
    DocumentsService.documentUpdate$,
    TasksService.taskUpdate$,
    isOpenCustomerDialog,
    isOpenProspectDialog,
    onChange,
  ])

  const selectOption = useCallback(
    (option: OptionRole, newOptions: OptionRole[]) => {
      const customer = Boolean(findRole(newOptions, CRMAccountRole.Customer))
      const prospect = Boolean(findRole(newOptions, CRMAccountRole.Prospect))

      // if both customer and prospect are present in the newOptions, then we propose converting the prospect into a customer
      if (customer && prospect) {
        openConfirmationDialog({
          message: confirmationDialogActionMessage.convertProspect,
          action: ConfirmationDialogAction.ConvertProspect,
        })
        return
      }

      if (!customer || !prospect) {
        switch (option.name) {
          case CRMAccountRole.Customer: {
            openConfirmationDialog({
              message: confirmationDialogActionMessage.createCustomer,
              action: ConfirmationDialogAction.CreateCustomer,
            })
            break
          }
          case CRMAccountRole.Prospect: {
            openConfirmationDialog({
              message: confirmationDialogActionMessage.createProspect,
              action: ConfirmationDialogAction.CreateProspect,
            })
            break
          }
          default: {
            break
          }
        }
      }
    },
    [openConfirmationDialog]
  )

  const removeOption = useCallback(
    ({ name, canDelete }: OptionRole) => {
      if (name === CRMAccountRole.Customer && canDelete) {
        openConfirmationDialog({
          message: confirmationDialogActionMessage.deleteCustomer(accountNumber),
          action: ConfirmationDialogAction.DeleteCustomer,
        })
      }
      if (name === CRMAccountRole.Prospect && canDelete) {
        openConfirmationDialog({
          message: confirmationDialogActionMessage.deleteProspect(accountNumber),
          action: ConfirmationDialogAction.DeleteProspect,
        })
      }
    },
    [openConfirmationDialog, accountNumber]
  )

  const handleAutocompleteAction = useCallback(
    (_, newOptions: OptionRole[], action: AutocompleteChangeReason, selectedOption: AutocompleteChangeDetails<OptionRole> | undefined) => {
      if (!selectedOption?.option) {
        return
      }
      switch (action) {
        case AutocompleteChangeAction.RemoveOption: {
          removeOption(selectedOption.option)
          break
        }
        case AutocompleteChangeAction.SelectOption: {
          selectOption(selectedOption.option, newOptions)
          break
        }
        default: {
          break
        }
      }
    },
    [removeOption, selectOption]
  )

  const handleTagAction = useCallback(
    ({ name, canOpen, modeToOpen }: OptionRole) => {
      if (name === CRMAccountRole.Customer && canOpen) {
        onOpenCustomerDialog(accountNumber, modeToOpen)
      }
      if (name === CRMAccountRole.Prospect && canOpen) {
        onOpenProspectDialog(accountNumber, modeToOpen)
      }
      refreshDataSubject.current.next()
    },
    [accountNumber, onOpenProspectDialog, onOpenCustomerDialog]
  )

  const handleConfirmationDialog = useCallback<() => Promise<void>>(async () => {
    if (!confirmationItem) {
      return
    }
    closeConfirmationDialog()
    try {
      setRolesState((prev) => ({ ...prev, loading: true }))
      switch (confirmationItem.action) {
        case ConfirmationDialogAction.DeleteCustomer: {
          const message = await CustomersService.delete(accountNumber)
          onChange({ accountUpdate: true, documentsUpdate: null, contactsUpdate: null, tasksUpdate: null })
          ToastService.showSuccess(message)
          break
        }
        case ConfirmationDialogAction.DeleteProspect: {
          const message = await ProspectsService.delete(accountNumber)
          onChange({ accountUpdate: true, documentsUpdate: null, contactsUpdate: null, tasksUpdate: null })
          ToastService.showSuccess(message)
          break
        }
        case ConfirmationDialogAction.ConvertProspect: {
          const message = await ProspectsService.convertToCustomer(accountNumber)
          onChange({ accountUpdate: true, documentsUpdate: null, contactsUpdate: null, tasksUpdate: null })
          onOpenCustomerDialog(accountNumber, XtMode.Edit)
          ToastService.showSuccess(message)
          break
        }
        case ConfirmationDialogAction.CreateCustomer:
          onOpenCustomerDialog(accountNumber, XtMode.New)
          break
        case ConfirmationDialogAction.CreateProspect:
          onOpenProspectDialog(accountNumber, XtMode.New)
          break
        default:
          break
      }
    } catch (error) {
      ErrorHandler.handleError(error)
    } finally {
      setRolesState((prev) => ({ ...prev, loading: false }))
    }
  }, [
    confirmationItem,
    closeConfirmationDialog,
    onOpenCustomerDialog,
    accountNumber,
    onOpenProspectDialog,
    CustomersService,
    onChange,
    ToastService,
    ProspectsService,
    ErrorHandler,
  ])

  const handleDialogSubmit = useCallback<VoidFunction>(
    () => onChange({ accountUpdate: true, documentsUpdate: null, contactsUpdate: null, tasksUpdate: null }),
    [onChange]
  )

  return (
    <div>
      {confirmationItem && (
        <XtConfirmationDialog
          open={confirmationDialogOpen}
          message={confirmationItem.message}
          confirmationButtonLabel="Yes"
          cancelButtonLabel="No"
          onConfirm={handleConfirmationDialog}
          onClose={closeConfirmationDialog}
        />
      )}
      <XtDialog
        className="xt-modal-details-content"
        open={isOpenCustomerDialog}
        fullScreen={false}
        animation={XtDialogAnimation.SlideAnimation}
      >
        <CustomerDetails
          mode={customerDialogMode}
          customerNumber={accountNumber}
          customerName={accountName}
          onCancel={onCloseCustomerDialog}
          onSubmit={handleDialogSubmit}
          refreshDataObservable={refreshData$.current}
        />
      </XtDialog>
      <XtDialog
        className="xt-modal-details-content"
        open={isOpenProspectDialog}
        fullScreen={false}
        animation={XtDialogAnimation.SlideAnimation}
      >
        <ProspectDetails
          mode={prospectDialogMode}
          prospectNumber={accountNumber}
          prospectName={accountName}
          onCancel={onCloseProspectDialog}
          onSubmit={handleDialogSubmit}
          refreshDataObservable={refreshData$.current}
        />
      </XtDialog>
      <Autocomplete
        multiple
        value={rolesState.selectedOptions}
        onChange={handleAutocompleteAction}
        getOptionDisabled={(option) => !option.canAdd || disabled}
        filterSelectedOptions
        disableClearable
        options={rolesState.listOptions}
        disabled={disabled || rolesState.loading}
        getOptionLabel={(option) => (option && option.label ? option.label : '')}
        className={cls('MuiFormField', styles.activeRolesFiled)}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            const tagProps = getTagProps({ index }) as ITagComponentProps
            const onDeleteTag = disabled || !option.canDelete ? undefined : tagProps.onDelete
            return (
              <Chip
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...tagProps}
                key={option.label}
                onClick={() => handleTagAction(option)}
                label={option.label}
                disabled={rolesState.loading || !option.canOpen}
                onDelete={onDeleteTag}
              />
            )
          })
        }
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {rolesState.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label="Active Roles"
            variant="outlined"
          />
        )}
      />
    </div>
  )
}
