import React, { FC, useCallback, useEffect } from 'react'
import { useCoreModule } from 'core/core-module-hook'
import { useXtForm } from 'common/hooks/form/form'
import { FormCheckboxLabel, FormSelectField } from 'common/utils/form/form.components'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { convertMode } from 'common/utils/mode.utils'
import { useProductsModule } from 'products/products-module-hook'
import { useXtSelect } from 'components/controls/xt-select/xt-select-hook'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import {
  ISitesDetailsDialogPlanning,
  SitesDetailsDialogPlanningForm,
  SitesDetailsDialogPlanningFormField,
  SitesDetailsDialogPlanningFormFieldLabel,
} from './sites-details-dialog-planning.types'
import {
  sitesDetailsDialogPlanningFormKey,
  suppliedFromSiteNoneOption,
  typicalMaxValue,
  typicalMinValue,
} from './sites-details-dialog-planning.consts'
import * as styles from './sites-details-dialog-planning.module.scss'
import { defineDefaultPlanningFormData, defineItemPlanningSystemOptions } from './sites-details-dialog-planning.utils'

export const SitesDetailsDialogPlanning: FC<ISitesDetailsDialogPlanning> = ({ mode, register, item, itemSite }) => {
  const { ErrorHandler } = useCoreModule()
  const { isViewMode } = convertMode(mode)
  const {
    ItemsUtilsService: { loadSiteOptions },
  } = useProductsModule()

  const { reset, control, formChanges$, getValues, formState, setValue, watch } = useXtForm<SitesDetailsDialogPlanningForm>({
    mode: 'onBlur',
  })

  const { options: sites } = useXtSelect(async () => {
    const data: IXtAutocompleteOption[] = [
      {
        id: suppliedFromSiteNoneOption,
        label: suppliedFromSiteNoneOption,
        disabled: false,
      },
    ]
    if (!item) {
      return data
    }

    const { data: fetchedSites } = await loadSiteOptions(undefined, undefined, undefined, {
      itemNumber: item.item_number,
      showDetails: true,
    })
    const filteredSites = fetchedSites.filter((s) => !s.is_transit_site)
    return data.concat(filteredSites.map((s) => ({ id: s.site, label: s.label })))
  })

  const enforceOrderParametersEnabled = watch(SitesDetailsDialogPlanningFormField.EnforceOrderParameters)
  const createPlannedTransfersOrders = watch(SitesDetailsDialogPlanningFormField.CreatePlannedTransfersOrders)

  useEffect(() => {
    const init = async (): Promise<void> => {
      try {
        reset(defineDefaultPlanningFormData(itemSite))
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }

    void init()
  }, [ErrorHandler, reset, itemSite])

  useEffect(() => {
    register(sitesDetailsDialogPlanningFormKey, formChanges$, null, { data: getValues(), state: formState }, reset)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formChanges$, register, getValues])

  const onCreatePlannedTransfersOrdersChange = useCallback(
    (checked: boolean) => {
      if (!checked) {
        setValue(SitesDetailsDialogPlanningFormField.SuppliedFromSite, null)
      }
      setValue(SitesDetailsDialogPlanningFormField.CreatePlannedTransfersOrders, checked)
    },
    [setValue]
  )

  return (
    <div className={styles.planningContainer}>
      <div>
        <div className={styles.planningContainerLeft}>
          <h1 className="xt-page-title">Scheduling</h1>
          <FormSelectField
            control={control}
            options={defineItemPlanningSystemOptions}
            name={SitesDetailsDialogPlanningFormField.PlanningSystem}
            label={SitesDetailsDialogPlanningFormFieldLabel.PlanningSystem}
            disabled={isViewMode}
          />
          <DecimalFormField
            control={control}
            name={SitesDetailsDialogPlanningFormField.SafetyStock}
            label={SitesDetailsDialogPlanningFormFieldLabel.SafetyStock}
            minValue={typicalMinValue}
            disabled={isViewMode}
          />
          <DecimalFormField
            control={control}
            name={SitesDetailsDialogPlanningFormField.GroupMpsMrpOrders}
            label={SitesDetailsDialogPlanningFormFieldLabel.GroupMpsMrpOrders}
            minValue={typicalMinValue}
            maxValue={typicalMaxValue}
            disabled={isViewMode}
          />
          <DecimalFormField
            control={control}
            name={SitesDetailsDialogPlanningFormField.LeadTime}
            label={SitesDetailsDialogPlanningFormFieldLabel.LeadTime}
            minValue={typicalMinValue}
            maxValue={typicalMaxValue}
            disabled={isViewMode}
          />
          <DecimalFormField
            control={control}
            name={SitesDetailsDialogPlanningFormField.MpsTimeFence}
            label={SitesDetailsDialogPlanningFormFieldLabel.MpsTimeFence}
            minValue={typicalMinValue}
            maxValue={typicalMaxValue}
            disabled={isViewMode}
          />
          <FormCheckboxLabel
            control={control}
            name={SitesDetailsDialogPlanningFormField.FirstGroup}
            label={SitesDetailsDialogPlanningFormFieldLabel.FirstGroup}
            disabled={isViewMode}
          />
          <FormCheckboxLabel
            control={control}
            name={SitesDetailsDialogPlanningFormField.CreatePlannedTransfersOrders}
            label={SitesDetailsDialogPlanningFormFieldLabel.CreatePlannedTransfersOrders}
            onChange={onCreatePlannedTransfersOrdersChange}
            disabled={isViewMode}
          />
          <FormSelectField
            control={control}
            options={sites}
            name={SitesDetailsDialogPlanningFormField.SuppliedFromSite}
            label={SitesDetailsDialogPlanningFormFieldLabel.SuppliedFromSite}
            disabled={isViewMode || !createPlannedTransfersOrders}
          />
        </div>
      </div>
      <div>
        <div className={styles.planningContainerRight}>
          <FormCheckboxLabel
            control={control}
            name={SitesDetailsDialogPlanningFormField.EnforceOrderParameters}
            label={SitesDetailsDialogPlanningFormFieldLabel.EnforceOrderParametersEnabled}
            disabled={isViewMode}
          />
          <DecimalFormField
            control={control}
            name={SitesDetailsDialogPlanningFormField.ReorderLevel}
            label={SitesDetailsDialogPlanningFormFieldLabel.ReorderLevel}
            disabled={isViewMode || !enforceOrderParametersEnabled}
          />
          <DecimalFormField
            control={control}
            name={SitesDetailsDialogPlanningFormField.OrderUpTo}
            label={SitesDetailsDialogPlanningFormFieldLabel.OrderUpTo}
            disabled={isViewMode || !enforceOrderParametersEnabled}
          />
          <DecimalFormField
            control={control}
            name={SitesDetailsDialogPlanningFormField.MinimumOrder}
            label={SitesDetailsDialogPlanningFormFieldLabel.MinimumOrder}
            disabled={isViewMode || !enforceOrderParametersEnabled}
          />
          <DecimalFormField
            control={control}
            name={SitesDetailsDialogPlanningFormField.MaximumOrder}
            label={SitesDetailsDialogPlanningFormFieldLabel.MaximumOrder}
            disabled={isViewMode || !enforceOrderParametersEnabled}
          />
          <DecimalFormField
            control={control}
            name={SitesDetailsDialogPlanningFormField.OrderMultiple}
            label={SitesDetailsDialogPlanningFormFieldLabel.OrderMultiple}
            disabled={isViewMode || !enforceOrderParametersEnabled}
          />
          <FormCheckboxLabel
            control={control}
            name={SitesDetailsDialogPlanningFormField.EnforceOnManualOrders}
            label={SitesDetailsDialogPlanningFormFieldLabel.EnforceOnManualOrders}
            disabled={isViewMode || !enforceOrderParametersEnabled}
          />
        </div>
      </div>
    </div>
  )
}
