import { merge, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { SingleFormStateChanges, MultipleFormMap, MultipleFormStateMap, FormChanges } from './multiple-form-manager.types'

export function buildMultipleFormObservable<FormType extends { [Key in keyof FormType]: FormType[Key] }>(
  multipleForm: MultipleFormMap<FormType>
): Observable<SingleFormStateChanges<FormType>> {
  const multipleFormObservable = Array.from(multipleForm.entries()).map(([key, { formChanges$ }]) =>
    formChanges$.pipe(map((formChanges) => ({ formChanges, key })))
  )

  return merge<SingleFormStateChanges<FormType>>(...multipleFormObservable)
}

export async function validateNestedForms<FormType extends { [Key in keyof FormType]: FormType[Key] }>(
  multipleFormsState: MultipleFormMap<FormType>
): Promise<boolean> {
  const buildMultipleValidate = Array.from(multipleFormsState.values()).map(({ validate }) =>
    validate !== null ? validate() : Promise.resolve(true)
  )
  const validState = await Promise.all(buildMultipleValidate)

  return validState.every(Boolean)
}
export function convertCombinedFormState<FormType>(multipleFormsState: MultipleFormStateMap<FormType>): FormChanges<FormType> {
  const multipleForm = {
    data: {} as FormType,
    state: {
      isDirty: false,
      isValid: true,
      fieldValidatorsShown: false,
      touched: false,
    },
  }

  multipleFormsState.forEach((form, key) => {
    multipleForm.data[key] = form.data

    if (form.state.touched) {
      multipleForm.state.touched = true
    }
    if (form.state.fieldValidatorsShown) {
      multipleForm.state.fieldValidatorsShown = true
    }
    if (form.state.isDirty) {
      multipleForm.state.isDirty = true
    }
    if (!form.state.isValid) {
      multipleForm.state.isValid = false
    }
  })

  return multipleForm
}

export function convertCombinedFormStateToValue<FormType extends { [Key in keyof FormType]: FormType[Key] }>(
  multipleFormsState: MultipleFormStateMap<FormType>
): FormType {
  const formValues = {} as FormType
  multipleFormsState.forEach(({ data }, key) => {
    formValues[key] = data
  })
  return formValues
}
