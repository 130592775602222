import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { numberConverter } from 'common/utils/utils'
import { dateConverter } from 'common/utils/date.utils'
import { booleanColumnConverter } from 'common/utils/table.utils'
import { IItemCostsSummaryWithId } from '../item-costs.types'
import { ISummaryFilters } from './summary-list.types'

export const initialSummaryFilters: ISummaryFilters = { itemNumber: null }

export const summaryColumns: IXtTableColumn<IItemCostsSummaryWithId>[] = [
  {
    id: 'element',
    field: 'element',
    headerName: 'Element',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'lowlevel',
    field: 'lowlevel',
    headerName: 'Lower',
    flex: '1 0 100px',
    width: 100,
    converter: booleanColumnConverter,
  },
  {
    id: 'std_cost',
    field: 'std_cost',
    headerName: 'Std. Cost',
    flex: '1 0 160px',
    width: 160,
    converter: (value) => numberConverter(value, 4),
  },

  {
    id: 'posted',
    field: 'posted',
    headerName: 'Posted',
    flex: '1 0 140px',
    width: 140,
    converter: dateConverter,
  },
  {
    id: 'act_cost',
    field: 'act_cost',
    headerName: 'Act. Cost',
    flex: '1 0 160px',
    width: 160,
    converter: (value) => numberConverter(value, 4),
  },

  {
    id: 'updated',
    field: 'updated',
    headerName: 'Updated',
    flex: '1 0 140px',
    width: 140,
    converter: dateConverter,
  },
]

export const summaryListPageTitle = 'xTuple - Item Costs Summary'
