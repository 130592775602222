import * as React from 'react'
import { DataGridPro, GridColumns, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { useLayoutEffect } from 'react'
import { ITableRowWithParentId } from 'components/table/table.types'
import { useTable } from 'common/hooks/useTable'
import { IRecursivelyDataGrid } from '../nested-xt-table.types'
import { useMultiSortModel } from '../nested-xt-table.utils'
import { recursivelyDataGridStyles, recursivelyDataGridComponents, defaultHeightSmall } from './recursively-table.constants'
import * as styles from '../nested-xt-table.module.scss'

export function RecursivelyTable<TData extends ITableRowWithParentId, TColumns extends GridColumns<TData>>({
  columns,
  params,
  loadRowsFn,
  setHeight,
  getIsHasChildrenForNestedRows,
}: IRecursivelyDataGrid<TData, TColumns>): JSX.Element {
  const ref = useGridApiRef()

  const {
    state: { data, loading },
    sort,
  } = useTable(params, loadRowsFn)
  const { sortModel, handleSortModelChange } = useMultiSortModel([], sort)

  useLayoutEffect(() => {
    if (setHeight) {
      setHeight((prev: number) => {
        if (ref?.current && !!data?.length) {
          return ref.current.state.density.rowHeight * data.length + ref.current.state.density.headerHeight
        }
        return prev
      })
    }
  }, [ref, setHeight, data])

  const getDetailPanelContent = (childParams: GridRowParams): JSX.Element | undefined =>
    getIsHasChildrenForNestedRows && getIsHasChildrenForNestedRows(childParams) ? (
      <RecursivelyTable
        params={childParams}
        columns={columns}
        loadRowsFn={loadRowsFn}
        getIsHasChildrenForNestedRows={getIsHasChildrenForNestedRows}
      />
    ) : undefined

  return (
    <DataGridPro
      classes={{ virtualScroller: styles.xtNestedScrollbarStyle }}
      apiRef={ref}
      columns={columns}
      rows={data}
      loading={loading}
      disableColumnMenu
      sortingMode="server"
      sx={recursivelyDataGridStyles}
      getDetailPanelContent={getDetailPanelContent}
      getDetailPanelHeight={() => defaultHeightSmall}
      components={recursivelyDataGridComponents}
      sortModel={sortModel}
      onColumnHeaderClick={handleSortModelChange}
      hideFooter
    />
  )
}
