import * as yup from 'yup'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { dateYupValidator } from 'common/utils/date.utils'
import { FilterType, IFilter, IFilterFormState } from './filter.types'

export function makeValidationSchema<TOption extends IXtAutocompleteOption, TFilters extends Array<IFilter<TOption>>>(
  filtersForSchema: TFilters
): yup.ObjectSchemaDefinition<IFilterFormState> {
  const result: Record<string, unknown> = {}
  filtersForSchema
    .filter((f) => f.type === FilterType.Date)
    .forEach((filter) => {
      result[filter.fieldName] = dateYupValidator.notRequired()
    })

  return result as yup.ObjectSchemaDefinition<IFilterFormState>
}
