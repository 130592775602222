import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { numberConverter } from 'common/utils/utils'
import { IQuantityOnHandListRow } from './quantity-on-hand-list.types'

export const quantityOnHandColumns: IXtTableColumn<IQuantityOnHandListRow>[] = [
  {
    id: 'site',
    field: 'site',
    headerName: 'Site',
    flex: '1 0 150px',
    width: 150,
  },
  {
    id: 'item_number',
    field: 'item_number',
    headerName: 'Item Number',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'item_description1',
    field: 'item_description1',
    headerName: 'Description',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'class_code',
    field: 'class_code',
    headerName: 'Class Code',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'cost_category',
    field: 'cost_category',
    headerName: 'Cost Category',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'abc_class',
    field: 'abc_class',
    headerName: 'ABC Class',
    flex: '1 0 130px',
    width: 130,
  },
  {
    id: 'uom',
    field: 'uom',
    headerName: 'UOM',
    flex: '1 0 130px',
    width: 130,
  },
  {
    id: 'default_location',
    field: 'default_location',
    headerName: 'Default Location',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'reorder_level',
    field: 'reorder_level',
    headerName: 'Reorder Lvl',
    flex: '1 0 130px',
    width: 130,
    converter: numberConverter,
  },
  {
    id: 'quantity_on_hand',
    field: 'quantity_on_hand',
    headerName: 'QoH',
    flex: '1 0 130px',
    width: 130,
    converter: numberConverter,
  },
  {
    id: 'usable_qty',
    field: 'usable_qty',
    headerName: 'Usable',
    flex: '1 0 130px',
    width: 130,
    converter: numberConverter,
  },
  {
    id: 'nonusable_qty',
    field: 'nonusable_qty',
    headerName: 'NonUsable',
    flex: '1 0 140px',
    width: 140,
    converter: numberConverter,
  },
  {
    id: 'netable',
    field: 'netable',
    headerName: 'Netable',
    flex: '1 0 200px',
    width: 200,
    converter: numberConverter,
  },
  {
    id: 'nonnetable',
    field: 'nonnetable',
    headerName: 'NonNetable',
    flex: '1 0 200px',
    width: 200,
    converter: numberConverter,
  },
  {
    id: 'cost',
    field: 'cost',
    headerName: 'Unit Cost',
    flex: '1 0 150px',
    width: 150,
    converter: numberConverter,
  },
  {
    id: 'value',
    field: 'value',
    headerName: 'Value',
    flex: '1 0 130px',
    width: 130,
    converter: numberConverter,
  },
  {
    id: 'available_value',
    field: 'available_value',
    headerName: 'Usable Value',
    flex: '1 0 180px',
    width: 180,
    converter: numberConverter,
  },
  {
    id: 'nonavailable_value',
    field: 'nonavailable_value',
    headerName: 'NonUsable Value',
    flex: '1 0 230px',
    width: 230,
    converter: numberConverter,
  },
  {
    id: 'netable_value',
    field: 'netable_value',
    headerName: 'Netable value',
    flex: '1 0 200px',
    width: 200,
    converter: numberConverter,
  },
  {
    id: 'nonnetable_value',
    field: 'nonnetable_value',
    headerName: 'NonNetable value',
    flex: '1 0 200px',
    width: 200,
    converter: numberConverter,
  },
  {
    id: 'cost_method',
    field: 'cost_method',
    headerName: 'Cost Method',
    flex: '1 0 200px',
    width: 200,
  },
]

export const qohListPageTitle = 'xTuple - Quantity on Hand'
