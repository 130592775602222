import React from 'react'
import { FieldPath } from 'react-hook-form/dist/types'
import { IXtAutocompleteOption, XtAutocompleteLoadOptionsFunc } from 'components/controls/xt-autocomplete/xt-autocomplete.types'

export type FilterValue = string | number | boolean

export enum FilterType {
  Dropdown,
  DropdownMultiple,
  Checkbox,
  Radio,
  Date,
  Text,
  Autocomplete,
}

export type FilterMakerFunction = <TOption extends IXtAutocompleteOption>(filter: IFilter<TOption>) => JSX.Element

export interface IFilterAutocompleteProps<TOption extends IXtAutocompleteOption, TFilters> {
  onChange?(option: TOption | null): void
  value?: TOption | null
  loadOptions: XtAutocompleteLoadOptionsFunc<TOption, TFilters>
  renderOption?: (option: TOption) => React.ReactElement
  getInputLabel?(option: TOption | null): string
  filters?: TFilters
}

export interface IFilter<TOption extends IXtAutocompleteOption = IXtAutocompleteOption, TFilters = never> {
  type: FilterType
  label: string
  fieldName: FieldPath<IFilterFormState> // TODO use more strict type
  options?: IXtAutocompleteOption[]
  autocompleteProps?: IFilterAutocompleteProps<TOption, TFilters>
}

export interface IFilterDescription<TFilters, TFiltersState extends IFilterFormState> {
  filters: TFilters
  open: boolean
  onSubmit: (values: TFiltersState) => void
  onClose: () => void
}

export interface IFilterFormState {
  [key: string]: FilterValue | FilterValue[] | IXtAutocompleteOption[] | Date | IXtAutocompleteOption | null | undefined
}
