import React, { ReactElement } from 'react'

import { PhoneCallDialog } from './phone-call-dialog/phone-call-dialog'
import { IPhoneLinkState, PhoneAction } from '../phone-link/phone-link.types'
import { PhoneMessageDialog } from './phone-message-dialog/phone-message-dialog'

interface IPhoneActionDialog {
  onClose: () => void
  phoneState: IPhoneLinkState
}

export const PhoneActionDialog = ({ onClose, phoneState }: IPhoneActionDialog): ReactElement | null => {
  if (!phoneState.action) {
    return <div />
  }

  return phoneState.action === PhoneAction.Call ? (
    <PhoneCallDialog phone={phoneState.phone} onClose={onClose} />
  ) : (
    <PhoneMessageDialog onClose={onClose} phone={phoneState.phone} />
  )
}
