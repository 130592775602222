import { parseAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { dateToServerFormat } from 'common/utils/date.utils'
import { DocumentLocationOption } from 'documents/documents.types'
import {
  InventoryHistoryByLocationFilters,
  InventoryHistoryByLocationState,
  LocationFormField,
} from './inventory-history-by-location-list-form.types'
import { allSitesOption, defaultTransactionTypeOption } from './inventory-history-by-location-list-form.constansts'

export const convertFormDataToInventoryHistoryFilters = (formData: InventoryHistoryByLocationState): InventoryHistoryByLocationFilters => {
  const site = formData[LocationFormField.Site]?.id
    ? parseAutocompleteOption(formData[LocationFormField.Site])
    : formData[LocationFormField.Location]?.site
  return {
    [LocationFormField.Location]: parseAutocompleteOption(formData[LocationFormField.Location]),
    [LocationFormField.Site]: site,
    [LocationFormField.TransactionType]: parseAutocompleteOption(formData[LocationFormField.TransactionType]),
    [LocationFormField.StartDate]: dateToServerFormat(formData[LocationFormField.StartDate]),
    [LocationFormField.EndDate]: dateToServerFormat(formData[LocationFormField.EndDate]),
  }
}

export function defineDefaultFormValues(location: DocumentLocationOption | null): InventoryHistoryByLocationState {
  return {
    [LocationFormField.Location]: location || null,
    [LocationFormField.Site]: allSitesOption,
    [LocationFormField.TransactionType]: defaultTransactionTypeOption,
    [LocationFormField.StartDate]: null,
    [LocationFormField.EndDate]: null,
  }
}
