import { PermissionGuard } from 'auth/permission.guard'
import { UserPermission } from 'auth/auth.types'
import { companiesRoutePath } from 'companies/companies.constants'

export class LeadsGuard extends PermissionGuard {
  public redirectTo = companiesRoutePath
  public permissions = [
    UserPermission.ViewAllContacts,
    UserPermission.ViewPersonalContacts,
    UserPermission.MaintainAllContacts,
    UserPermission.MaintainPersonalContacts,
  ]
}
