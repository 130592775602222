import React, { FC, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form/dist/types'
import { FormCheckboxLabel, FormSelectField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { cls } from 'common/utils/utils'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { maxNumber } from 'common/validation/validation'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useProductsModule } from 'products/products-module-hook'
import { throughputFormula } from './routing-item-general-tab.utils'
import { ReportRunCostAs } from './routing-item-general-tab.constants'
import * as styles from './routing-item-general-tab.module.scss'
import { RoutingItemFormData } from '../../routing-item.types'
import { MAX_NUMBER_VALUE, RoutingItemControlLabel, RoutingItemControlName } from '../../routing-item.constants'

interface IRoutingItemGeneralTab {
  formMethods: UseFormReturn<RoutingItemFormData>
  disabled: boolean
  uom?: string
}

export const renderWipLocationOption: (option: IXtAutocompleteOption | null) => React.ReactElement = (option) => (
  <div key={option?.id}>{`${option?.label}-${option?.id}`}</div>
)

export const RoutingItemGeneralTab: FC<IRoutingItemGeneralTab> = ({ formMethods, disabled, uom }) => {
  const { DocumentsUtilsService } = useDocumentsModule()
  const { RoutingUtilsService } = useProductsModule()

  const { control, watch } = formMethods

  const [per, runtime, productionRatio] = watch([
    RoutingItemControlName.Per,
    RoutingItemControlName.RunTime,
    RoutingItemControlName.ProductionRatio,
  ])

  const initialThroughputValues = useMemo(
    () => ({
      per: per ?? 0,
      runTime: runtime ?? 0,
      uomRatio: productionRatio ?? 0,
    }),
    [per, runtime, productionRatio]
  )

  const perMinuteValue = throughputFormula(initialThroughputValues, true)
  const minPerValue = throughputFormula(initialThroughputValues)

  return (
    <div>
      <div className={styles.content}>
        <div className={styles.topContent}>
          <div className={styles.fieldCol}>
            <DecimalFormField
              label={RoutingItemControlLabel.SetupTime}
              name={RoutingItemControlName.SetupTime}
              control={control}
              disabled={disabled}
              fixedDecimalScale={2}
              maxValue={MAX_NUMBER_VALUE}
            />
            <DecimalFormField
              label={RoutingItemControlLabel.RunTime}
              name={RoutingItemControlName.RunTime}
              control={control}
              disabled={disabled}
              fixedDecimalScale={2}
              maxValue={MAX_NUMBER_VALUE}
            />
            <DecimalFormField
              label={RoutingItemControlLabel.Per}
              name={RoutingItemControlName.Per}
              control={control}
              disabled={disabled}
              fixedDecimalScale={2}
              maxValue={maxNumber}
            />
          </div>
          <div className={styles.fieldCol}>
            <div className={styles.reportSection}>
              <FormSelectField
                required
                name={RoutingItemControlName.ReportSetupCostAs}
                control={control}
                label={RoutingItemControlLabel.ReportSetupCostAs}
                disabled={disabled}
                options={ReportRunCostAs}
              />

              <FormCheckboxLabel
                name={RoutingItemControlName.ReportSetupTime}
                control={control}
                disabled={disabled}
                label={RoutingItemControlLabel.ReportSetupTime}
              />
            </div>
            <div className={styles.reportSection}>
              <FormSelectField
                required
                name={RoutingItemControlName.ReportRunCostAs}
                control={control}
                label={RoutingItemControlLabel.ReportRunCostAs}
                disabled={disabled}
                options={ReportRunCostAs}
              />
              <FormCheckboxLabel
                name={RoutingItemControlName.ReportRunTime}
                control={control}
                disabled={disabled}
                label={RoutingItemControlLabel.ReportRunTime}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={cls(styles.content, styles.bottomContent)}>
        <FormXtAutocomplete
          name={RoutingItemControlName.ProductionUOM}
          control={control}
          disabled={disabled}
          label={RoutingItemControlLabel.ProductionUOM}
          loadOptions={DocumentsUtilsService.loadUomOptions}
        />
        <DecimalFormField
          name={RoutingItemControlName.ProductionRatio}
          control={control}
          disabled={disabled}
          label={RoutingItemControlLabel.ProductionRatio}
          fixedDecimalScale={5}
          maxValue={10 ** 9}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.checkboxContent}>
          <FormCheckboxLabel
            name={RoutingItemControlName.ReceiveInventory}
            control={control}
            disabled={disabled}
            label={RoutingItemControlLabel.ReceiveInventory}
          />
          <FormCheckboxLabel
            name={RoutingItemControlName.IssueComponents}
            control={control}
            disabled={disabled}
            label={RoutingItemControlLabel.IssueComponents}
          />
          <FormCheckboxLabel
            name={RoutingItemControlName.AllowOverlap}
            control={control}
            disabled={disabled}
            label={RoutingItemControlLabel.AllowOverlap}
          />
        </div>
        <FormXtAutocomplete
          name={RoutingItemControlName.WIPLocation}
          control={control}
          disabled={disabled}
          label={RoutingItemControlLabel.WIPLocation}
          loadOptions={RoutingUtilsService.loadWipLocationOptions}
          renderOption={renderWipLocationOption}
          className={styles.field}
        />
      </div>
      <span className={styles.throughput}>Throughput:</span>
      <div className={cls(styles.content, styles.throughputContent)}>
        <p>{`${perMinuteValue} ${uom} per Minute`}</p>
        <p>{`${minPerValue} (min.) per ${uom}`}</p>
      </div>
    </div>
  )
}
