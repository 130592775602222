import * as yup from 'yup'
import { NumberSchema, ObjectSchema } from 'yup'
import { MutableRefObject } from 'react'
import { minDate, validationMessage } from 'common/validation/validation'
import { IItemSite } from 'products/items/items.types'
import { InventoryAdjustmentField, InventoryAdjustmentMethod } from './inventory-adjustment-details.types'
import { InventoryAdjustmentCostType } from './inventory-adjustment-cost/inventory-adjustment-cost.types'

export function defineInventoryAdjustmentFormSchema(availableSiteItemsRef: MutableRefObject<number>, before: number): ObjectSchema {
  return yup.object().shape({
    [InventoryAdjustmentField.Item]: yup.object().nullable().required(validationMessage.isRequired),
    [InventoryAdjustmentField.Site]: yup.object().nullable().required(validationMessage.isRequired),
    [InventoryAdjustmentField.DistributionQty]: yup
      .number()
      .required(validationMessage.isRequired)
      .nullable()
      .when(
        [InventoryAdjustmentField.Method, InventoryAdjustmentField.Site],
        (method: InventoryAdjustmentMethod, site: IItemSite, schema: NumberSchema) => {
          if (site.prevent_negative_inventory) {
            if (method === InventoryAdjustmentMethod.Absolute) {
              const absoluteSchema = schema.notOneOf([before], validationMessage.notEqual(String(before)))
              return before === 0
                ? absoluteSchema.moreThan(0, validationMessage.min('0'))
                : absoluteSchema.min(0, validationMessage.cannotBeLessThan('0'))
            }
            const availableSiteItems = availableSiteItemsRef.current
            return schema
              .min(-availableSiteItems, validationMessage.cannotBeLessThan(String(-availableSiteItems)))
              .notOneOf([0], validationMessage.notEqual('0'))
          }
          return schema
        }
      ),
    [InventoryAdjustmentField.TransactionDate]: yup
      .date()
      .required(validationMessage.isRequired)
      .nullable()
      .typeError(validationMessage.invalidDate)
      .min(minDate, validationMessage.minDate(validationMessage.minimumDate)),
    [InventoryAdjustmentField.TotalCost]: yup
      .number()
      .typeError(validationMessage.isRequired)
      .when(InventoryAdjustmentField.CostType, (type: InventoryAdjustmentCostType, schema: NumberSchema) => {
        return type === InventoryAdjustmentCostType.Manual ? schema.required(validationMessage.isRequired) : schema
      }),
  })
}
