import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { ContactsModule } from 'contacts/contacts.module'
import { CoreModule } from 'core/core.module'
import { DictionaryModule } from 'dictionary/dictionary.module'
import { IErrorHandler } from 'core/services/error-handler.service'
import { errorHandlerServiceToken } from 'core/core.constants'
import { IShipmentsService } from './shipments.service'
import { shipmentsModule, shipmentsServiceToken, shipmentsUtilsServiceToken } from './shipments.constants'
import { IShipmentsUtilsService } from './shipments-utils.service'

export type ShipmentsModuleDefinition = typeof import('./shipments.exports')

export interface IShipmentsServices {
  ShipmentsService: IShipmentsService
  ShipmentsUtilsService: IShipmentsUtilsService
}

export const ShipmentsModule: ILazyModule<ShipmentsModuleDefinition> = {
  name: shipmentsModule,
  resolver: () => import('./shipments.exports'),
  initializer: ({ ShipmentsService, ShipmentsUtilsService }) => {
    Injector.register<IShipmentsService>(
      shipmentsServiceToken,
      (injector) => new ShipmentsService(injector.resolve<IHttpClient>(httpClientToken)),
      Scope.Singleton
    )
    Injector.register<IShipmentsUtilsService>(
      shipmentsUtilsServiceToken,
      (injector) =>
        new ShipmentsUtilsService(
          injector.resolve<IShipmentsService>(shipmentsServiceToken),
          injector.resolve<IErrorHandler>(errorHandlerServiceToken)
        ),
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, ContactsModule, DictionaryModule],
}
