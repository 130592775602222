import { IXtTableCellAction } from '../../components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from '../../components/svg-icon/svg-icon.types'
import { IXtTableColumn } from '../../components/table/table-head/table-head.types'
import { AttachedDocumentPurpose, AttachedDocumentTargetType, IAttachedDocument } from '../documents.types'
import { AttachedDocumentAction } from './documents.types'

const actions: { [key in keyof typeof AttachedDocumentAction]: IXtTableCellAction } = {
  [AttachedDocumentAction.Delete]: {
    name: AttachedDocumentAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
  [AttachedDocumentAction.Download]: {
    name: AttachedDocumentAction.Download,
    label: 'Download',
    icon: SvgIconIds.DOWNLOAD,
  },
  [AttachedDocumentAction.Preview]: {
    name: AttachedDocumentAction.Preview,
    label: 'Preview',
    icon: SvgIconIds.VISIBILITY,
  },
  [AttachedDocumentAction.Edit]: {
    name: AttachedDocumentAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
}

export const attachedDocumentViewModeActions: IXtTableCellAction[] = [
  actions[AttachedDocumentAction.Preview],
  actions[AttachedDocumentAction.Download],
]

export const attachedDocumentActions: IXtTableCellAction[] = [
  actions[AttachedDocumentAction.Preview],
  actions[AttachedDocumentAction.Edit],
  actions[AttachedDocumentAction.Download],
  actions[AttachedDocumentAction.Delete],
]

export const attachedDocumentColumns: IXtTableColumn<IAttachedDocument>[] = [
  {
    id: 'document_name',
    field: 'name',
    headerName: 'Document',
    flex: '1 0 160px',
    width: 160,
  },
]

export const initialPreviewImgState = { url: null, loading: false, open: false }

export const defaultDocumentState: IAttachedDocument = {
  id: Date.now(),
  name: '',
  file_link: '',
  notes: '',
  target_number: '',
  target_id: -1,
  description: '',
  canview: true,
  canedit: true,
  purpose: AttachedDocumentPurpose.Sibling,
  target_type: AttachedDocumentTargetType.File,
  document_characteristics: null,
}
