import React, { FC } from 'react'
import { TableStateHookValidationError } from 'shared/hooks/table-state-hook'
import { XtInfoValues } from 'components/xt-info-values/xt-info-values'
import { LotSerialEntryRow } from '../../lot-serial.types'
import { lotSerialEntryLabels } from '../../lot-serial.constants'

import * as styles from './lot-serial-table-row-validation-popup.module.scss'

export const LotSerialTableRowValidationPopup: FC<{ errors: TableStateHookValidationError<LotSerialEntryRow> }> = ({ errors }) => {
  const errorsArray = Object.entries(errors).map(([key, error]) => ({
    label: lotSerialEntryLabels[key],
    value: error ?? null,
  }))

  return (
    <div className={styles.lotSerialTableRowValidationPopup}>
      <XtInfoValues values={errorsArray} />
    </div>
  )
}
