import * as React from 'react'
import { FC, useEffect } from 'react'
import { ToastProvider, useToasts, ToastProps, DefaultToast } from 'react-toast-notifications'
import * as styles from './toast-provide.module.scss'
import { useToastModule } from './toast-module-hook'

const toast = {
  autoDismissTimeout: 4000,
  animationTimeout: 500,
}

const XtToast: FC<ToastProps> = ({ children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <DefaultToast {...props}>
    <div className={styles.toastText}>{children}</div>
  </DefaultToast>
)

const ToastRegister: FC = ({ children }) => {
  const { addToast, removeToast, removeAllToasts } = useToasts()
  const { ToastService } = useToastModule()

  useEffect(() => ToastService.initialize({ addToast, removeToast, removeAllToasts }), [addToast, removeToast, removeAllToasts])
  return <>{children}</>
}

export const Toast: FC = ({ children }) => (
  <ToastProvider
    autoDismiss={toast.animationTimeout}
    transitionDuration={toast.animationTimeout}
    autoDismissTimeout={toast.autoDismissTimeout}
    components={{ Toast: XtToast }}
  >
    <ToastRegister>{children}</ToastRegister>
  </ToastProvider>
)
