// extracted by mini-css-extract-plugin
export var root = "inventory-history-by-lot-serial-list_root__2bRuN";
export var xtContent = "inventory-history-by-lot-serial-list_xt-content__Y7GEM";
export var xtContentWithRemarks = "inventory-history-by-lot-serial-list_xt-content-with-remarks__avd0q";
export var muiFormHelperTextRoot = "inventory-history-by-lot-serial-list_MuiFormHelperText-root__Kj+Oc";
export var xtListPageContainer = "inventory-history-by-lot-serial-list_xt-list-page-container__g3jHi";
export var listContent = "inventory-history-by-lot-serial-list_listContent__h1A-U";
export var muiInputBaseRoot = "inventory-history-by-lot-serial-list_MuiInputBase-root__xjgQt";
export var muiTextFieldRoot = "inventory-history-by-lot-serial-list_MuiTextField-root__1-Hou";
export var muiTablePaginationRoot = "inventory-history-by-lot-serial-list_MuiTablePagination-root__L-CvB";
export var muiTablePaginationSelectRoot = "inventory-history-by-lot-serial-list_MuiTablePagination-selectRoot__v0XUh";
export var muiTablePaginationSelect = "inventory-history-by-lot-serial-list_MuiTablePagination-select__dNdor";
export var muiStepperRoot = "inventory-history-by-lot-serial-list_MuiStepper-root__vstza";
export var muiStepperHorizontal = "inventory-history-by-lot-serial-list_MuiStepper-horizontal__4yrXx";
export var muiStepIconRoot = "inventory-history-by-lot-serial-list_MuiStepIcon-root__+DAHD";
export var muiStepIconActive = "inventory-history-by-lot-serial-list_MuiStepIcon-active__QsMHI";
export var muiStepIconCompleted = "inventory-history-by-lot-serial-list_MuiStepIcon-completed__E+dIW";
export var muiListItemRoot = "inventory-history-by-lot-serial-list_MuiListItem-root__gR73i";
export var muiSelected = "inventory-history-by-lot-serial-list_Mui-selected__zLn8E";
export var xtPageTitle = "inventory-history-by-lot-serial-list_xt-page-title__peYyz";
export var xtSubPageTitle = "inventory-history-by-lot-serial-list_xt-subPage-title__d1Xfp";
export var xtSectionBorder = "inventory-history-by-lot-serial-list_xt-section-border__E0GJc";
export var xtPageHeader = "inventory-history-by-lot-serial-list_xt-page-header__1nVQ-";
export var xtDialogScrollable = "inventory-history-by-lot-serial-list_xt-dialog-scrollable__hs-Li";
export var reactToastNotificationsContainer = "inventory-history-by-lot-serial-list_react-toast-notifications__container__1EPe6";
export var xtScrollbarStyle = "inventory-history-by-lot-serial-list_xt-scrollbar-style__uX7od";
export var xtModalDetailsContent = "inventory-history-by-lot-serial-list_xt-modal-details-content__jNE++";
export var xtDialogDetailsContent = "inventory-history-by-lot-serial-list_xt-dialog-details-content__lSFxY";
export var xtLazyModuleLoaderTabContainer = "inventory-history-by-lot-serial-list_xt-lazy-module-loader-tab-container__99mOd";
export var xtTextTruncated = "inventory-history-by-lot-serial-list_xt-text-truncated__-LaMf";
export var xtSummaryContent = "inventory-history-by-lot-serial-list_xt-summary-content__SLPuo";
export var xtSummaryLabel = "inventory-history-by-lot-serial-list_xt-summary-label__qxOSi";
export var visibleHidden = "inventory-history-by-lot-serial-list_visible-hidden__jP0B+";
export var headerBottomMargin = "inventory-history-by-lot-serial-list_header-bottom-margin__lQ24p";
export var xtDialogHeaderLogoAlign = "inventory-history-by-lot-serial-list_xt-dialog-header-logoAlign__7vijE";
export var xtStickyHeader = "inventory-history-by-lot-serial-list_xt-sticky-header__t2K6D";
export var noBorder = "inventory-history-by-lot-serial-list_no-border__CIZCy";
export var muiOutlinedInputNotchedOutline = "inventory-history-by-lot-serial-list_MuiOutlinedInput-notchedOutline__NhSBJ";
export var headerWithPrint = "inventory-history-by-lot-serial-list_headerWithPrint__UwKCz";