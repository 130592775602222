import React, { FC, useMemo } from 'react'
import { IXtTab } from 'components/xt-tabs/xt-tabs.types'
import { FormTextAreaField } from 'common/utils/form/form.components'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { CostingMethodOption } from 'products/items/items.types'
import { InventoryAdjustmentField, InventoryAdjustmentLabel } from '../inventory-adjustment-details.types'
import { isLotSerialTabDisabled } from './inventory-adjustment-details-tabs.utils'
import { LotSerial } from '../lot-serial/lot-serial'
import { InventoryAdjustmentCost } from '../inventory-adjustment-cost/inventory-adjustment-cost'
import { IInventoryAdjustmentTabsParams, InventoryAdjustmentsTabName } from './inventory-adjustment-details-tabs.types'

export const InventoryAdjustmentDetailsTabs: FC<IInventoryAdjustmentTabsParams> = ({ submitted, lotSerialState, formMethods }) => {
  const { itemSite: site } = lotSerialState
  const costTabDisabled = !site || site?.cost_method !== CostingMethodOption.Average

  const lotSerialTabInvalid = useMemo(() => lotSerialState.data.some((item) => !item.isValid && (item.touched || submitted)), [
    lotSerialState.data,
    submitted,
  ])

  const { control } = formMethods

  const tabs: IXtTab[] = [
    {
      name: InventoryAdjustmentsTabName.LotSerial,
      disabled: isLotSerialTabDisabled(site),
      markAsInvalid: lotSerialTabInvalid,
      template: site && <LotSerial submitted={submitted} lotSerialState={lotSerialState} />,
    },
    {
      disabled: costTabDisabled,
      name: InventoryAdjustmentsTabName.Cost,
      template: <InventoryAdjustmentCost formMethods={formMethods} />,
    },
    {
      name: InventoryAdjustmentsTabName.Notes,
      template: <FormTextAreaField control={control} name={InventoryAdjustmentField.Notes} label={InventoryAdjustmentLabel.Notes} />,
    },
  ]

  return <XtTabs tabs={tabs} />
}
