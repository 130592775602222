import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { useXtForm } from 'common/hooks/form/form'
import { useCoreModule } from 'core/core-module-hook'
import { FormCheckboxLabel } from 'common/utils/form/form.components'
import { convertMode } from 'common/utils/mode.utils'
import * as styles from './sites-details-dialog-checkboxes.module.scss'
import {
  ISitesDetailsDialogCheckboxes,
  SitesDetailsDialogCheckboxesForm,
  SitesDetailsDialogCheckboxesFormField,
  SitesDetailsDialogCheckboxesFormLabel,
} from './sites-details-dialog-checkboxes.types'
import { sitesDetailsDialogCheckboxesFormKey } from './sites-details-dialog-checkboxes.consts'
import { setFormFieldsDisabled, sitesDetailsDialogCheckboxesDefaults } from './sites-details-dialog-checkboxes.utils'

export const SitesDetailsDialogCheckboxes: FC<ISitesDetailsDialogCheckboxes> = ({
  mode,
  register,
  item,
  itemSite,
  isJobCosted,
  isTransitSite,
}) => {
  const { control, formChanges$, watch, setValue, reset, getValues, formState } = useXtForm<SitesDetailsDialogCheckboxesForm>({
    mode: 'onBlur',
  })

  useEffect(() => {
    register(sitesDetailsDialogCheckboxesFormKey, formChanges$, null, { data: getValues(), state: formState }, reset)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formChanges$, register, getValues])

  const { ErrorHandler } = useCoreModule()
  const { isViewMode } = convertMode(mode)

  const woSuppliedAtSite = watch(SitesDetailsDialogCheckboxesFormField.WoSuppliedAtSite)
  const poSuppliedAtSite = watch(SitesDetailsDialogCheckboxesFormField.PoSuppliedAtSite)
  const createSopos = watch(SitesDetailsDialogCheckboxesFormField.CreateSopos)

  useEffect(() => {
    const init = async (): Promise<void> => {
      try {
        reset(sitesDetailsDialogCheckboxesDefaults(item, itemSite))
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }

    void init()
  }, [ErrorHandler, itemSite, reset])

  const disableState = useMemo(
    () => setFormFieldsDisabled(isViewMode, item, isTransitSite, isJobCosted, woSuppliedAtSite, poSuppliedAtSite, createSopos),
    [isViewMode, item, isTransitSite, isJobCosted, woSuppliedAtSite, poSuppliedAtSite, createSopos]
  )

  const updateCheckState = useCallback(() => {
    if (isJobCosted) {
      setValue(SitesDetailsDialogCheckboxesFormField.PoSuppliedAtSite, false)
    }

    if (!woSuppliedAtSite) {
      setValue(SitesDetailsDialogCheckboxesFormField.CreateWos, false)
    }

    if (!poSuppliedAtSite || isJobCosted) {
      setValue(SitesDetailsDialogCheckboxesFormField.CreatePrs, false)
      setValue(SitesDetailsDialogCheckboxesFormField.CreateSoprs, false)
      setValue(SitesDetailsDialogCheckboxesFormField.CreateSopos, false)
    }

    if (!createSopos || isJobCosted) {
      setValue(SitesDetailsDialogCheckboxesFormField.Dropship, false)
    }
  }, [isJobCosted, woSuppliedAtSite, poSuppliedAtSite, createSopos, setValue])

  useEffect(() => {
    updateCheckState()
  }, [updateCheckState])

  return (
    <div className={styles.content}>
      <FormCheckboxLabel
        className={styles.contentField}
        control={control}
        label={SitesDetailsDialogCheckboxesFormLabel.WoSuppliedAtSite}
        name={SitesDetailsDialogCheckboxesFormField.WoSuppliedAtSite}
        disabled={disableState[SitesDetailsDialogCheckboxesFormField.WoSuppliedAtSite]}
      />
      <FormCheckboxLabel
        className={styles.contentField}
        control={control}
        label={SitesDetailsDialogCheckboxesFormLabel.CreateWos}
        name={SitesDetailsDialogCheckboxesFormField.CreateWos}
        disabled={disableState[SitesDetailsDialogCheckboxesFormField.CreateWos]}
      />
      <hr />
      <FormCheckboxLabel
        className={styles.contentField}
        control={control}
        label={SitesDetailsDialogCheckboxesFormLabel.PoSuppliedAtSite}
        name={SitesDetailsDialogCheckboxesFormField.PoSuppliedAtSite}
        disabled={disableState[SitesDetailsDialogCheckboxesFormField.PoSuppliedAtSite]}
      />
      <div className={styles.contentInline}>
        <FormCheckboxLabel
          className={styles.contentField}
          control={control}
          label={SitesDetailsDialogCheckboxesFormLabel.CreatePrs}
          name={SitesDetailsDialogCheckboxesFormField.CreatePrs}
          disabled={disableState[SitesDetailsDialogCheckboxesFormField.CreatePrs]}
        />
        <FormCheckboxLabel
          className={styles.contentField}
          control={control}
          label={SitesDetailsDialogCheckboxesFormLabel.CreateSoprs}
          name={SitesDetailsDialogCheckboxesFormField.CreateSoprs}
          disabled={disableState[SitesDetailsDialogCheckboxesFormField.CreateSoprs]}
        />
        <FormCheckboxLabel
          className={styles.contentField}
          control={control}
          label={SitesDetailsDialogCheckboxesFormLabel.CreateSopos}
          name={SitesDetailsDialogCheckboxesFormField.CreateSopos}
          disabled={disableState[SitesDetailsDialogCheckboxesFormField.CreateSopos]}
        />
        <div className={styles.contentInline}>
          <FormCheckboxLabel
            className={styles.contentField}
            control={control}
            label={SitesDetailsDialogCheckboxesFormLabel.Dropship}
            name={SitesDetailsDialogCheckboxesFormField.Dropship}
            disabled={disableState[SitesDetailsDialogCheckboxesFormField.Dropship]}
          />
        </div>
      </div>
      <hr />
      <FormCheckboxLabel
        className={styles.contentField}
        control={control}
        label={SitesDetailsDialogCheckboxesFormLabel.SuppressAutomaticOrders}
        name={SitesDetailsDialogCheckboxesFormField.SuppressAutomaticOrders}
        disabled={disableState[SitesDetailsDialogCheckboxesFormField.SuppressAutomaticOrders]}
      />
    </div>
  )
}
