import { IPhone } from '../../../../contacts/contacts.types'
import { SvgIconIds } from '../../../svg-icon/svg-icon.types'

export interface IPhoneLink {
  phone?: string
  className?: string
  showMessageAction: boolean
}
export enum PhoneAction {
  Call = 'Call',
  Message = 'Text message',
}

export type PhoneActionOption = {
  name: string
  label: string
  icon: SvgIconIds
}

export interface IPhoneLinkData extends Pick<IPhone, 'number' | 'allowsms'> {}

export interface IPhoneLinkState {
  phone: string
  open: boolean
  action: PhoneAction | null
}
