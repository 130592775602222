import { parseServerDate } from 'common/utils/date.utils'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { BomDetailsFormField, BomFormData } from './bom-details.types'
import { IBom, IBomItem } from '../bom.types'
import { bomDetailsActionsEditMode, bomDetailsActionsViewMode, bomNewActionsNewBom } from './bom-details.constants'

export const findBomItem: (itemId: number, items: IBomItem[]) => IBomItem | null = (itemId, items) =>
  items.find(({ id }) => id === itemId) ?? null

export function defineAvailableActions(newBom: boolean, isViewMode: boolean): IXtTableCellAction[] {
  if (newBom) {
    return bomNewActionsNewBom
  }
  if (isViewMode) {
    return bomDetailsActionsViewMode
  }
  return bomDetailsActionsEditMode
}

export function processBom(bom: IBom | null, itemNumber: string | null = null): BomFormData {
  const itemNumberValue = bom?.item_number || itemNumber
  return {
    [BomDetailsFormField.BatchSize]: bom?.batch_size ?? '',
    [BomDetailsFormField.DocumentNumber]: bom?.document_number ?? '',
    [BomDetailsFormField.Revision]: bom?.revision ?? '',
    [BomDetailsFormField.RevisionDate]: parseServerDate(bom?.revision_date),
    [BomDetailsFormField.ItemNumber]: itemNumberValue
      ? {
          id: itemNumberValue,
          label: itemNumberValue,
          itemNumber: itemNumberValue,
          uom: bom?.item_inventory_uom,
          description1: bom?.item_description,
        }
      : null,
  }
}
