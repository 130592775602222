import { nanoid } from 'nanoid'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { ISortOption } from 'components/table/table-head/table-head.types'

import { IRunningAvailabilityWithId, IRequestItemAndSiteData } from './running-availability.types'
import { IItemsService } from '../products/items/items.service'
import { IItemSitesService } from '../products/items/item-sites.service'
import { RunningAvailabilityFilters } from '../products/items/items.types'

export interface IRunningAvailabilityUtilsService {
  fetchItems(
    filters: RunningAvailabilityFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IRunningAvailabilityWithId>>
  requestItemAndSiteData(itemNumber: string, site: string): Promise<IRequestItemAndSiteData>
}

export class RunningAvailabilityUtilsService implements IRunningAvailabilityUtilsService {
  constructor(private readonly itemService: IItemsService, private readonly itemSiteService: IItemSitesService) {
    bindAllInstanceMethods(this)
  }

  public async fetchItems(
    filters: RunningAvailabilityFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IRunningAvailabilityWithId>> {
    const { data, total } = await this.itemSiteService.getAllRunningAvailability(paginationParams, filters, sortOptions)

    return {
      data: data.map((item) => ({ ...item, id: nanoid() })),
      total,
    }
  }

  public async requestItemAndSiteData(itemNumber: string, site: string): Promise<IRequestItemAndSiteData> {
    const [itemOption, siteOption] = await Promise.all([
      this.itemService.get(itemNumber),
      this.itemSiteService.getItemSite(itemNumber, site),
    ])
    return {
      item: itemOption,
      site: siteOption,
    }
  }
}
