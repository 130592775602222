import { parseAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { CostedBomFilters, CostedBomSummaryFilter } from 'products/items/items.types'
import { CostsOptionField, FormSharedField, IBomListSharedForm } from '../../shared/shared.types'

export function convertFormDataToCostedBomFilters(formData: IBomListSharedForm): CostedBomFilters {
  const standardCost = formData[FormSharedField.CostsOption] === CostsOptionField.Standard ? true : undefined
  const actualCost = formData[FormSharedField.CostsOption] === CostsOptionField.Actual ? true : undefined

  return {
    [CostedBomSummaryFilter.ItemNumber]: parseAutocompleteOption(formData[FormSharedField.ItemDetails]),
    [CostedBomSummaryFilter.RevisionNumber]: formData[FormSharedField.Revision]?.id,
    [CostedBomSummaryFilter.UseStandardCosts]: standardCost,
    [CostedBomSummaryFilter.UseActualCosts]: actualCost,
  }
}
