import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { CharacteristicsModule } from 'characteristics/characteristics.module'
import { CommentsModule } from 'comments/comments.module'
import { DocumentsModule } from 'documents/documents.module'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { documentsServiceToken } from 'documents/documents.constants'
import { IDocumentsService } from 'documents/documents.types'
import { leadsModule, leadsServiceToken, leadsUtilsServiceToken } from './leads.constants'
import { ILeadsUtilsService } from './leads-utils.service'
import { ILeadService } from './leads.service'
import { CoreModule } from '../core/core.module'
import { AuthModule } from '../auth/auth.module'
import { DictionaryModule } from '../dictionary/dictionary.module'
import { SharedModule } from '../shared/shared-module'

export type LeadsModuleDefinition = typeof import('./leads.exports')

export interface ILeadsModuleServices {
  LeadsService: ILeadService
  LeadsUtilsService: ILeadsUtilsService
}

export const LeadsModule: ILazyModule<LeadsModuleDefinition> = {
  name: leadsModule,
  resolver: () => import('./leads.exports'),
  initializer: ({ LeadsService, LeadsUtilsService }) => {
    Injector.register(
      leadsServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)

        return new LeadsService(httpClient, documentsService)
      },
      Scope.Singleton
    )

    Injector.register<ILeadsUtilsService>(
      leadsUtilsServiceToken,
      (injector) => {
        const leadsService = injector.resolve<ILeadService>(leadsServiceToken)

        return new LeadsUtilsService(leadsService)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, SharedModule, AuthModule, CharacteristicsModule, CommentsModule, DocumentsModule, DictionaryModule],
}
