import * as yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { SitesDetailsDialogInventoryFormField } from './sites-details-dialog-inventory.types'

export const sitesDialogInventoryValidation = yup.object().shape({
  [SitesDetailsDialogInventoryFormField.ControlMethod]: yup.object().nullable().required(validationMessage.isRequired),
  [SitesDetailsDialogInventoryFormField.PlannerCode]: yup.object().nullable().required(validationMessage.isRequired),
  [SitesDetailsDialogInventoryFormField.CostCategoryCode]: yup.object().nullable().required(validationMessage.isRequired),
  [SitesDetailsDialogInventoryFormField.CostingMethod]: yup.string().nullable().required(validationMessage.isRequired),
})
