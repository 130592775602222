import * as React from 'react'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { deepEqual } from 'fast-equals'
import { useXtForm } from 'common/hooks/form/form'
import { IFormStateChanges } from 'common/hooks/form/form.types'
import { FilterMakerFunction, FilterType, IFilter, IFilterDescription, IFilterFormState } from './filter.types'
import { makeCheckboxFilter } from './components/filter.checkbox'
import { makeDropdownFilter } from './components/filter.dropdown'
import { makeRadioFilter } from './components/filter.radio'
import { makeDateFilter } from './components/filter.date'
import { makeTextFilter } from './components/filter.text'
import { makeAutocompleteFilter } from './components/filter.autocomplete'
import { IXtAutocompleteOption } from '../../controls/xt-autocomplete/xt-autocomplete.types'
import { makeValidationSchema } from './filter.validation'
import { clearFilterValues } from './filter.utils'
import { FilterDialogWrapper } from './filter-dialog-wrapper'
import { usePageFilterContext } from '../pagefilter.utils'

/** A component for specifying arbitrary filters.

 It accepts a list of pagefilter descriptions in the filters attribute and creates a modal sliding window.

 Here is example pagefilter description:

 `<XtFilter open={true}
 defaultValues={defaultFilterValues}
 label="Filters"
 onClose={()=> setOpen(false)}
 onSubmit={onSubmit}
 filters={filterFields} />


 const defaultFilterValues = {
    FieldAutocomplete: '',
    FieldCheckbox: false,
    FieldText: '',
    FieldDate: new Date() | '',
    FieldDropdown: 'value1' | '',// required
    FieldDropdownMultiple: ['value'] | [''],// required
    FieldRadio: 0 | 1 | 3, // [{radio 1},{radio 2},{radio 3}]
  }

 const filterFields = [
 {
      type: FilterType.Autocomplete,
      label: 'LabelAutocomplete',
      fieldName: 'FieldAutocomplete',
      autocompleteProps: {
      loadOptions: loadOptions, // src/common/utils
      },
    },
 {
      type: FilterType.Checkbox,
      label: 'LabelCheckbox',
      fieldName: 'FieldCheckbox',
    },
 {
      type: FilterType.Text,
      label: 'LabelText',
      fieldName: 'FieldText',
    },
 {
      type: FilterType.Date,
      label: 'LabelDate',
      fieldName: 'FieldDate',
    },
 {
      type: FilterType.Dropdown,
      label: 'tLabelDropdown',
      fieldName: 'FieldDropdown',
      options: ['value1', 'value2', 'value3'],
    },
 {
      type: FilterType.DropdownMultiple,
      label: 'LabelDropdownMultiple',
      fieldName: 'FieldDropdownMultiple',
      options: ['value1', 'value2', 'value3'],
  },
 {
      type: FilterType.Radio,
      label: 'LabelRadio ',
      fieldName: 'FieldRadio',
      radioOptions: [
          { label: 'radio 1', value: false },
          { label: 'radio 2', value: false },
      ],
    },
 ]` */

function FilterComponent<
  TOption extends IXtAutocompleteOption,
  TFilters extends Array<IFilter<TOption>>,
  TFiltersState extends IFilterFormState
>({ filters, open, onSubmit, onClose }: IFilterDescription<TFilters, TFiltersState>) {
  const {
    tableFilters: currentFilters,
    state: { lastUsedFilter },
    defaultFilterValues,
    onChange,
    resetFilters,
  } = usePageFilterContext<TFiltersState>()

  const [initValues, setInitValues] = useState(lastUsedFilter ?? defaultFilterValues)

  const { control, reset, getValues, handleSubmit, formChanges$ } = useXtForm<IFilterFormState>({
    validationSchema: yup.object().shape(makeValidationSchema<TOption, TFilters>(filters)),
    mode: 'onBlur',
  })

  const [initialFiltersState, setInitialFiltersState] = useState<IFilterFormState>(initValues)

  useEffect(() => {
    if (onChange) {
      const sub = formChanges$
        .pipe(
          distinctUntilChanged((prev, current) => deepEqual(prev.data, current.data)),
          map((filterValues) => {
            onChange(filterValues as IFormStateChanges<TFiltersState>)
          })
        )

        .subscribe()
      return () => sub.unsubscribe()
    }
    return () => {}
  }, [onChange])

  const onCancel = () => {
    reset(initialFiltersState)
    setInitValues(lastUsedFilter ?? defaultFilterValues)
    onClose()
  }

  const onResetFilters: (values?: TFiltersState) => void = (values) => {
    const newState = values ?? initValues
    reset(newState)
    setInitialFiltersState(newState)
  }

  const onClearFilters: VoidFunction = () => {
    const clearedValues = clearFilterValues<TOption, TFilters, TFiltersState>(getValues(), filters)

    reset(clearedValues)
    setInitialFiltersState(clearedValues)
    onSubmit(clearedValues)
    onClose()
  }

  /** Creates a filter. The type of the filter is determined by the type property. */
  const makeFilter: FilterMakerFunction = (filter) => {
    switch (filter.type) {
      case FilterType.DropdownMultiple:
        return makeDropdownFilter(filter, control, true) // true === multiple
      case FilterType.Autocomplete:
        return makeAutocompleteFilter(filter, control)
      case FilterType.Dropdown:
        return makeDropdownFilter(filter, control)
      case FilterType.Checkbox:
        return makeCheckboxFilter(filter, control)
      case FilterType.Radio:
        return makeRadioFilter(filter, control)
      case FilterType.Date:
        return makeDateFilter(filter, control)
      case FilterType.Text:
        return makeTextFilter(filter, control)
      default:
        throw new Error('Impossible pagefilter type')
    }
  }

  if (!filters || !filters.length) {
    throw new Error('XtFilter cannot be used without any filters defined')
  }

  useEffect(() => {
    const resetSubscription = resetFilters?.subscribe(onResetFilters)

    return () => resetSubscription?.unsubscribe()
  }, [resetFilters])

  const submitForm: VoidFunction = () => {
    const params = { ...initValues, ...currentFilters, ...getValues() } as TFiltersState
    onSubmit(params)
    setInitialFiltersState(getValues())
    onClose()
  }

  useEffect(() => {
    if (!deepEqual(initValues, currentFilters)) {
      setInitialFiltersState(initValues)
    }
  }, [initValues])

  useEffect(() => {
    onSubmit(lastUsedFilter ?? initValues)
  }, [lastUsedFilter])

  const onApplyFilters: VoidFunction = handleSubmit(submitForm)

  return (
    <FilterDialogWrapper
      open={open}
      onCancel={onCancel}
      onApplyFilters={onApplyFilters}
      onClearFilters={onClearFilters}
      getValues={getValues}
      reset={reset}
    >
      {filters.map(makeFilter)}
    </FilterDialogWrapper>
  )
}

export const XtFilter = React.memo(FilterComponent) as typeof FilterComponent // type casting is used to forward Generic types
