import { PageNotFound } from 'page-not-found/page-not-found'
import { IAsyncRoute, IRoute } from '../common/routing/routing.types'
import { quotesRoutePath } from './quotes.constants'
import { QuotesGuard } from './quotes.guard'
import { XtMode } from '../common/common.types'
import { buildPageComponent } from '../common/routing/routing.utils'
import { QuoteDetailsGuard } from './quote-details/quote-details.guard'
import { QuotesModule, QuotesModuleDefinition } from './quotes.module'

export function quoteRouteFactory({ QuotesList, QuoteDetailsPage, QuoteDetailsOpportunityNewPage }: QuotesModuleDefinition): IRoute[] {
  return [
    {
      path: '',
      exact: true,
      component: QuotesList,
    },
    {
      path: `/:quoteNumber/${XtMode.Edit}`,
      exact: true,
      component: (props) => buildPageComponent(props, XtMode.Edit, QuoteDetailsPage),
      guards: [QuoteDetailsGuard],
    },
    {
      path: `/:quoteNumber/${XtMode.View}`,
      exact: true,
      component: (props) => buildPageComponent(props, XtMode.View, QuoteDetailsPage),
    },
    {
      path: `/${XtMode.New}/opportunity/:opportunityNumber/:account`,
      exact: true,
      component: (props) => buildPageComponent(props, XtMode.New, QuoteDetailsOpportunityNewPage),
      guards: [QuoteDetailsGuard],
    },
    {
      path: `/${XtMode.New}`,
      exact: true,
      component: (props) => buildPageComponent(props, XtMode.New, QuoteDetailsPage),
      guards: [QuoteDetailsGuard],
    },
    {
      path: '*',
      component: PageNotFound,
      exact: true,
    },
  ]
}

export const quoteRoute: IAsyncRoute<QuotesModuleDefinition> = {
  module: QuotesModule,
  path: quotesRoutePath,
  name: 'Quotes',
  guards: [QuotesGuard],
  childrenRoutesFactory: quoteRouteFactory,
}
