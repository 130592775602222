import type { Overwrite } from 'utility-types'
import type { IOpportunitiesModuleService, OpportunitiesModuleDefinition } from './opportunities.module'
import type { IOpportunitiesKanbanService } from './opportunities-kanban/opportunities-kanban.service'
import type { IOpportunitiesKanbanUtilsService } from './opportunities-kanban/opportunities-kanban-utils.service'
import type { IOpportunitiesServices } from './services/opportunities-services.types'
import { Injector } from 'core/injector/injector.service'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { OpportunitiesModule } from './opportunities.module'
import { resolveOpportunitiesServicesModule } from './services/opportunities-services-module-hook'
import { opportunitiesKanbanServiceToken, opportunitiesKanbanUtilsServiceToken } from './opportunities.constants'

function resolveOpportunitiesModule(): Overwrite<OpportunitiesModuleDefinition, IOpportunitiesModuleService> & IOpportunitiesServices {
  const module = LazyModulesService.resolveModule<OpportunitiesModuleDefinition>(OpportunitiesModule)
  const OpportunitiesKanbanService = Injector.resolve<IOpportunitiesKanbanService>(opportunitiesKanbanServiceToken)
  const OpportunitiesKanbanUtilsService = Injector.resolve<IOpportunitiesKanbanUtilsService>(opportunitiesKanbanUtilsServiceToken)
  const services = resolveOpportunitiesServicesModule()

  return {
    ...module,
    ...services,
    OpportunitiesKanbanService,
    OpportunitiesKanbanUtilsService,
  }
}

export const useOpportunitiesModule = moduleHookResolver(resolveOpportunitiesModule)
