import { useCallback, useEffect, useRef, useState } from 'react'
import { combineLatest, Observable, Subject } from 'rxjs'
import { filter, map, pairwise, startWith, switchMap, tap } from 'rxjs/operators'
import { TableStateHookChange, useTableState } from 'shared/hooks/table-state-hook'
import { ControlMethodOption, IItem, IItemSite } from 'products/items/items.types'
import { useCoreModule } from 'core/core-module-hook'
import { useInventoryAdjustmentModule } from 'inventory/inventory-adjustments/inventory-adjustments-module.hook'
import { LotSerialEntryRow, LotSerialOption } from '../../lot-serial.types'
import { generateLotSerialItemPlaceholder } from '../../lot-serial.utils'
import { useLotSerialLocation } from '../lot-serial-location-hook/lot-serial-location-hook'
import { useLotSerialNumber } from '../lot-serial-number-hook/lot-serial-number-hook'
import {
  calculateMetaState,
  defineTableInitialData,
  findDuplicatedSerialNumber,
  isValidItemSitePayload,
  isValidQtyToAssignPayload,
  retrieveAssignedQuantity,
  supportsSerialAutomationMode,
  updateTableData,
} from './lot-serial-hook.utils'
import { ILotSerialHook, ILotSerialHookMeta } from './lot-serial-hook.types'
import {
  defaultLotSerialMetaState,
  defaultQuantityDecimalScale,
  fractionalQuantityDecimalScale,
  lotSerialErrors,
} from './lot-serial-hook.constants'
import { defineLotSerialEntryValidation, defineLotSerialNumberValidatorAsync } from '../../lot-serial.validation'

/**
 * Hook that provides the following functionality to Lot/Serial table:
 * - Table initialization: dropdown options, default rows
 * - Table validation
 * - Table reset/update in case item site or qty to assign is changed
 * @param qtyToAssign$
 */
export function useLotSerial(qtyToAssign$: Observable<number> | undefined): ILotSerialHook {
  const { ErrorHandler } = useCoreModule()
  const [meta, setMeta] = useState<ILotSerialHookMeta>(defaultLotSerialMetaState)

  const [loading, setLoading] = useState<boolean>(false)
  const [quantityDecimalScale, setQuantityDecimalScale] = useState<number>(defaultQuantityDecimalScale)

  const [itemSite, setItemSite] = useState<IItemSite | null>(null)

  const lotSerialTableRef = useRef<LotSerialEntryRow[]>([])
  const itemSiteSubjectRef = useRef<Subject<IItemSite | null>>()
  const quantityDecimalScaleRef = useRef<number>(defaultQuantityDecimalScale)

  const locationState = useLotSerialLocation()
  const lotSerialNumberState = useLotSerialNumber()

  const { resetLocations, getDefaultLocation } = locationState
  const { resetLotSerialNumberOptions } = lotSerialNumberState

  const onTableStateChange: TableStateHookChange<LotSerialEntryRow> = (change) => {
    lotSerialTableRef.current = change.data
    const qtyAssigned = retrieveAssignedQuantity(change.data)
    setMeta(({ qtyToAssign }) => calculateMetaState(qtyToAssign, qtyAssigned, quantityDecimalScaleRef.current))
  }

  const tableState = useTableState<LotSerialEntryRow>({ data: [], onChange: onTableStateChange })

  const {
    addItem,
    getState,
    validate: validateTableState,
    resetAsync,
    resetValidatorAsync,
    validateAsync: validateTableStateAsync,
  } = tableState

  const { InventoryAdjustmentUtilsService } = useInventoryAdjustmentModule()

  const resetState = useCallback<(itemSite: IItemSite, qtyToAssign: number) => Promise<void>>(
    async (site, qtyToAssign) => {
      try {
        setLoading(true)
        const { defaultLocation, lotSerialOptions } = await resetLocations(site, qtyToAssign)
        const { defaultNumbers, options } = await resetLotSerialNumberOptions(site, qtyToAssign, lotSerialOptions)
        const { data, meta: metaState } = defineTableInitialData(
          defaultLocation,
          defaultNumbers,
          options,
          site,
          qtyToAssign,
          quantityDecimalScaleRef.current
        )

        const validationSchema = defineLotSerialEntryValidation(site, qtyToAssign < 0, lotSerialTableRef, InventoryAdjustmentUtilsService)
        await resetAsync(data, defineLotSerialNumberValidatorAsync(validationSchema))
        setMeta(metaState)
      } catch (e) {
        ErrorHandler.handleError(e)
        const validationSchema = defineLotSerialEntryValidation(site, qtyToAssign < 0, lotSerialTableRef, InventoryAdjustmentUtilsService)
        await resetValidatorAsync(defineLotSerialNumberValidatorAsync(validationSchema))
        setMeta(({ qtyAssigned }) => calculateMetaState(qtyToAssign, qtyAssigned, quantityDecimalScaleRef.current))
      } finally {
        setLoading(false)
      }
    },
    [ErrorHandler, resetLocations, resetLotSerialNumberOptions, resetAsync, resetValidatorAsync, InventoryAdjustmentUtilsService]
  )

  const updateState = useCallback<(itemSite: IItemSite, qtyToAssign: number) => Promise<void>>(
    async (site, qtyToAssign) => {
      try {
        setLoading(true)
        let lotSerialNumbers: LotSerialOption[] | null = null

        if (supportsSerialAutomationMode(site, qtyToAssign)) {
          const { defaultNumbers } = await resetLotSerialNumberOptions(site, qtyToAssign)
          lotSerialNumbers = defaultNumbers
        }

        const { data } = getState()

        const tableData = updateTableData(data, site, qtyToAssign, getDefaultLocation(), lotSerialNumbers, quantityDecimalScaleRef.current)

        if (tableData) {
          setMeta(tableData.meta)
          await resetAsync(tableData.data)
        } else {
          setMeta(({ qtyAssigned }) => calculateMetaState(qtyToAssign, qtyAssigned, quantityDecimalScaleRef.current))
        }
      } catch (e) {
        ErrorHandler.handleError(e)
        setMeta(({ qtyAssigned }) => calculateMetaState(qtyToAssign, qtyAssigned, quantityDecimalScaleRef.current))
      } finally {
        setLoading(false)
      }
    },
    [ErrorHandler, getDefaultLocation, getState, resetLotSerialNumberOptions, resetAsync]
  )

  useEffect(() => {
    itemSiteSubjectRef.current = new Subject<IItemSite | null>()

    if (!qtyToAssign$) {
      return
    }

    const qtyToAssignChange$ = qtyToAssign$.pipe(
      startWith(undefined),
      pairwise(),
      filter<[number | undefined, number | undefined], [number | undefined, number]>(isValidQtyToAssignPayload),
      map(([prev, current]) => ({
        qtyToAssign: current,
        signChanged: prev === undefined || Math.sign(prev) !== Math.sign(current),
      }))
    )

    const itemSiteChange$ = itemSiteSubjectRef.current.asObservable().pipe(tap((site) => setItemSite(site)))

    const sub = combineLatest([qtyToAssignChange$, itemSiteChange$])
      .pipe(
        switchMap(async ([{ qtyToAssign, signChanged }, site]) => {
          if (!site || !isValidItemSitePayload(site)) {
            await resetAsync([])
            setMeta(defaultLotSerialMetaState)
            return
          }
          if (signChanged) {
            return resetState(site, qtyToAssign)
          } else {
            return updateState(site, qtyToAssign)
          }
        })
      )
      .subscribe()

    return () => sub.unsubscribe()
  }, [qtyToAssign$, resetState, resetAsync, updateState])

  const reset = useCallback<(item: IItem | null, site: IItemSite | null) => void>((item, site) => {
    itemSiteSubjectRef.current?.next(site)
    const decimalScale = !!item?.fractional ? fractionalQuantityDecimalScale : defaultQuantityDecimalScale
    quantityDecimalScaleRef.current = decimalScale
    setQuantityDecimalScale(decimalScale)
  }, [])

  const addLotSerialEntry = useCallback(() => {
    addItem(generateLotSerialItemPlaceholder(null, null, 0, 0))
  }, [addItem])

  const validate = useCallback<ILotSerialHook['validate']>(() => {
    if (!validateTableState()) {
      return { isValid: false }
    }
    const { qtyToAssign, qtyAssigned } = meta

    if (meta.qtyRemaining !== 0) {
      return { isValid: false, error: lotSerialErrors.invalidQuantityAssigned(qtyToAssign, qtyAssigned) }
    }

    if (itemSite && itemSite.control_method === ControlMethodOption.SerialNumber) {
      const { data } = getState()
      const duplicatedSerialNumber = findDuplicatedSerialNumber(data)
      if (duplicatedSerialNumber) {
        return { isValid: false, error: lotSerialErrors.duplicatedSerialNumber(duplicatedSerialNumber) }
      }
    }

    return { isValid: true }
  }, [getState, itemSite, meta, validateTableState])

  const validateAsync = useCallback<ILotSerialHook['validateAsync']>(async () => {
    if (!(await validateTableStateAsync())) {
      return { isValid: false }
    }
    const { qtyToAssign, qtyAssigned } = meta
    if (meta.qtyRemaining !== 0) {
      return { isValid: false, error: lotSerialErrors.invalidQuantityAssigned(qtyToAssign, qtyAssigned) ?? undefined }
    }
    const { data } = getState()
    const duplicatedSerialNumber = findDuplicatedSerialNumber(data)
    if (duplicatedSerialNumber) {
      return { isValid: false, error: lotSerialErrors.duplicatedSerialNumber(duplicatedSerialNumber) ?? undefined }
    }
    return { isValid: true }
  }, [getState, itemSite, meta, validateTableState])

  return {
    ...tableState,
    ...meta,
    loading,
    locations: locationState.locations,
    defaultLocation: locationState.defaultLocation,
    lotSerialNumberOptions: lotSerialNumberState.lotSerialNumberOptions,
    reset,
    addLotSerialEntry,
    validate,
    itemSite,
    quantityDecimalScale,
    validateAsync,
  }
}
