import { Overwrite } from 'utility-types'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from 'core/injector/injector.service'
import { moduleHookResolver } from '../../core/react-lazy-modules/react-lazy-modules.utils'
import { IInventoryAdjustmentModuleServices, InventoryAdjustmentModuleDefinition } from './inventory-adjustments.module'
import {
  inventoryAdjustmentModule,
  inventoryAdjustmentServiceToken,
  inventoryAdjustmentUtilsServiceToken,
} from './inventory-adjustments.constants'
import { IInventoryAdjustmentUtilsService } from './inventory-adjustments-utils.service'
import { IInventoryAdjustmentService } from './inventory-adjustments.service'
import { IRegistrationService } from './inventory-adjustment-details/lot-serial/lot-serial-dialog/registration/registration.service'
import {
  registrationServiceToken,
  registrationUtilsServiceToken,
} from './inventory-adjustment-details/lot-serial/lot-serial-dialog/registration/registration.constants'
import { IRegistrationUtilsService } from './inventory-adjustment-details/lot-serial/lot-serial-dialog/registration/registration-utils.service'

type InventoryAdjustmentModuleHook = Overwrite<InventoryAdjustmentModuleDefinition, IInventoryAdjustmentModuleServices>

function resolveInventoryAdjustmentModule(): InventoryAdjustmentModuleHook {
  const module = LazyModulesService.resolveModule<InventoryAdjustmentModuleDefinition>(inventoryAdjustmentModule)
  const InventoryAdjustmentService = Injector.resolve<IInventoryAdjustmentService>(inventoryAdjustmentServiceToken)
  const InventoryAdjustmentUtilsService = Injector.resolve<IInventoryAdjustmentUtilsService>(inventoryAdjustmentUtilsServiceToken)
  const RegistrationService = Injector.resolve<IRegistrationService>(registrationServiceToken)
  const RegistrationUtilsService = Injector.resolve<IRegistrationUtilsService>(registrationUtilsServiceToken)

  return { ...module, InventoryAdjustmentService, InventoryAdjustmentUtilsService, RegistrationService, RegistrationUtilsService }
}

export const useInventoryAdjustmentModule = moduleHookResolver<InventoryAdjustmentModuleHook>(resolveInventoryAdjustmentModule)
