import { Overwrite } from 'utility-types'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from 'core/injector/injector.service'
import { AuthModule, AuthModuleDefinition, IAuthModuleServices } from './auth.module'
import { IAuthService } from './auth.types'
import { authServiceToken, permissionsServiceToken } from './auth.constants'
import { IPermissionsService } from './permissions.service'
import { moduleHookResolver } from '../core/react-lazy-modules/react-lazy-modules.utils'

function resolveAuthModule(): Overwrite<AuthModuleDefinition, IAuthModuleServices> {
  const module = LazyModulesService.resolveModule<AuthModuleDefinition>(AuthModule)
  const AuthService = Injector.resolve<IAuthService>(authServiceToken)
  const PermissionsService = Injector.resolve<IPermissionsService>(permissionsServiceToken)

  return { ...module, AuthService, PermissionsService }
}

export const useAuthModule = moduleHookResolver(resolveAuthModule)
