import type { IHttpClient } from 'core/services/http-client'
import type { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import type { IOpportunitiesKanbanService } from './opportunities-kanban/opportunities-kanban.service'
import type { IOpportunitiesKanbanUtilsService } from './opportunities-kanban/opportunities-kanban-utils.service'
import { httpClientToken } from 'core/services/http-client'
import { CoreModule } from 'core/core.module'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { SharedModule } from 'shared/shared-module'
import { AuthModule } from 'auth/auth.module'
import { CommentsModule } from 'comments/comments.module'
import { CharacteristicsModule } from 'characteristics/characteristics.module'
import { DocumentsModule } from 'documents/documents.module'
import { DictionaryModule } from 'dictionary/dictionary.module'
import { ContactsModule } from 'contacts/contacts.module'
import { AccountsServicesModule } from 'companies/accounts/services/accounts-services.module'
import { TasksModule } from 'tasks/tasks.module'
import { KanbanModule } from 'kanban/kanban.module'
import { OpportunitiesServicesModule } from './services/opportunities-services.module'
import { opportunitiesKanbanServiceToken, opportunitiesKanbanUtilsServiceToken, opportunitiesModuleToken } from './opportunities.constants'

export type OpportunitiesModuleDefinition = typeof import('./opportunities.export')

export interface IOpportunitiesModuleService {
  OpportunitiesKanbanService: IOpportunitiesKanbanService
  OpportunitiesKanbanUtilsService: IOpportunitiesKanbanUtilsService
}

export const OpportunitiesModule: ILazyModule<OpportunitiesModuleDefinition> = {
  name: opportunitiesModuleToken,
  resolver: () => import('./opportunities.export'),
  initializer: ({ OpportunitiesKanbanService, OpportunitiesKanbanUtilsService }) => {
    const httpClient = Injector.resolve<IHttpClient>(httpClientToken)
    Injector.register(opportunitiesKanbanServiceToken, () => new OpportunitiesKanbanService(httpClient), Scope.Singleton)
    Injector.register(
      opportunitiesKanbanUtilsServiceToken,
      (injector) => {
        const service = injector.resolve<IOpportunitiesKanbanService>(opportunitiesKanbanServiceToken)
        return new OpportunitiesKanbanUtilsService(service)
      },
      Scope.Singleton
    )
  },
  dependencies: [
    CoreModule,
    SharedModule,
    KanbanModule,
    OpportunitiesServicesModule,
    AuthModule,
    CommentsModule,
    CharacteristicsModule,
    DocumentsModule,
    ContactsModule,
    DictionaryModule,
    TasksModule,
    AccountsServicesModule,
  ],
}
