import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { HoldTypeField } from 'sales-shared/sales-orders.types'

export const salesOrdersRoutePath = '/orders/sales-orders'
export const salesOrdersModule = 'SalesOrdersModule'
export const salesOrdersUtilsServiceToken = 'SalesOrdersUtilsService'

export const holdTypeOptions = Object.values(HoldTypeField).map((holdType) => defineAutocompleteOption(holdType))

export const getSalesOrderPageTitle = (id: string = '') => `xTuple - ${id ? `Sales Order: ${id}` : 'Create Sales Order'}`
