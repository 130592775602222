import { IAsyncRoute, IRoute } from 'common/routing/routing.types'
import { maintainShipmentsLabel, maintainShippingRoutePath } from './maintain-shipping.constants'
import { MaintainShippingModule, MaintainShippingModuleDefinition } from './maintain-shipping.module'
import { MaintainShippingGuard } from './maintain-shipping.guard'

export const maintainShippingRoute: IAsyncRoute<MaintainShippingModuleDefinition> = {
  name: maintainShipmentsLabel,
  path: maintainShippingRoutePath,
  module: MaintainShippingModule,
  guards: [MaintainShippingGuard],
  childrenRoutesFactory: ({ MaintainShipping }: MaintainShippingModuleDefinition): IRoute[] => {
    return [
      {
        path: '',
        exact: true,
        component: MaintainShipping,
      },
    ]
  },
}
