import { IAttachedDocument, IUpdateAttachedFileDocumentRequest } from '../../documents/documents.types'

export function extractFileNameFromPath(path: string): string {
  const matchResult = path.match(/^.*?([^\\/.]*)[^\\/]*$/) ?? []
  return matchResult.length > 0 ? matchResult[1] : 'N/A'
}

export const savePDFFile = (blob: Blob, filename: string): void => {
  const url = window.URL.createObjectURL(blob)
  // we create a node that will allow us to set a name for the file and download it
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = filename
  anchor.click()

  setTimeout(() => {
    window.URL.revokeObjectURL(url)
    anchor.remove()
  }, 0)
}

export function convertToUpdateType({
  id,
  name,
  notes,
  document_characteristics,
}: IAttachedDocument): IUpdateAttachedFileDocumentRequest[] {
  return [
    {
      id,
      name,
      document_notes: notes,
      document_characteristics: document_characteristics ?? [],
    },
  ]
}
