import React, { FC, useEffect, useState } from 'react'
import { ClickAwayListener, TableRow } from '@material-ui/core'
import { XtInfoPopupIcon } from 'components/info-popup-icon/info-popup-icon'
import { TableEditableCell } from 'components/table/table-editable-cell/table-editable-cell'
import { XtTableCell } from 'components/table/table-cell/table-cell'
import { cls } from 'common/utils/utils'
import { ItemSiteDistributionOption } from 'products/items/items.types'
import { convertItemSiteDistributionDate } from 'products/items/items.utils'
import { LotSerialEntryRow, LotSerialField, LotSerialOption } from '../lot-serial.types'
import { ILotSerialTableRow } from './lot-serial-table-row.types'
import {
  defineInitialLocationAndLotSerialData,
  defineLocationDataByLotSerial,
  defineLotSerialDataByLocation,
  retrieveColumnsSchema,
  shouldFilterLocationOrLotSerialOptions,
} from './lot-serial-table-row.utils'

import './lot-serial-table-row.scss'
import { LotSerialTableRowValidationPopup } from './lot-serial-table-row-validation-popup/lot-serial-table-row-validation-popup'

// We need to decrease the actions' cell height in order to display the validation styles correctly
const actionsCellHeight = 'calc(100% - 1px)'

/**
 * Component to display Lot/Serial table row. Handles Location and Lot/Serial dropdown updates
 * @param submitted
 * @param isNegativeQtyToAssign
 * @param data
 * @param columns
 * @param onChange
 * @param rowParams
 * @constructor
 */
export const LotSerialTableRow: FC<ILotSerialTableRow> = ({ submitted, isNegativeQtyToAssign, data, columns, onChange, rowParams }) => {
  const [editable, setEditable] = useState<boolean>(false)
  const [state, setState] = useState<LotSerialEntryRow>(data)

  const { selected, tabIndex, rowClasses, innerRef, draggableProps, dragHandleProps } = rowParams

  const columnsSchema = retrieveColumnsSchema(columns)

  const {
    actions,
    lotSerialNumber,
    location,
    documents,
    expirationDate,
    warrantyDate,
    quantity,
    quantityBefore,
    characteristics,
  } = columnsSchema

  const actionsColumn = { ...actions, height: actionsCellHeight }

  const [lotSerialOptions, setLotSerialOptions] = useState<LotSerialOption[]>(lotSerialNumber?.options ?? [])
  const [locations, setLocations] = useState<ItemSiteDistributionOption[]>(location?.options ?? [])

  useEffect(() => {
    if (shouldFilterLocationOrLotSerialOptions(isNegativeQtyToAssign, columnsSchema)) {
      const initialData = defineInitialLocationAndLotSerialData(
        columnsSchema[LotSerialField.Location].options,
        columnsSchema[LotSerialField.LotSerialNumber].options,
        data[LotSerialField.Location],
        data[LotSerialField.LotSerialNumber]
      )

      setState((prevState) => ({
        ...prevState,
        [LotSerialField.Location]: initialData.location,
        [LotSerialField.LotSerialNumber]: initialData.lotSerial,
        [LotSerialField.QuantityBefore]: initialData.location?.qty_before ?? 0,
      }))
      setLotSerialOptions(initialData.lotSerialOptions)
      setLocations(initialData.locations)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNegativeQtyToAssign])

  useEffect(() => {
    setState(data)
  }, [data])

  const handleClick: VoidFunction = () => {
    setEditable(true)
  }

  const handleClickOutside: VoidFunction = () => {
    if (editable) {
      setEditable(false)
      let newState = null

      setState((prevState) => {
        newState = { ...prevState, touched: true }
        return newState
      })

      if (newState) {
        onChange(newState)
      }
    }
  }

  const setValue: (key: keyof LotSerialEntryRow) => (value: LotSerialEntryRow[keyof LotSerialEntryRow]) => void = (key) => (value) => {
    setState((prevState) => ({ ...prevState, [key]: value }))
  }

  const handleLocationChange = (locationOption: ItemSiteDistributionOption | null): void => {
    let newLotSerialOptions: LotSerialOption[] | null = null

    setState((prevState) => {
      let currentLotSerial = prevState[LotSerialField.LotSerialNumber]

      const newState = {
        ...prevState,
        [LotSerialField.Location]: locationOption,
        [LotSerialField.QuantityBefore]: locationOption?.qty_before ?? 0,
      }

      if (locationOption && shouldFilterLocationOrLotSerialOptions(isNegativeQtyToAssign, columnsSchema)) {
        const lotSerialData = defineLotSerialDataByLocation(columnsSchema[LotSerialField.LotSerialNumber].options, locationOption)
        newLotSerialOptions = lotSerialData.lotSerialOptions
        currentLotSerial = lotSerialData.lotSerial
      }

      return {
        ...prevState,
        ...newState,
        [LotSerialField.LotSerialNumber]: currentLotSerial,
        [LotSerialField.WarrantyDate]: convertItemSiteDistributionDate(locationOption?.warranty_date),
        [LotSerialField.ExpirationDate]: convertItemSiteDistributionDate(locationOption?.expiry_date),
      }
    })

    if (newLotSerialOptions) {
      setLotSerialOptions(newLotSerialOptions)
    }
  }

  const handleLotSerialChange = (lotSerialOption: LotSerialOption | null): void => {
    let newLocationOptions: ItemSiteDistributionOption[] | null = null

    setState((prevState) => {
      let currentLocation = prevState[LotSerialField.Location]

      if (lotSerialOption && shouldFilterLocationOrLotSerialOptions(isNegativeQtyToAssign, columnsSchema)) {
        const locationData = defineLocationDataByLotSerial(columnsSchema[LotSerialField.Location].options, lotSerialOption)
        newLocationOptions = locationData.locations
        currentLocation = locationData.location
      }

      return {
        ...prevState,
        [LotSerialField.LotSerialNumber]: lotSerialOption,
        [LotSerialField.WarrantyDate]: lotSerialOption?.warranty_date ?? null,
        [LotSerialField.ExpirationDate]: lotSerialOption?.expiry_date ?? null,
        [LotSerialField.QuantityBefore]: lotSerialOption?.qty_before ?? 0,
        [LotSerialField.Location]: currentLocation,
        [LotSerialField.Characteristics]: [],
        [LotSerialField.Documents]: undefined,
      }
    })

    if (newLocationOptions) {
      setLocations(newLocationOptions)
    }
  }

  const shouldShowValidation = !state.isValid && (state.touched || submitted)

  return (
    <ClickAwayListener onClickAway={handleClickOutside}>
      <TableRow
        selected={selected}
        classes={rowClasses}
        component="div"
        ref={innerRef}
        {...draggableProps}
        {...dragHandleProps}
        onClick={handleClick}
        tabIndex={tabIndex}
        className={cls(
          'xt-lot-serial-table-row',
          editable && 'xt-lot-serial-table-row-editable',
          shouldShowValidation && 'xt-lot-serial-table-row-invalid'
        )}
      >
        {shouldShowValidation && state.errors && (
          <XtInfoPopupIcon
            title="Errors"
            className="xt-lot-serial-table-row-invalid-icon"
            popup={<LotSerialTableRowValidationPopup errors={state.errors} />}
          />
        )}
        {location && (
          <TableEditableCell
            disabled
            {...location}
            options={locations}
            editable={editable}
            value={state.location}
            onChange={handleLocationChange}
          />
        )}
        {lotSerialNumber && (
          <TableEditableCell
            disabled
            {...lotSerialNumber}
            options={lotSerialOptions}
            editable={editable}
            value={state.lotSerialNumber}
            onChange={handleLotSerialChange}
          />
        )}
        {expirationDate && (
          <TableEditableCell
            {...expirationDate}
            editable={editable}
            value={state.expirationDate}
            onChange={setValue(LotSerialField.ExpirationDate)}
          />
        )}
        {warrantyDate && (
          <TableEditableCell
            {...warrantyDate}
            editable={editable}
            value={state.warrantyDate}
            onChange={setValue(LotSerialField.WarrantyDate)}
          />
        )}
        {quantityBefore && <XtTableCell column={quantityBefore}>{state[LotSerialField.QuantityBefore]}</XtTableCell>}
        <TableEditableCell disabled {...quantity} editable={editable} value={state.quantity} onChange={setValue(LotSerialField.Quantity)} />
        {characteristics && characteristics.renderCell && (
          <XtTableCell className="xt-lot-serial-table-row-action-cell" column={characteristics}>
            {characteristics.renderCell(data)}
          </XtTableCell>
        )}
        {documents && documents.renderCell && (
          <XtTableCell className="xt-lot-serial-table-row-action-cell" column={documents}>
            {documents.renderCell(data)}
          </XtTableCell>
        )}
        <XtTableCell
          title="Actions"
          column={actionsColumn}
          className={shouldShowValidation ? 'xt-lot-serial-table-actions-cell-invalid' : ''}
        >
          {actions && actions.renderCell && actions.renderCell(data)}
        </XtTableCell>
      </TableRow>
    </ClickAwayListener>
  )
}
