import React, { FC, memo } from 'react'
import { ItemDetailsFormField } from '../../item-details.types'
import { ItemDetailsCard } from './components/item-details-card/item-details-card'
import * as styles from './details-tab.module.scss'
import { ItemSoldCard } from './components/item-sold-card/item-sold-card'
import { ItemWeightCard } from './components/item-weight-card/item-weight-card'
import { isItemCardDisabled } from './details-tab.utils'
import { IDetailsTab } from './details-tab.types'

export const DetailsTab: FC<IDetailsTab> = memo(
  ({ formMethods, disabled, isEditMode, onTaxTypeChange, taxTypes, item, isAnySiteSerial }) => {
    const { watch } = formMethods
    const itemType = watch(ItemDetailsFormField.ItemType)

    const disablingItemTypes = isItemCardDisabled(itemType)

    return (
      <div className={styles.detailsTabContainer}>
        <ItemDetailsCard
          formMethods={formMethods}
          disabled={disabled}
          isEditMode={isEditMode}
          currency={item?.currency ?? 'USD-$'}
          onTaxTypeChange={onTaxTypeChange}
          taxTypes={taxTypes}
          isAnySiteSerial={isAnySiteSerial}
        />
        <ItemSoldCard formMethods={formMethods} disabled={disabled || disablingItemTypes} />
        <ItemWeightCard formMethods={formMethods} disabled={disabled || disablingItemTypes} shouldClear={disablingItemTypes} />
      </div>
    )
  }
)
