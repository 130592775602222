import { PermissionGuard } from '../auth/permission.guard'
import { homeRoutePath } from '../home/home.constants'
import { UserPermission } from '../auth/auth.types'

export class ProductsGuard extends PermissionGuard {
  public redirectTo = homeRoutePath
  public permissions = [
    UserPermission.ViewItemMasters,
    UserPermission.MaintainItemMasters,
    UserPermission.ViewItemAvailabilityWorkbench,
    UserPermission.ViewBOMs,
    UserPermission.MaintainBOMs,
    UserPermission.ViewBOOs,
    UserPermission.MaintainBOOs,
  ]
}
