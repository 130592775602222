import React, { FC, useMemo, useState } from 'react'
import { useTable } from 'common/hooks/useTable'
import { cls } from 'common/utils/utils'
import { XtList } from 'components/list/list'
import { markTableItemAsSelected } from './elements.utils'
import { CostElementsColumns } from './elements.constants'
import * as styles from './elements.module.scss'
import { CostTableItem } from '../../../../../items.types'
import { useProductsModule } from '../../../../../../products-module-hook'

interface ICostElements {
  itemNumber: string
  isMobile: boolean
}

export const CostElements: FC<ICostElements> = ({ itemNumber, isMobile }) => {
  const { ItemsUtilsService } = useProductsModule()

  const [selectedItem, setSelectedItem] = useState<CostTableItem | null>()
  const { state } = useTable({ itemNumber }, ItemsUtilsService.fetchCost)

  const tableData = useMemo(() => markTableItemAsSelected(state.data, selectedItem?.id), [state.data, selectedItem?.id])

  const selectItem: (item: CostTableItem) => void = (item) => {
    setSelectedItem(item)
  }

  return (
    <div className={cls('xt-common-table-list', styles.elementsContainer)}>
      <XtList data={tableData} columns={CostElementsColumns} onRowClick={selectItem} isMobile={isMobile} />
    </div>
  )
}
