import type { IKanbanOpportunityColumn } from '../opportunities-board.types'
import React, { FC } from 'react'
import * as styles from './opportunity-kanban-column-header.module.scss'

export const OpportunityKanbanColumnHeader: FC<IKanbanOpportunityColumn> = ({ title, opportunityCounter, styles: opportunitiesStyles }) => {
  const total = `(${opportunityCounter ?? 0})`
  const backgroundColor = opportunitiesStyles?.headerColor
  const headerTitle = title || 'N/A'

  return (
    <div className={styles.columnHeader} style={{ backgroundColor }}>
      <span className="xt-text-truncated" title={headerTitle}>
        {headerTitle}
      </span>
      <span className="xt-text-truncated">{total}</span>
    </div>
  )
}
