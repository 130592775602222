import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { NewComment } from 'comments/comments.types'
import { IAttachedDocumentWithFile } from 'documents/documents.types'
import { IItemSite } from 'products/items/items.types'
import { IRegistration } from './registration/registration.types'
import { LotSerialEntryRow } from '../lot-serial.types'

export enum LotAndSerialFormField {
  ItemNumber = 'item_number',
  LotSerial = 'lot_serial_number',
}

export enum LotSerialFormLabel {
  ItemNumber = 'Item Number',
  LotSerial = 'Lot/Serial #',
}

export enum LotSerialDialogTab {
  Characteristics = 'Characteristics',
  Registrations = 'Registrations',
  Remarks = 'Remarks',
  Documents = 'Documents',
}

export interface ILotSerialDialogOutput {
  itemNumber: string
  lotSerialNumber: string
  registrations: IRegistration[]
  characteristics: IAppliedCharacteristic[]
  comments: NewComment[]
  notes: string
  documents: IAttachedDocumentWithFile[]
}

export interface ILotSerialDialog {
  itemSite: IItemSite
  item: LotSerialEntryRow
  lotSerialNumber: string
  onClose: () => void
  onClientSubmit(updatedLotSerialEntry: LotSerialEntryRow): void
}
