import { Overwrite } from 'utility-types'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from 'core/injector/injector.service'
import { ICharacteristicService } from './characteristics.sevice'
import { characteristicsModuleToken, characteristicsServiceToken, characteristicsUtilsServiceToken } from './characteristics.constants'
import { ICharacteristicsUtilsService } from './characteristics-utils.service'
import { moduleHookResolver } from '../core/react-lazy-modules/react-lazy-modules.utils'
import { CharacteristicsModuleDefinition, ICharacteristicsModuleServices } from './characteristics-module.types'

function resolveCharacteristicsModule(): Overwrite<CharacteristicsModuleDefinition, ICharacteristicsModuleServices> {
  const module = LazyModulesService.resolveModule<CharacteristicsModuleDefinition>(characteristicsModuleToken)
  const CharacteristicsService = Injector.resolve<ICharacteristicService>(characteristicsServiceToken)
  const CharacteristicsUtilsService = Injector.resolve<ICharacteristicsUtilsService>(characteristicsUtilsServiceToken)

  return { ...module, CharacteristicsService, CharacteristicsUtilsService }
}

export const useCharacteristicsModule = moduleHookResolver(resolveCharacteristicsModule)
