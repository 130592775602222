import { ILazyModule } from '../core/react-lazy-modules/react-lazy-modules.types'
import { pageNotFoundModule } from './page-not-found.constants'

export type PageNotFoundModuleDefinition = typeof import('./page-not-found.exports')

export const PageNotFoundModule: ILazyModule<PageNotFoundModuleDefinition> = {
  name: pageNotFoundModule,
  resolver: () => import('./page-not-found.exports'),
  dependencies: [],
}
