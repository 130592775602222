import { IAccount } from 'companies/accounts/accounts.types'
import { dateToServerFormat, parseServerDate } from 'common/utils/date.utils'
import { ISalesOrder } from 'sales-shared/sales-orders.types'
import { defineAutocompleteOption, parseAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { ContactOption } from 'contacts/contacts.types'
import { RegistrationDialogForm, RegistrationDialogFormFilled, RegistrationDialogFormField } from './registration-dialog.types'
import { IRegistration, IRegistrationContact, IRegistrationPayload } from '../registration.types'

export function convertRegistrationPayload(
  data: RegistrationDialogFormFilled,
  registration_characteristics: IAppliedCharacteristic[],
  lotSerialNumber: string,
  itemNumber: string
): IRegistrationPayload {
  return {
    [RegistrationDialogFormField.RegistrationNumber]: data.number,
    [RegistrationDialogFormField.RegistrationType]: parseAutocompleteOption(data.registration_type),
    [RegistrationDialogFormField.Qty]: data.quantity,
    [RegistrationDialogFormField.AccountNumber]: parseAutocompleteOption(data.account_number),
    [RegistrationDialogFormField.AccountName]: data.account_name ?? '',
    [RegistrationDialogFormField.SalesOrder]: data.salesorder ? parseAutocompleteOption(data.salesorder) : null,
    [RegistrationDialogFormField.Shipment]: data.shipment ? parseAutocompleteOption(data.shipment) : null,
    [RegistrationDialogFormField.RegisterDate]: dateToServerFormat(data.register_date) ?? null,
    [RegistrationDialogFormField.SaleDate]: dateToServerFormat(data.sale_date) ?? null,
    [RegistrationDialogFormField.ExpireDate]: dateToServerFormat(data.expire_date) ?? null,
    [RegistrationDialogFormField.Notes]: data.notes,
    registration_characteristics,
    contact_number: data.registration_contact.contact_number,
    lotserial: lotSerialNumber,
    item_number: itemNumber,
  }
}

function convertRegistrationContactToContactOption(registrationContact: IRegistrationContact | undefined): ContactOption | null {
  if (!registrationContact) {
    return null
  }

  const {
    contact_number,
    contact_honorific,
    contact_first,
    contact_middle,
    contact_last,
    contact_suffix,
    contact_job_title,
    contact_phones,
    contact_email,
    contact_web,
    contact_address,
  } = registrationContact

  return {
    contact_number,
    honorific: contact_honorific,
    first_name: contact_first,
    middle_name: contact_middle,
    last_name: contact_last,
    suffix: contact_suffix,
    job_title: contact_job_title,
    phones: contact_phones,
    email: contact_email,
    web: contact_web,
    contact_address,
    company: '',
    id: contact_number,
    label: `${contact_honorific} ${contact_first} ${contact_middle} ${contact_last}`,
  }
}

export function defineRegistrationFormValues(
  defaultRegistrationType: IXtAutocompleteOption,
  data?: IRegistration,
  account?: IAccount,
  salesOrder?: ISalesOrder | null
): RegistrationDialogForm {
  return {
    [RegistrationDialogFormField.RegistrationNumber]: data?.number ?? '',
    [RegistrationDialogFormField.RegistrationType]: data?.registration_type
      ? defineAutocompleteOption(data.registration_type)
      : defaultRegistrationType,
    [RegistrationDialogFormField.LotSerial]: data?.lotserial ?? '',
    [RegistrationDialogFormField.Qty]: data?.quantity ?? null,
    [RegistrationDialogFormField.AccountNumber]: account ? { ...account, id: account.number, label: account.number } : null,
    [RegistrationDialogFormField.AccountName]: account ? account.name : null,
    [RegistrationDialogFormField.SalesOrder]: salesOrder
      ? { ...salesOrder, id: salesOrder.order_number, label: salesOrder.order_number }
      : null,
    [RegistrationDialogFormField.Shipment]: defineAutocompleteOption(data?.shipment),
    [RegistrationDialogFormField.Contact]: convertRegistrationContactToContactOption(data?.registration_contact),
    [RegistrationDialogFormField.RegisterDate]: parseServerDate(data?.register_date),
    [RegistrationDialogFormField.SaleDate]: parseServerDate(data?.sale_date),
    [RegistrationDialogFormField.ExpireDate]: parseServerDate(data?.expire_date),
    [RegistrationDialogFormField.Notes]: data?.notes ?? '',
  }
}

export function isValidFormState(formState: RegistrationDialogForm): formState is RegistrationDialogFormFilled {
  const {
    registration_type,
    quantity,
    account_number,
    account_name,
    salesorder,
    shipment,
    registration_contact,
    register_date,
    sale_date,
    expire_date,
  } = formState
  return Boolean(
    registration_type &&
      quantity &&
      account_number &&
      account_name &&
      (salesorder || shipment) &&
      registration_contact &&
      register_date &&
      sale_date &&
      expire_date
  )
}
