import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { ISettingsService } from './settings.service'
import { settingsModuleToken, settingsServiceToken } from './settings.constants'

export type SettingsModuleDefinition = typeof import('./settings.exports')

export interface ISettingsModuleServices {
  SettingsService: ISettingsService
}

function settingsModuleInitializer({ SettingsService }: SettingsModuleDefinition): void {
  Injector.register<ISettingsService>(
    settingsServiceToken,
    (injector) => new SettingsService(injector.resolve<IHttpClient>(httpClientToken)),
    Scope.Singleton
  )
}

export const SettingsModule: ILazyModule<SettingsModuleDefinition> = {
  name: settingsModuleToken,
  resolver: () => import('./settings.exports'),
  initializer: settingsModuleInitializer,
  dependencies: [],
}
