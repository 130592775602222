import { CSSProperties } from 'react'
import { isSafari } from 'react-device-detect'
import { deepEqual } from 'fast-equals'
import { resolvePath } from 'common/utils/object.utils'
import { IXtTableColumn, TableValueConverter } from './table-head/table-head.types'
import { ITableRow, IXtTableProps } from './table.types'

export function definePositionStyles(isSticky: boolean = false): CSSProperties {
  return !isSticky
    ? {}
    : {
        position: isSafari ? '-webkit-sticky' : 'sticky',
        right: 0,
        backgroundColor: 'inherit',
      }
}

export function getFieldValue<TData extends ITableRow>(data: TData, field: string, converter?: TableValueConverter): string {
  const fieldValue = resolvePath<string>(data, field) ?? ''
  return typeof converter !== 'function' ? fieldValue : converter(fieldValue)
}

export function arePropsEqual<TData extends ITableRow, TColumn extends IXtTableColumn<TData>>(
  prev: IXtTableProps<TData, TColumn>,
  current: IXtTableProps<TData, TColumn>
): boolean {
  const { rows: prevRows, columns: prevColumns, sortOptions: prevSortOptions, ...prevRest } = prev
  const { rows: currentRows, columns: currentColumns, sortOptions: currentSortOptions, ...currentRest } = current

  if (prevRows !== currentRows || prevColumns !== currentColumns || prevSortOptions !== currentSortOptions) {
    return false
  }
  return deepEqual(prevRest, currentRest)
}
