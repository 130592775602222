import { FormRadioGroupOptions } from 'components/controls/xt-radio-group/xt-radio-group'
import { CostsOptionField, CostsOptionLabel, FormSharedField, IBomListSharedForm } from './shared.types'

export const costsOptions: FormRadioGroupOptions = [
  { label: CostsOptionLabel.Standard, id: CostsOptionField.Standard },
  { label: CostsOptionLabel.Actual, id: CostsOptionField.Actual },
]

export const defaultBomListSharedForm: IBomListSharedForm = {
  [FormSharedField.CostsOption]: CostsOptionField.Standard,
  [FormSharedField.ItemDetails]: null,
  [FormSharedField.Revision]: null,
}
