// extracted by mini-css-extract-plugin
export var routingItemDialog = "routing-item_routingItemDialog__BSuKN";
export var routingItemContent = "routing-item_routingItemContent__pGfKs";
export var leftSideForm = "routing-item_leftSideForm__+zVWC";
export var itemHeaderBlock = "routing-item_itemHeaderBlock__fKGvK";
export var itemNumberText = "routing-item_itemNumberText__P1tQH";
export var uomText = "routing-item_uomText__8X8HS";
export var field = "routing-item_field__AGgGA";
export var buttons = "routing-item_buttons__H8bsQ";
export var buttonsViewMode = "routing-item_buttonsViewMode__K-yXh";
export var routingTabsContainer = "routing-item_routingTabsContainer__hjM0O";