import React from 'react'
import { IXtAutocompleteOption, XtAutocompleteValue } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import './xt-autocomplete.scss'
import { IPaginationData } from 'common/common.types'

export function defineAutocompleteOption(id: string, label?: string): IXtAutocompleteOption
export function defineAutocompleteOption(id: string | null | undefined, label?: string): IXtAutocompleteOption | null

export function defineAutocompleteOption(id: string | null | undefined, label?: string): IXtAutocompleteOption | null {
  return id ? { id, label: label ?? id } : null
}

// TODO return null instead of empty string
export function parseAutocompleteOption(data: IXtAutocompleteOption | null): string {
  return data?.id ? data.id : ''
}

export function isAutocompleteValue(value: unknown): value is IXtAutocompleteOption {
  return typeof value === 'object' && value !== null && 'id' in value && 'label' in value
}

export function getLabel(option: IXtAutocompleteOption | null): string {
  return option?.label ?? ''
}

export function getAutocompleteInputLabelAsId(option: IXtAutocompleteOption | null): string {
  return option ? option.id : ''
}

export function getAutocompleteInputLabelInline(option: IXtAutocompleteOption | null): string {
  return option ? `${option.id}-${option.label}` : ''
}

export function renderAutocompleteOption(option: IXtAutocompleteOption): React.ReactElement {
  return (
    <div title={option.label} className="xt-autocomplete-container" key={option.id}>
      <div className="xt-text-truncated">{option.label}</div>
    </div>
  )
}

export function renderInlineOption(option: IXtAutocompleteOption): React.ReactElement {
  return (
    <div className="xt-autocomplete-container">
      <div className="xt-text-truncated">{getAutocompleteInputLabelInline(option)}</div>
    </div>
  )
}

export function renderColumnOption(option: IXtAutocompleteOption): React.ReactElement {
  const title = `${option.id?.toString()}\n${option.label}`
  return (
    <div className="xt-autocomplete-container" title={title}>
      <div className="xt-text-truncated">{option.id?.toString()}</div>
      <div className="xt-text-truncated">{option.label}</div>
    </div>
  )
}

export function getAutocompleteTitle<Option extends IXtAutocompleteOption>(value: XtAutocompleteValue<Option>): string | undefined {
  if (Array.isArray(value)) {
    return value.reduce<string>((res, { label }) => (res ? `${res}, ${label}` : label), '')
  }
  return value?.id
}

export function convertAutocompleteValue<Option extends IXtAutocompleteOption>(
  value: XtAutocompleteValue<Option>,
  multiple: boolean = false
): XtAutocompleteValue<Option> {
  if (multiple) {
    return Array.isArray(value) ? value : []
  }
  if (!value || typeof value !== 'object') {
    return null
  }
  return 'id' in value! && 'label' in value ? (value as Option) : null
}

export function buildInputProps(props: object, manualInputDisabled: boolean): object {
  return { ...props, disabled: manualInputDisabled }
}

export function isAutocompleteOptionDisabled(option: IXtAutocompleteOption | null): boolean {
  return !!option?.disabled
}

function retrieveOptionsFromResponse(
  request: PromiseSettledResult<IPaginationData<IXtAutocompleteOption> | undefined>
): IXtAutocompleteOption[] {
  if (request?.status !== 'fulfilled' || !request.value?.data.length) {
    return []
  }
  return request.value.data.map((data) => ({ id: data.id, label: data.label }))
}

export async function requestOptions(
  optionGetters: Array<Promise<IPaginationData<IXtAutocompleteOption>>>
): Promise<Array<IXtAutocompleteOption[]>> {
  const options = await Promise.allSettled(optionGetters)
  return options.map(retrieveOptionsFromResponse)
}
