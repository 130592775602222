// extracted by mini-css-extract-plugin
export var root = "leads-list_root__04RUE";
export var xtContent = "leads-list_xt-content__BWJty";
export var xtContentWithRemarks = "leads-list_xt-content-with-remarks__2xSUO";
export var muiFormHelperTextRoot = "leads-list_MuiFormHelperText-root__5Nlq9";
export var xtListPageContainer = "leads-list_xt-list-page-container__6NHmw";
export var listContent = "leads-list_listContent__aggwy";
export var muiInputBaseRoot = "leads-list_MuiInputBase-root__h0+1z";
export var muiTextFieldRoot = "leads-list_MuiTextField-root__WhC1l";
export var muiTablePaginationRoot = "leads-list_MuiTablePagination-root__CZo6y";
export var muiTablePaginationSelectRoot = "leads-list_MuiTablePagination-selectRoot__Wduwb";
export var muiTablePaginationSelect = "leads-list_MuiTablePagination-select__bb7Mb";
export var muiStepperRoot = "leads-list_MuiStepper-root__S56O+";
export var muiStepperHorizontal = "leads-list_MuiStepper-horizontal__9bJyE";
export var muiStepIconRoot = "leads-list_MuiStepIcon-root__4wKza";
export var muiStepIconActive = "leads-list_MuiStepIcon-active__UYu-w";
export var muiStepIconCompleted = "leads-list_MuiStepIcon-completed__vIltZ";
export var muiListItemRoot = "leads-list_MuiListItem-root__R1MH5";
export var muiSelected = "leads-list_Mui-selected__k3arU";
export var xtPageTitle = "leads-list_xt-page-title__6Vrly";
export var xtSubPageTitle = "leads-list_xt-subPage-title__pFUtk";
export var xtSectionBorder = "leads-list_xt-section-border__rmxNW";
export var xtPageHeader = "leads-list_xt-page-header__-W4vO";
export var xtDialogScrollable = "leads-list_xt-dialog-scrollable__GVYcr";
export var reactToastNotificationsContainer = "leads-list_react-toast-notifications__container__9F7y5";
export var xtScrollbarStyle = "leads-list_xt-scrollbar-style__PseZo";
export var xtModalDetailsContent = "leads-list_xt-modal-details-content__MLZk1";
export var xtDialogDetailsContent = "leads-list_xt-dialog-details-content__+Bj+M";
export var xtLazyModuleLoaderTabContainer = "leads-list_xt-lazy-module-loader-tab-container__jdcso";
export var xtTextTruncated = "leads-list_xt-text-truncated__aarkt";
export var xtSummaryContent = "leads-list_xt-summary-content__6BfdS";
export var xtSummaryLabel = "leads-list_xt-summary-label__Ty+YS";
export var visibleHidden = "leads-list_visible-hidden__-IJIp";
export var headerBottomMargin = "leads-list_header-bottom-margin__NCPHX";
export var xtDialogHeaderLogoAlign = "leads-list_xt-dialog-header-logoAlign__SldDS";
export var xtStickyHeader = "leads-list_xt-sticky-header__qWo2b";
export var noBorder = "leads-list_no-border__apdIw";
export var muiOutlinedInputNotchedOutline = "leads-list_MuiOutlinedInput-notchedOutline__ftd+4";
export var listControls = "leads-list_listControls__4FhwE";
export var headerContent = "leads-list_headerContent__um0p4";
export var filterButton = "leads-list_filterButton__b6Ov5";
export var contactsListCheckbox = "leads-list_contactsListCheckbox__0EbnA";
export var filtersContainer = "leads-list_filtersContainer__ZxJqp";