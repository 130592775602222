import { convertFiltersPanelState } from 'common/utils/filter.utils'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { ITasksKanbanService } from './tasks-kanban.service'
import { IKanbanTasksFilterPanel } from './tasks-kanban.types'
import { IKanbanTaskCard, IKanbanTaskColumn } from './tasks-board/tasks-board.types'
import { convertToKanbanColumn } from './tasks-board/tasks-board.utils'

export interface ITasksKanbanUtilsService {
  fetchTaskColumn(priority: string, filters?: IKanbanTasksFilterPanel): Promise<IKanbanTaskColumn>
  requestColumns(filters: IKanbanTasksFilterPanel): Promise<IKanbanTaskColumn[]>
  fetchTaskCards(priority: string, _: IPaginationParams, filters?: IKanbanTasksFilterPanel): Promise<IPaginationData<IKanbanTaskCard>>
}

export class TasksKanbanUtilsService implements ITasksKanbanUtilsService {
  constructor(private readonly tasksKanbanService: ITasksKanbanService) {
    bindAllInstanceMethods(this)
  }

  public async requestColumns(filters: IKanbanTasksFilterPanel): Promise<IKanbanTaskColumn[]> {
    const processedFilters = convertFiltersPanelState(filters)
    const taskPayloads = await this.tasksKanbanService.getTasks(processedFilters)

    return taskPayloads.map(convertToKanbanColumn)
  }

  public async fetchTaskColumn(priority: string, filters?: IKanbanTasksFilterPanel): Promise<IKanbanTaskColumn> {
    const task = await this.tasksKanbanService.getTaskByPriority(priority, filters)

    return convertToKanbanColumn(task)
  }

  public async fetchTaskCards(
    priority: string,
    _: IPaginationParams,
    filters?: IKanbanTasksFilterPanel
  ): Promise<IPaginationData<IKanbanTaskCard>> {
    const { data } = await this.fetchTaskColumn(priority, filters)

    return {
      data,
      total: data.length,
    }
  }
}
