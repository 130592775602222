import { FC, useCallback, useEffect, useState } from 'react'
import * as React from 'react'
import { cls } from 'common/utils/utils'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { XtInfoPopupIcon } from 'components/info-popup-icon/info-popup-icon'
import * as styles from './bom-item-counter.module.scss'
import { popupStyles } from './bom-item-counter.constants'
import { BomItemCounterErrorPopup } from './bom-item-error-popup/bom-item-error-popup'

export interface IBomItemCounterParams {
  value: number
  onChange: (value: number | null) => void
  disabled?: boolean
  error?: string
  hidden?: boolean
}

const counterFieldWidth = 44
const fontSizeCounterField = 18
export const minCounterValue = 10

const convertToCounter = (value: string): number => {
  // eslint-disable-next-line no-restricted-globals
  const numberValue: number = Number.parseInt(value, 10)
  if (!numberValue || Number.isNaN(numberValue) || Number.isFinite(value)) {
    return minCounterValue
  }
  return numberValue >= minCounterValue ? numberValue : minCounterValue
}

const calculateCountFieldWidth: (value: string) => number = (value) => {
  const additionalWidth = value?.length > 2 ? (value.length - 2) * fontSizeCounterField : 0
  return counterFieldWidth + additionalWidth
}

export const BomItemCounter: FC<IBomItemCounterParams> = ({ value, onChange, disabled = false, hidden = false, error }) => {
  const [inputValue, setInputValue] = useState<string>(minCounterValue.toString())

  useEffect(() => {
    if (value >= minCounterValue) {
      setInputValue((value ?? minCounterValue)?.toString())
    }
  }, [value])

  const onCounter: (sequenceCount: number) => void = (sequenceCount) => {
    const numValue = convertToCounter(inputValue)
    const result = numValue + sequenceCount
    const resultedValue = result >= minCounterValue ? result : minCounterValue
    setInputValue(resultedValue.toString())
    onChange(resultedValue)
  }

  const onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void = ({ target }) => {
    setInputValue(target.value)
  }

  const getInputWidth = useCallback(() => calculateCountFieldWidth(inputValue), [inputValue])

  const onBlur: (e: React.ChangeEvent<HTMLInputElement>) => void = ({ target }) => {
    const parsedValue = convertToCounter(target.value)
    setInputValue(parsedValue.toString())
    onChange(parsedValue)
  }

  return (
    <div hidden={hidden} className={styles.bomItemCounterSection}>
      <label className={styles.bomItemCounterLabel} htmlFor="sequence-label">
        Sequence #
      </label>
      <div className={error ? styles.bomItemCounterError : styles.bomItemCounter}>
        {error && (
          <XtInfoPopupIcon
            title="Error"
            popup={<BomItemCounterErrorPopup error={error} className={styles.bomItemCounterPopUpContent} />}
            className={styles.xtSeqNumberInvalidIcon}
            popupStyle={popupStyles}
          />
        )}
        <input
          disabled
          className={styles.bomItemCounterInput}
          onChange={onInputChange}
          onBlur={onBlur}
          style={{ width: `${getInputWidth()}px` }}
          value={inputValue}
          type="text"
        />
        <div className={cls(styles.bomItemCounterButtonsSection)}>
          <button type="button" disabled={disabled} onClick={() => onCounter(minCounterValue)} className={styles.bomItemCounterButton}>
            <SvgIcon iconId={SvgIconIds.COUNTER} className={styles.bomItemCounterButtonIcon} />
          </button>
          <button
            type="button"
            className={styles.bomItemCounterButton}
            disabled={value <= minCounterValue || disabled}
            onClick={() => onCounter(-minCounterValue)}
          >
            <SvgIcon
              className={cls(styles.bomItemCounterButtonIcon, styles.bomItemCounterButtonIconInverted)}
              iconId={SvgIconIds.COUNTER}
            />
          </button>
        </div>
      </div>
    </div>
  )
}
