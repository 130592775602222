import { PermissionGuard } from 'auth/permission.guard'
import { UserPermission } from 'auth/auth.types'
import { inventoryRoutePath } from 'inventory/inventory.constants'

export class ItemCostsGuard extends PermissionGuard {
  public redirectTo = inventoryRoutePath
  public permissions = [UserPermission.ViewCosts]
}

export class InventoryHistoryGuard extends PermissionGuard {
  public redirectTo = inventoryRoutePath
  public permissions = [UserPermission.ViewInventoryHistory]
}
