import { FilterType, IFilter } from 'components/pagefilter/filter/filter.types'
import { getAutocompleteInputLabelAsId, renderColumnOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { IDocumentsUtilsService } from 'documents/documents-utils.service'
import { convertFiltersPanelState } from 'common/utils/filter.utils'
import { ItemCostsByClassCodeFilter, ItemCostsByClassCodeFilters, ItemCostsByClassCodeLabel } from 'products/items/items.types'
import { ClassCodeFilter, ClassCodeFilters, ItemTypesField } from './by-class-code-list.types'
import { itemTypeOptions } from './by-class-code-list.constants'

export function resolverFilters({ loadClassCodesNumberAndDescription }: IDocumentsUtilsService): IFilter[] {
  return [
    {
      type: FilterType.Autocomplete,
      label: ItemCostsByClassCodeLabel.ClassCode,
      fieldName: ItemCostsByClassCodeFilter.ClassCode,
      autocompleteProps: {
        loadOptions: loadClassCodesNumberAndDescription,
        renderOption: renderColumnOption,
        getInputLabel: getAutocompleteInputLabelAsId,
      },
    },
    {
      type: FilterType.Text,
      label: ItemCostsByClassCodeLabel.ClassCodePattern,
      fieldName: ItemCostsByClassCodeFilter.ClassCodePattern,
    },
    {
      type: FilterType.DropdownMultiple,
      label: 'Item Types',
      fieldName: ClassCodeFilter.ItemTypes,
      options: itemTypeOptions,
    },
  ]
}

export function convertClassCodeFilters({ item_types, ...filters }: ClassCodeFilters): ItemCostsByClassCodeFilters {
  const filtersState: ItemCostsByClassCodeFilters = { ...filters }

  // converting multiple options to boolean filters
  item_types?.forEach(({ id }) => {
    filtersState[id as ItemTypesField] = true
  })

  return convertFiltersPanelState(filtersState)
}
