// extracted by mini-css-extract-plugin
export var root = "no-landscape_root__x0DRk";
export var xtContent = "no-landscape_xt-content__PXC-R";
export var xtContentWithRemarks = "no-landscape_xt-content-with-remarks__9-tGj";
export var muiFormHelperTextRoot = "no-landscape_MuiFormHelperText-root__80jE+";
export var xtListPageContainer = "no-landscape_xt-list-page-container__bXFNR";
export var muiInputBaseRoot = "no-landscape_MuiInputBase-root__Zf-Sp";
export var muiTextFieldRoot = "no-landscape_MuiTextField-root__URkJ5";
export var muiTablePaginationRoot = "no-landscape_MuiTablePagination-root__WXIhx";
export var muiTablePaginationSelectRoot = "no-landscape_MuiTablePagination-selectRoot__gKNXK";
export var muiTablePaginationSelect = "no-landscape_MuiTablePagination-select__yyPRc";
export var muiStepperRoot = "no-landscape_MuiStepper-root__0Jx7I";
export var muiStepperHorizontal = "no-landscape_MuiStepper-horizontal__itzx9";
export var muiStepIconRoot = "no-landscape_MuiStepIcon-root__lbHg7";
export var muiStepIconActive = "no-landscape_MuiStepIcon-active__dS1Ro";
export var muiStepIconCompleted = "no-landscape_MuiStepIcon-completed__9dYzh";
export var muiListItemRoot = "no-landscape_MuiListItem-root__XP+1h";
export var muiSelected = "no-landscape_Mui-selected__Pf2bh";
export var xtPageTitle = "no-landscape_xt-page-title__FqXhG";
export var xtSubPageTitle = "no-landscape_xt-subPage-title__Qqgof";
export var xtSectionBorder = "no-landscape_xt-section-border__w2rnW";
export var xtPageHeader = "no-landscape_xt-page-header__295v1";
export var xtDialogScrollable = "no-landscape_xt-dialog-scrollable__tvA1G";
export var reactToastNotificationsContainer = "no-landscape_react-toast-notifications__container__P+IIE";
export var xtScrollbarStyle = "no-landscape_xt-scrollbar-style__c8srs";
export var xtModalDetailsContent = "no-landscape_xt-modal-details-content__9caGE";
export var xtDialogDetailsContent = "no-landscape_xt-dialog-details-content__aQiRC";
export var xtLazyModuleLoaderTabContainer = "no-landscape_xt-lazy-module-loader-tab-container__6q3Fa";
export var xtTextTruncated = "no-landscape_xt-text-truncated__BVYiM";
export var xtSummaryContent = "no-landscape_xt-summary-content__C0m8J";
export var xtSummaryLabel = "no-landscape_xt-summary-label__OeS9+";
export var visibleHidden = "no-landscape_visible-hidden__tvH1t";
export var headerBottomMargin = "no-landscape_header-bottom-margin__1s50-";
export var xtDialogHeaderLogoAlign = "no-landscape_xt-dialog-header-logoAlign__0zQkM";
export var xtStickyHeader = "no-landscape_xt-sticky-header__QZrAN";
export var noBorder = "no-landscape_no-border__EpYsA";
export var muiOutlinedInputNotchedOutline = "no-landscape_MuiOutlinedInput-notchedOutline__7Rr79";
export var noLandscapeContainer = "no-landscape_noLandscapeContainer__X+Jw1";
export var noLandscapeText = "no-landscape_noLandscapeText__LusQV";