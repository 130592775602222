import { IShipping, IShippingLine, ShippingLineTableItem, IShippingReturnLinesInstance } from 'shipping/shipping.types'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { ISalesOrder, ISalesOrderItem } from 'sales-shared/sales-orders.types'
import { defineSalesPricesData } from 'sales-shared/sales-shared.utils'
import { parseServerDateWithDefault } from 'common/utils/date.utils'
import { ShippingLinesAction, ShippingSalesLineItemDialogState } from './shipping-extended-template.types'

export function normalizeShippingLine(shippingLines: IShippingLine[]): ShippingLineTableItem[] {
  return shippingLines.map((line) => ({ ...line, id: line.line_number }))
}

export function convertShippingLinesToReturnInstance({
  shipment_lines,
  order_type,
  order_number,
}: IShipping): IShippingReturnLinesInstance[] {
  return shipment_lines.map(({ line_number }) => ({
    order_line: String(line_number),
    order_number,
    order_type,
  }))
}

function getPermissionsActions(
  actions: IXtTableCellAction[],
  isShipmentShipped: boolean,
  canIssueStock: boolean,
  canReturnStock: boolean
): IXtTableCellAction[] {
  if (isShipmentShipped) {
    return actions.filter(({ name }) => name !== ShippingLinesAction.IssueAdditionalStock && name !== ShippingLinesAction.ReturnAll)
  }
  if (!canIssueStock) {
    actions = actions.filter(({ name }) => name !== ShippingLinesAction.IssueAdditionalStock)
  }

  if (!canReturnStock) {
    actions = actions.filter(({ name }) => name !== ShippingLinesAction.ReturnAll)
  }

  return actions
}

export function getLineItemsAction(
  actions: IXtTableCellAction[],
  isSalesOrder: boolean,
  isShipmentShipped: boolean,
  canIssueStock: boolean,
  canReturnStock: boolean,
  canViewSalesOrder: boolean
): IXtTableCellAction[] {
  let updatedActions = getPermissionsActions(actions, isShipmentShipped, canIssueStock, canReturnStock)

  if (!isSalesOrder || !canViewSalesOrder) {
    updatedActions = updatedActions.filter(({ name }) => name !== ShippingLinesAction.ViewOrder)
  }

  return updatedActions
}

export function defineShippingLineItemDialogState(
  salesOrder: ISalesOrder,
  lineItemInput: ISalesOrderItem
): ShippingSalesLineItemDialogState {
  return {
    site: salesOrder.site,
    customerNumber: salesOrder.customer_number,
    currency: salesOrder.currency,
    lineItemInput,
    pricesData: defineSalesPricesData(
      parseServerDateWithDefault(salesOrder.order_date),
      salesOrder.ship_to?.shipto_number,
      salesOrder.shipping_zone,
      salesOrder.sale_type
    ),
  }
}
