import { Overwrite } from 'utility-types'
import { Observable } from 'rxjs'
import { BomItemCreateRequest, BomItemQuickAddInput, IBom, IBomItem } from '../bom.types'
import { BomItemMode, BomItemSubmitFunc } from '../bom-item/bom-item.types'
import { ITable } from '../../../common/hooks/useTable'
import { IXtTableCellAction } from '../../../components/table/table-cell-actions/table-cell-actions'
import { IFormHook } from '../../../common/hooks/form/form.types'
import { IConfirmationDialog } from '../../../common/hooks/confirmation-dialog'
import { IConvertedMode } from '../../../common/common.types'
import { ItemOption } from '../../items/items.types'
import { IXtItemNumberOptionBase } from '../../items/components/item-number/item-number.types'

export interface IBomState {
  bom: IBom | null
  loading: boolean
}

export interface IBomItemDialogState {
  open: boolean
  bomItem: IBomItem | null
  mode: BomItemMode
}

export interface IBomDetailsParams {
  itemNumber: string
}

export enum BomDetailsFormField {
  ItemNumber = 'item_number',
  Revision = 'revision',
  RevisionDate = 'revision_date',
  DocumentNumber = 'document_number',
  BatchSize = 'batch_size',
}

export enum BomDetailsFormFieldLabel {
  Revision = 'Revision',
  Date = 'Date',
  DocNumber = 'Document #',
  BatchSize = 'Batch Size',
  ShowExpired = 'Show Expired',
  ShowFuture = 'Show Future',
}

export enum BomDetailsAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'View',
  Expire = 'Expire',
  Replace = 'Replace',
}

export type NewBomSubmitNewItemFunc = (bomItem: IBomItem | null, newItem: BomItemCreateRequest, mode: BomItemMode) => void

export type BomFormData = Overwrite<
  Pick<IBom, BomDetailsFormField>,
  {
    [BomDetailsFormField.RevisionDate]: Date | null
    [BomDetailsFormField.BatchSize]: number | string
    [BomDetailsFormField.ItemNumber]?: IXtItemNumberOptionBase | null
  }
>

export type OpenBomItem = (itemId: number | null, selectedMode: BomItemMode) => void
export type OnBomItemChange = (item: ItemOption | null) => Promise<void>

interface IBomDetailsTableFilters {
  showExpired?: boolean
  showFuture?: boolean
}

interface IBomDetailsTableState {
  state: ITable<IBomItem, IBomDetailsTableFilters>
  tableActions: IXtTableCellAction[]
  handleRowClick: (item: IBomItem) => void
  handleAction: (item: IBomItem, action: BomDetailsAction) => void
}
interface IBomDetailsFormState {
  state: IFormHook<BomFormData>
  onSubmit: (data: BomFormData) => Promise<void>
  onCancel: VoidFunction
}
interface IBomDetailsDeletionState {
  state: IConfirmationDialog<number>
  handleDeletion: VoidFunction
}
interface IBomDetailsQuickAddState {
  isQuickAddOpen: boolean
  openQuickAddForm: VoidFunction
  closeQuickAddForm: VoidFunction
  handleAdvancedSearch: VoidFunction
}
interface IBomDetailsBomItemState {
  addBomItem: (quickAddFormData: BomItemQuickAddInput) => Promise<void>
  onBomItemSubmit: BomItemSubmitFunc
  bomItemDialogState: IBomItemDialogState
  closeBomItemDialog: VoidFunction
}
export interface IUseBomDetails {
  tableState: IBomDetailsTableState
  formState: IBomDetailsFormState
  deletionState: IBomDetailsDeletionState
  bomItemState: IBomDetailsBomItemState
  updateFilters$: Observable<undefined>
  quickAddState: IBomDetailsQuickAddState
  onItemNumberChange: OnBomItemChange
  bomData: IBomState
  itemNumberValue?: IXtItemNumberOptionBase | null
  bomDetailsMode: IConvertedMode
}
