import type { IDocumentsUtilsService } from 'documents/documents-utils.service'
import type { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import type { IFilter } from 'components/pagefilter/filter/filter.types'
import type { IKanbanOpportunityCard, IKanbanOpportunityColumn } from './opportunities-board.types'
import type { IKanbanOpportunity, IKanbanOpportunityStage } from '../opportunities-kanban.types'
import hexToRgba from 'hex-to-rgba'
import { FilterType } from 'components/pagefilter/filter/filter.types'
import { parseServerDate } from 'common/utils/date.utils'
import {
  defineAutocompleteOption,
  getAutocompleteInputLabelAsId,
  renderColumnOption,
} from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { KanbanOpportunitiesFilter, KanbanOpportunitiesFilterPanel, KanbanOpportunitiesLabel } from '../opportunities-kanban.types'
import { defaultFilterValues } from './opportunities-board.constants'

function convertToCard(opportunity: IKanbanOpportunity): IKanbanOpportunityCard {
  const {
    opportunity_number,
    opportunity_name,
    contact,
    contact_phone,
    currency,
    amount,
    allow_sms,
    probability_percent,
    account,
    last_activity_date,
    editable,
  } = opportunity

  return {
    id: opportunity_number,
    name: opportunity_name,
    contact,
    phone: contact_phone,
    amount,
    currency,
    probability: probability_percent,
    account,
    lastActivityDate: parseServerDate(last_activity_date),
    draggable: editable,
    editable,
    allow_sms,
  }
}

export function convertToKanbanColumn(opportunityStage: IKanbanOpportunityStage): IKanbanOpportunityColumn {
  const data: IKanbanOpportunityCard[] = opportunityStage.opportunities?.map(convertToCard) ?? []

  return {
    data,
    id: opportunityStage.stage,
    title: opportunityStage.stage,
    opportunityTotal: opportunityStage.opportunity_total,
    opportunityCounter: opportunityStage.opportunity_counter,
    baseCurrency: opportunityStage.base_currency,
    styles: opportunityStage.stage_color
      ? {
          headerColor: opportunityStage.stage_color,
          bodyColor: hexToRgba(opportunityStage.stage_color, '0.5'),
          footerColor: opportunityStage.stage_color,
        }
      : undefined,
  }
}

export function isFilterByUserOpportunitiesChecked(filters: KanbanOpportunitiesFilterPanel, username: string | null): boolean {
  const selectedUser = filters[KanbanOpportunitiesFilter.User]

  return selectedUser && username ? selectedUser.id === username : false
}

export function defineDefaultFilters(username: string | null): KanbanOpportunitiesFilterPanel {
  const usernameFilter: IXtAutocompleteOption | null = defineAutocompleteOption(username)
  return { ...defaultFilterValues, [KanbanOpportunitiesFilter.User]: usernameFilter }
}

export function convertFilters(
  filtersNew: KanbanOpportunitiesFilterPanel,
  filtersPrev: KanbanOpportunitiesFilterPanel,
  username: string | null
): KanbanOpportunitiesFilterPanel {
  const usernameFilter: IXtAutocompleteOption | null = defineAutocompleteOption(username)

  let updatedUser = filtersNew[KanbanOpportunitiesFilter.User]

  if (filtersNew[KanbanOpportunitiesFilter.IsMyOpportunities] !== filtersPrev[KanbanOpportunitiesFilter.IsMyOpportunities]) {
    updatedUser = filtersNew[KanbanOpportunitiesFilter.IsMyOpportunities] ? usernameFilter : null
  }

  let isMyOpportunities = filtersNew[KanbanOpportunitiesFilter.IsMyOpportunities]
  if (filtersNew[KanbanOpportunitiesFilter.User]?.id !== filtersPrev[KanbanOpportunitiesFilter.User]?.id) {
    isMyOpportunities = filtersNew[KanbanOpportunitiesFilter.User]?.id === username
  }

  return {
    ...filtersNew,
    [KanbanOpportunitiesFilter.User]: updatedUser,
    [KanbanOpportunitiesFilter.IsMyOpportunities]: isMyOpportunities,
  }
}

export function resolveFilters({
  loadDocumentOppSourceOptions,
  loadOpportunityTypes,
  loadProjectOptions,
  loadAccountOptions,
  loadUserOptions,
}: IDocumentsUtilsService): IFilter[] {
  return [
    {
      type: FilterType.Autocomplete,
      label: KanbanOpportunitiesLabel.User,
      fieldName: KanbanOpportunitiesFilter.User,
      autocompleteProps: {
        loadOptions: loadUserOptions,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: KanbanOpportunitiesLabel.AssignedTo,
      fieldName: KanbanOpportunitiesFilter.AssignedTo,
      autocompleteProps: {
        loadOptions: loadUserOptions,
      },
    },

    {
      type: FilterType.Date,
      label: KanbanOpportunitiesLabel.TargetDateOnOrAfter,
      fieldName: KanbanOpportunitiesFilter.TargetDateOnOrAfter,
    },
    {
      type: FilterType.Date,
      label: KanbanOpportunitiesLabel.TargetDateOnOrBefore,
      fieldName: KanbanOpportunitiesFilter.TargetDateOnOrBefore,
    },
    {
      type: FilterType.Autocomplete,
      label: KanbanOpportunitiesLabel.Project,
      fieldName: KanbanOpportunitiesFilter.Project,
      autocompleteProps: {
        loadOptions: loadProjectOptions,
        renderOption: renderColumnOption,
        getInputLabel: getAutocompleteInputLabelAsId,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: KanbanOpportunitiesLabel.Owner,
      fieldName: KanbanOpportunitiesFilter.Owner,
      autocompleteProps: { loadOptions: loadUserOptions },
    },
    {
      type: FilterType.Autocomplete,
      label: KanbanOpportunitiesLabel.Account,
      fieldName: KanbanOpportunitiesFilter.Account,
      autocompleteProps: {
        loadOptions: loadAccountOptions,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: KanbanOpportunitiesLabel.Type,
      fieldName: KanbanOpportunitiesFilter.Type,
      autocompleteProps: {
        loadOptions: loadOpportunityTypes,
      },
    },
    {
      type: FilterType.Text,
      label: KanbanOpportunitiesLabel.TypePattern,
      fieldName: KanbanOpportunitiesFilter.TypePattern,
    },

    {
      type: FilterType.Autocomplete,
      label: KanbanOpportunitiesLabel.Source,
      fieldName: KanbanOpportunitiesFilter.Source,
      autocompleteProps: {
        loadOptions: loadDocumentOppSourceOptions,
      },
    },
    {
      type: FilterType.Text,
      label: KanbanOpportunitiesLabel.SourcePattern,
      fieldName: KanbanOpportunitiesFilter.SourcePattern,
    },
  ]
}

export function resolveMobileFilters(documentsUtilsService: IDocumentsUtilsService): IFilter[] {
  return [
    ...resolveFilters(documentsUtilsService),
    {
      type: FilterType.Checkbox,
      label: 'Only show my opportunities',
      fieldName: KanbanOpportunitiesFilter.IsMyOpportunities,
    },
  ]
}
