import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { dateConverter } from 'common/utils/date.utils'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { numberConverter } from 'common/utils/utils'
import { ICostedBomTotalsWithId, ICostedBomWithId } from '../bom-utils.types'
import { singleLevelNumberConverter } from './single-level-list.utils'

export enum SingleLevelAction {
  ViewItemCostingSummary = 'View Item Costing Summary',
}

export const singleLevelActions: IXtTableCellAction[] = [
  {
    name: SingleLevelAction.ViewItemCostingSummary,
    label: SingleLevelAction.ViewItemCostingSummary,
    icon: SvgIconIds.VISIBILITY,
  },
]

export const costedBomColumns: IXtTableColumn<ICostedBomWithId>[] = [
  {
    id: 'seq',
    field: 'seq',
    headerName: 'Seq #',
    flex: '1 0 100px',
    width: 100,
  },
  {
    id: 'item_number',
    field: 'item_number',
    headerName: 'Item Number',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'item_description',
    field: 'item_description',
    headerName: 'Description',
    flex: '1 0 400px',
    width: 400,
  },
  {
    id: 'uom',
    field: 'uom',
    headerName: 'UOM',
    flex: '1 0 110px',
    width: 110,
  },
  {
    id: 'batch_size',
    field: 'batch_size',
    headerName: 'Batch Size',
    flex: '1 0 120px',
    width: 120,
    converter: singleLevelNumberConverter,
  },
  {
    id: 'qty_fixed',
    field: 'qty_fixed',
    headerName: 'Fixed Qty.',
    flex: '1 0 200px',
    width: 200,
    converter: singleLevelNumberConverter,
  },
  {
    id: 'qty_per',
    field: 'qty_per',
    headerName: 'Qty. Per',
    flex: '1 0 200px',
    width: 200,
    converter: singleLevelNumberConverter,
  },
  {
    id: 'scrap',
    field: 'scrap',
    headerName: 'Scrap %',
    flex: '1 0 120px',
    width: 120,
    converter: singleLevelNumberConverter,
  },
  {
    id: 'effective',
    field: 'effective',
    headerName: 'Effective',
    flex: '1 0 140px',
    width: 140,
    converter: dateConverter,
  },
  {
    id: 'expires',
    field: 'expires',
    headerName: 'Expires',
    flex: '1 0 130px',
    width: 130,
    converter: dateConverter,
  },
  {
    id: 'unit_cost',
    field: 'unit_cost',
    headerName: 'Unit Cost',
    flex: '1 0 160px',
    width: 160,
    converter: singleLevelNumberConverter,
  },
  {
    id: 'extended_cost',
    field: 'extended_cost',
    headerName: 'Ext. Cost',
    flex: '1 0 160px',
    width: 160,
    converter: singleLevelNumberConverter,
  },
]

export const costedBomTotalsColumns: IXtTableColumn<ICostedBomTotalsWithId>[] = [
  {
    id: 'description',
    field: 'description',
    headerName: '',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'extended_cost',
    field: 'extended_cost',
    headerName: 'Extended Cost.',
    flex: '1 0 160px',
    width: 160,
    converter: (value) => numberConverter(value, 4),
    align: 'right',
  },
]

export const singleLevelBOMPageTitle = 'xTuple - Costed Single Level BOM'
