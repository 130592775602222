import type { TaskCalendarFiltersPanel, TaskCalendarItem } from './tasks-calendar.types'
import type { CalendarTask } from '../tasks.types'
import React, { FC, useCallback, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { cls } from 'common/utils/utils'
import { XtMode } from 'common/common.types'
import { CalendarView } from 'calendar/calendar.types'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useCalendarModule } from 'calendar/calendar-module-hook'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { XtPageFilter } from 'components/pagefilter/pagefilter'
import { usePageFilter } from 'components/pagefilter/pagefilter.utils'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { taskCalendarPageTitle, tasksCalendarFilterDefaultValues, tasksCalendarTemplates } from './tasks-calendar.constants'
import * as styles from './tasks-calendar.module.scss'
import { useTasksModule } from '../tasks-module-hook'
import { resolveFilters } from './tasks-calendar.utils'
import { TaskListFilterField, TaskListFilterLabel } from '../tasks.types'

export const TasksCalendar: FC = () => {
  const { TasksUtilsService } = useTasksModule()
  const { DocumentsUtilsService } = useDocumentsModule()
  const { ErrorHandler } = useCoreModule()
  const { XtCalendar, useCalendar } = useCalendarModule()
  useDocumentTitle(taskCalendarPageTitle)
  const history = useHistory()
  const routePath = useRouteMatch().path
  const loadCalendarItems = useCallback<
    (view: CalendarView, startDate: Date, endDate: Date, params?: TaskCalendarFiltersPanel) => Promise<TaskCalendarItem[]>
  >(
    async (view, startDate, endDate, params) => {
      const filters = params ?? tasksCalendarFilterDefaultValues
      return TasksUtilsService.requestCalendarTasks({ ...filters, fromdate: startDate, view, endDate })
    },
    [TasksUtilsService]
  )

  const { data, loading, handleItemUpdate, reset, calendarInstance, params } = useCalendar<CalendarTask, TaskCalendarFiltersPanel>({
    defaultView: CalendarView.Month,
    defaultParams: tasksCalendarFilterDefaultValues,
    loadData: loadCalendarItems,
    updateItem: TasksUtilsService.updateTaskDueDate,
  })

  const filterCalendar = useCallback<(filters: TaskCalendarFiltersPanel) => Promise<void>>(
    async (filters) => {
      try {
        await reset(undefined, undefined, filters)
      } catch (error) {
        ErrorHandler.handleError(error as Error)
      }
    },
    [ErrorHandler, reset]
  )

  const calendarListFilters = usePageFilter<TaskCalendarFiltersPanel>(PageFilterMapping.Calendar)

  useEffect(() => {
    if (calendarListFilters.lastUsedFilter) {
      void filterCalendar({ [TaskListFilterField.AllUsers]: calendarListFilters.lastUsedFilter[TaskListFilterField.AllUsers] })
    }
  }, [calendarListFilters.lastUsedFilter, filterCalendar])

  const onItemClick = useCallback<(item: TaskCalendarItem) => void>(
    ({ raw: { id, editable, number } }) => {
      const mode = editable ? XtMode.Edit : XtMode.View
      const path = routePath.slice(0, routePath.lastIndexOf('/'))
      history.push(`${path}/${id}/${number}/${mode}`)
    },
    [history]
  )

  const handleShowTasksForAllUsersFilterChange = (checked: boolean): void => {
    const newParams = { ...params, [TaskListFilterField.AllUsers]: checked }
    void filterCalendar(newParams)
    void calendarListFilters.handleLastUsedFilter(newParams)
  }

  return (
    <div className={cls('xt-content', styles.tasksCalendar)}>
      <div className={styles.filtersContainer}>
        <XtPageFilter
          state={calendarListFilters}
          defaultFilterValues={tasksCalendarFilterDefaultValues}
          resolveFilters={() => resolveFilters(DocumentsUtilsService)}
          filter={filterCalendar}
          tableFilters={params}
        />
        <XtCheckbox
          className={styles.calendarListCheckbox}
          label={TaskListFilterLabel.AllUsers}
          value={params[TaskListFilterField.AllUsers]}
          onChange={handleShowTasksForAllUsersFilterChange}
          disabled={loading}
        />
      </div>
      <XtCalendar
        calendarInstance={calendarInstance}
        data={data}
        loading={loading}
        handleItemUpdate={handleItemUpdate}
        templates={tasksCalendarTemplates}
        onItemClick={onItemClick}
      />
    </div>
  )
}
