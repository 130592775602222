import * as yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { dateYupValidator } from 'common/utils/date.utils'
import { RoutingDetailsFormField } from './routing-details.types'

export const routingDetailsValidationSchema = yup.object().shape({
  [RoutingDetailsFormField.FinalLocation]: yup.object().nullable(),
  [RoutingDetailsFormField.ItemNumber]: yup.object().nullable().required(validationMessage.isRequired),
  [RoutingDetailsFormField.RevisionDate]: dateYupValidator,
})
