import { IPaginationData } from 'common/common.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { IUsersService } from './users.service'
import { IUserFilters } from './users.types'

export interface IUsersUtilsService {
  loadUsersOptions(
    page: number,
    limit: number,
    searchFilter: string | null,
    filters?: IUserFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>>
}

export class UsersUtilsService implements IUsersUtilsService {
  constructor(private readonly usersService: IUsersService) {
    bindAllInstanceMethods(this)
  }

  public async loadUsersOptions(
    page: number,
    limit: number,
    searchFilter: string | null,
    filters?: IUserFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { total, data } = await this.usersService.getAll({ limit, page }, { ...filters, username_pattern: searchFilter })

    return {
      data: data.map(({ username, proper_name }) => ({ id: username, label: `${username}-${proper_name}` })),
      total,
    }
  }
}
