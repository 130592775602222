// extracted by mini-css-extract-plugin
export var root = "comments_root__HJ4CE";
export var xtContent = "comments_xt-content__ZuZWe";
export var xtContentWithRemarks = "comments_xt-content-with-remarks__LJ6lE";
export var muiFormHelperTextRoot = "comments_MuiFormHelperText-root__oJ8aW";
export var xtListPageContainer = "comments_xt-list-page-container__f+eEJ";
export var muiInputBaseRoot = "comments_MuiInputBase-root__IziCY";
export var muiTextFieldRoot = "comments_MuiTextField-root__vc84B";
export var muiTablePaginationRoot = "comments_MuiTablePagination-root__F-yto";
export var muiTablePaginationSelectRoot = "comments_MuiTablePagination-selectRoot__B7CtM";
export var muiTablePaginationSelect = "comments_MuiTablePagination-select__B1xkI";
export var muiStepperRoot = "comments_MuiStepper-root__u8Y8g";
export var muiStepperHorizontal = "comments_MuiStepper-horizontal__C6q+8";
export var muiStepIconRoot = "comments_MuiStepIcon-root__+vart";
export var muiStepIconActive = "comments_MuiStepIcon-active__Tvqxi";
export var muiStepIconCompleted = "comments_MuiStepIcon-completed__ty0Ap";
export var muiListItemRoot = "comments_MuiListItem-root__Ggkjl";
export var muiSelected = "comments_Mui-selected__2bQiv";
export var xtPageTitle = "comments_xt-page-title__feGZ3";
export var xtSubPageTitle = "comments_xt-subPage-title__P9Iun";
export var xtSectionBorder = "comments_xt-section-border__5KHLE";
export var xtPageHeader = "comments_xt-page-header__B7S2l";
export var xtDialogScrollable = "comments_xt-dialog-scrollable__P+hMO";
export var reactToastNotificationsContainer = "comments_react-toast-notifications__container__9PkTG";
export var xtScrollbarStyle = "comments_xt-scrollbar-style__vRVs4";
export var xtModalDetailsContent = "comments_xt-modal-details-content__e3ga3";
export var xtComments = "comments_xtComments__ioUfI";
export var xtDialogDetailsContent = "comments_xt-dialog-details-content__a3z2r";
export var xtLazyModuleLoaderTabContainer = "comments_xt-lazy-module-loader-tab-container__SkChZ";
export var xtTextTruncated = "comments_xt-text-truncated__MbdEh";
export var xtSummaryContent = "comments_xt-summary-content__4eZS9";
export var xtSummaryLabel = "comments_xt-summary-label__uwoxi";
export var visibleHidden = "comments_visible-hidden__W+Z4U";
export var headerBottomMargin = "comments_header-bottom-margin__GadaE";
export var xtDialogHeaderLogoAlign = "comments_xt-dialog-header-logoAlign__GRDhI";
export var xtStickyHeader = "comments_xt-sticky-header__yO1Zm";
export var noBorder = "comments_no-border__rNJlw";
export var muiOutlinedInputNotchedOutline = "comments_MuiOutlinedInput-notchedOutline__xcNAe";
export var stickyWrapper = "comments_stickyWrapper__w-UUN";
export var xtCommentsAddButton = "comments_xtCommentsAddButton__bcZ7B";
export var xtCommentsList = "comments_xtCommentsList__gjN6M";