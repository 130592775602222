import { Overwrite } from 'utility-types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IRoutingItem, IRouting } from '../routing.types'
import { ItemOption } from '../../items/items.types'

export enum RoutingDetailsFilter {
  ShowExpired = 'showExpired',
  ShowFuture = 'showFuture',
}

export enum RoutingDetailsFormField {
  RevisionNumber = 'revision_number',
  DocumentNumber = 'document_number',
  RevisionDate = 'revision_date',
  FinalLocation = 'final_location',
  FinalLocationSite = 'final_location_site',
  CloseWorkorder = 'close_workorder',
  ItemNumber = 'item_number',
}

export interface IRoutingDetailsFormData {
  [RoutingDetailsFormField.ItemNumber]: ItemOption | null
  [RoutingDetailsFormField.RevisionNumber]: string
  [RoutingDetailsFormField.RevisionDate]: Date | null
  [RoutingDetailsFormField.DocumentNumber]: string
  [RoutingDetailsFormField.FinalLocation]: IXtAutocompleteOption | null
  [RoutingDetailsFormField.FinalLocationSite]: IXtAutocompleteOption | null
  [RoutingDetailsFormField.CloseWorkorder]: boolean
}

export interface IRoutingDetailsCreate
  extends Overwrite<
    IRouting,
    {
      routing_items: IRoutingItem[]
    }
  > {}

export interface IRoutingDetailsUpdate extends Omit<IRouting, 'routing_items'> {}

export enum RoutingDetailsAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'View',
  Expire = 'Expire',
}

export interface IRoutingDetailsTableItem extends IRoutingItem {
  id: number
}
