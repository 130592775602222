import type { IKanbanTaskColumn } from '../tasks-board.types'
import React, { FC } from 'react'
import * as styles from './task-kanban-column-header.module.scss'

export const TaskKanbanColumnHeader: FC<IKanbanTaskColumn> = ({ title, taskCounter, styles: tasksStyles }) => {
  const total = `(${taskCounter})`
  const columnTitle = `Priority: ${title}`
  const backgroundColor = tasksStyles?.headerColor

  return (
    <div className={styles.columnHeader} style={{ backgroundColor }}>
      <span className="xt-text-truncated" title={columnTitle}>
        {columnTitle}
      </span>
      <span className="xt-text-truncated">{total}</span>
    </div>
  )
}
