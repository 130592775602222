import { isDate } from 'common/utils/filter.utils'
import { IFilterFormState } from 'components/pagefilter/filter/filter.types'
import { dateToServerFormat, parseServerDate } from 'common/utils/date.utils'
import { IPageFilterValue, IPageFilterValues, ObjectFilter } from './pagefilters.types'

export function parseFilterValues<TFilter extends {}>(json: string | object): TFilter {
  let parsedLastUsed: IPageFilterValues | null = null
  if (typeof json === 'string') {
    parsedLastUsed = JSON.parse(json) as IPageFilterValues
  }

  if (typeof json === 'object') {
    parsedLastUsed = json as IPageFilterValues
  }
  let lastUsedToApply: TFilter = {} as TFilter

  parsedLastUsed?.forEach((item) => {
    let value = item.value

    // TODO: simplify processing date formats in pagefilters
    if (value.hasOwnProperty('id')) {
      const date = value as ObjectFilter
      if (date?.id === 'date' && date?.value) {
        value = parseServerDate(date?.value)
      }
    }
    Object.assign(lastUsedToApply, { [item.parameter]: value })
  })
  return lastUsedToApply
}

export function buildPageFilerValues<TFilter extends IFilterFormState>(formValues: TFilter): string {
  let data: Array<IPageFilterValue> = []

  for (let key in formValues) {
    const value = formValues[key]
    if (value || typeof value === 'boolean') {
      let dateServer: ObjectFilter | null = null

      // TODO: simplify processing date formats in pagefilters
      if (isDate(value)) {
        dateServer = { id: 'date', value: dateToServerFormat(value) }
      }
      data = [...data, { parameter: key, value: dateServer ?? value } as IPageFilterValue]
    }
  }
  return JSON.stringify(data)
}
