import React from 'react'
import { Link } from 'react-router-dom'
import { footerRoutes } from './footer.constants'
import * as styles from './footer.module.scss'

export function Footer(): JSX.Element {
  return (
    <footer className={styles.footer}>
      <nav>
        <ul>
          {footerRoutes.map((route) => (
            <li key={route.name}>
              {route.link === '/' ? <Link to={route.link}>{route.name}</Link> : <a href={route.link}>{route.name}</a>}
            </li>
          ))}
        </ul>
      </nav>
      <p className={styles.copyright}>
        &copy; {new Date().getFullYear()} <a href="https://www.xtuple.com">xTuple</a>
      </p>
    </footer>
  )
}
