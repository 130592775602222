import { PermissionGuard } from '../auth/permission.guard'
import { homeRoutePath } from '../home/home.constants'
import { UserPermission } from '../auth/auth.types'

// TODO Rename to SettingsGuard ?
export class SetupGuard extends PermissionGuard {
  public redirectTo = homeRoutePath
  public permissions = [
    UserPermission.ConfigureSO,
    UserPermission.ViewCharacteristics,
    UserPermission.MaintainCharacteristics,
    UserPermission.ViewCustomerTypes,
    UserPermission.MaintainCustomerTypes,
    UserPermission.ViewSaleTypes,
    UserPermission.MaintainSaleTypes,
    UserPermission.ConfigureCRM,
    UserPermission.MaintainOpportunitySources,
    UserPermission.MaintainOpportunityStages,
    UserPermission.MaintainOpportunityTypes,
    UserPermission.ViewClassCodes,
    UserPermission.MaintainClassCodes,
    UserPermission.ViewProductCategories,
    UserPermission.MaintainProductCategories,
    UserPermission.ViewUOMs,
    UserPermission.MaintainUOMs,
    UserPermission.MaintainCommentTypes,
    UserPermission.MaintainUsers,
    UserPermission.MaintainGroups,
  ]
}
