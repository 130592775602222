import React, { FC, useCallback, useEffect, useState } from 'react'
import { MenuItem } from '@material-ui/core'
import { useAuthModule } from 'auth/auth-module-hook'
import * as styles from './personal-profile.module.scss'
import { IPersonalProfileMenuItems, logout, menuItems } from '../header.constants'

export const PersonalProfileMenuItem: FC = () => {
  const { AuthService } = useAuthModule()

  const [open, setOpen] = useState(false)

  const handleToggle = useCallback(() => {
    setOpen(!open)
  }, [])

  const handleMenuItemClick: (item: IPersonalProfileMenuItems) => void = (item) => {
    handleToggle()

    if (item.name === logout) {
      AuthService.logout()
    }
  }

  useEffect(() => {
    const handleResize = (): void => setOpen(false)

    window.addEventListener('resize', handleResize)

    // Remove event listener to prevent a memory leak
    return () => window.removeEventListener('resize', handleResize)
  }, [setOpen])

  const renderMenuItems: (items: IPersonalProfileMenuItems[]) => void = (items) =>
    items.map((item) => (
      <MenuItem key={item.name} onClick={() => handleMenuItemClick(item)}>
        {item.link ? (
          <a href={item.link} rel="noreferrer" className={styles.logout}>
            {item.name}
          </a>
        ) : (
          <span className={styles.logout}>{item.name}</span>
        )}
      </MenuItem>
    ))

  return <>{renderMenuItems(menuItems)}</>
}
