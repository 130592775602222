import { IHttpClient } from 'core/services/http-client'
import { SettingType } from 'setup/settings/settings.types'
import { ICreateItemsTableItem } from './basic-configuration-steps/create-items/create-items.types'
import { ICreateAccountsTableItem } from './basic-configuration-steps/create-accounts/create-accounts.types'
import { ItemCreationError, AccountCreationError } from './setup-wizard.types'
import { normalizeCustomerPayload, normalizeProspectPayload } from './basic-configuration-steps/create-accounts/create-accounts.utils'
import { AccountTypes } from './basic-configuration-steps/create-accounts/create-accounts.constants'

export interface ISetupWizardService {
  createItems(items: ICreateItemsTableItem[]): Promise<string[]>
  createAccounts(customers: ICreateAccountsTableItem[]): Promise<string[]>
  updateSetting(setting: boolean): Promise<void>
  setShowSetupDialog(setting: boolean): void
  getShowSetupDialog(): boolean
}

export class SetupWizardService implements ISetupWizardService {
  constructor(private readonly apiClient: IHttpClient) {}
  private showSetupDialog = true
  public async createItems(items: ICreateItemsTableItem[]): Promise<string[]> {
    const results = []
    if (!items.length) {
      return []
    }
    try {
      const datas = items.map(async (item, index) => {
        const data = { ...item, item_number: item.item_number.toUpperCase(), id: index, inventory_uom_name: 'EA' }
        const {
          data: {
            status: { message },
          },
        } = await this.apiClient.post('/item/create', { data })
        return message
      })
      for (const promise of datas) {
        try {
          const result = await promise
          results.push(result)
        } catch (e) {
          throw new ItemCreationError()
        }
      }
    } catch (e) {
      throw new ItemCreationError()
    }
    return results
  }

  public async createAccounts(accounts: ICreateAccountsTableItem[]): Promise<string[]> {
    if (!accounts.length) {
      return []
    }
    const results = []
    try {
      for (let account of accounts) {
        switch (account.account_type) {
          case AccountTypes.Customer:
            const {
              data: {
                status: { message },
              },
            } = await this.apiClient.post('/customer/create', { data: normalizeCustomerPayload(account) })
            results.push(message)
            break
          case AccountTypes.Prospect:
            const { data } = await this.apiClient.post('/prospect/create', { data: normalizeProspectPayload(account) })
            results.push(data?.status?.message)
            break
        }
      }
    } catch (e) {
      throw new AccountCreationError()
    }
    return results
  }

  public setShowSetupDialog(setting: boolean): void {
    this.showSetupDialog = setting
  }

  public async updateSetting(setting: boolean): Promise<void> {
    try {
      const data = {
        setting: SettingType.XTSetupWizardRun,
        setting_value: setting == true ? 't' : 'f',
      }
      this.setShowSetupDialog(false)
      await this.apiClient.post('/settings/set', { data })
    } catch (error) {
      console.error(error)
    }
  }

  public getShowSetupDialog(): boolean {
    return this.showSetupDialog
  }
}
