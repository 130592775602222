import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { ISalesOrdersService } from 'sales-shared/sales-orders.service'
import { Scope } from 'core/injector/injector.types'
import { CharacteristicsModule } from 'characteristics/characteristics.module'
import { ProductsModule } from 'products/products.module'
import { ShipmentsModule } from 'shipments/shipments.module'
import { ContactsModule } from 'contacts/contacts.module'
import { SalesSharedModule } from 'sales-shared/sales-shared.module'
import { salesOrdersServiceToken } from 'sales-shared/sales-shared.constants'
import { AuthModule } from 'auth/auth.module'
import { CoreModule } from 'core/core.module'
import { IToastService } from 'toast/toast.service'
import { toastServiceToken } from 'toast/toast.constants'
import { IErrorHandler } from 'core/services/error-handler.service'
import { errorHandlerServiceToken } from 'core/core.constants'
import { ProspectsServicesModule } from 'companies/prospects/services/prospects-services.module'
import { ISalesOrdersUtilsService } from './sales-orders-utils.service'
import { salesOrdersModule, salesOrdersUtilsServiceToken } from './sales-orders.constants'

export type SalesOrdersModuleDefinition = typeof import('./sales-orders.exports')

export interface ISalesOrdersModuleServices {
  SalesOrdersUtilsService: ISalesOrdersUtilsService
}

export const SalesOrdersModule: ILazyModule<SalesOrdersModuleDefinition> = {
  name: salesOrdersModule,
  resolver: () => import('./sales-orders.exports'),
  initializer: ({ SalesOrdersUtilsService }) => {
    Injector.register<ISalesOrdersUtilsService>(
      salesOrdersUtilsServiceToken,
      (injector) => {
        const salesOrdersService = injector.resolve<ISalesOrdersService>(salesOrdersServiceToken)
        const errorHandler = Injector.resolve<IErrorHandler>(errorHandlerServiceToken)
        const toastService = Injector.resolve<IToastService>(toastServiceToken)

        return new SalesOrdersUtilsService(salesOrdersService, toastService, errorHandler)
      },
      Scope.Singleton
    )
  },
  dependencies: [
    AuthModule,
    CoreModule,
    ProductsModule,
    CharacteristicsModule,
    SalesSharedModule,
    ShipmentsModule,
    ContactsModule,
    ProspectsServicesModule,
  ],
}
