import { FormRadioGroupOptions } from 'components/controls/xt-radio-group/xt-radio-group'
import {
  AbcClassOptions,
  AbcClassOptionsLabel,
  IQuantityOnHandListFilterForm,
  QuantityOnHandListFilterFormField,
  ShowQuantityOptions,
  ShowQuantityOptionsLabel,
  UseCostOptions,
  UseCostOptionsLabel,
} from './quantity-on-hand-list-filter.types'

export const maxPatternStringLength = 255

export const abcClassOptions: FormRadioGroupOptions = [
  {
    id: AbcClassOptions.A,
    label: AbcClassOptionsLabel.A,
  },
  {
    id: AbcClassOptions.B,
    label: AbcClassOptionsLabel.B,
  },
  {
    id: AbcClassOptions.C,
    label: AbcClassOptionsLabel.C,
  },
]

export const showQuantityOptions: FormRadioGroupOptions = [
  {
    id: ShowQuantityOptions.All,
    label: ShowQuantityOptionsLabel.All,
  },
  {
    id: ShowQuantityOptions.Positive,
    label: ShowQuantityOptionsLabel.Positive,
  },
  {
    id: ShowQuantityOptions.Negative,
    label: ShowQuantityOptionsLabel.Negative,
  },
]

export const showInventoryValueOptions: FormRadioGroupOptions = [
  {
    id: UseCostOptions.Actual,
    label: UseCostOptionsLabel.Actual,
  },
  {
    id: UseCostOptions.Standard,
    label: UseCostOptionsLabel.Standard,
  },
]

export const defaultQuantityOnHandListFilterValues: IQuantityOnHandListFilterForm = {
  [QuantityOnHandListFilterFormField.ClassCode]: null,
  [QuantityOnHandListFilterFormField.ClassCodePattern]: null,
  [QuantityOnHandListFilterFormField.CostCategory]: null,
  [QuantityOnHandListFilterFormField.CostCategoryPattern]: null,
  [QuantityOnHandListFilterFormField.ItemNumber]: null,
  [QuantityOnHandListFilterFormField.ItemGroup]: null,
  [QuantityOnHandListFilterFormField.ItemGroupPattern]: null,
  [QuantityOnHandListFilterFormField.AbcClass]: null,
  [QuantityOnHandListFilterFormField.Site]: null,
  [QuantityOnHandListFilterFormField.AsOf]: null,
  [QuantityOnHandListFilterFormField.ShowQuantities]: ShowQuantityOptions.All,
  [QuantityOnHandListFilterFormField.ShowValue]: false,
  [QuantityOnHandListFilterFormField.UseCost]: UseCostOptions.Actual,
}
