import { IAsyncRoute } from 'common/routing/routing.types'
import { PageNotFound } from 'page-not-found/page-not-found'
import { RunningAvailabilityModule, RunningAvailabilityModuleDefinition } from './running-availability.module'
import { runningAvailabilityRoutePath } from './running-availability.constants'
import { RunningAvailabilityGuard } from './running-availability.guard'

export const runningAvailabilityRoute: IAsyncRoute<RunningAvailabilityModuleDefinition> = {
  path: runningAvailabilityRoutePath,
  name: 'Running Availability',
  module: RunningAvailabilityModule,
  guards: [RunningAvailabilityGuard],
  childrenRoutesFactory: ({ RunningAvailabilityListPage }) => [
    {
      path: '',
      exact: true,
      component: RunningAvailabilityListPage,
    },
    {
      path: '*',
      component: PageNotFound,
      exact: true,
    },
  ],
}
