import * as React from 'react'
import { FC, useEffect, useMemo } from 'react'
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators'
import { Subscription } from 'rxjs'
import { FormSelectField } from 'common/utils/form/form.components'
import { globalConstants } from 'common/constants'
import { useXtForm } from 'common/hooks/form/form'
import { isEqual } from 'common/utils/object.utils'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import * as styles from './inventory-list-filter.module.scss'
import { AsOfFilters, IInventoryListFilterForm, IWorkbenchFilterParams, WorkbenchFilterFormField } from './inventory-list-filter.types'
import { defaultFilterState, filterOptions } from './inventory-list-filter.constants'
import { workbenchFilterValidationSchema } from './inventory-list-filter.validation'
import { convertToFilterParams, resolveSelectedFilterTemplate } from './inventory-list-filter.utils'

export const WorkbenchFilterComponent: FC<IWorkbenchFilterParams> = ({ onFilter, disabled = false }) => {
  const { control, watch, formValueChanges$, formChanges$, reset, trigger, setDisabled } = useXtForm<IInventoryListFilterForm>({
    defaultValues: defaultFilterState,
    validationSchema: workbenchFilterValidationSchema,
    mode: 'onBlur',
  })

  const filterField = watch(WorkbenchFilterFormField.FilterType)

  useEffect(() => {
    setDisabled(disabled)
    void trigger()
  }, [disabled, setDisabled, trigger])

  useEffect(() => {
    const sub = new Subscription()

    // date range validation
    sub.add(
      formValueChanges$
        .pipe(
          debounceTime(globalConstants.inputDebounce),
          filter((data) => data[WorkbenchFilterFormField.FilterType].id === AsOfFilters.Dates),
          distinctUntilChanged((prev, current) => {
            return prev[WorkbenchFilterFormField.StartDate]?.getTime() === current[WorkbenchFilterFormField.EndDate]?.getTime()
          }),
          switchMap(() => trigger([WorkbenchFilterFormField.StartDate, WorkbenchFilterFormField.EndDate]))
        )
        .subscribe()
    )

    // pagefilter on form values change
    sub.add(
      formChanges$
        .pipe(
          debounceTime(globalConstants.inputDebounce),
          filter(({ state }) => state.isValid && state.isDirty),
          distinctUntilChanged((prev, current) => isEqual(prev.data, current.data)),
          map(({ data }) => convertToFilterParams(data)),
          switchMap((value) => onFilter(value))
        )
        .subscribe()
    )

    return () => {
      sub.unsubscribe()
    }
  }, [formChanges$, formValueChanges$, onFilter, trigger])

  const filterTemplate = useMemo(() => resolveSelectedFilterTemplate(filterField.id, control, disabled), [
    filterField.id,
    disabled,
    control,
  ])

  const handleFilterTypeChange = (option: IXtAutocompleteOption): void => {
    reset({ filterType: option })
  }

  return (
    <form className={styles.formFilter}>
      <div className={styles.filterBlock}>
        <FormSelectField
          options={filterOptions}
          name={WorkbenchFilterFormField.FilterType}
          control={control}
          onChange={handleFilterTypeChange}
          disabled={disabled}
          label="As Of"
        />
        {filterTemplate}
      </div>
    </form>
  )
}
