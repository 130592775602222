import type { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { QuotesModule, QuotesModuleDefinition } from 'quotes/quotes.module'
import { SalesOrdersModule, SalesOrdersModuleDefinition } from 'sales-orders/sales-orders.module'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { ordersModuleToken } from './orders.constants'

export type OrdersModuleDefinition = QuotesModuleDefinition & SalesOrdersModuleDefinition

export const OrdersModule: ILazyModule<OrdersModuleDefinition> = {
  name: ordersModuleToken,
  resolver: async () => {
    const quotesModule = LazyModulesService.resolveModule<QuotesModuleDefinition>(QuotesModule)
    const salesOrdersModule = LazyModulesService.resolveModule<SalesOrdersModuleDefinition>(SalesOrdersModule)
    return {
      ...quotesModule,
      ...salesOrdersModule,
    }
  },
  initializer: () => {},
  dependencies: [QuotesModule, SalesOrdersModule],
}
