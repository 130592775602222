import { Observable } from 'rxjs'

import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { ITableRow } from 'components/table/table.types'
import { ILead } from 'leads/leads.types'

export enum LeadAction {
  Edit = 'edit',
  View = 'view',
  Detach = 'detach',
  Delete = 'delete',
}

export interface ILeadTableItem extends ITableRow, ILead {
  id: string
  account_number?: string
  contact_roles?: string
}

export interface ILeadList {
  account_number: string
  account_name: string
  isMobile: boolean
  disabled: boolean
  columns?: IXtTableColumn<ITableRow>[]
  refreshDataObservable?: Observable<unknown>
  editableByPermissions?: boolean
}
