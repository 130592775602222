import { IPaginationParams } from '../common.types'
import { checkAndCreateFiltersObject } from './filter.utils'
import { ISortOption, SortDirection } from '../../components/table/table-head/table-head.types'

export function prepareRequestParams<TFilters extends { [T in keyof TFilters]: TFilters[T] }>(
  paginationParams?: IPaginationParams,
  filters?: TFilters,
  sortOptions?: ISortOption[]
): Partial<TFilters> & Partial<IPaginationParams> {
  const filtersObject = checkAndCreateFiltersObject(filters)
  const sort = sortOptions?.find(({ sortDirection }) => sortDirection)
    ? sortOptions
        .filter(({ sortDirection }) => sortDirection)
        .map(({ sortField, sortDirection }) => `${sortDirection === SortDirection.Ascending ? '+' : '-'}${sortField}`)
        .join(',')
    : null

  if (paginationParams) {
    const { limit, page } = paginationParams
    return { limit, page: page !== 0 ? page : 1, ...filtersObject, sort } // page should start from 1
  }
  return { ...filtersObject, sort }
}

export function isRejectedPromise<T>(promise: PromiseSettledResult<T>): promise is PromiseRejectedResult {
  return promise.status === 'rejected'
}

export function isFulfilledPromise<T>(promise: PromiseSettledResult<T>): promise is PromiseFulfilledResult<T> {
  return promise.status === 'fulfilled'
}

export function isPromise<T>(promise: Promise<T> | undefined): promise is Promise<T> {
  return !!promise
}

type ExtractSettledPromisesType<T> = { result: { [P in keyof T]: ExtractSettledPromiseValueType<T[P]> | null }; errors: Error[] }
type ExtractSettledPromiseValueType<T> = T extends PromiseFulfilledResult<infer U> ? PromiseFulfilledResult<U>['value'] : null

export function processSettledPromises<T extends PromiseSettledResult<unknown>[]>(settledPromises: T): ExtractSettledPromisesType<T> {
  const result = new Array(settledPromises.length) as ExtractSettledPromisesType<T>['result']
  const errors = []

  for (let i = 0; i < settledPromises.length; i++) {
    const settledPromise = settledPromises[i]
    if (isRejectedPromise(settledPromise)) {
      errors.push(settledPromise.reason)
      result[i] = null
    } else {
      result[i] = settledPromise.value
    }
  }

  return { result, errors }
}
