import { Overwrite } from 'utility-types'
import { IItem, IItemSite } from 'products/items/items.types'
import { SingleFormRegister } from 'common/hooks/form/multiple-form-manager/multiple-form-manager.types'
import { XtMode } from 'common/common.types'
import { SiteOption } from 'core/services/sites.service'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { sitesDetailsDialogMainFormKey } from './sites-details-dialog-main.consts'
import { SiteTableItem } from '../../sites-tab.types'

export interface ISitesDetailsDialogMain {
  mode: XtMode
  register: SingleFormRegister<typeof sitesDetailsDialogMainFormKey, ISitesDetailsDialogMainForm>
  uomName: string | undefined
  item: IItem
  itemSite: IItemSite | null
}

export enum SitesDetailsDialogMainFormField {
  Site = 'site',
  Active = 'active',
  SoldFromSite = 'sold_from_site',
  Ranking = 'ranking',
}

export enum SitesDetailsDialogMainFormLabel {
  Site = 'Site',
  Active = 'Active',
  SoldFromSite = 'Sold from this Site',
  Ranking = 'Ranking',
}

export type SitesDetailsSiteOption = Pick<SiteOption, 'transit_site' | 'description' | keyof IXtAutocompleteOption>

export interface ISitesDetailsDialogMainForm
  extends Overwrite<
    Pick<
      SiteTableItem,
      | SitesDetailsDialogMainFormField.Site
      | SitesDetailsDialogMainFormField.Active
      | SitesDetailsDialogMainFormField.SoldFromSite
      | SitesDetailsDialogMainFormField.Ranking
    >,
    {
      [SitesDetailsDialogMainFormField.Site]: SitesDetailsSiteOption | null
    }
  > {}
