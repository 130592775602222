import * as React from 'react'
import { FC, useEffect } from 'react'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { FormDatePicker } from 'common/utils/form/form.components'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useXtForm } from 'common/hooks/form/form'
import { IFilter } from 'components/pagefilter/filter/filter.types'
import { XtPageFilter } from 'components/pagefilter/pagefilter'
import { usePageFilter } from 'components/pagefilter/pagefilter.utils'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { useInventoryHistoryModule } from 'inventory/inventory-history-section/inventory-history-module-hook'
import { resolveFilters } from './inventory-history-list-form.utils'
import {
  InventoryHistoryFilterField,
  InventoryHistoryFilterLabel,
  InventoryHistoryListParams,
  InventoryHistoryFormState,
} from '../inventory-history-list.types'
import { currentDate, defaultFilterValues } from './inventory-history-list-form.constants'
import * as styles from './inventory-history-list-form.module.scss'
import { inventoryHistoryValidationScheme } from './inventory-history-list-form.validation'

export const InventoryHistoryListForm: FC<InventoryHistoryListParams> = ({ onChange, filters, disabled }) => {
  const { DocumentsUtilsService } = useDocumentsModule()
  const { CoreUtilsService, ErrorHandler } = useCoreModule()
  const { CostingItemCostsUtilsService } = useInventoryHistoryModule()

  const {
    control,
    watch,
    formState: { isValid },
    reset,
  } = useXtForm<InventoryHistoryFormState>({
    defaultValues: defaultFilterValues,
    mode: 'onBlur',
    validationSchema: inventoryHistoryValidationScheme,
  })

  const startDate = watch(InventoryHistoryFilterField.StartDate)
  const endDate = watch(InventoryHistoryFilterField.EndDate)

  const onSubmit = (state: InventoryHistoryFormState): void => {
    if (isValid) {
      onChange({ ...state, startDate, endDate })
    }
  }

  const invHistoryListFilters = usePageFilter<InventoryHistoryFormState>(PageFilterMapping.InvHistory)

  const onSubmitWithUpdateFilter = (state: InventoryHistoryFormState): void => {
    void invHistoryListFilters.handleLastUsedFilter(state)
    onChange(state)
  }

  useEffect(() => {
    if (invHistoryListFilters.lastUsedFilter) {
      reset({
        startDate: invHistoryListFilters.lastUsedFilter[InventoryHistoryFilterField.StartDate] ?? currentDate,
        endDate: invHistoryListFilters.lastUsedFilter[InventoryHistoryFilterField.EndDate] ?? currentDate,
      })
    }
  }, [invHistoryListFilters.lastUsedFilter])

  const print = async (): Promise<void> => {
    try {
      await CostingItemCostsUtilsService.printInventoryHistory(filters)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const startDateOnChange = (newStartDate: Date): void => {
    onSubmitWithUpdateFilter({ ...filters, startDate: newStartDate })
  }

  const endDateOnChange = (newEndDate: Date): void => {
    onSubmitWithUpdateFilter({ ...filters, endDate: newEndDate })
  }

  return (
    <div className={styles.formContent}>
      <div className={styles.filtersContent}>
        <XtPageFilter
          state={invHistoryListFilters}
          defaultFilterValues={defaultFilterValues}
          resolveFilters={async () => (await resolveFilters(DocumentsUtilsService, CoreUtilsService)) as IFilter[]}
          filter={onSubmit}
          tableFilters={filters}
        />
        <div className={styles.filtersContentDates}>
          <FormDatePicker
            name={InventoryHistoryFilterField.StartDate}
            label={InventoryHistoryFilterLabel.StartDate}
            control={control}
            maxDate={currentDate}
            onChange={startDateOnChange}
          />
          <FormDatePicker
            name={InventoryHistoryFilterField.EndDate}
            label={InventoryHistoryFilterLabel.EndDate}
            control={control}
            minDate={startDate}
            onChange={endDateOnChange}
          />
        </div>
      </div>
      <XtResponsiveButton showIconOnDesktopMode={false} icon={SvgIconIds.PRINT} label="Print" disabled={disabled} onClick={print} />
    </div>
  )
}
