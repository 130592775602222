import { Observable } from 'rxjs'
import { IContact } from 'contacts/contacts.types'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { ITableRow } from 'components/table/table.types'

export enum ContactAction {
  Edit = 'edit',
  View = 'view',
  Detach = 'detach',
  Delete = 'delete',
  Copy = 'copy',
}

export interface IContactTableItem extends ITableRow, IContact {
  id: string
  account_number?: string
  contact_roles?: string
}

export interface IContactList {
  account_number: string
  account_name: string
  isMobile: boolean
  disabled: boolean
  columns?: IXtTableColumn<ITableRow>[]
  refreshDataObservable?: Observable<unknown>
  editableByPermissions?: boolean
  mode?: string
  infoMsg?: string
}
