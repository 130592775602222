import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { IPaginationData } from 'common/common.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IItemSitesService } from './item-sites.service'

export interface IItemSitesUtilsService {
  fetchLotSerialSequences(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>>
}

export class ItemSitesUtilsService implements IItemSitesUtilsService {
  constructor(private readonly itemSitesService: IItemSitesService) {
    bindAllInstanceMethods(this)
  }

  public async fetchLotSerialSequences(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
    const data = await this.itemSitesService.getLotSerialSequences({ page, limit })
    return {
      data: data.map((seq) => ({ id: seq.number, label: seq.description ?? seq.number })),
      total: data.length,
    }
  }
}
