import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { ICostedBomWithId } from '../bom-utils.types'
import { numberConverter } from '../../../common/utils/utils'

export function getItemSingleLevelActions(item: ICostedBomWithId, actions: IXtTableCellAction[]): IXtTableCellAction[] {
  return item.seq ? actions : []
}

export function singleLevelNumberConverter(value: unknown): string {
  if (typeof value !== 'number' && !value) {
    return ''
  }
  return numberConverter(value, 4)
}
