import React from 'react'
import { cls } from 'common/utils/utils'
import { XtAutocomplete } from 'components/controls/xt-autocomplete/xt-autocomplete'
import { getAutocompleteInputLabelAsId, renderColumnOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { XtAutocompleteFiltersType, XtAutocompleteLoadOptionsFunc } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IXtItemNumber, IXtItemNumberOptionBase } from './item-number.types'
import * as styles from './item-number.module.scss'

export function XtItemNumber<
  LoadFunc extends XtAutocompleteLoadOptionsFunc<IXtItemNumberOptionBase, TFilters>,
  TFilters = XtAutocompleteFiltersType<LoadFunc>
>({
  value,
  error,
  isEditMode,
  hidden,
  className,
  label = 'Item #',
  filters,
  onBlur,
  onChange = () => {},
  loadOptions,
  loading,
  disabled,
  required,
  renderOption = renderColumnOption,
  getInputLabel = getAutocompleteInputLabelAsId,
}: IXtItemNumber<LoadFunc>): React.ReactElement {
  return (
    <div hidden={hidden} className={cls(styles.xtItemNumber, className)}>
      {isEditMode && loadOptions ? (
        <XtAutocomplete
          value={value}
          error={error}
          loading={loading}
          loadOptions={loadOptions}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          getInputLabel={getInputLabel}
          renderOption={renderOption}
          filters={filters}
          className={styles.xtItemNumberControl}
          placeholder={label}
          required={required}
        />
      ) : (
        <h3 hidden={!value?.itemNumber && !value?.item_number} className="xt-subPage-title" title={value?.itemNumber ?? value?.item_number}>
          {value?.itemNumber ?? value?.item_number}
        </h3>
      )}
      <div hidden={!value?.uom && !value?.description1} className={styles.additionalInfo}>
        <span hidden={!value?.uom && !value?.inventory_uom_name}>{`UOM: ${value?.uom ?? value?.inventory_uom_name}`}</span>
        <span hidden={!value?.description1} title={value?.description1}>
          {value?.description1}
        </span>
        <span hidden={!value?.description2} title={value?.description2}>
          {value?.description2}
        </span>
      </div>
    </div>
  )
}
