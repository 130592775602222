import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IContact, IContactAddress } from 'contacts/contacts.types'
import { IHttpClient } from './http-client'

export interface ISite {
  site: string
  site_type: string
  active: boolean
  description: string
  site_contact: IContact | null
  site_address: IContactAddress | null
  unassigned_transaction_account: string
  shipping_site: boolean
  transit_site: boolean
  next_bill_of_lading_prefix: string
  next_bill_of_lading_number: number
  next_count_tag_prefix: string
  next_count_tag_number: number
  force_the_use_of_count_slips: boolean
  force_the_use_of_zones: boolean
  scheduling_sequence: number
  shipping_commission: number
  tax_zone: string | null
  default_fob: string
  default_ship_via: string
  default_shipping_form: string
  default_cost_category: string
  shipping_comments: string
  enforce_arbl_naming_convention: boolean
  aisle_size: number
  aisle_allow_alpha_characters: boolean
  rack_size: number
  rack_allow_alpha_characters: boolean
  bin_size: number
  bin_allow_alpha_characters: boolean
  location_size: number
  location_allow_alpha_characters: boolean
}

export type SiteOption = ISite & IXtAutocompleteOption

export interface ISiteFilters {
  showinactive?: boolean
  showDetail?: boolean
}

export interface ISitesService {
  getAll(pagination?: IPaginationParams, filters?: ISiteFilters): Promise<IPaginationData<ISite>>
  get(site: string): Promise<ISite>
}

export class SitesService implements ISitesService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getAll(pagination?: IPaginationParams, filters?: ISiteFilters): Promise<IPaginationData<ISite>> {
    const params = prepareRequestParams(pagination, filters)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ISite>>(`/site`, {
      params,
    })
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(site: string): Promise<ISite> {
    const { data } = await this.apiClient.get<IResponse<ISite>>(`/site/${site}`)

    return data.data
  }
}
