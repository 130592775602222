import { Dialog } from '@material-ui/core'
import * as React from 'react'
import { FC } from 'react'
import { XtButton } from '../buttons/xt-button/xt-button'
import * as style from './xt-confirmation-dialog.module.scss'

export interface IXtConfirmationDialogParams {
  open: boolean
  message: string
  title?: string
  confirmationButtonLabel: string
  cancelButtonLabel?: string
  ariaLabel?: string
  ariaDescription?: string
  onConfirm(): void
  onClose(): void
}

export const XtConfirmationDialog: FC<IXtConfirmationDialogParams> = ({
  open,
  onClose,
  onConfirm,
  message,
  confirmationButtonLabel,
  cancelButtonLabel,
  title,
  ariaLabel,
  ariaDescription,
}) => (
  <Dialog open={open} onClose={onClose} aria-labelledby={ariaLabel} aria-describedby={ariaDescription}>
    <div className={style.xtConfirmationDialog}>
      <h4 hidden={!title} className={style.xtConfirmationDialogTitle}>
        {title}
      </h4>
      <div>{message}</div>

      <div className={style.xtConfirmationDialogButtons}>
        <XtButton label={cancelButtonLabel ?? 'Cancel'} onClick={onClose} />
        <XtButton label={confirmationButtonLabel} onClick={onConfirm} />
      </div>
    </div>
  </Dialog>
)
