import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { isValidDate } from 'common/utils/date.utils'
import { IDatePicker } from '../../controls/date-picker/date-picker.types'
import { XtDatePicker } from '../../controls/date-picker/date-picker'

function defineOutputValue(currentDate: Date | null, initialDate: Date | null): Date | null {
  if (currentDate === null) {
    return null
  }
  return isValidDate(currentDate) ? currentDate : initialDate
}

export const XtEditableCellDatepicker: FC<IDatePicker> = ({ value, onBlur, onChange, ...restProps }) => {
  const [state, setState] = useState<Date | null>(value)

  const isBlurredRef = useRef<boolean>(false)

  // we should keep the state in the ref as well, so we prevent any issues with the race condition: onBlur function should have the access the actual state
  const stateRef = useRef(value)

  useEffect(() => {
    setState(value)
  }, [value])

  const handleBlur: VoidFunction = useCallback(() => {
    const date = defineOutputValue(stateRef.current, value)
    onChange(date)
    if (typeof onBlur === 'function') {
      onBlur()
    }

    isBlurredRef.current = true
  }, [onBlur, onChange, value])

  const handleChange = (date: Date | null): void => {
    stateRef.current = date
    setState(date)
  }

  useEffect(() => {
    return () => {
      if (!isBlurredRef.current) {
        handleBlur()
      }
    }
  }, [handleBlur])

  return <XtDatePicker onChange={handleChange} value={state} onBlur={handleBlur} {...restProps} />
}
