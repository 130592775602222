import React, { FC, useState } from 'react'
import { Paper, Tab, Tabs } from '@material-ui/core'
import { IXtTabs, IXtTab } from './xt-tabs.types'
import * as styles from './xt-tabs.module.scss'
import { cls } from '../../common/utils/utils'

const renderTabsPanel = ({ name, disabled = false, markAsInvalid = false }: IXtTab, mapKey: number, width: string): React.ReactElement => (
  <Tab
    disabled={disabled}
    style={{
      width,
    }}
    classes={{
      root: cls(styles.xtTabRoot, markAsInvalid && styles.xtTabInvalid),
      wrapper: styles.xtTabWrapper,
      textColorPrimary: styles.xtTabTextColorPrimary,
      selected: styles.xtTabSelected,
      disabled: styles.xtTabDisabled,
    }}
    key={`${mapKey}${name}`}
    label={name}
  />
)

function findFirstAvailableTabIndex(tabs: IXtTab[]): number {
  const index = tabs.findIndex(({ disabled }) => !disabled)
  return index !== -1 ? index : 0
}

export const XtTabs: FC<IXtTabs> = ({ tabs, className, classNameRoot, classNameTemplate }) => {
  const filteredTabs = tabs.filter(({ hidden }) => !hidden)

  const [tabIndex, setTabIndex] = useState<number>(() => findFirstAvailableTabIndex(filteredTabs))

  // we should change the tab index if the current one points to a disabled tab
  if (filteredTabs[tabIndex].disabled) {
    setTabIndex(findFirstAvailableTabIndex(filteredTabs))
  }

  const handleChange = (_: React.ChangeEvent<{}>, value: number): void => setTabIndex(value)

  const tabPanelWidth = `${100 / filteredTabs.length}%`

  return (
    <div className={cls(styles.xtTabs, classNameRoot)}>
      <Paper classes={{ root: styles.xtTabPaperRoot }} square elevation={0}>
        <Tabs
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          classes={{ indicator: styles.xtTabsIndicator, flexContainer: styles.xtTabFlexContainer }}
          value={tabIndex}
          onChange={handleChange}
          className={className}
        >
          {filteredTabs.map((xtTab, mapKey) => renderTabsPanel(xtTab, mapKey, tabPanelWidth))}
        </Tabs>
      </Paper>
      {filteredTabs.map(({ template }, index) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <div className={cls(styles.xtTabContent, classNameTemplate)} key={`XtTab-${index}`} hidden={tabIndex !== index}>
          {template}
        </div>
      ))}
    </div>
  )
}
