import React from 'react'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { IFormHook } from 'common/hooks/form/form.types'
import * as styles from './print-labels.module.scss'
import { PrintSharedFormField, PrintSharedFormLabel } from '../print-dialogs.types'
import { IPrintLabelsForm, IPrintLabelsParams } from './print-labels.types'

export function PrintLabels<FormValues extends IPrintLabelsForm>({ formMethods }: IPrintLabelsParams<FormValues>): React.ReactElement {
  const { control, trigger } = (formMethods as unknown) as IFormHook<IPrintLabelsForm>

  const triggerValidation = async (field: keyof IPrintLabelsForm): Promise<void> => {
    await trigger(field)
  }

  return (
    <div>
      <p className={styles.printLabelsTitle}>Labels:</p>
      <div className={styles.printLabelsFromTo}>
        <DecimalFormField
          fixedDecimalScale={0}
          name={PrintSharedFormField.From}
          control={control}
          label={PrintSharedFormLabel.From}
          onBlur={() => triggerValidation(PrintSharedFormField.To)}
        />
        <DecimalFormField
          fixedDecimalScale={0}
          name={PrintSharedFormField.To}
          control={control}
          label={PrintSharedFormLabel.To}
          onBlur={() => triggerValidation(PrintSharedFormField.From)}
        />
      </div>
    </div>
  )
}
