import React from 'react'
import { convertDate, isValidDate } from 'common/utils/date.utils'
import { IXtAutocompleteOption } from '../../controls/xt-autocomplete/xt-autocomplete.types'
import {
  IXtEditableCell,
  XtEditableCellControlDate,
  XtEditableCellControlInput,
  XtEditableCellControlSelect,
  XtEditableCellType,
} from './table-editable-cell.types'
import { XtSelectCreatable } from '../../controls/xt-select/xt-select-creatable'
import { XtEditableCellInput } from './table-editable-cell-input'
import { XtSelect } from '../../controls/xt-select/xt-select'
import { XtEditableCellDatepicker } from './table-editable-cell-datepicker'

export function isInputType(control: Pick<IXtEditableCell<unknown>, 'type'>): control is XtEditableCellControlInput {
  return control.type === XtEditableCellType.Number || control.type === XtEditableCellType.Input
}

export function isDateType(control: IXtEditableCell<unknown>): control is XtEditableCellControlDate {
  return control.type === XtEditableCellType.Date
}

export function isSelectType(control: IXtEditableCell<unknown>): control is XtEditableCellControlSelect<IXtAutocompleteOption> {
  return control.type === XtEditableCellType.Select
}

export function isSelectCreatableType(control: IXtEditableCell<unknown>): control is XtEditableCellControlSelect<IXtAutocompleteOption> {
  return control.type === XtEditableCellType.SelectCreatable
}

export function convertValue<TValue>(cell: IXtEditableCell<TValue>): string {
  if (cell.value === null || cell.value === undefined) {
    return ''
  }
  if (isInputType(cell)) {
    return cell.type === XtEditableCellType.Number && cell.decimalScale
      ? Number(cell.value).toFixed(cell.decimalScale)
      : cell.value.toString()
  }
  if (isDateType(cell)) {
    return isValidDate(cell.value) ? convertDate(cell.value) : cell.value.toString()
  }
  if (isSelectType(cell) || isSelectCreatableType(cell)) {
    return cell.value.label
  }
  return String(cell.value)
}

export function generateControl<TValue>(cell: IXtEditableCell<TValue>): React.ReactElement | null {
  if (isInputType(cell)) {
    return (
      <XtEditableCellInput
        disabled={cell.disabled}
        value={cell.value}
        onBlur={cell.onBlur}
        onChange={cell.onChange}
        type={cell.type}
        decimalScale={cell.decimalScale}
      />
    )
  }
  if (isDateType(cell)) {
    return (
      <XtEditableCellDatepicker
        disabled={cell.disabled}
        className="xt-table-editable-cell-picker"
        value={cell.value}
        onBlur={cell.onBlur}
        onChange={cell.onChange}
      />
    )
  }
  if (isSelectType(cell)) {
    return (
      <XtSelect
        disabled={cell.disabled}
        className="xt-table-editable-cell-select"
        label=""
        value={cell.value}
        options={cell.options ?? []}
        onChange={cell.onChange}
        onBlur={cell.onBlur}
        disableInput={true}
      />
    )
  }
  if (isSelectCreatableType(cell)) {
    return (
      <XtSelectCreatable
        disabled={cell.disabled}
        className="xt-table-editable-cell-select"
        label=""
        value={cell.value}
        options={cell.options ?? []}
        onChange={cell.onChange}
        onBlur={cell.onBlur}
      />
    )
  }
  return null
}
