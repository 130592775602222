import { IItem, IItemSite, ItemType } from 'products/items/items.types'
import {
  SitesDetailsDialogCheckboxesForm,
  SitesDetailsDialogCheckboxesFormDisableState,
  SitesDetailsDialogCheckboxesFormField,
} from './sites-details-dialog-checkboxes.types'

export function sitesDetailsDialogCheckboxesDefaults(item: IItem, site: IItemSite | null): SitesDetailsDialogCheckboxesForm {
  const itemTypeIsManufactured = item.item_type === ItemType.Manufactured
  return {
    [SitesDetailsDialogCheckboxesFormField.WoSuppliedAtSite]: itemTypeIsManufactured ? Boolean(site?.wo_supplied_at_site) : false,
    [SitesDetailsDialogCheckboxesFormField.CreateWos]: site?.create_wos ?? false,
    [SitesDetailsDialogCheckboxesFormField.PoSuppliedAtSite]: site?.po_supplied_at_site ?? false,
    [SitesDetailsDialogCheckboxesFormField.CreatePrs]: site?.create_prs ?? false,
    [SitesDetailsDialogCheckboxesFormField.CreateSoprs]: site?.create_soprs ?? false,
    [SitesDetailsDialogCheckboxesFormField.CreateSopos]: site?.create_sopos ?? false,
    [SitesDetailsDialogCheckboxesFormField.Dropship]: site?.dropship ?? false,
    [SitesDetailsDialogCheckboxesFormField.SuppressAutomaticOrders]: site?.suppress_automatic_orders ?? false,
  }
}

export function setFormFieldsDisabled(
  isViewMode: boolean,
  item: IItem,
  isTransitSite: boolean,
  isJobCosted: boolean,
  woSuppliedAtSite: boolean,
  poSuppliedAtSite: boolean,
  createSopos: boolean
): SitesDetailsDialogCheckboxesFormDisableState {
  const itemTypeIsManufactured = item.item_type === ItemType.Manufactured
  const itemTypeIsPlanningOrKit = item.item_type === ItemType.Planning || item.item_type === ItemType.Kit
  const itemTypeIsPurchased = item.item_type === ItemType.Purchased

  return {
    [SitesDetailsDialogCheckboxesFormField.WoSuppliedAtSite]: isViewMode || !itemTypeIsManufactured,
    [SitesDetailsDialogCheckboxesFormField.CreateWos]: isViewMode || !woSuppliedAtSite,

    [SitesDetailsDialogCheckboxesFormField.PoSuppliedAtSite]: isViewMode || isTransitSite || itemTypeIsPlanningOrKit || isJobCosted,

    [SitesDetailsDialogCheckboxesFormField.CreatePrs]: isViewMode || !poSuppliedAtSite || !itemTypeIsPurchased || isJobCosted,
    [SitesDetailsDialogCheckboxesFormField.CreateSoprs]: isViewMode || !poSuppliedAtSite || !itemTypeIsPurchased || isJobCosted,
    [SitesDetailsDialogCheckboxesFormField.CreateSopos]: isViewMode || !poSuppliedAtSite || !itemTypeIsPurchased || isJobCosted,

    [SitesDetailsDialogCheckboxesFormField.Dropship]: isViewMode || !createSopos || !itemTypeIsPurchased || isJobCosted,

    [SitesDetailsDialogCheckboxesFormField.SuppressAutomaticOrders]: isViewMode,
  }
}
