// extracted by mini-css-extract-plugin
export var root = "conversion-details-dialog_root__rSQbj";
export var xtContent = "conversion-details-dialog_xt-content__tiwD9";
export var xtContentWithRemarks = "conversion-details-dialog_xt-content-with-remarks__237dg";
export var muiFormHelperTextRoot = "conversion-details-dialog_MuiFormHelperText-root__rZ7C-";
export var xtListPageContainer = "conversion-details-dialog_xt-list-page-container__A1vAa";
export var muiInputBaseRoot = "conversion-details-dialog_MuiInputBase-root__EYulH";
export var muiTextFieldRoot = "conversion-details-dialog_MuiTextField-root__WdY3D";
export var muiTablePaginationRoot = "conversion-details-dialog_MuiTablePagination-root__5+GBI";
export var muiTablePaginationSelectRoot = "conversion-details-dialog_MuiTablePagination-selectRoot__GH2e8";
export var muiTablePaginationSelect = "conversion-details-dialog_MuiTablePagination-select__XYqGK";
export var muiStepperRoot = "conversion-details-dialog_MuiStepper-root__rC-jx";
export var muiStepperHorizontal = "conversion-details-dialog_MuiStepper-horizontal__D7lCL";
export var muiStepIconRoot = "conversion-details-dialog_MuiStepIcon-root__3MMjS";
export var muiStepIconActive = "conversion-details-dialog_MuiStepIcon-active__8u8PC";
export var muiStepIconCompleted = "conversion-details-dialog_MuiStepIcon-completed__cZ1ch";
export var muiListItemRoot = "conversion-details-dialog_MuiListItem-root__SOICt";
export var muiSelected = "conversion-details-dialog_Mui-selected__zRPRf";
export var xtPageTitle = "conversion-details-dialog_xt-page-title__83z9W";
export var xtSubPageTitle = "conversion-details-dialog_xt-subPage-title__XY8o2";
export var xtSectionBorder = "conversion-details-dialog_xt-section-border__37g0d";
export var xtPageHeader = "conversion-details-dialog_xt-page-header__2MSKr";
export var xtDialogScrollable = "conversion-details-dialog_xt-dialog-scrollable__vwpTT";
export var reactToastNotificationsContainer = "conversion-details-dialog_react-toast-notifications__container__5ffgW";
export var xtScrollbarStyle = "conversion-details-dialog_xt-scrollbar-style__gOiSs";
export var xtModalDetailsContent = "conversion-details-dialog_xt-modal-details-content__MYSvi";
export var conversionsDialogContainer = "conversion-details-dialog_conversionsDialogContainer__Zv6vt";
export var conversionsDialogSection = "conversion-details-dialog_conversionsDialogSection__NLWij";
export var xtDialogDetailsContent = "conversion-details-dialog_xt-dialog-details-content__hl8C0";
export var xtLazyModuleLoaderTabContainer = "conversion-details-dialog_xt-lazy-module-loader-tab-container__uOXNb";
export var xtTextTruncated = "conversion-details-dialog_xt-text-truncated__j4Q2w";
export var xtSummaryContent = "conversion-details-dialog_xt-summary-content__bYly5";
export var xtSummaryLabel = "conversion-details-dialog_xt-summary-label__8MRrq";
export var visibleHidden = "conversion-details-dialog_visible-hidden__BxEbN";
export var headerBottomMargin = "conversion-details-dialog_header-bottom-margin__7J5DV";
export var xtDialogHeaderLogoAlign = "conversion-details-dialog_xt-dialog-header-logoAlign__0RDcI";
export var xtStickyHeader = "conversion-details-dialog_xt-sticky-header__jGVf5";
export var noBorder = "conversion-details-dialog_no-border__DbPMX";
export var muiOutlinedInputNotchedOutline = "conversion-details-dialog_MuiOutlinedInput-notchedOutline__6QBmX";
export var conversionsDialogForm = "conversion-details-dialog_conversionsDialogForm__Z2acw";
export var conversionsDialogFormIcon = "conversion-details-dialog_conversionsDialogFormIcon__Yp4-v";
export var conversionsDialogColumn = "conversion-details-dialog_conversionsDialogColumn__zYfl8";
export var controlButtonsBlock = "conversion-details-dialog_controlButtonsBlock__zaM+P";