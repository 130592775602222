import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { IUser } from 'users/users.types'
import { cls } from 'common/utils/utils'
import * as styles from './personal-profile.module.scss'
import { XtDialog, XtDialogAnimation } from '../../../components/xt-dialog/xt-dialog'
import { ProfileDialog } from '../../../profile/profile'
import { useUsersModule } from '../../../users/users-module-hook'
import { PersonalProfileMenuItem } from './personal-profile-menu-items'

export const PersonalProfile: FC = () => {
  const { UsersService } = useUsersModule()

  const [user, setUser] = useState<IUser | null>(null)
  const [open, setOpen] = useState(false)
  const [isProfileDetailsDialogOpen, openProfileDetailsDialog] = useState<boolean>(false)

  const anchorRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const sub = UsersService.user$.subscribe(setUser)

    return () => sub.unsubscribe()
  }, [UsersService])

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen)
  }, [])

  const toggleProfile = useCallback(() => {
    openProfileDetailsDialog((prev) => !prev)
  }, [])

  const handleClose: (event: React.MouseEvent<Document, MouseEvent>) => void = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  return (
    <div>
      <XtDialog open={isProfileDetailsDialogOpen} fullScreen={false} animation={XtDialogAnimation.SlideAnimation}>
        <ProfileDialog onClose={toggleProfile} />
      </XtDialog>
      <span
        ref={anchorRef}
        className={cls(styles.logout, open && styles.hoverLogout)}
        onClick={handleToggle}
        role="button"
        tabIndex={0}
        onKeyPress={handleToggle}
      >
        <SvgIcon iconId={SvgIconIds.USER_ICON} />
        <p className={styles.logoutText}>My Account</p>
      </span>
      <Popper className={styles.personalProfileDialog} open={open} placement="bottom-end" anchorEl={anchorRef.current} transition>
        {({ TransitionProps }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
          >
            <Paper className={styles.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className={styles.menuList}>
                  <MenuItem hidden={!user} divider onClick={toggleProfile}>
                    <div className={styles.profileCard}>
                      <span className={styles.username} title={user?.proper_name}>
                        {user?.proper_name}
                      </span>
                      <span className={styles.email} title={user?.email}>
                        {user?.email}
                      </span>
                    </div>
                  </MenuItem>

                  <PersonalProfileMenuItem />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
