import { CalendarModuleDefinition } from './calendar-module.types'
import { LazyModulesService } from '../core/react-lazy-modules/react-lazy-modules.service'
import { calendarModuleToken } from './calendar.constants'
import { moduleHookResolver } from '../core/react-lazy-modules/react-lazy-modules.utils'

function resolveCalendarModule(): CalendarModuleDefinition {
  const module = LazyModulesService.resolveModule<CalendarModuleDefinition>(calendarModuleToken)

  return { ...module }
}

export const useCalendarModule = moduleHookResolver<CalendarModuleDefinition>(resolveCalendarModule)
