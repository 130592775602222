import {
  IMaintainShippingFilterForm,
  IMaintainShippingFilterFormFilled,
  MaintainShippingFilterField,
} from './maintain-shipping-filter.types'

export function convertFormData(filterData: IMaintainShippingFilterForm): IMaintainShippingFilterFormFilled {
  return {
    [MaintainShippingFilterField.Customer]: filterData[MaintainShippingFilterField.Customer]?.id ?? null,
    [MaintainShippingFilterField.Site]: filterData[MaintainShippingFilterField.Site]?.id ?? null,
    [MaintainShippingFilterField.OrderNumber]: filterData[MaintainShippingFilterField.OrderNumber]?.id ?? null,
  }
}
