import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { useMediaQuery } from '@material-ui/core'
import { useXtForm } from 'common/hooks/form/form'
import { FormDatePicker, FormField, FormSelectField, FormTextAreaField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { getAutocompleteInputLabelAsId, renderColumnOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { cls } from 'common/utils/utils'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { XtTabs } from 'components/xt-tabs/xt-tabs'

import { DocumentType, UsedOnValue } from 'documents/documents.types'
import { XtInput } from 'components/controls/xt-input/xt-input'
import { useContactsModule } from 'contacts/contacts-module-hook'

import { IXtTab } from 'components/xt-tabs/xt-tabs.types'
import { useInventoryAdjustmentModule } from 'inventory/inventory-adjustments/inventory-adjustments-module.hook'
import { useCharacteristicsModule } from 'characteristics/characteristics-module-hook'
import { useProductsModule } from 'products/products-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { SalesOrderOption } from 'sales-shared/sales-orders.types'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { defineItemNumberOption } from 'products/items/components/item-number/item-number.utils'
import { useShipmentsModule } from 'shipments/shipments-module-hook'
import { XtInfoValues } from 'components/xt-info-values/xt-info-values'
import { XtInfoValue } from 'components/xt-info-values/xt-info-values.types'
import { OrderType } from 'core/core.types'
import { IXtAutocompleteOption, XtAutocompleteLoadOptionsFunc } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { xsMq } from 'common/constants'
import { useXtSelect } from 'components/controls/xt-select/xt-select-hook'
import { convertMode } from 'common/utils/mode.utils'
import { LoadingSpinner } from 'components/loading-spinner'
import * as styles from './registration-dialog.module.scss'
import { convertRegistrationPayload, defineRegistrationFormValues, isValidFormState } from './registration-dialog.utils'
import { defaultRegistrationDialogState } from './registration-dialog.constants'
import {
  IRegistrationDialog,
  IRegistrationDialogState,
  RegistrationDialogTab,
  RegistrationLabel,
  RegistrationDialogForm,
  RegistrationInfoValueLabel,
  RegistrationDialogFormField,
} from './registration-dialog.types'
import { registrationDialogValidationSchema } from './registration-dialog.validation'

export const RegistrationDialog: FC<IRegistrationDialog> = ({
  onClose,
  onSubmit,
  itemNumber,
  lotSerialNumber,
  mode,
  registrationNumber,
}) => {
  const isMobile = useMediaQuery(xsMq)
  const { isEditMode } = convertMode(mode)

  const { XtItemNumber, ItemsService } = useProductsModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { useCharacteristics, XtCharacteristics } = useCharacteristicsModule()
  const { DocumentsService, DocumentsUtilsService } = useDocumentsModule()
  const { ShipmentsUtilsService } = useShipmentsModule()
  const { FormContactSearch } = useContactsModule()
  const { RegistrationUtilsService, RegistrationService } = useInventoryAdjustmentModule()

  const [{ item, loading }, setState] = useState<IRegistrationDialogState>(defaultRegistrationDialogState)

  const { options, reset: resetRegistrationType } = useXtSelect(DocumentsUtilsService.loadLotSerialRegistrationTypeOptions)

  const characteristicsState = useCharacteristics([])

  const { reset: characteristicsReset } = characteristicsState

  const {
    control,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting, isDirty: isFormDirty, errors, touchedFields },
    trigger,
  } = useXtForm<RegistrationDialogForm>({
    mode: 'onBlur',
    validationSchema: registrationDialogValidationSchema,
  })

  const isDirty = isFormDirty || characteristicsState.isDirty

  const accountNumber = watch(RegistrationDialogFormField.AccountNumber)
  const salesOrderNumber = watch(RegistrationDialogFormField.SalesOrder)

  const markContactAsInvalid = !!errors[RegistrationDialogFormField.Contact] && !!touchedFields[RegistrationDialogFormField.Contact]

  const infoValues: XtInfoValue[] = useMemo(
    () => [
      {
        label: RegistrationInfoValueLabel.SO,
        value: salesOrderNumber?.order_status ?? null,
      },
      {
        label: RegistrationInfoValueLabel.Customer,
        value: salesOrderNumber?.customer_number ?? null,
      },
    ],
    [salesOrderNumber]
  )

  const shipmentFilters = useMemo(
    () => ({
      order_number: salesOrderNumber?.order_number,
      item_number: !salesOrderNumber ? itemNumber : undefined,
      order_type: salesOrderNumber ? OrderType.SalesOrder : undefined,
    }),
    [itemNumber, salesOrderNumber]
  )

  const onCancel: VoidFunction = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave the dialog? Updates will not be applied.')) {
      return
    }
    onClose()
  }

  const onSaveForm: (formData: RegistrationDialogForm) => Promise<void> = async (formData) => {
    try {
      if (isValidFormState(formData)) {
        const payload = convertRegistrationPayload(formData, characteristicsState.characteristics, lotSerialNumber, itemNumber)
        if (isEditMode) {
          const message = await RegistrationService.update(payload)
          ToastService.showSuccess(message)
        } else {
          const message = await RegistrationService.create(payload)
          ToastService.showSuccess(message)
        }

        onClose()
        await onSubmit()
      }
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const onAccountNumberChange = useCallback<(accountNumberValue: IXtAutocompleteOption | null) => void>(
    (accountNumberValue) => {
      setValue(RegistrationDialogFormField.AccountNumber, accountNumberValue, { shouldValidate: true, shouldDirty: true })
      setValue(RegistrationDialogFormField.AccountName, accountNumberValue?.label ?? null)

      setValue(RegistrationDialogFormField.SalesOrder, null, { shouldValidate: true, shouldDirty: true })
      setValue(RegistrationDialogFormField.Shipment, null, { shouldValidate: true, shouldDirty: true })
      setValue(RegistrationDialogFormField.Contact, null, { shouldValidate: true, shouldDirty: true })
    },
    [setValue]
  )

  const onSalesOrderChange = useCallback<(salesOrderOption: SalesOrderOption | null) => void>(
    (salesOrderOption) => {
      setValue(RegistrationDialogFormField.SalesOrder, salesOrderOption, { shouldValidate: true, shouldDirty: true })
      setValue(RegistrationDialogFormField.Shipment, null, { shouldValidate: true, shouldDirty: true })
    },
    [setValue]
  )

  const onShipmentChange = useCallback<(shipmentOption: IXtAutocompleteOption | null) => void>(
    (shipmentOption) => {
      setValue(RegistrationDialogFormField.Shipment, shipmentOption, { shouldValidate: true, shouldDirty: true })
      void trigger(RegistrationDialogFormField.SalesOrder)
    },
    [setValue, trigger]
  )

  const loadAccounts = useCallback<XtAutocompleteLoadOptionsFunc>(
    async (page, limit, filter) => {
      const { data, total } = await DocumentsService.getDocuments(DocumentType.Account, { page, limit }, { search_pattern: filter })

      return { data: data.map(({ number, name }) => ({ id: number, label: name })), total }
    },
    [DocumentsService]
  )

  useEffect(() => {
    const init = async (): Promise<void> => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }))

        const itemData = await ItemsService.get(itemNumber)

        setState((prev) => ({ ...prev, item: itemData }))

        const [defaultRegistrationType] = await resetRegistrationType({})

        if (!registrationNumber) {
          reset(defineRegistrationFormValues(defaultRegistrationType))
          return
        }

        const registration = await RegistrationService.get(registrationNumber)

        const { account: accountData, salesOrder: salesOrderData } = await RegistrationUtilsService.requestRegistrationData(registration)
        reset(defineRegistrationFormValues(defaultRegistrationType, registration, accountData, salesOrderData))
        characteristicsReset(registration.registration_characteristics ?? [])
      } catch (e) {
        ErrorHandler.handleError(e)
      } finally {
        setState((prevState) => ({ ...prevState, loading: false }))
      }
    }

    void init()
  }, [
    ErrorHandler,
    ItemsService,
    RegistrationService,
    RegistrationUtilsService,
    characteristicsReset,
    itemNumber,
    registrationNumber,
    reset,
    resetRegistrationType,
  ])

  const tabs: IXtTab[] = useMemo(
    () => [
      {
        name: RegistrationDialogTab.Contact,
        markAsInvalid: markContactAsInvalid,
        template: (
          <FormContactSearch
            name={RegistrationDialogFormField.Contact}
            accountNumber={accountNumber?.id}
            control={control}
            label={RegistrationLabel.ContactNumber}
            disabled={false}
            isMobile={isMobile}
            filterByAccountOnly
          />
        ),
      },
      {
        name: RegistrationDialogTab.Characteristics,
        template: (
          <XtCharacteristics
            usedOnFilter={UsedOnValue.LotSerialRegistration}
            disabled={false}
            onCreate={characteristicsState.createCharacteristic}
            onUpdate={characteristicsState.updateCharacteristic}
            onDelete={characteristicsState.deleteCharacteristic}
            characteristics={characteristicsState.characteristics}
          />
        ),
      },
      {
        name: RegistrationDialogTab.Notes,
        template: <FormTextAreaField name={RegistrationDialogFormField.Notes} control={control} label={RegistrationLabel.Notes} />,
      },
    ],
    [
      FormContactSearch,
      XtCharacteristics,
      accountNumber?.id,
      characteristicsState.characteristics,
      characteristicsState.createCharacteristic,
      characteristicsState.deleteCharacteristic,
      characteristicsState.updateCharacteristic,
      control,
      isMobile,
      markContactAsInvalid,
    ]
  )

  return (
    <div className={styles.dialogContainer}>
      {loading && <LoadingSpinner />}
      <div className={styles.formContent} hidden={loading}>
        <div className={styles.header}>
          <XtItemNumber value={defineItemNumberOption(item)} />
          <div className={cls(styles.headerButtons)}>
            <XtButton label="Cancel" onClick={onCancel} />
            <XtButton
              label="Save"
              onClick={handleSubmit(onSaveForm)}
              loading={isSubmitting}
              disabled={!isDirty || isSubmitting || loading}
            />
          </div>
        </div>
        <div className={styles.formSection}>
          <div className={styles.fields}>
            <FormField
              disabled
              control={control}
              name={RegistrationDialogFormField.RegistrationNumber}
              label={RegistrationLabel.RegistrationNumber}
            />
            <FormSelectField
              options={options}
              name={RegistrationDialogFormField.RegistrationType}
              control={control}
              label={RegistrationLabel.RegistrationType}
            />
            <XtInput disabled value={lotSerialNumber} label={RegistrationLabel.LotSerial} />
            <DecimalFormField
              name={RegistrationDialogFormField.Qty}
              control={control}
              label={RegistrationLabel.Qty}
              allowNegative={false}
              disabled={isEditMode}
            />
            <FormXtAutocomplete
              control={control}
              name={RegistrationDialogFormField.AccountNumber}
              label={RegistrationLabel.AccountNumber}
              onChange={onAccountNumberChange}
              renderOption={renderColumnOption}
              getInputLabel={getAutocompleteInputLabelAsId}
              loadOptions={loadAccounts}
            />
            <FormField disabled control={control} name={RegistrationDialogFormField.AccountName} label={RegistrationLabel.AccountName} />
            <div className={styles.salesOrderWithInfo}>
              <FormXtAutocomplete
                disabled={!accountNumber}
                control={control}
                name={RegistrationDialogFormField.SalesOrder}
                label={RegistrationLabel.SalesOrder}
                renderOption={renderColumnOption}
                getInputLabel={getAutocompleteInputLabelAsId}
                loadOptions={RegistrationUtilsService.loadSalesOrderOptions}
                onChange={onSalesOrderChange}
                filters={accountNumber?.id}
              />
              <XtInfoValues values={infoValues} />
            </div>
            <FormXtAutocomplete
              name={RegistrationDialogFormField.Shipment}
              control={control}
              label={RegistrationLabel.Shipment}
              onChange={onShipmentChange}
              loadOptions={ShipmentsUtilsService.loadShipmentOptions}
              filters={shipmentFilters}
            />
          </div>
          <div className={styles.dateFields}>
            <FormDatePicker name={RegistrationDialogFormField.RegisterDate} label={RegistrationLabel.RegisterDate} control={control} />
            <FormDatePicker name={RegistrationDialogFormField.SaleDate} label={RegistrationLabel.SaleDate} control={control} />
            <FormDatePicker name={RegistrationDialogFormField.ExpireDate} label={RegistrationLabel.ExpireDate} control={control} />
          </div>
        </div>
        <XtTabs tabs={tabs} />
      </div>
    </div>
  )
}
