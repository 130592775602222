import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { UsedOnValue } from 'documents/documents.types'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { cls } from 'common/utils/utils'
import { useCoreModule } from 'core/core-module-hook'
import { IAppliedCharacteristic, IAppliedCharacteristicNew } from '../characteristics.types'
import { useCharacteristicsModule } from '../characteristics-module-hook'
import * as styles from './characteristics-dialog.module.scss'

interface ICharacteristicsDialog {
  characteristics: IAppliedCharacteristic[] | (() => Promise<IAppliedCharacteristic[]>)
  disabled?: boolean
  onClose: VoidFunction
  onConfirm(characteristics: IAppliedCharacteristicNew[], id: string | null): Promise<void>
  id: string | null
  usedOnFilter: UsedOnValue
}

export const CharacteristicsDialog: FC<ICharacteristicsDialog> = ({
  disabled = false,
  characteristics: payload,
  onClose,
  onConfirm,
  id,
  usedOnFilter,
}) => {
  const { ErrorHandler } = useCoreModule()
  const { XtCharacteristics, useCharacteristics } = useCharacteristicsModule()
  const [loading, setLoading] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const {
    isDirty,
    updateCharacteristic,
    reset,
    characteristics,
    deleteCharacteristic,
    createCharacteristic,
    getCharacteristicsPayload,
  } = useCharacteristics()

  const onCancel: VoidFunction = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave the dialog? Updates will not be applied.')) {
      return
    }
    onClose()
  }

  const onSave = async (): Promise<void> => {
    try {
      setSubmitting(true)
      await onConfirm(getCharacteristicsPayload(), id)
      onClose()
    } catch (e) {
      ErrorHandler.handleError(e)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    async function requestData(requestFn: () => Promise<IAppliedCharacteristic[]>): Promise<void> {
      try {
        setLoading(true)
        const data = await requestFn()
        reset(data)
      } catch (e) {
        ErrorHandler.handleError(e)
      } finally {
        setLoading(false)
      }
    }

    if (typeof payload === 'function') {
      void requestData(payload)
    } else {
      reset(payload)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const title: string = id ? `Characteristics ${id}` : 'New Characteristics'

  return (
    <div className={styles.characteristicsDialog}>
      <div className={cls('xt-section-border', styles.headerContent)}>
        <h1 className="xt-page-title">{title}</h1>
        <div className={styles.buttons}>
          <XtButton label="Cancel" onClick={onCancel} />
          <XtButton label="Save" onClick={onSave} disabled={!isDirty || disabled || submitting} />
        </div>
      </div>

      <XtCharacteristics
        loading={loading}
        usedOnFilter={usedOnFilter}
        disabled={disabled}
        onCreate={createCharacteristic}
        onUpdate={updateCharacteristic}
        onDelete={deleteCharacteristic}
        characteristics={characteristics}
      />
    </div>
  )
}
