import { UseFormReturn } from 'react-hook-form/dist/types'
import { IObjectWithId, XtMode } from 'common/common.types'
import { IItem, IItemSite } from 'products/items/items.types'
import { ItemDetailsForm } from 'products/items/item-details/item-details.types'

export enum SitesAction {
  Edit = 'edit',
  Delete = 'delete',
  View = 'view',
}

export type SiteTableItem = IItemSite & IObjectWithId

export interface ISitesTab {
  formMethods: UseFormReturn<ItemDetailsForm>
  isViewMode: boolean
  isMobile: boolean
  item: IItem
  checkIsAnySiteSerial(): Promise<void>
}

export interface ISiteDialogData {
  mode: XtMode
  site: string | null
}
