import React, { ChangeEventHandler, FC, useCallback, useRef, useState } from 'react'
import { Grow, MenuItem, MenuList, Paper, Popper, ClickAwayListener } from '@material-ui/core'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { cls } from 'common/utils/utils'
import * as styles from './attach-split-button.module.scss'

export interface IAttachSplitButton {
  disabled: boolean
  attachFIle: VoidFunction
  attachMultipleFiles: (newFiles: FileList | null) => void
  isMobile: boolean
}

export const AttachSplitButton: FC<IAttachSplitButton> = ({ disabled, attachFIle, attachMultipleFiles, isMobile }) => {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle: VoidFunction = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose: (event: React.MouseEvent<Document, MouseEvent>) => void = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleAttachFile: VoidFunction = () => {
    handleToggle()
    attachFIle()
  }

  const attachFiles: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (!event.target!) {
        return
      }
      const fileInput = event.target! as HTMLInputElement
      const newFiles = fileInput?.files ?? null
      attachMultipleFiles(newFiles)
      handleToggle()
    },
    [attachMultipleFiles]
  )

  return (
    <div className={styles.attachButtonContainer}>
      <XtResponsiveButton
        disabled={disabled}
        ref={anchorRef}
        label="Attach"
        onClick={handleToggle}
        labelClass={styles.documentsAddButtonLabel}
        className={cls(!isMobile && styles.attachButtonWidth)}
        icon={SvgIconIds.ATTACH}
      />
      <Popper
        className={styles.attachSplit}
        open={open}
        placement={isMobile ? 'bottom-end' : 'bottom'}
        anchorEl={anchorRef.current}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className={styles.menuButtonList}>
                  <MenuItem onClick={handleAttachFile}>Attach One File</MenuItem>
                  <form>
                    <label htmlFor="raised-button-file">
                      <MenuItem>Attach Multiple Files</MenuItem>
                      {open && <input onChange={attachFiles} accept="image/*" hidden id="raised-button-file" multiple type="file" />}
                    </label>
                  </form>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
