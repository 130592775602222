import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { IHttpClient } from '../http-client'
import { IFilterResponse } from './pagefilters.types'
import { IPageFiltersData, IPageFiltersParams } from './pagefilters-utils.types'

export interface IPageFilterService {
  getAll(pagename: string): Promise<IPageFiltersData>
  create(data: IPageFiltersParams): Promise<void>
  update(data: IPageFiltersParams): Promise<void>
  delete(data: IPageFiltersParams): Promise<void>
}

export class PageFilterService implements IPageFilterService {
  constructor(private readonly httpClient: IHttpClient) {
    bindAllInstanceMethods(this)
  }

  public async getAll(pagename: string): Promise<IPageFiltersData> {
    const {
      data: { data },
    } = await this.httpClient.get<IFilterResponse<IPageFiltersData>>(`/pagefilter/${pagename}`)

    return data
  }

  public async create(data: IPageFiltersParams): Promise<void> {
    const body = { data }
    return this.httpClient.post(`/pagefilter/create`, body)
  }

  public async update(data: IPageFiltersParams): Promise<void> {
    const body = { data }
    return this.httpClient.post(`/pagefilter/update`, body)
  }

  public async delete(data: IPageFiltersParams): Promise<void> {
    const body = { data }
    return this.httpClient.post(`/pagefilter/delete`, body)
  }
}
