import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { renderEmailCell } from 'components/controls/email/email-link/email-link.utils'
import { renderPhoneCell } from 'components/controls/phone/phone-link/phone-link.utils'
import { convertPhoneData } from 'common/utils/table.utils'
import { CustomersAction, ICustomersTableItem } from './customers-list.types'
import { PhoneRole } from '../../../contacts/contacts.types'
import { CustomerFiltersPanel, FilterFieldName } from '../services/customers-services.types'

export const defaultDeletionState = {
  customersId: '',
  confirmationOpen: false,
}

export const CustomersListActionsEditMode: IXtTableCellAction[] = [
  {
    name: CustomersAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: CustomersAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: CustomersAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
  {
    name: CustomersAction.Print,
    label: 'Print',
    icon: SvgIconIds.PRINT,
  },
]

export const CustomersListActionsViewMode: IXtTableCellAction[] = [
  {
    name: CustomersAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: CustomersAction.Print,
    label: 'Print',
    icon: SvgIconIds.PRINT,
  },
]

export const customerColumns: IXtTableColumn<ICustomersTableItem>[] = [
  {
    id: 'customer_number',
    field: 'customer_number',
    headerName: 'Customer #',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'active',
    field: 'active',
    headerName: 'Active',
    flex: '1 0 100px',
    width: 100,
  },
  {
    id: 'customer_name',
    field: 'customer_name',
    headerName: 'Name',
    flex: '1 0 200px',
    width: 200,
  },

  {
    id: 'customer_type',
    field: 'customer_type',
    headerName: 'Type',
    flex: '1 0 200px',
    width: 200,
  },

  {
    id: 'credit_status',
    field: 'credit_status',
    headerName: 'Hold Type',
    flex: '1 0 180px',
    width: 180,
  },

  {
    id: 'correspond_contact_first',
    field: 'correspond_contact_first',
    headerName: 'Corr. First Name',
    flex: '1 0 220px',
    width: 220,
    unsortable: true,
  },
  {
    id: 'correspond_contact_last',
    field: 'correspond_contact_last',
    headerName: 'Corr. Last Name',
    flex: '1 0 220px',
    width: 220,
    unsortable: true,
  },
  {
    id: 'correspond_contact_job_title',
    field: 'correspond_contact_job_title',
    headerName: 'Corr. Title',
    flex: '1 0 200px',
    width: 200,
    unsortable: true,
  },
  {
    id: 'correspond_contact_voice',
    headerName: 'Corr. Phone',
    flex: '1 0 300px',
    width: 300,
    unsortable: true,
    renderCell: (item) => renderPhoneCell(convertPhoneData(item.correspond_contact?.phones, PhoneRole.Office)),
  },
  {
    id: 'correspond_contact_email',
    headerName: 'Corr. Email',
    flex: '1 0 200px',
    width: 200,
    unsortable: true,
    renderCell: (item) => renderEmailCell(item?.correspond_contact_email),
  },
  {
    id: 'correspond_contact_address1',
    field: 'correspond_contact_address1',
    headerName: 'Corr. Address 1',
    flex: '1 0 200px',
    width: 200,
    unsortable: true,
  },
  {
    id: 'correspond_contact_city',
    field: 'correspond_contact_city',
    headerName: 'Corr. City',
    flex: '1 0 130px',
    width: 130,
    unsortable: true,
  },
  {
    id: 'correspond_contact_state',
    field: 'correspond_contact_state',
    headerName: 'Corr. State',
    flex: '1 0 130px',
    width: 130,
    unsortable: true,
  },
  {
    id: 'correspond_contact_country',
    field: 'correspond_contact_country',
    headerName: 'Corr. Country',
    flex: '1 0 150px',
    width: 150,
    unsortable: true,
  },
  {
    id: 'correspond_contact_postalcode',
    field: 'correspond_contact_postalcode',
    headerName: 'Corr. Postal Code',
    flex: '1 0 180px',
    width: 180,
    unsortable: true,
  },
]

export const defaultFilterValues: CustomerFiltersPanel = {
  [FilterFieldName.ShowInactive]: false,
  [FilterFieldName.CustomerNumberPattern]: '',
  [FilterFieldName.CustomerNamePattern]: '',
  [FilterFieldName.CustomerType]: null,
  [FilterFieldName.ContactNamePattern]: '',
  [FilterFieldName.CityPattern]: '',
  [FilterFieldName.StatePattern]: '',
  [FilterFieldName.SalesRep]: '',
}

export const customersPageTitle = 'xTuple - Customers'
