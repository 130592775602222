import * as React from 'react'
import { FC, useCallback } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { GridRowParams } from '@mui/x-data-grid-pro'
import { nanoid } from 'nanoid'
import { xsMq } from 'common/constants'
import { cls } from 'common/utils/utils'
import { useNestedXtTable } from 'components/nested-xt-table/nested-xt-table'
import { NestedXtList } from 'components/nested-xt-list/nested-xt-list'
import { NoMobileContent } from 'layout/no-mobile/no-mobile'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { useInventoryHistoryModule } from 'inventory/inventory-history-section/inventory-history-module-hook'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import * as styles from './inventory-history-list.module.scss'
import {
  inventoryHistoryColumns,
  inventoryHistoryDetailColumns,
  inventoryHistoryPageTitle,
  minDetailPanelContentHeight,
} from './inventory-history-list.constants'
import { InventoryHistoryListForm } from './inventory-history-list-form/inventory-history-list-form'
import { IInventoryHistoryDetail } from './inventory-history-list.types'
import { defaultFilterValues } from './inventory-history-list-form/inventory-history-list-form.constants'

export const InventoryHistoryList: FC = () => {
  const isMobile = useMediaQuery(xsMq)
  const { CostingItemCostsUtilsService } = useInventoryHistoryModule()
  useDocumentTitle(inventoryHistoryPageTitle)
  const {
    state: { loading, data, sortOptions, filters },
    filter,
    sort,
    pagination,
  } = useNestedXtTable(defaultFilterValues, CostingItemCostsUtilsService.getInventoryHistory, undefined, PageFilterMapping.InvHistory)

  const loadRows = useCallback(async ({ row: { inventory_detail } }: GridRowParams) => {
    if (inventory_detail) {
      return {
        data: inventory_detail.map((item: IInventoryHistoryDetail) => ({
          ...item,
          id: nanoid(),
        })),
        total: inventory_detail?.length,
      }
    }
    return {
      data: [],
      total: 0,
    }
  }, [])

  const getIsHasChildren = useCallback(({ row }: GridRowParams) => !!row?.inventory_detail, [])

  return (
    <NoMobileContent>
      <div className={cls('xt-list-page-container', styles.listContent)}>
        <div className="xt-page-header">
          <h1 className="xt-page-title">Inventory History</h1>
        </div>
        <InventoryHistoryListForm disabled={loading || !data.length} onChange={filter} filters={filters} />
        <div className={styles.table}>
          <NestedXtList
            isMobile={isMobile}
            sortOptions={sortOptions}
            onColumnHeaderClick={sort}
            columns={inventoryHistoryColumns}
            detailPanelColumns={inventoryHistoryDetailColumns}
            data={data}
            loading={loading}
            pagination={pagination}
            loadRowsFn={loadRows}
            getIsHasChildren={getIsHasChildren}
            minDetailPanelContentHeight={minDetailPanelContentHeight}
          />
        </div>
      </div>
    </NoMobileContent>
  )
}
