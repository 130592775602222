import { RouteGuardConstructor } from '../routing.types'
import { ICanActivateWithRedirect } from './guarded-route.types'

export function canActivateRoute(guards: RouteGuardConstructor[] | undefined): boolean {
  if (!Array.isArray(guards)) {
    return true
  }
  return guards.map((GuardConstructor) => new GuardConstructor().validate()).every(Boolean)
}

export function canActivateRouteWithRedirect(guards: RouteGuardConstructor[] | undefined): ICanActivateWithRedirect {
  if (!Array.isArray(guards)) {
    return { canActivate: true, redirectTo: null }
  }

  for (let i = 0; i < guards.length; i++) {
    const GuardConstructor = guards[i]
    const guard = new GuardConstructor()
    if (!guard.validate()) {
      return { canActivate: false, redirectTo: guard.redirectTo }
    }
  }

  return { canActivate: true, redirectTo: null }
}
