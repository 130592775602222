import { BrowserRouter } from 'react-router-dom'
import { INavigationService } from 'core/services/navigation.service'
import { Injector } from 'core/injector/injector.service'
import { navigationServiceToken } from 'core/core.constants'

export class XtBrowserRouter extends BrowserRouter {
  private readonly navigationService: INavigationService = Injector.resolve<INavigationService>(navigationServiceToken)

  public readonly history = this.navigationService.history
}
