export const purchaseOrdersModule = 'PurchaseOrdersModule'
export const purchaseOrdersServiceToken = 'PurchaseOrdersService'
export const purchaseOrdersUtilsServiceToken = 'PurchaseOrdersUtilsService'

export const purchaseOrdersRoutePath = '/purchasing/purchase-orders'
export const salesOrdersRoutePath = '/orders/sales-orders'
export const getPODetailsPageTitle = (id: string = '') => `xTuple - ${id ? `Purchase Order: ${id}` : 'Create Purchase Order'}`

export enum PurchaseOrderStatusLabel {
  Unreleased = 'Unreleased',
  Open = 'Open',
  Closed = 'Closed',
}
