import { ILazyModule } from '../core/react-lazy-modules/react-lazy-modules.types'
import { setupModule } from './setup.constants'
import { SetupUsersModule, SetupUsersModuleDefinition } from './users/users.module'
import { SetupSalesModule, SetupSalesModuleDefinition } from './sales/sales.module'
import { SetupCrmModule, SetupCrmModuleDefinition } from './crm/crm.module'
import { SetupProductsModule, SetupProductsModuleDefinition } from './products/products.module'
import { SetupSystemModule, SetupSystemModuleDefinition } from './system/system.module'
import { LazyModulesService } from '../core/react-lazy-modules/react-lazy-modules.service'

export type SetupModuleDefinition = SetupUsersModuleDefinition &
  SetupSalesModuleDefinition &
  SetupCrmModuleDefinition &
  SetupProductsModuleDefinition &
  SetupSystemModuleDefinition

export const SetupModule: ILazyModule<SetupModuleDefinition> = {
  name: setupModule,
  resolver: async () => {
    const usersModule = LazyModulesService.resolveModule<SetupUsersModuleDefinition>(SetupUsersModule)
    const systemModule = LazyModulesService.resolveModule<SetupSystemModuleDefinition>(SetupSystemModule)
    const salesModule = LazyModulesService.resolveModule<SetupSalesModuleDefinition>(SetupSalesModule)
    const productsModule = LazyModulesService.resolveModule<SetupProductsModuleDefinition>(SetupProductsModule)
    const crmModule = LazyModulesService.resolveModule<SetupCrmModuleDefinition>(SetupCrmModule)

    return { ...usersModule, ...crmModule, ...productsModule, ...salesModule, ...systemModule }
  },
  dependencies: [SetupCrmModule, SetupSalesModule, SetupSystemModule, SetupUsersModule, SetupProductsModule],
}
