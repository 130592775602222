import { ItemOption, ItemSiteOption } from 'products/items/items.types'
import { InventoryAdjustmentCostType } from './inventory-adjustment-cost/inventory-adjustment-cost.types'

export interface IInventoryAdjustmentDetailsParams {
  className?: string
  onClose?(): void | null
}

export enum InventoryAdjustmentField {
  Item = 'item_number',
  Site = 'site',
  TransactionDate = 'date',
  DistributionQty = 'distribution_qty',
  Description = 'description1',
  Uom = 'UOM',
  Method = 'method',
  CostType = 'cost',
  Document = 'document',
  Adjust = 'adjust',
  TotalCost = 'total_cost',
  UnitCost = 'unit_cost',
  Notes = 'comments',
}

export enum InventoryAdjustmentLabel {
  Item = 'Item Number',
  Site = 'Sites',
  TransactionDate = 'Transaction Date',
  DistributionQty = 'Adjustment Qty.',
  Before = 'Before',
  After = 'After',
  Document = 'Document #',
  Adjust = 'Adjust value',
  TotalCost = 'Total Cost',
  UnitCost = 'Unit Cost ',
  Description = 'Description',
  Uom = 'UOM',
  Notes = 'Notes',
}

export enum InventoryAdjustmentMethod {
  Relative = 'Relative',
  Absolute = 'Absolute',
}

export interface IInventoryAdjustmentFormState {
  [InventoryAdjustmentField.TransactionDate]: Date | null
  [InventoryAdjustmentField.DistributionQty]: number | null
  [InventoryAdjustmentField.Item]: ItemOption | null
  [InventoryAdjustmentField.Site]: ItemSiteOption | null
  [InventoryAdjustmentField.Method]: InventoryAdjustmentMethod
  [InventoryAdjustmentField.CostType]: InventoryAdjustmentCostType
  [InventoryAdjustmentField.Document]: string
  [InventoryAdjustmentField.Adjust]: boolean
  [InventoryAdjustmentField.TotalCost]: number
  [InventoryAdjustmentField.UnitCost]: number
  [InventoryAdjustmentField.Notes]: string
  [InventoryAdjustmentField.Description]: string
  [InventoryAdjustmentField.Uom]: string
}

export interface IInventoryAdjustmentMeta {
  before: number
  after: number
}
