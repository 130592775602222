import { FilterType, IFilter } from 'components/pagefilter/filter/filter.types'
import { IDocumentsUtilsService } from 'documents/documents-utils.service'
import {
  getAutocompleteInputLabelAsId,
  parseAutocompleteOption,
  renderAutocompleteOption,
  renderColumnOption,
} from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { ICoreUtilsService } from 'core/services/utils.service'
import { dateToServerFormat } from 'common/utils/date.utils'
import { IDocumentListFilters } from 'documents/documents.types'
import { transactionTypeOption } from '../../inventory-history-by-location/inventory-history-by-location-list-form/inventory-history-by-location-list-form.constansts'
import {
  InventoryHistoryFilterField,
  InventoryHistoryFilterLabel,
  InventoryHistoryFilters,
  InventoryHistoryFormState,
} from '../inventory-history-list.types'
import { abcClassOptions } from '../../../../quantity-on-hand/quantity-on-hand-list/quantity-on-hand-list-filter/quantity-on-hand-list-filter.constants'

export const resolveFilters = async (
  { loadSites, loadClassCodesNumberAndDescription, loadItemOptions, loadItemGroups, loadPlannerCodes, loadOrders }: IDocumentsUtilsService,
  { loadWorkOrderOptions }: ICoreUtilsService
): Promise<Array<IFilter | IFilter<IXtAutocompleteOption, IDocumentListFilters>>> => {
  return [
    {
      type: FilterType.Autocomplete,
      label: InventoryHistoryFilterLabel.ClassCode,
      fieldName: InventoryHistoryFilterField.ClassCode,
      autocompleteProps: {
        loadOptions: loadClassCodesNumberAndDescription,
        renderOption: renderColumnOption,
        getInputLabel: getAutocompleteInputLabelAsId,
      },
    },
    {
      type: FilterType.Text,
      label: InventoryHistoryFilterLabel.ClassCodePattern,
      fieldName: InventoryHistoryFilterField.ClassCodePattern,
    },
    {
      type: FilterType.Autocomplete,
      label: InventoryHistoryFilterLabel.ItemNumber,
      fieldName: InventoryHistoryFilterField.ItemNumber,
      autocompleteProps: {
        loadOptions: loadItemOptions,
        getInputLabel: getAutocompleteInputLabelAsId,
        renderOption: renderColumnOption,
        filters: { showInactive: true },
      },
    },
    {
      type: FilterType.Autocomplete,
      label: InventoryHistoryFilterLabel.ItemGroup,
      fieldName: InventoryHistoryFilterField.ItemGroup,
      autocompleteProps: {
        loadOptions: loadItemGroups,
        getInputLabel: getAutocompleteInputLabelAsId,
        renderOption: renderColumnOption,
      },
    },
    {
      type: FilterType.Text,
      label: InventoryHistoryFilterLabel.ItemGroupPattern,
      fieldName: InventoryHistoryFilterField.ItemGroupPattern,
    },
    {
      type: FilterType.Text,
      label: InventoryHistoryFilterLabel.OrderNumberPattern,
      fieldName: InventoryHistoryFilterField.OrderNumberPattern,
    },
    {
      type: FilterType.Autocomplete,
      label: InventoryHistoryFilterLabel.PlannerCode,
      fieldName: InventoryHistoryFilterField.PlannerCode,
      autocompleteProps: {
        loadOptions: loadPlannerCodes,
        getInputLabel: getAutocompleteInputLabelAsId,
        renderOption: renderColumnOption,
      },
    },
    {
      type: FilterType.Text,
      label: InventoryHistoryFilterLabel.PlannerCodePattern,
      fieldName: InventoryHistoryFilterField.PlannerCodePattern,
    },
    {
      type: FilterType.Autocomplete,
      label: InventoryHistoryFilterLabel.SalesOrder,
      fieldName: InventoryHistoryFilterField.SalesOrder,
      autocompleteProps: {
        loadOptions: loadOrders,
        getInputLabel: getAutocompleteInputLabelAsId,
        renderOption: renderAutocompleteOption,
        filters: { showInactive: true },
      },
    },
    {
      type: FilterType.Dropdown,
      label: InventoryHistoryFilterLabel.TransactionType,
      fieldName: InventoryHistoryFilterField.TransactionType,
      options: transactionTypeOption,
    },
    {
      type: FilterType.Autocomplete,
      label: InventoryHistoryFilterLabel.TransferOrder,
      fieldName: InventoryHistoryFilterField.TransferOrder,
      autocompleteProps: {
        loadOptions: loadOrders,
        getInputLabel: getAutocompleteInputLabelAsId,
        renderOption: renderAutocompleteOption,
        filters: { to: true, showInactive: true },
      },
    },
    {
      type: FilterType.Autocomplete,
      label: InventoryHistoryFilterLabel.WorkOrder,
      fieldName: InventoryHistoryFilterField.WorkOrder,
      autocompleteProps: {
        loadOptions: loadWorkOrderOptions,
        getInputLabel: getAutocompleteInputLabelAsId,
        renderOption: renderColumnOption,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: InventoryHistoryFilterLabel.Sites,
      fieldName: InventoryHistoryFilterField.Sites,
      autocompleteProps: {
        loadOptions: loadSites,
      },
    },
    {
      type: FilterType.Dropdown,
      label: InventoryHistoryFilterLabel.ABCClass,
      fieldName: InventoryHistoryFilterField.ABCClass,
      options: abcClassOptions,
    },
  ]
}

export const convertFormData = (filters: InventoryHistoryFormState): InventoryHistoryFilters => ({
  ...filters,
  [InventoryHistoryFilterField.ClassCode]: parseAutocompleteOption(filters[InventoryHistoryFilterField.ClassCode]),
  [InventoryHistoryFilterField.ItemNumber]: parseAutocompleteOption(filters[InventoryHistoryFilterField.ItemNumber]),
  [InventoryHistoryFilterField.ItemGroup]: parseAutocompleteOption(filters[InventoryHistoryFilterField.ItemGroup]),
  [InventoryHistoryFilterField.PlannerCode]: parseAutocompleteOption(filters[InventoryHistoryFilterField.PlannerCode]),
  [InventoryHistoryFilterField.SalesOrder]: parseAutocompleteOption(filters[InventoryHistoryFilterField.SalesOrder]),
  [InventoryHistoryFilterField.TransactionType]: parseAutocompleteOption(filters[InventoryHistoryFilterField.TransactionType]),
  [InventoryHistoryFilterField.TransferOrder]: parseAutocompleteOption(filters[InventoryHistoryFilterField.TransferOrder]),
  [InventoryHistoryFilterField.WorkOrder]: parseAutocompleteOption(filters[InventoryHistoryFilterField.WorkOrder]),
  [InventoryHistoryFilterField.Sites]: parseAutocompleteOption(filters[InventoryHistoryFilterField.Sites]),
  [InventoryHistoryFilterField.ABCClass]: parseAutocompleteOption(filters[InventoryHistoryFilterField.ABCClass]),
  [InventoryHistoryFilterField.StartDate]: dateToServerFormat(filters[InventoryHistoryFilterField.StartDate]),
  [InventoryHistoryFilterField.EndDate]: dateToServerFormat(filters[InventoryHistoryFilterField.EndDate]),
})
