import type { ICommentsService } from 'comments/comments.service'
import type { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import type { IErrorHandler } from 'core/services/error-handler.service'
import type { IHttpClient } from 'core/services/http-client'
import type { IDocumentsService } from 'documents/documents.types'
import type { ITasksUtilsService } from './tasks-utils.service'
import type { ITaskService } from './tasks.service'
import type { ITasksKanbanService } from './tasks-kanban/tasks-kanban.service'
import type { ITasksKanbanUtilsService } from './tasks-kanban/tasks-kanban-utils.service'
import { AuthModule } from 'auth/auth.module'
import { CharacteristicsModule } from 'characteristics/characteristics.module'
import { commentsServiceToken } from 'comments/comments.constants'
import { errorHandlerServiceToken } from 'core/core.constants'
import { CoreModule } from 'core/core.module'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken } from 'core/services/http-client'
import { CommentsModule } from 'comments/comments.module'
import { documentsServiceToken } from 'documents/documents.constants'
import { DocumentsModule } from 'documents/documents.module'
import { CalendarModule } from '../calendar/calendar.module'
import {
  tasksKanbanServiceToken,
  tasksKanbanUtilsServiceToken,
  tasksModule,
  tasksServiceToken,
  tasksUtilsServiceToken,
} from './tasks.constants'
import { TasksKanbanService } from './tasks-kanban/tasks-kanban.service'
import { TasksKanbanUtilsService } from './tasks-kanban/tasks-kanban-utils.service'

export type TasksModuleDefinition = typeof import('./tasks.exports')

export interface ITasksModuleServices {
  TasksUtilsService: ITasksUtilsService
  TasksService: ITaskService
  TasksKanbanService: ITasksKanbanService
  TasksKanbanUtilsService: ITasksKanbanUtilsService
}

export const TasksModule: ILazyModule<TasksModuleDefinition> = {
  name: tasksModule,
  resolver: () => import('./tasks.exports'),
  initializer: ({ TasksService, TasksUtilsService }) => {
    const httpClient = Injector.resolve<IHttpClient>(httpClientToken)

    Injector.register<ITaskService>(
      tasksServiceToken,
      (injector) => {
        const commentsService = injector.resolve<ICommentsService>(commentsServiceToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)

        return new TasksService(httpClient, commentsService, documentsService)
      },
      Scope.Singleton
    )

    Injector.register<ITasksUtilsService>(
      tasksUtilsServiceToken,
      (injector) => {
        const taskService = injector.resolve<ITaskService>(tasksServiceToken)
        const errorHandler = Injector.resolve<IErrorHandler>(errorHandlerServiceToken)
        return new TasksUtilsService(taskService, errorHandler)
      },
      Scope.Singleton
    )

    Injector.register<ITasksKanbanService>(tasksKanbanServiceToken, () => new TasksKanbanService(httpClient), Scope.Singleton)

    Injector.register<ITasksKanbanUtilsService>(
      tasksKanbanUtilsServiceToken,
      (injector) => {
        const service = injector.resolve<ITasksKanbanService>(tasksKanbanServiceToken)
        return new TasksKanbanUtilsService(service)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, CalendarModule, AuthModule, CharacteristicsModule, DocumentsModule, CommentsModule],
}
