import React, { FC, useCallback, useEffect } from 'react'
import { FormField, FormCheckboxLabel, FormRadioGroup, FormXtAutocomplete } from 'common/utils/form/form.components'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { useDictionaryModule } from 'dictionary/dictionary-module-hook'
import { CountryOption } from 'dictionary/dictionary.types'
import { cls } from 'common/utils/utils'
import { useCoreModule } from 'core/core-module-hook'
import { useXtForm } from 'common/hooks/form/form'
import { XtMode } from 'common/common.types'
import { ICreateAccountDialog, ICreateAccountValues } from './create-account-dialog.types'
import { CreateAccountFormSchema } from './create-account-dialog.validation'
import {
  defaultCreateAccountFormState,
  CreateAccountFormField,
  CreateAccountFormLabel,
  radioGroupOptions,
} from './create-account-dialog.constants'
import { defineAccountData } from './create-account-dialog.utils'
import * as styles from './create-account-dialog.module.scss'

export const CreateAccountDialog: FC<ICreateAccountDialog> = ({ onClose, onSubmit, data, mode }) => {
  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
    reset,
    watch,
    setValue,
  } = useXtForm<ICreateAccountValues>({
    defaultValues: defaultCreateAccountFormState,
    mode: 'onBlur',
    validationSchema: CreateAccountFormSchema,
  })
  const { ErrorHandler } = useCoreModule()
  const { DictionaryService, DictionaryUtilsService } = useDictionaryModule()

  const [countrydata] = watch([CreateAccountFormField.Country])
  const country = countrydata as CountryOption

  const disabledState = !country

  const init: () => Promise<void> = async () => {
    try {
      const countryStates = await DictionaryUtilsService.getCountryStates(data?.country, data?.state)
      reset(defineAccountData(data, countryStates))
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void init(), [data])

  const title = `Account: ${data?.account_number}`

  const onCancel: VoidFunction = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave the dialog? Updates will not be applied.')) {
      return
    }
    onClose()
  }

  const onSaveForm: (formData: ICreateAccountValues) => Promise<void> = async (formData) => {
    try {
      onSubmit(formData)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const handleCountryChange = useCallback(
    async (option: CountryOption | null) => {
      if (!option?.id) {
        setValue(CreateAccountFormField.Country, null, {
          shouldValidate: true,
          shouldDirty: true,
        })
        setValue(CreateAccountFormField.State, '', { shouldValidate: true, shouldDirty: true })
        return
      }
      const statesData = await DictionaryService.getStates(option.id, { limit: 1, page: 1 })
      const hasStates = Boolean(statesData?.data?.length)
      setValue(CreateAccountFormField.Country, option ? { ...option, hasStates } : null, {
        shouldValidate: true,
        shouldDirty: true,
      })

      if (hasStates) {
        setValue(CreateAccountFormField.State, null, { shouldValidate: true, shouldDirty: true })
      }
      if (country?.hasStates && !hasStates) {
        setValue(CreateAccountFormField.State, '', { shouldValidate: true, shouldDirty: true })
      }
    },
    [DictionaryService, country?.hasStates, setValue]
  )

  return (
    <div className={styles.accountDialogContent}>
      <div className={cls(styles.accountDialogFormHeader, 'xt-section-border')}>
        <h3 className="xt-page-title" title={title}>
          {mode == XtMode.New && !isSubmitting ? 'New Account' : title}
        </h3>
        <div className={styles.containerHeaderButtons}>
          <XtButton label="Cancel" onClick={onCancel} />
          <XtButton label="Save" onClick={handleSubmit(onSaveForm)} loading={isSubmitting} disabled={!isDirty} />
        </div>
      </div>
      <form className={styles.accountForm}>
        <div className={styles.formGroup}>
          <FormField
            control={control}
            name={CreateAccountFormField.AccountNumber}
            label={CreateAccountFormLabel.AccountNumber}
            className={styles.formField}
            disabled={mode == XtMode.Edit}
          />
          <FormField
            required
            control={control}
            name={CreateAccountFormField.AccountName}
            label={CreateAccountFormLabel.AccountName}
            className={styles.formField}
          />
        </div>
        <p className={styles.helperText}>Is this account a new prospect or an existing customer?</p>
        <FormRadioGroup
          name={CreateAccountFormField.AccountType}
          control={control}
          options={radioGroupOptions}
          className={styles.radioGroup}
        />
        <div className={styles.formGroup}>
          <FormField
            control={control}
            name={CreateAccountFormField.Email}
            label={CreateAccountFormLabel.Email}
            className={styles.formField}
          />
          <FormField
            control={control}
            name={CreateAccountFormField.Phone}
            label={CreateAccountFormLabel.Phone}
            className={styles.formField}
          />
          <FormCheckboxLabel
            control={control}
            name={CreateAccountFormField.AllowSMS}
            label={CreateAccountFormLabel.AllowSMS}
            className={cls(styles.formField, styles.allowText)}
          />
        </div>

        <div className={styles.formGroup}>
          <FormField
            control={control}
            name={CreateAccountFormField.FirstName}
            label={CreateAccountFormLabel.FirstName}
            className={styles.formField}
          />
          <FormField
            control={control}
            name={CreateAccountFormField.MiddleName}
            label={CreateAccountFormLabel.MiddleName}
            className={styles.formField}
          />
          <FormField
            control={control}
            name={CreateAccountFormField.LastName}
            label={CreateAccountFormLabel.LastName}
            className={styles.formField}
          />
        </div>
        <div className={styles.formGroup}>
          <FormField
            control={control}
            name={CreateAccountFormField.Address1}
            label={CreateAccountFormLabel.Address1}
            className={styles.formField}
          />
          <FormField
            control={control}
            name={CreateAccountFormField.Address2}
            label={CreateAccountFormLabel.Address2}
            className={styles.formField}
          />
        </div>
        <div className={styles.formGroup}>
          <FormXtAutocomplete
            label={CreateAccountFormLabel.Country}
            control={control}
            onChange={handleCountryChange}
            name={CreateAccountFormField.Country}
            loadOptions={DictionaryUtilsService.loadCountries}
            className={styles.formField}
          />
          {!country?.hasStates ? (
            <FormField
              control={control}
              className={styles.formField}
              disabled={disabledState}
              name={CreateAccountFormField.State}
              label={CreateAccountFormLabel.State}
            />
          ) : (
            <FormXtAutocomplete
              label={CreateAccountFormLabel.State}
              control={control}
              name={CreateAccountFormField.State}
              loadOptions={DictionaryUtilsService.loadStates}
              filters={country?.id ?? null}
              className={styles.formField}
            />
          )}
          <FormField
            control={control}
            name={CreateAccountFormField.City}
            label={CreateAccountFormLabel.City}
            className={styles.formField}
          />
          <FormField
            control={control}
            name={CreateAccountFormField.Postalcode}
            label={CreateAccountFormLabel.Postalcode}
            className={styles.formField}
          />
        </div>
      </form>
    </div>
  )
}
