/* eslint-disable max-classes-per-file */
import { isValid } from 'date-fns'

export class StringTypeUtils {
  public static parseDate(value: string | Date | null): string {
    if (!value || !isValid(value)) {
      return ''
    }
    return typeof value === 'string' ? value : value.toISOString()
  }
}

export class NumberTypeUtils {
  public static parseString(value: string | number): number {
    return typeof value === 'string' ? Number.parseInt(value, 10) : value
  }

  public static parseFloatString(value: string | number): number {
    return typeof value === 'string' ? Number.parseFloat(value) : value
  }

  public static parseFloatWithFixed(value: string | number, fixed: number): string {
    return parseFloat(String(value)).toFixed(fixed)
  }

  public static isNumber(value: unknown): boolean {
    if (typeof value !== 'string' && typeof value !== 'number') {
      return false
    }
    const number = Number(value)
    return !isNaN(number) // eslint-disable-line no-restricted-globals
  }
}

/**
 * comparison of two dates
 * @param date1
 * @param date2
 */
export class DateTypeUtils {
  public static compareDatesOnly(date1: Date, date2: Date): number {
    const date1WithoutTime = date1.setHours(0, 0, 0, 0)
    const date2WithoutTime = date2.setHours(0, 0, 0, 0)
    if (date1WithoutTime === date2WithoutTime) {
      return 0
    }
    if (date1WithoutTime < date2WithoutTime) {
      return -1
    }
    return 1
  }
}

export class BooleanUtils {
  public static parseString(value: string): boolean {
    if (value === 'true') {
      return true
    }
    if (value === 'false') {
      return false
    }
    return Boolean(value)
  }
}
