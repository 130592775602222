import {
  InventoryHistoryByLotSerialField,
  InventoryHistoryByLotSerialState,
  MultiLevelTraceOptionsField,
  MultiLevelTraceOptionsLabel,
} from '../../inventory-history-by-lot-serial-list.types'

export const defaultHistoryFiltersValues: InventoryHistoryByLotSerialState = {
  [InventoryHistoryByLotSerialField.StartDate]: null,
  [InventoryHistoryByLotSerialField.EndDate]: null,
  [InventoryHistoryByLotSerialField.Site]: null,
  [InventoryHistoryByLotSerialField.TransactionType]: null,
  [InventoryHistoryByLotSerialField.MultiLevelTrace]: null,
}

export const multiLevelTraceOptions = [
  {
    id: MultiLevelTraceOptionsField.Forward,
    label: MultiLevelTraceOptionsLabel.Forward,
  },
  {
    id: MultiLevelTraceOptionsField.Backward,
    label: MultiLevelTraceOptionsLabel.Backward,
  },
  {
    id: MultiLevelTraceOptionsField.None,
    label: MultiLevelTraceOptionsLabel.None,
  },
]

export const maxDate = new Date()
