import { Injector } from 'core/injector/injector.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { IAccountsService, IAccountsServices, IAccountsUtilsService } from './accounts-services.types'
import { accountsServiceToken, accountsUtilsServiceToken } from '../accounts.constants'

export function resolveAccountsServicesModule(): IAccountsServices {
  const AccountsService = Injector.resolve<IAccountsService>(accountsServiceToken)
  const AccountsUtilsService = Injector.resolve<IAccountsUtilsService>(accountsUtilsServiceToken)

  return { AccountsService, AccountsUtilsService }
}

export const useAccountsServicesModule = moduleHookResolver<IAccountsServices>(resolveAccountsServicesModule)
