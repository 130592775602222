import { IItem, IItemSite } from 'products/items/items.types'
import { ISite } from 'core/services/sites.service'
import { ISitesDetailsDialogMainForm, SitesDetailsDialogMainFormField, SitesDetailsSiteOption } from './sites-details-dialog-main.types'

export function convertItemSiteToSiteOption(site: IItemSite | null): SitesDetailsSiteOption | null {
  if (!site) {
    return null
  }
  return {
    id: site.site,
    label: site.site,
    transit_site: site.is_transit_site,
    description: site.site_description,
  }
}

export function defineSitesMap(item: IItem): Map<string, string> {
  return new Map(
    (item.sites ?? []).map<readonly [string, string]>(({ site }) => [site, site])
  )
}

export function convertToOption(site: Pick<ISite, 'site' | 'transit_site' | 'description'>): SitesDetailsSiteOption {
  return {
    id: site.site,
    label: site.site,
    transit_site: site.transit_site,
    description: site.description,
  }
}

export function sitesDetailsDialogMainDefaults(site: IItemSite | null): ISitesDetailsDialogMainForm {
  return {
    [SitesDetailsDialogMainFormField.Site]: convertItemSiteToSiteOption(site),
    [SitesDetailsDialogMainFormField.Active]: site?.active ?? true,
    [SitesDetailsDialogMainFormField.SoldFromSite]: site?.sold_from_site ?? false,
    [SitesDetailsDialogMainFormField.Ranking]: site?.ranking ?? 1,
  }
}
