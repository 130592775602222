import type { ICustomersService } from 'companies/customers/services/customers-services.types'
import type { IDocumentsService } from 'documents/documents.types'
import type { IHttpClient } from 'core/services/http-client'
import type { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import type { ISalesOrdersService } from 'sales-shared/sales-orders.service'
import type { IShippingService } from './shipping.service'
import type { IShippingUtilsService } from './shipping-utils.service'
import { CompaniesModule } from 'companies/companies.module'
import { customersServiceToken } from 'companies/customers/customers.constants'
import { CoreModule } from 'core/core.module'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken } from 'core/services/http-client'
import { documentsServiceToken } from 'documents/documents.constants'
import { DocumentsModule } from 'documents/documents.module'
import { InventoryAdjustmentModule } from 'inventory/inventory-adjustments/inventory-adjustments.module'
import { SalesOrdersModule } from 'sales-orders/sales-orders.module'
import { salesOrdersServiceToken } from 'sales-shared/sales-shared.constants'
import { SalesSharedModule } from 'sales-shared/sales-shared.module'
import { SettingsModule } from 'setup/settings/settings.module'
import { ShipmentsModule } from 'shipments/shipments.module'
import { shippingModule, shippingServiceToken, shippingUtilsServiceToken } from './shipping.constants'

export type ShippingModuleDefinition = typeof import('./shipping.exports')

export interface IShippingModuleService {
  ShippingService: IShippingService
  ShippingUtilsService: IShippingUtilsService
}

export const ShippingModule: ILazyModule<ShippingModuleDefinition> = {
  name: shippingModule,
  resolver: () => import('./shipping.exports'),
  initializer: ({ ShippingService, ShippingUtilsService }) => {
    Injector.register<IShippingService>(
      shippingServiceToken,
      (injector) => new ShippingService(injector.resolve<IHttpClient>(httpClientToken)),
      Scope.Singleton
    )
    Injector.register<IShippingUtilsService>(
      shippingUtilsServiceToken,
      (injector) => {
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
        const shippingService = injector.resolve<IShippingService>(shippingServiceToken)
        const customersService = injector.resolve<ICustomersService>(customersServiceToken)
        const salesOrdersService = injector.resolve<ISalesOrdersService>(salesOrdersServiceToken)

        return new ShippingUtilsService(documentsService, shippingService, customersService, salesOrdersService)
      },
      Scope.Singleton
    )
  },
  dependencies: [
    CoreModule,
    DocumentsModule,
    CompaniesModule,
    SalesSharedModule,
    ShipmentsModule,
    SalesOrdersModule,
    DocumentsModule,
    SettingsModule,
    InventoryAdjustmentModule,
  ],
}
