import { CRMAccountRole, DocumentUpdateEventType, IDocumentUpdateEvent } from 'documents/documents.types'
import { ContactUpdateEventType, IContactUpdateEvent } from 'contacts/contacts.types'
import { ITaskUpdateEvent, TaskUpdateEventType } from 'tasks/tasks.types'
import { XtMode } from 'common/common.types'
import { crmAccountRoleLabel } from './active-roles-section.constants'
import { IActiveRolesPermissions, IRolesOptionsState, OptionRole } from './active-roles-section.types'
import { IRoles } from '../../accounts.types'

function defineRoleOption(defaultOption: OptionRole, canView: boolean, canEdit: boolean): OptionRole {
  return {
    ...defaultOption,
    canAdd: canEdit,
    canOpen: canView || canEdit,
    canDelete: canEdit,
    modeToOpen: canEdit ? XtMode.Edit : XtMode.View,
  }
}

function convertRoleOptions(roles: IRoles, permissions: IActiveRolesPermissions, disabled: boolean): OptionRole[] {
  const { canViewCustomer, canEditCustomer, canViewProspect, canEditProspect } = permissions

  return Object.entries(roles).map(([name, crm_role]) => {
    const defaultOption = {
      crm_role,
      name,
      label: crmAccountRoleLabel[name],
      canAdd: false,
      canOpen: false,
      canDelete: false,
      modeToOpen: XtMode.View,
    }
    if (name === CRMAccountRole.Prospect) {
      return defineRoleOption(defaultOption, canViewProspect, canEditProspect && !disabled)
    }
    if (name === CRMAccountRole.Customer) {
      return defineRoleOption(defaultOption, canViewCustomer, canEditCustomer && !disabled)
    }
    return defaultOption
  })
}

export function findRole(options: OptionRole[], name: CRMAccountRole): OptionRole | undefined {
  return options.find((item) => item.name === name)
}

export function convertOptions(
  roles: IRoles,
  permissions: IActiveRolesPermissions,
  disabled: boolean
): Omit<IRolesOptionsState, 'loading'> {
  const options = convertRoleOptions(roles, permissions, disabled)
  const selectedOptions = options.filter((item) => item.crm_role)
  const listOptions = roles.customer ? options.filter((item) => item.name !== CRMAccountRole.Prospect) : options

  return { selectedOptions, listOptions }
}

export function isDocumentEvent(event: IDocumentUpdateEvent | IContactUpdateEvent | ITaskUpdateEvent): event is IDocumentUpdateEvent {
  return Object.values(DocumentUpdateEventType).map(String).includes(event.type)
}

export function isContactEvent(event: IDocumentUpdateEvent | IContactUpdateEvent | ITaskUpdateEvent): event is IContactUpdateEvent {
  return Object.values(ContactUpdateEventType).map(String).includes(event.type)
}

export function isTaskEvent(event: IDocumentUpdateEvent | IContactUpdateEvent | ITaskUpdateEvent): event is ITaskUpdateEvent {
  return Object.values(TaskUpdateEventType).map(String).includes(event.type)
}
