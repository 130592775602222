import React, { FC } from 'react'
import { AccordionTheme, XtAccordion } from 'components/xt-accordion/xt-accordion'
import { DocumentType } from 'documents/documents.types'
import { XtTextArea } from 'components/controls/xt-text-area/xt-text-area'
import * as styles from './remarks.module.scss'
import { CommentItem } from '../comment/comment.types'
import { IXtCommentsPaginationParams, XtComments } from '../comments/comments'

export interface IXtRemarks extends IXtCommentsPaginationParams {
  disabled: boolean
  /**
   * The function returns true if a comment is saved successfully. Otherwise it returns false.
   * @param comments
   */
  onCommentsSave(comments: CommentItem): boolean | Promise<boolean>
  onCommentsUpdate(comment: CommentItem): void | Promise<void>
  comments: CommentItem[]
  source: DocumentType
  textAreaOnChange(value: string): void
  textAreaValue: string
  textAreaName: string
  username: string
  extendedTextAreaOnChange?(value: string): void
  extendedTextAreaValue?: string
  extendedTextAreaName?: string
}
/* TODO integration api */
export const XtRemarks: FC<IXtRemarks> = ({
  disabled,
  onCommentsSave,
  onCommentsUpdate,
  comments,
  textAreaValue,
  textAreaOnChange,
  textAreaName,
  username,
  extendedTextAreaOnChange,
  extendedTextAreaValue,
  extendedTextAreaName,
  canLoadMore,
  loadMore,
  source,
}) => (
  <div>
    <div className={styles.accordionTabs}>
      <XtAccordion defaultExpanded summary="Comments" theme={AccordionTheme.Light}>
        <XtComments
          source={source}
          canLoadMore={canLoadMore}
          loadMore={loadMore}
          creationEnabled
          username={username}
          onUpdate={onCommentsUpdate}
          onAdd={onCommentsSave}
          comments={comments}
          disabled={disabled}
        />
      </XtAccordion>
    </div>
    <div className={styles.accordionTabs}>
      <XtAccordion summary={textAreaName} theme={AccordionTheme.Light}>
        <XtTextArea placeholder={textAreaName} disabled={disabled} value={textAreaValue} onChange={textAreaOnChange} />
      </XtAccordion>
    </div>
    {extendedTextAreaName && (
      <div className={styles.accordionTabs}>
        <XtAccordion summary={extendedTextAreaName} theme={AccordionTheme.Light}>
          <XtTextArea
            placeholder={extendedTextAreaName}
            disabled={disabled}
            value={extendedTextAreaValue}
            onChange={extendedTextAreaOnChange!}
          />
        </XtAccordion>
      </div>
    )}
  </div>
)
