import * as AuthModuleExports from './auth.exports'
import { IAuthService } from './auth.types'
import { IPermissionsService } from './permissions.service'
import { IModule } from '../core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from '../core/injector/injector.service'
import { CoreModule } from '../core/core.module'
import { authServiceToken, permissionsServiceToken } from './auth.constants'
import { Scope } from '../core/injector/injector.types'
import { httpClientToken, IHttpClient } from '../core/services/http-client'
import { INavigationService } from '../core/services/navigation.service'
import { navigationServiceToken } from '../core/core.constants'
import { toastServiceToken } from '../toast/toast.constants'
import { IToastService } from '../toast/toast.service'

export type AuthModuleDefinition = typeof AuthModuleExports

export interface IAuthModuleServices {
  AuthService: IAuthService
  PermissionsService: IPermissionsService
}

export const AuthModule: IModule<AuthModuleDefinition> = {
  name: 'AuthModule',
  resolver: () => AuthModuleExports,
  initializer: ({ PermissionsService, AuthService }) => {
    Injector.register<IAuthService>(
      authServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        const toastService = injector.resolve<IToastService>(toastServiceToken)
        const navigationService = injector.resolve<INavigationService>(navigationServiceToken)

        return new AuthService(httpClient, toastService, navigationService)
      },
      Scope.Singleton
    )

    Injector.register<IPermissionsService>(
      permissionsServiceToken,
      (injector) => {
        const authService = injector.resolve<IAuthService>(authServiceToken)

        return new PermissionsService(authService)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule],
}
