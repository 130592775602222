import { IPaginationResponse } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IHttpClient } from 'core/services/http-client'
import { IKanbanTaskPayload, IKanbanTasksFilter } from './tasks-kanban.types'

export interface ITasksKanbanService {
  getTasks(filters?: IKanbanTasksFilter): Promise<IKanbanTaskPayload[]>
  getTaskByPriority(priority: string, filters?: IKanbanTasksFilter): Promise<IKanbanTaskPayload>
}

export class TasksKanbanService implements ITasksKanbanService {
  constructor(private readonly httpClient: IHttpClient) {}

  public async getTasks(filters?: IKanbanTasksFilter): Promise<IKanbanTaskPayload[]> {
    const params = prepareRequestParams(undefined, filters)

    const {
      data: { data },
    } = await this.httpClient.get<IPaginationResponse<IKanbanTaskPayload>>('/kanban/task', { params })

    return Array.isArray(data) ? data : []
  }

  public async getTaskByPriority(priority: string, filters: IKanbanTasksFilter = {}): Promise<IKanbanTaskPayload> {
    const params = prepareRequestParams(undefined, { priority, ...filters })

    const {
      data: { data },
    } = await this.httpClient.get<IPaginationResponse<IKanbanTaskPayload>>('/kanban/task', { params })

    if (!Array.isArray(data) || !data.length) {
      throw new Error(`Task Priority ${priority} is not found.`)
    }
    return data[0]
  }
}
