import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { DocumentsModule } from 'documents/documents.module'
import { Injector } from 'core/injector/injector.service'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { Scope } from 'core/injector/injector.types'
import { CoreModule } from 'core/core.module'
import { AuthModule } from 'auth/auth.module'
import { ItemsSharedModule } from 'products/items-shared/items-shared.module'
import { IClassCodesService } from 'products/items-shared/class-codes/class-codes.service'
import { classCodesServiceToken } from 'products/items-shared/items-shared.constants'
import { IUomService } from 'core/services/uom.service'
import { uomServiceToken } from 'core/core.constants'
import { SharedModule } from 'shared/shared-module'
import { setupProductCategoriesServiceToken, setupProductsModule, setupProductsUtilsServiceToken } from './products.constants'
import { IProductCategoriesService } from './product-categories-tab/product-categories.service'
import { IProductsUtilsService } from './products-utils.service'

export type SetupProductsModuleDefinition = typeof import('./products.exports')

export interface ISetupProductsModuleService {
  ProductsUtilsService: IProductsUtilsService
  ProductCategoriesService: IProductCategoriesService
}

function setupProductsModuleInitializer({ ProductsUtilsService, ProductCategoriesService }: SetupProductsModuleDefinition): void {
  const httpClient = Injector.resolve<IHttpClient>(httpClientToken)

  Injector.register<IProductCategoriesService>(
    setupProductCategoriesServiceToken,
    () => new ProductCategoriesService(httpClient),
    Scope.Singleton
  )

  Injector.register<IProductsUtilsService>(
    setupProductsUtilsServiceToken,
    (injector) => {
      const classCodesService = injector.resolve<IClassCodesService>(classCodesServiceToken)
      const productCategoriesService = injector.resolve<IProductCategoriesService>(setupProductCategoriesServiceToken)
      const uomService = injector.resolve<IUomService>(uomServiceToken)
      return new ProductsUtilsService(uomService, productCategoriesService, classCodesService)
    },
    Scope.Singleton
  )
}

export const SetupProductsModule: ILazyModule<SetupProductsModuleDefinition> = {
  name: setupProductsModule,
  resolver: () => import('./products.exports'),
  initializer: setupProductsModuleInitializer,
  dependencies: [CoreModule, AuthModule, DocumentsModule, ItemsSharedModule, SharedModule],
}
