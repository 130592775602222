import React, { FC, useState } from 'react'
import { useCoreModule } from 'core/core-module-hook'
import { useXtForm } from 'common/hooks/form/form'
import { FormXtAutocomplete } from 'common/utils/form/form.components'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { useShipmentsModule } from 'shipments/shipments-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { XtInfoValue } from 'components/xt-info-values/xt-info-values.types'
import { XtInfoValues } from 'components/xt-info-values/xt-info-values'
import { DocumentOrderOption, IDocumentListFilters } from 'documents/documents.types'
import { cls } from 'common/utils/utils'
import { OrderType } from 'core/core.types'
import * as styles from '../print-dialogs.module.scss'
import { PrintLabels } from '../print-labels/print-labels'
import { IPrintFormDialogParams, IPrintFormSharedState, PrintSharedFormField, PrintSharedFormLabel } from '../print-dialogs.types'
import { convertPrintFormToPrintPayload } from '../print-dialogs.utils'
import { printDialogsValidationSchema } from '../print-dialogs.validation'
import { defaultPrintFormState } from '../print-dialogs.constants'
import { resolveTransferOrderValues } from './print-by-transfer-order.utils'

const transferOrderFilters: IDocumentListFilters = { to: true, showInactive: true }
const transferOrdersRequestLimit = 10

export const TransferOrderPrintForm: FC<IPrintFormDialogParams> = ({ onClose }) => {
  const { ErrorHandler, TransferOrderService } = useCoreModule()
  const { DocumentsUtilsService } = useDocumentsModule()
  const { ShipmentsUtilsService } = useShipmentsModule()

  const formMethods = useXtForm<IPrintFormSharedState<DocumentOrderOption>>({
    defaultValues: defaultPrintFormState,
    mode: 'onBlur',
    validationSchema: printDialogsValidationSchema,
  })

  const [transferOrderValues, setTransferOrderValues] = useState<XtInfoValue[]>(() => resolveTransferOrderValues(null))

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    setValue,
  } = formMethods

  const printForm = async (formData: IPrintFormSharedState<DocumentOrderOption>): Promise<void> => {
    try {
      const payload = convertPrintFormToPrintPayload(formData, OrderType.TransferOrder, formData[PrintSharedFormField.Number]?.number)
      await ShipmentsUtilsService.printShipmentLabel(payload)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const onTransferOrderChange = async (option: DocumentOrderOption | null): Promise<void> => {
    try {
      setValue(PrintSharedFormField.Number, option, { shouldValidate: true, shouldDirty: true })
      if (option) {
        const transferOrder = await TransferOrderService.get(option.number)
        setTransferOrderValues(resolveTransferOrderValues(transferOrder))
      } else {
        setTransferOrderValues(resolveTransferOrderValues(null))
      }
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  return (
    <form className={styles.printDialog}>
      <div className={cls('xt-page-header', styles.printDialogHeader)}>
        <h3 className="xt-page-title">Print Labels by Transfer Order</h3>
        <div className={styles.printDialogButtons}>
          <XtButton label="Cancel" onClick={onClose} />
          <XtButton label="Print" onClick={handleSubmit(printForm)} disabled={!isDirty || isSubmitting} />
        </div>
      </div>
      <div>
        <FormXtAutocomplete
          loadOptions={DocumentsUtilsService.loadOrders}
          name={PrintSharedFormField.Number}
          control={control}
          label={PrintSharedFormLabel.OrderNumber}
          filters={transferOrderFilters}
          limit={transferOrdersRequestLimit}
          onChange={onTransferOrderChange}
        />
        <XtInfoValues values={transferOrderValues} classes={{ values: styles.orderValues }} />
      </div>
      <FormXtAutocomplete
        name={PrintSharedFormField.ReportName}
        control={control}
        label={PrintSharedFormLabel.ReportName}
        loadOptions={DocumentsUtilsService.loadReportNameOptions}
      />
      <PrintLabels formMethods={formMethods} />
    </form>
  )
}
