import React, { FC, useEffect } from 'react'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useShippingModule } from 'shipping/shipping-module-hook'
import { useXtForm } from 'common/hooks/form/form'
import { FormSelectField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { useXtSelect } from 'components/controls/xt-select/xt-select-hook'
import { getAutocompleteInputLabelAsId, renderColumnOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import * as styles from './maintain-shipping-filter.module.scss'
import {
  IMaintainShippingFilterForm,
  IMaintainShippingFilterFormFilled,
  MaintainShippingFilterField,
  MaintainShippingFilterLabel,
} from './maintain-shipping-filter.types'
import { defaultFilterValues } from './maintain-shipping-filter.constants'
import { convertFormData } from './maintain-shipping-filter.utils'

interface IMaintainShippingFilter {
  onChange(filterData: IMaintainShippingFilterFormFilled): void
}

export const MaintainShippingFilter: FC<IMaintainShippingFilter> = ({ onChange }) => {
  const { DocumentsUtilsService } = useDocumentsModule()
  const { ShippingUtilsService } = useShippingModule()

  const { options: sites } = useXtSelect(DocumentsUtilsService.loadSiteOptions)

  const { control, formChanges$ } = useXtForm<IMaintainShippingFilterForm>({ mode: 'onBlur', defaultValues: defaultFilterValues })

  useEffect(() => {
    const sub = formChanges$.subscribe(({ data }) => onChange(convertFormData(data)))
    return () => sub.unsubscribe()
  }, [formChanges$, onChange])

  return (
    <div className={styles.autocompleteSection}>
      <FormXtAutocomplete
        label={MaintainShippingFilterLabel.Customer}
        name={MaintainShippingFilterField.Customer}
        control={control}
        loadOptions={ShippingUtilsService.fetchCustomers}
        renderOption={renderColumnOption}
        getInputLabel={getAutocompleteInputLabelAsId}
      />
      <FormSelectField
        label={MaintainShippingFilterLabel.Site}
        name={MaintainShippingFilterField.Site}
        control={control}
        options={sites}
        clearable
      />
      <FormXtAutocomplete
        label={MaintainShippingFilterLabel.OrderNumber}
        name={MaintainShippingFilterField.OrderNumber}
        control={control}
        loadOptions={ShippingUtilsService.fetchSalesOrders}
        renderOption={renderColumnOption}
        getInputLabel={getAutocompleteInputLabelAsId}
      />
    </div>
  )
}
