import * as React from 'react'
import { FC, useEffect, useMemo, useState } from 'react'
import { debounceTime, map } from 'rxjs/operators'
import { FormDatePicker, FormSelectField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useXtForm } from 'common/hooks/form/form'
import { globalConstants } from 'common/constants'
import { XtInfoValues } from 'components/xt-info-values/xt-info-values'
import { XtInfoValue } from 'components/xt-info-values/xt-info-values.types'
import { booleanColumnConverter } from 'common/utils/table.utils'
import { useCoreModule } from 'core/core-module-hook'
import { parseAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { useInventoryHistoryModule } from 'inventory/inventory-history-section/inventory-history-module-hook'
import { DocumentLocationOption } from 'documents/documents.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { historyByLocationMainValidationScheme } from './inventory-history-by-location-list-form.validation'
import { allSitesOption, maxDate, transactionTypeOption } from './inventory-history-by-location-list-form.constansts'
import * as styles from './inventory-history-by-location-list-form.module.scss'
import {
  InventoryHistoryByLocationListParams,
  InventoryHistoryByLocationState,
  LocationFormField,
  LocationFormLabel,
  LocationInfoLabel,
} from './inventory-history-by-location-list-form.types'
import { convertFormDataToInventoryHistoryFilters, defineDefaultFormValues } from './inventory-history-by-location-list-form.utils'

export const InventoryHistoryByLocationListForm: FC<InventoryHistoryByLocationListParams> = ({ onChange }) => {
  const { DocumentsUtilsService } = useDocumentsModule()
  const { ErrorHandler } = useCoreModule()
  const { CostingItemCostsUtilsService } = useInventoryHistoryModule()
  const [printing, setPrinting] = useState<boolean>(false)

  const { control, formChanges$, reset, watch, trigger, getValues, setValue } = useXtForm<InventoryHistoryByLocationState>({
    mode: 'onBlur',
    validationSchema: historyByLocationMainValidationScheme,
    defaultValues: defineDefaultFormValues(null),
  })

  const startDate = watch(LocationFormField.StartDate)
  const location = watch(LocationFormField.Location)
  const site = watch(LocationFormField.Site)

  const disablePrintButton = !location || !site || printing

  const filters = useMemo(() => (site ? { site: parseAutocompleteOption(site) } : {}), [site])

  useEffect(() => {
    const init = async (): Promise<void> => {
      try {
        const defaultLocation = await DocumentsUtilsService.loadDocumentDefaultListOption()
        reset(defineDefaultFormValues(defaultLocation))
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }

    void init()
  }, [DocumentsUtilsService, ErrorHandler, reset])

  useEffect(() => {
    const sub = formChanges$
      .pipe(
        debounceTime(globalConstants.tableFilterDebounce),
        map(({ data }) => convertFormDataToInventoryHistoryFilters(data))
      )
      .subscribe(onChange)

    return () => sub.unsubscribe()
  }, [formChanges$, onChange])

  const locationInfoValues: XtInfoValue[] = useMemo(
    () => [
      { label: LocationInfoLabel.Netable, value: booleanColumnConverter(location?.netable) },
      { label: LocationInfoLabel.Restricted, value: booleanColumnConverter(location?.restricted) },
      { label: LocationInfoLabel.Usable, value: booleanColumnConverter(location?.usable) },
    ],
    [location]
  )

  const triggerForRequiredFields = (): void => {
    void trigger([LocationFormField.Site, LocationFormField.Location])
  }

  const printForm = async (): Promise<void> => {
    const printFilters = getValues()
    if (!printFilters) {
      return
    }
    setPrinting(true)
    try {
      await CostingItemCostsUtilsService.printInventoryHistoryByLocation(convertFormDataToInventoryHistoryFilters(printFilters))
    } catch (e) {
      ErrorHandler.handleError(e)
    } finally {
      setPrinting(false)
    }
  }

  const renderOption = (option: DocumentLocationOption) => {
    const { site: siteVal } = getValues()
    return <div key={option.id}>{siteVal && siteVal.id ? option.label : `${option.site}-${option.label}`}</div>
  }

  const getInputLabel = (option: DocumentLocationOption | null) => {
    if (!option) return ''
    const { site: siteVal } = getValues()
    return siteVal && siteVal.id ? option.label : `${option.site}-${option.label}`
  }

  const onSiteChange = (option: IXtAutocompleteOption) => {
    setValue(LocationFormField.Location, null)
    setValue(LocationFormField.Site, option)
  }

  return (
    <div className={styles.formSection}>
      <div className={styles.formContent}>
        <FormXtAutocomplete
          control={control}
          name={LocationFormField.Location}
          label={LocationFormLabel.Location}
          loadOptions={DocumentsUtilsService.loadDocumentListOptions}
          filters={filters}
          onBlur={triggerForRequiredFields}
          renderOption={renderOption}
          getInputLabel={getInputLabel}
        />
        <FormXtAutocomplete
          required
          onBlur={triggerForRequiredFields}
          loadOptions={DocumentsUtilsService.loadSites}
          name={LocationFormField.Site}
          control={control}
          label={LocationFormLabel.Site}
          extraOption={allSitesOption}
          onChange={onSiteChange}
        />
        <FormSelectField
          onBlur={triggerForRequiredFields}
          options={transactionTypeOption}
          name={LocationFormField.TransactionType}
          control={control}
          label={LocationFormLabel.TransactionType}
        />
        <XtInfoValues values={locationInfoValues} classes={{ values: styles.locationInfoValues }} />
        <FormDatePicker
          onBlur={triggerForRequiredFields}
          name={LocationFormField.StartDate}
          label={LocationFormLabel.StartDate}
          control={control}
          maxDate={maxDate}
        />
        <FormDatePicker
          onBlur={triggerForRequiredFields}
          name={LocationFormField.EndDate}
          label={LocationFormLabel.EndDate}
          control={control}
          minDate={startDate}
        />
      </div>
      <XtButton disabled={disablePrintButton} className={styles.printButton} label="Print" onClick={printForm} />
    </div>
  )
}
