import { IHttpClient } from './http-client'
import { OrderType } from '../core.types'

export type IssueOrderLineDetail = {
  distribution_qty: number
  lotserial: string | null
  location: string | null
  expiration_date: string | null
  warranty_date: string | null
  notes: string
}

export type IssueOrderLine = {
  line_number: number
  qty: number
  date: string
  detail?: IssueOrderLineDetail[]
}

export interface IIssueOrderPayload {
  order_type: string
  order_number: string
  order_lines: IssueOrderLine[]
}

export interface IIssueOrderService {
  issue(data: IIssueOrderPayload): Promise<string>
}

export class IssueOrderService implements IIssueOrderService {
  constructor(private readonly httpClient: IHttpClient) {}

  public async issue(data: IIssueOrderPayload): Promise<string> {
    const body = { data }
    if (data.order_type === OrderType.SalesOrder) {
      const {
        data: {
          status: { message },
        },
      } = await this.httpClient.post('/salesorder/issue', body)
      return message
    }
    const {
      data: {
        status: { message },
      },
    } = await this.httpClient.post('/transferorder/issue', body)
    return message
  }
}
