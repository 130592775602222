import { useCallback, useState } from 'react'
import { IXtMode, XtMode } from 'common/common.types'

interface IOpportunityDetailsDialogState extends IXtMode {
  open: boolean
  opportunityNumber: string | null
}

export interface IOpportunityDetailsDialogHook extends IOpportunityDetailsDialogState {
  closeDialog(): void
  openDialog(opportunityNumber: string | null, mode: XtMode): void
}

const defaultState: IOpportunityDetailsDialogState = {
  open: false,
  opportunityNumber: null,
  mode: XtMode.New,
}

export function useOpportunityDetailsDialog(): IOpportunityDetailsDialogHook {
  const [state, setState] = useState<IOpportunityDetailsDialogState>(defaultState)

  const closeDialog = useCallback<VoidFunction>(() => {
    setState(defaultState)
  }, [])

  const openDialog = useCallback<(opportunityNumber: string, mode: XtMode) => void>((opportunityNumber, mode) => {
    setState({ open: true, opportunityNumber, mode })
  }, [])

  return {
    ...state,
    closeDialog,
    openDialog,
  }
}
