import { parseServerDate, parseServerDateWithDefault } from 'common/utils/date.utils'
import { convertContactOption } from 'contacts/contacts.utils'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { IOpportunityFormValues, OpportunityFormField, OpportunityFormState } from '../opportunity-details.types'

export function defineGeneralFormData(data: OpportunityFormState | null, user?: string | null): IOpportunityFormValues {
  return {
    [OpportunityFormField.Active]: data?.active ?? true,
    [OpportunityFormField.Number]: data?.number ?? '',
    [OpportunityFormField.Name]: data?.name ?? '',
    [OpportunityFormField.Account]: data?.accountDetails
      ? { ...data.accountDetails, id: data.accountDetails.number, label: data.accountDetails.number }
      : null,
    [OpportunityFormField.Amount]: data?.amount ?? 0,
    [OpportunityFormField.Currency]: defineAutocompleteOption(data?.currency ?? 'USD'),
    [OpportunityFormField.Owner]: defineAutocompleteOption(data?.owner ? data.owner : user),
    [OpportunityFormField.AssignedTo]: defineAutocompleteOption(data?.assigned_user),
    [OpportunityFormField.Project]: defineAutocompleteOption(data?.project),
    [OpportunityFormField.OppType]: defineAutocompleteOption(data?.type),
    [OpportunityFormField.Stage]: defineAutocompleteOption(data?.stage),
    [OpportunityFormField.Priority]: defineAutocompleteOption(data?.priority),
    [OpportunityFormField.LeadSource]: defineAutocompleteOption(data?.source),
    [OpportunityFormField.Probability]: data?.probability_percent ?? 0,
    [OpportunityFormField.StartDate]: data?.start_date === null ? null : parseServerDateWithDefault(data?.start_date),
    [OpportunityFormField.Contact]: convertContactOption(data?.contact),
    [OpportunityFormField.AssigneeDate]: data?.assigned_date ? parseServerDate(data?.assigned_date) : null,
    [OpportunityFormField.TargetDate]: data?.target_date ? parseServerDate(data?.target_date) : null,
    [OpportunityFormField.ActualDate]: data?.target_date ? parseServerDate(data?.actual_date) : null,
  }
}

export const maxProbabilityValue = 100.0
