import { useEffect, useState } from 'react'
import { ICurrency } from 'dictionary/dictionary.types'
import { useDictionaryModule } from '../dictionary-module-hook'

export const useBaseCurrency = (): ICurrency | null => {
  const [currency, setCurrency] = useState<ICurrency | null>(null)
  const { DictionaryService } = useDictionaryModule()

  useEffect(() => {
    const sub = DictionaryService.baseCurrency$.subscribe((value) => setCurrency(value))
    return () => {
      sub.unsubscribe()
    }
  }, [DictionaryService.baseCurrency$])

  return currency
}
