import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { CharacteristicsModule } from 'characteristics/characteristics.module'
import { ContactsModule } from 'contacts/contacts.module'
import { ShipmentsModule } from 'shipments/shipments.module'
import { PurchaseOrdersModule } from 'purchase-orders/purchase-orders.module'
import { CoreModule } from 'core/core.module'
import { ItemSourceModule } from 'item-source/item-source.module'
import { vendorModule } from './vendor.constants'
import { VendorServicesModule } from './vendor-services.module'

export type VendorModuleDefinition = typeof import('./vendor.exports')

export const VendorModule: ILazyModule<VendorModuleDefinition> = {
  name: vendorModule,
  resolver: () => import('./vendor.exports'),
  dependencies: [
    CoreModule,
    CharacteristicsModule,
    ContactsModule,
    ShipmentsModule,
    PurchaseOrdersModule,
    ItemSourceModule,
    VendorServicesModule,
  ],
}
