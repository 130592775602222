import { useCallback, useState } from 'react'
import { XtMode } from 'common/common.types'

interface IItemTabEntityDetailsDialogState<Data> {
  open: boolean
  data: Data | null
  mode: XtMode
}

export interface IItemTabEntityDetailsDialogHook<Data> extends IItemTabEntityDetailsDialogState<Data> {
  openItemTabEntityDialogInNewMode(): void
  closeItemTabEntityDialogDialog(): void
  openItemTabEntityDialog(itemDetails: Data, mode: XtMode): void
}

const defaultSitesDetailsDialogState = {
  open: false,
  data: null,
  mode: XtMode.New,
}

// TODO replace it with useDialog
export function useItemTabEntityDetailsDialog<Data>(): IItemTabEntityDetailsDialogHook<Data> {
  const [state, setState] = useState<IItemTabEntityDetailsDialogState<Data>>(defaultSitesDetailsDialogState)

  const closeItemDetailsDialog = useCallback<VoidFunction>(() => {
    setState((prev) => ({ ...prev, open: false }))
  }, [])

  const openItemDetailsDialog = useCallback<(itemDetails: Data, mode: XtMode) => void>((data: Data, mode: XtMode) => {
    setState({ open: true, data, mode })
  }, [])

  const openItemDetailsInNewMode = useCallback<VoidFunction>(() => {
    setState({ data: null, open: true, mode: XtMode.New })
  }, [])

  return {
    ...state,
    closeItemTabEntityDialogDialog: closeItemDetailsDialog,
    openItemTabEntityDialog: openItemDetailsDialog,
    openItemTabEntityDialogInNewMode: openItemDetailsInNewMode,
  }
}
