import { IRouteGuard } from '../common/routing/routing.types'
import { IAuthService } from './auth.types'
import { authServiceToken, loginRoutePath } from './auth.constants'
import { Injector } from '../core/injector/injector.service'

export class AuthGuard implements IRouteGuard {
  public readonly redirectTo: string = loginRoutePath
  private readonly authService: IAuthService = Injector.resolve<IAuthService>(authServiceToken)

  public validate(): boolean {
    return this.authService.isAuthenticated()
  }
}
