import { ISummarizedForm, FormField } from './summarized-list-form.types'
import { defaultBomListSharedForm } from '../../shared/shared.constants'

export const minThresholdDaysValue = 0
export const maxThresholdDaysValue = 999

export const defaultValues: ISummarizedForm = {
  ...defaultBomListSharedForm,
  [FormField.ThresholdWithExpiredDays]: 0,
  [FormField.ThresholdWithFutureDays]: 0,
  [FormField.ShowExpiredComponents]: false,
  [FormField.ShowFutureComponents]: false,
}
