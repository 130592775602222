import { IPaginationData, IPaginationParams, IPaginationResponse } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IHttpClient } from './http-client'

export interface IWorkingCenterFilters {
  showInactive?: boolean
}

export interface IWorkingCenter {
  active: boolean
  average_setup_time: number
  capacity_load_uom: string
  code: string
  daily_capacity: number
  department: string
  description: string
  efficiency_factor: number
  notes: string
  number_of_machines: number
  number_of_people: number
  percent_labor: number
  queue_days: number
  rate_per_labor_hr: number
  rate_per_machine_hr: number
  rate_per_production_unit: number
  run_labor_rate: number
  run_standard_labor?: string
  setup_labor_rate: number
  setup_standard_labor?: number
  site: string
  wip_location: string
}

export interface IWorkingCenterService {
  getAll: (params: IPaginationParams, filters?: IWorkingCenterFilters) => Promise<IPaginationData<IWorkingCenter>>
}

export class WorkCenterService implements IWorkingCenterService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getAll(paginationParams: IPaginationParams, filters?: IWorkingCenterFilters): Promise<IPaginationData<IWorkingCenter>> {
    const params = prepareRequestParams(paginationParams, filters)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IWorkingCenter>>('/workcenter', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}
