import { IPaginationResponse } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IHttpClient } from 'core/services/http-client'
import { IKanbanOpportunitiesFilter, IKanbanOpportunityStage } from './opportunities-kanban.types'

export interface IOpportunitiesKanbanService {
  getOpportunities(filters?: IKanbanOpportunitiesFilter): Promise<IKanbanOpportunityStage[]>
  getOpportunityByStage(opportunityStage: string, filters?: IKanbanOpportunitiesFilter): Promise<IKanbanOpportunityStage>
}

export class OpportunitiesKanbanService implements IOpportunitiesKanbanService {
  constructor(private readonly httpClient: IHttpClient) {}

  public async getOpportunities(filters?: IKanbanOpportunitiesFilter): Promise<IKanbanOpportunityStage[]> {
    const params = prepareRequestParams(undefined, filters)

    const {
      data: { data },
    } = await this.httpClient.get<IPaginationResponse<IKanbanOpportunityStage>>('/kanban/opportunity', { params })

    return Array.isArray(data) ? data : []
  }

  public async getOpportunityByStage(opportunityStage: string, filters: IKanbanOpportunitiesFilter = {}): Promise<IKanbanOpportunityStage> {
    const params = prepareRequestParams(undefined, { stage: opportunityStage, ...filters })

    const {
      data: { data },
    } = await this.httpClient.get<IPaginationResponse<IKanbanOpportunityStage>>('/kanban/opportunity', { params })

    if (!Array.isArray(data) || !data.length) {
      throw new Error(`Opportunity Stage ${opportunityStage} is not found.`)
    }
    return data[0]
  }
}
