import { ItemOption } from 'products/items/items.types'
import { RevisionOption, RevisionStatus, RevisionType } from 'core/services/revision.service'
import { IFormHook } from 'common/hooks/form/form.types'
import { useXtSelect } from 'components/controls/xt-select/xt-select-hook'
import { useProductsModule } from 'products/products-module-hook'
import { FormSharedField, IBomListSharedForm } from './shared.types'

export interface IBomListSharedHook {
  revisions: RevisionOption[]
  itemDetails: ItemOption | null
  revisionStatus: RevisionStatus | null
  onItemChange(option: ItemOption | null): Promise<void>
}

export function useBomListShared<T extends IBomListSharedForm>(formMethods: IFormHook<T>): IBomListSharedHook {
  const { ItemsUtilsService } = useProductsModule()

  // Direct type casting doesn't work
  const { watch, setValue } = (formMethods as unknown) as IFormHook<IBomListSharedForm>

  const { options: revisions, reset: resetRevisions } = useXtSelect<
    RevisionOption,
    {
      revisionType: RevisionType
      itemNumber: string
    } | null
  >(async (filters) => (filters ? ItemsUtilsService.loadRevisionOptions(filters.revisionType, filters.itemNumber) : []))

  const itemDetails = watch(FormSharedField.ItemDetails)
  const revision = watch(FormSharedField.Revision)

  const revisionStatus = revision?.revision_status ?? null

  const onItemChange = async (option: ItemOption | null): Promise<void> => {
    if (option === null) {
      await resetRevisions(null)
      setValue(FormSharedField.Revision, null)
    } else {
      const options = await resetRevisions({ itemNumber: option.item_number, revisionType: RevisionType.BOM })
      const revisionItem = options.find((item) => item.revision_status === RevisionStatus.Active) ?? null
      setValue(FormSharedField.Revision, revisionItem)
    }
    setValue(FormSharedField.ItemDetails, option)
  }

  return {
    revisions,
    itemDetails,
    revisionStatus,
    onItemChange,
  }
}
