import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { cls } from 'common/utils/utils'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { XtList } from 'components/list/list'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { useTable } from 'common/hooks/useTable'
import { XtMode } from 'common/common.types'
import { LoaderMessage, confirmationMessages, xsMq } from 'common/constants'
import { UserPermission } from 'auth/auth.types'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { XtPageFilter } from 'components/pagefilter/pagefilter'
import { usePageFilter } from 'components/pagefilter/pagefilter.utils'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import {
  ProspectActionsEditMode,
  ProspectActionsViewMode,
  prospectColumns,
  defaultDeletionState,
  defaultFilterValues,
} from './prospects-list.constants'
import { ProspectAction, IProspectTableItem, IDeletionState } from './prospects-list.types'
import { resolveFilters } from './prospects-list.utils'
import * as styles from './prospects-list.module.scss'
import { useProspectsModule } from '../prospects-module-hook'
import { ProspectFilterPanel, ProspectFiltersFieldName, ProspectFiltersLabel } from '../services/prospects-services.types'
import { prospectPageTitle } from '../prospects.constants'

export const ProspectsList: FC = () => {
  const { ProspectsService, ProspectsUtilsService } = useProspectsModule()
  const { DocumentsUtilsService } = useDocumentsModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { PermissionsService } = useAuthModule()

  const history = useHistory()
  const { path } = useRouteMatch()
  const prospectEditableGuard = PermissionsService.hasPermission(UserPermission.MaintainProspectMasters)
  useDocumentTitle(prospectPageTitle)
  const [deletionState, setDeletionState] = useState<IDeletionState>(defaultDeletionState)
  const {
    state: { loading, data, sortOptions, filters: tableFilters },
    refresh,
    pagination,
    filter,
    sort,
  } = useTable(defaultFilterValues, ProspectsUtilsService.fetchProspects, undefined, PageFilterMapping.Prospects)

  const prospectsListFilters = usePageFilter<ProspectFilterPanel>(PageFilterMapping.Prospects)

  const openCRMProspectDetails = useCallback<(item: IProspectTableItem, mode?: XtMode) => void>(
    ({ id }, mode: XtMode = XtMode.View) => history.push(`${path}/${id}/${mode}`),
    [path, history]
  )

  const handleRowClick = useCallback<(item: IProspectTableItem) => void>(
    (item) => {
      if (!prospectEditableGuard) {
        openCRMProspectDetails(item, XtMode.View)
        return
      }
      openCRMProspectDetails(item, XtMode.Edit)
    },
    [openCRMProspectDetails, prospectEditableGuard]
  )

  const handleAction = useCallback<(item: IProspectTableItem, action: ProspectAction) => void>(
    (item, action) => {
      switch (action) {
        case ProspectAction.Edit:
          return openCRMProspectDetails(item, XtMode.Edit)
        case ProspectAction.View:
          return openCRMProspectDetails(item, XtMode.View)
        case ProspectAction.Delete:
          return !loading && setDeletionState({ CRMProspectNumberId: item.number, confirmationOpen: true })
        default:
          return null
      }
    },
    [loading, openCRMProspectDetails, setDeletionState]
  )

  const isMobile = useMediaQuery(xsMq)

  const closeConfirmationDialog = useCallback<() => void>(() => setDeletionState(defaultDeletionState), [])

  const handleDeletion = async (): Promise<void> => {
    closeConfirmationDialog()
    if (deletionState.CRMProspectNumberId) {
      try {
        const message = await ProspectsService.delete(deletionState.CRMProspectNumberId)
        await refresh()
        ToastService.showSuccess(message)
      } catch (error) {
        ErrorHandler.handleError(error)
      }
    }
  }

  const handleShowInactiveFilterChange = (checked: boolean): void => {
    const params = { ...tableFilters, [ProspectFiltersFieldName.ShowInactive]: checked }
    void filter(params)
    void prospectsListFilters.handleLastUsedFilter(params)
  }

  return (
    <div className={cls('xt-content', styles.listContent)}>
      <XtConfirmationDialog
        open={deletionState.confirmationOpen}
        message={confirmationMessages.deleted}
        title="Delete Prospect"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />

      <div className={styles.listControls}>
        <div className={cls('xt-section-border', styles.headerContent)}>
          <h1 className="xt-page-title">Prospects</h1>
          <XtResponsiveButton
            disabled={!prospectEditableGuard}
            label="New Prospect"
            icon={SvgIconIds.ADD_CIRCLE}
            onClick={() => history.push(`${path}/${XtMode.New}`)}
          />
        </div>
        <div className={styles.filtersContainer}>
          <XtPageFilter
            state={prospectsListFilters}
            defaultFilterValues={defaultFilterValues}
            resolveFilters={() => resolveFilters(DocumentsUtilsService)}
            filter={filter}
            tableFilters={tableFilters}
          />
          <XtCheckbox
            className={styles.prospectsListCheckbox}
            label={ProspectFiltersLabel.ShowInactive}
            value={Boolean(tableFilters[ProspectFiltersFieldName.ShowInactive])}
            onChange={handleShowInactiveFilterChange}
            disabled={loading}
          />
        </div>
      </div>
      <XtList
        actions={prospectEditableGuard ? ProspectActionsEditMode : ProspectActionsViewMode}
        onRowClick={handleRowClick}
        onAction={handleAction}
        isMobile={isMobile}
        pagination={pagination}
        loading={loading}
        data={data}
        columns={prospectColumns}
        sortOptions={sortOptions}
        onColumnHeaderClick={sort}
        loadMessage={LoaderMessage.Prospects}
      />
    </div>
  )
}
