import { IAsyncRoute } from '../common/routing/routing.types'
import { setupWizardRoutePath } from './setup-wizard.constants'
import { SetupWizardModule, SetupWizardModuleDefinition } from './setup-wizard.module'

export const setupWizardRoute: IAsyncRoute<SetupWizardModuleDefinition> = {
  module: SetupWizardModule,
  path: setupWizardRoutePath,
  exact: true,
  name: 'Setup Wizard',
  childrenRoutesFactory: ({ SetupWizard }: SetupWizardModuleDefinition) => [
    {
      path: '',
      exact: true,
      component: SetupWizard,
    },
  ],
}
