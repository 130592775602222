import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { BomListAction, IBomTableItem } from './bom-list.types'

import { BomListFilters } from '../bom.types'

export const bomListActionsEditMode: IXtTableCellAction[] = [
  {
    name: BomListAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: BomListAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: BomListAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
  {
    name: BomListAction.Print,
    label: 'Print',
    icon: SvgIconIds.PRINT,
  },
  {
    name: BomListAction.Copy,
    label: 'Copy',
    icon: SvgIconIds.COPY,
  },
]

export const bomListActionsViewMode: IXtTableCellAction[] = [
  {
    name: BomListAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]

export const bomListColumns: IXtTableColumn<IBomTableItem>[] = [
  {
    id: 'item_number',
    field: 'item_number',
    headerName: 'Item Number',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'item_description',
    field: 'item_description',
    headerName: 'Description',
    flex: '2 0 260px',
    width: 260,
  },
  {
    id: 'revision',
    field: 'revision',
    headerName: 'Revision',
    flex: '1 0 110px',
    width: 110,
  },
  {
    id: 'revision_status',
    field: 'revision_status',
    headerName: 'Revision Status',
    flex: '1 0 140px',
    width: 140,
  },
]

export const defaultFilterValues = {
  [BomListFilters.ShowInactive]: false,
  [BomListFilters.ShowComponentItems]: false,
}

export const bomListPageTitle = 'xTuple - Bill of Materials'

export const bomListPageConstant = {
  new_bom: 'New BOM',
  bom: 'Bill of Materials',
}
