import { UseFormReturn } from 'react-hook-form/dist/types'
import { IRemarksHook } from 'comments/remarks/remarks-hook'
import { ICharacteristicsHook } from 'characteristics/characteristics-hook'
import { IDocumentsHook } from 'documents/documents-hook'
import { XtMode } from 'common/common.types'
import { ITaxType } from 'products/items-shared/tax-types/tax-types.types'
import { IItem } from 'products/items/items.types'
import { ItemDetailsForm } from '../item-details.types'

export interface IItemDetailsTabs {
  disabled: boolean
  remarks: IRemarksHook
  characteristics: ICharacteristicsHook
  documents: IDocumentsHook
  mode: XtMode
  formMethods: UseFormReturn<ItemDetailsForm>
  item: IItem | null
  itemNumber: string
  onTaxTypeChange: (taxTypes: ITaxType[]) => void
  taxTypes: ITaxType[]
}

export enum ItemDetailsTabName {
  Details = 'Details',
  Documents = 'Documents',
  Characteristics = 'Characteristics',
  Remarks = 'Remarks',
  Conversions = 'Conversions',
  Sites = 'Item Sites',
  TaxTypes = 'Tax Types',
  Cost = 'Cost',
}
