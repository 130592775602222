import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { dateConverter } from 'common/utils/date.utils'
import { numberConverter } from 'common/utils/utils'
import { IRunningAvailabilityWithId } from '../running-availability.types'

export const runningAvailabilityColumns: IXtTableColumn<IRunningAvailabilityWithId>[] = [
  {
    id: 'order_type',
    field: 'order_type',
    headerName: 'Order Type',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'order_number',
    field: 'order_number',
    headerName: 'Order #',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'source_dest',
    field: 'source_dest',
    headerName: 'Source/Destination',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'due_date',
    field: 'due_date',
    headerName: 'Due Date',
    flex: '1 0 160px',
    width: 160,
    converter: dateConverter,
  },
  {
    id: 'amount',
    field: 'amount',
    headerName: 'Amount',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
  {
    id: 'qty_ordered',
    field: 'qty_ordered',
    headerName: 'Ordered',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
  {
    id: 'qty_received',
    field: 'qty_received',
    headerName: 'Received',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
  {
    id: 'balance',
    field: 'balance',
    headerName: 'Balance',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
  {
    id: 'running_avail',
    field: 'running_avail',
    headerName: 'Running Availability',
    flex: '1 0 260px',
    width: 260,
    converter: numberConverter,
  },
  {
    id: 'running_netable',
    field: 'running_netable',
    headerName: 'Running Netable',
    flex: '1 0 260px',
    width: 260,
    converter: numberConverter,
  },
  {
    id: 'notes',
    field: 'notes',
    headerName: 'Notes',
    flex: '1 0 400px',
    width: 400,
  },
]
