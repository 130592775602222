import * as React from 'react'
import { FC, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FilterDialogWrapper } from 'components/pagefilter/filter/filter-dialog-wrapper'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useXtSelect } from 'components/controls/xt-select/xt-select-hook'
import { FormDatePicker, FormSelectField } from 'common/utils/form/form.components'
import { transactionTypeOption } from '../../../inventory-history-by-location/inventory-history-by-location-list-form/inventory-history-by-location-list-form.constansts'
import {
  InventoryHistoryByLotSerialField,
  InventoryHistoryByLotSerialLabel,
  InventoryHistoryByLotSerialState,
} from '../../inventory-history-by-lot-serial-list.types'
import { defaultHistoryFiltersValues, maxDate, multiLevelTraceOptions } from './inventory-history-by-lot-serial-form-filter.constants'
import { historyByLotSerialValidationScheme } from './inventory-history-by-lot-serial-form-filter.validation'

interface IInventoryHistoryByLotSerialFormFilter {
  open: boolean
  onClose(): void
  onSubmit(filters: InventoryHistoryByLotSerialState): void
  filters: InventoryHistoryByLotSerialState
}

export const InventoryHistoryByLotSerialFormFilter: FC<IInventoryHistoryByLotSerialFormFilter> = ({ open, onSubmit, onClose, filters }) => {
  const { DocumentsUtilsService } = useDocumentsModule()

  const { loadSiteOptions } = DocumentsUtilsService

  const { options: sites } = useXtSelect(loadSiteOptions)

  const { control, handleSubmit, watch, reset, getValues } = useForm<InventoryHistoryByLotSerialState>({
    mode: 'onBlur',
    defaultValues: defaultHistoryFiltersValues,
    resolver: yupResolver(historyByLotSerialValidationScheme),
  })

  const startDate = watch(InventoryHistoryByLotSerialField.StartDate)

  const onClearFilters: VoidFunction = () => {
    reset()
    onSubmit({ ...filters, ...defaultHistoryFiltersValues })
    onClose()
  }

  const submitForm = useCallback(
    (formData: InventoryHistoryByLotSerialState): void => {
      onSubmit({ ...filters, ...formData })
      onClose()
    },
    [filters, onClose, onSubmit]
  )

  return (
    <FilterDialogWrapper
      open={open}
      onCancel={onClose}
      onApplyFilters={handleSubmit(submitForm)}
      onClearFilters={onClearFilters}
      reset={reset}
      getValues={getValues}
    >
      <FormDatePicker
        name={InventoryHistoryByLotSerialField.StartDate}
        label={InventoryHistoryByLotSerialLabel.StartDate}
        control={control}
        maxDate={maxDate}
      />
      <FormDatePicker
        name={InventoryHistoryByLotSerialField.EndDate}
        label={InventoryHistoryByLotSerialLabel.EndDate}
        control={control}
        minDate={startDate}
      />
      <FormSelectField
        label={InventoryHistoryByLotSerialLabel.Site}
        control={control}
        name={InventoryHistoryByLotSerialField.Site}
        options={sites}
        clearable
      />
      <FormSelectField
        label={InventoryHistoryByLotSerialLabel.TransactionTypeDropDown}
        control={control}
        name={InventoryHistoryByLotSerialField.TransactionType}
        options={transactionTypeOption}
        clearable
      />
      <FormSelectField
        label={InventoryHistoryByLotSerialLabel.MultiLevelTrace}
        control={control}
        name={InventoryHistoryByLotSerialField.MultiLevelTrace}
        options={multiLevelTraceOptions}
        clearable
      />
    </FilterDialogWrapper>
  )
}
