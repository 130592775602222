import { Overwrite } from 'utility-types'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from 'core/injector/injector.service'
import { ISalesOrdersModuleServices, SalesOrdersModule, SalesOrdersModuleDefinition } from './sales-orders.module'
import { ISalesOrdersService } from '../sales-shared/sales-orders.service'
import { salesOrdersUtilsServiceToken } from './sales-orders.constants'
import { ISalesOrdersUtilsService } from './sales-orders-utils.service'
import { resolveSalesSharedModule } from '../sales-shared/sales-shared-module-hook'
import { moduleHookResolver } from '../core/react-lazy-modules/react-lazy-modules.utils'

function resolveSalesOrdersModule(): Overwrite<SalesOrdersModuleDefinition, ISalesOrdersModuleServices> & {
  SalesOrdersService: ISalesOrdersService
} {
  const module = LazyModulesService.resolveModule<SalesOrdersModuleDefinition>(SalesOrdersModule)
  const { SalesOrdersService } = resolveSalesSharedModule()
  const SalesOrdersUtilsService = Injector.resolve<ISalesOrdersUtilsService>(salesOrdersUtilsServiceToken)

  return { ...module, SalesOrdersService, SalesOrdersUtilsService }
}

export const useSalesOrdersModule = moduleHookResolver(resolveSalesOrdersModule)
