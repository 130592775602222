import { FormRadioGroupOptions } from 'components/controls/xt-radio-group/xt-radio-group'
import { ICreateAccountValues } from './create-account-dialog.types'

export enum CreateAccountFormField {
  Id = 'id',
  AccountNumber = 'account_number',
  AccountType = 'account_type',
  AccountName = 'account_name',
  Active = 'active',
  FirstName = 'first_name',
  MiddleName = 'middle_name',
  LastName = 'last_name',
  Address1 = 'address1',
  Address2 = 'address2',
  City = 'city',
  State = 'state',
  Postalcode = 'postalcode',
  Country = 'country',
  Phone = 'phone',
  AllowSMS = 'allowsms',
  Email = 'email',
}

export enum CreateAccountFormLabel {
  AccountNumber = 'Account Number (optional - this will default to a number if you leave blank)',
  AccountType = 'Account Type',
  AccountName = 'Account Name (full name of the company)',
  Active = 'Account is Active',
  FirstName = 'First Name',
  MiddleName = 'Middle Name',
  LastName = 'Last Name',
  Address1 = 'Address 1',
  Address2 = 'Address 2',
  City = 'City',
  State = 'State',
  Postalcode = 'Postal Code',
  Country = 'Country',
  Phone = 'Phone Number',
  AllowSMS = 'Allow SMS messages',
  Email = 'Email',
}

export const defaultCreateAccountDialogState = {
  open: false,
  selected: null,
  editMode: false,
}

export enum RadioGroupOption {
  Prospect = 'Prospect',
  Customer = 'Customer',
}

export const radioGroupOptions: FormRadioGroupOptions = [
  { id: RadioGroupOption.Prospect, label: RadioGroupOption.Prospect },
  { id: RadioGroupOption.Customer, label: RadioGroupOption.Customer },
]

export const defaultCreateAccountFormState: ICreateAccountValues = {
  [CreateAccountFormField.Id]: '',
  [CreateAccountFormField.AccountNumber]: '',
  [CreateAccountFormField.AccountName]: '',
  [CreateAccountFormField.AccountType]: RadioGroupOption.Prospect,
  [CreateAccountFormField.FirstName]: '',
  [CreateAccountFormField.MiddleName]: '',
  [CreateAccountFormField.LastName]: '',
  [CreateAccountFormField.Active]: true,
  [CreateAccountFormField.Address1]: '',
  [CreateAccountFormField.Address2]: '',
  [CreateAccountFormField.City]: '',
  [CreateAccountFormField.Country]: null,
  [CreateAccountFormField.State]: '',
  [CreateAccountFormField.Postalcode]: '',
  [CreateAccountFormField.Phone]: '',
  [CreateAccountFormField.Email]: '',
  [CreateAccountFormField.AllowSMS]: false,
}
