import { ControlMethodOption, IItemSite, ItemSiteDistributionOption } from 'products/items/items.types'
import { ILotSerialEntry, LotSerialEntryRow, LotSerialField, LotSerialOption } from '../../lot-serial.types'
import { generateLotSerialItemPlaceholder, supportsLotSerial } from '../../lot-serial.utils'
import { ILotSerialHookMeta } from './lot-serial-hook.types'
import { canApplyAutoDistributedLocation } from '../lot-serial-location-hook/lot-serial-location-hook.utils'

function roundNumberToFixedDecimalPoints(value: number, quantityDecimalScale: number): number {
  if (quantityDecimalScale === 0) {
    return value
  }
  return Math.round(value * 100) / 100
}

export function calculateMetaState(qtyToAssign: number, qtyAssigned: number, quantityDecimalScale: number): ILotSerialHookMeta {
  return { qtyAssigned, qtyToAssign, qtyRemaining: roundNumberToFixedDecimalPoints(qtyToAssign - qtyAssigned, quantityDecimalScale) }
}

export function supportsAutoLocationDistribution(
  { multiple_location_control, auto_distr_location }: IItemSite,
  location: ItemSiteDistributionOption | null,
  qtyToAssign: number
): boolean {
  if (!location || !multiple_location_control || !auto_distr_location) {
    return false
  }
  return canApplyAutoDistributedLocation(location, qtyToAssign)
}

export function supportsSerialAutomationMode({ control_method, auto_ls_number }: IItemSite, qtyToAssign: number): boolean {
  return control_method === ControlMethodOption.SerialNumber && !!auto_ls_number && qtyToAssign > 0
}

export function supportsLotAutomationMode({ control_method, auto_ls_number }: IItemSite, qtyToAssign: number): boolean {
  return control_method === ControlMethodOption.LotNumber && !!auto_ls_number && qtyToAssign > 0
}

export function defineTableInitialData(
  location: ItemSiteDistributionOption | null,
  lotSerialNumbers: LotSerialOption[] | null,
  lotSerialOptions: LotSerialOption[],
  itemSite: IItemSite,
  qtyToAssign: number,
  quantityDecimalScale: number
): { data: LotSerialEntryRow[]; meta: ILotSerialHookMeta } {
  let defaultQuantityBefore = location?.qty_before ?? 0

  if (supportsAutoLocationDistribution(itemSite, location, qtyToAssign)) {
    const entry = generateLotSerialItemPlaceholder(location, null, qtyToAssign, defaultQuantityBefore)
    return { data: [entry], meta: calculateMetaState(qtyToAssign, qtyToAssign, quantityDecimalScale) }
  }
  if (supportsLotAutomationMode(itemSite, qtyToAssign) && lotSerialNumbers) {
    const entry = generateLotSerialItemPlaceholder(location, lotSerialNumbers[0], qtyToAssign, defaultQuantityBefore)
    return { data: [entry], meta: calculateMetaState(qtyToAssign, qtyToAssign, quantityDecimalScale) }
  }
  if (supportsSerialAutomationMode(itemSite, qtyToAssign) && lotSerialNumbers) {
    const data = lotSerialNumbers.map((number) => generateLotSerialItemPlaceholder(location, number, 1, defaultQuantityBefore))
    return { data, meta: calculateMetaState(qtyToAssign, qtyToAssign, quantityDecimalScale) }
  }

  let defaultLotSerial: LotSerialOption | null = null

  if (location && lotSerialOptions.length && qtyToAssign < 0) {
    defaultLotSerial = lotSerialOptions.find(({ location_name }) => location_name === location.id) ?? null
    defaultQuantityBefore = defaultLotSerial?.qty_before ?? 0
  }

  const entry = generateLotSerialItemPlaceholder(location, defaultLotSerial, 0, defaultQuantityBefore)
  return { data: [entry], meta: calculateMetaState(qtyToAssign, 0, quantityDecimalScale) }
}

function updateLotSerialEntryQuantity<T extends ILotSerialEntry>(lotSerialEntry: ILotSerialEntry, quantity: number): T {
  return { ...lotSerialEntry, [LotSerialField.Quantity]: quantity } as T
}

// TODO support Warranty and Expiry dates
function updateSerialEntries(
  lotSerialEntries: LotSerialEntryRow[],
  lotSerialOptions: LotSerialOption[],
  location: ItemSiteDistributionOption | null
): LotSerialEntryRow[] {
  return lotSerialOptions.map((lotSerialOption, index) => {
    const currentEntry = lotSerialEntries[index]
    if (!currentEntry) {
      return generateLotSerialItemPlaceholder(location, lotSerialOption, 1, location?.qty_before ?? 0)
    } else {
      return { ...currentEntry, [LotSerialField.LotSerialNumber]: lotSerialOption }
    }
  })
}

export function updateTableData(
  data: LotSerialEntryRow[],
  itemSite: IItemSite,
  qtyToAssign: number,
  location: ItemSiteDistributionOption | null,
  lotSerialNumbers: LotSerialOption[] | null,
  quantityDecimalScale: number
): { data: LotSerialEntryRow[]; meta: ILotSerialHookMeta } | null {
  if (supportsAutoLocationDistribution(itemSite, location, qtyToAssign)) {
    const entry = updateLotSerialEntryQuantity<LotSerialEntryRow>(data[0], qtyToAssign)
    return { data: [entry], meta: calculateMetaState(qtyToAssign, qtyToAssign, quantityDecimalScale) }
  }
  if (supportsLotAutomationMode(itemSite, qtyToAssign)) {
    const entry = updateLotSerialEntryQuantity<LotSerialEntryRow>(data[0], qtyToAssign)
    return { data: [entry], meta: calculateMetaState(qtyToAssign, qtyToAssign, quantityDecimalScale) }
  }
  if (supportsSerialAutomationMode(itemSite, qtyToAssign) && lotSerialNumbers) {
    return {
      data: updateSerialEntries(data, lotSerialNumbers, location),
      meta: calculateMetaState(qtyToAssign, lotSerialNumbers.length, quantityDecimalScale),
    }
  }
  return null
}

export function retrieveAssignedQuantity(data: Pick<ILotSerialEntry, LotSerialField.Quantity>[]): number {
  return data.reduce((res, { quantity }) => res + Number(quantity), 0)
}

export function isValidQtyToAssignPayload(
  payload: [prev: number | undefined, current: number | undefined]
): payload is [prev: number | undefined, current: number] {
  const [prev, current] = payload
  if (prev === current) {
    return false
  }
  return current !== 0 && current !== undefined
}

export function isValidItemSitePayload({ control_method, multiple_location_control }: IItemSite): boolean {
  return supportsLotSerial(control_method) || multiple_location_control
}

export function findDuplicatedSerialNumber(data: ILotSerialEntry[]): string | null {
  const set: Set<string> = new Set<string>()

  for (let i = 0; i < data.length; i++) {
    const lotSerialOption = data[i][LotSerialField.LotSerialNumber]
    if (!lotSerialOption) {
      continue
    }
    if (set.has(lotSerialOption.id)) {
      return lotSerialOption.id
    } else {
      set.add(lotSerialOption.id)
    }
  }

  return null
}
