import * as yup from 'yup'
import { DateSchema } from 'yup'
import { validationMessage } from 'common/validation/validation'
import { dateYupValidator, isValidDate } from 'common/utils/date.utils'
import { LocationFormField, LocationFormLabel } from './inventory-history-by-location-list-form.types'

export const historyByLocationMainValidationScheme = yup.object().shape(
  {
    [LocationFormField.Location]: yup.object().required(validationMessage.isRequired).nullable(),
    [LocationFormField.Site]: yup.object().required(validationMessage.isRequired).nullable(),
    [LocationFormField.StartDate]: dateYupValidator.when(LocationFormField.EndDate, (endDate: Date | null, schema: DateSchema) =>
      endDate && isValidDate(endDate) ? schema.max(endDate, validationMessage.maxDate(LocationFormLabel.EndDate)) : schema
    ),
    [LocationFormField.EndDate]: dateYupValidator.when(LocationFormField.StartDate, (startDate: Date | null, schema: DateSchema) =>
      startDate && isValidDate(startDate) ? schema.min(startDate, validationMessage.minDate(LocationFormLabel.StartDate)) : schema
    ),
  },
  [[LocationFormField.StartDate, LocationFormField.EndDate]]
)
