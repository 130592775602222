import React, { FC, useCallback, useEffect, useRef } from 'react'
import { Observable, Subject } from 'rxjs'
import { useMediaQuery } from '@material-ui/core'
import { NavLink, useHistory } from 'react-router-dom'
import { cls } from 'common/utils/utils'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { UserPermission } from 'auth/auth.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { XtMode } from 'common/common.types'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { xsMq } from 'common/constants'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { useKanbanModule } from 'kanban/kanban-module-hook'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { useOpportunitiesModule } from 'activities/opportunities/opportunities-module-hook'
import { XtPageFilter } from 'components/pagefilter/pagefilter'
import { usePageFilter } from 'components/pagefilter/pagefilter.utils'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { opportunitiesRoutePath } from 'activities/opportunities/opportunities.constants'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { IKanbanOpportunityCard, IKanbanOpportunityColumn } from './opportunities-board.types'
import { defineDefaultFilters, isFilterByUserOpportunitiesChecked, resolveFilters } from './opportunities-board.utils'
import { itemsPerRequest, opportunitiesBoardPageTitle } from './opportunities-board.constants'
import { IKanbanOpportunitiesFilter, KanbanOpportunitiesFilter, KanbanOpportunitiesFilterPanel } from '../opportunities-kanban.types'
import { OpportunityKanbanCard } from './opportunity-kanban-card/opportunity-kanban-card'
import { OpportunityKanbanColumnHeader } from './opportunity-kanban-column-header/opportunity-kanban-column-header'
import { OpportunityKanbanColumnFooter } from './opportunity-kanban-column-footer/opportunity-kanban-column-footer'
import * as styles from './opportunities-board.module.scss'

export const OpportunitiesBoard: FC = () => {
  const { OpportunitiesKanbanUtilsService, OpportunitiesService } = useOpportunitiesModule()
  const { DocumentsUtilsService } = useDocumentsModule()
  const { ErrorHandler } = useCoreModule()
  const { PermissionsService, AuthService } = useAuthModule()
  const { XtKanbanBoard, useKanbanHook } = useKanbanModule()
  useDocumentTitle(opportunitiesBoardPageTitle)
  const { columns, initialiseBoard, setLoading, loading, findCard } = useKanbanHook<
    IKanbanOpportunityColumn,
    IKanbanOpportunityCard,
    IKanbanOpportunitiesFilter
  >({
    fetchItems: OpportunitiesKanbanUtilsService.fetchOpportunityCards,
    cardsLimitPerRequest: itemsPerRequest,
  })

  const canManageOpportunities = PermissionsService.hasSomePermission([
    UserPermission.MaintainAllOpportunities,
    UserPermission.MaintainPersonalOpportunities,
  ])

  const username = AuthService.getUsername()
  const isMobile = useMediaQuery(xsMq)
  const history = useHistory()
  const defaultFilters = defineDefaultFilters(username)
  const filtersStateRef = useRef<KanbanOpportunitiesFilterPanel>(defaultFilters)
  const resetFiltersSubject = useRef<Subject<KanbanOpportunitiesFilterPanel>>(new Subject())
  const resetFilters$ = useRef<Observable<KanbanOpportunitiesFilterPanel>>(resetFiltersSubject.current.asObservable())

  const filterBoard = useCallback<(boardFilters: KanbanOpportunitiesFilterPanel) => Promise<void>>(
    async (boardFilters) => {
      try {
        setLoading(true)
        filtersStateRef.current = boardFilters
        const { is_my_opportunities: _, ...serverFilters } = boardFilters
        const cols = await OpportunitiesKanbanUtilsService.requestColumns(serverFilters)
        initialiseBoard(cols)
      } catch (error) {
        ErrorHandler.handleError(error as Error)
      } finally {
        setLoading(false)
      }
    },
    [ErrorHandler, OpportunitiesKanbanUtilsService, initialiseBoard, setLoading]
  )

  const kanbanOpportunitiesFilters = usePageFilter<KanbanOpportunitiesFilterPanel>(PageFilterMapping.DashOpp)

  // Board initialisation
  useEffect(() => {
    void filterBoard(filtersStateRef.current)
  }, [filterBoard])

  const openOpportunityDetails = useCallback<(opportunityNumber: string, mode: XtMode) => void>(
    (number, mode) => {
      history.push(`${opportunitiesRoutePath}/${number}/${mode}`)
    },
    [history]
  )

  const handleCardClick = useCallback<(columnId: string, cardId: string) => void>(
    (columnId, cardId) => {
      try {
        const card = findCard(cardId, columnId)
        const dialogMode = card.editable ? XtMode.Edit : XtMode.View
        openOpportunityDetails(card.id, dialogMode)
      } catch (error) {
        ErrorHandler.handleError(error as Error)
      }
    },
    [ErrorHandler, findCard]
  )

  const updateOpportunityStage = useCallback<(opportunityId: string, stage: string) => Promise<void>>(
    async (opportunityId, stage) => {
      try {
        await OpportunitiesService.updateStage(opportunityId, stage)
      } catch (error) {
        ErrorHandler.handleError(error as Error)
      } finally {
        // board data should be reset in case of error or successful update
        await filterBoard(filtersStateRef.current)
      }
    },
    [ErrorHandler, OpportunitiesService, filterBoard]
  )

  const handleDragEnd = useCallback<
    (sourceColumnId: string, targetColumnId: string, position: number, card: IKanbanOpportunityCard) => boolean
  >(
    (sourceColumnId, targetColumnId, _position, card) => {
      if (sourceColumnId === targetColumnId) {
        return false
      }

      void updateOpportunityStage(card.id, targetColumnId)
      return true
    },
    [updateOpportunityStage]
  )

  const filterByMyOpportunities = useCallback<(checked: boolean) => void>(
    (checked) => {
      const usernameFilter: IXtAutocompleteOption | null = username && checked ? defineAutocompleteOption(username) : null
      const newFiltersState = { ...filtersStateRef.current, [KanbanOpportunitiesFilter.User]: usernameFilter }
      resetFiltersSubject.current.next({ ...filtersStateRef.current, [KanbanOpportunitiesFilter.User]: usernameFilter })

      void filterBoard(newFiltersState)
      void kanbanOpportunitiesFilters.handleLastUsedFilter(newFiltersState)
    },
    [username, filterBoard, kanbanOpportunitiesFilters]
  )

  const filterShowInactive = useCallback<(checked: boolean) => void>(
    (checked) => {
      const newFiltersState = { ...filtersStateRef.current, [KanbanOpportunitiesFilter.ShowInactive]: checked }
      resetFiltersSubject.current.next(newFiltersState)

      void filterBoard(newFiltersState)
      void kanbanOpportunitiesFilters.handleLastUsedFilter(newFiltersState)
    },
    [filterBoard, kanbanOpportunitiesFilters]
  )

  const createNewOpportunity = useCallback(() => history.push(`${opportunitiesRoutePath}/${XtMode.New}`), [])

  return (
    <div className={cls('xt-content', styles.opportunitiesBoard)}>
      <div className={styles.boardControls}>
        <div className={cls('xt-section-border', styles.boardHeader)}>
          <h1 className="xt-page-title">Opportunities Board</h1>
          {!isMobile && (
            <XtResponsiveButton
              onClick={createNewOpportunity}
              disabled={!canManageOpportunities}
              label="New Opportunity"
              icon={SvgIconIds.ADD_CIRCLE}
              className={styles.opportunitiesBoardAddNewButton}
            />
          )}

          {isMobile && (
            <XtResponsiveButton
              onClick={createNewOpportunity}
              disabled={!canManageOpportunities}
              label="New Opportunity"
              icon={SvgIconIds.ADD_CIRCLE}
              className={styles.opportunitiesBoardAddNewButton}
            />
          )}
        </div>
        <div className={cls(styles.filtersContainer)}>
          <XtPageFilter
            state={kanbanOpportunitiesFilters}
            defaultFilterValues={defaultFilters}
            resolveFilters={() => resolveFilters(DocumentsUtilsService)}
            filter={filterBoard}
            tableFilters={filtersStateRef.current}
            resetFilters={resetFilters$.current}
          />

          <div className={styles.opportunitiesBoardCheckboxesFilter}>
            <XtCheckbox
              value={isFilterByUserOpportunitiesChecked(filtersStateRef.current, username)}
              label="Only show my opportunities"
              onChange={filterByMyOpportunities}
              className={styles.opportunitiesBoardCheckboxFilter}
              disabled={loading}
            />
            <XtCheckbox
              value={filtersStateRef.current[KanbanOpportunitiesFilter.ShowInactive]}
              label="Show Inactive"
              onChange={filterShowInactive}
              className={styles.opportunitiesBoardCheckboxFilter}
              disabled={loading}
            />
          </div>
          <div className={styles.opportunitiesBoardLinksGroup}>
            <NavLink to={opportunitiesRoutePath} className={styles.opportunitiesBoardLink} title="List View">
              <div>
                <SvgIcon iconId={SvgIconIds.LIST_ICON} className={styles.opportunitiesBoardLinkIcon} />
                <span className="visible-hidden">List View</span>
              </div>
            </NavLink>
          </div>
        </div>
      </div>

      <XtKanbanBoard
        draggable
        loading={loading}
        columns={columns}
        id="opportunity_board"
        title="Opportunity board"
        onCardClick={handleCardClick}
        onDragEnd={handleDragEnd}
        laneDraggable={false}
        className={cls('xt-opportunities-kanban-board', 'xt-opportunities-kanban-board-mobile')}
        cardTemplate={OpportunityKanbanCard}
        headerTemplate={OpportunityKanbanColumnHeader}
        footerTemplate={OpportunityKanbanColumnFooter}
      />
    </div>
  )
}
