import type { IXtSidebarOption } from './sidebar.types'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { homeRoutePath } from 'home/home.constants'
import { productsRoutePath } from 'products/products.constants'
import { canActivateRoute } from 'common/routing/guarded-route/guarder-route.utils'
import { productRoute } from 'products/products.routes'
import { setupRoutePath } from 'setup/setup.constants'
import { setupRoute } from 'setup/setup.routes'
import { shippingLabel, shippingRoutePath } from 'shipping/shipping.constants'
import { shippingRoute } from 'shipping/shipping.routes'
import { companiesRoutePath } from 'companies/companies.constants'
import { companiesRoute } from 'companies/companies.routes'
import { activitiesRoutePath } from 'activities/activities.constants'
import { activitiesRoute } from 'activities/activities.routes'
import { ordersRoutePath } from 'orders/orders.constants'
import { ordersRoute } from 'orders/orders.routes'
import { purchasingRoutePath } from 'purchasing/purchasing.constants'
import { purchasingRoute } from 'purchasing/purchasing.routes'
import { inventoryRoute } from 'inventory/inventory.routes'
import { inventoryRoutePath } from '../../inventory/inventory.constants'

export function getSidebarOptions(): IXtSidebarOption[] {
  const sidebarOptions = [
    {
      name: 'Home',
      path: homeRoutePath,
      icon: SvgIconIds.HOME,
      exact: true,
      disabled: false,
    },
    {
      name: 'Products',
      path: productsRoutePath,
      icon: SvgIconIds.PRODUCTS,
      disabled: !canActivateRoute(productRoute.guards),
    },
    {
      name: 'Accounts',
      path: companiesRoutePath,
      icon: SvgIconIds.CRM,
      disabled: !canActivateRoute(companiesRoute.guards),
    },
    {
      name: 'Activities',
      path: activitiesRoutePath,
      icon: SvgIconIds.LIST_ICON,
      disabled: !canActivateRoute(activitiesRoute.guards),
    },
    {
      name: 'Inventory',
      path: inventoryRoutePath,
      icon: SvgIconIds.INVENTORY,
      disabled: !canActivateRoute(inventoryRoute.guards),
    },
    {
      name: shippingLabel,
      path: shippingRoutePath,
      icon: SvgIconIds.SHIPPING,
      disabled: !canActivateRoute(shippingRoute.guards),
    },
    {
      name: 'Purchasing',
      path: purchasingRoutePath,
      icon: SvgIconIds.PURCHASING,
      disabled: !canActivateRoute(purchasingRoute.guards),
    },
    {
      name: 'Sales',
      path: ordersRoutePath,
      icon: SvgIconIds.SALES_ORDERS,
      disabled: !canActivateRoute(ordersRoute.guards),
    },
    {
      name: 'Settings',
      path: setupRoutePath,
      icon: SvgIconIds.SETTINGS,
      disabled: !canActivateRoute(setupRoute.guards),
    },
  ]
  return sidebarOptions
}
