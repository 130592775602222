import { IAsyncRoute } from 'common/routing/routing.types'
import { InventoryAdjustmentModule, InventoryAdjustmentModuleDefinition } from './inventory-adjustments.module'

// TODO update me
export const lotSerialRoute: IAsyncRoute<InventoryAdjustmentModuleDefinition> = {
  path: 'inventory-adjustment',
  module: InventoryAdjustmentModule,
  name: 'Inventory Adjustment',
  childrenRoutesFactory: ({ InventoryAdjustmentsPage }: InventoryAdjustmentModuleDefinition) => [
    {
      path: '',
      exact: true,
      component: InventoryAdjustmentsPage,
    },
  ],
}
