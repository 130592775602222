import React, { FC } from 'react'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { runningAvailabilityPageTitle } from 'running-availability/running-availability.constants'
import { useRunningAvailabilityModule } from '../running-availability-module.hook'

export const RunningAvailabilityListPage: FC = () => {
  const { RunningAvailabilityList } = useRunningAvailabilityModule()
  useDocumentTitle(runningAvailabilityPageTitle)
  return <RunningAvailabilityList />
}
