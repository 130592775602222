import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IPaginationData, IPaginationParams, IPaginationResponse } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IHttpClient } from './http-client'

export interface IInvoiceItem {
  invoice_number: string
  line_number: number
  item_number: string
  item_description: string
  misc_item_number: string
  site: string
  misc_item_description: string
  sales_category: null | string
  customer_part_number: string
  qty_ordered: number
  qty_billed: number
  update_inventory: boolean
  net_unit_price: number
  tax_type: string
  qty_uom: string
  qty_uom_description: string
  price_uom: string
  price_uom_description: string
  notes: string
  alternate_rev_account: string | null
  invoice_subnumber: number
  tax_exemption_category: string | null
}

export interface IInvoice {
  invoice_number: string
  order_number: string
  invoice_date: string
  posted: boolean
  voided: boolean
  printed: boolean
  ship_date: string
  order_date: string
  site: string
  sale_type: string
  sales_rep: string
  tax_zone: string
  terms: string
  terms_description: string
  customer_number: string
  customer_name: string
  billto_name: string
  billto_address1: string
  billto_address2: string
  billto_address3: string
  billto_city: string
  billto_state: string
  billto_postal_code: string
  billto_country: string
  billto_phone: string
  shipto_number: string
  shipto_name: string
  shipto_address1: string
  shipto_address2: string
  shipto_address3: string
  shipto_city: string
  shipto_state: string
  shipto_postal_code: string
  shipto_country: string
  shipping_zone: string
  shipto_phone: string
  po_number: string
  ship_via: string
  fob: string
  misc_charge_description: string
  misc_charge: number
  misc_charge_account_number: string
  freight: number
  currency: string
  invoice_total: number
  amount_paid: number
  invoice_paid: boolean
  balance: number
  notes: string
  tax_exemption_category: null
  invoice_items: IInvoiceItem[]
  created: Date
  lastupdated: Date
}

export type InvoiceOption = IInvoice & IXtAutocompleteOption

export interface IInvoiceService {
  getAll(pagination?: IPaginationParams): Promise<IPaginationData<IInvoice>>
}

export class InvoiceService implements IInvoiceService {
  constructor(private readonly httpClient: IHttpClient) {}

  public async getAll(pagination?: IPaginationParams): Promise<IPaginationData<IInvoice>> {
    const params = prepareRequestParams(pagination)
    const {
      data: { data, status },
    } = await this.httpClient.get<IPaginationResponse<IInvoice>>('/invoice', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}
