import React, { FC } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { cls } from 'common/utils/utils'
import * as styles from './xt-checkbox.module.scss'

export interface IXtCheckboxProps {
  label: string
  disabled?: boolean
  className?: string
  error?: string
  hidden?: boolean
  onChange?(checked: boolean): void
  onBlur?(): void
  value: boolean
}

export const XtCheckbox: FC<IXtCheckboxProps> = ({ label, disabled, onChange, className, hidden, value, onBlur }) => (
  <FormControlLabel
    label={label}
    title={label}
    hidden={hidden}
    disabled={disabled}
    className={cls(className)}
    onBlur={onBlur}
    classes={{ root: styles.formControlLabelRoot }}
    control={
      <Checkbox
        color="primary"
        disabled={disabled}
        checked={value}
        onChange={({ target: { checked: targetChecked } }) => onChange && onChange(targetChecked)}
      />
    }
  />
)
