import { ICustomer } from 'companies/customers/customers.types'
import { defineAutocompleteOption, requestOptions } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'

import { IDocumentsUtilsService } from 'documents/documents-utils.service'
import { ISettingsTermsDefaultValues, ISettingTermsForm, SettingsTermsFormField } from './setting-tab.types'
import { defaultSettingsOptions } from './settings-tab.constants'

export async function requestDefaultValues({ loadTerms }: IDocumentsUtilsService): Promise<ISettingsTermsDefaultValues> {
  try {
    const requestedOptions = await requestOptions([loadTerms()])

    const [terms_options] = requestedOptions

    return {
      options: { terms_options },
    }
  } catch (e) {
    return defaultSettingsOptions
  }
}

export function setDefaultValues() {}

export function defineSettingsData(data: ICustomer | null): ISettingTermsForm {
  return {
    [SettingsTermsFormField.Terms]: defineAutocompleteOption(data?.terms_code ?? null),
    [SettingsTermsFormField.Discount]: data?.default_discount ?? null,
    [SettingsTermsFormField.Currency]: defineAutocompleteOption(data?.default_currency ?? null),
    [SettingsTermsFormField.CreditLimit]: data?.credit_limit ?? null,
    [SettingsTermsFormField.CreditLimitCurrency]: defineAutocompleteOption(data?.credit_limit_currency ?? null),
    [SettingsTermsFormField.CreditRating]: data?.credit_rating ?? '',
    [SettingsTermsFormField.CreditStatus]: defineAutocompleteOption(data?.credit_status ?? ''),
    [SettingsTermsFormField.CreditStatusExceedHold]: data?.credit_status_exceed_hold ?? false,
    [SettingsTermsFormField.CreditStatusExceedWarn]: data?.credit_status_exceed_warn ?? false,
    [SettingsTermsFormField.AlternateGraceDays]: data?.alternate_grace_days ?? 0,
    [SettingsTermsFormField.UsesPurchaseOrders]: data?.uses_purchase_orders ?? false,
    [SettingsTermsFormField.UsesBlanketPOs]: data?.uses_blanket_pos ?? false,
    [SettingsTermsFormField.AllowFreeFormBillTo]: data?.allow_free_form_billto ?? false,
    [SettingsTermsFormField.AllowFreeFormShipTo]: data?.allow_free_form_shipto ?? false,
  }
}
