import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { cardsDisablingTypesArray } from './details-tab.constants'

export function isItemCardDisabled(itemType: IXtAutocompleteOption | null): boolean {
  if (!itemType) {
    return false
  }

  return !!cardsDisablingTypesArray.find((type) => type.valueOf() === itemType.id)
}
