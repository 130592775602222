import { KanbanModuleDefinition } from './kanban-module.types'
import { LazyModulesService } from '../core/react-lazy-modules/react-lazy-modules.service'
import { kanbanModuleToken } from './kanban.constants'
import { moduleHookResolver } from '../core/react-lazy-modules/react-lazy-modules.utils'

function resolveKanbanModule(): KanbanModuleDefinition {
  const module = LazyModulesService.resolveModule<KanbanModuleDefinition>(kanbanModuleToken)

  return { ...module }
}

export const useKanbanModule = moduleHookResolver<KanbanModuleDefinition>(resolveKanbanModule)
