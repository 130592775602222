import type { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import type { VendorModuleDefinition } from 'vendor/vendor.module'
import type { CompaniesContactsModuleDefinition } from './contacts/contacts.module'
import type { AccountsModuleDefinition } from './accounts/accounts.module'
import type { CustomersModuleDefinition } from './customers/customers.module'
import type { ProspectsModuleDefinition } from './prospects/prospects.module'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { VendorModule } from 'vendor/vendor.module'
import { AccountsModule } from './accounts/accounts.module'
import { companiesModuleToken } from './companies.constants'
import { CompaniesContactsModule } from './contacts/contacts.module'
import { CustomersModule } from './customers/customers.module'
import { ProspectsModule } from './prospects/prospects.module'
import { CompaniesLeadsModule, CompaniesLeadsModuleDefinition } from './leads/leads.module'

export type CompaniesModuleDefinition = AccountsModuleDefinition &
  CustomersModuleDefinition &
  ProspectsModuleDefinition &
  VendorModuleDefinition

export const CompaniesModule: ILazyModule<CompaniesModuleDefinition> = {
  name: companiesModuleToken,
  resolver: async () => {
    const accountsModule = LazyModulesService.resolveModule<AccountsModuleDefinition>(AccountsModule)
    const companiesContactsModule = LazyModulesService.resolveModule<CompaniesContactsModuleDefinition>(CompaniesContactsModule)
    const customersModule = LazyModulesService.resolveModule<CustomersModuleDefinition>(CustomersModule)
    const prospectsModule = LazyModulesService.resolveModule<ProspectsModuleDefinition>(ProspectsModule)
    const vendorModule = LazyModulesService.resolveModule<VendorModuleDefinition>(VendorModule)
    const leadModule = LazyModulesService.resolveModule<CompaniesLeadsModuleDefinition>(CompaniesLeadsModule)
    return {
      ...accountsModule,
      ...companiesContactsModule,
      ...customersModule,
      ...prospectsModule,
      ...vendorModule,
      ...leadModule,
    }
  },
  initializer: () => {},
  dependencies: [AccountsModule, CompaniesContactsModule, CustomersModule, ProspectsModule, VendorModule, CompaniesLeadsModule],
}
