import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { CustomersModule, CustomersModuleDefinition } from './customers.module'
import { ICustomersServices } from './services/customers-services.types'
import { resolveCustomersServicesModule } from './services/customers-services-module-hook'

function resolveCustomersModule(): CustomersModuleDefinition & ICustomersServices {
  const module = LazyModulesService.resolveModule<CustomersModuleDefinition>(CustomersModule)
  const services = resolveCustomersServicesModule()

  return {
    ...module,
    ...services,
  }
}

export const useCustomersModule = moduleHookResolver(resolveCustomersModule)
