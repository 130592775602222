import React from 'react'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { useOpportunitiesModule } from '../opportunities-module-hook'
import { opportunitiesPageTitle } from './opportunities-list-page.constants'

export const OpportunitiesListPage = () => {
  useDocumentTitle(opportunitiesPageTitle)
  const { OpportunitiesList } = useOpportunitiesModule()
  return <OpportunitiesList />
}
