import type { IPaginationData, IPaginationParams } from 'common/common.types'
import type { ISortOption } from 'components/table/table-head/table-head.types'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { convertFiltersPanelState } from 'common/utils/filter.utils'
import { ILeadService } from 'leads/leads.service'
import { ILeadTableItem } from 'leads/lead-list/lead-list.types'
import { LeadFilterPanel } from './leads.types'

export interface ICompaniesLeadsUtilsService {
  deleteLead(contactNumber: string): Promise<string>
  fetchLeads(
    filters: LeadFilterPanel,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<ILeadTableItem>>
}

export class LeadsUtilsService implements ICompaniesLeadsUtilsService {
  constructor(private leadsService: ILeadService) {
    bindAllInstanceMethods(this)
  }

  public deleteLead(contactNumber: string): Promise<string> {
    return this.leadsService.delete(contactNumber)
  }

  public async fetchLeads(
    filters: LeadFilterPanel,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<ILeadTableItem>> {
    const { total, data } = await this.leadsService.getAll(paginationParams, convertFiltersPanelState(filters), sortOptions)

    return {
      data: data.map((item) => ({ ...item, id: item.contact_number })),
      total,
    }
  }
}
