import React, { FC, memo, useCallback } from 'react'
import { XtList } from 'components/list/list'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { XtMode } from 'common/common.types'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { confirmationMessages } from 'common/constants'
import { useTable } from 'common/hooks/useTable'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { useCoreModule } from 'core/core-module-hook'
import { useSharedModule } from 'shared/shared-module-hook'
import { useProductsModule } from 'products/products-module-hook'
import { ItemDetailsFormField } from 'products/items/item-details/item-details.types'
import { useAuthModule } from 'auth/auth-module-hook'
import { UserPermission } from 'auth/auth.types'
import { ISiteDialogData, ISitesTab, SitesAction, SiteTableItem } from './sites-tab.types'
import { SitesDetailsDialog } from './sites-details-dialog/sites-details-dialog'
import { SitesTabActions, SitesTabActionsViewMode, SitesTabColumns } from './sites-tab.constants'
import * as styles from '../conversions-tab/conversions-tab.module.scss'

export const SitesTab: FC<ISitesTab> = memo(({ formMethods, isViewMode, isMobile, item, checkIsAnySiteSerial }) => {
  const { ErrorHandler, ToastService } = useCoreModule()
  const { ItemSitesService, ItemsUtilsService } = useProductsModule()
  const { useDialog } = useSharedModule()
  const { PermissionsService } = useAuthModule()

  const canManageItemSites = PermissionsService.hasPermission(UserPermission.MaintainItemSites)
  const canViewItemSites = PermissionsService.hasPermission(UserPermission.ViewItemSites)

  const { watch } = formMethods
  const {
    open: siteDialogOpen,
    data: siteDialogData,
    closeDialog: closeSiteDialog,
    openDialog: openSiteDialog,
  } = useDialog<ISiteDialogData>({ mode: XtMode.New, site: null })
  const { open: openDeleteDialog, itemId, openDialog, closeDialog } = useConfirmationDialog()
  const { state, sort, pagination, refresh } = useTable(
    { showInactive: true, showDetails: true, itemNumber: item.item_number },
    ItemsUtilsService.fetchSites
  )

  const uom = watch(ItemDetailsFormField.InventoryUOM)
  const fractional = watch(ItemDetailsFormField.Fractional)

  const handleAction = useCallback<(tableItem: SiteTableItem, action: SitesAction) => void>(
    ({ site: selectedSite }, action) => {
      switch (action) {
        case SitesAction.Edit:
          return openSiteDialog({ mode: XtMode.Edit, site: selectedSite })
        case SitesAction.View:
          return openSiteDialog({ mode: XtMode.View, site: selectedSite })
        case SitesAction.Delete:
          return openDialog(selectedSite)
        default:
          return null
      }
    },
    [openSiteDialog, openDialog]
  )

  const onSuccessfulSubmit = useCallback((): void => {
    void checkIsAnySiteSerial()
    void refresh()
  }, [checkIsAnySiteSerial, refresh])

  const handleDeletion = useCallback(async () => {
    try {
      closeDialog()
      const message = await ItemSitesService.deleteItemSite(item.item_number, itemId as string)
      await refresh()
      void checkIsAnySiteSerial()
      ToastService.showSuccess(message)
    } catch (e) {
      closeDialog()
      ErrorHandler.handleError(e)
    }
  }, [closeDialog, ItemSitesService, item.item_number, itemId, refresh, checkIsAnySiteSerial, ToastService, ErrorHandler])

  const onSitesRowClick = useCallback(({ site }: SiteTableItem) => openSiteDialog({ mode: isViewMode ? XtMode.View : XtMode.Edit, site }), [
    isViewMode,
    openSiteDialog,
  ])

  return (
    <div>
      <XtConfirmationDialog
        open={openDeleteDialog}
        message={confirmationMessages.deleted}
        title="Delete Site"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeDialog}
      />
      <XtDialog open={siteDialogOpen} animation={XtDialogAnimation.SlideAnimation} fullScreen={true} className="xt-modal-details-content">
        <SitesDetailsDialog
          site={siteDialogData.site}
          uomName={uom?.name}
          onClose={closeSiteDialog}
          mode={siteDialogData.mode}
          onSuccessfulSubmit={onSuccessfulSubmit}
          fractional={fractional}
          item={item}
        />
      </XtDialog>
      <XtResponsiveButton
        disabled={isViewMode || !canManageItemSites}
        icon={SvgIconIds.ADD_CIRCLE}
        label="New Item Site"
        onClick={() => openSiteDialog({ mode: XtMode.New, site: null })}
        className={styles.newButton}
      />
      <div className="xt-common-table-list">
        <XtList
          columns={SitesTabColumns}
          data={state.data}
          loading={state.loading}
          actions={canViewItemSites ? (isViewMode || !canManageItemSites ? SitesTabActionsViewMode : SitesTabActions) : undefined}
          onAction={handleAction}
          onRowClick={onSitesRowClick}
          pagination={pagination}
          sortOptions={state.sortOptions}
          onColumnHeaderClick={sort}
          isMobile={isMobile}
        />
      </div>
    </div>
  )
})
