import React, { Component, ErrorInfo, ReactNode } from 'react'
import * as styles from 'page-not-found/page-not-found.module.scss'

interface IProps {
  children?: ReactNode
}

interface IState {
  hasError: boolean
  error: string
}

export class ErrorBoundary extends Component<IProps, IState> {
  public state: IState = {
    hasError: false,
    error: '',
  }

  public static getDerivedStateFromError(_: Error): IState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: _.message }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className={styles.container}>
          <div className={styles.fof}>
            <h1>Error 404</h1>
            <p>{this.state.error}</p>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}
