import * as React from 'react'
import { TableCell } from '@material-ui/core'
import { PropsWithChildren } from 'react'
import { cls } from 'common/utils/utils'
import { IObjectWithId } from 'common/common.types'
import { definePositionStyles } from '../table.utils'
import { IXtTableColumn } from '../table-head/table-head.types'

const tableCellClasses = { root: cls('xt-table-cell', 'xt-table-cell-body') }

export interface IXtTableCellProps<TData extends IObjectWithId> {
  column: IXtTableColumn<TData>
  title?: string
  className?: string
}

export function XtTableCell<TData extends IObjectWithId>({
  column,
  title,
  className,
  children,
}: PropsWithChildren<IXtTableCellProps<TData>>): React.ReactElement {
  const { width, flex, height, sticky, align, id } = column

  return (
    <TableCell
      className={className}
      classes={tableCellClasses}
      title={title}
      component="div"
      style={{ width, flex, height, ...definePositionStyles(sticky) }}
      align={align}
      key={id}
      tabIndex={0}
    >
      {children}
    </TableCell>
  )
}
