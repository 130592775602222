import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IContactAddress, IPhone } from 'contacts/contacts.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { isEmptyResponse } from 'core/core.utils'
import { IHttpClient } from './http-client'
export interface ISiteContact {
  contact_number: string
  contact_honorific: string
  contact_first: string
  contact_middle: string
  contact_last: string
  contact_suffix: string
  contact_job_title: string
  contact_phones: IPhone[]
  contact_email: string
  contact_web: string
  contact_address: IContactAddress
}

export interface IDestinationWarehouse {
  destination_site: string
  destination_site_address: ISiteContact
  destination_site_contact: ISiteContact
}
export interface ISourceWarehouse {
  source_site: string
  source_site_address: ISiteContact
  source_site_contact: ISiteContact
}

export interface ITransferOrderItem {
  line_number: string
  line_status: string
  item_number: string
  item_description: string
  due_date: string
  scheduled_ship_date: string
  promised_date: string
  qty_ordered: number
  qty_shipped: number
  qty_received: number
  uom: string
  unit_cost: number
  freight: number
  line_notes: string
}

export interface ITransferOrder {
  order_number: string
  order_date: string
  status: string
  source_warehouse: ISourceWarehouse
  destination_warehouse: IDestinationWarehouse
  intransit_site: string
  agent: string
  ship_via: string
  freight_currency: string
  ship_complete: boolean
  notes: string
  shipping_notes: string
  pack_date: string
  project: string
  scheduled: string
  order_items: ITransferOrderItem[]
  created: string
  lastupdated: string
}

export interface ITransferOrderFilters {
  showClosed?: boolean
}

export type TransferOrderOption = ITransferOrder & IXtAutocompleteOption

export interface ITransferOrderService {
  getAll(pagination?: IPaginationParams, filters?: ITransferOrderFilters): Promise<IPaginationData<ITransferOrder>>
  get(number: string): Promise<ITransferOrder>
}

export class TransferOrderService implements ITransferOrderService {
  constructor(private readonly httpClient: IHttpClient) {}

  public async getAll(pagination?: IPaginationParams, filters?: ITransferOrderFilters): Promise<IPaginationData<ITransferOrder>> {
    const params = prepareRequestParams(pagination, filters)
    const {
      data: { data, status },
    } = await this.httpClient.get<IPaginationResponse<ITransferOrder>>('/transferorder', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(number: string): Promise<ITransferOrder> {
    const params = { showClosed: true }
    const { data } = await this.httpClient.get<IResponse<ITransferOrder>>(`/transferorder/${number}`, { params })

    if (isEmptyResponse(data)) {
      throw new Error(`Transfer Order with number: ${number} is not found.`)
    }

    return data.data
  }
}
