import type { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import type { IContactService } from 'contacts/contacts.service'
import type { ICompaniesContactsUtilsService } from './contacts-utils.service'
import { SharedModule } from 'shared/shared-module'
import { ContactsModule } from 'contacts/contacts.module'
import { AuthModule } from 'auth/auth.module'
import { DocumentsModule } from 'documents/documents.module'
import { Injector } from 'core/injector/injector.service'
import { contactsServiceToken } from 'contacts/contacts.constants'
import { Scope } from 'core/injector/injector.types'
import { companiesContactsModule, companiesContactsUtilsServiceToken } from './contacts.constants'

export type CompaniesContactsModuleDefinition = typeof import('./contacts.export')

export const CompaniesContactsModule: ILazyModule<CompaniesContactsModuleDefinition> = {
  name: companiesContactsModule,
  resolver: () => import('./contacts.export'),
  initializer: ({ ContactsUtilsService }) => {
    Injector.register<ICompaniesContactsUtilsService>(
      companiesContactsUtilsServiceToken,
      (injector) => {
        const contactsService = injector.resolve<IContactService>(contactsServiceToken)

        return new ContactsUtilsService(contactsService)
      },
      Scope.Singleton
    )
  },
  dependencies: [SharedModule, ContactsModule, AuthModule, DocumentsModule],
}
