import { IPhoneLinkState, PhoneAction, PhoneActionOption } from './phone-link.types'
import { SvgIconIds } from '../../../svg-icon/svg-icon.types'
import { IXtTableCellAction } from '../../../table/table-cell-actions/table-cell-actions'

export const initialPhoneState: IPhoneLinkState = {
  phone: '',
  open: false,
  action: null,
}

const messageAction: PhoneActionOption = {
  name: PhoneAction.Message,
  label: PhoneAction.Message,
  icon: SvgIconIds.CHAT,
}
const callAction: PhoneActionOption = {
  name: PhoneAction.Call,
  label: PhoneAction.Call,
  icon: SvgIconIds.PHONE,
}

export const definePhoneLinkActions = (
  showMessageAction: boolean,
  canMakeVoiceCalls: boolean,
  canSendSMS: boolean
): IXtTableCellAction[] => {
  const phoneActions: PhoneActionOption[] = []

  if (canMakeVoiceCalls) {
    phoneActions.push(callAction)
  }

  if (showMessageAction && canSendSMS) {
    phoneActions.push(messageAction)
  }

  return phoneActions
}
