import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { dateConverter } from 'common/utils/date.utils'
import { numberConverter } from 'common/utils/utils'
import { booleanColumnConverter } from 'common/utils/table.utils'
import { renderEmailCell } from 'components/controls/email/email-link/email-link.utils'
import {
  InventoryHistoryByLotSerialField,
  InventoryHistoryByLotSerialLabel,
  InventoryHistoryByLotSerialTableItem,
} from './inventory-history-by-lot-serial-list.types'

export const inventoryHistoryByLocationColumns: IXtTableColumn<InventoryHistoryByLotSerialTableItem>[] = [
  {
    id: InventoryHistoryByLotSerialField.Site,
    field: InventoryHistoryByLotSerialField.Site,
    headerName: InventoryHistoryByLotSerialLabel.Site,
    flex: '1 0 110px',
    width: 110,
  },
  {
    id: InventoryHistoryByLotSerialField.TransactionDate,
    field: InventoryHistoryByLotSerialField.TransactionDate,
    headerName: InventoryHistoryByLotSerialLabel.TransactionDate,
    flex: '1 0 140px',
    width: 140,
    converter: dateConverter,
  },
  {
    id: InventoryHistoryByLotSerialField.TransactionType,
    field: InventoryHistoryByLotSerialField.TransactionType,
    headerName: InventoryHistoryByLotSerialLabel.TransactionType,
    flex: '1 0 120px',
    width: 120,
  },
  {
    id: InventoryHistoryByLotSerialField.OrderNumber,
    field: InventoryHistoryByLotSerialField.OrderNumber,
    headerName: InventoryHistoryByLotSerialLabel.OrderNumber,
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: InventoryHistoryByLotSerialField.ItemNumber,
    field: InventoryHistoryByLotSerialField.ItemNumber,
    headerName: InventoryHistoryByLotSerialLabel.ItemNumber,
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: InventoryHistoryByLotSerialField.LotSerial,
    field: InventoryHistoryByLotSerialField.LotSerial,
    headerName: InventoryHistoryByLotSerialLabel.LotSerial,
    flex: '1 0 140px',
    width: 140,
  },
  {
    id: InventoryHistoryByLotSerialField.Perishable,
    field: InventoryHistoryByLotSerialField.Perishable,
    headerName: InventoryHistoryByLotSerialLabel.Perishable,
    flex: '1 0 140px',
    width: 140,
    converter: booleanColumnConverter,
  },
  {
    id: InventoryHistoryByLotSerialField.ExpiryDate,
    field: InventoryHistoryByLotSerialField.ExpiryDate,
    headerName: InventoryHistoryByLotSerialLabel.ExpiryDate,
    flex: '1 0 140px',
    width: 140,
    converter: dateConverter,
  },
  {
    id: InventoryHistoryByLotSerialField.Uom,
    field: InventoryHistoryByLotSerialField.Uom,
    headerName: InventoryHistoryByLotSerialLabel.Uom,
    flex: '1 0 100px',
    width: 100,
  },
  {
    id: InventoryHistoryByLotSerialField.TransactionQty,
    field: InventoryHistoryByLotSerialField.TransactionQty,
    headerName: InventoryHistoryByLotSerialLabel.TransactionQty,
    flex: '1 0 130px',
    width: 130,
    converter: numberConverter,
  },
  {
    id: InventoryHistoryByLotSerialField.QtyBefore,
    field: InventoryHistoryByLotSerialField.QtyBefore,
    headerName: InventoryHistoryByLotSerialLabel.QtyBefore,
    flex: '1 0 140px',
    width: 140,
    converter: numberConverter,
  },
  {
    id: InventoryHistoryByLotSerialField.QtyAfter,
    field: InventoryHistoryByLotSerialField.QtyAfter,
    headerName: InventoryHistoryByLotSerialLabel.QtyAfter,
    flex: '1 0 130px',
    width: 130,
    converter: numberConverter,
  },
  {
    id: InventoryHistoryByLotSerialField.CustomerInfo,
    field: InventoryHistoryByLotSerialField.CustomerInfo,
    headerName: InventoryHistoryByLotSerialLabel.CustomerInfo,
    flex: '1 0 210px',
    width: 210,
  },
  {
    id: InventoryHistoryByLotSerialField.ShipToName,
    field: InventoryHistoryByLotSerialField.ShipToName,
    headerName: InventoryHistoryByLotSerialLabel.ShipToName,
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: InventoryHistoryByLotSerialField.ShipToAddress1,
    field: InventoryHistoryByLotSerialField.ShipToAddress1,
    headerName: InventoryHistoryByLotSerialLabel.ShipToAddress1,
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: InventoryHistoryByLotSerialField.ShipToAddress2,
    field: InventoryHistoryByLotSerialField.ShipToAddress2,
    headerName: InventoryHistoryByLotSerialLabel.ShipToAddress2,
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: InventoryHistoryByLotSerialField.ShipToAddress3,
    field: InventoryHistoryByLotSerialField.ShipToAddress3,
    headerName: InventoryHistoryByLotSerialLabel.ShipToAddress3,
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: InventoryHistoryByLotSerialField.CityStatePostal,
    field: InventoryHistoryByLotSerialField.CityStatePostal,
    headerName: InventoryHistoryByLotSerialLabel.CityStatePostal,
    flex: '1 0 170px',
    width: 170,
  },
  {
    id: InventoryHistoryByLotSerialField.Country,
    field: InventoryHistoryByLotSerialField.Country,
    headerName: InventoryHistoryByLotSerialLabel.Country,
    flex: '1 0 120px',
    width: 120,
  },
  {
    id: InventoryHistoryByLotSerialField.Contact,
    field: InventoryHistoryByLotSerialField.Contact,
    headerName: InventoryHistoryByLotSerialLabel.Contact,
    flex: '1 0 140px',
    width: 140,
  },
  {
    id: InventoryHistoryByLotSerialField.ContactPhone,
    field: InventoryHistoryByLotSerialField.ContactPhone,
    headerName: InventoryHistoryByLotSerialLabel.ContactPhone,
    flex: '1 0 180px',
    width: 180,
  },
  {
    id: InventoryHistoryByLotSerialField.ContactFax,
    field: InventoryHistoryByLotSerialField.ContactFax,
    headerName: InventoryHistoryByLotSerialLabel.ContactFax,
    flex: '1 0 180px',
    width: 180,
  },
  {
    id: InventoryHistoryByLotSerialField.ContactEmail,
    field: InventoryHistoryByLotSerialField.ContactEmail,
    headerName: InventoryHistoryByLotSerialLabel.ContactEmail,
    flex: '1 0 200px',
    width: 200,
    renderCell: (item) => renderEmailCell(item?.contact_email),
  },
  {
    id: InventoryHistoryByLotSerialField.Phone,
    field: InventoryHistoryByLotSerialField.Phone,
    headerName: InventoryHistoryByLotSerialLabel.Phone,
    flex: '1 0 180px',
    width: 180,
  },
]

export const historyByLotSerialPageTitle = 'xTuple - Inventory History By Lot/Serial'
