import type { IXtMode } from 'common/common.types'
import type { IContact } from 'contacts/contacts.types'
import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useContactsModule } from 'contacts/contacts-module-hook'
import { companiesRoutePath, companiesRoutes } from 'companies/companies.constants'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { getContactDetailsPageTitle } from './contacts.constants'

export const ContactsDetailsPage: FC<IXtMode> = ({ mode }) => {
  const { ContactsService, ContactDialogForm } = useContactsModule()
  const [contactNotFound, setContactNotFound] = useState(false)
  const { contactNumber } = useParams<{ contactNumber: string }>()
  const [contactState, setContactState] = useState<{ loading: boolean; contactData: IContact | null }>({
    loading: false,
    contactData: null,
  })
  useDocumentTitle(getContactDetailsPageTitle(contactNumber))
  const init = async (): Promise<void> => {
    try {
      if (contactNumber) {
        setContactState((prev) => ({ ...prev, loading: true }))
        const contactData = await ContactsService.get(contactNumber)
        setContactState({ contactData, loading: false })
      }
      setContactNotFound(false)
    } catch (error) {
      setContactNotFound(true)
    }
  }

  const history = useHistory()

  useEffect(() => void init(), [contactNumber])
  useEffect(() => {
    if (contactNotFound) throw Error(`Contact ${contactNumber} not found.`)
  }, [contactNotFound])
  const onCancel: VoidFunction = () => history.push(`${companiesRoutePath}/${companiesRoutes.contacts}`)

  return (
    <div className="xt-content">
      <ContactDialogForm
        serverSideUpdate
        loading={contactState.loading}
        contact={contactState.contactData}
        mode={mode}
        onClose={onCancel}
      />
    </div>
  )
}
