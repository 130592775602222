import { globalConstants } from 'common/constants'
import { NumberTypeUtils } from 'common/utils/type.utils'
import { IssueMethod } from 'common/common.types'
import { IUom } from 'core/services/uom.service'
import { dateToServerFormat, formatDate, parseServerDate, parseServerDateWithStringValue } from 'common/utils/date.utils'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { IItem } from 'products/items/items.types'
import { minCounterValue } from './bom-item-counter/bom-item-counter'
import { BomItemFormField, BomItemMode, IBomItemForm } from './bom-item.types'
import { BomItemCreateRequest, IBomItem } from '../bom.types'

export const isStringDateRepresentation: (date: string) => boolean = (date) => date === 'Always' || date === 'Never'

export const formatFormDate: (date: string) => string | Date = (date: string) => {
  if (!isStringDateRepresentation(date)) {
    return formatDate(parseServerDate(date), globalConstants.dateFormat)
  }
  return date
}

export function addLabelToItem(item: IItem | null): IItem | null {
  return item && !item.label ? { ...item, label: item.item_number ? item.item_number : '' } : null
}

export function defineFormState(data: IBomItem | null): IBomItemForm {
  // TODO need add item_type to this api
  return {
    [BomItemFormField.SequenceNumber]: data?.sequence_number ?? minCounterValue,
    [BomItemFormField.Item]: addLabelToItem(data?.item ?? null),
    [BomItemFormField.Effective]: parseServerDateWithStringValue(data?.effective),
    [BomItemFormField.Expires]: parseServerDateWithStringValue(data?.expires),
    [BomItemFormField.ScheduleOperation]: data?.schedule_at_wo_operation || false,
    [BomItemFormField.CreateChild]: data?.create_child_wo || false,
    [BomItemFormField.IssueChild]: false, // TODO need add value to api
    [BomItemFormField.FixedQty]: data?.qty_fxd ?? 0,
    [BomItemFormField.QtyPer]: data?.qty_per ?? 0,
    [BomItemFormField.IssueUom]: defineAutocompleteOption(data?.item.inventory_uom_name),
    [BomItemFormField.IssueMethod]: defineAutocompleteOption(data?.issue_method ?? IssueMethod.Mixed),
    [BomItemFormField.EcnNumber]: data?.ecn_number ?? '',
    [BomItemFormField.Scrap]: data?.scrap ?? 0,
    [BomItemFormField.UsedAt]: data?.used_at ? `${data?.used_at}-${data?.used_at_description}` : '',
  }
}

export function convertToBomItem(
  bomItem: IBomItem | null,
  formState: IBomItemForm,
  notes: string = '',
  reference: string = ''
): BomItemCreateRequest {
  const { effective, expires, scrap, qty_per, qty_fxd, used_at, item, issue_method, issue_uom, ...other } = formState
  if (!item) {
    throw new Error('Bom Item controls exception: no item has been selected!')
  }
  const [usedAt, usedAtDescription] = used_at ? used_at.split('-') : [null, null]

  //New_sequence_number is added to the object as a prop only when sequence_number is updated
  const new_sequence_number: number | null = bomItem?.sequence_number !== formState?.sequence_number ? formState?.sequence_number : null

  return {
    ...(bomItem ?? {}),
    effective: dateToServerFormat(effective, globalConstants.dateStringRepresentation.always),
    expires: dateToServerFormat(expires, globalConstants.dateStringRepresentation.never),
    scrap: NumberTypeUtils.parseString(scrap),
    qty_per: NumberTypeUtils.parseString(qty_per),
    qty_fxd: NumberTypeUtils.parseString(qty_fxd),
    used_at: usedAt,
    used_at_description: usedAtDescription,
    item,
    bom_item_number: item?.item_number,
    bom_item_inventory_uom: item?.inventory_uom_name ?? '',
    issue_method: issue_method?.id ?? '',
    issue_uom: issue_uom?.id ?? null,
    ...other,
    reference,
    notes,
    new_sequence_number,
  }
}

export function convertBomItemMode(
  mode: BomItemMode
): { isNewMode: boolean; isViewMode: boolean; isReplaceMode: boolean; isEditMode: boolean } {
  return {
    isNewMode: mode === BomItemMode.Search,
    isViewMode: mode === BomItemMode.View,
    isReplaceMode: mode === BomItemMode.Replace,
    isEditMode: mode === BomItemMode.Edit,
  }
}

function defineUomConversions(inventoryUom: IUom | null | undefined, itemInventoryUom: string | null | undefined): string[] {
  if (!inventoryUom) {
    // We should add the selected UOM in case it's missed in options array
    return itemInventoryUom ? [itemInventoryUom] : []
  }
  const uomConversions = inventoryUom.uom_conversions

  const uomOptions = [
    ...uomConversions.filter(({ active }) => active).map(({ uom_to, uom_from }) => (uom_to === inventoryUom.name ? uom_from : uom_to)),
    inventoryUom.name,
  ]

  // We should add the selected UOM in case it's missed in options array
  if (itemInventoryUom && !uomOptions.includes(itemInventoryUom)) {
    uomOptions.push(itemInventoryUom)
  }

  return uomOptions
}

export function convertUomConversions(
  inventoryUom: IUom | null | undefined,
  itemInventoryUom: string | null | undefined
): IXtAutocompleteOption[] {
  return defineUomConversions(inventoryUom, itemInventoryUom).map((uomConversion) => defineAutocompleteOption(uomConversion))
}

export const getItemInputLabel = (option: IXtAutocompleteOption | null) => {
  return option?.label ?? option?.id.toString() ?? ''
}
