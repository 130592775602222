import React, { ChangeEvent, FC, SyntheticEvent, useRef } from 'react'
import { IconButton, TextField as TextInput } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { BehaviorSubject } from 'rxjs'
import ClearIcon from '@material-ui/icons/Clear'
import { isAutocompleteValue, renderAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { cls } from 'common/utils/utils'
import { IXtAutocompleteOption } from '../xt-autocomplete/xt-autocomplete.types'
import * as styles from './xt-select.module.scss'
import { convertToAutocompleteValue } from './xt-select.utils'

interface IXtAutocompleteOptionNew extends IXtAutocompleteOption {
  _xtSelectCreatableOptionNew: true
}

export type XtSelectCreatableOptionChange = IXtAutocompleteOption | IXtAutocompleteOptionNew | null

function defineNewOption(id: string): IXtAutocompleteOptionNew {
  return { id, label: id, _xtSelectCreatableOptionNew: true }
}

export function isNewAutocompleteOption(option: IXtAutocompleteOption): boolean {
  return option.hasOwnProperty('_xtSelectCreatableOptionNew')
}

export interface IXtSelectCreatableProps {
  label: string
  value: IXtAutocompleteOption | null
  disabled?: boolean
  className?: string
  error?: string
  hidden?: boolean
  options: IXtAutocompleteOption[]
  endAdornment?: React.ReactElement
  onBlur?(): void
  renderOption?(option: IXtAutocompleteOption): React.ReactElement
  onChange(value: XtSelectCreatableOptionChange): void
  getInputLabel?: (option: IXtAutocompleteOption | null) => string
}

function isInputValue(value: unknown): value is { inputValue: string } {
  return typeof value === 'object' && !!value && 'inputValue' in value
}

interface IEndAdornmentParams {
  clearIconVisible: boolean
  endAdornment?: React.ReactElement
  onClearIconClick(event: SyntheticEvent): void
}

const EndAdornment: FC<IEndAdornmentParams> = ({ endAdornment, onClearIconClick, clearIconVisible }) => (
  <div className={styles.xtSelectCreatableEndAdornment}>
    <IconButton hidden={!clearIconVisible} className="xtSelectCreatableEndAdornmentIcon" title="Clear" onClick={onClearIconClick}>
      <ClearIcon />
    </IconButton>
    {endAdornment}
  </div>
)

function getOptionLabel(option: IXtAutocompleteOption | null): string {
  return option?.label ?? ''
}

export const XtSelectCreatable: FC<IXtSelectCreatableProps> = ({
  label,
  value,
  disabled,
  options,
  onChange,
  className,
  error,
  hidden,
  onBlur,
  renderOption = renderAutocompleteOption,
  endAdornment,
  getInputLabel = getOptionLabel,
}) => {
  const inputValueSubjectRef = useRef<BehaviorSubject<string | null>>(new BehaviorSubject<string | null>(value?.id ?? null))

  const selectValue = convertToAutocompleteValue(value)

  const handleChange: (event: ChangeEvent<{}>, newValue: unknown) => void = (_, newValue) => {
    if (typeof newValue === 'string') {
      onChange(defineNewOption(newValue))
    }
    if (isInputValue(newValue)) {
      onChange(defineNewOption(newValue.inputValue))
    }
    if (isAutocompleteValue(newValue) || newValue === null) {
      onChange(newValue)
    }
    inputValueSubjectRef.current.next(null)
  }

  const handleBlur: VoidFunction = () => {
    const currentInputValue = inputValueSubjectRef.current.value
    if (currentInputValue !== null) {
      onChange(defineNewOption(currentInputValue))
      inputValueSubjectRef.current.next(null)
    }
    if (typeof onBlur === 'function') {
      onBlur()
    }
  }

  const onClearIconClick: (event: SyntheticEvent) => void = (event) => {
    onChange(null)
    event.stopPropagation()
    if (typeof onBlur === 'function') {
      onBlur()
    }
    inputValueSubjectRef.current.next(null)
  }

  return (
    <Autocomplete
      disabled={disabled}
      hidden={hidden}
      className={cls('xtSelectCreatable', className)}
      value={selectValue}
      onBlur={handleBlur}
      onChange={handleChange}
      selectOnFocus
      clearOnBlur={false}
      handleHomeEndKeys
      options={options}
      getOptionLabel={getInputLabel}
      renderOption={renderOption}
      freeSolo
      renderInput={(params) => (
        <TextInput
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <EndAdornment clearIconVisible={!disabled && !!value} endAdornment={endAdornment} onClearIconClick={onClearIconClick}>
                {params.InputProps.endAdornment}
              </EndAdornment>
            ),
          }}
          label={label}
          variant="outlined"
          error={!!error}
          helperText={error}
          onChange={({ target: { value: inputValue } }) => inputValueSubjectRef.current.next(inputValue)}
        />
      )}
    />
  )
}
