import type { IPaginationData, IPaginationParams } from 'common/common.types'
import type { IKanbanOpportunitiesFilter, KanbanOpportunitiesFilterPanel } from './opportunities-kanban.types'
import type { IKanbanOpportunityCard, IKanbanOpportunityColumn } from './opportunity-board/opportunities-board.types'
import type { IOpportunitiesKanbanService } from './opportunities-kanban.service'
import { convertFiltersPanelState } from 'common/utils/filter.utils'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { convertToKanbanColumn } from './opportunity-board/opportunities-board.utils'

export interface IOpportunitiesKanbanUtilsService {
  fetchOpportunityCards(
    stage: string,
    _: IPaginationParams,
    filters?: IKanbanOpportunitiesFilter
  ): Promise<IPaginationData<IKanbanOpportunityCard>>
  requestColumns(filters: KanbanOpportunitiesFilterPanel): Promise<IKanbanOpportunityColumn[]>
  fetchOpportunityColumn(stage: string, filters?: IKanbanOpportunitiesFilter): Promise<IKanbanOpportunityColumn>
}

export class OpportunitiesKanbanUtilsService implements IOpportunitiesKanbanUtilsService {
  constructor(private readonly opportunitiesKanbanService: IOpportunitiesKanbanService) {
    bindAllInstanceMethods(this)
  }

  public async fetchOpportunityCards(
    stage: string,
    _: IPaginationParams,
    filters?: IKanbanOpportunitiesFilter
  ): Promise<IPaginationData<IKanbanOpportunityCard>> {
    const { data } = await this.fetchOpportunityColumn(stage, filters)

    return {
      data,
      total: data.length,
    }
  }

  public async requestColumns(filters: KanbanOpportunitiesFilterPanel): Promise<IKanbanOpportunityColumn[]> {
    const processedFilters = convertFiltersPanelState(filters)
    const opportunityStages = await this.opportunitiesKanbanService.getOpportunities(processedFilters)

    return opportunityStages.map(convertToKanbanColumn)
  }

  public async fetchOpportunityColumn(stage: string, filters?: IKanbanOpportunitiesFilter): Promise<IKanbanOpportunityColumn> {
    const opportunityStage = await this.opportunitiesKanbanService.getOpportunityByStage(stage, filters)

    return convertToKanbanColumn(opportunityStage)
  }
}
