import * as React from 'react'
import { FC } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useTable } from 'common/hooks/useTable'
import { cls } from 'common/utils/utils'
import { XtList } from 'components/list/list'
import { LoaderMessage, xsMq } from 'common/constants'
import { useInventoryHistoryModule } from 'inventory/inventory-history-section/inventory-history-module-hook'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { InventoryHistoryByLocationListForm } from './inventory-history-by-location-list-form/inventory-history-by-location-list-form'
import * as inventoryStyles from './inventory-history-by-location-list.module.scss'
import { inventoryHistoryByLocationColumns, inventoryHistoryByLocationPageTitle } from './inventory-history-by-location-list.constansts'

export const InventoryHistoryByLocationList: FC = () => {
  const isMobile = useMediaQuery(xsMq)
  const { CostingItemCostsUtilsService } = useInventoryHistoryModule()
  useDocumentTitle(inventoryHistoryByLocationPageTitle)
  const {
    state: { data, loading, sortOptions },
    filter,
    pagination,
    sort,
  } = useTable({}, CostingItemCostsUtilsService.getInventoryHistoryByLocation)

  return (
    <div className={cls('xt-list-page-container', inventoryStyles.listContent)}>
      <div className="xt-page-header">
        <h1 className="xt-page-title">Inventory History by Location</h1>
      </div>
      <InventoryHistoryByLocationListForm onChange={filter} />
      <XtList
        isMobile={isMobile}
        pagination={pagination}
        loading={loading}
        data={data}
        columns={inventoryHistoryByLocationColumns}
        sortOptions={sortOptions}
        onColumnHeaderClick={sort}
        loadMessage={LoaderMessage.InventoryHistoryLocation}
      />
    </div>
  )
}
