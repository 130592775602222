import { parseAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { CostedBomSummaryFilter, CostedBomSummaryFilters } from 'products/items/items.types'

import { FormField, ISummarizedForm } from './summarized-list-form.types'
import { CostsOptionField, FormSharedField } from '../../shared/shared.types'

export function convertFormDataToCostedBomFilters(formData: ISummarizedForm): CostedBomSummaryFilters {
  const standardCost = formData[FormSharedField.CostsOption] === CostsOptionField.Standard ? true : undefined
  const actualCost = formData[FormSharedField.CostsOption] === CostsOptionField.Actual ? true : undefined
  const expiredDays = formData[FormField.ShowExpiredComponents] ? formData[FormField.ThresholdWithExpiredDays] : undefined
  const futureDays = formData[FormField.ShowFutureComponents] ? formData[FormField.ThresholdWithFutureDays] : undefined

  return {
    [CostedBomSummaryFilter.ItemNumber]: parseAutocompleteOption(formData[FormSharedField.ItemDetails]),
    [CostedBomSummaryFilter.RevisionNumber]: formData[FormSharedField.Revision]?.id,
    [CostedBomSummaryFilter.UseStandardCosts]: standardCost,
    [CostedBomSummaryFilter.UseActualCosts]: actualCost,
    [CostedBomSummaryFilter.ExpiredDays]: expiredDays,
    [CostedBomSummaryFilter.FutureDays]: futureDays,
  }
}
