import { FormRadioGroupOptions } from 'components/controls/xt-radio-group/xt-radio-group'
import {
  IInventoryAdjustmentFormState,
  InventoryAdjustmentField,
  InventoryAdjustmentLabel,
  InventoryAdjustmentMethod,
} from './inventory-adjustment-details.types'
import { InventoryAdjustmentCostType } from './inventory-adjustment-cost/inventory-adjustment-cost.types'

export const defaultInventoryAdjustmentMeta = { before: 0, after: 0 }

export const initialInventoryAdjustmentFormState: IInventoryAdjustmentFormState = {
  [InventoryAdjustmentField.TransactionDate]: new Date(),
  [InventoryAdjustmentField.DistributionQty]: 0,
  [InventoryAdjustmentField.Item]: null,
  [InventoryAdjustmentField.Description]: '',
  [InventoryAdjustmentField.Uom]: '',
  [InventoryAdjustmentField.Site]: null,
  [InventoryAdjustmentField.Method]: InventoryAdjustmentMethod.Relative,
  [InventoryAdjustmentField.CostType]: InventoryAdjustmentCostType.Calculated,
  [InventoryAdjustmentField.Document]: '',
  [InventoryAdjustmentField.Adjust]: true,
  [InventoryAdjustmentField.TotalCost]: 0,
  [InventoryAdjustmentField.UnitCost]: 0,
  [InventoryAdjustmentField.Notes]: '',
}

export const inventoryAdjustmentOptions: FormRadioGroupOptions = [
  { label: InventoryAdjustmentMethod.Absolute, id: InventoryAdjustmentMethod.Absolute },
  { label: InventoryAdjustmentMethod.Relative, id: InventoryAdjustmentMethod.Relative },
]

export const inventoryAdjustmentPageTitle = 'xTuple - Inventory Adjustment'

export const AdditionalFieldsWithInput = [
  {
    label: InventoryAdjustmentLabel.Item,
    name: InventoryAdjustmentField.Item,
  },
  {
    label: InventoryAdjustmentLabel.Description,
    name: InventoryAdjustmentField.Description,
  },
  {
    label: InventoryAdjustmentLabel.Uom,
    name: InventoryAdjustmentField.Uom,
  },
]
