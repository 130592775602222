import { IContact, ContactOption, IPhone, PhoneRole, ContactLightweight } from './contacts.types'

export function buildFullContactName<Contact extends ContactLightweight>(contact: Contact | null): string {
  return contact ? [contact.honorific, contact.first_name, contact.middle_name, contact.last_name].filter(Boolean).join(' ') || 'N/A' : ''
}

export function convertToContactOption<Contact extends ContactLightweight>(contact: ContactLightweight): ContactOption<Contact> {
  return { ...contact, id: buildFullContactName(contact), label: buildFullContactName(contact) } as ContactOption<Contact>
}

export function retrievePhoneByRole(phones: IPhone[], role: PhoneRole): string {
  if (!Array.isArray(phones)) {
    return ''
  }
  const requiredPhone = phones.find((phone) => phone.role === role)
  return requiredPhone ? requiredPhone.number : ''
}

export function retrievePhone(contact: IContact | undefined | null, phoneRole: PhoneRole): string {
  if (!contact || !Array.isArray(contact?.phones)) {
    return ''
  }
  const phone = contact.phones.find(({ role }) => role === phoneRole)
  return phone?.number ?? ''
}

export function convertContactOption(contact: IContact | null | undefined): ContactOption<IContact> | null {
  return contact ? { ...contact, id: contact.contact_number, label: buildFullContactName(contact) } : null
}
