import { PermissionGuard } from 'auth/permission.guard'
import { UserPermission } from 'auth/auth.types'
import { productsRoutePath } from 'products/products.constants'

export class ItemCostsGuard extends PermissionGuard {
  public redirectTo = productsRoutePath
  public permissions = [UserPermission.ViewCosts]
}

export class InventoryHistoryGuard extends PermissionGuard {
  public redirectTo = productsRoutePath
  public permissions = [UserPermission.ViewInventoryHistory]
}
