import React from 'react'
import { CircularProgress } from '@material-ui/core'

const loadingSpinnerSize = 15

interface ILoader {
  text?: string
  className?: string
  size?: number
}

export function LoadingSpinner({ text = 'Loading...', className, size = loadingSpinnerSize }: ILoader): JSX.Element {
  return (
    <div style={{ display: 'grid', justifyContent: 'center', marginTop: '20%' }} className={className}>
      <div style={{ display: 'grid', justifyContent: 'center' }}>
        {' '}
        <CircularProgress size={size} />
      </div>
      <div style={{ marginTop: '.4em' }}>{text}</div>
    </div>
  )
}
