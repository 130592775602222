import { DeepPartial, get, set, UnpackNestedValue, useForm, UseFormReset } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types'
import { DefaultValues, KeepStateOptions, UseFormTrigger } from 'react-hook-form/dist/types/form'
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs'
import { FieldPath } from 'react-hook-form/dist/types/utils'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import { distinctUntilChanged, map } from 'rxjs/operators'
import { buildFormStateObservable, validationResolver } from './form.utils'
import { IFormHook, IFormProperties, IFormStateBase, IFormStateChanges, IXtFormControl } from './form.types'

export function useXtForm<TFieldValues extends FieldValues>({
  mode,
  defaultValues,
  validationSchema,
}: IFormProperties<TFieldValues>): IFormHook<TFieldValues> {
  const [_, forceUpdate] = useReducer((counter: number) => counter + 1, 0)

  const disabledRef = useRef<boolean>(false)
  const errorsSubjectRef = useRef<Subject<FieldErrors<TFieldValues>>>(new Subject())

  const methods = useForm<TFieldValues>({
    mode,
    defaultValues: defaultValues as UnpackNestedValue<DeepPartial<TFieldValues>>,
    resolver: validationSchema ? validationResolver(validationSchema, disabledRef) : undefined,
  })

  const [fieldValidatorsShown, setFieldValidatorsShown] = useState<boolean>(false)
  const [touched, setTouched] = useState<boolean>(false)

  const { formState, watch, trigger: formTrigger, reset: formReset, getValues, control: formControl } = methods

  const formStateSubject = useRef<BehaviorSubject<IFormStateBase>>(
    new BehaviorSubject<IFormStateBase>({ isDirty: formState.isDirty, isValid: formState.isValid })
  )
  const touchedSubject = useRef<BehaviorSubject<boolean>>(new BehaviorSubject<boolean>(false))
  const fieldValidatorsShownSubject = useRef<BehaviorSubject<boolean>>(new BehaviorSubject<boolean>(false))
  const formValueSubject = useRef<Subject<TFieldValues>>(new Subject<TFieldValues>())

  const formChanges$ = useMemo<Observable<IFormStateChanges<TFieldValues>>>(
    () =>
      buildFormStateObservable(
        formStateSubject.current,
        touchedSubject.current,
        formValueSubject.current,
        fieldValidatorsShownSubject.current,
        getValues
      ),
    [getValues]
  )

  const formValueChanges$ = useMemo(() => formValueSubject.current.asObservable(), [])

  /**
   * Used to convert React Hook Form internal state subject to RxJs Subject, so we can subscribe to "isValid" state changes for a specific control
   */
  useEffect(() => {
    const sub = formControl.formStateSubjectRef.current.subscribe({
      next: ({ errors }) => {
        if (errors !== undefined) {
          errorsSubjectRef.current.next(errors)
        }
      },
    })

    return () => sub.unsubscribe()
  }, [formControl.formStateSubjectRef])

  useEffect(() => {
    const sub = new Subscription()

    sub.add(touchedSubject.current.subscribe((value) => setTouched(value)))
    sub.add(fieldValidatorsShownSubject.current.subscribe((value) => setFieldValidatorsShown(value)))

    return () => sub.unsubscribe()
  }, [])

  useEffect(() => {
    const { isDirty, isValid: isValidState, touchedFields, errors } = formState
    const isValid = disabledRef.current || isValidState // TODO find a better way to handle controls validation if controls is disabled

    formStateSubject.current.next({ isDirty, isValid })

    const formTouched = !!Object.keys(touchedFields).length
    const fieldValidatorsShownValue = formTouched && !!Object.keys(errors).length

    touchedSubject.current.next(formTouched)
    fieldValidatorsShownSubject.current.next(fieldValidatorsShownValue)
  }, [formState])

  useEffect(() => {
    const watchSub = watch((value) => {
      formValueSubject.current.next(value as TFieldValues)
    })

    return () => {
      watchSub.unsubscribe()
    }
  }, [watch])

  const trigger = useCallback<UseFormTrigger<TFieldValues>>(
    async (name?: FieldPath<TFieldValues> | FieldPath<TFieldValues>[]) => {
      touchedSubject.current.next(true) // we should mark the controls as touched
      const isValidForm = await formTrigger(name)

      if (!isValidForm) {
        fieldValidatorsShownSubject.current.next(true)
        // We should mark invalid controls as Touched in order to display validation
        const { errors } = formControl.formStateRef.current
        Object.keys(errors).forEach((path) => {
          set(formControl.formStateRef.current.touchedFields, path, true)
        })
        formControl.formStateSubjectRef.current.next({
          ...formControl.formStateRef.current,
          touchedFields: formControl.formStateRef.current.touchedFields,
        })
      }

      return isValidForm
    },
    [formTrigger, formControl.formStateRef, formControl.formStateSubjectRef]
  )

  const reset = useCallback<UseFormReset<TFieldValues>>(
    (values?: DefaultValues<TFieldValues>, keepStateOptions?: KeepStateOptions) => {
      if (!keepStateOptions?.keepTouched) {
        touchedSubject.current.next(false) // we should mark the controls as untouched
      }
      formReset(values, keepStateOptions)
      // We have to force React Render to make React Hook Form works correctly with memoized components.
      // https://github.com/react-hook-form/react-hook-form/issues/7607#issuecomment-1018097748
      forceUpdate()
    },
    [formReset]
  )

  const markAsTouched = useCallback<VoidFunction>(() => touchedSubject.current.next(true), [])

  // TODO we should handle disabled state changes for every control in the controls, so we can remove a control from the validation schema.
  const setDisabled = useCallback<(disabled: boolean) => void>((disabled) => {
    disabledRef.current = disabled
  }, [])

  const validate = useCallback<(path: FieldPath<TFieldValues>) => Promise<boolean>>(
    async (path) => {
      const resolver = validationSchema ? validationResolver(validationSchema, disabledRef) : null
      const fieldPath = path.toString()
      const field = formControl.fieldsRef.current[fieldPath]
      if (!resolver || !field) {
        return false
      }
      const controlValue = getValues(path)
      const value = { path: controlValue }
      const fields = { [fieldPath]: field._f }
      const errors = await resolver(value, undefined, { fields })
      const error = get(errors, path)
      return !error
    },
    [validationSchema, formControl.fieldsRef, getValues]
  )

  const control = useMemo<IXtFormControl<TFieldValues>>(
    () => ({
      ...formControl,
      validate,
      subscribeToStateChanges: (name) =>
        errorsSubjectRef.current.asObservable().pipe(
          map((errors) => ({ isValid: !Object.prototype.hasOwnProperty.call(errors, name) })),
          distinctUntilChanged()
        ),
      getValue: (path) => getValues(path),
    }),
    [formControl, validate, getValues]
  )

  const isInvalid = useCallback<(path: FieldPath<TFieldValues>) => boolean>((path) => !!formState.errors[path], [formState.errors])

  const isTouched = useCallback<(path: FieldPath<TFieldValues>) => boolean>((path) => !!formState.touchedFields[path], [
    formState.touchedFields,
  ])

  return {
    ...methods,
    control,
    trigger,
    reset,
    formState: { ...formState, touched, fieldValidatorsShown },
    markAsTouched,
    formChanges$,
    formValueChanges$,
    setDisabled,
    isInvalid,
    isTouched,
  }
}
