import { useEffect, useState } from 'react'
import { XtInfoValue } from 'components/xt-info-values/xt-info-values.types'
import { OrderType } from 'core/core.types'
import { IShipmentData, IShipmentFilters, ShipmentDataOption } from 'shipments/shipments.types'
import { IFormHook } from 'common/hooks/form/form.types'
import { useSalesOrdersModule } from 'sales-orders/sales-orders-module-hook'
import { useShipmentsModule } from 'shipments/shipments-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { ISalesOrder } from 'sales-shared/sales-orders.types'
import { ITransferOrder } from 'core/services/transfer-order.service'
import { useXtAutocomplete } from 'components/controls/xt-autocomplete/xt-autocomplete-hook'
import { defineShipmentOption } from 'shipments/shipments.utils'
import { IPrintShippingFormHook } from './print-shipping-form-hook.types'
import { IPrintShippingFormSharedState, ShippingFormOrderOption, ShippingPrintFormSharedField } from '../print-dialogs.types'

export function usePrintShippingForm<FormState extends IPrintShippingFormSharedState>(
  formMethods: IFormHook<FormState>,
  resolveSalesOrderValues: (data: ISalesOrder) => XtInfoValue[],
  resolveTransferOrderValues: (data: ITransferOrder) => XtInfoValue[],
  resolveShipmentInfoValues: (data: IShipmentData) => XtInfoValue[] | null,
  defaultShipmentFilters?: IShipmentFilters,
  defaultShipmentNumber?: string
): IPrintShippingFormHook {
  const { SalesOrdersService } = useSalesOrdersModule()
  const { ShipmentsService, ShipmentsUtilsService } = useShipmentsModule()
  const { ErrorHandler, TransferOrderService } = useCoreModule()

  const [orderValues, setOrderValues] = useState<XtInfoValue[]>([])
  const [shipmentValues, setShipmentValues] = useState<XtInfoValue[] | null>(null)

  // TS definitions are too deep to directly cast formMethods to IPrintShippingFormSharedState, so unknown type is used
  const { setValue, getValues } = (formMethods as unknown) as IFormHook<IPrintShippingFormSharedState>

  const shipmentsState = useXtAutocomplete(ShipmentsUtilsService.loadShipmentOptions)

  const { filter: filterShipments } = shipmentsState

  const resetOrderValues = async (orderNumber: string, orderType: OrderType): Promise<void> => {
    try {
      if (orderType === OrderType.SalesOrder) {
        const data = await SalesOrdersService.get(orderNumber)

        setOrderValues(resolveSalesOrderValues(data))
      } else {
        const data = await TransferOrderService.get(orderNumber)
        setOrderValues(resolveTransferOrderValues(data))
      }
    } catch (e) {
      ErrorHandler.handleError(e)
      setOrderValues([])
    }
  }

  const onOrderChange = async (option: ShippingFormOrderOption | null): Promise<void> => {
    setValue(ShippingPrintFormSharedField.Order, option, { shouldValidate: true, shouldDirty: true })
    setValue(ShippingPrintFormSharedField.ShipmentNumber, null, { shouldValidate: true, shouldDirty: true })
    setShipmentValues(null)

    if (!option) {
      setOrderValues([])
      await filterShipments(defaultShipmentFilters)
    } else {
      const shipmentFilters = {
        ...defaultShipmentFilters,
        order_type: option.order_type,
        order_number: option.number,
      }

      await Promise.all([filterShipments(shipmentFilters), resetOrderValues(option.number, option.order_type)])
    }
  }

  const resetShipmentValues = async (shipmentNumber: string): Promise<void> => {
    try {
      const shipment = await ShipmentsService.getShipment(shipmentNumber)
      const values = resolveShipmentInfoValues(shipment)
      setShipmentValues(values)
    } catch (e) {
      ErrorHandler.handleError(e)
      setShipmentValues(null)
    }
  }

  const onShipmentChange = async (option: ShipmentDataOption | null): Promise<void> => {
    setValue(ShippingPrintFormSharedField.ShipmentNumber, option, { shouldValidate: true, shouldDirty: true })

    if (!option) {
      setShipmentValues(null)
      return
    }

    const selectedOrder = getValues(ShippingPrintFormSharedField.Order)

    if (selectedOrder) {
      await resetShipmentValues(option.shipment_number)
      return
    }

    setValue(
      ShippingPrintFormSharedField.Order,
      {
        id: option.order_number,
        label: option.order_number,
        number: option.order_number,
        order_type: option.order_type,
      },
      { shouldValidate: true, shouldDirty: true }
    )

    const shipmentFilters = {
      ...defaultShipmentFilters,
      order_type: option.order_type,
      order_number: option.order_number,
    }

    await Promise.all([
      filterShipments(shipmentFilters),
      resetShipmentValues(option.shipment_number),
      resetOrderValues(option.order_number, option.order_type),
    ])
  }

  useEffect(() => {
    async function init(): Promise<void> {
      try {
        if (defaultShipmentNumber) {
          const defaultShipment = await ShipmentsService.getShipment(defaultShipmentNumber)
          const defaultShipmentOption = defineShipmentOption(defaultShipment)
          await onShipmentChange(defaultShipmentOption)
          return
        }
        const { data } = await ShipmentsUtilsService.loadShipmentOptions(1, 1, null, defaultShipmentFilters)
        const defaultShipment = data[0] ?? null

        await onShipmentChange(defaultShipment)
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }

    void init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    shipmentValues,
    orderValues,
    onShipmentChange,
    onOrderChange,
    shipmentsState,
  }
}
