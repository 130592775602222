import * as React from 'react'
import { useMemo } from 'react'
import { GridColumns } from '@mui/x-data-grid-pro'
import { GridEnrichedColDef } from '@mui/x-data-grid/models/colDef/gridColDef'
import { ITableRowWithParentId } from 'components/table/table.types'
import { IActionNestedTableParams } from 'components/nested-xt-table/nested-xt-table.types'
import { NestedXtTable } from 'components/nested-xt-table/nested-xt-table'
import { addTooltipsToColumns, createActionNestedColumn } from './nested-xt-list.utils'

export function NestedXtList<TData extends ITableRowWithParentId, Action, TColumns extends GridColumns<TData>>({
  data,
  loading = false,
  pagination,
  isMobile = false,
  getIsHasChildren,
  onRowClick,
  columns,
  onAction,
  actions = [],
  className,
  getItemAction,
  sortOptions,
  onColumnHeaderClick,
  loadRowsFn,
  detailPanelColumns,
  getIsHasChildrenForNestedRows,
  detailPanelContent,
  minDetailPanelContentHeight,
}: IActionNestedTableParams<TData, Action, TColumns>): React.ReactElement | null {
  const columnsWithTooltip = addTooltipsToColumns(columns)

  const actionColumns = useMemo<Array<GridEnrichedColDef | TColumns[number]>>(() => {
    const actionColumn = createActionNestedColumn(onAction, actions, getItemAction)
    return actionColumn ? [...columnsWithTooltip, actionColumn] : [...columnsWithTooltip]
  }, [onAction, actions, getItemAction, columnsWithTooltip])

  return isMobile ? null : (
    <NestedXtTable
      loading={loading}
      onRowClick={onRowClick}
      data={data}
      columns={actionColumns}
      pagination={pagination}
      className={className}
      sortOptions={sortOptions}
      onColumnHeaderClick={onColumnHeaderClick}
      getIsHasChildren={getIsHasChildren}
      loadRowsFn={loadRowsFn}
      detailPanelColumns={detailPanelColumns && addTooltipsToColumns(detailPanelColumns)}
      getIsHasChildrenForNestedRows={getIsHasChildrenForNestedRows}
      detailPanelContent={detailPanelContent}
      minDetailPanelContentHeight={minDetailPanelContentHeight}
    />
  )
}
