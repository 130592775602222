import type { IAsyncRoute, IRoute } from 'common/routing/routing.types'
import type { InventoryModuleDefinition } from './inventory.module'
import { PageNotFound } from 'page-not-found/page-not-found'
import { InventoryList } from 'products/products.exports'
import { RunningAvailabilityGuard } from 'running-availability/running-availability.guard'
import { RunningAvailabilityListPage } from 'running-availability/running-availability.exports'
import { InventoryGuard } from './inventory.guard'
import { InventoryModule } from './inventory.module'
import { inventoryRoutePath, inventoryRoutes } from './inventory.constants'
import { InventoryAdjustmentsPage } from './inventory-adjustments/inventory-adjustments.page'
import { QuantityOnHandList } from './quantity-on-hand/quantity-on-hand.export'
import {
  InventoryHistoryByLocationList,
  InventoryHistoryByLotSerialList,
  InventoryHistoryList,
} from './inventory-history-section/inventory-history.exports'
import { InventoryHistoryGuard } from './inventory-history-section/item-costs/item-costs.guard'
import { QuantityOnHandGuard } from './quantity-on-hand/quantity-on-hand.guard'
import { quantityOnHandPath } from './quantity-on-hand/quantity-on-hand.constants'

export function inventoryRouteFactory(): IRoute[] {
  const inventoryRoutesList: IRoute[] = [
    {
      path: inventoryRoutes.inventoryAvailability,
      name: 'Inventory Availability',
      guards: [InventoryGuard],
      children: [
        {
          path: '',
          exact: true,
          component: InventoryList,
        },
        {
          path: '*',
          component: PageNotFound,
          exact: true,
        },
      ],
    },
    {
      path: inventoryRoutes.runningAvailability,
      name: 'Running Availability',
      guards: [RunningAvailabilityGuard],
      children: [
        {
          path: '',
          exact: true,
          component: RunningAvailabilityListPage,
        },
        {
          path: '*',
          component: PageNotFound,
          exact: true,
        },
      ],
    },
    {
      component: InventoryHistoryList,
      path: `${inventoryRoutes.inventoryHistory}`,
      name: 'Inventory History',
      exact: true,
      guards: [InventoryHistoryGuard],
    },
    {
      component: InventoryHistoryByLocationList,
      path: `${inventoryRoutes.inventoryHistoryByLocation}`,
      name: 'History by Location',
      exact: true,
      guards: [InventoryHistoryGuard],
    },
    {
      component: InventoryHistoryByLotSerialList,
      path: `${inventoryRoutes.inventoryHistoryByLotSerial}`,
      name: 'History by Lot/Serial',
      exact: true,
      guards: [InventoryHistoryGuard],
    },
    { component: QuantityOnHandList, path: `${quantityOnHandPath}`, name: 'Quantity on Hand', exact: true, guards: [QuantityOnHandGuard] },
    {
      path: `${inventoryRoutes.transactions}/inventory-adjustment`,
      name: 'Transactions',
      exact: true,
      type: 'dropdown',
      children: [
        {
          path: '',
          exact: true,
          name: 'Inventory Adjustment',
          component: InventoryAdjustmentsPage,
        },
      ],
    },
    {
      path: '*',
      component: PageNotFound,
      exact: true,
    },
  ]
  return inventoryRoutesList
}

export const inventoryRoute: IAsyncRoute<InventoryModuleDefinition> = {
  path: inventoryRoutePath,
  name: 'Inventory',
  module: InventoryModule,
  guards: [InventoryGuard],
  childrenRoutesFactory: inventoryRouteFactory,
}
