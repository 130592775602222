import { IPaginationData, IPaginationParams, IPaginationResponse } from 'common/common.types'
import { IHttpClient } from './http-client'

export enum RoleAppliesto {
  Contact = 'Contact',
  Address = 'Address',
  Phone = 'Phone',
}
export interface IRole {
  name: string
  system_role: boolean
  order: string
  appliesto: RoleAppliesto
}

export interface ICRMRolesService {
  getAll: (params: IPaginationParams) => Promise<IPaginationData<IRole>>
}

export class CRMRolesService implements ICRMRolesService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getAll({ limit, page }: IPaginationParams): Promise<IPaginationData<IRole>> {
    const params: Record<string, true | number> = { limit, page: page === 0 ? 1 : page }
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IRole>>('/crmrole', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}
