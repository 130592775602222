import { PermissionGuard } from '../../auth/permission.guard'
import { UserPermission } from '../../auth/auth.types'
import { setupRoutePath } from '../setup.constants'

export class ProductsGuard extends PermissionGuard {
  public redirectTo = setupRoutePath
  public permissions = [
    UserPermission.MaintainClassCodes,
    UserPermission.ViewClassCodes,
    UserPermission.MaintainProductCategories,
    UserPermission.ViewProductCategories,
    UserPermission.MaintainUOMs,
    UserPermission.ViewUOMs,
  ]
}
