import { useState } from 'react'

export interface IConfirmationDialog<ID> extends IItemConfirmationState<ID> {
  openDialog(itemId: ID): void
  closeDialog(): void
}

interface IItemConfirmationState<ID> {
  itemId: ID | null
  open: boolean
}
// TODO replace it with useDialog
export function useConfirmationDialog<ID>(): IConfirmationDialog<ID> {
  const [deletionState, setDeletionState] = useState<IItemConfirmationState<ID>>({ itemId: null, open: false })

  function open(itemId: ID): void {
    setDeletionState({ itemId, open: true })
  }

  function close(): void {
    setDeletionState({ itemId: null, open: false })
  }

  return {
    openDialog: open,
    closeDialog: close,
    itemId: deletionState.itemId,
    open: deletionState.open,
  }
}
