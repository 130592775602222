export const itemCostsRoutePath = 'item-costs'

export const itemCostsRoutes = {
  summary: 'summary',
  history: 'history',
  byClassCode: 'class-code',
  inventoryHistory: 'inventory-history',
  inventoryHistoryByLocation: 'history-by-location',
  inventoryHistoryByLotSerial: 'history-by-lot-serial',
}

export const inventoryHistoryItemCostsUtilsServiceToken = 'CostingItemCostsUtilsServiceToken'
export const inventoryHistoryServiceToken = 'InventoryHistoryService'
