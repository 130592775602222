import * as yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { NewPasswordFormField } from './new-password.types'

export const newPasswordValidation = yup.object({
  [NewPasswordFormField.Password]: yup.string().required(validationMessage.isRequired),
  [NewPasswordFormField.VerifyPassword]: yup
    .string()
    .required(validationMessage.isRequired)
    .oneOf([yup.ref(NewPasswordFormField.Password)], validationMessage.passwordMatch),
})
