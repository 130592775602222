import { nanoid } from 'nanoid'
import { IItemsService } from 'products/items/items.service'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { savePDFFile } from 'common/utils/file.utils'
import { IErrorHandler } from 'core/services/error-handler.service'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { IItemCostsSummaryTotal } from 'products/items/items.types'
import { getEmptyPaginationResponse } from 'core/utils/http.utils'
import { itemCostsSummaryTotals } from 'products/items/items.constants'
import { IItemCostsSummaryWithId, IItemCostsHistoryTableRow, IItemCostsByClassCodeWithId } from './item-costs.types'
import { ISummaryFilters } from './summary/summary-list.types'
import { IHistoryFilters } from './history/history-list.types'
import { ClassCodeFilters } from './by-class-code/by-class-code-list.types'
import { convertClassCodeFilters } from './by-class-code/by-class-code-list.utils'
import { newCurrencyFieldName, oldCurrencyFieldName } from './history/history-list.constants'

export interface ICostingItemCostsUtilsService {
  fetchCostsSummary(
    filters: ISummaryFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemCostsSummaryWithId>>
  fetchCostsSummaryTotal({ itemNumber }: ISummaryFilters): Promise<IItemCostsSummaryTotal>
  printCostsSummary(itemNumber: string): Promise<void>
  fetchCostsHistory(
    { itemNumber }: IHistoryFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemCostsHistoryTableRow>>
  printCostsHistory(itemNumber: string): Promise<void>
  fetchCostsByClassCode(
    filters: ClassCodeFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemCostsByClassCodeWithId>>
  printCostsByClassCode(filters: ClassCodeFilters): Promise<void>
}

export class CostingItemCostsUtilsService implements ICostingItemCostsUtilsService {
  constructor(private readonly itemsService: IItemsService, private readonly errorHandler: IErrorHandler) {
    bindAllInstanceMethods(this)
  }

  public async fetchCostsSummary(
    { itemNumber }: ISummaryFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemCostsSummaryWithId>> {
    if (!itemNumber) {
      return getEmptyPaginationResponse()
    }
    try {
      const { total, data } = await this.itemsService.getItemCostsSummary(itemNumber, paginationParams, sortOptions)
      return {
        data: data.map((item) => ({ ...item, id: nanoid() })),
        total,
      }
    } catch (e) {
      this.errorHandler.handleError(e)
      return getEmptyPaginationResponse()
    }
  }

  public async fetchCostsSummaryTotal({ itemNumber }: ISummaryFilters): Promise<IItemCostsSummaryTotal> {
    if (!itemNumber) {
      return itemCostsSummaryTotals
    }
    try {
      return await this.itemsService.getItemCostsSummaryTotal(itemNumber)
    } catch (e) {
      this.errorHandler.handleError(e)
      return itemCostsSummaryTotals
    }
  }

  public async printCostsSummary(itemNumber: string): Promise<void> {
    try {
      const file = await this.itemsService.getItemCostsSummaryPDF(itemNumber)
      savePDFFile(file, `item-costs-summary-${itemNumber}-file`)
    } catch (e) {
      this.errorHandler.handleError(e)
    }
  }

  public async fetchCostsHistory(
    { itemNumber }: IHistoryFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemCostsHistoryTableRow>> {
    if (!itemNumber) {
      return getEmptyPaginationResponse()
    }
    try {
      // We should sort data using existing fields "old_currency" and "new_currency"
      const processedSortOptions = sortOptions?.map((option) => {
        if (option.sortField === oldCurrencyFieldName) {
          return { sortDirection: option.sortDirection, sortField: 'old_currency' }
        }
        if (option.sortField === newCurrencyFieldName) {
          return { sortDirection: option.sortDirection, sortField: 'new_currency' }
        }
        return option
      })

      const { total, data } = await this.itemsService.getCostsHistory(itemNumber, paginationParams, processedSortOptions)
      return {
        data: data.map((item) => ({
          ...item,
          id: nanoid(),
          oldCurrency: `${item.old_currency} - ${item.old_currency_symbol}`,
          newCurrency: `${item.new_currency} - ${item.new_currency_symbol}`,
        })),
        total,
      }
    } catch (e) {
      this.errorHandler.handleError(e)
      return getEmptyPaginationResponse()
    }
  }

  public async printCostsHistory(itemNumber: string): Promise<void> {
    try {
      const file = await this.itemsService.getCostsHistoryPDF(itemNumber)
      savePDFFile(file, `item-costs-history-${itemNumber}-file`)
    } catch (e) {
      this.errorHandler.handleError(e)
    }
  }

  public async fetchCostsByClassCode(
    filters: ClassCodeFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemCostsByClassCodeWithId>> {
    try {
      const { total, data } = await this.itemsService.getCostsByClassCode(paginationParams, convertClassCodeFilters(filters), sortOptions)
      return {
        data: data.map((item) => ({ ...item, id: item.item_number })),
        total,
      }
    } catch (e) {
      this.errorHandler.handleError(e)
      return getEmptyPaginationResponse()
    }
  }

  public async printCostsByClassCode(filters: ClassCodeFilters): Promise<void> {
    try {
      const file = await this.itemsService.getCostsByClassCodePDF(convertClassCodeFilters(filters))
      savePDFFile(file, 'item-costs-by-class-code-file')
    } catch (e) {
      this.errorHandler.handleError(e)
    }
  }
}
