import { useCallback, useEffect, useRef, useState } from 'react'
import { DocumentType, IAttachedDocumentWithFile, IAttachedDocument, DocumentIdField } from 'documents/documents.types'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { ITable, useTable } from 'common/hooks/useTable'
import { convertToUpdateType } from 'common/utils/file.utils'
import { useCoreModule } from 'core/core-module-hook'
import { useDocumentsModule } from './documents-module-hook'

export interface IDocumentsHookState {
  isDirty: boolean
  loading: boolean
}

export interface IDocumentsHook extends ITable<IAttachedDocument, {}> {
  saveDocument(document: IAttachedDocumentWithFile): Promise<boolean>
  saveDocuments(documents: IAttachedDocumentWithFile[]): Promise<boolean>
  updateDocument(document: IAttachedDocument): Promise<boolean>
  deleteDocument(document: IAttachedDocument): Promise<void>
  reset(documents: IAttachedDocumentWithFile[]): void
  refresh(): Promise<void>
  getUnsavedDocuments: () => IAttachedDocumentWithFile[]
  isDirty: boolean
}

export function useDocuments(
  source: DocumentType,
  sourceNumber: string | null | undefined,
  documentIdField?: DocumentIdField
): IDocumentsHook {
  const { DocumentsService } = useDocumentsModule()
  const { ErrorHandler, ToastService } = useCoreModule()

  const newDocumentsRef = useRef<IAttachedDocumentWithFile[]>([])

  const [isDirty, setIsDirty] = useState<boolean>(false)

  const fetchDocuments = useCallback(
    async (filters, paginationParams: IPaginationParams): Promise<IPaginationData<IAttachedDocument>> => {
      if (filters.sourceNumber) {
        return DocumentsService.getAttachedForSource(filters.source, filters.sourceNumber, paginationParams, { showDetail: true })
      } else {
        return {
          data: newDocumentsRef.current,
          total: newDocumentsRef.current.length,
        }
      }
    },
    [DocumentsService]
  )

  const tableState = useTable({ source, sourceNumber }, fetchDocuments)

  const { refresh, filter, setData, setLoading } = tableState

  useEffect(() => filter({ source, sourceNumber }), [filter, source, sourceNumber])

  const saveDocument = async (document: IAttachedDocumentWithFile): Promise<boolean> => {
    if (!sourceNumber) {
      newDocumentsRef.current = [...newDocumentsRef.current, document]
      setIsDirty(true)
      setData(newDocumentsRef.current, newDocumentsRef.current.length)
      return true
    }

    try {
      setLoading(true)
      const message = await DocumentsService.createFileForSource(document, source, sourceNumber, documentIdField)
      ToastService.showSuccess(message)
      await refresh()
      return true
    } catch (e) {
      ErrorHandler.handleError(e)
      return false
    } finally {
      setLoading(false)
    }
  }

  const saveDocuments = async (documents: IAttachedDocumentWithFile[]): Promise<boolean> => {
    if (!sourceNumber) {
      newDocumentsRef.current = [...newDocumentsRef.current, ...documents]
      setIsDirty(true)
      setData(newDocumentsRef.current, newDocumentsRef.current.length)
      return true
    }

    try {
      setLoading(true)
      const message = await DocumentsService.createMultipleFiles(documents, source, sourceNumber, documentIdField)
      ToastService.showSuccess(message)
      await refresh()
      return true
    } catch (e) {
      ErrorHandler.handleError(e)
      return false
    } finally {
      setLoading(false)
    }
  }

  const updateDocument = async (document: IAttachedDocument): Promise<boolean> => {
    if (!sourceNumber) {
      newDocumentsRef.current = newDocumentsRef.current.map((arrayItem) =>
        arrayItem.id === document.id ? { ...document, file: document.file || arrayItem.file } : arrayItem
      )
      setIsDirty(true)
      setData(newDocumentsRef.current, newDocumentsRef.current.length)
      return true
    }

    try {
      setLoading(true)
      const updatedDocument = convertToUpdateType(document)
      const message = await DocumentsService.update(updatedDocument)
      ToastService.showSuccess(message)
      await refresh()
      return true
    } catch (e) {
      ErrorHandler.handleError(e)
      setLoading(false)
      return false
    }
  }

  const deleteDocument = async (document: IAttachedDocument): Promise<void> => {
    if (!sourceNumber) {
      newDocumentsRef.current = newDocumentsRef.current.filter((iteratedDocument) => iteratedDocument.id !== document.id)
      setIsDirty(newDocumentsRef.current.length > 0)
      setData(newDocumentsRef.current, newDocumentsRef.current.length)
      return
    }
    try {
      setLoading(true)
      const message = await DocumentsService.detach({ assignments: [{ target_type: document.target_type, id: document.id }] })
      ToastService.showSuccess(message)
      await refresh()
    } catch (e) {
      ErrorHandler.handleError(e)
    } finally {
      setLoading(false)
    }
  }

  const reset = useCallback(
    (documents: IAttachedDocumentWithFile[]): void => {
      newDocumentsRef.current = [...newDocumentsRef.current, ...documents]
      setData(newDocumentsRef.current, newDocumentsRef.current.length)
    },
    [setData]
  )

  const getUnsavedDocuments = useCallback(() => newDocumentsRef.current as IAttachedDocumentWithFile[], [])

  return {
    ...tableState,
    saveDocument,
    saveDocuments,
    updateDocument,
    deleteDocument,
    reset,
    isDirty,
    getUnsavedDocuments,
  }
}
