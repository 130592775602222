export const companiesModuleToken = 'CompaniesModule'

export const companiesRoutePath: string = '/companies'

export const companiesRoutes = {
  allAccounts: 'accounts',
  customers: 'customers',
  prospects: 'prospects',
  contacts: 'contacts',
  leads: 'leads',
  vendors: 'vendors',
}
