export interface ISetting {
  setting: string
  setting_value: string | null
  setting_module?: string
}

export enum SettingType {
  TaskNumberGeneration = 'TaskNumberGeneration',
  CRMAccountNumberGeneration = 'CRMAccountNumberGeneration',
  UnprivilegedViewDocInList = 'UnprivilegedViewDocInList',
  DefaultEmailOptIn = 'DefaultEmailOptIn',
  DefaultAddressOptIn = 'DefaultAddressOptIn',
  DisallowNegativeInventory = 'DisallowNegativeInventory',
  QuoteNumberGeneration = 'QUNumberGeneration',
  SalesOrderNumberGeneration = 'CONumberGeneration',
  XTSetupWizardRun = 'XTSetupWizardRun',
}

export enum GenerationValue {
  Manual = 'Manual',
  Automatic = 'Automatic',
  Override = 'Automatic, Allow Override',
  UseSO = "Automatic, Use S/O #'s",
  True = 'True',
  False = 'False',
}

export enum SettingValueGeneration {
  Manual = 'M',
  Automatic = 'A',
  Override = 'O',
  UseSO = 'S',
  True = 't',
  False = 'f',
}
