import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IInventoryLookupParams } from '../inventory-list.types'

export enum WorkbenchFilterFormField {
  FilterType = 'filterType',
  LookAheadDays = 'byDays',
  CutoffDate = 'byDate',
  StartDate = 'startDate',
  EndDate = 'endDate',
}

export enum AsOfFilters {
  ItemSiteLeadTime = 'Item Site Lead Time',
  LookAheadDays = 'Look Ahead Days',
  CutoffDate = 'Cutoff Date',
  Dates = 'Dates',
}

export interface IInventoryListFilterForm {
  [WorkbenchFilterFormField.FilterType]: IXtAutocompleteOption
  [WorkbenchFilterFormField.LookAheadDays]?: number
  [WorkbenchFilterFormField.CutoffDate]?: Date | null
  [WorkbenchFilterFormField.StartDate]?: Date | null
  [WorkbenchFilterFormField.EndDate]?: Date | null
}

export interface IWorkbenchFilterParams {
  disabled?: boolean
  onFilter: (filter: IInventoryLookupParams) => Promise<void>
}
