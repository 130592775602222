import { IPaginationData } from '../../common/common.types'
import { ISource } from '../../comments/comments.types'
import { IHttpClient } from './http-client'

export interface ISourceService {
  getSourceByModule(module: string): Promise<IPaginationData<ISource>>
}

export class SourceService implements ISourceService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getSourceByModule(module: string): Promise<IPaginationData<ISource>> {
    const params = { module }
    const {
      data: { data, total },
    } = await this.apiClient.get<IPaginationData<ISource>>('/source', { params })
    return {
      data,
      total,
    }
  }
}
