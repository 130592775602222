export enum PrintShippingFormsField {
  NewShipments = 'newShipments',
  ChangedShipments = 'changedShipments',
}

export enum PrintShippingFormsLabel {
  NewShipments = 'Print Shipping Forms for new Shipments',
  ChangedShipments = 'Print Shipping Forms for Changed Shipments',
}

export interface IPrintShippingFormsState {
  [PrintShippingFormsField.NewShipments]?: boolean
  [PrintShippingFormsField.ChangedShipments]?: boolean
}
