import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { Scope } from 'core/injector/injector.types'
import { IDocumentsService } from 'documents/documents.types'
import { documentsServiceToken } from 'documents/documents.constants'
import { ICommentsService } from 'comments/comments.service'
import { commentsServiceToken } from 'comments/comments.constants'
import { CoreModule } from 'core/core.module'
import { DocumentsModule } from 'documents/documents.module'
import { CommentsModule } from 'comments/comments.module'
import { UsersModule } from 'users/users.module'
import { IVendorUtilsService } from './vendor-utils.service'
import { IVendorService } from './vendor.service'
import { vendorServicesModule, vendorServiceToken, vendorUtilsServiceToken } from './vendor.constants'

export type VendorServicesModuleDefinition = typeof import('./vendor-services.exports')

export const VendorServicesModule: ILazyModule<VendorServicesModuleDefinition> = {
  name: vendorServicesModule,
  resolver: () => import('./vendor-services.exports'),
  initializer: ({ VendorService, VendorUtilsService }) => {
    Injector.register<IVendorService>(
      vendorServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
        const commentsService = injector.resolve<ICommentsService>(commentsServiceToken)

        return new VendorService(httpClient, commentsService, documentsService)
      },
      Scope.Singleton
    )
    Injector.register<IVendorUtilsService>(
      vendorUtilsServiceToken,
      (injector) => new VendorUtilsService(injector.resolve<IVendorService>(vendorServiceToken)),
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, CommentsModule, UsersModule, DocumentsModule],
}
