import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { cls } from 'common/utils/utils'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { LoaderMessage, confirmationMessages, xsMq } from 'common/constants'
import { useTable } from 'common/hooks/useTable'
import { XtList } from 'components/list/list'
import { XtMode } from 'common/common.types'
import { UserPermission } from 'auth/auth.types'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { XtPageFilter } from 'components/pagefilter/pagefilter'
import { usePageFilter } from 'components/pagefilter/pagefilter.utils'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import * as styles from './customers-list.module.scss'
import { CustomersAction, ICustomersTableItem, IDeletionState } from './customers-list.types'
import {
  defaultDeletionState,
  CustomersListActionsEditMode,
  customerColumns,
  defaultFilterValues,
  CustomersListActionsViewMode,
  customersPageTitle,
} from './customers-list.constants'
import { resolveFilters } from './customers-list.utils'
import { useCustomersModule } from '../customers-module-hook'
import { CustomerFiltersPanel, FilterFieldName, FilterLabel } from '../services/customers-services.types'

export const CustomersList: FC = () => {
  const { CustomersService, CustomersUtilsService } = useCustomersModule()
  const { DocumentsUtilsService } = useDocumentsModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { PermissionsService } = useAuthModule()
  useDocumentTitle(customersPageTitle)
  const history = useHistory()
  const { path } = useRouteMatch()
  const canEditCustomer = PermissionsService.hasPermission(UserPermission.MaintainCustomerMasters)
  const [deletionState, setDeletionState] = useState<IDeletionState>(defaultDeletionState)
  const {
    state: { loading, data, filters: tableFilters, sortOptions },
    setLoading,
    refresh,
    pagination,
    filter,
    sort,
  } = useTable(defaultFilterValues, CustomersUtilsService.fetchCustomers, undefined, PageFilterMapping.Customers)

  const customersListFilters = usePageFilter<CustomerFiltersPanel>(PageFilterMapping.Customers)

  const handleRowClick = useCallback<(item: ICustomersTableItem, mode?: XtMode) => void>(
    ({ id }, mode: XtMode = XtMode.Edit) => {
      if (!canEditCustomer) {
        history.push(`${path}/${id}/${XtMode.View}`)
        return
      }
      history.push(`${path}/${id}/${mode}`)
    },
    [path, history, canEditCustomer]
  )

  const handleAction = useCallback<(item: ICustomersTableItem, action: CustomersAction) => void>(
    (item, action) => {
      switch (action) {
        case CustomersAction.View:
          handleRowClick(item, XtMode.View)
          break
        case CustomersAction.Edit:
          handleRowClick(item, XtMode.Edit)
          break
        case CustomersAction.Delete:
          setDeletionState({ customersId: item.customer_number, confirmationOpen: true })
          break
        case CustomersAction.Print:
          void CustomersUtilsService.printCustomer(item.customer_number)
          break
        default:
          break
      }
    },
    [CustomersUtilsService, handleRowClick, loading]
  )

  const isMobile = useMediaQuery(xsMq)

  const closeConfirmationDialog = useCallback<() => void>(() => setDeletionState(defaultDeletionState), [])

  const handleDeletion = useCallback<() => Promise<void>>(async () => {
    closeConfirmationDialog()
    if (deletionState.customersId) {
      try {
        setLoading(true)
        const message = await CustomersService.delete(deletionState.customersId)
        await refresh()
        setLoading(false)
        ToastService.showSuccess(message)
      } catch (error) {
        ErrorHandler.handleError(error)
        setLoading(false)
      }
    }
  }, [closeConfirmationDialog, deletionState.customersId, setLoading, CustomersService, refresh, ToastService, ErrorHandler])

  const handleShowInactiveFilterChange = (checked: boolean): void => {
    const params = { ...tableFilters, [FilterFieldName.ShowInactive]: checked }
    void filter(params)
    void customersListFilters.handleLastUsedFilter(params)
  }

  return (
    <div className={cls('xt-content', styles.listContent)}>
      <XtConfirmationDialog
        open={deletionState.confirmationOpen}
        message={confirmationMessages.deleted}
        title="Delete Customer"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />
      <div className={styles.listControls}>
        <div className={cls('xt-section-border', styles.headerContent)}>
          <h1 className="xt-page-title">Customers</h1>
          <XtResponsiveButton
            label="New Customer"
            disabled={!canEditCustomer}
            icon={SvgIconIds.ADD_CIRCLE}
            onClick={() => history.push(`${path}/${XtMode.New}`)}
          />
        </div>

        <div className={styles.filtersContainer}>
          <XtPageFilter
            state={customersListFilters}
            defaultFilterValues={defaultFilterValues}
            resolveFilters={() => resolveFilters(DocumentsUtilsService)}
            filter={filter}
            tableFilters={tableFilters}
          />
          <XtCheckbox
            className={styles.customersListCheckbox}
            label={FilterLabel.ShowInactive}
            value={Boolean(tableFilters[FilterFieldName.ShowInactive])}
            onChange={handleShowInactiveFilterChange}
            disabled={loading}
          />
        </div>
      </div>

      <XtList
        actions={canEditCustomer ? CustomersListActionsEditMode : CustomersListActionsViewMode}
        onRowClick={handleRowClick}
        onAction={handleAction}
        isMobile={isMobile}
        pagination={pagination}
        loading={loading}
        data={data}
        columns={customerColumns}
        sortOptions={sortOptions}
        onColumnHeaderClick={sort}
        loadMessage={LoaderMessage.Customers}
      />
    </div>
  )
}
