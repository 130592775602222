import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { FilterType, IFilter } from 'components/pagefilter/filter/filter.types'
import { IDocumentsUtilsService } from 'documents/documents-utils.service'
import { leadActionsEditMode, leadActionsViewMode } from 'leads/lead-list/lead-list.constants'
import { LeadFilter } from 'leads/leads.types'
import { ILeadTableItem } from 'leads/lead-list/lead-list.types'

export function getLeadActions(item: ILeadTableItem): IXtTableCellAction[] {
  return item.editable ? leadActionsEditMode : leadActionsViewMode
}

export function resolveFilters({ loadAccountOptions, loadUserOptions }: IDocumentsUtilsService): IFilter[] {
  return [
    {
      type: FilterType.Autocomplete,
      label: 'Owner',
      fieldName: LeadFilter.Owner,
      autocompleteProps: {
        loadOptions: loadUserOptions,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: 'Account',
      fieldName: LeadFilter.AccountNumber,
      autocompleteProps: {
        loadOptions: loadAccountOptions,
      },
    },
    {
      type: FilterType.Text,
      label: 'Company pattern',
      fieldName: LeadFilter.CompanyPattern,
    },
    {
      type: FilterType.Text,
      label: 'Name pattern',
      fieldName: LeadFilter.NamePattern,
    },
    {
      type: FilterType.Text,
      label: 'City Pattern',
      fieldName: LeadFilter.CityPattern,
    },
    {
      type: FilterType.Text,
      label: 'State Pattern',
      fieldName: LeadFilter.StatePatten,
    },
    {
      type: FilterType.Text,
      label: 'Postal Code pattern',
      fieldName: LeadFilter.PostalCode,
    },
  ]
}
