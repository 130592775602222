import { IAccount } from 'companies/accounts/accounts.types'
import { ITableRow } from '../../../components/table/table.types'

export interface IDeletionState {
  CRMAccountNumberId: string
  confirmationOpen: boolean
}

export enum AccountAction {
  Delete = 'delete',
  Edit = 'edit',
  View = 'view',
}

export interface IAccountTableItem extends ITableRow, IAccount {
  id: string
}
