import { IHttpClient } from 'core/services/http-client'
import { IPaginationData, IPaginationParams, IResponse, IPaginationResponse } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { IRouting, IRoutingItem } from './routing.types'
import { IRoutingDetailsCreate, IRoutingDetailsUpdate } from './routing-details/routing-details.types'

export interface IRoutingListFilters {
  showActiveItemsOnly: boolean
  showActiveBOOsOnly: boolean
}
export interface IRoutingItemsFilters {
  showExpired: boolean
  showFuture: boolean
  itemNumber?: string | null
}
export interface IRoutingService {
  getAll(
    filters: IRoutingListFilters,
    params: IPaginationParams,
    sortOptions?: ISortOption[],
    showDetail?: boolean
  ): Promise<IPaginationData<IRouting>>
  get(itemNumber: string, showExpired: boolean, showFuture: boolean): Promise<IRouting>
  update(data: IRoutingDetailsUpdate): Promise<string>
  create(data: IRoutingDetailsCreate): Promise<string>
  delete(itemNumber: string): Promise<string>
  getAllItems(
    itemNumber: string,
    filters: IRoutingItemsFilters,
    { limit, page }: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IRoutingItem>>
  getItem(itemNumber: string, itemId: number): Promise<IRoutingItem>
  updateItem(itemNumber: string, body: IRoutingItem): Promise<string>
  createItem(itemNumber: string, body: IRoutingItem): Promise<string>
  deleteItem(itemNumber: string, sequenceNumber: number): Promise<string>
  expireItem(itemNumber: string, sequenceNumber: number): Promise<string>
}

export class RoutingService implements IRoutingService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getAll(
    filters: IRoutingListFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[],
    showDetail: boolean = true
  ): Promise<IPaginationData<IRouting>> {
    const params = prepareRequestParams(paginationParams, { ...filters, showDetail }, sortOptions)

    const response = await this.apiClient.get<IPaginationResponse<IRouting>>('/item/boo', {
      params,
    })

    const {
      data: { data, status },
    } = response

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(itemNumber: string, showExpired: boolean = false, showFuture: boolean = false): Promise<IRouting> {
    const response = await this.apiClient.get<IResponse<IRouting>>(`/item/${itemNumber}/boo`, { params: { showExpired, showFuture } })
    if (!Object.keys(response.data.data).length) {
      throw new Error(`response: ${JSON.stringify(response)}`)
    }

    return response.data.data
  }

  public async update(data: IRoutingDetailsUpdate): Promise<string> {
    const body = { data }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post('/item/boo/update', body)
    return message
  }

  public async create(data: IRoutingDetailsCreate): Promise<string> {
    const body = { data }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post('/item/boo/create', body)
    return message
  }

  public async delete(itemNumber: string): Promise<string> {
    const body = { data: { item_number: itemNumber } }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post('/item/boo/delete', body)
    return message
  }

  public async getAllItems(
    itemNumber: string,
    filters: IRoutingItemsFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IRoutingItem>> {
    const params = prepareRequestParams(paginationParams, filters, sortOptions)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IRoutingItem>>(`/item/${itemNumber}/booitem`, { params })
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getItem(itemNumber: string, itemId: number): Promise<IRoutingItem> {
    const response = await this.apiClient.get<IResponse<IRoutingItem>>(`/item/${itemNumber}/boo/${itemId}`)
    return response.data.data
  }

  public async updateItem(itemNumber: string, body: IRoutingItem): Promise<string> {
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post(`/item/${itemNumber}/booitem/update`, { data: [body] })
    return message
  }

  public async createItem(itemNumber: string, body: IRoutingItem): Promise<string> {
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post(`/item/${itemNumber}/booitem/create`, { data: [body] })
    return message
  }

  public async deleteItem(itemNumber: string, sequenceNumber: number): Promise<string> {
    const body = { sequence_number: sequenceNumber }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post(`item/${itemNumber}/booitem/delete`, { data: [body] })
    return message
  }

  public async expireItem(item_number: string, sequence_number: number): Promise<string> {
    const body = { item_number, sequence_number }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post(`/item/boo/expire`, { data: body })
    return message
  }
}
