import { IItemSite } from 'products/items/items.types'
import { dateToServerFormat } from 'common/utils/date.utils'
import { convertToNewComments } from 'comments/comments.utils'
import { IInventoryAdjustmentFormState, IInventoryAdjustmentMeta, InventoryAdjustmentMethod } from './inventory-adjustment-details.types'
import { ILotSerialEntry, LotSerialEntryRow } from './lot-serial/lot-serial.types'
import { IInventoryAdjustmentPayload, IInventoryAdjustmentDetails } from '../inventory-adjustments.types'
import { InventoryAdjustmentCostType } from './inventory-adjustment-cost/inventory-adjustment-cost.types'

export function calculateInventoryAdjustmentMeta(
  site: IItemSite | null,
  distributionQty: number | null,
  inventoryAdjustmentMethod: InventoryAdjustmentMethod
): IInventoryAdjustmentMeta & { qtyToAssign: number } {
  const distributionQuantity = distributionQty ?? 0
  const before = site?.total_on_hand_qty ?? 0
  const after = inventoryAdjustmentMethod === InventoryAdjustmentMethod.Relative ? before + distributionQuantity : distributionQuantity

  const qtyToAssign =
    inventoryAdjustmentMethod === InventoryAdjustmentMethod.Relative ? distributionQuantity : distributionQuantity - before

  return {
    before,
    after,
    qtyToAssign,
  }
}

function convertLotSerialEntryToPayload(entry: ILotSerialEntry): IInventoryAdjustmentDetails {
  return {
    distribution_qty: entry.quantity,
    lotserial: entry.lotSerialNumber?.id ?? null,
    location: entry.location?.id ?? null,
    expiration_date: dateToServerFormat(entry.expirationDate),
    warranty_date: dateToServerFormat(entry.warrantyDate),
    comments: convertToNewComments(entry.comments ?? []),
    lotserial_characteristics: entry.lotserial_characteristics ?? [],
    notes: entry.notes,
    documents: entry.documents,
  }
}

export function convertToInventoryAdjustmentPayload(
  formData: IInventoryAdjustmentFormState,
  tableData: LotSerialEntryRow[]
): IInventoryAdjustmentPayload {
  const { item_number, site, distribution_qty, method, date, comments, total_cost, adjust, cost, document } = formData

  if (!item_number || !site || !date) {
    throw new Error('Form is partially filled.')
  }

  const { qtyToAssign } = calculateInventoryAdjustmentMeta(site, distribution_qty, method)

  return {
    item_number: item_number.id,
    site: site.id,
    qty: qtyToAssign,
    date: dateToServerFormat(date),
    comments,
    cost: adjust && cost === InventoryAdjustmentCostType.Manual ? total_cost : null,
    document,
    detail: tableData.map(convertLotSerialEntryToPayload),
  }
}
