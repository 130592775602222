import * as React from 'react'
import { FC, useCallback } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams'
import { GridRowParams } from '@mui/x-data-grid-pro'
import { useSharedModule } from 'shared/shared-module-hook'
import { xsMq } from 'common/constants'
import { useTable } from 'common/hooks/useTable'
import { CostedBomFilters } from 'products/items/items.types'
import { cls } from 'common/utils/utils'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { XtList } from 'components/list/list'
import { XtAccordion } from 'components/xt-accordion/xt-accordion'
import { IPaginationParams } from 'common/common.types'
import { useNestedXtTable } from 'components/nested-xt-table/nested-xt-table'
import { NestedXtList } from 'components/nested-xt-list/nested-xt-list'
import { NoMobileContent } from 'layout/no-mobile/no-mobile'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { useAuthModule } from 'auth/auth-module-hook'
import { UserPermission } from 'auth/auth.types'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { columns, intendedListPageTitle } from './indented-list.constants'
import { SingleLevelListForm } from '../single-level/single-level-list-form/single-level-list-form'
import * as styles from './indented-list.module.scss'
import { costedBomTotalsColumns, SingleLevelAction, singleLevelActions } from '../single-level/single-level-list.constants'
import { useProductsModule } from '../../products-module-hook'

export const IndentedList: FC = () => {
  const { CostingBomUtilsService, SummaryList } = useProductsModule()
  const { useDialog } = useSharedModule()
  const { PermissionsService } = useAuthModule()
  useDocumentTitle(intendedListPageTitle)
  const canViewCosts = PermissionsService.hasPermission(UserPermission.ViewCosts)

  const isMobile = useMediaQuery(xsMq)

  const {
    state: { data: totals, loading: totalsLoading },
    filter: filterTotals,
  } = useTable({}, CostingBomUtilsService.getCostedBomTotals)

  const {
    open: summaryListDialogOpen,
    closeDialog: closeSummaryListDialog,
    openDialog: openSummaryListDialog,
    data: summaryListData,
  } = useDialog<string | undefined>()

  const {
    state: { loading, data, sortOptions },
    filter,
    sort,
    pagination,
  } = useNestedXtTable({}, CostingBomUtilsService.fetchCostedBom)

  const loadRows = useCallback(
    ({ row }: GridRowParams, paginationParams: IPaginationParams, sortOpt: ISortOption[]) => {
      return CostingBomUtilsService.fetchCostedBom({ item_number: row.parentId }, paginationParams, sortOpt)
    },
    [CostingBomUtilsService]
  )

  const onFiltersChange = useCallback(
    (filters: CostedBomFilters): void => {
      filter(filters)
      filterTotals(filters)
    },
    [filter, filterTotals]
  )

  const onAction = useCallback(
    ({ row: { item_number } }: GridRenderCellParams, action: SingleLevelAction) => {
      if (action === SingleLevelAction.ViewItemCostingSummary) {
        openSummaryListDialog(item_number)
      }
    },
    [openSummaryListDialog]
  )

  const onRowClick = useCallback(
    ({ row: { item_number, seq } }: GridRowParams) => {
      if (seq) {
        openSummaryListDialog(item_number)
      }
    },
    [openSummaryListDialog]
  )

  const getItemActions = useCallback(({ row: { seq } }: GridRenderCellParams, actions: IXtTableCellAction[]) => {
    return seq ? actions : []
  }, [])

  const getIsHasChildren = useCallback(({ row }: GridRowParams) => row?.parentId, [])

  return (
    <NoMobileContent>
      <div className={cls('xt-content', styles.listContent)}>
        <XtDialog
          className="xt-modal-details-content"
          open={summaryListDialogOpen}
          fullScreen={false}
          animation={XtDialogAnimation.FadeAnimation}
        >
          <SummaryList className="xt-item-costs-summary-dialog-content" onClose={closeSummaryListDialog} itemNumber={summaryListData} />
        </XtDialog>
        <div className="xt-page-header">
          <h1 className="xt-page-title">Costed Indented Level BOM</h1>
        </div>
        <SingleLevelListForm onChange={onFiltersChange} />
        <NestedXtList
          isMobile={isMobile}
          sortOptions={sortOptions}
          onColumnHeaderClick={sort}
          columns={columns}
          data={data}
          loading={loading}
          pagination={pagination}
          loadRowsFn={loadRows}
          actions={canViewCosts ? singleLevelActions : []}
          onAction={onAction}
          onRowClick={onRowClick}
          getItemAction={getItemActions}
          getIsHasChildren={getIsHasChildren}
          getIsHasChildrenForNestedRows={getIsHasChildren}
        />
        <XtAccordion loading={totalsLoading} summary="Totals">
          <XtList
            className={styles.tableTotals}
            loading={totalsLoading}
            columns={costedBomTotalsColumns}
            isMobile={isMobile}
            data={totals}
          />
        </XtAccordion>
      </div>
    </NoMobileContent>
  )
}
