import { IXtTableColumnEditable } from 'components/table/table-head/table-head.types'
import { ItemSiteDistributionOption } from 'products/items/items.types'
import { filterByField } from 'common/utils/array.utils'
import {
  ILotSerialColumnSchema,
  LotSerialDefaultColumnsKeys,
  LotSerialEntryRow,
  LotSerialField,
  LotSerialOption,
} from '../lot-serial.types'
import { ILotSerialTableInitialData, LotSerialAndLocationColumnSchema } from './lot-serial-table-row.types'

function isLocationColumn(
  column: IXtTableColumnEditable<LotSerialEntryRow> | undefined
): column is ILotSerialColumnSchema[LotSerialField.Location] {
  return column?.id === LotSerialField.Location
}

function isLotSerialColumn(
  column: IXtTableColumnEditable<LotSerialEntryRow> | undefined
): column is ILotSerialColumnSchema[LotSerialField.LotSerialNumber] {
  return column?.id === LotSerialField.LotSerialNumber
}

function isQuantityColumn(column: IXtTableColumnEditable<LotSerialEntryRow>): column is ILotSerialColumnSchema[LotSerialField.Quantity] {
  return column?.id === LotSerialField.Quantity
}

export function retrieveColumnsSchema(columns: IXtTableColumnEditable<LotSerialEntryRow>[]): ILotSerialColumnSchema {
  return columns.reduce((res, column) => {
    if (isLocationColumn(column)) {
      res[column.id as LotSerialField.Location] = column
    } else if (isLotSerialColumn(column)) {
      res[column.id as LotSerialField.LotSerialNumber] = column
    } else if (isQuantityColumn(column)) {
      res[column.id as LotSerialField.Quantity] = column
    } else {
      res[column.id as LotSerialDefaultColumnsKeys] = column
    }
    return res
  }, {} as ILotSerialColumnSchema)
}

export function filterLotSerialOptionsByLocation(location: string, options: LotSerialOption[]): LotSerialOption[] {
  return options.filter(({ location_name }) => location_name === location)
}

export function filterLocationsByLotSerial(lotSerialNumber: string, locations: ItemSiteDistributionOption[]): ItemSiteDistributionOption[] {
  return locations.filter(({ lotserial }) => lotserial === lotSerialNumber)
}

/**
 * Function defines if Location and Lot/Serial # columns have options defined and also checks for negative Qty to assign value.
 * If the function returns true, it means that both Location and Lot/Serial columns are enabled, so selected options combination (location + lotserial) should be unique and valid (only for Qty to Assign < 0).
 * @param isNegativeQtyToAssign
 * @param columnsSchema
 */
export function shouldFilterLocationOrLotSerialOptions(
  isNegativeQtyToAssign: boolean,
  columnsSchema: ILotSerialColumnSchema
): columnsSchema is LotSerialAndLocationColumnSchema {
  const { location: locationColumn, lotSerialNumber: lotSerialNumberColumn } = columnsSchema
  return isNegativeQtyToAssign && !!locationColumn?.options && !!lotSerialNumberColumn?.options
}

export function defineLotSerialDataByLocation(
  lotSerialOptions: LotSerialOption[],
  location: ItemSiteDistributionOption
): Pick<ILotSerialTableInitialData, 'lotSerial' | 'lotSerialOptions'> {
  const filteredSerialOptions = filterLotSerialOptionsByLocation(location.id, lotSerialOptions)
  const defaultLotSerial = filteredSerialOptions.find(({ lotserial }) => lotserial === location.lotserial) ?? null

  return {
    lotSerialOptions: filteredSerialOptions,
    lotSerial: defaultLotSerial,
  }
}

export function defineLocationDataByLotSerial(
  locations: ItemSiteDistributionOption[],
  lotSerial: LotSerialOption
): Pick<ILotSerialTableInitialData, 'location' | 'locations'> {
  const filteredLocations = filterLocationsByLotSerial(lotSerial.lotserial, locations)

  const defaultLocation = filteredLocations.find(({ location_name }) => location_name === lotSerial.location_name) ?? null

  return {
    location: defaultLocation,
    locations: filteredLocations,
  }
}

/**
 * Defines the initial data for case where we have Location and LotSerial pairs tied to each other.
 * Such case happens if Qty. to Assign is negative and Lot/Serial + Multiple location control options are enabled for the given site.
 * Location and Lot/Serial # controls should display only unique options. We should also filter Lot/Serial # control options by the selected location and vice versa.
 * Example:
 * Location column options:
 * | Option | Location | Lot/Serial |
 * |--------|----------|------------|
 * | 1      | A        | C          |
 * | 2      | A        | D          |
 * | 3      | B        | D          |
 * Lot/Serial column options:
 * | Option | Location | Lot/Serial |
 * |--------|----------|------------|
 * | 1      | A        | C          |
 * | 2      | A        | D          |
 * | 3      | B        | D          |
 * 1) If there is neither Location nor Lot/Serial is selected, we should display only unique options for the corresponding column:
 * Location: Options = [1, 3]
 * Lot/Serial: Options = [1, 2]
 * 2) If the user selects either Location or Lot/Serial we should filter the opposite column options against the selected value:
 *  a) Selected Location: 3 (Location = B, Lot/Serial = D),
 *  b) We should filter Lot/Serial options where location equals to B, so Lot/Serial options = [3]
 *  3) We should set Lot/Serial option 3 as the default option.
 * @param locations - Location control options
 * @param lotSerialOptions - Lot/Serial # control options
 * @param location - selected location
 * @param lotSerial - selected lot/serial
 * @return rowData [ILotSerialTableInitialData] - returns the object contains the filtered options for Lot/Serial # and Location controls as well as controls' values.
 */
export function defineInitialLocationAndLotSerialData(
  locations: ItemSiteDistributionOption[],
  lotSerialOptions: LotSerialOption[],
  location: ItemSiteDistributionOption | null,
  lotSerial: LotSerialOption | null
): ILotSerialTableInitialData {
  if (location) {
    if (lotSerial && (location.lotserial !== lotSerial.lotserial || location.location_name !== lotSerial.location_name)) {
      throw new Error(`Location and Lot/Serial # combination is invalid - lotserial or location_name fields do not match each other.`)
    }

    return {
      locations: filterByField(locations, 'location_name'),
      location,
      ...defineLotSerialDataByLocation(lotSerialOptions, location),
    }
  }
  if (lotSerial) {
    return {
      ...defineLocationDataByLotSerial(locations, lotSerial),
      lotSerialOptions: filterByField(lotSerialOptions, 'lotserial'),
      lotSerial,
    }
  }
  return {
    locations: filterByField(locations, 'location_name'),
    lotSerialOptions: filterByField(lotSerialOptions, 'lotserial'),
    location,
    lotSerial,
  }
}
