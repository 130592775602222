import React, { FC } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { BaseSyntheticEvent } from 'react-transition-group/node_modules/@types/react'
import { validateEmail } from '../../../../common/validation/validation'
import * as styles from './email-link.module.scss'
import { cls } from '../../../../common/utils/utils'

interface IEmailLink {
  email?: string
  className?: string
  classNameContainer?: string
}

function onClick(event: BaseSyntheticEvent): void {
  event.stopPropagation()
}

export const EmailLink: FC<IEmailLink> = ({ email, className, classNameContainer }) => (
  <div className={cls(styles.emailLink, classNameContainer)}>
    {validateEmail(email) ? (
      <a onClick={onClick} className={className} href={`mailto:${email}`} title={email}>
        {email}
      </a>
    ) : (
      <span className={className} title={email}>
        {email}
      </span>
    )}
  </div>
)
