import type { IAsyncRoute, IRoute } from 'common/routing/routing.types'
import type { OrdersModuleDefinition } from './orders.module'
import { QuotesGuard } from 'quotes/quotes.guard'
import { quoteRouteFactory } from 'quotes/quotes.routes'
import { SalesOrdersGuard } from 'sales-orders/sales-orders.guard'
import { salesOrdersRouteFactory } from 'sales-orders/sales-orders.routes'
import { PageNotFound } from 'page-not-found/page-not-found'
import { ordersRoutePath, ordersRoutes } from './orders.constants'
import { OrdersModule } from './orders.module'
import { OrdersGuard } from './orders.guard'

export function ordersRouteFactory(moduleDefinition: OrdersModuleDefinition): IRoute[] {
  const orderRoutesList: IRoute[] = [
    {
      path: ordersRoutes.quotes,
      name: 'Quotes',
      children: quoteRouteFactory(moduleDefinition),
      guards: [QuotesGuard],
    },
    {
      path: ordersRoutes.salesOrders,
      name: 'Sales Orders',
      children: salesOrdersRouteFactory(moduleDefinition),
      guards: [SalesOrdersGuard],
    },
    {
      path: '*',
      component: PageNotFound,
      exact: true,
    },
  ]
  return orderRoutesList
}

export const ordersRoute: IAsyncRoute<OrdersModuleDefinition> = {
  path: ordersRoutePath,
  name: 'Orders',
  module: OrdersModule,
  guards: [OrdersGuard],
  childrenRoutesFactory: ordersRouteFactory,
}
