import { IObjectWithId } from 'common/common.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { ItemOption } from 'products/items/items.types'
import { DocumentOption } from 'documents/documents.types'

export enum MultiLevelTraceOptionsField {
  Forward = 'forward',
  Backward = 'backwards',
  None = 'none',
}

export enum MultiLevelTraceOptionsLabel {
  Forward = 'Forward',
  Backward = 'Backward',
  None = 'None',
}

export enum InventoryHistoryByLotSerialField {
  Site = 'site',
  TransactionDate = 'transaction_date',
  TransactionType = 'transaction_type',
  OrderNumber = 'order_number',
  ItemNumber = 'item_number',
  LotSerial = 'lotserial',
  LotSerialPatter = 'lotserial_pattern',
  Perishable = 'perishable',
  ExpiryDate = 'expiry_date',
  Uom = 'uom',
  TransactionQty = 'transaction_qty',
  QtyBefore = 'qty_before',
  QtyAfter = 'qty_after',
  CustomerInfo = 'customer_info',
  ShipToName = 'shipto_name',
  ShipToAddress1 = 'shipto_address1',
  ShipToAddress2 = 'shipto_address2',
  ShipToAddress3 = 'shipto_address3',
  CityStatePostal = 'citystatepostal',
  Country = 'country',
  Contact = 'contact',
  ContactPhone = 'contact_phone',
  ContactFax = 'contact_fax',
  ContactEmail = 'contact_email',
  Phone = 'phone',
  StartDate = 'startDate',
  EndDate = 'endDate',
  MultiLevelTrace = 'trace',
  ShowDetail = 'showDetail',
}

export enum InventoryHistoryByLotSerialLabel {
  Site = 'Site',
  TransactionDate = 'Date',
  TransactionType = 'Type',
  OrderNumber = 'Order #',
  ItemNumber = 'Item Number',
  LotSerial = 'Lot/Serial #',
  LotSerialPattern = 'Lot/Serial Pattern',
  Perishable = 'Perishable',
  ExpiryDate = 'Expiration',
  Uom = 'UOM',
  TransactionQty = 'Trans-Qty',
  QtyBefore = 'Qty. Before',
  QtyAfter = 'Qty. After',
  CustomerInfo = 'Customer #/Name ',
  ShipToName = 'ShipTo Name',
  ShipToAddress1 = 'ShipTo Line 1',
  ShipToAddress2 = 'ShipTo Line 2',
  ShipToAddress3 = 'ShipTo Line 3',
  CityStatePostal = 'City/State/Zip',
  Country = 'Country',
  Contact = 'Contact',
  ContactPhone = 'Contact Phone',
  ContactFax = 'Contact Fax',
  ContactEmail = 'Contact Email',
  Phone = 'Phone',
  StartDate = 'Start Date',
  EndDate = 'End Date',
  MultiLevelTrace = 'Multi Level Trace',
  TransactionTypeDropDown = 'Transaction Type',
}

export interface IInventoryHistoryByLotSerial {
  [InventoryHistoryByLotSerialField.Site]: string
  [InventoryHistoryByLotSerialField.TransactionDate]: string
  [InventoryHistoryByLotSerialField.TransactionType]: string
  [InventoryHistoryByLotSerialField.OrderNumber]: string
  [InventoryHistoryByLotSerialField.ItemNumber]: string
  [InventoryHistoryByLotSerialField.LotSerial]: string
  [InventoryHistoryByLotSerialField.Perishable]: boolean
  [InventoryHistoryByLotSerialField.ExpiryDate]: string
  [InventoryHistoryByLotSerialField.Uom]: string
  [InventoryHistoryByLotSerialField.TransactionQty]: string
  [InventoryHistoryByLotSerialField.QtyBefore]: number
  [InventoryHistoryByLotSerialField.QtyAfter]: number
  [InventoryHistoryByLotSerialField.CustomerInfo]: string
  [InventoryHistoryByLotSerialField.ShipToName]: string
  [InventoryHistoryByLotSerialField.ShipToAddress1]: string
  [InventoryHistoryByLotSerialField.ShipToAddress2]: string
  [InventoryHistoryByLotSerialField.ShipToAddress3]: string
  [InventoryHistoryByLotSerialField.CityStatePostal]: string
  [InventoryHistoryByLotSerialField.Country]: string
  [InventoryHistoryByLotSerialField.Contact]: string
  [InventoryHistoryByLotSerialField.ContactPhone]: string
  [InventoryHistoryByLotSerialField.ContactFax]: string
  [InventoryHistoryByLotSerialField.ContactEmail]: string
  [InventoryHistoryByLotSerialField.Phone]: string
}

export type InventoryHistoryByLotSerialTableItem = IInventoryHistoryByLotSerial & IObjectWithId

export type InventoryHistoryByLotSerialState = {
  [InventoryHistoryByLotSerialField.Site]?: IXtAutocompleteOption | null
  [InventoryHistoryByLotSerialField.TransactionType]?: IXtAutocompleteOption | null
  [InventoryHistoryByLotSerialField.StartDate]?: Date | null
  [InventoryHistoryByLotSerialField.EndDate]?: Date | null
  [InventoryHistoryByLotSerialField.MultiLevelTrace]?: IXtAutocompleteOption | null
  [InventoryHistoryByLotSerialField.ItemNumber]?: ItemOption | null
  [InventoryHistoryByLotSerialField.LotSerial]?: DocumentOption | null
  [InventoryHistoryByLotSerialField.LotSerialPatter]?: string | null
}

export type InventoryHistoryByLotSerialFilters = {
  [InventoryHistoryByLotSerialField.ItemNumber]?: string | null
  [InventoryHistoryByLotSerialField.LotSerial]?: string | null
  [InventoryHistoryByLotSerialField.LotSerialPatter]?: string | null
  [InventoryHistoryByLotSerialField.Site]?: string | null
  [InventoryHistoryByLotSerialField.TransactionType]?: string | null
  [InventoryHistoryByLotSerialField.StartDate]?: string | null
  [InventoryHistoryByLotSerialField.EndDate]?: string | null
  [InventoryHistoryByLotSerialField.MultiLevelTrace]?: string | null
  [InventoryHistoryByLotSerialField.ShowDetail]?: boolean
}

export interface IInventoryHistoryByLotSerialSharedParams<TFilters> {
  onChange: (filters: TFilters) => void
  loading: boolean
  filters: TFilters
}

export type InventoryHistoryByLotSerialListParams = IInventoryHistoryByLotSerialSharedParams<InventoryHistoryByLotSerialState>
