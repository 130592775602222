import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { renderPhoneCell } from 'components/controls/phone/phone-link/phone-link.utils'
import { convertPhoneData } from 'common/utils/table.utils'
import { PhoneRole } from 'contacts/contacts.types'
import { RegistrationActions } from './registration-list.types'
import { RegistrationTableItem } from '../registration.types'

export const registrationActions: IXtTableCellAction[] = [
  {
    name: RegistrationActions.Edit,
    label: RegistrationActions.Edit,
    icon: SvgIconIds.CREATE,
  },
  {
    name: RegistrationActions.Delete,
    label: RegistrationActions.Delete,
    icon: SvgIconIds.DELETE,
  },
]

export const registrationColumns: IXtTableColumn<RegistrationTableItem>[] = [
  {
    id: 'number',
    field: 'number',
    headerName: 'Number',
    flex: '1 0 115px',
    width: 115,
  },
  {
    id: 'account_number',
    field: 'account_number',
    headerName: 'Account #',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'account_name',
    field: 'account_name',
    headerName: 'Name',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'firstName',
    field: 'contact.first_name',
    headerName: 'First Name',
    flex: '1 0 160px',
    width: 160,
    unsortable: true,
  },
  {
    id: 'phone',
    headerName: 'Phone',
    flex: '1 0 300px',
    width: 300,
    renderCell: ({ registration_contact: { contact_phones } }) => renderPhoneCell(convertPhoneData(contact_phones, PhoneRole.Office)),
    unsortable: true,
  },
]
