import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { convertDate } from 'common/utils/date.utils'
import { IXtTableColumn, TableValueConverter } from 'components/table/table-head/table-head.types'
import { IRoutingDetailsTableItem, RoutingDetailsAction } from './routing-details.types'

const routingDateConverter: TableValueConverter = (date: unknown) => {
  if (!date || typeof date !== 'string') {
    return ''
  }
  if (date === 'Always' || date === 'Never') {
    return date
  }
  return convertDate(date)
}

const routingNoneValueConverter: TableValueConverter = (value?: unknown) => {
  if (!value) {
    return 'None'
  }
  return String(value)
}

export const RoutingDetailsActionsViewMode: IXtTableCellAction[] = [
  {
    name: RoutingDetailsAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
]

export const RoutingDetailsActionsEditMode: IXtTableCellAction[] = [
  {
    name: RoutingDetailsAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: RoutingDetailsAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: RoutingDetailsAction.Expire,
    label: 'Expire',
    icon: SvgIconIds.EXPIRE,
  },
  {
    name: RoutingDetailsAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const RoutingDetailsActionsNewMode: IXtTableCellAction[] = [
  {
    name: RoutingDetailsAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: RoutingDetailsAction.View,
    label: 'View',
    icon: SvgIconIds.VISIBILITY,
  },
  {
    name: RoutingDetailsAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const routingDetailsColumns: IXtTableColumn<IRoutingDetailsTableItem>[] = [
  {
    id: 'sequence_number',
    field: 'sequence_number',
    headerName: '#',
    flex: '1 0 100px',
    width: 100,
  },
  {
    id: 'standard_operation',
    field: 'standard_operation',
    headerName: 'Std. Oper.',
    flex: '1 0 160px',
    width: 160,
    converter: routingNoneValueConverter,
  },
  {
    id: 'work_center',
    field: 'work_center',
    headerName: 'Work Cntr.',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'routing_description1',
    field: 'description',
    headerName: 'Description',
    flex: '2 0 260px',
    width: 260,
  },
  {
    id: 'effective',
    field: 'effective',
    headerName: 'Effective',
    flex: '1 0 160px',
    width: 160,
    converter: routingDateConverter,
  },
  {
    id: 'expires',
    field: 'expires',
    headerName: 'Expires',
    flex: '1 0 160px',
    width: 160,
    converter: routingDateConverter,
  },
  {
    id: 'execution_day',
    field: 'execution_day',
    headerName: 'Exec. Day',
    flex: '1 0 160px',
    width: 160,
    align: 'center',
  },
]

export const getRoutingDetailsPageTitle = (id: string = '') => `xTuple - ${id ? `Routing: ${id}` : 'Create Routing'}`
