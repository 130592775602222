import { Overwrite } from 'utility-types'
import { Injector } from 'core/injector/injector.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { IItemsSharedModuleServices, ItemsSharedModule, ItemsSharedModuleDefinition } from './items-shared.module'
import { IClassCodesService } from './class-codes/class-codes.service'
import { classCodesServiceToken, itemsSharedUtilsServiceToken } from './items-shared.constants'
import { IItemsSharedUtilsService } from './items-shared-utils.service'

function resolveItemsSharedModule(): Overwrite<ItemsSharedModuleDefinition, IItemsSharedModuleServices> {
  const module = LazyModulesService.resolveModule<ItemsSharedModuleDefinition>(ItemsSharedModule)
  const ClassCodesService = Injector.resolve<IClassCodesService>(classCodesServiceToken)
  const ItemsSharedUtilsService = Injector.resolve<IItemsSharedUtilsService>(itemsSharedUtilsServiceToken)

  return { ...module, ClassCodesService, ItemsSharedUtilsService }
}

export const useItemsSharedModule = moduleHookResolver(resolveItemsSharedModule)
