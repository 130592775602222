import { IModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { CharacteristicsModule } from 'characteristics/characteristics.module'
import { CoreModule } from 'core/core.module'
import { IDocumentsUtilsService } from './documents-utils.service'
import { IDocumentsService } from './documents.types'
import { documentsModuleToken, documentsServiceToken, documentsUtilsServiceToken } from './documents.constants'
import { DocumentsModuleDefinition } from './documents-module.types'
import * as DocumentsModuleExports from './documents.exports'
import { SharedModule } from '../shared/shared-module'

export const DocumentsModule: IModule<DocumentsModuleDefinition> = {
  name: documentsModuleToken,
  resolver: () => DocumentsModuleExports,
  initializer: ({ DocumentsService, DocumentsUtilsService }) => {
    Injector.register<IDocumentsService>(
      documentsServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        return new DocumentsService(httpClient)
      },
      Scope.Singleton
    )
    Injector.register<IDocumentsUtilsService>(
      documentsUtilsServiceToken,
      (injector) => {
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
        return new DocumentsUtilsService(documentsService)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, CharacteristicsModule, SharedModule],
}
