import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { CoreModule } from 'core/core.module'
import { Injector } from 'core/injector/injector.service'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { Scope } from 'core/injector/injector.types'
import { IDocumentsUtilsService } from 'documents/documents-utils.service'
import { documentsUtilsServiceToken } from 'documents/documents.constants'
import { DocumentsModule } from 'documents/documents.module'
import { SharedModule } from 'shared/shared-module'
import { IItemsSharedUtilsService } from './items-shared-utils.service'
import { IClassCodesService } from './class-codes/class-codes.service'
import { classCodesServiceToken, itemsSharedModuleToken, itemsSharedUtilsServiceToken } from './items-shared.constants'

export type ItemsSharedModuleDefinition = typeof import('./items-shared.exports')

export interface IItemsSharedModuleServices {
  ClassCodesService: IClassCodesService
  ItemsSharedUtilsService: IItemsSharedUtilsService
}

export const ItemsSharedModule: ILazyModule<ItemsSharedModuleDefinition> = {
  name: itemsSharedModuleToken,
  resolver: () => import('./items-shared.exports'),
  initializer: ({ ClassCodesService, ItemsSharedUtilsService }) => {
    const httpClient = Injector.resolve<IHttpClient>(httpClientToken)
    Injector.register<IClassCodesService>(classCodesServiceToken, () => new ClassCodesService(httpClient), Scope.Singleton)
    Injector.register<IItemsSharedUtilsService>(
      itemsSharedUtilsServiceToken,
      (injector) => {
        const documentsUtilsService = injector.resolve<IDocumentsUtilsService>(documentsUtilsServiceToken)
        return new ItemsSharedUtilsService(documentsUtilsService)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, DocumentsModule, SharedModule],
}
