import { parse as fnsParseDate, format as fnsFormatDate, parseISO, addDays } from 'date-fns'
import * as yup from 'yup'
import { globalConstants } from '../constants'
import { minDate, validationMessage } from '../validation/validation'

export const parseDate = (date: string, format: string): Date => fnsParseDate(date, format, new Date())
export const formatDate = (date: Date, format: string): string => fnsFormatDate(date, format)

export function isValidDate(date: Date): boolean {
  return !Number.isNaN(date.getTime())
}

/**
 * Parses data in specific server format like 2021-11-11 to the Date object without Timezone information.
 * @param date - date to parse
 * @param datetime - true if time should be included into the parsed date
 */
export function parseServerDate(date: string, datetime?: boolean): Date
/**
 * Parses data in specific server format like 2021-11-11 to the Date object without Timezone information.
 * @param date - date to parse
 * @param datetime - true if time should be included into the parsed date
 * @return {Date | null} Returns null if date is not defined
 */
export function parseServerDate(date: string | null | undefined, datetime?: boolean): Date | null

export function parseServerDate(date: string | null | undefined, datetime?: boolean): Date | null {
  if (typeof date !== 'string') {
    return null
  }
  const parsedDate = datetime ? parseISO(date) : parseDate(date, globalConstants.serverDateFormat)
  return isValidDate(parsedDate) ? parsedDate : parseISO(date)
}

export function parseServerDateWithDefault(date: string | undefined | null, defaultDate: Date = new Date()): Date {
  return date ? parseServerDate(date) : defaultDate
}

export function parseServerDateWithStringValue(date: string | undefined | null): Date | null {
  const parsedDate = parseServerDate(date)
  return parsedDate && isValidDate(parsedDate) ? parsedDate : null
}

/**
 * Converts the date to string representation
 * @param date
 * @param datetime [boolean] - true if time should be presented in the result
 */
export function convertDate(date: Date | string | null, datetime: boolean = false): string {
  if (!date) {
    return ''
  }

  const format = datetime ? globalConstants.datetimeFormat : globalConstants.dateFormat

  if (date instanceof Date) {
    return formatDate(date, format)
  }

  const dateObject = parseServerDate(date, datetime)

  return isValidDate(dateObject) ? formatDate(dateObject, format) : ''
}

export function dateToServerFormat(date: Date, defaultValue?: string): string
export function dateToServerFormat(date: Date | null, defaultValue: string): string
export function dateToServerFormat(date: Date, defaultValue?: string): string
export function dateToServerFormat(date: Date | null, defaultValue?: string): string | null

export function dateToServerFormat(date: Date | null, defaultValue?: string): string | null {
  if (!date || !isValidDate(date)) {
    return defaultValue ?? null
  }
  return formatDate(date, globalConstants.serverDateFormat)
}

export function getCurrentDateInServerFormat(): string {
  return formatDate(new Date(), globalConstants.serverDateFormat)
}

/**
 * Converts table column value into formatted string
 * @param date
 * @param datetime [boolean] - true if time should be presented in the result
 */
export function dateConverter(date: unknown, datetime?: boolean): string {
  if (!date || typeof date !== 'string') {
    return ''
  }

  if (Object.values(globalConstants.dateStringRepresentation).includes(date)) {
    return date
  }

  return convertDate(date, datetime)
}

function addOrRemoveDay(date: Date | null, change: number): Date | undefined {
  return date ? addDays(date, change) : undefined
}

/**
 * Reduce date by one day
 */
export function reduceOneDay(date: Date | null): Date | undefined {
  return addOrRemoveDay(date, -1)
}

/**
 * Increase date by one day
 */
export function increaseOneDay(date: Date | null): Date | undefined {
  return addOrRemoveDay(date, 1)
}

// TODO: if there are more yup utilities, move to yup.utils
export const dateYupValidator = yup
  .date()
  .nullable()
  .typeError(validationMessage.invalidDate)
  .min(minDate, validationMessage.minDate(validationMessage.minimumDate))
