import React, { useEffect , FC } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { Prompt } from 'react-router'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { IXtMode } from 'common/common.types'

import { cls } from 'common/utils/utils'
import { LoaderMessage, confirmationMessages, xsMq } from 'common/constants'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { LoadingSpinner } from 'components/loading-spinner'
import { XtList } from 'components/list/list'

import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { FormDatePicker, FormField } from 'common/utils/form/form.components'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { maxNumber } from 'common/validation/validation'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { useProductsModule } from 'products/products-module-hook'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { XtButtonLabels } from 'components/buttons/xt-button/xt-button.types'
import { UseBomDetails } from './bom-details.hook'
import { bomDetailsTableColumns, getBomDetailsPageTitle } from './bom-details.constants'
import { BomItem } from '../bom-item/bom-item'
import { BomDetailsFormField, BomDetailsFormFieldLabel } from './bom-details.types'
import * as styles from './bom-details.module.scss'
import { BomItemQuickAdd } from '../bom-item/bom-item-quick-add/bom-item-quick-add'
import { BomDetailsSection } from './bom-details-section/bom-details-section'

interface IBomDetails extends IXtMode {
  componentMode?: boolean
  className?: string
}

export const BomDetails: FC<IBomDetails> = ({ mode, componentMode, className }) => {
  const { FormXtItemNumber, ItemsUtilsService } = useProductsModule()
  const {
    quickAddState: { openQuickAddForm, closeQuickAddForm, isQuickAddOpen, handleAdvancedSearch },
    bomItemState: { bomItemDialogState, addBomItem, onBomItemSubmit, closeBomItemDialog },
    deletionState: {
      state: { open: confirmationDialogOpen, closeDialog },
      handleDeletion,
    },
    formState: {
      state: {
        control,
        handleSubmit,
        formState: { isDirty, isSubmitting, isSubmitSuccessful },
      },
      onCancel,
      onSubmit,
    },
    tableState: {
      state: { filter, state, pagination, sort },
      tableActions,
      handleAction,
      handleRowClick,
    },
    bomData,
    itemNumberValue,
    bomDetailsMode: { isViewMode, isNewMode },
    onItemNumberChange,
  } = UseBomDetails(mode)
  const showPrompt = !isSubmitSuccessful && isDirty
  useDocumentTitle(getBomDetailsPageTitle(itemNumberValue?.itemNumber))
  const anchorEl = React.useRef(null)
  const isMobile = useMediaQuery(xsMq)
  const { filters: tableFilters } = state

  const handleShowExpiredChange = (checked: boolean) => {
    const updatedTableFilters = { ...tableFilters, showExpired: checked }
    void filter(updatedTableFilters)
  }

  const handleShowFutureChange = (checked: boolean) => {
    const updatedTableFilters = { ...tableFilters, showFuture: checked }
    void filter(updatedTableFilters)
  }

  useEffect(() => {
    if (isSubmitSuccessful) onCancel()
  }, [isSubmitSuccessful])

  return (
    <div>
      <XtConfirmationDialog
        open={confirmationDialogOpen}
        message={confirmationMessages.deleted}
        title="Delete BOM Item"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeDialog}
      />
      {itemNumberValue && (
        <XtDialog
          className="xt-modal-details-content"
          open={bomItemDialogState.open}
          fullScreen={true}
          animation={XtDialogAnimation.SlideAnimation}
        >
          <BomItem
            parentItemNumber={itemNumberValue.itemNumber}
            bomItem={bomItemDialogState.bomItem}
            onSubmit={onBomItemSubmit}
            onClose={closeBomItemDialog}
            mode={bomItemDialogState.mode}
            bomItemsList={state.data}
          />
        </XtDialog>
      )}
      {itemNumberValue && (
        <BomItemQuickAdd
          itemNumber={itemNumberValue.itemNumber}
          onAdvancedSearch={handleAdvancedSearch}
          anchorEl={anchorEl.current}
          onClose={closeQuickAddForm}
          open={isQuickAddOpen}
          onAddItem={addBomItem}
        />
      )}
      <main className={cls(className, componentMode && styles.componentMode)}>
        {bomData.loading && <LoadingSpinner />}
        <form hidden={bomData.loading} onSubmit={handleSubmit(onSubmit)}>
          <h1 className={cls(styles.bomHeader, 'xt-page-title')}>{isNewMode ? 'BOM Item' : 'BOM Details'}</h1>
          <div className={cls(styles.header, 'xt-sticky-header', 'xt-section-border')} hidden={componentMode}>
            <div className={styles.title}>
              <FormXtItemNumber
                required
                control={control}
                name={BomDetailsFormField.ItemNumber}
                isEditMode={isNewMode}
                onChange={onItemNumberChange}
                loading={state.loading}
                loadOptions={ItemsUtilsService.loadItemOptions}
              />
              <SvgIcon hidden={isViewMode} iconId={SvgIconIds.PRINT} className={styles.bomDetailsPrintIcon} />
            </div>
            <div className={cls(styles.headerButtons, isViewMode && styles.buttonsIsViewMode)}>
              <XtButton disabled={isSubmitting} label={XtButtonLabels.Close} onClick={onCancel} />
              <XtButton
                hidden={isViewMode}
                label={XtButtonLabels.Save}
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting || isViewMode || !itemNumberValue}
              />
            </div>
          </div>
          <div className={styles.bomDetailsFormFields}>
            <FormField control={control} name={BomDetailsFormField.Revision} label={BomDetailsFormFieldLabel.Revision} disabled />
            <FormDatePicker
              control={control}
              name={BomDetailsFormField.RevisionDate}
              label={BomDetailsFormFieldLabel.Date}
              disabled={isViewMode}
            />
            <FormField
              control={control}
              name={BomDetailsFormField.DocumentNumber}
              label={BomDetailsFormFieldLabel.DocNumber}
              disabled={isViewMode}
            />
            <DecimalFormField
              control={control}
              name={BomDetailsFormField.BatchSize}
              label={BomDetailsFormFieldLabel.BatchSize}
              disabled={isViewMode}
              maxValue={maxNumber}
              allowNegative={false}
            />
          </div>
          <div className={styles.bomDetailsFilters}>
            <XtCheckbox
              label={BomDetailsFormFieldLabel.ShowExpired}
              value={tableFilters.showExpired ?? false}
              onChange={handleShowExpiredChange}
            />
            <XtCheckbox
              label={BomDetailsFormFieldLabel.ShowFuture}
              value={tableFilters.showFuture ?? false}
              onChange={handleShowFutureChange}
            />
            <XtResponsiveButton
              disabled={state.loading || isViewMode || !itemNumberValue}
              icon={SvgIconIds.ADD_CIRCLE}
              label="Add BOM Item"
              onClick={openQuickAddForm}
              ref={anchorEl}
            />
          </div>
          <div className={cls('xt-common-table-list', styles.table)}>
            <XtList
              actions={tableActions}
              onRowClick={handleRowClick}
              onAction={handleAction}
              isMobile={isMobile}
              pagination={pagination}
              loading={state.loading}
              data={state.data}
              columns={bomDetailsTableColumns}
              sortOptions={state.sortOptions}
              onColumnHeaderClick={sort}
              loadMessage={LoaderMessage.Items}
            />
          </div>

          <BomDetailsSection data={bomData.bom?.bom_details} isMobile={isMobile} />
        </form>
      </main>

      <Prompt when={showPrompt} message={confirmationMessages.unsavedChanges} />
    </div>
  )
}
