import { useCallback, useState } from 'react'
import { IItemSite, ItemSiteDistributionResponse } from 'products/items/items.types'
import { useCoreModule } from 'core/core-module-hook'
import { useProductsModule } from 'products/products-module-hook'
import { supportsLotSerial } from '../../lot-serial.utils'
import { LotSerialOption } from '../../lot-serial.types'
import { handleAutomaticAssignment, handleManualAssignment, handleNegativeAssignment } from './lot-serial-number-hook.utils'
import { ILotSerialNumberHook, ILotSerialNumberHookResetResponse } from './lot-serial-number-hook.types'

/**
 * Hook handles Lot/Serial # table column: dropdown options, default option
 */
export function useLotSerialNumber(): ILotSerialNumberHook {
  const { ErrorHandler } = useCoreModule()
  const { ItemSitesService, ItemsService } = useProductsModule()

  const [options, setOptions] = useState<LotSerialOption[]>([])

  const resetOptions = useCallback<
    (
      itemSite: IItemSite,
      qtyToAssign: number,
      locations?: ItemSiteDistributionResponse<true>[]
    ) => Promise<ILotSerialNumberHookResetResponse>
  >(
    async (itemSite, qtyToAssign, locations) => {
      let result: ILotSerialNumberHookResetResponse = { options: [], defaultNumbers: null }

      try {
        const { control_method, auto_ls_number } = itemSite

        // We should hide the field if Lot/Serial control method is not supported
        if (!supportsLotSerial(control_method)) {
          setOptions([])
          return result
        }

        const isNegativeQtyToAssign = qtyToAssign < 0

        // Handles negative quantity assignment
        if (isNegativeQtyToAssign) {
          result = await handleNegativeAssignment(ItemSitesService, itemSite, locations)
        } else if (auto_ls_number === null) {
          result = await handleManualAssignment(ItemsService, itemSite)
        } else {
          result = await handleAutomaticAssignment(ItemsService, itemSite, qtyToAssign)
        }

        setOptions(result.options)

        return result
      } catch (e) {
        ErrorHandler.handleError(e)
        return result
      }
    },
    [ErrorHandler, ItemSitesService, ItemsService]
  )

  return {
    lotSerialNumberOptions: options,
    setLotSerialNumberOptions: setOptions,
    resetLotSerialNumberOptions: resetOptions,
  }
}
