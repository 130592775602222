// extracted by mini-css-extract-plugin
export var root = "lot-serial-details_root__Uepdx";
export var xtContent = "lot-serial-details_xt-content__Itw6C";
export var xtContentWithRemarks = "lot-serial-details_xt-content-with-remarks__DM7M8";
export var muiFormHelperTextRoot = "lot-serial-details_MuiFormHelperText-root__x-9l4";
export var xtListPageContainer = "lot-serial-details_xt-list-page-container__yuBXY";
export var muiInputBaseRoot = "lot-serial-details_MuiInputBase-root__AAiJ6";
export var muiTextFieldRoot = "lot-serial-details_MuiTextField-root__9GlhV";
export var muiTablePaginationRoot = "lot-serial-details_MuiTablePagination-root__H0qpk";
export var muiTablePaginationSelectRoot = "lot-serial-details_MuiTablePagination-selectRoot__Zdp27";
export var muiTablePaginationSelect = "lot-serial-details_MuiTablePagination-select__MYnVT";
export var muiStepperRoot = "lot-serial-details_MuiStepper-root__ARjh5";
export var muiStepperHorizontal = "lot-serial-details_MuiStepper-horizontal__qLYVD";
export var muiStepIconRoot = "lot-serial-details_MuiStepIcon-root__WrMjE";
export var muiStepIconActive = "lot-serial-details_MuiStepIcon-active__BKK3F";
export var muiStepIconCompleted = "lot-serial-details_MuiStepIcon-completed__kszdW";
export var muiListItemRoot = "lot-serial-details_MuiListItem-root__dpUAD";
export var muiSelected = "lot-serial-details_Mui-selected__bfhhL";
export var xtPageTitle = "lot-serial-details_xt-page-title__PAaw+";
export var xtSubPageTitle = "lot-serial-details_xt-subPage-title__PYrLQ";
export var xtSectionBorder = "lot-serial-details_xt-section-border__XmEzM";
export var xtPageHeader = "lot-serial-details_xt-page-header__bZwde";
export var xtDialogScrollable = "lot-serial-details_xt-dialog-scrollable__VYTf1";
export var reactToastNotificationsContainer = "lot-serial-details_react-toast-notifications__container__D849l";
export var xtScrollbarStyle = "lot-serial-details_xt-scrollbar-style__K56wv";
export var xtModalDetailsContent = "lot-serial-details_xt-modal-details-content__H9jBd";
export var lotSerialDialog = "lot-serial-details_lotSerialDialog__0BbEs";
export var xtDialogDetailsContent = "lot-serial-details_xt-dialog-details-content__H3t+B";
export var xtLazyModuleLoaderTabContainer = "lot-serial-details_xt-lazy-module-loader-tab-container__CbP9U";
export var xtTextTruncated = "lot-serial-details_xt-text-truncated__x76La";
export var xtSummaryContent = "lot-serial-details_xt-summary-content__bdK7g";
export var xtSummaryLabel = "lot-serial-details_xt-summary-label__DPaBE";
export var visibleHidden = "lot-serial-details_visible-hidden__J2n1v";
export var headerBottomMargin = "lot-serial-details_header-bottom-margin__RItF5";
export var xtDialogHeaderLogoAlign = "lot-serial-details_xt-dialog-header-logoAlign__9RFhj";
export var xtStickyHeader = "lot-serial-details_xt-sticky-header__2HYU4";
export var noBorder = "lot-serial-details_no-border__4HqRi";
export var muiOutlinedInputNotchedOutline = "lot-serial-details_MuiOutlinedInput-notchedOutline__80trC";
export var headerForm = "lot-serial-details_headerForm__rwr8x";
export var headerFormSection = "lot-serial-details_headerFormSection__ToMxO";
export var headerFormFields = "lot-serial-details_headerFormFields__zQhiP";
export var headerFormButtons = "lot-serial-details_headerFormButtons__tYTfl";
export var xtTabs = "lot-serial-details_xtTabs__bhSkF";