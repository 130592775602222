import { nanoid } from 'nanoid'
import { ContactCreatePayload, ContactUpdatePayload, IContactAccount, IContact } from 'contacts/contacts.types'
import { ContactAction, IContactTableItem } from './contact-list.types'
import { IXtTableCellAction } from '../../components/table/table-cell-actions/table-cell-actions'
import { contactActionsViewMode, newContactNumberPrefix } from './contact-list.constants'

export function convertToTableItem(contact: IContact, number: string): IContactTableItem {
  return {
    ...contact,
    id: contact.contact_number,
    account_number: number,
  }
}

export function normalizeData(items: IContact[], number: string): IContactTableItem[] {
  return items.map((contact) => convertToTableItem(contact, number))
}

export function convertToContacts(items: IContactTableItem[]): ContactCreatePayload[] {
  return items.map(({ account_number: _, contact_number: _contact_number, id: _id, ...contact }) => contact)
}

export function convertAccountNumbers(accounts: IContactAccount[]): string {
  return accounts ? [...new Set(accounts.map((v) => v?.account_number))].join(', ') : ''
}

export function convertFromPayload(contact: ContactUpdatePayload, account_number: string): IContactTableItem {
  return {
    ...contact,
    id: contact.contact_number.toString(),
    account_number,
    contact_characteristics: contact.contact_characteristics?.map((value) => ({ ...value, id: -Date.now() })) ?? [],
  }
}

export function getContactActions(
  item: IContactTableItem,
  actions: IXtTableCellAction[],
  accountNumber: string | undefined
): IXtTableCellAction[] {
  if (!item.editable) {
    return contactActionsViewMode
  }

  return actions.filter((action: IXtTableCellAction) => {
    if (accountNumber && action.name === ContactAction.Detach) {
      return Boolean(item.accounts?.some((account) => account.account_number === accountNumber && account.active_role))
    }

    return item.editable
  })
}

export function convertTableItemToContact({
  id: _id,
  account_number: _account_number,
  contact_roles: _contact_roles,
  selected: _selected,
  ...contact
}: IContactTableItem): IContact {
  return contact
}

export function isContact(contact: IContact | ContactCreatePayload): contact is IContact {
  return Object.prototype.hasOwnProperty.call(contact, 'contact_number')
}

export function generateContactNumberPlaceholder(): string {
  return `${newContactNumberPrefix}-${nanoid()}`
}
