import { IItem, IItemSite, RunningAvailabilityFilter } from 'products/items/items.types'
import { defineItemNumberOption } from 'products/items/components/item-number/item-number.utils'
import { IRunningAvailabilityForm, IRunningAvailabilityFormFilled } from './running-availability-list-form.types'

export function defineFormData(item: IItem | null, site: IItemSite | null): IRunningAvailabilityForm {
  return {
    [RunningAvailabilityFilter.ItemNumber]: defineItemNumberOption(item),
    [RunningAvailabilityFilter.Site]: site ? { ...site, id: site.site, label: site.site } : null,
  }
}

export function isFilterFormDataFilled(formData: IRunningAvailabilityForm): formData is IRunningAvailabilityFormFilled {
  const { itemNumber, site } = formData

  return Boolean(itemNumber && site)
}
