// extracted by mini-css-extract-plugin
export var header = "bom-details_header__s6kl-";
export var bomHeader = "bom-details_bomHeader__1rlRK";
export var title = "bom-details_title__htSjy";
export var bomDetailsFormFields = "bom-details_bomDetailsFormFields__dH2CP";
export var filter = "bom-details_filter__hEivb";
export var headerButtons = "bom-details_headerButtons__l3Stl";
export var buttonsIsViewMode = "bom-details_buttonsIsViewMode__duIQ8";
export var bomDetailsPrintIcon = "bom-details_bomDetailsPrintIcon__yt2bW";
export var componentMode = "bom-details_componentMode__xvS2Z";
export var table = "bom-details_table__4HRAs";
export var bomDetailsFilters = "bom-details_bomDetailsFilters__Lo9Wn";