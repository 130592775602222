import * as React from 'react'
import { FC } from 'react'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { RunningAvailabilityList } from './running-availability-list'
import { IRunningAvailabilityListDialog } from './running-availability-list.types'
import * as styles from './running-availability-list.module.scss'

export const RunningAvailabilityListDialog: FC<IRunningAvailabilityListDialog> = ({ open, itemNumber, site, onClose }) => {
  return (
    <XtDialog className="xt-modal-details-content" open={open} fullScreen={false} animation={XtDialogAnimation.SlideAnimation}>
      <RunningAvailabilityList
        className={styles.runningAvailabilityDialog}
        itemNumber={itemNumber}
        site={site ?? undefined}
        onClose={onClose}
      />
    </XtDialog>
  )
}
