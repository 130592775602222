import type { IXtMode } from 'common/common.types'
import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { companiesRoutePath, companiesRoutes } from 'companies/companies.constants'
import { useLeadsModule } from 'leads/leads-module-hook'
import { ILead } from 'leads/leads.types'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { getLeadDetailsPageTitle } from './leads.constants'

export const LeadsDetailsPage: FC<IXtMode> = ({ mode }) => {
  const { LeadsService, LeadDialogForm } = useLeadsModule()
  const [contactNotFound, setContactNotFound] = useState(false)
  const { contactNumber } = useParams<{ contactNumber: string }>()
  const [contactState, setContactState] = useState<{ loading: boolean; contactData: ILead | null }>({
    loading: false,
    contactData: null,
  })
  useDocumentTitle(getLeadDetailsPageTitle(contactNumber))
  const init = async (): Promise<void> => {
    try {
      if (contactNumber) {
        setContactState((prev) => ({ ...prev, loading: true }))
        const contactData = await LeadsService.get(contactNumber)
        setContactState({ contactData, loading: false })
      }
      setContactNotFound(false)
    } catch (error) {
      setContactNotFound(true)
    }
  }

  const history = useHistory()

  useEffect(() => void init(), [contactNumber])
  useEffect(() => {
    if (contactNotFound) throw Error(`Lead ${contactNumber} not found.`)
  }, [contactNotFound])
  const onCancel: VoidFunction = () => history.push(`${companiesRoutePath}/${companiesRoutes.leads}`)

  return (
    <div className="xt-content">
      <LeadDialogForm serverSideUpdate loading={contactState.loading} contact={contactState.contactData} mode={mode} onClose={onCancel} />
    </div>
  )
}
