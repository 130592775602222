import React, { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { IObjectWithId } from 'common/common.types'
import { ITablePagination } from 'common/types/table.types'
import * as styles from './xt-nested-list.module.scss'
import { AccordionTheme, XtAccordion } from '../xt-accordion/xt-accordion'
import { LoadingSpinner } from '../loading-spinner'

interface IXtNestedList<T extends IObjectWithId> {
  data: T[]
  summaryTemplate(data: T): React.ReactElement
  expandedTemplate(data: T): React.ReactElement
  hidden?: boolean
  pagination: ITablePagination
  loading: boolean
}

export function XtNestedList<TNestedListValue extends IObjectWithId>({
  data,
  summaryTemplate,
  expandedTemplate,
  hidden,
  pagination,
  loading,
}: IXtNestedList<TNestedListValue>): React.ReactElement {
  const { loadMore, canLoadMore } = pagination

  const [expandedItemId, setExpandedItemId] = useState<string | null>(null)

  return (
    <>
      {loading && <LoadingSpinner />}
      <div hidden={hidden || loading} id="xt-nested-list-scrollable" className={styles.xtNestedListContainer}>
        {!data.length && <div className="xt-table-empty-container">No data available.</div>}
        <InfiniteScroll
          scrollableTarget="xt-nested-list-scrollable"
          className={styles.xtNestedList}
          next={loadMore}
          hasMore={canLoadMore}
          dataLength={data.length}
          loader={<LoadingSpinner />}
        >
          {data.map((nestedValue) => (
            <XtAccordion
              key={nestedValue.id}
              theme={AccordionTheme.Light}
              summary={summaryTemplate(nestedValue)}
              summaryContentClassName={styles.xtAccordionSummary}
              expanded={expandedItemId === String(nestedValue.id)}
              onChange={(expanded) => setExpandedItemId(expanded ? String(nestedValue.id) : null)}
            >
              {expandedItemId === String(nestedValue.id) && (
                <div className={styles.xtNestedListTemplate}>{expandedTemplate(nestedValue)}</div>
              )}
            </XtAccordion>
          ))}
        </InfiniteScroll>
      </div>
    </>
  )
}
