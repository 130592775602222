import { IObjectWithId } from 'common/common.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'

export enum InventoryHistoryField {
  TransactionTime = 'transaction_date',
  CreatedTime = 'created',
  Site = 'site',
  ItemNumber = 'item_number',
  Type = 'transaction_type',
  Order = 'order_location',
  UOM = 'uom',
  Qty = 'transaction_qty',
  Value = 'transaction_value',
  FromArea = 'location_from',
  QtyBefore = 'qoh_before',
  ToArea = 'location_to',
  QohAfter = 'qoh_after',
  CostMethod = 'cost_method',
  ValueBefore = 'value_before',
  ValueAfter = 'value_after',
  TransactionUser = 'transaction_user',
}

export enum InventoryHistoryLabel {
  TransactionTime = 'Transaction Time',
  CreatedTime = 'Created Time',
  Site = 'Site',
  ItemNumber = 'Item Number',
  Type = 'Type',
  Order = 'Order #',
  UOM = 'UOM',
  Qty = 'Qty',
  Value = 'Value',
  FromArea = 'From Area',
  QtyBefore = 'QOH Before',
  ToArea = 'To Area',
  QohAfter = 'QOH After',
  CostMethod = 'Cost Method',
  ValueBefore = 'Value Before',
  ValueAfter = 'Value After',
  TransactionUser = 'User',
}

export enum InventoryHistoryDetailField {
  DetailLocation = 'detail_location',
  Qty = 'detail_qty',
  QohBefore = 'detail_qty_before',
  QohAfter = 'detail_qty_after',
}

export enum InventoryHistoryDetailLabel {
  DetailLocation = 'Location/Lot/Serial',
  Qty = 'Qty',
  QohBefore = 'QOH Before',
  QohAfter = 'QOH After',
}

export interface IInventoryHistory {
  [InventoryHistoryField.TransactionTime]: string
  [InventoryHistoryField.CreatedTime]: string
  [InventoryHistoryField.Site]: string
  [InventoryHistoryField.ItemNumber]: string
  [InventoryHistoryField.Type]: string
  [InventoryHistoryField.Order]: string
  [InventoryHistoryField.UOM]: string
  [InventoryHistoryField.Qty]: number
  [InventoryHistoryField.Value]: number
  [InventoryHistoryField.FromArea]: string
  [InventoryHistoryField.QtyBefore]: number
  [InventoryHistoryField.ToArea]: string
  [InventoryHistoryField.QohAfter]: number
  [InventoryHistoryField.CostMethod]: string
  [InventoryHistoryField.ValueBefore]: number
  [InventoryHistoryField.ValueAfter]: number
  [InventoryHistoryField.TransactionUser]: string
}

export interface IInventoryHistoryDetail {
  [InventoryHistoryDetailField.DetailLocation]: string
  [InventoryHistoryDetailField.Qty]: number
  [InventoryHistoryDetailField.QohBefore]: number
  [InventoryHistoryDetailField.QohAfter]: number
}

export enum InventoryHistoryFilterField {
  ClassCode = 'classCode',
  ClassCodePattern = 'classCodePattern',
  ItemNumber = 'item_number',
  ItemGroup = 'itemGroup',
  ItemGroupPattern = 'itemGroupPattern',
  OrderNumberPattern = 'orderNumber',
  PlannerCode = 'plannerCode',
  PlannerCodePattern = 'plannerCodePattern',
  SalesOrder = 'sales_order',
  TransactionType = 'transaction_type',
  TransferOrder = 'transfer_order',
  WorkOrder = 'work_order',
  Sites = 'site',
  ABCClass = 'abc_class',
  StartDate = 'startDate',
  EndDate = 'endDate',
  ShowDetail = 'showDetail',
}

export enum InventoryHistoryFilterLabel {
  ClassCode = 'Class Code',
  ClassCodePattern = 'Class Code Pattern',
  ItemNumber = 'Item Number',
  ItemGroup = 'Item Group',
  ItemGroupPattern = 'Item Group Pattern',
  OrderNumberPattern = 'Order Number Pattern',
  PlannerCode = 'Planner Code',
  PlannerCodePattern = 'Planner Code Pattern',
  SalesOrder = 'Sales Order',
  TransactionType = 'Transaction Type',
  TransferOrder = 'Transfer Order',
  WorkOrder = 'Work Order',
  Sites = 'Sites',
  ABCClass = 'ABC Class',
  StartDate = 'Start Date',
  EndDate = 'End Date',
}

export type InventoryHistoryTableItem = IInventoryHistory & IObjectWithId

export type InventoryHistoryDetailTableItem = IInventoryHistoryDetail & IObjectWithId

export type InventoryHistoryFilters = {
  [InventoryHistoryFilterField.ClassCode]?: string | null
  [InventoryHistoryFilterField.ClassCodePattern]?: string | null
  [InventoryHistoryFilterField.ItemNumber]?: string | null
  [InventoryHistoryFilterField.ItemGroup]?: string | null
  [InventoryHistoryFilterField.ItemGroupPattern]?: string | null
  [InventoryHistoryFilterField.OrderNumberPattern]?: string | null
  [InventoryHistoryFilterField.PlannerCode]?: string | null
  [InventoryHistoryFilterField.PlannerCodePattern]?: string | null
  [InventoryHistoryFilterField.SalesOrder]?: string | null
  [InventoryHistoryFilterField.TransactionType]?: string | null
  [InventoryHistoryFilterField.TransferOrder]?: string | null
  [InventoryHistoryFilterField.WorkOrder]?: string | null
  [InventoryHistoryFilterField.Sites]?: string | null
  [InventoryHistoryFilterField.ABCClass]?: string | null
  [InventoryHistoryFilterField.StartDate]?: string | null
  [InventoryHistoryFilterField.EndDate]?: string | null
  [InventoryHistoryFilterField.ShowDetail]?: boolean
}

export type InventoryHistoryFormState = {
  [InventoryHistoryFilterField.ClassCode]: IXtAutocompleteOption | null
  [InventoryHistoryFilterField.ClassCodePattern]: string | null
  [InventoryHistoryFilterField.ItemNumber]: IXtAutocompleteOption | null
  [InventoryHistoryFilterField.ItemGroup]: IXtAutocompleteOption | null
  [InventoryHistoryFilterField.ItemGroupPattern]: string
  [InventoryHistoryFilterField.OrderNumberPattern]: string
  [InventoryHistoryFilterField.PlannerCode]: IXtAutocompleteOption | null
  [InventoryHistoryFilterField.PlannerCodePattern]: string | null
  [InventoryHistoryFilterField.SalesOrder]: IXtAutocompleteOption | null
  [InventoryHistoryFilterField.TransactionType]: IXtAutocompleteOption | null
  [InventoryHistoryFilterField.TransferOrder]: IXtAutocompleteOption | null
  [InventoryHistoryFilterField.WorkOrder]: IXtAutocompleteOption | null
  [InventoryHistoryFilterField.Sites]: IXtAutocompleteOption | null
  [InventoryHistoryFilterField.ABCClass]: IXtAutocompleteOption | null
  [InventoryHistoryFilterField.StartDate]: Date | null
  [InventoryHistoryFilterField.EndDate]: Date | null
}

export interface IInventoryHistorySharedParams<TFilters> {
  onChange: (filters: TFilters) => void
  filters: TFilters
  disabled: boolean
}

export type InventoryHistoryListParams = IInventoryHistorySharedParams<InventoryHistoryFormState>
