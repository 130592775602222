import * as Yup from 'yup'
import { validationMessage, validationRegExp } from 'common/validation/validation'
import { CreateAccountFormField } from './create-account-dialog.constants'

export const CreateAccountFormSchema = Yup.object().shape({
  [CreateAccountFormField.AccountName]: Yup.string().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [CreateAccountFormField.Phone]: Yup.string().matches(validationRegExp.phone, {
    message: validationMessage.invalidPhone,
    excludeEmptyString: true,
  }),
  [CreateAccountFormField.Postalcode]: Yup.string()
    .nullable()
    .transform((originalValue) => {
      if (!originalValue) {
        return null
      }
      return originalValue
    })
    .matches(validationRegExp.postalCode, {
      message: validationMessage.invalidPostalCode,
      excludeEmptyString: true,
    })
    .min(3, validationMessage.invalidPostalCode)
    .max(15, validationMessage.invalidPostalCode),
  [CreateAccountFormField.Email]: Yup.string().matches(validationRegExp.email, {
    message: validationMessage.invalidEmail,
    excludeEmptyString: true,
  }),
})
