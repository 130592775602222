import * as React from 'react'
import { FC, useState, useMemo, useEffect } from 'react'
import { useProductsModule } from 'products/products-module-hook'
import { ItemOption } from 'products/items/items.types'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { XtAutocomplete } from 'components/controls/xt-autocomplete/xt-autocomplete'
import { XtInput } from 'components/controls/xt-input/xt-input'
import { XtRadioGroup } from 'components/controls/xt-radio-group/xt-radio-group'
import { DocumentOption } from 'documents/documents.types'
import { useSharedModule } from 'shared/shared-module-hook'
import { XtPageFilter } from 'components/pagefilter/pagefilter'
import { usePageFilter } from 'components/pagefilter/pagefilter.utils'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { defaultItemNumberFilters } from 'inventory/inventory.constants'
import * as styles from './inventory-history-by-lot-serial-list-form.module.scss'
import {
  InventoryHistoryByLotSerialField,
  InventoryHistoryByLotSerialLabel,
  InventoryHistoryByLotSerialListParams,
  InventoryHistoryByLotSerialState,
} from '../inventory-history-by-lot-serial-list.types'
import { InventoryHistoryByLotSerialFormFilter } from './inventory-history-by-lot-serial-form-filter/inventory-history-by-lot-serial-form-filter'
import { defaultHistoryFiltersValues } from './inventory-history-by-lot-serial-form-filter/inventory-history-by-lot-serial-form-filter.constants'

export const InventoryHistoryByLotSerialListForm: FC<InventoryHistoryByLotSerialListParams> = ({ loading, onChange, filters }) => {
  const { XtItemNumber, ItemsUtilsService } = useProductsModule()
  const { DocumentsUtilsService } = useDocumentsModule()
  const { useDialog } = useSharedModule()

  const historyLotListFilters = usePageFilter<InventoryHistoryByLotSerialState>(PageFilterMapping.HistoryLot)

  const [itemOption, setItemOption] = useState<ItemOption | null>(null)
  const [lotSerialOption, setLotSerialOption] = useState<DocumentOption | null>(null)
  const [lotSerialPetter, setLotSerialPetter] = useState<string>('')
  const [lotSerialValueOption, setLotSerialValueOption] = useState<string>(InventoryHistoryByLotSerialField.LotSerial)
  const { open: filterDialogOpen, closeDialog: closeFilterDialogDialog, openDialog: openFilterDialogDialog } = useDialog()

  const siteFilters = useMemo(() => ({ [InventoryHistoryByLotSerialField.ItemNumber]: itemOption?.item_number }), [itemOption?.item_number])

  const isLotSerialOption = useMemo(() => lotSerialValueOption === InventoryHistoryByLotSerialField.LotSerial, [lotSerialValueOption])

  useEffect(() => {
    if (historyLotListFilters.lastUsedFilter) {
      setItemOption(historyLotListFilters.lastUsedFilter[InventoryHistoryByLotSerialField.ItemNumber] ?? null)
      setLotSerialOption(historyLotListFilters.lastUsedFilter[InventoryHistoryByLotSerialField.LotSerial] ?? null)
    }
  }, [historyLotListFilters.lastUsedFilter])

  const onItemChange = (option: ItemOption | null): void => {
    setItemOption(option)
    const param = {
      ...filters,
      [InventoryHistoryByLotSerialField.ItemNumber]: option,
      [InventoryHistoryByLotSerialField.LotSerial]: isLotSerialOption ? lotSerialOption ?? null : null,
      [InventoryHistoryByLotSerialField.LotSerialPatter]: !isLotSerialOption ? lotSerialPetter ?? null : null,
    }
    onChange(param)
    void historyLotListFilters.handleLastUsedFilter(param)
  }

  const onLotSerialChange = (option: DocumentOption | null): void => {
    setLotSerialOption(option)
    const param = {
      ...filters,
      [InventoryHistoryByLotSerialField.LotSerial]: option,
      [InventoryHistoryByLotSerialField.ItemNumber]: itemOption ?? null,
      [InventoryHistoryByLotSerialField.LotSerialPatter]: null,
    }
    onChange(param)
    void historyLotListFilters.handleLastUsedFilter(param)
  }

  const onLotSerialPatternChange = (value: string): void => {
    setLotSerialPetter(value)
    const param = {
      ...filters,
      [InventoryHistoryByLotSerialField.LotSerialPatter]: value,
      [InventoryHistoryByLotSerialField.ItemNumber]: itemOption ?? null,
      [InventoryHistoryByLotSerialField.LotSerial]: null,
    }
    onChange(param)
    void historyLotListFilters.handleLastUsedFilter(param)
  }

  const options = [
    {
      label: InventoryHistoryByLotSerialLabel.LotSerial,
      id: InventoryHistoryByLotSerialField.LotSerial,
      template: (
        <XtAutocomplete
          value={lotSerialOption}
          loadOptions={DocumentsUtilsService.loadDocumentLotSerialOptions}
          onChange={onLotSerialChange}
          filters={siteFilters}
          placeholder={InventoryHistoryByLotSerialLabel.LotSerial}
          disabled={!isLotSerialOption}
        />
      ),
    },
    {
      label: InventoryHistoryByLotSerialLabel.LotSerialPattern,
      id: InventoryHistoryByLotSerialField.LotSerialPatter,
      template: (
        <XtInput
          label={InventoryHistoryByLotSerialLabel.LotSerialPattern}
          value={lotSerialPetter}
          onChange={onLotSerialPatternChange}
          disabled={isLotSerialOption}
        />
      ),
    },
  ]

  return (
    <div className={styles.formContent}>
      <XtItemNumber
        loadOptions={ItemsUtilsService.loadItemOptions}
        isEditMode
        className={styles.itemNumber}
        value={itemOption}
        onChange={onItemChange}
        disabled={loading}
        loading={loading}
        filters={defaultItemNumberFilters}
      />
      <XtRadioGroup value={lotSerialValueOption} onChange={setLotSerialValueOption} options={options} className={styles.radioGroup} />
      <XtPageFilter
        state={historyLotListFilters}
        defaultFilterValues={defaultHistoryFiltersValues}
        filter={onChange}
        tableFilters={filters}
        onClick={openFilterDialogDialog}
        CustomFilterDialog={() => (
          <InventoryHistoryByLotSerialFormFilter
            filters={filters}
            open={filterDialogOpen}
            onClose={closeFilterDialogDialog}
            onSubmit={onChange}
          />
        )}
      />
    </div>
  )
}
