import React, { FC, useCallback, useEffect, useState } from 'react'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { ProfileDialog } from 'profile/profile'
import { useAuthModule } from 'auth/auth-module-hook'
import * as styles from './sidebar.module.scss'
import { getSidebarOptions } from './sidebar.utils'
import { IXtSidebarOption, IXtSidebarParams } from './sidebar.types'
import { SidebarService } from './sidebar.service'
import { XtSidebarStatic } from './sidebar-panel-static/sidebar-panel-static'
import { XtSidebarPanelDynamic } from './sidebar-panel-dynamic/sidebar-panel-dynamic'

export const XtSidebar: FC<IXtSidebarParams> = ({ staticMode }) => {
  const { AuthService } = useAuthModule()
  const [sidebarShown, setSidebarShown] = useState<boolean>(false)
  const [sidebarOptions, setSidebarOptions] = useState<IXtSidebarOption[]>(getSidebarOptions())
  const [isProfileDetailsDialogOpen, openProfileDetailsDialog] = useState<boolean>(false)

  useEffect(() => {
    const sub = AuthService.isAuthenticated$.subscribe(() => {
      setSidebarOptions(getSidebarOptions())
    })

    const sidebarServiceSub = SidebarService.sidebar$.subscribe(({ shown }) => {
      setSidebarShown(shown)
    })

    return () => {
      sub.unsubscribe()
      sidebarServiceSub.unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (!staticMode) {
      SidebarService.close()
    }
  }, [staticMode])

  const onOptionClick = useCallback(() => {
    if (!staticMode) {
      SidebarService.close()
    }
  }, [staticMode])

  const toggleProfile = useCallback(() => {
    openProfileDetailsDialog((prev) => !prev)
    SidebarService.close()
  }, [])

  const onProfileKeyDown = useCallback(
    ({ key }: React.KeyboardEvent<HTMLDivElement>) => {
      if (key === 'Enter') {
        toggleProfile()
      }
    },
    [toggleProfile]
  )

  const onClose = useCallback<VoidFunction>(() => {
    SidebarService.close()
  }, [])

  return (
    <div className={styles.sidebar}>
      <XtDialog open={isProfileDetailsDialogOpen} fullScreen={false} animation={XtDialogAnimation.SlideAnimation}>
        <ProfileDialog onClose={toggleProfile} />
      </XtDialog>
      {staticMode ? (
        <XtSidebarStatic
          sidebarOptions={sidebarOptions}
          onOptionClick={onOptionClick}
          toggleProfile={toggleProfile}
          onProfileKeyDown={onProfileKeyDown}
        />
      ) : (
        <XtSidebarPanelDynamic
          shown={sidebarShown}
          sidebarOptions={sidebarOptions}
          onOptionClick={onOptionClick}
          toggleProfile={toggleProfile}
          onProfileKeyDown={onProfileKeyDown}
          onClose={onClose}
        />
      )}
    </div>
  )
}
