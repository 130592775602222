import React, { FC, memo } from 'react'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { IXtTab } from 'components/xt-tabs/xt-tabs.types'
import { IRemarksHook } from 'comments/remarks/remarks-hook'
import { DocumentType } from 'documents/documents.types'
import { useCommentsModule } from 'comments/comments-module-hook'
import { MultipleFormRegister } from 'common/hooks/form/multiple-form-manager/multiple-form-manager.types'
import { convertMode } from 'common/utils/mode.utils'
import { XtMode } from 'common/common.types'
import { ControlMethodOption, IItem, IItemSite } from 'products/items/items.types'
import * as styles from '../sites-details-dialog.module.scss'
import { SitesDetailsDialogTabTitle } from './sites-details-dialog-tabs.constants'
import { SitesDetailsDialogInventory } from './sites-details-dialog-inventory/sites-details-dialog-inventory'
import { SitesDetailsDialogLocation } from './sites-details-dialog-location/sites-details-dialog-location'
import { SitesDetailsDialogPlanning } from './sites-details-dialog-planning/sites-details-dialog-planning'
import { ISitesDetailsDialogCombinedState } from '../sites-details-dialog.types'
import { SitesDetailsSiteOption } from '../sites-details-dialog-main/sites-details-dialog-main.types'

interface ISitesDetailsDialogTabs {
  register: MultipleFormRegister<ISitesDetailsDialogCombinedState>
  mode: XtMode
  item: IItem
  itemSite: IItemSite | null
  fractional: boolean
  controlMethod: ControlMethodOption
  currentSite: SitesDetailsSiteOption | null

  markAsInvalidInventoryTab: boolean
  markAsInvalidLocationTab: boolean
  markAsInvalidPlanningTab: boolean

  remarks: IRemarksHook
}

export const SitesDetailsDialogTabs: FC<ISitesDetailsDialogTabs> = memo(
  ({
    remarks,
    item,
    itemSite,
    mode,
    register,
    fractional,
    controlMethod,
    currentSite,
    markAsInvalidInventoryTab,
    markAsInvalidPlanningTab,
    markAsInvalidLocationTab,
  }) => {
    const { XtRemarks } = useCommentsModule()
    const { isViewMode } = convertMode(mode)

    const tabs: IXtTab[] = [
      {
        name: SitesDetailsDialogTabTitle.Inventory,
        markAsInvalid: markAsInvalidInventoryTab,
        template: <SitesDetailsDialogInventory mode={mode} register={register} itemSite={itemSite} fractional={fractional} />,
      },
      {
        name: SitesDetailsDialogTabTitle.Location,
        markAsInvalid: markAsInvalidLocationTab,
        template: (
          <SitesDetailsDialogLocation
            itemSite={itemSite}
            controlMethod={controlMethod}
            mode={mode}
            register={register}
            currentSite={currentSite}
          />
        ),
      },
      {
        name: SitesDetailsDialogTabTitle.Planning,
        markAsInvalid: markAsInvalidPlanningTab,
        template: <SitesDetailsDialogPlanning mode={mode} register={register} item={item} itemSite={itemSite} />,
      },
      {
        name: SitesDetailsDialogTabTitle.Remarks,
        template: (
          <XtRemarks
            source={DocumentType.Warehouse}
            loadMore={remarks.loadMore}
            canLoadMore={remarks.canLoadMore}
            textAreaOnChange={remarks.setNotes}
            textAreaValue={remarks.notes}
            textAreaName="Notes"
            onCommentsUpdate={remarks.updateComment}
            onCommentsSave={remarks.saveComment}
            comments={remarks.comments}
            username={remarks.username}
            disabled={isViewMode}
          />
        ),
      },
    ]

    return <XtTabs className={styles.sitesTabs} tabs={tabs} />
  }
)
