import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { ShippingModule } from 'shipping/shipping.module'
import { maintainShippingModule } from './maintain-shipping.constants'

export type MaintainShippingModuleDefinition = typeof import('./maintain-shipping.exports')

export const MaintainShippingModule: ILazyModule<MaintainShippingModuleDefinition> = {
  name: maintainShippingModule,
  resolver: () => import('./maintain-shipping.exports'),

  dependencies: [ShippingModule],
}
