import React, { FC, memo } from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { IProgressStepper } from './progress-stepper.types'
import { StepperIcon } from './stepper-icon'
import * as styles from './progress-stepper.module.scss'

export const ProgressStepper: FC<IProgressStepper> = memo(({ state }) => {
  return (
    <div className={styles.stepperContainer}>
      <Stepper activeStep={state.activeStep.id - 1} className={styles.progressStepper}>
        {state.activeModule.steps.map((step) => {
          return (
            <Step key={step.label}>
              <StepLabel StepIconComponent={StepperIcon}>{step.label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </div>
  )
})
