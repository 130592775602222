import { PermissionGuard } from '../auth/permission.guard'
import { homeRoutePath } from '../home/home.constants'
import { UserPermission } from '../auth/auth.types'

export class TasksGuard extends PermissionGuard {
  public redirectTo = homeRoutePath
  public permissions = [
    UserPermission.ViewAllTaskItems,
    UserPermission.ViewPersonalTaskItems,
    UserPermission.MaintainAllTaskItems,
    UserPermission.MaintainPersonalTaskItems,
  ]
}
