import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { XtMode } from 'common/common.types'
import { IAppliedCharacteristic } from '../characteristics.types'
import { characteristicValueConverter, convertCharacteristicDefault } from '../characteristics.utils'
import { AppliedCharacteristicAction, ICharacteristicDialogData } from './characteristics.types'

export const defaultCharacteristicDialogData: ICharacteristicDialogData = {
  mode: XtMode.New,
  characteristic: null,
  value: '',
  id: null,
}

export const AppliedCharacteristicActionsEditMode: IXtTableCellAction[] = [
  {
    name: AppliedCharacteristicAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: AppliedCharacteristicAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const characteristicColumns: IXtTableColumn<IAppliedCharacteristic>[] = [
  {
    id: 'characteristic',
    field: 'characteristic',
    headerName: 'Characteristic',
    flex: '2 0 260px',
    width: 260,
  },
  {
    id: 'characteristic_group',
    field: 'characteristic_group',
    headerName: 'Group',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'characteristic_value',
    field: 'characteristic_value',
    headerName: 'Value',
    flex: '2 0 260px',
    width: 260,
    converter: characteristicValueConverter,
  },
  {
    id: 'default',
    field: 'default_characteristic',
    headerName: 'Default',
    flex: '1 0 140px',
    width: 140,
    converter: convertCharacteristicDefault,
  },
]
