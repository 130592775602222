import * as React from 'react'
import { FC, useCallback } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { cls } from 'common/utils/utils'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { LoaderMessage, confirmationMessages, xsMq } from 'common/constants'
import { useTable } from 'common/hooks/useTable'
import { XtList } from 'components/list/list'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { UserPermission } from 'auth/auth.types'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { getRoutingDetailsActions } from './routing-list.utils'
import { productsRoutePath, productsRoutes } from '../../products.constants'
import { RoutingListActionsEditMode, routingListColumns, routingListPageTitle } from './routing-list.constants'
import { IRoutingListTableRow, RoutingListAction, RoutingListCheckboxFilters } from './routing-list.types'
import * as styles from './routing-list.module.scss'
import { RoutingDetailsMode } from '../routing.types'
import { useProductsModule } from '../../products-module-hook'

// TODO user roles
export const RoutingList: FC = () => {
  const { RoutingService, RoutingUtilsService, ItemsUtilsService } = useProductsModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { PermissionsService } = useAuthModule()
  useDocumentTitle(routingListPageTitle)
  const history = useHistory()
  const isMobile = useMediaQuery(xsMq)
  const { path } = useRouteMatch()

  const { open, openDialog, closeDialog, itemId } = useConfirmationDialog<string>()

  const { state, setLoading, refresh, filter, pagination, sort } = useTable(
    { showActiveBOOsOnly: true, showActiveItemsOnly: true },
    RoutingUtilsService.fetchRoutings
  )

  const canCreateUpdateDelete = PermissionsService.hasPermission(UserPermission.MaintainBOOs)
  const canPrintPermission = PermissionsService.hasSomePermission([UserPermission.ViewBOMs, UserPermission.MaintainBOMs])

  const onShowFilterChange = (name: RoutingListCheckboxFilters) => (checked: boolean): void => {
    filter({ ...state.filters, [name]: !checked })
  }

  const openRoutingItem: (itemNumber: string, mode: RoutingDetailsMode) => void = (itemNumber, mode) => {
    history.push(`${path}/${itemNumber}/${mode}`)
  }

  const handleAction = useCallback<(item: IRoutingListTableRow, action: RoutingListAction) => void>(
    ({ item_number }, action: RoutingListAction) => {
      // TODO integration api
      switch (action) {
        case RoutingListAction.View:
          return openRoutingItem(item_number, RoutingDetailsMode.View)
        case RoutingListAction.Edit:
          return openRoutingItem(item_number, RoutingDetailsMode.Edit)
        case RoutingListAction.Delete:
          return openDialog(item_number)
        case RoutingListAction.Print:
          return ItemsUtilsService.printItem(item_number) // TODO: change me for Routings
        default:
          return null
      }
    },
    [ItemsUtilsService, openDialog, openRoutingItem]
  )

  const handleDeletion = useCallback<() => Promise<void>>(async () => {
    closeDialog()
    if (itemId) {
      try {
        setLoading(true)
        const message = await RoutingService.delete(itemId)
        ToastService.showSuccess(message)
        await refresh()
      } catch (error) {
        ErrorHandler.handleError(error)
      } finally {
        setLoading(false)
      }
    }
  }, [closeDialog, itemId, setLoading, RoutingService, refresh])

  const navigateToRoutingItem = useCallback(
    ({ item_number }) => openRoutingItem(item_number, canCreateUpdateDelete ? RoutingDetailsMode.Edit : RoutingDetailsMode.View),
    [canCreateUpdateDelete, openRoutingItem]
  )

  return (
    <div>
      <XtConfirmationDialog
        open={open}
        message={confirmationMessages.deleted}
        title="Delete Routing"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeDialog}
      />
      <div className={cls('routingListMuiOverrides', 'xt-content', styles.routingListContent)}>
        <div className={cls('xt-section-border', styles.routingListHeader)}>
          <h1 className="xt-page-title">Routing</h1>
          <XtResponsiveButton
            disabled={state.loading || !canCreateUpdateDelete}
            label="New Routing"
            className={styles.newButton}
            icon={SvgIconIds.ADD_CIRCLE}
            onClick={() => history.push(`${productsRoutePath}/${productsRoutes.routing}/new`)}
          />
        </div>

        <div hidden={isMobile} className={styles.routingListFilters}>
          <XtCheckbox
            label="Show Inactive Routings"
            value={!state.filters.showActiveBOOsOnly}
            onChange={onShowFilterChange(RoutingListCheckboxFilters.ShowActiveBOOsOnly)}
            disabled={state.loading}
          />
          <XtCheckbox
            label="Show Inactive Items"
            value={!state.filters.showActiveItemsOnly}
            onChange={onShowFilterChange(RoutingListCheckboxFilters.ShowActiveItemsOnly)}
            disabled={state.loading}
          />
        </div>
        <XtList
          actions={RoutingListActionsEditMode}
          onRowClick={navigateToRoutingItem}
          onAction={handleAction}
          isMobile={isMobile}
          pagination={pagination}
          columns={routingListColumns}
          loading={state.loading}
          data={state.data}
          getItemActions={getRoutingDetailsActions(canCreateUpdateDelete, canPrintPermission)}
          sortOptions={state.sortOptions}
          onColumnHeaderClick={sort}
          loadMessage={LoaderMessage.Routing}
        />
      </div>
    </div>
  )
}
