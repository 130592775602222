import { useCallback, useEffect } from 'react'
import { ICommentsHook, useComments } from '../comments/comments-hook'
import { DocumentType } from '../../documents/documents.types'
import { useFormControlState } from '../../common/hooks/form/form-control.hook'
import { CommentItem } from '../comment/comment.types'

export interface IRemarksHook extends ICommentsHook {
  notes: string
  additionalNotes: string
  isDirty: boolean

  /**
   * Resets the remarks state. Should be used to reset isDirty state.
   * @param notes
   * @param additionalNotes
   * @param comments
   */
  reset(comments: CommentItem[], notes?: string, additionalNotes?: string): void
  reset(sourceNumber: string, notes?: string, additionalNotes?: string): void
  setNotes(notes: string): void
  setAdditionalNotes(notes: string): void
}

export function useRemarks(
  source: DocumentType,
  sourceNumber?: string,
  initialComments: CommentItem[] = [],
  initialNotes: string = '',
  initialAdditionalNotes: string = ''
): IRemarksHook {
  const commentsState = useComments(source, sourceNumber, initialComments)
  const notesState = useFormControlState<string>(initialNotes)
  const additionalNotesState = useFormControlState<string>(initialAdditionalNotes)

  const { resetComments } = commentsState
  const { reset: resetNotes } = notesState
  const { reset: resetAdditionalNotes } = additionalNotesState

  const reset = useCallback(
    (commentsData, notes = '', additionalNotes = '') => {
      resetNotes(notes)
      resetAdditionalNotes(additionalNotes)
      resetComments(commentsData)
    },
    [resetAdditionalNotes, resetComments, resetNotes]
  )

  useEffect(() => {
    resetNotes(initialNotes)
    resetAdditionalNotes(initialAdditionalNotes)
    resetComments(initialComments)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    notes: notesState.value,
    additionalNotes: additionalNotesState.value,
    setNotes: notesState.setValue,
    setAdditionalNotes: additionalNotesState.setValue,
    reset,
    isDirty: commentsState.isDirtyComments || notesState.isDirty || additionalNotesState.isDirty,
    ...commentsState,
  }
}
