// extracted by mini-css-extract-plugin
export var root = "shipping-extended-template_root__MUCuA";
export var xtContent = "shipping-extended-template_xt-content__UZl24";
export var xtContentWithRemarks = "shipping-extended-template_xt-content-with-remarks__oyAbQ";
export var muiFormHelperTextRoot = "shipping-extended-template_MuiFormHelperText-root__HtRbU";
export var xtListPageContainer = "shipping-extended-template_xt-list-page-container__t1uiu";
export var muiInputBaseRoot = "shipping-extended-template_MuiInputBase-root__Xua+v";
export var muiTextFieldRoot = "shipping-extended-template_MuiTextField-root__LSiH-";
export var muiTablePaginationRoot = "shipping-extended-template_MuiTablePagination-root__+iATg";
export var muiTablePaginationSelectRoot = "shipping-extended-template_MuiTablePagination-selectRoot__IW0VK";
export var muiTablePaginationSelect = "shipping-extended-template_MuiTablePagination-select__-CA6F";
export var muiStepperRoot = "shipping-extended-template_MuiStepper-root__ruYaN";
export var muiStepperHorizontal = "shipping-extended-template_MuiStepper-horizontal__GCLsj";
export var muiStepIconRoot = "shipping-extended-template_MuiStepIcon-root__ELoP-";
export var muiStepIconActive = "shipping-extended-template_MuiStepIcon-active__lai5j";
export var muiStepIconCompleted = "shipping-extended-template_MuiStepIcon-completed__iaunt";
export var muiListItemRoot = "shipping-extended-template_MuiListItem-root__lQRre";
export var muiSelected = "shipping-extended-template_Mui-selected__JV1Ar";
export var xtPageTitle = "shipping-extended-template_xt-page-title__z8U+Y";
export var xtSubPageTitle = "shipping-extended-template_xt-subPage-title__GQHNL";
export var xtSectionBorder = "shipping-extended-template_xt-section-border__UNV-K";
export var xtPageHeader = "shipping-extended-template_xt-page-header__nkubi";
export var xtDialogScrollable = "shipping-extended-template_xt-dialog-scrollable__xKAnX";
export var reactToastNotificationsContainer = "shipping-extended-template_react-toast-notifications__container__0aRGJ";
export var xtScrollbarStyle = "shipping-extended-template_xt-scrollbar-style__DW8dN";
export var xtModalDetailsContent = "shipping-extended-template_xt-modal-details-content__TH5ao";
export var xtDialogDetailsContent = "shipping-extended-template_xt-dialog-details-content__x1Jjj";
export var xtLazyModuleLoaderTabContainer = "shipping-extended-template_xt-lazy-module-loader-tab-container__jMN9k";
export var xtTextTruncated = "shipping-extended-template_xt-text-truncated__pYoy5";
export var extendedTemplateContent = "shipping-extended-template_extendedTemplateContent__imJd0";
export var shipmentInformationText = "shipping-extended-template_shipmentInformationText__rBdJ4";
export var shipmentTextColumn = "shipping-extended-template_shipmentTextColumn__OgGpz";
export var xtSummaryContent = "shipping-extended-template_xt-summary-content__89AGQ";
export var xtSummaryLabel = "shipping-extended-template_xt-summary-label__iLNP8";
export var visibleHidden = "shipping-extended-template_visible-hidden__-xd5x";
export var headerBottomMargin = "shipping-extended-template_header-bottom-margin__+KQqI";
export var xtDialogHeaderLogoAlign = "shipping-extended-template_xt-dialog-header-logoAlign__ZMFmt";
export var xtStickyHeader = "shipping-extended-template_xt-sticky-header__3EjD0";
export var noBorder = "shipping-extended-template_no-border__Ilnyg";
export var muiOutlinedInputNotchedOutline = "shipping-extended-template_MuiOutlinedInput-notchedOutline__BAnNa";
export var shipmentInformationMainSection = "shipping-extended-template_shipmentInformationMainSection__Wx1zT";
export var shipmentInformationControlSection = "shipping-extended-template_shipmentInformationControlSection__OfCMt";