import { Overwrite } from 'utility-types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { IAttachedDocumentWithFile } from 'documents/documents.types'
import { IProspect, IProspectCreatePayload, IProspectUpdatePayload } from '../prospects.types'
import { IProspectTableItem } from '../prospects-list/prospects-list.types'

export interface IProspectsServices {
  ProspectsService: IProspectsService
  ProspectsUtilsService: IProspectsUtilsService
}

export enum ProspectFiltersLabel {
  ShowInactive = 'Show Inactive',
  ProspectNumber = 'Prospect Number Pattern',
  ProspectName = 'Prospect Name Pattern',
  Owner = 'Owner',
  AssignedTo = 'Assigned To',
  Source = 'Source',
  ContactName = 'Contact Name Pattern',
  City = 'City Pattern',
  State = 'State Pattern',
}

export enum ProspectFiltersFieldName {
  ShowInactive = 'showInactive',
  ProspectNumber = 'prospect_number',
  ProspectName = 'prospect_name',
  Owner = 'owner',
  AssignedTo = 'assignedto',
  Source = 'source',
  ContactName = 'contact_name',
  City = 'city',
  State = 'state',
  LastUpdated = 'lastupdated',
  ShowDetail = 'showDetail',
}

export interface IProspectFilters {
  [ProspectFiltersFieldName.ProspectNumber]?: string | null
  [ProspectFiltersFieldName.ProspectName]?: string | null
  [ProspectFiltersFieldName.Owner]?: string | null
  [ProspectFiltersFieldName.AssignedTo]?: string | null
  [ProspectFiltersFieldName.Source]?: string | null
  [ProspectFiltersFieldName.ContactName]?: string | null
  [ProspectFiltersFieldName.City]?: string | null
  [ProspectFiltersFieldName.State]?: string | null
  [ProspectFiltersFieldName.LastUpdated]?: string | null
  [ProspectFiltersFieldName.ShowInactive]?: boolean
  [ProspectFiltersFieldName.ShowDetail]?: boolean
}

export type ProspectFilterPanel = Overwrite<
  Required<Omit<IProspectFilters, 'showDetail' | 'lastupdated'>>,
  {
    [ProspectFiltersFieldName.Owner]: IXtAutocompleteOption | null
    [ProspectFiltersFieldName.Source]: IXtAutocompleteOption | null
    [ProspectFiltersFieldName.AssignedTo]: IXtAutocompleteOption | null
  }
>

export interface IProspectsService {
  getAll: (pagination: IPaginationParams, filters?: IProspectFilters, sortOptions?: ISortOption[]) => Promise<IPaginationData<IProspect>>
  get(crmProspect: string): Promise<IProspect>
  delete(crmProspectId: string): Promise<string>
  create(data: IProspectCreatePayload, documents: IAttachedDocumentWithFile[]): Promise<string>
  update(data: IProspectUpdatePayload): Promise<string>
  convertToCustomer(number: string): Promise<string>
}

export interface IProspectsUtilsService {
  fetchProspects(
    filters: ProspectFilterPanel,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IProspectTableItem>>
}
