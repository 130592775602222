import { Overwrite } from 'utility-types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IItem } from 'products/items/items.types'
import { SalesOrderOption } from 'sales-shared/sales-orders.types'
import { ContactOption } from 'contacts/contacts.types'
import { XtMode } from 'common/common.types'
import { IRegistration } from '../registration.types'

export enum RegistrationInfoValueLabel {
  SO = 'SO',
  Customer = 'Customer',
}

export enum RegistrationDialogTab {
  Contact = 'Contact',
  Characteristics = 'Characteristics',
  Notes = 'Notes',
}

export enum RegistrationDialogFormField {
  RegistrationNumber = 'number',
  RegistrationType = 'registration_type',
  LotSerial = 'lotserial',
  Qty = 'quantity',
  AccountNumber = 'account_number',
  AccountName = 'account_name',
  SalesOrder = 'salesorder',
  Shipment = 'shipment',
  Contact = 'registration_contact',
  RegisterDate = 'register_date',
  SaleDate = 'sale_date',
  ExpireDate = 'expire_date',
  Notes = 'notes',
}

export enum RegistrationLabel {
  RegistrationNumber = 'Registration #',
  RegistrationType = 'Type',
  ItemNumber = 'Item',
  LotSerial = 'Lot/Serial #',
  Qty = 'Qty.',
  AccountNumber = 'Account',
  AccountName = 'Account Name',
  SalesOrder = 'Sales Order',
  Shipment = 'Shipment #',
  ContactNumber = 'Contact',
  RegisterDate = 'Register Date',
  SaleDate = 'Sold Date',
  ExpireDate = 'Expire Date',
  Notes = 'Notes',
}

export interface IRegistrationDialog {
  onClose: () => void
  onSubmit: () => Promise<void>
  registrationNumber: string | null
  itemNumber: string
  lotSerialNumber: string
  mode: XtMode
}

export interface IRegistrationDialogState {
  item: IItem | null
  loading: boolean
}

export type RegistrationDialogFormFilled = Overwrite<
  Pick<
    IRegistration,
    | RegistrationDialogFormField.RegistrationNumber
    | RegistrationDialogFormField.RegistrationType
    | RegistrationDialogFormField.LotSerial
    | RegistrationDialogFormField.Qty
    | RegistrationDialogFormField.AccountNumber
    | RegistrationDialogFormField.AccountName
    | RegistrationDialogFormField.SalesOrder
    | RegistrationDialogFormField.Shipment
    | RegistrationDialogFormField.Contact
    | RegistrationDialogFormField.RegisterDate
    | RegistrationDialogFormField.SaleDate
    | RegistrationDialogFormField.ExpireDate
    | RegistrationDialogFormField.Notes
  >,
  {
    [RegistrationDialogFormField.RegistrationType]: IXtAutocompleteOption
    [RegistrationDialogFormField.AccountNumber]: IXtAutocompleteOption
    [RegistrationDialogFormField.SalesOrder]: SalesOrderOption
    [RegistrationDialogFormField.Shipment]: IXtAutocompleteOption
    [RegistrationDialogFormField.Contact]: ContactOption
    [RegistrationDialogFormField.RegisterDate]: Date
    [RegistrationDialogFormField.SaleDate]: Date
    [RegistrationDialogFormField.ExpireDate]: Date
  }
>

export type RegistrationDialogForm = Overwrite<
  RegistrationDialogFormFilled,
  {
    [RegistrationDialogFormField.RegistrationType]: IXtAutocompleteOption | null
    [RegistrationDialogFormField.Qty]: number | null
    [RegistrationDialogFormField.AccountNumber]: IXtAutocompleteOption | null
    [RegistrationDialogFormField.AccountName]: string | null
    [RegistrationDialogFormField.SalesOrder]: SalesOrderOption | null
    [RegistrationDialogFormField.Shipment]: IXtAutocompleteOption | null
    [RegistrationDialogFormField.Contact]: ContactOption | null
    [RegistrationDialogFormField.RegisterDate]: Date | null
    [RegistrationDialogFormField.SaleDate]: Date | null
    [RegistrationDialogFormField.ExpireDate]: Date | null
  }
>
