import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { loginRoutePath, newPasswordRoutePath, resetPasswordRoutePath } from './auth.constants'
import { Login } from './login/login'
import { ResetPassword } from './reset-password/reset-password'
import { NewPassword } from './reset-password/new-password/new-password'

export const AuthRoutes = () => (
  <Switch>
    <Route exact path={loginRoutePath} component={Login} />
    <Route path={resetPasswordRoutePath} component={ResetPassword} />
    {/* TODO: implement NewPassword guards */}
    <Route path={newPasswordRoutePath} component={NewPassword} />
  </Switch>
)
