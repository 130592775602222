import { CRMAccountRole } from '../../../../documents/documents.types'

export const initialRolesOptionsState = {
  listOptions: [],
  selectedOptions: [],
  loading: false,
}

export const confirmationDialogActionMessage = {
  createCustomer: 'Do you want to create a new Customer for this CRM Account?',
  createProspect: 'Do you want to create a new Prospect for this CRM Account?',
  convertProspect: 'Are you sure you want to convert this Prospect to a Customer?',
  deleteProspect: (accountNumber: string) => `Are you sure you want to delete ${accountNumber} as a Prospect?`,
  deleteCustomer: (accountNumber: string) => `Are you sure you want to delete ${accountNumber} as a Customer?`,
}

export const crmAccountRoleLabel: { [key: string]: string } = {
  [CRMAccountRole.Prospect]: 'Prospect',
  [CRMAccountRole.Vendor]: 'Vendor',
  [CRMAccountRole.TaxAuthority]: 'Tax Authority',
  [CRMAccountRole.User]: 'User',
  [CRMAccountRole.Employee]: 'Employee',
  [CRMAccountRole.SalesRep]: 'Sales Rep',
  [CRMAccountRole.Customer]: 'Customer',
  [CRMAccountRole.Competitor]: 'Competitor',
  [CRMAccountRole.Partner]: 'Partner',
}
