// extracted by mini-css-extract-plugin
export var root = "history-list_root__AmGij";
export var xtContent = "history-list_xt-content__4FB-+";
export var xtContentWithRemarks = "history-list_xt-content-with-remarks__B-sf+";
export var muiFormHelperTextRoot = "history-list_MuiFormHelperText-root__RWDUO";
export var xtListPageContainer = "history-list_xt-list-page-container__i4OVz";
export var listContent = "history-list_listContent__PedGm";
export var muiInputBaseRoot = "history-list_MuiInputBase-root__hBU+X";
export var muiTextFieldRoot = "history-list_MuiTextField-root__mFqSK";
export var muiTablePaginationRoot = "history-list_MuiTablePagination-root__QH+c9";
export var muiTablePaginationSelectRoot = "history-list_MuiTablePagination-selectRoot__KdBsu";
export var muiTablePaginationSelect = "history-list_MuiTablePagination-select__f+1aM";
export var muiStepperRoot = "history-list_MuiStepper-root__yyCud";
export var muiStepperHorizontal = "history-list_MuiStepper-horizontal__EnE8N";
export var muiStepIconRoot = "history-list_MuiStepIcon-root__uv1dD";
export var muiStepIconActive = "history-list_MuiStepIcon-active__NY96Z";
export var muiStepIconCompleted = "history-list_MuiStepIcon-completed__tGXXr";
export var muiListItemRoot = "history-list_MuiListItem-root__74Gsz";
export var muiSelected = "history-list_Mui-selected__7oFgt";
export var xtPageTitle = "history-list_xt-page-title__uklpD";
export var xtSubPageTitle = "history-list_xt-subPage-title__X+pJt";
export var xtSectionBorder = "history-list_xt-section-border__QBiZb";
export var xtPageHeader = "history-list_xt-page-header__fCj7Q";
export var xtDialogScrollable = "history-list_xt-dialog-scrollable__ktLr5";
export var reactToastNotificationsContainer = "history-list_react-toast-notifications__container__Lo9dN";
export var xtScrollbarStyle = "history-list_xt-scrollbar-style__UNb2p";
export var xtModalDetailsContent = "history-list_xt-modal-details-content__1zshc";
export var xtDialogDetailsContent = "history-list_xt-dialog-details-content__cv8Uc";
export var xtLazyModuleLoaderTabContainer = "history-list_xt-lazy-module-loader-tab-container__192U2";
export var xtTextTruncated = "history-list_xt-text-truncated__xvyiS";
export var xtSummaryContent = "history-list_xt-summary-content__ADZuT";
export var xtSummaryLabel = "history-list_xt-summary-label__MDEhk";
export var visibleHidden = "history-list_visible-hidden__cNlEL";
export var headerBottomMargin = "history-list_header-bottom-margin__D4-Vi";
export var xtDialogHeaderLogoAlign = "history-list_xt-dialog-header-logoAlign__CouHR";
export var xtStickyHeader = "history-list_xt-sticky-header__sLRyo";
export var noBorder = "history-list_no-border__UL1cC";
export var muiOutlinedInputNotchedOutline = "history-list_MuiOutlinedInput-notchedOutline__LY+1Z";
export var filterSection = "history-list_filterSection__YRQhP";
export var itemNumber = "history-list_itemNumber__YH-fb";
export var printButton = "history-list_printButton__mPPQs";
export var listControls = "history-list_listControls__BYaEX";