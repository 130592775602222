// extracted by mini-css-extract-plugin
export var root = "items-list_root__0U+3J";
export var xtContent = "items-list_xt-content__YHjCM";
export var xtContentWithRemarks = "items-list_xt-content-with-remarks__R5BHY";
export var muiFormHelperTextRoot = "items-list_MuiFormHelperText-root__eADH0";
export var xtListPageContainer = "items-list_xt-list-page-container__syPxR";
export var listContent = "items-list_listContent__fowrG";
export var muiInputBaseRoot = "items-list_MuiInputBase-root__yCg-r";
export var muiTextFieldRoot = "items-list_MuiTextField-root__F1VSB";
export var muiTablePaginationRoot = "items-list_MuiTablePagination-root__iFWm5";
export var muiTablePaginationSelectRoot = "items-list_MuiTablePagination-selectRoot__4k8EP";
export var muiTablePaginationSelect = "items-list_MuiTablePagination-select__mHTeq";
export var muiStepperRoot = "items-list_MuiStepper-root__ZFAD5";
export var muiStepperHorizontal = "items-list_MuiStepper-horizontal__lJoGJ";
export var muiStepIconRoot = "items-list_MuiStepIcon-root__vold6";
export var muiStepIconActive = "items-list_MuiStepIcon-active__uPmVB";
export var muiStepIconCompleted = "items-list_MuiStepIcon-completed__ym-Em";
export var muiListItemRoot = "items-list_MuiListItem-root__X0GAj";
export var muiSelected = "items-list_Mui-selected__dFuu4";
export var xtPageTitle = "items-list_xt-page-title__cN0ge";
export var xtSubPageTitle = "items-list_xt-subPage-title__Mte+y";
export var xtSectionBorder = "items-list_xt-section-border__VhXNU";
export var xtPageHeader = "items-list_xt-page-header__EyRiD";
export var xtDialogScrollable = "items-list_xt-dialog-scrollable__RqXXv";
export var reactToastNotificationsContainer = "items-list_react-toast-notifications__container__2WWpl";
export var xtScrollbarStyle = "items-list_xt-scrollbar-style__BPSsr";
export var xtModalDetailsContent = "items-list_xt-modal-details-content__5mmJC";
export var xtDialogDetailsContent = "items-list_xt-dialog-details-content__UCNOS";
export var xtLazyModuleLoaderTabContainer = "items-list_xt-lazy-module-loader-tab-container__BrEtO";
export var xtTextTruncated = "items-list_xt-text-truncated__7fF0h";
export var xtSummaryContent = "items-list_xt-summary-content__epBGy";
export var xtSummaryLabel = "items-list_xt-summary-label__sRivY";
export var visibleHidden = "items-list_visible-hidden__nNelz";
export var headerBottomMargin = "items-list_header-bottom-margin__yRS+s";
export var xtDialogHeaderLogoAlign = "items-list_xt-dialog-header-logoAlign__k-pNd";
export var xtStickyHeader = "items-list_xt-sticky-header__B00yP";
export var noBorder = "items-list_no-border__clLT7";
export var muiOutlinedInputNotchedOutline = "items-list_MuiOutlinedInput-notchedOutline__Ckypc";
export var headerContent = "items-list_headerContent__dpEZc";
export var listControls = "items-list_listControls__Hc9Hj";
export var filterButton = "items-list_filterButton__IPPB+";
export var itemsListCheckbox = "items-list_itemsListCheckbox__clrF+";
export var filtersContainer = "items-list_filtersContainer__XcGrs";