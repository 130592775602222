import { IBomCopyDialogState } from './bom-copy-dialog.types'
import { IBomTableItem } from '../bom-list/bom-list.types'

export const defaultCopyDialogState: IBomCopyDialogState = {
  open: false,
  itemNumber: '',
  itemInventoryUOM: '',
  itemDescription: '',
  item: {} as IBomTableItem,
}

export enum BomCopyDialogFormLabels {
  SourceItemNumber = 'Source Item Number',
  Description = 'Description',
  Uom = 'Uom',
  Item = 'Item',
}

export enum BomCopyDialogFormLabelsTargetNumber {
  TargetItemNumber = 'Target Item Number',
  Item = 'Item',
}

export enum BomCopyDialogFormFieldsTargetNumber {
  TargetItemNumber = 'targetItemNumber',
  Item = 'Item',
}

export enum BomCopyDialogFormFields {
  SourceItemNumber = 'sourceItemNumber',
  Item = 'Item',
}

export enum BomItem {
  SourceItem = 'Source Item',
  TragetItem = 'Target Item',
}
