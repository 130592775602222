import { UserPermission } from 'auth/auth.types'
import { PermissionGuard } from 'auth/permission.guard'
import { homeRoutePath } from 'home/home.constants'

export class ActivitiesGuard extends PermissionGuard {
  public redirectTo = homeRoutePath
  public permissions = [
    UserPermission.ViewAllOpportunities,
    UserPermission.ViewPersonalOpportunities,
    UserPermission.MaintainAllOpportunities,
    UserPermission.MaintainPersonalOpportunities,
    UserPermission.ViewAllTaskItems,
    UserPermission.ViewPersonalTaskItems,
    UserPermission.MaintainAllTaskItems,
    UserPermission.MaintainPersonalTaskItems,
  ]
}
