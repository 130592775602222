import { Overwrite } from 'utility-types'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from 'core/injector/injector.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { dictionaryModuleToken, dictionaryServiceToken, dictionaryUtilsServiceToken } from './dictionary.constants'
import { IDictionaryService } from './dictionary.types'
import { DictionaryModuleDefinition, IDictionaryModuleServices } from './dictionary.module'
import { IDictionaryUtilsService } from './dictionary-utils.service'

function resolveDictionaryModule(): Overwrite<DictionaryModuleDefinition, IDictionaryModuleServices> {
  const module = LazyModulesService.resolveModule<DictionaryModuleDefinition>(dictionaryModuleToken)
  const DictionaryService = Injector.resolve<IDictionaryService>(dictionaryServiceToken)
  const DictionaryUtilsService = Injector.resolve<IDictionaryUtilsService>(dictionaryUtilsServiceToken)

  return { ...module, DictionaryService, DictionaryUtilsService }
}

export const useDictionaryModule = moduleHookResolver(resolveDictionaryModule)
