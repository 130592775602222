import * as yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { CustomerMainFormField } from './customer-details-form.types'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const CustomerDetailsFormSchema = (disabledCustomerType: boolean) =>
  yup.object().shape({
    [CustomerMainFormField.Number]: yup.string().nullable().required(validationMessage.isRequired),
    [CustomerMainFormField.Name]: yup.string().nullable().required(validationMessage.isRequired),
    [CustomerMainFormField.Type]: disabledCustomerType
      ? yup.mixed().nullable().notRequired()
      : yup.mixed().nullable().required(validationMessage.isRequired),
    [CustomerMainFormField.Site]: yup.mixed().nullable().notRequired(),
    [CustomerMainFormField.Active]: yup.boolean().required(),
  })
