import * as React from 'react'
import { FC, useMemo } from 'react'
import { cls } from 'common/utils/utils'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { useXtForm } from 'common/hooks/form/form'
import { FormCheckboxLabel } from 'common/utils/form/form.components'
import { useShipmentsModule } from 'shipments/shipments-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { IPrintShippingFormsState, PrintShippingFormsField, PrintShippingFormsLabel } from './print-shipping-forms.types'
import { defaultPrintFormsState } from './print-shipping-forms.constants'
import * as styles from '../print-dialogs.module.scss'
import { IPrintFormDialogParams } from '../print-dialogs.types'

export const PrintShippingForms: FC<IPrintFormDialogParams> = ({ onClose }) => {
  const { ShipmentsUtilsService } = useShipmentsModule()
  const { ErrorHandler } = useCoreModule()

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
  } = useXtForm<IPrintShippingFormsState>({
    defaultValues: defaultPrintFormsState,
    mode: 'onBlur',
  })

  const newShipmentsChecked = watch(PrintShippingFormsField.NewShipments)
  const changedShipmentsChecked = watch(PrintShippingFormsField.ChangedShipments)

  const disabled = useMemo(() => (!newShipmentsChecked && !changedShipmentsChecked) || isSubmitting, [
    newShipmentsChecked,
    changedShipmentsChecked,
    isSubmitting,
  ])

  const onSaveForm = async (formData: IPrintShippingFormsState): Promise<void> => {
    try {
      await ShipmentsUtilsService.printShipmentsForms(formData)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  return (
    <form className={styles.printDialog}>
      <div className={cls('xt-page-header', styles.printDialogHeader)}>
        <h3 className="xt-page-title">Print Shipping Forms</h3>
        <div className={styles.printDialogButtons}>
          <XtButton label="Cancel" onClick={onClose} />
          <XtButton label="Print" onClick={handleSubmit(onSaveForm)} disabled={disabled} />
        </div>
      </div>
      <FormCheckboxLabel control={control} name={PrintShippingFormsField.NewShipments} label={PrintShippingFormsLabel.NewShipments} />
      <FormCheckboxLabel
        control={control}
        name={PrintShippingFormsField.ChangedShipments}
        label={PrintShippingFormsLabel.ChangedShipments}
      />
    </form>
  )
}
