import { SingleFormRegister } from 'common/hooks/form/multiple-form-manager/multiple-form-manager.types'

import { ContactOption, IContact } from 'contacts/contacts.types'
import { contactSectionKey } from './contact-section.constants'

export enum ContactSectionFormField {
  Contact1 = 'contact1',
  Contact2 = 'contact2',
}

export enum ContactSectionFormLabel {
  Contact1 = 'Contact 1',
  Contact2 = 'Contact 2',
}
export interface IContactSectionForm {
  [ContactSectionFormField.Contact1]: ContactOption<IContact> | null
  [ContactSectionFormField.Contact2]: ContactOption<IContact> | null
}

export type ContactSectionData = {
  [ContactSectionFormField.Contact1]: IContact | null
  [ContactSectionFormField.Contact2]: IContact | null
} | null

export interface IContactSection {
  register: SingleFormRegister<typeof contactSectionKey, IContactSectionForm>
  data: ContactSectionData
  isMobile: boolean
  disabled: boolean
  firstContactLabel?: string
  secondContactLabel?: string
  hidden?: boolean
}
