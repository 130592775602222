import React, { FC, useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { XtList } from 'components/list/list'
import { xsMq } from 'common/constants'
import { IShipping, ShippingLineTableItem } from 'shipping/shipping.types'
import { useCoreModule } from 'core/core-module-hook'
import { XtAccordion } from 'components/xt-accordion/xt-accordion'
import { useShippingModule } from 'shipping/shipping-module-hook'
import { ISalesOrder } from 'sales-shared/sales-orders.types'
import { useSharedModule } from 'shared/shared-module-hook'
import { useSalesSharedModule } from 'sales-shared/sales-shared-module-hook'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { XtMode } from 'common/common.types'
import { OrderType } from 'core/core.types'
import {
  IssueToShippingDialogState,
  ShippingLinesAction,
  ShippingSalesLineItemDialogState,
} from 'maintain-shipping/components/shipping-extended-template/shipping-extended-template.types'
import { shippingLinesActions } from 'maintain-shipping/components/shipping-extended-template/shipping-extended-template.constants'
import {
  convertShippingLinesToReturnInstance,
  defineShippingLineItemDialogState,
  getLineItemsAction,
} from 'maintain-shipping/components/shipping-extended-template/shipping-extended-template.utils'
import { useAuthModule } from 'auth/auth-module-hook'
import { UserPermission } from 'auth/auth.types'
import { columnsItemsList, columnsItemsTotal } from './items-tab.constant'
import * as styles from './items-tab.module.scss'
import { IItemsTabState } from './items-tab.types'
import { defineItemsState } from './items-tab.utils'

interface IItemsTab {
  shipping: IShipping | null
  handleItemReturn(): void
  refreshShipping(): Promise<void>
  onClose(): void
}

export const ItemsTab: FC<IItemsTab> = ({ shipping, handleItemReturn, refreshShipping, onClose }) => {
  const isMobile = useMediaQuery(xsMq)
  const { ErrorHandler, ToastService } = useCoreModule()
  const { ShippingService, IssueToShipping } = useShippingModule()
  const { useDialog } = useSharedModule()
  const { LineItemDetails, SalesOrdersService } = useSalesSharedModule()
  const { PermissionsService } = useAuthModule()

  const [canIssueStock, canReturnStock] = PermissionsService.hasPermissions([
    UserPermission.IssueStockToShipping,
    UserPermission.ReturnStockFromShipping,
  ])

  const canViewSalesOrder = PermissionsService.hasSomePermission([UserPermission.ViewSalesOrders, UserPermission.MaintainSalesOrders])

  const [{ shipmentLines, total }, setItemsState] = useState<IItemsTabState>(defineItemsState(null))
  const [salesOrder, setSalesOrder] = useState<ISalesOrder | null>(null)

  const {
    open: openSalesLineItem,
    openDialog: openSalesLineItemDialog,
    closeDialog: closeSalesLineItemDialog,
    data: salesLineItemData,
  } = useDialog<ShippingSalesLineItemDialogState | null>(null)

  const {
    open: openIssueToShipping,
    openDialog: openIssueToShippingDialog,
    closeDialog: closeIssueToShippingDialog,
    data: issueToShippingData,
  } = useDialog<IssueToShippingDialogState | null>(null)

  const fillShipmentLines = useCallback(async (): Promise<void> => {
    if (!shipping?.shipment_number) {
      return
    }

    try {
      const data = await ShippingService.get(shipping.shipment_number)
      setItemsState(defineItemsState(data))
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }, [ErrorHandler, ShippingService, shipping?.shipment_number])

  const handleReturnAll = useCallback(
    async (item: ShippingLineTableItem): Promise<void> => {
      if (!shipping) {
        return
      }

      const isLastItem = shipmentLines.length === 1

      if (
        isLastItem &&
        !confirm(
          `${item.item_number} is the last item. Returning this item will delete Shipment ${shipping.shipment_number}. Are you sure?`
        )
      ) {
        return
      }

      try {
        const returnLinesInstance = convertShippingLinesToReturnInstance({ ...shipping, shipment_lines: [item] })
        const message = await ShippingService.returnLines(returnLinesInstance)
        ToastService.showSuccess(message)

        if (isLastItem) {
          onClose()
          await refreshShipping()
          return
        }

        handleItemReturn()
        await fillShipmentLines()
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    },
    [
      shipping,
      shipmentLines.length,
      ShippingService,
      ToastService,
      handleItemReturn,
      fillShipmentLines,
      onClose,
      refreshShipping,
      ErrorHandler,
    ]
  )

  const handleOpenSalesLineItemDialog = useCallback(
    async (lineNumber: string): Promise<void> => {
      if (!salesOrder || !shipping) {
        return
      }
      const orderItem = await SalesOrdersService.getOrderItem(shipping.order_number, lineNumber)
      openSalesLineItemDialog(defineShippingLineItemDialogState(salesOrder, orderItem))
    },
    [SalesOrdersService, openSalesLineItemDialog, salesOrder, shipping]
  )

  const onAction = useCallback(
    (item: ShippingLineTableItem, action: ShippingLinesAction) => {
      switch (action) {
        case ShippingLinesAction.IssueAdditionalStock:
          return openIssueToShippingDialog({ site: item.site, orderNumber: shipping?.order_number ?? '' })
        case ShippingLinesAction.ReturnAll:
          return handleReturnAll(item)
        case ShippingLinesAction.ViewOrder:
          return handleOpenSalesLineItemDialog(String(item.line_number))
      }
    },
    [handleOpenSalesLineItemDialog, handleReturnAll, openIssueToShippingDialog, shipping?.order_number]
  )

  const handleIssueShip = useCallback(() => {
    closeIssueToShippingDialog()
    onClose()
    void refreshShipping()
  }, [closeIssueToShippingDialog, onClose, refreshShipping])

  useEffect(() => {
    async function init(): Promise<void> {
      if (!shipping) {
        return
      }

      setItemsState(defineItemsState(shipping))

      try {
        if (shipping.order_type === OrderType.SalesOrder) {
          const salesOrderData = await SalesOrdersService.get(shipping.order_number)
          setSalesOrder(salesOrderData)
        }
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }

    void init()
  }, [ErrorHandler, SalesOrdersService, ShippingService, shipping])

  return (
    <div className={styles.itemsTabContainer}>
      <XtDialog
        className="xt-modal-details-content"
        open={openIssueToShipping}
        fullScreen={false}
        animation={XtDialogAnimation.SlideAnimation}
      >
        {issueToShippingData && (
          <IssueToShipping
            onClose={closeIssueToShippingDialog}
            orderNumber={issueToShippingData.orderNumber}
            site={issueToShippingData.site}
            onShip={handleIssueShip}
          />
        )}
      </XtDialog>
      <XtDialog
        className="xt-modal-details-content"
        open={openSalesLineItem}
        fullScreen={false}
        animation={XtDialogAnimation.SlideAnimation}
      >
        {salesLineItemData && (
          <LineItemDetails
            mode={XtMode.View}
            onClose={closeSalesLineItemDialog}
            site={salesLineItemData.site}
            customerNumber={salesLineItemData.customerNumber}
            currency={salesLineItemData.currency}
            lineItemInput={salesLineItemData.lineItemInput}
            pricesData={salesLineItemData.pricesData}
            orderNumber={salesOrder?.order_number}
          />
        )}
      </XtDialog>
      <XtList
        columns={columnsItemsList}
        data={shipmentLines}
        isMobile={isMobile}
        actions={shippingLinesActions}
        onAction={onAction}
        getItemActions={(_, actions) =>
          getLineItemsAction(actions, Boolean(salesOrder), Boolean(shipping?.shipped), canIssueStock, canReturnStock, canViewSalesOrder)
        }
      />
      <XtAccordion summary="Totals">
        <XtList columns={columnsItemsTotal} data={total ? [total] : []} isMobile={isMobile || !total} />
      </XtAccordion>
    </div>
  )
}
