import { isValidDate, parseDate } from 'common/utils/date.utils'
import { globalConstants } from 'common/constants'

export function convertItemSiteDistributionDate(date: string | null | undefined): Date | null {
  const convertedDate = date ? parseDate(date, globalConstants.dateFormat) : null

  return convertedDate && isValidDate(convertedDate) ? convertedDate : null
}

export function defineLotSerialSourceNumber(itemNumber: string, lotSerialNumber: string): string {
  return `${itemNumber}-${lotSerialNumber}`
}
