import { CountryOption, CurrencyOption, ICountry, ICurrency } from './dictionary.types'

export function defineCurrencyLabel(currencyCode: string, currencySymbol: string): string {
  return `${currencyCode} - ${currencySymbol}`
}

export function defineCurrencyOption(currency: ICurrency): CurrencyOption

export function defineCurrencyOption(currency: ICurrency | null | undefined): CurrencyOption | null {
  return currency ? { ...currency, id: currency.iso_code, label: defineCurrencyLabel(currency.iso_code, currency.symbol) } : null
}

export function defineCountryOption({ country_code, country, state_count }: ICountry): CountryOption {
  return { id: country_code, label: country, hasStates: state_count > 0 }
}
