import React, { FC, useCallback, useEffect, useState } from 'react'
import { Subscription } from 'rxjs'
import { distinctUntilChanged, tap } from 'rxjs/operators'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { DocumentType } from 'documents/documents.types'
import { cls } from 'common/utils/utils'
import { convertMode } from 'common/utils/mode.utils'
import { useCommentsModule } from 'comments/comments-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useProductsModule } from 'products/products-module-hook'
import { useMultipleFormStateManager } from 'common/hooks/form/multiple-form-manager/multiple-form-manager'
import { FormChanges } from 'common/hooks/form/multiple-form-manager/multiple-form-manager.types'
import { ControlMethodOption, CostingMethodOption, IItemSite } from 'products/items/items.types'
import { convertFormDataToSite } from './sites-details-dialog.utils'
import { ISiteDetailsDialog, ISitesDetailsDialogCombinedState } from './sites-details-dialog.types'
import * as styles from './sites-details-dialog.module.scss'
import { SitesDetailsDialogCheckboxes } from './sites-details-dialog-checkboxes/sites-details-dialog-checkboxes'
import { SitesDetailsDialogTabs } from './sites-details-dialog-tabs/sites-details-dialog-tabs'
import { sitesDetailsDialogInventoryFormKey } from './sites-details-dialog-tabs/sites-details-dialog-inventory/sites-details-dialog-inventory.consts'
import { sitesDetailsDialogLocationFormKey } from './sites-details-dialog-tabs/sites-details-dialog-location/sites-details-dialog-location.consts'
import { sitesDetailsDialogPlanningFormKey } from './sites-details-dialog-tabs/sites-details-dialog-planning/sites-details-dialog-planning.consts'
import { SitesDetailsDialogInventoryFormField } from './sites-details-dialog-tabs/sites-details-dialog-inventory/sites-details-dialog-inventory.types'
import { sitesDetailsDialogCheckboxesFormKey } from './sites-details-dialog-checkboxes/sites-details-dialog-checkboxes.consts'
import { SitesDetailsDialogMain } from './sites-details-dialog-main/sites-details-dialog-main'
import { sitesDetailsDialogMainFormKey } from './sites-details-dialog-main/sites-details-dialog-main.consts'
import { SitesDetailsDialogMainFormField, SitesDetailsSiteOption } from './sites-details-dialog-main/sites-details-dialog-main.types'

export const SitesDetailsDialog: FC<ISiteDetailsDialog> = ({ site, uomName, item, onClose, mode, onSuccessfulSubmit, fractional }) => {
  const { ItemSitesService } = useProductsModule()
  const { useRemarks } = useCommentsModule()
  const { ToastService, ErrorHandler } = useCoreModule()
  const { isViewMode, isEditMode, isNewMode } = convertMode(mode)
  const remarksState = useRemarks(DocumentType.ItemSite)
  const { reset: remarksStateReset } = remarksState

  const {
    register,
    formState,
    handleSubmit,
    getFormState,
    multipleFormChanges$,
  } = useMultipleFormStateManager<ISitesDetailsDialogCombinedState>()

  const IsSubmitting = formState.isSubmitting
  const isDirty = formState.isDirty || remarksState.isDirty

  const [itemSite, setItemSite] = useState<IItemSite | null>(null)

  const [isJobCosted, setIsJobCosted] = useState<boolean>(false)
  const [selectedSite, setSelectedSite] = useState<SitesDetailsSiteOption | null>(null)
  const [controlMethod, setControlMethod] = useState<ControlMethodOption>(ControlMethodOption.None)

  const [inventoryValid, setInventoryValid] = useState<boolean>(false)
  const [locationValid, setLocation] = useState<boolean>(false)
  const [planningValid, setPlanning] = useState<boolean>(false)

  useEffect(() => {
    const init: () => Promise<void> = async () => {
      try {
        if (!site || isNewMode) {
          return
        }

        const siteData = await ItemSitesService.getItemSite(item.item_number, site)
        setItemSite(siteData)

        const sourceNumber = `${item.item_number}-${site}`
        remarksStateReset(sourceNumber, siteData.notes)
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }

    void init()
  }, [ErrorHandler, ItemSitesService, isNewMode, item.item_number, remarksStateReset, site])

  useEffect(() => {
    const collectChildFormValues = async (changes: FormChanges<ISitesDetailsDialogCombinedState>): Promise<void> => {
      const values = changes.data

      setInventoryValid(getFormState(sitesDetailsDialogInventoryFormKey)?.fieldValidatorsShown ?? false)
      setLocation(getFormState(sitesDetailsDialogLocationFormKey)?.fieldValidatorsShown ?? false)
      setPlanning(getFormState(sitesDetailsDialogPlanningFormKey)?.fieldValidatorsShown ?? false)

      const IsJobCosted =
        values[sitesDetailsDialogInventoryFormKey][SitesDetailsDialogInventoryFormField.CostingMethod] === CostingMethodOption.Job
      setIsJobCosted(IsJobCosted)

      const selectedMethod = values[sitesDetailsDialogInventoryFormKey][SitesDetailsDialogInventoryFormField.ControlMethod]
      setControlMethod((selectedMethod?.id as unknown) as ControlMethodOption)

      const currentSite = values[sitesDetailsDialogMainFormKey][SitesDetailsDialogMainFormField.Site]
      setSelectedSite(currentSite)
    }

    const sub = new Subscription()
    sub.add(multipleFormChanges$.pipe(distinctUntilChanged(), tap(collectChildFormValues)).subscribe())

    return () => {
      sub.unsubscribe()
    }
  }, [ItemSitesService, getFormState, item.item_number, multipleFormChanges$])

  const onSaveItemSite = useCallback<(formData: ISitesDetailsDialogCombinedState) => Promise<void>>(
    async (formData) => {
      const {
        [sitesDetailsDialogMainFormKey]: mainForm,
        [sitesDetailsDialogCheckboxesFormKey]: checkboxesForm,
        [sitesDetailsDialogInventoryFormKey]: inventoryForm,
        [sitesDetailsDialogLocationFormKey]: locationForm,
        [sitesDetailsDialogPlanningFormKey]: planningForm,
      } = formData

      const payload = convertFormDataToSite(
        item.item_number,
        mainForm,
        checkboxesForm,
        inventoryForm,
        locationForm,
        planningForm,
        remarksState.notes
      )

      try {
        if (isNewMode) {
          const message = await ItemSitesService.createItemSite(payload, remarksState.comments)
          ToastService.showSuccess(message)
        }
        if (isEditMode) {
          const message = await ItemSitesService.updateItemSite(payload)
          ToastService.showSuccess(message)
        }
        onSuccessfulSubmit()
        onClose()
      } catch (error) {
        ErrorHandler.handleError(error)
      }
    },
    [
      ErrorHandler,
      ItemSitesService,
      ToastService,
      isEditMode,
      isNewMode,
      item.item_number,
      onClose,
      onSuccessfulSubmit,
      remarksState.comments,
      remarksState.notes,
      site,
    ]
  )

  const onCancel: VoidFunction = () => {
    // eslint-disable-next-line no-restricted-globals
    if (isDirty && !confirm('Are you sure you want to leave this page? Updates will not be applied.')) {
      return
    }
    onClose()
  }

  return (
    <div>
      <div className={cls(styles.sitesDetailsDialogFormHeader, 'xt-section-border')}>
        <SitesDetailsDialogMain mode={mode} register={register} item={item} uomName={uomName} itemSite={itemSite} />
        <div className={isViewMode ? styles.controlButtonsBlockViewMode : styles.controlButtonsContainer}>
          <XtButton label="Cancel" onClick={onCancel} />
          <XtButton
            label="Save"
            hidden={isViewMode}
            loading={IsSubmitting}
            disabled={!isDirty || IsSubmitting}
            onClick={handleSubmit(onSaveItemSite)}
          />
        </div>
      </div>
      <div className={styles.mainBlock}>
        <SitesDetailsDialogCheckboxes
          mode={mode}
          register={register}
          item={item}
          itemSite={itemSite}
          isJobCosted={isJobCosted}
          isTransitSite={selectedSite?.transit_site ?? false}
        />
        <SitesDetailsDialogTabs
          item={item}
          itemSite={itemSite}
          remarks={remarksState}
          mode={mode}
          fractional={fractional}
          controlMethod={controlMethod}
          currentSite={selectedSite}
          register={register}
          markAsInvalidInventoryTab={inventoryValid}
          markAsInvalidLocationTab={locationValid}
          markAsInvalidPlanningTab={planningValid}
        />
      </div>
    </div>
  )
}
