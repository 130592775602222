import type { ICRMAccountDocument } from 'documents/documents.types'
import type { IAccountFulfilled } from './accounts.types'
import type { ICustomer } from '../customers/customers.types'
import type { IProspect } from '../prospects/prospects.types'
import { convertBillToContactAndAddressType } from 'companies/companies.utils'
import { CRMAccountRole } from 'documents/documents.types'
import { BaseDocumentAccount } from './accounts.types'

export function isCustomerCrmAccount(account: Pick<ICRMAccountDocument, 'crm_roles'>): boolean {
  return !!account.crm_roles?.includes(CRMAccountRole.Customer)
}

export function isProspectCrmAccount(account: Pick<ICRMAccountDocument, 'crm_roles'>): boolean {
  return !!account.crm_roles?.includes(CRMAccountRole.Prospect)
}

export function convertCustomerToAccountFulfilled(
  customer: ICustomer,
  roles: CRMAccountRole[] = [CRMAccountRole.Customer]
): IAccountFulfilled {
  return {
    number: customer.customer_number,
    name: customer.customer_name,
    billTo: convertBillToContactAndAddressType(customer.billing_contact),
    allow_free_form_shipto: customer.allow_free_form_shipto,
    allow_free_form_billto: customer.allow_free_form_billto,
    credit_status_exceed_warn: customer.credit_status_exceed_warn,
    credit_status_exceed_hold: customer.credit_status_exceed_hold,
    crm_roles: roles,
    preferredSite: customer.preferred_selling_site,
    hasProspectRole: false,
    terms: customer.terms_code,
    sales_rep: customer.sales_rep,
    tax_zone: customer.taxzone_code,
    credit_limit_currency_symbol: customer.credit_limit_currency_symbol,
    default_currency_symbol: customer.default_currency_symbol,
  }
}

export function convertProspectToAccountFulfilled(
  prospect: IProspect,
  roles: CRMAccountRole[] = [CRMAccountRole.Prospect],
  creditStatusExceedWarn: boolean = false,
  creditStatusExceedHold: boolean = false
): IAccountFulfilled {
  return {
    number: prospect.number,
    name: prospect.name,
    billTo: convertBillToContactAndAddressType(prospect.primary_contact),
    allow_free_form_shipto: true,
    allow_free_form_billto: true,
    credit_status_exceed_warn: creditStatusExceedWarn,
    credit_status_exceed_hold: creditStatusExceedHold,
    crm_roles: roles,
    preferredSite: null,
    hasProspectRole: true,
  }
}

export function convertToAccountFulFilled(
  { customerData, prospectData }: { customerData?: ICustomer | null; prospectData?: IProspect | null },
  defaultData: BaseDocumentAccount
): IAccountFulfilled {
  if (customerData) {
    return convertCustomerToAccountFulfilled(customerData, defaultData.crm_roles)
  }
  return {
    number: prospectData?.number ?? defaultData.number,
    name: prospectData?.name ?? defaultData.name,
    billTo: convertBillToContactAndAddressType(prospectData?.primary_contact),
    allow_free_form_shipto: true,
    allow_free_form_billto: true,
    credit_status_exceed_warn: defaultData.credit_status_exceed_warn,
    credit_status_exceed_hold: defaultData.credit_status_exceed_hold,
    crm_roles: defaultData.crm_roles,
    preferredSite: null,
    hasProspectRole: !!prospectData,
  }
}
