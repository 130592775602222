import React, { FC } from 'react'
import { FormDatePicker, FormXtAutocomplete } from 'common/utils/form/form.components'
import { useShipmentsModule } from 'shipments/shipments-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { numberConverter } from 'common/utils/utils'
import { useDictionaryModule } from 'dictionary/dictionary-module-hook'
import { IFormHook } from 'common/hooks/form/form.types'
import * as styles from './shipment-header-tab.module.scss'
import { freightMaxValue } from './shipment-header-tab.constants'
import { ShipmentHeaderForm, ShippingInformationFormField, ShippingInformationFormLabel } from '../../shipping-information-dialog.types'

interface IShipmentHeaderTab {
  formMethods: IFormHook<ShipmentHeaderForm>
}

export const ShipmentHeaderTab: FC<IShipmentHeaderTab> = ({ formMethods }) => {
  const { ShipmentsUtilsService } = useShipmentsModule()
  const { DocumentsUtilsService } = useDocumentsModule()
  const { DictionaryUtilsService } = useDictionaryModule()

  const { control, watch } = formMethods

  const freight = watch(ShippingInformationFormField.Freight)
  const currency = watch(ShippingInformationFormField.Currency)

  const isCurrencyInfoHidden: boolean = !currency?.id || currency.id === 'USD'

  return (
    <div className={styles.shipmentHeaderContent}>
      <FormDatePicker name={ShippingInformationFormField.ShipDate} control={control} label={ShippingInformationFormLabel.ShipDate} />
      <FormXtAutocomplete
        loadOptions={ShipmentsUtilsService.loadShipmentsVia}
        name={ShippingInformationFormField.ShipVia}
        control={control}
        label={ShippingInformationFormLabel.ShipVia}
      />
      <FormXtAutocomplete
        loadOptions={DocumentsUtilsService.loadShippingForm}
        name={ShippingInformationFormField.ShippingForm}
        control={control}
        label={ShippingInformationFormLabel.ShippingForm}
      />
      <DecimalFormField
        name={ShippingInformationFormField.Freight}
        control={control}
        label={ShippingInformationFormLabel.Freight}
        maxValue={freightMaxValue}
        allowNegative={false}
      />
      <FormXtAutocomplete
        loadOptions={DictionaryUtilsService.loadCurrencyOptions}
        name={ShippingInformationFormField.Currency}
        control={control}
        label={ShippingInformationFormLabel.Currency}
      />
      <span hidden={isCurrencyInfoHidden}>{`${numberConverter(freight)} ${currency?.id}`}</span>
    </div>
  )
}
