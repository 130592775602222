import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { ProductsModule } from 'products/products.module'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { IItemsService } from 'products/items/items.service'
import { itemsServiceToken } from 'products/products.constants'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { IDocumentsService } from 'documents/documents.types'
import { documentsServiceToken } from 'documents/documents.constants'
import { ICommentsService } from 'comments/comments.service'
import { commentsServiceToken } from 'comments/comments.constants'
import { DocumentsModule } from 'documents/documents.module'
import { CommentsModule } from 'comments/comments.module'
import { ShipmentsModule } from 'shipments/shipments.module'
import { IShipmentsService } from 'shipments/shipments.service'
import { shipmentsServiceToken } from 'shipments/shipments.constants'
import { CoreModule } from 'core/core.module'
import { AuthModule } from 'auth/auth.module'
import { IGlAccountsService } from 'core/services/gl-accounts.service'
import { glAccountsServiceToken } from 'core/core.constants'
import { DictionaryModule } from 'dictionary/dictionary.module'
import { SharedModule } from 'shared/shared-module'
import { ICustomersService } from 'companies/customers/services/customers-services.types'
import { customersServiceToken } from 'companies/customers/customers.constants'
import { CustomersServicesModule } from 'companies/customers/services/customers-services.module'
import { AccountsServicesModule } from 'companies/accounts/services/accounts-services.module'
import { ISalesSharedUtilsService } from './sales-shared-utils.service'
import { IQuotesService } from './quotes.service'
import { ISalesOrdersService } from './sales-orders.service'
import { quotesServiceToken, salesOrdersServiceToken, salesSharedModule, salesSharedUtilsServiceToken } from './sales-shared.constants'

export type SalesSharedModuleDefinition = typeof import('./sales-shared.exports')

export interface ISalesSharedModuleServices {
  SalesOrdersService: ISalesOrdersService
  QuotesService: IQuotesService
  SalesSharedUtilsService: ISalesSharedUtilsService
}

export const SalesSharedModule: ILazyModule<SalesSharedModuleDefinition> = {
  name: salesSharedModule,
  resolver: () => import('./sales-shared.exports'),
  initializer: ({ QuotesService, SalesOrdersService, SalesSharedUtilsService }) => {
    Injector.register<ISalesOrdersService>(
      salesOrdersServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
        const commentsService = injector.resolve<ICommentsService>(commentsServiceToken)
        const customersService = injector.resolve<ICustomersService>(customersServiceToken)

        return new SalesOrdersService(httpClient, customersService, commentsService, documentsService)
      },
      Scope.Singleton
    )

    Injector.register<IQuotesService>(
      quotesServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
        const commentsService = injector.resolve<ICommentsService>(commentsServiceToken)

        return new QuotesService(httpClient, commentsService, documentsService)
      },
      Scope.Singleton
    )

    Injector.register<ISalesSharedUtilsService>(
      salesSharedUtilsServiceToken,
      (injector) => {
        const glAccountsService = injector.resolve<IGlAccountsService>(glAccountsServiceToken)

        const salesOrdersService = injector.resolve<ISalesOrdersService>(salesOrdersServiceToken)
        const quotesService = injector.resolve<IQuotesService>(quotesServiceToken)
        const itemsService = injector.resolve<IItemsService>(itemsServiceToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
        const shipmentsService = injector.resolve<IShipmentsService>(shipmentsServiceToken)

        return new SalesSharedUtilsService(
          glAccountsService,
          itemsService,
          quotesService,
          salesOrdersService,
          documentsService,
          shipmentsService
        )
      },
      Scope.Singleton
    )
  },
  dependencies: [
    CoreModule,
    CustomersServicesModule,
    AccountsServicesModule,
    AuthModule,
    DocumentsModule,
    CommentsModule,
    ProductsModule,
    ShipmentsModule,
    DictionaryModule,
    SharedModule,
  ],
}
