import { useCallback, useEffect, useRef, useState } from 'react'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { debounceTime, skip, switchMap, tap } from 'rxjs/operators'
import { useCoreModule } from 'core/core-module-hook'
import { ISortOption, TableSortingFn, TSortOptions } from 'components/table/table-head/table-head.types'
import { useUsersModule } from 'users/users-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { isObjectEmpty } from 'common/utils/utils'
import { IObjectWithId, IPaginationData, IPaginationParams } from '../common.types'
import { globalConstants } from '../constants'
import { ITablePagination } from '../types/table.types'

interface IFiltersState<TFilters extends {}> {
  filters: TFilters
}

type TPaginationRequestFn<TData, TFilters extends {}> = (
  filters: IFiltersState<TFilters>['filters'],
  pagination: IPaginationParams,
  sorting: ISortOption[],
  data?: TData[]
) => Promise<IPaginationData<TData>>

export interface ITableMeta extends IPaginationParams {
  total: number
}

const defaultMeta: ITableMeta = {
  page: 0,
  limit: globalConstants.paginationLimit,
  total: 0,
}

export interface ITableState<TData extends IObjectWithId, TFilters extends {}> {
  data: TData[]
  loading: boolean
  meta: ITableMeta
  filters: TFilters
  sortOptions: TSortOptions
}

export function canLoadMore({ page, limit, total }: ITableMeta): boolean {
  return page * limit <= total
}

export interface ITable<TData extends IObjectWithId, TFilters extends {}> {
  state: ITableState<TData, TFilters>
  filter(filters: TFilters): void
  sort: TableSortingFn
  refresh(): Promise<void>
  setLoading(loading: boolean): void
  pagination: ITablePagination
  setData(data: TData[], total: number): void
  onChange$: Observable<TData[]>
}

export type TableRequestFn<TData, TFilters extends {}> = TPaginationRequestFn<TData, TFilters>

export function useTable<TData extends IObjectWithId, TFilters extends {}>(
  initialFilters: TFilters,
  requestFn: TableRequestFn<TData, TFilters>,
  defaultSortOptions?: ISortOption[],
  pagename?: PageFilterMapping,
  defaultLimit: number = globalConstants.paginationLimit
): ITable<TData, TFilters> {
  const { PageFilterUtilsService, ErrorHandler } = useCoreModule()
  const { UsersService } = useUsersModule()
  const { AuthService } = useAuthModule()

  const dataSubjectRef = useRef<BehaviorSubject<TData[]>>(new BehaviorSubject<TData[]>([]))
  const dataChangesRef = useRef<Observable<TData[]>>(dataSubjectRef.current.asObservable().pipe(skip(1)))

  const metaRef = useRef<ITableMeta>({ ...defaultMeta, limit: defaultLimit })
  const filtersRef = useRef<TFilters>(initialFilters)
  if (filtersRef.current && isObjectEmpty(filtersRef.current)) filtersRef.current = initialFilters ?? {}

  const sortOptionsMapRef = useRef<TSortOptions>(new Map())

  const filtersSubjectRef = useRef<Subject<TFilters>>(new Subject<TFilters>())

  const [state, setState] = useState<ITableState<TData, TFilters>>({
    filters: initialFilters,
    meta: metaRef.current,
    data: dataSubjectRef.current.value,
    loading: false,
    sortOptions: sortOptionsMapRef.current,
  })

  const requestData = useCallback<
    (reset?: boolean, filters?: TFilters, pagination?: IPaginationParams, sortingOptions?: TSortOptions) => Promise<void>
  >(
    async (reset = true, filters = filtersRef.current, pagination = metaRef.current, sortingOptionsMap = sortOptionsMapRef.current) => {
      try {
        const sortOptions = Array.from(sortingOptionsMap.values())
        setState((prevState) => ({
          ...prevState,
          filters,
          loading: true,
          meta: {
            limit: pagination.limit,
            total: prevState.meta.total,
            page: pagination.page,
          },
        }))
        // we keep zero-based index to work with Material UI table, but the server counts pages from 1
        const serverPagination = { limit: pagination.limit, page: pagination.page + 1 }
        const { data: newData, total } = await requestFn(filters, serverPagination, sortOptions, dataSubjectRef.current.value)
        const newDataState = reset ? newData : [...dataSubjectRef.current.value, ...newData]

        metaRef.current.total = total

        setState((prevState) => ({
          ...prevState,
          sortOptions: sortingOptionsMap,
          loading: false,
          data: newDataState,
          meta: {
            ...prevState.meta,
            total,
          },
        }))

        dataSubjectRef.current.next(newDataState)
      } catch (e) {
        setState((prevState) => ({ ...prevState, loading: false }))
        ErrorHandler.handleError(e)
        dataSubjectRef.current.next([])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ErrorHandler]
  )

  /**
   * Data initialization
   */

  useEffect(() => {
    if (defaultSortOptions) {
      sortOptionsMapRef.current = new Map(defaultSortOptions.map((option) => [option.sortField, option]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const sub = filtersSubjectRef.current
      .asObservable()
      .pipe(
        tap((filters) => {
          filtersRef.current = filters
          metaRef.current.page = 0
          setState((prevState) => ({ ...prevState, filters, meta: metaRef.current }))
        }),
        debounceTime(globalConstants.tableFilterDebounce),
        switchMap(() => {
          return requestData(true, filtersRef.current, metaRef.current)
        })
      )
      .subscribe()

    return () => sub.unsubscribe()
  }, [requestData])

  const loadMore = useCallback<() => Promise<void>>(async () => {
    if (canLoadMore(metaRef.current)) {
      metaRef.current.page++
      await requestData(false, filtersRef.current, metaRef.current)
    }
  }, [requestData])

  const changePage = useCallback<(page: number) => Promise<void>>(
    async (page) => {
      if (canLoadMore({ ...metaRef.current, page })) {
        metaRef.current.page = page
        await requestData(true, filtersRef.current, metaRef.current)
      }
    },
    [requestData]
  )

  const changeLimit = useCallback<(limit: number) => Promise<void>>(
    async (limit) => {
      metaRef.current.limit = limit
      metaRef.current.page = 0
      await requestData(true, filtersRef.current, metaRef.current)
    },
    [requestData]
  )

  useEffect(() => {
    const init = async (): Promise<void> => {
      const username = AuthService.getUsername()
      if (!username) {
        void requestData(true, filtersRef.current, metaRef.current)
        return
      }
      const user = await UsersService.get(username)
      if (!user?.rows_per_page) {
        void requestData(true, filtersRef.current, metaRef.current)
        return
      }
      metaRef.current.limit = user.rows_per_page ?? defaultLimit
      //TODO Remove comments from 203 and 205 after testing
      // const lastUsed = (pagename ? await PageFilterUtilsService.getLastUsedFilter(pagename) : initialFilters) as TFilters
      if (!pagename) filtersSubjectRef.current.next({ ...initialFilters })
      // await changeLimit(user.rows_per_page)
    }

    void init()
  }, [AuthService, PageFilterUtilsService, UsersService, changeLimit, pagename, requestData])

  const sort = useCallback<(sortOption: ISortOption) => Promise<void>>(
    async (sortOption) => {
      const { sortField, sortDirection } = sortOption
      if (sortDirection) {
        sortOptionsMapRef.current.set(sortField, sortOption)
      } else {
        sortOptionsMapRef.current.delete(sortField)
      }
      metaRef.current.page = 0

      await requestData(true, filtersRef.current, metaRef.current, sortOptionsMapRef.current)
    },
    [requestData]
  )

  const setLoading = useCallback<(loading: boolean) => void>((loading) => {
    setState((prevState) => ({ ...prevState, loading }))
  }, [])

  const setData = useCallback<(data: TData[], total: number) => void>((data, total) => {
    metaRef.current.total = total
    setState((prevState) => ({ ...prevState, data, meta: metaRef.current }))
    dataSubjectRef.current.next(data)
  }, [])

  const filter = useCallback<(filters: TFilters) => void>((filters) => {
    filtersSubjectRef.current.next(filters ?? { ...initialFilters })
  }, [])

  const pagination: ITablePagination = {
    count: state.meta.total,
    rowsPerPage: state.meta.limit,
    page: state.meta.page,
    onChangePage: changePage,
    onChangeRowsPerPage: changeLimit,
    loadMore,
    canLoadMore: canLoadMore({ ...metaRef.current, page: metaRef.current.page + 1 }),
  }

  return {
    filter,
    sort,
    refresh: requestData,
    setLoading,
    setData,
    state,
    pagination,
    onChange$: dataChangesRef.current,
  }
}
