import { Overwrite } from 'utility-types'
import { IAppliedCharacteristic, IAppliedCharacteristicNew } from 'characteristics/characteristics.types'
import { IComment } from 'comments/comments.types'
import { IXtAutocompleteOption } from '../components/controls/xt-autocomplete/xt-autocomplete.types'

export interface ILeadAddress {
  active?: boolean
  city: string
  state: string
  country: string
  accuracy?: number | null
  address1: string
  address2: string
  address3: string
  geocoded?: boolean
  latitude?: number | null
  longitude?: number | null
  incomplete?: boolean
  postalcode: string
  address_change?: string
  address_number?: number | string
  formatted_address?: string
  allow_marketing?: boolean
}

export interface ILeadAddressWithAccount extends ILeadAddress {
  account: string
}

export enum PhoneRole {
  Office = 'Office',
  Mobile = 'Mobile',
  Fax = 'Fax',
}

export interface IPhone {
  id?: string
  role: string
  number: string
  allowsms: boolean
}

export interface ILeadAccount {
  account_number: string
  account_name: string
  role: string
  active_role: boolean
  default: boolean
  owner?: string
  active?: boolean
  editable?: boolean
}

export interface ILead {
  editable?: boolean
  contact_number: string
  active?: boolean
  honorific: string
  first_name: string
  middle_name: string
  last_name: string
  suffix?: string
  job_title: string
  phones: IPhone[]
  accounts?: ILeadAccount[]
  email: string
  web: string
  company: string
  contact_address?: ILeadAddress | null
  owner?: string | null
  notes?: string
  contact_characteristics: IAppliedCharacteristic[] | null
  comments?: Partial<IComment>[] | null
  created?: string | null
  lastupdated?: string | null
  is_lead?: boolean
}

export enum LeadFormField {
  Active = 'active',
  Honorific = 'honorific',
  FirstName = 'first_name',
  MidName = 'middle_name',
  LastName = 'last_name',
  Company = 'company',
  JobTitle = 'job_title',
  Email = 'email',
  Web = 'web',
  Address1 = 'address1',
  Address2 = 'address2',
  Address3 = 'address3',
  City = 'city',
  Country = 'country',
  State = 'state',
  Postalcode = 'postalcode',
  Latitude = 'latitude',
  Longitude = 'longitude',
  AllowMarketing = 'allow_marketing',
  Alternate = 'mobile_phone',
  Fax = 'fax_phone',
  Suffix = 'suffix',
  Phones = 'phones',
  Notes = 'notes',
  Comments = 'comments',
  ContactAddress = 'contact_address',
  Lead = 'is_lead',
  Owner = 'owner',
}

export type LeadUpdatePayload = Overwrite<
  ILead,
  {
    contact_characteristics: IAppliedCharacteristicNew[] | null
  }
>

export type LeadLightweight = Omit<ILead, 'contact_characteristics'>

export type LeadCreatePayload = Omit<LeadUpdatePayload, 'contact_number'>

export type LeadOption<Lead extends LeadLightweight = LeadLightweight> = Lead & IXtAutocompleteOption

export enum LeadUpdateEventType {
  Create = 'LeadCreateEvent',
  Update = 'LeadUpdateEvent',
  Delete = 'LeadDeleteEvent',
}

export interface ILeadUpdateEvent {
  type: LeadUpdateEventType
  leadId: string
}

export enum LeadFilter {
  ShowInactive = 'showInactive',
  PostalCode = 'postalcode',
  StatePatten = 'state',
  CityPattern = 'city',
  NamePattern = 'contact_name_pattern',
  CompanyPattern = 'company_pattern',
  AccountNumber = 'account_number',
  Owner = 'owner',
  SearchPattern = 'search_pattern',
}

export interface ILeadFilters {
  [LeadFilter.ShowInactive]?: boolean
  [LeadFilter.PostalCode]?: string
  [LeadFilter.StatePatten]?: string
  [LeadFilter.CityPattern]?: string
  [LeadFilter.AccountNumber]?: string | null
  [LeadFilter.Owner]?: string
  [LeadFilter.SearchPattern]?: string | null
  [LeadFilter.NamePattern]?: string
  [LeadFilter.CompanyPattern]?: string
}

export interface ILeadOptionsFilters {
  filterByAccountOnly?: boolean
  accountNumber?: string
}
