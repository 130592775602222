import React, { FC } from 'react'
import { ErrorBoundary } from 'layout/error-boundary/error-boundary'
import { XtHeader } from '../header/header'
import { convertRouteChildrenToNavbarOptions, renderRoute } from './content.utils'
import { IXtContent } from './content.types'

export const XtContent: FC<IXtContent> = ({ route }) => {
  const options = convertRouteChildrenToNavbarOptions(route.children)

  return (
    <div>
      <XtHeader navbarOptions={options} title={route.name} />
      <ErrorBoundary>{renderRoute(route, true)}</ErrorBoundary>
    </div>
  )
}
