import { IPaginationData, IPaginationParams, IPaginationResponse } from 'common/common.types'
import { IHttpClient } from './http-client'

export interface ISalesRep {
  number: string
  name: string
  active: boolean
  account: string
  commission: number
  created: string
  lastupdated: string
}

export interface ISalesRepService {
  getAll: (params: IPaginationParams) => Promise<IPaginationData<ISalesRep>>
}

export class SalesRepService implements ISalesRepService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getAll({ limit, page }: IPaginationParams): Promise<IPaginationData<ISalesRep>> {
    const params: Record<string, true | number> = { limit, page: page === 0 ? 1 : page }
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ISalesRep>>('/salesrep', params)

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}
