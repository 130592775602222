import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IContact } from 'contacts/contacts.types'
import { ITask } from 'tasks/tasks.types'
import { IPaginationData, IPaginationParams, IPaginationResponse } from '../../common/common.types'
import { IHttpClient } from './http-client'

export interface IAssignableOrders {
  sales_orders: boolean
  work_orders: boolean
  purchase_orders: boolean
}

export interface IProject {
  number: string
  name: string
  project_type: string
  description: string
  status: string
  account: string
  contact: IContact
  department: string
  owner: string
  assigned: string
  planned_start_date: string
  start_date: string
  assigned_date: string
  due_date: string
  completed_date: string
  priority: string
  assignable_orders: IAssignableOrders
  project_tasks: ITask[]
  project_characteristics: IAppliedCharacteristic[] | null
  created: Date
  lastupdated: Date
}

interface IProjectFilters {
  showComplete: boolean
}

export interface IProjectService {
  getAll: (params: IPaginationParams, filters?: IProjectFilters) => Promise<IPaginationData<IProject>>
}

export class ProjectService implements IProjectService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getAll(paginationParams: IPaginationParams, filters?: IProjectFilters): Promise<IPaginationData<IProject>> {
    const params = prepareRequestParams(paginationParams, filters)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IProject>>('/project', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}
