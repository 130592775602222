// extracted by mini-css-extract-plugin
export var sidebar = "sidebar_sidebar__4psxg";
export var sidebarLogo = "sidebar_sidebarLogo__5Eu4o";
export var sidebarLogoImage = "sidebar_sidebarLogoImage__vUMEN";
export var sidebarContent = "sidebar_sidebarContent__b0S7o";
export var sidebarOption = "sidebar_sidebarOption__tIniv";
export var sidebarOptionLink = "sidebar_sidebarOptionLink__Rgp8X";
export var sidebarOptionActive = "sidebar_sidebarOptionActive__BfviE";
export var sidebarOptionLabel = "sidebar_sidebarOptionLabel__1xwNG";
export var sidebarOptionIcon = "sidebar_sidebarOptionIcon__G9aF6";
export var profileButton = "sidebar_profileButton__eakA7";