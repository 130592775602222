import { IXtItemNumberOptionBase } from './item-number.types'
import { IItem } from '../../items.types'

export function defineItemNumberOption<Option extends IItem>(item: Option): Option & IXtItemNumberOptionBase
export function defineItemNumberOption<Option extends IItem>(item: Option | null | undefined): (Option & IXtItemNumberOptionBase) | null

export function defineItemNumberOption<Option extends IItem>(item: Option | null | undefined): (Option & IXtItemNumberOptionBase) | null {
  return item
    ? {
        ...item,
        id: item.item_number,
        label: item.description1,
        itemNumber: item.item_number,
        uom: item.inventory_uom_name,
        description1: item.description1,
        description2: item.description2,
        inventory_uom_name: item.inventory_uom_name ?? undefined,
        item_number: item.item_number,
      }
    : null
}
