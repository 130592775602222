import { useCallback, useMemo, useRef, useState } from 'react'
import { deepEqual } from 'fast-equals'
import { BehaviorSubject, Observable } from 'rxjs'
import { isInitializer } from 'common/utils/utils'

export interface IFormControlState<TValue> {
  value: TValue
  isDirty: boolean
  reset(value: TValue): void
  setValue(value: TValue): void
  formControlState$: Observable<IFormControlStateBase<TValue>>
}

interface IUseState<TValue> {
  value: TValue
  initialValue: TValue
}

type UseCallback<TValue> = (value: TValue) => void

interface IFormControlStateBase<TValue> {
  value: TValue
  isDirty: boolean
}

/**
 An important point regarding the initial initialValue -> it affects IsDirty,
 if we want to return IsDirty to its original state, the value that we get from setValue will be compared with initialValue
 * @param initialValue
 */

export function useFormControlState<TValue>(initialValue: TValue | (() => TValue)): IFormControlState<TValue> {
  const [state, setState] = useState<IUseState<TValue>>(() => {
    if (isInitializer(initialValue)) {
      const value = initialValue()
      return {
        value,
        initialValue: value,
      }
    }
    return {
      value: initialValue,
      initialValue,
    }
  })

  const isDirty = useMemo(() => !deepEqual(state.initialValue, state.value), [state])

  const formControlStateSubject = useRef<BehaviorSubject<IFormControlStateBase<TValue>>>(
    new BehaviorSubject<IFormControlStateBase<TValue>>({
      isDirty,
      value: state.value,
    })
  )

  const formControlState$ = useMemo<Observable<IFormControlStateBase<TValue>>>(() => formControlStateSubject.current.asObservable(), [])

  const setValue = useCallback<UseCallback<TValue>>(
    (value) => {
      setState((prevState) => ({ ...prevState, value }))
      formControlStateSubject.current.next({ isDirty: !deepEqual(state.initialValue, value), value })
    },
    [state.initialValue]
  )

  const reset = useCallback<UseCallback<TValue>>((value) => {
    setState({
      value,
      initialValue: value,
    })
    formControlStateSubject.current.next({ isDirty: false, value })
  }, [])

  return {
    value: state.value,
    setValue,
    reset,
    isDirty,
    formControlState$,
  }
}
