import { Required } from 'utility-types'
import { IXtTableColumn } from '../table-head/table-head.types'
import { IXtAutocompleteOption } from '../../controls/xt-autocomplete/xt-autocomplete.types'

export enum XtEditableCellType {
  Input,
  Date,
  Select,
  SelectCreatable,
  Number,
  Custom,
}

export interface IXtEditableCell<TValue> extends IXtTableColumn<never> {
  type: XtEditableCellType
  editable: boolean
  disabled?: boolean
  value: TValue | null
  onChange(value: TValue | null): void
  onBlur?: VoidFunction
  options?: IXtAutocompleteOption[]
  decimalScale?: number
}

export type XtEditableCellControlInput = Pick<
  IXtEditableCell<string | number>,
  'value' | 'onChange' | 'onBlur' | 'type' | 'disabled' | 'decimalScale'
>
export type XtEditableCellControlDate = IXtEditableCell<Date>
export type XtEditableCellControlSelect<TValue> = Required<IXtEditableCell<TValue>, 'options'>
