import { ControlMethodOption, CostingMethodOption, IItemSitePayload, IItemSiteRestrictedLocationsPayload } from 'products/items/items.types'
import { SitesDetailsDialogCheckboxesForm } from './sites-details-dialog-checkboxes/sites-details-dialog-checkboxes.types'
import {
  ItemCostRecognition,
  SitesDetailsDialogInventoryForm,
} from './sites-details-dialog-tabs/sites-details-dialog-inventory/sites-details-dialog-inventory.types'
import {
  DefaultLocationType,
  IRestrictedLocationRow,
  SitesDetailsDialogLocationForm,
} from './sites-details-dialog-tabs/sites-details-dialog-location/sites-details-dialog-location.types'
import { SitesDetailsDialogPlanningForm } from './sites-details-dialog-tabs/sites-details-dialog-planning/sites-details-dialog-planning.types'
import { suppliedFromSiteNoneOption } from './sites-details-dialog-tabs/sites-details-dialog-planning/sites-details-dialog-planning.consts'
import { ISitesDetailsDialogMainForm } from './sites-details-dialog-main/sites-details-dialog-main.types'

function convertToRestrictedLocationsPayload(locations: IRestrictedLocationRow[]): IItemSiteRestrictedLocationsPayload[] {
  return locations.map((row) => ({
    restricted_location: row.restrictedLocation,
    allowed: row.allowed,
  }))
}

export function convertFormDataToSite(
  item_number: string,
  mainForm: ISitesDetailsDialogMainForm,
  checkboxesForm: SitesDetailsDialogCheckboxesForm,
  inventoryForm: SitesDetailsDialogInventoryForm,
  locationForm: SitesDetailsDialogLocationForm,
  planningForm: SitesDetailsDialogPlanningForm,
  notes: string
): IItemSitePayload {
  const isLocationSelected =
    locationForm.default_location_type === DefaultLocationType.Location &&
    locationForm.use_default_location &&
    locationForm.multiple_location_control

  return {
    item_number: item_number,
    site: mainForm.site?.id ?? '',
    active: mainForm.active,
    ranking: mainForm.sold_from_site ? mainForm.ranking : 1,
    abc_class: inventoryForm.abc_class?.id ?? '',
    cycl_count_freq: inventoryForm.cycl_count_freq ?? 0,
    wo_supplied_at_site: checkboxesForm.wo_supplied_at_site,
    create_wos: checkboxesForm.create_wos,
    po_supplied_at_site: checkboxesForm.po_supplied_at_site,
    create_prs: checkboxesForm.create_prs,
    create_soprs: checkboxesForm.create_soprs,
    create_sopos: checkboxesForm.create_sopos,
    dropship: checkboxesForm.dropship,
    sold_from_site: mainForm.sold_from_site,
    cost_method: inventoryForm.cost_method as CostingMethodOption,
    auto_ls_number: inventoryForm.auto_ls_number?.id ?? '',
    control_method: (inventoryForm.control_method?.id ?? ControlMethodOption.None) as ControlMethodOption,
    planner_code: inventoryForm.planner_code?.id ?? '',
    cost_category: inventoryForm.cost_category_code?.id ?? '',
    prevent_negative_inventory: inventoryForm.prevent_negative_inventory,
    suppress_automatic_orders: checkboxesForm.suppress_automatic_orders,
    override_wo_cost_recognition: inventoryForm.override_wo_cost_recognition_enabled
      ? ((inventoryForm.override_wo_cost_recognition ?? ItemCostRecognition.ToDate) as ItemCostRecognition)
      : ItemCostRecognition.ToDate,
    stocked: inventoryForm.stocked,
    allow_automatic_updates: inventoryForm.allow_automatic_updates,
    event_fence: inventoryForm.event_fence ?? 0,
    multiple_location_control: locationForm.multiple_location_control,
    location: isLocationSelected ? locationForm.location?.id : null,
    auto_distr_location: isLocationSelected ? locationForm.auto_distr_location : false,
    receive_location: isLocationSelected ? locationForm.receive_location?.id : null,
    auto_distr_receive_location: isLocationSelected ? locationForm.auto_distr_receive_location : false,
    issue_location: isLocationSelected ? locationForm.issue_location?.id : null,
    auto_distr_issue_location: isLocationSelected ? locationForm.auto_distr_issue_location : false,
    user_defined_location:
      locationForm.default_location_type === DefaultLocationType.UserDefined && locationForm.use_default_location
        ? locationForm.user_defined_location ?? ''
        : '',
    location_comment: locationForm.location_comment ?? '',
    restricted_locations: convertToRestrictedLocationsPayload(locationForm.restricted_locations),
    disallow_blank_wip_locations: locationForm.multiple_location_control ? locationForm.disallow_blank_wip_locations : false,
    enforce_order_parameters: planningForm.enforce_order_parameters,
    reorder_level: planningForm.enforce_order_parameters ? planningForm.reorder_level ?? 0 : 0,
    order_up_to: planningForm.enforce_order_parameters ? planningForm.order_up_to ?? 0 : 0,
    minimum_order: planningForm.enforce_order_parameters ? planningForm.minimum_order ?? 0 : 0,
    maximum_order: planningForm.enforce_order_parameters ? planningForm.maximum_order ?? 0 : 0,
    order_multiple: planningForm.enforce_order_parameters ? planningForm.order_multiple ?? 0 : 0,
    enforce_on_manual_orders: planningForm.enforce_order_parameters ? planningForm.enforce_on_manual_orders : false,
    planning_system: planningForm.planning_system?.id ?? '',
    group_mps_mrp_orders: planningForm.group_mps_mrp_orders ?? 1,
    first_group: planningForm.first_group,
    mps_time_fence: planningForm.mps_time_fence ?? 0,
    lead_time: planningForm.lead_time ?? 0,
    safety_stock: planningForm.safety_stock ?? 0,
    supplied_from_site: planningForm.create_planned_transfers_orders
      ? planningForm.supplied_from_site?.id ?? suppliedFromSiteNoneOption
      : suppliedFromSiteNoneOption,
    notes: notes,
  }
}
