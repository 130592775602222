import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { dateConverter } from 'common/utils/date.utils'
import { numberConverter } from 'common/utils/utils'
import { InventoryHistoryByLocationTableItem } from './inventory-history-by-location-list.types'

export const inventoryHistoryByLocationColumns: IXtTableColumn<InventoryHistoryByLocationTableItem>[] = [
  {
    id: 'transaction_date',
    field: 'transaction_date',
    headerName: 'Date',
    flex: '1 0 160px',
    width: 160,
    converter: dateConverter,
  },
  {
    id: 'transaction_type',
    field: 'transaction_type',
    headerName: 'Type',
    flex: '1 0 100px',
    width: 100,
  },
  {
    id: 'order_number',
    field: 'order_number',
    headerName: 'Order #',
    flex: '1 0 120px',
    width: 120,
  },
  {
    id: 'item_number',
    field: 'item_number',
    headerName: 'Item Number',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'lotserial',
    field: 'lotserial',
    headerName: 'Lot/Serial #',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'uom',
    field: 'uom',
    headerName: 'UOM',
    flex: '1 0 110px',
    width: 110,
  },
  {
    id: 'transaction_qty',
    field: 'transaction_qty',
    headerName: 'Trans-Qty',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
  {
    id: 'qoh_before',
    field: 'qoh_before',
    headerName: 'Qty. Before',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
  {
    id: 'qoh_after',
    field: 'qoh_after',
    headerName: 'Qty. After',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
]

export const inventoryHistoryByLocationPageTitle = 'xTuple - Inventory History By Location'
