import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { numberConverter } from 'common/utils/utils'
import { ICostedBomSummaryWithId } from '../bom-utils.types'

export const costedBomColumns: IXtTableColumn<ICostedBomSummaryWithId>[] = [
  {
    id: 'item_number',
    field: 'item_number',
    headerName: 'Item Number',
    flex: '1 0 200px',
    width: 200,
  },
  {
    id: 'item_description',
    field: 'item_description',
    headerName: 'Description',
    flex: '1 0 230px',
    width: 230,
  },
  {
    id: 'uom',
    field: 'uom',
    headerName: 'UOM',
    flex: '1 0 100px',
    width: 100,
  },
  {
    id: 'qty_required',
    field: 'qty_required',
    headerName: 'Qty. Req.',
    flex: '1 0 200px',
    width: 200,
    converter: numberConverter,
  },
  {
    id: 'unit_cost',
    field: 'unit_cost',
    headerName: 'Unit Cost',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
  {
    id: 'extended_cost',
    field: 'extended_cost',
    headerName: 'Ext. Cost',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
]

export const summarizedListPageTitle = 'xTuple - Costed Summarized BOM'
