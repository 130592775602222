import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { CoreModule } from 'core/core.module'
import { DocumentsModule } from 'documents/documents.module'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { IDocumentsService } from 'documents/documents.types'
import { documentsServiceToken } from 'documents/documents.constants'
import { ICommentsService } from 'comments/comments.service'
import { commentsServiceToken } from 'comments/comments.constants'
import { CommentsModule } from 'comments/comments.module'
import { ICustomersService } from '../../customers/services/customers-services.types'
import { customersServiceToken } from '../../customers/customers.constants'
import { IProspectsService } from '../../prospects/services/prospects-services.types'
import { accountsServicesModuleToken, accountsServiceToken, accountsUtilsServiceToken } from '../accounts.constants'
import { IAccountsService, IAccountsUtilsService } from './accounts-services.types'
import { CustomersServicesModule } from '../../customers/services/customers-services.module'
import { ProspectsServicesModule } from '../../prospects/services/prospects-services.module'
import { prospectsServiceToken } from '../../prospects/prospects.constants'

type AccountsServicesModuleDefinition = typeof import('./accounts-services.export')

export const AccountsServicesModule: ILazyModule<AccountsServicesModuleDefinition> = {
  name: accountsServicesModuleToken,
  resolver: () => import('./accounts-services.export'),
  initializer: ({ AccountsService, AccountsUtilsService }) => {
    Injector.register(
      accountsServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        const prospectsService = injector.resolve<IProspectsService>(prospectsServiceToken)
        const customersService = injector.resolve<ICustomersService>(customersServiceToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
        const commentsService = injector.resolve<ICommentsService>(commentsServiceToken)

        return new AccountsService(httpClient, commentsService, documentsService, customersService, prospectsService)
      },
      Scope.Singleton
    )

    Injector.register<IAccountsUtilsService>(
      accountsUtilsServiceToken,
      (injector) => {
        const accountsService = injector.resolve<IAccountsService>(accountsServiceToken)

        return new AccountsUtilsService(accountsService)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, DocumentsModule, CommentsModule, CustomersServicesModule, ProspectsServicesModule],
}
