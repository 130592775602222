import React, { FC } from 'react'
import * as styles from './page-not-found.module.scss'
// import logo from '../../assets/img/xtuple.png'

export const PageNotFound: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.fof}>
        <h1>Error 404</h1>
        <p>Uh Oh! Page not found!</p>
      </div>
    </div>
  )
}
