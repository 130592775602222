import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { ProspectsModule, ProspectsModuleDefinition } from './prospects.module'
import { resolveProspectsServicesModule } from './services/prospects-services-module-hook'
import { IProspectsServices } from './services/prospects-services.types'

function resolveProspectsModule(): ProspectsModuleDefinition & IProspectsServices {
  const module = LazyModulesService.resolveModule<ProspectsModuleDefinition>(ProspectsModule)
  const services = resolveProspectsServicesModule()

  return {
    ...module,
    ...services,
  }
}

export const useProspectsModule = moduleHookResolver(resolveProspectsModule)
