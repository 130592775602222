import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { DocumentType, UsedOnValue } from 'documents/documents.types'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { xsMq } from 'common/constants'
import { useCharacteristicsModule } from 'characteristics/characteristics-module-hook'
import { useCommentsModule } from 'comments/comments-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { convertMode } from 'common/utils/mode.utils'
import { useItemsSharedModule } from 'products/items-shared/items-shared-module-hook'
import { useProductsModule } from 'products/products-module-hook'
import { ControlMethodServerOption } from 'products/items/items.types'
import { UserPermission } from 'auth/auth.types'
import { useAuthModule } from 'auth/auth-module-hook'
import { DetailsTab } from './details-tab/details-tab'
import { SitesTab } from './sites-tab/sites-tab'
import { ConversionsTab } from './conversions-tab/conversions-tab'
import { CostTab } from './cost-tab/cost-tab'
import { IItemDetailsTabs, ItemDetailsTabName } from './item-details-tabs.types'
import * as styles from './item-details-tabs.module.scss'

export const ItemDetailsTabs: FC<IItemDetailsTabs> = memo(
  ({ disabled, remarks, characteristics, documents, mode, formMethods, itemNumber, onTaxTypeChange, item, taxTypes }) => {
    const { XtCharacteristics } = useCharacteristicsModule()
    const { XtRemarks } = useCommentsModule()
    const { XtDocuments } = useDocumentsModule()
    const { TaxTypes } = useItemsSharedModule()
    const { ItemsUtilsService } = useProductsModule()
    const { PermissionsService } = useAuthModule()

    const canManageOrViewItemSites = PermissionsService.hasSomePermission([UserPermission.MaintainItemSites, UserPermission.ViewItemSites])
    const isMobile = useMediaQuery(xsMq)

    const { isViewMode, isEditMode, isNewMode } = convertMode(mode)

    const [isAnySiteSerial, setIsAnySiteSerial] = useState<boolean>(false)

    const checkIsAnySiteSerial = useCallback(async (): Promise<void> => {
      const { data } = await ItemsUtilsService.fetchSites(
        { control_method: ControlMethodServerOption.SerialNumber, itemNumber: item?.item_number },
        { limit: 1, page: 1 }
      )

      setIsAnySiteSerial(Boolean(data.length))
    }, [ItemsUtilsService, item])

    useEffect(() => {
      void checkIsAnySiteSerial()
    }, [checkIsAnySiteSerial])

    const tabs = [
      {
        name: ItemDetailsTabName.Details,
        template: (
          <DetailsTab
            formMethods={formMethods}
            disabled={disabled}
            isEditMode={isEditMode}
            onTaxTypeChange={onTaxTypeChange}
            item={item}
            taxTypes={taxTypes}
            isAnySiteSerial={isAnySiteSerial}
          />
        ),
      },
      {
        name: ItemDetailsTabName.Characteristics,
        template: (
          <XtCharacteristics
            usedOnFilter={UsedOnValue.Item}
            disabled={disabled}
            onCreate={characteristics.createCharacteristic}
            onUpdate={characteristics.updateCharacteristic}
            onDelete={characteristics.deleteCharacteristic}
            characteristics={characteristics.characteristics}
          />
        ),
      },
      {
        name: ItemDetailsTabName.Conversions,
        hidden: isNewMode,
        template: <ConversionsTab formMethods={formMethods} isViewMode={isViewMode} isMobile={isMobile} />,
      },
      {
        name: ItemDetailsTabName.Cost,
        hidden: isNewMode,
        template: <CostTab itemNumber={itemNumber} isMobile={isMobile} />,
      },
      {
        name: ItemDetailsTabName.Documents,
        hidden: isNewMode,
        template: (
          <XtDocuments
            disabled={disabled}
            onDocumentCreate={documents.saveDocument}
            onDocumentsCreate={documents.saveDocuments}
            onDocumentDelete={documents.deleteDocument}
            onDocumentUpdate={documents.updateDocument}
            tableState={documents.state}
            pagination={documents.pagination}
            mode={mode}
          />
        ),
      },
      {
        name: ItemDetailsTabName.Sites,
        template: item && (
          <SitesTab
            formMethods={formMethods}
            isViewMode={isViewMode}
            isMobile={isMobile}
            item={item}
            checkIsAnySiteSerial={checkIsAnySiteSerial}
          />
        ),
        hidden: isNewMode || !item || !canManageOrViewItemSites,
      },
      {
        name: ItemDetailsTabName.TaxTypes,
        template: (
          <TaxTypes
            className={styles.taxTypesContent}
            mode={mode}
            isMobile={isMobile}
            onTaxTypeChange={onTaxTypeChange}
            taxTypes={taxTypes}
          />
        ),
      },
      {
        name: ItemDetailsTabName.Remarks,
        template: (
          <XtRemarks
            source={DocumentType.Item}
            loadMore={remarks.loadMore}
            canLoadMore={remarks.canLoadMore}
            textAreaOnChange={remarks.setNotes}
            textAreaValue={remarks.notes}
            textAreaName="Notes"
            onCommentsUpdate={remarks.updateComment}
            onCommentsSave={remarks.saveComment}
            comments={remarks.comments}
            username={remarks.username}
            disabled={disabled}
            extendedTextAreaOnChange={remarks.setAdditionalNotes}
            extendedTextAreaValue={remarks.additionalNotes}
            extendedTextAreaName="Extended Description"
          />
        ),
      },
    ]
    return <XtTabs tabs={tabs} />
  }
)
