import * as React from 'react'
import { PropsWithChildren, ReactElement, useEffect, useState, useCallback } from 'react'
import { IconButton, Modal, Slide } from '@material-ui/core'
import { deepEqual } from 'fast-equals'
import { UseFormReset } from 'react-hook-form'
import { UseFormGetValues } from 'react-hook-form/dist/types/form'
import { globalConstants } from 'common/constants'
import { cls, removeEmptyFields } from 'common/utils/utils'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { XtButtonVariant } from 'components/buttons/xt-button/xt-button.types'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { XtInfoValues } from 'components/xt-info-values/xt-info-values'
import { XtSelect } from 'components/controls/xt-select/xt-select'
import { buildPageFilerValues, parseFilterValues } from 'core/services/pagefilters/pagefilters.utils'
import { useDialog } from 'shared/hooks/dialog-hook'
import { IFormStateChanges } from 'common/hooks/form/form.types'
import { FilterOption } from '../pagefilter.types'
import { usePageFilterContext } from '../pagefilter.utils'
import { IFilterFormState } from './filter.types'
import { SavePresetDialog } from './save-pre-set-dialog/save-pre-set-dialog'
import * as styles from './filter.module.scss'
import { FilterButtonLabels } from '../pagefilter.constants'

export const filtersPresetInfoValue = [{ label: 'Filters pre-set', value: '' }]

interface IFilterDialogWrapper<TFiltersState extends IFilterFormState> {
  onCancel: VoidFunction
  open: boolean
  onClearFilters: VoidFunction
  onApplyFilters: VoidFunction
  onSaveAndApplyFilters?: VoidFunction
  getValues: () => TFiltersState | UseFormGetValues<TFiltersState>
  reset: (filters: TFiltersState) => void | UseFormReset<IFilterFormState>
}

export function FilterDialogWrapper<TFiltersState extends IFilterFormState>({
  children,
  onCancel,
  open,
  onApplyFilters,
  onClearFilters,
  onSaveAndApplyFilters,
  getValues,
  reset,
}: PropsWithChildren<IFilterDialogWrapper<TFiltersState>>): ReactElement {
  const {
    tableFilters: currentFilters,
    state,
    defaultFilterValues,
    label: filterLabel,
    preset,
    setPreset,
    filter,
    onChange,
  } = usePageFilterContext<TFiltersState>()

  const label = filterLabel ?? 'Filters'
  const initValues = state?.lastUsedFilter && !!Object.keys(state?.lastUsedFilter).length ? state?.lastUsedFilter : defaultFilterValues

  const { openDialog, closeDialog: closeSaveDialog, open: openSaveDialog } = useDialog()

  const [isClearable, setIsClearable] = useState(false)

  const clearButtonDisable = useCallback(() => {
    const params = { ...initValues, ...currentFilters, ...getValues() }
    return deepEqual(defaultFilterValues, removeEmptyFields(params))
  }, [initValues, currentFilters, getValues])

  const onDelete = (): void => {
    if (preset) {
      void state?.deletePageFilter(preset)
      setPreset(null)
      reset(initValues)
    }
  }

  const onUpdatePageFilter = (customPreSet: FilterOption, shared?: boolean): void => {
    const params = { ...initValues, ...currentFilters, ...getValues() } as TFiltersState
    const newPreSet = {
      ...customPreSet,
      value: buildPageFilerValues(params),
    }

    void state?.handleLastUsedFilter(params)
    void state?.updatePageFilter(params, newPreSet.label, shared)
    setPreset(newPreSet)
    reset(params)
    if (openSaveDialog) {
      closeSaveDialog()
    }

    onApplyFilters()
  }

  const onCreatePageFilter = (customPreset: FilterOption, shared?: boolean): void => {
    const params = { ...initValues, ...currentFilters, ...getValues() } as TFiltersState
    const newPreset = {
      ...customPreset,
      value: buildPageFilerValues(params),
    }

    void state?.handleLastUsedFilter(params)
    void state?.createPageFilter(params, newPreset.label, shared)
    setPreset(newPreset)
    reset(params)
    if (openSaveDialog) {
      closeSaveDialog()
    }

    onApplyFilters()
  }

  const onSaveAndApply: VoidFunction = () => {
    if (!preset) {
      return openDialog()
    }

    onUpdatePageFilter(preset, false)

    if (onSaveAndApplyFilters) {
      onSaveAndApplyFilters()
    }
  }

  const onClear = (): void => {
    setPreset(null)
    setIsClearable(true)
    void state?.handleLastUsedFilter({} as TFiltersState)
    onClearFilters()
  }

  const clearPresetIsEqual = (): void => {
    if (preset) {
      const values = { ...initValues, ...currentFilters, ...getValues() } as TFiltersState
      const preSetValues = parseFilterValues<TFiltersState>(preset.value)

      if (!deepEqual(removeEmptyFields(values), preSetValues)) {
        setPreset(null)
      }
    }
  }

  const onCancelFilter = (): void => {
    clearPresetIsEqual()
    onCancel()
  }

  const onApply = (): void => {
    const params = { ...initValues, ...currentFilters, ...getValues() } as TFiltersState

    void state?.handleLastUsedFilter(params)
    onApplyFilters()
    reset(params)
    clearPresetIsEqual()
  }

  useEffect(() => {
    reset(initValues)

    if (onChange) {
      onChange({ data: initValues } as IFormStateChanges<TFiltersState>)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValues])

  useEffect(() => {
    if (isClearable) {
      setIsClearable(false)
    }

    if (!preset && !open && !isClearable) {
      reset(initValues)
      void filter(initValues as TFiltersState)
    }

    if (preset) {
      const params = parseFilterValues<TFiltersState>(preset.value)

      if (params) {
        reset(params)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preset])

  return (
    <Modal open={open} onClose={onCancel}>
      <Slide timeout={globalConstants.dialogAnimationTime} in={open} direction="left">
        <form className={cls(styles.filtersFormContent, 'xt-dialog-scrollable')}>
          <IconButton className={styles.closeIcon} onClick={onCancelFilter}>
            <SvgIcon className={styles.closeIconSize} iconId={SvgIconIds.CLOSE_ICON} />
          </IconButton>
          <h2>{label}</h2>
          <div className={styles.filtersContent}>
            <XtDialog
              className="xt-dialog-details-content"
              open={openSaveDialog}
              fullScreen={false}
              animation={XtDialogAnimation.FadeAnimation}
            >
              <SavePresetDialog
                onClose={closeSaveDialog}
                pagename={state?.pagename}
                updatePageFilter={onUpdatePageFilter}
                createPageFilter={onCreatePageFilter}
              />
            </XtDialog>
            <div className="xt-page-header">
              <div className={styles.preSetContent}>
                <XtInfoValues values={filtersPresetInfoValue} />
                <XtSelect clearable options={state?.pageFilters ?? []} label="Filter Name" onChange={setPreset} value={preset} />
                <XtButton
                  icon={SvgIconIds.DELETE}
                  iconClass={styles.icon}
                  className={styles.button}
                  label=""
                  onClick={onDelete}
                  labelClass={styles.label}
                />
              </div>
            </div>
            <XtButton label={FilterButtonLabels.Apply} key="button-apply" variant={XtButtonVariant.Primary} onClick={onApply} />
            <div className={styles.buttonContainer}>
              <XtButton
                label={FilterButtonLabels.Clear}
                key="button-clear"
                variant={XtButtonVariant.Secondary}
                onClick={onClear}
                disabled={clearButtonDisable()}
              />
              <XtButton
                label={FilterButtonLabels.Save}
                key="button-save-and-apply"
                variant={XtButtonVariant.Primary}
                onClick={onSaveAndApply}
              />
            </div>
            {children}
          </div>
        </form>
      </Slide>
    </Modal>
  )
}
