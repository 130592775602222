import React, { BaseSyntheticEvent, FC, useRef, useState, CSSProperties } from 'react'

import { IconButton, Popover } from '@material-ui/core'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'

export interface IXtInfoPopupIcon {
  title: string
  popup: React.ReactElement
  className?: string
  popupStyle?: CSSProperties
}

export const XtInfoPopupIcon: FC<IXtInfoPopupIcon> = ({ title, popup, className, popupStyle }) => {
  const anchorElRef = useRef(null)
  const [open, setOpen] = useState(false)

  const togglePopup: (event: BaseSyntheticEvent) => void = (event) => {
    // We should prevent a parent component to be focused
    event.stopPropagation()
    setOpen((prevState) => !prevState)
  }

  const handleBackdropClick: (event: BaseSyntheticEvent) => void = (event) => {
    // We should prevent a parent component to be focused
    event.stopPropagation()
    setOpen(false)
  }

  return (
    <div title={title} className={className}>
      <IconButton ref={anchorElRef} onClick={togglePopup}>
        <SvgIcon iconId={SvgIconIds.INFO_ICON} />
      </IconButton>
      <Popover style={popupStyle} anchorEl={anchorElRef.current} open={open} onBackdropClick={handleBackdropClick}>
        {popup}
      </Popover>
    </div>
  )
}
