import { IHttpClient } from '../../../core/services/http-client'
import { bindAllInstanceMethods } from '../../../common/utils/object.utils'
import { InventoryHistoryByLocationFilters } from './inventory-history-by-location/inventory-history-by-location-list-form/inventory-history-by-location-list-form.types'
import { IPaginationData, IPaginationParams, IPaginationResponse } from '../../../common/common.types'
import { ISortOption } from '../../../components/table/table-head/table-head.types'
import {
  IInventoryHistoryByLocation,
  InventoryHistoryByLocationTableItem,
} from './inventory-history-by-location/inventory-history-by-location-list.types'
import { prepareRequestParams } from '../../../common/utils/request.utils'
import {
  IInventoryHistoryByLotSerial,
  InventoryHistoryByLotSerialFilters,
} from './inventory-history-by-lot-serial-list/inventory-history-by-lot-serial-list.types'
import { IInventoryHistory, InventoryHistoryFilters } from './inventory-history/inventory-history-list.types'

export interface IInventoryHistoryService {
  getAllByLocation(
    filters: InventoryHistoryByLocationFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IInventoryHistoryByLocation>>
  getAllByLotSerial(
    filters: InventoryHistoryByLotSerialFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IInventoryHistoryByLotSerial>>
  getAll(
    filters: InventoryHistoryFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IInventoryHistory>>
  printByLocation(filters: InventoryHistoryByLocationFilters): Promise<Blob>
  printByLotserial(filters: InventoryHistoryByLotSerialFilters): Promise<Blob>
  printAll(filters: InventoryHistoryFilters): Promise<Blob>
}

export class InventoryHistoryService implements IInventoryHistoryService {
  constructor(private readonly httpClient: IHttpClient) {
    bindAllInstanceMethods(this)
  }

  public async getAllByLocation(
    filters: InventoryHistoryByLocationFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<InventoryHistoryByLocationTableItem>> {
    const params = prepareRequestParams(paginationParams, filters, sortOptions)

    const {
      data: { data, status },
    } = await this.httpClient.get<IPaginationResponse<InventoryHistoryByLocationTableItem>>(`/inventoryhistory/location`, { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getAllByLotSerial(
    filters: InventoryHistoryByLotSerialFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IInventoryHistoryByLotSerial>> {
    const params = prepareRequestParams(paginationParams, filters, sortOptions)

    const {
      data: { data, status },
    } = await this.httpClient.get<IPaginationResponse<IInventoryHistoryByLotSerial>>(`/inventoryhistory/lotserial`, { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getAll(
    filters: InventoryHistoryFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IInventoryHistory>> {
    const params = prepareRequestParams(paginationParams, filters, sortOptions)

    const {
      data: { data, status },
    } = await this.httpClient.get<IPaginationResponse<IInventoryHistory>>(`/inventoryhistory`, { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async printByLocation(filters: InventoryHistoryByLocationFilters): Promise<Blob> {
    const params = prepareRequestParams(undefined, filters)
    try {
      const { data } = await this.httpClient.get<Blob>(`/inventoryhistory/location/form`, { params, responseType: 'blob' })
      return data
    } catch {
      throw new Error(`There are no documents to print`)
    }
  }

  public async printByLotserial(filters: InventoryHistoryByLotSerialFilters): Promise<Blob> {
    const params = prepareRequestParams(undefined, filters)
    try {
      const { data } = await this.httpClient.get<Blob>(`/inventoryhistory/lotserial/form`, { params, responseType: 'blob' })
      return data
    } catch {
      throw new Error(`There are no documents to print`)
    }
  }

  public async printAll(filters: InventoryHistoryFilters): Promise<Blob> {
    const params = prepareRequestParams(undefined, filters)
    try {
      const { data } = await this.httpClient.get<Blob>(`/inventoryhistory/form`, { params, responseType: 'blob' })
      return data
    } catch {
      throw new Error(`There are no documents to print`)
    }
  }
}
