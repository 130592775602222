import React, { FC, ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { Subject } from 'rxjs'
import { delay, distinctUntilChanged, filter, tap } from 'rxjs/operators'
import { Fade, Modal, Slide } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import { globalConstants } from 'common/constants'
import { cls } from 'common/utils/utils'
import { homeRoutePath } from 'home/home.constants'
import logo from 'assets/img/x.png'
import * as styles from './xt-dialog.module.scss'

export enum XtDialogAnimation {
  SlideAnimation,
  FadeAnimation,
}

export interface IXtDialogParams {
  open: boolean
  animation: XtDialogAnimation
  className?: string
  onClose?: () => void
  fullScreen: boolean
}

function buildDialog(
  open: boolean,
  onClose: (() => void) | undefined,
  animation: XtDialogAnimation,
  children: ReactNode,
  fullScreen: boolean,
  className?: string
): ReactElement | null {
  const content = (
    <div className={cls(fullScreen == true ? styles.xtDialogContainerFullScreen : styles.xtDialogContainer, className)}>
      {fullScreen ? (
        <NavLink className={styles.xtDialogLogoNav} to={homeRoutePath}>
          <img className={styles.xtDialogLogoImage} src={logo} alt="xTuple Logo" />
        </NavLink>
      ) : (
        ''
      )}
      {children}
    </div>
  )

  if (animation === XtDialogAnimation.SlideAnimation) {
    return (
      <Modal open={open} onClose={onClose}>
        <Slide timeout={globalConstants.dialogAnimationTime} in={open} direction="left">
          {content}
        </Slide>
      </Modal>
    )
  }
  return (
    <Modal open={open} onClose={onClose}>
      <Fade timeout={globalConstants.dialogAnimationFadeTime} in={open}>
        {content}
      </Fade>
    </Modal>
  )
}

// TODO make children required
export const XtDialog: FC<IXtDialogParams> = ({ open, animation, children, className, onClose, fullScreen }) => {
  const [dialogExists, setDialogExists] = useState<boolean>(false)
  const openStateSubject = useRef<Subject<boolean>>(new Subject<boolean>())

  useEffect(() => {
    openStateSubject.current.next(open)
  }, [open])

  useEffect(() => {
    const sub = openStateSubject.current
      .asObservable()
      .pipe(
        distinctUntilChanged(),
        tap((isOpen) => {
          if (isOpen) {
            setDialogExists(isOpen)
          }
        }),
        filter((isOpen) => !isOpen),
        delay(globalConstants.dialogAnimationFadeTime)
      )
      .subscribe((closed) => setDialogExists(closed))

    return () => sub.unsubscribe()
  }, [])

  return dialogExists ? buildDialog(open, onClose, animation, children, fullScreen, className) : null
}
