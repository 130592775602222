import React, { FC } from 'react'
import * as styles from './welcome-msg.module.scss'

export const WelcomeMsg: FC = () => {
  return (
    <div className={styles.welcomeMsgContainer}>
      <div className={styles.welcomeMsgHeader}>
        Congratulations on your purchase of xT Sales, a powerful relationship management tool for manufacturers and distributors!
      </div>
      <div>
        There are two ways you can get started with xT Sales: reach out to the xTuple Customer Success team to help get you onboarded - or -
        do it yourself online. Either way, there is some basic information you’ll need to pull together to get everything set up. Let’s
        start with some core concepts:
      </div>
      <div>
        <ul className={styles.listContainer}>
          <li>
            <span className={styles.boldText}>Master Information</span>. There are a handful of things that you can either enter directly
            into your xT Sales system, one at a time - or - if you prefer, you can fill out an easy spreadsheet template we’ve designed to
            simplify the process.
          </li>
          <li className={styles.disabledBullet}>
            <ul>
              <li>
                <span className={styles.boldText}>Items</span>. Simply, these are the things you sell. We’ll get names, descriptions, SKU
                numbers, list prices, and a few other basic data points.
              </li>
              <li>
                <span className={styles.boldText}>Accounts</span>. An Account is any company you do business with. You can associate an
                unlimited number of Contacts with any Account. An Account can also be easily converted from a Prospect to a Customer.
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  )
}
