// extracted by mini-css-extract-plugin
export var navContainer = "navbar_navContainer__UzJh-";
export var tabsContainer = "navbar_tabsContainer__KPkRe";
export var checkk = "navbar_checkk__435OA";
export var printBtnWidth = "navbar_printBtnWidth__UMzEC";
export var indicator = "navbar_indicator__4hRXD";
export var menuButton = "navbar_menuButton__ezFHY";
export var tabTextInherit = "navbar_tabTextInherit__AKyIW";
export var dropDownTabMenuWidth = "navbar_dropDownTabMenuWidth__qCoBt";
export var paper = "navbar_paper__P8Wig";
export var menuList = "navbar_menuList__F+Z4x";
export var muiExpandIconSize = "navbar_muiExpandIconSize__BCnjP";
export var muiCollapseIcon = "navbar_muiCollapseIcon__WtT7A";