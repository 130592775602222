import * as React from 'react'
import { FC, useCallback } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { cls } from 'common/utils/utils'
import { xsMq } from 'common/constants'
import { useTable } from 'common/hooks/useTable'
import { XtList } from 'components/list/list'

import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { SummarizedListForm } from './summarized-list-form/summarized-list-form'
import { costedBomColumns, summarizedListPageTitle } from './summarized-list.constants'
import * as styles from '../bom-list-pages.module.scss'
import { useProductsModule } from '../../products-module-hook'

export const SummarizedList: FC = () => {
  const { CostingBomUtilsService } = useProductsModule()
  const isMobile = useMediaQuery(xsMq)
  useDocumentTitle(summarizedListPageTitle)
  const {
    state: { data, loading, sortOptions },
    filter,
    pagination,
    sort,
  } = useTable({}, CostingBomUtilsService.fetchCostedBomSummary)

  const onChangeFilters = useCallback(
    (filters) => {
      void filter(filters)
    },
    [filter]
  )

  return (
    <div className={cls('xt-content', styles.listContent)}>
      <div className="xt-page-header">
        <h1 className="xt-page-title">Costed Summarized BOM</h1>
      </div>
      <SummarizedListForm onChange={onChangeFilters} />
      <XtList
        sortOptions={sortOptions}
        onColumnHeaderClick={sort}
        pagination={pagination}
        columns={costedBomColumns}
        data={data}
        isMobile={isMobile}
        loading={loading}
      />
    </div>
  )
}
