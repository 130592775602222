import React, { FC, useCallback, useMemo, useRef } from 'react'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { XtList } from 'components/list/list'
import { confirmationMessages } from 'common/constants'
import { RowRenderer } from 'components/table/table-row/table-row.types'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { useSharedModule } from 'shared/shared-module-hook'
import { IXtTableColumnEditable } from 'components/table/table-head/table-head.types'
import { XtMode } from 'common/common.types'
import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { useCoreModule } from 'core/core-module-hook'
import { DocumentType, IAttachedDocumentWithFile, UsedOnValue } from 'documents/documents.types'
import { useCharacteristicsModule } from 'characteristics/characteristics-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { cls } from 'common/utils/utils'
import { XtInfoValue } from 'components/xt-info-values/xt-info-values.types'
import { XtInfoValues } from 'components/xt-info-values/xt-info-values'
import * as styles from './lot-serial.module.scss'
import { HeaderInfoLabelKey, ILotSerialParams, LotSerialAction, LotSerialEntryRow, LotSerialField } from './lot-serial.types'
import { LotSerialTableRow } from './table-row/lot-serial-table-row'
import { defineAddButtonLabel, defineTableActions, defineTableColumns } from './lot-serial.utils'
import { LotSerialDetails } from './lot-serial-dialog/lot-serial-details'
import { supportsAutoLocationDistribution } from './hooks/lot-serial-hook/lot-serial-hook.utils'
import { useInventoryAdjustmentModule } from '../../inventory-adjustments-module.hook'
import { defaultHeaderInfoLabels } from './lot-serial.constants'

export const LotSerial: FC<ILotSerialParams> = ({ lotSerialState, submitted, headerInfoLabels = defaultHeaderInfoLabels, className }) => {
  const { useDialog } = useSharedModule()
  const { ErrorHandler } = useCoreModule()
  const { InventoryAdjustmentUtilsService } = useInventoryAdjustmentModule()
  const { CharacteristicsDialog } = useCharacteristicsModule()
  const { XtDocumentsDialog } = useDocumentsModule()

  const { data: lotSerialData, open: lotSerialDialogOpen, openDialog: openLotSerialDialog, closeDialog: closeLotSerialDialog } = useDialog<{
    item: LotSerialEntryRow
    lotSerialNumber: string
  } | null>(null)

  const pendingRowRef = useRef<LotSerialEntryRow | null>(null)

  const {
    data,
    addLotSerialEntry,
    deleteItem,
    updateItem,
    qtyAssigned,
    qtyToAssign,
    qtyRemaining,
    defaultLocation,
    locations,
    lotSerialNumberOptions,
    itemSite,
    loading,
    quantityDecimalScale,
    updateItemAsync,
  } = lotSerialState

  const {
    open: characteristicsDialogOpen,
    openDialog: openCharacteristicsDialog,
    closeDialog: closeCharacteristicsDialog,
    data: characteristicsData,
  } = useDialog<{ payload: IAppliedCharacteristic[]; id: string; mode: XtMode }>()

  const { open: documentsDialogOpen, openDialog: openDocumentsDialog, closeDialog: closeDocumentsDialog, data: documentsData } = useDialog<{
    sourceNumber: string | null
    lotSerialNumber: string
    documents: IAttachedDocumentWithFile[] | undefined
  }>()

  const {
    open: confirmationDialogOpen,
    openDialog: openConfirmationDialog,
    closeDialog: closeConfirmationDialog,
    data: itemIdToDelete,
  } = useDialog<string | null>()

  const isQtyToAssignNegative = qtyToAssign < 0

  const handleAction = useCallback<(item: LotSerialEntryRow, action: LotSerialAction) => Promise<void>>(
    async (item, action) => {
      const lotSerialNumber = item[LotSerialField.LotSerialNumber]?.id

      if (action === LotSerialAction.Delete) {
        openConfirmationDialog(item.id)
      }

      if (!lotSerialNumber || !item.isValid) {
        return
      }

      pendingRowRef.current = item

      if (action === LotSerialAction.Edit) {
        openLotSerialDialog({ item, lotSerialNumber })
      }

      if (!itemSite) {
        return
      }

      const lotSerial = await InventoryAdjustmentUtilsService.requestLotSerial(itemSite.item_number, lotSerialNumber)
      const lotSerialMode = lotSerial ? XtMode.Edit : XtMode.New

      if (action === LotSerialAction.Documents) {
        const sourceNumber = lotSerial ? `${itemSite.item_number}-${lotSerialNumber}` : null
        openDocumentsDialog({ sourceNumber, lotSerialNumber, documents: item.documents })
      }

      if (action === LotSerialAction.Characteristics) {
        const characteristics = lotSerial?.lotserial_characteristics
          ? lotSerial?.lotserial_characteristics
          : item.lotserial_characteristics ?? []

        openCharacteristicsDialog({
          payload: characteristics,
          id: lotSerialNumber,
          mode: lotSerialMode,
        })
      }
    },
    [itemSite, openLotSerialDialog, openConfirmationDialog, openDocumentsDialog, openCharacteristicsDialog, InventoryAdjustmentUtilsService]
  )

  const renderRow = useCallback<RowRenderer<LotSerialEntryRow, IXtTableColumnEditable<LotSerialEntryRow>>>(
    (row, columns, rowParams) => (
      <LotSerialTableRow
        submitted={submitted}
        isNegativeQtyToAssign={isQtyToAssignNegative}
        data={row}
        columns={columns}
        onChange={updateItemAsync}
        rowParams={rowParams}
      />
    ),
    [isQtyToAssignNegative, submitted, updateItem]
  )

  const handleDeletion: () => void = () => {
    closeConfirmationDialog()
    if (itemIdToDelete) {
      deleteItem(itemIdToDelete)
    }
  }

  const autoDistributedLocationApplied = !!itemSite && supportsAutoLocationDistribution(itemSite, defaultLocation, qtyToAssign)

  const columns = useMemo(
    () =>
      defineTableColumns(
        itemSite,
        locations,
        lotSerialNumberOptions,
        isQtyToAssignNegative,
        autoDistributedLocationApplied,
        handleAction,
        quantityDecimalScale
      ),
    [autoDistributedLocationApplied, handleAction, isQtyToAssignNegative, itemSite, locations, lotSerialNumberOptions, quantityDecimalScale]
  )

  const tableActions = useMemo(() => defineTableActions(itemSite, isQtyToAssignNegative, autoDistributedLocationApplied), [
    autoDistributedLocationApplied,
    isQtyToAssignNegative,
    itemSite,
  ])

  const infoValues: XtInfoValue[] = useMemo(
    () => [
      { label: headerInfoLabels[HeaderInfoLabelKey.QtyToAssign], value: String(qtyToAssign) },
      { label: headerInfoLabels[HeaderInfoLabelKey.QtyAssigned], value: String(qtyAssigned) },
      { label: headerInfoLabels[HeaderInfoLabelKey.QtyRemaining], value: String(qtyRemaining) },
    ],
    [headerInfoLabels, qtyAssigned, qtyRemaining, qtyToAssign]
  )

  const handleLotSerialDialogClose = (): void => {
    if (pendingRowRef.current) {
      updateItem(pendingRowRef.current)
      pendingRowRef.current = null
    }
    closeLotSerialDialog()
  }

  const onSubmitLotSerialDialog = (updatedEntry: LotSerialEntryRow): void => {
    updateItem(updatedEntry)
    pendingRowRef.current = null

    closeLotSerialDialog()
  }

  const saveLotSerialCharacteristics = async (characteristics: IAppliedCharacteristic[], lotSerialNumber: string | null): Promise<void> => {
    if (!lotSerialNumber || !itemSite || characteristicsData.mode === XtMode.New) {
      if (!pendingRowRef.current) {
        return
      }

      updateItem({ ...pendingRowRef.current, lotserial_characteristics: characteristics })
      pendingRowRef.current = null
      return
    }
    try {
      await InventoryAdjustmentUtilsService.updateLotSerialCharacteristics(itemSite.item_number, lotSerialNumber, characteristics)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const saveLotSerialDocuments = async (documents: IAttachedDocumentWithFile[], lotSerialNumber: string | null): Promise<void> => {
    if (!lotSerialNumber || !itemSite || !documentsData.sourceNumber) {
      if (!pendingRowRef.current) {
        return
      }

      updateItem({ ...pendingRowRef.current, documents })
      pendingRowRef.current = null
      return
    }
    try {
      await InventoryAdjustmentUtilsService.updateLotSerialDocuments(itemSite.item_number, lotSerialNumber, documents)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  return (
    <div className={cls(className, styles.lotSerial)}>
      <XtDialog
        className="xt-dialog-details-content"
        open={lotSerialDialogOpen}
        fullScreen={false}
        animation={XtDialogAnimation.FadeAnimation}
      >
        {itemSite && lotSerialData && pendingRowRef.current && (
          <LotSerialDetails
            item={pendingRowRef.current}
            lotSerialNumber={lotSerialData.lotSerialNumber}
            itemSite={itemSite}
            onClose={handleLotSerialDialogClose}
            onClientSubmit={onSubmitLotSerialDialog}
          />
        )}
      </XtDialog>

      <XtDialog
        className="xt-dialog-details-content"
        open={characteristicsDialogOpen}
        fullScreen={false}
        animation={XtDialogAnimation.FadeAnimation}
      >
        {characteristicsData && (
          <CharacteristicsDialog
            characteristics={characteristicsData.payload}
            id={characteristicsData.id}
            onClose={closeCharacteristicsDialog}
            onConfirm={saveLotSerialCharacteristics}
            usedOnFilter={UsedOnValue.LotSerial}
            disabled={isQtyToAssignNegative}
          />
        )}
      </XtDialog>

      <XtDialog
        className="xt-dialog-details-content"
        open={documentsDialogOpen}
        fullScreen={false}
        animation={XtDialogAnimation.FadeAnimation}
      >
        {documentsData && (
          <XtDocumentsDialog
            type={DocumentType.LotSerial}
            sourceNumber={documentsData.sourceNumber}
            entityId={documentsData.lotSerialNumber}
            onClose={closeDocumentsDialog}
            disabled={isQtyToAssignNegative}
            onConfirm={saveLotSerialDocuments}
            mode={XtMode.New}
            defaultDocuments={documentsData.documents}
          />
        )}
      </XtDialog>

      <XtConfirmationDialog
        open={confirmationDialogOpen}
        message={confirmationMessages.deleted}
        title="Delete Item"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />
      <div className={styles.lotSerialHeader}>
        <XtInfoValues values={infoValues} classes={{ values: styles.lotSerialHeaderInfo }} />
        {itemSite && (
          <XtResponsiveButton
            disabled={qtyRemaining === 0}
            className={styles.lotSerialAddButton}
            onClick={addLotSerialEntry}
            label={defineAddButtonLabel(itemSite)}
            icon={SvgIconIds.ADD_CIRCLE}
          />
        )}
      </div>
      <XtList
        data={data}
        className="xt-lot-serial-table"
        renderRow={renderRow}
        actions={tableActions}
        onAction={handleAction}
        columns={columns}
        loading={loading}
      />
    </div>
  )
}
