import { IHttpClient } from 'core/services/http-client'
import { IPaginationData, IPaginationParams, IPaginationResponse, IResponse, IResponseStatusPagination } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IOrderAvailabilityFilters } from 'sales-shared/line-items/line-item-supply/line-item-supply.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { CommentsCreationError, NewComment } from 'comments/comments.types'
import { DocumentCreationError, DocumentType, IAttachedDocumentWithFile, IDocumentsService } from 'documents/documents.types'
import { convertNewComments } from 'comments/comments.utils'
import { ICommentsService } from 'comments/comments.service'
import { isEmptyResponse } from 'core/core.utils'
import { EmptyResponseError } from 'core/core.types'
import { ICreateItemFormState } from 'setup-wizard/basic-configuration-steps/create-items/create-item-dialog/create-item-dialog.types'
import { IInventoryLookupParams } from '../inventory/inventory-list/inventory-list.types'
import { IItemsFilters } from './items-list/items-list.types'
import {
  IInventoryAvailability,
  IItem,
  IItemPrice,
  IOrderAvailabilityItem,
  CreateItemPayload,
  ICostedBom,
  CostedBomSummaryFilters,
  CostedBomFilters,
  ICostedBomSummary,
  ICostedBomTotals,
  IItemCostsSummary,
  IItemCostsByClassCode,
  ItemCostsByClassCodeFilters,
  IItemCostsSummaryTotal,
  IItemCostsHistory,
  ILotSerialNumber,
  ILotSerialNumberFilters,
  ILotSerial,
  LotSerialPayload,
} from './items.types'
import { defineLotSerialSourceNumber } from './items.utils'

interface IItemPriceFilters {
  customer_number: string
  quantity: number | null
  site: string
  currency: string
  selling_uom: string
  order_date: string | null
  schedule_date: string | null
  shipto_number: string | null
  shipping_zone: string | null
  sale_type: string | null
}

export interface IItemsService {
  getAll(pagination?: IPaginationParams, filters?: IItemsFilters, sortOptions?: ISortOption[]): Promise<IPaginationData<IItem>>
  create(item: CreateItemPayload | ICreateItemFormState, comments?: NewComment[], documents?: IAttachedDocumentWithFile[]): Promise<string>
  update(item: CreateItemPayload | ICreateItemFormState): Promise<string>
  get(itemNumber: string): Promise<IItem>
  getItemPrices(itemNumber: string, params: IItemPriceFilters): Promise<IItemPrice>
  getInventoryAvailabilityItems(
    itemNumber: string,
    pagination: IPaginationParams,
    filters?: IInventoryLookupParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IInventoryAvailability>>
  getItemPDF(itemNumber: string): Promise<Blob>
  delete(item_number: string): Promise<string>
  getOrderAvailability(
    itemNumber: string,
    site: string,
    filters: IOrderAvailabilityFilters,
    sortOptions?: ISortOption[]
  ): Promise<IResponse<IOrderAvailabilityItem, IResponseStatusPagination>>
  getCostedBomSummary(
    filters: CostedBomSummaryFilters,
    paginationParams?: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<ICostedBomSummary>>
  getCostedBom(
    filters: CostedBomFilters,
    paginationParams?: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<ICostedBom>>
  getCostedBomTotals(filters: CostedBomFilters): Promise<IPaginationData<ICostedBomTotals>>
  getCostedBomSummaryPDF(filters: CostedBomSummaryFilters): Promise<Blob>
  getCostsByClassCode(
    paginationParams: IPaginationParams,
    filters: ItemCostsByClassCodeFilters,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemCostsByClassCode>>
  getCostsByClassCodePDF(filters: ItemCostsByClassCodeFilters): Promise<Blob>
  getCostedBomPDF(filters: CostedBomFilters): Promise<Blob>
  getItemCostsSummary(
    itemNumber: string,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemCostsSummary>>
  getItemCostsSummaryTotal(itemNumber: string): Promise<IItemCostsSummaryTotal>
  getItemCostsSummaryPDF(itemNumber: string): Promise<Blob>
  getCostsHistory(
    itemNumber: string,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemCostsHistory>>
  getCostsHistoryPDF(itemNumber: string): Promise<Blob>
  getLotSerialNumbers(filters: ILotSerialNumberFilters): Promise<ILotSerialNumber[]>
  getLotSerial(itemNumber: string, lotSerial: string): Promise<ILotSerial>
  updateLotSerial(lotSerial: LotSerialPayload): Promise<string>
}

export class ItemsService implements IItemsService {
  constructor(
    private readonly apiClient: IHttpClient,
    private readonly commentsService: ICommentsService,
    private readonly documentsService: IDocumentsService
  ) {}

  public async getAll(
    paginationParams?: IPaginationParams,
    filters?: IItemsFilters,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItem>> {
    const params = prepareRequestParams(paginationParams, filters, sortOptions)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IItem>>('/item', { params })
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async create(
    item: CreateItemPayload | ICreateItemFormState,
    comments?: NewComment[],
    documents?: IAttachedDocumentWithFile[]
  ): Promise<string> {
    const body = { data: item }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post('/item/create', body)

    if (comments) await this.createCommentsForItem(item.item_number, comments)
    if (documents) await this.createDocumentsForItem(item.item_number, documents)
    return message
  }

  public async update(item: CreateItemPayload): Promise<string> {
    const body = { data: item }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post('/item/update', body)
    return message
  }

  public async get(itemNumber: string): Promise<IItem> {
    const response = await this.apiClient.get<IResponse<IItem>>(`/item/${itemNumber}`)
    if (!Object.keys(response.data.data).length) {
      throw new Error(`Item: ${itemNumber} not found.`)
    }
    return response.data.data
  }

  public async getItemPrices(itemNumber: string, params: IItemPriceFilters): Promise<IItemPrice> {
    const response = await this.apiClient.get<IResponse<IItemPrice>>(`/item/${itemNumber}/prices`, { params })
    return response.data.data
  }

  public async delete(item_number: string): Promise<string> {
    const body = { data: { item_number } }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post('/item/delete', body)
    return message
  }

  public async getItemPDF(itemNumber: string): Promise<Blob> {
    const { data } = await this.apiClient.get(`/item/${itemNumber}/form`, {
      responseType: 'blob',
    })
    return data
  }

  public async getInventoryAvailabilityItems(
    itemNumber: string,
    pagination: IPaginationParams,
    filters?: IInventoryLookupParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IInventoryAvailability>> {
    const params = prepareRequestParams(pagination, filters, sortOptions)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IInventoryAvailability>>(`/item/${itemNumber}/availability`, { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getOrderAvailability(
    itemNumber: string,
    site: string,
    filters: IOrderAvailabilityFilters,
    sortOptions?: ISortOption[]
  ): Promise<IResponse<IOrderAvailabilityItem, IResponseStatusPagination>> {
    // TODO: TBD discuss with David about lineNumber and orderNumber and update me

    const params = prepareRequestParams(undefined, filters, sortOptions)
    const {
      data: { data, status },
    } = await this.apiClient.get<IResponse<IOrderAvailabilityItem, IResponseStatusPagination>>(
      `/item/${itemNumber}/orderavailability/${site}`,
      {
        params,
      }
    )

    return {
      data,
      status,
    }
  }

  public async getCostedBomSummary(
    { item_number: itemNumber, ...filters }: CostedBomSummaryFilters,
    paginationParams?: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<ICostedBomSummary>> {
    const params = prepareRequestParams(paginationParams, filters, sortOptions)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ICostedBomSummary>>(`/item/${itemNumber}/costedbomsummary`, { params })
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getCostedBom(
    { item_number: itemNumber, ...filters }: CostedBomFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<ICostedBom>> {
    const params = prepareRequestParams(paginationParams, filters, sortOptions)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<ICostedBom>>(`/item/${itemNumber}/costedbom`, { params })
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getCostsByClassCode(
    paginationParams: IPaginationParams,
    filters: ItemCostsByClassCodeFilters,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemCostsByClassCode>> {
    const params = prepareRequestParams(paginationParams, filters, sortOptions)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IItemCostsByClassCode>>('/item/costs/byclasscode', { params })
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getCostedBomSummaryPDF({ item_number: itemNumber, ...filters }: CostedBomSummaryFilters): Promise<Blob> {
    const params = prepareRequestParams(undefined, filters)
    const { data } = await this.apiClient.get(`/item/${itemNumber}/costedbomsummary/form`, {
      params,
      responseType: 'blob',
    })
    return data
  }

  public async getCostsByClassCodePDF(filters: ItemCostsByClassCodeFilters): Promise<Blob> {
    const params = prepareRequestParams(undefined, filters)
    const { data } = await this.apiClient.get('/item/costs/byclasscode/form', {
      params,
      responseType: 'blob',
    })
    return data
  }

  public async getCostedBomTotals({ item_number, ...filters }: CostedBomFilters): Promise<IPaginationData<ICostedBomTotals>> {
    const params = prepareRequestParams(undefined, filters)
    const {
      data: { data, status },
    } = await this.apiClient.get(`/item/${item_number}/costedbom/totals`, { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getCostedBomPDF({ item_number: itemNumber, ...filters }: CostedBomFilters): Promise<Blob> {
    const params = prepareRequestParams(undefined, filters)
    const { data } = await this.apiClient.get(`/item/${itemNumber}/costedbom/form`, {
      params,
      responseType: 'blob',
    })
    return data
  }

  public async getItemCostsSummary(
    itemNumber: string,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemCostsSummary>> {
    const params = prepareRequestParams(paginationParams, undefined, sortOptions)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IItemCostsSummary>>(`/item/${itemNumber}/costs/summary`, { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getItemCostsSummaryTotal(itemNumber: string): Promise<IItemCostsSummaryTotal> {
    const {
      data: { data },
    } = await this.apiClient.get<IResponse<IItemCostsSummary>>(`/item/${itemNumber}/costs/summary/totals`)

    return data
  }

  public async getItemCostsSummaryPDF(itemNumber: string): Promise<Blob> {
    const { data } = await this.apiClient.get(`/item/${itemNumber}/costs/summary/form`, {
      responseType: 'blob',
    })
    return data
  }

  public async getCostsHistory(
    itemNumber: string,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemCostsHistory>> {
    const params = prepareRequestParams(paginationParams, undefined, sortOptions)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IItemCostsHistory>>(`/item/${itemNumber}/costs/history`, { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async getCostsHistoryPDF(itemNumber: string): Promise<Blob> {
    const { data } = await this.apiClient.get(`/item/${itemNumber}/costs/history/form`, {
      responseType: 'blob',
    })
    return data
  }

  public async getLotSerialNumbers(filters: ILotSerialNumberFilters): Promise<ILotSerialNumber[]> {
    const params = prepareRequestParams(undefined, filters)

    const {
      data: { data },
    } = await this.apiClient.get<IResponse<ILotSerialNumber[]>>(`/item/lotserial`, {
      params,
    })
    return data
  }

  public async getLotSerial(itemNumber: string, lotSerialNumber: string): Promise<ILotSerial> {
    const { data } = await this.apiClient.get<IResponse<ILotSerial>>(`/item/${itemNumber}/lotserial/${lotSerialNumber}`)

    if (isEmptyResponse(data)) {
      throw new EmptyResponseError(`Lot/Serial ${lotSerialNumber} doesn't exist for item number: ${itemNumber}`)
    }

    return data.data
  }

  public async updateLotSerial(lotSerial: LotSerialPayload): Promise<string> {
    const data = { data: lotSerial }
    const {
      data: {
        status: { message },
      },
    } = await this.apiClient.post<IResponse<string>>(`/item/lotserial/update`, data)
    return message
  }

  private async createCommentsForItem(quoteNumber: string, comments: NewComment[]): Promise<void> {
    if (!comments.length) {
      return
    }
    try {
      const commentsPayload = convertNewComments(comments, DocumentType.Item, quoteNumber)
      await this.commentsService.createAll(commentsPayload)
    } catch (e) {
      throw new CommentsCreationError()
    }
  }

  private async createDocumentsForItem(number: string, documents: IAttachedDocumentWithFile[]): Promise<void> {
    if (!documents.length) {
      return
    }
    try {
      await this.documentsService.createFilesForSource(documents, DocumentType.Item, number)
    } catch (e) {
      throw new DocumentCreationError()
    }
  }

  protected async createDocumentsForLotSerial(
    itemNumber: string,
    lotSerialNumber: string,
    documents: IAttachedDocumentWithFile[]
  ): Promise<void> {
    if (!documents.length) {
      return
    }
    const sourceNumber = defineLotSerialSourceNumber(itemNumber, lotSerialNumber)
    try {
      await this.documentsService.createFilesForSource(documents, DocumentType.LotSerial, sourceNumber)
    } catch (e) {
      throw new DocumentCreationError()
    }
  }
}
