import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { FormControlLabel, Radio } from '@material-ui/core'
import { XtMode } from 'common/common.types'
import { CostElements } from './components/elements/elements'
import { ElementsOption } from './cost-tab.types'
import * as styles from './cost-tab.module.scss'
import { BomDetails } from '../../../../bom/bom-details/bom-details'
import { useProductsModule } from '../../../../products-module-hook'

interface ICostTab {
  itemNumber: string
  isMobile: boolean
}

export const CostTab: FC<ICostTab> = memo(({ itemNumber, isMobile }) => {
  const { BomService } = useProductsModule()
  const [controlElements, setControlRadio] = useState<string>(ElementsOption.Elements)
  const [areBomItemExist, setBomItemExist] = useState(false)

  async function init() {
    try {
      await BomService.get(itemNumber, false, false)
      setBomItemExist(true)
    } catch {
      setBomItemExist(false)
    }
  }

  useEffect(() => {
    void init()
  }, [])

  const handleRadioChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setControlRadio(event.target.value)
  }, [])

  return (
    <div>
      <div>
        <FormControlLabel
          className={styles.radio}
          control={
            <Radio
              color="primary"
              value={ElementsOption.Elements}
              checked={controlElements === ElementsOption.Elements}
              onChange={handleRadioChange}
            />
          }
          label={ElementsOption.Elements}
        />
        <FormControlLabel
          className={styles.radio}
          control={
            <Radio
              color="primary"
              value={ElementsOption.Materials}
              checked={controlElements === ElementsOption.Materials}
              onChange={handleRadioChange}
              disabled={!areBomItemExist}
            />
          }
          label={ElementsOption.Materials}
        />
      </div>
      {controlElements === ElementsOption.Elements ? (
        <CostElements itemNumber={itemNumber} isMobile={isMobile} />
      ) : (
        <BomDetails mode={XtMode.View} componentMode />
      )}
    </div>
  )
})
