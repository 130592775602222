import React from 'react'
import { XtInfoValue } from 'components/xt-info-values/xt-info-values.types'
import { IShipmentData } from 'shipments/shipments.types'
import { OrderType } from 'core/core.types'
import { convertDate } from 'common/utils/date.utils'
import { PhoneLink } from 'components/controls/phone/phone-link/phone-link'

const customerPhoneLabel = 'Customer Phone'

export function resolveShipmentInfoValues({ customer_info, order_type }: IShipmentData): XtInfoValue[] | null {
  if (!customer_info) {
    return null
  }

  const { order_date, customer_name, customer_phone, po_number } = customer_info

  const defaultValues = [
    {
      label: 'Purchase Order #',
      value: po_number,
    },
    {
      label: 'Order Date',
      value: convertDate(order_date),
    },
  ]

  if (order_type !== OrderType.TransferOrder) {
    return [
      {
        label: 'Customer Name',
        value: customer_name,
      },
      {
        label: customerPhoneLabel,
        value: customer_phone,
      },
      ...defaultValues,
    ]
  }

  return defaultValues
}

export function renderShipmentValues(shipmentValues: XtInfoValue[]): React.ReactElement {
  return (
    <div>
      {shipmentValues.map(({ label, value }) => {
        return (
          <div key={`${label}-${value}`} className="xt-summary-content">
            <span className="xt-summary-label">{label}: &nbsp;</span>
            {label === customerPhoneLabel && value ? (
              <PhoneLink showMessageAction={false} phone={value} />
            ) : (
              <span className="xt-text-truncated" title={value ? value : undefined}>
                {value}
              </span>
            )}
          </div>
        )
      })}
    </div>
  )
}
