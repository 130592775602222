import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { switchMap } from 'rxjs/operators'
import { useMediaQuery } from '@material-ui/core'
import { Observable, Subject } from 'rxjs'
import { cls } from 'common/utils/utils'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { LoadingSpinner } from 'components/loading-spinner'
import { confirmationMessages, xsMq } from 'common/constants'
import { DocnumberSetting, DocumentType } from 'documents/documents.types'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { useMultipleFormStateManager } from 'common/hooks/form/multiple-form-manager/multiple-form-manager'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { convertMode } from 'common/utils/mode.utils'
import { UserPermission } from 'auth/auth.types'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCommentsModule } from 'comments/comments-module-hook'
import { useCharacteristicsModule } from 'characteristics/characteristics-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { getCustomerDetailsPageTitle, initialCustomerState } from 'companies/customers/customer-details/customer-details.constants'
import { customerMainFormKey } from 'companies/customers/customer-details/customer-details-form/customer-details-form.constants'
import { contactSectionKey } from 'contacts/components/contact-section/contact-section.constants'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { XtPrompt } from 'components/xt-prompt'
import { CustomerDetailsTabs } from './customer-details-tabs/customer-details-tabs'
import { CustomerDetailsForm } from './customer-details-form/customer-details-form'
import { convertCustomerPayload, shouldDisableCustomerType } from './customer-details.utils'
import * as styles from './customer-details.module.scss'
import { ICustomerCombinedState, ICustomerDetails, ICustomerDetailsState } from './customer-details.types'
import { useCustomersModule } from '../customers-module-hook'
import { useProspectsServicesModule } from '../../prospects/services/prospects-services-module-hook'
import { settingsFormKey } from './customer-details-tabs/settings-tab/settings-tab.constants'

export const CustomerDetails: FC<ICustomerDetails> = ({
  mode,
  onCancel,
  onSubmit,
  refreshDataObservable,
  customerNumber,
  customerName,
}) => {
  const { DocumentsUtilsService, useDocuments, DocumentsService } = useDocumentsModule()
  const { useRemarks } = useCommentsModule()
  const { useCharacteristics } = useCharacteristicsModule()
  const { CustomersService } = useCustomersModule()
  const { ProspectsService } = useProspectsServicesModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { PermissionsService } = useAuthModule()

  const { register, formState, getValue, handleSubmit: onSubmitForm, reset } = useMultipleFormStateManager<ICustomerCombinedState>()

  const [canCreateType, canEditType] = PermissionsService.hasPermissions([
    UserPermission.MaintainCustomerMastersCustomerTypeOnCreate,
    UserPermission.MaintainCustomerMastersCustomerType,
  ])

  const { isViewMode, isEditMode, isNewMode } = convertMode(mode)
  useDocumentTitle(getCustomerDetailsPageTitle(customerNumber ?? undefined))
  const [isReadOnlyNumber, setIsReadOnlyNumber] = useState<boolean>(false)
  const [autoUpdate, setAutoUpadte] = useState<boolean>(false)
  const [_, setDocNumber] = useState<number>()
  const docNumberRef = useRef<string | null>()
  const customerNumberSubject = useRef<Subject<string | null>>(new Subject())
  const customerNumber$ = useRef<Observable<string | null>>(customerNumberSubject.current.asObservable())

  const [{ customer, loading }, setState] = useState<ICustomerDetailsState>(initialCustomerState)

  const disabled = isViewMode || formState.isSubmitting
  const remarksState = useRemarks(DocumentType.Customer)
  const characteristicsState = useCharacteristics([])
  const documentsState = useDocuments(DocumentType.Customer, customer?.customer_number)

  const { reset: characteristicsReset } = characteristicsState
  const { reset: remarksReset } = remarksState

  const { itemId: duplicatedProspectId, open: confirmationDialogOpen, openDialog, closeDialog } = useConfirmationDialog<string>()

  const isMobile = useMediaQuery(xsMq)
  const disabledCustomerType = disabled || shouldDisableCustomerType(isNewMode, isEditMode, canCreateType, canEditType)
  const isDirty = formState.isDirty || remarksState.isDirty || characteristicsState.isDirty || documentsState.isDirty
  const title = !isNewMode ? `${customer?.customer_number}: ${customer?.customer_name}` : 'New Customer'
  const customerNumberDisabled = !isNewMode || !!customerNumber || isReadOnlyNumber

  const [customerNotFound, setCustomerNotFound] = useState(false)

  const releaseDocnumber = useCallback(() => {
    if (isNewMode && docNumberRef.current) void DocumentsService.releaseDocnumber(DocumentType.Account, Number(docNumberRef.current))
  }, [isNewMode, docNumberRef.current])

  const onClose: VoidFunction = async () => {
    // eslint-disable-next-line no-restricted-globals
    releaseDocnumber()
    onCancel()
  }

  const resetAll: () => void = () => {
    reset(isNewMode ? true : false)
    const { comments, notes, additionalNotes } = remarksState
    remarksReset(comments, notes, additionalNotes)
    characteristicsReset(characteristicsState.characteristics)
    documentsState.reset(documentsState.getUnsavedDocuments())
  }

  const onSaveCustomer = useCallback<(formData: ICustomerCombinedState) => Promise<void>>(
    async (formData) => {
      const { [customerMainFormKey]: mainForm, [contactSectionKey]: contactSectionForm, [settingsFormKey]: settingsTermForm } = formData

      const payload = convertCustomerPayload(
        mainForm,
        contactSectionForm,
        characteristicsState.characteristics,
        remarksState.notes,
        settingsTermForm
      )
      try {
        if (isNewMode) {
          const message = await CustomersService.create(payload, documentsState.getUnsavedDocuments())
          ToastService.showSuccess(message)
        }
        if (isEditMode && customer) {
          const message = await CustomersService.update({
            ...customer,
            ...payload,
          })
          ToastService.showSuccess(message)
        }
        onSubmit?.call(this)
        resetAll()
        onCancel()
      } catch (error) {
        ErrorHandler.handleError(error as Error)
      }
    },
    [
      CustomersService,
      ErrorHandler,
      ToastService,
      characteristicsState.characteristics,
      customer,
      documentsState,
      isEditMode,
      isNewMode,
      onCancel,
      onSubmit,
      remarksState.notes,
    ]
  )

  const onSaveForm = async (formData: ICustomerCombinedState): Promise<void> => {
    const mainForm = formData[customerMainFormKey]
    if (!mainForm) {
      return
    }

    try {
      const duplicatedProspect = await CustomersService.getDuplicatedProspect(mainForm.customer_number)
      if (duplicatedProspect) {
        openDialog(mainForm.customer_number)
      } else {
        await onSaveCustomer(formData)
      }
    } catch (error) {
      ErrorHandler.handleError(error as Error)
    }
  }

  const handleConfirmationDialog = useCallback<VoidFunction>(async () => {
    closeDialog()
    if (duplicatedProspectId) {
      try {
        setState((prevState) => ({ ...prevState, isSubmitting: true }))
        await ProspectsService.delete(duplicatedProspectId)
        await onSaveCustomer(getValue())
      } catch (error) {
        ErrorHandler.handleError(error as Error)
      } finally {
        setState((prevState) => ({ ...prevState, isSubmitting: false }))
      }
    }
  }, [ErrorHandler, ProspectsService, closeDialog, duplicatedProspectId, getValue, onSaveCustomer])

  const onCancelDuplicatedProspectDialog = useCallback(() => {
    closeDialog()
    if (!customerNumber || isNewMode) {
      customerNumberSubject.current.next(null)
    }
  }, [closeDialog, customerNumber, isNewMode])

  const init = useCallback(async (): Promise<void> => {
    if (!customerNumber || isNewMode) {
      const { isReadOnly, number } = await DocumentsUtilsService.fetchGenerationSettingsData(
        DocumentType.Account,
        [DocnumberSetting.Override, DocnumberSetting.Manual],
        [DocnumberSetting.Automatic]
      )
      const nextNumber = customerNumber || number || null
      customerNumberSubject.current.next(nextNumber)
      setDocNumber(Number(number))
      docNumberRef.current = number
      setIsReadOnlyNumber(isReadOnly)
      return
    }
    try {
      setState((prev) => ({ ...prev, loading: true }))
      const customerData = await CustomersService.get(customerNumber)
      setState({ loading: false, customer: customerData })
      reset(false)
      characteristicsReset(customerData.customer_characteristics ?? [])
      remarksReset(customerNumber, customerData.notes)
      setCustomerNotFound(false)
    } catch (error) {
      setCustomerNotFound(true)
    }
  }, [CustomersService, DocumentsUtilsService, ErrorHandler, characteristicsReset, customerNumber, isNewMode, remarksReset])

  useEffect(() => releaseDocnumber, [])
  useEffect(() => {
    void init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerNumber])

  useEffect(() => {
    if (customerNotFound) throw Error(`Customer ${customerNumber} not found.`)
  }, [customerNotFound])

  useEffect(() => {
    const refreshData = refreshDataObservable?.pipe(switchMap(() => init())).subscribe()
    return refreshData?.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDataObservable])

  return (
    <div className="xt-content xt-content-with-remarks">
      {loading && <LoadingSpinner />}
      <XtConfirmationDialog
        open={confirmationDialogOpen}
        message={confirmationMessages.customerDuplicatedProspect}
        title={`Duplicated Prospect #: ${duplicatedProspectId}`}
        cancelButtonLabel="No"
        confirmationButtonLabel="Yes"
        onConfirm={handleConfirmationDialog}
        onClose={onCancelDuplicatedProspectDialog}
      />
      <div hidden={loading}>
        <div className={styles.customerDetails}>
          <div className={cls(styles.customerDetailsHeader, 'xt-sticky-header', 'xt-section-border')}>
            <h3 className="xt-page-title" title={title}>
              {title}
            </h3>
            <div className={cls(styles.customerDetailsHeaderButtons, isViewMode && styles.buttonsIsViewMode)}>
              <XtButton label="Cancel" onClick={onClose} />
              <XtButton
                hidden={isViewMode}
                disabled={disabled || !isDirty || !formState.isValid}
                loading={formState.isSubmitting}
                label="Save"
                onClick={onSubmitForm(onSaveForm)}
              />
            </div>
          </div>
          <div className={styles.customerDetailsContent}>
            <CustomerDetailsForm
              register={register}
              disabledCustomerType={disabledCustomerType}
              customerNumber$={customerNumber$.current}
              isSubmitting={formState.isSubmitting}
              disabled={disabled}
              customer={customer}
              customerName={customerName}
              customerNumberDisabled={customerNumberDisabled}
            />
            <CustomerDetailsTabs
              remarks={remarksState}
              characteristics={characteristicsState}
              documents={documentsState}
              isMobile={isMobile}
              disabled={disabled}
              customer={customer}
              register={register}
              mode={mode}
              autoUpdate={autoUpdate}
              setAutoUpdate={setAutoUpadte}
            />
          </div>
        </div>
      </div>
      <XtPrompt showPrompt={isDirty} message={confirmationMessages.unsavedChanges} />
    </div>
  )
}
