import { Observable, Subject } from 'rxjs'
import { useMemo, useRef } from 'react'
import { filter, map } from 'rxjs/operators'
import { ITaskUpdateEvent } from 'tasks/tasks.types'
import { IDocumentUpdateEvent } from 'documents/documents.types'
import { IAccountDetailsChanges } from './active-roles-section/active-roles-section.types'

export interface IAccountDetailsChangesHook {
  handleChanges(changes: IAccountDetailsChanges): void
  tasksUpdate$: Observable<unknown>
  contactsUpdate$: Observable<unknown>
  documentsUpdate$: Observable<unknown>
  accountUpdate$: Observable<true>
}

function isEvent<T>(event: T | null): event is T {
  return event !== null
}

function isAccountUpdate(hasUpdated: boolean): hasUpdated is true {
  return hasUpdated
}

export function useAccountDetailsChanges(): IAccountDetailsChangesHook {
  const changesSubject = useRef<Subject<IAccountDetailsChanges>>(new Subject<IAccountDetailsChanges>())

  return useMemo(
    () => ({
      handleChanges: (changes) => changesSubject.current.next(changes),
      tasksUpdate$: changesSubject.current.asObservable().pipe(
        map(({ tasksUpdate }) => tasksUpdate),
        filter<ITaskUpdateEvent | null, ITaskUpdateEvent>(isEvent)
      ),
      contactsUpdate$: changesSubject.current
        .asObservable()
        .pipe(filter(({ accountUpdate, contactsUpdate }) => accountUpdate || isEvent(contactsUpdate))),
      documentsUpdate$: changesSubject.current.asObservable().pipe(
        map(({ documentsUpdate }) => documentsUpdate),
        filter<IDocumentUpdateEvent | null, IDocumentUpdateEvent>(isEvent)
      ),
      accountUpdate$: changesSubject.current.asObservable().pipe(
        map<IAccountDetailsChanges, boolean>(({ accountUpdate }) => accountUpdate),
        filter<boolean, true>(isAccountUpdate)
      ),
    }),
    []
  )
}
