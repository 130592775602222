import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { booleanColumnConverter } from 'common/utils/table.utils'
import { numberConverter } from 'common/utils/utils'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { CreateItemFormAction, ICreateItemsTableItem } from './create-items.types'

export const createItemsActions: IXtTableCellAction[] = [
  {
    name: CreateItemFormAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: CreateItemFormAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const createItemsColumns: IXtTableColumn<ICreateItemsTableItem>[] = [
  {
    id: 'item_number',
    field: 'item_number',
    headerName: 'Item Number',
    flex: '1 0 100px',
    width: 100,
  },
  {
    id: 'description1',
    field: 'description1',
    headerName: 'Item Description',
    flex: '1 0 120px',
    width: 120,
  },
  {
    id: 'item_type',
    field: 'item_type',
    headerName: 'Item Type',
    flex: '1 0 80px',
    width: 80,
  },
  {
    id: 'list_price',
    field: 'list_price',
    headerName: 'List Price',
    flex: '1 0 80px',
    width: 80,
    converter: numberConverter,
  },
  {
    id: 'item_is_sold',
    field: 'item_is_sold',
    headerName: 'Is Sold',
    flex: '1 0 80px',
    width: 80,
    converter: booleanColumnConverter,
  },
]

export const createNewItem = 'Add Item'

export const deleteItem = 'Delete Item'
