import { GenerationValue, SettingValueGeneration } from './settings.types'

export const settingsModuleToken = 'SettingsModule'

export const settingsServiceToken = 'SettingService'

export const generationValueMap: Record<string, string> = {
  Manual: SettingValueGeneration.Manual,
  Automatic: SettingValueGeneration.Automatic,
  Override: SettingValueGeneration.Override,
  'Sales Order': SettingValueGeneration.UseSO,
  True: SettingValueGeneration.True,
  False: SettingValueGeneration.False,
}

export const generationLabelMap: Record<string, string> = {
  Manual: GenerationValue.Manual,
  Automatic: GenerationValue.Automatic,
  Override: GenerationValue.Override,
  'Sales Order': GenerationValue.UseSO,
  True: GenerationValue.True,
  False: GenerationValue.False,
}
