import * as React from 'react'
import { FC, useEffect, useMemo } from 'react'
import { IconButton, Popover } from '@material-ui/core'
import * as Yup from 'yup'

import { XtButton } from 'components/buttons/xt-button/xt-button'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { validationMessage, validateDecimal } from 'common/validation/validation'
import { FormField, FormXtAutocompletePlain } from 'common/utils/form/form.components'
import { useXtForm } from 'common/hooks/form/form'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { useProductsModule } from 'products/products-module-hook'
import { useXtAutocomplete } from 'components/controls/xt-autocomplete/xt-autocomplete-hook'
import { getAutocompleteInputLabelAsId, renderColumnOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import * as styles from './bom-item-quick-add.module.scss'
import { BomItemQuickAddFormState, BomItemQuickAddFormField, IBomItemForm } from './bom-item-quick-add.types'
import { bomItemQuickAddAutocompletePlainFilters } from './bom-item-quick-add.constants'

const QuickAddItemFormSchema = Yup.object().shape({
  [BomItemQuickAddFormField.Item]: Yup.object().required(validationMessage.isRequired).typeError(validationMessage.isRequired),
  [BomItemQuickAddFormField.Quantity]: Yup.number()
    .min(0, validationMessage.min(0))
    .required(validationMessage.isRequired)
    .typeError(validationMessage.invalidNumber)
    .test('is-valid-decimal', validationMessage.invalidDecimal, (value) => validateDecimal(String(value))),
})
const defaultValues = {
  [BomItemQuickAddFormField.Item]: null,
  [BomItemQuickAddFormField.Quantity]: 0,
  [BomItemQuickAddFormField.IssueUom]: null,
  [BomItemQuickAddFormField.ItemNumber]: null,
}
export const BomItemQuickAdd: FC<IBomItemForm> = ({ onAddItem, anchorEl, onClose, open, onAdvancedSearch, itemNumber }) => {
  const { ItemsUtilsService } = useProductsModule()

  const { options, search, loading, loadMoreOptions, reset: resetAutocomplete } = useXtAutocomplete(
    ItemsUtilsService.loadItemOptions,
    bomItemQuickAddAutocompletePlainFilters,
    true
  )

  const {
    formState: { isSubmitting },
    handleSubmit,
    control,
    reset,
  } = useXtForm<BomItemQuickAddFormState>({
    defaultValues,
    validationSchema: QuickAddItemFormSchema,
    mode: 'onBlur',
  })

  const submitForm: (e: React.BaseSyntheticEvent) => void = (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(onAddItem, (error) => console.error(error))
    void eventHandler(e)
  }

  const filteredItems = useMemo(() => options.filter((item) => item?.item_number !== itemNumber), [itemNumber, options])

  useEffect(() => reset(defaultValues), [open, reset])

  return (
    <Popover anchorEl={anchorEl} open={open} onClose={onClose}>
      <div className={styles.popoverContainer}>
        <div className={styles.closeForm}>
          <IconButton onClick={onClose} disabled={isSubmitting}>
            <SvgIcon iconId={SvgIconIds.CLOSE_ICON} className={styles.closeIcon} />
          </IconButton>
        </div>
        <form onSubmit={submitForm} className={styles.formContainer}>
          <div className={styles.formTitle}>Quick Item Add</div>
          <FormXtAutocompletePlain
            required
            control={control}
            name={BomItemQuickAddFormField.Item}
            className={styles.formField}
            options={filteredItems}
            filter={search}
            loadMore={loadMoreOptions}
            label="Item #"
            loading={loading}
            renderOption={renderColumnOption}
            reset={resetAutocomplete}
            getInputLabel={getAutocompleteInputLabelAsId}
          />

          <FormField
            className={styles.formField}
            control={control}
            name={BomItemQuickAddFormField.Quantity}
            label="Quantity"
            inputProps={{ type: 'number', step: 'any' }}
          />
          <XtButton
            label="Add Item"
            type="submit"
            className={styles.addButton}
            labelClass={styles.addButtonLabel}
            icon={SvgIconIds.ADD_CIRCLE}
            disabled={isSubmitting}
            loading={isSubmitting}
          />
        </form>
        <div className={styles.footer}>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <button className={styles.advancedSearchLink} type="button" onClick={onAdvancedSearch}>
            Detailed Item Entry
          </button>
        </div>
      </div>
    </Popover>
  )
}
