import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { IItemSite } from 'products/items/items.types'
import {
  ItemPlanningSystem,
  SitesDetailsDialogPlanningForm,
  SitesDetailsDialogPlanningFormField,
} from './sites-details-dialog-planning.types'
import { suppliedFromSiteNoneOption } from './sites-details-dialog-planning.consts'

export const defineItemPlanningSystemOptions: IXtAutocompleteOption[] = [
  {
    id: ItemPlanningSystem.None,
    label: ItemPlanningSystem.None,
  },
  {
    id: ItemPlanningSystem.MRP,
    label: ItemPlanningSystem.MRP,
  },
  {
    id: ItemPlanningSystem.MPS,
    label: ItemPlanningSystem.MPS,
  },
]

const emptyPlanningFormData: SitesDetailsDialogPlanningForm = {
  [SitesDetailsDialogPlanningFormField.PlanningSystem]: defineAutocompleteOption(ItemPlanningSystem.None),
  [SitesDetailsDialogPlanningFormField.SafetyStock]: 0,
  [SitesDetailsDialogPlanningFormField.GroupMpsMrpOrders]: 1,
  [SitesDetailsDialogPlanningFormField.LeadTime]: 0,
  [SitesDetailsDialogPlanningFormField.MpsTimeFence]: 0,
  [SitesDetailsDialogPlanningFormField.FirstGroup]: false,
  [SitesDetailsDialogPlanningFormField.SuppliedFromSite]: defineAutocompleteOption(suppliedFromSiteNoneOption),
  [SitesDetailsDialogPlanningFormField.ReorderLevel]: 0,
  [SitesDetailsDialogPlanningFormField.OrderUpTo]: 0,
  [SitesDetailsDialogPlanningFormField.MinimumOrder]: 0,
  [SitesDetailsDialogPlanningFormField.MaximumOrder]: 0,
  [SitesDetailsDialogPlanningFormField.OrderMultiple]: 0,
  [SitesDetailsDialogPlanningFormField.EnforceOnManualOrders]: false,
  [SitesDetailsDialogPlanningFormField.CreatePlannedTransfersOrders]: false,
  [SitesDetailsDialogPlanningFormField.EnforceOrderParameters]: false,
}

export function defineDefaultPlanningFormData(site: IItemSite | null): SitesDetailsDialogPlanningForm {
  if (!site) {
    return emptyPlanningFormData
  }

  return {
    [SitesDetailsDialogPlanningFormField.PlanningSystem]: defineAutocompleteOption(site.planning_system),
    [SitesDetailsDialogPlanningFormField.SafetyStock]: Number(site.safety_stock),
    [SitesDetailsDialogPlanningFormField.GroupMpsMrpOrders]: Number(site.group_mps_mrp_orders),
    [SitesDetailsDialogPlanningFormField.LeadTime]: Number(site.lead_time),
    [SitesDetailsDialogPlanningFormField.MpsTimeFence]: Number(site.mps_time_fence),
    [SitesDetailsDialogPlanningFormField.FirstGroup]: Boolean(site.first_group),
    [SitesDetailsDialogPlanningFormField.SuppliedFromSite]: defineAutocompleteOption(site.supplied_from_site),
    [SitesDetailsDialogPlanningFormField.ReorderLevel]: Number(site.reorder_level),
    [SitesDetailsDialogPlanningFormField.OrderUpTo]: Number(site.order_up_to),
    [SitesDetailsDialogPlanningFormField.MinimumOrder]: Number(site.minimum_order),
    [SitesDetailsDialogPlanningFormField.MaximumOrder]: Number(site.maximum_order),
    [SitesDetailsDialogPlanningFormField.OrderMultiple]: Number(site.order_multiple),
    [SitesDetailsDialogPlanningFormField.EnforceOnManualOrders]: Boolean(site.enforce_on_manual_orders),
    [SitesDetailsDialogPlanningFormField.CreatePlannedTransfersOrders]: site.supplied_from_site !== suppliedFromSiteNoneOption,
    [SitesDetailsDialogPlanningFormField.EnforceOrderParameters]: Boolean(site.enforce_order_parameters),
  }
}
