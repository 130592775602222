import { Overwrite } from 'utility-types'
import { Injector } from 'core/injector/injector.service'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { ICostingItemCostsUtilsService } from './item-costs/item-costs-utils.service'
import { inventoryHistoryItemCostsUtilsServiceToken } from './item-costs/item-costs.constants'
import { IInventoryHistoryInterfaces, InventoryHistoryModule, InventoryHistoryModuleDefinition } from './inventory-history.module'

function resolveProductsModule(): Overwrite<InventoryHistoryModuleDefinition, IInventoryHistoryInterfaces> {
  const CostingItemCostsUtilsService = Injector.resolve<ICostingItemCostsUtilsService>(inventoryHistoryItemCostsUtilsServiceToken)

  const module = LazyModulesService.resolveModule<InventoryHistoryModuleDefinition>(InventoryHistoryModule)

  return {
    ...module,
    CostingItemCostsUtilsService,
  }
}

export const useInventoryHistoryModule = moduleHookResolver(resolveProductsModule)
