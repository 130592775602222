// extracted by mini-css-extract-plugin
export var xtTabs = "xt-tabs_xtTabs__1o1of";
export var xtTabPaperRoot = "xt-tabs_xtTabPaperRoot__Jrk-Z";
export var xtTabsIndicator = "xt-tabs_xtTabsIndicator__JNTvs";
export var xtTabFlexContainer = "xt-tabs_xtTabFlexContainer__vVRzV";
export var xtTabRoot = "xt-tabs_xtTabRoot__Fi6FS";
export var xtTabWrapper = "xt-tabs_xtTabWrapper__rpnMa";
export var xtTabSelected = "xt-tabs_xtTabSelected__LIvhT";
export var xtTabTextColorPrimary = "xt-tabs_xtTabTextColorPrimary__7REre";
export var xtTabInvalid = "xt-tabs_xtTabInvalid__7+NQz";
export var xtTabDisabled = "xt-tabs_xtTabDisabled__IUuaK";
export var xtTabContent = "xt-tabs_xtTabContent__MNR8b";