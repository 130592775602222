import React, { FC, useCallback, useState } from 'react'

import { XtButton } from 'components/buttons/xt-button/xt-button'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { IBasePhoneDialog } from '../phone-action-dialog.types'
import { PhoneCallTwilioDialog } from './phone-call-twilio/phone-call-twilio-dialog'
import * as styles from '../phone-action-dialog.module.scss'

export const PhoneCallDialog: FC<IBasePhoneDialog> = ({ onClose, phone }) => {
  const [openTwilioDialog, setOpenTwilioDialog] = useState<boolean>(false)
  const toggleTwilioDialog = useCallback(() => setOpenTwilioDialog((prev) => !prev), [])

  const onCallDirectlyClick = useCallback(() => {
    onClose()
    window.location.href = `tel:${phone}`
  }, [phone])

  return (
    <div className={styles.phoneCallDialog}>
      <XtDialog
        className="xt-dialog-details-content"
        open={openTwilioDialog}
        fullScreen={false}
        animation={XtDialogAnimation.FadeAnimation}
      >
        <PhoneCallTwilioDialog onSubmit={onClose} onClose={toggleTwilioDialog} phone={phone} />
      </XtDialog>
      <p className={styles.title}>Do you want to call this number?</p>
      <div className={styles.phoneActionDialogButtons}>
        <XtButton label="Cancel" onClick={onClose} />
        <XtButton label="Call Directly" onClick={onCallDirectlyClick} />
        <XtButton label="Call via Twilio" onClick={toggleTwilioDialog} className={styles.callViaTwilio} />
      </div>
    </div>
  )
}
