import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { AccountsModule, AccountsModuleDefinition } from './accounts.module'
import { IAccountsServices } from './services/accounts-services.types'
import { resolveAccountsServicesModule } from './services/accounts-services-module-hook'

function resolveAccountsModule(): AccountsModuleDefinition & IAccountsServices {
  const module = LazyModulesService.resolveModule<AccountsModuleDefinition>(AccountsModule)
  const services = resolveAccountsServicesModule()

  return {
    ...module,
    ...services,
  }
}

export const useAccountsModule = moduleHookResolver(resolveAccountsModule)
