import React from 'react'
import { ShippingTableItem } from 'shipping/shipping.types'
import * as styles from './maintain-shipping.module.scss'

export function getSummaryTemplate(data: ShippingTableItem): React.ReactElement {
  return (
    <div className={styles.summaryContent}>
      <span>Shipment #: {data.shipment_number}</span>
      <span>Order #: {data.order_number}</span>
      <span>Customer #: {data.customer}</span>
      <span>Hold Type: {data.customer_info.hold_type}</span>
    </div>
  )
}
