import { ISetupWizardDialogState } from './setup-wizard.types'
import { setupModuleList } from './setup-wizard.exports'

export function countSteps(): number {
  let totalSteps = 0
  setupModuleList.forEach((item) => {
    item.steps.forEach(() => {
      totalSteps++
    })
  })
  return totalSteps
}

export function getCurrentStep(state: ISetupWizardDialogState): number {
  let counter = 0
  let currentStep = 0
  setupModuleList.forEach((item) => {
    item.steps.forEach((step) => {
      counter++
      if (item.id == state.activeModule.id && step.id == state.activeStep.id) {
        currentStep = counter
      }
    })
  })
  return currentStep
}
