import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { IErrorHandler } from 'core/services/error-handler.service'
import { errorHandlerServiceToken } from 'core/core.constants'
import { CoreModule } from 'core/core.module'
import { quantityOnHandServiceToken, quantityOnHandToken, quantityOnHandUtilsServiceToken } from './quantity-on-hand.constants'
import { IQuantityOnHandService } from './quantity-on-hand.service'
import { IQuantityOnHandUtilsService } from './quantity-on-hand-utils.service'

export type QuantityOnHandModuleDefinition = typeof import('./quantity-on-hand.export')

export const QuantityOnHandModule: ILazyModule<QuantityOnHandModuleDefinition> = {
  name: quantityOnHandToken,
  resolver: () => import('./quantity-on-hand.export'),
  initializer: ({ QuantityOnHandService, QuantityOnHandUtilsService }) => {
    Injector.register<IQuantityOnHandService>(
      quantityOnHandServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)

        return new QuantityOnHandService(httpClient)
      },
      Scope.Singleton
    )

    Injector.register<IQuantityOnHandUtilsService>(
      quantityOnHandUtilsServiceToken,
      (injector) => {
        const quantityOnHandService = injector.resolve<IQuantityOnHandService>(quantityOnHandServiceToken)
        const errorHandler = injector.resolve<IErrorHandler>(errorHandlerServiceToken)

        return new QuantityOnHandUtilsService(quantityOnHandService, errorHandler)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule],
}
