import React from 'react'
import { XtTableCell } from '../table-cell/table-cell'
import { convertValue, generateControl } from './table-editable-cell.utils'
import { IXtEditableCell } from './table-editable-cell.types'

export function TableEditableCell<TValue>(cell: IXtEditableCell<TValue>): React.ReactElement {
  const { editable, disabled } = cell

  const canEdit = editable && !disabled

  const control = canEdit ? generateControl(cell) : null

  const valueLabel = convertValue(cell)

  return (
    <XtTableCell title={valueLabel} column={cell} className={canEdit ? 'xt-table-editable-cell' : 'xt-table-editable-cell-default'}>
      {control ?? valueLabel}
    </XtTableCell>
  )
}
