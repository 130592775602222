import { characteristicsToPayload } from 'characteristics/characteristics.utils'
import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { IProspectUpdatePayload } from 'companies/prospects/prospects.types'
import { dateToServerFormat } from 'common/utils/date.utils'
import { IProspectForm } from './prospect-details.types'

export function convertFormDataToTaskPayload(
  formData: IProspectForm,
  characteristics: IAppliedCharacteristic[],
  notes: string
): IProspectUpdatePayload {
  return {
    ...formData,
    number: formData.number ?? '',
    name: formData.name ?? '',
    active: formData.active ?? true,
    sales_rep: formData.sales_rep?.id ?? null,
    source: formData.source?.id ?? null,
    site: formData.site?.id ?? null,
    tax_zone: formData.tax_zone?.id ?? null,
    currency: formData.currency?.id ?? null,
    last_touched: formData.last_touched ? dateToServerFormat(formData.last_touched) : null,
    owner: formData.owner?.id ?? '',
    assigned_user: formData.assigned_user?.id ?? '',
    assigned_date: formData.assigned_date ? dateToServerFormat(formData.assigned_date) : null,
    prospect_characteristics: characteristicsToPayload(characteristics),
    notes,
    created: (formData.created ? formData.created : new Date()).toISOString(),
    lastupdated: new Date().toISOString(),
  }
}
