import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { CreateAccountFormAction, ICreateAccountsTableItem } from './create-accounts.types'

export const createAccountsActions: IXtTableCellAction[] = [
  {
    name: CreateAccountFormAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: CreateAccountFormAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const createAccountsColumns: IXtTableColumn<ICreateAccountsTableItem>[] = [
  {
    id: 'account_number',
    field: 'account_number',
    headerName: 'Account Number',
    flex: '1 0 100px',
    width: 100,
  },
  {
    id: 'account_name',
    field: 'account_name',
    headerName: 'Account Name',
    flex: '1 0 100px',
    width: 100,
  },
  {
    id: 'account_type',
    field: 'account_type',
    headerName: 'Account Type',
    flex: '1 0 100px',
    width: 100,
  },
]

export enum AccountTypes {
  Prospect = 'Prospect',
  Customer = 'Customer',
}

export const createNewAccount = 'Add Account'

export const deleteAccount = 'Delete Account'
