export const enum IndentedListField {
  Seq = 'seq',
  ItemNumber = 'item_number',
  ItemDescription = 'item_description',
  UOM = 'uom',
  BatchSize = 'batch_size',
  QtyFixed = 'qty_fixed',
  QtyPer = 'qty_per',
  Scrap = 'scrap',
  Effective = 'effective',
  Expires = 'expires',
  UnitCost = 'unit_cost',
  ExtendedCost = 'extended_cost',
}

export const enum IndentedListLabel {
  Seq = 'Seq #',
  ItemNumber = 'Item Number',
  ItemDescription = 'Description',
  UOM = 'UOM',
  BatchSize = 'Batch Size',
  QtyFixed = 'Fixed Qty.',
  QtyPer = 'Qty. Per',
  Scrap = 'Scrap %',
  Effective = 'Effective',
  Expires = 'Expires',
  UnitCost = 'Unit Cost',
  ExtendedCost = 'Ext. Cost',
}
