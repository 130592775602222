import { PermissionGuard } from '../../auth/permission.guard'
import { UserPermission } from '../../auth/auth.types'
import { setupRoutePath } from '../setup.constants'

export class SystemGuard extends PermissionGuard {
  public redirectTo = setupRoutePath
  public permissions = [
    UserPermission.MaintainCommentTypes,
    UserPermission.ViewCharacteristics,
    UserPermission.MaintainCharacteristics,
    UserPermission.MaintainGroups,
  ]
}
