import { IObjectWithId } from 'common/common.types'
import { IUomConversion } from 'core/services/uom.service'

export enum ConversionsAction {
  Edit = 'edit',
  Delete = 'delete',
}

export interface IConversionsTableItem extends IObjectWithId, IUomConversion {
  conversions_where_used: string
  uom_types_table: string
  ratio: string
}
