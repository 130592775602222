import { Overwrite, Required } from 'utility-types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IObjectWithId } from 'common/common.types'
import { IItem } from '../items.types'

export interface IDeletionState {
  itemNumber: string
  confirmationOpen: boolean
}

export enum ItemsAction {
  Edit = 'edit',
  Delete = 'delete',
  View = 'view',
  Print = 'print',
}

export interface IItemsTableItem
  extends IObjectWithId,
    Pick<IItem, 'item_number' | 'description1' | 'class_code' | 'item_type' | 'inventory_uom_name'> {
  active: string
}

export enum ItemsFilterLabel {
  ClassCode = 'Class Code',
  ClassCodePattern = 'Class Code Pattern',
  FreightClass = 'Freight Class',
  FreightClassPattern = 'Freight Class Pattern',
  ItemDescription = 'Item Description',
  ItemGroup = 'Item Group',
  ItemNumberPattern = 'Item Number Pattern',
  ItemType = 'Item Types',
  ProductCategoryPattern = 'Product Category Pattern',
  ProductCategory = 'Product Category',
  ShowInactive = 'Show Inactive',
}

export enum ItemsFilterFieldName {
  ClassCode = 'classCode',
  ClassCodePattern = 'classCodePattern',
  FreightClass = 'freightClass',
  FreightClassPattern = 'freightClassPattern',
  ItemDescription = 'itemDescription',
  ItemGroup = 'itemGroup',
  ItemNumberPattern = 'itemNumberPattern',
  ItemType = 'itemType',
  ProductCategoryPattern = 'productCategoryPattern',
  ProductCategory = 'productCategory',
  ShowInactive = 'showInactive',
  Sold = 'sold',
  CustomerNumber = 'customer_number',
  ShowDetail = 'showDetail',
  PurchasedItems = 'purchased_items',
  Vendor = 'vendor',
  BarCode = 'upc_code',
}

export interface IItemsFilters {
  [ItemsFilterFieldName.ClassCode]?: string
  [ItemsFilterFieldName.ClassCodePattern]?: string | null
  [ItemsFilterFieldName.FreightClass]?: string
  [ItemsFilterFieldName.FreightClassPattern]?: string | null
  [ItemsFilterFieldName.ItemDescription]?: string
  [ItemsFilterFieldName.ItemGroup]?: string
  [ItemsFilterFieldName.ItemNumberPattern]?: string | null
  [ItemsFilterFieldName.ItemType]?: string
  [ItemsFilterFieldName.ProductCategoryPattern]?: string | null
  [ItemsFilterFieldName.ProductCategory]?: string
  [ItemsFilterFieldName.ShowInactive]?: boolean
  [ItemsFilterFieldName.Sold]?: boolean
  [ItemsFilterFieldName.CustomerNumber]?: string
  [ItemsFilterFieldName.ShowDetail]?: boolean
  [ItemsFilterFieldName.PurchasedItems]?: boolean
  [ItemsFilterFieldName.Vendor]?: string | null
  [ItemsFilterFieldName.BarCode]?: string | null
}

export type ItemsFilterPanel = Overwrite<
  Required<Omit<IItemsFilters, 'showDetail' | 'customer_number' | 'sold' | 'purchased_items' | 'vendor' | 'upc_code'>>,
  {
    [ItemsFilterFieldName.ItemGroup]: IXtAutocompleteOption | null
    [ItemsFilterFieldName.ItemType]: IXtAutocompleteOption | null
  }
>
