import { nanoid } from 'nanoid'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { IErrorHandler } from 'core/services/error-handler.service'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { getEmptyPaginationResponse } from 'core/utils/http.utils'
import { savePDFFile } from 'common/utils/file.utils'
import { IQuantityOnHandService } from './quantity-on-hand.service'
import { IQuantityOnHandListFilterForm } from './quantity-on-hand-list/quantity-on-hand-list-filter/quantity-on-hand-list-filter.types'
import { IQuantityOnHandListRow } from './quantity-on-hand-list/quantity-on-hand-list.types'
import { prepareQuantityOnHandListFilter } from './quantity-on-hand-list/quantity-on-hand-list-filter/quantity-on-hand-list-filter.utils'

export interface IQuantityOnHandUtilsService {
  fetchQuantityOnHand(
    filter: IQuantityOnHandListFilterForm,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IQuantityOnHandListRow>>

  printQuantityOnHand(filters: IQuantityOnHandListFilterForm): Promise<void>
}

export class QuantityOnHandUtilsService implements IQuantityOnHandUtilsService {
  constructor(private readonly quantityOnHandService: IQuantityOnHandService, private readonly errorHandler: IErrorHandler) {
    bindAllInstanceMethods(this)
  }

  public async fetchQuantityOnHand(
    filter: IQuantityOnHandListFilterForm,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IQuantityOnHandListRow>> {
    try {
      const { total, data } = await this.quantityOnHandService.getQuantityOnHand(
        paginationParams,
        prepareQuantityOnHandListFilter(filter),
        sortOptions
      )
      return {
        data: data.map((item) => ({ ...item, id: nanoid() })),
        total,
      }
    } catch (e) {
      this.errorHandler.handleError(e)
      return getEmptyPaginationResponse()
    }
  }

  public async printQuantityOnHand(filters: IQuantityOnHandListFilterForm): Promise<void> {
    try {
      const file = await this.quantityOnHandService.printQuantityOnHand(prepareQuantityOnHandListFilter(filters))
      savePDFFile(file, 'quantity-on-hand-file')
    } catch (e) {
      this.errorHandler.handleError(e)
    }
  }
}
