import type { Overwrite } from 'utility-types'
import type { ITasksModuleServices, TasksModuleDefinition } from './tasks.module'
import type { ITaskService } from './tasks.service'
import type { ITasksUtilsService } from './tasks-utils.service'
import type { ITasksKanbanService } from './tasks-kanban/tasks-kanban.service'
import type { ITasksKanbanUtilsService } from './tasks-kanban/tasks-kanban-utils.service'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from 'core/injector/injector.service'
import { TasksModule } from './tasks.module'
import { tasksKanbanServiceToken, tasksKanbanUtilsServiceToken, tasksServiceToken, tasksUtilsServiceToken } from './tasks.constants'
import { moduleHookResolver } from '../core/react-lazy-modules/react-lazy-modules.utils'

type TasksModuleHook = Overwrite<TasksModuleDefinition, ITasksModuleServices>

function resolveTasksModule(): TasksModuleHook {
  const module = LazyModulesService.resolveModule<TasksModuleDefinition>(TasksModule)
  const TasksService = Injector.resolve<ITaskService>(tasksServiceToken)
  const TasksUtilsService = Injector.resolve<ITasksUtilsService>(tasksUtilsServiceToken)
  const TasksKanbanService = Injector.resolve<ITasksKanbanService>(tasksKanbanServiceToken)
  const TasksKanbanUtilsService = Injector.resolve<ITasksKanbanUtilsService>(tasksKanbanUtilsServiceToken)

  return { ...module, TasksService, TasksUtilsService, TasksKanbanService, TasksKanbanUtilsService }
}

export const useTasksModule = moduleHookResolver<TasksModuleHook>(resolveTasksModule)
