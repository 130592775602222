import * as React from 'react'
import { FC, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { cls } from 'common/utils/utils'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { useTable } from 'common/hooks/useTable'
import { useProductsModule } from 'products/products-module-hook'
import { ItemOption } from 'products/items/items.types'
import { useCoreModule } from 'core/core-module-hook'
import { xsMq } from 'common/constants'
import { XtList } from 'components/list/list'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { historyColumns, historyListPageTitle, initialHistoryFilters } from './history-list.constants'
import * as styles from './history-list.module.scss'
import { convertHistoryFilters } from './history-list.utils'
import { defaultItemNumberFilters } from '../../products.constants'

export const HistoryList: FC = () => {
  const { ErrorHandler } = useCoreModule()
  const { XtItemNumber, ItemsUtilsService, CostingItemCostsUtilsService } = useProductsModule()
  useDocumentTitle(historyListPageTitle)
  const isMobile = useMediaQuery(xsMq)

  const [itemOption, setItemOption] = useState<ItemOption | null>(null)

  const { state, pagination, sort, filter } = useTable(initialHistoryFilters, CostingItemCostsUtilsService.fetchCostsHistory)

  const print = async (): Promise<void> => {
    if (!itemOption) {
      return
    }
    try {
      await CostingItemCostsUtilsService.printCostsHistory(itemOption.item_number)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const onItemChange = async (option: ItemOption | null): Promise<void> => {
    setItemOption(option)
    filter(convertHistoryFilters(option))
  }

  return (
    <div className={cls('xt-list-page-container', styles.listContent)}>
      <div className={styles.listControls}>
        <div className="xt-page-header">
          <h1 className="xt-page-title">Item Costs History</h1>
        </div>
        <div className={styles.filterSection}>
          <XtItemNumber
            loadOptions={ItemsUtilsService.loadItemOptions}
            className={styles.itemNumber}
            value={itemOption}
            onChange={onItemChange}
            isEditMode
            filters={defaultItemNumberFilters}
          />
          <XtResponsiveButton
            showIconOnDesktopMode={false}
            className={styles.printButton}
            label="Print"
            icon={SvgIconIds.PRINT}
            disabled={state.loading || !state.data.length}
            onClick={print}
          />
        </div>
      </div>
      <XtList
        isMobile={isMobile}
        pagination={pagination}
        loading={state.loading}
        data={state.data}
        columns={historyColumns}
        sortOptions={state.sortOptions}
        onColumnHeaderClick={sort}
      />
    </div>
  )
}
