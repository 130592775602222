import { nanoid } from 'nanoid'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { convertFiltersPanelState } from 'common/utils/filter.utils'
import { savePDFFile } from 'common/utils/file.utils'
import { IErrorHandler } from 'core/services/error-handler.service'
import { DocumentType, IDocument, IDocumentsService } from 'documents/documents.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { processSettledPromises } from 'common/utils/request.utils'
import { IRevisionService, RevisionOption, RevisionType } from 'core/services/revision.service'
import { IItemsService } from './items.service'
import { IItemsFilters, IItemsTableItem, ItemsFilterPanel } from './items-list/items-list.types'
import { IItemSitesFilters, IItemSitesService } from './item-sites.service'
import {
  CostTableItem,
  ICost,
  IItem,
  IItemCostFilters,
  IItemSiteDistributionFilters,
  ItemOption,
  ItemSiteDistributionOption,
  ItemSiteOption,
} from './items.types'
import { SiteTableItem } from './item-details/item-details-tabs/sites-tab/sites-tab.types'
import { checkPaginationParams } from '../../common/utils/utils'
import { defineItemNumberOption } from './components/item-number/item-number.utils'

export interface IItemsUtilsService {
  fetchItems(
    filters: ItemsFilterPanel,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemsTableItem>>
  printItem(itemNumber: string): Promise<void>
  requestOptions(): Promise<IXtAutocompleteOption[][]>
  fetchCost(filters: IItemCostFilters): Promise<IPaginationData<CostTableItem>>
  fetchSites(
    filters: IItemSitesFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<SiteTableItem>>
  loadSiteOptions(
    page?: number,
    limit?: number,
    filter?: string | null,
    filters?: IItemSitesFilters
  ): Promise<IPaginationData<ItemSiteOption>>
  loadItemOptions(page: number, limit: number, filter: string | null, filters?: IItemsFilters): Promise<IPaginationData<ItemOption>>
  requestItemDetails(items: Array<string | null>): Promise<Array<IItem | null>>
  loadRevisionOptions(revisionType: RevisionType, itemNumber: string | null): Promise<RevisionOption[]>
  loadItemSiteDistributionOptions<IncludeLotSerial extends boolean>(
    filters: IItemSiteDistributionFilters<IncludeLotSerial>
  ): Promise<ItemSiteDistributionOption<IncludeLotSerial>[]>
}

export class ItemsUtilsService implements IItemsUtilsService {
  constructor(
    private readonly itemsService: IItemsService,
    private readonly itemSitesService: IItemSitesService,
    private readonly documentsService: IDocumentsService,
    private readonly errorHandler: IErrorHandler,
    private readonly revisionService: IRevisionService
  ) {
    bindAllInstanceMethods(this)
  }

  public async fetchItems(
    filters: ItemsFilterPanel,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IItemsTableItem>> {
    const { total, data } = await this.itemsService.getAll(paginationParams, convertFiltersPanelState(filters), sortOptions)
    return {
      data: data.map((item, index) => ({ ...item, id: index.toString(), active: item.active ? 'Yes' : 'No' })),
      total,
    }
  }

  public async printItem(itemNumber: string): Promise<void> {
    try {
      const file = await this.itemsService.getItemPDF(itemNumber)
      savePDFFile(file, `item-${itemNumber}-file`)
    } catch (e) {
      this.errorHandler.handleError(e)
    }
  }

  public async requestOptions(): Promise<IXtAutocompleteOption[][]> {
    const options = await Promise.allSettled([
      this.documentsService.getDocuments(DocumentType.ClassCode),
      this.documentsService.getDocuments(DocumentType.FreightClass),
      this.documentsService.getDocuments(DocumentType.ProdCategory),
    ])
    return options.map(ItemsUtilsService.retrieveDocumentsFromResponse)
  }

  private static retrieveDocumentsFromResponse(
    request: PromiseSettledResult<IPaginationData<IDocument>> | undefined
  ): IXtAutocompleteOption[] {
    if (!request || request.status !== 'fulfilled') {
      return []
    }
    return request.value.data.map((value) => ({
      label: value.description ? `${value.number} - ${value.description}` : value.number,
      id: value.number,
    }))
  }

  public async fetchCost(filters: IItemCostFilters): Promise<IPaginationData<CostTableItem>> {
    const { itemNumber } = filters
    const { data } = await this.itemSitesService.getItemCosts(itemNumber ?? '')
    const preparedData = this.normalizeFetchCostData(data)
    return {
      data: preparedData,
      total: preparedData.length,
    }
  }

  public async fetchSites(
    filters: IItemSitesFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<SiteTableItem>> {
    const { itemNumber, ...filter } = filters
    if (!itemNumber) {
      return { data: [], total: 0 }
    }
    const { total, data } = await this.itemSitesService.getItemSites(itemNumber, paginationParams, filter, sortOptions)
    return {
      data: data.map((site) => ({ ...site, id: site.site })),
      total,
    }
  }

  private normalizeFetchCostData(cost: ICost): CostTableItem[] {
    const costDetails = cost.cost_detail.map((value) => ({ ...value, id: nanoid() }))
    const totalCost: CostTableItem = {
      id: nanoid(),
      cost_element: 'Totals',
      standard_cost: cost.total_standard_cost,
      standard_cost_currency: cost.currency,
      actual_cost: cost.total_actual_cost,
      actual_cost_currency: cost.currency,
      lower_level: null,
    }
    return [...costDetails, totalCost]
  }

  public async loadSiteOptions(
    page?: number,
    limit?: number,
    _searchFilter?: string | null,
    filters?: IItemSitesFilters
  ): Promise<IPaginationData<ItemSiteOption>> {
    if (!filters?.itemNumber) {
      return { data: [], total: 0 }
    }
    const { itemNumber: _, ...restFilters } = filters

    const paginationParams = checkPaginationParams(page, limit)

    const { total, data } = await this.itemSitesService.getItemSites(filters.itemNumber, paginationParams, restFilters)
    return {
      data: data.map((site) => ({ ...site, id: site.site, label: site.site })),
      total,
    }
  }

  public async loadItemOptions(
    page: number,
    limit: number,
    filter: string | null,
    filters: IItemsFilters
  ): Promise<IPaginationData<ItemOption>> {
    const { total, data } = await this.itemsService.getAll({ page, limit }, { ...filters, itemNumberPattern: filter })

    return {
      data: data.map((item) => defineItemNumberOption(item)),
      total,
    }
  }

  public async loadRevisionOptions(revisionType: RevisionType, itemNumber: string | null): Promise<RevisionOption[]> {
    if (!itemNumber) {
      return []
    }
    const { data } = await this.revisionService.getRevisionsWithItemNumber(revisionType, itemNumber)
    return data.map((item) => ({ ...item, id: item.revision, label: item.revision }))
  }

  public async loadItemSiteDistributionOptions<IncludeLotSerial extends boolean>({
    itemNumber,
    site,
    transactionType,
    includeLotSerial,
  }: IItemSiteDistributionFilters<IncludeLotSerial>): Promise<ItemSiteDistributionOption<IncludeLotSerial>[]> {
    const data = await this.itemSitesService.getItemSiteDistributions(itemNumber, site, transactionType, includeLotSerial)
    return data.map((item) => ({ ...item, id: item.location_name, label: item.location_name }))
  }

  /**
   * Requests all the items using their IDs. Checks for duplicates to make less HTTP requests.
   * @param items - list of item IDs
   * @return {Array<string | null>} - list of items requested from the server. Null returns for nullable item ID or in case of HTTP request error.
   */
  // TODO implement Unit tests
  public async requestItemDetails(items: Array<string | null>): Promise<Array<IItem | null>> {
    // we use Map to check for duplicates with efficient search/add operation - O(1)
    const map: Map<string, number[]> = new Map()

    const requestedItems: Array<IItem | null> = new Array<IItem | null>(items.length)
    const promises: Array<Promise<IItem>> = []

    items.forEach((itemNumber, index) => {
      if (!itemNumber) {
        requestedItems[index] = null
        return
      }
      const itemOccurrences = map.get(itemNumber)

      if (!itemOccurrences) {
        map.set(itemNumber, [index])
        promises.push(this.itemsService.get(itemNumber))
      } else {
        itemOccurrences.push(index)
      }
    })

    const settledPromises = await Promise.allSettled(promises)

    const { result } = processSettledPromises(settledPromises)

    result.forEach((item) => {
      if (!item) {
        return
      }
      const itemOccurrences = map.get(item.item_number)

      if (itemOccurrences) {
        itemOccurrences.forEach((index) => (requestedItems[index] = item))
      }
    })

    return requestedItems
  }
}
