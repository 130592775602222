import { XtMode } from 'common/common.types'

export const opportunitiesRoutes = {
  kanban: 'kanban',
}

export const opportunitiesRoutePath: string = '/activities/opportunities'
export const opportunitiesKanbanRoutePath: string = `${opportunitiesRoutePath}/${opportunitiesRoutes.kanban}`

export const opportunitiesModuleToken = 'OpportunityModule'
export const opportunitiesServicesModuleToken = 'OpportunitiesServicesModule'

export const opportunitiesServiceToken = 'OpportunitiesService'
export const opportunitiesUtilsServiceToken = 'OpportunitiesUtilsService'

export const opportunitiesKanbanServiceToken = 'OpportunitiesKanbanService'
export const opportunitiesKanbanUtilsServiceToken = 'OpportunitiesKanbanUtilsService'

export const opportunityStagesServiceToken = 'OpportunityStagesService'
export const opportunityTypesServiceToken = 'OpportunityTypesService'
export const opportunitySourcesServiceToken = 'OpportunitySourcesService'

export const defaultOpportunityDetailsDialogState = { id: null, mode: XtMode.New, open: false }

export const getOpportunityDetailsPageTitle = (id: string = '') => `xTuple - ${id ? `Opportunity: ${id}` : 'Create Opportunity'}`
