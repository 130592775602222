import React, { FC, useCallback, useEffect, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useCoreModule } from 'core/core-module-hook'
import { useXtForm } from 'common/hooks/form/form'
import { FormCheckboxLabel, FormField, FormRadioGroup, FormSelectField } from 'common/utils/form/form.components'
import { xsMq } from 'common/constants'
import { XtList } from 'components/list/list'
import { AccordionTheme, XtAccordion } from 'components/xt-accordion/xt-accordion'
import { convertMode } from 'common/utils/mode.utils'
import { FormRadioGroupOptions } from 'components/controls/xt-radio-group/xt-radio-group'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { ControlMethodOption } from 'products/items/items.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import {
  DefaultLocationType,
  IRestrictedLocationRow,
  ISitesDetailsDialogLocation,
  SitesDetailsDialogLocationForm,
  SitesDetailsDialogLocationFormField,
  SitesDetailsDialogLocationFormFieldLabel,
} from './sites-details-dialog-location.types'
import {
  RestrictedLocationAction,
  RestrictedLocationActions,
  restrictedLocationColumns,
  sitesDetailsDialogLocationFormKey,
} from './sites-details-dialog-location.consts'
import { defineDefaultLocationFormData, getRestrictedLocationRowActions } from './sites-details-dialog-location.utils'
import * as styles from './sites-details-dialog-location.module.scss'
import { sitesDialogValidation } from './sites-details-dialog-location.validation'

export const SitesDetailsDialogLocation: FC<ISitesDetailsDialogLocation> = ({ itemSite, mode, register, controlMethod, currentSite }) => {
  const { ErrorHandler } = useCoreModule()
  const { DocumentsUtilsService } = useDocumentsModule()
  const { loadItemSiteLocations } = DocumentsUtilsService
  const isMobile = useMediaQuery(xsMq)
  const { isViewMode } = convertMode(mode)

  const { reset, control, formChanges$, trigger, getValues, setValue, formState, watch } = useXtForm<SitesDetailsDialogLocationForm>({
    mode: 'onBlur',
    defaultValues: defineDefaultLocationFormData(null),
    validationSchema: sitesDialogValidation,
  })

  const [sites, setSites] = useState<IXtAutocompleteOption[]>([])

  const isMultipleLocationControl = watch(SitesDetailsDialogLocationFormField.MultipleLocationControl)
  const defaultLocationType = watch(SitesDetailsDialogLocationFormField.DefaultLocationType)
  const useDefaultLocation = watch(SitesDetailsDialogLocationFormField.UseDefaultLocation)

  const restrictedLocations = watch(SitesDetailsDialogLocationFormField.RestrictedLocations)

  const isLotOrSerial = controlMethod === ControlMethodOption.LotNumber || controlMethod === ControlMethodOption.SerialNumber

  useEffect(() => {
    const init = async (): Promise<void> => {
      try {
        if (isLotOrSerial) {
          setValue(SitesDetailsDialogLocationFormField.AutoDistrReceiveLocation, false)
          setValue(SitesDetailsDialogLocationFormField.AutoDistrLocation, false)
          setValue(SitesDetailsDialogLocationFormField.AutoDistrIssueLocation, false)
        }

        reset(defineDefaultLocationFormData(itemSite))
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }

    void init()
  }, [ErrorHandler, isLotOrSerial, reset, setValue, itemSite])

  useEffect(() => {
    const fetchLocaions = async (): Promise<void> => {
      if (!currentSite) {
        return
      }

      const { data } = await loadItemSiteLocations(undefined, undefined, null, { site: currentSite.id })
      setSites(data)
    }

    void fetchLocaions()
  }, [currentSite, loadItemSiteLocations])

  useEffect(() => {
    register(sitesDetailsDialogLocationFormKey, formChanges$, trigger, { data: getValues(), state: formState }, reset)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formChanges$, register, trigger, getValues])

  useEffect(() => {
    if (!useDefaultLocation) {
      return
    }
    if (isMultipleLocationControl) {
      setValue(SitesDetailsDialogLocationFormField.DefaultLocationType, DefaultLocationType.Location)
    } else {
      setValue(SitesDetailsDialogLocationFormField.DefaultLocationType, DefaultLocationType.UserDefined)
    }
  }, [isMultipleLocationControl, setValue, useDefaultLocation])

  const handleAction = useCallback<(item: IRestrictedLocationRow, action: RestrictedLocationAction) => void>(
    ({ id }, action) => {
      const updatedRestrictedLocations = getValues(SitesDetailsDialogLocationFormField.RestrictedLocations).map((row) => ({
        ...row,
        allowed: id === row.id ? action == RestrictedLocationAction.Allow : row.allowed,
      }))
      setValue(SitesDetailsDialogLocationFormField.RestrictedLocations, updatedRestrictedLocations)
    },
    [getValues, setValue]
  )

  const locationsChoiseDisabled =
    isViewMode || !isMultipleLocationControl || !useDefaultLocation || defaultLocationType === DefaultLocationType.UserDefined

  const locationAutoDisabled =
    isViewMode ||
    !isMultipleLocationControl ||
    !useDefaultLocation ||
    isLotOrSerial ||
    defaultLocationType === DefaultLocationType.UserDefined

  const defaultLocationRadioGroupOptions: FormRadioGroupOptions = [
    {
      id: DefaultLocationType.Location,
      label: DefaultLocationType.Location,
      template: (
        <div>
          <div className={styles.locationDetailOption}>
            <FormSelectField
              control={control}
              options={sites}
              name={SitesDetailsDialogLocationFormField.ReceiveLocation}
              label={SitesDetailsDialogLocationFormFieldLabel.ReceiveLocation}
              clearable={true}
              disabled={locationsChoiseDisabled}
            />
            <FormCheckboxLabel
              control={control}
              name={SitesDetailsDialogLocationFormField.AutoDistrReceiveLocation}
              label={SitesDetailsDialogLocationFormFieldLabel.AutoDistrReceiveLocation}
              disabled={locationAutoDisabled}
            />
          </div>
          <div className={styles.locationDetailOption}>
            <FormSelectField
              control={control}
              options={sites}
              name={SitesDetailsDialogLocationFormField.Location}
              label={SitesDetailsDialogLocationFormFieldLabel.Location}
              clearable={true}
              disabled={locationsChoiseDisabled}
            />
            <FormCheckboxLabel
              control={control}
              name={SitesDetailsDialogLocationFormField.AutoDistrLocation}
              label={SitesDetailsDialogLocationFormFieldLabel.AutoDistrLocation}
              disabled={locationAutoDisabled}
            />
          </div>
          <div className={styles.locationDetailOption}>
            <FormSelectField
              control={control}
              options={sites}
              name={SitesDetailsDialogLocationFormField.IssueLocation}
              label={SitesDetailsDialogLocationFormFieldLabel.IssueLocation}
              clearable={true}
              disabled={locationsChoiseDisabled}
            />
            <FormCheckboxLabel
              control={control}
              name={SitesDetailsDialogLocationFormField.AutoDistrIssueLocation}
              label={SitesDetailsDialogLocationFormFieldLabel.AutoDistrIssueLocation}
              disabled={locationAutoDisabled}
            />
          </div>
        </div>
      ),
      disabled: isMultipleLocationControl || useDefaultLocation,
    },
    {
      id: DefaultLocationType.UserDefined,
      label: DefaultLocationType.UserDefined,
      template: (
        <div>
          <FormField
            control={control}
            name={SitesDetailsDialogLocationFormField.UserDefinedLocation}
            label={SitesDetailsDialogLocationFormFieldLabel.UserDefinedLocation}
            disabled={isViewMode || isMultipleLocationControl || defaultLocationType === DefaultLocationType.Location}
          />
        </div>
      ),
      disabled: isMultipleLocationControl,
    },
  ]

  return (
    <div>
      <h1 className="xt-page-title">Control</h1>
      <div className={styles.locationContainer}>
        <div className={styles.locationContainerHeader}>
          <FormCheckboxLabel
            control={control}
            name={SitesDetailsDialogLocationFormField.MultipleLocationControl}
            label={SitesDetailsDialogLocationFormFieldLabel.MultipleLocationControl}
            disabled={isViewMode}
          />
          <FormCheckboxLabel
            control={control}
            name={SitesDetailsDialogLocationFormField.DisallowBlankWipLocations}
            label={SitesDetailsDialogLocationFormFieldLabel.DisallowBlankWipLocations}
            disabled={isViewMode || !isMultipleLocationControl}
          />
        </div>
      </div>
      <div className={styles.locationDetailContainer}>
        <FormCheckboxLabel
          control={control}
          name={SitesDetailsDialogLocationFormField.UseDefaultLocation}
          label={SitesDetailsDialogLocationFormFieldLabel.UseDefaultLocation}
          disabled={isViewMode}
        />
        <FormRadioGroup
          control={control}
          name={SitesDetailsDialogLocationFormField.DefaultLocationType}
          label=""
          options={defaultLocationRadioGroupOptions}
          className={styles.locationOption}
          disabled={isViewMode || !useDefaultLocation}
        />
      </div>

      <FormField
        control={control}
        name={SitesDetailsDialogLocationFormField.LocationComment}
        label={SitesDetailsDialogLocationFormFieldLabel.LocationComment}
        disabled={isViewMode}
      />
      <div className={styles.restrictedLocationContainer}>
        <XtAccordion
          summary={SitesDetailsDialogLocationFormFieldLabel.RestrictedLocations}
          theme={AccordionTheme.Dark}
          expanded={true}
          disabled={isViewMode}
        >
          <XtList
            className={styles.restrictedLocationTable}
            isMobile={isMobile}
            data={restrictedLocations}
            columns={restrictedLocationColumns}
            actions={RestrictedLocationActions}
            getItemActions={getRestrictedLocationRowActions}
            onAction={handleAction}
          />
        </XtAccordion>
      </div>
    </div>
  )
}
