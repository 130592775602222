import { Overwrite } from 'utility-types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'

export enum MaintainShippingFilterField {
  Customer = 'customer',
  Site = 'site',
  OrderNumber = 'order_number',
}

export enum MaintainShippingFilterLabel {
  Customer = 'Customer',
  Site = 'Site',
  OrderNumber = 'Sales Order #',
}

export interface IMaintainShippingFilterFormFilled {
  [MaintainShippingFilterField.Customer]: string | null
  [MaintainShippingFilterField.Site]: string | null
  [MaintainShippingFilterField.OrderNumber]: string | null
}

export interface IMaintainShippingFilterForm
  extends Overwrite<
    IMaintainShippingFilterFormFilled,
    {
      [MaintainShippingFilterField.Customer]: IXtAutocompleteOption | null
      [MaintainShippingFilterField.Site]: IXtAutocompleteOption | null
      [MaintainShippingFilterField.OrderNumber]: IXtAutocompleteOption | null
    }
  > {}
