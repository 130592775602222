import { ItemType } from '../../../../../items.types'
import {
  IItemDetailsCardFormFieldDisabledState,
  IItemDetailsCardFormFieldTypes,
  ItemDetailsCardFormFieldValue,
} from './item-details-card.types'
import { ItemDetailsFormField } from '../../../../item-details.types'
import {
  IItemSoldCardFormFieldDisabledState,
  IItemSoldCardFormFieldTypes,
  ItemSoldCardFormFieldValue,
} from '../item-sold-card/item-sold-card.types'

export const MAX_VALUE = 10 ** 10 - 1

const pickListTypesArray: ItemType[] = [
  ItemType.Phantom,
  ItemType.Reference,
  ItemType.Costing,
  ItemType.OutsideProcess,
  ItemType.Planning,
  ItemType.Kit,
  ItemType.Breeder,
]

const fractionalTypesArray: ItemType[] = [ItemType.Kit]

const configuredTypesArray: ItemType[] = [
  ItemType.Purchased,
  ItemType.Phantom,
  ItemType.Costing,
  ItemType.Tooling,
  ItemType.OutsideProcess,
  ItemType.Planning,
  ItemType.Kit,
  ItemType.Breeder,
  ItemType.CoProduct,
  ItemType.ByProduct,
]

const freightClassTypesArray: ItemType[] = [ItemType.Phantom, ItemType.Costing, ItemType.Planning, ItemType.Kit]

const barCodeTypesArray: ItemType[] = [ItemType.Phantom, ItemType.Costing, ItemType.OutsideProcess, ItemType.Planning, ItemType.Breeder]

const itemIsSoldTypesArray: ItemType[] = [ItemType.Phantom, ItemType.Costing, ItemType.OutsideProcess, ItemType.Planning, ItemType.Breeder]

export const formFieldTypes: IItemDetailsCardFormFieldTypes & IItemSoldCardFormFieldTypes = {
  [ItemDetailsFormField.Configured]: configuredTypesArray,
  [ItemDetailsFormField.PickList]: pickListTypesArray,
  [ItemDetailsFormField.Fractional]: fractionalTypesArray,
  [ItemDetailsFormField.BarCode]: barCodeTypesArray,
  [ItemDetailsFormField.FreightClass]: freightClassTypesArray,
  [ItemDetailsFormField.ItemIsSold]: itemIsSoldTypesArray,
}

export const formFieldValues: ItemDetailsCardFormFieldValue & ItemSoldCardFormFieldValue = {
  [ItemDetailsFormField.Configured]: false,
  [ItemDetailsFormField.PickList]: false,
  [ItemDetailsFormField.Fractional]: false,
  [ItemDetailsFormField.BarCode]: '',
  [ItemDetailsFormField.FreightClass]: null,
  [ItemDetailsFormField.ItemIsSold]: false,
}

export const defaultFormDisabledState: IItemDetailsCardFormFieldDisabledState & IItemSoldCardFormFieldDisabledState = {
  [ItemDetailsFormField.Configured]: false,
  [ItemDetailsFormField.PickList]: false,
  [ItemDetailsFormField.Fractional]: false,
  [ItemDetailsFormField.BarCode]: false,
  [ItemDetailsFormField.FreightClass]: false,
  [ItemDetailsFormField.ItemIsSold]: false,
}
