import React, { FC, memo } from 'react'
import { IDocumentsHook } from 'documents/documents-hook'
import { ICharacteristicsHook } from 'characteristics/characteristics-hook'
import { IRemarksHook } from 'comments/remarks/remarks-hook'
import { CRMAccountDocumentOption, DocumentType, UsedOnValue } from 'documents/documents.types'
import { TaskParentType } from 'tasks/tasks.types'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { XtMode } from 'common/common.types'
import { convertMode } from 'common/utils/mode.utils'
import { UserPermission } from 'auth/auth.types'
import { useCommentsModule } from 'comments/comments-module-hook'
import { useCharacteristicsModule } from 'characteristics/characteristics-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useTasksModule } from 'tasks/tasks-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { isCustomerCrmAccount, isProspectCrmAccount } from 'companies/accounts/accounts.utils'
import { MultipleFormRegister } from 'common/hooks/form/multiple-form-manager/multiple-form-manager.types'
import { SalesTabs } from './sales-tab/sales-tabs'
import { GeneralForm } from './general-form/general-form'
import { IOpportunityDetailsCombinedState, OpportunityFormState, OpportunityTab } from './opportunity-details.types'
import { opportunityParentConverter } from './opportunity-details-tabs.utils'

export interface IOpportunityDetailsTabs {
  register: MultipleFormRegister<IOpportunityDetailsCombinedState>
  markGeneralTabAsInvalid: boolean
  user: string | null
  account: CRMAccountDocumentOption | null
  opportunity: OpportunityFormState | null
  mode: XtMode
  disabled: boolean
  isMobile: boolean
  documentsState: IDocumentsHook
  characteristicsState: ICharacteristicsHook
  remarksState: IRemarksHook
}

// TODO looks like we can reuse it all over the app
export const OpportunityDetailsTabs: FC<IOpportunityDetailsTabs> = memo(
  ({
    register,
    markGeneralTabAsInvalid: markAsInvalid,
    user,
    account,
    opportunity,
    mode,
    disabled,
    isMobile,
    documentsState,
    characteristicsState,
    remarksState,
  }) => {
    const { XtRemarks } = useCommentsModule()
    const { XtCharacteristics } = useCharacteristicsModule()
    const { XtDocuments } = useDocumentsModule()
    const { TaskList } = useTasksModule()
    const { PermissionsService } = useAuthModule()

    const canViewTasks = PermissionsService.hasSomePermission([
      UserPermission.MaintainAllTaskItems,
      UserPermission.MaintainPersonalTaskItems,
      UserPermission.ViewAllTaskItems,
      UserPermission.ViewPersonalTaskItems,
    ])

    const canViewQuotes = PermissionsService.hasSomePermission([UserPermission.MaintainQuotes, UserPermission.ViewQuotes])
    const canViewSalesOrders = PermissionsService.hasSomePermission([UserPermission.MaintainSalesOrders, UserPermission.ViewSalesOrders])

    const { isViewMode, isNewMode } = convertMode(mode)

    const generalTab = {
      markAsInvalid,
      name: OpportunityTab.General,
      template: (
        <GeneralForm
          register={register}
          user={user}
          isMobile={isMobile}
          disabled={disabled}
          opportunity={opportunity}
          isNewMode={isNewMode}
        />
      ),
    }
    const documentsTab = {
      name: OpportunityTab.Documents,
      template: (
        <XtDocuments
          onDocumentCreate={documentsState.saveDocument}
          onDocumentsCreate={documentsState.saveDocuments}
          onDocumentDelete={documentsState.deleteDocument}
          onDocumentUpdate={documentsState.updateDocument}
          tableState={documentsState.state}
          pagination={documentsState.pagination}
          mode={mode}
        />
      ),
    }
    const remarksTab = {
      name: OpportunityTab.Remarks,
      template: (
        <XtRemarks
          source={DocumentType.Opportunity}
          canLoadMore={remarksState.canLoadMore}
          loadMore={remarksState.loadMore}
          comments={remarksState.comments}
          disabled={isViewMode}
          onCommentsSave={remarksState.saveComment}
          onCommentsUpdate={remarksState.updateComment}
          username={remarksState.username}
          textAreaName="Notes"
          textAreaOnChange={remarksState.setNotes}
          textAreaValue={remarksState.notes}
        />
      ),
    }

    const salesTab = {
      disabled: !account || (!isCustomerCrmAccount(account) && !isProspectCrmAccount(account)),
      hidden: !canViewQuotes && !canViewSalesOrders,
      name: OpportunityTab.Sales,
      template: account && (
        <SalesTabs
          canViewQuotes={canViewQuotes}
          canViewSalesOrders={canViewSalesOrders}
          opportunityNumber={opportunity?.number}
          disabled={disabled}
          isMobile={isMobile}
          account={account}
          mode={mode}
        />
      ),
    }

    const tasksTab = {
      hidden: !canViewTasks,
      name: OpportunityTab.Tasks,
      template: opportunity?.number && (
        <TaskList
          parentType={TaskParentType.Opportunity}
          parentNumber={opportunity.number}
          filters={{
            opportunity: opportunity.number,
          }}
          disabled={disabled}
          parentColumnConverter={opportunityParentConverter}
        />
      ),
    }
    const characteristicsTab = {
      name: OpportunityTab.Characteristics,
      template: (
        <XtCharacteristics
          usedOnFilter={UsedOnValue.Opportunity}
          disabled={isViewMode}
          onCreate={characteristicsState.createCharacteristic}
          onUpdate={characteristicsState.updateCharacteristic}
          onDelete={characteristicsState.deleteCharacteristic}
          characteristics={characteristicsState.characteristics}
        />
      ),
    }

    const tabs = isNewMode
      ? [generalTab, documentsTab, remarksTab, characteristicsTab]
      : [generalTab, documentsTab, remarksTab, salesTab, tasksTab, characteristicsTab]

    return <XtTabs tabs={tabs} />
  }
)
