import * as yup from 'yup'
import { validationMessage, validationRegExp } from '../../common/validation/validation'

export const loginValidation = yup.object({
  email: yup.string().required(validationMessage.isRequired).matches(validationRegExp.email, {
    message: validationMessage.invalidEmail,
    excludeEmptyString: true,
  }),
  password: yup.string().required(validationMessage.isRequired),
})
