import * as React from 'react'
import { Control } from 'react-hook-form'
import { FormCheckboxLabel } from 'common/utils/form/form.components'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IFilterFormState, IFilter } from '../filter.types'
import * as styles from '../filter.module.scss'

export function makeCheckboxFilter<TOption extends IXtAutocompleteOption>(
  filter: IFilter<TOption>,
  control: Control<IFilterFormState>
): JSX.Element {
  return (
    <FormCheckboxLabel
      className={styles.filterCheckbox}
      label={filter.label}
      key={`filter-${filter.fieldName}-controller`}
      control={control}
      name={filter.fieldName}
    />
  )
}
