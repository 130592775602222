import { Overwrite } from 'utility-types'
import { IItem } from 'products/items/items.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { InventoryUomOption } from 'core/services/uom.service'

export enum ItemDetailsFormField {
  ItemNumber = 'item_number',
  Active = 'active',
  Description1 = 'description1',
  Description2 = 'description2',
  ItemType = 'item_type',
  ClassCode = 'class_code',
  Currency = 'currency',
  PickList = 'pick_list_item',
  Fractional = 'fractional',
  Configured = 'configured',
  MaximumDesiredCost = 'maximum_desired_cost',
  FreightClass = 'freight_class',
  BarCode = 'upc_code',
  Notes = 'notes',
  ExtDescription = 'ext_description',
  ItemIsSold = 'item_is_sold',
  ProductCategory = 'product_category',
  ListPrice = 'list_price',
  // TODO update selling_uom
  InventoryUOM = 'inventory_uom',
  InventoryUomName = 'inventory_uom_name',
  SellingUOM = 'list_price_uom_name',
  Warranty = 'warranty_days',
  // TODO update wholesale_price
  WholesalePrice = 'wholesale_price',
  Exclusive = 'exclusive',
  ProductWeight = 'product_weight',
  Packaging = 'packaging_weight',
}
export enum ItemDetailsFormLabel {
  ItemType = 'Item Type',
  ClassCode = 'Class Code',
  Currency = 'Currency',
  InventoryUOM = 'Inventory UOM',
  PickList = 'Pick List',
  Fractional = 'Fractional',
  Configured = 'Configured',
  MaximumDesiredCost = 'Maximum Desired Cost',
  FreightClass = 'Freight Class',
  BarCode = 'Bar Code',
}

export enum ItemSoldFormLabel {
  ItemIsSold = 'Item is Sold',
  ProductCategory = 'Product Category',
  ListPrice = 'List Price',
  SellingUOM = 'Selling UOM',
  Warranty = 'Warranty',
  WholesalePrice = 'Wholesale Price',
  Exclusive = 'Exclusive',
  Description1 = 'Item Description 1',
  Description2 = 'Item Description 2',
}

export enum ItemWeightFormLabel {
  Product = 'Product',
  Packaging = 'Packaging',
}

export type ItemDetails = Pick<
  IItem,
  | ItemDetailsFormField.ItemNumber
  | ItemDetailsFormField.Description1
  | ItemDetailsFormField.Description2
  | ItemDetailsFormField.Active
  | ItemDetailsFormField.ItemType
  | ItemDetailsFormField.ClassCode
  | ItemDetailsFormField.InventoryUOM
  | ItemDetailsFormField.InventoryUomName
  | ItemDetailsFormField.PickList
  | ItemDetailsFormField.Fractional
  | ItemDetailsFormField.Configured
  | ItemDetailsFormField.MaximumDesiredCost
  | ItemDetailsFormField.FreightClass
  | ItemDetailsFormField.BarCode
  | ItemDetailsFormField.ItemIsSold
  | ItemDetailsFormField.ProductCategory
  | ItemDetailsFormField.ListPrice
  | ItemDetailsFormField.SellingUOM
  | ItemDetailsFormField.Warranty
  | ItemDetailsFormField.WholesalePrice
  | ItemDetailsFormField.Exclusive
  | ItemDetailsFormField.ProductWeight
  | ItemDetailsFormField.Packaging
>

export type ItemDetailsForm = Overwrite<
  Omit<ItemDetails, ItemDetailsFormField.InventoryUomName>,
  {
    [ItemDetailsFormField.SellingUOM]: IXtAutocompleteOption | null
    [ItemDetailsFormField.ItemType]: IXtAutocompleteOption | null
    [ItemDetailsFormField.ClassCode]: IXtAutocompleteOption | null
    [ItemDetailsFormField.InventoryUOM]: InventoryUomOption | null
    [ItemDetailsFormField.FreightClass]: IXtAutocompleteOption | null
    [ItemDetailsFormField.ProductCategory]: IXtAutocompleteOption | null
    [ItemDetailsFormField.ProductWeight]: number | null
    [ItemDetailsFormField.Packaging]: number | null
  }
>
