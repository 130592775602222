import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { IRoutingListTableRow, RoutingListAction } from './routing-list.types'

export const getRoutingDetailsActions = (canCreateUpdateDeletePermission: boolean, canPrintPermission: boolean) => (
  _: IRoutingListTableRow,
  actions: IXtTableCellAction[]
): IXtTableCellAction[] => {
  if (!canCreateUpdateDeletePermission && !canPrintPermission) {
    return actions.filter(({ name }) => name === RoutingListAction.View)
  }
  if (!canCreateUpdateDeletePermission && canPrintPermission) {
    return actions.filter(({ name }) => name === RoutingListAction.View || name === RoutingListAction.Print)
  }
  return canPrintPermission ? actions : actions.filter(({ name }) => name !== RoutingListAction.Print)
}
