import React, { FC, memo, useCallback, useState } from 'react'
import { nanoid } from 'nanoid'
import Tooltip from '@material-ui/core/Tooltip'
import { useMediaQuery } from '@material-ui/core'
import { useAuthModule } from 'auth/auth-module-hook'
import { UserPermission } from 'auth/auth.types'
import { XtList } from 'components/list/list'
import { XtMode } from 'common/common.types'
import { convertStateForRequest } from 'common/utils/country-state.utils'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { confirmationMessages, xsMq } from 'common/constants'
import { IXtEntityDialogData } from 'core/core.types'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { useCoreModule } from 'core/core-module-hook'
import { useSharedModule } from 'shared/shared-module-hook'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { useAccountsModule } from 'companies/accounts/accounts-module-hook'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { createAccountsActions, createAccountsColumns, createNewAccount, deleteAccount } from './create-accounts.constants'
import { ICreateAccountsTableItem, CreateAccountFormAction, ICreateAccounts } from './create-accounts.types'
import { CreateAccountDialog } from './create-account-dialog/create-account-dialog'
import { ICreateAccountValues } from './create-account-dialog/create-account-dialog.types'
import * as styles from './create-accounts.module.scss'

export const CreateAccounts: FC<ICreateAccounts> = memo(({ onChange }) => {
  const [accounts, setAccounts] = useState<ICreateAccountsTableItem[]>([])

  const { useDialog } = useSharedModule()

  const { AccountsService } = useAccountsModule()
  const { PermissionsService } = useAuthModule()

  const { open, openDialog, closeDialog, itemId } = useConfirmationDialog<string>()
  const { ErrorHandler, ToastService } = useCoreModule()

  const isMobile = useMediaQuery(xsMq)
  const canMaintainAccounts = PermissionsService.hasPermission(UserPermission.MaintainAllCRMAccounts)

  const updateAccounts = useCallback(
    (data: ICreateAccountsTableItem[]): void => {
      setAccounts(data)
      onChange({ accounts: data }, true)
    },
    [onChange, setAccounts]
  )

  const { open: accountDialogOpen, openDialog: openAccountDialog, closeDialog: closeAccountDialog, data: accountDialogData } = useDialog<
    IXtEntityDialogData<ICreateAccountsTableItem | null>
  >({
    mode: XtMode.New,
    data: null,
  })

  const handleAction = useCallback<(account: ICreateAccountsTableItem, action: CreateAccountFormAction) => void>(
    (account, action) => {
      switch (action) {
        case CreateAccountFormAction.Delete: {
          openDialog(account.id)
          break
        }
        case CreateAccountFormAction.Edit: {
          openAccountDialog({
            mode: XtMode.Edit,
            data: account,
          })
          break
        }
      }
    },
    [openDialog, openAccountDialog]
  )

  const onConfirmCreateAccountDialog = useCallback(
    async (formData: ICreateAccountValues) => {
      if (accountDialogData.mode == XtMode.Edit) {
        updateAccounts(
          accounts.map((item) => ({
            ...item,
            account_type: item.id === formData.id ? formData.account_type : item.account_type,
            account_number: item.id === formData.id ? formData.account_number : item.account_number,
            account_name: item.id === formData.id ? formData.account_name : item.account_name,
            active: item.id === formData.id ? formData.active : item.active,
            first_name: item.id === formData.id ? formData.first_name : item.first_name,
            middle_name: item.id === formData.id ? formData.middle_name : item.middle_name,
            last_name: item.id === formData.id ? formData.middle_name : item.last_name,
            address1: item.id === formData.id ? formData.address1 : item.address1,
            address2: item.id === formData.id ? formData.address2 : item.address2,
            country: item.id === formData.id ? formData.country?.label ?? '' : item.country,
            state: item.id === formData.id ? convertStateForRequest(formData.state) : item.state,
            city: item.id === formData.id ? formData.city : item.city,
            postalcode: item.id === formData.id ? formData.postalcode : item.postalcode,
            phone: item.id === formData.id ? formData.phone : item.phone,
            allowsms: item.id === formData.id ? formData.allowsms : item.allowsms,
          }))
        )
        closeAccountDialog()
        return
      }
      if (accountDialogData.mode === XtMode.New) {
        let accountData = {}
        try {
          if (formData.account_number) accountData = await AccountsService.get(formData.account_number)
        } catch (e) {}
        const accountExists =
          accounts.some((account) => account.account_number && account.account_number === formData.account_number) ||
          Object.keys(accountData).length > 0
        if (accountExists) {
          ToastService.showError('The account number you entered already exists.')
          return
        }
      }
      updateAccounts([
        ...accounts,
        {
          ...formData,
          state: convertStateForRequest(formData.state),
          country: formData.country?.id ?? '',
          id: nanoid(),
        },
      ])
      closeAccountDialog()
    },
    [closeAccountDialog, accounts, accountDialogData, updateAccounts, ToastService, AccountsService]
  )

  const handleRowClick = useCallback<(account: ICreateAccountsTableItem) => void>(
    (account) => {
      openAccountDialog({
        mode: XtMode.Edit,
        data: account,
      })
    },
    [openAccountDialog]
  )

  const handleDelete = useCallback<() => Promise<void>>(async () => {
    closeDialog()
    if (itemId) {
      try {
        const AccountsList: ICreateAccountsTableItem[] = accounts.filter((account) => account.id !== itemId)
        setAccounts(AccountsList)
      } catch (error) {
        ErrorHandler.handleError(error)
      }
    }
  }, [ErrorHandler, closeDialog, itemId, accounts, setAccounts])

  return (
    <div className={styles.createAccounts}>
      <XtConfirmationDialog
        open={open}
        message={confirmationMessages.deleted}
        title={deleteAccount}
        confirmationButtonLabel="Delete"
        onConfirm={handleDelete}
        onClose={closeDialog}
      />
      {!canMaintainAccounts && (
        <Tooltip title="You do not have permission to do this">
          <div className={styles.buttonContainer}>
            <XtButton
              onClick={() =>
                openAccountDialog({
                  mode: XtMode.New,
                  data: null,
                })
              }
              icon={SvgIconIds.ADD_CIRCLE}
              label={createNewAccount}
              disabled
            />
          </div>
        </Tooltip>
      )}
      {canMaintainAccounts && (
        <div className={styles.accountHeaderSection}>
          <p>
            Now let&#39;s enter some basic contact information about the companies and people you work with. An Account in xT Sales can be
            either a new Prospect, or an existing Customer. Again, if you need help, please feel free to email{' '}
            <a href="mailto:xthelp@caisoft.com">xthelp@caisoft.com</a>. When you&#39;ve finished adding Accounts, please click
            &#34;Next&#34; below.
          </p>
          <div className={styles.buttonContainer}>
            <XtButton
              onClick={() =>
                openAccountDialog({
                  mode: XtMode.New,
                  data: null,
                })
              }
              icon={SvgIconIds.ADD_CIRCLE}
              label={createNewAccount}
            />
          </div>
        </div>
      )}
      <XtList
        actions={createAccountsActions}
        data={accounts}
        onAction={handleAction}
        columns={createAccountsColumns}
        onRowClick={handleRowClick}
        isMobile={isMobile}
      />
      <XtDialog
        className="xt-dialog-details-content"
        fullScreen={false}
        open={accountDialogOpen}
        animation={XtDialogAnimation.SlideAnimation}
      >
        <CreateAccountDialog
          onClose={closeAccountDialog}
          onSubmit={onConfirmCreateAccountDialog}
          data={accountDialogData.data}
          mode={accountDialogData.mode}
        />
      </XtDialog>
    </div>
  )
})
