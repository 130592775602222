import { prepareRequestParams } from 'common/utils/request.utils'
import { IHttpClient } from './http-client'
import { IPaginationData, IPaginationParams, IPaginationResponse } from '../../common/common.types'

export interface IWorkingOrder {
  order_number: string
  priority: number
  order_status: string
  site: string
  uom: string
  item_number: string
  item_description: string
  start_date: string
  due_date: string
  project: string
  parent_order: string
  qty_ordered: number
  qty_received: number
  wip_value: number
  posted_value: number
  cost_method: string
  production_notes: string
  created: string
  lastupdated: string
  close_date: string
}

export interface IWorkingOrderFilter {
  site?: string
  startDate?: string
  endDate?: string
  showClosed?: string
  showOnlyTopLevel?: string
  search_pattern: string | null
}

export interface IWorkingOrderService {
  getAll: (params: IPaginationParams, filters?: IWorkingOrderFilter) => Promise<IPaginationData<IWorkingOrder>>
}

export class WorkOrderService implements IWorkingOrderService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getAll(pagination?: IPaginationParams, filters?: IWorkingOrderFilter): Promise<IPaginationData<IWorkingOrder>> {
    const params = prepareRequestParams(pagination, filters)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IWorkingOrder>>('/workorder', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}
