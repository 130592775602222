// extracted by mini-css-extract-plugin
export var xtButtonSpinnerSize = "30px";
export var xtButton = "xt-button_xtButton__a1LHU";
export var primary = "xt-button_primary__bDJ8H";
export var xtButtonIcon = "xt-button_xtButtonIcon__I-9UZ";
export var xtButtonIconHidden = "xt-button_xtButtonIconHidden__9riwK";
export var xtButtonLabel = "xt-button_xtButtonLabel__5AJYg";
export var xtButtonLabelHidden = "xt-button_xtButtonLabelHidden__YU6Ta";
export var xtButtonSpinner = "xt-button_xtButtonSpinner__P0s55";
export var secondary = "xt-button_secondary__nzdxK";
export var accent = "xt-button_accent__kK3FM";