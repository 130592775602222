import { sortByField } from 'common/utils/array.utils'
import { ITransferListOptionsChange, TransferListAction, TransferListOption } from './xt-transfer-list.types'

export function prepareDataForAddAction<Option extends TransferListOption>(
  availableOptions: Option[],
  selectedOptions: Option[],
  addedOption: Option
): ITransferListOptionsChange<Option> {
  const updateSelectedOptions = [...selectedOptions, addedOption].sort(sortByField('label'))
  const updateAvailableOptions = availableOptions.filter(({ id }) => id !== addedOption.id)
  return {
    selectedOptions: updateSelectedOptions,
    availableOptions: updateAvailableOptions,
    addedOptions: [addedOption],
    removedOptions: [],
    action: TransferListAction.Add,
  }
}

export function prepareDataForAddAllAction<Option extends TransferListOption>(
  availableOptions: Option[],
  selectedOptions: Option[]
): ITransferListOptionsChange<Option> {
  const availableOptionsArray: Option[] = []
  const addedOptions: Option[] = []
  const selectedOptionsArray: Option[] = [...selectedOptions]

  availableOptions.forEach((availableOption) => {
    if (availableOption.disabled) {
      availableOptionsArray.push(availableOption)
      return
    }
    addedOptions.push(availableOption)
    selectedOptionsArray.push(availableOption)
  })

  return {
    selectedOptions: selectedOptionsArray.sort(sortByField('label')),
    availableOptions: availableOptionsArray,
    removedOptions: [],
    addedOptions,
    action: TransferListAction.AddAll,
  }
}

export function prepareDataForRemoveAction<Option extends TransferListOption>(
  availableOptions: Option[],
  selectedOptions: Option[],
  removedOption: Option
): ITransferListOptionsChange<Option> {
  const updateSelectedOptions = selectedOptions.filter(({ id }) => id !== removedOption.id)
  const updateAvailableOptions = [...availableOptions, removedOption].sort(sortByField('label'))
  return {
    selectedOptions: updateSelectedOptions,
    availableOptions: updateAvailableOptions,
    addedOptions: [],
    removedOptions: [removedOption],
    action: TransferListAction.Remove,
  }
}

export function prepareDataForRemoveAllAction<Option extends TransferListOption>(
  availableOptions: Option[],
  selectedOptions: Option[]
): ITransferListOptionsChange<Option> {
  const availableOptionsArray: Option[] = [...availableOptions]
  const removedOptionsArray: Option[] = []
  const selectedOptionsArray: Option[] = []

  selectedOptions.forEach((selectedOpt) => {
    if (selectedOpt.disabled) {
      selectedOptionsArray.push(selectedOpt)
      return
    }
    removedOptionsArray.push(selectedOpt)
    availableOptionsArray.push(selectedOpt)
  })

  return {
    selectedOptions: selectedOptionsArray,
    availableOptions: availableOptionsArray.sort(sortByField('label')),
    removedOptions: removedOptionsArray,
    addedOptions: [],
    action: TransferListAction.RemoveAll,
  }
}
