import React, { FC } from 'react'

import { ISalesDetails } from 'companies/customers/customers.types'
import { convertDate } from 'common/utils/date.utils'
import { convertNumberWithThousandSeparatorAndDigits } from 'common/utils/utils'

interface ISummaryTab {
  salesDetails: ISalesDetails | null
}
export const SummaryTab: FC<ISummaryTab> = ({ salesDetails }) => {
  const salesData: { label: string; value: string }[] = [
    { label: 'First Sales Date', value: convertDate(salesDetails?.first_sales_date ?? null) },
    { label: 'Last Sales Date', value: convertDate(salesDetails?.last_sales_date ?? null) },
    { label: 'Backlog', value: convertNumberWithThousandSeparatorAndDigits(salesDetails?.backlog ?? 0) },
    {
      label: 'Last Year Sales',
      value: convertNumberWithThousandSeparatorAndDigits(salesDetails?.last_year_sales ?? 0),
    },
    { label: 'Year To Date Sales', value: convertNumberWithThousandSeparatorAndDigits(salesDetails?.ytd_sales ?? 0) },
    { label: 'Open Balance', value: convertNumberWithThousandSeparatorAndDigits(salesDetails?.balance?.toFixed(2) ?? 0) },
    { label: 'Late Balance', value: convertNumberWithThousandSeparatorAndDigits(salesDetails?.late_balance?.toFixed(2) ?? 0) },
  ]
  return (
    <div>
      {salesData.map(({ label, value }) => (
        <span key={label} className="xt-summary-content">
          <p className="xt-summary-label">{label}: &nbsp;</p>
          <p className="xt-text-truncated" title={value}>
            {value}
          </p>
        </span>
      ))}
    </div>
  )
}
