import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { CoreModule } from 'core/core.module'
import { DocumentsModule } from 'documents/documents.module'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { IDocumentsService } from 'documents/documents.types'
import { documentsServiceToken } from 'documents/documents.constants'
import { ICommentsService } from 'comments/comments.service'
import { commentsServiceToken } from 'comments/comments.constants'
import { CommentsModule } from 'comments/comments.module'
import {
  IOpportunitiesService,
  IOpportunitiesUtilsService,
  IOpportunityStagesService,
  IOpportunityTypesService,
  IOpportunitySourcesService,
} from './opportunities-services.types'
import {
  opportunitiesServicesModuleToken,
  opportunitiesServiceToken,
  opportunitiesUtilsServiceToken,
  opportunitySourcesServiceToken,
  opportunityStagesServiceToken,
  opportunityTypesServiceToken,
} from '../opportunities.constants'
import { AccountsServicesModule } from '../../../companies/accounts/services/accounts-services.module'
import { accountsServiceToken } from '../../../companies/accounts/accounts.constants'
import { IAccountsService } from '../../../companies/accounts/services/accounts-services.types'

type OpportunitiesServicesModuleDefinition = typeof import('./opportunities-services.export')

export const OpportunitiesServicesModule: ILazyModule<OpportunitiesServicesModuleDefinition> = {
  name: opportunitiesServicesModuleToken,
  resolver: () => import('./opportunities-services.export'),
  initializer: ({
    OpportunitiesService,
    OpportunitiesUtilsService,
    OpportunityTypesService,
    OpportunitySourcesService,
    OpportunityStagesService,
  }) => {
    const httpClient = Injector.resolve<IHttpClient>(httpClientToken)

    Injector.register<IOpportunityTypesService>(
      opportunityTypesServiceToken,
      () => new OpportunityTypesService(httpClient),
      Scope.Singleton
    )
    Injector.register<IOpportunitySourcesService>(
      opportunitySourcesServiceToken,
      () => new OpportunitySourcesService(httpClient),
      Scope.Singleton
    )
    Injector.register<IOpportunityStagesService>(
      opportunityStagesServiceToken,
      () => new OpportunityStagesService(httpClient),
      Scope.Singleton
    )

    Injector.register(
      opportunitiesServiceToken,
      (injector) => {
        const documentsService = Injector.resolve<IDocumentsService>(documentsServiceToken)
        const commentsService = Injector.resolve<ICommentsService>(commentsServiceToken)

        const accountsService = injector.resolve<IAccountsService>(accountsServiceToken)

        return new OpportunitiesService(httpClient, commentsService, documentsService, accountsService)
      },
      Scope.Singleton
    )

    Injector.register<IOpportunitiesUtilsService>(
      opportunitiesUtilsServiceToken,
      (injector) => {
        const opportunitiesService = injector.resolve<IOpportunitiesService>(opportunitiesServiceToken)
        const opportunityTypesService = injector.resolve<IOpportunityTypesService>(opportunityTypesServiceToken)
        const opportunitySourcesService = injector.resolve<IOpportunitySourcesService>(opportunitySourcesServiceToken)
        const opportunityStagesService = injector.resolve<IOpportunityStagesService>(opportunityStagesServiceToken)

        return new OpportunitiesUtilsService(
          opportunitiesService,
          opportunityStagesService,
          opportunityTypesService,
          opportunitySourcesService
        )
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, DocumentsModule, CommentsModule, AccountsServicesModule],
}
