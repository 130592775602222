import { Observable } from 'rxjs'
import { Overwrite } from 'utility-types'
import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IProspect } from 'companies/prospects/prospects.types'
import { XtMode } from 'common/common.types'

export interface IProspectDetailsState {
  isAdditionallyDirty: boolean // TODO remove this field later
  characteristics: IAppliedCharacteristic[]
  loading: boolean
  prospect: IProspect | null
}

export enum ProspectFormField {
  Number = 'number',
  Name = 'name',
  Active = 'active',
  SalesRep = 'sales_rep',
  Source = 'source',
  Site = 'site',
  TaxZone = 'tax_zone',
  Currency = 'currency',
  LastTouched = 'last_touched',
  Owner = 'owner',
  AssignedDate = 'assigned_date',
  AssignedUser = 'assigned_user',
  ProspectCharacteristics = 'prospect_characteristics',
  Created = 'created',
  LastUpdated = 'lastupdated',
}

export enum ProspectFormLabel {
  Number = 'Prospect #',
  Name = 'Prospect Name',
  Active = 'Active',
  SalesRep = 'Sales Rep.',
  Source = 'Source',
  Site = 'Site',
  TaxZone = 'Tax Zone',
  Currency = 'Currency',
  LastTouched = 'Last Touched',
  Owner = 'Owner',
  AssignedDate = 'Assigned',
  AssignedUser = 'Assigned To',
  ProspectCharacteristics = 'Characteristics',
  Notes = 'Notes',
  Created = 'Created',
  LastUpdated = 'Updated',
}

export interface IProspectForm
  extends Overwrite<
    Pick<
      IProspect,
      | ProspectFormField.Number
      | ProspectFormField.Name
      | ProspectFormField.Owner
      | ProspectFormField.Active
      | ProspectFormField.AssignedDate
      | ProspectFormField.AssignedUser
      | ProspectFormField.LastTouched
      | ProspectFormField.Site
      | ProspectFormField.SalesRep
      | ProspectFormField.Source
      | ProspectFormField.Created
      | ProspectFormField.LastUpdated
      | ProspectFormField.Currency
      | ProspectFormField.TaxZone
    >,
    {
      [ProspectFormField.Owner]: IXtAutocompleteOption | null
      [ProspectFormField.AssignedUser]: IXtAutocompleteOption | null
      [ProspectFormField.LastTouched]: Date | null
      [ProspectFormField.AssignedDate]: Date | null
      [ProspectFormField.Site]: IXtAutocompleteOption | null
      [ProspectFormField.SalesRep]: IXtAutocompleteOption | null
      [ProspectFormField.Source]: IXtAutocompleteOption | null
      [ProspectFormField.Created]: Date | null
      [ProspectFormField.LastUpdated]: Date | null
      [ProspectFormField.Currency]: IXtAutocompleteOption | null
      [ProspectFormField.TaxZone]: IXtAutocompleteOption | null
    }
  > {}

export interface IProspectDetails {
  onCancel(): void
  onSubmit?(): void
  mode: XtMode
  prospectNumber: string | null
  prospectName: string | null
  refreshDataObservable?: Observable<void>
}

export interface IProspectDetailsDialog extends Omit<IProspectDetails, 'prospect' | 'loading'> {}
