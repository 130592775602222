import { useCallback, useEffect, useState } from 'react'
import { useCoreModule } from 'core/core-module-hook'
import { IXtAutocompleteOption } from '../xt-autocomplete/xt-autocomplete.types'

export interface IXtSelectHook<Option extends IXtAutocompleteOption, TFilters> {
  options: Option[]
  getFirstAvailableValue(): IXtAutocompleteOption | null
  reset(filters: TFilters): Promise<Option[]>
}

export type XtSelectRequestFn<Option extends IXtAutocompleteOption, TFilters> = (filters?: TFilters) => Promise<Option[]>

export function useXtSelect<Option extends IXtAutocompleteOption, TFilters>(
  requestFn: XtSelectRequestFn<Option, TFilters>,
  initialFilters?: TFilters,
  requestOptionsOnStart = true
): IXtSelectHook<Option, TFilters> {
  const { ErrorHandler } = useCoreModule()

  const [options, setOptions] = useState<Option[]>([])

  const requestOptions: (filters?: TFilters) => Promise<Option[]> = async (filters) => {
    try {
      const data = await requestFn(filters)
      setOptions(data)
      return data
    } catch (e) {
      ErrorHandler.handleError(e)
      setOptions([])
      return []
    }
  }

  const getFirstAvailableValue: () => IXtAutocompleteOption | null = () => options[0] ?? null

  useEffect(() => {
    if (requestOptionsOnStart) {
      void requestOptions(initialFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const reset = useCallback<(filters?: TFilters) => Promise<Option[]>>(requestOptions, [])

  return {
    reset,
    options,
    getFirstAvailableValue,
  }
}
