import { Overwrite } from 'utility-types'
import { IAppliedCharacteristic, IAppliedCharacteristicNew } from 'characteristics/characteristics.types'
import { IUom, IUomConversion } from 'core/services/uom.service'
import { IObjectWithId } from 'common/common.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IComment } from 'comments/comments.types'
import { CommentItem } from 'comments/comment/comment.types'
import { ITaxType } from '../items-shared/tax-types/tax-types.types'
import { IXtItemNumberOptionBase } from './components/item-number/item-number.types'
import { ItemCostRecognition } from './item-details/item-details-tabs/sites-tab/sites-details-dialog/sites-details-dialog-tabs/sites-details-dialog-inventory/sites-details-dialog-inventory.types'

export enum ItemType {
  Breeder = 'Breeder',
  CoProduct = 'Co-Product',
  Phantom = 'Phantom',
  Job = 'Job',
  Kit = 'Kit',
  Planning = 'Planning',
  Manufactured = 'Manufactured',
  OutsideProcess = 'Outside Process',
  Purchased = 'Purchased',
  Reference = 'Reference',
  Costing = 'Costing',
  Tooling = 'Tooling',
  ByProduct = 'By-Product',
}

export interface ICostDetail {
  is_system: boolean
  cost_element: string
  lower_level?: boolean
  standard_cost: number
  standard_cost_currency: string
  actual_cost: number
  actual_cost_currency: string
  lastupdated?: string
  posted?: string
}

export interface ICost {
  item_number: string
  item_description: string
  total_standard_cost: number
  total_actual_cost: number
  currency: string
  cost_detail: ICostDetail[]
}

export enum ControlMethodServerOption {
  None = 'None',
  Regular = 'Regular',
  LotNumber = 'Lot',
  SerialNumber = 'Serial',
}

export enum ControlMethodOption {
  None = 'None',
  Regular = 'Regular',
  LotNumber = 'Lot #',
  SerialNumber = 'Serial #',
}

export enum CostingMethodOption {
  None = 'None',
  Average = 'Average',
  Standard = 'Standard',
  Job = 'Job',
}

export interface IItemSite {
  item_number: string
  site: string
  active: boolean
  is_transit_site: boolean
  wo_supplied_at_site?: boolean
  create_wos?: boolean
  po_supplied_at_site?: boolean
  create_prs?: boolean
  create_soprs?: boolean
  create_sopos?: boolean
  dropship?: boolean
  site_description: string
  sold_from_site: boolean
  ranking?: number
  cost_method: CostingMethodOption
  control_method: ControlMethodOption
  planner_code: string
  cost_category_code: string
  cost_category: IItemSiteCostCategory
  stocked?: boolean
  abc_class?: string
  allow_automatic_updates?: boolean
  cycl_count_freq?: number
  event_fence?: number
  multiple_location_control: boolean
  location?: string | null
  receive_location?: string | null
  issue_location?: string | null
  auto_distr_location?: boolean
  auto_distr_receive_location?: boolean
  auto_distr_issue_location?: boolean
  user_defined_location?: string
  location_comment?: string
  disallow_blank_wip_locations?: boolean
  enforce_order_parameters?: boolean
  reorder_level?: number
  order_up_to?: number
  minimum_order?: number
  maximum_order?: number
  order_multiple?: number
  enforce_on_manual_orders?: boolean
  planning_system?: string
  group_mps_mrp_orders?: number
  first_group?: boolean
  mps_time_fence?: number
  lead_time?: number
  safety_stock?: number
  supplied_from_site?: string
  notes: string
  perishable?: boolean
  require_warranty?: boolean
  auto_register?: boolean
  total_on_hand_qty?: number
  prevent_negative_inventory: boolean
  available_qty?: number
  nonuseable_available_qty?: number
  netable_qty?: number
  nonnetable_available_qty?: number
  allocated_qty?: number
  ordered_qty?: number
  requested_qty?: number
  auto_ls_number?: string
  created?: string
  lastupdated?: string
  suppress_automatic_orders: boolean
  override_wo_cost_recognition?: ItemCostRecognition
  restricted_locations: IItemSiteRestrictedLocation[]
}

export interface IItemSiteCostCategory {
  cost_category_code: string
  description: string
  asset_account: string
  wip_account: string
  invcost_account: string
  purchprice_account: string
  adjustment_account: string
  scrap_account: string
  laboroverhead_account: string
  po_liability_account: string
  to_liability_account: string
  transform_account: string
}

export interface IItemSiteRestrictedLocation {
  restricted_location: string
  location_description: string
  allowed: boolean
}

export interface IItemSiteRestrictedLocationsPayload extends Pick<IItemSiteRestrictedLocation, 'restricted_location' | 'allowed'> {}

export type IItemSitePayload = Overwrite<
  Pick<
    IItemSite,
    | 'item_number'
    | 'site'
    | 'active'
    | 'ranking'
    | 'abc_class'
    | 'cycl_count_freq'
    | 'wo_supplied_at_site'
    | 'create_wos'
    | 'po_supplied_at_site'
    | 'create_prs'
    | 'create_soprs'
    | 'create_sopos'
    | 'dropship'
    | 'sold_from_site'
    | 'cost_method'
    | 'auto_ls_number'
    | 'control_method'
    | 'planner_code'
    | 'cost_category'
    | 'prevent_negative_inventory'
    | 'suppress_automatic_orders'
    | 'override_wo_cost_recognition'
    | 'stocked'
    | 'allow_automatic_updates'
    | 'event_fence'
    | 'multiple_location_control'
    | 'location'
    | 'receive_location'
    | 'issue_location'
    | 'auto_distr_location'
    | 'auto_distr_receive_location'
    | 'auto_distr_issue_location'
    | 'user_defined_location'
    | 'location_comment'
    | 'restricted_locations'
    | 'disallow_blank_wip_locations'
    | 'enforce_order_parameters'
    | 'reorder_level'
    | 'order_up_to'
    | 'minimum_order'
    | 'maximum_order'
    | 'order_multiple'
    | 'enforce_on_manual_orders'
    | 'planning_system'
    | 'group_mps_mrp_orders'
    | 'first_group'
    | 'mps_time_fence'
    | 'lead_time'
    | 'safety_stock'
    | 'supplied_from_site'
    | 'notes'
  >,
  { cost_category: string; restricted_locations: IItemSiteRestrictedLocationsPayload[] }
>

export interface IItem {
  line_number: string
  item_number: string
  active: boolean
  description1: string
  description2: string
  item_type: string
  maximum_desired_cost: number
  class_code: string
  inventory_uom_name: string | null
  inventory_uom: IUom | null
  pick_list_item: boolean
  fractional: boolean
  configured: boolean
  freight_class: string | null
  item_is_sold: boolean
  product_category: string | null
  exclusive: boolean
  list_price: number
  list_cost: number
  list_price_uom: IUom | null
  warranty_days: number
  sites: IItemSite[] | null
  tax_types: ITaxType[] | null
  upc_code: string
  product_weight: number
  packaging_weight: number
  notes: string
  ext_description: string
  item_characteristics: IAppliedCharacteristic[] | null
  created: string
  lastupdated: string
  // TODO update
  uom_conversions: IUomConversion[]
  currency: string
  wholesale_price: number
  list_price_uom_name: string
  label?: string
}

export interface IInventoryAvailability {
  site: string
  item_number: string
  item_description1: string
  item_description2: string
  uom: string
  abc_class: string
  lead_time: number
  quantity_on_hand: number
  quantity_allocated: number
  quantity_unallocated: number
  allocated_orders: IInventoryAvailabilityOrder[]
  on_order: number
  onorder_orders: IInventoryAvailabilityOrder[]
  requests: number
  request_orders: IInventoryAvailabilityOrder[]
  reorder_level: number
  out_level: number
  available: number
  reserved: number
  vendor: string
  details: IInventoryAvailabilityDetails[]
}

export interface IInventoryAvailabilityOrder {
  order_type: string
  order_number: string
  order_date: string // TODO update me
  order_qty: number
  order_url: string
}

export interface IInventoryAvailabilityDetails {
  qoh: number
  site: string
  usable: boolean
  netable: boolean
  warranty: string
  lotserial: string
  expiration: string
  location_name: string
}

export interface IPricingItem {
  schedule_name: string
  source: string
  qty_break: number
  qty_uom: string
  price: number
  price_uom: string
  percent: number
  fixed_amount?: string
  pricing_type?: string
  currency: string
  price_in_base: number
}

export interface IItemPrice {
  customer_price: number
  item_number: string
  site: string
  quantity: number
  list_price: number
  wholesale_price: number
  unit_cost: number
  discount_from_list: number
  pricing_items: IPricingItem[]
}

export interface IOrderBomAvailability {
  reorder_level: number
  sequence_number: number
  item_number: string
  item_description: string
  uom: string
  pending_allocation: number
  on_order: number
  available_qoh: number
  total_allocations: number
  total_availability: number
}

export interface IOrderAvailabilityItem {
  available_qoh: number
  allocated: number
  unallocated: number
  on_order: number
  total_availability: number
  reserved: number
  reservable: number
  itemsite_leadtime: number
  bom_availability: IOrderBomAvailability[]
}

export type CreateItemPayload = Overwrite<
  Omit<IItem, 'lastupdated' | 'created' | 'list_price_uom' | 'list_cost' | 'line_number' | 'sites'>,
  { item_characteristics: IAppliedCharacteristicNew[]; list_price_uom_name: string | null }
>

export interface IItemCostFilters {
  itemNumber: string
}

export type CostTableItem = Overwrite<Omit<ICostDetail, 'is_system'> & IObjectWithId, { lower_level?: boolean | null }>

export type ItemOption = IItem & IXtItemNumberOptionBase
export type ItemSiteOption = IItemSite & IXtAutocompleteOption

export interface IRunningAvailability {
  order_type: string
  order_number: string
  source_dest: string
  due_date: string
  late: boolean
  amount: number
  qty_ordered: number
  qty_received: number
  reserved: number
  balance: number
  running_avail: number
  running_netable: number
  notes: string
}

export enum RunningAvailabilityFilter {
  ItemNumber = 'itemNumber',
  Site = 'site',
  ShowPlanned = 'showPlanned',
}

export type RunningAvailabilityFilters = {
  [RunningAvailabilityFilter.ItemNumber]: string | null
  [RunningAvailabilityFilter.Site]: string | null
  [RunningAvailabilityFilter.ShowPlanned]?: boolean
}

export enum CostedBomSummaryFilter {
  ItemNumber = 'item_number',
  RevisionNumber = 'revision_number',
  UseStandardCosts = 'useStandardCosts',
  UseActualCosts = 'useActualCosts',
  ExpiredDays = 'expiredDays',
  FutureDays = 'futureDays',
}

export type CostedBomSummaryFilters = {
  [CostedBomSummaryFilter.ItemNumber]: string
  [CostedBomSummaryFilter.RevisionNumber]?: string | null
  [CostedBomSummaryFilter.UseStandardCosts]?: boolean
  [CostedBomSummaryFilter.UseActualCosts]?: boolean
  [CostedBomSummaryFilter.ExpiredDays]?: number
  [CostedBomSummaryFilter.FutureDays]?: number
}

export type CostedBomFilters = Omit<CostedBomSummaryFilters, CostedBomSummaryFilter.ExpiredDays | CostedBomSummaryFilter.FutureDays>

export interface ICostedBom {
  seq: number | null
  item_number: string
  item_description: string
  uom: string
  batch_size: number | null
  qty_fixed: number | null
  qty_per: number | null
  scrap: number | null
  effective: string
  expires: string
  unit_cost: number | null
  extended_cost: number | null
  has_children: boolean
  srtorder: number | null
  bomdata_seq_ord: string
  bomitemid: number | null
}

export interface ICostedBomTotals {
  description: string
  extended_cost: number
  srt: number
}

export interface ICostedBomSummary {
  extended_cost: number
  item_description: string
  item_number: string
  qty_required: number
  unit_cost: number
  uom: string
}

export enum ItemCostsByClassCodeFilter {
  ClassCode = 'classcode',
  ClassCodePattern = 'classcode_pattern',
  OnlyShowZeroStdCosts = 'onlyShowZeroStdCosts',
  OnlyShowDiffCosts = 'onlyShowDiffCosts',
  ShowInactive = 'showInactive',
  OnlyShowZeroLastCosts = 'onlyShowZeroLastCosts',
  ShowPurchased = 'showPurchased',
  ShowMfg = 'showMfg',
  ShowOS = 'showOS',
  ShowCosting = 'showCosting',
  ShowPlanning = 'showPlanning',
  ShowOther = 'showOther',
}

export enum ItemCostsByClassCodeLabel {
  ClassCode = 'Class Code',
  ClassCodePattern = 'Class Code Pattern',
  OnlyShowZeroStdCosts = 'Only Show Zero Standard Cost Items',
  OnlyShowDiffCosts = 'Only Show Items where Actual and Standard are different',
  ShowInactive = 'Show Inactive Items',
  OnlyShowZeroLastCosts = 'Only Show Zero Last Actual Items',
}

export type ItemCostsByClassCodeFilters = {
  [ItemCostsByClassCodeFilter.ClassCode]?: string
  [ItemCostsByClassCodeFilter.ClassCodePattern]?: string
  [ItemCostsByClassCodeFilter.OnlyShowZeroStdCosts]?: boolean
  [ItemCostsByClassCodeFilter.OnlyShowDiffCosts]?: boolean
  [ItemCostsByClassCodeFilter.ShowInactive]?: boolean
  [ItemCostsByClassCodeFilter.OnlyShowZeroLastCosts]?: boolean
  [ItemCostsByClassCodeFilter.ShowPurchased]?: boolean
  [ItemCostsByClassCodeFilter.ShowMfg]?: boolean
  [ItemCostsByClassCodeFilter.ShowOS]?: boolean
  [ItemCostsByClassCodeFilter.ShowCosting]?: boolean
  [ItemCostsByClassCodeFilter.ShowPlanning]?: boolean
  [ItemCostsByClassCodeFilter.ShowOther]?: boolean
}

export interface IItemCostsByClassCode {
  item_number: string
  active: boolean
  item_description: string
  uom: string
  std_cost: number
  act_cost: number
  percent_variance: number
  item_type: string
}

export interface IItemCostsSummary {
  element: string
  lowlevel: boolean
  std_cost: number
  act_cost: number
  updated: string
  posted: string
  srtorder: number
}

export interface IItemCostsSummaryTotal {
  std_cost: number | null
  act_cost: number | null
}

export interface IItemCostsHistory {
  element: string
  lowlevel: boolean
  type: string
  datetime: string
  username: string
  old_cost: number
  old_currency: string
  old_currency_symbol: string
  new_cost: number
  new_currency: string
  new_currency_symbol: string
}

export enum TransactionType {
  MiscAdjustment = 'Misc Adjustment',
  CycleCount = 'Cycle Count',
  Expense = 'Expense',
  IssueBreeder = 'Issue Breeder',
  IssueManf = 'Issue Manf',
  IssueTransform = 'Issue Transform',
  NonNetable = 'Non Netable',
  ReceiptBreeder = 'Receipt Breeder',
  ReceiptReturn = 'Receipt Return',
  Relocate = 'Relocate',
  ReceiptManf = 'Receipt Manf',
  ReceiptPurchasing = 'Receipt Purchasing',
  Return = 'Return',
  ReturnStock = 'Return Stock',
  ReceiptTransform = 'Receipt Transform',
  MiscReceipt = 'Misc Receipt',
  StdCostChange = 'Std Cost Change',
  ShipInventory = 'Ship Inventory',
  ScrapInventory = 'Scrap Inventory',
  ShipVendor = 'Ship Vendor',
  TransferOrderReceipt = 'Transfer Order Receipt',
  TransferOrderShip = 'Transfer Order Ship',
  SiteTransfer = 'Site Transfer',
}

export interface IItemSiteDistribution {
  location_name: string
  default_location: boolean
  location_netable: boolean
  location_usable: boolean
  lotserial: string | null
  expiry_date: string
  expired: boolean
  warranty_date: string
  qty_before: number
}

export type ItemSiteDistributionWithLotSerial = Overwrite<IItemSiteDistribution, { lotserial: string }>

export type ItemSiteDistributionResponse<IncludeLotSerial> = IncludeLotSerial extends true
  ? ItemSiteDistributionWithLotSerial
  : IItemSiteDistribution

export interface ILotSerialNumber {
  lotserial_number: string
  qty: number
}

export interface ILotSerialNumberFilters {
  item_number: string
  site: string
  transaction_type: TransactionType
  qty?: number
}

export interface ILotSerialSequence {
  number: string
  description?: string
  min_length?: number
  prefix?: number
  suffix?: number
  next_number?: number
}

export interface ILotSerialSequencesFilters {
  sequence?: string
  showDetail?: boolean
}

export interface IItemSiteDistributionFilters<IncludeLotSerial extends boolean> {
  itemNumber: string
  site: string
  transactionType: TransactionType
  includeLotSerial: IncludeLotSerial
}

export type ItemSiteDistributionOption<IncludeLotSerial extends boolean = false> = ItemSiteDistributionResponse<IncludeLotSerial> &
  IXtAutocompleteOption

export type ItemSiteDistributionFilters<IncludeLotSerial extends boolean> = IncludeLotSerial extends true
  ? { transaction_type: TransactionType; IncludeLotSerial: true }
  : { transaction_type: TransactionType; NoIncludeLotSerial: true }

export interface ILotSerial {
  item_number: string
  lotserial: string
  notes: string
  lotserial_characteristics: IAppliedCharacteristic[] | null
  comments: IComment[] | null
}

export type LotSerialPayload = Overwrite<
  ILotSerial,
  {
    comments: CommentItem[] | null
  }
>
