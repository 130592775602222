import * as React from 'react'
import { FC } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { xsMq } from 'common/constants'
import logo from 'assets/img/xtuple.png'
import * as styles from './no-mobile.module.scss'

export const NoMobileContent: FC = ({ children }) => {
  const isMobile = useMediaQuery(xsMq)

  return isMobile ? (
    <div className={styles.noMobileContainer}>
      <img className={styles.noMobileLogo} src={logo} alt="xTuple Logo" />
      <div className={styles.noMobileText}>
        <p>This page is not supported for mobile devices.</p>
      </div>
    </div>
  ) : (
    <>{children}</>
  )
}
