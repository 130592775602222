import { useEffect, useState } from 'react'
import { DocnumberSetting, DocumentType, IGenerationSettingsData } from '../documents.types'
import { useDocumentsModule } from '../documents-module-hook'

interface IGenerationSettingsHook {
  isNewMode: boolean
  readOnlyParams: DocnumberSetting[]
  inputParams: DocnumberSetting[]
  source: DocumentType
}

export function useGenerationSettings({
  isNewMode,
  readOnlyParams,
  inputParams,
  source,
}: IGenerationSettingsHook): IGenerationSettingsData {
  const [state, setState] = useState<IGenerationSettingsData>({ isReadOnly: false, number: null })

  const { DocumentsUtilsService } = useDocumentsModule()

  async function init(): Promise<void> {
    const { isReadOnly, number } = await DocumentsUtilsService.fetchGenerationSettingsData(source, inputParams, readOnlyParams)
    setState({ isReadOnly, number })
  }

  useEffect(() => {
    if (isNewMode) {
      void init()
    }
  }, [isNewMode])

  return state
}
