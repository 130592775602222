import { Overwrite } from 'utility-types'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from 'core/injector/injector.service'
import { ICommentsService } from './comments.service'
import { commentsModuleToken, commentsServiceToken } from './comments.constants'
import { moduleHookResolver } from '../core/react-lazy-modules/react-lazy-modules.utils'
import { CommentsModuleDefinition, ICommentsModuleServices } from './comments-module.types'

function resolveCommentsModule(): Overwrite<CommentsModuleDefinition, ICommentsModuleServices> {
  const module = LazyModulesService.resolveModule<CommentsModuleDefinition>(commentsModuleToken)
  const CommentsService = Injector.resolve<ICommentsService>(commentsServiceToken)

  return { ...module, CommentsService }
}

export const useCommentsModule = moduleHookResolver(resolveCommentsModule)
