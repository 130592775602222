// extracted by mini-css-extract-plugin
export var bomItemDialogContent = "bom-item_bomItemDialogContent__iUgG-";
export var bomFormContent = "bom-item_bomFormContent__iZ0H-";
export var bomItemFormHeader = "bom-item_bomItemFormHeader__FviNV";
export var bomItemHeaderControls = "bom-item_bomItemHeaderControls__ifNTq";
export var bomItemHeaderButtonsSection = "bom-item_bomItemHeaderButtonsSection__46-x+";
export var bomItemHeaderButtonsIsViewMode = "bom-item_bomItemHeaderButtonsIsViewMode__tmP2l";
export var bomItemForm = "bom-item_bomItemForm__q6xzy";
export var bomItemFormTabs = "bom-item_bomItemFormTabs__gIoNU";
export var mainContentFormCheckboxes = "bom-item_mainContentFormCheckboxes__PLavb";
export var bomItemUsedAtSection = "bom-item_bomItemUsedAtSection__bgiwL";
export var bomItemButtonsSection = "bom-item_bomItemButtonsSection__675Ev";
export var bomItemFormButton = "bom-item_bomItemFormButton__tqibC";