import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { saveAs } from 'file-saver'
import { IconButton, Modal, useMediaQuery } from '@material-ui/core'
import { confirmationMessages, xsMq } from 'common/constants'
import { AttachedDocumentTargetType, IAttachedDocument, IAttachedDocumentWithFile } from 'documents/documents.types'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { XtList } from 'components/list/list'
import { LoadingSpinner } from 'components/loading-spinner'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { useCoreModule } from 'core/core-module-hook'
import { useSharedModule } from 'shared/shared-module-hook'
import { convertMode } from 'common/utils/mode.utils'
import { AttachSplitButton } from './attach-split-button/attach-split-button'
import * as styles from './documents.module.scss'
import { useDocumentsModule } from '../documents-module-hook'
import { convertFileListToDocuments, getDocumentActions } from './documents.utils'
import { XtDocumentDialog } from './document-dialog/document-dialog'
import {
  attachedDocumentActions,
  attachedDocumentColumns,
  attachedDocumentViewModeActions,
  defaultDocumentState,
  initialPreviewImgState,
} from './documents.constants'
import { AttachedDocumentAction, IAttachedDocumentPreviewImage, IAttachedDocumentsDescription } from './documents.types'

export const XtDocuments: FC<IAttachedDocumentsDescription> = ({
  tableState,
  pagination,
  onDocumentCreate,
  onDocumentDelete,
  onDocumentUpdate,
  mode,
  disabled = false,
  onDocumentsCreate,
}) => {
  const { DocumentsService } = useDocumentsModule()
  const { ErrorHandler } = useCoreModule()
  const { useDialog } = useSharedModule()

  const { isViewMode, isEditMode, isNewMode } = convertMode(mode)

  const {
    data: documentToDelete,
    open: deleteConfirmationDialogOpen,
    openDialog: openDeleteConfirmationDialog,
    closeDialog: closeDeleteConfirmationDialog,
  } = useDialog<IAttachedDocument | null>(null)

  const {
    data: documentDialogData,
    open: documentDialogOpen,
    openDialog: openDocumentDialog,
    closeDialog: closeDocumentDialog,
  } = useDialog<{ data: IAttachedDocument | null; uploadDisabled: boolean }>({ uploadDisabled: false, data: null })

  const [previewImage, setPreviewImage] = useState<IAttachedDocumentPreviewImage>(initialPreviewImgState)
  const isMobile = useMediaQuery(xsMq)

  const attachNewDocuments = async (newFiles: FileList | null): Promise<void> => {
    if (!newFiles) {
      return
    }
    const newDocuments: IAttachedDocumentWithFile[] = convertFileListToDocuments(newFiles)
    try {
      await onDocumentsCreate(newDocuments)
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }

  const previewDocument = useCallback<(fileLink: string, file?: File) => Promise<void>>(
    async (fileLink, file) => {
      setPreviewImage({ url: null, loading: true, open: true })
      const documentBlob = file ?? (await DocumentsService.loadFileData(fileLink))
      const url = URL.createObjectURL(documentBlob)
      setPreviewImage({ open: true, url, loading: false })
    },
    [DocumentsService]
  )

  const downloadDocument = useCallback<(filename: string, file_link: string, file?: File) => Promise<void>>(
    async (filename, file_link, file) => {
      const document = file ?? (await DocumentsService.loadFileData(file_link))
      saveAs(document, filename)
    },
    [DocumentsService]
  )

  const onPreviewClose: VoidFunction = () => {
    if (previewImage.url) {
      URL.revokeObjectURL(previewImage.url)
    }
    setPreviewImage(initialPreviewImgState)
  }

  const onAction = useCallback(
    (item: IAttachedDocument, action: AttachedDocumentAction): void => {
      switch (action) {
        case AttachedDocumentAction.Delete:
          void openDeleteConfirmationDialog(item)
          break
        case AttachedDocumentAction.Edit:
          openDocumentDialog({ data: item, uploadDisabled: isEditMode })
          break
        case AttachedDocumentAction.Preview:
          void previewDocument(item.file_link, item?.file)
          break
        case AttachedDocumentAction.Download:
          void downloadDocument(item.name, item.file_link, item?.file)
          break
        default:
          throw new Error('Incorrect action type')
      }
    },
    [downloadDocument, isEditMode, openDeleteConfirmationDialog, openDocumentDialog, previewDocument]
  )

  const handleDeletion = async (): Promise<void> => {
    closeDeleteConfirmationDialog()
    if (documentToDelete) {
      try {
        await onDocumentDelete(documentToDelete)
      } catch (error) {
        ErrorHandler.handleError(error)
      }
    }
  }

  const handleRowClick = useCallback<(document: IAttachedDocument) => void>(
    (document) => {
      if (document.target_type !== AttachedDocumentTargetType.File) {
        return
      }
      openDocumentDialog({ data: document, uploadDisabled: isEditMode })
    },
    [isEditMode, openDocumentDialog]
  )

  return (
    <div className={styles.documentsContent}>
      <XtConfirmationDialog
        open={deleteConfirmationDialogOpen}
        message={confirmationMessages.deleted}
        title="Delete Document"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeDeleteConfirmationDialog}
      />

      <Modal open={previewImage.open} className={styles.documentPreviewDialogRoot}>
        <div className={styles.documentPreviewDialogContainer}>
          <IconButton className={styles.closeIcon} onClick={onPreviewClose}>
            <SvgIcon className={styles.closeIconSize} iconId={SvgIconIds.CLOSE_ICON} />
          </IconButton>
          {previewImage.loading ? (
            <LoadingSpinner className={styles.documentPreviewLoadingSpinner} />
          ) : (
            previewImage.url && <img className={styles.documentPreviewImg} src={previewImage.url} alt="Preview" />
          )}
        </div>
      </Modal>
      <XtDialog
        className="xt-modal-details-content"
        open={documentDialogOpen}
        fullScreen={true}
        animation={XtDialogAnimation.SlideAnimation}
      >
        <XtDocumentDialog
          disabled={disabled || isViewMode}
          onClose={closeDocumentDialog}
          onCreate={onDocumentCreate}
          onUpdate={onDocumentUpdate}
          editedDocument={documentDialogData.data}
          uploadDisabled={documentDialogData.uploadDisabled}
        />
      </XtDialog>
      <AttachSplitButton
        isMobile={isMobile}
        attachMultipleFiles={attachNewDocuments}
        disabled={disabled || isViewMode}
        attachFIle={() => openDocumentDialog({ data: defaultDocumentState, uploadDisabled: false })}
      />

      <div className={styles.documentsList}>
        <XtList
          data={tableState.data}
          columns={attachedDocumentColumns}
          actions={isViewMode ? attachedDocumentViewModeActions : attachedDocumentActions}
          onAction={onAction}
          pagination={isNewMode ? undefined : pagination}
          getItemActions={getDocumentActions}
          isMobile={isMobile}
          loading={tableState.loading}
          onRowClick={handleRowClick}
        />
      </div>
    </div>
  )
}
