import React, { forwardRef } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { cls } from 'common/utils/utils'
import * as styles from './xt-responsive-button.module.scss'
import { SvgIconIds } from '../../svg-icon/svg-icon.types'
import { xsMq } from '../../../common/constants'

export interface IXtResponsiveButton {
  onClick: () => void
  disabled?: boolean
  className?: string
  labelClass?: string
  hidden?: boolean
  label: string
  loading?: boolean
  icon: SvgIconIds
  showIconOnDesktopMode?: boolean
}

export const XtResponsiveButton = forwardRef<HTMLButtonElement, IXtResponsiveButton>(
  ({ label, onClick, disabled = false, className, labelClass, hidden, loading, icon, showIconOnDesktopMode = true }, ref) => {
    const isMobile = useMediaQuery(xsMq)
    return (
      <XtButton
        hidden={hidden}
        icon={showIconOnDesktopMode || isMobile ? icon : undefined}
        labelClass={labelClass}
        className={cls(isMobile && styles.xtResponsiveButton, className)}
        disabled={disabled || loading}
        loading={loading}
        label={isMobile ? '' : label}
        onClick={onClick}
        ref={ref}
      />
    )
  }
)
