import { AdditionalDataCreationError } from 'common/common.types'
import { DocumentType } from 'documents/documents.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'

export type CommentCreateResponse = { id: number }

export interface ISource {
  name: string
  module: string
  description: string
  document_abbreviation: string
  characteristic_abbreviation: string
  listable: boolean
  has_active_field: boolean
  create_privilege: string
  view_privilege: string
  created: string
  lastupdated: string
}

export interface ICommentType {
  id: number
  name: string
  description: string
  system_type: boolean
  editable: boolean
  order: number | null
  assignments: string[]
  available: string[]
}

export interface ICommentTypePayload {
  name: string
  description: string
  editable: boolean
  order: number | null
  assignments: string[]
}

export interface ICommentTypesFilters {
  showDetail?: boolean
  module?: string
  name?: string
}

export interface ICommentTypeOption extends IXtAutocompleteOption, Partial<Omit<ICommentType, 'id'>> {}

export interface IComment {
  id: number
  created: string
  username: string
  comment: string
  comment_type: string
  source: DocumentType
  comment_source: string
  comment_source_id: string
  comment_source_number: string
  comment_public: boolean
  editable: boolean
}

export interface ICommentsFilters {
  source?: string
  commentSourceNumber?: string
}

export type CommentCreateRequest = Pick<IComment, 'comment' | 'comment_type' | 'source' | 'comment_source_number'>
export type CommentUpdateRequest = CommentCreateRequest & { id: number }

export type NewComment = Pick<IComment, 'comment' | 'comment_type'>

export class CommentsCreationError extends AdditionalDataCreationError {
  public message = 'Comments saving failed. Please try to add comments later.'
}
