// extracted by mini-css-extract-plugin
export var xtQuickAddButton = "routing-details_xtQuickAddButton__STIFi";
export var xtQuickAddButtonIcon = "routing-details_xtQuickAddButtonIcon__q6A1q";
export var routingDetailsHeaderSection = "routing-details_routingDetailsHeaderSection__m9kdc";
export var routingHeader = "routing-details_routingHeader__jZxMI";
export var headerIsNewMode = "routing-details_headerIsNewMode__4AMdn";
export var routingDetailsButtonSection = "routing-details_routingDetailsButtonSection__9wvrh";
export var routingNewSearch = "routing-details_routingNewSearch__lVt3f";
export var routingDetailsTableFilters = "routing-details_routingDetailsTableFilters__s6sfO";
export var checkboxOverflow = "routing-details_checkboxOverflow__QYXOh";
export var newButton = "routing-details_newButton__MA0t8";
export var table = "routing-details_table__Bu1l1";