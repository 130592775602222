import React, { FC, memo, useCallback, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form/dist/types'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { XtList } from 'components/list/list'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { confirmationMessages } from 'common/constants'
import { XtMode } from 'common/common.types'
import { TransferListOption } from 'components/controls/xt-transfer-list/xt-transfer-list.types'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { ConversionsTabActions, ConversionsTabColumns } from './conversions-tab.constants'
import { ConversionsAction, IConversionsTableItem } from './conversions-tab.types'
import { convertToTransferList, convertToTableItem, convertToUom, calculateUsedUom } from './conversions-tab.utils'
import { ConversionDetailsDialog } from './conversion-details-dialog/conversion-details-dialog'
import { useItemTabEntityDetailsDialog } from '../../items-details-dialog-hook/items-details-dialog-hook'
import { ItemDetailsForm, ItemDetailsFormField } from '../../item-details.types'
import * as styles from './conversions-tab.module.scss'

interface IConversionsTab {
  formMethods: UseFormReturn<ItemDetailsForm>
  isViewMode: boolean
  isMobile: boolean
}

export const ConversionsTab: FC<IConversionsTab> = memo(({ formMethods, isViewMode, isMobile }) => {
  const { watch, setValue } = formMethods
  const { open: openDeleteDialog, itemId, openDialog, closeDialog } = useConfirmationDialog()
  const {
    open,
    data,
    openItemTabEntityDialogInNewMode,
    openItemTabEntityDialog,
    closeItemTabEntityDialogDialog,
    mode,
  } = useItemTabEntityDetailsDialog<IConversionsTableItem>()

  const inventoryUom = watch(ItemDetailsFormField.InventoryUOM)

  const usedUom: Set<string> = useMemo(() => calculateUsedUom(inventoryUom?.uom_conversions, inventoryUom?.name), [
    inventoryUom?.name,
    inventoryUom?.uom_conversions,
  ])

  const handleAction = useCallback<(tableItem: IConversionsTableItem, action: ConversionsAction) => void>(
    (tableItem, action) => {
      switch (action) {
        case ConversionsAction.Edit:
          return openItemTabEntityDialog(tableItem, XtMode.Edit)
        case ConversionsAction.Delete:
          return openDialog(tableItem.id)
        default:
          return null
      }
    },
    [openItemTabEntityDialog, openDialog]
  )

  const availableTypes: TransferListOption[] = useMemo(() => convertToTransferList(inventoryUom?.uom_conversions), [inventoryUom])

  const tableData = useMemo(() => (inventoryUom?.uom_conversions.length ? inventoryUom?.uom_conversions.map(convertToTableItem) : []), [
    inventoryUom,
  ])

  const handleDeletion = useCallback(() => {
    if (!inventoryUom) {
      return
    }
    const updatedUomConversions = tableData.filter((value) => value.id !== itemId)
    setValue(ItemDetailsFormField.InventoryUOM, convertToUom(inventoryUom, updatedUomConversions), { shouldDirty: true })
    closeDialog()
  }, [setValue, tableData, itemId, closeDialog, inventoryUom])

  const onConversionDetailsSubmit = (uomConversionData: IConversionsTableItem): void => {
    if (!inventoryUom) {
      return
    }

    if (mode === XtMode.New) {
      setValue(ItemDetailsFormField.InventoryUOM, convertToUom(inventoryUom, [...tableData, uomConversionData]), { shouldDirty: true })
      closeItemTabEntityDialogDialog()
      return
    }
    if (mode === XtMode.Edit) {
      const currentConversionIndex = tableData.findIndex((item) => item.id === uomConversionData.id)
      const updatedConversions = [
        ...tableData.slice(0, currentConversionIndex),
        uomConversionData,
        ...tableData.slice(currentConversionIndex + 1, tableData.length),
      ]
      if (updatedConversions) {
        setValue(ItemDetailsFormField.InventoryUOM, convertToUom(inventoryUom, updatedConversions), { shouldDirty: true })
      }
      closeItemTabEntityDialogDialog()
    }
  }

  const onConversionRowClick = useCallback(
    (conversion: IConversionsTableItem) => {
      if (!isViewMode) {
        openItemTabEntityDialog(conversion, XtMode.Edit)
      }
    },
    [isViewMode, openItemTabEntityDialog]
  )

  return (
    <div>
      <XtConfirmationDialog
        open={openDeleteDialog}
        message={confirmationMessages.deleted}
        title="Delete Conversion"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeDialog}
      />
      <XtDialog open={open} animation={XtDialogAnimation.FadeAnimation} fullScreen={false} className="xt-dialog-details-content">
        <ConversionDetailsDialog
          uomConversion={data}
          onSubmit={onConversionDetailsSubmit}
          onClose={closeItemTabEntityDialogDialog}
          availableTypes={availableTypes}
          uomName={inventoryUom?.name ?? ''}
          usedUom={usedUom}
        />
      </XtDialog>
      <div>
        <XtResponsiveButton
          disabled={isViewMode}
          icon={SvgIconIds.ADD_CIRCLE}
          label="New Conversion"
          onClick={openItemTabEntityDialogInNewMode}
          className={styles.newButton}
        />
        <div className="xt-common-table-list">
          <XtList
            columns={ConversionsTabColumns}
            data={tableData}
            actions={isViewMode ? [] : ConversionsTabActions}
            onAction={handleAction}
            isMobile={isMobile}
            onRowClick={onConversionRowClick}
          />
        </div>
      </div>
    </div>
  )
})
