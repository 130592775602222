import * as React from 'react'
import { FC, memo, useEffect } from 'react'

import { FormCheckboxLabel, FormField, FormSelectField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { useXtForm } from 'common/hooks/form/form'
import { useXtSelect } from 'components/controls/xt-select/xt-select-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { getAutocompleteInputLabelInline, renderColumnOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import * as styles from './customer-details-form.module.scss'
import { CustomerDetailsFormSchema } from './customer-details-form.validation'
import { CustomerMainFormField, CustomerMainFormLabel, ICustomerDetailsForm, ICustomerMainForm } from './customer-details-form.types'
import { defineCustomerFormData } from './customer-details-form.utils'
import { customerMainFormKey } from './customer-details-form.constants'

export const CustomerDetailsForm: FC<ICustomerDetailsForm> = memo(
  ({ disabled, register, customer, customerNumber$, customerNumberDisabled, disabledCustomerType, customerName }) => {
    const { DocumentsUtilsService } = useDocumentsModule()
    const { options: sites } = useXtSelect(DocumentsUtilsService.loadSiteOptions, { nontransit: true })

    const { control, reset, trigger, formChanges$, setValue, getValues, formState } = useXtForm<ICustomerMainForm>({
      defaultValues: defineCustomerFormData(customer, customerName),
      validationSchema: CustomerDetailsFormSchema(disabledCustomerType),
      mode: 'onBlur',
    })

    useEffect(
      () => void reset(defineCustomerFormData(customer, customerName)),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [customer]
    )

    useEffect(
      () => register(customerMainFormKey, formChanges$, trigger, { data: getValues(), state: formState }, reset),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [register, formChanges$, customer]
    )

    useEffect(() => {
      const sub = customerNumber$.subscribe((customerNumber) => setValue(CustomerMainFormField.Number, customerNumber ?? ''))
      return () => {
        sub.unsubscribe()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (!customer) {
        setValue(CustomerMainFormField.Name, customerName ?? '', { shouldValidate: true, shouldDirty: false })
      }
    }, [customer, customerName, setValue])

    return (
      <div className={styles.customerDetailsFields}>
        <FormField
          required
          control={control}
          disabled={disabled || customerNumberDisabled}
          name={CustomerMainFormField.Number}
          label={CustomerMainFormLabel.Number}
        />
        <FormField
          required
          control={control}
          disabled={disabled || !!customerName}
          name={CustomerMainFormField.Name}
          label={CustomerMainFormLabel.Name}
        />
        <FormXtAutocomplete
          required
          control={control}
          name={CustomerMainFormField.Type}
          label={CustomerMainFormLabel.Type}
          loadOptions={DocumentsUtilsService.loadCustomerTypeOptions}
          getInputLabel={getAutocompleteInputLabelInline}
          renderOption={renderColumnOption}
          disabled={disabledCustomerType}
        />
        <FormSelectField
          control={control}
          disabled={disabled}
          name={CustomerMainFormField.Site}
          label={CustomerMainFormLabel.Site}
          options={sites}
          clearable
        />

        <div className={styles.checkboxActive}>
          <FormCheckboxLabel
            control={control}
            disabled={disabled}
            name={CustomerMainFormField.Active}
            label={CustomerMainFormLabel.Active}
          />
        </div>
      </div>
    )
  }
)
