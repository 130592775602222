import React, { FC, useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form/dist/types'
import { reduceOneDay, increaseOneDay } from 'common/utils/date.utils'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { FormDatePicker, FormField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { useCoreModule } from 'core/core-module-hook'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { defineStdOperationData } from '../routing-item.utils'
import {
  MAX_EXECUTION_DAY,
  MIN_EXECUTION_DAY,
  RoutingItemControlLabel,
  RoutingItemControlName,
  StandardOperationNone,
} from '../routing-item.constants'
import * as styles from './routing-item-form.module.scss'
import { RoutingItemFormData, StandardOperationData } from '../routing-item.types'

export interface IRoutingItemForm {
  disabled: boolean
  initialOperationType: string | null
  formMethods: UseFormReturn<RoutingItemFormData>
  sequenceNumber: number | undefined
}

export const RoutingItemForm: FC<IRoutingItemForm> = ({ disabled, initialOperationType, formMethods, sequenceNumber }) => {
  const { ErrorHandler, OperationTypesService, StandardOperationService, CoreUtilsService } = useCoreModule()

  const { control, watch, setValue, trigger } = formMethods

  const [effective, expires, standardOperation] = watch([
    RoutingItemControlName.Effective,
    RoutingItemControlName.Expires,
    RoutingItemControlName.StandardOperation,
  ])

  const hasStandardOperation = standardOperation && standardOperation !== StandardOperationNone

  useEffect(() => {
    async function requestOperationType(): Promise<void> {
      if (!initialOperationType) {
        setValue(RoutingItemControlName.OperationType, null)
        return
      }
      try {
        const { description } = await OperationTypesService.get(initialOperationType)
        setValue(RoutingItemControlName.OperationType, { id: initialOperationType, label: description })
      } catch (error) {
        ErrorHandler.handleError(error as Error)
      }
    }

    void requestOperationType()
  }, [ErrorHandler, OperationTypesService, initialOperationType, setValue])

  const onStandardOperationChange = async (option: IXtAutocompleteOption | null): Promise<void> => {
    setValue(RoutingItemControlName.StandardOperation, option ?? StandardOperationNone, { shouldValidate: true, shouldDirty: true })
    if (!option || option === StandardOperationNone) {
      return
    }
    try {
      const standardOperationItem = await StandardOperationService.get(option.id)
      const stdOperationData = defineStdOperationData(standardOperationItem)

      Object.entries(stdOperationData).forEach(([key, value]) => {
        setValue(key as keyof StandardOperationData, value)
      })

      await trigger(Object.keys(stdOperationData) as Array<keyof StandardOperationData>)

      if (standardOperationItem.operation_type) {
        const { code, description } = await OperationTypesService.get(standardOperationItem.operation_type)
        setValue(RoutingItemControlName.OperationType, defineAutocompleteOption(code, description), {
          shouldValidate: true,
          shouldDirty: true,
        })
      }
    } catch (e) {
      ErrorHandler.handleError(e as Error)
    }
  }

  const triggerDatesValidation: VoidFunction = () => {
    void trigger([RoutingItemControlName.Expires, RoutingItemControlName.Effective])
  }

  return (
    <div className={styles.mainContentForm}>
      <div className={styles.routingItemFields}>
        <div className={styles.operationBlock}>
          <FormXtAutocomplete
            name={RoutingItemControlName.StandardOperation}
            control={control}
            disabled={disabled}
            label={RoutingItemControlLabel.StandardOperation}
            onChange={onStandardOperationChange}
            loadOptions={CoreUtilsService.loadStandardOperationOptions}
          />
          <FormXtAutocomplete
            name={RoutingItemControlName.OperationType}
            control={control}
            disabled={disabled || hasStandardOperation}
            label={RoutingItemControlLabel.OperationType}
            loadOptions={CoreUtilsService.loadOperationTypeOptions}
          />
        </div>
        <p>Description:</p>
        <div className={styles.descriptionBlock}>
          <FormField
            disabled={disabled || hasStandardOperation}
            name={RoutingItemControlName.RoutingDescription1}
            control={control}
            label="Description #1"
          />
          <FormField
            disabled={disabled || hasStandardOperation}
            name={RoutingItemControlName.RoutingDescription2}
            control={control}
            label="Description #2"
          />
          <FormXtAutocomplete
            required
            name={RoutingItemControlName.WorkCenter}
            control={control}
            disabled={disabled}
            label={RoutingItemControlLabel.WorkCenter}
            loadOptions={CoreUtilsService.loadWorkCenterOptions}
          />
          <FormField
            label={RoutingItemControlLabel.ToolingReference}
            name={RoutingItemControlName.ToolingReference}
            control={control}
            disabled={disabled}
          />
        </div>
        <p>{`Oper. Seq. #: ${sequenceNumber ?? 'N/A'}`}</p>
        <div className={styles.dateBlock}>
          <DecimalFormField
            disabled={disabled}
            name={RoutingItemControlName.ExecutionDay}
            control={control}
            label={RoutingItemControlLabel.ExecutionDay}
            fixedDecimalScale={0}
            maxValue={MAX_EXECUTION_DAY}
            minValue={MIN_EXECUTION_DAY}
            counters
            allowNegative={false}
          />
          <FormDatePicker
            name={RoutingItemControlName.Effective}
            control={control}
            label={RoutingItemControlLabel.Effective}
            disabled={disabled}
            maxDate={reduceOneDay(expires)}
            onBlur={triggerDatesValidation}
            invalidOrNullableLabel="Always"
          />
          <FormDatePicker
            name={RoutingItemControlName.Expires}
            control={control}
            disabled={disabled}
            label={RoutingItemControlLabel.Expires}
            minDate={increaseOneDay(effective)}
            onBlur={triggerDatesValidation}
            invalidOrNullableLabel="Never"
          />
        </div>
      </div>
    </div>
  )
}
