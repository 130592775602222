import { InventoryHistoryFilterField, InventoryHistoryFormState } from '../inventory-history-list.types'

export const currentDate = new Date()

export const defaultFilterValues: InventoryHistoryFormState = {
  [InventoryHistoryFilterField.ClassCode]: null,
  [InventoryHistoryFilterField.ClassCodePattern]: '',
  [InventoryHistoryFilterField.ItemNumber]: null,
  [InventoryHistoryFilterField.ItemGroup]: null,
  [InventoryHistoryFilterField.ItemGroupPattern]: '',
  [InventoryHistoryFilterField.OrderNumberPattern]: '',
  [InventoryHistoryFilterField.PlannerCode]: null,
  [InventoryHistoryFilterField.PlannerCodePattern]: '',
  [InventoryHistoryFilterField.SalesOrder]: null,
  [InventoryHistoryFilterField.TransactionType]: null,
  [InventoryHistoryFilterField.TransferOrder]: null,
  [InventoryHistoryFilterField.WorkOrder]: null,
  [InventoryHistoryFilterField.Sites]: null,
  [InventoryHistoryFilterField.ABCClass]: null,
  [InventoryHistoryFilterField.StartDate]: currentDate,
  [InventoryHistoryFilterField.EndDate]: currentDate,
}
