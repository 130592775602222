import { IPhone, PhoneRole } from '../../contacts/contacts.types'
import { IPhoneLinkData } from '../../components/controls/phone/phone-link/phone-link.types'

export function booleanColumnConverter(active: unknown): string {
  if (typeof active !== 'boolean') {
    return 'No'
  }
  return active ? 'Yes' : 'No'
}

export function arrayColumnConverter(array: unknown): string {
  if (!Array.isArray(array)) {
    return ''
  }
  return array.join(', ')
}

export function firstCapitalizeColumnConverter(str: unknown): string {
  if (typeof str !== 'string') {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function phoneColumnConverter(role: PhoneRole): (phones: unknown) => string {
  return (phones) => {
    if (!Array.isArray(phones)) {
      return ''
    }
    const requiredPhone = phones.find((phone) => phone.role === role)
    return requiredPhone ? requiredPhone.number : ''
  }
}

export function convertPhoneData(phones: IPhone[] | undefined, role: PhoneRole): IPhoneLinkData {
  const requiredPhone = Array.isArray(phones) ? phones.find((phone) => phone.role === role) : null
  return {
    number: requiredPhone?.number ?? '',
    allowsms: requiredPhone?.allowsms ?? false,
  }
}
