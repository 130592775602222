import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { lightFormat } from 'date-fns'
import { isValidDate } from 'common/utils/date.utils'

export function resolveLabel(date: MaterialUiPickersDate, format: string, invalidOrNullableLabel?: string): string {
  if (!date) {
    return invalidOrNullableLabel ?? ''
  }
  return isValidDate(date) ? lightFormat(date, format) : ''
}
