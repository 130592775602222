import { IShipping, IShippingLine, ShippingLineTableItem } from 'shipping/shipping.types'
import { IItemsTabState, ShippingLineTotal } from './items-tab.types'

export function normalizeShipmentLines(shipmentLines: IShippingLine[]): ShippingLineTableItem[] {
  return shipmentLines.map((line) => ({ ...line, id: line.line_number }))
}

export function calculateShipmentLinesTotal(shipmentLines: ShippingLineTableItem[]): ShippingLineTotal | null {
  if (!shipmentLines.length) {
    return null
  }

  const total = shipmentLines
    .map(({ net_weight, tare_weight, gross_weight }) => ({ net_weight, tare_weight, gross_weight }))
    .reduce(
      (prev, current) => ({
        net_weight: prev.net_weight + current.net_weight,
        tare_weight: prev.tare_weight + current.tare_weight,
        gross_weight: prev.gross_weight + current.gross_weight,
      }),
      { net_weight: 0, tare_weight: 0, gross_weight: 0 }
    )

  return { ...total, id: 'Totals', line_number: 'Totals' }
}

export function defineItemsState(shipment: IShipping | null): IItemsTabState {
  const shipmentLines = normalizeShipmentLines(shipment?.shipment_lines ?? [])

  return {
    shipmentLines,
    total: calculateShipmentLinesTotal(shipmentLines),
  }
}
