import { UsedOnValue } from 'documents/documents.types'
import { CharacteristicValue, IAppliedCharacteristic, ICharacteristic } from '../characteristics.types'
import { IXtMode } from '../../common/common.types'

export enum AppliedCharacteristicAction {
  Delete = 'Delete',
  Edit = 'Edit',
}

export interface IXtCharacteristics {
  characteristics: IAppliedCharacteristic[]
  usedOnFilter: UsedOnValue
  onCreate(newCharacteristic: IAppliedCharacteristic): void
  onUpdate(updatedCharacteristic: IAppliedCharacteristic): void
  onDelete(deletedCharacteristic: IAppliedCharacteristic): void
  disabled?: boolean
  loading?: boolean
}

export interface ICharacteristicDialogData extends IXtMode {
  id: number | null
  characteristic: ICharacteristic | null
  value: CharacteristicValue
}
