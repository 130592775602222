import React from 'react'

export interface IXtCalendarTemplates<CalendarItemData extends CalendarItemDataType> {
  calendarItem(calendarItem: ICalendarItem<CalendarItemData>): React.ReactElement
}

export enum CalendarView {
  Day = 'day',
  Week = 'week',
  Month = 'month',
}

export type CalendarItemDataType = {
  [propName: string]: string | number | boolean | object | null
}

export interface ICalendarItem<TData extends CalendarItemDataType> {
  id: string
  title: string
  start: Date
  end?: Date
  raw: TData
  draggable?: boolean
}

export enum CalendarItemCategory {
  Task = 'task',
  Time = 'time',
  Milestone = 'milestone',
  AddDay = 'allday',
}

export interface IXtCalendarRange {
  currentDate: Date
  startDate: Date
  endDate: Date
}
