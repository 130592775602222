import { Overwrite } from 'utility-types'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from 'core/injector/injector.service'
import { IDocumentsService } from './documents.types'
import { documentsModuleToken, documentsServiceToken, documentsUtilsServiceToken } from './documents.constants'
import { IDocumentsUtilsService } from './documents-utils.service'
import { moduleHookResolver } from '../core/react-lazy-modules/react-lazy-modules.utils'
import { DocumentsModuleDefinition, IDocumentsModuleServices } from './documents-module.types'

function resolveDocumentsModule(): Overwrite<DocumentsModuleDefinition, IDocumentsModuleServices> {
  const module = LazyModulesService.resolveModule<DocumentsModuleDefinition>(documentsModuleToken)
  const DocumentsService = Injector.resolve<IDocumentsService>(documentsServiceToken)
  const DocumentsUtilsService = Injector.resolve<IDocumentsUtilsService>(documentsUtilsServiceToken)

  return { ...module, DocumentsService, DocumentsUtilsService }
}

export const useDocumentsModule = moduleHookResolver(resolveDocumentsModule)
