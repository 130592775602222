import { IAsyncRoute, IRoute } from 'common/routing/routing.types'
import { XtMode } from 'common/common.types'
import { buildPageComponent } from 'common/routing/routing.utils'
import { development } from 'common/constants'
import { PageNotFound } from 'page-not-found/page-not-found'
import { productsRoutePath, productsRoutes } from './products.constants'
import { ItemsGuard } from './items/items.guard'
import { ProductsGuard } from './products.guard'
import { BomGuard } from './bom/bom.guard'
import { RoutingGuard } from './routing/routing.guard'
import { RoutingDetailsGuard } from './routing/routing-details/routing-details.guard'
import { BomDetailsGuard } from './bom/bom-details/bom-details.guard'
import { ProductsModule, ProductsModuleDefinition } from './products.module'
import { bomRoutes } from './bom-cost/bom.constants'
import { itemCostsRoutes } from './product-item-costs/item-costs.constants'
import { ItemCostsGuard } from './product-item-costs/item-costs.guard'

function productRouteFactory({
  ItemsList,
  ItemDetails,
  BomDetailsPage,
  BomList,
  RoutingDetails,
  RoutingList,
  IndentedList,
  SummarizedList,
  SingleLevelList,
  ByClassCodeList,
  HistoryList,
  SummaryListPage,
}: ProductsModuleDefinition): IRoute[] {
  const productRoutesList: IRoute[] = [
    {
      path: productsRoutes.items,
      name: 'Items',
      guards: [ItemsGuard],
      children: [
        {
          path: '',
          exact: true,
          component: ItemsList,
        },
        {
          path: `/:itemNumber/${XtMode.Edit}`, // TODO: implement guards
          exact: true,
          component: (props) => buildPageComponent(props, XtMode.Edit, ItemDetails),
        },
        {
          path: `/:itemNumber/${XtMode.View}`, // TODO: implement guards]
          exact: true,
          component: (props) => buildPageComponent(props, XtMode.View, ItemDetails),
        },
        {
          path: `/${XtMode.New}`, // TODO: implement guards
          exact: true,
          component: (props) => buildPageComponent(props, XtMode.New, ItemDetails),
        },
        {
          path: '*',
          component: PageNotFound,
          exact: true,
        },
      ],
    },
    {
      path: productsRoutes.bom,
      name: 'Bill of Materials',
      guards: [BomGuard],
      children: [
        {
          path: '',
          exact: true,
          component: BomList,
        },
        {
          guards: [BomDetailsGuard],
          path: `/${XtMode.New}`,
          exact: true,
          component: (props) => buildPageComponent(props, XtMode.New, BomDetailsPage),
        },
        {
          path: `/:itemNumber/${XtMode.View}`,
          exact: true,
          component: (props) => buildPageComponent(props, XtMode.View, BomDetailsPage),
        },
        {
          guards: [BomDetailsGuard],
          path: `/:itemNumber/${XtMode.Edit}`,
          exact: true,
          component: (props) => buildPageComponent(props, XtMode.Edit, BomDetailsPage),
        },
        {
          path: '*',
          component: PageNotFound,
          exact: true,
        },
      ],
    },
    {
      path: productsRoutes.routing,
      name: 'Routing',
      guards: [RoutingGuard],
      children: [
        {
          path: '',
          exact: true,
          component: RoutingList,
        },
        {
          path: `/:itemNumber/${XtMode.Edit}`,
          exact: true,
          component: (props) => buildPageComponent(props, XtMode.Edit, RoutingDetails),
          guards: [RoutingDetailsGuard],
        },
        {
          path: `/:itemNumber/${XtMode.View}`,
          exact: true,
          component: (props) => buildPageComponent(props, XtMode.View, RoutingDetails),
        },
        {
          path: `/${XtMode.New}`,
          exact: true,
          component: (props) => buildPageComponent(props, XtMode.New, RoutingDetails),
          guards: [RoutingDetailsGuard],
        },
        {
          path: '*',
          component: PageNotFound,
          exact: true,
        },
      ],
    },
    {
      path: productsRoutes.costingReports,
      name: 'Costed Reports',
      type: 'dropdown',
      children: [
        {
          path: `${bomRoutes.indented}`,
          exact: true,
          name: 'Indented',
          component: IndentedList,
          guards: [BomGuard],
        },
        {
          path: `${bomRoutes.singleLevel}`,
          exact: true,
          name: 'Single Level',
          component: SingleLevelList,
          guards: [BomGuard],
        },
        {
          path: `${bomRoutes.summarized}`,
          exact: true,
          name: 'Summarized',
          component: SummarizedList,
          guards: [BomGuard],
        },
        {
          component: ByClassCodeList,
          path: `${itemCostsRoutes.byClassCode}`,
          name: 'Items Costs by Class Code',
          exact: true,
          guards: [ItemCostsGuard],
        },
        {
          component: HistoryList,
          path: `${itemCostsRoutes.history}`,
          name: 'Item Costs History',
          exact: true,
          guards: [ItemCostsGuard],
        },
        {
          component: SummaryListPage,
          path: `${itemCostsRoutes.summary}`,
          name: 'Item Costs Summary',
          exact: true,
          guards: [ItemCostsGuard],
        },
      ],
    },
  ]
  return process.env.NODE_ENV === development ? productRoutesList : [...productRoutesList.slice(0, 1)]
}

export const productRoute: IAsyncRoute<ProductsModuleDefinition> = {
  name: 'Products',
  path: productsRoutePath,
  guards: [ProductsGuard],
  module: ProductsModule,
  childrenRoutesFactory: productRouteFactory,
}
