import { IAsyncRoute, IRoute } from 'common/routing/routing.types'
import { PurchaseRequestModule, PurchaseRequestsModuleDefinition } from './purchase-requests.module'
import { purchaseRequestsRoutePath } from './purchase-requests.constants'
import { PurchaseRequestsGuard } from './purchase-requests.guard'

export function purchaseRequestsRouteFactory({ PurchaseRequestsList }: PurchaseRequestsModuleDefinition): IRoute[] {
  return [
    {
      path: '',
      exact: true,
      component: PurchaseRequestsList,
    },
  ]
}

export const purchaseRequestsRoute: IAsyncRoute<PurchaseRequestsModuleDefinition> = {
  path: purchaseRequestsRoutePath,
  name: 'Purchase Requests',
  module: PurchaseRequestModule,
  guards: [PurchaseRequestsGuard],
  childrenRoutesFactory: purchaseRequestsRouteFactory,
}
