import { Injector } from 'core/injector/injector.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { IQuantityOnHandService } from './quantity-on-hand.service'
import { IQuantityOnHandUtilsService } from './quantity-on-hand-utils.service'
import { quantityOnHandServiceToken, quantityOnHandUtilsServiceToken } from './quantity-on-hand.constants'

interface IQuantityOnHandServices {
  QuantityOnHandService: IQuantityOnHandService
  QuantityOnHandUtilsService: IQuantityOnHandUtilsService
}

export const useQuantityOnHandServices = moduleHookResolver<IQuantityOnHandServices>(() => {
  const QuantityOnHandService = Injector.resolve<IQuantityOnHandService>(quantityOnHandServiceToken)
  const QuantityOnHandUtilsService = Injector.resolve<IQuantityOnHandUtilsService>(quantityOnHandUtilsServiceToken)

  return { QuantityOnHandService, QuantityOnHandUtilsService }
})
