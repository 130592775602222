import { ITableRow } from 'components/table/table.types'
import { ISetupWizardModuleState } from '../../setup-wizard.types'

export interface ICreateItems {
  onChange(formData: ICreateItemsState, isDirty: boolean): void
  onSubmit(): void
  state: ISetupWizardModuleState
}

export interface IProductItem {
  item_number: string
  description1: string
  item_is_sold: boolean
  list_price: number
  item_type: string
}

export interface ICreateItemsTableItem extends ITableRow, IProductItem {
  id: string
}

export enum CreateItemFormAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'View',
  New = 'New',
}

export interface ICreateItemsState {
  items: ICreateItemsTableItem[]
}
