import { nanoid } from 'nanoid'
import { IUomConversion, UomTypes } from 'core/services/uom.service'
import { TransferListOption } from 'components/controls/xt-transfer-list/xt-transfer-list.types'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { IConversionsTableItem } from '../conversions-tab.types'
import { ConversionsDetailsDialogForm, ConversionsDetailsDialogFormField } from './conversions-details-dialog.types'
import { calculateRatio } from '../conversions-tab.utils'

export function defineFormData(
  uomConversion: IConversionsTableItem | null,
  uomName: string,
  fromValue?: number,
  toValue?: number,
  fractional?: boolean
): ConversionsDetailsDialogForm {
  return {
    [ConversionsDetailsDialogFormField.UomFrom]: defineAutocompleteOption(uomConversion?.uom_from ?? uomName),
    [ConversionsDetailsDialogFormField.UomTo]: defineAutocompleteOption(uomConversion?.uom_to ?? uomName),
    [ConversionsDetailsDialogFormField.FromValue]: fromValue ?? (uomConversion?.from_value ? Number(uomConversion.from_value) : 1),
    [ConversionsDetailsDialogFormField.ToValue]: toValue ?? (uomConversion?.to_value ? Number(uomConversion.to_value) : 1),
    [ConversionsDetailsDialogFormField.Active]: uomConversion?.active ?? true,
    [ConversionsDetailsDialogFormField.Fractional]: fractional ?? uomConversion?.fractional ?? false,
    [ConversionsDetailsDialogFormField.UomTypes]: uomConversion?.uom_types
      ? uomConversion.uom_types.map((type) => ({ id: type, label: type }))
      : [],
  }
}

export function onUomChangeFormDataDefinition(
  uomConversion: IUomConversion,
  selectedUomName: string,
  selectedKey: string,
  defaultUomName: string,
  conversionsTableItem: IConversionsTableItem | null
): ConversionsDetailsDialogForm {
  if (
    (uomConversion.uom_from === selectedUomName && selectedKey == ConversionsDetailsDialogFormField.UomFrom) ||
    (uomConversion.uom_from === defaultUomName && selectedKey == ConversionsDetailsDialogFormField.UomTo)
  ) {
    return defineFormData(conversionsTableItem, defaultUomName, uomConversion.from_value, uomConversion.to_value, uomConversion.fractional)
  } else {
    return defineFormData(conversionsTableItem, defaultUomName, uomConversion.to_value, uomConversion.from_value, uomConversion.fractional)
  }
}

function isUomType(value: string): value is UomTypes {
  return Object.values(UomTypes).some((type) => type === value)
}

export function convertUomTypes(uomTypes: TransferListOption[]): UomTypes[] {
  return uomTypes.map(({ label }) => label).filter<UomTypes>(isUomType)
}

export function convertFormDataToTableItem(
  data: ConversionsDetailsDialogForm,
  tableItem: IConversionsTableItem | null
): IConversionsTableItem {
  const uom_to = data[ConversionsDetailsDialogFormField.UomTo].id
  const uom_from = data[ConversionsDetailsDialogFormField.UomFrom].id
  const uom_types = convertUomTypes(data[ConversionsDetailsDialogFormField.UomTypes])

  return {
    ...data,
    id: tableItem ? tableItem.id : nanoid(),
    conversions_where_used: `${uom_from}/${uom_to}`,
    uom_types_table: (uom_types ?? []).join(','),
    ratio: calculateRatio(data.from_value, data.to_value),
    uom_to,
    uom_from,
    uom_types,
  }
}
