import { Observable } from 'rxjs'
import { IAppliedCharacteristic, IAppliedCharacteristicNew } from 'characteristics/characteristics.types'
import { AdditionalDataCreationError, IPaginationData, IPaginationParams } from 'common/common.types'
import { OrderType } from 'core/core.types'
import { InventoryHistoryByLocationFilters } from 'inventory/inventory-history-section/item-costs/inventory-history-by-location/inventory-history-by-location-list-form/inventory-history-by-location-list-form.types'
import { IXtAutocompleteOption } from '../components/controls/xt-autocomplete/xt-autocomplete.types'
import { ISortOption } from '../components/table/table-head/table-head.types'

export interface IDocumentsService {
  getDocuments(
    docType: DocumentType,
    paginationParams?: IPaginationParams,
    filters?: IDocumentListFilters
  ): Promise<IPaginationData<IDocument>>
  createFileForSource(
    document: IAttachedDocumentWithFile,
    sourceType: DocumentType,
    sourceNumber: string,
    documentIdField?: DocumentIdField
  ): Promise<string>
  createFilesForSource(
    documents: IAttachedDocumentWithFile[],
    sourceType: DocumentType,
    sourceNumber: string,
    documentIdField?: DocumentIdField
  ): Promise<void>
  loadFileData(file_link: string): Promise<Blob>
  getCRMAccounts(paginationParams: IPaginationParams, filters: ICRMAccountDocumentFilters): Promise<IPaginationData<ICRMAccountDocument>>
  getLocations(paginationParams?: IPaginationParams, filters?: DocumentLocationFilters): Promise<IPaginationData<IDocumentLocation>>
  getContracts(paginationParams?: IPaginationParams, filters?: IDocumentListFilters): Promise<IPaginationData<IDocumentContract>>
  getOrders(paginationParams?: IPaginationParams, filters?: IDocumentListFilters): Promise<IPaginationData<IDocumentOrder>>
  getAccountingPeriods(
    paginationParams?: IPaginationParams,
    filters?: IDocumentListFilters,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IDocumentAccountingPeriod>>
  createMultipleFiles(
    documents: IAttachedDocumentWithFile[],
    sourceType: DocumentType,
    sourceNumber: string,
    documentIdField: DocumentIdField | undefined
  ): Promise<string>
  getAttachedForSource(
    source: DocumentType,
    sourceNumber: string,
    paginationParams: IPaginationParams,
    filters?: IDocumentFilters
  ): Promise<IPaginationData<IAttachedDocument>>
  detach(data: IDetachDocumentRequest): Promise<string>
  createFile(data: ICreateAttachedFileDocumentRequest): Promise<string>
  update(assignments: IUpdateAttachedFileDocumentRequest[]): Promise<string>
  getDocnumber(source: DocumentType): Promise<IDocnumber>
  releaseDocnumber(source: DocumentType, docNumber: number | undefined): Promise<IDocnumber>
  setDocnumber(source: DocumentType, number: number): Promise<void>
  getCurrentDocnumber(source: DocumentType): Promise<IDocnumber>
  documentUpdate$: Observable<IDocumentUpdateEvent>
  getLotSerial(paginationParams?: IPaginationParams, filters?: IDocumentListFilters): Promise<IPaginationData<IDocument>>
}

interface IDocumentBase {
  id: number
  number: string
  name: string
  description: string
  active: boolean
}

export interface IDocument extends IDocumentBase {
  mark_inactive?: boolean
  default?: boolean
}

export interface IDocumentContract extends IDocumentBase {
  default: boolean
  effective: string | null
  expires: string | null
}

export type ContractOption = Omit<IDocumentContract, 'id'> & IXtAutocompleteOption

export interface IDocumentAccountingPeriod extends IDocumentBase {
  default: boolean
  period_start: string | null
  period_end: string | null
}

export type AccountingPeriodOption = Omit<IDocumentAccountingPeriod, 'id'> & IXtAutocompleteOption

export enum AttachedDocumentTargetType {
  File = 'File',
  Image = 'Image',
  URL = 'URL',
  Contact = 'Contact',
  Customer = 'Customer',
  // TODO: add remaining files
}

// TODO: add all fields
export enum AttachedDocumentPurpose {
  Sibling = 'Sibling',
  Inventory = 'Inventory',
}

// TODO: unify this with another type
export interface IAttachedDocument {
  id: number
  target_type: AttachedDocumentTargetType
  target_number: string
  target_id: number
  name: string
  description: string
  notes: string
  canview: boolean
  canedit: boolean
  purpose: AttachedDocumentPurpose
  file_link: string
  document_characteristics: IAppliedCharacteristic[] | null
  file?: File
  // assignedRoles: RoleId[] // TODO: Role assignments is out of scope for the first phase.
}

export interface IAttachedDocumentWithFile extends IAttachedDocument {
  file: File
}

export interface ICreateAttachedFileDocumentRequest {
  file: File
  document_type: DocumentType
  document_number: string
  document_notes: string
  file_name?: string
  document_characteristics: IAppliedCharacteristicNew[]
}

export interface IUpdateAttachedFileDocumentRequest {
  id: number
  name: string
  document_notes: string
  document_characteristics: IAppliedCharacteristic[]
}

export interface IDetachDocumentAssignment {
  target_type: AttachedDocumentTargetType
  id: number // this is the ID element of the document association that is supplied in the /documents/<doctype>/<docnumber> endpoint
}

export interface IDetachDocumentRequest {
  assignments: IDetachDocumentAssignment[]
}

export enum DocnumberSetting {
  Automatic = 'Automatic',
  SalesOrder = 'Sales Order',
  Override = 'Override',
  Manual = 'Manual',
}

export interface IDocnumber {
  number: string
  setting: DocnumberSetting
}

export enum DocumentIdField {
  AssignmentId = 'assignment_id',
  DocumentNumber = 'document_number',
}

export enum DocumentType {
  Account = 'CRMA',
  Address = 'ADDR',
  BomHead = 'BMH',
  BomItem = 'BMI',
  BreederBom = 'BBH',
  BreederBomItem = 'BBI',
  ClassCode = 'CLSCODE',
  Contact = 'T',
  Contract = 'CNTR',
  Manufacturer = 'MANUF',
  CostCategory = 'COSTCAT',
  Customer = 'C',
  CustomerType = 'CT',
  Document = 'FILE',
  Employee = 'EMP',
  ExchangeRates = 'FX',
  FixedAsset = 'FADOC',
  FreightClass = 'FRGHTCLASS',
  GLJournal = 'JE',
  Honorific = 'HONORIFIC',
  Incident = 'INCDT',
  Invoice = 'INV',
  InvoiceItem = 'INVI',
  Item = 'I',
  // ItemGroup = 'ITEMGRP', // same as ItemGroups
  ItemGroups = 'ITEMGRP', // same as ItemGroup; introduced in xTuple web app before importing the full list
  ItemSite = 'IS',
  ItemSource = 'IR',
  // ItemType = 'ITEMTYPE', // same as ItemTypes
  ItemTypes = 'ITEMTYPE', // same as ItemType; introduced in xTuple web app before importing the full list
  List = 'l', // same as Location; introduced in xTuple web app before importing the full list
  // TODO: find out why our name ('List') is so different from API's description ('Location') and maybe rename it
  // Location = 'L', // same as List
  Locale = 'LOCALE',
  LotSerial = 'LS',
  LotSerialRegistration = 'LSR',
  LotSerialRegistrationType = 'LSRTYPE',
  MaintenanceOrder = 'FAMAINT',
  Opportunity = 'OPP',
  OppSource = 'OPPSOURCE', // same as OpportunitySource; introduced in xTuple web app before importing the full list
  // OpportunitySource = 'OPPSOURCE', // same as OppSource
  OpportunityStage = 'OPPSTAGE',
  OpportunityType = 'OPPTYPE',
  Priority = 'PRIORITY',
  ProdCategory = 'prodcat', // same as ProductCategory; introduced in xTuple web app before importing the full list
  // ProductCategory = 'PRODCAT', // same as ProdCategory
  Project = 'J',
  Prospect = 'PSPCT',
  PurchaseOrder = 'P',
  PurchaseOrderItem = 'PI',
  PurchaseRequest = 'PR',
  PurchaseOrderType = 'POTYPE',
  PlanCode = 'PLANCODE',
  Quote = 'Q',
  QuoteItem = 'QI',
  RentalItem = 'RNTI',
  Return = 'CM',
  ReturnAuthorization = 'RA',
  ReturnAuthorizationItem = 'RI',
  ReturnItem = 'CMI',
  RoutingHead = 'BOH',
  RoutingItem = 'BOI',
  SaleType = 'SALETYPE',
  SalesOrder = 'S',
  SalesOrderItem = 'SI',
  SalesRep = 'SR',
  ShipTo = 'SHP',
  ShipCharges = 'SHIPCHRG', // same as ShippingCharges; introduced in xTuple web app before importing the full list
  // ShippingCharges = 'SHIPCHRG', // same as ShipCharges
  ShipForm = 'SHIPFORM', // same as ShippingForm; introduced in xTuple web app before importing the full list
  // ShippingForm = 'SHIPFORM', // same as ShipForm
  ShipZone = 'SHIPZONE', // same as ShippingZone; introduced in xTuple web app before importing the full list
  // ShippingZone = 'SHIPZONE', // same as ShipZone
  // Site = 'WH', // same as Warehouse
  Task = 'TA',
  TaxAuthority = 'TAXAUTH',
  TaxType = 'TAXTYPE',
  TaxZone = 'TAXZONE',
  TaxCode = 'TAXCODE',
  TimeExpense = 'TE',
  TransferOrder = 'TO',
  TransferOrderItem = 'TI',
  User = 'USR',
  UserRoles = 'USERROLE',
  Vendor = 'V',
  VendorGroup = 'CRMGRP',
  Voucher = 'VCH',
  Warehouse = 'WH', // same as Site; introduced in xTuple web app before importing the full list
  WorkOrder = 'W',
  AccountType = 'ACCTTYPE',
  CharacteristicGroup = 'CHARGRP',
  PrivilegeModules = 'PRIVMOD',
  UOM = 'UOM',
  Terms = 'TERMS',
  ReportName = 'LABELS',
  Orders = 'ORD',
  Period = 'PERIOD',
}

// TODO: not specified in API, make sure the values are correct
export enum UsedOnValue {
  Account = 'Account',
  Item = 'Item',
  InvoiceItem = 'Invoice Item',
  QuoteItem = 'Quote Item',
  SalesOrder = 'Sales Order',
  SalesOrderItem = 'Sales Order Item',
  PurchaseOrderItem = 'Purchase Order Item',
  TransferOrderItem = 'Transfer Order Item',
  WorkOrder = 'Work Order',
  Incident = 'Incident',
  Customer = 'Customer',
  Prospect = 'Prospect',
  Task = 'Task',
  Contact = 'Contact',
  Opportunity = 'Opportunity',
  Document = 'Document',
  Quote = 'Quote',
  Address = 'Address',
  Employee = 'Employee',
  FixedAsset = 'Fixed Asset',
  Invoice = 'Invoice',
  ItemGroup = 'Item Group',
  LotSerial = 'Lot/Serial',
  LotSerialRegistration = 'Lot/Serial Registration',
  MaintenanceOrder = 'Maintenance Order',
  Project = 'Project',
  PurchaseOrder = 'Purchase Order',
  ReturnAuthorization = 'Return Authorization',
  Vendor = 'Vendor',
  Voucher = 'Voucher',
}

export class DocumentCreationError extends AdditionalDataCreationError {
  public message = 'Document saving failed. Please try to add documents later.'
}

export interface IDocumentListFilters {
  showInactive?: boolean
  active?: boolean
  search_pattern?: string | null
  use_for_ap?: boolean
  vendor_number?: string
  source?: DocumentType
  shipping?: boolean
  to?: boolean
  so?: boolean
  site?: string
  item_number?: string
  withshipments?: boolean
  nontransit?: boolean
}

export interface IDocumentFilters {
  showDetail?: boolean
  active?: boolean
  fileOnly?: boolean
  sold?: boolean
}

export interface ISitesDocumentFilters extends IDocumentFilters {
  nontransit?: boolean
}

// TODO move to some other place
export enum CRMAccountRole {
  Customer = 'customer',
  Prospect = 'prospect',
  Vendor = 'vendor',
  TaxAuthority = 'tax_authority',
  User = 'user',
  Employee = 'employee',
  SalesRep = 'sales_rep',
  None = 'no role',
  Competitor = 'competitor',
  Partner = 'partner',
}

export interface ICRMAccountDocument {
  number: string
  name: string
  description: string
  default: boolean
  active: boolean
  credit_status_exceed_warn: boolean
  credit_status_exceed_hold: boolean
  crm_roles: CRMAccountRole[]
}

export interface ICRMAccountDocumentFilters {
  prospect?: boolean
  customer?: boolean
  active?: boolean
  search_pattern?: string | null
}

export enum DocumentUpdateEventType {
  Create = 'DocumentCreateEvent',
  Update = 'DocumentUpdateEvent',
  Delete = 'DocumentDeleteEvent',
}

export interface IDocumentUpdateEvent {
  type: DocumentUpdateEventType
  documentId: string | string[]
}

export interface IGenerationSettingsData {
  isReadOnly: boolean
  number: string | null
}

export type CRMAccountDocumentOption = ICRMAccountDocument & IXtAutocompleteOption

export interface IDocumentOrder extends IDocumentBase {
  order_type: OrderType
  order_from: string
  order_to: string
  order_status: string
  status_code: string
  default: boolean
}

export type DocumentOption = Omit<IDocument, 'id'> & IXtAutocompleteOption

export type DocumentOrderOption = Omit<IDocumentOrder, 'id'> & IXtAutocompleteOption

export interface IDocumentLocation extends IDocument {
  netable: boolean
  usable: boolean
  restricted: boolean
  site: string
}

export type DocumentLocationOption = Omit<IDocumentLocation, 'id'> & IXtAutocompleteOption
export type DocumentLocationFilters = IDocumentListFilters & InventoryHistoryByLocationFilters
