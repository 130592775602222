import React, { FC, useMemo } from 'react'
import { UserPermission } from 'auth/auth.types'
import { useShipmentsModule } from 'shipments/shipments-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { useContactsModule } from 'contacts/contacts-module-hook'
import { convertToContactSectionData } from 'contacts/components/contact-section/contact-section.utils'
import { IAddressTab } from './address-tab.types'
import * as styles from './address-tab.module.scss'

export const AddressTab: FC<IAddressTab> = ({ register, customer, isMobile, disabled }) => {
  const { ShipmentsList } = useShipmentsModule()
  const { PermissionsService } = useAuthModule()
  const { ContactSection } = useContactsModule()

  const canEditShipment = PermissionsService.hasPermission(UserPermission.MaintainShiptos)

  const contactSectionData = useMemo(() => convertToContactSectionData(customer?.billing_contact, customer?.correspond_contact), [customer])

  return (
    <div>
      <ContactSection
        disabled={disabled}
        isMobile={isMobile}
        data={contactSectionData}
        register={register}
        firstContactLabel="Billing Contact"
        secondContactLabel="Correspondence Contact"
      />
      <div hidden={!canEditShipment} className={styles.splitter} />
      {canEditShipment && <ShipmentsList disabled={disabled} isMobile={isMobile} accountNumber={customer?.customer_number ?? ''} />}
    </div>
  )
}
