import { IItemSite, ItemSiteDistributionOption, TransactionType } from 'products/items/items.types'
import { IItemsUtilsService } from 'products/items/items-utils.service'
import { supportsLotSerial } from '../../lot-serial.utils'
import { ILotSerialLocationHookResetResponse } from './lot-serial-location-hook.types'

export function canApplyAutoDistributedLocation(location: ItemSiteDistributionOption | null, qtyToAssign: number): boolean {
  if (!location) {
    return false
  }
  return location.qty_before + qtyToAssign >= 0
}

async function requestDefaultDistributionData(
  ItemsUtilsService: IItemsUtilsService,
  { item_number, site, location }: IItemSite
): Promise<ILotSerialLocationHookResetResponse> {
  const locations = await ItemsUtilsService.loadItemSiteDistributionOptions({
    itemNumber: item_number,
    site,
    transactionType: TransactionType.MiscAdjustment,
    includeLotSerial: false,
  })

  const defaultLocation = locations.find(({ id }) => id === location) ?? null

  return {
    locations,
    defaultLocation,
  }
}

export async function handleManualLocationDistributionForNegativeQty(
  ItemsUtilsService: IItemsUtilsService,
  { item_number, site, location }: IItemSite
): Promise<ILotSerialLocationHookResetResponse> {
  const locations = await ItemsUtilsService.loadItemSiteDistributionOptions({
    itemNumber: item_number,
    site,
    transactionType: TransactionType.MiscAdjustment,
    includeLotSerial: true,
  })

  const defaultLocation = locations.find(({ location_name }) => location_name === location) ?? null

  return {
    locations,
    defaultLocation: defaultLocation,
    lotSerialOptions: locations,
  }
}

export async function handleManualLocationDistribution(
  ItemsUtilsService: IItemsUtilsService,
  itemSite: IItemSite,
  qtyToAssign: number,
  defaults?: ILotSerialLocationHookResetResponse
): Promise<ILotSerialLocationHookResetResponse> {
  const { control_method } = itemSite

  const isNegativeQtyToAssign = qtyToAssign < 0

  if (isNegativeQtyToAssign && supportsLotSerial(control_method)) {
    return handleManualLocationDistributionForNegativeQty(ItemsUtilsService, itemSite)
  }

  return defaults ?? (await requestDefaultDistributionData(ItemsUtilsService, itemSite))
}

export async function handleAutomaticLocationDistribution(
  ItemsUtilsService: IItemsUtilsService,
  itemSite: IItemSite,
  qtyToAssign: number
): Promise<ILotSerialLocationHookResetResponse> {
  const defaults = await requestDefaultDistributionData(ItemsUtilsService, itemSite)

  const isNegativeQtyToAssign = qtyToAssign < 0

  if (isNegativeQtyToAssign && !canApplyAutoDistributedLocation(defaults.defaultLocation, qtyToAssign)) {
    return handleManualLocationDistribution(ItemsUtilsService, itemSite, qtyToAssign, defaults)
  }
  return defaults
}

export async function handleLocationDistribution(
  ItemsUtilsService: IItemsUtilsService,
  itemSite: IItemSite,
  qtyToAssign: number
): Promise<ILotSerialLocationHookResetResponse> {
  const { auto_distr_location, multiple_location_control } = itemSite

  if (!multiple_location_control) {
    return { locations: [], defaultLocation: null }
  }

  if (auto_distr_location) {
    return handleAutomaticLocationDistribution(ItemsUtilsService, itemSite, qtyToAssign)
  } else {
    return handleManualLocationDistribution(ItemsUtilsService, itemSite, qtyToAssign)
  }
}
