import { IModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { Scope } from 'core/injector/injector.types'
import { commentsModuleToken, commentsServiceToken } from './comments.constants'
import { ICommentsService } from './comments.service'
import { CoreModule } from '../core/core.module'
import { AuthModule } from '../auth/auth.module'
import { CommentsModuleDefinition } from './comments-module.types'
import * as CommentsModuleExports from './comments.exports'

export const CommentsModule: IModule<CommentsModuleDefinition> = {
  name: commentsModuleToken,
  resolver: () => CommentsModuleExports,
  initializer: ({ CommentsService }) => {
    Injector.register<ICommentsService>(
      commentsServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        return new CommentsService(httpClient)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, AuthModule],
}
