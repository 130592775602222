import { CostTableItem } from '../../../../../items.types'

export function markTableItemAsSelected(data: CostTableItem[], selectedId?: number | string): CostTableItem[] {
  return data.map((value) => ({ ...value, selected: Boolean(value.id === selectedId) }))
}

export function booleanCostColumnConverter(active: unknown): string {
  if (typeof active !== 'boolean') {
    return ''
  }

  return active ? 'Yes' : 'No'
}
