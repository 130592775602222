import { FilterType, IFilter } from 'components/pagefilter/filter/filter.types'
import { IDocumentsUtilsService } from 'documents/documents-utils.service'
import {
  defineAutocompleteOption,
  getAutocompleteInputLabelAsId,
  renderColumnOption,
} from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { IOpportunitiesFilters, OpportunitiesFilter, OpportunitiesFiltersPanel } from '../services/opportunities-services.types'

export function resolveFilters({
  loadProjectOptions,
  loadOpportunityTypes,
  loadDocumentOppSourceOptions,
  loadOpportunityStages,
  loadAccountOptions,
  loadUserOptions,
}: IDocumentsUtilsService): IFilter[] {
  return [
    {
      type: FilterType.Autocomplete,
      label: 'User',
      fieldName: OpportunitiesFilter.User,
      autocompleteProps: {
        loadOptions: loadUserOptions,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: 'Assigned To',
      fieldName: OpportunitiesFilter.AssignedTo,
      autocompleteProps: {
        loadOptions: loadUserOptions,
      },
    },

    {
      type: FilterType.Date,
      label: 'Target Date on or After',
      fieldName: OpportunitiesFilter.TargetDateOnOrAfter,
    },
    {
      type: FilterType.Date,
      label: 'Target Date on or Before',
      fieldName: OpportunitiesFilter.TargetDateOnOrBefore,
    },
    {
      type: FilterType.Autocomplete,
      label: 'Project',
      fieldName: OpportunitiesFilter.Project,
      autocompleteProps: {
        loadOptions: loadProjectOptions,
        renderOption: renderColumnOption,
        getInputLabel: getAutocompleteInputLabelAsId,
      },
    },
    {
      type: FilterType.Text,
      label: 'Name Pattern',
      fieldName: OpportunitiesFilter.NamePattern,
    },
    {
      type: FilterType.Autocomplete,
      label: 'Account',
      fieldName: OpportunitiesFilter.Account,
      autocompleteProps: {
        loadOptions: loadAccountOptions,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: 'Type',
      fieldName: OpportunitiesFilter.Type,
      autocompleteProps: {
        loadOptions: loadOpportunityTypes,
      },
    },
    {
      type: FilterType.Text,
      label: 'Type Pattern',
      fieldName: OpportunitiesFilter.TypePattern,
    },
    {
      type: FilterType.Autocomplete,
      label: 'Source',
      fieldName: OpportunitiesFilter.Source,
      autocompleteProps: {
        loadOptions: loadDocumentOppSourceOptions,
      },
    },
    {
      type: FilterType.Text,
      label: 'Source Pattern',
      fieldName: OpportunitiesFilter.SourcePattern,
    },
    {
      type: FilterType.Autocomplete,
      label: 'Stage',
      fieldName: OpportunitiesFilter.Stage,
      autocompleteProps: {
        loadOptions: loadOpportunityStages,
      },
    },
    {
      type: FilterType.Text,
      label: 'Stage Pattern',
      fieldName: OpportunitiesFilter.StagePattern,
    },
    {
      type: FilterType.Date,
      label: 'Create Date on or After',
      fieldName: OpportunitiesFilter.CreateDateOnOrAfter,
    },
    {
      type: FilterType.Date,
      label: 'Create Date on or Before',
      fieldName: OpportunitiesFilter.CreateDateOnOrBefore,
    },
  ]
}

export function defineFiltersPanel(
  defaultFiltersPanel: OpportunitiesFiltersPanel,
  filters?: IOpportunitiesFilters
): OpportunitiesFiltersPanel {
  if (!filters) return defaultFiltersPanel

  const {
    account,
    assigned_username,
    endCreateDate,
    endDate,
    name_pattern,
    project,
    showinactive,
    source,
    source_pattern,
    stage,
    stage_pattern,
    startCreateDate,
    startDate,
    type,
    type_pattern,
    username,
  } = filters
  return {
    account: account ? defineAutocompleteOption(account) : null,
    assigned_username: assigned_username ? defineAutocompleteOption(account) : null,
    endCreateDate: endCreateDate ? new Date(endCreateDate) : null,
    endDate: endDate ? new Date(endDate) : null,
    name_pattern: name_pattern ?? '',
    project: project ? defineAutocompleteOption(project) : null,
    showinactive: showinactive ?? false,
    source: source ? defineAutocompleteOption(source) : null,
    source_pattern: source_pattern ?? '',
    stage: stage ? defineAutocompleteOption(stage) : null,
    stage_pattern: stage_pattern ?? '',
    startCreateDate: startCreateDate ? new Date(startCreateDate) : null,
    startDate: startDate ? new Date(startDate) : null,
    type: type ? defineAutocompleteOption(type) : null,
    type_pattern: type_pattern ?? '',
    username: username ? defineAutocompleteOption(username) : null,
  }
}
