import * as yup from 'yup'
import { DateSchema } from 'yup'
import { validationMessage } from 'common/validation/validation'
import { dateYupValidator, isValidDate } from 'common/utils/date.utils'
import { InventoryHistoryFilterField, InventoryHistoryFilterLabel } from '../inventory-history-list.types'

export const inventoryHistoryValidationScheme = yup.object().shape(
  {
    [InventoryHistoryFilterField.StartDate]: dateYupValidator.when(
      InventoryHistoryFilterField.EndDate,
      (endDate: Date | null, schema: DateSchema) =>
        endDate && isValidDate(endDate) ? schema.max(endDate, validationMessage.maxDate(InventoryHistoryFilterLabel.EndDate)) : schema
    ),
    [InventoryHistoryFilterField.EndDate]: dateYupValidator.when(
      InventoryHistoryFilterField.StartDate,
      (startDate: Date | null, schema: DateSchema) =>
        startDate && isValidDate(startDate)
          ? schema.min(startDate, validationMessage.minDate(InventoryHistoryFilterLabel.StartDate))
          : schema
    ),
  },
  [[InventoryHistoryFilterField.StartDate, InventoryHistoryFilterField.EndDate]]
)
