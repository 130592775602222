import type { IXtMode } from 'common/common.types'
import React, { FC } from 'react'
import { useHistory, useParams } from 'react-router'
import { companiesRoutePath, companiesRoutes } from 'companies/companies.constants'
import { CustomerDetails } from './customer-details'

export const CustomerDetailsPage: FC<IXtMode> = ({ mode }) => {
  const { customerNumber } = useParams<{ customerNumber: string }>()

  const history = useHistory()

  const onCancel = (): void => history.push(`${companiesRoutePath}/${companiesRoutes.customers}`)
  return <CustomerDetails customerNumber={customerNumber} customerName={null} mode={mode} onCancel={onCancel} />
}
