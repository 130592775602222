import * as React from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams'
import { GridEnrichedColDef } from '@mui/x-data-grid/models/colDef/gridColDef'
import { Tooltip } from '@material-ui/core'
import { ITableRowWithParentId } from 'components/table/table.types'
import { TableActionHandler } from 'common/types/table.types'
import { calculateActionsWidth, IXtTableCellAction, XtTableCellActions } from 'components/table/table-cell-actions/table-cell-actions'
import { shouldDisplayActions } from 'components/list/list'
import { TableCell } from 'components/table/table-head/table-head.types'
import * as styles from '../nested-xt-table/nested-xt-table.module.scss'

export function createActionNestedColumn<TData extends ITableRowWithParentId, Action>(
  onAction?: TableActionHandler<GridRenderCellParams, Action>,
  actions: IXtTableCellAction[] = [],
  getItemAction?: (item: GridRenderCellParams, actions: IXtTableCellAction[]) => IXtTableCellAction[]
): GridEnrichedColDef<TData> | null {
  if (!shouldDisplayActions(onAction, actions)) {
    return null
  }

  return {
    renderCell: (params: GridRenderCellParams) => (
      <XtTableCellActions
        className="xt-table-cell-actions"
        actions={getItemAction ? getItemAction(params, actions) : actions}
        onClick={(action) => onAction!(params, (action as unknown) as Action)}
      />
    ),
    minWidth: calculateActionsWidth(actions.length),
    headerName: TableCell.Action,
    field: 'XtTableCellActions',
    sortable: false,
  }
}

export function addTooltipsToColumns<TData extends ITableRowWithParentId>(
  columns: Array<GridEnrichedColDef<TData>>
): Array<GridEnrichedColDef<TData>> {
  return columns.map((column) => ({
    ...column,
    renderCell: (params: GridRenderCellParams) => (
      <Tooltip title={params.value}>
        <span className={styles.tableCell}>{column?.valueParser ? column?.valueParser(params.value) : params.value}</span>
      </Tooltip>
    ),
  }))
}
