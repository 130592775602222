import * as React from 'react'
import { useMemo } from 'react'
import { IActionTableParams, TableActionHandler } from 'common/types/table.types'
import { IObjectWithId } from 'common/common.types'
import { XtTable } from '../table/table'
import { XtCardList } from '../card-list/card-list'
import { ICardData } from '../card-list/card-list.types'
import { IXtTableColumn, TableCell } from '../table/table-head/table-head.types'
import { calculateActionsWidth, IXtTableCellAction, XtTableCellActions } from '../table/table-cell-actions/table-cell-actions'
import { getFieldValue } from '../table/table.utils'
import { ITableRow } from '../table/table.types'
import './list.scss'

function convertColumnsToMobileData<TData extends ITableRow>(items: TData[], columns: IXtTableColumn<TData>[]): ICardData<TData>[] {
  return items.map((item) => ({
    data: item,
    fields: columns.map(({ field, headerName, converter, renderCell }) => {
      const value = field ? getFieldValue<TData>(item, field!, converter) : undefined
      return { value, label: headerName ?? '', cellTemplate: renderCell?.(item) }
    }),
  }))
}

export function shouldDisplayActions<TData, Action>(onAction?: TableActionHandler<TData, Action>, actions?: IXtTableCellAction[]): boolean {
  return typeof onAction === 'function' && !!actions?.length
}

export function createActionColumn<TData extends IObjectWithId, Action>(
  onAction?: TableActionHandler<TData, Action>,
  actions: IXtTableCellAction[] = [],
  getItemAction?: (item: TData, actions: IXtTableCellAction[]) => IXtTableCellAction[]
): IXtTableColumn<TData> | null {
  if (!shouldDisplayActions(onAction, actions)) {
    return null
  }

  return {
    id: 'actions',
    renderCell: (item) => (
      <XtTableCellActions
        className="xt-table-cell-actions"
        actions={getItemAction ? getItemAction(item, actions) : actions}
        onClick={(action) => onAction!(item, (action as unknown) as Action)} // TODO fix type casting
      />
    ),
    flex: `0 0 ${calculateActionsWidth(actions.length)}px`,
    width: calculateActionsWidth(actions.length),
    headerName: TableCell.Action,
    sticky: true,
    height: '100%',
    align: 'center',
    unsortable: true,
  }
}

const defaultLoadMoreHandler: VoidFunction = () => {}

export function XtList<TData extends ITableRow, Action, TColumn extends IXtTableColumn<TData>>({
  data,
  loading = false,
  pagination,
  isMobile = false,
  onRowClick,
  columns,
  onAction,
  actions = [],
  convertToMobileData,
  className,
  cardListClassName,
  getItemActions,
  sortOptions,
  onColumnHeaderClick,
  renderRow,
  infoMsg,
  loadMessage,
}: IActionTableParams<TData, Action, TColumn>): React.ReactElement {
  const actionColumns = useMemo<TColumn[]>(() => {
    const actionColumn = createActionColumn(onAction, actions, getItemActions) as TColumn
    return actionColumn ? [...columns, actionColumn] : [...columns]
  }, [onAction, actions, getItemActions, columns])

  const mobileData = useMemo(
    () => (typeof convertToMobileData === 'function' ? convertToMobileData(data) : convertColumnsToMobileData(data, actionColumns)),
    [data, convertToMobileData, actionColumns]
  )

  return isMobile ? (
    <XtCardList
      canLoadMore={pagination?.canLoadMore ?? false}
      loading={loading}
      loadMore={pagination?.loadMore ?? defaultLoadMoreHandler}
      onCardClick={onRowClick}
      data={mobileData}
      className={cardListClassName}
    />
  ) : (
    <XtTable
      loading={loading}
      onRowClick={onRowClick}
      rows={data}
      columns={actionColumns}
      pagination={pagination}
      className={className}
      rowClassName="xt-grid-table-button-cell"
      sortOptions={sortOptions}
      onColumnHeaderClick={onColumnHeaderClick}
      renderRow={renderRow}
      infoMsg={infoMsg}
      loadMessage={loadMessage}
    />
  )
}
