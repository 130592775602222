import { ItemOption } from 'products/items/items.types'
import { RevisionOption } from 'core/services/revision.service'

export enum CostsOptionLabel {
  Standard = 'Use Standard Costs',
  Actual = 'Use Actual Costs',
}

export enum FormSharedLabel {
  ItemDetails = 'Item #',
  Revision = 'Revision',
}

export enum FormSharedField {
  CostsOption = 'cost_option',
  ItemDetails = 'item_details',
  Revision = 'revision',
}

export enum CostsOptionField {
  Standard = 'useStandardCosts',
  Actual = 'useActualCosts',
}

export interface IBomListSharedForm {
  [FormSharedField.CostsOption]: CostsOptionField
  [FormSharedField.ItemDetails]: ItemOption | null
  [FormSharedField.Revision]: RevisionOption | null
}

export interface IBomListFormSharedParams<TFilters> {
  onChange: (filters: TFilters) => void
}
