import { Overwrite } from 'utility-types'
import { IContact, IContactAddress } from 'contacts/contacts.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { OrderType } from 'core/core.types'
import { IObjectWithId } from 'common/common.types'

export interface IShipment {
  shipto_number: string
  active: boolean
  name: string
  default_flag: boolean
  shipto_address: IContactAddress | null
  shipto_contact: IContact | null
  sales_rep: string
  shipping_zone: string | null
  tax_zone: string | null
  ship_via: string | null
  shipping_charges: string | null
  shipping_form: string | null
  preferred_selling_site: string | null
  general_notes: string
  shipping_notes: string
  created: string
  lastupdated: string
}

export interface IShipmentResponse {
  customer_number: string
  customer_name: string
  shiptos: IShipment[] | null
}

export type ShipmentPayload = Overwrite<
  Omit<IShipment, 'created' | 'lastupdated'>,
  { shipto_contact: Pick<IContact, 'contact_number'> | null }
>

export interface IShipmentVia {
  code: string
  description: string
  active: boolean
  created: string
  lastupdated: string
}

export type ShipmentOption = IXtAutocompleteOption & IShipment & { addressLine1: string; addressLine2: string }

export interface IShipmentPrintLabelPayload {
  order_type: OrderType
  order_number: string
  report_name?: string
  label_from: number
  label_to: number
}

export interface IShipmentFilters {
  order_type?: string
  order_number?: string
  item_number?: string
  showShipped?: boolean
  site?: string
}

export interface ICustomerInfo {
  order_date: string
  hold_type: string
  order_status: string
  po_number: string
  customer_name: string
  customer_phone: string
  shipto_name: string
  shipto_address1: string
  shipto_address2: string
  shipto_address3: string
  shipto_city: string
  shipto_state: string
  shipto_postalcode: string
  shipto_country: string
}

export interface IShipmentLine {
  order_id: number
  line_number: number
  item_number: string
  item_description: string
  site: string
  qty_ordered: number
  uom: string
  qty_at_shipping: number
  shipment_value: number
  qty_to_ship: number
  qty_shipped: number
  hold_type: string
  description: string
  net_weight: number
  tare_weight: number
  gross_weight: number
}

export interface IShipmentDataLightweight {
  shipment_number: string
  shipment_site: string
  order_type: OrderType
  order_number: string
  customer: string
  shipped: boolean
  ship_date: string
  ship_via: string
  tracking_number: string
  freight: number
  freight_currency: string
  freight_base_currency: string
  notes: string
  created: string
  lastupdated: string
}

export interface IShipmentData extends IShipmentDataLightweight {
  customer_info: ICustomerInfo | null
  shipping_form: string
  dropship: boolean
  shipping_charges: string
  shipment_lines: IShipmentLine[]
}

export type ShipmentLineTable = IShipmentLine & IObjectWithId

export type ShipmentDataOption = IXtAutocompleteOption & IShipmentDataLightweight

export type ShipmentsResponseType<ShowDetails> = ShowDetails extends true ? IShipmentData : IShipmentDataLightweight

export enum ReportType {
  Auto = 'auto',
  PackingList = 'packing_list',
  PickList = 'pick_list',
}
