import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { AsOfFilters, IInventoryListFilterForm, WorkbenchFilterFormField } from './inventory-list-filter.types'

export const lookAheadDaysMinValue = 0
export const lookAheadDaysMaxValue = 999

export const filterAsOfParams = [AsOfFilters.ItemSiteLeadTime, AsOfFilters.LookAheadDays, AsOfFilters.CutoffDate, AsOfFilters.Dates]

export const filterOptions = filterAsOfParams.map((filter) => defineAutocompleteOption(filter))

export const defaultFilterState: IInventoryListFilterForm = {
  [WorkbenchFilterFormField.FilterType]: defineAutocompleteOption(AsOfFilters.ItemSiteLeadTime),
  // [WorkbenchFilterFormField.OnlyShowPositiveQuantity]: Boolean(AsOfFilters.OnlyShowPositiveQuantity),
}

export const OnlyShowPositiveQuantity = 'Only Show Positive Quantity'
