import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Observable, Subject } from 'rxjs'

import { useHistory, useParams } from 'react-router'
import { XtMode } from 'common/common.types'

import { NumberTypeUtils } from 'common/utils/type.utils'
import { useTable } from 'common/hooks/useTable'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'

import { useXtForm } from 'common/hooks/form/form'
import { convertMode } from 'common/utils/mode.utils'
import { dateToServerFormat } from 'common/utils/date.utils'
import { globalConstants } from 'common/constants'
import { useCoreModule } from 'core/core-module-hook'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { productsRoutePath, productsRoutes } from '../../products.constants'
import { defaultBomItemDialogState, defaultBomState, defaultFilterValues } from './bom-details.constants'
import { BomItemMode, BomItemSubmitFunc } from '../bom-item/bom-item.types'
import { defineAvailableActions, findBomItem, processBom } from './bom-details.utils'
import { bomDetailsValidationSchema } from './bom-details.validation'
import { BomCreateInput, BomItemQuickAddInput, IBomItem } from '../bom.types'
import {
  BomDetailsAction,
  BomDetailsFormField,
  BomFormData,
  IBomDetailsParams,
  IBomItemDialogState,
  IBomState,
  IUseBomDetails,
  NewBomSubmitNewItemFunc,
  OnBomItemChange,
  OpenBomItem,
} from './bom-details.types'
import { useProductsModule } from '../../products-module-hook'

let bomItemId: number = 1

export const UseBomDetails = (mode: XtMode): IUseBomDetails => {
  const { BomService, BomItemsService, BomUtilsService } = useProductsModule()
  const { ErrorHandler, ToastService } = useCoreModule()

  const [isQuickAddOpen, setOpenQuickAdd] = useState(false)
  const openQuickAddForm = useCallback(() => setOpenQuickAdd(true), [])
  const closeQuickAddForm = useCallback(() => setOpenQuickAdd(false), [])
  const [bomItemDialogState, setBomItemDialogState] = useState<IBomItemDialogState>(defaultBomItemDialogState)
  const pageParams = useParams<IBomDetailsParams>()
  const bomDetailsMode = convertMode(mode)
  const history = useHistory()
  const { isViewMode } = bomDetailsMode
  const [bomData, setBomData] = useState<IBomState>(defaultBomState)
  const updateFiltersSubject = useRef<Subject<undefined>>(new Subject())
  const updateFilters$ = useRef<Observable<undefined>>(updateFiltersSubject.current.asObservable()).current
  const deletionState = useConfirmationDialog<number>()
  const { itemId: itemIdToDelete, openDialog, closeDialog } = deletionState
  const formState = useXtForm<BomFormData>({
    defaultValues: processBom(null),
    validationSchema: bomDetailsValidationSchema,
    mode: 'onBlur',
  })

  const { reset, watch } = formState

  const itemNumberValue = watch(BomDetailsFormField.ItemNumber)

  const [bomNotFound, setBomNotFound] = useState(false)

  const tableState = useTable(
    defaultFilterValues,
    async (filters, paginationParams, sorting) =>
      BomUtilsService.fetchBomItems(filters, paginationParams, sorting, itemNumberValue?.itemNumber ?? pageParams.itemNumber),
    undefined,
    PageFilterMapping.BOM
  )
  const { state, setLoading, refresh, filter, setData } = tableState

  async function init(number: string): Promise<void> {
    if (!number) {
      return
    }
    try {
      setBomData((prev) => ({ ...prev, loading: true }))
      const bom = await BomService.get(number, false, false)
      setData(bom.bom_items, bom.bom_items.length)
      setBomData({ bom, loading: false })
      reset(processBom(bom))
      setBomNotFound(false)
    } catch (error) {
      setBomNotFound(true)
    }
  }

  const refreshTableData = async () => {
    const { bom_items } = await BomService.get(itemNumberValue?.itemNumber ?? pageParams.itemNumber, false, false)
    setData(bom_items, bom_items.length)
  }

  useEffect(() => void init(pageParams.itemNumber), [pageParams.itemNumber])

  useEffect(() => {
    if (bomNotFound) throw Error(`BOM not found. Item number: ${pageParams.itemNumber}.`)
  }, [bomNotFound])

  const setDefaultFormData: (itemId?: string) => void = (itemId?: string) => {
    reset(processBom(null, itemId))
    setBomData(defaultBomState)
    setData([], 0)
  }

  const handleProcessingBom = async (itemId: string): Promise<void> => {
    try {
      const bom = await BomService.get(itemId, false, false)
      reset(processBom(bom))
      setBomData((prev) => ({ ...prev, bom }))
      setLoading(true)
      const { total, data } = await BomItemsService.getAll(
        itemId,
        { showExpired: false, showFuture: false },
        { page: 0, limit: globalConstants.paginationLimit }
      )
      setLoading(false)
      setData(data, total)
    } catch (error) {
      if (error?.message) {
        ToastService.showError(error.message)
      }

      setLoading(false)
      // is new mode
      setDefaultFormData(itemId)
    }
  }

  const onItemNumberChange = useCallback<OnBomItemChange>(
    async (item) => {
      updateFiltersSubject.current.next()
      filter(defaultFilterValues)
      if (item?.item_number) {
        await handleProcessingBom(item.item_number)
      } else {
        setDefaultFormData()
      }
    },
    [filter, handleProcessingBom, setDefaultFormData]
  )

  const openBomItem: OpenBomItem = (itemId, selectedMode) => {
    setBomItemDialogState({ open: true, bomItem: itemId ? findBomItem(itemId, state.data) : null, mode: selectedMode })
  }

  const closeBomItemDialog = useCallback(() => {
    setBomItemDialogState({ open: false, bomItem: null, mode: BomItemMode.View })
    closeQuickAddForm()
  }, [closeQuickAddForm])

  const handleAdvancedSearch = useCallback(() => openBomItem(null, BomItemMode.Search), [state.data])

  const addBomItemForNewBom: (newItem: BomItemQuickAddInput) => void = (newItem) => {
    const newBomItem: IBomItem = {
      ...newItem,
      id: bomItemId++,
    } as IBomItem
    const data = [...state.data, newBomItem]
    setData(data, data.length)
  }

  const addBomItemForExistingBom: (itemId: string, newItem: BomItemQuickAddInput) => Promise<void> = async (
    itemId: string,
    newItem: BomItemQuickAddInput
  ) => {
    try {
      setLoading(true)
      const message = await BomItemsService.quickAdd(itemId, newItem)
      await refreshTableData()
      ToastService.showSuccess(message)
      setLoading(false)
    } catch (error) {
      ErrorHandler.handleError(error)
      setLoading(false)
    }
  }

  const addBomItem = useCallback(
    async (quickAddFormData: BomItemQuickAddInput) => {
      const newItem = {
        ...quickAddFormData,
        bom_item_inventory_uom: quickAddFormData.item.inventory_uom_name,
        bom_item_description: quickAddFormData.item.description1,
        bom_item_number: quickAddFormData.item.item_number,
        issue_uom: quickAddFormData.item.inventory_uom?.name ?? null,
      }

      if (!bomData.bom || !itemNumberValue) {
        addBomItemForNewBom(newItem)
      } else {
        await addBomItemForExistingBom(itemNumberValue.itemNumber, newItem)
      }
      closeQuickAddForm()
    },
    [closeQuickAddForm, addBomItemForNewBom, addBomItemForExistingBom, bomData.bom]
  )

  const submitBomItemForExistingBom: BomItemSubmitFunc = async (parentItemNumber, bomItem, newItem, dialogMode, comments) => {
    try {
      if ((dialogMode === BomItemMode.Edit || dialogMode === BomItemMode.Search) && !bomItem) {
        const message = await BomItemsService.create(parentItemNumber, newItem, comments)
        ToastService.showSuccess(message)
        return
      }
      if (!bomItem) {
        return
      }
      if (dialogMode === BomItemMode.Edit || dialogMode === BomItemMode.Search) {
        const message = await BomItemsService.update(parentItemNumber, { id: bomItem.id, ...newItem })
        ToastService.showSuccess(message)
        return
      }
      if (dialogMode === BomItemMode.Replace) {
        await BomItemsService.replace(parentItemNumber, bomItem.sequence_number, newItem, comments)
        ToastService.showSuccess(`BOM Item ${newItem.bom_item_number} has been replaced.`)
        return
      }
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }

  const submitBomItemForNewBom: NewBomSubmitNewItemFunc = (bomItem, newItem, dialogMode) => {
    if (dialogMode === BomItemMode.Search && !bomItem) {
      // eslint-disable-next-line no-plusplus
      const newBomItem: IBomItem = { ...newItem, id: bomItemId++ } as IBomItem
      const data = [...state.data, newBomItem]
      setData(data, data.length)
      closeBomItemDialog()
      return
    }
    if ((dialogMode === BomItemMode.Search || dialogMode === BomItemMode.Edit) && bomItem) {
      const itemIndex = state.data.findIndex(({ id }) => id === bomItem.id)
      const updatedBom = { ...bomItem, ...newItem, item_type: newItem.item.item_type }
      const data = [...state.data.slice(0, itemIndex), updatedBom, ...state.data.slice(itemIndex + 1)]
      setData(data, data.length)
    }
  }

  const onBomItemSubmit = useCallback<BomItemSubmitFunc>(
    async (parentItemNumber, bomItem, newItem, dialogMode, comments) => {
      if (!bomData.bom) {
        submitBomItemForNewBom(bomItem, newItem, dialogMode)
      } else {
        setLoading(true)
        await submitBomItemForExistingBom(parentItemNumber, bomItem, newItem, dialogMode, comments)
        await refreshTableData()
        setLoading(false)
      }
      closeBomItemDialog()
    },
    [closeBomItemDialog, state.data, bomData.bom, submitBomItemForExistingBom, submitBomItemForNewBom]
  )

  const expireItem = async (bomItemNumber: string, bomItem: IBomItem): Promise<void> => {
    try {
      setLoading(true)
      const message = await BomItemsService.expire(bomItemNumber, bomItem.sequence_number)
      await refreshTableData()
      setLoading(false)
      ToastService.showSuccess(message)
    } catch (error) {
      setLoading(false)
      ErrorHandler.handleError(error)
    }
  }

  const tableActions = useMemo(() => defineAvailableActions(!bomData.bom, isViewMode), [bomData.bom])

  const handleRowClick = useCallback(
    ({ id: itemId }: IBomItem) => {
      const itemMode = bomData.bom ? BomItemMode.Edit : BomItemMode.Search
      openBomItem(itemId, isViewMode ? BomItemMode.View : itemMode)
    },
    [isViewMode, openBomItem]
  )

  const handleAction = useCallback(
    ({ id }: IBomItem, action: BomDetailsAction) => {
      switch (action) {
        case BomDetailsAction.View:
          return openBomItem(id, BomItemMode.View)
        case BomDetailsAction.Edit: {
          const itemMode = bomData.bom ? BomItemMode.Edit : BomItemMode.Search
          return openBomItem(id, itemMode)
        }
        case BomDetailsAction.Replace:
          return openBomItem(id, BomItemMode.Replace)
        case BomDetailsAction.Expire: {
          const item = findBomItem(id, state.data)
          return bomData.bom && item && bomData.bom.item_number && expireItem(bomData.bom.item_number, item)
        }
        case BomDetailsAction.Delete:
          // TODO:  update unique id after discussing the incident 160064
          return openDialog(id)
        default:
          return openBomItem(id, BomItemMode.View)
      }
    },
    [bomData.bom?.item_number, state.data, filter, openBomItem]
  )

  const handleDeletionForNewBom = () => {
    const itemIndex = state.data.findIndex(({ id }) => id === itemIdToDelete)
    const data = [...state.data.slice(0, itemIndex), ...state.data.slice(itemIndex + 1)]
    setData(data, data.length)
  }

  const handleDeletion = useCallback<VoidFunction>(async () => {
    closeDialog()
    if (!itemIdToDelete) {
      return
    }
    if (!bomData.bom) {
      handleDeletionForNewBom()
      return
    }
    const bomItem = findBomItem(itemIdToDelete, state.data)
    if (bomItem) {
      try {
        setLoading(true)
        const message = await BomItemsService.delete(bomData.bom.item_number, bomItem.sequence_number)
        await refreshTableData()
        ToastService.showSuccess(message)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        ErrorHandler.handleError(error)
      }
    }
  }, [closeDialog, itemIdToDelete, bomData.bom?.item_number, state.data, setData, setLoading, refresh])

  const onCancel = () => {
    // TODO implement confirmation dialog
    // eslint-disable-next-line no-restricted-globals
    history.push(`${productsRoutePath}/${productsRoutes.bom}`)
  }

  const onSubmit: (data: BomFormData) => Promise<void> = async (formValues) => {
    if (!itemNumberValue) {
      return
    }
    try {
      const bomInput = {
        ...formValues,
        revision_date: formValues.revision_date ? dateToServerFormat(formValues.revision_date) : null,
        batch_size: NumberTypeUtils.parseString(formValues.batch_size),
        bom_items: state.data,
        item_number: itemNumberValue.itemNumber,
      }
      if (!bomData.bom) {
        const bomCreateInput: BomCreateInput = {
          ...bomInput,
          item_number: bomInput.item_number,
          bom_items: bomInput.bom_items.map(({ id: _, ...other }) => ({ ...other })),
        }
        const message = await BomService.create(bomCreateInput)
        ToastService.showSuccess(message)
        return
      }
      const message = await BomService.update(bomInput)
      ToastService.showSuccess(message)
    } catch (error) {
      ErrorHandler.handleError(error)
    }
  }

  return {
    tableState: { state: tableState, tableActions, handleRowClick, handleAction },
    formState: {
      state: formState,
      onSubmit,
      onCancel,
    },
    deletionState: {
      state: deletionState,
      handleDeletion,
    },
    quickAddState: {
      isQuickAddOpen,
      openQuickAddForm,
      closeQuickAddForm,
      handleAdvancedSearch,
    },
    bomItemState: {
      bomItemDialogState,
      addBomItem,
      onBomItemSubmit,
      closeBomItemDialog,
    },
    onItemNumberChange,
    updateFilters$,
    bomData,
    itemNumberValue,
    bomDetailsMode,
  }
}
