import * as yup from 'yup'
import { StringSchema } from 'yup'
import { ProfileDetailsFormField } from './profile.types'
import { validationMessage } from '../common/validation/validation'
import { PasswordChangeFormField } from './password-dialog/password-dialog.types'
import { isAutocompleteValue } from '../components/controls/xt-autocomplete/xt-autocomplete.utils'

export const profileValidation = yup.object().shape(
  {
    [ProfileDetailsFormField.Locale]: yup.object().required(validationMessage.isRequired).nullable(),
    [PasswordChangeFormField.Password]: yup
      .string()
      .when(PasswordChangeFormField.Verify, (verify: string, schema: StringSchema) =>
        verify ? schema.required(validationMessage.isRequired) : schema
      ),
    [PasswordChangeFormField.Verify]: yup
      .string()
      .when(PasswordChangeFormField.Password, (password: string, schema: StringSchema) =>
        password
          ? schema
              .required(validationMessage.isRequired)
              .oneOf([yup.ref(PasswordChangeFormField.Password)], validationMessage.passwordMatch)
          : schema
      ),
    [ProfileDetailsFormField.RowsPerPage]: yup
      .object()
      .nullable()
      .test({
        test: (rowsPerPage: unknown) =>
          isAutocompleteValue(rowsPerPage) && !Number.isNaN(Number(rowsPerPage.id))
            ? 1 <= Number(rowsPerPage.id) && Number(rowsPerPage.id) <= 200
            : true,
        message: validationMessage.diapason(1, 200),
      }),
  },
  [[PasswordChangeFormField.Password, PasswordChangeFormField.Verify]]
)
