import { IModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import * as ToastModuleExports from './toast.exports'
import { IToastService } from './toast.service'
import { toastModuleToken, toastServiceToken } from './toast.constants'
import { ToastModuleDefinition } from './toast-module.types'

export const ToastModule: IModule<ToastModuleDefinition> = {
  name: toastModuleToken,
  resolver: () => ToastModuleExports,
  initializer: ({ ToastService }) => {
    Injector.register<IToastService>(toastServiceToken, () => new ToastService(), Scope.Singleton)
  },
  dependencies: [],
}
