import * as Yup from 'yup'
import { validationMessage } from 'common/validation/validation'
import { PhoneMessageField } from './phone-message-dialog.types'

const MAX_MESSAGE_CHARS = 160
export const PhoneMessageDialogFormSchema = Yup.object().shape({
  [PhoneMessageField.Message]: Yup.string()
    .max(MAX_MESSAGE_CHARS, validationMessage.max(MAX_MESSAGE_CHARS))
    .required(validationMessage.isRequired),
})
