// extracted by mini-css-extract-plugin
export var root = "shipping-information-dialog_root__xWPoB";
export var xtContent = "shipping-information-dialog_xt-content__6zKRO";
export var xtContentWithRemarks = "shipping-information-dialog_xt-content-with-remarks__MXF8V";
export var muiFormHelperTextRoot = "shipping-information-dialog_MuiFormHelperText-root__ki4qn";
export var xtListPageContainer = "shipping-information-dialog_xt-list-page-container__fJmLC";
export var muiInputBaseRoot = "shipping-information-dialog_MuiInputBase-root__yXMpD";
export var muiTextFieldRoot = "shipping-information-dialog_MuiTextField-root__D45iy";
export var muiTablePaginationRoot = "shipping-information-dialog_MuiTablePagination-root__tReJo";
export var muiTablePaginationSelectRoot = "shipping-information-dialog_MuiTablePagination-selectRoot__p+Oz-";
export var muiTablePaginationSelect = "shipping-information-dialog_MuiTablePagination-select__UmWB6";
export var muiStepperRoot = "shipping-information-dialog_MuiStepper-root__pYvcA";
export var muiStepperHorizontal = "shipping-information-dialog_MuiStepper-horizontal__Lazd8";
export var muiStepIconRoot = "shipping-information-dialog_MuiStepIcon-root__MXyoN";
export var muiStepIconActive = "shipping-information-dialog_MuiStepIcon-active__L6g07";
export var muiStepIconCompleted = "shipping-information-dialog_MuiStepIcon-completed__aqpQ5";
export var muiListItemRoot = "shipping-information-dialog_MuiListItem-root__E8JTY";
export var muiSelected = "shipping-information-dialog_Mui-selected__PciYC";
export var xtPageTitle = "shipping-information-dialog_xt-page-title__L6Gl2";
export var xtSubPageTitle = "shipping-information-dialog_xt-subPage-title__OXLDs";
export var xtSectionBorder = "shipping-information-dialog_xt-section-border__Dov9H";
export var xtPageHeader = "shipping-information-dialog_xt-page-header__CjIna";
export var xtDialogScrollable = "shipping-information-dialog_xt-dialog-scrollable__hzw4a";
export var reactToastNotificationsContainer = "shipping-information-dialog_react-toast-notifications__container__QzeTL";
export var xtScrollbarStyle = "shipping-information-dialog_xt-scrollbar-style__AeYrO";
export var xtModalDetailsContent = "shipping-information-dialog_xt-modal-details-content__pw-qr";
export var xtDialogDetailsContent = "shipping-information-dialog_xt-dialog-details-content__Wjcrd";
export var xtLazyModuleLoaderTabContainer = "shipping-information-dialog_xt-lazy-module-loader-tab-container__cDwbD";
export var xtTextTruncated = "shipping-information-dialog_xt-text-truncated__Z0nyF";
export var informationFields = "shipping-information-dialog_informationFields__GOvcP";
export var xtSummaryContent = "shipping-information-dialog_xt-summary-content__zfCAp";
export var xtSummaryLabel = "shipping-information-dialog_xt-summary-label__754yK";
export var visibleHidden = "shipping-information-dialog_visible-hidden__Z5wt6";
export var headerBottomMargin = "shipping-information-dialog_header-bottom-margin__uP+1s";
export var xtDialogHeaderLogoAlign = "shipping-information-dialog_xt-dialog-header-logoAlign__KyglO";
export var xtStickyHeader = "shipping-information-dialog_xt-sticky-header__VoY4g";
export var noBorder = "shipping-information-dialog_no-border__B2gQ2";
export var muiOutlinedInputNotchedOutline = "shipping-information-dialog_MuiOutlinedInput-notchedOutline__k8tQJ";
export var shippingInformationDialogContainer = "shipping-information-dialog_shippingInformationDialogContainer__Y3iL0";
export var shippingInformationDialogFormContainer = "shipping-information-dialog_shippingInformationDialogFormContainer__bB-td";
export var shippingInformationHeader = "shipping-information-dialog_shippingInformationHeader__mP4Q9";
export var controlBlock = "shipping-information-dialog_controlBlock__BxUqH";
export var formContainer = "shipping-information-dialog_formContainer__qhIWf";
export var separateLine = "shipping-information-dialog_separateLine__ffCd7";