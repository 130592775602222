import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useXtForm } from 'common/hooks/form/form'
import { FormField } from 'common/utils/form/form.components'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { IServerError } from 'core/core.types'
import { useCoreModule } from 'core/core-module-hook'
import { ILoginForm, LoginFormField } from './login.types'
import { loginValidation } from './login.validation'
import { defaultLoginValues } from './login.constants'
import * as styles from '../auth.module.scss'
import { AuthPageContainer } from '../auth-page-container'
import { resetPasswordRoutePath } from '../auth.constants'
import { useAuthModule } from '../auth-module-hook'

export const Login: FC<VoidFunction> = () => {
  const { AuthService } = useAuthModule()
  const { ErrorHandler } = useCoreModule()

  const history = useHistory()
  const [isLoading, setLoading] = useState<boolean>(false)

  const { control, handleSubmit } = useXtForm<ILoginForm>({
    defaultValues: defaultLoginValues,
    mode: 'onBlur',
    validationSchema: loginValidation,
  })

  // TODO handle it inside the Error Handler?
  const handleLoginError: (e: IServerError) => void = (e) => {
    let errorMessage: string = e.message
    if (e?.request?.status === 0) {
      errorMessage = 'Network error. Please try again later.'
    }
    if (e?.request?.status === 401 || e?.request?.status === 422) {
      errorMessage = e?.response?.data?.status?.message
    }
    ErrorHandler.handleError(new Error(errorMessage))
  }

  const onSubmit: (data: ILoginForm) => Promise<void> = async ({ email, password }) => {
    try {
      setLoading(true)
      await AuthService.login(email, password)
      setLoading(false)
      history.push('/')
    } catch (e) {
      setLoading(false)
      handleLoginError(e)
    }
  }
  const openResetPasswordScreen: VoidFunction = () => history.push(resetPasswordRoutePath)

  return (
    <AuthPageContainer>
      <form className={styles.login} onSubmit={handleSubmit(onSubmit)}>
        <FormField name={LoginFormField.Email} control={control} label="Email Address" disabled={isLoading} className={styles.authFields} />
        <FormField
          name={LoginFormField.Password}
          control={control}
          label="Password"
          type="password"
          disabled={isLoading}
          className={styles.authFields}
          disableTitle
        />
        <XtButton label="Login" type="submit" loading={isLoading} />
        <XtButton label="Reset Password" onClick={openResetPasswordScreen} disabled={isLoading} />
      </form>
    </AuthPageContainer>
  )
}
