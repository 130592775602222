import * as yup from 'yup'
import { ref } from 'yup'
import { validationMessage } from 'common/validation/validation'
import { PrintSharedFormField, PrintSharedFormLabel, ShippingPrintFormSharedField } from './print-dialogs.types'

const moreThanValue: number = 0
const lessThanValue = 10000

export const printDialogsValidationSchema = yup.object().shape(
  {
    [PrintSharedFormField.Number]: yup.object().nullable().required(validationMessage.isRequired),
    [PrintSharedFormField.ReportName]: yup.mixed().required(validationMessage.isRequired),
    [PrintSharedFormField.From]: yup
      .number()
      .required(validationMessage.isRequired)
      .typeError(validationMessage.isRequired)
      .moreThan(moreThanValue, validationMessage.min(moreThanValue))
      .max(ref(PrintSharedFormField.To), validationMessage.cannotBeGreaterThan(PrintSharedFormLabel.To)),
    [PrintSharedFormField.To]: yup
      .number()
      .required(validationMessage.isRequired)
      .typeError(validationMessage.isRequired)
      .min(ref(PrintSharedFormField.From), validationMessage.cannotBeLessThan(PrintSharedFormLabel.From))
      .lessThan(lessThanValue, validationMessage.max(lessThanValue)),
  },
  [[PrintSharedFormField.From, PrintSharedFormField.To]]
)

export const shippingPrintDialogsSharedValidationSchema = {
  [ShippingPrintFormSharedField.Order]: yup.object().nullable().required(validationMessage.isRequired),
  [ShippingPrintFormSharedField.ShipmentNumber]: yup.object().nullable().required(validationMessage.isRequired),
}
