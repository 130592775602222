import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import React, { FC, useEffect, useRef, useState } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { cls } from 'common/utils/utils'
import { globalConstants } from 'common/constants'
import { isValidDate } from 'common/utils/date.utils'
import { IDatePicker } from './date-picker.types'
import { resolveLabel } from './date-picker.utils'

export const XtDatePicker: FC<IDatePicker> = ({
  hidden,
  className,
  format = globalConstants.dateFormat,
  onBlur,
  label,
  value,
  onChange,
  disabled,
  invalidOrNullableLabel,
  error,
  maxDate,
  minDate,
}) => {
  const [inputValue, setInputValue] = useState<string | undefined>(() => resolveLabel(value, format, invalidOrNullableLabel))
  const invalidDateRef = useRef<boolean>(false)

  useEffect(() => {
    setInputValue(resolveLabel(value, format, invalidOrNullableLabel))
  }, [format, invalidOrNullableLabel, value])

  const handleBlur: VoidFunction = () => {
    if (invalidOrNullableLabel && invalidDateRef.current) {
      onChange(null)
      invalidDateRef.current = false
    }
    if (typeof onBlur === 'function') {
      onBlur()
    }
  }

  const handleChange: (date: MaterialUiPickersDate | null, stringDate?: string | null) => void = (date, stringDate) => {
    if (date) {
      invalidDateRef.current = !isValidDate(date)
    }
    onChange(date, stringDate)
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        hidden={hidden}
        className={cls('MuiFormField MuiFormFieldPicker', className)}
        inputVariant="outlined"
        format={format}
        variant="inline"
        autoOk
        onBlur={handleBlur}
        label={label}
        value={value}
        onClose={handleBlur}
        onChange={handleChange}
        disabled={disabled}
        error={!!error}
        helperText={error}
        maxDate={maxDate}
        minDate={minDate}
        inputValue={inputValue}
        initialFocusedDate={minDate}
      />
    </MuiPickersUtilsProvider>
  )
}
