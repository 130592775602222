import { PermissionGuard } from '../../auth/permission.guard'
import { UserPermission } from '../../auth/auth.types'
import { setupRoutePath } from '../setup.constants'

export class SalesGuard extends PermissionGuard {
  public redirectTo = setupRoutePath
  public permissions = [
    UserPermission.ConfigureSO,
    UserPermission.MaintainCustomerTypes,
    UserPermission.ViewCustomerTypes,
    UserPermission.MaintainSaleTypes,
    UserPermission.ViewSaleTypes,
  ]
}
