import { useCallback, useState } from 'react'

import { defineDefaultState } from './prospects-detais.hook.utils'
import { IProspectDialogHookState, IProspectDialogState } from './prospects-detais.hook.types'

export function useProspectDialogState(): IProspectDialogHookState {
  const [state, setState] = useState<IProspectDialogState>(defineDefaultState)

  const onCloseCustomerDialog = useCallback(() => setState(defineDefaultState), [])

  const onOpenCustomerDialog = useCallback(
    (prospectDialogNumber, prospectDialogMode) => setState({ isOpenProspectDialog: true, prospectDialogMode, prospectDialogNumber }),
    []
  )

  return {
    ...state,
    onCloseProspectDialog: onCloseCustomerDialog,
    onOpenProspectDialog: onOpenCustomerDialog,
  }
}
