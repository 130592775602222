import * as yup from 'yup'
import { DateSchema } from 'yup'
import { validationMessage } from 'common/validation/validation'
import { dateYupValidator, isValidDate } from 'common/utils/date.utils'
import { OpportunityFormField, OpportunityFormLabel } from '../opportunity-details.types'

export const GeneralFormSchema = yup.object().shape(
  {
    [OpportunityFormField.Name]: yup.string().required(validationMessage.isRequired),
    [OpportunityFormField.Stage]: yup.mixed().required(validationMessage.isRequired),
    [OpportunityFormField.OppType]: yup.mixed().required(validationMessage.isRequired),
    [OpportunityFormField.Account]: yup.mixed().required(validationMessage.isRequired),
    [OpportunityFormField.Currency]: yup.mixed().required(validationMessage.isRequired),
    [OpportunityFormField.LeadSource]: yup.mixed().required(validationMessage.isRequired),
    [OpportunityFormField.StartDate]: dateYupValidator.notRequired(),
    [OpportunityFormField.AssigneeDate]: dateYupValidator
      .notRequired()
      .when(OpportunityFormField.StartDate, (startDate: Date | null, schema: DateSchema) =>
        startDate && isValidDate(startDate) ? schema.min(startDate, validationMessage.minDate(OpportunityFormLabel.StartDate)) : schema
      ),
    [OpportunityFormField.TargetDate]: dateYupValidator
      .notRequired()
      .when(OpportunityFormField.StartDate, (startDate: Date | null, schema: DateSchema) =>
        startDate && isValidDate(startDate) ? schema.min(startDate, validationMessage.minDate(OpportunityFormLabel.StartDate)) : schema
      ),
    [OpportunityFormField.ActualDate]: dateYupValidator
      .notRequired()
      .when(OpportunityFormField.StartDate, (startDate: Date | null, schema: DateSchema) =>
        startDate && isValidDate(startDate) ? schema.min(startDate, validationMessage.minDate(OpportunityFormLabel.StartDate)) : schema
      ),
  },
  [
    [OpportunityFormField.StartDate, OpportunityFormField.AssigneeDate],
    [OpportunityFormField.StartDate, OpportunityFormField.TargetDate],
    [OpportunityFormField.StartDate, OpportunityFormField.ActualDate],
  ]
)
