import * as React from 'react'
import { BaseSyntheticEvent, FC } from 'react'
import { SvgIconIds } from '../../svg-icon/svg-icon.types'
import { XtTableCellButton } from './table-cell-button/table-cell-button'

export interface IXtTableCellAction {
  name: string
  label: string
  icon: SvgIconIds
}

export interface IXtTableCellActionsParams {
  onClick: (action: string) => void
  actions: IXtTableCellAction[]
  className?: string
}

const minActionsWidth: number = 50
const additionalActionWidth: number = 45

/**
 * Calculates the required width depends on actions length. This method is supposed to be used in table components.
 * @param actions - number of actions to display.
 */
export function calculateActionsWidth(actions: number): number {
  return actions > 0 ? minActionsWidth + additionalActionWidth * actions : 0
}

export const XtTableCellActions: FC<IXtTableCellActionsParams> = ({ onClick, actions, className }) => {
  function onEventClick(event: BaseSyntheticEvent, action: string): void {
    event.stopPropagation()
    onClick(action)
  }

  return (
    <div className={className}>
      {actions.map((action) => (
        <XtTableCellButton
          key={action.name}
          onClick={(event) => onEventClick(event, action.name)}
          title={action.label}
          icon={action.icon}
        />
      ))}
    </div>
  )
}
