import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'

import { CoreModule } from 'core/core.module'

import { ProductsModule } from 'products/products.module'

import { runningAvailabilityModule, runningAvailabilityUtilsServiceToken } from './running-availability.constants'

import { IItemsService } from '../products/items/items.service'
import { itemSitesServiceToken, itemsServiceToken } from '../products/products.constants'
import { IItemSitesService } from '../products/items/item-sites.service'
import { IRunningAvailabilityUtilsService } from './running-availability-utils.service'

export type RunningAvailabilityModuleDefinition = typeof import('./running-availability.exports')

export interface IRunningAvailabilityModuleServices {
  RunningAvailabilityUtilsService: IRunningAvailabilityUtilsService
}

export const RunningAvailabilityModule: ILazyModule<RunningAvailabilityModuleDefinition> = {
  name: runningAvailabilityModule,
  resolver: () => import('./running-availability.exports'),
  initializer: ({ RunningAvailabilityUtilsService }) => {
    Injector.register<IRunningAvailabilityUtilsService>(
      runningAvailabilityUtilsServiceToken,
      (injector) => {
        const itemService = injector.resolve<IItemsService>(itemsServiceToken)
        const itemSiteService = injector.resolve<IItemSitesService>(itemSitesServiceToken)
        return new RunningAvailabilityUtilsService(itemService, itemSiteService)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, ProductsModule],
}
