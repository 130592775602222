import React from 'react'
import { StepIconProps } from '@material-ui/core'
import { CheckCircle, RadioButtonChecked } from '@material-ui/icons'
import * as styles from './progress-stepper.module.scss'

export function StepperIcon(props: StepIconProps) {
  const { completed, active } = props
  return completed ? (
    <CheckCircle className={styles.checkCircle} />
  ) : (
    <RadioButtonChecked className={active ? styles.radioButtonActive : styles.radioButtonDisable} />
  )
}
