import React from 'react'
import { OutlinedInputProps, TextField } from '@material-ui/core'
import * as styles from './xt-text-area.module.scss'
import { cls } from '../../../common/utils/utils'

export interface IXtTextArea {
  hidden?: boolean
  onChange(value: string): void
  value?: string
  placeholder?: string
  disabled?: boolean
  rows?: number
  className?: string
  error?: string
  onBlur?: VoidFunction
  inputProps?: OutlinedInputProps['inputProps']
  title?: string
  label?: string
}

export function XtTextArea({
  value,
  rows = 11,
  onChange,
  placeholder,
  disabled = false,
  className,
  onBlur,
  error,
  hidden,
  inputProps,
  title,
  label,
}: IXtTextArea): React.ReactElement {
  return (
    <TextField
      hidden={hidden}
      inputProps={inputProps}
      title={title}
      label={label}
      multiline
      variant="outlined"
      rows={rows}
      error={!!error}
      helperText={error}
      className={cls(styles.textArea, className)}
      placeholder={placeholder}
      disabled={disabled}
      value={value ?? ''}
      onChange={(e) => onChange(e.target.value)}
      onBlur={onBlur}
    />
  )
}
