import { IRouteGuard } from '../common/routing/routing.types'
import { UserPermission } from './auth.types'
import { IPermissionsService } from './permissions.service'
import { Injector } from '../core/injector/injector.service'
import { permissionsServiceToken } from './auth.constants'

export abstract class PermissionGuard implements IRouteGuard {
  abstract permissions: UserPermission[]
  abstract redirectTo: string
  private readonly permissionsService: IPermissionsService = Injector.resolve<IPermissionsService>(permissionsServiceToken)

  // TODO we may need to check that the user has all of the permissions, not just one of them
  public validate(): boolean {
    return this.permissionsService.hasSomePermission(this.permissions)
  }
}
