// extracted by mini-css-extract-plugin
export var bomItemCounterSection = "bom-item-counter_bomItemCounterSection__-Vncn";
export var bomItemCounterLabel = "bom-item-counter_bomItemCounterLabel__O+tD9";
export var bomItemCounter = "bom-item-counter_bomItemCounter__QSUtm";
export var bomItemCounterError = "bom-item-counter_bomItemCounterError__4uSWe";
export var bomItemCounterInput = "bom-item-counter_bomItemCounterInput__pkr0M";
export var bomItemCounterButtonsSection = "bom-item-counter_bomItemCounterButtonsSection__EjSEE";
export var bomItemCounterButton = "bom-item-counter_bomItemCounterButton__Qa9a4";
export var bomItemCounterButtonIcon = "bom-item-counter_bomItemCounterButtonIcon__0vWW+";
export var bomItemCounterButtonIconInverted = "bom-item-counter_bomItemCounterButtonIconInverted__klLJg";
export var xtSeqNumberInvalidIcon = "bom-item-counter_xtSeqNumberInvalidIcon__bsAZD";
export var bomItemCounterPopUpContent = "bom-item-counter_bomItemCounterPopUpContent__gmX8w";