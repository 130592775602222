import { UsedOnValue } from 'documents/documents.types'
import { IXtAutocompleteOption } from '../components/controls/xt-autocomplete/xt-autocomplete.types'

export type CharacteristicValue = Date | string | number | string[] | null | IXtAutocompleteOption

export interface IAppliedCharacteristic {
  id: number
  characteristic: string
  characteristic_value: CharacteristicValue
  characteristic_group: string | null
  default_characteristic: boolean
}

export interface IAppliedCharacteristicNew extends Omit<IAppliedCharacteristic, 'id'> {}
/**
 * This interface described a characteristic not applied to any object.
 *
 * Based on the API /characteristics/{characteristic}
 */
export interface ICharacteristic {
  name: string
  description: string
  characteristic_type: CharacteristicType
  characteristic_group: string | null
  mask: string | null
  characteristic_validator: string | null
  characteristic_order: number
  searchable: boolean
  unique_assignment: boolean
  list_options: ICharacteristicListOption[]
  used_on: UsedOnValue[]
}

// We keep CharacteristicPayload as a separate type in case of further updates in the characteristic payload type
export type CharacteristicPayload = ICharacteristic

export interface ICharacteristicWithId extends ICharacteristic {
  id: string
}

// TODO: not specified in API, make sure the values are correct
export enum CharacteristicType {
  Text = 'text',
  List = 'list',
  Date = 'date',
  Number = 'number',
}

export interface ICharacteristicListOption {
  option_value: string
  option_order: number
}

export type CharacteristicOption = ICharacteristic & IXtAutocompleteOption
