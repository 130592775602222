import * as yup from 'yup'
import { DateSchema } from 'yup'
import { validationMessage } from 'common/validation/validation'
import { dateYupValidator, isValidDate } from 'common/utils/date.utils'
import { InventoryHistoryByLotSerialField, InventoryHistoryByLotSerialLabel } from '../../inventory-history-by-lot-serial-list.types'

export const historyByLotSerialValidationScheme = yup.object().shape(
  {
    [InventoryHistoryByLotSerialField.StartDate]: dateYupValidator.when(
      InventoryHistoryByLotSerialField.EndDate,
      (endDate: Date | null, schema: DateSchema) =>
        endDate && isValidDate(endDate) ? schema.max(endDate, validationMessage.maxDate(InventoryHistoryByLotSerialLabel.EndDate)) : schema
    ),
    [InventoryHistoryByLotSerialField.EndDate]: dateYupValidator.when(
      InventoryHistoryByLotSerialField.StartDate,
      (startDate: Date | null, schema: DateSchema) =>
        startDate && isValidDate(startDate)
          ? schema.min(startDate, validationMessage.minDate(InventoryHistoryByLotSerialLabel.StartDate))
          : schema
    ),
  },
  [[InventoryHistoryByLotSerialField.StartDate, InventoryHistoryByLotSerialField.EndDate]]
)
