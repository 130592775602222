import { Control } from 'react-hook-form'
import { UsedOnValue } from 'documents/documents.types'
import {
  CharacteristicType,
  ICharacteristicListOption,
  IAppliedCharacteristicNew,
  CharacteristicValue,
  CharacteristicOption,
  ICharacteristic,
} from '../characteristics.types'

export enum CharacteristicDialogFormLabel {
  Characteristic = 'Characteristic',
  Value = 'Value',
}

export interface ICharacteristicDialog {
  onClose(): void
  onConfirm(formData: IAppliedCharacteristicNew): void
  usedOnFilter: UsedOnValue
  characteristic: ICharacteristic | null
  value: CharacteristicValue
}

export interface ICharacteristicDialogValue {
  type?: CharacteristicType
  control: Control<ICharacteristicFormState>
  options: ICharacteristicListOption[]
  mask?: string | null
  validator: string | null
}

export enum CharacteristicDialogFormField {
  Characteristic = 'characteristic',
  Value = 'characteristic_value',
}

export interface ICharacteristicFormState {
  [CharacteristicDialogFormField.Characteristic]: CharacteristicOption | null
  [CharacteristicDialogFormField.Value]: CharacteristicValue
}
