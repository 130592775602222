import { Overwrite } from 'utility-types'
import { SingleFormRegister } from 'common/hooks/form/multiple-form-manager/multiple-form-manager.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { XtMode } from 'common/common.types'
import { CostingMethodOption, IItemSite, IItemSiteCostCategory } from 'products/items/items.types'
import { sitesDetailsDialogInventoryFormKey } from './sites-details-dialog-inventory.consts'

export interface ISitesDetailsDialogInventory {
  mode: XtMode
  register: SingleFormRegister<typeof sitesDetailsDialogInventoryFormKey, SitesDetailsDialogInventoryForm>
  itemSite: IItemSite | null
  fractional: boolean
}

export enum SitesDetailsDialogInventoryFormField {
  ControlMethod = 'control_method',
  PlannerCode = 'planner_code',
  CostCategoryCode = 'cost_category_code',
  CostCategory = 'cost_category',
  PreventNegativeInventory = 'prevent_negative_inventory',
  CostingMethod = 'cost_method',
  OverrideWorkOrderItemCostRecognitionDetailsEnabled = 'override_wo_cost_recognition_enabled',
  OverrideWorkOrderItemCostRecognitionDetails = 'override_wo_cost_recognition',
  CalculateInventoryBuffer = 'stocked',
  AllowAutomaticABCUpdated = 'allow_automatic_updates',
  AbcClass = 'abc_class',
  CyclCntFreq = 'cycl_count_freq',
  EventFence = 'event_fence',
  AutomaticLotSerialNumbering = 'auto_ls_number',
}

export enum SitesDetailsDialogInventoryFormFieldLabel {
  ControlMethod = 'Control Method',
  PlannerCode = 'Planner Code',
  CostCategoryCode = 'Cost Category',
  CostCategory = 'Cost Category',
  NegativeInventoryPrevented = 'Neg. Inv. Prevented by Job Costing',
  PreventNegativeInventory = 'Prevent Negative Qty. Inventory',
  CostingMethod = 'Costing Method',
  OverrideWorkOrderItemCostRecognitionDetailsEnabled = 'Override Work Order Item Cost Recognition Details',
  CalculateInventoryBuffer = 'Calculate Inventory Buffer',
  AllowAutomaticABCUpdated = 'Allow Automatic ABC Updated',
  AbcClass = 'ABC class',
  CyclCntFreq = 'Cycl. Cnt. Freq',
  EventFence = 'Event Fence',
  AutomaticLotSerialNumbering = 'Automatic Lot/Serial Numbering',
}

export type ItemSiteInventory = Pick<
  IItemSite,
  | SitesDetailsDialogInventoryFormField.ControlMethod
  | SitesDetailsDialogInventoryFormField.PlannerCode
  | SitesDetailsDialogInventoryFormField.CostCategoryCode
  | SitesDetailsDialogInventoryFormField.CostCategory
  | SitesDetailsDialogInventoryFormField.PreventNegativeInventory
  | SitesDetailsDialogInventoryFormField.CostingMethod
  | SitesDetailsDialogInventoryFormField.OverrideWorkOrderItemCostRecognitionDetails
  | SitesDetailsDialogInventoryFormField.CalculateInventoryBuffer
  | SitesDetailsDialogInventoryFormField.AllowAutomaticABCUpdated
  | SitesDetailsDialogInventoryFormField.AbcClass
  | SitesDetailsDialogInventoryFormField.CyclCntFreq
  | SitesDetailsDialogInventoryFormField.EventFence
  | SitesDetailsDialogInventoryFormField.AutomaticLotSerialNumbering
>

export type SitesDetailsDialogInventoryForm = Overwrite<
  ItemSiteInventory,
  {
    [SitesDetailsDialogInventoryFormField.ControlMethod]: IXtAutocompleteOption | null
    [SitesDetailsDialogInventoryFormField.PlannerCode]: IXtAutocompleteOption | null
    [SitesDetailsDialogInventoryFormField.CostCategoryCode]: IXtAutocompleteOption | null
    [SitesDetailsDialogInventoryFormField.CostCategory]: IItemSiteCostCategory | null
    [SitesDetailsDialogInventoryFormField.PreventNegativeInventory]: boolean
    [SitesDetailsDialogInventoryFormField.CostingMethod]: CostingMethodOption | null
    [SitesDetailsDialogInventoryFormField.OverrideWorkOrderItemCostRecognitionDetails]: ItemCostRecognition | null
    [SitesDetailsDialogInventoryFormField.CalculateInventoryBuffer]: boolean
    [SitesDetailsDialogInventoryFormField.AllowAutomaticABCUpdated]: boolean
    [SitesDetailsDialogInventoryFormField.AbcClass]: IXtAutocompleteOption | null
    [SitesDetailsDialogInventoryFormField.CyclCntFreq]: number | null
    [SitesDetailsDialogInventoryFormField.EventFence]: number | null
    [SitesDetailsDialogInventoryFormField.AutomaticLotSerialNumbering]: IXtAutocompleteOption | null
  }
> & {
  [SitesDetailsDialogInventoryFormField.OverrideWorkOrderItemCostRecognitionDetailsEnabled]: boolean
}

export enum ItemCostRecognition {
  ToDate = 'to_date',
  Proportional = 'proportional',
}

export enum ItemCostRecognitionLabel {
  ToDate = 'To date',
  Proportional = 'Proportional',
}

export enum AbcClassOptions {
  A = 'A',
  B = 'B',
  C = 'C',
}
