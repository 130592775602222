import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { cls } from 'common/utils/utils'
import { homeRoutePath } from 'home/home.constants'
import logo from 'assets/img/x.png'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import * as styles from './sidebar-panel-static.module.scss'
import * as mainStyles from '../sidebar.module.scss'
import { IXtSidebarStaticParams } from '../sidebar.types'

export const XtSidebarStatic: FC<IXtSidebarStaticParams> = ({ sidebarOptions, onOptionClick, onProfileKeyDown, toggleProfile }) => (
  <div className={cls(mainStyles.sidebar)}>
    <div className={mainStyles.sidebarLogo}>
      <NavLink to={homeRoutePath}>
        <img className={mainStyles.sidebarLogoImage} src={logo} alt="xTuple Logo" />
      </NavLink>
    </div>
    <div className={mainStyles.sidebarContent}>
      <ul className={styles.sidebarOptions}>
        {sidebarOptions
          .filter(({ disabled }) => !disabled)
          .map(({ path, name, icon, exact }) => (
            <li key={path}>
              <NavLink
                onClick={onOptionClick}
                to={path}
                exact={exact}
                className={mainStyles.sidebarOptionLink}
                activeClassName={mainStyles.sidebarOptionActive}
                title={name}
              >
                <div className={mainStyles.sidebarOption}>
                  <SvgIcon className={mainStyles.sidebarOptionIcon} iconId={icon} />
                  <p className={mainStyles.sidebarOptionLabel}>{name}</p>
                </div>
              </NavLink>
            </li>
          ))}
        <div
          className={cls(mainStyles.sidebarOption, mainStyles.sidebarOptionLink, mainStyles.profileButton)}
          onClick={toggleProfile}
          tabIndex={0}
          role="button"
          onKeyDown={onProfileKeyDown}
        >
          <SvgIcon className={mainStyles.sidebarOptionIcon} iconId={SvgIconIds.USER_ICON} />
          <p className={mainStyles.sidebarOptionLabel}>Profile</p>
        </div>
      </ul>
    </div>
  </div>
)
