import { Overwrite } from 'utility-types'
import { LeadsModule, LeadsModuleDefinition, ILeadsModuleServices } from './leads.module'
import { LazyModulesService } from '../core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from '../core/injector/injector.service'
import { ILeadService } from './leads.service'
import { ILeadsUtilsService } from './leads-utils.service'
import { leadsServiceToken, leadsUtilsServiceToken } from './leads.constants'
import { moduleHookResolver } from '../core/react-lazy-modules/react-lazy-modules.utils'

function resolveLeadsModule(): Overwrite<LeadsModuleDefinition, ILeadsModuleServices> {
  const module = LazyModulesService.resolveModule<LeadsModuleDefinition>(LeadsModule)
  const LeadsService = Injector.resolve<ILeadService>(leadsServiceToken)
  const LeadsUtilsService = Injector.resolve<ILeadsUtilsService>(leadsUtilsServiceToken)

  return { ...module, LeadsService, LeadsUtilsService }
}

export const useLeadsModule = moduleHookResolver(resolveLeadsModule)
