// extracted by mini-css-extract-plugin
export var root = "no-mobile_root__ddaNl";
export var xtContent = "no-mobile_xt-content__DpH1s";
export var xtContentWithRemarks = "no-mobile_xt-content-with-remarks__D1iij";
export var muiFormHelperTextRoot = "no-mobile_MuiFormHelperText-root__rYjbF";
export var xtListPageContainer = "no-mobile_xt-list-page-container__l9rQ4";
export var muiInputBaseRoot = "no-mobile_MuiInputBase-root__QXggM";
export var muiTextFieldRoot = "no-mobile_MuiTextField-root__3DT0L";
export var muiTablePaginationRoot = "no-mobile_MuiTablePagination-root__s8yZ0";
export var muiTablePaginationSelectRoot = "no-mobile_MuiTablePagination-selectRoot__RE5Hb";
export var muiTablePaginationSelect = "no-mobile_MuiTablePagination-select__pdmf+";
export var muiStepperRoot = "no-mobile_MuiStepper-root__w3+w+";
export var muiStepperHorizontal = "no-mobile_MuiStepper-horizontal__9FPja";
export var muiStepIconRoot = "no-mobile_MuiStepIcon-root__23TA8";
export var muiStepIconActive = "no-mobile_MuiStepIcon-active__8ICGU";
export var muiStepIconCompleted = "no-mobile_MuiStepIcon-completed__0nkJ7";
export var muiListItemRoot = "no-mobile_MuiListItem-root__yihBq";
export var muiSelected = "no-mobile_Mui-selected__2CWFF";
export var xtPageTitle = "no-mobile_xt-page-title__CSpFV";
export var xtSubPageTitle = "no-mobile_xt-subPage-title__snue2";
export var xtSectionBorder = "no-mobile_xt-section-border__4N8SX";
export var xtPageHeader = "no-mobile_xt-page-header__XdjSL";
export var xtDialogScrollable = "no-mobile_xt-dialog-scrollable__5x1mA";
export var reactToastNotificationsContainer = "no-mobile_react-toast-notifications__container__F0MLe";
export var xtScrollbarStyle = "no-mobile_xt-scrollbar-style__gskjX";
export var xtModalDetailsContent = "no-mobile_xt-modal-details-content__X06HD";
export var xtDialogDetailsContent = "no-mobile_xt-dialog-details-content__iSJx7";
export var xtLazyModuleLoaderTabContainer = "no-mobile_xt-lazy-module-loader-tab-container__atQ0H";
export var xtTextTruncated = "no-mobile_xt-text-truncated__g+Pfn";
export var xtSummaryContent = "no-mobile_xt-summary-content__abaEC";
export var xtSummaryLabel = "no-mobile_xt-summary-label__9GXDh";
export var visibleHidden = "no-mobile_visible-hidden__nVbq8";
export var headerBottomMargin = "no-mobile_header-bottom-margin__zkhZP";
export var xtDialogHeaderLogoAlign = "no-mobile_xt-dialog-header-logoAlign__HZAdO";
export var xtStickyHeader = "no-mobile_xt-sticky-header__22Ldd";
export var noBorder = "no-mobile_no-border__FLDFM";
export var muiOutlinedInputNotchedOutline = "no-mobile_MuiOutlinedInput-notchedOutline__cKq-8";
export var noMobileContainer = "no-mobile_noMobileContainer__MoQxG";
export var noMobileLogo = "no-mobile_noMobileLogo__nzczM";
export var noMobileText = "no-mobile_noMobileText__-0tH-";