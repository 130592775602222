import { IPaginationData, IPaginationParams, IPaginationResponse } from 'common/common.types'
import { isEmptyResponse } from 'core/core.utils'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IHttpClient } from './http-client'

export interface IStandardOperationFilters {
  showInactive?: boolean
}

export interface IStandardOperation {
  active: boolean
  instructions: string
  inventory_production_ratio: number
  number: string
  operation_description1: string
  operation_description2: string
  operation_type: string
  production_uom: string
  qty_per: number
  report_run: boolean
  report_run_as: string
  report_setup: boolean
  report_setup_as: string
  run_time: number
  setup_time: number
  tooling_reference: string
  use_standard_times: boolean
  work_center: string
}

export interface IStandardOperationService {
  getAll(params: IPaginationParams, filters?: IStandardOperationFilters): Promise<IPaginationData<IStandardOperation>>
  get(option: string): Promise<IStandardOperation>
}

export class StandardOperationService implements IStandardOperationService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getAll(
    paginationParams: IPaginationParams,
    filters?: IStandardOperationFilters
  ): Promise<IPaginationData<IStandardOperation>> {
    const params = prepareRequestParams(paginationParams, filters)
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IStandardOperation>>('/stdoperation', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(option: string): Promise<IStandardOperation> {
    const { data } = await this.apiClient.get(`/stdoperation/${option}`)

    if (isEmptyResponse(data)) {
      throw new Error(`Standard operation ${option} is not found.`)
    }

    return data.data
  }
}
