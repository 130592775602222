import React, { FC, useEffect, useState } from 'react'

import * as yup from 'yup'
import { FormCheckboxLabel, FormField, FormSelectField, FormXtAutocomplete } from 'common/utils/form/form.components'

import { useDocumentsModule } from 'documents/documents-module-hook'
import { useXtForm } from 'common/hooks/form/form'
import { useDictionaryModule } from 'dictionary/dictionary-module-hook'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { maxNumber, validationMessage } from 'common/validation/validation'
import { defineSettingsData, requestDefaultValues } from './settings-tabs.utils'
import { defaultSettingsOptions, settingsFormKey } from './settings-tab.constants'
import {
  ISettingsTermsDefaultValues,
  ISettingTab,
  ISettingTermsForm,
  SettingsTermsFormField,
  SettingsTermsFormFieldLabel,
} from './setting-tab.types'
import * as styles from './settings-tab.module.scss'

export const SettingTab: FC<ISettingTab> = ({ register, customer, disabled = false }) => {
  // const { PermissionsService } = useAuthModule()
  const { DocumentsUtilsService } = useDocumentsModule()
  const { DictionaryUtilsService } = useDictionaryModule()
  const { control, trigger, formChanges$, getValues, reset, formState, setValue } = useXtForm<ISettingTermsForm>({
    defaultValues: defineSettingsData(null),
    mode: 'onBlur',
    validationSchema: yup.object().shape({
      [SettingsTermsFormField.Terms]: yup.mixed().nullable().required(validationMessage.isRequired),
    }),
  })

  const [defaultOptions, setDefaultOptions] = useState<ISettingsTermsDefaultValues>(defaultSettingsOptions)
  const [disableBlanketPos, setDisableBlanketPos] = useState(true)
  const init: () => Promise<void> = async () => {
    // register(quoteDetailsShippingAndSalesFormKey, formChanges$, trigger, { data: getValues(), state: formState })
    const options = await requestDefaultValues(DocumentsUtilsService)
    setDefaultOptions(options)
  }

  useEffect(() => {
    register(settingsFormKey, formChanges$, trigger, { data: getValues(), state: formState }, reset)
  }, [register, formChanges$])
  useEffect(() => {
    reset(defineSettingsData(customer))
  }, [customer])
  useEffect(() => {
    void init()
  }, [])

  const handleBlanketPos = (value: boolean): void => {
    setValue(SettingsTermsFormField.UsesPurchaseOrders, value, { shouldDirty: true })
    if (value) {
      setDisableBlanketPos(false)
      return
    }
    setDisableBlanketPos(true)
  }

  return (
    <div>
      <div className={styles.quoteDetailsShippingAndSales}>
        {/* TODO: investigate what's the problem with FormSelectField title */}
        <div className={styles.quoteDetailsShippingAndSalesForm}>
          <FormSelectField
            required
            options={defaultOptions.options.terms_options}
            name={SettingsTermsFormField.Terms}
            control={control}
            label={SettingsTermsFormFieldLabel.Terms}
            clearable
            disabled={disabled}
          />
          <DecimalFormField
            name={SettingsTermsFormField.Discount}
            control={control}
            label={SettingsTermsFormFieldLabel.Discount}
            maxValue={maxNumber}
            allowNegative={false}
            disabled={disabled}
          />
          <FormXtAutocomplete
            loadOptions={DictionaryUtilsService.loadCurrencyOptions}
            name={SettingsTermsFormField.Currency}
            control={control}
            label={SettingsTermsFormFieldLabel.Currency}
            disabled={disabled}
          />
          <DecimalFormField
            name={SettingsTermsFormField.CreditLimit}
            control={control}
            label={SettingsTermsFormFieldLabel.CreditLimit}
            maxValue={maxNumber}
            allowNegative={false}
            disabled={disabled}
          />

          <FormXtAutocomplete
            loadOptions={DictionaryUtilsService.loadCurrencyOptions}
            name={SettingsTermsFormField.CreditLimitCurrency}
            control={control}
            label={SettingsTermsFormFieldLabel.CreditLimitCurrency}
            disabled={disabled}
          />
        </div>
        <div className={styles.quoteDetailsShippingAndSalesForm}>
          <FormCheckboxLabel
            control={control}
            name={SettingsTermsFormField.CreditStatusExceedWarn}
            label={SettingsTermsFormFieldLabel.CreditStatusExceedWarn}
            disabled={disabled}
          />
          <FormCheckboxLabel
            control={control}
            name={SettingsTermsFormField.CreditStatusExceedHold}
            label={SettingsTermsFormFieldLabel.CreditStatusExceedHold}
            disabled={disabled}
          />
          <FormSelectField
            options={[
              { id: 'In Good Standing', label: 'In Good Standing' },
              { id: 'On Credit Warning', label: 'On Credit Warning' },
              { id: 'On Credit Hold', label: 'On Credit Hold' },
            ]}
            name={SettingsTermsFormField.CreditStatus}
            control={control}
            label={SettingsTermsFormFieldLabel.CreditStatus}
            clearable
            disabled={disabled}
          />
          <FormCheckboxLabel
            control={control}
            name={SettingsTermsFormField.UsesPurchaseOrders}
            label={SettingsTermsFormFieldLabel.UsesPurchaseOrders}
            onChange={handleBlanketPos}
            disabled={disabled}
          />
          <FormCheckboxLabel
            control={control}
            name={SettingsTermsFormField.UsesBlanketPOs}
            label={SettingsTermsFormFieldLabel.UsesBlanketPOs}
            disabled={disabled || disableBlanketPos}
          />
          <FormField
            type="number"
            name={SettingsTermsFormField.AlternateGraceDays}
            control={control}
            label="Alternate Grace Days "
            disabled={disabled}
          />
          <FormCheckboxLabel
            control={control}
            name={SettingsTermsFormField.AllowFreeFormShipTo}
            label={SettingsTermsFormFieldLabel.AllowFreeFormShipTo}
            disabled={disabled}
          />
          <FormCheckboxLabel
            control={control}
            name={SettingsTermsFormField.AllowFreeFormBillTo}
            label={SettingsTermsFormFieldLabel.AllowFreeFormBillTo}
            disabled={disabled}
          />

          <FormField
            type="number"
            name={SettingsTermsFormField.AlternateGraceDays}
            control={control}
            label={SettingsTermsFormFieldLabel.AlternateGraceDays}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  )
}
