import React, { FC } from 'react'
import logo from 'assets/img/xT-Sales.png'
import * as styles from './header.module.scss'

export const XtSalesHeader: FC<{}> = () => {
  return (
    <div className={styles.headerContainer}>
      <img className={styles.headerLogo} src={logo} alt="xTuple Logo" />
    </div>
  )
}
