import React, { useEffect , FC, useCallback, useMemo } from 'react'
import { Prompt, useHistory, useParams } from 'react-router'
import { useMediaQuery } from '@material-ui/core'
import { confirmationMessages, xsMq } from 'common/constants'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { cls } from 'common/utils/utils'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { XtList } from 'components/list/list'
import { FormCheckboxLabel } from 'common/utils/form/form.components'
import { convertMode } from 'common/utils/mode.utils'
import { IXtMode, XtMode } from 'common/common.types'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { LoadingSpinnerDelayed } from 'components/loading-spinner-delayed'
import { useSharedModule } from 'shared/shared-module-hook'
import { productsRoutePath, productsRoutes } from 'products/products.constants'
import { useProductsModule } from 'products/products-module-hook'
import { IXtEntityDialogData } from 'core/core.types'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { RoutingItem } from '../routing-item/routing-item'
import * as styles from './routing-details.module.scss'
import { IRoutingDetailsTableItem, RoutingDetailsAction, RoutingDetailsFilter, RoutingDetailsFormField } from './routing-details.types'
import { getRoutingDetailsPageTitle, routingDetailsColumns } from './routing-details.constants'
import { defineAvailableActions, getRoutingDetailsActions } from './routing-details.utils'
import { RoutingDetailsForm } from './routing-details-form/routing-details-form'
import { useRoutingDetails } from './routing-details-hook/routing-details-hook'
import { IRoutingItem } from '../routing.types'

export const RoutingDetails: FC<IXtMode> = ({ mode }) => {
  const { useDialog } = useSharedModule()
  const { FormXtItemNumber, ItemsUtilsService } = useProductsModule()

  const { itemNumber } = useParams<{ itemNumber: string }>()
  useDocumentTitle(getRoutingDetailsPageTitle(itemNumber))
  const history = useHistory()
  const isMobile = useMediaQuery(xsMq)

  const {
    mode: currentMode,
    tableState: { state, sort, pagination },
    formMethods,
    routingState: { initializing, routing },
    handleItemNumberChange,
    expireRoutingItem,
    deleteRoutingItem,
    handleRoutingItemSubmit,
    onRoutingSubmit,
    onFiltersChange,
  } = useRoutingDetails(itemNumber, mode)

  const {
    data: routingItemDialogData,
    openDialog: openRoutingItemDialog,
    closeDialog: closeRoutingItemDialog,
    open: routingItemDialogOpen,
  } = useDialog<IXtEntityDialogData<IRoutingItem | null>>({ mode: XtMode.New, data: null })

  const {
    open: confirmationDialogOpen,
    data: confirmationDialogData,
    openDialog: openConfirmationDialog,
    closeDialog: closeConfirmationDialog,
  } = useDialog<number | null>(null)

  const { isNewMode, isViewMode } = convertMode(currentMode)

  const {
    formState: { isSubmitting, isDirty: formStateIsDirty, isSubmitSuccessful },
    handleSubmit,
    control,
    watch,
  } = formMethods

  const currentItem = watch(RoutingDetailsFormField.ItemNumber)

  const isDirty = formStateIsDirty || (isNewMode && state.data.length)
  const showPrompt = !isSubmitSuccessful && !!isDirty
  const handleAction = useCallback<(item: IRoutingDetailsTableItem, action: RoutingDetailsAction) => void>(
    (item, action: RoutingDetailsAction) => {
      switch (action) {
        case RoutingDetailsAction.Edit:
          return openRoutingItemDialog({ data: item, mode: XtMode.Edit })
        case RoutingDetailsAction.Expire:
          return expireRoutingItem(item.id)
        case RoutingDetailsAction.Delete:
          return openConfirmationDialog(item.id)
        default:
          return openRoutingItemDialog({ data: item, mode: XtMode.View })
      }
    },
    [expireRoutingItem, openConfirmationDialog, openRoutingItemDialog]
  )

  const onCancel = (): void => {
    // TODO implement confirmation dialog
    // eslint-disable-next-line no-restricted-globals,no-alert
    history.push(`${productsRoutePath}/${productsRoutes.routing}`)
  }

  const handleRoutingItemClick = useCallback(
    (item: IRoutingDetailsTableItem) => openRoutingItemDialog({ data: item, mode: isViewMode ? XtMode.View : XtMode.Edit }),
    [isViewMode, openRoutingItemDialog]
  )

  const actions = useMemo(() => defineAvailableActions(isNewMode, isViewMode), [isNewMode, isViewMode])

  useEffect(() => {
    if (isSubmitSuccessful) onCancel()
  }, [isSubmitSuccessful])

  if (initializing) {
    return (
      <div className="xt-content">
        <LoadingSpinnerDelayed />
      </div>
    )
  }

  const handleItemDeletion = async (id: number): Promise<void> => {
    closeConfirmationDialog()
    await deleteRoutingItem(id)
  }

  return (
    <main className="xt-content">
      {confirmationDialogData && (
        <XtConfirmationDialog
          open={confirmationDialogOpen}
          message={confirmationMessages.deleted}
          title="Delete Routing"
          confirmationButtonLabel="Delete"
          onConfirm={() => handleItemDeletion(confirmationDialogData)}
          onClose={closeConfirmationDialog}
        />
      )}
      <XtDialog
        className="xt-modal-details-content"
        open={routingItemDialogOpen}
        fullScreen={true}
        animation={XtDialogAnimation.SlideAnimation}
      >
        {currentItem && (
          <RoutingItem
            onSubmit={handleRoutingItemSubmit}
            parentItem={currentItem}
            onClose={closeRoutingItemDialog}
            mode={routingItemDialogData.mode}
            data={routingItemDialogData.data}
          />
        )}
      </XtDialog>
      <form onSubmit={handleSubmit(onRoutingSubmit)}>
        <h1 className={cls(styles.routingHeader, 'xt-page-title')}>{isNewMode ? 'New Routing' : 'Routing Details'}</h1>
        <div
          className={cls(styles.routingDetailsHeaderSection, 'xt-section-border', isNewMode && styles.headerIsNewMode, 'xt-sticky-header')}
        >
          <FormXtItemNumber
            required
            disabled={!!state.data.length}
            control={control}
            name={RoutingDetailsFormField.ItemNumber}
            isEditMode={isNewMode}
            onChange={handleItemNumberChange}
            loading={state.loading}
            loadOptions={ItemsUtilsService.loadItemOptions}
          />
          <div className={styles.routingDetailsButtonSection}>
            <SvgIcon iconId={SvgIconIds.PRINT} />
            <XtButton disabled={isSubmitting} label="Cancel" onClick={onCancel} />
            <XtButton
              hidden={isViewMode}
              label="Save"
              type="submit"
              loading={isSubmitting}
              disabled={isSubmitting || !isDirty || isViewMode}
            />
          </div>
        </div>
        <RoutingDetailsForm leadTime={routing?.lead_time ?? 0} disabled={isViewMode} formMethods={formMethods} />
        <div className={styles.routingDetailsTableFilters}>
          <XtCheckbox
            label="Show Expired Operations"
            value={state.filters.showExpired}
            disabled={state.loading || isSubmitting || isViewMode || isNewMode}
            onChange={onFiltersChange(RoutingDetailsFilter.ShowExpired)}
            className={styles.checkboxOverflow}
          />
          <XtCheckbox
            value={state.filters.showFuture}
            disabled={state.loading || isSubmitting || isViewMode || isNewMode}
            onChange={onFiltersChange(RoutingDetailsFilter.ShowFuture)}
            label="Show Future Operations"
            className={styles.checkboxOverflow}
          />
          <FormCheckboxLabel
            disabled={isSubmitting || isViewMode}
            name={RoutingDetailsFormField.CloseWorkorder}
            control={control}
            label="Close work order when fully qty. posted"
            className={styles.checkboxOverflow}
          />
        </div>
      </form>
      <div>
        <XtResponsiveButton
          disabled={isViewMode || !currentItem}
          icon={SvgIconIds.ADD_CIRCLE}
          label="New BOO Item"
          onClick={() => openRoutingItemDialog({ mode: XtMode.New, data: null })}
          className={styles.newButton}
        />
        <div className={cls('xt-common-table-list', styles.table)}>
          <XtList
            pagination={isNewMode ? undefined : pagination}
            isMobile={isMobile}
            actions={actions}
            data={state.data}
            loading={state.loading}
            onAction={handleAction}
            onRowClick={handleRoutingItemClick}
            getItemActions={getRoutingDetailsActions}
            columns={routingDetailsColumns}
            sortOptions={state.sortOptions}
            onColumnHeaderClick={sort}
          />
        </div>
      </div>

      <Prompt when={showPrompt} message={confirmationMessages.unsavedChanges} />
    </main>
  )
}
