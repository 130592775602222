import React, { FC, useEffect } from 'react'
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators'
import { FormCheckboxLabel, FormRadioGroup } from 'common/utils/form/form.components'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { isEqual } from 'common/utils/object.utils'
import { globalConstants } from 'common/constants'
import * as styles from '../inventory-adjustment-details.module.scss'
import { IInventoryAdjustmentCostParams, InventoryAdjustmentCostType } from './inventory-adjustment-cost.types'
import { InventoryAdjustmentField, InventoryAdjustmentLabel } from '../inventory-adjustment-details.types'
import { inventoryAdjustmentCostOptions } from './inventory-adjustment-cost.constants'

export const InventoryAdjustmentCost: FC<IInventoryAdjustmentCostParams> = ({ formMethods }) => {
  const { control, setValue, watch, formValueChanges$ } = formMethods

  const adjust = watch(InventoryAdjustmentField.Adjust)
  const costType = watch(InventoryAdjustmentField.CostType)

  const handleCostTypeChange = (costTypeOption: InventoryAdjustmentCostType): void => {
    setValue(InventoryAdjustmentField.CostType, costTypeOption, { shouldDirty: true, shouldValidate: true })
    if (costTypeOption === InventoryAdjustmentCostType.Calculated) {
      setValue(InventoryAdjustmentField.TotalCost, 0, { shouldDirty: true, shouldValidate: true })
      setValue(InventoryAdjustmentField.UnitCost, 0, { shouldDirty: true, shouldValidate: true })
    }
  }

  useEffect(() => {
    const sub = formValueChanges$
      .pipe(
        map(({ distribution_qty, total_cost }) => ({ total_cost, distribution_qty })),
        distinctUntilChanged((prev, current) => isEqual(prev, current)),
        debounceTime(globalConstants.inputDebounce)
      )
      .subscribe(({ distribution_qty, total_cost }) => {
        const unitCost = distribution_qty && distribution_qty !== 0 ? total_cost / distribution_qty : 0
        setValue(InventoryAdjustmentField.UnitCost, unitCost, { shouldDirty: true, shouldValidate: true })
      })

    return () => sub.unsubscribe()
  }, [formValueChanges$, setValue])

  return (
    <div>
      <FormCheckboxLabel control={control} name={InventoryAdjustmentField.Adjust} label={InventoryAdjustmentLabel.Adjust} />
      <div className={styles.formContent}>
        <FormRadioGroup
          disabled={!adjust}
          className={styles.inventoryAdjustmentOptions}
          options={inventoryAdjustmentCostOptions}
          label=""
          control={control}
          name={InventoryAdjustmentField.CostType}
          onChange={handleCostTypeChange}
        />
        <div className={styles.formFields}>
          <DecimalFormField
            disabled={!adjust || costType === InventoryAdjustmentCostType.Calculated}
            control={control}
            fixedDecimalScale={5}
            name={InventoryAdjustmentField.TotalCost}
            label={InventoryAdjustmentLabel.TotalCost}
          />
          <DecimalFormField disabled control={control} name={InventoryAdjustmentField.UnitCost} label={InventoryAdjustmentLabel.UnitCost} />
        </div>
      </div>
    </div>
  )
}
