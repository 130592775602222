import { ISetupWizardDialogState, ISetupWizardModuleState } from './setup-wizard.types'
import { setupModuleList, SetupModules, SetupModulesParams } from './setup-wizard.exports'

export const defaultSetupWizardRoute: string = '/setup-wizard/basic/welcome'
export const setupWizardRoutePath: string = '/setup-wizard/:activeModule/:activeStep'
export const setupWizardServiceToken = 'SetupWizardService'

export const defaultWizardDialogState: ISetupWizardDialogState = {
  activeModule: setupModuleList.filter((module) => module.name == SetupModules.BasicConfiguration)[0],
  activeStep: {
    id: 1,
    state: setupModuleList[0].steps[0],
  },
  isDirty: false,
  isLastStep: false,
}

export const defaultWizardState: ISetupWizardModuleState = {
  [SetupModulesParams.BasicConfiguration]: {
    welcomeMsg: null,
    createItems: {
      items: [],
    },
    createAccounts: {
      accounts: [],
    },
  },
}
