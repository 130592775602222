import { Observable } from 'rxjs'
import { debounceTime, map, pairwise } from 'rxjs/operators'
import { isProspectCrmAccount } from 'companies/accounts/accounts.utils'
import { FormChanges, MultipleFormGetFormState } from 'common/hooks/form/multiple-form-manager/multiple-form-manager.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { CurrencyOption, ICurrency } from 'dictionary/dictionary.types'
import { IAccountFulfilled, IContactAndAddress } from 'companies/accounts/accounts.types'
import { IShipment, ShipmentOption } from 'shipments/shipments.types'
import { ITableRow } from 'components/table/table.types'
import { ICRMAccountDocument } from 'documents/documents.types'
import { globalConstants } from 'common/constants'
import { parseServerDateWithDefault } from 'common/utils/date.utils'
import { defaultCurrency } from 'dictionary/dictionary.constants'
import { NumberTypeUtils } from 'common/utils/type.utils'
import { convertContactAddressToAddress } from 'companies/companies.utils'
import { headerInformationFormKey } from './header-information/header-information.constants'
import { lineItemsKey, lineItemsTableKey } from './line-items/line-items.constants'
import {
  CustomerAccountOption,
  IPricesData,
  ISalesBillTo,
  ISalesCombinedForm,
  ISalesDetailsState,
  ISalesFormChangeOutput,
  ISalesMarksAsInvalidState,
  ISalesSharedType,
  ISalesShipment,
  SalesShippingAndSalesInput,
} from './sales-shared.types'
import { orderDateColumnId, orderNumberColumnId } from './attach-dialog/attach-dialog.constants'
import { QuoteLightweight } from './quotes.types'
import { AttachTableItem } from './attach-dialog/attach-dialog.types'
import { ISalesOrder, SalesOrderLightweight } from './sales-orders.types'

export function convertOrdersData(data: SalesOrderLightweight[]): AttachTableItem[] {
  return data.map((item) => ({
    id: item.order_number,
    selected: false,
    order_number: item.order_number,
    customer_number: item.customer_number,
    order_date: item.order_date,
    scheduled_date: item.scheduled_date,
  }))
}

export function convertQuotesData(data: QuoteLightweight[]): AttachTableItem[] {
  return data.map((item) => ({
    id: item.quote_number,
    selected: false,
    order_number: item.quote_number,
    customer_number: item.customer_number,
    order_date: item.quote_date,
    scheduled_date: item.scheduled_date,
  }))
}

export function convertSortOptionsForQuote(sortOptions?: ISortOption[]): ISortOption[] | undefined {
  if (!Array.isArray(sortOptions)) {
    return undefined
  }
  return sortOptions.map(({ sortField, sortDirection }) => {
    if (sortField === orderNumberColumnId) {
      return { sortField: 'quote_number', sortDirection }
    }
    if (sortField === orderDateColumnId) {
      return { sortField: 'quote_date', sortDirection }
    }
    return { sortField, sortDirection }
  })
}

export function convertDocumentAccountToCustomerAccountOption(account: ICRMAccountDocument): CustomerAccountOption {
  return {
    name: account.name,
    number: account.number,
    id: account.number,
    label: account.name,
    allow_free_form_shipto: true,
    allow_free_form_billto: true,
    billTo: null,
    crm_roles: account.crm_roles,
    credit_status_exceed_warn: account.credit_status_exceed_warn,
    credit_status_exceed_hold: account.credit_status_exceed_hold,
    hasProspectRole: isProspectCrmAccount(account),
    preferredSite: null,
  }
}

export function convertShipmentToOption(shipment: IShipment): ShipmentOption {
  const { address1, address2, address3, city, state, postalcode } = shipment.shipto_address ?? {}

  return {
    ...shipment,
    id: shipment.shipto_number,
    label: shipment.shipto_number,
    addressLine1: [address1, address2, address3].filter(Boolean).join(', '),
    addressLine2: [city, state, postalcode].filter(Boolean).join(', '),
    name: shipment.name,
    shipto_number: shipment.shipto_number,
  }
}

export function convertToCurrencyOption(currency?: string, symbol?: string): CurrencyOption | null {
  if (!currency) {
    return {
      id: defaultCurrency,
      label: defaultCurrency,
      currency: defaultCurrency,
      iso_code: defaultCurrency,
      rates: [],
      symbol: '$',
      conversion_basis: '',
      base_currency: false,
    }
  }
  return currency && symbol
    ? {
        id: currency,
        label: currency,
        currency,
        iso_code: currency,
        rates: [],
        symbol,
        conversion_basis: '',
        base_currency: false,
      }
    : null
}

export function calcTotal(subtotal: number, tax: number, miscCharge: number | null, freight: number | null): number {
  const total = subtotal + tax + (miscCharge ?? 0) + (freight ?? 0)
  return NumberTypeUtils.parseFloatString(total.toFixed(2))
}

export function isValidLineItemsTableState(items: ITableRow[]): boolean {
  return !!items.length
}

export function parseBillTo(billTo: ISalesBillTo | undefined | null): IContactAndAddress | null {
  if (!billTo) {
    return null
  }
  return {
    contact: billTo.billto_contact?.contact_number ? billTo.billto_contact : null,
    address: billTo.billto_address,
  }
}

export function convertToBillTo(billTo: IContactAndAddress, name: string): ISalesBillTo {
  return {
    billto_name: name,
    billto_contact: billTo.contact,
    billto_address: billTo.address,
  }
}

export function parseShipTo(shipTo: ISalesShipment | undefined | null): IContactAndAddress | null {
  if (!shipTo) {
    return null
  }
  return {
    contact: shipTo.shipto_contact,
    address: shipTo.shipto_address,
  }
}

export function convertToShipTo(shipTo: IContactAndAddress, name: string, number: string | null): ISalesShipment {
  return {
    shipto_name: name,
    shipto_number: number,
    shipto_contact: shipTo.contact,
    shipto_address: shipTo.address,
  }
}

export function defineSalesPricesData(
  orderDate: Date | null | undefined,
  shipToNumber: string | null | undefined,
  shippingZone: string | null | undefined,
  saleType: string | null | undefined
): IPricesData {
  return {
    order_date: orderDate ?? null,
    shipto_number: shipToNumber ?? null,
    shipping_zone: shippingZone ?? null,
    sale_type: saleType ?? null,
  }
}

export function convertShipToToContactAndAddressType(shipTo: ShipmentOption | null): IContactAndAddress | null {
  if (!shipTo) {
    return null
  }
  return {
    contact: shipTo.shipto_contact,
    address: convertContactAddressToAddress(shipTo.shipto_address),
  }
}

// TODO fix the return type
export function convertShipmentToShipToOption(shipment: ISalesShipment | null | undefined): ShipmentOption | null {
  if (!shipment || !shipment.shipto_number) {
    return null
  }
  return ({
    id: shipment.shipto_number,
    label: shipment.shipto_name,
    shipto_number: shipment.shipto_number,
    shipto_address: { ...shipment.shipto_address, postalcode: shipment.shipto_address.postal_code },
    shipto_contact: shipment.shipto_contact,
    name: shipment.shipto_name,
  } as unknown) as ShipmentOption
}

function calculateSalesFormMarksAsInvalidState(getState: MultipleFormGetFormState<ISalesCombinedForm>): ISalesMarksAsInvalidState {
  const headerInformationFormState = getState(headerInformationFormKey)
  const lineItemsFormState = getState(lineItemsKey)

  return {
    headerInfoMarkAsInvalid: headerInformationFormState?.fieldValidatorsShown ?? false,
    lineItemsMarkAsInvalid: lineItemsFormState?.fieldValidatorsShown ?? false,
  }
}

export function defineSalesState<ShippingAndSalesInput extends SalesShippingAndSalesInput>(
  prevState: ISalesDetailsState<ShippingAndSalesInput>,
  { mainForm: { customer, ship_to_option, date, site }, lineItems }: ISalesCombinedForm,
  lineItemsMarkIsInvalid: boolean,
  headerInfoMarkIsValid: boolean,
  editMode: boolean,
  customerChanged: boolean,
  shipToOptionChanged: boolean,
  orderDateChanged: boolean,
  shippingAndSalesConverter: (input: ShippingAndSalesInput | null, shipmentOption: ShipmentOption | null) => ShippingAndSalesInput,
  customerDisabled: boolean
): ISalesDetailsState<ShippingAndSalesInput> {
  const shippingAndSalesInput = shipToOptionChanged
    ? shippingAndSalesConverter(prevState.shippingAndSalesInput, ship_to_option)
    : prevState.shippingAndSalesInput

  const pricesData =
    shipToOptionChanged || orderDateChanged
      ? defineSalesPricesData(date, ship_to_option?.id, shippingAndSalesInput?.shipping_zone, shippingAndSalesInput?.sale_type)
      : prevState.pricesData

  return {
    site: site?.id ?? null,
    customer,
    customerDisabled: customerDisabled || editMode || !!lineItems?.[lineItemsTableKey]?.length,
    pricesData,
    shipToInput: shipToOptionChanged ? convertShipToToContactAndAddressType(ship_to_option) : prevState.shipToInput,
    billToInput: customerChanged ? customer?.billTo ?? null : prevState.billToInput,
    shippingAndSalesInput: customerChanged
      ? ({
          ...shippingAndSalesInput,
          terms: customer?.terms,
          sales_rep: customer?.sales_rep,
          tax_zone: customer?.tax_zone,
        } as ShippingAndSalesInput)
      : shippingAndSalesInput,
    headerInfoMarkAsInvalid: headerInfoMarkIsValid,
    lineItemsMarkAsInvalid: lineItemsMarkIsInvalid,
  }
}

export function defineSalesFormChangesObservable(
  multipleFormChanges$: Observable<FormChanges<ISalesCombinedForm>>,
  getFormState: MultipleFormGetFormState<ISalesCombinedForm>
): Observable<ISalesFormChangeOutput> {
  return multipleFormChanges$.pipe(
    debounceTime(globalConstants.formChangeDebounce),
    pairwise(),
    map(([prev, current]) => {
      const {
        data: { mainForm: prevMainForm },
      } = prev

      const {
        data: { mainForm: currentMainForm },
      } = current

      const { lineItemsMarkAsInvalid, headerInfoMarkAsInvalid } = calculateSalesFormMarksAsInvalidState(getFormState)

      const shipToOptionChanged = prevMainForm.ship_to_option?.id !== currentMainForm.ship_to_option?.id
      const customerChanged = prevMainForm.customer?.number !== currentMainForm.customer?.number
      const dateChanged = prevMainForm.date.getTime() !== currentMainForm.date.getTime()

      return {
        currentFormData: current.data,
        lineItemsMarkAsInvalid,
        headerInfoMarkAsInvalid,
        shipToOptionChanged,
        customerChanged,
        dateChanged,
      }
    })
  )
}

function defineBillToInput<SalesDetails extends ISalesSharedType>(
  salesDetails: SalesDetails | null,
  account: IAccountFulfilled | null
): IContactAndAddress | null {
  if (salesDetails) {
    return parseBillTo(salesDetails.bill_to)
  }
  return account?.billTo ?? null
}

export function defineSalesDetailsState<
  ShippingAndSalesInput extends SalesShippingAndSalesInput,
  SalesDetails extends ISalesSharedType & ShippingAndSalesInput
>(
  salesDetails: SalesDetails | null,
  account: IAccountFulfilled | null,
  salesDate: string | undefined | null
): ISalesDetailsState<ShippingAndSalesInput> {
  return {
    site: null,
    customer: account ?? null,
    customerDisabled: !!account,
    pricesData: defineSalesPricesData(
      parseServerDateWithDefault(salesDate),
      salesDetails?.ship_to?.shipto_number,
      salesDetails?.shipping_zone,
      salesDetails?.sale_type
    ),
    headerInfoMarkAsInvalid: false,
    lineItemsMarkAsInvalid: false,
    billToInput: defineBillToInput(salesDetails, account),
    shipToInput: parseShipTo(salesDetails?.ship_to ?? null),
    shippingAndSalesInput: salesDetails ?? null,
  }
}

export const getCurrencySymbol = async (
  salesOrder: ISalesOrder | null,
  customer: IAccountFulfilled | null,
  getBaseCurrency: () => Promise<ICurrency | null>
): Promise<string> => {
  if (salesOrder && salesOrder.currency_symbol) return salesOrder.currency_symbol

  if (customer && customer.default_currency_symbol) return customer.default_currency_symbol

  const baseCurrency = await getBaseCurrency()
  if (baseCurrency && baseCurrency.symbol) return baseCurrency.symbol

  return ''
}
