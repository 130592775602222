// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory, History } from 'history'

export interface INavigationService {
  history: History
  navigateTo(path: string): void
}

export class NavigationService implements INavigationService {
  public readonly history = createBrowserHistory()

  public navigateTo(path: string): void {
    this.history.push(path)
  }
}
