export const itemCostsRoutePath = 'item-costs'

export const itemCostsRoutes = {
  summary: 'summary',
  history: 'history',
  byClassCode: 'class-code',
}

export const costingItemCostsUtilsServiceToken = 'CostingItemCostsUtilsServiceToken'
export const inventoryHistoryServiceToken = 'InventoryHistoryService'
