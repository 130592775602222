import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { booleanColumnConverter } from 'common/utils/table.utils'
import { IRestrictedLocationRow } from './sites-details-dialog-location.types'

export const locationNA = 'N/A'

export const sitesDetailsDialogLocationFormKey = 'sitesDetailsDialogLocationFormKey'

export const restrictedLocationColumns: IXtTableColumn<IRestrictedLocationRow>[] = [
  {
    id: 'restrictedLocation',
    field: 'restrictedLocation',
    headerName: 'Location',
    flex: '1 0 250px',
    width: 250,
  },
  {
    id: 'locationDescription',
    field: 'locationDescription',
    headerName: 'Description',
    flex: '1 0 400px',
    width: 400,
  },
  {
    id: 'allowed',
    field: 'allowed',
    headerName: 'Allowed',
    flex: '1 0 160px',
    width: 160,
    converter: booleanColumnConverter,
  },
]

export enum RestrictedLocationAction {
  Allow = 'Allow',
  Disallow = 'Disallow',
}

export const RestrictedLocationActions: IXtTableCellAction[] = [
  {
    name: RestrictedLocationAction.Allow,
    label: RestrictedLocationAction.Allow,
    icon: SvgIconIds.CHECKED_CIRCLE,
  },
  {
    name: RestrictedLocationAction.Disallow,
    label: RestrictedLocationAction.Disallow,
    icon: SvgIconIds.OUTLINE_CLOSE_CIRLCE,
  },
]
