// extracted by mini-css-extract-plugin
export var accountDetailsMainContainer = "account-details_accountDetailsMainContainer__yq8Kh";
export var accountDetailsHeader = "account-details_accountDetailsHeader__yJyAK";
export var accountDetailsFormContainer = "account-details_accountDetailsFormContainer__jrIJH";
export var accountDetailsCheckboxGroup = "account-details_accountDetailsCheckboxGroup__3Jy6X";
export var controlButtonsBlock = "account-details_controlButtonsBlock__6E-Kr";
export var controlButtonsBlockViewMode = "account-details_controlButtonsBlockViewMode__6HfAx";
export var accountDetailsCheckboxActive = "account-details_accountDetailsCheckboxActive__0t2c2";
export var accountDetailsRadioGroup = "account-details_accountDetailsRadioGroup__0EJxl";
export var accountDetailsHeaderSection = "account-details_accountDetailsHeaderSection__48WV8";
export var accountDetailsTabs = "account-details_accountDetailsTabs__yVDDZ";