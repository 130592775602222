export enum RegistrationActions {
  Edit = 'Edit',
  Delete = 'Delete',
  View = 'View',
}

export interface IRegistrationsList {
  itemNumber: string
  lotSerialNumber: string
}
