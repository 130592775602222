import React from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { cls } from 'common/utils/utils'
import * as styles from './xt-radio-group.module.scss'

export type FormRadioGroupOptions = { label: string; id: string; disabled?: boolean; template?: React.ReactElement }[]

export interface IXtRadioGroup {
  options: FormRadioGroupOptions
  onChange?(checked: string): void
  label?: string
  disabled?: boolean
  className?: string
  hidden?: boolean
  value?: string
  onBlur?: () => void
}

export function XtRadioGroup({ label, disabled, className, hidden, options, onChange, value, onBlur }: IXtRadioGroup): React.ReactElement {
  return (
    <RadioGroup className={cls('MuiFormRadioGroup', className)} hidden={hidden}>
      <p hidden={!label} className="MuiFormRadioGroupLabel">
        {label}
      </p>
      {options.map((option) => (
        <React.Fragment key={option.id}>
          <FormControlLabel
            disabled={disabled || option.disabled}
            control={<Radio classes={{ root: styles.radioRoot }} color="primary" />}
            label={option.label}
            value={value}
            classes={{ root: styles.formControlLabelRoot }}
            key={`${option.id}-${option.label}`}
            onChange={() => onChange?.(option.id)}
            onBlur={onBlur}
            checked={value === option.id}
          />
          {option.template}
        </React.Fragment>
      ))}
    </RadioGroup>
  )
}
