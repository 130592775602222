import * as Yup from 'yup'
import { ObjectSchema } from 'yup'
import { minDate, validationMessage } from 'common/validation/validation'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { SalesOrderOption } from 'sales-shared/sales-orders.types'
import { RegistrationDialogFormField } from './registration-dialog.types'
import { minQtyValue } from './registration-dialog.constants'

export const registrationDialogValidationSchema = Yup.object().shape(
  {
    [RegistrationDialogFormField.RegistrationType]: Yup.object().required(validationMessage.isRequired).nullable(),
    [RegistrationDialogFormField.RegisterDate]: Yup.date()
      .required(validationMessage.isRequired)
      .nullable()
      .typeError(validationMessage.invalidDate)
      .min(minDate, validationMessage.minDate(validationMessage.minimumDate)),
    [RegistrationDialogFormField.SaleDate]: Yup.date()
      .required(validationMessage.isRequired)
      .nullable()
      .typeError(validationMessage.invalidDate)
      .min(minDate, validationMessage.minDate(validationMessage.minimumDate)),
    [RegistrationDialogFormField.ExpireDate]: Yup.date()
      .required(validationMessage.isRequired)
      .nullable()
      .typeError(validationMessage.invalidDate)
      .min(minDate, validationMessage.minDate(validationMessage.minimumDate)),
    [RegistrationDialogFormField.Qty]: Yup.number()
      .required(validationMessage.isRequired)
      .nullable()
      .min(minQtyValue, validationMessage.cannotBeLessThan(String(minQtyValue))),
    [RegistrationDialogFormField.AccountNumber]: Yup.object().nullable().required(validationMessage.isRequired),
    [RegistrationDialogFormField.SalesOrder]: Yup.object()
      .nullable()
      .when(RegistrationDialogFormField.Shipment, (shipment: IXtAutocompleteOption | null, schema: ObjectSchema) =>
        !shipment ? schema.required(validationMessage.isRequired) : schema
      ),
    [RegistrationDialogFormField.Shipment]: Yup.object()
      .nullable()
      .when(RegistrationDialogFormField.SalesOrder, (salesOrder: SalesOrderOption | null, schema: ObjectSchema) =>
        !salesOrder ? schema.required(validationMessage.isRequired) : schema
      ),
    [RegistrationDialogFormField.Contact]: Yup.object().nullable().required(validationMessage.isRequired),
  },
  [[RegistrationDialogFormField.SalesOrder, RegistrationDialogFormField.Shipment]]
)
