import * as yup from 'yup'
import { StringSchema } from 'yup'
import { validationMessage } from 'common/validation/validation'
import { ConversionsDetailsDialogFormField, ConversionsDetailsDialogLabel } from './conversions-details-dialog.types'
import { valueDecimalScale } from './conversions-details-dialog.constants'
import { IXtAutocompleteOption } from '../../../../../../components/controls/xt-autocomplete/xt-autocomplete.types'

export const conversionDetailsDialogValidation = yup.object().shape(
  {
    [ConversionsDetailsDialogFormField.UomFrom]: yup
      .object()
      .nullable()
      .required(validationMessage.isRequired)
      .when(ConversionsDetailsDialogFormField.UomTo, (uomTo: IXtAutocompleteOption, schema: StringSchema) =>
        schema.test({
          test: (uomFrom) => uomFrom.id !== uomTo.id,
          message: validationMessage.notEqual(ConversionsDetailsDialogLabel.UomTo),
        })
      ),
    [ConversionsDetailsDialogFormField.FromValue]: yup
      .number()
      .nullable()
      .required(validationMessage.isRequired)
      .moreThan(0, validationMessage.min(0, valueDecimalScale))
      .max(10 ** 9, validationMessage.max(10 ** 9)),
    [ConversionsDetailsDialogFormField.UomTo]: yup
      .object()
      .nullable()
      .required(validationMessage.isRequired)
      .when(ConversionsDetailsDialogFormField.UomFrom, (uomFrom: IXtAutocompleteOption, schema: StringSchema) =>
        schema.test({
          test: (uomTo) => uomFrom.id !== uomTo.id,
          message: validationMessage.notEqual(ConversionsDetailsDialogLabel.UomFrom),
        })
      ),
    [ConversionsDetailsDialogFormField.ToValue]: yup
      .number()
      .nullable()
      .required(validationMessage.isRequired)
      .moreThan(0, validationMessage.min(0, valueDecimalScale))
      .max(10 ** 9, validationMessage.max(10 ** 9)),
    [ConversionsDetailsDialogFormField.UomTypes]: yup.array().required(validationMessage.isRequired),
  },
  [[ConversionsDetailsDialogFormField.UomFrom, ConversionsDetailsDialogFormField.UomTo]]
)
