import {
  IQuantityOnHandListFilter,
  IQuantityOnHandListFilterForm,
  QuantityOnHandListFilterField,
  UseCostOptions,
  ShowQuantityOptions,
} from './quantity-on-hand-list-filter.types'

export function prepareQuantityOnHandListFilter(filters: IQuantityOnHandListFilterForm): IQuantityOnHandListFilter {
  const showPositiveQuantities =
    filters.show_quantities === ShowQuantityOptions.All ? null : filters.show_quantities === ShowQuantityOptions.Positive

  const showOnlyNegativeQuantities =
    filters.show_quantities === ShowQuantityOptions.All ? null : filters.show_quantities === ShowQuantityOptions.Negative

  const showValue = filters.showValue ? true : null

  const useActualCosts = showValue ? filters.useCost === UseCostOptions.Actual : null

  return {
    [QuantityOnHandListFilterField.ClassCode]: filters.class_code?.id ?? null,
    [QuantityOnHandListFilterField.ClassCodePattern]: filters.class_code_pattern,
    [QuantityOnHandListFilterField.CostCategory]: filters.cost_category?.id ?? null,
    [QuantityOnHandListFilterField.CostCategoryPattern]: filters.cost_category_pattern,
    [QuantityOnHandListFilterField.ItemNumber]: filters.item_number?.id ?? null,
    [QuantityOnHandListFilterField.ItemGroup]: filters.item_group?.id ?? null,
    [QuantityOnHandListFilterField.ItemGroupPattern]: filters.item_group_pattern,
    [QuantityOnHandListFilterField.AbcClass]: filters.abc_class?.id ?? null,
    [QuantityOnHandListFilterField.Site]: filters.site?.id ?? null,
    [QuantityOnHandListFilterField.AsOf]: filters.asOf?.id ?? null,
    [QuantityOnHandListFilterField.ShowPositiveQuantities]: showPositiveQuantities,
    [QuantityOnHandListFilterField.ShowNegativeQuantities]: showOnlyNegativeQuantities,
    [QuantityOnHandListFilterField.ShowValue]: showValue,
    [QuantityOnHandListFilterField.UseActualCosts]: useActualCosts,
  }
}
