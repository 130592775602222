import { FilterType, IFilter } from 'components/pagefilter/filter/filter.types'
import { getAutocompleteInputLabelInline, renderColumnOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { IDocumentsUtilsService } from 'documents/documents-utils.service'
import { FilterFieldName, FilterLabel } from '../services/customers-services.types'

export function resolveFilters({ loadSalesReps, loadCustomerTypeOptions }: IDocumentsUtilsService): IFilter[] {
  return [
    {
      type: FilterType.Text,
      label: FilterLabel.CustomerNumberPattern,
      fieldName: FilterFieldName.CustomerNumberPattern,
    },
    {
      type: FilterType.Text,
      label: FilterLabel.CustomerNamePattern,
      fieldName: FilterFieldName.CustomerNamePattern,
    },
    {
      type: FilterType.Autocomplete,
      label: FilterLabel.CustomerType,
      fieldName: FilterFieldName.CustomerType,
      autocompleteProps: {
        loadOptions: loadCustomerTypeOptions,
        getInputLabel: getAutocompleteInputLabelInline,
        renderOption: renderColumnOption,
      },
    },
    {
      type: FilterType.Text,
      label: FilterLabel.ContactNamePattern,
      fieldName: FilterFieldName.ContactNamePattern,
    },
    {
      type: FilterType.Text,
      label: FilterLabel.CityPattern,
      fieldName: FilterFieldName.CityPattern,
    },
    {
      type: FilterType.Text,
      label: FilterLabel.StatePattern,
      fieldName: FilterFieldName.StatePattern,
    },
    {
      type: FilterType.Autocomplete,
      label: FilterLabel.SalesRep,
      fieldName: FilterFieldName.SalesRep,
      autocompleteProps: {
        loadOptions: loadSalesReps,
      },
    },
  ]
}
