import { useEffect, useState } from 'react'
import { checkIsLandscape, checkIsMobile } from './useDeviceDetect.utils'

interface IDeviceDetectState {
  isLandscape: boolean
  isMobile: boolean
  isLandscapeBlocked: boolean
}

const useLandscapeDefaultState: IDeviceDetectState = {
  isLandscape: false,
  isMobile: false,
  isLandscapeBlocked: false,
}

export function useDeviceDetect() {
  const [deviceScreen, setDeviceScreen] = useState<IDeviceDetectState>(useLandscapeDefaultState)

  const changeUseLandscapeState = (window: Window): void => {
    const isLandscape = checkIsLandscape(window)
    const isMobile = checkIsMobile()
    setDeviceScreen((prev) => ({
      ...prev,
      isLandscape,
      isMobile,
      isLandscapeBlocked: isMobile && isLandscape,
    }))
  }

  useEffect(() => {
    const eventListener = ({ target }: UIEvent) => changeUseLandscapeState(target as Window)

    changeUseLandscapeState(window)
    window.addEventListener('resize', eventListener)

    return () => window.removeEventListener('resize', eventListener)
  }, [])
  return deviceScreen
}
