import { IPaginationData, IPaginationParams, IPaginationResponse } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { IHttpClient } from './http-client'
import { IXtAutocompleteOption } from '../../components/controls/xt-autocomplete/xt-autocomplete.types'

export interface IRevision {
  revision: string
  revision_status: RevisionStatus
  revision_type: string
  created: string
  revision_date: string
}

export type RevisionOption = IRevision & IXtAutocompleteOption

export enum RevisionType {
  BOO = 'BOO',
  BOM = 'BOM',
}

export enum RevisionStatus {
  Active = 'Active',
  Pending = 'Pending',
}

export interface IRevisionService {
  getRevisionsWithItemNumber(
    revisionType: RevisionType,
    itemNumber: string,
    pagination?: IPaginationParams
  ): Promise<IPaginationData<IRevision>>
}

export class RevisionService implements IRevisionService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getRevisionsWithItemNumber(
    revisionType: RevisionType,
    itemNumber: string,
    pagination?: IPaginationParams
  ): Promise<IPaginationData<IRevision>> {
    const params = prepareRequestParams(pagination)

    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IRevision>>(`/revision/${revisionType}/${itemNumber}`, {
      params,
    })
    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}
