import * as React from 'react'
import { FC, useCallback } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { xsMq } from 'common/constants'
import { useTable } from 'common/hooks/useTable'
import { cls } from 'common/utils/utils'
import { XtList } from 'components/list/list'
import { CostedBomFilters } from 'products/items/items.types'
import { XtAccordion } from 'components/xt-accordion/xt-accordion'
import { useSharedModule } from 'shared/shared-module-hook'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { useAuthModule } from 'auth/auth-module-hook'
import { UserPermission } from 'auth/auth.types'
import { useProductsModule } from 'products/products-module-hook'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import * as styles from '../bom-list-pages.module.scss'
import {
  costedBomColumns,
  costedBomTotalsColumns,
  SingleLevelAction,
  singleLevelActions,
  singleLevelBOMPageTitle,
} from './single-level-list.constants'
import { SingleLevelListForm } from './single-level-list-form/single-level-list-form'
import { ICostedBomWithId } from '../bom-utils.types'
import { getItemSingleLevelActions } from './single-level-list.utils'

export const SingleLevelList: FC = () => {
  const { CostingBomUtilsService, SummaryList } = useProductsModule()
  const { useDialog } = useSharedModule()
  const { PermissionsService } = useAuthModule()
  useDocumentTitle(singleLevelBOMPageTitle)
  const canViewCosts = PermissionsService.hasPermission(UserPermission.ViewCosts)

  const isMobile = useMediaQuery(xsMq)

  const {
    state: { data: totals, loading: totalsLoading },
    filter: filterTotals,
  } = useTable({}, CostingBomUtilsService.getCostedBomTotals)

  const {
    open: summaryListDialogOpen,
    closeDialog: closeSummaryListDialog,
    openDialog: openSummaryListDialog,
    data: summaryListData,
  } = useDialog<string | undefined>()

  const {
    state: { data, loading, sortOptions },
    filter,
    sort,
    pagination,
  } = useTable({}, CostingBomUtilsService.fetchCostedBom)

  const onFiltersChange = useCallback(
    (filters: CostedBomFilters): void => {
      filter(filters)
      filterTotals(filters)
    },
    [filter, filterTotals]
  )

  const onAction = useCallback(
    ({ item_number }: ICostedBomWithId, action: SingleLevelAction) => {
      if (action === SingleLevelAction.ViewItemCostingSummary) {
        openSummaryListDialog(item_number)
      }
    },
    [openSummaryListDialog]
  )

  const onRowClick = useCallback(
    ({ seq, item_number }: ICostedBomWithId) => {
      if (seq) {
        openSummaryListDialog(item_number)
      }
    },
    [openSummaryListDialog]
  )

  return (
    <div className={cls('xt-content', styles.listContent)}>
      <XtDialog
        className="xt-modal-details-content"
        open={summaryListDialogOpen}
        fullScreen={false}
        animation={XtDialogAnimation.FadeAnimation}
      >
        <SummaryList className="xt-item-costs-summary-dialog-content" onClose={closeSummaryListDialog} itemNumber={summaryListData} />
      </XtDialog>
      <div className="xt-page-header">
        <h1 className="xt-page-title">Costed Single Level BOM</h1>
      </div>
      <SingleLevelListForm onChange={onFiltersChange} />
      <XtList
        sortOptions={sortOptions}
        onColumnHeaderClick={sort}
        pagination={pagination}
        columns={costedBomColumns}
        data={data}
        isMobile={isMobile}
        onAction={onAction}
        actions={canViewCosts ? singleLevelActions : []}
        getItemActions={getItemSingleLevelActions}
        loading={loading}
        onRowClick={onRowClick}
      />
      <XtAccordion loading={totalsLoading} summary="Totals">
        <XtList className={styles.tableTotals} loading={totalsLoading} columns={costedBomTotalsColumns} isMobile={isMobile} data={totals} />
      </XtAccordion>
    </div>
  )
}
