import React, { FC } from 'react'
import { useXtForm } from 'common/hooks/form/form'
import { FormXtAutocomplete } from 'common/utils/form/form.components'
import { XtInfoValue } from 'components/xt-info-values/xt-info-values.types'
import { XtInfoValues } from 'components/xt-info-values/xt-info-values'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { useShipmentsModule } from 'shipments/shipments-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { DocumentOrderOption, IDocumentListFilters } from 'documents/documents.types'
import { cls } from 'common/utils/utils'
import { OrderType } from 'core/core.types'
import { defaultPrintFormState } from '../print-dialogs.constants'
import * as styles from '../print-dialogs.module.scss'

import { printDialogsValidationSchema } from '../print-dialogs.validation'
import { IPrintFormDialogParams, IPrintFormSharedState, PrintSharedFormField, PrintSharedFormLabel } from '../print-dialogs.types'
import { convertPrintFormToPrintPayload } from '../print-dialogs.utils'
import { PrintLabels } from '../print-labels/print-labels'

const optionsFilter: IDocumentListFilters = { showInactive: true }

export const SalesOrderPrintForm: FC<IPrintFormDialogParams> = ({ onClose }) => {
  const { ErrorHandler } = useCoreModule()
  const { ShipmentsUtilsService } = useShipmentsModule()
  const { DocumentsUtilsService } = useDocumentsModule()

  const formMethods = useXtForm<IPrintFormSharedState<DocumentOrderOption>>({
    defaultValues: defaultPrintFormState,
    mode: 'onBlur',
    validationSchema: printDialogsValidationSchema,
  })

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    watch,
  } = formMethods

  const salesOrder = watch(PrintSharedFormField.Number)

  const printForm = async (formData: IPrintFormSharedState<DocumentOrderOption>): Promise<void> => {
    try {
      const payload = convertPrintFormToPrintPayload(formData, OrderType.SalesOrder, formData[PrintSharedFormField.Number]?.number)
      await ShipmentsUtilsService.printShipmentLabel(payload)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const salesOrderValues: XtInfoValue[] = [
    {
      label: 'SO',
      value: salesOrder?.order_status ?? null,
    },
  ]

  return (
    <form className={styles.printDialog}>
      <div className={cls('xt-page-header', styles.printDialogHeader)}>
        <h3 className="xt-page-title">Print Labels by Sales Order</h3>
        <div className={styles.printDialogButtons}>
          <XtButton label="Cancel" onClick={onClose} />
          <XtButton label="Print" onClick={handleSubmit(printForm)} disabled={!isDirty || isSubmitting} />
        </div>
      </div>
      <div>
        <FormXtAutocomplete
          loadOptions={DocumentsUtilsService.loadOrders}
          filters={optionsFilter}
          name={PrintSharedFormField.Number}
          control={control}
          label={PrintSharedFormLabel.OrderNumber}
        />
        <XtInfoValues values={salesOrderValues} classes={{ values: styles.orderValues }} />
      </div>
      <FormXtAutocomplete
        name={PrintSharedFormField.ReportName}
        control={control}
        label={PrintSharedFormLabel.ReportName}
        loadOptions={DocumentsUtilsService.loadReportNameOptions}
      />
      <PrintLabels formMethods={formMethods} />
    </form>
  )
}
