import { IPaginationData, IPaginationParams, IPaginationResponse } from 'common/common.types'
import { IHttpClient } from './http-client'
import { isEmptyResponse } from '../core.utils'

export interface IOperationType {
  code: string
  description: string
  system_type: boolean
}

export interface IOperationTypesService {
  getAll(params: IPaginationParams): Promise<IPaginationData<IOperationType>>
  get(option: string): Promise<IOperationType>
}

export class OperationTypesService implements IOperationTypesService {
  constructor(private readonly apiClient: IHttpClient) {}

  public async getAll({ limit, page }: IPaginationParams): Promise<IPaginationData<IOperationType>> {
    const params: Record<string, true | number> = { limit, page: page === 0 ? 1 : page }
    const {
      data: { data, status },
    } = await this.apiClient.get<IPaginationResponse<IOperationType>>('/operationtype', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }

  public async get(option: string): Promise<IOperationType> {
    const { data } = await this.apiClient.get(`/operationtype/${option}`)

    if (isEmptyResponse(data)) {
      throw new Error(`Operation Type ${option} is not found.`)
    }

    return data.data
  }
}
