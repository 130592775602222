import { IObjectWithId } from 'common/common.types'
import { IProspect } from '../prospects.types'

export interface IDeletionState {
  CRMProspectNumberId: string
  confirmationOpen: boolean
}

export enum ProspectAction {
  Delete = 'Delete',
  Edit = 'Edit',
  View = 'view',
}

export interface IProspectTableItem extends IObjectWithId, IProspect {}
