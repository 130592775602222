import { UserPermission } from 'auth/auth.types'
import { PermissionGuard } from 'auth/permission.guard'
import { homeRoutePath } from 'home/home.constants'

export class OrdersGuard extends PermissionGuard {
  public redirectTo = homeRoutePath
  public permissions = [
    UserPermission.ViewQuotes,
    UserPermission.MaintainQuotes,
    UserPermission.ViewSalesOrders,
    UserPermission.MaintainSalesOrders,
  ]
}
