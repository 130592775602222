import { Overwrite } from 'utility-types'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from 'core/injector/injector.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import {
  IRunningAvailabilityModuleServices,
  RunningAvailabilityModule,
  RunningAvailabilityModuleDefinition,
} from './running-availability.module'
import { runningAvailabilityUtilsServiceToken } from './running-availability.constants'
import { IRunningAvailabilityUtilsService } from './running-availability-utils.service'

type RunningAvailabilityModuleHook = Overwrite<RunningAvailabilityModuleDefinition, IRunningAvailabilityModuleServices>

function resolveRunningAvailabilityModule(): RunningAvailabilityModuleHook {
  const module = LazyModulesService.resolveModule<RunningAvailabilityModuleDefinition>(RunningAvailabilityModule)
  const RunningAvailabilityUtilsService = Injector.resolve<IRunningAvailabilityUtilsService>(runningAvailabilityUtilsServiceToken)

  return { ...module, RunningAvailabilityUtilsService }
}

export const useRunningAvailabilityModule = moduleHookResolver<RunningAvailabilityModuleHook>(resolveRunningAvailabilityModule)
