import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { convertDate, parseServerDate } from 'common/utils/date.utils'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import {
  IRoutingDetailsFormData,
  IRoutingDetailsTableItem,
  RoutingDetailsFormField,
  IRoutingDetailsUpdate,
  IRoutingDetailsCreate,
} from './routing-details.types'
import { IRouting, IRoutingItem } from '../routing.types'
import { RoutingDetailsActionsEditMode, RoutingDetailsActionsNewMode, RoutingDetailsActionsViewMode } from './routing-details.constants'
import { IItem } from '../../items/items.types'
import { defineItemNumberOption } from '../../items/components/item-number/item-number.utils'

export function defineFormData(data: IRouting | null, item: IItem | null): IRoutingDetailsFormData {
  return {
    [RoutingDetailsFormField.ItemNumber]: defineItemNumberOption(item),
    [RoutingDetailsFormField.RevisionNumber]: data?.revision_number ?? '',
    [RoutingDetailsFormField.RevisionDate]: parseServerDate(data?.revision_date),
    [RoutingDetailsFormField.DocumentNumber]: data?.document_number ?? '',
    [RoutingDetailsFormField.FinalLocation]: defineAutocompleteOption(data?.final_location),
    [RoutingDetailsFormField.FinalLocationSite]: defineAutocompleteOption(data?.final_location),
    [RoutingDetailsFormField.CloseWorkorder]: data?.close_workorder ?? false,
  }
}

export function defineAvailableActions(newRouting: boolean, isViewMode: boolean): IXtTableCellAction[] {
  if (newRouting) {
    return RoutingDetailsActionsNewMode
  }
  if (isViewMode) {
    return RoutingDetailsActionsViewMode
  }
  return RoutingDetailsActionsEditMode
}

export function convertFormDataUpdate(
  formData: IRoutingDetailsFormData,
  item_number: string,
  item_description: string,
  lead_time: number | undefined,
  revision_status: string | undefined
): IRoutingDetailsUpdate {
  return {
    close_workorder: formData.close_workorder,
    document_number: formData.document_number,
    final_location: formData.final_location?.id ?? null,
    final_location_site: formData.final_location_site?.id ?? null,
    item_number,
    lead_time: lead_time ?? 0,
    revision_date: formData.revision_date ? convertDate(formData.revision_date) : null,
    revision_number: formData.revision_number,
    revision_status: revision_status ?? '',
    item_description,
  }
}

export function convertFormDataCreate(
  formData: IRoutingDetailsFormData,
  lead_time: number | undefined,
  revision_status: string | undefined,
  routing_items: IRoutingItem[]
): IRoutingDetailsCreate {
  const { item_number } = formData

  if (!item_number) {
    throw new Error('Item Number is required.')
  }

  return {
    close_workorder: formData.close_workorder,
    document_number: formData.document_number,
    final_location: formData.final_location?.id ?? null,
    final_location_site: formData.final_location_site?.id ?? null,
    item_number: item_number.id,
    lead_time: lead_time ?? 0,
    revision_date: formData.revision_date ? convertDate(formData.revision_date) : null,
    revision_number: formData.revision_number,
    revision_status: revision_status ?? '',
    routing_items,
    item_description: item_number.description1,
  }
}

export function getRoutingDetailsActions(item: IRoutingDetailsTableItem, actions: IXtTableCellAction[]): IXtTableCellAction[] {
  if (new Date(item.expires) < new Date()) {
    return RoutingDetailsActionsNewMode
  }
  return actions
}
