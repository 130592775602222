import React, { FC, memo } from 'react'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { ICustomer } from 'companies/customers/customers.types'
import { TaskParentType } from 'tasks/tasks.types'
import { IXtTab } from 'components/xt-tabs/xt-tabs.types'
import { useTasksModule } from 'tasks/tasks-module-hook'
import { useContactsModule } from 'contacts/contacts-module-hook'

interface ICRMTabs {
  disabled: boolean
  customer: ICustomer | null
  isMobile: boolean
  canViewContacts: boolean
  canViewActivities: boolean
  canEditCustomer: boolean
}

enum CRMTabName {
  Contacts = 'Contacts',
  Activities = 'Activities',
}

export const CRMTabs: FC<ICRMTabs> = memo(({ disabled, customer, isMobile, canViewActivities, canViewContacts, canEditCustomer }) => {
  const { TaskList } = useTasksModule()
  const { ContactList } = useContactsModule()

  const tabs: IXtTab[] = [
    {
      hidden: !canViewContacts,
      name: CRMTabName.Contacts,
      template: customer && (
        <ContactList
          disabled={disabled}
          isMobile={isMobile}
          account_name={customer.customer_name}
          account_number={customer.customer_number}
          editableByPermissions={canEditCustomer}
        />
      ),
    },
    {
      hidden: !canViewActivities,
      name: CRMTabName.Activities,
      template: customer && (
        <TaskList
          parentType={TaskParentType.Account}
          parentNumber={customer.customer_number}
          filters={{ account: customer.customer_number }}
          disabled={disabled}
        />
      ),
    },
  ]

  return <XtTabs tabs={tabs} />
})
