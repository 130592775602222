import { IXtMode } from 'common/common.types'

export enum OrderStatus {
  Closed = 'Closed',
  Unreleased = 'Unreleased',
  Open = 'Open',
  Unposted = 'Unposted',
}

export enum OrderType {
  Invoice = 'INV',
  SalesOrder = 'SO',
  TransferOrder = 'TO',
}

export interface IHttpErrorResponseData {
  status: { message: string }
}

export interface IHttpErrorResponse {
  status: number
  statusText: string
  headers: Record<string, string>
  data: IHttpErrorResponseData
}

export interface IServerError extends Error {
  message: string
  response: IHttpErrorResponse
  request: XMLHttpRequest
}

export interface IXtEntityDialogData<T> extends IXtMode {
  data: T
}

export class EmptyResponseError extends Error {}
