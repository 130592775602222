import React, { FC } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { canActivateRouteWithRedirect } from './guarder-route.utils'
import { IGuardedRoute } from './guarded-route.types'

export const GuardedRoute: FC<IGuardedRoute> = ({ path, exact, component: providedComponent, render, guards }) => {
  // We shouldn't provide both component and render to the route.
  const component = render ? undefined : providedComponent
  const route = <Route key={path} exact={exact} path={path} component={component} render={render} />
  const { canActivate, redirectTo } = canActivateRouteWithRedirect(guards)
  if (canActivate) {
    return route
  }
  return redirectTo ? (
    <Route key={path} exact={exact} path={path}>
      <Redirect to={redirectTo} />
    </Route>
  ) : null
}
