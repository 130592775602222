import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { SharedModule } from 'shared/shared-module'
import { CoreModule } from 'core/core.module'
import { ContactsModule } from 'contacts/contacts.module'
import { TasksModule } from 'tasks/tasks.module'
import { AuthModule } from 'auth/auth.module'
import { CharacteristicsModule } from 'characteristics/characteristics.module'
import { CommentsModule } from 'comments/comments.module'
import { DocumentsModule } from 'documents/documents.module'
import { accountsModuleToken } from './accounts.constants'
import { ProspectsModule } from '../prospects/prospects.module'
import { CustomersModule } from '../customers/customers.module'
import { AccountsServicesModule } from './services/accounts-services.module'

export type AccountsModuleDefinition = typeof import('./accounts.exports')

export const AccountsModule: ILazyModule<AccountsModuleDefinition> = {
  name: accountsModuleToken,
  resolver: () => import('./accounts.exports'),
  dependencies: [
    CoreModule,
    SharedModule,
    ContactsModule,
    TasksModule,
    AuthModule,
    DocumentsModule,
    CharacteristicsModule,
    CommentsModule,
    ProspectsModule,
    CustomersModule,
    AccountsServicesModule,
  ],
}
