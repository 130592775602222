import type { IXtMode } from 'common/common.types'
import React, { FC } from 'react'
import { useHistory, useParams } from 'react-router'
import { companiesRoutePath, companiesRoutes } from 'companies/companies.constants'
import { ProspectDetails } from './prospect-details'

export const ProspectDetailsPage: FC<IXtMode> = ({ mode }) => {
  const { prospectNumber } = useParams<{ prospectNumber: string }>()

  const history = useHistory()

  const onCancel = (): void => history.push(`${companiesRoutePath}/${companiesRoutes.prospects}`)

  return <ProspectDetails prospectNumber={prospectNumber} prospectName={null} mode={mode} onCancel={onCancel} />
}
