import { IAsyncRoute } from 'common/routing/routing.types'
import { PageNotFound } from 'page-not-found/page-not-found'
import { maintainShipmentsLabel, maintainShippingRoutePath } from 'maintain-shipping/maintain-shipping.constants'
import { MaintainShippingGuard } from 'maintain-shipping/maintain-shipping.guard'
import { MaintainShipping } from 'maintain-shipping/maintain-shipping'
import { ShippingModule, ShippingModuleDefinition } from './shipping.module'
import { shippingLabel, shippingRoutePath, shippingRoutePaths } from './shipping.constants'
import { ShippingGuard } from './shipping.guard'
import { RecallOrdersToShippingGuard } from './components/recall-orders-to-shipping/recall-orders-to-shipping.guard'
import { ShipOrderGuard } from './components/ship-order/ship-order.guard'
import { PrintDialogsGuard } from './print-dialogs/print-dialogs.guard'
import { IssueToShippingGuard } from './components/issue-to-shipping/issue-to-shipping.guard'

export const shippingRoute: IAsyncRoute<ShippingModuleDefinition> = {
  path: shippingRoutePath,
  name: shippingLabel,
  module: ShippingModule,
  guards: [ShippingGuard],
  childrenRoutesFactory: ({ RecallOrdersToShipping, IssueToShippingList, ShipOrderPage }) => [
    {
      path: shippingRoutePaths.issueToShipping,
      name: 'Issue to Shipping',
      guards: [IssueToShippingGuard],
      children: [{ path: '', exact: true, component: IssueToShippingList }],
    },
    {
      path: 'ship-order',
      name: 'Ship Order',
      guards: [ShipOrderGuard],
      children: [{ path: '', exact: true, component: ShipOrderPage }],
    },
    {
      path: maintainShippingRoutePath,
      name: maintainShipmentsLabel,
      guards: [MaintainShippingGuard],
      children: [{ path: '', exact: true, component: MaintainShipping }],
    },
    {
      path: shippingRoutePaths.recallOrders,
      name: 'Recall Orders',
      guards: [RecallOrdersToShippingGuard],
      children: [{ path: '', exact: true, component: RecallOrdersToShipping }],
    },
    // TODO remove me
    {
      path: '',
      name: 'Labels & Forms',
      type: 'buttons',
      guards: [PrintDialogsGuard],
    },
    {
      path: '*',
      component: PageNotFound,
      exact: true,
    },
  ],
}
