import { SvgIconIds } from '../../svg-icon/svg-icon.types'

export enum IconPosition {
  Left,
  Right,
}

export enum XtButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Accent = 'accent',
}
export interface IXtButton {
  icon?: SvgIconIds
  iconClass?: string
  label: string
  labelClass?: string
  className?: string
  onClick?: () => void
  disabled?: boolean
  type?: 'submit' | 'button'
  variant?: XtButtonVariant
  hidden?: boolean
  loading?: boolean
  iconPosition?: IconPosition
}

export enum XtButtonLabels {
  Yes = 'Yes',
  No = 'No',
  OK = 'OK',
  Cancel = 'Cancel',
  Next = 'Next',
  Previuos = 'Previous',
  Back = 'Back',
  Save = 'Save',
  Close = 'Close',
}
