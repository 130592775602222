import React, { FC, useState } from 'react'
import { useCoreModule } from 'core/core-module-hook'
import { useXtForm } from 'common/hooks/form/form'
import { useProductsModule } from 'products/products-module-hook'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { cls } from 'common/utils/utils'
import { ItemOption } from 'products/items/items.types'
import { BomCopyDialogFormFields, BomCopyDialogFormFieldsTargetNumber, BomItem } from './bom-copy-dialog.constants'
import { generateCopyBomPayload } from './bom-copy-dialog.utils'
import { IBomCopyDialogFormItemState, IBomCopyDialogFormState, IBomCopyDialogParams } from './bom-copy-dialog.types'
import * as styles from './bom-copy-dialog.module.scss'
import { itemNumberFilters } from '../bom-item/bom-item.constants'
import { getItemInputLabel } from '../bom-item/bom-item.utils'

export const BomCopyDialog: FC<IBomCopyDialogParams> = ({ bomItem, onClose, refresh }) => {
  const { itemNumber, ...rest } = bomItem
  const { control: disabledControl } = useXtForm<IBomCopyDialogFormState>({
    mode: 'onBlur',
    defaultValues: {
      [BomCopyDialogFormFields.SourceItemNumber]: itemNumber,
      [BomCopyDialogFormFields.Item]: { ...rest.item, uom: rest.item.item_inventory_uom, description1: rest.item.item_description },
    },
  })
  const {
    control,
    formState: { isSubmitting, isDirty },
    setValue,
    handleSubmit,
  } = useXtForm<IBomCopyDialogFormItemState>({
    mode: 'onBlur',
    defaultValues: {
      [BomCopyDialogFormFieldsTargetNumber.TargetItemNumber]: null,
      [BomCopyDialogFormFieldsTargetNumber.Item]: null,
    },
  })

  const [loading] = useState<boolean>(false)
  const disabled = isSubmitting || loading
  const { FormXtItemNumber, ItemsUtilsService, BomService } = useProductsModule()
  const { ErrorHandler, ToastService } = useCoreModule()

  const onSubmit = async (val: IBomCopyDialogFormItemState) => {
    const response = await BomService.copy(
      generateCopyBomPayload({
        [BomCopyDialogFormFields.SourceItemNumber]: itemNumber,
        targetItemNumber: val.targetItemNumber,
      })
    )
    ToastService.showSuccess(response)
    void refresh()
    onClose()
  }

  const onItemChange = async (item: ItemOption | null): Promise<void> => {
    try {
      setValue(BomCopyDialogFormFieldsTargetNumber.TargetItemNumber, item, { shouldDirty: true, shouldValidate: true })
      setValue(BomCopyDialogFormFieldsTargetNumber.Item, item, { shouldDirty: true, shouldValidate: true })
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.bomCopyDialog}>
      <div className={cls(styles.header, 'xt-section-border')}>
        <h3 className="xt-subPage-title" title={'Copy BOM'}>
          Copy BOM
        </h3>
        <div className={styles.headerButtons}>
          <XtButton label="Cancel" onClick={onClose} />
          <XtButton loading={disabled} label="Save" type="submit" disabled={disabled || !isDirty} />
        </div>
      </div>
      <div className={styles.bomFields}>
        <div className={styles.fields}>
          <p>{BomItem.SourceItem} : </p>
          <FormXtItemNumber required control={disabledControl} name={BomCopyDialogFormFields.Item} />
        </div>
        <div className={styles.fields}>
          <p>{BomItem.TragetItem} : </p>
          <FormXtItemNumber
            required
            control={control}
            name={BomCopyDialogFormFieldsTargetNumber.Item}
            isEditMode={true}
            loadOptions={ItemsUtilsService.loadItemOptions}
            onChange={onItemChange}
            filters={itemNumberFilters}
            getInputLabel={getItemInputLabel}
          />
        </div>
      </div>
    </form>
  )
}
