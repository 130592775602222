import React, { ForwardRefExoticComponent, KeyboardEventHandler, Ref } from 'react'
import { Key } from 'ts-key-enum'
import * as styles from './xt-quick-add-button.module.scss'
import { SvgIcon } from '../../svg-icon/svg-icon'
import { SvgIconIds } from '../../svg-icon/svg-icon.types'
import { cls } from '../../../common/utils/utils'

export interface IXtQuickAddButtonProps {
  onClick: () => void
  disabled?: boolean
  className?: string
  hidden?: boolean
  ref?: Ref<HTMLButtonElement>
  iconId?: SvgIconIds
}

export const XtQuickAddButton: ForwardRefExoticComponent<IXtQuickAddButtonProps> = React.forwardRef<
  HTMLButtonElement,
  IXtQuickAddButtonProps
>(({ onClick, disabled = false, className, hidden, iconId = SvgIconIds.ADD_CIRCLE }, ref) => {
  const handleClick: () => void = () => {
    if (disabled || typeof onClick !== 'function') {
      return
    }
    onClick()
  }

  const onKeyDown: KeyboardEventHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === Key.Enter) {
      handleClick()
    }
  }

  return (
    <button
      hidden={hidden}
      disabled={disabled}
      type="button"
      onKeyDown={onKeyDown}
      onClick={handleClick}
      className={cls(styles.xtQuickAddButton, className)}
      ref={ref}
    >
      <SvgIcon className={styles.xtQuickAddButtonIcon} iconId={iconId} />
    </button>
  )
})
