import React, { FC } from 'react'
import { useTasksModule } from 'tasks/tasks-module-hook'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { useOpportunitiesModule } from 'activities/opportunities/opportunities-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { UserPermission } from 'auth/auth.types'
import { IXtTab } from 'components/xt-tabs/xt-tabs.types'
import { IActvitiesTabParams } from './activities-tab.types'

export const ActivitiesTab: FC<IActvitiesTabParams> = ({ parentType, parentNumber, disabled }) => {
  const { TaskList } = useTasksModule()
  const { OpportunitiesList } = useOpportunitiesModule()
  const { PermissionsService } = useAuthModule()
  const tasksFilters = { prospect: parentNumber }
  const opportunitiesFilters = { account: parentNumber }
  const canViewTasks = PermissionsService.hasSomePermission([
    UserPermission.MaintainAllTaskItems,
    UserPermission.MaintainPersonalTaskItems,
    UserPermission.ViewAllTaskItems,
    UserPermission.ViewPersonalTaskItems,
  ])

  const canViewOpportunities = PermissionsService.hasSomePermission([
    UserPermission.ViewAllOpportunities,
    UserPermission.MaintainAllOpportunities,
    UserPermission.MaintainPersonalOpportunities,
    UserPermission.ViewPersonalOpportunities,
  ])

  const taskTab: IXtTab = {
    hidden: !canViewTasks,
    name: 'Tasks',
    template: <TaskList disabled={disabled} filters={tasksFilters} parentType={parentType} parentNumber={parentNumber} />,
  }

  const opportunitiesTab: IXtTab = {
    hidden: !canViewOpportunities,
    name: 'Opportunities',
    template: <OpportunitiesList filters={opportunitiesFilters} />,
  }
  const tabs = [taskTab, opportunitiesTab]
  return <XtTabs tabs={tabs} />
}
