import hexToRgba from 'hex-to-rgba'
import { FilterType, IFilter } from 'components/pagefilter/filter/filter.types'
import {
  defineAutocompleteOption,
  getAutocompleteInputLabelAsId,
  renderColumnOption,
} from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { IDocumentsUtilsService } from 'documents/documents-utils.service'
import { IKanbanTaskCard, IKanbanTaskColumn } from './tasks-board.types'
import { IKanbanTask, IKanbanTaskPayload, IKanbanTasksFilterPanel, KanbanTasksFilter, KanbanTasksLabel } from '../tasks-kanban.types'
import { parseServerDate } from '../../../common/utils/date.utils'

function convertToCard(task: IKanbanTask): IKanbanTaskCard {
  const { task_id, task_number, task_name, due_date, assignees, editable, task_status } = task
  const assigneesValue: string = assignees ? assignees.map(({ user }) => user).join(',') : ''

  return {
    id: task_id,
    name: task_name,
    number: task_number,
    status: task_status,
    dueDate: parseServerDate(due_date),
    assignees: assigneesValue,
    draggable: editable,
    editable,
  }
}

export function convertToKanbanColumn(task: IKanbanTaskPayload): IKanbanTaskColumn {
  const data: IKanbanTaskCard[] = (task.tasks ?? []).map((currentTask) => convertToCard(currentTask))

  return {
    data,
    id: task.priority,
    title: task.priority,
    taskCounter: task.task_counter ?? 0,
    styles: task.priority_color ? { headerColor: task.priority_color, bodyColor: hexToRgba(task.priority_color, '0.5') } : undefined,
  }
}

export function defineDefaultFilters(username: string | null): IKanbanTasksFilterPanel {
  return {
    [KanbanTasksFilter.ShowCompleted]: false,
    [KanbanTasksFilter.Owner]: null,
    [KanbanTasksFilter.AssignedTo]: null,
    [KanbanTasksFilter.Account]: null,
    [KanbanTasksFilter.Project]: null,
    [KanbanTasksFilter.DueDate]: null,
    [KanbanTasksFilter.DueBefore]: null,
    [KanbanTasksFilter.DueAfter]: null,
    [KanbanTasksFilter.CompletedOnly]: false,
    [KanbanTasksFilter.User]: defineAutocompleteOption(username),
  }
}

export function resolveFilters({ loadAccountOptions, loadProjectOptions, loadUserOptions }: IDocumentsUtilsService): IFilter[] {
  return [
    {
      type: FilterType.Autocomplete,
      label: KanbanTasksLabel.User,
      fieldName: KanbanTasksFilter.User,
      autocompleteProps: {
        loadOptions: loadUserOptions,
        renderOption: renderColumnOption,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: KanbanTasksLabel.Owner,
      fieldName: KanbanTasksFilter.Owner,
      autocompleteProps: {
        loadOptions: loadUserOptions,
        renderOption: renderColumnOption,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: KanbanTasksLabel.AssignedTo,
      fieldName: KanbanTasksFilter.AssignedTo,
      autocompleteProps: {
        loadOptions: loadUserOptions,
        renderOption: renderColumnOption,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: KanbanTasksLabel.Account,
      fieldName: KanbanTasksFilter.Account,
      autocompleteProps: {
        loadOptions: loadAccountOptions,
      },
    },
    {
      type: FilterType.Autocomplete,
      label: KanbanTasksLabel.Project,
      fieldName: KanbanTasksFilter.Project,
      autocompleteProps: {
        loadOptions: loadProjectOptions,
        renderOption: renderColumnOption,
        getInputLabel: getAutocompleteInputLabelAsId,
      },
    },
    {
      type: FilterType.Date,
      label: KanbanTasksLabel.DueDate,
      fieldName: KanbanTasksFilter.DueDate,
    },
    {
      type: FilterType.Date,
      label: KanbanTasksLabel.DueBefore,
      fieldName: KanbanTasksFilter.DueBefore,
    },
    {
      type: FilterType.Date,
      label: KanbanTasksLabel.DueAfter,
      fieldName: KanbanTasksFilter.DueAfter,
    },
  ]
}

export function resolveMobileFilters(documentsUtilsService: IDocumentsUtilsService): IFilter[] {
  return [
    {
      type: FilterType.Checkbox,
      label: 'Show Completed',
      fieldName: KanbanTasksFilter.ShowCompleted,
    },
    ...resolveFilters(documentsUtilsService),
  ]
}
