import { useCallback, useEffect, useState } from 'react'
import { IFilter, IFilterFormState } from 'components/pagefilter/filter/filter.types'
import { useCoreModule } from 'core/core-module-hook'

export interface IFilterHookState {
  filterOpen: boolean
  filters: IFilter[]
  initialized: boolean
}

export interface IFilterHook<Filters extends IFilterFormState> extends IFilterHookState {
  onFiltersSubmit(filters: Filters): void
  openFilters: VoidFunction
  closeFilters: VoidFunction
  filtersAvailable: boolean
  changeFilters(updatedFilters: IFilter[]): void
}

export function useFilter<Filters extends IFilterFormState>(
  initializer: () => Promise<IFilter[]> | IFilter[],
  filter: (filters: Filters) => void | Promise<void>
): IFilterHook<Filters> {
  const { ErrorHandler } = useCoreModule()

  const [state, setState] = useState<IFilterHookState>({ filterOpen: false, filters: [], initialized: false })

  const openDialog: VoidFunction = () => setState((prevState) => ({ ...prevState, filterOpen: true }))
  const closeDialog: VoidFunction = () => setState((prevState) => ({ ...prevState, filterOpen: false }))

  const onFilter = useCallback<(filters: Filters) => void>(
    async (filters) => {
      setState((prevState) => ({ ...prevState, filterOpen: false }))
      try {
        await filter(filters)
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    },
    [ErrorHandler, filter]
  )

  const initFilters: () => Promise<void> = async () => {
    try {
      const filters = await initializer()
      setState((prevState) => ({ ...prevState, filters, initialized: true }))
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const changeFilters = useCallback(
    (updatedFilters: IFilter[]) => {
      if (state.initialized) {
        setState((prev) => ({ ...prev, filters: updatedFilters }))
      }
    },
    [state.initialized]
  )

  useEffect(() => {
    void initFilters()
  }, [])

  return {
    ...state,
    filtersAvailable: Boolean(state.filters.length),
    onFiltersSubmit: onFilter,
    openFilters: openDialog,
    closeFilters: closeDialog,
    changeFilters,
  }
}
