import { Overwrite } from 'utility-types'
import { DateSchema, NumberSchema, ObjectSchema } from 'yup'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IItemSiteDistribution, ItemSiteDistributionOption } from 'products/items/items.types'
import {
  IXtTableColumn,
  IXtTableColumnEditable,
  IXtTableColumnNumberEditable,
  IXtTableColumnSelectEditable,
} from 'components/table/table-head/table-head.types'
import { TableStateHookItem } from 'shared/hooks/table-state-hook'
import { IAppliedCharacteristic } from 'characteristics/characteristics.types'
import { IAttachedDocumentWithFile } from 'documents/documents.types'
import { CommentItem } from 'comments/comment/comment.types'
import { ILotSerialHook } from './hooks/lot-serial-hook/lot-serial-hook.types'

export enum HeaderInfoLabelKey {
  QtyToAssign = 'QtyToAssign',
  QtyAssigned = 'QtyAssigned',
  QtyRemaining = 'QtyRemaining',
}

export interface IHeaderInfoLabels {
  [HeaderInfoLabelKey.QtyToAssign]: string
  [HeaderInfoLabelKey.QtyAssigned]: string
  [HeaderInfoLabelKey.QtyRemaining]: string
}

export interface ILotSerialParams {
  lotSerialState: ILotSerialHook
  submitted: boolean
  headerInfoLabels?: IHeaderInfoLabels
  className?: string
}

export enum LotSerialField {
  Location = 'location',
  LotSerialNumber = 'lotSerialNumber',
  ExpirationDate = 'expirationDate',
  WarrantyDate = 'warrantyDate',
  Quantity = 'quantity',
  QuantityBefore = 'quantityBefore',
  Characteristics = 'lotserial_characteristics',
  Comments = 'comments',
  Notes = 'notes',
  Documents = 'documents',
}

export interface ILotSerialEntry {
  [LotSerialField.Location]: ItemSiteDistributionOption | null
  [LotSerialField.LotSerialNumber]: LotSerialOption | null
  [LotSerialField.ExpirationDate]: Date | null
  [LotSerialField.WarrantyDate]: Date | null
  [LotSerialField.Quantity]: number
  [LotSerialField.QuantityBefore]: number
  [LotSerialField.Characteristics]: IAppliedCharacteristic[] | null
  [LotSerialField.Comments]: CommentItem[] | null
  [LotSerialField.Notes]: string
  [LotSerialField.Documents]?: IAttachedDocumentWithFile[]
}

export type LotSerialDefaultColumnsKeys =
  | keyof Omit<
      ILotSerialEntry,
      | LotSerialField.LotSerialNumber
      | LotSerialField.Location
      | LotSerialField.Quantity
      | LotSerialField.Comments
      | LotSerialField.Characteristics
      | LotSerialField.Documents
      | LotSerialField.Notes
    >
  | 'actions'

export interface ILotSerialColumnSchema {
  [LotSerialField.Location]: IXtTableColumnSelectEditable<LotSerialEntryRow, ItemSiteDistributionOption> | undefined
  [LotSerialField.LotSerialNumber]: IXtTableColumnSelectEditable<LotSerialEntryRow, LotSerialOption> | undefined
  [LotSerialField.ExpirationDate]: IXtTableColumnEditable<LotSerialEntryRow> | undefined
  [LotSerialField.WarrantyDate]: IXtTableColumnEditable<LotSerialEntryRow> | undefined
  [LotSerialField.Quantity]: IXtTableColumnNumberEditable<LotSerialEntryRow>
  [LotSerialField.QuantityBefore]: IXtTableColumn<LotSerialEntryRow> | undefined
  characteristics: IXtTableColumn<LotSerialEntryRow> | undefined
  documents: IXtTableColumn<LotSerialEntryRow> | undefined
  actions: IXtTableColumn<LotSerialEntryRow>
}

export type LotSerialEntryRow = TableStateHookItem<ILotSerialEntry & { id: string }>

export enum LotSerialAction {
  Edit = 'Edit',
  Delete = 'Delete',
  Characteristics = 'Characteristics',
  Documents = 'Documents',
}

export type LotSerialOption = IXtAutocompleteOption &
  Overwrite<
    Pick<IItemSiteDistribution, 'location_name' | 'expiry_date' | 'warranty_date' | 'qty_before' | 'lotserial'>,
    { location_name?: string; expiry_date?: Date | null; warranty_date?: Date | null; qty_before?: number; lotserial: string }
  >

export enum NewLotSerialButtonLabel {
  Location = 'Assign Location',
  LocationAndLotSerial = 'Assign Location/Lot/Serial #',
  LotSerial = 'Assign Lot/Serial #',
}

export interface ILotSerialValidation {
  [LotSerialField.Quantity]: NumberSchema
  [LotSerialField.LotSerialNumber]?: ObjectSchema
  [LotSerialField.Location]?: ObjectSchema
  [LotSerialField.ExpirationDate]?: DateSchema<Date | undefined | null>
  [LotSerialField.WarrantyDate]?: DateSchema<Date | undefined | null>
}
