import { IShipping, IShippingUpdateInstance } from 'shipping/shipping.types'
import { dateToServerFormat } from 'common/utils/date.utils'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { ShipmentHeaderForm, ShipmentHeaderFormFilled, ShippingInformationFormField } from './shipping-information-dialog.types'

export function isShipmentHeaderFormFilled(formData: ShipmentHeaderForm): formData is ShipmentHeaderFormFilled {
  const { freight_currency, shipping_form, ship_via } = formData

  return Boolean(freight_currency && shipping_form && ship_via)
}
export function convertDataToInstance(shipment: IShipping | null, formData: ShipmentHeaderFormFilled): IShippingUpdateInstance {
  const { ship_date, ship_via, freight, freight_currency, shipping_form, notes } = formData

  return {
    shipment_number: shipment?.shipment_number ?? '',
    tracking_number: shipment?.tracking_number ?? '',
    ship_date: dateToServerFormat(ship_date),
    ship_via: ship_via.id,
    freight: freight ?? 0,
    freight_currency: freight_currency.id,
    shipping_form: shipping_form.id,
    notes,
  }
}

export function defineShippingInformationFormData(data: IShipping | null): ShipmentHeaderForm {
  return {
    [ShippingInformationFormField.ShipDate]: data?.ship_date ? new Date(data.ship_date) : null,
    [ShippingInformationFormField.ShipVia]: defineAutocompleteOption(data?.ship_via),
    [ShippingInformationFormField.ShippingForm]: defineAutocompleteOption(data?.shipping_form),
    [ShippingInformationFormField.Freight]: data?.freight ?? 0,
    [ShippingInformationFormField.Currency]: defineAutocompleteOption(data?.freight_currency),
    [ShippingInformationFormField.Notes]: data?.notes ?? '',
  }
}
