import React, { FC, useEffect, useRef, useState } from 'react'
import { debounceTime, distinctUntilChanged, map, pluck, startWith, tap } from 'rxjs/operators'
import { defer, Observable, Subscription } from 'rxjs'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import {
  FormDatePicker,
  FormField,
  FormRadioGroup,
  FormSelectField,
  FormXtAutocompleteWithTextInputs,
} from 'common/utils/form/form.components'
import { useXtSelect, DecimalField, DecimalFormField, getAutocompleteInputLabelAsId, renderColumnOption } from 'components/controls'
import { cls } from 'common/utils/utils'
import { useXtForm } from 'common/hooks/form/form'
import { ControlMethodOption, CostingMethodOption, ItemOption, ItemSiteOption } from 'products/items/items.types'
import { useProductsModule } from 'products/products-module-hook'
import { IItemSitesFilters } from 'products/items/item-sites.service'
import { confirmationMessages, globalConstants } from 'common/constants'
import { useCoreModule } from 'core/core-module-hook'
import { isEqual } from 'common/utils/object.utils'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { XtPrompt } from 'components/xt-prompt'
import { calculateInventoryAdjustmentMeta, convertToInventoryAdjustmentPayload } from './inventory-adjustment-details.utils'
import {
  defaultInventoryAdjustmentMeta,
  initialInventoryAdjustmentFormState,
  inventoryAdjustmentOptions,
  AdditionalFieldsWithInput,
  inventoryAdjustmentPageTitle,
} from './inventory-adjustment-details.constants'
import {
  IInventoryAdjustmentDetailsParams,
  IInventoryAdjustmentFormState,
  IInventoryAdjustmentMeta,
  InventoryAdjustmentField,
  InventoryAdjustmentLabel,
} from './inventory-adjustment-details.types'
import { InventoryAdjustmentDetailsTabs } from './inventory-adjustment-details-tabs/inventory-adjustment-details-tabs'

import * as styles from './inventory-adjustment-details.module.scss'
import { defineInventoryAdjustmentFormSchema } from './inventory-adjustment-details.validation'
import { useLotSerial } from './lot-serial/hooks/lot-serial-hook/lot-serial-hook'
import { useInventoryAdjustmentModule } from '../inventory-adjustments-module.hook'
import { DefaultFormErrorMeesage } from '../inventory-adjustments.constants'

export const InventoryAdjustmentDetails: FC<IInventoryAdjustmentDetailsParams> = ({ className, onClose }) => {
  const { ItemsUtilsService, ItemSitesService } = useProductsModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { InventoryAdjustmentService } = useInventoryAdjustmentModule()
  useDocumentTitle(inventoryAdjustmentPageTitle)
  const { options: sites, reset: resetSites } = useXtSelect(async (filters?: IItemSitesFilters) => {
    const { data } = await ItemsUtilsService.loadSiteOptions(undefined, undefined, undefined, filters)

    return data.filter(
      ({ control_method, cost_method }) => control_method !== ControlMethodOption.None && cost_method !== CostingMethodOption.Job
    )
  })

  const [{ before, after }, setAdjustmentMetadata] = useState<IInventoryAdjustmentMeta>(defaultInventoryAdjustmentMeta)
  const [isFormChanging, setIsFormChanging] = useState<boolean>(false)

  const availableItemsRef = useRef<number>(defaultInventoryAdjustmentMeta.before)

  const formMethods = useXtForm<IInventoryAdjustmentFormState>({
    defaultValues: initialInventoryAdjustmentFormState,
    validationSchema: defineInventoryAdjustmentFormSchema(availableItemsRef, before),
    mode: 'onBlur',
  })

  const qtyToAssignRef = useRef<Observable<number>>()

  const {
    control,
    formState: { isDirty, isSubmitting, isSubmitted },
    setValue,
    watch,
    handleSubmit,
    formValueChanges$,
    getValues,
    trigger,
    reset: mainFormReset,
  } = formMethods
  const itemOption = watch(InventoryAdjustmentField.Item)

  useEffect(() => {
    const metaChanges$ = formValueChanges$.pipe(
      distinctUntilChanged((prev, current) => {
        return (
          prev[InventoryAdjustmentField.Item]?.id === current[InventoryAdjustmentField.Item]?.id &&
          prev[InventoryAdjustmentField.Site]?.id === current[InventoryAdjustmentField.Site]?.id &&
          prev[InventoryAdjustmentField.Method] === current[InventoryAdjustmentField.Method] &&
          prev[InventoryAdjustmentField.DistributionQty] === current[InventoryAdjustmentField.DistributionQty]
        )
      }),
      tap(() => setIsFormChanging(true)),
      debounceTime(globalConstants.inputDebounce),
      map(({ site, distribution_qty, method }) => calculateInventoryAdjustmentMeta(site, distribution_qty, method))
    )

    qtyToAssignRef.current = defer(() => {
      const { site, distribution_qty, method } = getValues()
      const meta = calculateInventoryAdjustmentMeta(site, distribution_qty, method)
      return metaChanges$.pipe(
        tap(() => setIsFormChanging(false)),
        startWith(meta),
        pluck('qtyToAssign'),
        distinctUntilChanged()
      )
    })

    const sub = new Subscription()

    sub.add(metaChanges$.subscribe(setAdjustmentMetadata))

    sub.add(
      metaChanges$.pipe(distinctUntilChanged((prev, current) => isEqual(prev, current))).subscribe(async ({ before: availableItems }) => {
        availableItemsRef.current = availableItems
        await trigger(InventoryAdjustmentField.DistributionQty)
      })
    )

    return () => sub.unsubscribe()
  }, [formValueChanges$, getValues, trigger])

  const lotSerialState = useLotSerial(qtyToAssignRef.current)

  const { reset, data: tableData, quantityDecimalScale, loading, validateAsync } = lotSerialState

  const onCancel = (): void => {
    // eslint-disable-next-line no-restricted-globals
    if (onClose) {
      onClose()
    }
  }

  const onItemChange = async (item: ItemOption): Promise<void> => {
    try {
      await resetSites({ itemNumber: item?.item_number })
      reset(item, null)
      setValue(InventoryAdjustmentField.Item, item, { shouldDirty: true, shouldValidate: true })
      setValue(
        InventoryAdjustmentField.Description,
        item !== null ? item.description1 || '' : initialInventoryAdjustmentFormState.description1,
        {
          shouldDirty: true,
          shouldValidate: true,
        }
      )
      setValue(InventoryAdjustmentField.Uom, item !== null ? item.uom || '' : initialInventoryAdjustmentFormState.UOM, {
        shouldDirty: true,
        shouldValidate: true,
      })
      setValue(InventoryAdjustmentField.Site, null, { shouldDirty: true, shouldValidate: true })
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const resetAll: VoidFunction = () => {
    reset(null, null)
    mainFormReset()
  }

  const onSaveForm: (formData: IInventoryAdjustmentFormState) => Promise<void> = async (formData) => {
    try {
      const { isValid, error } = await validateAsync()
      if (!isValid) {
        ToastService.showError(error ?? DefaultFormErrorMeesage)
        return
      }
      const payload = convertToInventoryAdjustmentPayload(formData, tableData)

      const message = await InventoryAdjustmentService.postAdjustment(payload)

      ToastService.showSuccess(message)
      resetAll()
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const onSiteChange = async ({ item_number, site }: ItemSiteOption): Promise<void> => {
    try {
      const itemSite = await ItemSitesService.getItemSite(item_number, site)
      const itemSiteOption = { id: itemSite.site, label: itemSite.site, ...itemSite }
      setValue(InventoryAdjustmentField.Site, itemSiteOption, { shouldDirty: true, shouldValidate: true })
      const { item_number: item } = getValues()
      reset(item, itemSite)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  return (
    // Todo: Remove the top wrapper if dialog will needed.
    <div className="xt-content">
      <form className={cls(styles.inventoryAdjustmentsForm, className)}>
        <div className={cls('xt-page-header', 'xt-sticky-header', styles.inventoryAdjustmentsHeader)}>
          <h3 className="xt-page-title">Inventory Adjustment</h3>
          <div className={!onClose ? styles.containerHeaderFormButtons : styles.containerHeaderDialogButtons}>
            <XtButton label="Cancel" onClick={onCancel} hidden={!onClose} />
            <XtButton
              label="Post"
              onClick={handleSubmit(onSaveForm)}
              disabled={!isDirty || isSubmitting}
              loading={loading || isFormChanging}
            />
          </div>
        </div>
        <div className={styles.formContent}>
          <div className={styles.formFields}>
            <FormDatePicker
              control={control}
              name={InventoryAdjustmentField.TransactionDate}
              label={InventoryAdjustmentLabel.TransactionDate}
            />
            <FormXtAutocompleteWithTextInputs
              inputProps={AdditionalFieldsWithInput}
              required
              control={control}
              loadOptions={ItemsUtilsService.loadItemOptions}
              renderOption={renderColumnOption}
              getInputLabel={getAutocompleteInputLabelAsId}
              onChange={onItemChange}
            />
            <FormSelectField
              required
              control={control}
              name={InventoryAdjustmentField.Site}
              label={InventoryAdjustmentLabel.Site}
              disabled={!itemOption}
              options={sites}
              clearable={false}
              onChange={onSiteChange}
            />
            <DecimalFormField
              required
              control={control}
              name={InventoryAdjustmentField.DistributionQty}
              label={InventoryAdjustmentLabel.DistributionQty}
              fixedDecimalScale={quantityDecimalScale}
            />
            <FormRadioGroup
              className={styles.inventoryAdjustmentOptions}
              options={inventoryAdjustmentOptions}
              label=""
              control={control}
              name={InventoryAdjustmentField.Method}
            />
          </div>
          <div className={styles.formFields}>
            <DecimalField disabled value={before} label={InventoryAdjustmentLabel.Before} />
            <DecimalField disabled value={after} label={InventoryAdjustmentLabel.After} />

            <FormField control={control} name={InventoryAdjustmentField.Document} label={InventoryAdjustmentLabel.Document} />
          </div>
        </div>

        <InventoryAdjustmentDetailsTabs formMethods={formMethods} lotSerialState={lotSerialState} submitted={isSubmitted} />
      </form>

      <XtPrompt showPrompt={isDirty} message={confirmationMessages.unsavedChanges} />
    </div>
  )
}
