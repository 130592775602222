import { IAsyncRoute, IRoute } from 'common/routing/routing.types'
import { XtMode } from 'common/common.types'
import { buildPageComponent } from 'common/routing/routing.utils'

import { PageNotFound } from 'page-not-found/page-not-found'
import { ItemSourceModule, ItemSourceModuleDefinition } from './item-source.module'
import { itemSourcesRoutePath } from './item-source.constants'
import { ItemSourcesGuard } from './item-source.guard'
import { ItemSourceDetailsGuard } from './item-source-details/item-source-details.guard'

export function itemSourceRouteFactory({ ItemSourceDetails, ItemSourceList }: ItemSourceModuleDefinition): IRoute[] {
  return [
    {
      path: '',
      exact: true,
      component: ItemSourceList,
    },
    {
      path: `/:id/${XtMode.Edit}`,
      exact: true,
      component: (props) => buildPageComponent(props, XtMode.Edit, ItemSourceDetails),
      guards: [ItemSourceDetailsGuard],
    },
    {
      path: `/:id/${XtMode.View}`,
      exact: true,
      component: (props) => buildPageComponent(props, XtMode.View, ItemSourceDetails),
    },
    {
      path: `/${XtMode.New}`,
      exact: true,
      component: (props) => buildPageComponent(props, XtMode.New, ItemSourceDetails),
      guards: [ItemSourceDetailsGuard],
    },
    {
      path: '*',
      component: PageNotFound,
      exact: true,
    },
  ]
}

export const itemSourcesRoute: IAsyncRoute<ItemSourceModuleDefinition> = {
  path: itemSourcesRoutePath,
  name: 'Item Sources',
  module: ItemSourceModule,
  guards: [ItemSourcesGuard],
  childrenRoutesFactory: itemSourceRouteFactory,
}
