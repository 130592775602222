import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { cls } from 'common/utils/utils'
import { useTable } from 'common/hooks/useTable'
import { XtList } from 'components/list/list'
import { IPaginationData, IPaginationParams } from 'common/common.types'
import { LoaderMessage, xsMq } from 'common/constants'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import * as styles from './inventory-list.module.scss'
import {
  inventoryAvailabilityPageTitle,
  inventoryAvailabilityWorkbenchPageTitle,
  inventoryColumns,
  itemNumberFilter,
} from './inventory-list.constants'
import { IInventoryListFilters, IInventoryLookup, IInventoryLookupParams } from './inventory-list.types'
import { convertToTableItems } from './inventory-list.utils'
import { WorkbenchFilterComponent } from './filter/inventory-list-filter'
import { useProductsModule } from '../../products-module-hook'
import { ItemOption } from '../../items/items.types'
import { OnlyShowPositiveQuantity } from './filter/inventory-list-filter.constants'

type FetchItemsType = (
  filters: IInventoryListFilters,
  pagination: IPaginationParams,
  sortOptions?: ISortOption[]
) => Promise<IPaginationData<IInventoryLookup>>

export const InventoryList: FC = () => {
  const [currentItem, setItem] = useState<ItemOption | null>(null)
  useDocumentTitle(inventoryAvailabilityPageTitle)
  const { ItemsService, XtItemNumber, ItemsUtilsService } = useProductsModule()

  const fetchItems = useCallback<FetchItemsType>(
    async (filters, pagination, sortOptions) => {
      if (!filters.itemNumber) {
        return {
          data: [],
          total: 0,
        }
      }
      const { itemNumber: _, ...requestFilters } = filters
      const { total, data } = await ItemsService.getInventoryAvailabilityItems(filters.itemNumber, pagination, requestFilters, sortOptions)
      return {
        data: convertToTableItems(data),
        total,
      }
    },
    [ItemsService]
  )

  const { state, pagination, filter, sort } = useTable<IInventoryLookup, IInventoryListFilters>(
    { itemNumber: currentItem?.item_number ?? null },
    fetchItems
  )

  const filterData = useCallback(
    async (filters: IInventoryLookupParams): Promise<void> => {
      filter({ ...filters, itemNumber: currentItem?.item_number ?? null })
    },
    [currentItem?.item_number, filter]
  )

  const handleItemNumberChange = useCallback(
    (item: ItemOption | null) => {
      setItem(item)
      void filter({ ...state.filters, itemNumber: item?.item_number ?? null })
    },
    [filter, state.filters]
  )

  const onOnlyShowPositiveQtyChange = (showpositiveonly: boolean) => {
    void filter({ ...state.filters, showpositiveonly })
  }

  const onShowFilterChange = (filterString: string, value: boolean) => {
    if (filterString === 'showShortages') {
      let showShortages = value
      void filter({ ...state.filters, showShortages })
      return
    }
    if (filterString === 'showReorder') {
      let showReorder = value
      void filter({ ...state.filters, showReorder })
      if (value === false) {
        let ignoreReorderAtZero = false
        void filter({ ...state.filters, showReorder, ignoreReorderAtZero })
      }
      return
    }
    if (filterString === 'ignoreReorderAtZero') {
      let ignoreReorderAtZero = value
      void filter({ ...state.filters, ignoreReorderAtZero })
      return
    }
    let byVendor = value
    void filter({ ...state.filters, byVendor })
    return
  }

  const isMobile = useMediaQuery(xsMq)

  return (
    <div className={cls('xt-content', styles.listContent)}>
      <div className="xt-page-header">
        <h1 className="xt-page-title">{inventoryAvailabilityWorkbenchPageTitle}</h1>
      </div>
      <div className={styles.filtersContainer}>
        <XtItemNumber
          isEditMode
          value={currentItem}
          onChange={handleItemNumberChange}
          loadOptions={ItemsUtilsService.loadItemOptions}
          filters={itemNumberFilter}
        />
        <div className={styles.workbenchFilters}>
          <WorkbenchFilterComponent onFilter={filterData} disabled={!currentItem} />
          <XtCheckbox
            className={styles.showPositiveQty}
            label={OnlyShowPositiveQuantity}
            value={Boolean(state?.filters?.showpositiveonly)}
            disabled={!currentItem || state.loading}
            onChange={onOnlyShowPositiveQtyChange}
          />
        </div>
        <div className={styles.showFiltersSection}>
          <div className={styles.showFilters}>
            <p className={styles.showText}>Show</p>
            <XtCheckbox
              className={styles.showPositiveQty}
              label="Shortages"
              value={Boolean(state?.filters?.showShortages)}
              disabled={!currentItem || state.loading}
              onChange={(value) => onShowFilterChange('showShortages', value)}
            />
            <XtCheckbox
              className={styles.showPositiveQty}
              label="Reorder Exceptions"
              value={Boolean(state?.filters?.showReorder)}
              disabled={!currentItem || state.loading}
              onChange={(value) => onShowFilterChange('showReorder', value)}
            />
            <XtCheckbox
              className={styles.showPositiveQty}
              label="Ignore Reorder at 0"
              value={Boolean(state?.filters?.ignoreReorderAtZero)}
              disabled={!currentItem || state.loading || !state?.filters?.showReorder}
              onChange={(value) => onShowFilterChange('ignoreReorderAtZero', value)}
            />
            <XtCheckbox
              className={styles.showPositiveQty}
              label="By Source Vendor"
              value={Boolean(state?.filters?.byVendor)}
              disabled={!currentItem || state.loading}
              onChange={(value) => onShowFilterChange('byVendor', value)}
            />
          </div>
        </div>
      </div>
      <XtList
        isMobile={isMobile}
        pagination={pagination}
        loading={state.loading}
        data={state.data}
        columns={inventoryColumns}
        sortOptions={state.sortOptions}
        onColumnHeaderClick={sort}
        loadMessage={LoaderMessage.InventoryAvailabilityWorkbench}
      />
    </div>
  )
}
