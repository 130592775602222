import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { ShippingLineTableItem } from 'shipping/shipping.types'
import { numberConverter } from 'common/utils/utils'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { ShippingLinesAction } from './shipping-extended-template.types'

export const shippingLinesActions: IXtTableCellAction[] = [
  {
    name: ShippingLinesAction.IssueAdditionalStock,
    label: ShippingLinesAction.IssueAdditionalStock,
    icon: SvgIconIds.ADDITIONAL,
  },
  {
    name: ShippingLinesAction.ReturnAll,
    label: ShippingLinesAction.ReturnAll,
    icon: SvgIconIds.EXPIRE,
  },
  {
    name: ShippingLinesAction.ViewOrder,
    label: ShippingLinesAction.ViewOrder,
    icon: SvgIconIds.VISIBILITY,
  },
]

export const shippingLinesColumns: IXtTableColumn<ShippingLineTableItem>[] = [
  {
    id: 'line_number',
    field: 'line_number',
    headerName: 'Line #',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'item_number',
    field: 'item_number',
    headerName: 'Item #',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'item_description',
    field: 'item_description',
    headerName: 'Description',
    flex: '1 0 260px',
    width: 260,
  },
  {
    id: 'uom',
    field: 'uom',
    headerName: 'UOM',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'qty_shipped',
    field: 'qty_shipped',
    headerName: 'Qty. At Ship',
    flex: '1 0 180px',
    width: 180,
    converter: numberConverter,
  },
  {
    id: 'qty_at_shipping',
    field: 'qty_at_shipping',
    headerName: 'Value at Ship',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
]
