import React, { FC, useMemo } from 'react'
import { useXtForm } from 'common/hooks/form/form'
import { FormRadioGroup, FormSelectField, FormXtAutocomplete, FormXtAutocompletePlain } from 'common/utils/form/form.components'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { useShipmentsModule } from 'shipments/shipments-module-hook'
import { useXtSelect } from 'components/controls/xt-select/xt-select-hook'
import { XtInfoValues } from 'components/xt-info-values/xt-info-values'
import { useCoreModule } from 'core/core-module-hook'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { cls } from 'common/utils/utils'
import { ReportType, ShipmentDataOption } from 'shipments/shipments.types'
import {
  IPackingListPrintForm,
  IPackingListPrintFormState,
  PackingListPrintFormField,
  PackingListPrintFormLabel,
} from './print-packing-list.types'
import { packingListPrintFormSchema as validationSchema } from './print-packing-list.validation'
import { reportTypeOptions, packingListFormDefaultValues as defaultValues } from './print-packing-list.constants'
import { usePrintShippingForm } from '../print-shipping-form-hook/print-shipping-form-hook'
import { resolveSalesOrderValues, resolveTransferOrderValues } from '../print-shipping-form/print-shipping-form.utils'
import { renderShipmentValues, resolveShipmentInfoValues } from './print-packing-list.utils'
import { ShippingPrintFormSharedField, ShippingPrintFormSharedLabel } from '../print-dialogs.types'

import * as styles from './print-packing-list.module.scss'

export const PackingListPrintForm: FC<IPackingListPrintForm> = ({ onClose }) => {
  const { DocumentsUtilsService } = useDocumentsModule()
  const { ShipmentsUtilsService } = useShipmentsModule()
  const { ErrorHandler } = useCoreModule()

  const formMethods = useXtForm<IPackingListPrintFormState>({
    defaultValues,
    mode: 'onBlur',
    validationSchema,
  })

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
    watch,
    getValues,
    setValue,
    trigger,
  } = formMethods

  const { orderValues, shipmentValues, shipmentsState, onShipmentChange, onOrderChange } = usePrintShippingForm(
    formMethods,
    resolveSalesOrderValues,
    resolveTransferOrderValues,
    resolveShipmentInfoValues
  )

  const site = watch(PackingListPrintFormField.Site)

  const { options: sites } = useXtSelect(DocumentsUtilsService.loadSiteOptions)

  const onSaveForm = async (): Promise<void> => {
    try {
      const { shipment_number: shipment, report_type, order_number: order } = getValues()

      if (report_type === ReportType.PackingList && shipment) {
        await ShipmentsUtilsService.printPackingList(shipment.shipment_number, report_type)
      }
      if (report_type !== ReportType.PackingList && order) {
        await ShipmentsUtilsService.printPickList(order.order_type, order.number, report_type)
      }
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const onSiteChange = async (option: IXtAutocompleteOption | null): Promise<void> => {
    setValue(PackingListPrintFormField.Site, option, { shouldValidate: true, shouldDirty: true })
    await onOrderChange(null)
  }

  const onShipmentOptionChange = async (option: ShipmentDataOption | null): Promise<void> => {
    const order = getValues(ShippingPrintFormSharedField.Order)
    if (!order) {
      setValue(PackingListPrintFormField.Site, null, { shouldValidate: true, shouldDirty: true })
    }
    await onShipmentChange(option)
  }

  const onReportTypeChange = async (option: ReportType): Promise<void> => {
    setValue(PackingListPrintFormField.ReportType, option, { shouldValidate: true, shouldDirty: true })
    await trigger(ShippingPrintFormSharedField.ShipmentNumber)
  }

  const orderFilters = useMemo(() => ({ so: true, to: true, site: site?.id }), [site])

  return (
    <form className={styles.packingListDialog}>
      <div className={cls('xt-page-header', styles.packingListDialogHeader)}>
        <h3 className="xt-page-title">Print Packing List</h3>
        <div className={styles.packingListDialogHeaderButtons}>
          <XtButton label="Cancel" onClick={onClose} />
          <XtButton label="Print" onClick={handleSubmit(onSaveForm)} disabled={!isDirty || isSubmitting} />
        </div>
      </div>
      <div className={styles.packingListDialogFields}>
        <FormSelectField
          clearable
          onChange={onSiteChange}
          name={PackingListPrintFormField.Site}
          control={control}
          label={PackingListPrintFormLabel.Site}
          options={sites}
        />
        <FormRadioGroup
          className="packingListDialogRadioGroup"
          options={reportTypeOptions}
          label=""
          control={control}
          name={PackingListPrintFormField.ReportType}
          onChange={onReportTypeChange}
        />
        <div className={styles.packingListDialogOrderSection}>
          <FormXtAutocomplete
            loadOptions={DocumentsUtilsService.loadOrders}
            onChange={onOrderChange}
            name={ShippingPrintFormSharedField.Order}
            control={control}
            label={ShippingPrintFormSharedLabel.Order}
            filters={orderFilters}
          />
          <XtInfoValues values={orderValues} />
        </div>
        <div className={styles.packingListDialogShipmentSection}>
          <FormXtAutocompletePlain
            name={ShippingPrintFormSharedField.ShipmentNumber}
            control={control}
            label={ShippingPrintFormSharedLabel.ShipmentNumber}
            options={shipmentsState.options}
            onChange={onShipmentOptionChange}
            loading={shipmentsState.loading}
            loadMore={shipmentsState.loadMoreOptions}
            filter={shipmentsState.search}
            reset={shipmentsState.reset}
          />
          {shipmentValues && renderShipmentValues(shipmentValues)}
        </div>
      </div>
    </form>
  )
}
