import { Overwrite } from 'utility-types'
import { ContactsModule, ContactsModuleDefinition, IContactsModuleServices } from './contacts.module'
import { LazyModulesService } from '../core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from '../core/injector/injector.service'
import { IContactService } from './contacts.service'
import { IContactsUtilsService } from './contacts-utils.service'
import { contactsServiceToken, contactsUtilsServiceToken } from './contacts.constants'
import { moduleHookResolver } from '../core/react-lazy-modules/react-lazy-modules.utils'

function resolveContactsModule(): Overwrite<ContactsModuleDefinition, IContactsModuleServices> {
  const module = LazyModulesService.resolveModule<ContactsModuleDefinition>(ContactsModule)
  const ContactsService = Injector.resolve<IContactService>(contactsServiceToken)
  const ContactsUtilsService = Injector.resolve<IContactsUtilsService>(contactsUtilsServiceToken)

  return { ...module, ContactsService, ContactsUtilsService }
}

export const useContactsModule = moduleHookResolver(resolveContactsModule)
