import React, { FC, useState, useRef, useCallback, KeyboardEvent } from 'react'
import { Tab } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { ClickAwayListener, Grow, Paper, Popper } from '@material-ui/core'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { useAuthModule } from 'auth/auth-module-hook'
import { UserPermission } from 'auth/auth.types'
import { useSharedModule } from 'shared/shared-module-hook'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import {
  InvoicePrintForm,
  PackingListPrintForm,
  PrintShippingForm,
  PrintShippingForms,
  SalesOrderPrintForm,
  TransferOrderPrintForm,
} from './navbar-print-dialogs/print-dialogs.export'
import { IXtDropdwonMenuOption } from './navbar.types'
import * as styles from './navbar.module.scss'

export const XtDropdownMenu: FC<IXtDropdwonMenuOption> = ({ menuList, type, name }) => {
  const { useDialog } = useSharedModule()
  const { PermissionsService } = useAuthModule()

  const canPrintByInvoice = PermissionsService.hasPermission(UserPermission.PrintInvoices)
  const canPrintBySO = PermissionsService.hasSomePermission([UserPermission.MaintainSalesOrders, UserPermission.ViewSalesOrders])
  const canPrintByTO = PermissionsService.hasSomePermission([UserPermission.MaintainTransferOrders, UserPermission.ViewTransferOrders])
  const canPrintShippingForm = PermissionsService.hasPermission(UserPermission.PrintBillsOfLading)
  const canPrintPackagingList = PermissionsService.hasPermission(UserPermission.PrintPackingLists)
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)
  const { openDialog: openPrintByInvoiceDialog, closeDialog: closePrintByInvoiceDialog, open: printByInvoiceDialogOpen } = useDialog()

  const { openDialog: openPrintBySODialog, closeDialog: closePrintBySODialog, open: printBySODialogOpen } = useDialog()
  const { openDialog: openPrintByTODialog, closeDialog: closePrintByTODialog, open: printByTODialogOpen } = useDialog()

  const {
    openDialog: openPrintShippingFormDialog,
    closeDialog: closePrintShippingFormDialog,
    open: printShippingFormDialogOpen,
  } = useDialog()

  const { openDialog: openPrintPackingListDialog, closeDialog: closePrintPackingListDialog, open: printPackingListDialogOpen } = useDialog()
  const {
    openDialog: openPrintShippingFormsDialog,
    closeDialog: closePrintShippingFormsDialog,
    open: printShippingFormsDialogOpen,
  } = useDialog()

  const handleClose: (event: React.MouseEvent<Document, MouseEvent>) => void = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen)
  }, [])

  const closePopUpMenu = (): void => {
    setOpen(false)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
      closePopUpMenu()
    }
  }

  return (
    <div>
      <span ref={anchorRef} className={styles.menuButton} onClick={handleToggle} role="button" tabIndex={0} onKeyPress={handleToggle}>
        {name}
        {open ? (
          <SvgIcon iconId={SvgIconIds.EXPAND_MORE} className={styles.muiCollapseIcon} />
        ) : (
          <SvgIcon iconId={SvgIconIds.EXPAND_MORE} className={styles.muiExpandIconSize} />
        )}
      </span>
      <Popper
        style={{ zIndex: 9999 }}
        className={styles.dropDownTabMenuWidth}
        open={open}
        placement="bottom-end"
        anchorEl={anchorRef.current}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={styles.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <>
                  {type === 'buttons' ? (
                    <div role="button" tabIndex={0} onClick={closePopUpMenu} onKeyDown={handleKeyDown}>
                      <XtButton
                        className={styles.printBtnWidth}
                        label="Print Labels by Invoice"
                        onClick={openPrintByInvoiceDialog}
                        hidden={!canPrintByInvoice}
                      />
                      <XtButton
                        className={styles.printBtnWidth}
                        label="Print Labels by Sales Order"
                        onClick={openPrintBySODialog}
                        hidden={!canPrintBySO}
                      />
                      <XtButton
                        className={styles.printBtnWidth}
                        label="Print Labels by Transfer Order"
                        onClick={openPrintByTODialog}
                        hidden={!canPrintByTO}
                      />
                      <XtButton
                        className={styles.printBtnWidth}
                        label="Print Shipping Form"
                        onClick={openPrintShippingFormDialog}
                        hidden={!canPrintShippingForm}
                      />
                      <XtButton
                        className={styles.printBtnWidth}
                        label="Print Packing List"
                        onClick={openPrintPackingListDialog}
                        hidden={!canPrintPackagingList}
                      />
                      <XtButton
                        className={styles.printBtnWidth}
                        label="Print Shipping Forms"
                        onClick={openPrintShippingFormsDialog}
                        hidden={!canPrintShippingForm}
                      />
                    </div>
                  ) : (
                    <>
                      {menuList?.map((val, index) => (
                        <Tab
                          key={index}
                          label={val?.name}
                          classes={{ root: styles.navContainer, textColorInherit: styles.tabTextInherit }}
                          className={styles.checkk}
                          to={val?.path}
                          component={NavLink}
                        />
                      ))}
                    </>
                  )}
                </>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <XtDialog
        className="xt-dialog-details-content"
        open={printByInvoiceDialogOpen}
        fullScreen={false}
        animation={XtDialogAnimation.FadeAnimation}
      >
        <InvoicePrintForm onClose={closePrintByInvoiceDialog} />
      </XtDialog>
      <XtDialog
        className="xt-dialog-details-content"
        open={printBySODialogOpen}
        fullScreen={false}
        animation={XtDialogAnimation.FadeAnimation}
      >
        <SalesOrderPrintForm onClose={closePrintBySODialog} />
      </XtDialog>
      <XtDialog
        className="xt-dialog-details-content"
        open={printByTODialogOpen}
        fullScreen={false}
        animation={XtDialogAnimation.FadeAnimation}
      >
        <TransferOrderPrintForm onClose={closePrintByTODialog} />
      </XtDialog>
      <XtDialog
        className="xt-dialog-details-content"
        open={printShippingFormDialogOpen}
        fullScreen={false}
        animation={XtDialogAnimation.FadeAnimation}
      >
        <PrintShippingForm onClose={closePrintShippingFormDialog} />
      </XtDialog>
      <XtDialog
        className="xt-dialog-details-content"
        open={printPackingListDialogOpen}
        fullScreen={false}
        animation={XtDialogAnimation.FadeAnimation}
      >
        <PackingListPrintForm onClose={closePrintPackingListDialog} />
      </XtDialog>
      <XtDialog
        className="xt-dialog-details-content"
        open={printShippingFormsDialogOpen}
        fullScreen={false}
        animation={XtDialogAnimation.FadeAnimation}
      >
        <PrintShippingForms onClose={closePrintShippingFormsDialog} />
      </XtDialog>
    </div>
  )
}
