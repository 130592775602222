import React, { FC, memo } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { INavigationList } from './navigation-list.types'
import { setupModuleList } from '../setup-wizard.exports'
import * as styles from '../setup-wizard.module.scss'

export const NavigationList: FC<INavigationList> = memo(({ activeStep, activeModule, onChangeStep }) => {
  return (
    <div className={styles.navigationListContainer}>
      <List component="nav" aria-label="">
        {setupModuleList.map((item, index) => (
          <div key={index}>
            <ListItem button disabled key={index} className={styles.disabledListItem}>
              <ListItemText primary={item.name} />
            </ListItem>

            {item.steps.map((step, subindex) => (
              <ListItem
                button
                selected={activeStep == step.name && activeModule == item.param}
                key={subindex}
                onClick={() => onChangeStep(item.id, step.id)}
              >
                <ListItemText primary={step.label} className={styles.stepListItem} />
              </ListItem>
            ))}
          </div>
        ))}
      </List>
    </div>
  )
})
