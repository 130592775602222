import { prepareRequestParams } from 'common/utils/request.utils'
import { IPaginationData, IPaginationParams, IPaginationResponse } from 'common/common.types'
import { IHttpClient } from './http-client'

export interface IExpenseCategory {
  code: string
  description: string
  active: boolean
  expense_account: string
  purchase_price_account: string
  po_liability_account: string
  freight_expense_account: string
  sales_tax_account: string
  created: string
  lastupdated: string
}

export interface IExpenseCategoryService {
  getAll(pagination: IPaginationParams): Promise<IPaginationData<IExpenseCategory>>
}

export class ExpenseCategoryService implements IExpenseCategoryService {
  constructor(private readonly httpClient: IHttpClient) {}

  public async getAll(paginationParams: IPaginationParams): Promise<IPaginationData<IExpenseCategory>> {
    const params = prepareRequestParams(paginationParams)
    const {
      data: { data, status },
    } = await this.httpClient.get<IPaginationResponse<IExpenseCategory>>('/expensecategory', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}
