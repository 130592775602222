import type { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { IItemsService } from 'products/items/items.service'
import { IErrorHandler } from 'core/services/error-handler.service'
import { itemsServiceToken } from 'products/products.constants'
import { errorHandlerServiceToken } from 'core/core.constants'
import { inventoryModuleToken } from './inventory.constants'
import { RunningAvailabilityModule } from './running-availability/running-availability.module'
import { InventoryAdjustmentModule } from './inventory-adjustments/inventory-adjustments.module'
import { QuantityOnHandModule } from './quantity-on-hand/quantity-on-hand.module'
import {
  CostingItemCostsUtilsService,
  ICostingItemCostsUtilsService,
} from './inventory-history-section/item-costs/item-costs-utils.service'
import {
  inventoryHistoryItemCostsUtilsServiceToken,
  inventoryHistoryServiceToken,
} from './inventory-history-section/item-costs/item-costs.constants'
import { IInventoryHistoryService } from './inventory-history-section/item-costs/inventory-history.service'
import { InventoryHistoryModule } from './inventory-history-section/inventory-history.module'

export type RunningAvailabilityModuleDefinition = typeof import('./running-availability/running-availability.exports')
export type InventoryAdjustmentModuleDefinition = typeof import('./inventory-adjustments/inventory-adjustments.exports')
export type QuantityOnHandModuleDefinition = typeof import('./quantity-on-hand/quantity-on-hand.export')

export type InventoryModuleDefinition = RunningAvailabilityModuleDefinition &
  InventoryAdjustmentModuleDefinition &
  QuantityOnHandModuleDefinition

export interface IProductsModuleServices {
  CostingItemCostsUtilsService: ICostingItemCostsUtilsService
}

export const InventoryModule: ILazyModule<InventoryModuleDefinition> = {
  name: inventoryModuleToken,
  resolver: async () => {
    const runningAvailabilityModule = LazyModulesService.resolveModule<RunningAvailabilityModuleDefinition>(RunningAvailabilityModule)
    const inventoryAdjustmentModule = LazyModulesService.resolveModule<InventoryAdjustmentModuleDefinition>(InventoryAdjustmentModule)
    const quantityOnHandModule = LazyModulesService.resolveModule<QuantityOnHandModuleDefinition>(QuantityOnHandModule)
    return {
      ...runningAvailabilityModule,
      ...inventoryAdjustmentModule,
      ...quantityOnHandModule,
    }
  },
  initializer: ({}) => {
    Injector.register<ICostingItemCostsUtilsService>(
      inventoryHistoryItemCostsUtilsServiceToken,
      (injector) => {
        const itemsService = injector.resolve<IItemsService>(itemsServiceToken)
        const errorHandler = injector.resolve<IErrorHandler>(errorHandlerServiceToken)
        const inventoryHistoryService = injector.resolve<IInventoryHistoryService>(inventoryHistoryServiceToken)

        return new CostingItemCostsUtilsService(itemsService, errorHandler, inventoryHistoryService)
      },
      Scope.Singleton
    )
  },
  dependencies: [RunningAvailabilityModule, InventoryAdjustmentModule, QuantityOnHandModule, InventoryHistoryModule],
}
