import React, { FC } from 'react'
import { useHistory, useParams } from 'react-router'
import { IXtMode } from 'common/common.types'
import { companiesRoutePath, companiesRoutes } from 'companies/companies.constants'
import { AccountDetails } from './account-details'
import { IAccountDetailsParams } from './account-details.types'

export const AccountDetailsPage: FC<IXtMode> = ({ mode }) => {
  const history = useHistory()
  const onCancel = (): void => history.push(`${companiesRoutePath}/${companiesRoutes.allAccounts}`)

  const { id } = useParams<IAccountDetailsParams>()

  return <AccountDetails className="xt-content" onCancel={onCancel} accountNumber={id} mode={mode} />
}
