import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'

export const MIN_NUMBER_VALUE = 0
export const MAX_NUMBER_VALUE = 10 ** 5
export const MIN_EXECUTION_DAY = 1
export const MAX_EXECUTION_DAY = 99

export enum RoutingItemControlName {
  StandardOperation = 'standard_operation',
  OperationType = 'operation_type',
  RoutingDescription1 = 'routing_description1',
  RoutingDescription2 = 'routing_description2',
  WorkCenter = 'work_center',
  ToolingReference = 'tooling_reference',
  ExecutionDay = 'execution_day',
  Effective = 'effective',
  Expires = 'expires',
  Instructions = 'instructions',
  SequenceNumber = 'sequence_number',
  InventoryUom = 'inventory_uom',
  SetupTime = 'setup_time',
  RunTime = 'run_time',
  Per = 'run_qty_per',
  ReportSetupCostAs = 'report_setup_as',
  ReportSetupTime = 'report_setup',
  ReportRunCostAs = 'report_runtime_as',
  ReportRunTime = 'report_runtime',
  ProductionUOM = 'production_uom',
  ProductionRatio = 'production_inventory_ratio',
  ReceiveInventory = 'receive_inventory',
  IssueComponents = 'issue_components',
  AllowOverlap = 'allow_overlap',
  WIPLocation = 'wip_location',
}

export enum RoutingItemControlLabel {
  StandardOperation = 'Standard Operation',
  OperationType = 'Operation Type',
  WorkCenter = 'Work Center',
  ToolingReference = 'Tooling Reference',
  ExecutionDay = 'Execution Day',
  Effective = 'Effective',
  Expires = 'Expires',
  SetupTime = 'Setup Time',
  ReportSetupCostAs = 'Report Setup Cost As',
  ReportRunCostAs = 'Report Run Cost As',
  ReportSetupTime = 'Report Setup Time',
  ReportRunTime = 'Report Run Time',
  RunTime = 'Run Time',
  Per = 'Per',
  ProductionUOM = 'Production UOM',
  ProductionRatio = 'Production/Inventory Ratio',
  ReceiveInventory = 'Receive Inventory at this Operation',
  IssueComponents = 'Auto Issue Components at this Operation',
  AllowOverlap = 'May Overlap with Preceding Operation',
  WIPLocation = 'WIP Location',
}

export const StandardOperationNone: IXtAutocompleteOption = { id: 'NONE', label: 'NONE' } as const
