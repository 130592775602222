// extracted by mini-css-extract-plugin
export var root = "page-not-found_root__+60vC";
export var xtContent = "page-not-found_xt-content__urPrv";
export var xtContentWithRemarks = "page-not-found_xt-content-with-remarks__qspOg";
export var muiFormHelperTextRoot = "page-not-found_MuiFormHelperText-root__hB-lu";
export var xtListPageContainer = "page-not-found_xt-list-page-container__2jtNU";
export var muiInputBaseRoot = "page-not-found_MuiInputBase-root__+cDV3";
export var muiTextFieldRoot = "page-not-found_MuiTextField-root__WA7hV";
export var muiTablePaginationRoot = "page-not-found_MuiTablePagination-root__kQNiB";
export var muiTablePaginationSelectRoot = "page-not-found_MuiTablePagination-selectRoot__nDH0P";
export var muiTablePaginationSelect = "page-not-found_MuiTablePagination-select__seHS8";
export var muiStepperRoot = "page-not-found_MuiStepper-root__JSgFb";
export var muiStepperHorizontal = "page-not-found_MuiStepper-horizontal__andnX";
export var muiStepIconRoot = "page-not-found_MuiStepIcon-root__svNxk";
export var muiStepIconActive = "page-not-found_MuiStepIcon-active__vw1cF";
export var muiStepIconCompleted = "page-not-found_MuiStepIcon-completed__qPyaq";
export var muiListItemRoot = "page-not-found_MuiListItem-root__JGXZd";
export var muiSelected = "page-not-found_Mui-selected__NXPjb";
export var xtPageTitle = "page-not-found_xt-page-title__xpU3B";
export var xtSubPageTitle = "page-not-found_xt-subPage-title__7KXXW";
export var xtSectionBorder = "page-not-found_xt-section-border__fvdMc";
export var xtPageHeader = "page-not-found_xt-page-header__tHumJ";
export var xtDialogScrollable = "page-not-found_xt-dialog-scrollable__+Jxpn";
export var reactToastNotificationsContainer = "page-not-found_react-toast-notifications__container__I0PTp";
export var xtScrollbarStyle = "page-not-found_xt-scrollbar-style__CQuaQ";
export var xtModalDetailsContent = "page-not-found_xt-modal-details-content__cDYpA";
export var xtDialogDetailsContent = "page-not-found_xt-dialog-details-content__rjZlZ";
export var xtLazyModuleLoaderTabContainer = "page-not-found_xt-lazy-module-loader-tab-container__iE7B0";
export var xtTextTruncated = "page-not-found_xt-text-truncated__Cu4HW";
export var xtSummaryContent = "page-not-found_xt-summary-content__5Lsro";
export var xtSummaryLabel = "page-not-found_xt-summary-label__+DjJ5";
export var visibleHidden = "page-not-found_visible-hidden__khzni";
export var headerBottomMargin = "page-not-found_header-bottom-margin__KR3N-";
export var xtDialogHeaderLogoAlign = "page-not-found_xt-dialog-header-logoAlign__Zg2z9";
export var xtStickyHeader = "page-not-found_xt-sticky-header__Mxd33";
export var noBorder = "page-not-found_no-border__0Npor";
export var muiOutlinedInputNotchedOutline = "page-not-found_MuiOutlinedInput-notchedOutline__+--kU";
export var container = "page-not-found_container__OwDDO";
export var fof = "page-not-found_fof__XOhiR";
export var type = "page-not-found_type__9mWb5";