import { ISalesOrder } from 'sales-shared/sales-orders.types'
import { XtInfoValue } from 'components/xt-info-values/xt-info-values.types'
import { ITransferOrder } from 'core/services/transfer-order.service'
import { IShipmentData } from 'shipments/shipments.types'
import { OrderType } from 'core/core.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IPrintShippingFormState, PrintShippingFormStateFilled, ShippingPrintFormField } from './print-shipping-form.types'
import { ShippingPrintFormSharedField } from '../print-dialogs.types'

export function resolveSalesOrderValues(data: ISalesOrder): XtInfoValue[] {
  return [
    {
      label: 'SO',
      value: data.order_status,
    },
    {
      label: 'Customer',
      value: data.customer_number,
    },
  ]
}

export function resolveTransferOrderValues(data: ITransferOrder): XtInfoValue[] {
  return [
    {
      label: 'TO',
      value: data.status,
    },
    {
      label: 'From',
      value: data.source_warehouse.source_site,
    },
    {
      label: 'To',
      value: data.destination_warehouse.destination_site,
    },
  ]
}

export function resolveShipmentInfoValues({ customer_info, shipment_number, order_type }: IShipmentData): XtInfoValue[] | null {
  if (order_type === OrderType.TransferOrder) {
    return null
  }
  const value = shipment_number && customer_info?.shipto_address1 ? `${shipment_number} ${customer_info.shipto_address1}` : 'N/A'

  return [
    {
      label: 'Ship To',
      value,
    },
  ]
}

export function isFilledForm(form: IPrintShippingFormState): form is PrintShippingFormStateFilled {
  return (
    form[ShippingPrintFormSharedField.Order] !== null &&
    form[ShippingPrintFormSharedField.ShipmentNumber] !== null &&
    form[ShippingPrintFormField.ShippingForm] !== null
  )
}

export function defineDefaultShippingForm(
  defaultShippingForm: string | undefined,
  shippingFormOptions: IXtAutocompleteOption[]
): IXtAutocompleteOption | null {
  if (defaultShippingForm && shippingFormOptions.length) {
    const shippingForm = shippingFormOptions.find(({ id }) => defaultShippingForm === id)
    return shippingForm ?? shippingFormOptions[0]
  }

  if (shippingFormOptions.length) {
    return shippingFormOptions[0]
  }

  return null
}
