import { IObjectWithId } from 'common/common.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'

export interface IAttachDialogParams {
  onClose: VoidFunction
  quoteDetails: boolean
  account: string
  onSelect(item: AttachTableItem): void
  isMobile: boolean
  isAttaching: boolean
}

export type AttachTableItem = {
  order_number: string
  customer_number: string
  order_date: string | null
  scheduled_date: string | null
} & IObjectWithId

export interface IAttachmentDialogOptions {
  opportunityNumber: string
  opportunityAccount: string
}
export type AttachmentOptions = { attachmentOptions?: IAttachmentDialogOptions }

export interface ILoadOrders {
  quoteDetails: boolean
  site?: string
  account: string
  unassignedToOpportunity?: boolean
}

export enum AttachField {
  Site = 'site',
}

export interface IAttachForm {
  [AttachField.Site]: IXtAutocompleteOption | null
}
