import React, { useCallback } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useHistory, useRouteMatch } from 'react-router'
import { XtMode } from 'common/common.types'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { useTable } from 'common/hooks/useTable'
import { XtList } from 'components/list/list'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { LoaderMessage, confirmationMessages, xsMq } from 'common/constants'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { UserPermission } from 'auth/auth.types'
import { cls } from 'common/utils/utils'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { XtCheckbox } from 'components/controls/xt-checkbox/xt-checkbox'
import { PageFilterMapping } from 'core/services/pagefilters/pagefilters.types'
import { usePageFilter } from 'components/pagefilter/pagefilter.utils'
import { XtPageFilter } from 'components/pagefilter/pagefilter'
import { LeadFilter } from 'leads/leads.types'
import { leadActionsViewMode } from 'leads/lead-list/lead-list.constants'
import { ILeadTableItem, LeadAction } from 'leads/lead-list/lead-list.types'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import * as styles from './leads-list.module.scss'
import { getLeadActions, resolveFilters } from './leads-list.utils'
import { leadColumns, defaultFilterValues, leadActionsEditMode, leadsPageTitle } from './leads-list.constants'
import { useCompaniesLeadsModule } from '../leads-module-hook'
import { LeadFilterPanel } from '../leads.types'

export function CompaniesLeadsList(): React.ReactElement {
  const { DocumentsUtilsService } = useDocumentsModule()
  const { LeadsUtilsService } = useCompaniesLeadsModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { PermissionsService } = useAuthModule()

  const {
    open,
    openDialog: openConfirmationDialog,
    closeDialog: closeConfirmationDialog,
    itemId: itemIdToDelete,
  } = useConfirmationDialog<string>()
  const isMobile = useMediaQuery(xsMq)
  const canCreateContact = PermissionsService.hasSomePermission([
    UserPermission.MaintainAllContacts,
    UserPermission.MaintainPersonalContacts,
  ])
  const history = useHistory()
  const { path } = useRouteMatch()
  useDocumentTitle(leadsPageTitle)
  const {
    state: { loading, sortOptions, filters: tableFilters, data },
    refresh,
    filter,
    pagination,
    setLoading,
    sort,
  } = useTable(defaultFilterValues, LeadsUtilsService.fetchLeads, undefined, PageFilterMapping.Contacts)

  const contactsListFilters = usePageFilter<LeadFilterPanel>(PageFilterMapping.Contacts)

  const handleAction = useCallback<(item: ILeadTableItem, action: LeadAction) => void>(
    (item, action) => {
      if (action === LeadAction.Edit || action === LeadAction.View) {
        history.push(`${path}/${item.contact_number}/${action === LeadAction.Edit && item.editable ? XtMode.Edit : XtMode.View}`)
      }

      openConfirmationDialog(item.id)
    },
    [history, openConfirmationDialog, path]
  )

  const handleRowClick = useCallback<(item: ILeadTableItem) => void>(
    (item) => history.push(`${path}/${item.contact_number}/${item.editable ? XtMode.Edit : XtMode.View}`),
    [history, path]
  )

  const handleDeletion = useCallback<VoidFunction>(async () => {
    closeConfirmationDialog()
    if (itemIdToDelete) {
      try {
        setLoading(true)
        const message = await LeadsUtilsService.deleteLead(itemIdToDelete)
        await refresh()
        ToastService.showSuccess(message)
        setLoading(false)
      } catch (error) {
        ErrorHandler.handleError(error as Error)
        setLoading(false)
      }
    }
  }, [closeConfirmationDialog, itemIdToDelete, setLoading, LeadsUtilsService, refresh, ToastService, ErrorHandler])

  const handleShowInactiveFilterChange = (checked: boolean): void => {
    const params = { ...tableFilters, [LeadFilter.ShowInactive]: checked }
    void filter(params)
    void contactsListFilters.handleLastUsedFilter(params)
  }

  return (
    <div className={cls('xt-content', styles.listContent)}>
      <XtConfirmationDialog
        open={open}
        message={confirmationMessages.deleted}
        title="Delete Contact"
        confirmationButtonLabel="Delete"
        onConfirm={handleDeletion}
        onClose={closeConfirmationDialog}
      />
      <div className={styles.listControls}>
        <div className={cls('xt-section-border', styles.headerContent)}>
          <h1 className="xt-page-title">Leads</h1>
          <XtResponsiveButton
            label="New Lead"
            disabled={!canCreateContact}
            icon={SvgIconIds.ADD_CIRCLE}
            onClick={() => history.push(`${path}/${XtMode.New}`)}
          />
        </div>

        <div className={styles.filtersContainer}>
          <XtPageFilter
            state={contactsListFilters}
            defaultFilterValues={defaultFilterValues}
            resolveFilters={() => resolveFilters(DocumentsUtilsService)}
            filter={filter}
            tableFilters={tableFilters}
          />
          <XtCheckbox
            className={styles.contactsListCheckbox}
            label="Show Inactive"
            value={Boolean(tableFilters[LeadFilter.ShowInactive])}
            onChange={handleShowInactiveFilterChange}
            disabled={loading}
          />
        </div>
      </div>

      <XtList
        actions={canCreateContact ? leadActionsEditMode : leadActionsViewMode}
        onRowClick={handleRowClick}
        onAction={handleAction}
        isMobile={isMobile}
        pagination={pagination}
        columns={leadColumns}
        getItemActions={getLeadActions}
        loading={loading}
        data={data}
        sortOptions={sortOptions}
        onColumnHeaderClick={sort}
        loadMessage={LoaderMessage.Leads}
      />
    </div>
  )
}
