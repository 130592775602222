import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { Injector } from 'core/injector/injector.service'
import { ISetupWizardService } from './setup-wizard.service'
import { SetupWizardModuleDefinition, SetupWizardModule } from './setup-wizard.module'
import { setupWizardServiceToken } from './setup-wizard.constants'

function resolveSetupWizardModule(): { SetupWizardService: ISetupWizardService } {
  const module = LazyModulesService.resolveModule<SetupWizardModuleDefinition>(SetupWizardModule)
  const SetupWizardService = Injector.resolve<ISetupWizardService>(setupWizardServiceToken)

  return { ...module, SetupWizardService }
}

export const useSetupWizardModule = moduleHookResolver(resolveSetupWizardModule)
