import { LazyModulesService } from 'core/react-lazy-modules/react-lazy-modules.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { SharedModuleDefinition } from './shared-module'
import { sharedModuleToken } from './shared.constants'

function sharedModuleResolver(): SharedModuleDefinition {
  return LazyModulesService.resolveModule<SharedModuleDefinition>(sharedModuleToken)
}

export const useSharedModule = moduleHookResolver(sharedModuleResolver)
