import React, { FC, useEffect, useRef } from 'react'
import { Switch, useLocation } from 'react-router-dom'
import { useMediaQuery } from '@material-ui/core'
import { smMq } from 'common/constants'
import { IRoute } from 'common/routing/routing.types'
import { buildRoute, isAsyncRoute } from 'common/routing/routing.utils'
import { calculateVH } from 'common/utils/style.utils'
import { LoadingSpinner } from 'components/loading-spinner'
import { buildAsyncRoute } from 'common/routing/lazy-routing/lazy-routing.utils'
import { Footer } from '../footer/footer'
import * as styles from './main.module.scss'
import { XtSidebar } from '../sidebar/sidebar'
import { XtContent } from '../content/content'
import { IXtMain } from './main.types'

function buildContentRoute(route: IRoute): JSX.Element {
  if (isAsyncRoute(route)) {
    return buildAsyncRoute(route, (processedRoute) => <XtContent route={processedRoute} />)
  }
  return buildRoute(route, () => <XtContent route={route} />)
}

export const XtMain: FC<IXtMain> = ({ routes }) => {
  const isNotDesktop = useMediaQuery(smMq)
  const containerRef = useRef<HTMLDivElement>(null)
  const { pathname } = useLocation()

  useEffect(() => calculateVH())

  useEffect(() => {
    if (!!containerRef.current) {
      containerRef.current.scrollTop = 0
    }
  }, [pathname, containerRef])

  return (
    <div className={styles.mainContainer} ref={containerRef}>
      <XtSidebar staticMode={!isNotDesktop} />
      <div className={styles.mainPanel}>
        <React.Suspense fallback={<LoadingSpinner />}>
          <Switch>{routes.map(buildContentRoute)}</Switch>
        </React.Suspense>
        <Footer />
      </div>
    </div>
  )
}
