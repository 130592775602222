import { NumberSchema, ObjectSchema, ValidationError } from 'yup'
import * as yup from 'yup'
import { MutableRefObject } from 'react'
import { validationMessage } from 'common/validation/validation'
import { ControlMethodOption, IItemSite, ItemSiteDistributionOption } from 'products/items/items.types'
import { TableStateHookAsyncValidator, TableStateHookValidationError, TableStateHookValidator } from 'shared/hooks/table-state-hook'
import { isAutocompleteValue } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IInventoryAdjustmentUtilsService } from 'inventory/inventory-adjustments/inventory-adjustments-utils.service'
import { supportsLotSerial } from './lot-serial.utils'
import { ILotSerialEntry, ILotSerialValidation, LotSerialEntryRow, LotSerialField, LotSerialOption } from './lot-serial.types'
import { lotSerialEntryLabels, lotSerialTableRulesValidationMessage } from './lot-serial.constants'

export function defineQuantityValidator(
  locationControl: boolean,
  controlMethod: ControlMethodOption,
  isNegativeQtyToAssign: boolean
): NumberSchema {
  let quantityValidator = yup.number().nullable().required(validationMessage.isRequired)

  if (controlMethod === ControlMethodOption.SerialNumber) {
    const valueToEqual = isNegativeQtyToAssign ? -1 : 1
    quantityValidator = quantityValidator.oneOf([valueToEqual], validationMessage.equals(String(valueToEqual)))
  } else if (isNegativeQtyToAssign) {
    quantityValidator = quantityValidator.negative(validationMessage.max(0))
  } else {
    // No need to handle Qty Before changes in case of positive Qty assignment
    return quantityValidator.positive(validationMessage.min(0))
  }

  if (locationControl) {
    quantityValidator = quantityValidator.when(
      LotSerialField.Location,
      (location: ItemSiteDistributionOption | null, schema: NumberSchema) => {
        if (location) {
          const { qty_before } = location
          const minValue = -qty_before
          return schema.min(minValue, validationMessage.cannotBeLessThan(lotSerialEntryLabels[LotSerialField.QuantityBefore]))
        }
        return schema
      }
    )
  } else if (supportsLotSerial(controlMethod)) {
    quantityValidator = quantityValidator.when(
      LotSerialField.LotSerialNumber,
      (lotSerial: LotSerialOption | null, schema: NumberSchema) => {
        if (lotSerial && lotSerial.qty_before !== undefined) {
          const minValue = -lotSerial.qty_before
          return schema.min(minValue, validationMessage.cannotBeLessThan(lotSerialEntryLabels[LotSerialField.QuantityBefore]))
        }
        return schema
      }
    )
  }

  return quantityValidator
}

async function validateLotSerialNumber(
  inventoryAdjustmentUtilsService: IInventoryAdjustmentUtilsService,
  itemNumber: string,
  lotSerialNumber: string,
  tableItemsRef: MutableRefObject<LotSerialEntryRow[]>,
  controlMethod: ControlMethodOption
): Promise<boolean> {
  if (tableItemsRef.current.filter((item) => item.lotSerialNumber?.id === lotSerialNumber).length >= 2) return false

  if (controlMethod === ControlMethodOption.SerialNumber)
    return inventoryAdjustmentUtilsService.isSerialNumberUnique(itemNumber, lotSerialNumber)

  return true
}

function retrieveLotSerialRules(
  controlMethod: ControlMethodOption,
  tableItemsRef: MutableRefObject<LotSerialEntryRow[]>,
  isMultipleLocationControl: boolean,
  { location: locationDefaultSchema, lotSerialNumber: lotSerialDefaultSchema }: ILotSerialValidation,
  itemNumber: string,
  inventoryAdjustmentUtilsService: IInventoryAdjustmentUtilsService
): Pick<ILotSerialValidation, LotSerialField.LotSerialNumber | LotSerialField.Location> {
  const locationSchema = locationDefaultSchema ?? yup.object()
  const lotSerialSchema = lotSerialDefaultSchema ?? yup.object()

  if (!supportsLotSerial(controlMethod) && isMultipleLocationControl) {
    return {
      location: locationSchema.test({
        name: 'unique-location',
        test: (location: unknown) =>
          isAutocompleteValue(location) && location.id && tableItemsRef.current.length
            ? tableItemsRef.current.filter((item) => item.location?.id === location.id).length < 2
            : true,
        message: lotSerialTableRulesValidationMessage.uniqueLocation,
      }),
    }
  }

  if (supportsLotSerial(controlMethod) && locationSchema) {
    if (isMultipleLocationControl) {
      return {
        location: locationSchema.when(
          LotSerialField.LotSerialNumber,
          (lotSerialNumber: IXtAutocompleteOption | null, schema: ObjectSchema) =>
            schema.test({
              name: 'unique-pair',
              test: (location: unknown) =>
                isAutocompleteValue(location) && lotSerialNumber && tableItemsRef.current.length
                  ? tableItemsRef.current.filter(
                      (item) => item.location?.id === location.id && item.lotSerialNumber?.id === lotSerialNumber.id
                    ).length < 2
                  : true,
              message: lotSerialTableRulesValidationMessage.uniquePair,
            })
        ),
      }
    }

    return {
      lotSerialNumber: lotSerialSchema.test({
        name: 'unique-lot-serial',
        test: async (lotSerial: unknown) =>
          isAutocompleteValue(lotSerial) && lotSerial.id && tableItemsRef.current.length
            ? validateLotSerialNumber(inventoryAdjustmentUtilsService, itemNumber ?? '', lotSerial.id, tableItemsRef, controlMethod)
            : true,
        message: lotSerialTableRulesValidationMessage.uniqueLotSerial,
      }),
    }
  }

  return { location: locationDefaultSchema, lotSerialNumber: lotSerialDefaultSchema }
}

export function defineLotSerialEntryValidation(
  { control_method, multiple_location_control, perishable, item_number }: IItemSite,
  isNegativeQtyToAssign: boolean,
  tableItemsRef: MutableRefObject<LotSerialEntryRow[]>,
  inventoryAdjustmentUtilsService: IInventoryAdjustmentUtilsService
): ObjectSchema {
  const quantityValidator = defineQuantityValidator(multiple_location_control, control_method, isNegativeQtyToAssign)

  let schema: ILotSerialValidation = {
    quantity: quantityValidator,
  }

  if (multiple_location_control) {
    schema = {
      ...schema,
      location: yup.object().nullable().required(validationMessage.isRequired),
    }
  }

  if (supportsLotSerial(control_method)) {
    let expirationDate = yup.date().nullable().typeError(validationMessage.invalidDate)

    if (perishable) {
      expirationDate = expirationDate.required(validationMessage.isRequired)
    }

    schema = {
      ...schema,
      lotSerialNumber: yup.object().nullable().required(validationMessage.isRequired),
      expirationDate,
      warrantyDate: yup.date().nullable().typeError(validationMessage.invalidDate),
    }
  }

  const { location, lotSerialNumber } = retrieveLotSerialRules(
    control_method,
    tableItemsRef,
    multiple_location_control,
    schema,
    item_number,
    inventoryAdjustmentUtilsService
  )

  schema = isNegativeQtyToAssign ? { ...schema, location } : { ...schema, location, lotSerialNumber }

  return yup.object().shape(schema)
}

function resolveErrors(errors: ValidationError[]): TableStateHookValidationError<ILotSerialEntry> {
  return errors.reduce(
    (allErrors, currentError) => ({
      ...allErrors,
      [currentError.path]: currentError.message,
    }),
    {}
  )
}

export function defineLotSerialNumberValidator(schema: ObjectSchema): TableStateHookValidator<ILotSerialEntry> {
  return (item: ILotSerialEntry) => {
    try {
      schema.validateSync(item, { abortEarly: false })
      return
    } catch (error) {
      if (!(error instanceof ValidationError)) {
        return
      }
      return resolveErrors(error.inner)
    }
  }
}

export function defineLotSerialNumberValidatorAsync(schema: ObjectSchema): TableStateHookAsyncValidator<ILotSerialEntry> {
  return async (item: ILotSerialEntry) => {
    try {
      await schema.validate(item, { abortEarly: false })
      return
    } catch (error) {
      return error instanceof ValidationError ? resolveErrors(error.inner) : undefined
    }
  }
}
