import React, { FC } from 'react'
import { useCoreModule } from 'core/core-module-hook'
import { useXtForm } from 'common/hooks/form/form'
import { FormXtAutocomplete } from 'common/utils/form/form.components'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { useShipmentsModule } from 'shipments/shipments-module-hook'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { cls } from 'common/utils/utils'
import { OrderType } from 'core/core.types'
import { InvoicePrintFormLabel } from './print-by-invoice.types'
import { defaultPrintFormState } from '../print-dialogs.constants'
import { printDialogsValidationSchema } from '../print-dialogs.validation'
import { IPrintFormDialogParams, IPrintFormSharedState, PrintSharedFormField, PrintSharedFormLabel } from '../print-dialogs.types'
import { convertPrintFormToPrintPayload } from '../print-dialogs.utils'
import { PrintLabels } from '../print-labels/print-labels'

import * as styles from '../print-dialogs.module.scss'

export const InvoicePrintForm: FC<IPrintFormDialogParams> = ({ onClose }) => {
  const { ErrorHandler } = useCoreModule()
  const { ShipmentsUtilsService } = useShipmentsModule()
  const { DocumentsUtilsService } = useDocumentsModule()

  const formMethods = useXtForm<IPrintFormSharedState>({
    defaultValues: defaultPrintFormState,
    mode: 'onBlur',
    validationSchema: printDialogsValidationSchema,
  })

  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = formMethods

  const printForm = async (formData: IPrintFormSharedState): Promise<void> => {
    try {
      const payload = convertPrintFormToPrintPayload(formData, OrderType.Invoice, formData[PrintSharedFormField.Number]?.id)
      await ShipmentsUtilsService.printShipmentLabel(payload)
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  return (
    <form className={styles.printDialog}>
      <div className={cls('xt-page-header', styles.printDialogHeader)}>
        <h3 className="xt-page-title">Print Labels by Invoice</h3>
        <div className={styles.printDialogButtons}>
          <XtButton label="Cancel" onClick={onClose} />
          <XtButton label="Print" onClick={handleSubmit(printForm)} disabled={!isDirty || isSubmitting} />
        </div>
      </div>
      <FormXtAutocomplete
        loadOptions={DocumentsUtilsService.loadInventoryOptions}
        name={PrintSharedFormField.Number}
        control={control}
        label={InvoicePrintFormLabel.InvoiceNumber}
      />
      <FormXtAutocomplete
        name={PrintSharedFormField.ReportName}
        control={control}
        label={PrintSharedFormLabel.ReportName}
        loadOptions={DocumentsUtilsService.loadReportNameOptions}
      />
      <PrintLabels formMethods={formMethods} />
    </form>
  )
}
