import { FormRadioGroupOptions } from 'components/controls/xt-radio-group/xt-radio-group'
import { ReportType } from 'shipments/shipments.types'
import { ReportTypeLabel, IPackingListPrintFormState, PackingListPrintFormField } from './print-packing-list.types'
import { defaultPrintShippingFormState } from '../print-dialogs.constants'

export const packingListFormDefaultValues: IPackingListPrintFormState = {
  ...defaultPrintShippingFormState,
  [PackingListPrintFormField.ReportType]: ReportType.Auto,
  [PackingListPrintFormField.Site]: null,
}

export const reportTypeOptions: FormRadioGroupOptions = [
  { label: ReportTypeLabel.AutoSelect, id: ReportType.Auto },
  { label: ReportTypeLabel.PackingList, id: ReportType.PackingList },
  { label: ReportTypeLabel.PickList, id: ReportType.PickList },
]
