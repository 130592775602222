import React, { FC } from 'react'
import logo from '../assets/img/xtuple.png'
import * as styles from './auth.module.scss'

export const AuthPageContainer: FC = ({ children }) => (
  <div className={styles.authPage}>
    <div className={styles.authPageContent}>
      <img src={logo} alt="xTuple Logo" className={styles.authPageLogo} />
      {children}
    </div>
  </div>
)
