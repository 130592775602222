import React, { FC, memo, useEffect, useState } from 'react'
import { isCustomerCrmAccount, isProspectCrmAccount } from 'companies/accounts/accounts.utils'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { SalesOrderFilterFieldName } from 'sales-shared/sales-orders.types'
import { QuoteFilterFieldName } from 'sales-shared/quotes.types'
import { ICRMAccountDocument } from 'documents/documents.types'
import { XtMode } from 'common/common.types'
import { SalesOrdersModule, SalesOrdersModuleDefinition } from 'sales-orders/sales-orders.module'
import { QuotesModule, QuotesModuleDefinition } from 'quotes/quotes.module'
import { LazyModuleLoader } from 'components/lazy-module-loader/lazy-module-loader'
import { useCoreModule } from 'core/core-module-hook'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { opportunitySalesOrderActions } from './sales-tabs.constants'
import { defineQuoteActions } from './sales-tabs.utils'
import { IAccountFulfilled } from '../../../../companies/accounts/accounts.types'
import { useAccountsServicesModule } from '../../../../companies/accounts/services/accounts-services-module-hook'

interface ISalesTabs {
  account: ICRMAccountDocument
  disabled: boolean
  isMobile: boolean
  mode: XtMode
  opportunityNumber: string | undefined
  canViewQuotes: boolean
  canViewSalesOrders: boolean
}

enum SalesTabName {
  Quotes = 'Quotes',
  Orders = 'Orders',
}

export const SalesTabs: FC<ISalesTabs> = memo(
  ({ opportunityNumber, account: accountInput, disabled, isMobile, mode, canViewQuotes, canViewSalesOrders }) => {
    const { AccountsService } = useAccountsServicesModule()
    const { ErrorHandler } = useCoreModule()

    const [account, setAccount] = useState<IAccountFulfilled | null>(null)

    const init: () => Promise<void> = async () => {
      if (!canViewSalesOrders) {
        return
      }
      try {
        const accountData = await AccountsService.getFulfilledAccount(accountInput)
        setAccount(accountData)
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => void init(), [accountInput])

    const quotesDisabled = disabled || (!isCustomerCrmAccount(accountInput) && !isProspectCrmAccount(accountInput))

    const tabs = [
      {
        hidden: !canViewQuotes,
        name: SalesTabName.Quotes,
        template: opportunityNumber && (
          <LazyModuleLoader
            className="xt-lazy-module-loader-tab-container"
            module={QuotesModule}
            render={({ QuotesListGeneral, defineQuoteFilters, filterActions }: QuotesModuleDefinition) => (
              <QuotesListGeneral
                attachmentOptions={{ opportunityNumber, opportunityAccount: accountInput.number }}
                mode={mode}
                disabled={quotesDisabled}
                shouldOpenDetailsInDialog
                isMobile={isMobile}
                account={account}
                filters={defineQuoteFilters({ [QuoteFilterFieldName.Opportunity]: defineAutocompleteOption(opportunityNumber) })}
                actions={defineQuoteActions(account ?? null)}
                getItemActions={filterActions}
              />
            )}
          />
        ),
      },
      {
        hidden: !canViewSalesOrders,
        disabled: !account || !isCustomerCrmAccount(account),
        name: SalesTabName.Orders,
        template: account && opportunityNumber && (
          <LazyModuleLoader
            className="xt-lazy-module-loader-tab-container"
            module={SalesOrdersModule}
            render={({ SalesOrdersListGeneral, defineSalesOrderFilters }: SalesOrdersModuleDefinition) => (
              <SalesOrdersListGeneral
                mode={mode}
                actions={opportunitySalesOrderActions}
                filters={defineSalesOrderFilters({ [SalesOrderFilterFieldName.Opportunity]: opportunityNumber })}
                shouldOpenDetailsInDialog
                attachmentOptions={{ opportunityNumber, opportunityAccount: account.number }}
                disabled={disabled}
                isMobile={isMobile}
                account={account}
              />
            )}
          />
        ),
      },
    ]

    return <XtTabs tabs={tabs} />
  }
)
