import { IFormHook } from 'common/hooks/form/form.types'
import { IInventoryAdjustmentFormState } from '../inventory-adjustment-details.types'

export interface IInventoryAdjustmentCostParams {
  formMethods: IFormHook<IInventoryAdjustmentFormState>
}

export enum InventoryAdjustmentCostType {
  Calculated = 'Calculated',
  Manual = 'Manual',
}
