import type { Overwrite } from 'utility-types'
import type { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'

export interface IKanbanOpportunity {
  opportunity_number: string
  opportunity_name: string
  opportunity_stage: string
  account: string
  contact: string | null
  allow_sms: boolean | null
  contact_phone: string | null
  opportunity_source: string
  opportunity_type: string
  opportunity_priority: string | null
  project: string | null
  probability_percent: number | null
  amount: number | null
  currency: string | null
  users: Array<string | null>
  last_activity_date: string
  inactive_for_days: number
  editable: boolean
}

export interface IKanbanOpportunityStage {
  stage: string
  base_currency: string
  opportunity_counter: number
  opportunity_total: number
  average_deal: number
  stage_color: string | null
  opportunities: IKanbanOpportunity[] | null
}

export enum KanbanOpportunitiesLabel {
  ShowInactive = 'showinactive',
  User = 'User',
  AssignedTo = 'Assigned To',
  TargetDateOnOrAfter = 'Target Date on or After',
  TargetDateOnOrBefore = 'Target Date on or Before',
  Project = 'Project',
  Owner = 'Owner',
  Account = 'Account',
  Type = 'Type',
  TypePattern = 'Type Pattern',
  Source = 'Source',
  SourcePattern = 'Source Pattern',
  IsMyOpportunities = 'is_my_opportunities',
}

export enum KanbanOpportunitiesFilter {
  ShowInactive = 'showinactive',
  User = 'username',
  AssignedTo = 'assigned_username',
  TargetDateOnOrAfter = 'startDate',
  TargetDateOnOrBefore = 'endDate',
  Project = 'project',
  Owner = 'owner',
  Account = 'account',
  Type = 'type',
  TypePattern = 'type_pattern',
  Source = 'source',
  SourcePattern = 'source_pattern',
  IsMyOpportunities = 'is_my_opportunities',
}

export interface IKanbanOpportunitiesFilter {
  [KanbanOpportunitiesFilter.ShowInactive]?: boolean
  [KanbanOpportunitiesFilter.User]?: string
  [KanbanOpportunitiesFilter.AssignedTo]?: string
  [KanbanOpportunitiesFilter.TargetDateOnOrAfter]?: string
  [KanbanOpportunitiesFilter.TargetDateOnOrBefore]?: string
  [KanbanOpportunitiesFilter.Project]?: string
  [KanbanOpportunitiesFilter.Account]?: string
  [KanbanOpportunitiesFilter.Type]?: string
  [KanbanOpportunitiesFilter.TypePattern]?: string
  [KanbanOpportunitiesFilter.Source]?: string
  [KanbanOpportunitiesFilter.SourcePattern]?: string
  [KanbanOpportunitiesFilter.Owner]?: string
  [KanbanOpportunitiesFilter.IsMyOpportunities]?: boolean
}

export type KanbanOpportunitiesFilterPanel = Overwrite<
  IKanbanOpportunitiesFilter,
  {
    [KanbanOpportunitiesFilter.ShowInactive]: boolean
    [KanbanOpportunitiesFilter.User]: IXtAutocompleteOption | null
    [KanbanOpportunitiesFilter.AssignedTo]: IXtAutocompleteOption | null
    [KanbanOpportunitiesFilter.TargetDateOnOrAfter]: string | null
    [KanbanOpportunitiesFilter.TargetDateOnOrBefore]: string | null
    [KanbanOpportunitiesFilter.Project]: IXtAutocompleteOption | null
    [KanbanOpportunitiesFilter.Account]: IXtAutocompleteOption | null
    [KanbanOpportunitiesFilter.Type]: IXtAutocompleteOption | null
    [KanbanOpportunitiesFilter.TypePattern]: string
    [KanbanOpportunitiesFilter.Source]: IXtAutocompleteOption | null
    [KanbanOpportunitiesFilter.SourcePattern]: string
    [KanbanOpportunitiesFilter.Owner]: IXtAutocompleteOption | null
  }
>
