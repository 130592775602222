import React, { FC, memo } from 'react'
import { XtTabs } from 'components/xt-tabs/xt-tabs'
import { ICustomer } from 'companies/customers/customers.types'
import { QuoteFilterFieldName } from 'sales-shared/quotes.types'
import { SalesOrderFilterFieldName } from 'sales-shared/sales-orders.types'
import { XtMode } from 'common/common.types'
import { SalesOrdersModule, SalesOrdersModuleDefinition } from 'sales-orders/sales-orders.module'
import { QuotesModule, QuotesModuleDefinition } from 'quotes/quotes.module'
import { LazyModuleLoader } from 'components/lazy-module-loader/lazy-module-loader'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { SummaryTab } from './summary/summary'
import { customerSalesOrderActions, QuotesListActionsEditMode } from './sales-tabs.constants'
import { convertCustomerToAccountFulfilled } from '../../../../accounts/accounts.utils'

interface ISalesTabs {
  customer: ICustomer | null
  disabled: boolean
  isMobile: boolean
  mode: XtMode
  canViewQuotes: boolean
  canViewSalesOrders: boolean
  autoUpdate:boolean
  setAutoUpdate:Function
}

enum SalesTabName {
  Summary = 'Summary',
  Quotes = 'Quotes',
  Orders = 'Orders',
}

export const SalesTabs: FC<ISalesTabs> = memo(({ customer, disabled, isMobile, mode, canViewQuotes, canViewSalesOrders,autoUpdate,setAutoUpdate }) => {
  const tabs = [
    {
      name: SalesTabName.Summary,
      template: <SummaryTab salesDetails={customer?.sales_details ?? null} />,
    },
    {
      hidden: !canViewQuotes,
      name: SalesTabName.Quotes,
      template: customer && (
        <LazyModuleLoader
          className="xt-lazy-module-loader-tab-container"
          module={QuotesModule}
          render={({ QuotesListGeneral, defineQuoteFilters, filterActions }: QuotesModuleDefinition) => (
            <QuotesListGeneral
              shouldOpenDetailsInDialog
              mode={mode}
              disabled={disabled}
              isMobile={isMobile}
              account={convertCustomerToAccountFulfilled(customer)}
              actions={QuotesListActionsEditMode}
              filters={defineQuoteFilters({ [QuoteFilterFieldName.Customer]: defineAutocompleteOption(customer.customer_number) })}
              getItemActions={filterActions}
              setAutoUpdate={setAutoUpdate}
            />
          )}
        />
      ),
    },
    {
      hidden: !canViewSalesOrders,
      name: SalesTabName.Orders,
      template: customer && (
        <LazyModuleLoader
          className="xt-lazy-module-loader-tab-container"
          module={SalesOrdersModule}
          render={({ SalesOrdersListGeneral, defineSalesOrderFilters }: SalesOrdersModuleDefinition) => (
            <SalesOrdersListGeneral
              disabled={disabled}
              isMobile={isMobile}
              account={convertCustomerToAccountFulfilled(customer)}
              actions={customerSalesOrderActions}
              filters={defineSalesOrderFilters({ [SalesOrderFilterFieldName.CustomerNumber]: customer.customer_number })}
              mode={mode}
              shouldOpenDetailsInDialog
              autoUpdate={autoUpdate}
            />
          )}
        />
      ),
    },
  ]

  return <XtTabs tabs={tabs} />
})
