import type { IXtNavbar, IXtNavbarOption } from './navbar.types'
import React, { FC } from 'react'
import { Tab, Tabs } from '@mui/material'
import { NavLink, useLocation } from 'react-router-dom'
import * as styles from './navbar.module.scss'
import { XtDropdownMenu } from './dropdown-menu'

export const XtNavbar: FC<IXtNavbar> = function XtNavBar({ options }) {
  const location = useLocation()

  const currentTabIndex = options.findIndex((option) => {
    const currentTabRegEx = new RegExp(`^${option.path}.*`)
    return currentTabRegEx.test(location.pathname)
  })
  const currentTab = currentTabIndex === -1 ? 0 : currentTabIndex

  const renderTab = (navbarOptions: IXtNavbarOption[]): JSX.Element[] =>
    navbarOptions.map(({ path, name, type, children }, i) =>
      type !== 'dropdown' && type !== 'buttons' ? (
        <Tab
          key={i}
          label={name}
          classes={{
            root: styles.navContainer,
            textColorInherit: styles.tabTextInherit,
          }}
          to={path}
          component={NavLink}
        />
      ) : (
        <XtDropdownMenu menuList={children} type={type} name={name} />
      )
    )

  return (
    <>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={currentTab}
        classes={{ flexContainer: styles.tabsContainer, indicator: styles.indicator }}
        textColor="inherit"
        allowScrollButtonsMobile
      >
        {renderTab(options)}
      </Tabs>
    </>
  )
}
