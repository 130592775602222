import React, { FC } from 'react'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { useAuthModule } from 'auth/auth-module-hook'
import { PersonalProfile } from '../personal-profile/personal-profile'
import * as styles from './menu.module.scss'

interface IXtMenuProps {
  sidebar?: boolean
}

export const XtMenu: FC<IXtMenuProps> = ({ sidebar }) => {
  const { AuthService } = useAuthModule()
  const handleClick: VoidFunction = () => {
    AuthService.logout()
  }

  return sidebar ? (
    <span className={styles.logout} onClick={handleClick} role="button" tabIndex={0} onKeyPress={handleClick}>
      <SvgIcon iconId={SvgIconIds.USER_ICON} />
      <p className={styles.logoutText}>Log out</p>
    </span>
  ) : (
    <PersonalProfile />
  )
}
