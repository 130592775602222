import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'

export const defaultCreateItemFormState = {
  id: '',
  item_number: '',
  description1: '',
  item_is_sold: false,
  list_price: 0,
  item_type: '',
}

export enum CreateItemFormField {
  Number = 'item_number',
  Description = 'description1',
  Sold = 'item_is_sold',
  Price = 'list_price',
  ItemType = 'item_type',
  Id = 'id',
}

export enum CreateItemFormLabel {
  Number = 'Item Number',
  Description = 'Item Description',
  Sold = 'Item is Sold',
  Price = 'List Price',
  ItemType = 'Item Type',
}

export enum CreateItemValue {
  Purchased = 'Purchased',
  Manufactured = 'Manufactured',
  Reference = 'Reference',
}

export const createItemOptions: IXtAutocompleteOption[] = [
  { label: CreateItemValue.Purchased, id: CreateItemValue.Purchased },
  { label: CreateItemValue.Manufactured, id: CreateItemValue.Manufactured },
  { label: CreateItemValue.Reference, id: CreateItemValue.Reference },
]

export const defaultCreateItemDialogState = {
  open: false,
  selected: null,
  editMode: false,
}
