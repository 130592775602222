import { nanoid } from 'nanoid'
import { IItemsService } from 'products/items/items.service'
import { IObjectWithId, IPaginationData, IPaginationParams } from 'common/common.types'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { savePDFFile } from 'common/utils/file.utils'
import { IErrorHandler } from 'core/services/error-handler.service'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { convertFiltersPanelState } from 'common/utils/filter.utils'
import { InventoryHistoryByLocationTableItem } from './inventory-history-by-location/inventory-history-by-location-list.types'
import { InventoryHistoryByLocationFilters } from './inventory-history-by-location/inventory-history-by-location-list-form/inventory-history-by-location-list-form.types'
import {
  InventoryHistoryByLotSerialState,
  InventoryHistoryByLotSerialTableItem,
} from './inventory-history-by-lot-serial-list/inventory-history-by-lot-serial-list.types'
import { getFileNameForHistoryByLotSerial } from './inventory-history-by-lot-serial-list/inventory-history-by-lot-serial-list-form/inventory-history-by-lot-serial-form-filter/inventory-history-by-lot-serial-form-filter.utils'
import { InventoryHistoryFormState, InventoryHistoryTableItem } from './inventory-history/inventory-history-list.types'
import { IInventoryHistoryService } from './inventory-history.service'

function addIdToData<TData>(data: Array<TData>): Array<TData & IObjectWithId> {
  return Array.isArray(data)
    ? data.map((item) => ({
        ...item,
        id: nanoid(),
      }))
    : []
}

export interface ICostingItemCostsUtilsService {
  printCostsSummary(itemNumber: string): Promise<void>
  printCostsHistory(itemNumber: string): Promise<void>
  getInventoryHistoryByLocation(
    filters: InventoryHistoryByLocationFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<InventoryHistoryByLocationTableItem>>
  printInventoryHistoryByLocation(filters: InventoryHistoryByLocationFilters): Promise<void>
  getInventoryHistoryByLotSerial(
    filters: InventoryHistoryByLotSerialState,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<InventoryHistoryByLotSerialTableItem>>
  printInventoryHistoryByLotSerial(filters: InventoryHistoryByLotSerialState): Promise<void>
  getInventoryHistory(
    filters: InventoryHistoryFormState,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<InventoryHistoryTableItem>>
  printInventoryHistory(filters: InventoryHistoryFormState): Promise<void>
}

export class CostingItemCostsUtilsService implements ICostingItemCostsUtilsService {
  constructor(
    private readonly itemsService: IItemsService,
    private readonly errorHandler: IErrorHandler,
    private readonly inventoryHistoryService: IInventoryHistoryService
  ) {
    bindAllInstanceMethods(this)
  }

  public async printCostsSummary(itemNumber: string): Promise<void> {
    try {
      const file = await this.itemsService.getItemCostsSummaryPDF(itemNumber)
      savePDFFile(file, `item-costs-summary-${itemNumber}-file`)
    } catch (e) {
      this.errorHandler.handleError(e)
    }
  }

  public async printCostsHistory(itemNumber: string): Promise<void> {
    try {
      const file = await this.itemsService.getCostsHistoryPDF(itemNumber)
      savePDFFile(file, `item-costs-history-${itemNumber}-file`)
    } catch (e) {
      this.errorHandler.handleError(e)
    }
  }

  public async getInventoryHistoryByLocation(
    filters: InventoryHistoryByLocationFilters,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<InventoryHistoryByLocationTableItem>> {
    try {
      const { data, total } = await this.inventoryHistoryService.getAllByLocation(filters, paginationParams, sortOptions)

      return {
        data: addIdToData(data),
        total,
      }
    } catch {
      return {
        data: [],
        total: 0,
      }
    }
  }

  public async getInventoryHistoryByLotSerial(
    filters: InventoryHistoryByLotSerialState,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<InventoryHistoryByLotSerialTableItem>> {
    try {
      const { data, total } = await this.inventoryHistoryService.getAllByLotSerial(
        convertFiltersPanelState({ ...filters, showDetail: true }),
        paginationParams,
        sortOptions
      )

      return {
        data: addIdToData(data),
        total,
      }
    } catch {
      return {
        data: [],
        total: 0,
      }
    }
  }

  public async printInventoryHistoryByLocation(filters: InventoryHistoryByLocationFilters): Promise<void> {
    const { location } = filters
    try {
      const file = await this.inventoryHistoryService.printByLocation(filters)
      savePDFFile(file, `inventory-history-by-${location}`)
    } catch (e) {
      this.errorHandler.handleError(e)
    }
  }

  public async printInventoryHistoryByLotSerial(filters: InventoryHistoryByLotSerialState): Promise<void> {
    const convertedFilters = convertFiltersPanelState(filters)
    const { item_number, lotserial } = convertedFilters
    try {
      const file = await this.inventoryHistoryService.printByLotserial(convertedFilters)
      savePDFFile(file, getFileNameForHistoryByLotSerial(item_number, lotserial))
    } catch {
      throw new Error(`There are no documents to print`)
    }
  }

  public async getInventoryHistory(
    filters: InventoryHistoryFormState,
    paginationParams: IPaginationParams,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<InventoryHistoryTableItem>> {
    try {
      const { data, total } = await this.inventoryHistoryService.getAll(
        convertFiltersPanelState({ ...filters, showDetail: true }),
        paginationParams,
        sortOptions
      )

      return {
        data: addIdToData(data),
        total,
      }
    } catch {
      return {
        data: [],
        total: 0,
      }
    }
  }

  public async printInventoryHistory(filters: InventoryHistoryFormState): Promise<void> {
    try {
      const file = await this.inventoryHistoryService.printAll(convertFiltersPanelState(filters))
      savePDFFile(file, `inventory-history`)
    } catch (e) {
      throw new Error(`There are no documents to print`)
    }
  }
}
