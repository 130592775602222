import React, { FC, ReactElement } from 'react'
import { RouteProps, StaticContext } from 'react-router'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { IAsyncRoute, IRoute, RouteGuardConstructor } from './routing.types'
import { GuardedRoute } from './guarded-route/guarded-route'
import { IXtMode, XtMode } from '../common.types'

export function processPath(path: string): string {
  return path.startsWith('/') ? path : `/${path}`
}

function buildPath(routePath: string, parentRoutePath?: string): string {
  const path = parentRoutePath ? `${processPath(parentRoutePath)}${processPath(routePath)}` : processPath(routePath)
  return path !== '/' ? path.replace(/\/$/, '') : path
}

function mergeGuards(
  parentGuards: RouteGuardConstructor[] | undefined = [],
  childGuards: RouteGuardConstructor[] | undefined = []
): RouteGuardConstructor[] {
  return [...parentGuards, ...childGuards]
}

export function isAsyncRoute<T = never>(route: IRoute | IAsyncRoute<T>): route is IAsyncRoute<T> {
  return 'module' in route
}

/**
 * Updates a route in order to include parent route data like path, guards, children and so on.
 * @param route
 * @param parentRoute
 */
export function processRoute(route: IRoute | IAsyncRoute<never>, parentRoute?: IRoute | IAsyncRoute<never>): IRoute {
  if (isAsyncRoute(route)) {
    return {
      ...route,
      path: buildPath(route.path, parentRoute?.path),
      guards: mergeGuards(parentRoute?.guards, route.guards),
    }
  }

  const processedRoute = {
    ...route,
    path: buildPath(route.path, parentRoute?.path),
    redirectTo: route.redirectTo !== undefined ? processPath(route.redirectTo) : undefined,
    guards: mergeGuards(parentRoute?.guards, route.guards),
  }
  processedRoute.children = route.children?.map((childRoute) => processRoute(childRoute, processedRoute))
  return processedRoute
}

/**
 * Used to update route path, build path for a child route, merge guards
 * @param routes - array of routes
 */
export function processRoutes(routes: Array<IRoute | IAsyncRoute<never>>): Array<IRoute | IAsyncRoute<never>> {
  return routes.map((route) => processRoute(route))
}

function isRedirectRoute(redirect: string | undefined): redirect is string {
  return typeof redirect === 'string'
}

export function buildRoute(route: IRoute, render?: RouteProps['render']): JSX.Element {
  const renderFunction = isRedirectRoute(route.redirectTo) ? () => <Redirect to={route.redirectTo as string} /> : render

  return (
    <GuardedRoute
      key={route.path}
      path={route.path}
      guards={route.guards}
      exact={route.exact}
      render={renderFunction}
      component={route.component}
    />
  )
}

export function buildPageComponent(
  props: React.PropsWithChildren<RouteComponentProps<{}, StaticContext, unknown>>,
  mode: XtMode,
  Component: FC<IXtMode>
): ReactElement {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...props} mode={mode} />
}

export function getQueryParam(name: string): string | null {
  return new URLSearchParams(window.location.search).get(name)
}
