import React, { FC, useEffect } from 'react'
import { Prompt } from 'react-router'
import { IXtPromptProps } from './xt-prompt.types'

export const XtPrompt: FC<IXtPromptProps> = ({ showPrompt, message }) => {
  useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent): void => {
      if (showPrompt) {
        e.preventDefault()
        e.returnValue = ''
      }
    }
    const onPopState = function () {
      history.pushState(null, '', window.location.href)
    }

    if (showPrompt) {
      window.addEventListener('beforeunload', beforeUnload)
      window.addEventListener('popstate', onPopState)
    }

    return () => {
      history.pushState(null, '', window.location.href)
      window.removeEventListener('beforeunload', beforeUnload)
      window.removeEventListener('popstate', onPopState)
    }
  }, [showPrompt])

  return <Prompt when={showPrompt} message={message} />
}
