import { IMask } from 'react-imask'
import { expandN } from 'regex-to-strings'
import { inputMaskDefinitions, maskDefinitions } from '../constants'

export function isValidRegex(regex: string): boolean {
  try {
    return Boolean(new RegExp(regex))
  } catch {
    return false
  }
}

export function convertStringToRegex(pattern: string): RegExp {
  return new RegExp(pattern)
}

export function isValidMask(mask: string): boolean {
  const definitionSymbols = [maskDefinitions.any.symbol, maskDefinitions.digit.symbol, maskDefinitions.letter.symbol]
  return [...mask].some((char) => definitionSymbols.includes(char))
}

const maxNumberOfTestStrings = 20 // max number of generated data that should be tested against input mask and Regex
const testStringMaxLength = 50 // max test string length. In some cases a test string should be expanded to fully check a mask

/**
 * Checks that the input mask doesn't conflict with the regex pattern.
 * @param mask
 * @param validator
 */
export async function isMaskValidForValidator(mask: string, validator: RegExp): Promise<boolean> {
  if (!mask.length) {
    return false
  }

  const testStrings = expandN(validator, maxNumberOfTestStrings).map((testString) => testString.padEnd(testStringMaxLength, testString))

  const maskInstance = IMask.createMask({
    mask,
    definitions: inputMaskDefinitions,
  })

  return testStrings.every((testString) => {
    maskInstance.resolve(testString)
    return validator.test(maskInstance.unmaskedValue)
  })
}
