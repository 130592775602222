import { Overwrite } from 'utility-types'
import { IUomConversion } from 'core/services/uom.service'
import { TransferListOption } from 'components/controls/xt-transfer-list/xt-transfer-list.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'

export enum ConversionsDetailsDialogFormField {
  UomTo = 'uom_to',
  ToValue = 'to_value',
  UomFrom = 'uom_from',
  FromValue = 'from_value',
  Fractional = 'fractional',
  Active = 'active',
  UomTypes = 'uom_types',
}

export enum ConversionsDetailsDialogLabel {
  UomTo = 'To Inventory UOM',
  ToValue = 'To value',
  UomFrom = 'From Inventory UOM',
  FromValue = 'From value',
  Fractional = 'Fractional ',
  Active = 'Active',
  UomTypes = 'Selected Types',
}

export type ConversionsDetailsDialogForm = Overwrite<
  Pick<
    IUomConversion,
    | ConversionsDetailsDialogFormField.UomTo
    | ConversionsDetailsDialogFormField.ToValue
    | ConversionsDetailsDialogFormField.UomFrom
    | ConversionsDetailsDialogFormField.FromValue
    | ConversionsDetailsDialogFormField.Fractional
    | ConversionsDetailsDialogFormField.Active
    | ConversionsDetailsDialogFormField.UomTypes
  >,
  {
    [ConversionsDetailsDialogFormField.UomTypes]: TransferListOption[]
    [ConversionsDetailsDialogFormField.UomTo]: IXtAutocompleteOption
    [ConversionsDetailsDialogFormField.UomFrom]: IXtAutocompleteOption
  }
>
