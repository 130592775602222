import * as yup from 'yup'
import { ObjectSchema } from 'yup'
import { validationMessage } from 'common/validation/validation'
import { ReportType } from 'shipments/shipments.types'
import { shippingPrintDialogsSharedValidationSchema } from '../print-dialogs.validation'
import { ShippingPrintFormSharedField } from '../print-dialogs.types'
import { PackingListPrintFormField } from './print-packing-list.types'

export const packingListPrintFormSchema = yup.object().shape({
  ...shippingPrintDialogsSharedValidationSchema,
  [ShippingPrintFormSharedField.ShipmentNumber]: yup
    .object()
    .nullable()
    .when(PackingListPrintFormField.ReportType, (reportType: ReportType, schema: ObjectSchema) => {
      return reportType === ReportType.PackingList ? schema.required(validationMessage.isRequired) : schema
    }),
})
