// extracted by mini-css-extract-plugin
export var root = "customers-list_root__M5zF6";
export var xtContent = "customers-list_xt-content__P+WEw";
export var xtContentWithRemarks = "customers-list_xt-content-with-remarks__ifJZK";
export var muiFormHelperTextRoot = "customers-list_MuiFormHelperText-root__aaQ0+";
export var xtListPageContainer = "customers-list_xt-list-page-container__71XDT";
export var listContent = "customers-list_listContent__BLKPx";
export var muiInputBaseRoot = "customers-list_MuiInputBase-root__8ZMDG";
export var muiTextFieldRoot = "customers-list_MuiTextField-root__rDAI9";
export var muiTablePaginationRoot = "customers-list_MuiTablePagination-root__lBtsx";
export var muiTablePaginationSelectRoot = "customers-list_MuiTablePagination-selectRoot__ht3sG";
export var muiTablePaginationSelect = "customers-list_MuiTablePagination-select__QZJWe";
export var muiStepperRoot = "customers-list_MuiStepper-root__ATraq";
export var muiStepperHorizontal = "customers-list_MuiStepper-horizontal__W+8dg";
export var muiStepIconRoot = "customers-list_MuiStepIcon-root__6W7Bc";
export var muiStepIconActive = "customers-list_MuiStepIcon-active__jbn2r";
export var muiStepIconCompleted = "customers-list_MuiStepIcon-completed__jVwL4";
export var muiListItemRoot = "customers-list_MuiListItem-root__ZKSIR";
export var muiSelected = "customers-list_Mui-selected__b7xnX";
export var xtPageTitle = "customers-list_xt-page-title__1lLMV";
export var xtSubPageTitle = "customers-list_xt-subPage-title__5O+t1";
export var xtSectionBorder = "customers-list_xt-section-border__OZvRj";
export var xtPageHeader = "customers-list_xt-page-header__yHVUz";
export var xtDialogScrollable = "customers-list_xt-dialog-scrollable__ZWqAE";
export var reactToastNotificationsContainer = "customers-list_react-toast-notifications__container__AZNvP";
export var xtScrollbarStyle = "customers-list_xt-scrollbar-style__sxguK";
export var xtModalDetailsContent = "customers-list_xt-modal-details-content__eR-Nu";
export var xtDialogDetailsContent = "customers-list_xt-dialog-details-content__rCbPh";
export var xtLazyModuleLoaderTabContainer = "customers-list_xt-lazy-module-loader-tab-container__vVae-";
export var xtTextTruncated = "customers-list_xt-text-truncated__ksd6z";
export var xtSummaryContent = "customers-list_xt-summary-content__kBveB";
export var xtSummaryLabel = "customers-list_xt-summary-label__lviHU";
export var visibleHidden = "customers-list_visible-hidden__+kglU";
export var headerBottomMargin = "customers-list_header-bottom-margin__gY0Zb";
export var xtDialogHeaderLogoAlign = "customers-list_xt-dialog-header-logoAlign__+T5Dr";
export var xtStickyHeader = "customers-list_xt-sticky-header__vJJN-";
export var noBorder = "customers-list_no-border__jv8BZ";
export var muiOutlinedInputNotchedOutline = "customers-list_MuiOutlinedInput-notchedOutline__RGHok";
export var headerContent = "customers-list_headerContent__4VSBl";
export var listControls = "customers-list_listControls__WeXyc";
export var filterButton = "customers-list_filterButton__eey94";
export var customersListCheckbox = "customers-list_customersListCheckbox__kgZCm";
export var filtersContainer = "customers-list_filtersContainer__vTd09";