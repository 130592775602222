import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { ShippingLineTableItem } from 'shipping/shipping.types'
import { numberConverter } from 'common/utils/utils'
import { ShippingLineTotal } from './items-tab.types'

export const columnsItemsList: IXtTableColumn<ShippingLineTableItem>[] = [
  {
    id: 'line_number',
    field: 'line_number',
    headerName: '#',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'item_number',
    field: 'item_number',
    headerName: 'Item #',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'qty_at_shipping',
    field: 'qty_at_shipping',
    headerName: 'At Shipping',
    flex: '1 0 180px',
    width: 180,
    converter: numberConverter,
  },
  {
    id: 'net_weight',
    field: 'net_weight',
    headerName: 'Net Wght.',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
  {
    id: 'tare_weight',
    field: 'tare_weight',
    headerName: 'Tare Wght.',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
  {
    id: 'gross_weight',
    field: 'gross_weight',
    headerName: 'Gross Wght.',
    flex: '1 0 180px',
    width: 180,
    converter: numberConverter,
  },
]
export const columnsItemsTotal: IXtTableColumn<ShippingLineTotal>[] = [
  {
    id: 'line_number',
    field: 'line_number',
    headerName: '#',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'net_weight',
    field: 'net_weight',
    headerName: 'Net Wght.',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
  {
    id: 'tare_weight',
    field: 'tare_weight',
    headerName: 'Tare Wght.',
    flex: '1 0 160px',
    width: 160,
    converter: numberConverter,
  },
  {
    id: 'gross_weight',
    field: 'gross_weight',
    headerName: 'Gross Wght.',
    flex: '1 0 180px',
    width: 180,
    converter: numberConverter,
  },
]
