import React, { FC, useCallback, useEffect, useState } from 'react'
import { CardHeader, MenuItem, TextField } from '@material-ui/core'
import { useItemsSharedModule } from 'products/items-shared/items-shared-module-hook'
import { FormCheckboxLabel, FormField, FormXtAutocomplete } from 'common/utils/form/form.components'
import { DecimalFormField } from 'components/controls/decimal-form-field/decimal-form-field'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { useDocumentsModule } from 'documents/documents-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { InventoryUomOption } from 'core/services/uom.service'
import { ItemDetailsFormField, ItemDetailsFormLabel } from '../../../../item-details.types'
import * as styles from '../item-details-cards.module.scss'
import { IItemDetailsCard } from '../item-details-cards.types'
import { defaultFormDisabledState, MAX_VALUE } from './item-details-card.constants'
import { IItemDetailsCardFormFieldDisabledState } from './item-details-card.types'
import { calculateFormFieldState, convertToTaxTypes } from './item-details-card.utils'

export const ItemDetailsCard: FC<IItemDetailsCard> = ({
  formMethods,
  disabled,
  isEditMode,
  currency,
  taxTypes,
  onTaxTypeChange,
  isAnySiteSerial,
}) => {
  const { control, watch, setValue } = formMethods
  const { DocumentsUtilsService } = useDocumentsModule()
  const { CoreUtilsService, ErrorHandler } = useCoreModule()
  const { ClassCodesService } = useItemsSharedModule()

  const [disabledState, setDisabledState] = useState<IItemDetailsCardFormFieldDisabledState>(defaultFormDisabledState)

  const uom = watch(ItemDetailsFormField.InventoryUOM)
  const itemType = watch(ItemDetailsFormField.ItemType)

  const onInventoryUomChange = useCallback(
    (inventoryUom: InventoryUomOption | null) => {
      setValue(ItemDetailsFormField.InventoryUOM, inventoryUom, { shouldDirty: true, shouldValidate: true })
      setValue(ItemDetailsFormField.SellingUOM, inventoryUom, { shouldDirty: true, shouldValidate: true })
    },
    [setValue]
  )

  const handleClassCodeChange = useCallback(
    async (classCode: IXtAutocompleteOption | null) => {
      setValue(ItemDetailsFormField.ClassCode, classCode, { shouldDirty: true, shouldValidate: true })
      if (!classCode) {
        return
      }
      try {
        const data = await ClassCodesService.get(classCode.id)
        if (data.default_tax_type && onTaxTypeChange) {
          onTaxTypeChange(convertToTaxTypes(taxTypes, data.default_tax_type))
        }
      } catch (e) {
        ErrorHandler.handleError(e)
      }
    },
    [setValue, ClassCodesService, onTaxTypeChange, taxTypes, ErrorHandler]
  )

  useEffect(() => {
    const formFieldStates = calculateFormFieldState(itemType)

    if (!formFieldStates) {
      setDisabledState(defaultFormDisabledState)
      return
    }
    const newDisabledState = { ...defaultFormDisabledState }

    formFieldStates.forEach(({ field, value, disabled: fieldDisabled }) => {
      setValue(field, value)
      newDisabledState[field] = fieldDisabled
    })
    setDisabledState(newDisabledState)
  }, [itemType, setValue])

  return (
    <div className={styles.cardContainer}>
      <CardHeader title="Item Details" className={styles.cardHeader} />
      <div className={styles.cardContent}>
        <FormXtAutocomplete
          loadOptions={DocumentsUtilsService.loadItemTypes}
          name={ItemDetailsFormField.ItemType}
          control={control}
          label={ItemDetailsFormLabel.ItemType}
          disabled={disabled}
        />
        <FormXtAutocomplete
          required
          loadOptions={DocumentsUtilsService.loadClassCodes}
          name={ItemDetailsFormField.ClassCode}
          control={control}
          label={ItemDetailsFormLabel.ClassCode}
          disabled={disabled}
          onChange={handleClassCodeChange}
        />
        <FormXtAutocomplete
          required
          loadOptions={CoreUtilsService.loadUomOptions}
          name={ItemDetailsFormField.InventoryUOM}
          control={control}
          label={ItemDetailsFormLabel.InventoryUOM}
          disabled={disabled || isEditMode || Boolean(uom?.uom_conversions.length)}
          onChange={onInventoryUomChange}
        />
        <div className={styles.checkboxBlock}>
          <FormCheckboxLabel
            label={ItemDetailsFormLabel.PickList}
            control={control}
            name={ItemDetailsFormField.PickList}
            disabled={disabled || disabledState[ItemDetailsFormField.PickList]}
          />
          <FormCheckboxLabel
            label={ItemDetailsFormLabel.Fractional}
            control={control}
            name={ItemDetailsFormField.Fractional}
            disabled={disabled || disabledState[ItemDetailsFormField.Fractional] || isAnySiteSerial}
          />
          <FormCheckboxLabel
            label={ItemDetailsFormLabel.Configured}
            control={control}
            name={ItemDetailsFormField.Configured}
            disabled={disabled || disabledState[ItemDetailsFormField.Configured]}
          />
        </div>
        <DecimalFormField
          name={ItemDetailsFormField.MaximumDesiredCost}
          control={control}
          label={ItemDetailsFormLabel.MaximumDesiredCost}
          maxValue={MAX_VALUE}
          fixedDecimalScale={6}
          disabled={disabled}
          allowNegative={false}
        />
        <TextField select disabled defaultValue={currency} variant="outlined">
          <MenuItem value={currency}>{currency}</MenuItem>
        </TextField>
        <FormXtAutocomplete
          loadOptions={DocumentsUtilsService.loadFreightClasses}
          name={ItemDetailsFormField.FreightClass}
          control={control}
          label={ItemDetailsFormLabel.FreightClass}
          disabled={disabled || disabledState[ItemDetailsFormField.FreightClass]}
        />
        <FormField
          name={ItemDetailsFormField.BarCode}
          control={control}
          label={ItemDetailsFormLabel.BarCode}
          disabled={disabled || disabledState[ItemDetailsFormField.BarCode]}
        />
      </div>
    </div>
  )
}
