import { Overwrite } from 'utility-types'
import { SingleFormRegister } from 'common/hooks/form/multiple-form-manager/multiple-form-manager.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IObjectWithId, XtMode } from 'common/common.types'
import { ControlMethodOption, IItemSite } from 'products/items/items.types'
import { sitesDetailsDialogLocationFormKey } from './sites-details-dialog-location.consts'
import { SitesDetailsSiteOption } from '../../sites-details-dialog-main/sites-details-dialog-main.types'

export interface ISitesDetailsDialogLocation {
  mode: XtMode
  register: SingleFormRegister<typeof sitesDetailsDialogLocationFormKey, SitesDetailsDialogLocationForm>
  itemSite: IItemSite | null
  controlMethod: ControlMethodOption
  currentSite: SitesDetailsSiteOption | null
}

export enum SitesDetailsDialogLocationFormField {
  MultipleLocationControl = 'multiple_location_control',
  DisallowBlankWipLocations = 'disallow_blank_wip_locations',
  UseDefaultLocation = 'use_default_location',
  DefaultLocationType = 'default_location_type',
  ReceiveLocation = 'receive_location',
  AutoDistrReceiveLocation = 'auto_distr_receive_location',
  Location = 'location',
  AutoDistrLocation = 'auto_distr_location',
  IssueLocation = 'issue_location',
  AutoDistrIssueLocation = 'auto_distr_issue_location',
  UserDefinedLocation = 'user_defined_location',
  LocationComment = 'location_comment',
  RestrictedLocations = 'restricted_locations',
}

export enum SitesDetailsDialogLocationFormFieldLabel {
  LocationSystem = 'Location System',
  MultipleLocationControl = 'Multiple Location Control',
  DisallowBlankWipLocations = 'Disallow Blank Wip Locations',
  UseDefaultLocation = 'Use Default Location',
  DefaultLocationType = 'Default Location Type',
  ReceiveLocation = 'Receive',
  AutoDistrReceiveLocation = 'Receive Auto',
  Location = 'Stock',
  AutoDistrLocation = 'Stock Auto',
  IssueLocation = 'Issue',
  AutoDistrIssueLocation = 'Issue Auto',
  UserDefinedLocation = 'User Defined Location',
  LocationComment = 'Location Comment',
  RestrictedLocations = 'Restricted Locations',
}

export type ItemSiteLocation = Pick<
  IItemSite,
  | SitesDetailsDialogLocationFormField.MultipleLocationControl
  | SitesDetailsDialogLocationFormField.DisallowBlankWipLocations
  | SitesDetailsDialogLocationFormField.ReceiveLocation
  | SitesDetailsDialogLocationFormField.AutoDistrReceiveLocation
  | SitesDetailsDialogLocationFormField.Location
  | SitesDetailsDialogLocationFormField.AutoDistrLocation
  | SitesDetailsDialogLocationFormField.IssueLocation
  | SitesDetailsDialogLocationFormField.AutoDistrIssueLocation
  | SitesDetailsDialogLocationFormField.UserDefinedLocation
  | SitesDetailsDialogLocationFormField.LocationComment
  | SitesDetailsDialogLocationFormField.RestrictedLocations
>

export type SitesDetailsDialogLocationForm = Overwrite<
  ItemSiteLocation,
  {
    [SitesDetailsDialogLocationFormField.MultipleLocationControl]: boolean
    [SitesDetailsDialogLocationFormField.DisallowBlankWipLocations]: boolean
    [SitesDetailsDialogLocationFormField.ReceiveLocation]: IXtAutocompleteOption | null
    [SitesDetailsDialogLocationFormField.AutoDistrReceiveLocation]: boolean
    [SitesDetailsDialogLocationFormField.Location]: IXtAutocompleteOption | null
    [SitesDetailsDialogLocationFormField.AutoDistrLocation]: boolean
    [SitesDetailsDialogLocationFormField.IssueLocation]: IXtAutocompleteOption | null
    [SitesDetailsDialogLocationFormField.AutoDistrIssueLocation]: boolean
    [SitesDetailsDialogLocationFormField.UserDefinedLocation]: string | null
    [SitesDetailsDialogLocationFormField.LocationComment]: string | null
    [SitesDetailsDialogLocationFormField.RestrictedLocations]: IRestrictedLocationRow[]
  }
> & {
  [SitesDetailsDialogLocationFormField.UseDefaultLocation]: boolean
  [SitesDetailsDialogLocationFormField.DefaultLocationType]: DefaultLocationType | null
}

export enum DefaultLocationType {
  Location = 'Location',
  UserDefined = 'User-Defined',
}

export interface IRestrictedLocationRow extends IObjectWithId {
  restrictedLocation: string
  locationDescription: string
  allowed: boolean
}
