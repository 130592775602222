import { Overwrite } from 'utility-types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IFilterFormState } from 'components/pagefilter/filter/filter.types'

export enum KanbanTasksLabel {
  ShowCompleted = 'Show Completed',
  User = 'User',
  Owner = 'Owner',
  AssignedTo = 'Assigned To',
  Account = 'Account',
  Project = 'Project',
  DueDate = 'Due Date',
  DueBefore = 'Due Date on or Before',
  DueAfter = 'Due Date on or After',
  CompletedOnly = 'Show Completed Only',
}

export enum KanbanTasksFilter {
  ShowCompleted = 'showcompleted',
  User = 'user',
  Owner = 'owner',
  AssignedTo = 'assignedto',
  Account = 'account',
  Project = 'project',
  DueDate = 'duedate',
  DueBefore = 'duebefore',
  DueAfter = 'dueafter',
  CompletedOnly = 'completedonly',
}

export interface IKanbanAssignee {
  user: string
}

export interface IKanbanTask {
  task_id: number
  task_number: string
  task_name: string
  task_state: string
  task_status: string
  due_date: string
  assignees: IKanbanAssignee[]
  status_color: string | null
  editable: boolean
}

export interface IKanbanTaskPayload {
  priority: string
  task_counter: number | null
  priority_color: string | null
  order: number
  tasks: IKanbanTask[] | null
}

export interface IKanbanTasksFilter {
  [KanbanTasksFilter.ShowCompleted]?: boolean
  [KanbanTasksFilter.User]?: string
  [KanbanTasksFilter.Owner]?: string
  [KanbanTasksFilter.AssignedTo]?: string
  [KanbanTasksFilter.Account]?: string
  [KanbanTasksFilter.Project]?: string
  [KanbanTasksFilter.DueDate]?: string
  [KanbanTasksFilter.DueBefore]?: string
  [KanbanTasksFilter.DueAfter]?: string
  [KanbanTasksFilter.CompletedOnly]?: boolean
}

export interface IKanbanTasksFilterPanel
  extends Overwrite<
    IKanbanTasksFilter,
    {
      [KanbanTasksFilter.User]: IXtAutocompleteOption | null
      [KanbanTasksFilter.Owner]: IXtAutocompleteOption | null
      [KanbanTasksFilter.AssignedTo]: IXtAutocompleteOption | null
      [KanbanTasksFilter.Account]: IXtAutocompleteOption | null
      [KanbanTasksFilter.Project]: IXtAutocompleteOption | null
      [KanbanTasksFilter.DueDate]: string | null
      [KanbanTasksFilter.DueBefore]: string | null
      [KanbanTasksFilter.DueAfter]: string | null
    },
    IFilterFormState
  > {}
