import { IItemsFilters } from 'products/items/items-list/items-list.types'

export const inventoryModuleToken = 'InventoryModule'
export const inventoryRoutePath = '/inventory'
export const inventoryRoutes = {
  inventoryHistory: 'inventory-history',
  inventoryHistoryByLocation: 'history-by-location',
  inventoryHistoryByLotSerial: 'history-by-lot-serial',
  inventoryAvailability: 'inventory-availability',
  runningAvailability: 'running-availability',
  transactions: 'transactions',
}

export const defaultItemNumberFilters: IItemsFilters = { showInactive: true }
