import { FormRadioGroupOptions } from 'components/controls/xt-radio-group/xt-radio-group'
import { AccountDetailsField, AccountDetailsType, IAccountsDetails } from './account-details.types'

export const defaultAccountDetailsFormValues: IAccountsDetails = {
  [AccountDetailsField.AccountName]: '',
  [AccountDetailsField.AccountNumber]: '',
  [AccountDetailsField.Active]: true,
  [AccountDetailsField.Parent]: null,
  [AccountDetailsField.Owner]: null,
  [AccountDetailsField.Type]: AccountDetailsType.Organization,
  [AccountDetailsField.Addresses]: null,
  [AccountDetailsField.AssignedUser]: null,
}

export const accountDetailsType: FormRadioGroupOptions = [
  { label: AccountDetailsType.Organization, id: AccountDetailsType.Organization },
  { label: AccountDetailsType.Individual, id: AccountDetailsType.Individual },
]

export const getAccountDetailsPageTitle = (id: string = '') => `xTuple - ${id ? `Account: ${id}` : 'Create Account'}`
