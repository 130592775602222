import { Overwrite } from 'utility-types'
import { ItemOption, ItemSiteOption } from 'products/items/items.types'

export interface IRunningAvailabilityFormParams {
  disabled: boolean
  onFilterChange(formData: IRunningAvailabilityFormFilled): Promise<void>
  site: string | null
  itemNumber: string | undefined
}

export enum RunningAvailabilityFormField {
  ItemNumber = 'itemNumber',
  Site = 'site',
}
export enum RunningAvailabilityFormLabel {
  ItemNumber = 'Item Number',
  Site = 'Site',
}

export interface IRunningAvailabilityFormFilled {
  [RunningAvailabilityFormField.ItemNumber]: ItemOption
  [RunningAvailabilityFormField.Site]: ItemSiteOption
}

export interface IRunningAvailabilityForm
  extends Overwrite<
    IRunningAvailabilityFormFilled,
    {
      [RunningAvailabilityFormField.ItemNumber]: ItemOption | null
      [RunningAvailabilityFormField.Site]: ItemSiteOption | null
    }
  > {}
