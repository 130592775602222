import { useCallback, useRef, useState } from 'react'
import { IItemSite, ItemSiteDistributionOption } from 'products/items/items.types'
import { useCoreModule } from 'core/core-module-hook'
import { useProductsModule } from 'products/products-module-hook'
import { handleLocationDistribution } from './lot-serial-location-hook.utils'
import { ILotSerialLocationHook, ILotSerialLocationHookResetResponse, ILotSerialLocationState } from './lot-serial-location-hook.types'

/**
 * Hook handles Location table column: dropdown options, default option
 */
export function useLotSerialLocation(): ILotSerialLocationHook {
  const { ErrorHandler } = useCoreModule()
  const { ItemsUtilsService } = useProductsModule()

  const defaultLocationRef = useRef<ItemSiteDistributionOption | null>(null)

  const [{ locations, defaultLocation }, setState] = useState<ILotSerialLocationState>({
    locations: [],
    defaultLocation: null,
  })

  const resetLocations = useCallback<(itemSite: IItemSite, qtyToAssign: number) => Promise<ILotSerialLocationHookResetResponse>>(
    async (itemSite, qtyToAssign) => {
      try {
        const data = await handleLocationDistribution(ItemsUtilsService, itemSite, qtyToAssign)
        setState({ locations: data.locations, defaultLocation: data.defaultLocation })
        defaultLocationRef.current = data.defaultLocation
        return data
      } catch (e) {
        ErrorHandler.handleError(e)
        return { locations: [], defaultLocation: null, lotSerialIncluded: false, autoDistributedLocationApplied: false }
      }
    },
    [ErrorHandler, ItemsUtilsService]
  )

  const getDefaultLocation = useCallback<() => ItemSiteDistributionOption | null>(() => defaultLocationRef.current, [])

  return {
    locations,
    resetLocations,
    defaultLocation,
    getDefaultLocation,
  }
}
