import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { AuthModule } from 'auth/auth.module'
import { CoreModule } from 'core/core.module'
import { DocumentsModule } from 'documents/documents.module'
import { ProductsModule } from 'products/products.module'
import { RunningAvailabilityModule } from 'running-availability/running-availability.module'
import { SharedModule } from 'shared/shared-module'
import { IPurchaseRequestsService } from './purchase-requests.service'
import { purchaseRequestsModule, purchaseRequestsServiceToken, purchaseRequestsUtilsServiceToken } from './purchase-requests.constants'
import { IPurchaseRequestsUtilsService } from './purchase-requests-utils.service'

export type PurchaseRequestsModuleDefinition = typeof import('./purchase-requests.exports')

export interface IPurchaseRequestModuleServices {
  PurchaseRequestsService: IPurchaseRequestsService
  PurchaseRequestsUtilsService: IPurchaseRequestsUtilsService
}

export const PurchaseRequestModule: ILazyModule<PurchaseRequestsModuleDefinition> = {
  name: purchaseRequestsModule,
  resolver: () => import('./purchase-requests.exports'),
  initializer: ({ PurchaseRequestsService, PurchaseRequestsUtilsService }) => {
    Injector.register<IPurchaseRequestsService>(
      purchaseRequestsServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        return new PurchaseRequestsService(httpClient)
      },
      Scope.Singleton
    )

    Injector.register<IPurchaseRequestsUtilsService>(
      purchaseRequestsUtilsServiceToken,
      (injector) => {
        const purchaseRequestService = injector.resolve<IPurchaseRequestsService>(purchaseRequestsServiceToken)

        return new PurchaseRequestsUtilsService(purchaseRequestService)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, AuthModule, DocumentsModule, ProductsModule, RunningAvailabilityModule, SharedModule],
}
