import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { cls } from 'common/utils/utils'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { useDocuments } from 'documents/documents-hook'
import { LoadingSpinner } from 'components/loading-spinner'
import { DocumentType } from 'documents/documents.types'
import { FormCheckboxLabel, FormField } from 'common/utils/form/form.components'
import { AdditionalDataCreationError, IXtMode } from 'common/common.types'
import { convertMode } from 'common/utils/mode.utils'
import { useFormControlState } from 'common/hooks/form/form-control.hook'
import { useCommentsModule } from 'comments/comments-module-hook'
import { useCharacteristicsModule } from 'characteristics/characteristics-module-hook'
import { useCoreModule } from 'core/core-module-hook'
import { ITaxType } from 'products/items-shared/tax-types/tax-types.types'
import { confirmationMessages } from 'common/constants'
import { useDocumentTitle } from 'common/hooks/documentTitle/useDocumentTitle'
import { XtPrompt } from 'components/xt-prompt'
import { ItemDetailsForm, ItemDetailsFormField, ItemSoldFormLabel } from './item-details.types'
import * as styles from './item-details.module.scss'
import { ItemDetailsTabs } from './item-details-tabs/item-details-tabs'
import { productsRoutePath, productsRoutes } from '../../products.constants'
import { itemsDetailsValidation } from './items-details.validation'
import { convertItemData, defineFormData } from './item-details.utils'
import { CreateItemPayload, IItem } from '../items.types'
import { useProductsModule } from '../../products-module-hook'
import { IXtItemNumberOptionBase } from '../components/item-number/item-number.types'
import { defineItemNumberOption } from '../components/item-number/item-number.utils'
import { getItemDetailsPageTitle } from '../items.constants'

interface IItemDetailsState {
  loading: boolean
  item: (IItem & IXtItemNumberOptionBase) | null
}

export const ItemDetails: FC<IXtMode> = ({ mode }) => {
  const { useRemarks } = useCommentsModule()
  const { useCharacteristics } = useCharacteristicsModule()
  const { XtItemNumber, ItemsUtilsService, ItemsService } = useProductsModule()
  const { ErrorHandler, ToastService } = useCoreModule()
  const { itemNumber } = useParams<{ itemNumber: string }>()
  useDocumentTitle(getItemDetailsPageTitle(itemNumber))
  const { isNewMode, isViewMode } = convertMode(mode)
  const history = useHistory()
  const [{ item, loading }, setState] = useState<IItemDetailsState>({ loading: false, item: null })
  const { value: taxTypes, isDirty: isTaxTypesDirty, reset: resetTaxTypes, setValue: setTaxTypes } = useFormControlState<ITaxType[]>([])

  const formMethods = useForm<ItemDetailsForm>({
    defaultValues: defineFormData(null),
    resolver: yupResolver(itemsDetailsValidation),
    mode: 'onBlur',
  })

  const {
    formState: { isDirty: isFormDirty, isSubmitting },
    reset,
    control,
    handleSubmit,
    watch,
    register,
    getValues,
  } = formMethods

  // We don't have any control in Edit mode that registers item number
  register(ItemDetailsFormField.ItemNumber)

  const remarksState = useRemarks(DocumentType.Item, itemNumber)
  const characteristicsState = useCharacteristics([])
  const documentsState = useDocuments(DocumentType.Item, itemNumber)
  const [itemNotFound, setItemNotFound] = useState(false)

  const isDirty = isFormDirty || remarksState.isDirty || characteristicsState.isDirty || documentsState.isDirty || isTaxTypesDirty
  const formItemNumber = watch(ItemDetailsFormField.ItemNumber)

  async function init(): Promise<void> {
    if (!itemNumber) {
      return
    }
    try {
      setState((prevState) => ({ ...prevState, loading: true }))
      const itemData = await ItemsService.get(itemNumber)
      reset(defineFormData(itemData))
      characteristicsState.reset(itemData.item_characteristics ?? [])
      remarksState.reset(itemNumber, itemData.notes, itemData.ext_description)
      setState({
        item: defineItemNumberOption(itemData),
        loading: false,
      })
      resetTaxTypes(itemData.tax_types ?? [])
      setItemNotFound(false)
    } catch (error) {
      setItemNotFound(true)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void init(), [])

  useEffect(() => {
    if (itemNotFound) throw Error(`Item not found.`)
  }, [itemNotFound])

  const onTaxTypeChange = useCallback((types: ITaxType[]) => setTaxTypes(types), [setTaxTypes])

  const onCancel: VoidFunction = () => {
    // eslint-disable-next-line no-restricted-globals
    history.push(`${productsRoutePath}/${productsRoutes.items}`)
  }

  const resetAll: VoidFunction = () => {
    reset(isNewMode ? undefined : getValues())

    const { reset: resetRemarks, comments, notes, additionalNotes } = remarksState
    resetRemarks(comments, notes, additionalNotes)

    const { characteristics, reset: resetCharecteristics } = characteristicsState
    resetCharecteristics(characteristics)

    const { reset: resetDocuments, getUnsavedDocuments } = documentsState
    resetDocuments(getUnsavedDocuments())

    resetTaxTypes(item?.tax_types ?? [])
  }

  const saveItem: (payload: CreateItemPayload) => Promise<string> = async (payload: CreateItemPayload) => {
    try {
      const message = await ItemsService.create(payload, remarksState.comments, documentsState.getUnsavedDocuments())
      return message
    } catch (e) {
      if (e instanceof AdditionalDataCreationError) {
        ErrorHandler.handleError(e)
      } else {
        throw e
      }
      return 'error'
    }
  }

  const updateItem: (payload: CreateItemPayload) => Promise<string> = async (payload: CreateItemPayload) => {
    try {
      const message = await ItemsService.update(payload)
      return message
    } catch (e) {
      if (e instanceof AdditionalDataCreationError) {
        ErrorHandler.handleError(e)
      } else {
        throw e
      }
      return 'error'
    }
  }

  const onSaveItem: (formData: ItemDetailsForm) => Promise<void> = async (formData) => {
    try {
      const payload = convertItemData(
        formData,
        characteristicsState.characteristics,
        remarksState.notes,
        remarksState.additionalNotes,
        taxTypes,
        item?.currency ?? ''
      )
      if (isNewMode) {
        const message = await saveItem(payload)
        ToastService.showSuccess(message)
      } else {
        const message = await updateItem(payload)
        ToastService.showSuccess(message)
      }
      resetAll()
      onCancel()
    } catch (e) {
      ErrorHandler.handleError(e)
    }
  }

  const onSubmit: (e: React.BaseSyntheticEvent) => Promise<void> = async (e) => {
    e.stopPropagation() // To prevent submitting parent forms
    const eventHandler = handleSubmit(onSaveItem)
    void eventHandler(e)
  }

  return (
    <div className="xt-content xt-content-with-remarks">
      {loading && <LoadingSpinner />}
      <h1 className={cls(styles.itemHeader, 'xt-page-title')}>{isNewMode ? 'New Item' : 'Item Details'}</h1>
      <form hidden={loading} onSubmit={onSubmit} className={styles.itemFormContent}>
        <div className={cls(styles.itemFormHeader, 'xt-sticky-header', 'xt-section-border')}>
          <div className={styles.itemNumberContainer}>
            {isNewMode ? (
              <FormField required name={ItemDetailsFormField.ItemNumber} control={control} label="Item #" />
            ) : (
              <XtItemNumber value={item} loadOptions={ItemsUtilsService.loadItemOptions} />
            )}
          </div>
          <div className={styles.controlButtonsContainer}>
            <XtButton label="Cancel" onClick={onCancel} />
            <XtButton label="Save" hidden={isViewMode} type="submit" disabled={!isDirty || isSubmitting} />
          </div>
        </div>
        <div className={styles.itemFormHeaderControls}>
          <FormCheckboxLabel disabled={isViewMode} control={control} label="Active" name={ItemDetailsFormField.Active} />
          <div className={styles.descriptionBlock}>
            <FormField
              name={ItemDetailsFormField.Description1}
              control={control}
              label={ItemSoldFormLabel.Description1}
              disabled={isViewMode}
            />
            <FormField
              name={ItemDetailsFormField.Description2}
              control={control}
              label={ItemSoldFormLabel.Description2}
              disabled={isViewMode}
            />
          </div>
        </div>
        <div className={styles.itemDetailsContent}>
          <ItemDetailsTabs
            disabled={isViewMode}
            remarks={remarksState}
            formMethods={formMethods}
            characteristics={characteristicsState}
            documents={documentsState}
            mode={mode}
            item={item}
            itemNumber={itemNumber ?? formItemNumber}
            onTaxTypeChange={onTaxTypeChange}
            taxTypes={taxTypes}
          />
        </div>
      </form>
      <XtPrompt showPrompt={isDirty} message={confirmationMessages.unsavedChanges} />
    </div>
  )
}
