import { ItemCostsByClassCodeFilter, ItemCostsByClassCodeFilters } from 'products/items/items.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'

export enum ClassCodeFilter {
  ItemTypes = 'item_types',
}

export type ItemTypesField =
  | ItemCostsByClassCodeFilter.ShowPurchased
  | ItemCostsByClassCodeFilter.ShowMfg
  | ItemCostsByClassCodeFilter.ShowOS
  | ItemCostsByClassCodeFilter.ShowCosting
  | ItemCostsByClassCodeFilter.ShowPlanning
  | ItemCostsByClassCodeFilter.ShowOther

export type ClassCodeFilters = Pick<
  ItemCostsByClassCodeFilters,
  | ItemCostsByClassCodeFilter.ClassCode
  | ItemCostsByClassCodeFilter.ClassCodePattern
  | ItemCostsByClassCodeFilter.OnlyShowZeroStdCosts
  | ItemCostsByClassCodeFilter.OnlyShowDiffCosts
  | ItemCostsByClassCodeFilter.ShowInactive
  | ItemCostsByClassCodeFilter.OnlyShowZeroLastCosts
  | ItemCostsByClassCodeFilter.ShowPurchased
> & {
  [ClassCodeFilter.ItemTypes]: IXtAutocompleteOption[] | null
}
