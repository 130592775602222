import { IAsyncRoute } from '../common/routing/routing.types'
import { pageNotFoundRoutePath } from './page-not-found.constants'

import { PageNotFoundModule, PageNotFoundModuleDefinition } from './page-not-found.module'

export const pageNotFoundRoute: IAsyncRoute<PageNotFoundModuleDefinition> = {
  module: PageNotFoundModule,
  path: pageNotFoundRoutePath,
  exact: true,
  // name: 'PageNotFound',
  childrenRoutesFactory: ({ PageNotFound }: PageNotFoundModuleDefinition) => [
    {
      path: '',
      exact: true,
      component: PageNotFound,
    },
  ],
}
