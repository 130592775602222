import { ILazyModule } from 'core/react-lazy-modules/react-lazy-modules.types'
import { Injector } from 'core/injector/injector.service'
import { Scope } from 'core/injector/injector.types'
import { httpClientToken, IHttpClient } from 'core/services/http-client'
import { AuthModule } from 'auth/auth.module'
import { CoreModule } from 'core/core.module'
import { IDocumentsService } from 'documents/documents.types'
import { documentsServiceToken } from 'documents/documents.constants'
import { vendorServiceToken } from 'vendor/vendor.constants'
import { IVendorService } from 'vendor/vendor.service'
import { DocumentsModule } from 'documents/documents.module'
import { ProductsModule } from 'products/products.module'
import { itemsServiceToken } from 'products/products.constants'
import { IItemsService } from 'products/items/items.service'
import { VendorServicesModule } from 'vendor/vendor-services.module'
import { IItemSourceUtilsService } from './item-source-utils.service'
import { itemSourceServiceToken, itemSourceUtilsServiceToken, itemSourcesUtilsModule } from './item-source.constants'
import { IItemSourceService } from './item-source.service'

export type ItemSourceUtilsModuleDefinition = typeof import('./item-source-utils.exports')

export const ItemSourceUtilsModule: ILazyModule<ItemSourceUtilsModuleDefinition> = {
  name: itemSourcesUtilsModule,
  resolver: () => import('./item-source-utils.exports'),
  initializer: ({ ItemSourceService, ItemSourceUtilsService }) => {
    Injector.register<IItemSourceService>(
      itemSourceServiceToken,
      (injector) => {
        const httpClient = injector.resolve<IHttpClient>(httpClientToken)
        const documentsService = injector.resolve<IDocumentsService>(documentsServiceToken)
        return new ItemSourceService(httpClient, documentsService)
      },
      Scope.Singleton
    )

    Injector.register<IItemSourceUtilsService>(
      itemSourceUtilsServiceToken,
      (injector) => {
        const itemSourceService = injector.resolve<IItemSourceService>(itemSourceServiceToken)
        const itemService = injector.resolve<IItemsService>(itemsServiceToken)
        const vendorService = injector.resolve<IVendorService>(vendorServiceToken)

        return new ItemSourceUtilsService(itemSourceService, vendorService, itemService)
      },
      Scope.Singleton
    )
  },
  dependencies: [CoreModule, AuthModule, ProductsModule, DocumentsModule, VendorServicesModule],
}
