import type { IAsyncRoute, IRoute } from 'common/routing/routing.types'
import type { PurchasingModuleDefinition } from './purchasing.module'
import { purchaseOrdersRouteFactory } from 'purchase-orders/purchase-orders.routes'
import { PurchaseOrdersGuard } from 'purchase-orders/purchase-orders.guard'
import { purchaseRequestsRouteFactory } from 'purchase-requests/purchase-requests.routes'
import { PurchaseRequestsGuard } from 'purchase-requests/purchase-requests.guard'
import { itemSourceRouteFactory } from 'item-source/item-source.routes'
import { ItemSourcesGuard } from 'item-source/item-source.guard'
import { PageNotFound } from 'page-not-found/page-not-found'
import { purchasingRoutePath, purchasingRoutes } from './purchasing.constants'
import { PurchasingModule } from './purchasing.module'
import { PurchasingGuard } from './purchasing.guard'

export function purchasingRouteFactory(moduleDefinition: PurchasingModuleDefinition): IRoute[] {
  const purchasingRoutesList: IRoute[] = [
    {
      path: purchasingRoutes.purchaseOrders,
      name: 'Purchase Orders',
      children: purchaseOrdersRouteFactory(moduleDefinition),
      guards: [PurchaseOrdersGuard],
    },
    {
      path: purchasingRoutes.purchaseRequests,
      name: 'Purchase Requests',
      children: purchaseRequestsRouteFactory(moduleDefinition),
      guards: [PurchaseRequestsGuard],
    },
    {
      path: purchasingRoutes.itemSources,
      name: 'Item Sources',
      children: itemSourceRouteFactory(moduleDefinition),
      guards: [ItemSourcesGuard],
    },
    {
      path: '*',
      component: PageNotFound,
      exact: true,
    },
  ]
  return purchasingRoutesList
}

export const purchasingRoute: IAsyncRoute<PurchasingModuleDefinition> = {
  path: purchasingRoutePath,
  name: 'Purchasing',
  module: PurchasingModule,
  guards: [PurchasingGuard],
  childrenRoutesFactory: purchasingRouteFactory,
}
