import { IXtTableColumn } from 'components/table/table-head/table-head.types'
import { booleanColumnConverter } from 'common/utils/table.utils'
import { IXtTableCellAction } from 'components/table/table-cell-actions/table-cell-actions'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { TransferListOption } from 'components/controls/xt-transfer-list/xt-transfer-list.types'
import { ConversionsAction, IConversionsTableItem } from './conversions-tab.types'

export const availableTypes: TransferListOption[] = [
  {
    id: 'AltCapacity',
    label: 'AltCapacity',
  },
  { id: 'Capacity', label: 'Capacity' },
  { id: 'MaterialIssue', label: 'MaterialIssue' },
  { id: 'Selling', label: 'Selling' },
]

export const ConversionsTabActions: IXtTableCellAction[] = [
  {
    name: ConversionsAction.Edit,
    label: 'Edit',
    icon: SvgIconIds.CREATE,
  },
  {
    name: ConversionsAction.Delete,
    label: 'Delete',
    icon: SvgIconIds.DELETE,
  },
]

export const ConversionsTabColumns: IXtTableColumn<IConversionsTableItem>[] = [
  {
    id: 'conversions_where_used',
    field: 'conversions_where_used',
    headerName: 'Conversions/Where Used',
    flex: '1 0 300px',
    width: 300,
  },
  {
    id: 'uom_types_table',
    field: 'uom_types_table',
    headerName: 'Types',
    flex: '1 0 130px',
    width: 130,
  },
  {
    id: 'ratio',
    field: 'ratio',
    headerName: 'Ratio',
    flex: '1 0 160px',
    width: 160,
  },
  {
    id: 'global',
    field: 'global',
    headerName: 'Global',
    flex: '1 0 90px',
    width: 90,
    converter: booleanColumnConverter,
  },
  {
    id: 'fractional',
    field: 'fractional',
    headerName: 'Fractional',
    flex: '1 0 120px',
    width: 120,
    converter: booleanColumnConverter,
  },
  {
    id: 'active',
    field: 'active',
    headerName: 'Active',
    flex: '1 0 100px',
    width: 100,
    converter: booleanColumnConverter,
  },
]
