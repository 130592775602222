import { IPaginationData, IPaginationParams, IPaginationResponse } from 'common/common.types'
import { prepareRequestParams } from 'common/utils/request.utils'
import { ISortOption } from 'components/table/table-head/table-head.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { IHttpClient } from './http-client'

export enum InventoryAvailabilityFilterField {
  ItemNumber = 'item_number',
  Site = 'site',
  ByDays = 'byDays',
  ByDate = 'byDate',
  StartDate = 'startDate',
  EndDate = 'endDate',
  Vendor = 'vendor',
  ClassCode = 'classcode',
  Planner = 'planner',
  ItemGroup = 'item_group',
  VendorType = 'vendor_type',
  AbcClass = 'abc_class',
  ShowReorder = 'showReorder',
  IgnoreReorderAtZero = 'ignoreReorderAtZero',
  ShowShortages = 'showShortages',
  ShowDetail = 'showDetail',
}

export interface IInventoryAvailabilityOrder {
  order_type: string
  order_number: string
  order_date: string
  order_qty: number
  order_url: string
}

export interface IInventoryAvailabilityDetail {
  qoh: number
  site: string
  usable: boolean
  netable: boolean
  warranty: string
  lotserial: string
  expiration: string
  location_name: string
}

export interface IInventoryAvailability {
  site: string
  item_number: string
  item_description1: string
  item_description2: string
  uom: string
  abc_class: string
  lead_time: number
  quantity_on_hand: number
  quantity_allocated: number
  quantity_unallocated: number
  allocated_orders: IInventoryAvailabilityOrder[]
  on_order: number
  onorder_orders: IInventoryAvailabilityOrder[]
  requests: number
  request_orders: IInventoryAvailabilityOrder[]
  reorder_level: number
  out_level: number
  available: number
  reserved: number
  vendor: string
  details: IInventoryAvailabilityDetail[]
}

export interface IInventoryAvailabilityFilters {
  [InventoryAvailabilityFilterField.ItemNumber]?: string
  [InventoryAvailabilityFilterField.Site]?: string
  [InventoryAvailabilityFilterField.ByDays]?: number
  [InventoryAvailabilityFilterField.ByDate]?: string
  [InventoryAvailabilityFilterField.StartDate]?: string
  [InventoryAvailabilityFilterField.EndDate]?: string
  [InventoryAvailabilityFilterField.Vendor]?: string
  [InventoryAvailabilityFilterField.ClassCode]?: string
  [InventoryAvailabilityFilterField.Planner]?: string
  [InventoryAvailabilityFilterField.ItemGroup]?: string
  [InventoryAvailabilityFilterField.VendorType]?: string
  [InventoryAvailabilityFilterField.AbcClass]?: string
  [InventoryAvailabilityFilterField.ShowReorder]?: boolean
  [InventoryAvailabilityFilterField.IgnoreReorderAtZero]?: boolean
  [InventoryAvailabilityFilterField.ShowShortages]?: boolean
  [InventoryAvailabilityFilterField.ShowDetail]?: boolean
}

export type InventoryAvailabilityOption = IInventoryAvailability & IXtAutocompleteOption

export interface IInventoryAvailabilityService {
  getAll(pagination?: IPaginationParams, filters?: IInventoryAvailabilityFilters): Promise<IPaginationData<IInventoryAvailability>>
}

export class InventoryAvailabilityService implements IInventoryAvailabilityService {
  constructor(private readonly httpClient: IHttpClient) {}

  public async getAll(
    pagination?: IPaginationParams,
    filters?: IInventoryAvailabilityFilters,
    sortOptions?: ISortOption[]
  ): Promise<IPaginationData<IInventoryAvailability>> {
    if (!filters?.site) {
      return {
        data: [],
        total: 0,
      }
    }

    const params = prepareRequestParams(pagination, filters, sortOptions)
    const {
      data: { data, status },
    } = await this.httpClient.get<IPaginationResponse<IInventoryAvailability>>('/inventoryavailability', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}
