import * as React from 'react'
import { useState, ReactElement, PropsWithChildren, useEffect } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { XtResponsiveButton } from 'components/buttons/xt-responsive-button/xt-responsive-button'
import { XtSelect } from 'components/controls/xt-select/xt-select'
import { XtInfoValues } from 'components/xt-info-values/xt-info-values'
import { IFilterFormState } from 'components/pagefilter/filter/filter.types'
import { useFilter } from 'common/hooks/filter'
import { XtFilter } from 'components/pagefilter/filter/filter'
import { parseFilterValues } from 'core/services/pagefilters/pagefilters.utils'
import { SvgIcon } from 'components/svg-icon/svg-icon'
import { clearFilters } from 'common/constants'
import { isObjectEmpty } from 'common/utils/utils'
import { FilterOption, IXtPageFilter, IXtPageFilterContext } from './pagefilter.types'
import * as styles from './pagefilter.module.scss'
import { createPageFilterContext, usePageFilterContext } from './pagefilter.utils'
import { filtersPresetInfoValue } from './filter/filter-dialog-wrapper'
import { equalFilterValues } from './filter/filter.utils'

function PageFilterComponentLayout<TFiltersState extends IFilterFormState>({
  children,
  onFilterClick,
  onClearAllFiltersClick,
}: PropsWithChildren<{ onFilterClick?: () => void; onClearAllFiltersClick?: () => void }>): ReactElement {
  const {
    state: { pageFilters, handleLastUsedFilter },
    preset,
    filtersState,
    setPreset,
    filter,
    onClick,
    defaultFilterValues,
    tableFilters,
  } = usePageFilterContext<TFiltersState>()

  const onPresetChange = (option: FilterOption): void => {
    setPreset(option)
    const params = parseFilterValues<TFiltersState>(option.value)
    if (params) {
      void filter(params)
      void handleLastUsedFilter(params)
    }
  }

  const onFilterButtonClick = (): void => {
    if (onClick) {
      onClick()
    }
    if (onFilterClick) {
      onFilterClick()
    }
  }

  const clearAllFilters = async (): Promise<void> => {
    await filtersState.handleLastUsedFilter({} as TFiltersState)
    if (onClearAllFiltersClick) {
      onClearAllFiltersClick()
    }
  }

  const activeFiltersExist = !equalFilterValues(defaultFilterValues, tableFilters)

  return (
    <div className={styles.filterContent}>
      {children}
      <span className={styles.filterSection}>
        {activeFiltersExist ? <div className={styles.notificationDot}></div> : ''}
        <XtResponsiveButton showIconOnDesktopMode={false} icon={SvgIconIds.FILTER} label="Filters" onClick={onFilterButtonClick} />
        {activeFiltersExist ? (
          <Tooltip title={clearFilters}>
            <IconButton className={styles.closeIcon} onClick={clearAllFilters}>
              <SvgIcon className={styles.closeIconSize} iconId={SvgIconIds.CLOSE_ICON} />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </span>
      <div className={styles.presetContent}>
        <XtInfoValues values={filtersPresetInfoValue} />
        <XtSelect clearable options={pageFilters} label="" onChange={onPresetChange} value={preset} disabled={!pageFilters.length} />
      </div>
    </div>
  )
}

function PageFilterComponentWithCustom<TFiltersState extends IFilterFormState>(): ReactElement {
  const context = usePageFilterContext<TFiltersState>()
  const {
    resolveFilters,
    filter,
    filtersState: { lastUsedFilter },
    defaultFilterValues,
  } = context

  const { CustomFilterDialog } = usePageFilterContext<TFiltersState>()

  const { onFiltersSubmit } = useFilter(resolveFilters ? resolveFilters : () => [], filter)

  const onClearAllFiltersClick = () => {
    onFiltersSubmit({} as TFiltersState)
  }

  useEffect(() => {
    onFiltersSubmit(lastUsedFilter && !isObjectEmpty(lastUsedFilter) ? lastUsedFilter : defaultFilterValues)
  }, [lastUsedFilter])

  return (
    <PageFilterComponentLayout onClearAllFiltersClick={onClearAllFiltersClick}>
      {CustomFilterDialog && CustomFilterDialog()}
    </PageFilterComponentLayout>
  )
}

function PageFilterComponent<TFiltersState extends IFilterFormState>(): ReactElement {
  const context = usePageFilterContext<TFiltersState>()
  const { filter, resolveFilters } = context

  const { openFilters, closeFilters, filtersAvailable, onFiltersSubmit, filterOpen, filters } = useFilter(
    resolveFilters ? resolveFilters : () => [],
    filter
  )
  const onFilterClick = (): void => {
    openFilters()
  }

  const onClearAllFiltersClick = () => {
    onFiltersSubmit({} as TFiltersState)
  }

  return (
    <PageFilterComponentLayout onFilterClick={onFilterClick} onClearAllFiltersClick={onClearAllFiltersClick}>
      {filtersAvailable && <XtFilter open={filterOpen} onClose={closeFilters} onSubmit={onFiltersSubmit} filters={filters} />}
    </PageFilterComponentLayout>
  )
}

export function XtPageFilter<TFiltersState extends IFilterFormState>(context: IXtPageFilter<TFiltersState>): React.ReactElement {
  const PageFilterContext = createPageFilterContext<IXtPageFilterContext<TFiltersState>>()
  const [preset, setPreset] = useState<FilterOption | null>(null)

  const { CustomFilterDialog } = context

  const activeFiltersCount = context?.state?.lastUsedFilter ? Object.keys(context?.state?.lastUsedFilter).length : 0
  const filtersState = context?.state
  return (
    <PageFilterContext.Provider value={{ ...context, preset, setPreset, activeFiltersCount, filtersState }}>
      {CustomFilterDialog ? <PageFilterComponentWithCustom /> : <PageFilterComponent />}
    </PageFilterContext.Provider>
  )
}
