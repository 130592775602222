import { IPaginationData, IPaginationParams, IPaginationResponse } from '../../common/common.types'
import { IHttpClient } from './http-client'

export interface IGlAccount {
  glaccount: string
  description: string
  type: string
  active: boolean
  company: string
  profit_center: string
  account_number: string
  sub_account: string
  ext_reference: string
  sub_type: string
  terms_created: string
  forward_update_trial_balances: boolean
  notes: string
  created: string
  lastupdated: string
}

export interface IGlAccountsService {
  getAll(pagination: IPaginationParams): Promise<IPaginationData<IGlAccount>>
}

export class GlAccountsService implements IGlAccountsService {
  constructor(private readonly httpClient: IHttpClient) {}

  public async getAll({ page, limit }: IPaginationParams): Promise<IPaginationData<IGlAccount>> {
    const params = { page: page === 0 ? 1 : page, limit }
    const {
      data: { data, status },
    } = await this.httpClient.get<IPaginationResponse<IGlAccount>>('/glaccount', { params })

    return {
      data: Array.isArray(data) ? data : [],
      total: status.totalrowcount,
    }
  }
}
