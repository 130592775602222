// extracted by mini-css-extract-plugin
export var popoverContainer = "bom-item-quick-add_popoverContainer__Y0pej";
export var closeForm = "bom-item-quick-add_closeForm__LEx9f";
export var formContainer = "bom-item-quick-add_formContainer__WxkxO";
export var formTitle = "bom-item-quick-add_formTitle__iTPyi";
export var formField = "bom-item-quick-add_formField__+k-oE";
export var addButton = "bom-item-quick-add_addButton__tQivX";
export var addButtonLabel = "bom-item-quick-add_addButtonLabel__o2oio";
export var footer = "bom-item-quick-add_footer__wTiPD";
export var advancedSearchLink = "bom-item-quick-add_advancedSearchLink__kuBeA";
export var closeIcon = "bom-item-quick-add_closeIcon__pDL3p";