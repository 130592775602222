import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { CustomerMainFormData, CustomerMainFormField, ICustomerMainForm } from './customer-details-form.types'
import { ICustomer } from '../../customers.types'

export function defineCustomerFormData(data: ICustomer | null, customerName: string | null): ICustomerMainForm {
  return {
    [CustomerMainFormField.Number]: data?.customer_number ?? '',
    [CustomerMainFormField.Name]: customerName ?? data?.customer_name ?? '',
    [CustomerMainFormField.Type]: defineAutocompleteOption(data?.customer_type),
    [CustomerMainFormField.Site]: defineAutocompleteOption(data?.preferred_selling_site),
    [CustomerMainFormField.Active]: data?.active ?? true,
  }
}

export function convertCustomerMainFormState(data: ICustomerMainForm): CustomerMainFormData {
  return { ...data, customer_type: data.customer_type?.id ?? null, preferred_selling_site: data.preferred_selling_site?.id ?? null }
}
