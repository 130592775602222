import { Injector } from 'core/injector/injector.service'
import { moduleHookResolver } from 'core/react-lazy-modules/react-lazy-modules.utils'
import { customersServiceToken, customersUtilsServiceToken } from '../customers.constants'
import { ICustomersService, ICustomersServices, ICustomersUtilsService } from './customers-services.types'

export function resolveCustomersServicesModule(): ICustomersServices {
  const CustomersService = Injector.resolve<ICustomersService>(customersServiceToken)
  const CustomersUtilsService = Injector.resolve<ICustomersUtilsService>(customersUtilsServiceToken)

  return { CustomersService, CustomersUtilsService }
}

export const useCustomersServicesModule = moduleHookResolver<ICustomersServices>(resolveCustomersServicesModule)
