import React, { FC, memo, useCallback, useState } from 'react'
import { nanoid } from 'nanoid'
import Tooltip from '@material-ui/core/Tooltip'
import { useMediaQuery } from '@material-ui/core'
import { useAuthModule } from 'auth/auth-module-hook'
import { UserPermission } from 'auth/auth.types'
import { XtList } from 'components/list/list'
import { XtMode } from 'common/common.types'
import { XtDialog, XtDialogAnimation } from 'components/xt-dialog/xt-dialog'
import { XtConfirmationDialog } from 'components/xt-confirmation-dialog/xt-confirmation-dialog'
import { confirmationMessages, xsMq } from 'common/constants'
import { IXtEntityDialogData } from 'core/core.types'
import { useConfirmationDialog } from 'common/hooks/confirmation-dialog'
import { useCoreModule } from 'core/core-module-hook'
import { useSharedModule } from 'shared/shared-module-hook'
import { SvgIconIds } from 'components/svg-icon/svg-icon.types'
import { useProductsModule } from 'products/products-module-hook'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { createItemsActions, createItemsColumns, createNewItem, deleteItem } from './create-items.constants'
import { ICreateItemsTableItem, CreateItemFormAction, ICreateItems } from './create-items.types'
import { CreateItemDialog } from './create-item-dialog/create-item-dialog'
import { ICreateItemFormState } from './create-item-dialog/create-item-dialog.types'
import { convertItemType } from './create-items.utils'
import * as styles from './create-items.module.scss'

export const CreateItems: FC<ICreateItems> = memo(({ onChange }) => {
  const [productItems, setProductItems] = useState<ICreateItemsTableItem[]>([])

  const { useDialog } = useSharedModule()

  const { ItemsService } = useProductsModule()
  const { PermissionsService } = useAuthModule()

  const { open, openDialog, closeDialog, itemId } = useConfirmationDialog<string>()
  const { ErrorHandler, ToastService } = useCoreModule()

  const isMobile = useMediaQuery(xsMq)
  const canMaintainItems = PermissionsService.hasPermission(UserPermission.MaintainItemMasters)

  const updateItems = useCallback(
    (data: ICreateItemsTableItem[]): void => {
      setProductItems(data)
      onChange({ items: data }, true)
    },
    [onChange, setProductItems]
  )

  const { open: itemDialogOpen, openDialog: openItemDialog, closeDialog: closeItemDialog, data: itemDialogData } = useDialog<
    IXtEntityDialogData<ICreateItemsTableItem | null>
  >({
    mode: XtMode.New,
    data: null,
  })

  const handleAction = useCallback<(item: ICreateItemsTableItem, action: CreateItemFormAction) => void>(
    (item, action) => {
      switch (action) {
        case CreateItemFormAction.Delete: {
          openDialog(item.id)
          break
        }
        case CreateItemFormAction.Edit: {
          openItemDialog({
            mode: XtMode.Edit,
            data: item,
          })
          break
        }
      }
    },
    [openDialog, openItemDialog]
  )

  const onConfirmCreateItemDialog = useCallback(
    async (formData: ICreateItemFormState) => {
      try {
        if (itemDialogData.mode == XtMode.Edit) {
          updateItems(
            productItems.map((item) => ({
              ...item,
              item_number: item.id === formData.id ? formData.item_number : item.item_number,
              description1: item.id === formData.id ? formData.description1 : item.description1,
              item_is_sold: item.id === formData.id ? formData.item_is_sold : item.item_is_sold,
              list_price: item.id === formData.id ? formData.list_price : item.list_price,
              item_type: item.id === formData.id ? convertItemType(formData.item_type) : item.item_type,
            }))
          )
          closeItemDialog()
          return
        } else if (itemDialogData.mode === XtMode.New) {
          let itemData = {}
          try {
            itemData = await ItemsService.get(formData.item_number)
          } catch (e) {}
          const itemExists = productItems.some((item) => item.item_number === formData.item_number) || Object.keys(itemData).length > 0
          if (itemExists) {
            ToastService.showError(`Item number ${formData.item_number} already exists.`)
            return
          }
        }
        updateItems([
          ...productItems,
          {
            ...formData,
            item_type: typeof formData.item_type == 'object' ? formData.item_type?.id ?? '' : formData.item_type,
            id: nanoid(),
          },
        ])
        closeItemDialog()
      } catch (e) {
        ErrorHandler.handleError(e as Error)
      }
    },
    [closeItemDialog, productItems, itemDialogData, updateItems, ToastService, ItemsService, ErrorHandler]
  )

  const handleRowClick = useCallback<(item: ICreateItemsTableItem) => void>(
    (item) => {
      openItemDialog({
        mode: XtMode.Edit,
        data: item,
      })
    },
    [openItemDialog]
  )

  const handleDelete = useCallback<() => Promise<void>>(async () => {
    closeDialog()
    if (itemId) {
      try {
        const itemsList: ICreateItemsTableItem[] = productItems.filter((item) => item.id !== itemId)
        setProductItems(itemsList)
      } catch (error) {
        ErrorHandler.handleError(error)
      }
    }
  }, [ErrorHandler, closeDialog, itemId, productItems, setProductItems])

  return (
    <div className={styles.createItems}>
      <XtConfirmationDialog
        open={open}
        message={confirmationMessages.deleted}
        title={deleteItem}
        confirmationButtonLabel="Delete"
        onConfirm={handleDelete}
        onClose={closeDialog}
      />
      {!canMaintainItems && (
        <Tooltip title="You do not have permission to do this">
          <div className={styles.buttonContainer}>
            <XtButton
              onClick={() =>
                openItemDialog({
                  mode: XtMode.New,
                  data: null,
                })
              }
              icon={SvgIconIds.ADD_CIRCLE}
              label={createNewItem}
              disabled
            />
          </div>
        </Tooltip>
      )}

      {canMaintainItems && (
        <div className={styles.itemHeaderSection}>
          <p>
            Let&#39;s get started! Please click &#39;Add Item&#39; to add basic information about the items you sell. If you need help,
            please feel free to email <a href="mailto:xthelp@caisoft.com">xthelp@caisoft.com</a>. When you&#39;ve finished adding Items,
            please click &#34;Next&#34; below.
          </p>
          <div className={styles.buttonContainer}>
            <XtButton
              onClick={() =>
                openItemDialog({
                  mode: XtMode.New,
                  data: null,
                })
              }
              icon={SvgIconIds.ADD_CIRCLE}
              label={createNewItem}
            />
          </div>
        </div>
      )}
      <XtList
        actions={createItemsActions}
        data={productItems}
        onAction={handleAction}
        columns={createItemsColumns}
        onRowClick={handleRowClick}
        isMobile={isMobile}
      />
      <XtDialog className="xt-dialog-details-content" fullScreen={false} open={itemDialogOpen} animation={XtDialogAnimation.FadeAnimation}>
        <CreateItemDialog
          onClose={closeItemDialog}
          onConfirm={onConfirmCreateItemDialog}
          data={itemDialogData.data}
          mode={itemDialogData.mode}
        />
      </XtDialog>
    </div>
  )
})
