import { AdditionalDataCreationError } from 'common/common.types'
import { ICreateItemsState } from './basic-configuration-steps/create-items/create-items.types'
import { ICreateAccountsState } from './basic-configuration-steps/create-accounts/create-accounts.types'

export interface ISetupModule {
  id: number
  name: string
  param: keyof ISetupWizardModuleState
  steps: ISetupModuleStep[]
}

export interface ISetupComponent {
  onChange(formData: ICreateItemsState | ICreateAccountsState, isDirty: boolean): void
  onSubmit(): void
  state: ISetupWizardModuleState
}

export interface ISetupModuleStep {
  id: number
  name: string
  label: string
  component: React.FC<ISetupComponent>
  param: string
}

export interface ISetupActiveModule {
  id: number
  state: ISetupModule
}

export interface ISetupActiveStep {
  id: number
  state: ISetupModuleStep
}

export interface ISetupWizardDialogState {
  activeModule: ISetupModule
  activeStep: ISetupActiveStep
  isDirty: boolean
  isLastStep: boolean
}

export interface ISetupWizardRequestParams {
  activeModule: keyof ISetupWizardModuleState
  activeStep: string
}

export interface ISetupWizardModuleState {
  basic: ISetupWizardBasicConfig
}

export interface ISetupWizardBasicConfig {
  welcomeMsg: null
  createItems: ICreateItemsState
  createAccounts: ICreateAccountsState
}

export class ItemCreationError extends AdditionalDataCreationError {
  public message = 'Item saving failed. Please try again later.'
}

export class AccountCreationError extends AdditionalDataCreationError {
  public message = 'Account saving failed. Please try again later.'
}
