import { IAsyncRoute } from '../common/routing/routing.types'
import { homeRoutePath } from './home.constants'
import { HomeModule, HomeModuleDefinition } from './home.module'

export const homeRoute: IAsyncRoute<HomeModuleDefinition> = {
  module: HomeModule,
  path: homeRoutePath,
  exact: true,
  name: 'Home',
  childrenRoutesFactory: ({ Home }: HomeModuleDefinition) => [
    {
      path: '',
      exact: true,
      component: Home,
    },
  ],
}
