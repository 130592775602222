import { IAttachedDocument } from 'documents/documents.types'
import { extractFileNameFromPath } from 'common/utils/file.utils'
import { DocumentDialogFormField, IDocumentDialogFormState } from './document-dialog.types'

export function retrieveFileFromDocument(document: IAttachedDocument | null): File | null {
  if (document?.file) {
    return document.file
  }
  return document?.file_link ? ({ name: extractFileNameFromPath(document.file_link) } as File) : null
}

export function setDefaultDocumentFormValues(document: IAttachedDocument | null): IDocumentDialogFormState {
  return {
    [DocumentDialogFormField.Name]: document?.name ?? '',
    [DocumentDialogFormField.Notes]: document?.notes ?? '',
    [DocumentDialogFormField.File]: retrieveFileFromDocument(document),
  }
}
