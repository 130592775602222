import { IPaginationData } from 'common/common.types'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { defineAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.utils'
import { StandardOperationNone } from 'products/routing/routing-item/routing-item.constants'
import { IGlAccountsService } from './gl-accounts.service'
import { IStandardOperationFilters, IStandardOperationService } from './standard-operation.service'
import { IWorkingCenterFilters, IWorkingCenterService } from './work-center.service'
import { IOperationTypesService } from './operation-types.service'
import { InventoryUomOption, IUomService } from './uom.service'
import { ICRMRolesService, RoleAppliesto } from './crm-role.service'
import { IExpenseCategoryService } from './expense-category.service'
import { ITransferOrderFilters, ITransferOrderService, TransferOrderOption } from './transfer-order.service'
import { IInvoiceService, InvoiceOption } from './invoice.service'
import { IWorkingOrderFilter, IWorkingOrderService } from './work-order.service'
import { IInventoryAvailabilityFilters, IInventoryAvailabilityService, InventoryAvailabilityOption } from './inventory-availability.service'

export interface ICoreUtilsService {
  loadGlAccountOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>>
  loadExpenseCategoryOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>>
  loadWorkCenterOptions(
    page: number,
    limit: number,
    searchFilter: string | null,
    filters?: IWorkingCenterFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>>
  loadStandardOperationOptions(
    page: number,
    limit: number,
    searchFilter: string | null,
    filters?: IStandardOperationFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>>
  loadCompaniesContactRoleOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>>
  loadOperationTypeOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>>
  loadCRMPhoneRoleOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>>
  loadUomOptions(page: number, limit: number): Promise<IPaginationData<InventoryUomOption>>
  loadTransferOrderOptions(
    page: number,
    limit: number,
    searchFilter: string | null,
    filters?: ITransferOrderFilters
  ): Promise<IPaginationData<TransferOrderOption>>
  loadInventoryAvailabilityOptions(
    page: number,
    limit: number,
    searchFilter: string | null,
    filters?: IInventoryAvailabilityFilters
  ): Promise<IPaginationData<InventoryAvailabilityOption>>
  loadInvoiceOptions(page: number, limit: number): Promise<IPaginationData<InvoiceOption>>
  loadWorkOrderOptions(
    page: number,
    limit: number,
    _filter: string | null,
    filters?: IWorkingOrderFilter
  ): Promise<IPaginationData<IXtAutocompleteOption>>
}

export class CoreUtilsService implements ICoreUtilsService {
  constructor(
    private readonly expenseCategoryService: IExpenseCategoryService,
    private readonly glAccountsService: IGlAccountsService,
    private readonly workCenterService: IWorkingCenterService,
    private readonly operationTypesService: IOperationTypesService,
    private readonly standardOperationService: IStandardOperationService,
    private readonly uomService: IUomService,
    private readonly crmRolesService: ICRMRolesService,
    private readonly transferOrderService: ITransferOrderService,
    private readonly inventoryAvailabilityService: IInventoryAvailabilityService,
    private readonly invoiceService: IInvoiceService,
    private readonly workOrderService: IWorkingOrderService
  ) {
    bindAllInstanceMethods(this)
  }

  public async loadGlAccountOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { data, total } = await this.glAccountsService.getAll({ page, limit })
    return {
      data: data.map(({ glaccount, description, type }) => ({
        id: glaccount,
        label: `${glaccount}-${description}-${type}`,
      })),
      total,
    }
  }

  public async loadExpenseCategoryOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { data, total } = await this.expenseCategoryService.getAll({ page, limit })
    return {
      data: data.map(({ code, description }) => ({ id: code, label: `${code}-${description}` })),
      total,
    }
  }

  public async loadStandardOperationOptions(
    page: number,
    limit: number,
    _: string | null,
    filters?: IStandardOperationFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { total, data } = await this.standardOperationService.getAll({ limit, page }, filters)
    return {
      total,
      data: [StandardOperationNone, ...data.map(({ number }) => defineAutocompleteOption(number))],
    }
  }

  public async loadInvoiceOptions(page: number, limit: number): Promise<IPaginationData<InvoiceOption>> {
    const { total, data } = await this.invoiceService.getAll({ limit, page })
    return {
      total,
      data: data.map((item) => ({ ...item, id: item.invoice_number, label: item.invoice_number })),
    }
  }

  public async loadTransferOrderOptions(
    page: number,
    limit: number,
    _searchFilter: string | null = null,
    filters?: ITransferOrderFilters
  ): Promise<IPaginationData<TransferOrderOption>> {
    const { total, data } = await this.transferOrderService.getAll({ limit, page }, filters)
    return {
      total,
      data: data.map((item) => ({ ...item, id: item.order_number, label: item.order_number })),
    }
  }

  public async loadInventoryAvailabilityOptions(
    page: number,
    limit: number,
    _searchFilter: string | null = null,
    filters?: IInventoryAvailabilityFilters
  ): Promise<IPaginationData<InventoryAvailabilityOption>> {
    const { total, data } = await this.inventoryAvailabilityService.getAll({ limit, page }, filters)
    return {
      total,
      data: data.map((item) => ({ ...item, id: item.item_number, label: item.item_number })),
    }
  }

  public async loadWorkCenterOptions(
    page: number,
    limit: number,
    _: string | null,
    filters?: IWorkingCenterFilters
  ): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { total, data } = await this.workCenterService.getAll({ limit, page }, filters)
    return {
      total,
      data: data.map(({ code }) => defineAutocompleteOption(code)),
    }
  }

  public async loadOperationTypeOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { total, data } = await this.operationTypesService.getAll({ limit, page })
    return {
      total,
      data: data.map(({ description, code }) => ({ id: code, label: description })),
    }
  }

  public async loadUomOptions(page: number, limit: number): Promise<IPaginationData<InventoryUomOption>> {
    const { total, data } = await this.uomService.getAll({ limit, page })
    return {
      total,
      data: data.map((value) => ({ ...value, id: value.name, label: value.name, uom_conversions: [] })),
    }
  }

  public async loadCompaniesContactRoleOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { total, data } = await this.crmRolesService.getAll({ limit, page })
    const filterData = data.filter(({ appliesto }) => appliesto.includes(RoleAppliesto.Contact))
    return {
      total,
      data: filterData.map(({ name }) => defineAutocompleteOption(name)),
    }
  }

  public async loadCRMPhoneRoleOptions(page: number, limit: number): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { total, data } = await this.crmRolesService.getAll({ limit, page })
    const filterData = data.filter(({ appliesto }) => appliesto.includes(RoleAppliesto.Phone))
    return {
      total,
      data: filterData.map(({ name }) => defineAutocompleteOption(name)),
    }
  }

  public async loadWorkOrderOptions(
    page: number,
    limit: number,
    filter: string | null,
    filters?: IWorkingOrderFilter
  ): Promise<IPaginationData<IXtAutocompleteOption>> {
    const { total, data } = await this.workOrderService.getAll({ limit, page }, { ...filters, search_pattern: filter })

    return {
      total,
      data: data.map((item) => ({ ...item, id: item.order_number, label: item.item_description })),
    }
  }
}
