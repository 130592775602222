import { bindAllInstanceMethods } from 'common/utils/object.utils'
import { UsedOnValue } from 'documents/documents.types'
import { IPaginationData } from 'common/common.types'
import { ICharacteristicService } from './characteristics.sevice'
import { CharacteristicOption } from './characteristics.types'
import { defineCharacteristicOption } from './characteristics.utils'

export interface ICharacteristicsUtilsService {
  loadCharacteristicOptions(
    page: number,
    limit: number,
    filter: string | null,
    usedOn: UsedOnValue
  ): Promise<IPaginationData<CharacteristicOption>>
}

export class CharacteristicsUtilsService implements ICharacteristicsUtilsService {
  constructor(private readonly characteristicsService: ICharacteristicService) {
    bindAllInstanceMethods(this)
  }

  public async loadCharacteristicOptions(
    page: number,
    limit: number,
    _searchFilter: string | null,
    usedOn: UsedOnValue
  ): Promise<IPaginationData<CharacteristicOption>> {
    const { total, data } = await this.characteristicsService.getAll({ limit, page }, usedOn)
    return {
      total,
      data: data.map((item) => defineCharacteristicOption(item)),
    }
  }
}
