/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react'
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { Table, TableBody, TableContainer, TablePagination, TablePaginationProps } from '@material-ui/core'
import { useCallback, useRef, useEffect } from 'react'
import { useCoreModule } from 'core/core-module-hook'
import { useAuthModule } from 'auth/auth-module-hook'
import { useUsersModule } from 'users/users-module-hook'
import { noDataAvailable } from 'common/constants'
import { LoadingSpinner } from 'components/loading-spinner'
import { XtTableHead } from './table-head/table-head'
import { IPagination, IXtTableType } from './table.types'
import { XtTableRow } from './table-row/table-row'
import { cls } from '../../common/utils/utils'
import { arePropsEqual } from './table.utils'
import './table.scss'

const tableContainerRootClasses = { root: 'xt-table-container' }
const tableRootClasses = { root: 'xt-table-root-element' }
const tableBodyClasses = { root: 'xt-table-body' }
const tablePaginationRootClasses = { root: 'xt-table-pagination' }
const defaultRowsPerPageOptions = [10, 25, 50, 100]

export const getRowsPerPageOptions = (pagination: IPagination): Array<number> => {
  if (!pagination || defaultRowsPerPageOptions.includes(pagination.rowsPerPage)) {
    return defaultRowsPerPageOptions
  }
  return [...defaultRowsPerPageOptions, pagination.rowsPerPage].sort((a, b) => a - b)
}

// TODO check out dnd on small screen width
// for performance issues search for react beautiful dnd optimize performance
export const XtTable: IXtTableType = React.memo(
  ({
    columns,
    rows,
    loading = false,
    onRowClick,
    withDnd = false,
    onRowPositionChanged,
    rowClassName,
    pagination,
    className,
    sortOptions,
    onColumnHeaderClick,
    renderRow,
    infoMsg,
    loadMessage = 'Loading...',
  }) => {
    const { ToastService } = useCoreModule()
    const { AuthService } = useAuthModule()
    const { UsersService } = useUsersModule()
    const isInitialized = useRef<boolean>(false)
    if (withDnd && typeof onRowPositionChanged !== 'function') {
      throw Error('onRowPositionChanged should be provided for proper dnd feature usage.')
    }

    const onDragEnd = useCallback(
      (result: DropResult, _: ResponderProvided) => {
        if (!withDnd || typeof onRowPositionChanged !== 'function') {
          return
        }

        const { destination, source, draggableId, reason } = result

        if (!destination || reason === 'CANCEL') {
          return
        }
        const oldPosition = source.index
        const newPosition = destination.index
        if (oldPosition === newPosition && destination.droppableId === source.droppableId) {
          return
        }

        onRowPositionChanged({
          newPosition,
          oldPosition,
          rowId: draggableId,
        })
      },
      [withDnd, onRowPositionChanged]
    )

    const onChangePage = useCallback<TablePaginationProps['onChangePage']>((_, newPage) => pagination?.onChangePage(newPage), [pagination])

    const onChangeRowsPerPage: TablePaginationProps['onChangeRowsPerPage'] = useCallback(
      ({ target: { value } }) => {
        pagination?.onChangeRowsPerPage(parseInt(value, 10))
        const init = async (): Promise<void> => {
          const username = AuthService.getUsername()
          const user = username && (await UsersService.get(username))
          if (!user) return
          try {
            await UsersService.update({
              ...user,
              rows_per_page: parseInt(value, 10),
            })
          } catch {
            ToastService.showError('Something went wrong.')
          }
        }
        void init()
      },
      [pagination, AuthService, UsersService]
    )

    useEffect(() => {
      if (!isInitialized.current && loading) isInitialized.current = true
    }, [loading])

    return (
      <div className={cls('xt-table', className)}>
        <TableContainer classes={tableContainerRootClasses}>
          <Table component="div" classes={tableRootClasses}>
            <XtTableHead loading={false} columns={columns} sortOptions={sortOptions} onSortLabelClick={onColumnHeaderClick} />
            <div hidden={!loading} className="xt-table-empty-container">
              <LoadingSpinner text={loadMessage} size={30} className="xt-table-loader" />
            </div>
            <div hidden={loading || rows.length !== 0} className="xt-table-empty-container">
              {infoMsg ? infoMsg : isInitialized.current ? noDataAvailable : ''}
            </div>
            {(rows.length > 0 || loading) && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable isDropDisabled={!withDnd} droppableId="droppable">
                  {(provided) => (
                    <TableBody classes={tableBodyClasses} component="div" {...provided.droppableProps} ref={provided.innerRef}>
                      {rows.map((row, index) => (
                        <XtTableRow
                          key={row.id}
                          rowClassName={rowClassName}
                          withDnd={withDnd}
                          onClick={onRowClick}
                          item={row}
                          columns={columns}
                          index={index}
                          selected={row.selected}
                          renderRow={renderRow}
                        />
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Table>
        </TableContainer>
        {pagination && (
          <TablePagination
            classes={tablePaginationRootClasses}
            component="div"
            count={pagination.count}
            rowsPerPage={pagination.rowsPerPage}
            rowsPerPageOptions={getRowsPerPageOptions(pagination)}
            page={pagination.page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        )}
      </div>
    )
  },
  arePropsEqual
)
