import { IRouting, IRoutingItem } from './routing.types'
import { IRoutingListTableRow } from './routing-list/routing-list.types'
import { IRoutingDetailsTableItem } from './routing-details/routing-details.types'

export const normalizeData = (data: IRouting[]): IRoutingListTableRow[] =>
  data.map((routing, index) => ({ ...routing, id: `${routing.item_number}${index}` }))

export function normalizeRoutingItemsData(data: IRoutingItem[]): IRoutingDetailsTableItem[] {
  return data.map((item) => ({
    ...item,
    id: item.sequence_number,
    description: item.routing_description2 ? `${item.routing_description1} - ${item.routing_description2}` : item.routing_description1,
  }))
}
