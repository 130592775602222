import { IBasePhoneDialog } from '../../phone-action-dialog.types'

export enum PhoneCallTwilioField {
  Phone = 'phone',
  IsRecordCall = 'is_record_call',
}
export enum PhoneCallTwilioLabel {
  Phone = 'Enter your phone number',
  IsRecordCall = 'Do you want to record a call?',
}
export interface IPhoneCallTwilioFormState {
  [PhoneCallTwilioField.Phone]: string
  [PhoneCallTwilioField.IsRecordCall]: boolean
}
export interface IPhoneCallTwilioDialog extends IBasePhoneDialog {
  onSubmit: () => void
}
