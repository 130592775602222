import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { XtMode } from 'common/common.types'
import { IAddresses, IAccount } from '../accounts.types'

export enum AccountDetailsType {
  Organization = 'Organization',
  Individual = 'Individual',
}

export enum AccountDetailsField {
  AccountNumber = 'number',
  AccountName = 'name',
  Active = 'active',
  Parent = 'parent',
  Owner = 'owner',
  Type = 'type',
  Addresses = 'addresses',
  AssignedUser = 'assigned_user',
  AccountTasks = 'account_tasks',
}

export enum AccountDetailsLabel {
  AccountNumber = 'Account #',
  AccountName = 'Account Name',
  Active = 'Active',
  Parent = 'Parent',
  Owner = 'Owner',
  Cancel = 'Cancel',
  Save = 'Save',
}

export interface IAccountDetails {
  accountNumber: string | null
  onCancel(): void
  mode: XtMode
  className?: string
}

export type AccountExtended = IAccount & { notes: string }

export interface IAccountDetailsParams {
  id: string
}

export interface IAccountDetailsState {
  loading: boolean
  account: AccountExtended | null
}

export interface IAccountsDetails {
  [AccountDetailsField.AccountNumber]: string
  [AccountDetailsField.AccountName]: string
  [AccountDetailsField.Active]: boolean
  [AccountDetailsField.Parent]: IXtAutocompleteOption | null
  [AccountDetailsField.Owner]: IXtAutocompleteOption | null
  [AccountDetailsField.Type]: string
  [AccountDetailsField.Addresses]: IAddresses[] | null
  [AccountDetailsField.AssignedUser]: string | null
}
