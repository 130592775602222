import * as React from 'react'
import { Controller } from 'react-hook-form'
import { convertToError, shouldShowError } from 'common/utils/form/form.components.utils'
import { IBaseControllerProps } from 'common/utils/form/form.components.types'
import { handleOnBlur } from 'common/utils/form/form.components'
import { XtAutocompleteFiltersType, XtAutocompleteLoadOptionsFunc } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { XtItemNumber } from './item-number'
import { IXtItemNumber, IXtItemNumberOptionBase } from './item-number.types'

interface IFormXtItemNumber<
  TFieldValues,
  LoadFunc extends XtAutocompleteLoadOptionsFunc<IXtItemNumberOptionBase, TFilters>,
  TFilters = XtAutocompleteFiltersType<LoadFunc>
> extends Omit<IBaseControllerProps<TFieldValues>, 'label'>,
    Omit<IXtItemNumber<LoadFunc>, 'value'> {}

export function FormXtItemNumber<
  TFieldValues,
  LoadFunc extends XtAutocompleteLoadOptionsFunc<IXtItemNumberOptionBase, TFilters>,
  TFilters = XtAutocompleteFiltersType<LoadFunc>
>({
  control,
  name,
  disabled,
  onChange,
  loadOptions,
  label,
  loading,
  onBlur,
  filters,
  className,
  hidden,
  error,
  isEditMode,
  renderOption,
  required,
  getInputLabel,
}: IFormXtItemNumber<TFieldValues, LoadFunc>): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { value, onChange: onControlChange, onBlur: onControlBlur },
        fieldState: { error: fieldError, isTouched },
        formState: { isSubmitted },
      }) => {
        const controlError = shouldShowError(isSubmitted, isTouched, disabled) ? convertToError(error, fieldError) : undefined

        const controlValue = value as IXtItemNumber<LoadFunc>['value']

        return (
          <XtItemNumber
            isEditMode={isEditMode}
            hidden={hidden}
            value={controlValue ?? null}
            className={className}
            onChange={onChange ?? onControlChange}
            onBlur={() => handleOnBlur({ onControlBlur, onBlur })}
            error={controlError}
            disabled={disabled}
            filters={filters}
            loadOptions={loadOptions}
            label={label}
            loading={loading}
            renderOption={renderOption}
            required={required}
            getInputLabel={getInputLabel}
          />
        )
      }}
    />
  )
}
