import * as yup from 'yup'
import { DateSchema, NumberSchema } from 'yup'
import startOfDay from 'date-fns/startOfDay'
import { IXtAutocompleteOption } from 'components/controls/xt-autocomplete/xt-autocomplete.types'
import { validationMessage } from 'common/validation/validation'
import { dateYupValidator } from 'common/utils/date.utils'
import { lookAheadDaysMaxValue, lookAheadDaysMinValue } from './inventory-list-filter.constants'
import { AsOfFilters, WorkbenchFilterFormField } from './inventory-list-filter.types'

export const workbenchFilterValidationSchema = yup.object().shape(
  {
    [WorkbenchFilterFormField.LookAheadDays]: yup
      .number()
      .when(WorkbenchFilterFormField.FilterType, (filter: IXtAutocompleteOption, schema: NumberSchema) =>
        filter.id === AsOfFilters.LookAheadDays
          ? schema
              .min(lookAheadDaysMinValue, validationMessage.min(lookAheadDaysMinValue))
              .max(lookAheadDaysMaxValue, validationMessage.max(lookAheadDaysMaxValue))
          : schema
      ),
    [WorkbenchFilterFormField.CutoffDate]: dateYupValidator.when(
      WorkbenchFilterFormField.FilterType,
      (filter: IXtAutocompleteOption, schema: DateSchema) =>
        filter.id === AsOfFilters.CutoffDate ? schema.nullable().typeError(validationMessage.invalidDate) : schema
    ),
    [WorkbenchFilterFormField.StartDate]: dateYupValidator.when(
      WorkbenchFilterFormField.FilterType,
      (filter: IXtAutocompleteOption, schema: DateSchema) =>
        filter.id === AsOfFilters.Dates
          ? schema
              .nullable()
              .typeError(validationMessage.invalidDate)
              .when(WorkbenchFilterFormField.EndDate, (endDate: Date | null, dateSchema: DateSchema) =>
                endDate instanceof Date ? dateSchema.required(validationMessage.isRequired) : dateSchema
              )
              .test({
                name: 'maxDate',
                exclusive: true,
                message: validationMessage.maxDate('To date'),
                test(startDate) {
                  const endDate = this?.parent ? this.parent[WorkbenchFilterFormField.EndDate] : null
                  if (startDate instanceof Date && endDate instanceof Date) {
                    return startOfDay(startDate).getTime() <= startOfDay(endDate).getTime()
                  }
                  return true
                },
              })
          : schema
    ),
    [WorkbenchFilterFormField.EndDate]: dateYupValidator.when(
      WorkbenchFilterFormField.FilterType,
      (filter: IXtAutocompleteOption, schema: DateSchema) =>
        filter.id === AsOfFilters.Dates
          ? schema
              .nullable()
              .typeError(validationMessage.invalidDate)
              .when(WorkbenchFilterFormField.StartDate, (startDate: Date | null, dateSchema: DateSchema) =>
                startDate instanceof Date ? dateSchema.required(validationMessage.isRequired) : dateSchema
              )
              .test({
                name: 'minDate',
                exclusive: true,
                message: validationMessage.minDate('From date'),
                test(endDate) {
                  const startDate = this?.parent ? this.parent[WorkbenchFilterFormField.StartDate] : null
                  if (startDate instanceof Date && endDate instanceof Date) {
                    return startOfDay(endDate).getTime() >= startOfDay(startDate).getTime()
                  }
                  return true
                },
              })
          : schema
    ),
  },
  [[WorkbenchFilterFormField.EndDate, WorkbenchFilterFormField.StartDate]]
)
