import { ITaxType } from './tax-types/tax-types.types'
import { IClassCodeDefaultTaxType } from './class-codes/class-codes.types'

export function convertClassCodeTaxTypes(taxTypes: IClassCodeDefaultTaxType[]): ITaxType[] {
  return taxTypes.map((taxType) => ({
    tax_zone: taxType.taxzone_code ?? '',
    isDefault: true,
    taxtype_name: taxType.tax_type,
  }))
}

export function convertTaxTypesToClassCodeTaxTypes(taxTypes: ITaxType[]): IClassCodeDefaultTaxType[] {
  return taxTypes.map((taxType) => ({
    taxzone_code: taxType.tax_zone,
    tax_type: taxType.taxtype_name,
  }))
}
