import { convertContactOption } from 'contacts/contacts.utils'
import { IContact } from 'contacts/contacts.types'
import { ContactSectionData, ContactSectionFormField, IContactSectionForm } from './contact-section.types'

export function convertDataToState(data: ContactSectionData): IContactSectionForm {
  return {
    [ContactSectionFormField.Contact1]: convertContactOption(data?.[ContactSectionFormField.Contact1]),
    [ContactSectionFormField.Contact2]: convertContactOption(data?.[ContactSectionFormField.Contact2]),
  }
}

export function convertToContactSectionData(
  contact1: IContact | null | undefined,
  contact2: IContact | null | undefined
): ContactSectionData {
  return { contact1: contact1 ?? null, contact2: contact2 ?? null }
}
