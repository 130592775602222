import * as React from 'react'
import { FC, useCallback, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'
import { XtButton } from 'components/buttons/xt-button/xt-button'
import { xsMq } from 'common/constants'
import { XtList } from 'components/list/list'
import { IRoutingItemsDialog, IRoutingTableItem, IRoutingItemsState } from './routing-items-dialog.types'
import * as styles from './routing-items-dialog.module.scss'
import { routingDialogColumns } from './routing-dialog.constants'
import { convertBooItems, convertToOperation } from './routing-dialog.utils'

export const RoutingItemsDialog: FC<IRoutingItemsDialog> = ({ onClose, items, parentItemNumber, bomUom }) => {
  const [state, setState] = useState<IRoutingItemsState>({ selected: null, items: convertBooItems(items) })
  const isMobile = useMediaQuery(xsMq)
  const onCancel: (value?: string) => void = (value) => {
    setState((prevState) => ({ ...prevState, selected: null }))
    onClose(value)
  }

  const onSelect: VoidFunction = () => {
    onCancel(convertToOperation(state.selected))
  }

  const onRowClick = useCallback<(item: IRoutingTableItem) => void>(
    (selectedItem) => {
      const updatedItems = state.items.map((item) => ({ ...item, selected: item.id === selectedItem.id }))
      setState({ items: updatedItems, selected: selectedItem })
    },
    [state]
  )

  return (
    <div className={styles.dialog}>
      <div className={styles.topBar}>
        <div className={styles.topBarTitle}>
          <h1>{parentItemNumber}</h1>
          <p>UOM: {bomUom}</p>
        </div>
        <div className={styles.topBarButton}>
          <XtButton onClick={onCancel} label="Cancel" />
          <XtButton disabled={!state?.selected} onClick={onSelect} label="Select" />
        </div>
      </div>
      <XtList isMobile={isMobile} onRowClick={onRowClick} columns={routingDialogColumns} data={state.items} />
    </div>
  )
}
